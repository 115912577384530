import React, { useState, useEffect } from "react";
import { Label, FormGroup, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import api from "../../../constant/apilist";
import client from "../../../Api/HTTPClient";

import { toast } from "react-toastify";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import "react-dropzone-uploader/dist/styles.css";

import { Copy } from "react-feather";

// iMAGES IMPORTS
import welcome from "../../../assets/images/quizDropdown/welcome.png";
import welcomehover from "../../../assets/images/quizDropdown/welcomehover.png";

import questionimg from "../../../assets/images/Question.png";

import single from "../../../assets/images/quizDropdown/single.png";
import singlehover from "../../../assets/images/quizDropdown/singlehover.png";

import yesimg from "../../../assets/images/quizDropdown/imgyesno.png";
// import yesno from "../../../assets/images/quizDropdown/yesno.png";
import yesnohover from "../../../assets/images/quizDropdown/hoveryesno.png";

import multiplechoice from "../../../assets/images/quizDropdown/multiplechoice.png";
import multiplehover from "../../../assets/images/quizDropdown/multiplehover.png";

import picturescale from "../../../assets/images/quizDropdown/picturescale.png";
import picturehover from "../../../assets/images/quizDropdown/picturehover.png";

import "./AppSettings.css";
import { v4 as uuidv4 } from "uuid";
import ImageCroper from "./ImageCroper";
import ImageCroperDynamic from "./ImageCroperDynamic";

const QuizForm = (props) => {
	// UseStates

	// state to open dropdown
	const [dropdown, setDropdown] = useState(false);

	// state to display publish button
	const [publishbtn, setPublishbtn] = useState(false);

	// state to open welcome screen form group
	const [welcomebtn, setWelcomebtn] = useState(false);

	//state for text inside input
	const [welcometext, setWelcometext] = useState("");

	// new state
	const [inputFields, setInputFields] = useState([]);
	const [displayFields, setDisplayFields] = useState([]);
	// when user clicks on button in dropdown
	const [singlechoicebtndrop, setSinglechoicebtndrop] = useState(false);

	// multiple question states
	const [inputFieldsMult, setInputFieldsMult] = useState([]);
	const [displayFieldsMult, setDisplayFieldsMult] = useState([]);
	// when user clicks on button in dropdown
	const [multiplechoicebtndrop, setMultiplechoicebtndrop] = useState(false);

	// picture choice states
	const [inputFieldsPict, setInputFieldsPict] = useState([]);
	const [displayFieldsPict, setDisplayFieldsPict] = useState([]);

	// when user clicks on button in dropdown
	const [picturechoicebtndrop, setPicturechoicebtndrop] = useState(false);

	// Yes/No choice states
	const [inputFieldsYesno, setInputFieldsYesno] = useState([]);
	const [displayFieldsYesno, setDisplayFieldsYesno] = useState([]);

	// when user clicks on button in dropdown
	const [yesnochoicebtndrop, setYesnochoicebtndrop] = useState(false);

	// state for picture icon toggle
	const [pictureins, setPictureins] = useState(false);
	const [pictureinsSingle, setPictureinsSingle] = useState(false);
	const [pictureinsYesno, setPictureinsYesno] = useState(false);
	const [pictureinsMultiple, setPictureinsMultiple] = useState(false);
	const [pictureinsPicture, setPictureinsPicture] = useState(false);

	const [pictureinsPicture1, setPictureinsPicture1] = useState(false);
	const [pictureinsPicture2, setPictureinsPicture2] = useState(false);
	const [pictureinsPicture3, setPictureinsPicture3] = useState(false);
	const [pictureinsPicture4, setPictureinsPicture4] = useState(false);

	const [openModal, setopenModal] = useState(false);
	const [openModalSingle, setopenModalSingle] = useState(false);
	const [openModalYesno, setopenModalYesno] = useState(false);
	const [openModalMultiple, setopenModalMultiple] = useState(false);
	const [openModalPicture, setopenModalPicture] = useState(false);

	const [openModalPicture1, setopenModalPicture1] = useState(false);
	const [openModalPicture2, setopenModalPicture2] = useState(false);
	const [openModalPicture3, setopenModalPicture3] = useState(false);
	const [openModalPicture4, setopenModalPicture4] = useState(false);

	const [singleChoiceBtn, setSingleChoiceBtn] = useState(false);

	const [multipleChoiceBtn, setMultipleChoiceBtn] = useState(false);

	const [pictureChoiceBtn, setPictureChoiceBtn] = useState(false);

	const [yesnoChoiceBtn, setYesnoChoiceBtn] = useState(false);

	// state for validation
	const [publishClk, setPublishClk] = useState(false);
	// State for image handling
	const [welcomeImage, setWelcomeImage] = useState(null);

	const [welcomeTxtError, setWelcomeTxtError] = useState(false);
	const [singleError, setsingleError] = useState(false);
	const [multipleError, setmultipleError] = useState(false);
	const [pictureError, setpictureError] = useState(false);
	const [yesnoError, setyesnoError] = useState(false);
	const [inputFieldId, setInputFieldId] = useState(null);

	// Funct on btn (div) click for preview
	const welcomebtnclick = () => {
		setWelcomebtn(true);
		setSingleChoiceBtn(false);
		setMultipleChoiceBtn(false);
		setPictureChoiceBtn(false);
		setYesnoChoiceBtn(false);
	};

	const singlebtnclick = () => {
		setSingleChoiceBtn(true);
		setMultipleChoiceBtn(false);
		setPictureChoiceBtn(false);
		setYesnoChoiceBtn(false);
	};

	const multiplebtnclick = () => {
		setSingleChoiceBtn(false);
		setMultipleChoiceBtn(true);
		setPictureChoiceBtn(false);
		setYesnoChoiceBtn(false);
	};

	const picturebtnclick = () => {
		setPictureChoiceBtn(true);
		setSingleChoiceBtn(false);
		setMultipleChoiceBtn(false);
		setYesnoChoiceBtn(false);
	};

	const yesnobtnclick = () => {
		setPictureChoiceBtn(false);
		setSingleChoiceBtn(false);
		setMultipleChoiceBtn(false);
		setYesnoChoiceBtn(true);
	};
	// Func to open dropdown

	const openDropdown = () => {
		setDropdown(!dropdown);
	};

	// Func to open Welcome formgroup
	const welcomeclickfunc = () => {
		setDropdown(false);
		setPublishbtn(true);
		setWelcomebtn(true);
		setSingleChoiceBtn(false);
		setMultipleChoiceBtn(false);
		setPictureChoiceBtn(false);
		setYesnoChoiceBtn(false);
	};

	const handleWelcomeDelete = (e) => {
		e.preventDefault();
		setWelcometext("");
		setWelcomebtn(false);
		console.log(welcomebtn, "welcomebtn");
		console.log(welcometext, "welcometext");
	};

	// onclick functs of dropdown divs respectively
	const singleclickfunc = (e) => {
		e.preventDefault();
		setDropdown(false);
		setInputFields([
			...inputFields,
			{
				id: uuidv4(),
				type: 1,
				quizQuestion: "",
				choice1: "",
				choice2: "",
				choice3: "",
				choice4: "",
				radio0: "",
				radio: "",
				points: "",
				seconds: "",
			},
		]);
		setSinglechoicebtndrop(true);
		setPublishbtn(true);
		// setSingleChoice([...singleChoice, "single choice"]);
		setSingleChoiceBtn(true);
		setMultipleChoiceBtn(false);
		setPictureChoiceBtn(false);
		setYesnoChoiceBtn(false);
	};

	const yesnoclickfunc = (e) => {
		e.preventDefault();
		setDropdown(false);
		setInputFieldsYesno([
			...inputFieldsYesno,
			{
				id: uuidv4(),
				type: 5,
				quizQuestion: "",
				choice1: "",
				choice2: "",
				radio0: "",
				radio: "",
				points: "",
				seconds: "",
			},
		]);
		setYesnochoicebtndrop(true);
		setYesnoChoiceBtn(true);
		setPublishbtn(true);
		// setSingleChoice([...singleChoice, "single choice"]);
		setSingleChoiceBtn(false);
		setMultipleChoiceBtn(false);
		setPictureChoiceBtn(false);
	};

	const multipleclickfunc = (e) => {
		setDropdown(false);
		setPublishbtn(true);
		// setSinglechoicebtndrop(false);
		setMultiplechoicebtndrop(true);
		setInputFieldsMult([
			...inputFieldsMult,
			{
				id: uuidv4(),
				type: 2,
				quizQuestion: "",
				choice1: "",
				choice2: "",
				choice3: "",
				choice4: "",
				checkbox1: false,
				checkbox2: false,
				checkbox3: false,
				checkbox4: false,
				points: "",
				seconds: "",
			},
		]);
		setSingleChoiceBtn(false);
		setMultipleChoiceBtn(true);
		setPictureChoiceBtn(false);
		setYesnoChoiceBtn(false);
	};

	const pictureclickfunc = (e) => {
		setDropdown(false);
		setPublishbtn(true);
		setInputFieldsPict([
			...inputFieldsPict,
			{
				id: uuidv4(),
				type: 3,
				quizQuestion: "",
				choice1: "",
				choice2: "",
				choice3: "",
				choice4: "",
				radio0: "",
				radio: "",
				points: "",
				seconds: "",
			},
		]);
		setPicturechoicebtndrop(true);
		setSingleChoiceBtn(false);
		setMultipleChoiceBtn(false);
		setPictureChoiceBtn(true);
		setYesnoChoiceBtn(false);
	};

	const [mradioError, setMRadioError] = useState(false);
	const [pimageError, setPImageError] = useState(false);

	// Main submit func
	const sumbitfunc = async () => {
		const postdata = {
			welcomeText: welcometext,
			singleQues: inputFields,
			multipleQues: inputFieldsMult,
			pictureQues: inputFieldsPict,
			welcomeImage: welcomeImage,
			yesnoQues: inputFieldsYesno,
		};
		setPublishClk(true);
		console.log(postdata);
		// console.log(inputFields.length, "single");
		// console.log(inputFieldsMult.length, "multiple");
		// console.log(inputFieldsPict.length, "pict");
		// console.log(inputFieldsYesno.length, "yesno");
		const quesLength = inputFields.length + inputFieldsMult.length + inputFieldsPict.length + inputFieldsYesno.length;
		console.log(quesLength, "quesLength");

		// Validation code

		// Validation for radio buttons

		// single choice
		if (postdata.singleQues.length > 0) {
			console.log("single ques not empty");
			postdata.singleQues.map((valid, index) => {
				console.log(valid);
				if (index == 0 && valid.radio0 == "") {
					toast.error("please select answer for single choice");
				}
				if (index == 1 && valid.radio1 == undefined) {
					toast.error("please select answer for single choice");
				}
				if (index == 2 && valid.radio2 == undefined) {
					toast.error("please select answer for single choice");
				}
				if (index == 3 && valid.radio3 == undefined) {
					toast.error("please select answer for single choice");
				}
				if (index == 4 && valid.radio3 == undefined) {
					toast.error("please select answer for single choice");
				}
			});
		}
		// yesno choice
		if (postdata.yesnoQues.length > 0) {
			console.log("yes ques not empty");
			postdata.yesnoQues.map((valid, index) => {
				console.log(valid);
				if (index == 0 && valid.radio0 == "") {
					toast.error("please select answer for yes/no ");
				}
				if (index == 1 && valid.radio1 == undefined) {
					toast.error("please select answer for yes/no ");
				}
				if (index == 2 && valid.radio2 == undefined) {
					toast.error("please select answer for yes/no ");
				}
				if (index == 3 && valid.radio3 == undefined) {
					toast.error("please select answer for yes/no ");
				}
				if (index == 4 && valid.radio3 == undefined) {
					toast.error("please select answer for yes/no ");
				}
			});
		}

		// picture choice
		if (postdata.multipleQues.length > 0) {
			console.log("multiple ques not empty");
			// setMRadioError(false);
			postdata.multipleQues.map((valid) => {
				// console.log(valid);
				let length = 0;
				if (valid.checkbox1) {
					length = length + 1;
				}
				if (valid.checkbox2) {
					length = length + 1;
				}
				if (valid.checkbox3) {
					length = length + 1;
				}
				if (valid.checkbox4) {
					length = length + 1;
				}
				if (length < 2) {
					toast.error("please select answer for multiple choice");
					// setMRadioError(true);
				}
			});
		}

		// picture choice
		if (postdata.pictureQues.length > 0) {
			console.log("picture ques not empty");
			postdata.pictureQues.map((valid, index) => {
				console.log(valid);
				if (index == 0 && valid.radio0 == "") {
					toast.error("please select answer for picture choice");
				}
				if (index == 1 && valid.radio1 == undefined) {
					toast.error("please select answer for picture choice");
				}
				if (index == 2 && valid.radio2 == undefined) {
					toast.error("please select answer for picture choice");
				}
				if (index == 3 && valid.radio3 == undefined) {
					toast.error("please select answer for picture choice");
				}
				if (index == 4 && valid.radio3 == undefined) {
					toast.error("please select answer for picture choice");
				}
			});
		}

		// validation for Picture choice images
		if (postdata.pictureQues.length > 0) {
			console.log("picture ques not empty");
			postdata.pictureQues.map((valid) => {
				console.log(valid);
				if (!valid.pictureImage1) {
					toast.error("Please insert Picture image 1");
				}
				if (!valid.pictureImage2) {
					toast.error("Please insert Picture image 2");
				}
				if (!valid.pictureImage3) {
					toast.error("Please insert Picture image 3");
				}
				if (!valid.pictureImage4) {
					toast.error("Please insert Picture image 4");
				}
			});
		}
		console.log(postdata, "postdata");
		validateFunc();
		if (quesLength > 1) {
			// console.log(mradioError, "mradioErrorrrrrr");

			if (
				welcomeTxtError == false &&
				singleError == false &&
				yesnoError == false &&
				multipleError == false &&
				pictureError == false &&
				sradioError == false &&
				ynradioError == false &&
				mradioError == false &&
				pradioError == false &&
				pimageError == false
			) {
				// console.log("yipeeee");
				props.setPostData(postdata);
				props.setTabIndex(1);
			} else {
				toast.error("please fill the required fields");
			}
		} else {
			toast.error("Minimum 2 questions are required to publish the quiz");
		}
	};

	useEffect(() => {
		validateFunc();
	}, [welcometext, inputFields, inputFieldsYesno, inputFieldsMult, inputFieldsPict]);

	// console.log(radioState, "radio");
	const [sradioError, setSRadioError] = useState(false);
	const [ynradioError, setYNRadioError] = useState(false);
	const [pradioError, setPRadioError] = useState(false);

	const validateFunc = () => {
		if (welcomebtn) {
			if (welcometext.length == 0) {
				// console.log(welcometext.length);
				setWelcomeTxtError(true);
				// console.log(welcomeTxtError, "welcome");
			} else {
				// console.log(welcometext.length, "else");
				setWelcomeTxtError(false);
				// console.log(welcomeTxtError, "welcome fasle");
			}
		}
		if (inputFields.length > 0) {
			inputFields.map((inputField) => {
				console.log(inputField, "inputField");
				if (
					inputField.quizQuestion.length == 0 ||
					inputField.choice1.length == 0 ||
					inputField.choice2.length == 0 ||
					inputField.choice3.length == 0 ||
					inputField.choice4.length == 0 ||
					inputField.points?.length == 0 ||
					inputField.seconds?.length == 0
				) {
					setsingleError(true);
					console.log(" validation single error");
				} else {
					setsingleError(false);
				}
			});
		}
		if (inputFieldsYesno.length > 0) {
			inputFieldsYesno.map((inputFieldYesno) => {
				console.log(inputFieldYesno, "inputField");
				if (
					inputFieldYesno.quizQuestion.length == 0 ||
					inputFieldYesno.choice1.length == 0 ||
					inputFieldYesno.choice2.length == 0 ||
					inputFieldYesno.points?.length == 0 ||
					inputFieldYesno.seconds?.length == 0
				) {
					setyesnoError(true);
					console.log(" validation yesno error");
				} else {
					setyesnoError(false);
				}
			});
		}

		//Validation for Multiple choice
		if (inputFieldsMult.length > 0) {
			console.log(inputFieldsMult.length, "length");
			inputFieldsMult.map((inputField) => {
				console.log(inputField, "inputField multiple");
				if (
					inputField.quizQuestion.length == 0 ||
					inputField.choice1.length == 0 ||
					inputField.choice2.length == 0 ||
					inputField.choice3.length == 0 ||
					inputField.choice4.length == 0 ||
					inputField.points?.length == 0 ||
					inputField.seconds?.length == 0
				) {
					setmultipleError(true);
					console.log(" validation multiple error");
				} else {
					setmultipleError(false);
				}
			});
		}
		// Validation for Picture Choice ques and ans
		if (inputFieldsPict.length > 0) {
			inputFieldsPict.map((inputField) => {
				console.log(inputField, "inputField Picture");
				if (
					inputField.quizQuestion.length == 0 ||
					inputField.choice1.length == 0 ||
					inputField.choice2.length == 0 ||
					inputField.choice3.length == 0 ||
					inputField.choice4.length == 0 ||
					inputField.points?.length == 0 ||
					inputField.seconds?.length == 0
				) {
					setpictureError(true);
					console.log(" validation picture  error");
				} else {
					setpictureError(false);
				}
			});
		}

		// validation for radio single
		if (inputFields.length > 0) {
			// console.log("single ques radio not empty");
			setSRadioError(false);
			inputFields.map((valid, index) => {
				console.log(valid);
				if (index == 0 && valid.radio0 == "") {
					setSRadioError(true);
				}
				if (index == 1 && valid.radio1 == undefined) {
					setSRadioError(true);
				}
				if (index == 2 && valid.radio2 == undefined) {
					setSRadioError(true);
				}
				if (index == 3 && valid.radio3 == undefined) {
					setSRadioError(true);
				}
				if (index == 4 && valid.radio3 == undefined) {
					setSRadioError(true);
				}
			});
		}

		// validation for radio yesno
		if (inputFieldsYesno.length > 0) {
			// console.log("single ques radio not empty");
			setYNRadioError(false);
			inputFieldsYesno.map((valid, index) => {
				console.log(valid);
				if (index == 0 && valid.radio0 == "") {
					setYNRadioError(true);
				}
				if (index == 1 && valid.radio1 == undefined) {
					setYNRadioError(true);
				}
				if (index == 2 && valid.radio2 == undefined) {
					setYNRadioError(true);
				}
				if (index == 3 && valid.radio3 == undefined) {
					setYNRadioError(true);
				}
				if (index == 4 && valid.radio3 == undefined) {
					setYNRadioError(true);
				}
			});
		}

		// multiple  choice checkbox
		if (inputFieldsMult.length > 0) {
			// console.log("multiple ques not empty");
			setMRadioError(false);
			inputFieldsMult.map((valid) => {
				let length = 0;
				if (valid.checkbox1) {
					length = length + 1;
				}
				if (valid.checkbox2) {
					length = length + 1;
				}
				if (valid.checkbox3) {
					length = length + 1;
				}
				if (valid.checkbox4) {
					length = length + 1;
				}
				if (length < 2) {
					// toast.error("please select answer for multiple choice");
					setMRadioError(true);
				}
			});
		}

		//  Validation for picture ques choice
		if (inputFieldsPict.length > 0) {
			// console.log("picture ques radio not empty");
			setPRadioError(false);
			inputFieldsPict.map((valid, index) => {
				console.log(valid);
				if (index == 0 && valid.radio0 == "") {
					setPRadioError(true);
				}
				if (index == 1 && valid.radio1 == undefined) {
					setPRadioError(true);
				}
				if (index == 2 && valid.radio2 == undefined) {
					setPRadioError(true);
				}
				if (index == 3 && valid.radio3 == undefined) {
					setPRadioError(true);
				}
				if (index == 4 && valid.radio3 == undefined) {
					setPRadioError(true);
				}
			});
		}
		if (inputFieldsPict.length > 0) {
			// console.log("picture ques not empty");
			setPImageError(false);
			inputFieldsPict.map((valid) => {
				console.log(valid);
				if (!valid.pictureImage1 || !valid.pictureImage2 || !valid.pictureImage3 || !valid.pictureImage4) {
					setPImageError(true);
				} else {
					setPImageError(false);
				}
			});
		}
	};
	// console.log(sradioError, "sradioError");

	// Func when user clicks picture icon
	const pictureinsert = () => {
		setPictureins(true);
		setopenModal(true);
	};
	// Func when user clicks picture icon of  single choice
	const pictureinsertSingle = () => {
		setPictureinsSingle(true);
		setopenModalSingle(true);
	};
	// Func when user clicks picture icon of  yesno choice
	const pictureinsertYesno = () => {
		setPictureinsYesno(true);
		setopenModalYesno(true);
	};
	// Func when user clicks picture icon of  Multiple choice
	const pictureinsertMultiple = () => {
		setPictureinsMultiple(true);
		setopenModalMultiple(true);
	};

	// Func when user clicks picture icon of  Picture choice
	const pictureinsertPicture = () => {
		setPictureinsPicture(true);
		setopenModalPicture(true);
	};

	const pictureinsertPicture1 = () => {
		setPictureinsPicture1(true);
		setopenModalPicture1(true);
	};
	const pictureinsertPicture2 = () => {
		setPictureinsPicture2(true);
		setopenModalPicture2(true);
	};
	const pictureinsertPicture3 = () => {
		setPictureinsPicture3(true);
		setopenModalPicture3(true);
	};
	const pictureinsertPicture4 = () => {
		setPictureinsPicture4(true);
		setopenModalPicture4(true);
	};

	//Popover
	const [popoverOpen, setPopoverOpen] = useState(false);
	const Toggle = () => {
		setPopoverOpen(!popoverOpen);
	};

	const [popoverOpenSingle, setPopoverOpenSingle] = useState(false);
	const ToggleSingle = () => {
		setPopoverOpenSingle(!popoverOpenSingle);
	};

	const [popoverOpenMultiple, setPopoverOpenMultiple] = useState(false);
	const ToggleMultiple = () => {
		setPopoverOpenMultiple(!popoverOpenMultiple);
	};

	const [popoverOpenPict, setPopoverOpenPict] = useState(false);
	const TogglePict = () => {
		setPopoverOpenPict(!popoverOpenPict);
	};

	// function for Dropdowns Screen

	// Function for welcome Screen option when hovered
	const welcomefunc = () => {
		document.querySelector(".hovereffectswelcome").style.display = "block";
	};
	const welcomeleave = () => {
		document.querySelector(".hovereffectswelcome").style.display = "none";
	};

	// Function for single choice Screen option when hovered
	const singlefunc = () => {
		document.querySelector(".hovereffectssingle").style.display = "block";
	};
	const singleleave = () => {
		document.querySelector(".hovereffectssingle").style.display = "none";
	};

	// Function for single choice Screen option when hovered
	const yesnofunc = () => {
		document.querySelector(".hovereffectsyesno").style.display = "block";
	};
	const yesnoleave = () => {
		document.querySelector(".hovereffectsyesno").style.display = "none";
	};

	// Function for Multiple Screen option when hovered
	const multiplefunc = () => {
		document.querySelector(".hovereffectsmultiple").style.display = "block";
	};
	const multipleleave = () => {
		document.querySelector(".hovereffectsmultiple").style.display = "none";
	};

	// Function for picture Screen option when hovered
	const picturefunc = () => {
		document.querySelector(".hovereffectspicture").style.display = "block";
	};
	const pictureleave = () => {
		document.querySelector(".hovereffectspicture").style.display = "none";
	};

	//To render Preview question  text respectively
	const renderPreviewtext = () => {
		if (pictureins) {
			if (welcometext.length == 0) {
				return <h6 style={{ marginLeft: "30%" }}>Preview</h6>;
			} else if (welcometext.length <= 20) {
				return <h5 style={{ textAlign: "center" }}>{welcometext}</h5>;
			} else {
				return <span className="previewtext">{welcometext}</span>;
			}
		} else {
			if (welcometext.length == 0) {
				return <h6 style={{ marginLeft: "30%" }}>Preview</h6>;
			} else if (welcometext.length <= 20) {
				return <h5 style={{ textAlign: "center" }}>{welcometext}</h5>;
			} else {
				return <span className="previewtext">{welcometext}</span>;
			}
		}
	};

	// render Single choice previews
	const renderSinglequestion = (inputField) => {
		if (inputField.quizQuestion.length > 0 && inputField.quizQuestion.length <= 16) {
			document.querySelector(".smartphone").style.height = "600px";
			return <h6>{inputField.quizQuestion}</h6>;
		} else if (inputField.quizQuestion.length > 0 && inputField.quizQuestion.length <= 36) {
			document.querySelector(".smartphone").style.height = "650px";
			return <span style={{ fontSize: "18px" }}>{inputField.quizQuestion}</span>;
		} else if (inputField.quizQuestion.length > 0 && inputField.quizQuestion.length <= 55) {
			document.querySelector(".smartphone").style.height = "700px";
			return <span>{inputField.quizQuestion}</span>;
		} else if (inputField.quizQuestion.length > 0 && inputField.quizQuestion.length <= 75) {
			document.querySelector(".smartphone").style.height = "750px";
			document.querySelector(".headertitle").style.top = "5%";
			return <span>{inputField.quizQuestion}</span>;
		} else if (inputField.quizQuestion.length > 0) {
			document.querySelector(".smartphone").style.height = "800px";
			document.querySelector(".headertitle").style.top = "4%";
			return <span>{inputField.quizQuestion}</span>;
		} else {
			document.querySelector(".smartphone").style.height = "600px";
			return <h6>Your question here</h6>;
		}
	};

	// render Yes/No choice previews
	const renderYesnoquestion = (inputFieldYesno) => {
		if (inputFieldYesno.quizQuestion.length > 0 && inputFieldYesno.quizQuestion.length <= 16) {
			document.querySelector(".smartphone").style.height = "600px";
			return <h6>{inputFieldYesno.quizQuestion}</h6>;
		} else if (inputFieldYesno.quizQuestion.length > 0 && inputFieldYesno.quizQuestion.length <= 36) {
			document.querySelector(".smartphone").style.height = "650px";
			return <span style={{ fontSize: "18px" }}>{inputFieldYesno.quizQuestion}</span>;
		} else if (inputFieldYesno.quizQuestion.length > 0 && inputFieldYesno.quizQuestion.length <= 55) {
			document.querySelector(".smartphone").style.height = "700px";
			return <span>{inputFieldYesno.quizQuestion}</span>;
		} else if (inputFieldYesno.quizQuestion.length > 0 && inputFieldYesno.quizQuestion.length <= 75) {
			document.querySelector(".smartphone").style.height = "750px";
			document.querySelector(".headertitle").style.top = "5%";
			return <span>{inputFieldYesno.quizQuestion}</span>;
		} else if (inputFieldYesno.quizQuestion.length > 0) {
			document.querySelector(".smartphone").style.height = "800px";
			document.querySelector(".headertitle").style.top = "4%";
			return <span>{inputFieldYesno.quizQuestion}</span>;
		} else {
			document.querySelector(".smartphone").style.height = "600px";
			return <h6>Your question here</h6>;
		}
	};

	// render Multiple choice preview
	const renderMultiplequestion = (inputFieldMult) => {
		if (inputFieldMult.quizQuestion.length > 0 && inputFieldMult.quizQuestion.length <= 16) {
			document.querySelector(".smartphone").style.height = "600px";
			return <h6>{inputFieldMult.quizQuestion}</h6>;
		} else if (inputFieldMult.quizQuestion.length > 0 && inputFieldMult.quizQuestion.length <= 36) {
			document.querySelector(".smartphone").style.height = "650px";
			return <span style={{ fontSize: "18px" }}>{inputFieldMult.quizQuestion}</span>;
		} else if (inputFieldMult.quizQuestion.length > 0 && inputFieldMult.quizQuestion.length <= 55) {
			document.querySelector(".smartphone").style.height = "700px";
			document.querySelector(".headertitle").style.top = "5%";

			return <span>{inputFieldMult.quizQuestion}</span>;
		} else if (inputFieldMult.quizQuestion.length > 0 && inputFieldMult.quizQuestion.length <= 75) {
			document.querySelector(".smartphone").style.height = "750px";
			document.querySelector(".headertitle").style.top = "5%";

			return <span>{inputFieldMult.quizQuestion}</span>;
		} else if (inputFieldMult.quizQuestion.length > 0) {
			document.querySelector(".smartphone").style.height = "800px";
			document.querySelector(".headertitle").style.top = "5%";

			return <span>{inputFieldMult.quizQuestion}</span>;
		} else {
			document.querySelector(".smartphone").style.height = "600px";
			return <h6>Your question here.</h6>;
		}
	};

	// render Picture choice
	const renderPicturequestion = (inputFieldPict) => {
		if (inputFieldPict.quizQuestion.length > 0 && inputFieldPict.quizQuestion.length <= 16) {
			document.querySelector(".smartphone").style.height = "620px";
			return <h6>{inputFieldPict.quizQuestion}</h6>;
		} else if (inputFieldPict.quizQuestion.length > 0 && inputFieldPict.quizQuestion.length <= 36) {
			document.querySelector(".smartphone").style.height = "650px";
			return <span style={{ fontSize: "16px" }}>{inputFieldPict.quizQuestion}</span>;
		} else if (inputFieldPict.quizQuestion.length > 0 && inputFieldPict.quizQuestion.length <= 55) {
			document.querySelector(".smartphone").style.height = "700px";
			document.querySelector(".headertitle").style.top = "5%";

			return <span>{inputFieldPict.quizQuestion}</span>;
		} else if (inputFieldPict.quizQuestion.length > 0 && inputFieldPict.quizQuestion.length <= 75) {
			document.querySelector(".smartphone").style.height = "750px";
			document.querySelector(".headertitle").style.top = "5%";

			return <span>{inputFieldPict.quizQuestion}</span>;
		} else if (inputFieldPict.quizQuestion.length > 0) {
			document.querySelector(".smartphone").style.height = "750px";
			document.querySelector(".headertitle").style.top = "5%";

			return <span>{inputFieldPict.quizQuestion}</span>;
		} else {
			document.querySelector(".smartphone").style.height = "620px";
			return <h6>Your question here</h6>;
		}
	};

	//-----------------------------------------------------Render Smartphone----------------------------------------------------------------
	const renderSmartphone = () => {
		if (singleChoiceBtn) {
			return (
				<div className="smartphone">
					<div className="smartphoneheader">
						<h6 className="headertitle">
							<i className="fa fa-angle-left fa-lg"></i>&nbsp;&nbsp;iPayPro Play
						</h6>
					</div>
					{displayFields.map((inputField) => (
						<div key={inputField.id}>
							<div className="smartphonebody">
								<div className="timercont">
									<div className="progresscont">
										<div class="progress progressdiv">
											<div
												class="progress-bar"
												role="progressbar"
												style={{ width: "50%", backgroundColor: "#3d90ab" }}
												aria-valuenow="50"
												aria-valuemin="0"
												aria-valuemax="100"
											></div>
										</div>
									</div>
								</div>
								{inputField.singleImage?.imageresult ? (
									<div className="imagecard">
										<img src={inputField.singleImage?.imageresult} alt="Image" className="imagecardimage" />
									</div>
								) : (
									<div className="imagecardsingle">
										<img className="imagecardimagesingle" src={questionimg} alt="Quiz" />
									</div>
								)}
								{/* <div className="imagecardsingle">
									<img className="imagecardimagesingle" src={questionimg} alt="Quiz" />
								</div> */}
								<div class="centerchoice">
									{/* <h5 style={{color:"grey"}}>{welcometext.length==0?<h6 style={{marginLeft:"30%"}}>Preview</h6>:<span className="previewtext">{welcometext}</span>}</h5> */}
									{/* {renderPreviewtext()} */}
									{renderSinglequestion(inputField)}
									<Row>
										<div className="previewchoicediv">
											{inputField.choice1.length > 0 ? <h6>{inputField.choice1}</h6> : <h6>Choice 1</h6>}
										</div>
									</Row>
									<Row>
										<div className="previewchoicediv">
											{inputField.choice2.length > 0 ? <h6>{inputField.choice2}</h6> : <h6>Choice 2</h6>}
										</div>
									</Row>
									<Row>
										<div className="previewchoicediv">
											{inputField.choice3.length > 0 ? <h6>{inputField.choice3}</h6> : <h6>Choice 3</h6>}
										</div>
									</Row>
									<Row>
										<div className="previewchoicediv">
											{inputField.choice4.length > 0 ? <h6>{inputField.choice4}</h6> : <h6>Choice 4</h6>}
										</div>
									</Row>
								</div>
							</div>
						</div>
					))}
					<div className="smartphonefooter">
						<button className="previewcontinuebtn">Continue</button>
					</div>
				</div>
			);
		} else if (yesnoChoiceBtn) {
			return (
				<div className="smartphone">
					<div className="smartphoneheader">
						<h6 className="headertitle">
							<i className="fa fa-angle-left fa-lg"></i>&nbsp;&nbsp;iPayPro Play
						</h6>
					</div>
					{displayFieldsYesno.map((inputFieldYesno) => (
						<div key={inputFieldYesno.id}>
							<div className="smartphonebody">
								<div className="timercont">
									<div className="progresscont">
										<div class="progress progressdiv">
											<div
												class="progress-bar"
												role="progressbar"
												style={{ width: "50%", backgroundColor: "#3d90ab" }}
												aria-valuenow="50"
												aria-valuemin="0"
												aria-valuemax="100"
											></div>
										</div>
									</div>
								</div>
								{inputFieldYesno.yesnoImage?.imageresult ? (
									<div className="imagecard">
										<img src={inputFieldYesno.yesnoImage?.imageresult} alt="Image" className="imagecardimage" />
									</div>
								) : (
									<div className="imagecardsingle">
										<img className="imagecardimagesingle" src={questionimg} alt="Quiz" />
									</div>
								)}
								<div class="centerchoiceYesno">
									{/* <h5 style={{color:"grey"}}>{welcometext.length==0?<h6 style={{marginLeft:"30%"}}>Preview</h6>:<span className="previewtext">{welcometext}</span>}</h5> */}
									{/* {renderPreviewtext()} */}
									{renderYesnoquestion(inputFieldYesno)}
									<Row>
										<div className="previewchoicediv">
											{inputFieldYesno.choice1.length > 0 ? <h6>{inputFieldYesno.choice1}</h6> : <h6>Choice 1</h6>}
										</div>
									</Row>
									<Row>
										<div className="previewchoicediv">
											{inputFieldYesno.choice2.length > 0 ? <h6>{inputFieldYesno.choice2}</h6> : <h6>Choice 2</h6>}
										</div>
									</Row>
								</div>
							</div>
						</div>
					))}
					<div className="smartphonefooter">
						<button className="previewcontinuebtn">Continue</button>
					</div>
				</div>
			);
		} else if (multipleChoiceBtn) {
			return (
				<div className="smartphone">
					<div className="smartphoneheader">
						<h6 className="headertitle">
							<i className="fa fa-angle-left fa-lg"></i>&nbsp;&nbsp;iPayPro Play
						</h6>
					</div>

					{displayFieldsMult.map((inputFieldMult) => (
						<div key={inputFieldMult.id}>
							<div className="smartphonebody">
								<div className="timercont">
									<div className="progresscont">
										<div class="progress progressdiv">
											<div
												class="progress-bar"
												role="progressbar"
												style={{ width: "50%", backgroundColor: "#3d90ab" }}
												aria-valuenow="50"
												aria-valuemin="0"
												aria-valuemax="100"
											></div>
										</div>
									</div>
								</div>
								{inputFieldMult.multImage?.imageresult ? (
									<div className="imagecard">
										<img src={inputFieldMult.multImage?.imageresult} alt="Image" className="imagecardimage" />
									</div>
								) : (
									<div className="imagecardsingle">
										<img className="imagecardimagesingle" src={questionimg} alt="Quiz" />
									</div>
								)}
								<div class="centerchoice">
									{/* <h5 style={{color:"grey"}}>{welcometext.length==0?<h6 style={{marginLeft:"30%"}}>Preview</h6>:<span className="previewtext">{welcometext}</span>}</h5> */}
									{/* {renderPreviewtext()} */}
									{renderMultiplequestion(inputFieldMult)}
									<Row>
										<div className="previewchoicediv">
											{inputFieldMult.choice1.length > 0 ? <h6>{inputFieldMult.choice1}</h6> : <h6>Choice 1</h6>}
										</div>
									</Row>
									<Row>
										<div className="previewchoicediv">
											{inputFieldMult.choice2.length > 0 ? <h6>{inputFieldMult.choice2}</h6> : <h6>Choice 2</h6>}
										</div>
									</Row>
									<Row>
										<div className="previewchoicediv">
											{inputFieldMult.choice3.length > 0 ? <h6>{inputFieldMult.choice3}</h6> : <h6>Choice 3</h6>}
										</div>
									</Row>
									<Row>
										<div className="previewchoicediv">
											{inputFieldMult.choice4.length > 0 ? <h6>{inputFieldMult.choice4}</h6> : <h6>Choice 4</h6>}
										</div>
									</Row>
								</div>
							</div>
						</div>
					))}
					<div className="smartphonefooter">
						<button className="previewcontinuebtn">Continue</button>
					</div>
				</div>
			);
		} else if (pictureChoiceBtn) {
			return (
				<div className="smartphone">
					<div className="smartphoneheader">
						<h6 className="headertitle">
							<i className="fa fa-angle-left fa-lg"></i>&nbsp;&nbsp;iPayPro Play
						</h6>
					</div>
					{displayFieldsPict.map((inputFieldPict) => (
						<div key={inputFieldPict.id}>
							<div className="smartphonebody">
								<div className="timercont">
									<div className="progresscont">
										<div class="progress progressdiv">
											<div
												class="progress-bar"
												role="progressbar"
												style={{ width: "50%", backgroundColor: "#3d90ab" }}
												aria-valuenow="50"
												aria-valuemin="0"
												aria-valuemax="100"
											></div>
										</div>
									</div>
								</div>
								{inputFieldPict.pictImage?.imageresult ? (
									<div className="imagecard">
										<img src={inputFieldPict.pictImage?.imageresult} alt="Image" className="imagecardimage" />
									</div>
								) : (
									<div className="imagecardsingle">
										<img className="imagecardimagesingle" src={questionimg} alt="Quiz" />
									</div>
								)}
								<div className="centerpicturechoice">
									{/* <h5 style={{color:"grey"}}>{welcometext.length==0?<h6 style={{marginLeft:"30%"}}>Preview</h6>:<span className="previewtext">{welcometext}</span>}</h5> */}
									{/* {renderPreviewtext()} */}
									{renderPicturequestion(inputFieldPict)}
									<table>
										<tr>
											<td>
												{inputFieldPict.pictureImage1?.imageresult ? (
													<img src={inputFieldPict.pictureImage1?.imageresult} alt="choice 1" className="imagechoice1" />
												) : (
													<img src={welcomehover} alt="choice 1" className="imagechoice1" />
												)}
												{inputFieldPict.choice1.length > 0 ? (
													<h6 className="choicecaption1" style={{ marginLeft: "-20px !important" }}>
														{inputFieldPict.choice1}
													</h6>
												) : (
													<h6 className="choicecaption1" style={{ marginLeft: "-23px !important" }}>
														Choice 1
													</h6>
												)}
											</td>
											<td>
												{inputFieldPict.pictureImage2?.imageresult ? (
													<img src={inputFieldPict.pictureImage2?.imageresult} alt="choice 2" className="imagechoice2" />
												) : (
													<img src={welcomehover} alt="choice 2" className="imagechoice2" />
												)}
												{inputFieldPict.choice2.length > 0 ? (
													<h6 className="choicecaption2">{inputFieldPict.choice2}</h6>
												) : (
													<h6 className="choicecaption2" style={{ marginLeft: "-23px !important" }}>
														Choice 2
													</h6>
												)}
											</td>
										</tr>
										<tr>
											<td>
												{inputFieldPict.pictureImage3?.imageresult ? (
													<img src={inputFieldPict.pictureImage3?.imageresult} alt="choice 3" className="imagechoice1" />
												) : (
													<img src={welcomehover} alt="choice 3" className="imagechoice1" />
												)}
												{inputFieldPict.choice3.length > 0 ? (
													<h6 className="choicecaption1" style={{ marginLeft: "-20px !important" }}>
														{inputFieldPict.choice3}
													</h6>
												) : (
													<h6 className="choicecaption1" style={{ marginLeft: "-23px !important" }}>
														Choice 3
													</h6>
												)}
											</td>
											<td>
												{inputFieldPict.pictureImage4?.imageresult ? (
													<img src={inputFieldPict.pictureImage4?.imageresult} alt="choice 4" className="imagechoice2" />
												) : (
													<img src={welcomehover} alt="choice 4" className="imagechoice2" />
												)}
												{inputFieldPict.choice4.length > 0 ? (
													<h6 className="choicecaption2">{inputFieldPict.choice4}</h6>
												) : (
													<h6 className="choicecaption2" style={{ marginLeft: "-23px !important" }}>
														Choice 4
													</h6>
												)}
											</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
					))}
					<div className="smartphonefooter">
						<button className="previewcontinuebtn">Continue</button>
					</div>
				</div>
			);
		} else if (welcomebtn) {
			return (
				<div className="smartphone">
					<div className="smartphoneheader">
						<h6 className="headertitle">
							<i className="fa fa-angle-left fa-lg"></i>&nbsp;&nbsp;iPayPro Play
						</h6>
					</div>
					<div className="smartphonebody">
						<div className="imagecard">
							{welcomeImage ? <img className="imagecardimage" src={welcomeImage?.imageresult} alt="welcome" /> : null}
						</div>
						<div class="center">
							{/* <h5 style={{color:"grey"}}>{welcometext.length==0?<h6 style={{marginLeft:"30%"}}>Preview</h6>:<span className="previewtext">{welcometext}</span>}</h5> */}
							{renderPreviewtext()}
						</div>
					</div>
					<div className="smartphonefooter">
						<button className="previewcontinuebtn">Continue</button>
					</div>
				</div>
			);
		} else {
			return (
				<div className="smartphone">
					<div className="smartphoneheader">
						<h6 className="headertitle">
							<i className="fa fa-angle-left fa-lg"></i>&nbsp;&nbsp;iPayPro Play
						</h6>
					</div>
					<div className="smartphonebody">
						<div class="center">{renderPreviewtext()}</div>
					</div>
					<div className="smartphonefooter">
						<button className="previewcontinuebtn">Continue</button>
					</div>
				</div>
			);
		}
	};

	const handleSubmited = (e) => {
		e.preventDefault();
		console.log("InputFields", inputFields);
	};

	const handleSubmitedMult = (e) => {
		e.preventDefault();
		console.log("InputFieldsMult", inputFieldsMult);
	};

	const handleChangeInput = (id, event) => {
		const newInputFields = inputFields.map((i) => {
			if (id === i.id) {
				i["display"] = false;
				i[event.target.name] = event.target.value;
			}
			return i;
		});
		setInputFields(newInputFields);
	};

	const handleChangeInputYesno = (id, event) => {
		const newInputFields = inputFieldsYesno.map((i) => {
			if (id === i.id) {
				i["display"] = false;
				i[event.target.name] = event.target.value;
			}
			return i;
		});
		setInputFieldsYesno(newInputFields);
	};

	const handleChangeInputMult = (id, event) => {
		const newInputFieldsMult = inputFieldsMult.map((i) => {
			if (id === i.id) {
				i["display"] = false;
				const value = event.target.type == "checkbox" ? event.target.checked : event.target.value;
				i[event.target.name] = value;
			}
			return i;
		});

		setInputFieldsMult(newInputFieldsMult);
	};

	const handleChangeInputPict = (id, event) => {
		const newInputFieldsPict = inputFieldsPict.map((i) => {
			if (id === i.id) {
				i["display"] = false;
				i[event.target.name] = event.target.value;
			}
			return i;
		});

		setInputFieldsPict(newInputFieldsPict);
	};

	const handleCloneInput = (id, event, index) => {
		event.preventDefault();
		let temp = inputFields[index];
		const cloned = Object.assign({}, temp);
		cloned.id = uuidv4();
		// inputFields.push({
		// 	id: uuidv4(),
		// 	type: 1,
		// 	quizQuestion: inputFields[index].quizQuestion,
		// 	choice1: inputFields[index].choice1,
		// 	choice2: inputFields[index].choice2,
		// 	choice3: inputFields[index].choice3,
		// 	choice4: inputFields[index].choice4,
		// 	radio0: inputFields[index].radio0,
		// 	radio: inputFields[index].radio,
		// });
		console.log(temp, "tempp");
		console.log(cloned, "cloned");
		setInputFields([...inputFields, cloned]);
		console.log(inputFields, "inputfieldsssss");
	};

	const handleCloneInputYesno = (id, event, index) => {
		event.preventDefault();
		const temp = inputFieldsYesno[index];
		const cloned = Object.assign({}, temp);
		cloned.id = uuidv4();
		console.log(temp, "tempp");
		console.log(cloned, "cloned");
		setInputFieldsYesno([...inputFieldsYesno, cloned]);
		// console.log(inputFieldsYesno, "inputfieldsssss");
	};

	const handleCloneInputMult = (id, event, index) => {
		event.preventDefault();
		const temp = inputFieldsMult[index];
		const cloned = Object.assign({}, temp);
		cloned.id = uuidv4();
		console.log(temp, "tempp");
		console.log(cloned, "cloned");
		setInputFieldsMult([...inputFieldsMult, cloned]);
		// console.log(inputFieldsMult, "inputfieldsssss");
	};

	const handleCloneInputPict = (id, event, index) => {
		event.preventDefault();
		const temp = inputFieldsPict[index];
		const cloned = Object.assign({}, temp);
		cloned.id = uuidv4();
		console.log(temp, "tempp");
		console.log(cloned, "cloned");
		setInputFieldsPict([...inputFieldsPict, cloned]);
		// console.log(inputFieldsPict, "inputfieldsssss");
	};

	const handleRemoveFields = (id) => {
		const values = [...inputFields];
		values.splice(
			values.findIndex((value) => value.id === id),
			1
		);
		setInputFields(values);
	};

	const handleRemoveFieldsYesno = (id) => {
		const values = [...inputFieldsYesno];
		values.splice(
			values.findIndex((value) => value.id === id),
			1
		);
		setInputFieldsYesno(values);
	};

	// mult ques remove func
	const handleRemoveFieldsMult = (id) => {
		const values = [...inputFieldsMult];
		values.splice(
			values.findIndex((value) => value.id === id),
			1
		);
		setInputFieldsMult(values);
	};

	// mult ques remove func
	const handleRemoveFieldsPict = (id) => {
		const values = [...inputFieldsPict];
		values.splice(
			values.findIndex((value) => value.id === id),
			1
		);
		setInputFieldsPict(values);
	};

	const handleClickDisplay = (id) => {
		console.log(id);
		let tempdata = [];
		const input = inputFields.map((input, index) => {
			if (input.id == id) {
				return tempdata.push(input);
			}
		});
		console.log(input);
		// setInputFields(inputFields);
		setDisplayFields(tempdata);
	};

	const handleClickDisplayYesno = (id) => {
		console.log(id);
		let tempdata = [];
		const input = inputFieldsYesno.map((input, index) => {
			if (input.id == id) {
				return tempdata.push(input);
			}
		});
		console.log(input);
		// setInputFields(inputFields);
		setDisplayFieldsYesno(tempdata);
	};

	// handle click of mult ques
	const handleClickDisplayMult = (id) => {
		console.log(id);
		let tempdata = [];
		const input = inputFieldsMult.map((input, index) => {
			if (input.id == id) {
				return tempdata.push(input);
			}
		});
		setDisplayFieldsMult(tempdata);
	};

	// handle click of Picture ques
	const handleClickDisplayPict = (id) => {
		console.log(id);
		let tempdata = [];
		const input = inputFieldsPict.map((input, index) => {
			if (input.id == id) {
				return tempdata.push(input);
			}
		});
		console.log(input);
		// setInputFields(inputFields);
		setDisplayFieldsPict(tempdata);
	};

	// const [url, setUrl] = useState(null);
	// Handle Images
	const readUrl = async (event, id) => {
		event.preventDefault();
		event.persist();
		let targetName = await event.target.name;
		let files = await event.target?.files;
		console.log(files, "files");
		var mimeType = files[0].type;
		if (files) {
			if (mimeType.includes("image")) {
				if (files[0].size <= 102400) {
					var reader = new FileReader();
					reader.readAsDataURL(files[0]);
					reader.onload = (_event) => {
						let imageData;
						imageData = reader.result;
						setCropsrc(reader.result);
						// setUrl(reader.result);
						imageData = imageData.split(",")[1];
						let image_data = {
							mime: mimeType,
							data: imageData,
							imageresult: reader.result,
						};
						// console.log(imageData, "imagedata");

						if (targetName == "welcomeImage") {
							setWelcomeImage(image_data);
							setImageCroper("1");
						}
						if (targetName == "singleImage") {
							const newInputFields = inputFields.map((i) => {
								if (targetName == "singleImage") {
									if (id == i.id) {
										i[event.target.name] = image_data;
									}
									return i;
								}
							});
							setInputFields(newInputFields);
							setImageCroper("2");
						}
						if (targetName == "yesnoImage") {
							const newInputFieldsYesno = inputFieldsYesno.map((i) => {
								if (targetName == "yesnoImage") {
									if (id == i.id) {
										i[event.target.name] = image_data;
									}
									return i;
								}
							});
							setInputFieldsYesno(newInputFieldsYesno);
							setImageCroper("3");
						}
						if (targetName == "multImage") {
							const newInputFieldsMult = inputFieldsMult.map((i) => {
								if (targetName == "multImage") {
									if (id == i.id) {
										i[event.target.name] = image_data;
									}
									return i;
								}
							});
							setInputFieldsMult(newInputFieldsMult);
							setImageCroper("4");
						}
						if (targetName == "pictImage") {
							const newInputFieldsPict = inputFieldsPict.map((i) => {
								if (targetName == "pictImage") {
									if (id == i.id) {
										i[event.target.name] = image_data;
									}
									return i;
								}
							});
							setInputFieldsPict(newInputFieldsPict);
							setImageCroper("5");
						}
						const newInputFieldsPict = inputFieldsPict.map((i) => {
							if (targetName == "pictureImage1") {
								if (id == i.id) {
									i[event.target.name] = image_data;
								}
								setImageCroper("6");
							}
							if (targetName == "pictureImage2") {
								if (id == i.id) {
									i[event.target.name] = image_data;
								}
								setImageCroper("6");
							}
							if (targetName == "pictureImage3") {
								if (id == i.id) {
									i[event.target.name] = image_data;
								}
								setImageCroper("6");
							}
							if (targetName == "pictureImage4") {
								if (id == i.id) {
									i[event.target.name] = image_data;
								}
								setImageCroper("6");
							}
							return i;
						});
						setInputFieldsPict(newInputFieldsPict);
					};
				} else {
					toast.error("Please insert image size less than 100Kb");
				}
			} else toast.error("Invalid file format");
		}
	};

	// console.log(welcomebtn, "welcomebtn");
	// console.log(welcometext, "welcomeTxt");
	const [imageCroper, setImageCroper] = useState("0");
	const [cropSrc, setCropsrc] = useState(null);
	// console.log(cropSrc, "cropdata");
	// console.log(displayFields, displayFieldsYesno, "displayFiels");
	// console.log(inputFields, "inputfieldddd");

	//main return
	return (
		<div className="card-body">
			<Row>
				<Col style={{ borderRight: "1px solid rgb(207 207 207 / 70%) " }}>
					<Row>
						<Col md={12} className="Column1">
							<FormGroup>
								{/*--------------------------------------------- WELCOME BUTTON DIVISION------------------------------------------------- */}
								{welcomebtn ? (
									<div className="card">
										<div className="card-body" onClick={welcomebtnclick}>
											<Row>
												<Col md={1}>
													<img src={welcome} alt="Welcome Screen" className="logomultiple" />
												</Col>
												<Col>
													<input
														type="text"
														placeholder="Say hi!"
														className="multipleinput"
														maxLength="1000"
														autoComplete="off"
														onChange={(event) => {
															setWelcometext(event.target.value);
														}}
													/>
												</Col>
												<Col md={2}>
													<h6 className="lengthcounter"> {welcometext.length}/100</h6>
												</Col>
											</Row>
											{!welcometext && publishClk ? (
												<Row>
													<Col md={1}></Col>
													<Col>
														<h6 style={{ color: "red", fontSize: "13px" }}>Required</h6>
													</Col>
												</Row>
											) : null}
										</div>
										<div className="card-footer">
											<div className="iconscont">
												<i className="fa fa-picture-o fa-md" onClick={pictureinsert}></i>
												&nbsp;&nbsp;
												<i
													style={{
														color: "red",
														fontSize: "15px",
														margin: "0 7px 0 0",
														cursor: "pointer",
													}}
													className="fa fa-trash-o"
													onClick={handleWelcomeDelete}
												></i>
												<Modal isOpen={openModal}>
													<ModalHeader>Image Uploader</ModalHeader>
													{imageCroper == "1" ? (
														<ModalBody>
															<ImageCroper
																src={cropSrc}
																setImageCroper={setImageCroper}
																setCropsrc={setCropsrc}
																setopenModal={setopenModal}
																setWelcomeImage={setWelcomeImage}
															/>
														</ModalBody>
													) : (
														<ModalBody>
															<div className="dropzone dropzone-primary">
																<div class="dzu-dropzone">
																	<label className="dzu-inputLabel">
																		Select an image
																		<input
																			className="dzu-input"
																			type="file"
																			name="welcomeImage"
																			accept="image/*"
																			onChange={(event) => readUrl(event)}
																		/>
																	</label>
																</div>
															</div>
														</ModalBody>
													)}
													{/* {imageCroper == "0" ? (
														<ModalFooter>
															<Button
																className="btn btn-danger"
																onClick={() => {
																	setopenModal(false);
																	setWelcomeImage(null);
																}}
															>
																Cancel
															</Button>
															<Button
																className="btn btn-primary"
																onClick={() => {
																	// setopenModal(false);
																	setImageCroper("1");
																}}
															>
																Proceed
															</Button>
														</ModalFooter>
													) : null} */}
												</Modal>
											</div>
										</div>
									</div>
								) : null}

								{/*--------------------------------------------- SINGLE CHOICE BUTTON DIVISION------------------------------------------------- */}
								{singlechoicebtndrop ? (
									<form onSubmit={handleSubmited}>
										{inputFields.map((inputField, index) => (
											<div key={inputField.id}>
												<div className="card">
													<div
														className="card-body"
														onClick={(e) => {
															singlebtnclick(e);
															handleClickDisplay(inputField.id);
														}}
													>
														<Row>
															<Col md={1}>
																<img src={single} alt="single choice" className="logomultiple" />
															</Col>
															<Col>
																<input
																	type="text"
																	name="quizQuestion"
																	placeholder="Your question here."
																	className="multipleinput"
																	value={inputField.quizQuestion}
																	onChange={(event) => handleChangeInput(inputField.id, event)}
																	maxLength="1000"
																/>
															</Col>
															<Col md={2} style={{ paddingRight: "0px" }}>
																<span style={{ marginLeft: "10%", fontSize: "12px" }}>Answer</span>
															</Col>
														</Row>
														{!inputField.quizQuestion && publishClk ? (
															<Row>
																<Col md={1}></Col>
																<Col>
																	<h6 style={{ color: "red", fontSize: "13px" }}>Required</h6>
																</Col>
															</Row>
														) : null}
														<Row>
															<Col md={1}>
																<h6 className="hyphencss"> -</h6>
															</Col>
															<Col>
																<input
																	type="text"
																	placeholder="Choice 1"
																	className="multipleinput"
																	maxLength="200"
																	name="choice1"
																	value={inputField.choice1}
																	onChange={(event) => handleChangeInput(inputField.id, event)}
																/>
															</Col>
															<Col md={1}>
																<div class="radio">
																	<input
																		id="radio1"
																		className="radiobtn"
																		type="radio"
																		name={"radio" + index}
																		value="choice1"
																		defaultChecked={inputField.radio == "choice1" ? true : false}
																		onChange={(event) => {
																			handleChangeInput(inputField.id, event);
																			inputField.radio = event.target.value;
																		}}
																	/>
																</div>
															</Col>
														</Row>
														{!inputField.choice1 && publishClk ? (
															<Row>
																<Col md={1}></Col>
																<Col>
																	<h6 style={{ color: "red", fontSize: "13px" }}>Required</h6>
																</Col>
															</Row>
														) : null}
														<Row>
															<Col md={1}>
																<h6 className="hyphencss"> -</h6>
															</Col>
															<Col>
																<input
																	type="text"
																	placeholder="Choice 2"
																	className="multipleinput"
																	maxLength="200"
																	name="choice2"
																	value={inputField.choice2}
																	onChange={(event) => handleChangeInput(inputField.id, event)}
																/>
															</Col>
															<Col md={1}>
																<div class="radio">
																	<input
																		id="radio1"
																		className="radiobtn"
																		type="radio"
																		name={"radio" + index}
																		value="choice2"
																		defaultChecked={inputField.radio == "choice2" ? true : false}
																		onChange={(event) => {
																			handleChangeInput(inputField.id, event);
																			inputField.radio = event.target.value;
																		}}
																	/>
																</div>
															</Col>
														</Row>
														{!inputField.choice2 && publishClk ? (
															<Row>
																<Col md={1}></Col>
																<Col>
																	<h6 style={{ color: "red", fontSize: "13px" }}>Required</h6>
																</Col>
															</Row>
														) : null}
														<Row>
															<Col md={1}>
																<h6 className="hyphencss"> -</h6>
															</Col>
															<Col>
																<input
																	type="text"
																	placeholder="Choice 3"
																	className="multipleinput"
																	maxLength="200"
																	name="choice3"
																	value={inputField.choice3}
																	onChange={(event) => handleChangeInput(inputField.id, event)}
																/>
															</Col>
															<Col md={1}>
																<div class="radio">
																	<input
																		id="radio1"
																		className="radiobtn"
																		type="radio"
																		name={"radio" + index}
																		value="choice3"
																		defaultChecked={inputField.radio == "choice3" ? true : false}
																		onChange={(event) => {
																			handleChangeInput(inputField.id, event);
																			inputField.radio = event.target.value;
																		}}
																	/>
																</div>
															</Col>
														</Row>
														{!inputField.choice3 && publishClk ? (
															<Row>
																<Col md={1}></Col>
																<Col>
																	<h6 style={{ color: "red", fontSize: "13px" }}>Required</h6>
																</Col>
															</Row>
														) : null}
														<Row>
															<Col md={1}>
																<h6 className="hyphencss"> -</h6>
															</Col>
															<Col>
																<input
																	type="text"
																	placeholder="Choice 4"
																	className="multipleinput"
																	maxLength="200"
																	name="choice4"
																	value={inputField.choice4}
																	onChange={(event) => handleChangeInput(inputField.id, event)}
																/>
															</Col>
															<Col md={1}>
																<div class="radio">
																	<input
																		id="radio1"
																		className="radiobtn"
																		type="radio"
																		name={"radio" + index}
																		value="choice4"
																		defaultChecked={inputField.radio == "choice4" ? true : false}
																		onChange={(event) => {
																			handleChangeInput(inputField.id, event);
																			inputField.radio = event.target.value;
																		}}
																	/>
																</div>
															</Col>
														</Row>
														{!inputField.choice4 && publishClk ? (
															<Row>
																<Col md={1}></Col>
																<Col>
																	<h6 style={{ color: "red", fontSize: "13px" }}>Required</h6>
																</Col>
															</Row>
														) : null}
													</div>
													<div className="card-footer">
														<Row style={{ marginLeft: "0px" }} md={12}>
															<Col md={3}>
																<Row>
																	<input
																		type="number"
																		placeholder="Enter Credits"
																		className="multipleinput"
																		min="0"
																		name="points"
																		value={inputField.points}
																		onChange={(event) => handleChangeInput(inputField.id, event)}
																	/>
																</Row>
															</Col>
															<Col md={3}>
																<Row>
																	<input
																		type="number"
																		placeholder="Que time (in sec)"
																		className="multipleinput"
																		min="0"
																		name="seconds"
																		value={inputField.seconds}
																		onChange={(event) => handleChangeInput(inputField.id, event)}
																	/>
																</Row>
															</Col>
															<Col md={6}>
																<Row>
																	<Col style={{ padding: "0px 3%" }}>
																		{/* <i className="icofont icofont-copy-alt"></i> */}
																		<button
																			className="clonebtn"
																			onClick={(event) => handleCloneInput(inputField.id, event, index)}
																			style={{ margin: "0 0px 0 40%" }}
																		>
																			<Copy size={20} stroke-width="1.5" />
																		</button>
																	</Col>
																	<Col style={{ padding: "0px 3%" }}>
																		<i
																			className="fa fa-picture-o fa-md"
																			style={{ margin: "0 0px 0 40%" }}
																			onClick={() => {
																				setInputFieldId(inputField.id);
																				pictureinsertSingle();
																			}}
																		></i>
																	</Col>
																	<Col style={{ padding: "0px 3%" }}>
																		<i
																			style={{
																				color: "red",
																				fontSize: "16px",
																				margin: "0 0 0 40%",
																				cursor: "pointer",
																			}}
																			className="fa fa-trash-o"
																			onClick={() => handleRemoveFields(inputField.id)}
																		></i>
																	</Col>
																</Row>
															</Col>
														</Row>
													</div>
												</div>
											</div>
										))}
										<Modal isOpen={openModalSingle}>
											<ModalHeader>Image Uploader</ModalHeader>
											{imageCroper == "2" ? (
												<ModalBody>
													<ImageCroperDynamic
														src={cropSrc}
														inputfieldId={inputFieldId}
														ImageCroper={imageCroper}
														setImageCroper={setImageCroper}
														inputFields={inputFields}
														setInputFields={setInputFields}
														setopenModalSingle={setopenModalSingle}
														setInputFieldId={setInputFieldId}
													/>
												</ModalBody>
											) : (
												<ModalBody>
													<div className="dropzone dropzone-primary">
														<div class="dzu-dropzone">
															<label className="dzu-inputLabel">
																Select an image
																<input
																	className="dzu-input"
																	type="file"
																	name="singleImage"
																	accept="image/*"
																	onChange={(event) => readUrl(event, inputFieldId)}
																/>
															</label>
														</div>
													</div>
												</ModalBody>
											)}
										</Modal>
									</form>
								) : null}
								{/*--------------------------------------------- YES/NO BUTTON DIVISION------------------------------------------------- */}
								{yesnochoicebtndrop ? (
									<form onSubmit={handleSubmited}>
										{inputFieldsYesno.map((inputFieldYesno, index) => (
											<div key={inputFieldYesno.id}>
												<div className="card">
													<div
														className="card-body"
														onClick={(e) => {
															yesnobtnclick(e);
															handleClickDisplayYesno(inputFieldYesno.id);
														}}
													>
														<Row>
															<Col md={1}>
																<img src={yesimg} alt="yesno choice" className="logomultiple" />
															</Col>
															<Col>
																<input
																	type="text"
																	name="quizQuestion"
																	placeholder="Your question here."
																	className="multipleinput"
																	onChange={(event) => handleChangeInputYesno(inputFieldYesno.id, event)}
																	maxLength="1000"
																	value={inputFieldYesno.quizQuestion}
																/>
															</Col>
															<Col md={2} style={{ paddingRight: "0px" }}>
																<span style={{ marginLeft: "10%", fontSize: "12px" }}>Answer</span>
															</Col>
														</Row>
														{!inputFieldYesno.quizQuestion && publishClk ? (
															<Row>
																<Col md={1}></Col>
																<Col>
																	<h6 style={{ color: "red", fontSize: "13px" }}>Required</h6>
																</Col>
															</Row>
														) : null}
														<Row>
															<Col md={1}>
																<h6 className="hyphencss"> -</h6>
															</Col>
															<Col>
																<input
																	type="text"
																	placeholder="Choice 1"
																	className="multipleinput"
																	maxLength="200"
																	name="choice1"
																	value={inputFieldYesno.choice1}
																	onChange={(event) => handleChangeInputYesno(inputFieldYesno.id, event)}
																/>
															</Col>
															<Col md={1}>
																<div class="radio">
																	<input
																		id="radio1"
																		className="radiobtn"
																		type="radio"
																		name={"radio" + index}
																		value="choice1"
																		defaultChecked={inputFieldYesno.radio == "choice1" ? true : false}
																		onChange={(event) => {
																			handleChangeInputYesno(inputFieldYesno.id, event);
																			inputFieldYesno.radio = event.target.value;
																		}}
																	/>
																</div>
															</Col>
														</Row>
														{!inputFieldYesno.choice1 && publishClk ? (
															<Row>
																<Col md={1}></Col>
																<Col>
																	<h6 style={{ color: "red", fontSize: "13px" }}>Required</h6>
																</Col>
															</Row>
														) : null}
														<Row>
															<Col md={1}>
																<h6 className="hyphencss"> -</h6>
															</Col>
															<Col>
																<input
																	type="text"
																	placeholder="Choice 2"
																	className="multipleinput"
																	maxLength="200"
																	name="choice2"
																	value={inputFieldYesno.choice2}
																	onChange={(event) => handleChangeInputYesno(inputFieldYesno.id, event)}
																/>
															</Col>
															<Col md={1}>
																<div class="radio">
																	<input
																		id="radio1"
																		className="radiobtn"
																		type="radio"
																		name={"radio" + index}
																		value="choice2"
																		defaultChecked={inputFieldYesno.radio == "choice2" ? true : false}
																		onChange={(event) => {
																			handleChangeInputYesno(inputFieldYesno.id, event);
																			inputFieldYesno.radio = event.target.value;
																		}}
																	/>
																</div>
															</Col>
														</Row>
														{!inputFieldYesno.choice2 && publishClk ? (
															<Row>
																<Col md={1}></Col>
																<Col>
																	<h6 style={{ color: "red", fontSize: "13px" }}>Required</h6>
																</Col>
															</Row>
														) : null}
													</div>
													<div className="card-footer">
														<Row style={{ marginLeft: "0px" }} md={12}>
															<Col md={3}>
																<Row>
																	<input
																		type="number"
																		placeholder="Enter Credits"
																		className="multipleinput"
																		min="0"
																		name="points"
																		value={inputFieldYesno.points}
																		onChange={(event) => handleChangeInputYesno(inputFieldYesno.id, event)}
																	/>
																</Row>
															</Col>
															<Col md={3}>
																<Row>
																	<input
																		type="number"
																		placeholder="Que time (in sec)"
																		className="multipleinput"
																		min="0"
																		name="seconds"
																		value={inputFieldYesno.seconds}
																		onChange={(event) => handleChangeInputYesno(inputFieldYesno.id, event)}
																	/>
																</Row>
															</Col>
															<Col md={6}>
																<Row>
																	<Col style={{ padding: "0px 3%" }}>
																		<button
																			className="clonebtn"
																			style={{ margin: "0 0px 0 40%" }}
																			onClick={(event) => handleCloneInputYesno(inputFieldYesno.id, event, index)}
																		>
																			<Copy size={20} stroke-width="1.5" />
																		</button>
																		{/* <i className="icofont icofont-copy-alt"></i> */}
																	</Col>
																	<Col style={{ padding: "0px 3%" }}>
																		<i
																			className="fa fa-picture-o fa-md"
																			style={{ margin: "0 0px 0 40%" }}
																			onClick={() => {
																				setInputFieldId(inputFieldYesno.id);
																				pictureinsertYesno();
																			}}
																		></i>
																	</Col>
																	<Col style={{ padding: "0px 3%" }}>
																		<i
																			style={{
																				color: "red",
																				fontSize: "16px",
																				margin: "0 0 0 40%",
																				cursor: "pointer",
																			}}
																			className="fa fa-trash-o"
																			onClick={() => handleRemoveFieldsYesno(inputFieldYesno.id)}
																		></i>
																	</Col>
																</Row>
															</Col>
														</Row>
													</div>
												</div>
											</div>
										))}
										<Modal isOpen={openModalYesno}>
											<ModalHeader>Image Uploader</ModalHeader>
											{imageCroper == "3" ? (
												<ModalBody>
													<ImageCroperDynamic
														src={cropSrc}
														inputfieldIdYesno={inputFieldId}
														ImageCroper={imageCroper}
														setImageCroper={setImageCroper}
														inputFieldsYesno={inputFieldsYesno}
														setInputFieldsYesno={setInputFieldsYesno}
														setopenModalYesno={setopenModalYesno}
														setInputFieldId={setInputFieldId}
													/>
												</ModalBody>
											) : (
												<ModalBody>
													<div className="dropzone dropzone-primary">
														<div class="dzu-dropzone">
															<label className="dzu-inputLabel">
																Select an image
																<input
																	className="dzu-input"
																	type="file"
																	name="yesnoImage"
																	accept="image/*"
																	onChange={(event) => readUrl(event, inputFieldId)}
																/>
															</label>
														</div>
													</div>
												</ModalBody>
											)}
										</Modal>
									</form>
								) : null}

								{/*---------------------------------- Multiple choice ques button-----------------------------------------  */}
								{multiplechoicebtndrop ? (
									<form onSubmit={handleSubmitedMult}>
										{inputFieldsMult.map((inputFieldMult, index) => (
											<div key={inputFieldMult.id}>
												<div className="card">
													<div
														className="card-body"
														onClick={(e) => {
															multiplebtnclick(e);
															handleClickDisplayMult(inputFieldMult.id);
														}}
													>
														<Row>
															<Col md={1}>
																<img src={multiplechoice} alt="multiple choice" className="logomultiple" />
															</Col>
															<Col>
																<input
																	type="text"
																	name="quizQuestion"
																	placeholder="Your question here."
																	className="multipleinput"
																	value={inputFieldMult.quizQuestion}
																	onChange={(event) => handleChangeInputMult(inputFieldMult.id, event)}
																	maxLength="100"
																/>
															</Col>
															<Col md={2} style={{ paddingRight: "0px" }}>
																<span style={{ marginLeft: "10%", fontSize: "12px" }}>Answer</span>
															</Col>
														</Row>
														{!inputFieldMult.quizQuestion && publishClk ? (
															<Row>
																<Col md={1}></Col>
																<Col>
																	<h6 style={{ color: "red", fontSize: "13px" }}>Required</h6>
																</Col>
															</Row>
														) : null}
														<Row>
															<Col md={1}>
																<h6 className="hyphencss"> -</h6>
															</Col>
															<Col>
																<input
																	type="text"
																	placeholder="Choice 1"
																	value={inputFieldMult.choice1}
																	className="multipleinput"
																	maxLength="20"
																	name="choice1"
																	onChange={(event) => handleChangeInputMult(inputFieldMult.id, event)}
																/>
															</Col>
															<Col md={1}>
																<div class="radio">
																	<input
																		id="checkbox1"
																		className="radiobtn"
																		type="checkbox"
																		name="checkbox1"
																		defaultChecked={inputFieldMult.checkbox1}
																		onChange={(event) => handleChangeInputMult(inputFieldMult.id, event)}
																	/>
																</div>
															</Col>
														</Row>
														{!inputFieldMult.choice1 && publishClk ? (
															<Row>
																<Col md={1}></Col>
																<Col>
																	<h6 style={{ color: "red", fontSize: "13px" }}>Required</h6>
																</Col>
															</Row>
														) : null}
														<Row>
															<Col md={1}>
																<h6 className="hyphencss"> -</h6>
															</Col>
															<Col>
																<input
																	type="text"
																	placeholder="Choice 2"
																	className="multipleinput"
																	maxLength="20"
																	name="choice2"
																	value={inputFieldMult.choice2}
																	onChange={(event) => handleChangeInputMult(inputFieldMult.id, event)}
																/>
															</Col>
															<Col md={1}>
																<div class="radio">
																	<input
																		id="radio1"
																		className="radiobtn"
																		type="checkbox"
																		name="checkbox2"
																		defaultChecked={inputFieldMult.checkbox2}
																		onChange={(event) => handleChangeInputMult(inputFieldMult.id, event)}
																	/>
																</div>
															</Col>
														</Row>
														{!inputFieldMult.choice2 && publishClk ? (
															<Row>
																<Col md={1}></Col>
																<Col>
																	<h6 style={{ color: "red", fontSize: "13px" }}>Required</h6>
																</Col>
															</Row>
														) : null}
														<Row>
															<Col md={1}>
																<h6 className="hyphencss"> -</h6>
															</Col>
															<Col>
																<input
																	type="text"
																	placeholder="Choice 3"
																	className="multipleinput"
																	maxLength="20"
																	name="choice3"
																	value={inputFieldMult.choice3}
																	onChange={(event) => handleChangeInputMult(inputFieldMult.id, event)}
																/>
															</Col>
															<Col md={1}>
																<div class="radio">
																	<input
																		id="radio1"
																		className="radiobtn"
																		type="checkbox"
																		name="checkbox3"
																		defaultChecked={inputFieldMult.checkbox3}
																		onChange={(event) => handleChangeInputMult(inputFieldMult.id, event)}
																	/>
																</div>
															</Col>
														</Row>
														{!inputFieldMult.choice3 && publishClk ? (
															<Row>
																<Col md={1}></Col>
																<Col>
																	<h6 style={{ color: "red", fontSize: "13px" }}>Required</h6>
																</Col>
															</Row>
														) : null}
														<Row>
															<Col md={1}>
																<h6 className="hyphencss"> -</h6>
															</Col>
															<Col>
																<input
																	type="text"
																	placeholder="Choice 4"
																	className="multipleinput"
																	maxLength="20"
																	name="choice4"
																	value={inputFieldMult.choice4}
																	onChange={(event) => handleChangeInputMult(inputFieldMult.id, event)}
																/>
															</Col>
															<Col md={1}>
																<div class="radio">
																	<input
																		id="radio1"
																		className="radiobtn"
																		type="checkbox"
																		name="checkbox4"
																		onChange={(event) => handleChangeInputMult(inputFieldMult.id, event)}
																		defaultChecked={inputFieldMult.checkbox4}
																	/>
																</div>
															</Col>
														</Row>
														{!inputFieldMult.choice4 && publishClk ? (
															<Row>
																<Col md={1}></Col>
																<Col>
																	<h6 style={{ color: "red", fontSize: "13px" }}>Required</h6>
																</Col>
															</Row>
														) : null}
													</div>
													<div className="card-footer">
														<Row style={{ marginLeft: "0px" }} md={12}>
															<Col md={3}>
																<Row>
																	<input
																		type="number"
																		placeholder="Enter Credits"
																		className="multipleinput"
																		min="0"
																		name="points"
																		value={inputFieldMult.points}
																		onChange={(event) => handleChangeInputMult(inputFieldMult.id, event)}
																	/>
																</Row>
															</Col>
															<Col md={3}>
																<Row>
																	<input
																		type="number"
																		placeholder="Que time (in sec)"
																		className="multipleinput"
																		min="0"
																		name="seconds"
																		value={inputFieldMult.seconds}
																		onChange={(event) => handleChangeInputMult(inputFieldMult.id, event)}
																	/>
																</Row>
															</Col>
															<Col md={6}>
																<Row>
																	<Col>
																		<button
																			className="clonebtn"
																			style={{ margin: "0 0px 0 40%" }}
																			onClick={(event) => handleCloneInputMult(inputFieldMult.id, event, index)}
																		>
																			{/* <i className="icofont icofont-copy-alt"></i> */}
																			<Copy size={20} stroke-width="1.5" />
																		</button>
																	</Col>
																	<Col>
																		<i
																			className="fa fa-picture-o fa-md"
																			style={{ margin: "0 0px 0 40%" }}
																			onClick={() => {
																				setInputFieldId(inputFieldMult.id);
																				pictureinsertMultiple();
																			}}
																		></i>
																	</Col>
																	<Col>
																		<i
																			style={{
																				color: "red",
																				fontSize: "16px",
																				margin: "0 0 0 40%",
																				cursor: "pointer",
																			}}
																			className="fa fa-trash-o"
																			onClick={() => handleRemoveFieldsMult(inputFieldMult.id)}
																		></i>
																	</Col>
																</Row>
															</Col>
														</Row>
													</div>
												</div>
											</div>
										))}
										<Modal isOpen={openModalMultiple}>
											<ModalHeader>Image Uploader</ModalHeader>
											{imageCroper == "4" ? (
												<ModalBody>
													<ImageCroperDynamic
														src={cropSrc}
														inputfieldIdMult={inputFieldId}
														ImageCroper={imageCroper}
														setImageCroper={setImageCroper}
														inputFieldsMult={inputFieldsMult}
														setInputFieldsMult={setInputFieldsMult}
														setopenModalMultiple={setopenModalMultiple}
														setInputFieldId={setInputFieldId}
													/>
												</ModalBody>
											) : (
												<ModalBody>
													<div className="dropzone dropzone-primary">
														<div class="dzu-dropzone">
															<label className="dzu-inputLabel">
																Select an image
																<input
																	className="dzu-input"
																	type="file"
																	name="multImage"
																	accept="image/*"
																	onChange={(event) => readUrl(event, inputFieldId)}
																/>
															</label>
														</div>
													</div>
												</ModalBody>
											)}
											{/* <ModalFooter>
																	<Button
																		className="btn btn-danger"
																		onClick={() => {
																			setopenModalMultiple(false);
																			// setWelcomeImage(null);
																		}}
																	>
																		Cancel
																	</Button>
																	<Button
																		className="btn btn-primary"
																		onClick={() => {
																			setopenModalMultiple(false);
																			// setImageCroper("1");
																		}}
																	>
																		Proceed
																	</Button>
																</ModalFooter> */}
										</Modal>
									</form>
								) : null}

								{/*--------------------------------------------- PICTURE CHOICE BUTTON DIVISION------------------------------------------------- */}

								{picturechoicebtndrop ? (
									<form onSubmit={handleSubmited}>
										{inputFieldsPict.map((inputFieldPict, index) => (
											<div key={inputFieldPict.id}>
												<div className="card">
													<div
														className="card-body"
														onClick={(e) => {
															picturebtnclick(e);
															handleClickDisplayPict(inputFieldPict.id);
														}}
													>
														<Row>
															<Col md={1}>
																<img src={picturescale} alt="picture choice" className="logomultiple" />
															</Col>
															<Col style={{ marginBottom: "16px" }}>
																<input
																	type="text"
																	placeholder="Your question here."
																	className="multipleinput"
																	name="quizQuestion"
																	value={inputFieldPict.quizQuestion}
																	onChange={(event) => handleChangeInputPict(inputFieldPict.id, event)}
																	maxLength="100"
																/>
															</Col>
															<Col md={2} style={{ paddingRight: "0px" }}>
																<span style={{ marginLeft: "10%", fontSize: "12px" }}>Answer</span>
															</Col>
														</Row>
														{!inputFieldPict.quizQuestion && publishClk ? (
															<Row>
																<Col md={1}></Col>
																<Col>
																	<h6 style={{ color: "red", fontSize: "13px" }}>Required</h6>
																</Col>
															</Row>
														) : null}
														<Row>
															<Col md={1}>
																<h6 className="hyphencss"> -</h6>
															</Col>
															<Col md={1.1}>
																<div className="imageinputcont">
																	<img src={picturescale} alt="picture choice" className="logomultipleinput" />
																	<i
																		className="icofont icofont-plus"
																		style={{
																			color: "rgb(176 12 176)",
																			marginTop: "4px",
																			marginLeft: "3px",
																			cursor: "pointer",
																		}}
																		onClick={pictureinsertPicture1}
																	>
																		{/* <input
																			className="addimage"
																			type="file"
																			accept="image/*"
																			name="pictureImage1"
																			onChange={(event) => readUrl(event, inputFieldPict.id)}
																		/> */}
																	</i>
																	<Modal isOpen={openModalPicture1}>
																		<ModalHeader>Image Uploader</ModalHeader>
																		{imageCroper == "6" ? (
																			<ModalBody>
																				<ImageCroperDynamic
																					src={cropSrc}
																					inputfieldIdPict={inputFieldPict.id}
																					ImageCroper={imageCroper}
																					setImageCroper={setImageCroper}
																					inputFieldsPict={inputFieldsPict}
																					setInputFieldsPict={setInputFieldsPict}
																					setopenModalPicture={setopenModalPicture1}
																					targetName="pictureImage1"
																				/>
																			</ModalBody>
																		) : (
																			<ModalBody>
																				<div className="dropzone dropzone-primary">
																					<div class="dzu-dropzone">
																						<label className="dzu-inputLabel">
																							Select an image
																							<input
																								className="dzu-input"
																								type="file"
																								name="pictureImage1"
																								accept="image/*"
																								onChange={(event) => readUrl(event, inputFieldPict.id)}
																							/>
																						</label>
																					</div>
																				</div>
																			</ModalBody>
																		)}
																	</Modal>
																</div>
															</Col>
															<Col>
																<input
																	type="text"
																	placeholder="Choice label"
																	className="multipleinput"
																	maxLength="14"
																	name="choice1"
																	value={inputFieldPict.choice1}
																	onChange={(event) => handleChangeInputPict(inputFieldPict.id, event)}
																/>
															</Col>
															<Col md={1}>
																<div class="radio">
																	<input
																		id="radio1"
																		className="radiobtn"
																		type="radio"
																		name={"radio" + index}
																		defaultChecked={inputFieldPict.radio == "choice1" ? true : false}
																		value="choice1"
																		onChange={(event) => {
																			handleChangeInputPict(inputFieldPict.id, event);
																			inputFieldPict.radio = event.target.value;
																		}}
																	/>
																</div>
															</Col>
														</Row>
														{!inputFieldPict.choice1 && publishClk ? (
															<Row>
																<Col md={1}></Col>
																<Col md={1.1}></Col>
																<Col>
																	<h6
																		style={{
																			color: "red",
																			fontSize: "13px",
																			marginLeft: "12%",
																		}}
																	>
																		Required
																	</h6>
																</Col>
															</Row>
														) : null}
														<Row>
															<Col md={1}>
																<h6 className="hyphencss"> -</h6>
															</Col>
															<Col md={1.1}>
																<div className="imageinputcont">
																	<img src={picturescale} alt="picture choice" className="logomultipleinput" />
																	<i
																		className="icofont icofont-plus"
																		style={{
																			color: "rgb(176 12 176)",
																			marginTop: "4px",
																			marginLeft: "3px",
																			cursor: "pointer",
																		}}
																		onClick={pictureinsertPicture2}
																	>
																		{/* <input
																			className="addimage"
																			type="file"
																			accept="image/*"
																			name="pictureImage2"
																			onChange={(event) => readUrl(event, inputFieldPict.id)}
																		/> */}
																	</i>
																	<Modal isOpen={openModalPicture2}>
																		<ModalHeader>Image Uploader</ModalHeader>
																		{imageCroper == "6" ? (
																			<ModalBody>
																				<ImageCroperDynamic
																					src={cropSrc}
																					inputfieldIdPict={inputFieldPict.id}
																					ImageCroper={imageCroper}
																					setImageCroper={setImageCroper}
																					inputFieldsPict={inputFieldsPict}
																					setInputFieldsPict={setInputFieldsPict}
																					setopenModalPicture={setopenModalPicture2}
																					targetName="pictureImage2"
																				/>
																			</ModalBody>
																		) : (
																			<ModalBody>
																				<div className="dropzone dropzone-primary">
																					<div class="dzu-dropzone">
																						<label className="dzu-inputLabel">
																							Select an image
																							<input
																								className="dzu-input"
																								type="file"
																								name="pictureImage2"
																								accept="image/*"
																								onChange={(event) => readUrl(event, inputFieldPict.id)}
																							/>
																						</label>
																					</div>
																				</div>
																			</ModalBody>
																		)}
																	</Modal>
																</div>
															</Col>
															<Col>
																<input
																	type="text"
																	placeholder="Choice label"
																	className="multipleinput"
																	maxLength="14"
																	name="choice2"
																	value={inputFieldPict.choice2}
																	onChange={(event) => handleChangeInputPict(inputFieldPict.id, event)}
																/>
															</Col>
															<Col md={1}>
																<div class="radio">
																	<input
																		id="radio1"
																		className="radiobtn"
																		type="radio"
																		name={"radio" + index}
																		value="choice2"
																		defaultChecked={inputFieldPict.radio == "choice2" ? true : false}
																		onChange={(event) => {
																			handleChangeInputPict(inputFieldPict.id, event);
																			inputFieldPict.radio = event.target.value;
																		}}
																	/>
																</div>
															</Col>
														</Row>
														{!inputFieldPict.choice2 && publishClk ? (
															<Row>
																<Col md={1}></Col>
																<Col md={1.1}></Col>
																<Col>
																	<h6
																		style={{
																			color: "red",
																			fontSize: "13px",
																			marginLeft: "12%",
																		}}
																	>
																		Required
																	</h6>
																</Col>
															</Row>
														) : null}
														<Row>
															<Col md={1}>
																<h6 className="hyphencss"> -</h6>
															</Col>
															<Col md={1.1}>
																<div className="imageinputcont">
																	<img src={picturescale} alt="picture choice" className="logomultipleinput" />
																	<i
																		className="icofont icofont-plus"
																		style={{
																			color: "rgb(176 12 176)",
																			marginTop: "4px",
																			marginLeft: "3px",
																			cursor: "pointer",
																		}}
																		onClick={pictureinsertPicture3}
																	></i>
																	<Modal isOpen={openModalPicture3}>
																		<ModalHeader>Image Uploader</ModalHeader>
																		{imageCroper == "6" ? (
																			<ModalBody>
																				<ImageCroperDynamic
																					src={cropSrc}
																					inputfieldIdPict={inputFieldPict.id}
																					ImageCroper={imageCroper}
																					setImageCroper={setImageCroper}
																					inputFieldsPict={inputFieldsPict}
																					setInputFieldsPict={setInputFieldsPict}
																					setopenModalPicture={setopenModalPicture3}
																					targetName="pictureImage3"
																				/>
																			</ModalBody>
																		) : (
																			<ModalBody>
																				<div className="dropzone dropzone-primary">
																					<div class="dzu-dropzone">
																						<label className="dzu-inputLabel">
																							Select an image
																							<input
																								className="dzu-input"
																								type="file"
																								name="pictureImage3"
																								accept="image/*"
																								onChange={(event) => readUrl(event, inputFieldPict.id)}
																							/>
																						</label>
																					</div>
																				</div>
																			</ModalBody>
																		)}
																	</Modal>
																</div>
															</Col>
															<Col>
																<input
																	type="text"
																	placeholder="Choice label"
																	className="multipleinput"
																	maxLength="14"
																	name="choice3"
																	value={inputFieldPict.choice3}
																	onChange={(event) => handleChangeInputPict(inputFieldPict.id, event)}
																/>
															</Col>
															<Col md={1}>
																<div class="radio">
																	<input
																		id="radio1"
																		className="radiobtn"
																		type="radio"
																		name={"radio" + index}
																		value="choice3"
																		defaultChecked={inputFieldPict.radio == "choice3" ? true : false}
																		onChange={(event) => {
																			handleChangeInputPict(inputFieldPict.id, event);
																			inputFieldPict.radio = event.target.value;
																		}}
																	/>
																</div>
															</Col>
														</Row>
														{!inputFieldPict.choice3 && publishClk ? (
															<Row>
																<Col md={1}></Col>
																<Col md={1.1}></Col>
																<Col>
																	<h6
																		style={{
																			color: "red",
																			fontSize: "13px",
																			marginLeft: "12%",
																		}}
																	>
																		Required
																	</h6>
																</Col>
															</Row>
														) : null}
														<Row>
															<Col md={1}>
																<h6 className="hyphencss"> -</h6>
															</Col>
															<Col md={1.1}>
																<div className="imageinputcont">
																	<img src={picturescale} alt="picture choice" className="logomultipleinput" />
																	<i
																		className="icofont icofont-plus"
																		style={{
																			color: "rgb(176 12 176)",
																			marginTop: "4px",
																			marginLeft: "3px",
																			cursor: "pointer",
																		}}
																		onClick={pictureinsertPicture4}
																	></i>
																	<Modal isOpen={openModalPicture4}>
																		<ModalHeader>Image Uploader</ModalHeader>
																		{imageCroper == "6" ? (
																			<ModalBody>
																				<ImageCroperDynamic
																					src={cropSrc}
																					inputfieldIdPict={inputFieldPict.id}
																					ImageCroper={imageCroper}
																					setImageCroper={setImageCroper}
																					inputFieldsPict={inputFieldsPict}
																					setInputFieldsPict={setInputFieldsPict}
																					setopenModalPicture={setopenModalPicture4}
																					targetName="pictureImage4"
																				/>
																			</ModalBody>
																		) : (
																			<ModalBody>
																				<div className="dropzone dropzone-primary">
																					<div class="dzu-dropzone">
																						<label className="dzu-inputLabel">
																							Select an image
																							<input
																								className="dzu-input"
																								type="file"
																								name="pictureImage4"
																								accept="image/*"
																								onChange={(event) => readUrl(event, inputFieldPict.id)}
																							/>
																						</label>
																					</div>
																				</div>
																			</ModalBody>
																		)}
																	</Modal>
																</div>
															</Col>
															<Col>
																<input
																	type="text"
																	placeholder="Choice label"
																	className="multipleinput"
																	maxLength="14"
																	name="choice4"
																	value={inputFieldPict.choice4}
																	onChange={(event) => handleChangeInputPict(inputFieldPict.id, event)}
																/>
															</Col>
															<Col md={1}>
																<div class="radio">
																	<input
																		id="radio1"
																		className="radiobtn"
																		type="radio"
																		name={"radio" + index}
																		value="choice4"
																		defaultChecked={inputFieldPict.radio == "choice4" ? true : false}
																		onChange={(event) => {
																			handleChangeInputPict(inputFieldPict.id, event);
																			inputFieldPict.radio = event.target.value;
																		}}
																	/>
																</div>
															</Col>
														</Row>
														{!inputFieldPict.choice4 && publishClk ? (
															<Row>
																<Col md={1}></Col>
																<Col md={1.1}></Col>
																<Col>
																	<h6
																		style={{
																			color: "red",
																			fontSize: "13px",
																			marginLeft: "12%",
																		}}
																	>
																		Required
																	</h6>
																</Col>
															</Row>
														) : null}
													</div>
													<div className="card-footer">
														<Row style={{ marginLeft: "0px" }}>
															<Col md={3}>
																<Row>
																	<input
																		type="number"
																		placeholder="Enter Credits"
																		className="multipleinput"
																		min="0"
																		name="points"
																		value={inputFieldPict.points}
																		onChange={(event) => handleChangeInputPict(inputFieldPict.id, event)}
																	/>
																</Row>
															</Col>
															<Col md={3}>
																<Row>
																	<input
																		type="number"
																		placeholder="Que time (in sec)"
																		className="multipleinput"
																		min="0"
																		name="seconds"
																		value={inputFieldPict.seconds}
																		onChange={(event) => handleChangeInputPict(inputFieldPict.id, event)}
																	/>
																</Row>
															</Col>
															<Col md={6}>
																<Row>
																	<Col style={{ padding: "0px 3%" }}>
																		<button
																			className="clonebtn"
																			style={{ margin: "0 0px 0 40%" }}
																			onClick={(event) => handleCloneInputPict(inputFieldPict.id, event, index)}
																		>
																			{/* <i className="icofont icofont-copy-alt"></i> */}
																			<Copy size={20} stroke-width="1.5" />
																		</button>
																	</Col>
																	<Col style={{ padding: "0px 3%" }}>
																		<i
																			className="fa fa-picture-o fa-md"
																			style={{ margin: "0 0px 0 40%" }}
																			onClick={() => {
																				setInputFieldId(inputFieldPict.id);
																				pictureinsertPicture();
																			}}
																		></i>
																	</Col>
																	<Col style={{ padding: "0px 3%" }}>
																		<i
																			style={{
																				color: "red",
																				fontSize: "16px",
																				margin: "0 0 0 40%",
																				cursor: "pointer",
																			}}
																			className="fa fa-trash-o"
																			onClick={() => handleRemoveFieldsPict(inputFieldPict.id)}
																		></i>
																	</Col>
																</Row>
															</Col>
														</Row>
													</div>
												</div>
											</div>
										))}
										<Modal isOpen={openModalPicture}>
											<ModalHeader>Image Uploader</ModalHeader>
											{imageCroper == "5" ? (
												<ModalBody>
													<ImageCroperDynamic
														src={cropSrc}
														inputfieldIdPict={inputFieldId}
														ImageCroper={imageCroper}
														setImageCroper={setImageCroper}
														inputFieldsPict={inputFieldsPict}
														setInputFieldsPict={setInputFieldsPict}
														setopenModalPicture={setopenModalPicture}
														setInputFieldId={setInputFieldId}
													/>
												</ModalBody>
											) : (
												<ModalBody>
													<div className="dropzone dropzone-primary">
														<div class="dzu-dropzone">
															<label className="dzu-inputLabel">
																Select an image
																<input
																	className="dzu-input"
																	type="file"
																	name="pictImage"
																	accept="image/*"
																	onChange={(event) => readUrl(event, inputFieldId)}
																/>
															</label>
														</div>
													</div>
												</ModalBody>
											)}
										</Modal>
									</form>
								) : null}
								<Label
									for="appType"
									onClick={openDropdown}
									style={{
										display: "flex",
										alignItems: "center",
										cursor: "pointer",
									}}
								>
									<i className="fa fa-plus-circle fa-3x" style={{ color: "#4466f2" }}></i> &nbsp;&nbsp;Add your first
									question{" "}
								</Label>
								{publishbtn ? (
									<button
										className="btn btn-primary btn-block publishbtn"
										// onClick={(e) => props.setTabIndex(1)}
										onClick={sumbitfunc}
									>
										Publish
									</button>
								) : null}

								{/*--------------------------------------------- DROPDOWN------------------------------------------------- */}

								{dropdown ? (
									<div className="dropcontainer">
										<Row>
											<Col>
												<div className="dropheader">
													<label
														style={{
															fontWeight: "500",
															marginLeft: "8px",
															marginTop: "4px",
														}}
													>
														&nbsp;Choose a question type
													</label>
												</div>
												<div className="buttonscont">
													<div
														className={!welcomebtn == false ? "singlebutton disabledTrue" : "singlebutton"}
														id="welcomescreen"
														onMouseEnter={welcomefunc}
														onMouseLeave={welcomeleave}
														onClick={welcomeclickfunc}
													>
														<label>
															{" "}
															<img src={welcome} alt="empty" className="logo" />
															&nbsp;Welcome Screen
														</label>
													</div>
													<div
														className="singlebutton1"
														id="singlechoice"
														onMouseEnter={singlefunc}
														onMouseLeave={singleleave}
														onClick={singleclickfunc}
													>
														<label>
															{" "}
															<img src={single} alt="empty" className="logo" />
															&nbsp;Single Choice
														</label>
													</div>
													<div
														className="singlebutton1"
														id="singlechoice"
														onMouseEnter={yesnofunc}
														onMouseLeave={yesnoleave}
														onClick={yesnoclickfunc}
													>
														<label>
															{" "}
															<img src={yesimg} alt="empty" className="logo" />
															&nbsp;Yes/No
														</label>
													</div>
													<div
														className="singlebutton2"
														id="multiplechoice"
														onMouseEnter={multiplefunc}
														onMouseLeave={multipleleave}
														onClick={multipleclickfunc}
													>
														<label>
															{" "}
															<img src={multiplechoice} alt="empty" className="logo" />
															&nbsp;Multiple Choice{" "}
														</label>
													</div>
													<div
														className="singlebutton3"
														id="picturechoice"
														onMouseEnter={picturefunc}
														onMouseLeave={pictureleave}
														onClick={pictureclickfunc}
													>
														<label>
															{" "}
															<img src={picturescale} alt="empty" className="logo" />
															&nbsp;Picture Choice
														</label>
													</div>
												</div>
											</Col>

											{/*---------------------------------------------DROPDOWN HOVER EFFECT ------------------------------------------------- */}

											<Col>
												<div className="hovereffectswelcome">
													<img className="hoverimagecard" src={welcomehover} alt="welcome" />
													<h4 className="hovertitle">Welcome Screen</h4>
													<span className="hoverpara">
														{" "}
														The first thing your audience will see-a great opportunity to engage right away
													</span>
												</div>
												<div className="hovereffectssingle">
													<img className="hoverimagecard" src={singlehover} alt="welcome" />
													<h4 className="hovertitle" style={{ paddingRight: "20px", marginTop: "4px" }}>
														Single choice
													</h4>
													<span className="hoverpara" style={{ marginTop: "6px" }}>
														Get your audience options to choose one. Good for getting determined option.
													</span>
												</div>
												<div className="hovereffectsyesno">
													<img className="hoverimagecard" src={yesnohover} alt="yesno" />
													<h4 className="hovertitleyesno" style={{ paddingRight: "20px", marginTop: "4px" }}>
														Yes/No
													</h4>
													<span className="hoverpara" style={{ marginTop: "6px" }}>
														Get your audience just two options (no price for guessing which)
													</span>
												</div>
												<div className="hovereffectsmultiple">
													<img className="hoverimagecard" src={multiplehover} alt="multiple choice" />

													<h4 className="hovertitle" style={{ paddingRight: "10px", marginTop: "5px" }}>
														Multiple Choice
													</h4>
													<span className="hoverpara" style={{ marginTop: "6px" }}>
														Get your audience options to choose from. Good for getting quantitative data.
													</span>
												</div>
												<div className="hovereffectspicture">
													<img className="hoverimagecard" src={picturehover} alt="Picture choice" />
													<h4 className="hovertitle" style={{ paddingRight: "17px", marginTop: "5px" }}>
														Picture Choice
													</h4>
													<span className="hoverpara" style={{ marginTop: "7px" }}>
														{" "}
														Get your audience image options to choose from --bring questions to life
													</span>
												</div>
											</Col>
										</Row>
									</div>
								) : null}
							</FormGroup>
						</Col>
					</Row>
				</Col>
				<Col>{renderSmartphone()}</Col>
			</Row>
		</div>
		// </div>
	);
};

export default QuizForm;
