import React, { Fragment, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import displayMobile from "../../components/ui-elements/display_mobile";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import { Link as NavLink } from "react-router-dom";
import moment from "moment-timezone";
import { TabContent, TabPane, Nav, NavItem, NavLink as NavLinkTab, UncontrolledTooltip, ButtonToolbar } from "reactstrap";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Table, Row, Col, Card, CardBody, Container } from "reactstrap";
import swal from "sweetalert";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import PaginationComponent from "react-reactstrap-pagination";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import "../../table.css";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import { DisappearedLoading } from "react-loadingg";

import imgunverified from "../../assets/staticImages/merchant-unverified.png";
import imgverified from "../../assets/staticImages/merchant-verified.png";
import imgverificationSent from "../../assets/staticImages/merchant-underreview.png";
import MerchantDocPending from "./MerchantDocPending";
import UpdateRequests from "./UpdateRequests";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { DivideCircle } from "react-feather";
import PendingVerification from "../PendingVerification";
// import Slider from "../../components/ui-elements/slider";
import BarLoader from "react-spinners/BarLoader";
import '../../pages/merchants/MerchantInvoiceDetailsCss.scss';

class TrashMerchantReqList extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);

		this.state = {
			error: null,
			isLoaded: false,
			items: [],
			customers: [],
			plan_not_selected: [],
			verified: [],
			webRequests: [],
			expired_plan: [],
			unverified: [],
			blocked: [],
			flagged: [],
			deleteModal: false,
			selectedId: null,
			tabIndex: 0,
			defaultIndexNo: this.props.history.location.state?.activeTab ? this.props.history.location.state?.activeTab : 0,
			send_mail_modal: false,
			deleteSelectedModal: false,
			selectedIDs: [],
			activePage: 1,
			pageSize: 15,
			infoModal: false,
			mobileModal: false,
			blockModal: false,
			blockItem: null,
			flagItem: null,
			infoItem: null,
			flagModal: false,
			flag_note: "",
			searchValue: null,
			searchedData: null,
			value: 100,
			sendCredOption: null,
			send_to_mer_id: "",
			mail_templates: null,
			bulkMail: false,
			send_templ_mail_id: "select",
			access_level: localStorage.getItem("accessLevel"),
			adminUser: JSON.parse(localStorage.getItem("user")),
			loading: false,
			exportModalClick: false,
			exportModalOption: "select",
			startDate: null,
			endDate: null,
			createCsv: false,
			csvData: [],
			csvHeader: [],
			invoiceModal: false,
			invoiceFrom: null,
			invoiceDetails: null,

			searchLoading: true,
			companyData: [],
			trashMerchants: [],
			trashCustomers: [],
			result: [],
			allresult: [],
			underreview: [],
			subscription_invoices: [],
			fetchType: "trashMerchants",
			tab_count: {
				all: 0,
				blockedCount: 0,
				expiredPlan_count: 0,
				flaggedCount: 0,
				pendingveriCount: 0,
				plan_not_selected_count: 0,
				unveriCount: 0,
				veriCount: 0,
				subscription_invoices_count: 0,
				trashMerchantsCount: 0,
			}
		};
	}

	componentDidMount() {
		// alert("hrer");
		const defaultFetch = this.props.history.location.pathname == "/merchants/trash_merchant_requests" ? "trashMerchants" : "verified"
		this.merchantTabCount();
		this.getCustomers(defaultFetch, this.state.activePage);
	}


	handlePageChange = (pageNumber) => {
		console.log(pageNumber, "pageNumber");
		this.setState({ activePage: pageNumber });
		this.getCustomers(this.state.fetchType, pageNumber);
	};

	setPageSize = (event) => {
		this.setState({ pageSize: event.target.value });
		this.getCustomers(this.state.fetchType, 1);
	};


	changeTab = async (type) => {
		console.log(type, "type")
		this.setState(
			{
				fetchType: type,
				activePage: 1
			},
			() => {
				// alert(type);
				this.getCustomers(type, 1);
			}
		);
	};


	merchantTabCount = async () => {

		let postdata = {};
		let current = this;
		current.setState({ loading: true });

		client.post(api.merchantTabCount, postdata, async function (error, response) {
			if (!error) {
				// alert(JSON.stringify(response));
				if (typeof response.error !== "undefined") {
					try {
						if (!response.error) {
							// console.log(response.result);
							current.setState({ tab_count: response.result.tab_data })
						} else {
							toast.error(response.message);
						}
					} catch (e) {
						toast.error("Internal error occured. Please contact support");
					}
				} else {
					toast.error("Internal error occured. Please contact support");
				}
			} else {
				toast.error("Internal error occured. Please contact support");
			}
			current.setState({ loading: false });
		});
	};

	getCustomers = async (fetchType, activePage, is_filter) => {


		let { pageSize } = this.state;
		let skip = (parseInt(activePage) - 1) * parseInt(pageSize);
		// console.log(skip, "skip");

		if (this.state.searchValue !== '' && this.state.searchValue !== null) {
			skip = 0;
		}

		const postdata = { type: fetchType, searchValue: this.state.searchValue, limit: parseInt(pageSize), skip };
		let current = this;
		if (!is_filter) current.setState({ loading: true });
		else {
			current.setState({ searchLoading: true });
		}

		console.log(postdata)

		client.post(api.merchant_request_list, postdata, async function (error, response) {
			if (!error) {
				console.log(response.result.data, "fet mer res")
				if (typeof response.error !== "undefined") {
					try {
						if (!response.error) {
							current.setState({ loading: false })
							// console.log(response.result, "list detail")
							current.setState({ [fetchType]: response.result.data });
							// console.log(response.result.data.map(item=>item.merchants._id), "merchant_ids")
							// current.setState({ companyData: response.result.companyData });
						} else {
							// setTimeout(() => {
							toast.error(response.message);
							// }, 200);
						}
					} catch (e) {
						// setTimeout(() => {}, 200);
						toast.error("Internal error occured. Please contact support");
						// _showToast.error("Internal error occured. Please contact support.");
						// currentComponent.setState({ errormessage: "Internal error." });
					}
				} else {
					// setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
					// }, 200);
					//   _showToast.error("Internal error occured. Please contact support.");
				}
			} else {
				// setTimeout(() => {
				toast.error("Internal error occured. Please contact support");
				// }, 200);
				// alert(JSON.stringify(error));
				// _showToast.error("Internal error occured. Please contact support.");
			}
			current.setState({ loading: false, searchLoading: false });
		});
	};


	refreshPage = () => {
		this.getCustomers(this.state.fetchType, 1);
	};


	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	}

	getComment = (item) => {
		// const customer = this.state.customers.find((el) => el._id == id);
		const comment = item?.comment?.find((item) => item.type == "block");
		//console.log(comment);
		if (comment) {
			return (
				<Fragment>
					<p>{comment.user}</p>
					<p style={{ height: "22px", overflow: "auto", margin: "0px" }}>{comment.note}</p>
				</Fragment>
			);
		} else {
			return;
		}
	};

	rendertableRow = (user, item, index, tab, compData) => {
		const { access_level } = this.state;
		return (
			<>
				<tr key={item._id}>
					<th scope="row">{(this.state.activePage - 1) * this.state.pageSize + index + 1}</th>
					<td style={{ width: "9%" }}>{this.render_date(item.added_date)}</td>
					<td>
						{
							<NavLink to={`/merchants/merchantDetails/${item._id}`} item={item}>
								<span> {item.ipay_id} </span>
								<br />
								<span>{item.location && item.location.suburb ? `/${item.location.suburb}` : ""} </span>
							</NavLink>
						}
					</td>
					<td>
						{user.name}/<br />
						{user.userName}
					</td>
					<td>
						<p> {item.business_name}</p>
						{/* <p>{item.abn}</p> */}
					</td>
					{
						tab != 1 ?
							<td>
								{item.company ? item.company.displayName : "-"}
							</td> : null
					}
					<td style={{ width: "15%" }}>
						{user.mobile}/<br />
						{user.email}
					</td>

					{tab == 3 && <td>{typeof item.comment == "undefined" ? "" : this.getComment(item)}</td>}

					{tab == 1 && (
						<td>
							{item.status == 0
								? "Approved"
								: item.status == 0
									? "Waiting"
									: item.status == 2
										? "Unapproved"
										: item.status == 5
											? "blocked"
											: null}
						</td>
					)}

					{
						access_level != 1 && (
							<td>
								<span style={{ fontSize: "0.75rem" }}>
									{/* <NavLink to={`/merchants/merchantDetails/${item._id}`} item={item}> */}
									<NavLink
										to={{
											pathname: `/merchants/merchantDetails/${item._id}`,
											state: {
												type: 'trashMerchant',
												merchantData: item
											}
										}}
									>
										<VisibilityIcon style={{ fontSize: 15 }} />
									</NavLink>
								</span>
							</td>
						)
					}

					<td>
						{typeof item.comment != "undefined" && item.comment.length > 0 ? (
							<p style={{ height: "22px", overflow: "auto" }}>{item.comment[item.comment.length - 1].note}</p>
						) : (
							"N.A"
						)}
					</td>
				</tr>
			</>
		);
	};

	rendertable = (tab, data, companyData) => {
		const { access_level, fetchType, tab_count, searchValue } = this.state;
		// let customers = [];

		let data_lengh = 0;
		let fdata_lengh;

		if (fetchType == 'trashMerchants') data_lengh = tab_count.trashMerchantsCount;

		if (!searchValue) {
			fdata_lengh = data_lengh;

		} else {
			fdata_lengh = data.length;

		}

		let index = 0;

		// if (this.state.searchedData != null) {
		// 	customers = this.state.searchedData;
		// } else if (tab == 0) {
		// 	customers = this.state.verified;
		// } else if (tab == 1) {
		// 	customers = this.state.webRequests;
		// } else if (tab == 2) {
		// 	customers = this.state.unverified;
		// } else if (tab == 3) {
		// 	customers = this.state.blocked;
		// } else if (tab == 4) {
		// 	customers = this.state.flagged;
		// } else if (tab == 5) {
		// 	customers = this.state.customers;
		// }

		return (
			<Fragment>
				{true ?
					<div className="container-fluid">
						<div className="row">
							<div className="col-sm-12 col-xl-12 xl-100" style={{ padding: "0" }}>
								<div className="card">
									{this.state.searchLoading && <BarLoader height={1} width={"100%"} />}
									<div className="card-body" style={{ padding: "1rem" }}>
										<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: '5px' }}>
											<div>
												<form onSubmit={(event) => event.preventDefault()}>
													<div>
														<input
															style={{
																border: "#dfe8f1 solid 1px",
																borderRadius: "3px",
																padding: "10px",
																fontSize: "0.75rem",
																outlineColor: '#dfe8f1'
															}}
															type="text"
															placeholder="Search"
															value={this.state.searchValue}
															onChange={this.onSearchChangeHandler}
														/>
													</div>
												</form>
											</div>
											<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
												<select
													className="custom-select"
													style={{ width: "32%", marginRight: "7px" }}
													onChange={this.setPageSize}
													value={this.state.pageSize}
												>
													<option value="15">15</option>
													<option value="25">25</option>
													<option value="50">50</option>
													<option value="75">75</option>
													<option value="100">100</option>
												</select>
												<span>Records per Page</span>
											</div>
										</div>

										<div className="table-responsive">
											<table className="table table-border-horizontal">
												<thead>
													<tr style={{ background: '#f9fafe' }}>
														<th scope="col">#</th>
														<th scope="col" style={{ width: "9%" }}>
															<p>Date</p>
															<p>Time</p>
														</th>
														<th scope="col">Linky ID</th>
														<th scope="col">Admin Name/ Username</th>
														<th scope="col">Store Name</th>
														<th scope="col">Company Name</th>
														<th scope="col" style={{ width: "15%" }}> Admin Email/Mobile </th>
														<th scope="col" style={{ width: "12%" }}> Action </th>
														<th scope="col">Notes</th>
													</tr>
												</thead>

												<tbody>
													{
														data.length > 0 ?
															<>
																{
																	data.map((item, index) => {
																		let compData = null;
																		if (tab != 1 && companyData.length > 0) {
																			compData = companyData.find((ele) => ele.adminId == item.merchants.adminId);

																		}

																		return this.rendertableRow(item, item, index, tab, compData);
																	})
																}
															</>
															: <tr>
																<td colspan="10" scope='row' style={{ padding: 10, textAlign: 'center' }}>No records found</td>
															</tr>
													}
												</tbody>
											</table>
											<div className="mt-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
												{this.state.activePage * this.state.pageSize > fdata_lengh ? (
													<p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>
														Showing {(this.state.activePage - 1) * this.state.pageSize + 1} to {fdata_lengh} of {fdata_lengh}{" "}
														entries
													</p>
												) : (
													<p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>
														Showing {(this.state.activePage - 1) * this.state.pageSize + 1} to{" "}
														{this.state.activePage * this.state.pageSize} of {fdata_lengh} entries
													</p>
												)}
												<PaginationComponent
													totalItems={fdata_lengh}
													pageSize={this.state.pageSize}
													onSelect={this.handlePageChange}
													defaultActivePage={this.state.activePage}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					:
					<div>
						<div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
							<div className="loader" style={{ display: "flex", alignItems: "center" }}>
								<DisappearedLoading size="medium" color="#514F4E" />
							</div>
						</div>
					</div>
				}
			</Fragment>
		);
	};

	render_date = (add_date) => {
		if (typeof add_date === "undefined") {
			return;
		}

		const convert_date = new Date(add_date);
		const added_date = moment.tz(convert_date, "Australia/Sydney").format("DD/MM/YYYY HH:mm");
		return added_date;
	};


	onSearchChangeHandler = (event) => {
		if (event.target.value !== '') {
			this.setState(
				{
					searchValue: event.target.value,
				},
				() => {
					// alert(type);
					this.getCustomers(this.state.fetchType, this.state.activePage, true);
				}
			);
		} else {
			this.setState(
				{
					searchValue: null,
				},
				() => {
					// alert(type);
					this.getCustomers(this.state.fetchType, this.state.activePage, true);
				}
			);
		}

	};


	render() {
		let {
			result: customers,
			tab_count,
			companyData,
			trashMerchants,
		} = this.state;

		// console.log(tab_count);

		return (
			<Fragment>
				<div className="row">
					<div className="col-lg-7 col-sm-12" style={{ fontSize: "12px" }}>
						<Breadcrumb title={"I-Pay merchants"} parent={"Home"} />
					</div>
				</div>

				<div className="container-fluid">
					<div className="row theme-tab">
						<Tabs
							className="col-sm-12"
							defaultIndex={this.state.defaultIndexNo}
							style={{
								backgroundColor: "white",
								borderRadius: "3px",
								border: "1px solid rgb(223, 232, 241)",
								marginBottom: "0px",
							}}
							onSelect={(index) => {
								this.setState({ tabIndex: index });
								this.setState({ searchValue: "" });
								this.setState({ searchedData: null });
							}}
						>
							<TabList className="tabs tab-title">
								<Tab
									style={{ display: "flex", alignItems: "center" }}
									onClick={() => this.changeTab("trashMerchants")}
								>
									<i className="icofont icofont-copy-invert" style={{ fontSize: "0.9rem", marginRight: "8px" }}></i>
									Trash Merchants ({tab_count.trashMerchantsCount})
								</Tab>

								{/* <Tab
									style={{ display: "flex", alignItems: "center" }}
									onClick={() => this.changeTab("trashCustomers")}
								>
									<i className="icofont icofont-copy-invert" style={{ fontSize: "0.9rem", marginRight: "8px" }}></i>
									Trash Customers (0)
								</Tab> */}
							</TabList>

							{!this.state.loading ? (
								<div className="tab-content-cls">
									<TabPanel>{this.rendertable(0, trashMerchants, companyData)}</TabPanel>
									{/* <TabPanel>{this.rendertable(1, trashMerchants, companyData)}</TabPanel> */}
								</div>
							) : (
								<>
									<div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
										<div className="loader" style={{ display: "flex", alignItems: "center" }}>
											<DisappearedLoading size="medium" color="#514F4E" />
										</div>
									</div>
								</>
							)}
						</Tabs>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default TrashMerchantReqList;
