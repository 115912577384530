import React, { useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { components } from "react-select";
// import './CustomOptionsMultiSelect.scss';
// import ReactCountryFlag from 'react-country-flag';
// import { useSelector, connect, useDispatch } from "react-redux";


const MultiSelectField = ({
    value,
    name,
    className,
    options,
    countryData,
    isDisabled,
    setGetMobileRegex,
    onChange,
    props,
    custom_style

}) => {


    const customStyles = {

        control: base => ({
            ...base,
            height: 36,
            minHeight: 36,
            width: custom_style ? 85 : '100%',
            maxWidth: custom_style ? 85 : '100%',
            fontSize: '14px',
            boxShadow: null,
            boxShadow: '0 !important',
            border: '1px solid rgb(118, 118, 118) !important',
            borderRadius: "unset",
            borderRight: custom_style ? "none !important" : '100%',
            // '&:hover': {
            //     border: '1px solid #f2f4f7 !important',
            // },
            '&:focus': {
                border: '1px solid #f2f4f7 !important',
            }
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '32px',
            padding: '0',

        }),
        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        option: base => ({
            ...base,
            fontFamily: "sans-serif",
            fontSize: '13px',
            color: 'black'
        }),
        singleValue: base => ({
            ...base,
            marginLeft: "0.5rem "
        }),
        menu: base => ({
            ...base,
            width: custom_style ? "250px !important" : "100%"
        }),
        // option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        //     return {
        //         ...styles,
        //         backgroundColor: isFocused ? "#999999" : null,
        //         color: "#333333"
        //     };
        // }
    };


    const getCountryData = (value) => {

        let findCountryData = countryData.find((val) => val.country_code == value);
        setGetMobileRegex(findCountryData?.phone_regex)

        return findCountryData
    }

    // const customOption = (props) => {
    //     return (

    //         <div>
    //             <components.Option  {...props}>
    //                 <div className='d-flex align-items-center'>
    //                     {
    //                         props.value != '+0' ?
    //                             <ReactCountryFlag countryCode={getCountryData(props.value)?.country_code} svg />
    //                             : null
    //                     }
    //                     <span className='m-0 py-0 pl-2 text-capitalize'>{props.label}</span>
    //                 </div>
    //             </components.Option>
    //         </div>
    //     );
    // };


    // const ValueContainer = ({ children, ...props }) => (
    //     <components.ValueContainer {...props}>
    //         <div className='d-flex align-items-center justify-content-end w-100'>
    //             {
    //                 props.value != '+0' ?
    //                     <>
    //                         <ReactCountryFlag className='mr-1' countryCode={getCountryData(children[0].props.data?.value)?.country_code} svg />
    //                         {/* <span style={{ color: "black", marginRight: '0.5rem' }} >{getCountryData(children[0].props.data?.value)?.phone_prefix}</span> */}
    //                     </>
    //                     : null
    //             }
    //             <span> {children}</span>
    //         </div>
    //     </components.ValueContainer>
    // );



    return (
        <Select
            isMulti={false}
            name={name}
            value={value}
            onChange={onChange}
            options={options}
            classNamePrefix="react-select"
            isSearchable={false}
            className={className}
            styles={customStyles}
            isDisabled={isDisabled}
            // selectedValue={defaultContry.value, defaultContry.label}
            // menuIsOpen={true}
            components={{
                // Option: customOption,
                // ValueContainer: ValueContainer,
                // DropdownIndicator: () => null,
                IndicatorSeparator: () => null
            }}
        />
    );
};


const renderMultiSelectField = ({
    input,
    meta,
    options,
    name,
    placeholder,
    onMenuClose,
    isDisabled,
    className,
    countryData,
    optionBottomText,
    setActiveTab,
    style,
    setGetMobileRegex,
    onChange,
    value,
    custom_style
}) => (
    <div>
        <MultiSelectField
            {...input}
            countryData={countryData}
            options={options}
            isDisabled={isDisabled}
            onMenuClose={onMenuClose}
            optionBottomText={optionBottomText}
            className={className}
            setGetMobileRegex={setGetMobileRegex}
            name={name}
            onChange={onChange}
            value={value}
            custom_style={custom_style}
        />

        {/* {meta.touched && meta.error && <span style={{ padding: "2px 5px", color: "#fcb33d" }}>{meta.error}</span>} */}
    </div>
);

export default renderMultiSelectField;
