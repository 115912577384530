import React, { useState, useRef, useEffect, useMemo } from 'react';
import {
    Button,
    ButtonToolbar,
    Card,
    CardBody,
    Col,
    Input,
    Container,
    Row,
    Progress,
    Modal,
    Table,
    FormGroup,
    Alert,
} from 'reactstrap';
import moment from 'moment';
import { withRouter } from "react-router";
import { toast, ToastContainer } from 'react-toastify';
import Icon from '@mdi/react'
import { mdiAlertCircle, mdiDownload } from '@mdi/js';
import "./MerchantInvoiceDetailsCss.scss"
import HTTPClient from "../../Api/HTTPClient"
import api from "../../constant/apilist"
import BeatLoader from "react-spinners/BeatLoader";


const MerchantInvoiceDetails = (props) => {
    const { setInvoiceDetails, invoiceData, merchantInfo } = props
    const [loading, setLoading] = useState(false);
    const [invoiceFrom, setinvoiceFrom] = useState(null);
    const [whiteLabelInvoice, setWhiteLabelInvoice] = useState(false);
    // const [paymentLog, setPaymentLog] = useState(null);

    console.log(invoiceData, 'inVDATA')

    useEffect(() => {
        const invoice_id = invoiceData?._id
        setLoading(true)
        HTTPClient.get(api.fetch_invoice, { invoice_id }, async function (error, response) {
            // console.log(response, "fetch_subscription_invoice", error)
            if (!error) {
                // console.log(response, "fetch_subscription_invoice")
                if (!response.error) {
                    setinvoiceFrom(response.invoice_from)
                    // setPaymentLog(response.result.payment_log)
                    setLoading(false)
                } else {
                    toast.error("Internal error occured.Please contact support");
                    setLoading(false)

                }
            }
            else {
                toast.error("Internal error occured.Please contact support");
                setLoading(false)

            }
        })

    }, [])

    useEffect(() => {
        let getData = invoiceData?.invoice_title.includes("White label Enterprise plan");
        if (getData == true) {
            setWhiteLabelInvoice(true);
        } else {
            setWhiteLabelInvoice(false)
        }
    }, [invoiceData])

    const ShowCurrencyNum = (amount, showSymbol) => {
        // const merchantData = JSON.parse(localStorage.getItem("merchant"));
        const merchantData = invoiceData?.fk_merchant_id;
        const currency_symbol = merchantData.country.currency_symbol;
        // const currency_symbol = countryData.currency_symbol;

        let getCurrency = !isNaN(parseFloat(amount)) ? amount : 0;

        if (merchantData && merchantData.country) {
            // if (countryData) {
            if (showSymbol && currency_symbol) {
                getCurrency = `${currency_symbol} ${parseFloat(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
            }
            else {
                getCurrency = +`${parseFloat(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
            }
        }

        return getCurrency;
    }


    return (
        <Card className='merchantInvoice'>
            {!loading ? <CardBody>
                {invoiceData && <Container style={{ marginTop: "20px" }}>
                    <Row>
                        <Col md={12}>
                            <div className='text-right'>

                                {/* <span className="mr-4 font-weight-bold" style={{ cursor: 'pointer', color: 'gray' }}>
                                    <Icon path={mdiDownload} size={1.2} /> PDF</span> */}
                                <Button
                                    className='my-0'
                                    type='button'
                                    size='sm'
                                    color='primary'
                                    outline

                                    onClick={() => {
                                        // history.goBack();
                                        setInvoiceDetails(false)
                                    }
                                    }
                                > Go Back </Button>

                            </div>
                            <Row className='pt-4 pb-3'>
                                <Col md={12}>
                                    <div className='border rounded p-4'>
                                        <Row>
                                            <Col md={6} className='d-flex align-items-center justify-content-start'>
                                                <div
                                                    className="invoice__logo"
                                                    style={{
                                                        height: '50px',
                                                    }}
                                                />
                                            </Col>
                                            <Col md={6} className='d-flex align-items-center justify-content-end'>
                                                <div>
                                                    <p className='invoice__grand-total' > Invoice #{invoiceData?.invoice_number} </p>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='py-3 border-top'>
                                            <Col md={8} className='text-left'>
                                                <div>
                                                    <p className='my-0'> {invoiceFrom?.business_name} </p>
                                                    <p className='my-0'>
                                                        {/* {invoiceFrom.address.split(',').map((line, i) => (
                                                            <span key={i}>
                                                                {line}
                                                                <br />
                                                            </span>
                                                        ))
                                                        } */}
                                                        {invoiceFrom?.address}
                                                    </p>
                                                    {invoiceFrom?.abn != "" &&
                                                        <p dir="ltr" className='my-0'> ABN - {invoiceFrom?.abn} </p>
                                                    }
                                                    {invoiceFrom?.website != "" &&
                                                        <a href={invoiceFrom?.website} target='_blank'> {invoiceFrom?.website} </a>
                                                    }
                                                    {/* <a href='https://www.store.com' target='_blank'> www.store.com </a> */}
                                                </div>
                                            </Col>
                                            <Col md={4} className='text-right'>
                                                <div>
                                                    <p className="invoice__date my-0"> Date/Time : {moment(invoiceData?.payment_date).format('DD-MM-YYYY/H:mm')} </p>
                                                    <p className='my-0' style={{ textTransform: "capitalize" }}> {invoiceData?.fk_merchant_id?.business_name} </p>
                                                    {/* <p className='my-0'> {invoiceData?.fk_merchant_id?.address} </p>
                                                     */}
                                                    <p className='my-0'>
                                                        {/* {invoiceData?.fk_merchant_id?.address.split(',').map((line, i) => (
                                                            <span key={i}>
                                                                {line}
                                                                <br />
                                                            </span>
                                                        ))
                                                        } */}
                                                        {invoiceData?.fk_merchant_id?.address}
                                                    </p>
                                                    {/* <p className='my-0'> Australia </p> */}
                                                    {invoiceData?.fk_merchant_id?.country?.code == "AU" && invoiceData?.fk_merchant_id?.company_id?.abn && invoiceData?.fk_merchant_id?.company_id?.abn != "" &&
                                                        <p dir="ltr" className='my-0'> ABN - {invoiceData?.fk_merchant_id?.company_id?.abn} </p>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='py-3'>
                                            <Col md={12}>
                                                <Table className="table--bordered" responsive striped>
                                                    <thead>
                                                        <tr>
                                                            <th> # </th>
                                                            <th> Plan </th>
                                                            {whiteLabelInvoice ? null : <th> Quantity </th>}
                                                            {whiteLabelInvoice ? null : <th> Unit Cost </th>}
                                                            <th> Total </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            <tr>
                                                                <td>1</td>
                                                                <td>{`${invoiceData?.invoice_title}  ${whiteLabelInvoice && invoiceData?.no_of_stores ? `  (${invoiceData.no_of_stores} Stores)` : ""}`}</td>
                                                                {whiteLabelInvoice ? null : <td>1</td>}
                                                                {/* {whiteLabelInvoice ? null : <td>${invoiceData.sub_total.toFixed(2)}</td>} */}
                                                                {whiteLabelInvoice ? null :
                                                                    <td>{ShowCurrencyNum(invoiceData?.sub_total.toFixed(2), true)}</td>}
                                                                {/* <td>${invoiceData.sub_total.toFixed(2)}</td> */}
                                                                <td>{ShowCurrencyNum(invoiceData?.sub_total.toFixed(2), true)}</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={12} className='text-right'>
                                                <div className="invoice__total">
                                                    {/* <p> Sub - total amount: ${(invoiceData.sub_total).toFixed(2)} </p> */}
                                                    <p> Sub - total amount: {ShowCurrencyNum((invoiceData?.sub_total).toFixed(2), true)} </p>
                                                    {/* {invoiceData?.discount > 0 ? (<p> Discount: ${(invoiceData?.discount).toFixed(2)} </p>) : ('')} */}
                                                    {invoiceData?.discount > 0 ? (<p> Discount: {ShowCurrencyNum((invoiceData?.discount).toFixed(2), true)} </p>) : ('')}
                                                    {/* {invoiceData?.promo_code_discount > 0 && <p>Promo code :${(invoiceData?.promo_code_discount).toFixed(2)}</p>} */}
                                                    {invoiceData?.promo_code_discount > 0 && <p>Promo code :{ShowCurrencyNum((invoiceData?.promo_code_discount).toFixed(2), true)}</p>}

                                                    {invoiceData?.charges && invoiceData?.charges.map((item) =>
                                                        <p> {item?.title}: {ShowCurrencyNum((item?.amount).toFixed(2), true)} </p>
                                                    )}
                                                    {/* <p> {item.title}: ${(item.amount).toFixed(2)} </p> */}
                                                    {/* <p className="invoice__grand-total"> Grand Total: ${(invoiceData.total).toFixed(2)} </p> */}
                                                    <p className="invoice__grand-total"> Grand Total: {ShowCurrencyNum((invoiceData?.total).toFixed(2), true)} </p>
                                                    <ButtonToolbar className="invoice__toolbar">
                                                        {/* <Button color="primary"> Proceed to payment </Button> */}
                                                        {/* <Button> Print </Button> */}
                                                    </ButtonToolbar>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>}

            </CardBody> :
                <div className='loaderDiv'>
                    <BeatLoader
                        color={"#fff"}
                        loading={true}
                        size="7"
                    />
                </div>
            }
        </Card>
    )
}

export default withRouter(MerchantInvoiceDetails);
