import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { components } from "react-select";


const SelectField = ({ onChange, value, name, placeholder, options, optionBottomText }) => {
    const handleChange = (selectedOption) => {
        onChange(selectedOption);
    };
    const colourStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "#FFFFFF !important",
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? "#F4F5F8" : "#FFFFFF !important",
                color: isDisabled ? "#D3D3D3 !important" : "gray",

            };
        },
    };

    const customMenu = (props) => {
        return (
            <div>
                <components.Menu {...props}>
                    <div className='overflow-hidden border'>
                        {
                            props.selectProps.fetchingData ?
                                <span> Fetching data... </span>
                                : <div className='react_select_option_list'>
                                    {props.children}
                                </div>
                        }
                        <div>
                            {optionBottomText}
                        </div>
                    </div>
                </components.Menu>
            </div>
        );
    };

    return (
        <Select
            name={name}
            value={value}
            onChange={handleChange}
            options={options}
            clearable={false}
            className="react-select "
            placeholder={placeholder}
            classNamePrefix="react-select"
            styles={colourStyles}
            isSearchable={false}
            components={{
                Menu: customMenu,
            }}
            maxMenuHeight={220}
        />
    );
};

SelectField.propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        })
    ),
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        }),
    ]).isRequired,
};

SelectField.defaultProps = {
    placeholder: "",
    options: [],
};

const renderSelectField = ({
    input,
    meta,
    options,
    placeholder,
    className,
    optionBottomText,
    onChange,
    value,
    style
}) => (
    <div
        className={`form__form-group-input-wrap label ${className}`}
        style={style}
    >
        <SelectField
            {...input}
            value={value}
            onChange={onChange}
            options={options}
            placeholder={placeholder}
            optionBottomText={optionBottomText}
        />
        {meta && meta.touched && meta.error && (
            <span className="form__form-group-error">{meta.error}</span>
        )}
    </div>
);

renderSelectField.propTypes = {
    input: PropTypes.shape({
        onChange: PropTypes.func,
        name: PropTypes.string,
    }).isRequired,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }),
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        })
    ),
    placeholder: PropTypes.string,
    className: PropTypes.string,
};

renderSelectField.defaultProps = {
    meta: null,
    options: [],
    placeholder: "",
    className: "",
};

export default renderSelectField;
