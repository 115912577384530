import React, { Component, Fragment } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup, Col, Row, FormFeedback, CustomInput } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import { Link as NavLink } from "react-router-dom";
import moment from "moment-timezone";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Popover, PopoverHeader, PopoverBody, UncontrolledPopover } from 'reactstrap';

import Breadcrumb from "../../../components/common/breadcrumb";
import "../../../table.css";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import swal from 'sweetalert';
import Select from 'react-select';

const unlock_offer_options = [
    // { value: 'Unlock feature', label: 'Unlock feature' },
    { value: 'Unlock Credits', label: 'Unlock Credits' },
    { value: 'Freebies', label: 'Freebies' }
  ]

class MerchantCrownsPoints extends Component {

    state = {
        //modal: false,
        tabIndex : 0,
        crownPointLevels: null,
        url: null,
        crownPointsChangeInfo: null,
        editModal: false,
        level: null,
        editLevelInfo: null,
        isOpen: false,
        imageSrc: null,
        levelId: null,
        unlock_offer: '',
        adminUser: JSON.parse(localStorage.getItem("user")),
        access_level: localStorage.getItem("accessLevel")

    }

    componentDidMount() {
        this.get_all_crownLevel();
    }

    /* toggle = () => {
        this.setState(prevState => {
            return {
                modal: !prevState.modal,
            }
        });
    }
 */
    onEditClick = (level) => {
        this.setState(prevState => {
            return {
                editModal: !prevState.editModal,
                level: level
            }
        });
    }
    toggleEditModal = () => {
        this.setState(prevState => {
            return {
                editModal: !prevState.editModal,
            }
        });
    }

    handleUnlockOffer = (unlockOffer) => {
        const { value } = unlockOffer;
        this.setState({ unlock_offer: unlockOffer })

        this.setState(prevState => {
            return {
                ...prevState,
                level: {
                    ...prevState.level,
                    ['unlock_offer']: value

                },
                editLevelInfo: {
                    ...prevState.editLevelInfo,
                    ['unlock_offer']: value
                }
            }
        });

        if(value == 'Freebies') {
            this.setState(prevState => {
                return {
                    ...prevState,
                    level: {
                        ...prevState.level,
                        ['credits']: null
    
                    },
                    editLevelInfo: {
                        ...prevState.editLevelInfo,
                        ['credits']: null
                    }
                }
            });
        }else if(value == 'Unlock Credits')
        {
            this.setState(prevState => {
                return {
                    ...prevState,
                    level: {
                        ...prevState.level,
                        ['freebie_name']: null
    
                    },
                    editLevelInfo: {
                        ...prevState.editLevelInfo,
                        ['freebie_name']: null
                    }
                }
            });
        }else{
            this.setState(prevState => {
                return {
                    ...prevState,
                    level: {
                        ...prevState.level,
                        ['freebie_name']: null,
                        ['credits']: null
    
                    },
                    editLevelInfo: {
                        ...prevState.editLevelInfo,
                        ['freebie_name']: null,
                        ['credits']: null
                    }
                }
            });
        }
    }

    toggleDeleteModal = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                deleteModal: !prevState.deleteModal
            }
        });
    }

    imageClicked = (src) => {
        //console.log("clicked");
        this.setState({ isOpen: true, imageSrc: src })
    }

    deleteClick = (id) => {
        this.setState(prevState => {
            return {
                ...prevState,
                deleteModal: !prevState.deleteModal,
                levelId: id
            }
        });
    }

    deleteCrownLevel = (e) => {
        e.preventDefault();

        const data = { levelId: this.state.levelId }

        client.post(api.del_Merc_Crown_level, data, (error, response) => {
            if (!error) {
                // alert(JSON.stringify(response));
                //   return false;
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            toast.success(response.message);
                            window.location.reload(true);
                        } else {
                            // toast.error(response.message);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }

        });
    }

    render_date = (add_date) => {

        if (typeof (add_date) === "undefined") {
            return;
        }

        const convert_date = new Date(add_date)
        const added_date = moment.tz(convert_date, "Australia/Sydney").format("DD-MM-YYYY HH:mm");
        return added_date;
    }

    refreshPage() {
        window.location.reload(false);
    }

    get_all_crownLevel = () => {
        const postdata = {};

        client.post(api.get_Merc_allCrownLevel, postdata, (error, response) => {
            if (!error) {
                if (!response.error) {
                    console.log(response);
                    this.setState({ crownPointLevels: response.result.data, redeem_request: response.result.redeem_request.filter((elem) => elem.credits == null || elem.credits == undefined) })
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);

                }

            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        });
    }

    onCheckboxChange = (event, id) => {
        let postData = "";

        const foundLevel = this.state.crownPointLevels.find(el => {
            return el._id == id
        });

        postData = { _id: id, level_status: !foundLevel.level_status }



        client.post(api.edit_Merc_Crown_level, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    // this.refreshPage();
                    console.log(response);
                    toast.success(response.message);
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);

                }

            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        });
    }

    onlevelFieldChange = (event) => {
        const { name, value } = event.target;
        // console.log(event.target.files);
        if (event.target.type == "file" && event.target.files.length !=0) {
            var mimeType = event.target.files[0].type;

            if (mimeType.match(/(jpeg|jpg|png|pdf)$/) == null) {
                return;
            }
            // Image upload
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = (_event) => {
                let imageData;
                this.setState({ url: reader.result });
                imageData = reader.result;
                imageData = imageData.split(",")[1];

                let image_data = { mime: mimeType, data: imageData }
                // console.log(image_data);
                this.setState(prevState => {
                    return {
                        ...prevState,
                        crownPointsChangeInfo: {
                            ...prevState.crownPointsChangeInfo,
                            [name]: image_data
                        }
                    }
                })
            }
        } else {
            this.setState(prevState => {
                return {
                    ...prevState,
                    crownPointsChangeInfo: {
                        ...prevState.crownPointsChangeInfo,
                        [name]: value
                    }
                }
            })
        }
    }

    onEditCrownLevel = (event) => {
        const { name, value } = event.target;
        if (event.target.type == "file") {
            var mimeType = event.target.files[0].type;

            if (mimeType.match(/(jpeg|jpg|png|pdf)$/) == null) {
                return;
            }
            // Image upload
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = (_event) => {
                let imageData;
                this.setState({ url: reader.result });
                imageData = reader.result;
                imageData = imageData.split(",")[1];

                let image_data = { mime: mimeType, data: imageData }
                // console.log(image_data);
                this.setState(prevState => {
                    return {
                        ...prevState,
                        level: {
                            ...prevState.level,
                            [name]: image_data
                        },
                        editLevelInfo: {
                            ...prevState.editLevelInfo,
                            [name]: image_data
                        }
                    }
                })
            }
        } else {
            this.setState(prevState => {
                return {
                    ...prevState,
                    level: {
                        ...prevState.level,
                        [name]: value

                    },
                    editLevelInfo: {
                        ...prevState.editLevelInfo,
                        [name]: value
                    }
                }
            })

        }

    }

    newLevelSubmitHandler = (event) => {
        event.preventDefault();
        const postdata = { ...this.state.crownPointsChangeInfo, level_status: 1 };

        client.post(api.add_New_Level_Merc_Crown, postdata, (error, response) => {
            if (!error) {
                if (!response.error) {
                    // this.refreshPage();
                    this.get_all_crownLevel();
                    // console.log(response);
                    toast.success(response.message);
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);

                }

            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        });

    }

    editLevelSubmitHandler = (event) => {
        event.preventDefault();
        const postdata = { _id: this.state.level._id, ...this.state.editLevelInfo };
        // console.log(postdata);

        client.post(api.edit_Merc_Crown_level, postdata, (error, response) => {
            if (!error) {
                if (!response.error) {
                    this.refreshPage();
                    // console.log(response);
                    toast.success(response.message);
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);

                }

                // setTimeout(() => {
                //     toast.error("Internal error occured. Please contact support");
                // }, 200);
            }
        });

    }

    processedRewardAction = async(reward_id) => {


        let process = await swal({
            title: "Are you sure?",
            text: "Is the offer fulfilled to merchant?",
            icon: "warning",
            buttons: {
                cancel: true,
                confirm: {
                    text: "Yes!",
                    closeModal: false,
                },
            },
            dangerMode: true,
        })

        if (process) {
            let postdata = { reward_id: reward_id, admin: this.state.adminUser.full_name,};
            let current = this;
            let message = "Internal error occured. Please contact support";
            let status = 0;
            current.setState({
                loading: true,
            });
            
            client.post(api.level_reward_processed, postdata, async function (error, response) {
                if (!error) {
                    // alert(JSON.stringify(response));
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                status = 1;
                                message = "Status updated successfully"
                            } else {
                                status = 0;
                                message = response.message
                            }
                        } catch (e) {
                        }
                    }
                }

                swal.close();
                swal(message);
                if (status == 1)
                    current.get_all_crownLevel();
            });
        }
    
    }

    renderTable = () => {
        const { access_level } = this.state;

        return (
            <Tabs className="col-sm-12" defaultIndex={this.state.tabIndex} onSelect={index => this.setState({ tabIndex: index })} style={{ padding: "0" }}>
                <TabList className="tabs tab-title" >
                    <Tab>
                        Levels
                    </Tab>
                    <Tab>
                        Redeem Requests
                    </Tab>
                </TabList>
                <div className="tab-content-cls">
                    <TabPanel>
                        <div className="table-responsive">
                            <table className="table table-border-horizontal">
                                <thead>
                                    <tr style={{background: '#f9fafe'}}>
                                        <th scope="col" style={{ width: "45px" }}>#</th>
                                        <th scope="col" style={{ width: "90px" }}>
                                            <p>Date </p>
                                            <p>Time</p>
                                        </th>
                                        <th scope="col" style={{ width: "80px" }}>Level Title</th>
                                        <th scope="col" style={{ width: "110px" }}>From (customers)</th>
                                        <th scope="col" style={{ width: "110px" }}>To (customers)</th>
                                        <th scope="col" style={{ width: "110px" }}>Points   (per customer)</th>
                                        <th scope="col" style={{ width: "110px" }}>Crown Title</th>
                                        <th scope="col" style={{ width: "90px" }}>Crown icon</th>
                                        <th scope="col" style={{ width: "120px" }}>Unlock Offer</th>
                                        <th scope="col" style={{ width: "120px" }}>Credits</th>
                                        <th scope="col" style={{ width: "120px" }}>Freebie Name</th>
                                        <th scope="col" style={{ width: "110px" }}>Offer icon</th>
                                        <th scope="col" style={{ width: "110px" }}>T&C</th>
                                        {access_level != 1 && <th scope="col" style={{ width: "100px" }}>Action</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.crownPointLevels ? this.state.crownPointLevels.map((item, index) => {
                                        return (
                                            <tr>
                                                <th scope="row" style={{ width: "45px" }}>{index + 1}</th>
                                                <td style={{ width: "90px" }}>{this.render_date(item.createdAt)}</td>
                                                <td style={{ width: "80px" }}>{item.level_title}</td>
                                                <td style={{ width: "110px" }}>{item.from_cust}</td>
                                                <td style={{ width: "110px" }}>{item.to_cust}</td>
                                                <td style={{ width: "110px" }}>{item.points_per_cust}</td>
                                                <td style={{ width: "110px" }}>{item.crown_title}</td>
                                                <td style={{ width: "90px" }}>
                                                    <img src={`${client.url(`images/crown-level/merchant/${item.crown_icon}`)}`}
                                                        onClick={() => this.imageClicked(`${client.url(`images/crown-level/merchant/${item.crown_icon}`)}`)} style={{ width: "40px", height: "40px" }}></img>

                                                </td>
                                                <td style={{ width: "120px" }}>{item.unlock_feat_name}</td>
                                                <td style={{width: "120px"}}>{item.credits ? item.credits : '-'}</td>
                                                <td style={{width: "120px"}}>{item.freebie_name ? item.freebie_name: '-'}</td>
                                                <td style={{ width: "110px" }}>
                                                    {item.unlock_feat_icon != "" ? <img src={`${client.url(`images/crown-level/merchant/${item.unlock_feat_icon}`)}`}
                                                        onClick={() => this.imageClicked(`${client.url(`images/crown-level/merchant/${item.unlock_feat_icon}`)}`)} style={{ width: "40px", height: "40px" }}></img>
                                                        : null}
                                                </td>
                                                <td style={{ width: "110px" }}>
                                                    {item.term_and_conditions &&
                                                        <div className="btn-showcase">
                                                             {item.term_and_conditions.substring(0, 30)}
                                                            {
                                                                (item.term_and_conditions).length > 30 ?
                                                                    <>
                                                                    

                                                                        <Button style={{
                                                                            width: "auto",
                                                                            padding: 0,
                                                                            backgroundColor: "white !important",
                                                                            border: "none",
                                                                            color: "#212529",
                                                                            fontSize: 12,
                                                                        }}
                                                                            id={`PopoverFocus${index}`} className="more-button-custom" type="button" >
                                                                            More...
                                                                </Button>
                                                        
                                                                        <UncontrolledPopover trigger="focus" placement="bottom" target={`PopoverFocus${index}`}>
                                                                            <PopoverHeader>T&C</PopoverHeader>
                                                                            <PopoverBody>
                                                                                {item.term_and_conditions}
                                                                            </PopoverBody>
                                                                        </UncontrolledPopover>
                                                                    </>
                                                                    :
                                                                    null
                                                            }

                                                        </div>
                                                    }
                                                </td>
                                                {access_level != 1 && <td style={{ width: "100px" }}>
                                                    {access_level == 3 && <span style={{ marginRight: "10px", fontSize: "0.75rem" }} onClick={() => this.deleteClick(item._id)}>
                                                        <i style={{ color: "red" }} id="delete" className="fa fa-trash-o"></i>
                                                    </span>}
                                                    <span style={{ marginRight: "5px", fontSize: "0.75rem", color: "black" }} onClick={() => this.onEditClick(item)}>
                                                        <i className="fa fa-edit"></i>
                                                    </span>
                                                    <span >
                                                        <input type="checkbox" onChange={(event) => this.onCheckboxChange(event, item._id)} name={`level${item._id}`}
                                                            checked={item.level_status == true ? "checked" : null}></input>
                                                    </span>
                                                </td>}
                                            </tr>
                                        )
                                    }) : null}
                                </tbody>
                            </table>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div>
                            {/* redeem_request */}
                            <div className="table-responsive">
                                <table className="table table-border-horizontal">
                                    <thead>
                                        <tr style={{background: '#f9fafe'}}>
                                            <th scope="col" >#</th>
                                            <th scope="col" >
                                                <p>Date </p>
                                                <p>Time</p>
                                            </th>
                                            <th scope="col" >Merchant</th>
                                            <th scope="col" >Unlock feature name</th>
                                            <th scope="col" >Unock feature icon</th>
                                            <th scope="col" >Status</th>
                                            {/* {access_level != 1 && <th scope="col" style={{ width: "100px" }}>Action</th>} */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.redeem_request ? this.state.redeem_request.map((item, index) => {
                                            return (
                                                <tr>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{this.render_date(item.created_date)}</td>
                                                    
                                                    <td >{item.merchant ? item.merchant.business_name : "" }</td>
                                                    <td >{item.unlock_feat_name}</td>
                                                    <td >
                                                        {item.unlock_feat_icon != "" ? <img src={`${client.url(`images/crown-level/merchant/${item.unlock_feat_icon}`)}`}
                                                            onClick={() => this.imageClicked(`${client.url(`images/crown-level/merchant/${item.unlock_feat_icon}`)}`)} style={{ width: "40px", height: "40px" }}></img>
                                                            : null}
                                                    </td>
                                                    <td>
                                                        {
                                                            item.status == 0 ?
                                                                "Pending redemption" :
                                                                item.status == 3 ?
                                                                    < p > <span className="authorize" onClick={() => this.processedRewardAction(item._id)} style={{ cursor: "pointer", padding: "2px 10px", fontSize: "0.68rem" }} >Redeem request received</span></p>
                                                                    :
                                                                    item.status == 1 ?
                                                                        "Processed" :
                                                                        ""
                                                        }
                                                    </td>
                                                    {/* <td>Action</td> */}

                                                    {/* {access_level != 1 && <td style={{ width: "100px" }}>
                                                        {access_level == 3 && <span style={{ marginRight: "10px", fontSize: "0.75rem" }} onClick={() => this.deleteClick(item._id)}>
                                                            <i style={{ color: "red" }} id="delete" className="fa fa-trash-o"></i>
                                                        </span>}
                                                        <span style={{ marginRight: "5px", fontSize: "0.75rem", color: "black" }} onClick={() => this.onEditClick(item)}>
                                                            <i className="fa fa-edit"></i>
                                                        </span>
                                                        <span >
                                                            <input type="checkbox" onChange={(event) => this.onCheckboxChange(event, item._id)} name={`level${item._id}`}
                                                                checked={item.level_status == true ? "checked" : null}></input>
                                                        </span>
                                                    </td>} */}
                                                </tr>
                                            )
                                        }) : null}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </TabPanel>
                </div>
            </Tabs>
        )
    }


    renderAddNewLevelModal = () => {
        const { unlock_offer } = this.state;
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggle}>
                <Form onSubmit={this.newLevelSubmitHandler}>
                    <ModalHeader toggle={this.props.toggle}>Add New Level</ModalHeader>
                    <ModalBody>

                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="level_title">Level title</Label>
                                    <Input type="text" name="level_title" required placeholder="Level title" 
                                    onChange={this.onlevelFieldChange} autoComplete="off"/>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="from_cust">From (no. of customer)</Label>
                                    <Input className="number" step="any" type="number" name="from_cust" required placeholder="From " onChange={this.onlevelFieldChange} />
                                </FormGroup>
                            </Col>

                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="to_cust">To (no. of customer)</Label>
                                    <Input className="number" step="any" type="number" name="to_cust" required placeholder="To" onChange={this.onlevelFieldChange} />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="points_per_cust">Points (per customer)</Label>
                                    <Input className="number" required step="any" type="number" name="points_per_cust" placeholder="Points" onChange={this.onlevelFieldChange} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="crown_title">Crown title</Label>
                                    <Input type="text" name="crown_title" required placeholder="Crown title" 
                                          onChange={this.onlevelFieldChange} autoComplete="off"/>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="crown_icon">Crown icon</Label>
                                    <Input type="file" name="crown_icon" onChange={this.onlevelFieldChange} required>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="unlock_offer">Unlock offer</Label>
                                    <Select
                                    name="unlock_offer"
                                    options={unlock_offer_options}
                                    value={unlock_offer}
                                    onChange={this.handleUnlockOffer} />
                                    {unlock_offer?.value == 'Unlock feature' ?
                                    <small style={{ color: 'red' }}>Coming soon</small>
                                    : null }
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                {unlock_offer?.value == 'Unlock Credits' ?
                                <FormGroup>
                                    <Label for="credits">Credits</Label>
                                    <Input type="number" name="credits" placeholder="Credits"/>
                                </FormGroup>
                                : unlock_offer?.value == 'Freebies' ?
                                <FormGroup>
                                    <Label for="freebie_name">Freebie Name</Label>
                                    <Input type="text" name="freebie_name" placeholder="Freebie Name"/>
                                </FormGroup>
                                : null }
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="unlock_feat_name">Unlock feature name</Label>
                                    <Input type="text" name="unlock_feat_name" placeholder="Unlock feature name" 
                                       onChange={this.onlevelFieldChange} autoComplete="off"/>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="unlock_feat_icon">Unlock feature icon</Label>
                                    <Input type="file" name="unlock_feat_icon" onChange={this.onlevelFieldChange}>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="term_and_conditions">Unlock feature terms & conditions</Label>
                                    <Input type="textarea" name="term_and_conditions" placeholder="Unlock feature terms & conditions"
                                        onChange={this.onlevelFieldChange} autoComplete="off" />
                                </FormGroup>
                            </Col>
                            
                        </Row>

                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" type="button" onClick={this.props.toggle}>Cancel</Button>
                        <Button color="primary" type="submit">Submit</Button>{' '}

                    </ModalFooter>
                </Form>
            </Modal >
        );

    }

    renderDeleteModal = () => {
        const { deleteModal } = this.state;

        return (
            <Modal isOpen={deleteModal} toggle={this.toggleDeleteModal} className="modal-body" centered={true}>
                <form className="theme-form" noValidate="" onSubmit={this.deleteCrownLevel}>
                    <ModalHeader><h5 style={{ fontSize: "1.15rem" }}>Delete Level</h5></ModalHeader>
                    <ModalBody>
                        <ModalBody style={{ padding: "16px 0" }}>
                            <h5 style={{ fontSize: "1.15rem" }}>Are you sure you want to delete this level ?</h5>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="default" onClick={this.toggleDeleteModal}>Not sure!</Button>
                            <Button color="primary" type="submit"  >Yes Please!</Button>
                        </ModalFooter>
                    </ModalBody>
                </form>
            </Modal>
        );
    }

    renderEditLevelModal = () => {
        const { crownPointLevels, level, unlock_offer } = this.state;
        return (
            <Modal isOpen={this.state.editModal} toggle={this.toggleEditModal}>
                <Form onSubmit={this.editLevelSubmitHandler}>
                    <ModalHeader toggle={this.toggleEditModal}>Edit Level</ModalHeader>
                    <ModalBody>

                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="level_title">Level title</Label>
                                    <Input type="text" name="level_title" required placeholder="Level title"
                                        onChange={this.onEditCrownLevel} value={level.level_title} />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="from_cust">From (no. of customer)</Label>
                                    <Input className="number" step="any" type="number" name="from_cust"
                                        required placeholder="From " onChange={this.onEditCrownLevel} value={level.from_cust} />
                                </FormGroup>
                            </Col>

                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="to_cust">To (no. of customer)</Label>
                                    <Input className="number" step="any" type="number" name="to_cust" required placeholder="To"
                                        onChange={this.onEditCrownLevel} value={level.to_cust} />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="points_per_cust">Points (per customer)</Label>
                                    <Input className="number" step="any" type="number" name="points_per_cust" placeholder="Points"
                                        onChange={this.onEditCrownLevel} value={level.points_per_cust} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="crown_title">Crown title</Label>
                                    <Input type="text" name="crown_title" required placeholder="Crown title"
                                        onChange={this.onEditCrownLevel} value={level.crown_title} />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="crown_icon">Crown icon</Label>
                                    <Input type="file" name="crown_icon" onChange={this.onEditCrownLevel}>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="unlock_offer">Unlock offer</Label>
                                    <Select
                                    name="unlock_offer"
                                    options={unlock_offer_options}
                                    value={unlock_offer_options.filter(item => item.value == level?.unlock_offer)}
                                    onChange={this.handleUnlockOffer} />
                                    {/* {level?.unlock_offer == 'Unlock feature' ?
                                    <small style={{ color: 'red' }}>Coming soon</small>
                                    : null } */}
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                {level?.unlock_offer == 'Unlock Credits' ?
                                <FormGroup>
                                    <Label for="credits">Credits</Label>
                                    <Input onChange={this.onEditCrownLevel} type="number" name="credits" placeholder="Credits" value={level.credits}/>
                                </FormGroup>
                                : level?.unlock_offer == 'Freebies' ?
                                <FormGroup>
                                    <Label for="freebie_name">Freebie Name</Label>
                                    <Input onChange={this.onEditCrownLevel} type="text" name="freebie_name" placeholder="Freebie Name" value={level.freebie_name}/>
                                </FormGroup>
                                : null }
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="unlock_feat_name">Unlock Offer name</Label>
                                    <Input type="text" name="unlock_feat_name" placeholder="Offer name"
                                        onChange={this.onEditCrownLevel} value={level.unlock_feat_name} />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="unlock_feat_icon">Unlock Offer icon</Label>
                                    <Input type="file" name="unlock_feat_icon" onChange={this.onEditCrownLevel}>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="term_and_conditions">Unlock feature terms & conditions</Label>
                                    <Input type="textarea" value={level.term_and_conditions} name="term_and_conditions" placeholder="Unlock feature terms & conditions"
                                        onChange={this.onEditCrownLevel} autoComplete="off" />
                                </FormGroup>
                            </Col>

                        </Row>

                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" onClick={this.toggleEditModal}>Cancel</Button>
                        <Button color="primary" type="submit">Save</Button>{' '}

                    </ModalFooter>
                </Form>
            </Modal >
        );

    }
    render() {
        const { crownPointsChangeInfo, crownPointLevels, level, isOpen, imageSrc, levelId, access_level } = this.state;
        // console.log(crownPointsChangeInfo);
        // console.log(crownPointLevels);
        // console.log(level);
        return (
            <Fragment>
                {levelId ? this.renderDeleteModal() : null}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-xl-12">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body" style={{ padding: "1rem" }}>
                                            <div>
                                                {/* {access_level != 1 && <button className="btn btn-outline-primary" onClick={this.toggle} style={{ marginBottom: "20px" }}><i className="fa fa-plus" style={{ margin: "0px 7px" }}></i> Add New Level </button>} */}
                                                {this.renderTable()}
                                                {isOpen && (
                                                    <Lightbox
                                                        mainSrc={imageSrc}
                                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                                    />
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {this.renderAddNewLevelModal()}
                        {this.state.level && this.renderEditLevelModal()}
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default MerchantCrownsPoints;