import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem, UncontrolledTooltip, NavLink as NavLinkTab } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";

import mastercard from "../../assets/images/cards/mastercard.png"
import visa from "../../assets/images/cards/visa.png";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";


class SavedCards extends Component {
  state = {
    blockModal: false,
    cardNumber: null,
    blocked: false,
    blockedCards: [],
    adminUser: JSON.parse(localStorage.getItem("user")),
    blockNote: null
  }

  refreshPage() {
    window.location.reload(false);
  }

  componentDidMount() {
    this.get_blocked_cards();
  }

  get_blocked_cards = () => {
    const postdata = {};
    client.post(api.getBlockedCards, postdata, (error, response) => {
      if (!error) {
        if (!response.error) {
          console.log(response);
          this.setState({ blockedCards: response.result.data })
        } else {
          setTimeout(() => {
            toast.error(" No cards found");
          }, 200);
        }
      } else {
        setTimeout(() => {
          toast.error("Internal error occured. Please contact support");
        }, 200);
      }
    })

  }

  blockCardHandler = (event) => {
    event.preventDefault();
    let postdata = {};
    let url = null;

    if (event.target.name == "unblock") {
      let { item } = this.props;

      url = api.unblockCard
      postdata = { card_number: this.state.cardNumber, card_id: item._id }
    } else {
      url = api.blockCard;
      postdata = { card_number: this.state.cardNumber, blockNote: this.state.blockNote, adminUser: this.state.adminUser.email }
    }

    client.post(url, postdata, (error, response) => {
      if (!error) {
        if (!response.error) {
          //console.log(response);
          this.refreshPage();
        } else {
          setTimeout(() => {
            toast.error(response.message);
          }, 200);
        }
      } else {
        setTimeout(() => {
          toast.error("Internal error occured. Please contact support");
        }, 200);
      }
    })
  }

  blockClick = (number) => {
    this.setState(prevState => {
      return {
        ...prevState,
        blockModal: !prevState.blockModal,
        cardNumber: number
      }
    });
  }

  toggleBlockModal = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        blockModal: !prevState.blockModal
      }
    })
  }



  onBlockNoteChange = (event) => {
    this.setState({ blockNote: event.target.value })

  }

  renderBlockModal = () => {
    const foundCard = this.state.blockedCards.find(el => {
      return el.card_number == this.state.cardNumber
    });
    const style = {
      outline: "none",
      ':focus': {
        outlineColor: "black"
      },
      fontSize: "1rem",
      padding: "2px 7px"
    };

    if (!foundCard) {
      return (
        <Modal isOpen={this.state.blockModal} toggle={this.toggleBlockModal} className="modal-body" centered={true}>
          <ModalBody>
            <form className="needs-validation" onSubmit={this.blockCardHandler}>
              <div style={{ fontSize: "3rem", textAlign: "center" }}>
                <i className="icofont icofont-exclamation-tringle" style={{ color: "red" }}></i>
              </div>
              <h5 style={{ textAlign: "center" }}>Are you sure you want to block the card for future transactions?</h5>
              <label className="form-label" style={{ display: "block" }}>Note:</label>
              <input type="text" size="40"
                style={style} placeholder="Please enter comment for your action" onChange={this.onBlockNoteChange}
                required></input>
              <Button color="secondary" style={{ marginTop: "20px", marginRight: "20px" }} onClick={this.toggleBlockModal}>Cancel</Button>
              <Button color="primary" style={{ marginTop: "20px", marginRight: "20px" }} name="block">Yes, Please!</Button>
            </form>
          </ModalBody>
        </Modal>
      );
    }
    return (
      <Modal isOpen={this.state.blockModal} toggle={this.toggleBlockModal} className="modal-body" centered={true}>
        <ModalBody>
          <h5>Are you sure you want to unblock card ?</h5>
          <Button color="secondary" style={{ marginTop: "20px", marginRight: "20px" }} onClick={this.toggleBlockModal}>Not Sure</Button>
          <Button color="primary" style={{ marginTop: "20px", marginRight: "20px" }} name="unblock" type="submit" onClick={this.blockCardHandler}>Yes, please!</Button>
        </ModalBody>
      </Modal>
    );

  }

  render() {
    //console.log(this.state.blockedCards);
    const { blocked } = this.state;
    let { item } = this.props;
    console.log(item)

    const foundBlockedCard = this.state.blockedCards.find(el => {
      return el.card_number == this.props.card_number
    })
    return (
      <div className="row" style={{ marginBottom:'20px' }}>
        <div className="col-xl-12">
          <div className="card" style={{ border: "1px solid black", boxSizing: "border-box", marginBottom: "5px" }}>

            {
              <div className="card-body d-flex justify-content-between" style={{ padding: "5 13px", }}>
                <div>
                  <span style={{ marginRight: "10px", fontSize: "1rem" }} onClick={() => this.blockClick(this.props.card_number)}>
                    <i style={{ color: foundBlockedCard ? "red" : "black" }} id="block" className="icofont icofont-ui-block" ></i>
                  </span>
                  {/* <input type="radio" style={{ marginRight: "15px" }}></input> */}
                  <img src={this.props.card_number.substring(0, 1) == 5 ? mastercard : visa} alt="payment-1" ></img>
                </div>
                <div>
                  <span>{this.props.card_number.substring(0, 6).replace(/(\d{4})(\d{2})/, "$1 $2")}** **** {this.props.card_number.substring(12)}</span>
                  <p style={{ marginBottom: "0" }}>{this.props.card_number.substring(0, 1) == 5 ? "Mastercard" : "Visa"}</p>
                  <p>Expiry:{this.props.card_expiry_month}/{this.props.card_expiry_year}</p>

                </div>
              </div>
            }


            <div style={{ alignItems: "center", borderTop: "1px solid black", color: 'red' }}>
              {item.refund_status == 1 &&
                <p style={{ marginBottom: 0, paddingLeft: 5, color: "green" }}>Refunded</p>
              }

              {item.status == 2 &&
                <div style={{ display: "inline-block", color: 'red', paddingLeft: "5px" }}>3 wrong attempts during card verification.</div>
              }
              {item.status == 1 &&
                <div style={{ display: "inline-block", color: 'green', paddingLeft: "5px" }}>Verified(${item.charge_amount}) {item.verified_date ? `on ${item.verified_date}` : ""}</div>
              }
              {item.status == 0 &&
                <div style={{ display: "inline-block", color: 'red', paddingLeft: "5px" }}>Pending Verification.</div>
              }
              <div style={{ display: "inline-block", float: "right", color: 'red', paddingRight: "20px" }}>{this.props.saveDate}</div>
            </div>
          </div>
          {
            this.props.lastTransaction && this.props.lastTransaction ?
              <div>
              <span style={{ marginLeft: '10px' }}>{`$${this.props.lastTransaction?.pay_amount} debited on ${this.props.lastTransaction?.transaction_date}`} </span>
              <span hidden={!this.props.lastTransaction.refund_data}>{`(Refunded on ${this.props.lastTransaction?.refund_data?.refund_date})`}</span> </div> 
              : null
          }
        </div>
        {this.state.cardNumber && this.renderBlockModal()}
      </div>
    );
  }
}

export default SavedCards;