import React, { Component, useEffect, useState } from "react";
import { getImageURL } from "../../Api/libDropbox";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Card, CardBody, Tooltip } from 'reactstrap';
import ContentCopyIcon from "mdi-react/ContentCopyIcon";

let RenderLinkView = (props) => {

    const {item} = props
    let [imgLink, setimgLink] = useState('');
    const [textCopy, setTextCopy] = useState(false);
    const [loading, setLoading] = useState(false);
    const [copybuttonstyle, setcopybuttonstyle] = useState({display: 'none'});

    const getLink = async () => {
        setLoading(true)
        let l = await getImageURL(props.item.path_lower)
        setimgLink(l)
        setLoading(false)

    }

    const RenderGetLink = () => {
        if (imgLink) {
            return (
                <div style={{ marginLeft: 20 }}>
                    <div className="qr-details__name">
                        <span style={{ color: "grey", textDecoration: "underline" }}>{imgLink}</span>
                        <CopyToClipboard text={imgLink}
                            onCopy={() => setTextCopy(true)}>
                            <span className="font-weight-bold" id="link-copy" style={{ cursor: "pointer", marginLeft : 5 }}><ContentCopyIcon /></span>
                        </CopyToClipboard>
                    </div>
                    <Tooltip placement="bottom" target="link-copy" isOpen={textCopy}>
                        Copied!
                    </Tooltip>
                </div>
                // <span>
                //     {imgLink}
                // </span>
            );
        } else {

            return (
                <div style={{ marginLeft: 20 }}>
                    {props.item.name}
                    {loading ?
                        <span style={{marginLeft: 10 }}>
                            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                        </span>
                        :
                        <button type="button" className="btn btn-xs" style={{...copybuttonstyle, marginLeft: 10 }} onClick={() => getLink()}>
                            Get Link
                        </button>
                    }
                </div>
            );
        }
    }

    return (
        <div style={{ display: "flex", alignItems: "center" }}
            onMouseEnter={e => {
                setcopybuttonstyle({ display: 'inline-block' });
            }}
            onMouseLeave={e => {
                setcopybuttonstyle({ display: 'none' })
            }}
        >
            <div className=" border p-0 mb-1 ml-0 mt-1"
                    style={{
                        height: '3.5rem',
                        width: '3.5rem',
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                }}>
                <div className="dropzone__input align-items-center">
                        <aside className="dropzone__img p-0">
                        {item[".tag"] == "folder" ?
                            <span>
                                <i className={item[".tag"] == "folder" ? "fa fa-folder" : "fa fa-file"} style={{ fontSize: '18px', color: 'rgb(146, 206, 255)', paddingRight: '4px' }}></i>
                                      
                            </span>
                            :
                
                            // <div className="dropzone__input align-items-center">
                            //     <aside className="dropzone__img p-0">
                            // {/* {JSON.stringify(item)} */}
                            <img src={item.url} className="img-fluid p-0" alt="drop-img" />
                       
                
                        }
                     </aside>
                    </div>
            </div>
            
            {item[".tag"] == "folder" ?
                <span style={{ marginLeft: 20 }}>
                    {item.name}
                </span>
                :
                RenderGetLink()
                //   <RenderGetLink item={item}></RenderGetLink>
            }
        </div>
    )
}

export default RenderLinkView;