import React, { useState } from 'react'
import { Col, Row, Table } from 'reactstrap';
import client from "../../Api/HTTPClient";
import { ToastContainer, toast } from "react-toastify";

import api from "../../constant/apilist";


function Sms_rates(props) {

    const {sms_rate_data,setsmsRateData}=props




    function UpdateMarginandTotal(data)
    {

        client.post(api.update_sms_data, JSON.stringify(data), async function (error, response) {
            if (!error) {
              
                setsmsRateData(response.result?.data)
            } else {
              toast.error("Internal error occured. Please contact support");
              // _showToast.error("Internal error occured. Please contact support.");
            }
            // current.setState({ loading: false });
      
          });
        

    }

    

    function ChangeMargin(e,i)

    {
        // const {}

        let marginValue=e.target.value.replace(/^\D.?\D$/g, '')
        if(marginValue=='' || marginValue=='.')
        {
            marginValue=''
        }

        setsmsRateData((prev)=>{
          return  prev.map((elem,ind)=>{
            if(ind==i)
            {
                return ({
                    ...elem,
                    margin:marginValue
                })
            }
            else{
                return elem
            }
               
            })
        })

       
    }



    return ( 
        <>
        <div className='container-fluid px-4 py-3'>
            <Row>
           
                <Col md={12}>
                <h5>
            SMS Rates
        </h5>
                </Col>
                <Col md={12}>
                <Table bordered className='sms-table mt-2'>

                    <thead>
                    <tr>
                        <th>Countries/Standard code</th>
                        <th>Burst sms rate (in AUD)</th>
                        <th>Our margin</th>
                        <th>Total</th>

                    </tr>
                   
                    </thead>

                    <tbody>

                        {
                            sms_rate_data.map((elem,i)=>{
                                return (
                                    <tr>
                                    <td>{elem.country} (+{elem.country_code})</td>
                              <td>{elem.charges}</td>
                              <td><input type="text" name="" value={elem.margin} onChange={e=>{ChangeMargin(e,i)}}
                              onBlur={()=>{

                                UpdateMarginandTotal(sms_rate_data[i])
                              }}
                              
                              /></td>
                                  <td>{elem.total.toFixed(3)}</td>
                                    </tr>

                                )
                            })
                        }

                   
                    </tbody>

                </Table>
                </Col>
            </Row>
       
            
        </div>
       
        </>
     );
}

export default Sms_rates;