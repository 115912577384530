import React, { Fragment } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import { Redirect, withRouter } from "react-router-dom";
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { TabContent, TabPane, Nav, NavItem, NavLink as NavLinkTab } from "reactstrap";
import { NavLink, Link } from "react-router-dom";
import VerifiedMerchantProfile from "./VerifiedMerchantProfile";
import {InviteUserProfile} from "./InviteUserProfile";
import transfer from "../../assets/images/money-transfer.png"
import HospoSafeRegistry from "./HospoSafeRegistry";
import VerifiedMerchantTransaction from "./VerifiedMerchantTransaction";
import VerifiedMerchantDocuments from "./VerifiedMerchantDocuments";
import Mbilling_setting from "./mbilling_setting";
import VerifiedMerchantSettlements from "./VerifiedMerchantSettlements";
import RewardHistory from "./RewardHistory";
import MerchantAdminNote from "./MerchantAdminNote";
import MerchantBills from "./MerchantBills";
import Offers from "../settings/CrownsPoints/Offers";
import GuardLink from "./GuardLink";
import Dinein from "./Dinein";
import MerchantQr from "./MerchantQr";
import { DisappearedLoading } from "react-loadingg";
import { POStab } from "./POStab";
import { Settingtab } from './SettingsTab'
import { Merchantsprofile } from "./Merchantsprofile";
import EnableFeatures from "./EnabledFeatures";

class VerifiedMerchantInfo extends React.Component {

    // this.toggle = this.toggle.bind(this);
    state = {
        error: null,
        isLoaded: false,
        merchant_id: this.props.merchant_id,
        merchant_info: this.props.merchant_info,
        wallet_info: [],
        transactions: [],
        subscriptions: [],
        invoices: [],
        stores_list: [],
        subUsers: [],
        tabIndex: 0,
        user_details: null,
        wlm_Subscriptions: null,
        //activeTab: "1",
        userCompanyList: [],
        userStorelist: [],
        merchant_user: this.props.merchant_user,
    };


    componentDidMount() {
        //console.log(this.props.merchant_info,'country code')
        //console.log(this.props.merchant_id);
        // console.log(this.props.location.search );
        /* if (this.props.location.search != "") {
            console.log(this.props.tabIndex);
            this.setState({tabIndex : this.props.tabIndex})
        } */
        this.getMerchantWalletInfo(this.state.merchant_id);
        this.fetch_merchant_users(this.state.merchant_id);
        // this.getinvoices(this.state.merchant_id);
        this.getinvoices(this.props.merchant_info?.adminId);
        this.getStoresList(this.props.merchant_info?.adminId);
        if (this.props.merchantDataType && this.props.merchantDataType.type == 'trashMerchant') { }
        else {
            this.getWLMSubscriptions(this.state.merchant_id);
            this.getSubscriptions(this.state.merchant_id);
        }

        this.getCompanyStore(this.props.merchant_info?.adminId);
        
        // this.getMerchantTransactions(this.state.merchant_id);

    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        // console.log(nextProps, prevState)
        if (nextProps.match.params.merchant_id != prevState.merchant_info?._id) {
            return {
                merchant_id: nextProps.match.params.merchant_id,
                merchant_info: nextProps.merchant_info

            }
        }
        if (nextProps.location.search != "") {
            let tab;
            const query = new URLSearchParams(nextProps.location.search);
            for (let params of query.entries()) {
                tab = +params[1]
            };

            return {
                tabIndex: tab

            }

        }
        /* if (nextProps.location.search != "") {
            return {
                tabIndex : nextProps.tabIndex

            }
        } */
    }

    fetch_merchant_users = (merchant_id) => {

        let postdata = {
            merchant_id: merchant_id,
            merchantDataType: this.props.merchantDataType.type
        };

    // console.log(this.state.merchant_user)


        client.post(api.fetch_merchant_users, postdata, (error, response) => {

            if (!error) {

                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            this.setState({
                                subUsers: response.result.subUsers,
                            })
                        }

                    } catch (e) {
                        // console.log(e);
                        setTimeout(() => {
                            toast.error("Internal error occured. Please contact support");
                        }, 200);
                    }
                } else {
                    setTimeout(() => {
                        toast.error("Internal error occured. Please contact support");
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }

        });
    };


    getMerchantWalletInfo = (merchant_id) => {

        let postdata = {
            merchant_id: merchant_id,
            merchantDataType: this.props.merchantDataType.type
        };

        client.post(api.merchant_wallet, postdata, (error, response) => {

            if (!error) {

                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            this.setState({
                                wallet_info: response.result.wallet_info,
                                merchant_info: response.result.merchant_info,
                                user_details: response.result.user_details,
                                // subUsers: response.result.subUsers,
                                // transactions: response.result.transactions.reverse()
                            })
                        } 

                    } catch (e) {
                        // console.log(e);
                        setTimeout(() => {
                            toast.error("Internal error occured. Please contact support");
                        }, 200);
                    }
                } else {
                    setTimeout(() => {
                        toast.error("Internal error occured. Please contact support");
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }

        });
    };


    getSubscriptions = (merchant_id) => {
        // let postdata = { merchant_id: merchant_id };
        // client.get(api.fetch_subscription, postdata, (error, response) => {
        //     // console.log(response, "response");
        //     if (!error) {

        //         if (typeof response.error !== "undefined") {
        //             try {
        //                 if (!response.error) {

        //                     this.setState({
        //                         subscriptions: response.merchant.subscription,
        //                     })
        //                 }

        //             } catch (e) {
        //                 console.log(e);
        //                 setTimeout(() => {
        //                     toast.error("Internal error occured. Please contact support");
        //                 }, 200);
        //             }
        //         } else {
        //             setTimeout(() => {
        //                 toast.error("Internal error occured. Please contact support");
        //             }, 200);
        //         }
        //     } else {
        //         setTimeout(() => {
        //             toast.error("Internal error occured. Please contact support");
        //         }, 200);
        //     }

        // });
    };

    getWLMSubscriptions = (merchant_id) => {
        // let postdata = { merchant_id: merchant_id };
        // client.get(api.fetch_wlm_subscription, postdata, (error, response) => {
        //     // console.log(response, "response");
        //     if (!error) {

        //         if (typeof response.error !== "undefined") {
        //             try {
        //                 if (!response.error) {

        //                     if (response.result != null) {
        //                         this.setState({
        //                             wlm_Subscriptions: { ...response.result.filter, store_name: response.result.storeName },
        //                         })
        //                     } else {
        //                         this.setState({
        //                             wlm_Subscriptions: null,
        //                         })
        //                     }
        //                 }

        //             } catch (e) {
        //                 console.log(e);
        //                 setTimeout(() => {
        //                     toast.error("Internal error occured. Please contact support");
        //                 }, 200);
        //             }
        //         } else {
        //             setTimeout(() => {
        //                 toast.error("Internal error occured. Please contact support");
        //             }, 200);
        //         }
        //     } else {
        //         setTimeout(() => {
        //             toast.error("Internal error occured. Please contact support");
        //         }, 200);
        //     }

        // });
    };


    getinvoices = (adminId) => {
        // let postdata = {
        //     // merchant_id: merchant_id,
        //     // merchantDataType: this.props.merchantDataType.type
        //     adminId: adminId
        // };
        // client.get(api.fetch_invoice, postdata, (error, response) => {
        //     // console.log(response, "invoie response")
        //     if (!error) {

        //         if (typeof response.error !== "undefined") {
        //             try {
        //                 if (!response.error) {
        //                     this.setState({ invoices: response.result, })
        //                 }

        //             } catch (e) {
        //                 console.log(e);
        //                 setTimeout(() => {
        //                     toast.error("Internal error occured. Please contact support");
        //                 }, 200);
        //             }
        //         } else {
        //             setTimeout(() => {
        //                 toast.error("Internal error occured. Please contact support");
        //             }, 200);
        //         }
        //     } else {
        //         setTimeout(() => {
        //             toast.error("Internal error occured. Please contact support");
        //         }, 200);
        //     }

        // });
    }

    getStoresList = (adminId) => {
        // let postdata = {
        //     admin_id: adminId,
        //     merchantDataType: this.props.merchantDataType.type
        // };
        // client.post(api.stores_list, postdata, (error, response) => {

        //     if (!error) {

        //         if (typeof response.error !== "undefined") {
        //             try {
        //                 if (!response.error) {

        //                     // console.log(response.result, "company details")

        //                     this.setState({
        //                         stores_list: response.result,
        //                     })
        //                 }

        //             } catch (e) {
        //                 console.log(e);
        //                 setTimeout(() => {
        //                     toast.error("Internal error occured. Please contact support");
        //                 }, 200);
        //             }
        //         } else {
        //             setTimeout(() => {
        //                 toast.error("Internal error occured. Please contact support");
        //             }, 200);
        //         }
        //     } else {
        //         setTimeout(() => {
        //             toast.error("Internal error occured. Please contact support");
        //         }, 200);
        //     }

        // });
    }

    getCompanyStore = (adminId) => {

        // let postdata = { admin_id: adminId }

        // client.post(api.fetch_all_stores, postdata, (error, response) => {

        //     if (!error) {

        //         if (typeof response.error !== "undefined") {
        //             try {
        //                 if (!response.error) {

        //                     this.setState({
        //                         userCompanyList: response.result.company,
        //                         userStorelist: response.result.merchants,
        //                     })
        //                 }

        //             } catch (e) {
        //                 console.log(e);
        //                 setTimeout(() => {
        //                     toast.error("Internal error occured. Please contact support");
        //                 }, 200);
        //             }
        //         } else {
        //             setTimeout(() => {
        //                 toast.error("Internal error occured. Please contact support");
        //             }, 200);
        //         }
        //     } else {
        //         setTimeout(() => {
        //             toast.error("Internal error occured. Please contact support");
        //         }, 200);
        //     }

        // });
    }

    render_date = (transaction_date) => {

        const convert_date = new Date(transaction_date)
        let added_date = new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }).format(convert_date)
        added_date = added_date.replace(",", ";");
        return added_date;

    }

    render() {
        const {
            merchant_id,
            merchant_info,
            subscriptions,
            invoices,
            transactions,
            wallet_info,
            tabIndex,
            wlm_Subscriptions,
            stores_list,
            userCompanyList,
            userStorelist,
            merchant_user
        } = this.state;
        // console.log(merchant_user, "merchant_user");
        // console.log(this.props, "this.props")
        return (

            <Fragment>
                {
                    merchant_id ?
                        <Fragment>
                            {
                                merchant_user ?
                                    <Fragment>
                                        <div className="container-fluid">
                                            <div className="row theme-tab">
                                                <Tabs className="col-sm-12" style={{ padding: "0", backgroundColor: "white", borderRadius: "3px", border: "1px solid rgb(223, 232, 241)", marginBottom: "0px" }} defaultIndex={this.state.tabIndex} onSelect={index => { this.setState({ tabIndex: index }); this.setState({ merchant_user: this.props.merchant_user }); }}>
                                                    <TabList className="tabs tab-title" style={{ display: "flex", alignItems: "center", marginBottom: "-10px !important" }}>

                                                        <Tab style={{ display: "flex", alignItems: "center" }} >
                                                            General
                                                        </Tab>

                                                       
                                                        

                                                        {/* {merchant_info.email_status == 0 && merchant_info.request_source == 2 || merchant_info.email_status == 0 && merchant_info.request_source != 2 ?
                                                    null : */}
                                                       
                                                        {/* } */}

                                                       


                                                      

                                                        
                                                        

                                                        

                                                       

                                                      

                                                        

                                                       

                                                       

                                                        

                                                        

                                                       

                                                        

                                                    </TabList>


                                                    <div className="tab-content-cls">
                                                    <TabPanel>
                                                            {/* <VerifiedMerchantProfile merchant_info={merchant_info} transactions={transactions} getMerchantWalletInfo={this.getMerchantWalletInfo}
                                                        merchant_id={merchant_id}></VerifiedMerchantProfile> */}
                                                            {
                                                                transactions ?
                                                                    <InviteUserProfile
                                                                        profiledata={{ merchant_id: merchant_id, merchant_info: merchant_info, transactions: transactions,merchant_user:merchant_user }}
                                                                        getMerchantWalletInfo={this.getMerchantWalletInfo}
                                                                        user_details={this.state.user_details}
                                                                        subUsers={this.state.subUsers}
                                                                        storesList={this.state.stores_list}
                                                                        merchantDataType={this.props.merchantDataType}
                                                                        merchant_user={this.state.merchant_user}
                                                                    />
                                                                    : null
                                                            }
                                                        </TabPanel>
                                                      </div>
                                                </Tabs>
                                            </div>
                                        </div>
                                        {/* <Nav tabs className="nav-pills nav-primary">
                            <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                                <NavLinkTab className={this.state.activeTab == '1' ? 'active nav-link' : ''} onClick={() => { this.toggle('1') }} value='1'>
                                    
                                                    Profile Info
                                                    </NavLinkTab>
                            </NavItem>
                            <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                                <NavLinkTab className={this.state.activeTab == '2' ? 'active nav-link' : ''} onClick={() => { this.toggle('2') }} value='2'>
                                    
                                                    Transaction History
                                                    </NavLinkTab>
                            </NavItem>
                            <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                                <NavLinkTab className={this.state.activeTab == '3' ? 'active nav-link' : ''} onClick={() => { this.toggle('3') }} value='3'>
                                    Reward History
                                                    </NavLinkTab>
                            </NavItem>
                            <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                                <NavLinkTab className={this.state.activeTab == '4' ? 'active nav-link' : ''} onClick={() => { this.toggle('4') }} value='4'>
                                    Hospo Registry
                                                    </NavLinkTab>
                            </NavItem>
                            <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                                <NavLinkTab className={this.state.activeTab == '5' ? 'active nav-link' : ''} onClick={() => { this.toggle('5') }} value='5'>
                                    Billings 
                                                    </NavLinkTab>
                            </NavItem>
                            <NavItem className="nav nav-tabs " id="myTab" role="tablist">
                                <NavLinkTab className={this.state.activeTab == '6' ? 'active nav-link' : ''} onClick={() => { this.toggle('6') }} value='6'>
                                    Documents
                                                    </NavLinkTab>
                            </NavItem>
                            <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                                <NavLinkTab className={this.state.activeTab == '7' ? 'active nav-link' : ''} onClick={() => { this.toggle('7') }} value='7'>
                                    Settlements
                                                    </NavLinkTab>
                            </NavItem>
                            <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                                <NavLinkTab className={this.state.activeTab == '8' ? 'active nav-link' : ''} onClick={() => { this.toggle('8') }} value='8'>
                                    
                                                    Admin Note
                                                    </NavLinkTab>
                            </NavItem>
                        </Nav>

                        <TabContent activeTab={this.state.activeTab} style={{ marginTop: "15px" }} >
                            <TabPane tabId="1">
                                <VerifiedMerchantProfile merchant_info={merchant_info}
                                    merchant_id={merchant_id}></VerifiedMerchantProfile>

                            </TabPane>
                            <TabPane tabId="2">
                                <VerifiedMerchantTransaction merchant_info={merchant_info}
                                    merchant_id={merchant_id}
                                ></VerifiedMerchantTransaction>


                            </TabPane>

                            <TabPane tabId="3">
                                <RewardHistory merchant_info={merchant_info} merchant_id={merchant_id}></RewardHistory>


                            </TabPane>
                            <TabPane tabId="4">
                                <HospoSafeRegistry merchant_info={merchant_info}
                                    merchant_id={merchant_id}></HospoSafeRegistry>


                            </TabPane>
                            <TabPane tabId="5">
                            
                                <Mbilling_setting  merchant_info={merchant_info}
                                    merchant_id={merchant_id}/>

                            </TabPane>
                            <TabPane tabId="6">
                                <VerifiedMerchantDocuments merchant_info={merchant_info}
                                    merchant_id={merchant_id}></VerifiedMerchantDocuments>


                            </TabPane>
                            <TabPane tabId="7">
                                <VerifiedMerchantSettlements merchant_id={merchant_id}></VerifiedMerchantSettlements>
                            </TabPane>
                            <TabPane tabId="8">
                                <MerchantAdminNote merchant_info={merchant_info}
                                    merchant_id={merchant_id}></MerchantAdminNote>
                            </TabPane>
                        </TabContent> */}
                                    </Fragment>

                                    : <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                                        <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                                            <DisappearedLoading size="medium" color="#514F4E" />
                                        </div>
                                    </div>}
                        </Fragment> : <Redirect to={`${process.env.PUBLIC_URL}/merchants/merchant_requests`} />
                }
            </Fragment>
        );
    }
}

export default withRouter(VerifiedMerchantInfo);
