import React, { useState, useEffect } from 'react';
import {
    Button,
    ButtonToolbar,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Table
} from 'reactstrap';
import moment from 'moment';
import { withRouter } from "react-router";
import { toast, ToastContainer } from 'react-toastify';
import "./MerchantInvoiceDetailsCss.scss"
import HTTPClient from "../../../Api/HTTPClient"
import api from "../../../constant/apilist"
import BeatLoader from "react-spinners/BeatLoader";


const InvoiceDetails = (props) => {

    const { setShowInvoice, invoiceID, selectedWLMData } = props
    const [loading, setLoading] = useState(false);
    const [invoiceFrom, setinvoiceFrom] = useState(null);
    const [whiteLabelInvoice, setWhiteLabelInvoice] = useState(false);
    const [invoiceData, setInvoiceData] = useState(null);


    const fetchInvoiceData = () => {

        const white_Invoice_id = invoiceID;
        setLoading(true)
        HTTPClient.get(api.fetch_invoice, { white_Invoice_id }, async function (error, response) {

            if (!error) {
                if (!response.error) {
                    setinvoiceFrom(response.invoice_from);
                    setInvoiceData(response.result);
                    setLoading(false)
                } else {
                    toast.error("Internal error occured.Please contact support");
                    setLoading(false)
                }
            }
            else {
                toast.error("Internal error occured.Please contact support");
                setLoading(false)
            }
        })

    }

    useEffect(() => {

        if (!invoiceData) {
            fetchInvoiceData();
        }

    }, [])

    useEffect(() => {
        if (invoiceData) {
            let getData = invoiceData.invoice_title.includes("White label Enterprise plan");
            if (getData == true) {
                setWhiteLabelInvoice(true);
            } else {
                setWhiteLabelInvoice(false)
            }
        }

    }, [invoiceData]);

    return (
        <Card className='merchantInvoice'>
            {
                !loading ?
                    <CardBody>
                        {invoiceData && <Container style={{ marginTop: "20px" }}>
                            <Row>
                                <Col md={12}>
                                    <div className='text-right'>

                                        {/* <span className="mr-4 font-weight-bold" style={{ cursor: 'pointer', color: 'gray' }}>
                                    <Icon path={mdiDownload} size={1.2} /> PDF</span> */}
                                        <Button
                                            className='my-0'
                                            type='button'
                                            size='sm'
                                            color='primary'
                                            outline

                                            onClick={() => {
                                                // history.goBack();
                                                setShowInvoice(false)
                                            }
                                            }
                                        > Go Back </Button>

                                    </div>
                                    <Row className='pt-4 pb-3'>
                                        <Col md={12}>
                                            <div className='border rounded p-4'>
                                                <Row>
                                                    <Col md={6} className='d-flex align-items-center justify-content-start'>
                                                        <div
                                                            className="invoice__logo"
                                                            style={{
                                                                height: '50px',
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col md={6} className='d-flex align-items-center justify-content-end'>
                                                        <div>
                                                            <p className='invoice__grand-total' > Invoice #{invoiceData.invoice_number} </p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className='py-3 border-top'>
                                                    <Col md={8} className='text-left'>
                                                        <div>
                                                            <p className='my-0'> {invoiceFrom?.business_name} </p>
                                                            <p className='my-0'>
                                                                {/* {invoiceFrom.address.split(',').map((line, i) => (
                                                            <span key={i}>
                                                                {line}
                                                                <br />
                                                            </span>
                                                        ))
                                                        } */}
                                                                {invoiceFrom?.address}
                                                            </p>
                                                            {invoiceFrom?.abn != "" &&
                                                                <p dir="ltr" className='my-0'> ABN - {invoiceFrom?.abn} </p>
                                                            }
                                                            {invoiceFrom?.website != "" &&
                                                                <a href={invoiceFrom?.website} target='_blank'> {invoiceFrom?.website} </a>
                                                            }
                                                            {/* <a href='https://www.store.com' target='_blank'> www.store.com </a> */}
                                                        </div>
                                                    </Col>
                                                    <Col md={4} className='text-right'>
                                                        <div>
                                                            <p className="invoice__date my-0"> Date/Time : {moment(invoiceData.payment_date).format('DD-MM-YYYY/H:mm')} </p>
                                                            <p className='my-0' style={{ textTransform: "capitalize" }}> {invoiceData?.fk_merchant_id?.business_name} </p>
                                                            {/* <p className='my-0'> {invoiceData?.fk_merchant_id?.address} </p>
                                                     */}
                                                            <p className='my-0'>
                                                                {/* {invoiceData?.fk_merchant_id?.address.split(',').map((line, i) => (
                                                            <span key={i}>
                                                                {line}
                                                                <br />
                                                            </span>
                                                        ))
                                                        } */}
                                                                {invoiceData?.fk_merchant_id?.address}
                                                            </p>
                                                            {/* <p className='my-0'> Australia </p> */}
                                                            {invoiceData?.fk_merchant_id?.country?.code == "AU" && invoiceData?.fk_merchant_id?.company_id?.abn && invoiceData?.fk_merchant_id?.company_id?.abn != "" &&
                                                                <p dir="ltr" className='my-0'> ABN - {invoiceData?.fk_merchant_id?.company_id?.abn} </p>
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className='py-3'>
                                                    <Col md={12}>
                                                        <Table className="table--bordered" responsive striped>
                                                            <thead>
                                                                <tr>
                                                                    <th> # </th>
                                                                    <th> Plan </th>
                                                                    {whiteLabelInvoice ? null : <th> Quantity </th>}
                                                                    {whiteLabelInvoice ? null : <th> Unit Cost </th>}
                                                                    <th> Total </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    <tr>
                                                                        <td>1</td>
                                                                        <td>{`${invoiceData.invoice_title}  ${whiteLabelInvoice && invoiceData.no_of_stores ? `  (${invoiceData.no_of_stores} Stores)` : ""}`}</td>
                                                                        {whiteLabelInvoice ? null : <td>1</td>}
                                                                        {whiteLabelInvoice ? null : <td>{selectedWLMData?.country.currency_symbol}{invoiceData.sub_total.toFixed(2)}</td>}
                                                                        <td>{selectedWLMData?.country.currency_symbol}{invoiceData.sub_total.toFixed(2)}</td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col md={12} className='text-right'>
                                                        <div className="invoice__total">
                                                            <p> Sub - total amount: {selectedWLMData?.country.currency_symbol}{(invoiceData.sub_total).toFixed(2)} </p>
                                                            {invoiceData.discount > 0 ? (<p> Discount: {selectedWLMData?.country.currency_symbol}{(invoiceData.discount).toFixed(2)} </p>) : ('')}
                                                            {invoiceData?.promo_code_discount > 0 && <p>Promo code :{selectedWLMData?.country.currency_symbol}{(invoiceData.promo_code_discount).toFixed(2)}</p>}

                                                            {invoiceData.charges && invoiceData.charges.map((item) =>
                                                                <p> {item.title}: {selectedWLMData?.country.currency_symbol}{(item.amount).toFixed(2)} </p>
                                                            )}
                                                            <p className="invoice__grand-total"> Grand Total: {selectedWLMData?.country.currency_symbol}{(invoiceData.total).toFixed(2)} </p>
                                                            <ButtonToolbar className="invoice__toolbar">
                                                                {/* <Button color="primary"> Proceed to payment </Button> */}
                                                                {/* <Button> Print </Button> */}
                                                            </ButtonToolbar>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>}
                    </CardBody>
                    :
                    <div className='loaderDiv'>
                        <BeatLoader
                            color={"#327EBC"}
                            loading={true}
                            size="15"
                        />
                    </div>
            }
        </Card>
    )
}

export default withRouter(InvoiceDetails);
