import React, { useEffect, useState, usep } from 'react';
import useForm from 'react-hook-form'
import Breadcrumb from '../../components/common/breadcrumb';
import "./style.css"
import { Link, useHistory } from "react-router-dom";
import { Button, Input } from 'reactstrap';
import DatePicker from "react-datepicker";
import moment from "moment";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from 'react-router';
import { DisappearedLoading } from "react-loadingg";
import Select from "react-select";

let DatePickerInput = ({ value, onClick }) => {
    return (
        <Input type="text" defaultValue={value} onFocus={onClick} onClick={onClick} />
    );
}

const TooltipForm = () => {

    const history = useHistory();
    const { subscription_id } = useParams();

    let [loadingbtn, setloadingbtn] = useState(false);
    const [start_date, setStartDate] = useState(null);
    const [end_date, setEndDate] = useState(null);
    let [showerrorstatus, setshowerrorstatus] = useState(false);
    const { register, handleSubmit, errors, setValue, getValues } = useForm();
    const [validateClass, setValidateClass] = useState(false);
    const [nor_readonly, setnor_readonly] = useState(false);
    const [spd_readonly, setspd_readonly] = useState(false);
    const [nou_readonly, setnou_readonly] = useState(false);
    const [rgst_readonly, setRgst_readonly] = useState(false);
    const [ls_readonly, setLs_readonly] = useState(false)
    const [period, setperiod] = useState(null);
    const [features, setfeatures] = useState([]);

    let [result, setResult] = useState(null);
    console.log(result, "====>")

    useEffect(() => {

        client.get(api.fetch_subscription, { subscription_id }, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            setResult(response.result)
                            try {
                                if (response.result.promo_end_date)
                                    setEndDate(moment(response.result.promo_end_date).toDate())
                            } catch { }
                            setnor_readonly(response.result.unlimited_registration);
                            setspd_readonly(response.result.unlimited_sales);
                            setnou_readonly(response.result.unlimited_users);
                            setRgst_readonly(response.result.unlimited_vregistries);
                            setLs_readonly(response.result.unlimited_linkyScan);
                            try {
                                if (response.result.is_trial) {
                                    setperiod(response.periods[0])
                                }

                            } catch {

                            }

                            //   history.push('/subscriptions');
                            //   toast.success("Added Successfully");
                            // setcashbackdata(response.result.data);
                        } else {
                            toast.error(response.message);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }

            setloadingbtn(false);
            fetchfeatures();
        });

    }, [])
    // console.log(period, result, "pr")

    const fetchfeatures = () => {

        client.get(api.fetch_features, { activeall: true }, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            setfeatures(response.result)
                        } else {
                        }
                    } catch (e) {
                    }
                } else {
                }
            } else {
            }

            setloadingbtn(false);
        });

    }

    const onSubmit = data => {


        if (data != '') {
            if (data.description.length <= 50) {

                // if (!moment(start_date).isValid()) {
                //   return false;
                // }
                // console.log(data.promo_rate, "data.promo_rate")
                if (data.promo_rate > 0 && !moment(end_date).isValid()) {
                    return false;
                }

                data.start_date = start_date;
                data.end_date = end_date;
                data.subscription_id = subscription_id;

                setloadingbtn(true);
                // console.log(data, "Data")

                // return false;

                client.post(api.update_subscription, data, async function (error, response) {
                    // console.log(data, "Data")
                    if (!error) {
                        if (typeof response.error !== "undefined") {
                            try {
                                if (!response.error) {
                                    // console.log(response, "response")
                                    history.push('/subscriptions');
                                    toast.success("updated Successfully");
                                    // setcashbackdata(response.result.data);
                                } else {
                                    toast.error(response.message);
                                }
                            } catch (e) {
                                toast.error("Internal error occured. Please contact support");
                            }
                        } else {
                            toast.error("Internal error occured. Please contact support");
                        }
                    } else {
                        toast.error("Internal error occured. Please contact support");
                    }

                    setloadingbtn(false);
                });
            } else {
                toast.error("Description max limit 50 characters")
            }

            // alert('You submitted the form and stuff!');
        } else {
            setshowerrorstatus(true)
            errors.showMessages();
        }


    };


    return (
        <>
            <Breadcrumb title="Add subscription" parent="Home" />

            <div className="form-container" >
                <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                    <div className="card countryDetails">
                        {/* <div className="card-header b-l-primary">
              <h3>subscription </h3>
            </div> */}
                        <div className="card-body">
                            {!result ?

                                <div className="loader-box" style={{}}>
                                    <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                                        <DisappearedLoading size="medium" color="#514F4E" />
                                    </div>
                                </div>
                                :
                                <div>
                                    <div className="form-group form-row">
                                        <div className="col-md-6 mb-6">
                                            <label htmlFor="">Title Of Subscription</label>
                                            <input className="form-control" defaultValue={result.title} name="title" disabled={result?.is_free === true ? true : false} type="text" ref={register({ required: true })} />
                                            <span>{errors.title && 'This field is required'}</span>
                                            <div className="valid-feedback">Looks good!</div>
                                        </div>
                                        <div className="col-md-6 mb-6">
                                            <label htmlFor="">Sub Title</label>
                                            <input className="form-control" defaultValue={result.description}
                                                name="description" type="text"
                                                ref={register({ required: 'This field is required', maxLength: 50 })} />

                                            {/* <textarea className="form-control textarea" name="description" rows="3" cols="50" placeholder="" ref={register({ required: true })}></textarea> */}
                                            {/* <span style={{ color: '#ff5370' }}>{errors.description && 'This field is required'}</span> */}
                                            <span style={{ color: '#ff5370' }}>{errors?.description?.message && errors?.description?.message}</span>
                                            {errors.description?.type == 'maxLength' && <span style={{ color: '#ff5370' }}>Subtitle should be less than 50 chars</span>}
                                            {/* <div className="valid-feedback">Looks good!</div> */}
                                        </div>


                                    </div>
                                    <div className="form-group form-row">
                                        <div className="col-md-6 mb-6">
                                            <label htmlFor="">Subscription Fee</label>
                                            <input className="form-control" id="" step={0.01} disabled={result.is_trial === true || result?.is_free === true ? true : false} defaultValue={result.fees} name="fee" onWheel={() => document.activeElement.blur()} type="number" ref={register({ required: true })} />
                                            <span>{errors.fee && 'This field is required'}</span>
                                            {/* <div className="invalid-feedback">Please provide a valid city.</div> */}
                                        </div>
                                        {!(result.is_trial === true || result?.is_free === true ? true : false) &&
                                            <>
                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="">Promotional Rate</label>
                                                    <input className="form-control" id="" step={0.01} defaultValue={result.promo_rate} name="promo_rate" onWheel={() => document.activeElement.blur()} type="number" ref={register({ required: false })} />
                                                    <span>{errors.promo_rate && 'This field is required'}</span>
                                                </div>

                                                <div className="col-md-3 mb-3">
                                                    <label htmlFor="validationTooltip05">End Date</label>
                                                    <DatePicker
                                                        customInput={<DatePickerInput />}
                                                        selected={end_date}
                                                        minDate={new Date()}
                                                        onChange={date => setEndDate(date)
                                                        }
                                                    />
                                                    <span style={{ color: '#ff5370' }}>{(showerrorstatus == true && !moment(end_date).isValid()) ? 'Select valid date' : ""}</span>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {result.is_trial &&
                                        <div className="form-group form-row">
                                            <div className="col-md-3 mb-3">
                                                <label htmlFor="">Trial Period</label>
                                                <input className="form-control" id="" defaultValue={period?.period} name="trial_period" onWheel={() => document.activeElement.blur()} type="number" ref={register({ required: true })} />
                                                <span>{errors.trial_period && 'This field is required'}</span>
                                                {/* <div className="invalid-feedback">Please provide a valid city.</div> */}
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <label htmlFor="">Period Type</label>
                                                <select className="form-control" name="period_type" size="1" ref={register({ required: true })}>
                                                    <option value="1" selected={period?.period_type == 1 ? true : false}>Days</option>
                                                    <option value="3" selected={period?.period_type == 3 ? true : false}>Weeks</option>
                                                    <option value="2" selected={period?.period_type == 2 ? true : false}>Months</option>
                                                </select>
                                            </div>
                                        </div>
                                    }
                                    <h5>Choose Features</h5>
                                    <div className="form-group form-row">
                                        <div className="col-4 mb-3 d-flex align-items-center">
                                            <div>
                                                <label htmlFor="">Sales Per Day</label>
                                                <input readOnly={spd_readonly} defaultValue={result.sales_per_day} className="form-control" name="sales_per_day" onWheel={() => document.activeElement.blur()} type="number" ref={register({ required: true })} />
                                                <span>{errors.sales_per_day && 'This field is required'}</span>
                                            </div>
                                            <div>
                                                <label htmlFor=""></label>
                                                <div className="form-check">
                                                    <div className="checkbox p-0">
                                                        <input className="form-check-input" name="unlimited_sales_per_day" onChange={() => {
                                                            setValue('sales_per_day', '0')
                                                            setspd_readonly(!spd_readonly)

                                                        }} id="check_sales_per_day" defaultChecked={result.unlimited_sales} type="checkbox" ref={register({ required: false })} />
                                                        <label className="form-check-label" htmlFor="check_sales_per_day">Unlimited</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-4 mb-3 d-flex align-items-center">
                                            <div>
                                                <label htmlFor="">Number Of Registers</label>
                                                <input readOnly={nor_readonly} defaultValue={result.number_of_registers} className="form-control" name="number_of_registers" onWheel={() => document.activeElement.blur()} type="number" ref={register({ required: true })} />
                                                <span>{errors.number_of_registers && 'This field is required'}</span>
                                            </div>
                                            <div>
                                                <label htmlFor=""></label>
                                                <div className="form-check">
                                                    <div className="checkbox p-0">
                                                        <input className="form-check-input" defaultChecked={result.unlimited_registration} onChange={() => {
                                                            setValue('number_of_registers', '0')
                                                            setnor_readonly(!nor_readonly)
                                                        }} name="unlimited_number_of_registers" id="check_number_of_registers" type="checkbox" ref={register({ required: false })} />
                                                        <label className="form-check-label" htmlFor="check_number_of_registers">Unlimited</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-4 mb-3 d-flex align-items-center">
                                            <div>
                                                <label htmlFor="">Number of users</label>
                                                <input readOnly={nou_readonly} defaultValue={result.number_of_users} className="form-control" name="number_of_users" onWheel={() => document.activeElement.blur()} type="number" ref={register({ required: true })} />
                                                <span>{errors.number_of_users && 'This field is required'}</span>
                                            </div>
                                            <div>
                                                <label htmlFor=""></label>
                                                <div className="form-check">
                                                    <div className="checkbox p-0">
                                                        <input className="form-check-input" name="unlimited_users" onChange={() => {
                                                            setValue('number_of_users', '0')
                                                            setnou_readonly(!nou_readonly)

                                                        }} id="check_number_of_users" defaultChecked={result.unlimited_users} type="checkbox" ref={register({ required: false })} />
                                                        <label className="form-check-label" htmlFor="check_number_of_users">Unlimited</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-4 mb-3 d-flex align-items-center">
                                            <div>
                                                <label htmlFor=""> Check-in registry per day </label>
                                                <input readOnly={rgst_readonly} defaultValue={result.number_of_vregistries} className="form-control" name="number_of_vregistries" onWheel={() => document.activeElement.blur()} type="number" ref={register({ required: true })} />
                                                <span>{errors.number_of_vregistries && 'This field is required'}</span>
                                            </div>
                                            <div>
                                                <label htmlFor=""></label>
                                                <div className="form-check">
                                                    <div className="checkbox p-0">
                                                        <input className="form-check-input" name="unlimited_vregistries" onChange={() => {
                                                            setValue('number_of_vregistries', '0')
                                                            setRgst_readonly(!rgst_readonly)

                                                        }} id="check_number_of_vregistries" defaultChecked={result.unlimited_vregistries} type="checkbox" ref={register({ required: false })} />
                                                        <label className="form-check-label" htmlFor="check_number_of_vregistries">Unlimited</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 mb-3 d-flex align-items-center">
                                            <div>
                                                <label htmlFor=""> Number of scans in linky scan </label>
                                                <input readOnly={ls_readonly} defaultValue={result.number_of_linkyScan} className="form-control" name="number_of_linkyScan" onWheel={() => document.activeElement.blur()} type="number" ref={register({ required: true })} />
                                                <span>{errors.number_of_linkyScan && 'This field is required'}</span>
                                            </div>
                                            <div>
                                                <label htmlFor=""></label>
                                                <div className="form-check">
                                                    <div className="checkbox p-0">
                                                        <input className="form-check-input" name="unlimited_linkyScan" onChange={() => {
                                                            setValue('number_of_linkyScan', '0')
                                                            setLs_readonly(!ls_readonly)

                                                        }} id="check_number_of_linkyScan" defaultChecked={result.unlimited_linkyScan} type="checkbox" ref={register({ required: false })} />
                                                        <label className="form-check-label" htmlFor="check_number_of_linkyScan">Unlimited</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    {/* <div className="form-group">
                                        <div className="col-md-12 mb-12 row">
                                            <div className="form-check">
                                            <div className="checkbox p-0">
                                                <input className="form-check-input" defaultChecked={result.is_trial} name="is_trial" id="is_trial" type="checkbox" ref={register({ required: false })}/>
                                                <label className="form-check-label" htmlFor="is_trial">Is Trial</label>
                                            </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="form-group">
                                        <div className="col-md-12 mb-12 row">
                                            {/* <div className="form-check">
                                                <div className="checkbox p-0">
                                                    <input className="form-check-input" defaultChecked={result.multiuser} name="multiuser" id="checkbox_multiuser" type="checkbox" ref={register({ required: false })} />
                                                    <label className="form-check-label" htmlFor="checkbox_multiuser">Multiuser</label>
                                                </div>
                                            </div> */}
                                            <div className="form-check">
                                                <div className="checkbox p-0">
                                                    <input className="form-check-input" defaultChecked={result.online_orders} name="online_orders" id="checkbox_online_orders" type="checkbox" ref={register({ required: false })} />
                                                    <label className="form-check-label" htmlFor="checkbox_online_orders">
                                                        Online Ordering System
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-check">
                                                <div className="checkbox p-0">
                                                    <input className="form-check-input" defaultChecked={result.table_reservation} name="table_reservation" id="checkbox_table_reservation" type="checkbox" ref={register({ required: false })} />
                                                    <label className="form-check-label" htmlFor="checkbox_table_reservation">
                                                        {/* Table Reservation */}
                                                        Bookings & Reservations
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-check">
                                                <div className="checkbox p-0">
                                                    <input className="form-check-input" defaultChecked={result.offers_vouchers} name="offers_vouchers" id="checkbox_offers_vouchers" type="checkbox" ref={register({ required: false })} />
                                                    <label className="form-check-label" htmlFor="checkbox_offers_vouchers">Offers & vouchers</label>
                                                </div>
                                            </div>
                                            <div className="form-check">
                                                <div className="checkbox p-0">
                                                    <input className="form-check-input" defaultChecked={result.loyalty_cards} name="loyalty_cards" id="checkbox_loyalty_cards" type="checkbox" ref={register({ required: false })} />
                                                    <label className="form-check-label" htmlFor="checkbox_loyalty_cards">Loyalty Cards</label>
                                                </div>
                                            </div>
                                            <div className="form-check">
                                                <div className="checkbox p-0">
                                                    <input className="form-check-input" defaultChecked={result.payment_links} name="payment_links" id="checkbox_payment_links" type="checkbox" ref={register({ required: false })} />
                                                    <label className="form-check-label" htmlFor="checkbox_payment_links">Payment Links</label>
                                                </div>
                                            </div>
                                            <div className="form-check">
                                                <div className="checkbox p-0">
                                                    <input className="form-check-input" defaultChecked={result.invoice_quote} name="invoice_quote" id="checkbox_invoice_quote" type="checkbox" ref={register({ required: false })} />
                                                    <label className="form-check-label" htmlFor="checkbox_invoice_quote">Invoices & Quotes</label>
                                                </div>
                                            </div>
                                            <div className="form-check">
                                                <div className="checkbox p-0">
                                                    <input className="form-check-input" defaultChecked={result.price_list} name="price_list" id="checkbox_price_list" type="checkbox" ref={register({ required: false })} />
                                                    <label className="form-check-label" htmlFor="checkbox_price_list">Price list</label>
                                                </div>
                                            </div>
                                            <div className="form-check">
                                                <div className="checkbox p-0">
                                                    <input className="form-check-input" defaultChecked={result.remove_logo} name="remove_logo" id="checkbox_remove_logo" type="checkbox" ref={register({ required: false })} />
                                                    <label className="form-check-label" htmlFor="checkbox_remove_logo">Remove the Linky logo</label>
                                                </div>
                                            </div>
                                            <div className="form-check">
                                                <div className="checkbox p-0">
                                                    <input className="form-check-input" defaultChecked={result.tips_reviews} name="tips_reviews" id="checkbox_tips_reviews" type="checkbox" ref={register({ required: false })} />
                                                    <label className="form-check-label" htmlFor="checkbox_tips_reviews">Tips & Reviews</label>
                                                </div>
                                            </div>
                                            <div className="form-check">
                                                <div className="checkbox p-0">
                                                    <input className="form-check-input" defaultChecked={result.call_waiter} name="call_waiter" id="checkbox_call_waiter" type="checkbox" ref={register({ required: false })} />
                                                    <label className="form-check-label" htmlFor="checkbox_call_waiter">Call Waiter</label>
                                                </div>
                                            </div>
                                            <div className="form-check">
                                                <div className="checkbox p-0">
                                                    <input className="form-check-input" defaultChecked={result.clean_table} name="clean_table" id="checkbox_clean_table" type="checkbox" ref={register({ required: false })} />
                                                    <label className="form-check-label" htmlFor="checkbox_clean_table">Clean Table</label>
                                                </div>
                                            </div>
                                            <div className="form-check">
                                                <div className="checkbox p-0">
                                                    <input className="form-check-input" defaultChecked={result.customize_themes_fonts} name="customize_themes_fonts" id="checkbox_customize_themes_fonts" type="checkbox" ref={register({ required: false })} />
                                                    <label className="form-check-label" htmlFor="checkbox_customize_themes_fonts">Customizable themes & fonts</label>
                                                </div>
                                            </div>
                                            <div className="form-check">
                                                <div className="checkbox p-0">
                                                    <input className="form-check-input" defaultChecked={result.sell_ticket} name="sell_ticket" id="checkbox_sell_ticket" type="checkbox" ref={register({ required: false })} />
                                                    <label className="form-check-label" htmlFor="checkbox_sell_ticket">Events & Tickets</label>
                                                </div>
                                            </div>

                                            <div className="form-check">
                                                <div className="checkbox p-0">
                                                    <input className="form-check-input" defaultChecked={result.linky_meet} name="linky_meet" id="linky_meet" type="checkbox" ref={register({ required: false })} />
                                                    <label className="form-check-label" htmlFor="linky_meet">Linky Meet</label>
                                                </div>
                                            </div>

                                            <div className="form-check">
                                                <div className="checkbox p-0">
                                                    <input className="form-check-input" defaultChecked={result.review_booster} name="review_booster" id="review_booster" type="checkbox" ref={register({ required: false })} />
                                                    <label className="form-check-label" htmlFor="review_booster">Review Booster</label>
                                                </div>
                                            </div>

                                            <div className="form-check">
                                                <div className="checkbox p-0">
                                                    <input
                                                        className="form-check-input"
                                                        defaultChecked={result.xero_integration}
                                                        name="xero_integration"
                                                        id="xero_integration"
                                                        type="checkbox"
                                                        ref={register({ required: false })}
                                                    />
                                                    <label className="form-check-label" htmlFor="xero_integration">Xero Integration</label>
                                                </div>
                                            </div>

                                            <div className="form-check">
                                                <div className="checkbox p-0">
                                                    <input
                                                        className="form-check-input"
                                                        defaultChecked={result.quick_sms}
                                                        name="quick_sms"
                                                        id="quick_sms"
                                                        type="checkbox"
                                                        ref={register({ required: false })}
                                                    />
                                                    <label className="form-check-label" htmlFor="quick_sms">Quick SMS</label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <br />
                                    <h5>Select Descriptive Features</h5>
                                    <div className="form-group">
                                        <div className="col-md-12 mb-12 row">
                                            {features && features.length > 0 ? features.map((item, index) => {
                                                if (item.descriptive_feature)
                                                    return (
                                                        <div className="form-check">
                                                            <div className="checkbox p-0">
                                                                <input className="form-check-input" defaultChecked={result.descriptive_features.indexOf(item.title) !== -1 ? true : false} name="multifeature" id={"checkbox_multifeature" + index} type="checkbox" value={item.title} ref={register({ required: false })} />
                                                                <label className="form-check-label" htmlFor={"checkbox_multifeature" + index}>{item.title}</label>
                                                            </div>
                                                        </div>
                                                    )
                                            }) : null}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="">
                                        {!loadingbtn ?
                                            <>
                                                <Link to={`${process.env.PUBLIC_URL}/subscriptions`}>
                                                    <Button color="default" type="button">Cancel</Button>
                                                </Link>
                                                <Button color="primary" className="pull-right" type="submit" style={{ marginLeft: "2rem" }}>Save</Button>
                                            </>
                                            :
                                            <div className="loader-box pull-right" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 20, width: 20 }}></span></div>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default TooltipForm;