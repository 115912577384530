import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect, NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import * as devices from "react-device-detect";
import ipLocation from "iplocation";
import publicIp from "public-ip";
import { Modal, Button, Spinner } from "react-bootstrap";
// import axios from "axios";

import Breadcrumb from "../components/common/breadcrumb";
import client from "../Api/HTTPClient";
import api from "../constant/apilist";
// import logo from "../assets/images/ipay-logo-40-40.png";
import app from "../data/base";
import * as actionCreators from "../store/actions/index";
import VerifyOtpResetPwd from "./VerifyOtpResetPwd";
import BeatLoader from "react-spinners/BeatLoader";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Logo from "../assets/images/blue_linky_logo.svg";


class Login extends React.Component {
	state = {
		email: "",
		password: "",
		emailid: "",
		loggedIn: null,
		error: "",
		location: null,
		browserId: this.props.browserId,
		ipAddress: null,
		show: false,
		requstProcessing: false,
		showPassword: 'password'
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		// console.log(nextProps, prevState)
		if (nextProps.browserId != prevState.browserId) {
			return {
				browserId: nextProps.browserId,
			};
		} else {
			return null;
		}
	}

	onChange = (ev) => {
		this.setState({
			[ev.target.name]: ev.target.value,
		});
		this.props.onRevertToInitialState();
	};

	componentDidMount() {
		this.getLocation();
		const userAgent = devices.getUA;

		if (this.props && !this.props.error && this.props.successMsg) {
			toast.success(this.props.successMsg);
			console.log(this.props.successMsg, "this.props.successMsg");
		} else if (this.props.error) {
			toast.error(this.props.errorMsg);
		}

	}

	getLocation = async () => {
		try {
			const ipAddress = await publicIp.v4();
			/* console.log(ipAddress); */
			this.setState({ ipAddress: ipAddress });
			const locationFetched = await ipLocation(ipAddress);
			const locationObject = {
				city: locationFetched.city,
				state: locationFetched.region.name,
				country: locationFetched.country.name,
			};
			const location = Object.values(locationObject).join(",");
			this.setState({ location: location });
		} catch { }
	};

	formSubmit = (ev) => {
		ev.preventDefault();
		const { email, password, browserId } = this.state;

		const device_name = devices.browserName;
		const device_version = devices.browserVersion;
		const device_os = devices.osName;
		const device_os_version = devices.osVersion;
		const device =
			device_name +
			" " +
			device_version +
			", " +
			device_os +
			" " +
			device_os_version;
		// const currentTime = moment.tz("Australia/Sydney").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
		const currentTime = moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
		const device_loggedIn = {
			browserId: this.state.browserId,
			device: device,
			time: currentTime,
			location: this.state.location,
			ipAddress: this.state.ipAddress,
		};
		this.props.onSignIn(
			email,
			password,
			device_loggedIn,
			this.props.history,
			browserId,
			toast
		);
	};

	resetPasswordSubmit = (ev) => {
		ev.preventDefault();

		this.setState({ requstProcessing: true });

		const { emailid } = this.state;
		console.log("emailid:", emailid);
		const device_name = devices.browserName;
		const device_version = devices.browserVersion;
		const device_os = devices.osName;
		const device_os_version = devices.osVersion;
		const device =
			device_name +
			" " +
			device_version +
			", " +
			device_os +
			" " +
			device_os_version;
		const postdata = {
			emailid: this.state.emailid,
			location: this.state.location,
			device: device,
		};

		client.post(api.admin_resetPassword, postdata, (error, response) => {
			console.log("reset password response ", response);
			if (!error) {
				if (!response.error) {
					toast.success("email send successfully");
					this.props.history.push(
						`${process.env.PUBLIC_URL}/verifyOTP/${response.result?.data.id}`
					);
					this.setState({ requstProcessing: false });
				} else {
					this.setState({ requstProcessing: false });
					toast.error("Admin user not found");
				}
			} else {
				this.setState({ requstProcessing: false });
				toast.error("Internal error occured. Please contact support");
			}
		});
	};

	render() {

		let authRedirect = null;

		if (this.props.isAuthenticated) {
			authRedirect = (
				<Redirect to={`${process.env.PUBLIC_URL}/dashboard/default`}></Redirect>
			);
		}

		const handleClose = () => this.setState({ show: false });
		const handleShow = () => this.setState({ show: true });

		return (
			<Fragment>
				{authRedirect}

				<div className="page-wrapper">
					{/* <!-- login page start--> */}

					<div className="container-fluid p-0">
						<div className="authentication-main">
							<div className="row">
								<div className="col-sm-12">
									<div className="auth-innerright">
										<div className="authentication-box">
											{/* <div className="text-center">
												<img src={logo} alt="" />
											</div> */}
											<div className=" d-flex justify-content-center">
												<img
													src={Logo}
													style={{ height: "90px", width: "120px" }}
													alt="logo"
												/>
											</div>
											<div className="card mt-1">
												<div className="card-body">
													<form onSubmit={this.formSubmit}>
														<div className="text-center" style={{ marginBottom: '24px' }}>
															<h4>WELCOME TO LINKY</h4>
															{/* <h6>Enter your Username and Password </h6> */}
														</div>

														<div className="form-group">
															<label className="col-form-label pt-0">
																Your Name
															</label>
															<input
																className="form-control "
																type="email"
																name="email"
																value={this.state.email}
																onChange={this.onChange}
																placeholder="Email address"
															/>
															{/* {errors.email && 'Email is required'} */}
														</div>
														<div className="form-group ">
															<label className="col-form-label">Password</label>

															<div className="input_box input-group" style={{ position: "relative" }}>
																<input
																	style={{ position: "relative" }}
																	className="form-control border"
																	type={this.state.showPassword}
																	name="password"
																	value={this.state.password}
																	onChange={this.onChange}
																	placeholder="Enter password"

																/>
																{
																	this.state.showPassword == "password" ?

																		<VisibilityOffIcon

																			style={{
																				position: "absolute",
																				top: "50%",
																				transform: "translateY(-50%)",
																				right: "10px"
																			}}
																			onClick={() => {
																				if (this.state.showPassword == "password") {
																					this.setState({ showPassword: "text" });

																				}
																				else {
																					this.setState({ showPassword: "password" });
																				}

																			}}
																		/>
																		:
																		<VisibilityIcon
																			className="password_icon"
																			style={{
																				position: "absolute",
																				top: "50%",
																				transform: "translateY(-50%)",
																				right: '10px'
																			}}
																			onClick={() => {
																				if (this.state.showPassword == "password") {
																					this.setState({ showPassword: "text" });

																				}
																				else {
																					this.setState({ showPassword: "password" });
																				}

																			}}


																		/>

																}
															</div>


															{/* {errors.password && 'Email is required'} */}
														</div>
														<div className="checkbox p-0">
															<input id="checkbox1" type="checkbox" />
															<label htmlFor="checkbox1">Remember me</label>
														</div>
														<div className="form-group form-row mt-3 mb-0">
															<button
																className="btn btn-primary btn-block"
																type="submit"
															>
																Login
															</button>
														</div>
													</form>
													<div
														className="text-center"
														style={{ marginTop: "1rem" }}
													>
														<button
															style={{
																backgroundColor: "transparent",
																border: "none",
																outline: "none",
																cursor: "pointer",
															}}
															onClick={handleShow}
														>
															Forgot Password?
														</button>
														<Modal show={this.state.show} onHide={handleClose}>
															<form>
																<Modal.Header closeButton>
																	<Modal.Title>Forgot Password?</Modal.Title>
																</Modal.Header>
																<Modal.Body>
																	<p style={{ fontSize: "14px" }}>
																		Enter linked email id
																	</p>
																	<input
																		className="form-control"
																		type="email"
																		name="emailid"
																		value={this.state.emailid}
																		onChange={(e) =>
																			this.setState({ emailid: e.target.value })
																		}
																		placeholder="Email address"
																	/>
																</Modal.Body>
																<Modal.Footer>
																	<Button variant="default" onClick={handleClose}>
																		Cancel
																	</Button>
																	<Button
																		disabled={this.state.requstProcessing}
																		variant="primary"
																		onClick={(e) => this.resetPasswordSubmit(e)}
																		type="submit"
																	>
																		{this.state.requstProcessing ? (
																			<BeatLoader
																				color={"#fff"}
																				loading={true}
																				size="7"
																			/>
																		) : (
																			"Proceed"
																		)}
																	</Button>
																</Modal.Footer>
															</form>
														</Modal>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<ToastContainer />

						{/* <!-- login page end--> */}
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		isAuthenticated: state.auth.token != null,
		error: state.auth.error,
		successMsg: state.auth.successMsg,
		errorMsg: state.auth.errorMsg,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onSignIn: (email, password, device_loggedIn, history, browserId, toast) =>
			dispatch(
				actionCreators.auth(
					email,
					password,
					device_loggedIn,
					history,
					browserId,
					toast
				)
			),
		onRevertToInitialState: () =>
			dispatch(actionCreators.reverToInitialState()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
