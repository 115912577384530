import React from 'react';
import { ReactComponent as Ameba } from '../../../assets/icons/social-media_2_svg/ameba.svg'
import { ReactComponent as ClubhouseA } from '../../../assets/icons/social-media_2_svg/clubhouse.svg'
import { ReactComponent as Fb } from '../../../assets/icons/social-media_2_svg/fb.svg'
import { ReactComponent as Ig } from '../../../assets/icons/social-media_2_svg/ig.svg'
import { ReactComponent as LineA } from '../../../assets/icons/social-media_2_svg/line.svg'
import { ReactComponent as Mail } from '../../../assets/icons/social-media_2_svg/mail.svg'
import { ReactComponent as Note } from '../../../assets/icons/social-media_2_svg/note.svg'
import { ReactComponent as PinterestA } from '../../../assets/icons/social-media_2_svg/pinterest.svg'
import { ReactComponent as PixivA } from '../../../assets/icons/social-media_2_svg/pixiv.svg'
import { ReactComponent as SnapchatA } from '../../../assets/icons/social-media_2_svg/snapchat.svg'
import { ReactComponent as Stand } from '../../../assets/icons/social-media_2_svg/stand.svg'
import { ReactComponent as TiktokA } from '../../../assets/icons/social-media_2_svg/tiktok.svg'
import { ReactComponent as TwitterA } from '../../../assets/icons/social-media_2_svg/twitter.svg'
import { ReactComponent as TelegramA } from '../../../assets/icons/social-media_2_svg/telegram.svg'
import { ReactComponent as LinkedinA } from '../../../assets/icons/social-media_2_svg/linkedin.svg'
import { ReactComponent as WebsiteA } from '../../../assets/icons/social-media_2_svg/website.svg'
import { ReactComponent as YoutubeA } from '../../../assets/icons/social-media_2_svg/youtube.svg'
import { ReactComponent as TwitchA } from '../../../assets/icons/social-media_2_svg/twitch.svg'
import { ReactComponent as WeChatA } from '../../../assets/icons/social-media_2_svg/wechat.svg'
import { ReactComponent as DiscordA } from '../../../assets/icons/social-media_2_svg/discord.svg'
import { ReactComponent as TextA } from '../../../assets/icons/social-media_2_svg/sms.svg'
import { ReactComponent as CallA } from '../../../assets/icons/social-media_2_svg/call.svg'
import { ReactComponent as FaceTimeA } from '../../../assets/icons/social-media_2_svg/facetime.svg'
import { ReactComponent as AddressA } from '../../../assets/icons/social-media_2_svg/address.svg'
import { ReactComponent as WhatsAppA } from '../../../assets/icons/social-media_2_svg/whatsapp.svg'

const Documents = "/assets/images/linky-biz-images/linky-icons/Documents.svg";
const Address = "/assets/images/linky-biz-images/add-more-logos/Address.svg";
const Call = "/assets/images/linky-biz-images/add-more-logos/Call.svg";
const ContactCard = "/assets/images/linky-biz-images/add-more-logos/Contact card.svg";
const Email = "/assets/images/linky-biz-images/add-more-logos/Email.svg";
const FaceTime = "/assets/images/linky-biz-images/add-more-logos/FaceTime.svg";
const Text = "/assets/images/linky-biz-images/add-more-logos/Text.svg";
const Website = "/assets/images/linky-biz-images/add-more-logos/Website.svg";
const WhatsApp = "/assets/images/linky-biz-images/add-more-logos/WhatsApp.svg";
const Discord = "/assets/images/linky-biz-images/social-media/Discord.svg";
const Facebook = "/assets/images/linky-biz-images/social-media/Facebook.svg";
const Instagram = "/assets/images/linky-biz-images/social-media/Instagram.svg";
const Linkedin = "/assets/images/linky-biz-images/social-media/Linkedin.svg";
const Pinterest = "/assets/images/linky-biz-images/social-media/Pinterest.svg";
const Snapchat = "/assets/images/linky-biz-images/social-media/Snapchat.svg";
const Telegram = "/assets/images/linky-biz-images/social-media/Telegram.svg";
const Tiktok = "/assets/images/linky-biz-images/social-media/Tiktok.svg";
const Twitch = "/assets/images/linky-biz-images/social-media/Twitch.svg";
const Twitter = "/assets/images/linky-biz-images/social-media/Twitter.svg";
const Wechat = "/assets/images/linky-biz-images/social-media/Wechat.svg";
const Youtube = "/assets/images/linky-biz-images/social-media/Youtube.svg";

const Pixiv = "/assets/images/linky-biz-images/social-media/Pixiv.svg";
const Standfm = "/assets/images/linky-biz-images/social-media/Stand.fm.svg";
const Notes = "/assets/images/linky-biz-images/social-media/Notes.svg";
const Line = "/assets/images/linky-biz-images/social-media/Line.svg";
const Clubhouse = "/assets/images/linky-biz-images/social-media/Clubhouse.svg";
const Ameblo = "/assets/images/linky-biz-images/social-media/Ameblo.svg";

export const array_of_social_handles_form_data = [
    {
        name: "Instagram",
        URL: "https://fb4b385ba89ed43a2df2cf6f7fbbac67.cdn.bubble.io/f1615340981629x672505293305957600/ig.svg",
    },
    {
        name: "Facebook",
        URL: "https://fb4b385ba89ed43a2df2cf6f7fbbac67.cdn.bubble.io/f1615126102020x781770632106283000/fb.svg",
        placeholder: "https://www.facebook.com/people/example",
    },
    {
        name: "Tiktok",
        altName: "TikTok",
        URL: "https://fb4b385ba89ed43a2df2cf6f7fbbac67.cdn.bubble.io/f1615342329447x847204688260509800/tiktok.svg",
    },
    {
        name: "Twitter",
        addCustomStyle: true,
        URL: "https://api.iconify.design/akar-icons/x-fill.svg?color=%234f4f4f&width=10&height=10",
    },
    {
        name: "Twitch",
        addCustomStyle: true,
        URL: 'https://api.iconify.design/akar-icons/twitch-fill.svg?color=%234f4f4f&width=10&height=10',
    },
    {
        name: "Youtube",
        altName: "YouTube",
        URL: "https://fb4b385ba89ed43a2df2cf6f7fbbac67.cdn.bubble.io/f1615342859013x778235037150910300/youtube.svg",
        placeholder: "https://youtube.com/example",
    },
    {
        name: "Snapchat",
        URL: "https://fb4b385ba89ed43a2df2cf6f7fbbac67.cdn.bubble.io/f1615417329973x733458541389769600/snapchat.svg",
    },
    {
        name: "Pinterest",
        URL: "https://fb4b385ba89ed43a2df2cf6f7fbbac67.cdn.bubble.io/f1615417596631x704809070988955100/pinterest.svg",
    },
    {
        name: "Mail",
        altName: "Email",
        URL: "https://fb4b385ba89ed43a2df2cf6f7fbbac67.cdn.bubble.io/f1625224871210x780986880682012400/Frame%2028667.svg",
        placeholder: "example@email.com",
    },
    {
        name: "Line",
        URL: "https://fb4b385ba89ed43a2df2cf6f7fbbac67.cdn.bubble.io/f1615426616809x219983897034627740/line.svg",
        placeholder: "https://line.me/example",
    },
    {
        name: "Clubhouse",
        URL: "https://fb4b385ba89ed43a2df2cf6f7fbbac67.cdn.bubble.io/f1625224766739x464304818031800770/Clubhouse%20%281%29.svg",
    },
    {
        name: "Note",
        URL: "https://dl.dropboxusercontent.com/scl/fi/2jrywr5xtzdrityaqeg2x/notes.svg?rlkey=k7cgr3psdet9fwv8vdwpm401w&dl=0",
        placeholder: "https://note.com/example",
    },
    {
        name: "Pixiv",
        URL: "https://fb4b385ba89ed43a2df2cf6f7fbbac67.cdn.bubble.io/f1622342053429x276900768969639870/Pixiv.svg",
        placeholder: "https://www.pixiv.net/example",
    },
    {
        name: "stand.fm",
        URL: "https://fb4b385ba89ed43a2df2cf6f7fbbac67.cdn.bubble.io/f1625224783137x613617377646285700/Stand.fm%20%281%29.svg",
        placeholder: "https://stand.fm/example",
    },
    {
        name: "Ameblo",
        URL: "https://fb4b385ba89ed43a2df2cf6f7fbbac67.cdn.bubble.io/f1626515198766x739668695451863400/Ameba%20%282%29.svg",
        placeholder: "https://ameblo.jp/example",
    },
    {
        name: "Telegram",
        addCustomStyle: true,
        URL: `https://api.iconify.design/mingcute/telegram-fill.svg?color=%234f4f4f&width=10&height=10`,
    },
    {
        name: "Linkedin",
        altName: "LinkedIn",
        addCustomStyle: true,
        URL: `https://api.iconify.design/uit/linkedin-alt.svg?color=%234f4f4f&width=10&height=10`,
        placeholder: "https://www.linkedin.com/in/user/",
    },
    {
        name: "Website",
        addCustomStyle: true,
        URL: `https://api.iconify.design/streamline/web.svg?color=%234f4f4f&width=10&height=10`,
        placeholder: "https://example.com",
    },
    {
        name: "WeChat",
        addCustomStyle: true,
        URL: `https://api.iconify.design/ic/baseline-wechat.svg?color=%234f4f4f&width=10&height=10`,
    },
    {
        name: "Discord",
        URL: 'https://api.iconify.design/ic/baseline-discord.svg?color=%234f4f4f&width=10&height=10',
    },
    {
        name: "Text",
        URL: 'https://api.iconify.design/subway/sms-5.svg?color=%234f4f4f&width=10&height=10',
    },
    {
        name: "Call",
        URL: 'https://api.iconify.design/material-symbols/call.svg?color=%234f4f4f&width=10&height=10',
    },
    {
        name: "FaceTime",
        URL: 'https://api.iconify.design/iconoir/facetime.svg?color=%234f4f4f&width=10&height=10',
    },
    {
        name: "Address",
        URL: 'https://api.iconify.design/mdi/address-marker-outline.svg?color=%234f4f4f&width=10&height=10',
    },
    {
        name: "WhatsApp",
        URL: 'https://api.iconify.design/ic/baseline-whatsapp.svg?color=%234f4f4f&width=10&height=10',
    },
];

export const array_of_social_handles_phone = [
    {
        name: 'Instagram',
        URL: <Ig />
    },
    {
        name: 'Facebook',
        URL: <Fb />
    },
    {
        name: 'Tiktok',
        altName: "TikTok",
        URL: <TiktokA />
    },
    {
        name: 'Twitter',
        URL: <TwitterA />
    },
    {
        name: 'Youtube',
        altName: 'YouTube',
        URL: <YoutubeA />
    },
    {
        name: 'Snapchat',
        URL: <SnapchatA />
    },
    {
        name: 'Pinterest',
        URL: <PinterestA />
    },
    {
        name: 'Mail',
        altName: 'Email',
        URL: <Mail />
    },
    {
        name: 'Line',
        URL: <LineA />
    },
    {
        name: 'Clubhouse',
        URL: <ClubhouseA />
    },
    {
        name: 'Note',
        URL: <Note />
    },
    {
        name: 'Pixiv',
        URL: <PixivA />
    },
    {
        name: 'stand.fm',
        URL: <Stand />
    },
    {
        name: 'Ameblo',
        URL: <Ameba />
    },
    {
        name: 'Telegram',
        URL: <TelegramA />,
    },
    {
        name: 'Linkedin',
        altName: 'LinkedIn',
        URL: <LinkedinA />,
    },
    {
        name: 'Website',
        URL: <WebsiteA />,
    },
    {
        name: 'Twitch',
        URL: <TwitchA />
    },
    {
        name: "WeChat",
        URL: <WeChatA />
    },
    {
        name: 'Discord',
        URL: <DiscordA />,
    },
    {
        name: 'Text',
        URL: <TextA />,
    },
    {
        name: 'Call',
        URL: <CallA />,
    },
    {
        name: "FaceTime",
        URL: <FaceTimeA />
    },
    {
        name: "Address",
        URL: <AddressA />
    },
    {
        name: "WhatsApp",
        URL: <WhatsAppA />
    },
]

export const defaultDataForSocialMedia = (val) => {
    if (val === "Instagram") {
        return {
            image: Instagram,
            fieldOneLabel: 'Instagram username/link'
            // fieldOneLabel: i18n.t(
            //     "my_store:store_biz_link.add_new_content.default_ph1"
            // ),
        };
    } else if (val === "Snapchat") {
        return {
            image: Snapchat,
            fieldOneLabel: 'Snapchat username'
            // fieldOneLabel: i18n.t(
            //     "my_store:store_biz_link.add_new_content.default_ph2"
            // ),
        };
    } else if (val === "TikTok") {
        return {
            image: Tiktok,
            fieldOneLabel: 'TikTok username/link'
            // fieldOneLabel: i18n.t(
            //     "my_store:store_biz_link.add_new_content.default_ph3"
            // ),
        };
    } else if (val === "Facebook") {
        return {
            image: Facebook,
            fieldOneLabel: 'Facebook profile link/username'
            // fieldOneLabel: i18n.t(
            //     "my_store:store_biz_link.add_new_content.default_ph4"
            // ),
        };
    } else if (val === "LinkedIn") {
        return {
            image: Linkedin,
            fieldOneLabel: 'LinkedIn profile link'
            // fieldOneLabel: i18n.t(
            //     "my_store:store_biz_link.add_new_content.default_ph5"
            // ),
        };
    } else if (val === "Twitter") {
        return {
            image: Twitter,
            fieldOneLabel: 'Twitter username'
            // fieldOneLabel: i18n.t(
            //     "my_store:store_biz_link.add_new_content.default_ph6"
            // ),
        };
    } else if (val === "YouTube") {
        return {
            image: Youtube,
            fieldOneLabel: 'Youtube user/channel link'
            // fieldOneLabel: i18n.t(
            //     "my_store:store_biz_link.add_new_content.default_ph7"
            // ),
        };
    } else if (val === "Twitch") {
        return {
            image: Twitch,
            fieldOneLabel: 'Twitch username'
            // fieldOneLabel: i18n.t(
            //     "my_store:store_biz_link.add_new_content.default_ph8"
            // ),
        };
    } else if (val === "Pinterest") {
        return {
            image: Pinterest,
            fieldOneLabel: 'Pinterest username/link'
            // fieldOneLabel: i18n.t(
            //     "my_store:store_biz_link.add_new_content.default_ph9"
            // ),
        };
    } else if (val === "WeChat") {
        return {
            image: Wechat,
            fieldOneLabel: 'WeChat number'
            // fieldOneLabel: i18n.t(
            //     "my_store:store_biz_link.add_new_content.default_ph10"
            // ),
        };
    } else if (val === "Discord") {
        return {
            image: Discord,
            fieldOneLabel: 'Discord server link'
            // fieldOneLabel: i18n.t(
            //     "my_store:store_biz_link.add_new_content.default_ph11"
            // ),
        };
    } else if (val === "Telegram") {
        return {
            image: Telegram,
            fieldOneLabel: 'Your Telegram link/username'
            // fieldOneLabel: i18n.t(
            //     "my_store:store_biz_link.add_new_content.default_ph12"
            // ),
        };
    } else if (val === "Website") {
        return {
            image: Website,
            fieldOneLabel: 'Website link'
            // fieldOneLabel: i18n.t(
            //     "my_store:store_biz_link.add_new_content.default_ph13"
            // ),
        };
    } else if (val === "Text") {
        return {
            image: Text,
            fieldOneLabel: 'Phone number'
            // fieldOneLabel: i18n.t(
            //     "my_store:store_biz_link.add_new_content.default_ph14"
            // ),
        };
    } else if (val === "Call") {
        return {
            image: Call,
            fieldOneLabel: 'Phone number'
            // fieldOneLabel: i18n.t(
            //     "my_store:store_biz_link.add_new_content.default_ph15"
            // ),
        };
    } else if (val === "Email") {
        return {
            image: Email,
            fieldOneLabel: 'Email address'
            // fieldOneLabel: i18n.t(
            //     "my_store:store_biz_link.add_new_content.default_ph16"
            // ),
        };
    } else if (val === "Contact Card") {
        return {
            image: ContactCard,
            fieldOneLabel: 'Facebook profile link'
            // fieldOneLabel: i18n.t(
            //     "my_store:store_biz_link.add_new_content.default_ph17"
            // ),
        };
    } else if (val === "FaceTime") {
        return {
            image: FaceTime,
            fieldOneLabel: 'FaceTime link'
            // fieldOneLabel: i18n.t(
            //     "my_store:store_biz_link.add_new_content.default_ph18"
            // ),
        };
    } else if (val === "WhatsApp") {
        return {
            image: WhatsApp,
            fieldOneLabel: 'WhatsApp number'
            // fieldOneLabel: i18n.t(
            //     "my_store:store_biz_link.add_new_content.default_ph19"
            // ),
        };
    } else if (val === "Address") {
        return {
            image: Address,
            fieldOneLabel: 'Business address'
            // fieldOneLabel: i18n.t(
            //     "my_store:store_biz_link.add_new_content.default_ph20"
            // ),
        };
    } else if (val === "Document") {
        return {
            image: Documents,
            fieldOneLabel: 'Document link'
            // fieldOneLabel: i18n.t(
            //     "my_store:store_biz_link.add_new_content.default_ph21"
            // ),
        };
    } else if (val === "Line") {
        return {
            image: Line,
            fieldOneLabel: 'Your Line link/username'
            // fieldOneLabel: i18n.t(
            //     "my_store:store_biz_link.add_new_content.default_ph12"
            // ),
        };
    } else if (val === "Clubhouse") {
        return {
            image: Clubhouse,
            fieldOneLabel: 'Your Clubhouse link/username'
            // fieldOneLabel: i18n.t(
            //     "my_store:store_biz_link.add_new_content.default_ph12"
            // ),
        };
    } else if (val === "Note") {
        return {
            image: Notes,
            fieldOneLabel: 'Your Note.com link/username'
            // fieldOneLabel: i18n.t(
            //     "my_store:store_biz_link.add_new_content.default_ph12"
            // ),
        };
    } else if (val === "Pixiv") {
        return {
            image: Pixiv,
            fieldOneLabel: 'Your Pixiv link/username'
            // fieldOneLabel: i18n.t(
            //     "my_store:store_biz_link.add_new_content.default_ph12"
            // ),
        };
    } else if (val === "stand.fm") {
        return {
            image: Standfm,
            fieldOneLabel: 'Your Stand.fm link/username'
            // fieldOneLabel: i18n.t(
            //     "my_store:store_biz_link.add_new_content.default_ph12"
            // ),
        };
    } else if (val === "Ameblo") {
        return {
            image: Ameblo,
            fieldOneLabel: 'Your Ameblo link/username'
            // fieldOneLabel: i18n.t(
            //     "my_store:store_biz_link.add_new_content.default_ph12"
            // ),
        };
    }
};