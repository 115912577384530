import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../../../components/common/breadcrumb';

import client from "../../../../Api/HTTPClient";
import api from "../../../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import { Link as NavLink } from "react-router-dom";
import useForm from 'react-hook-form'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem, NavLink as NavLinkTab } from 'reactstrap';
import { DollarSign, MapPin, X, TrendingDown, ArrowUp, ShoppingCart, Search, Activity, User, Users, Book } from 'react-feather';
import Slider from 'react-slick';
import CountUp from 'react-countup';
import RightBar from '../../../../components/social-app/rightBar';
var Knob = require('knob')// browserify require
var primary = localStorage.getItem('primary_color') || '#4466f2';


function Sms(props) {
    const [modal2, setModal2] = useState();
    const [delete_modal, set_delete_toggle] = useState();
    const [del_templ_id, set_del_templ_id] = useState("");
    const [access_level, setAccessLevel] = useState(localStorage.getItem("accessLevel"))



    let { del_temp_id } = '';

    const [templ_name, set_templ_Name] = useState("");
    const [templ_message, set_templ_message] = useState("");
    const [templ_user_type, set_templ_user_type] = useState("");
    const [templ_comments, set_templ_comments] = useState("");
    const [activeTab, setActiveTab] = useState('1');
    let [template_data, set_template_data] = useState(null);
    const [btnloader, setBtnloader] = useState(false);

    const delete_toggle = () => {
        set_delete_toggle(!delete_modal)
    }

    const set_del_id = (temp_id) => {
        //console.log(temp_id);
        set_del_templ_id(temp_id)
    }

    const [settings, setSettings] = useState({
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        // variableWidth:true,
        arrows: false,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 370,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });

    const { errors } = useForm(); // initialise the hook
    const [loadingbtn, setloadingbtn] = useState(false);
    const [loading, setloading] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [templateChangeData, setTemplateChangeData] = useState({})

    const handleSubmit = (e, data) => {
        e.preventDefault();
        //alert(`Submitting Name ${templ_name}`)
        data = {
            templ_name: templ_name, templ_user_type: templ_user_type,
            templ_message: templ_message, templ_comments: templ_comments, auto_reminder: true
        };
        
        if (data !== '') {
            setloadingbtn(true);
            client.post(api.add_new_sms_template, data, async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                toast.success(response.message);
                                refreshPage();
                                setBtnloader(false);
                                props.toggle3();
                            } else {
                                toast.error(response.message);
                                setBtnloader(false)
                            }
                        } catch (e) {
                            toast.error("Internal error occured. Please contact support");
                            setBtnloader(false)
                        }
                    } else {
                        toast.error("Internal error occured. Please contact support");
                        setBtnloader(false)
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                    setBtnloader(false)
                }
            });
        } else {
            errors.showMessages();
        }
    }

    const handleDeleteSubmit = (e, templ_id) => {
        e.preventDefault();
        let data = { template_id: templ_id };
        if (data !== '') {
            setBtnloader(true)
            client.post(api.delete_sms_template, data, async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                toast.success("Template Deleted Successfully");
                                delete_toggle();
                                refreshPage();
                                setBtnloader(false)
                            } else {
                                toast.error(response.message);
                                setBtnloader(false)
                            }
                        } catch (e) {
                            toast.error("Internal error occured. Please contact support");
                            setBtnloader(false)
                        }
                    } else {
                        toast.error("Internal error occured. Please contact support");
                        setBtnloader(false)
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                    setBtnloader(false)
                }
            });
        } else {
            errors.showMessages();
        }
    }

    const geta_all_templates = async () => {
        let postdata = { auto_reminder: true };
        let current = this;
        // current.setState({ loading: true });
        setloading(true);
        client.post(api.get_all_sms_templates, postdata, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            console.log(response);
                            set_template_data(response.result.data);
                            // current.props.navigation.navigate("Verification");
                        } else {
                            toast.error(response.message);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
                // _showToast.error("Internal error occured. Please contact support.");
            }
            // current.setState({ loading: false });
            setloading(false);
        });
    };

    useEffect(() => {
        geta_all_templates();
    }, []);

    const refreshPage = () =>{
        geta_all_templates();
    }


    const toggleupdateModal = () => {
        setUpdateModal(!updateModal)
    }

    const onUpdateTemplate = (event) => {
        event.preventDefault();
        const postdata = template_data
        setBtnloader(true)
        client.post(api.update_many_sms_template, postdata, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            setBtnloader(false)
                            toast.success(response.message);
                            setUpdateModal(!updateModal);
                            refreshPage();
                        } else {
                            toast.error(response.message);
                            setBtnloader(false)
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support");
                        setBtnloader(false)
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                    setBtnloader(false)
                }
            } else {
                toast.error("Internal error occured. Please contact support");
                setBtnloader(false)
            }
            
        });
    }

    const renderUpdateModal = () => {
        return (
            <Modal isOpen={updateModal} toggle={toggleupdateModal} className="modal-body" centered={true}>
                <form className="theme-form" onSubmit={onUpdateTemplate}>
                    <ModalHeader>Update Changes</ModalHeader>
                    <ModalBody>
                        <h6>Do you really want to update the changes ?</h6>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" onClick={toggleupdateModal}>Not sure!</Button>
                        <Button color="primary" type="submit"  >Yes, Please!</Button>
                    </ModalFooter>
                </form>
            </Modal>

        );
    }

    const onChangeMultipleTemplate = (event, id) => {
        const { name, value } = event.target;
        const updatedTemplateData = [...template_data]
        const template = updatedTemplateData.find(el => {
            return el._id == id
        });
        const templateIndex = updatedTemplateData.findIndex(el => {
            return el._id == id
        });
        const updatedTemplate = {
            ...template,
            [name]: value
        }
        updatedTemplateData[templateIndex] = updatedTemplate
        set_template_data(updatedTemplateData)
    }

    const rendertable = (template_type_check) => {

        const templates = template_data.filter((item) => (
            template_type_check == item.templ_user_type
        ))
        return (
            <Fragment>
                {templates.length > 0 ? <div className="table-responsive">
                    <table className="table table-border-horizontal">
                        <thead>
                            <tr style={{background: '#f9fafe'}}>
                                <th scope="col">#</th>
                                <th scope="col">Template Name</th>
                                <th scope="col">Message</th>
                                <th scope="col">Comments</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>

                        <tbody>
                            {templates.map((item, index) => (
                                <tr>
                                    <th scope="row">{index + 1}</th>
                                    <td>
                                        <textarea className="form-control" rows="1" onChange={(event) => onChangeMultipleTemplate(event, item._id)}
                                            value={item.templ_name} name="templ_name" disabled={access_level == 1 ? true : false}>
                                        </textarea>
                                    </td>
                                    <td><textarea className="form-control" rows="1" onChange={(event) => onChangeMultipleTemplate(event, item._id)}
                                        value={item.templ_message} name="templ_message" disabled={access_level == 1 ? true : false}></textarea></td>
                                    <td><textarea className="form-control" rows="1" onChange={(event) => onChangeMultipleTemplate(event, item._id)}
                                        value={item.templ_comments} name="templ_comments" disabled={access_level == 1 ? true : false}></textarea></td>
                                    <td>
                                        {access_level == 3 && <i className="btn fa fa-trash" style={{ color: "rgb(228, 86, 110)", padding: "0px" }} onClick={() => {
                                            delete_toggle();
                                            set_del_id(item._id);
                                        }} ></i>}
                                    </td>
                                </tr>

                            ))}
                        </tbody>
                    </table>
                </div> : <h6 style={{ textAlign: "center" }}>No template added!</h6>}
                {template_data.filter(item => template_type_check == item.templ_user_type).length > 0 && <div style={{ textAlign: "center" }}>
                    <button className="btn btn-primary btn-xs pull-right" style={{marginTop:"15px"}}
                        onClick={toggleupdateModal}>Update</button>
                </div>}
            </Fragment>
        )
    }

    return (
        <Fragment>
            {renderUpdateModal()}
            <Modal isOpen={props.modal5} toggle={props.toggle3} className="modal-body" centered={true}>
                <form className="theme-form" noValidate="" onSubmit={handleSubmit}>
                    <ModalHeader toggle={props.toggle3}>Add New Template</ModalHeader>
                    <ModalBody>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label" htmlFor="business_name">User</label>
                            <div className="col-sm-9">
                                <select className="form-control" name="templ_user_type" id="user_type" value={templ_user_type}
                                    onChange={e => set_templ_user_type(e.target.value)} >
                                    <option value="" >Select Option</option>
                                    <option value="customer" >Customer</option>
                                    <option value="merchant" >Merchant</option>
                                    <option value="others" >Others</option>
                                </select>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Name</label>
                            <div className="col-sm-9">
                                <input className="form-control" type="text" placeholder="Name" name="templ_name" value={templ_name}
                                    onChange={e => set_templ_Name(e.target.value)} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Message</label>
                            <div className="col-sm-9">
                                <textarea className="form-control" rows="2" name="templ_message" value={templ_message} placeholder="Message"
                                    onChange={e => set_templ_message(e.target.value)}></textarea>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Comments:</label>
                            <div className="col-sm-9">
                                <textarea className="form-control" rows="2" name="templ_comments" value={templ_comments}
                                    onChange={e => set_templ_comments(e.target.value)} ></textarea>
                            </div>
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">Save Changes</Button>
                        <Button color="default" onClick={props.toggle3}>Cancel</Button>
                    </ModalFooter>
                </form>
            </Modal>

            <Modal isOpen={delete_modal} toggle={delete_toggle} className="modal-body" centered={true}>
                <form className="theme-form" noValidate="" >
                    <ModalHeader toggle={delete_toggle}>Delete Template</ModalHeader>
                    <ModalBody>
                        <h6>Do you really want to delete this Template ?</h6>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit" onClick={(e) => { handleDeleteSubmit(e, del_templ_id) }} >Yes</Button>
                        <Button color="default" onClick={delete_toggle}>No</Button>
                    </ModalFooter>
                </form>
            </Modal>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-xl-12 xl-100">
                        {/* {access_level != 1 && <Button className="mb-3" outline color="primary" onClick={toggle2}><i className="fa fa-plus" style={{ margin: "0 7px 0 0" }}></i>Add New Template</Button>} */}
                        <Nav tabs className="nav-pills nav-light mb-1">
                            <NavItem className="nav nav-tabs " id="myTab" role="tablist">
                                <NavLinkTab className={activeTab == '1' ? 'active nav-link' : ''} onClick={() => setActiveTab('1')} value='1'>
                                    Customers ({template_data ? template_data.filter(function (element) {
                                    return element.templ_user_type === 'customer';
                                }).length : 0})
                                            </NavLinkTab>
                            </NavItem>

                            <NavItem className="nav nav-tabs " id="myTab" role="tablist">
                                <NavLinkTab className={activeTab == '2' ? 'active nav-link' : ''} onClick={() => setActiveTab('2')} value='2'>
                                    Merchants ({template_data ? template_data.filter(function (element) {
                                    return element.templ_user_type === 'merchant';
                                }).length : 0})
                                            </NavLinkTab>
                            </NavItem>

                            <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                                <NavLinkTab className={activeTab == '3' ? 'active nav-link' : ''} onClick={() => setActiveTab('3')} value='3'>
                                    Others ({template_data ? template_data.filter(function (element) {
                                    return element.templ_user_type === 'others';
                                }).length : 0})
                                            </NavLinkTab>
                            </NavItem>
                        </Nav>

                        {template_data ?

                            <TabContent activeTab={activeTab} >
                                <TabPane tabId="1">
                                    {rendertable('customer')}
                                </TabPane>

                                <TabPane tabId="2">
                                    {rendertable('merchant')}
                                </TabPane>

                                <TabPane tabId="3">
                                    {rendertable('others')}
                                </TabPane>
                            </TabContent>
                            : <div className="loader-box" >
                                <div className="loader" style={{ margin: "auto auto" }}>
                                    <div className="line bg-primary"></div>
                                    <div className="line bg-primary"></div>
                                    <div className="line bg-primary"></div>
                                    <div className="line bg-primary"></div>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>

        </Fragment >
    );
    // }
}

export default Sms;