import React, { Component, Fragment } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import Breadcrumb from "../../../components/common/breadcrumb";
import VersionSettings from "../AppSettings/VersionSettings";
import SilentUpdateSetting from "../AppSettings/SilentUpdateSetting";
import SliderSettings from "../AppSettings/SliderSettings";
import QuizTable from "../AppSettings/QuizTable";
import TopupSettingMerchant from "../AppSettings/TopupSettingMerchant";
import TopupSettingCust from "../AppSettings/TopupSettingCust";
import PublishQuiz from "../AppSettings/PublishQuiz";
import LocationSetting from "./LocationSetting";
import { useLocation, withRouter } from "react-router";


const LoctionSettings = (props) => {

    const location = useLocation();
    console.log(location.state?.countryCode);
    console.log(props);
    

		return (
			<Fragment>
				<div className="row">
					<div className="col-lg-7 col-sm-12" style={{ fontSize: "12px" }}>
						<Breadcrumb title={"Location settings"} parent={"Home"} />
					</div>
				</div>
                <div className="container-fluid">
					<div className="row theme-tab">
						<Tabs
							className="col-sm-12"
							// defaultIndex={this.state.tabIndex}
							// onSelect={(index) => this.setState({ tabIndex: index })}
							style={{
								padding: "0",
								backgroundColor: "white",
								borderRadius: "3px",
								border: "1px solid rgb(223, 232, 241)",
								marginBottom: "0px",
							}}
						>
							<TabList className="tbs tab-title">
								{/* <Tab style={{marginTop:"0.5rem",display:"flex",alignItems:"center"}} >
                                    Version Settings
                            </Tab> */}
								<Tab
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									Location
								</Tab>
								{/* <Tab
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									Slider Settings
								</Tab> */}
								{/* <Tab
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									Quiz/Survey
								</Tab> */}
								{/* <Tab
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									Top-up
								</Tab> */}
								{/* <Tab
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									Publish
								</Tab> */}
							</TabList>

							{/* {customers  */}
							<div className="tab-content-cls">
								{/* <TabPanel>
                                    <VersionSettings toggleAddVersionModal={this.toggleAddVersionModal} addVersionModal={this.state.addVersionModal}></VersionSettings>
                                </TabPanel> */}

								<TabPanel>
									<Tabs
										className="col-sm-12"
										defaultIndex={1}
										style={{ padding: "0" }}
									>
										<TabList className="tabs tab-title">
											{/* <Tab>Merchant App</Tab> */}
											{/* <Tab>Customer App</Tab> */}
										</TabList>
										{/* <TabPanel>
											<SilentUpdateSetting></SilentUpdateSetting>
										</TabPanel> */}
										<TabPanel>
											<LocationSetting countryCode={location.state?.countryCode} countryId={location.state?.countryId}></LocationSetting>
										</TabPanel>
									</Tabs>
								</TabPanel>
								<TabPanel>
									<SliderSettings />
								</TabPanel>
								<TabPanel>
									<QuizTable></QuizTable>
								</TabPanel>
								{/* <TabPanel>
									<Tabs
										className="col-sm-12"
										// defaultIndex={1}
										style={{ padding: "0" }}
									>
										<TabList className="tabs tab-title">
											<Tab>Merchant</Tab>
											<Tab>Customer</Tab>
										</TabList>
										<TabPanel>
											<TopupSettingMerchant></TopupSettingMerchant>
										</TabPanel>
										<TabPanel>
											<TopupSettingCust></TopupSettingCust>
										</TabPanel>
									</Tabs>
								</TabPanel> */}
								
							</div>
						</Tabs>
					</div>
				</div>
			</Fragment>
		);
}

export default withRouter(LoctionSettings);
