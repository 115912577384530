import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './ToggleButton.scss';
import { propTypes } from 'react-bootstrap/esm/Image';

export const ToggleButtonField = ({
    onChange, defaultChecked, name, disabled, value, textOne, textTwo, custom, id, opposite
}) => {
    // useEffect(() => {
    //   onChange(defaultChecked);
    // }, [onChange, defaultChecked]);

    // console.log(value)

    return (
        <div className='toggle_button'>
            <label className="switch">
                <input
                    id={id}
                    className="toggle-btn__input border"
                    type="checkbox"
                    name={name}
                    onChange={onChange}
                    checked={value}
                    disabled={disabled}
                />

                <div className={` sliderCustom ${disabled ? `sliderDisabled` : `slider`} round ${custom ? `redslide` : ``} ${opposite ? `opposite` : ``}`} >
                    <span className="on"> {textOne} </span>
                    <span className="off"> {textTwo} </span>
                </div>
            </label>
            <style>
                {`
.sliderCustom.disabled {
  background-color: silver !important;
}
      `}
            </style>
        </div >
    );
};

ToggleButtonField.propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    defaultChecked: PropTypes.bool,
    custom: PropTypes.bool,
    opposite: PropTypes.bool,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]).isRequired,
};

ToggleButtonField.defaultProps = {
    defaultChecked: false,
    disabled: false,
};

const renderToggleButtonField = ({ input, defaultChecked, disabled, textOne, textTwo, custom, opposite, id }) => (
    <ToggleButtonField
        {...input}
        defaultChecked={defaultChecked}
        disabled={disabled}
        textOne={textOne}
        textTwo={textTwo}
        custom={custom}
        opposite={opposite}
        id={id}
    />

);

renderToggleButtonField.propTypes = {
    input: PropTypes.shape({
        onChange: PropTypes.func,
        name: PropTypes.string,
    }).isRequired,
    defaultChecked: PropTypes.bool,
    disabled: PropTypes.bool,
    custom: PropTypes.bool,
    opposite: PropTypes.bool,
    id: PropTypes.string,
};

renderToggleButtonField.defaultProps = {
    defaultChecked: false,
    disabled: false,
};


export default renderToggleButtonField;
