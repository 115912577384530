const { Dropbox } = require('dropbox');

const dbx = new Dropbox({ 
    clientId: 'xq3p59q2oj7xmql',
    clientSecret: 'ykemee19fj2pqsq',
  refreshToken: 'c-CXmBJ_hDgAAAAAAAAAAUWqh_nm2RbSAIxd6_ieFBQne6xRxsU-jXhJiFJYLvyO'
    // sl.Bwbr40c9KyXCenbGSmbioLeg_1Ih9GN-97HYpaZGW1WTBnZFJJE-66LUfdQFAFr6HbXpHHjt8wBY3DuEohhWHEA2WBmcMYejzERYg7HgREDcwyZ9zflPL_y295zCBJKjJVPEyZVcP4nfY8gNe4b9dSI
});

const arrange_files = async(data) => {


  let files = []
  for (let row of data) {
    if (row[".tag"] == "folder") {
      files.push(row)
    } else {
      console.log(row, "row")
      let url = ''
      try {

        let thumbnail = await dbx.filesGetThumbnail({ path: row.path_lower })
        url = URL.createObjectURL(thumbnail.result.fileBlob)
      }catch{}
      // console.log(thumbnail.result.fileBlob, "url")
      // row.url = url
      files.push({ ...row, url })
    }
  }

  return files
  
}

const getFolderContent = async (path, callback) => {


  dbx.filesListFolder({ path: "/ipaypro-image-folder"+path })
    .then(async(response)=> {
      // console.log('response', response)
      if (response.result.entries) {
        let files = await arrange_files(response.result.entries)
        callback(null, files); //console.log(folders, "folders")
      }
      // displayFiles(response.result.entries);
    })
    .catch(function (error) {
      callback(error.error || error)
      // console.error(error.error || error, "response");
    });
  // https://www.dropbox.com/scl/fo/7k1qf24l6ms74y1icmyzv/h?rlkey=qvfay5o4qo8t9tvtrcov2745p&dl=0
  // https://www.dropbox.com/scl/fo/ecgvgl5fofn2y4kd5gg8h/h?rlkey=ihrinhcogdcypp4ovaf8e945c&dl=0
}

const createFolder = (folder_name, callback) => {
  
  try {
    dbx.filesCreateFolderV2({ path: "/ipaypro-image-folder"+`/${folder_name}` }).then(function (response) {
      //console.log(response, 'RESPONSE')
      callback(null, response);
    }).catch(function (error) {
      //console.log(error,'ERROR')
      callback(error);
    });
  } catch (err) {
    //console.log(err);
  }
}

const doDelete = async (imagePath, callback) => {
  try {
    dbx.filesDeleteV2({path: imagePath}).then(function(response) {
      //console.log(response, 'RESPONSE')
      callback(true);
    }).catch(function(error){
      //console.log(error,'ERROR')
      callback(false);
    });
  }catch(err){
    //console.log(err);
  }
};

const doUpload = async (imagePath, contents, callback) => {
  try {
    // return new Promise((resolve, reject) => {
      

      //dbx.filesDeleteV2({path: '/' + imagePath});

      dbx.filesUpload({ path: "/ipaypro-image-folder"+imagePath, contents, mode: 'overwrite' })
        .then(function (response) {

          //fs.unlinkSync(`${imagePath}`);

          dbx.sharingCreateSharedLinkWithSettings({
            path: "/ipaypro-image-folder"+`${imagePath}`, settings: {
              "access": "viewer",
              "allow_download": true,
              "audience": "public",
              "requested_visibility": "public"
            }
          })
            .then(function (response) {
              if (response) {
                let url = response?.result?.url?.replace('www.dropbox.com', 'dl.dropboxusercontent.com');
                callback(null, { dropboxUrl: url });
                // resolve();
              }
            }).catch(function (error) {
              //console.error(error.error || error);

              dbx.sharingListSharedLinks({ path: "/ipaypro-image-folder"+`${imagePath}` }).then(function (response) {
                if (response) {
                  let url = response?.result?.links[0]?.url.replace('www.dropbox.com', 'dl.dropboxusercontent.com');
                  callback(null, { dropboxUrl: url });
                  // resolve();
                }
              }).catch(function (error) {
                // resolve();
                callback(error);
              });
            })

        })
        .catch(function (error) {
          callback(error.error || error)
        });

    // });
  } catch (error) {
    callback("Internal error")
  }
}

// // Function to check if the file exists in the Dropbox folder
// async function checkFileExists(dropboxFolderPath, fileName) {
//     try {
//       const response = await dbx.filesGetMetadata({ path: dropboxFolderPath + fileName });
//       if(response)
//       {
//         return true; // File exists
//       }else{
//         return false; // File doesn't exists
//       }
      
//     } catch (error) {
//         return false;
//     }
//   }

// // Function to wait for the file to be uploaded
// async function waitForFileUpload(dropboxFolderPath, fileName) {
//     try {
//         let fileExists = false;
//         while (!fileExists) {
//             fileExists = await checkFileExists(dropboxFolderPath, fileName);
//             console.log(fileExists, 'fileExists')
//             if (fileExists) {
//                 console.log('File uploaded successfully!');
//                 return fileExists;
//             }else{
//                 await delay(5000); // Wait for 5 seconds before checking again
//             }
//             // Perform any further actions here
//         }
//     } catch (error) {
//       //console.error('Error waiting for file upload:', error);
//     }
//   }

// // Utility function to introduce a delay
// function delay(ms) {
//     return new Promise(resolve => setTimeout(resolve, ms));
// }

const getImageURL = async (imagePath, callback) => {
  try {
    let response = await dbx.sharingCreateSharedLinkWithSettings({
      path: `${imagePath}`
    })
    // console.log(response, "response")
    let url = response?.result?.url?.replace('www.dropbox.com', 'dl.dropboxusercontent.com');
    return url
    // .then(function (response) {
    //   if (response) {
    //     let url = response?.result?.url?.replace('www.dropbox.com', 'dl.dropboxusercontent.com');
    //     // callback(null, { dropboxUrl: url });
    //     return url
    //     // resolve();
    //   }
    // }).catch(function (error) {
    //   //console.error(error.error || error);

    //   await dbx.sharingListSharedLinks({ path: `${imagePath}` }).then(function (response) {
    //     if (response) {
    //       let url = response?.result?.links[0]?.url.replace('www.dropbox.com', 'dl.dropboxusercontent.com');
    //       // callback(null, { dropboxUrl: url });
    //       return url
    //       // resolve();
    //     }
    //   }).catch(function (error) {
    //     // resolve();
    //     return ""
    //   });
    // })
    
  } catch (err) {
    try {
      let response = await dbx.sharingListSharedLinks({ path: `${imagePath}` })
      //.then(function (response) {
      // if (response) {
      let url = response?.result?.links[0]?.url.replace('www.dropbox.com', 'dl.dropboxusercontent.com');
      // callback(null, { dropboxUrl: url });
      return url
      // resolve();
      // }
      // })
    } catch { }
    // console.log(err)
    return ""
  }
}

async function filesSearch(fileName) {
  try {

    // Search for the file by name
    const response = await dbx.filesSearchV2({ options: { path: '/ipaypro-image-folder' }, query: fileName });
    // .then(response => {
    console.log(response, "response")
    let result = []
    for (let row of response.result.matches) {
      result.push(row.metadata.metadata
        )
    }

    // console.log(result, "result")
    let files = await arrange_files(result)

    // let files = await arrange_files(response.result.entries)
    // callback(null, files); //console.log(folders, "folders")
    return files
    

  } catch (error) {
    console.log(error, "error")
    return null
  }
}

// module.exports = { doUpload, waitForFileUpload, updateImageURL, filesSearch, doDelete, getFolderContent };
module.exports = { getFolderContent, createFolder, doUpload, doDelete, getImageURL, filesSearch };