import * as actionTypes from "../actions/actionTypes";

const initialState ={
    sliderData : null,
    loading : null,
    errorMsg : null,
    successMsg : null
}

const sliderReducer = (state=initialState, action) =>{
    switch(action.type){
        case actionTypes.FETCH_SLIDATA_START:
            return{
                ...state, 
                loading : true
            }

        case actionTypes.FETCH_SLIDATA_SUCCESS:
            return{
                ...state,
                loading : false,
                sliderData : action.payload
            }
        case actionTypes.FETCH_SLIDATA_FAILED:
            return{
                ...state,
                loading : false,
                errorMsg : action.payload
            }

        default:
            return state;
    }

}

export default sliderReducer;