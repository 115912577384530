import React, { Fragment } from "react";
import Breadcrumb from "../components/common/breadcrumb";
import client from "../Api/HTTPClient";
import api from "../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import {
  DollarSign,
  MapPin,
  X,
  TrendingDown,
  ArrowUp,
  ShoppingCart,
  Search,
  Activity,
  User
} from "react-feather";
import { Redirect } from "react-router-dom";
import { Link  } from "react-router-dom";

// const BorderTable = () => {
class BorderTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      customer_id: this.props.match.params.customer_id,
      isLoaded: false,
      items: [],
      customer_info: null,
      wallet_info: null,
      transactions:null
    };
  }

  view_details=async(cust_id)=>{
    await this.setState({ customer_id: cust_id });
     //console.log("customer_id ="+this.state.customer_id);
    this.getCustomers();
    
  }

  componentDidMount() {
    let { customer_id } = this.state;

    // alert("hrer");
    this.getCustomers();
  }

  

  // displayDate(date) {
  //   let transaction_date = new Date(date);
  //   return (
  //       <Text>
  //           {`${transaction_date.getDate()} ${mowStrings.monthNamesShort[(transaction_date.getMonth())]}, ${transaction_date.getFullYear()} ${transaction_date.getHours() <= 9 ? `0${transaction_date.getHours()}` : `${transaction_date.getHours()}`}:${transaction_date.getMinutes() <= 9 ? `0${transaction_date.getMinutes()}` : `${transaction_date.getMinutes()}` }`}
  //       </Text>
  //   )
  //   // 
// }

  getCustomers = async () => {
    let { customer_id } = this.state;
    let postdata = {customer_id : customer_id};
    let current = this;
    current.setState({ loading: true });

    //console.log("postdata :"+JSON.stringify(postdata));
    client.post(api.customers_wallet, postdata, async function(error, response) {
      if (!error) {
        // alert(JSON.stringify(response));
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              current.setState({ customer_info: response.result.customer_info, wallet_info: response.result.wallet_info, transactions: response.result.transactions.reverse() });
              // current.props.navigation.navigate("Verification");
            } else {
              setTimeout(() => {
                toast.error(response.message);
              }, 200);
            }
          } catch (e) {
            setTimeout(() => {
              toast.error("Internal error occured. Please contact support");
            }, 200);
            // _showToast.error("Internal error occured. Please contact support.");
            // currentComponent.setState({ errormessage: "Internal error." });
          }
        } else {
          setTimeout(() => {
            toast.error("Internal error occured. Please contact support");
          }, 200);
          //   _showToast.error("Internal error occured. Please contact support.");
        }
      } else {
        setTimeout(() => {
          toast.error("Internal error occured. Please contact support");
        }, 200);
        // alert(JSON.stringify(error));
        // _showToast.error("Internal error occured. Please contact support.");
      }
      current.setState({ loading: false });
    });
  };

  renderTitle (item, ) {

    console.log(item);
    let sender_recv_id='';
    

    const { customer_id } = this.state;
    
    let title = "";
    let subtitle = "";
    let from_type=false;
    if (item.type == 1) {
        title = "Added to wallet from:";
        subtitle = `**${item.card_number[12]}${item.card_number[13]}${item.card_number[14]}${item.card_number[15]}`;
        
    } else if (item.type == 3) {
           from_type = true;
        let display_data = {
            from_name: '',
            from_mobile : '',
            to_name : '',
            to_mobile : '',
            
        }
        try{
            display_data = JSON.parse(item.display_data);
        }catch (error) {
            
        }

        if (customer_id == item.to_customer_id) {
            title = "Received From ";
            subtitle = display_data.from_name =="" ? display_data.from_mobile : display_data.from_name;
            sender_recv_id=item.from_customer_id;
        } else {
            title = "Paid To ";
            subtitle = display_data.to_name ==""? display_data.to_mobile : display_data.to_name;
            sender_recv_id=item.to_customer_id;
        }
    } else {
      from_type = true;
        title = "Cashback i-Pay Wallet";
        
        subtitle = " ";
    }
    return (
        <div>
            
            <span
                style={{
                    marginLeft: 5,
                    // fontSize: hp("1.8%"),
                    // fontWeight: "bold",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    textAlign: "left",
                    // color: mowColors.titleTextColor
                }}>
                {title} { subtitle+" " }
                {from_type?(<Link to={`/customer/wallet/${item._id}`} item={item} onClick={() => {this.view_details(sender_recv_id)}} >
                <i style={{color:"green"}}  className="icon-wallet"></i>
                </Link>):( <span> </span> )}
                
            </span>
        </div>
    )
}

render_date=(transaction_date)=>{
   
  const convert_date = new Date(transaction_date)
let  added_date =new Intl.DateTimeFormat('en-GB', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit',second: '2-digit', hour12: false}).format(convert_date)
added_date=added_date.replace(",",";");
return added_date;
  
}

  render() {
    let { customer_info, customer_id, wallet_info, transactions  } = this.state;

    return (
      <Fragment>
      <div className="col-lg-7 col-sm-12" style={{fontSize:"12px"}}>
        <Breadcrumb title={"Wallet"} parent={"Home"} />
        </div>
        {customer_id ? (
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                {customer_info && wallet_info ?
                  <div className="card">
                    <div className="card-header">
                      <h6>
                        {/* {customer_id} */}
                      Name : {customer_info.name}, Mobile : {customer_info.mobile}
                      </h6>
                    </div>
                    <div
                      className="card-body updating-chart height-equal"
                      style={{ paddingBottom: 0 }}
                    >
                      <div
                        className="upadates text-center"
                        style={{ paddingBottom: 0 }}
                      >
                        <h2 className="font-primary">
                          <DollarSign />
                          <span> {  wallet_info.wallet_amount.toFixed(2)} </span>
                        </h2>
                        <p>Current Wallet Points</p>
                      </div>
                    </div>
                    <div className="card-header">
                      <h5>Transaction History</h5>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-border-horizontal table-striped table-light">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            {/* <th scope="col">ID</th> */}
                            <th scope="col">Transaction No</th>
                            <th scope="col">Amount</th>
                            {/* <th scope="col">Type</th> */}
                            <th scope="col">To/From</th>
                            <th scope="col">Status</th>
                            <th scope="col">Date</th>
                            
                          </tr>
                        </thead>
                        <tbody>
                          {transactions ?
                            transactions.map((item, index) => (
                              <tr>
                                <td scope="row">{index + 1}</td>
                                {/* <td>{item._id}</td> */}
                                <td>{item.transaction_no}</td>
                                <td>${item.amount.toFixed(2)}</td>
                                
                                <td>{this.renderTitle(item)}</td>

                                <td>{item.status == 1 ? "Success" : item.status == 0 ? "Processing" : "Failed"}</td>
                                <td>{this.render_date(item.transaction_date)}</td>
                              </tr>
                            ))
                            :
                            null
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                  :
                  null
                  }
              </div>
            </div>
          </div>
        ) : (
          <Redirect to={`${process.env.PUBLIC_URL}/customers/all_customers`} />
        )}
      </Fragment>
    );
  }
}

export default BorderTable;
