import React, { Fragment, useState, useEffect } from "react";

const Features = ({ featureEnableData }) => {
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-xl-12 xl-100">
                        <div className="card">
                            <div className="card-body" style={{ padding: "1rem" }}>
                                <h6 style={{ fontWeight: "bold", marginBottom: "10px" }}>Features</h6>
                                <div className="row" style={{ margin: "0rem" }}>
                                    {
                                        <div className="table-responsive col col-sm-12">
                                            <table className="table table-border-horizontal table-striped table-light">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style={{ width: '5%' }}>#</th>
                                                        <th scope="col" style={{ width: '40%' }}>Features Name</th>
                                                        <th scope="col" style={{ width: '55%' }}>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">{1}</th>
                                                        <td>
                                                            Multiuser
                                                        </td>
                                                        <td>
                                                            {featureEnableData.multi_user == true ? "Enabled" : "Disabled"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">{2}</th>
                                                        <td>
                                                            Online Ordering System
                                                        </td>
                                                        <td>
                                                            {featureEnableData.online_orders == true ? "Enabled" : "Disabled"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">{3}</th>
                                                        <td>
                                                            Table Reservation
                                                        </td>
                                                        <td>
                                                            {featureEnableData.table_reservation == true ? "Enabled" : "Disabled"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">{4}</th>
                                                        <td>
                                                            Offers & vouchers
                                                        </td>
                                                        <td>
                                                            {featureEnableData.offers_vouchers == true ? "Enabled" : "Disabled"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">{5}</th>
                                                        <td>
                                                            Loyalty Cards
                                                        </td>
                                                        <td>
                                                            {featureEnableData.loyalty_cards == true ? "Enabled" : "Disabled"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">{6}</th>
                                                        <td>
                                                            Create Payment Links
                                                        </td>
                                                        <td>
                                                            {featureEnableData.payment_links == true ? "Enabled" : "Disabled"}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Features