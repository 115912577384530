import React, { useEffect, Fragment, useState } from "react";
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Progress, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormInput, FormGroup, Col, Row, Input, CustomInput } from 'reactstrap';
import DisableModal from './othersettings/DisableStatus';
import EditModal from './othersettings/EditModal';
import DeleteModal from './othersettings/CatDeleteModal';
import AddCatSubModal from './othersettings/AddCatSubcatModal';
import EditCatSubModal from './othersettings/EditCatSubcatModal';
import { Toast } from "react-bootstrap";
import useForm from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { DisappearedLoading } from "react-loadingg";
import { Link, useHistory } from "react-router-dom";
import Dropzone from "../../components/dropzone/DropZoneField";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import ReactDragListView from "react-drag-listview";
import DropzoneField from "./dropzone/DropZoneField";
import "../../assets/scss/customTable.scss";
import ClipLoader from "react-spinners/ClipLoader";


const iPayFeatures = [

  { featureName: "POS", featureValue: "pos" },
  { featureName: "Registers", featureValue: "registers" },
  { featureName: "Printer & Cash drawer", featureValue: "printer_and_cash_drawer" },
  { featureName: "Visitor Registry", featureValue: "visitor_registry" },
  { featureName: "Online ordering system", featureValue: "online_ordering_system" },
  { featureName: "Booking Management", featureValue: "table_reservation" },
  { featureName: "Offers & Vouchers", featureValue: "offers_and_vouchers" },
  { featureName: "Loyalty cards", featureValue: "loyalty_cards" },
  { featureName: "Customers", featureValue: "marketing" },
  { featureName: "Payment links", featureValue: "payment_links" },
  { featureName: "All in one QR code - Hospo tools", featureValue: "hospo_tools" },
  { featureName: "Developers", featureValue: "developers" },
  { featureName: "My website - Reviews", featureValue: "mywebsite_reviews" },
  { featureName: "My website - Offers", featureValue: "mywebsite_offers" },
  { featureName: "Price list", featureValue: "price_list" },

];

const OtherSetting = (props) => {

  let [loading, setloading] = useState(false);
  let [loadingbtn, setloadingbtn] = useState(false);
  let [editformOpen, seteditformOpen] = useState(false);
  let [editmodalOpen, seteditmodalOpen] = useState(false);
  let [addmodalOpen, setaddmodalOpen] = useState(false);
  let [editcatmodalOpen, seteditcatmodalOpen] = useState(false);
  let [deletemodalOpen, setdeletemodalOpen] = useState(false);
  let [statusDetails, setStatusDetails] = useState(null);
  let [catsubcat, setcatsubcat] = useState([]);
  let history = useHistory();
  let [categoryId, setCategoryId] = useState(null);
  let [catDeleteId, setCatDeleteId] = useState(null);
  let [categoryName, setCategoryName] = useState(null);
  let [inputList, setInputList] = useState([{ subCategory: "", status: 1 }]);
  let [inputCat, setInputCat] = useState('');
  let [editItem, seteditItem] = useState(null);
  const [featureArr, setFeaturesArr] = useState(iPayFeatures);
  const [lodingModal, setLoadingModal] = useState(false);

  const toggleLoadingModal = () => { setLoadingModal(!lodingModal) };



  const dispatch = useDispatch();

  const slider = catsubcat
  // { slider && console.log(slider) }

  const tableDragProps = {
    onDragEnd(fromIndex, toIndex) {
      let NewStoreTableData = slider;
      let item = NewStoreTableData.splice(fromIndex, 1)[0];
      NewStoreTableData.splice(toIndex, 0, item);
      NewStoreTableData = NewStoreTableData;
      client.post(api.edit_catsubcat, { order: NewStoreTableData, type: 2 }, async function (error, response) {
        if (!error) {
          if (typeof response.error !== "undefined") {
            fetchData();
            toast.success(response.message);
          }
        }
      })
      // console.log(NewStoreTableData);
    },
    nodeSelector: "tr",
  };

  const handleDeleteClick = (category, _id) => {
    let postdata = { business_category: category, show: 'category' }
    setloadingbtn(true);
    setLoadingModal(true);
    client.post(api.merchant_list, postdata, async function (error, response) {
      if (!error) {
        // alert(JSON.stringify(response));
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              if (response.result.merchantDetails.length > 0) {
                setdeletemodalOpen(true);
                setStatusDetails(response.result.merchantDetails);
              } else {
                handleCatDelete(_id);
              }
              setLoadingModal(false);
            } else {
              setTimeout(() => {
                toast.error(response.message);
              }, 200);
              setLoadingModal(false);
            }
          } catch (e) {
            setTimeout(() => {
              toast.error("Internal error occured. Please contact support");
            }, 200);
          }
        } else {
          setTimeout(() => {
            toast.error("Internal error occured. Please contact support");
          }, 200);
          setLoadingModal(false);
        }
      } else {
        setTimeout(() => {
          toast.error("Internal error occured. Please contact support");
        }, 200);
        setLoadingModal(false);
      }
      setloadingbtn(false);
    })
  }

  const handleCatDelete = (id) => {

    setloadingbtn(true);

    client.post(api.delete_catsubcat, { _id: id }, async function (error, response) {
      if (!error) {
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              fetchData();
              toast.success("Deleted Successfully");
            } else {
              toast.error(response.message);
            }
          } catch (e) {
            toast.error("Internal error occured. Please contact support 2");
          }
        } else {
          toast.error("Internal error occured. Please contact support 2");
        }
      } else {
        toast.error("Internal error occured. Please contact support 3");
      }
      setloadingbtn(false);
    });

  }

  const fetchData = () => {

    setloading(true);

    client.post(api.fetch_catsubcat, { show_all: true }, async function (error, response) {
      if (!error) {
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              setcatsubcat(response.result.data);
              setloading(false);
            } else {
              toast.error("Internal error occured. Please contact support");
            }
          } catch {
            toast.error("Internal error occured. Please contact support");
            setloading(false);
          }
        }
      }
    });
  }

  const handleEnableCatMode = (data) => {

    if (data !== '') {

      setloadingbtn(true);

      client.post(api.disable_subcat, data, async function (error, response) {
        if (!error) {
          if (typeof response.error !== "undefined") {
            try {
              if (!response.error) {
                toast.success("Updated Successfully");
                // setcashbackdata(response.result.data);
                fetchData();
              } else {
                toast.error(response.message);
              }
            } catch (e) {
              toast.error("Internal error occured. Please contact support");
            }
          } else {
            toast.error("Internal error occured. Please contact support");
          }
        } else {
          toast.error("Internal error occured. Please contact support");
        }

        setloadingbtn(false);
      });
    }
    else {
      //errors.showMessages();
    }
  }

  const handleEditClick = (category, subcategory, catid) => {

    let postdata = { business_category: category, show: 'category' }
    setloadingbtn(true);
    setLoadingModal(true);
    client.post(api.merchant_list, postdata, async function (error, response) {
      if (!error) {
        //console.log(response);
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              if (response.result.merchantDetails.length > 0) {
                seteditmodalOpen(true);
                setLoadingModal(false);
                setStatusDetails(response.result.merchantDetails);
              } else {
                setInputCat(category);
                setInputList(subcategory);
                seteditItem(catid);
                seteditcatmodalOpen(true);
                setLoadingModal(false);
              }
            } else {
              setTimeout(() => {
                toast.error(response.message);
              }, 200);
            }
          } catch (e) {
            setTimeout(() => {
              toast.error("Internal error occured. Please contact support");
            }, 200);
            // _showToast.error("Internal error occured. Please contact support.");
            // currentComponent.setState({ errormessage: "Internal error." });
          }
        } else {
          setTimeout(() => {
            toast.error("Internal error occured. Please contact support");
          }, 200);
          //   _showToast.error("Internal error occured. Please contact support.");
        }
      } else {
        setTimeout(() => {
          toast.error("Internal error occured. Please contact support");
        }, 200);
        // alert(JSON.stringify(error));
        // _showToast.error("Internal error occured. Please contact support.");
        setLoadingModal(false);
      }
      setloadingbtn(false);
    })
  }

  const handleStatusChange = (category, subcategory, catid, status) => {

    let postdata = { business_category: category, business_sub_category: subcategory }
    setloadingbtn(true);
    client.post(api.merchant_list, postdata, async function (error, response) {
      if (!error) {
        // alert(JSON.stringify(response));
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              if (response.result.merchantDetails.length > 0 && status == 1) {
                seteditformOpen(true);
                setStatusDetails(response.result.merchantDetails);
              } else {
                let changestatus;
                if (status == 1) { changestatus = 0; } else { changestatus = 1 }
                let enabledata = { _id: catid, subCategory: subcategory, status: changestatus }
                handleEnableCatMode(enabledata);
              }
            } else {
              setTimeout(() => {
                toast.error(response.message);
              }, 200);
            }
          } catch (e) {
            setTimeout(() => {
              toast.error("Internal error occured. Please contact support");
            }, 200);
            // _showToast.error("Internal error occured. Please contact support.");
            // currentComponent.setState({ errormessage: "Internal error." });
          }
        } else {
          setTimeout(() => {
            toast.error("Internal error occured. Please contact support");
          }, 200);
          //   _showToast.error("Internal error occured. Please contact support.");
        }
      } else {
        setTimeout(() => {
          toast.error("Internal error occured. Please contact support");
        }, 200);
        // alert(JSON.stringify(error));
        // _showToast.error("Internal error occured. Please contact support.");
      }
      setloadingbtn(false);
    })

  }

  const handleFeatureChange = (item, e) => {
    let postData = {
      catId: item._id,
      [e.target.name]: e.target.checked == true ? 1 : 0,
      checked: e.target.checked,
    }
    // console.log(postData, "pos")
    // setloadingbtn(true);
    client.post(api.update_cat_feat, postData, (error, response) => {
      // console.log(error, response, "err res")
      if (!error) {
        try {
          if (!response.error) {

            setcatsubcat(response.result.data);

            toast.success("Updated Successfully");

          } else {
            setTimeout(() => { toast.error("Internal error occured. Please contact support"); }, 200);
          }
        } catch {
          setTimeout(() => { toast.error("Internal error occured. Please contact support"); }, 200);
        }
      } else {
        setTimeout(() => { toast.error("Internal error occured. Please contact support"); }, 200);
      }
    })
  }

  const updateCatdesc = (inputValue, item) => {

    let postdata = { ...item, category_description: inputValue };
    client.post(api.update_desc, postdata, async function (error, response) {

      if (!error) {
        try {
          if (!response.error) {
            // setcatsubcat(response.result.data);
            fetchData();
            toast.success("Updated Successfully");
          } else {
            setTimeout(() => { toast.error("Internal error occured. Please contact support"); }, 200);
          }
        } catch {
          setTimeout(() => { toast.error("Internal error occured. Please contact support"); }, 200);
        }
      } else {
        setTimeout(() => { toast.error("Internal error occured. Please contact support"); }, 200);
      }

    })
  }

  useEffect(() => {
    fetchData();
    dispatch(actionCreators.fetch_category());
  }, []);

  // console.log(catsubcat, "catsubcat")

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-7 col-sm-12" style={{ fontSize: "12px" }}>
          <Breadcrumb title="Categories" parent="Home" />
          {/* <Breadcrumb title="Other Setting" parent="Home" /> */}
        </div>
        <div className="col-lg-5 col-sm-12 ">
          <div className="btn-group" style={{ paddingTop: "10px", marginLeft: "0.4rem", marginRight: "0.6rem", marginBottom: "0rem", width: "auto", display: "flex", justifyContent: "flex-end", alignItems: "center" }} role="group" aria-label="Basic example">
            {true && <button className="btnCashback custBtnAdmin" onClick={() => setaddmodalOpen(true)} ><i style={{ margin: "0 7px 0 0", color: "#327ebc" }} id="Add New" title="Add New" className="fa fa-plus"></i>Add Category & Subcategory</button>}
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card merchantSetttings" style={{ border: '1px solid rgb(223, 232, 241)', borderRadius: '3px' }}>
              <div className="card-header">
                <h7 class="font-weight-bold" style={{ fontSize: "13px" }}>All Category & Subcategory</h7>
              </div>
              <div className="card-body table" style={{ padding: "1rem" }}>

                {loading ?
                  <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                    <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                      <DisappearedLoading size="medium" color="#514F4E" />
                    </div>
                  </div>

                  : <div className="table-responsive custom-scrollbar">
                    <ReactDragListView {...tableDragProps}>
                      <table className="table table-border-horizontal" >
                        <thead>
                          <tr style={{ background: '#f9fafe' }}>
                            <th scope="col" style={{ fontSize: "13px" }}>#</th>
                            <th scope="col" style={{ fontSize: "13px" }}>Icon</th>
                            {/* <th scope="col" style={{ fontSize: "13px" }}>Png Image</th> */}
                            <th scope="col" style={{ fontSize: "13px", width: '25%' }} >Category</th>
                            <th scope="col" style={{ fontSize: "13px" }} >Subcategory</th>
                            <th scope="col" style={{ fontSize: "13px" }} >Subcategory Status</th>
                            {/* <th scope="col" style={{ fontSize: "13px", width: "24%" }} >Category Features</th>
                            <th scope="col" style={{ fontSize: "13px" }} >Feature status</th> */}
                            <th scope="col" style={{ fontSize: "13px" }} >Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* countries{} */}
                          {catsubcat && catsubcat.length > 0 ? catsubcat.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>#</td>
                                <td>
                                  <Dropzone
                                    name={item._id}
                                    cat={item._id}
                                    profile={item.profile}
                                  />
                                </td>
                                {/* <td>
                                  <DropzoneField
                                    name={item._id}
                                    image={item.pngImage}
                                    data={item}
                                  />

                                </td> */}
                                <td>
                                  <div>
                                    <p style={{ fontSize: "12px" }}>{item.category_name}</p>
                                    <div className="inputDiv mt-1">
                                      <textarea
                                        className="form-control textAreaa"
                                        maxlength="85"
                                        defaultValue={item.category_description}
                                        name="cloud_based_desc"
                                        placeholder="Category description" rows={4} cols={60}
                                        onBlur={(event) => {
                                          let inputValue = event.target.value;
                                          updateCatdesc(inputValue, item);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </td>
                                <td style={{ padding: "0.3rem !important" }}>{item.sub_category.map((sub, si) => {
                                  return (
                                    <p style={{ fontSize: "12px" }}>{sub.subCategory}</p>
                                  )
                                })}</td>
                                <td style={{ padding: "0.3rem !important" }}>{item.sub_category.map((sat, st) => {
                                  return (
                                    <p><input
                                      type="checkbox"
                                      value={sat.status}
                                      defaultChecked={sat.status == 1 ? "true" : ""}
                                      onChange={() => {
                                        handleStatusChange(item.category_name, sat.subCategory, item._id, sat.status);
                                        setCategoryId(item._id);
                                        setCategoryName(sat.subCategory);

                                      }
                                      }
                                    /></p>
                                  )
                                })}</td>

                                {/* <td style={{ verticalAlign: "top" }}>
                                  {iPayFeatures.map((fea) => {
                                    return (<p style={{ fontSize: "12px" }}>{fea.featureName}</p>)
                                  })}


                                </td>
                                <td style={{ verticalAlign: "top" }}>
                                  <p><input
                                    type="checkbox"
                                    value={item.pos}
                                    defaultChecked={item.pos == 1 ? "true" : ""}
                                    name="pos"
                                    onChange={(e) => {
                                      handleFeatureChange(item, e)
                                    }}

                                  /></p>
                                  <p><input
                                    type="checkbox"
                                    value={item.registers}
                                    defaultChecked={item.registers == 1 ? "true" : ""}
                                    name="registers"
                                    onChange={(e) => {
                                      handleFeatureChange(item, e)
                                    }}
                                  /></p>
                                  <p><input
                                    type="checkbox"
                                    value={item.printer_and_cash_drawer}
                                    defaultChecked={item.printer_and_cash_drawer == 1 ? "true" : ""}
                                    name="printer_and_cash_drawer"
                                    onChange={(e) => {
                                      handleFeatureChange(item, e)
                                    }}
                                  /></p>
                                  <p><input
                                    type="checkbox"
                                    value={item.visitor_registry}
                                    defaultChecked={item.visitor_registry == 1 ? "true" : ""}
                                    name="visitor_registry"
                                    onChange={(e) => {
                                      handleFeatureChange(item, e)
                                    }}
                                  /></p>
                                  <p><input
                                    type="checkbox"
                                    value={item.online_ordering_system}
                                    defaultChecked={item.online_ordering_system == 1 ? "true" : ""}
                                    name="online_ordering_system"
                                    onChange={(e) => {
                                      handleFeatureChange(item, e)
                                    }}
                                  /></p>
                                  <p><input
                                    type="checkbox"
                                    value={item.table_reservation}
                                    defaultChecked={item.table_reservation == 1 ? "true" : ""}
                                    name="table_reservation"
                                    onChange={(e) => {
                                      handleFeatureChange(item, e)
                                    }}
                                  /></p>
                                  <p><input
                                    type="checkbox"
                                    value={item.offers_and_vouchers}
                                    defaultChecked={item.offers_and_vouchers == 1 ? "true" : ""}
                                    name="offers_and_vouchers"
                                    onChange={(e) => {
                                      handleFeatureChange(item, e)
                                    }}
                                  /></p>
                                  <p><input
                                    type="checkbox"
                                    value={item.loyalty_cards}
                                    defaultChecked={item.loyalty_cards == 1 ? "true" : ""}
                                    name="loyalty_cards"
                                    onChange={(e) => {
                                      handleFeatureChange(item, e)
                                    }}
                                  /></p>
                                  <p><input
                                    type="checkbox"
                                    value={item.marketing}
                                    defaultChecked={item.marketing == 1 ? "true" : ""}
                                    name="marketing"
                                    onChange={(e) => {
                                      handleFeatureChange(item, e)
                                    }}
                                  /></p>
                                  <p><input
                                    type="checkbox"
                                    value={item.payment_links}
                                    defaultChecked={item.payment_links == 1 ? "true" : ""}
                                    name="payment_links"
                                    onChange={(e) => {
                                      handleFeatureChange(item, e)
                                    }}
                                  /></p>
                                  <p><input
                                    type="checkbox"
                                    value={item.hospo_tools}
                                    defaultChecked={item.hospo_tools == 1 ? "true" : ""}
                                    name="hospo_tools"
                                    onChange={(e) => {
                                      handleFeatureChange(item, e)
                                    }}
                                  /></p>
                                  <p><input
                                    type="checkbox"
                                    value={item.developers}
                                    defaultChecked={item.developers == 1 ? "true" : ""}
                                    name="developers"
                                    onChange={(e) => {
                                      handleFeatureChange(item, e)
                                    }}
                                  /></p>
                                  <p><input
                                    type="checkbox"
                                    value={item.mywebsite_reviews}
                                    defaultChecked={item.mywebsite_reviews == 1 ? "true" : ""}
                                    name="mywebsite_reviews"
                                    onChange={(e) => {
                                      handleFeatureChange(item, e)
                                    }}
                                  /></p>
                                  <p><input
                                    type="checkbox"
                                    value={item.mywebsite_offers}
                                    defaultChecked={item.mywebsite_offers == 1 ? "true" : ""}
                                    name="mywebsite_offers"
                                    onChange={(e) => {
                                      handleFeatureChange(item, e)
                                    }}
                                  /></p>
                                  <p><input
                                    type="checkbox"
                                    value={item.price_list}
                                    defaultChecked={item.price_list == 1 ? "true" : ""}
                                    name="price_list"
                                    onChange={(e) => {
                                      handleFeatureChange(item, e)
                                    }}
                                  /></p>



                                </td> */}
                                <td>
                                  <span
                                    style={{ marginRight: "10px", fontSize: "1rem", color: "black" }}
                                    onClick={() => { handleEditClick(item.category_name, item.sub_category, item._id) }}
                                  >
                                    <i className="fa fa-edit"></i>
                                  </span>
                                  <span
                                    style={{ marginRight: "10px", fontSize: "1rem", color: "red" }}
                                    onClick={() => handleDeleteClick(item.category_name, item._id)}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </span>
                                </td>
                              </tr>
                            )
                          })
                            : null
                          }
                        </tbody>
                      </table>
                    </ReactDragListView>
                  </div>}
              </div>
            </div>
          </div>
        </div>
      </div>

      <DisableModal
        isOpen={editformOpen}
        items={statusDetails}
        catid={categoryId}
        catname={categoryName}
        status="0"
        onClose={() => {
          seteditformOpen(false);
          setCategoryId(null);
          setStatusDetails(null);
          setCategoryName(null);
        }}
      />

      <EditModal
        isOpen={editmodalOpen}
        items={statusDetails}
        catid={categoryId}
        catname={categoryName}
        onClose={(cleardata = true) => {
          if (cleardata) {
            seteditmodalOpen(false);
          } else {
            seteditmodalOpen(false);
          }
        }}
      />

      <AddCatSubModal
        isOpen={addmodalOpen}
        onClose={(cleardata = false) => {
          setaddmodalOpen(false);
          setInputCat('');
          seteditItem(null);
          setInputList([{ subCategory: "", status: 1 }]);
        }}
        fetchData={fetchData}
      />

      <EditCatSubModal
        isOpen={editcatmodalOpen}
        category={inputCat}
        subCategory={inputList}
        catid={editItem}
        onClose={(cleardata = false) => {
          seteditcatmodalOpen(false);
          setInputCat('');
          seteditItem(null);
          setInputList([{ subCategory: "", status: 1 }]);
        }}
        fetchData={fetchData}
      />

      <DeleteModal
        isOpen={deletemodalOpen}
        items={statusDetails}
        catid={catDeleteId}
        onClose={() => {
          setdeletemodalOpen(false);
          setCatDeleteId(false);
        }}
      />

      <Modal
        isOpen={lodingModal}
        toggle={toggleLoadingModal}
        className="modal-body"
        centered={true}
        style={{ maxWidth: "350px" }}
      >
        <ModalBody style={{ height: "160px", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div className="w-100 d-flex flex-column align-items-center">
            {/* <Progress animated className="my-3" value={100} /> */}
            <ClipLoader
              color={"#0092DC"}
              loading={true}
              size="40"
            />
            <h3 className="mb-0 text-center mt-3" style={{ fontSize: "18px" }}>Please wait...</h3>
          </div>
        </ModalBody>
      </Modal>

    </Fragment>
  );

}

export default OtherSetting;