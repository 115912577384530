import React, { useEffect, Fragment, useState } from "react";
import { useHistory} from "react-router-dom";
import Breadcrumb from '../../../components/common/breadcrumb';
// import AddForm from './AddForm';
import EditForm from './EditForm';
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import {DisappearedLoading} from "react-loadingg";
import {Link} from "react-router-dom";

function Countries() {

    let [loading, setloading] = useState(true);
    // let [addformOpen, setaddformOpen] = useState(false);
    let [editformOpen, seteditformOpen] = useState(false);
    let [editItem, seteditItem] = useState(null);
    let [countries, setcountries] = useState([]);

    async function fetchData() {
        client.post(api.fetch_country, {show_all : true}, async function (error, response) {
            // console.log(response)
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            setcountries(response.result.data);
                            
                        }
                    } catch {
                        
                    }
                }
            }
            setloading(false);
        });
    }

    useEffect(() => {
        fetchData();

        // fetch("https://swapi.co/api/planets/4/")
        //     .then(res => res.json())
        //     .then(res => this.setState({ planets: res }))
        //     .catch(() => this.setState({ hasErrors: true }))
    }, []);


    return (
        <Fragment>
            <div className="row">
                <div className="col-lg-7 col-sm-12" style={{fontSize:"12px"}}>
                    <Breadcrumb title="Countries" parent="Home" />
                </div>
                <div className="col-lg-5 col-sm-12 ">
                    {/* <div className="btn-group pull-right" style={{ paddingTop: "15px" }} role="group" aria-label="Basic example">
                        <button className="btn btn" onClick={() => { setaddformOpen(true) }} style={{border:"none",height:"2rem",width:"auto",backgroundColor:"#f6f7fb", color:"grey",cursor:"pointer",outline:"none",display:"flex",alignItems:"center",paddingRight:"1rem",marginRight:"0.5rem"}}>
                            <i style={{ margin: "0 7px 0 0",color: "#4466f2" }} id="Add New" title="Add New" className="fa fa-plus"></i>
                                Add new country
                            </button>
                    </div> */}
                    <div className="btn-group pull-right"  style={{ paddingTop: "8px",marginLeft:"0.4rem",marginRight:"0.6rem",marginBottom:"0rem"}} role="group" aria-label="Basic example">
                    <Link to={`${process.env.PUBLIC_URL}/settings/countries/addCountryForm`}>
                        <button className="btn custBtnAdmin">
                            <i style={{ margin: "0 7px 0 0",color: "#327ebc" }} id="Add New" title="Add New" className="fa fa-plus"></i>
                                Add new country
                            </button>
                    </Link>
                    </div>
                </div>
            </div>
            
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card" style={{ border: '1px solid rgb(223, 232, 241)', borderRadius: '3px'}}>
                            <div className="card-body" style={{ padding: "1rem" }}>
                                {!loading ?
                                    <div className="table-responsive" >
                                        <table className="table table-border-horizontal">
                                            <thead>
                                                <tr style={{background: '#f9fafe'}}>
                                                    <th scope="col">#</th>
                                                    <th scope="col" >Country Name</th>
                                                    <th scope="col" >Country Code</th>
                                                    <th scope="col" >Phone Code</th>
                                                    <th scope="col" >Phone Regex</th>
                                                    <th scope="col" >Currency</th>
                                                    <th scope="col" >Currency Symbol</th>
                                                    <th scope="col" >Status</th>
                                                    <th scope="col" >Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* countries{} */}
                                                {countries && countries.length > 0 ? countries.map((item, index) => {
                                                    return (
                                                        <tr>
                                                            <td>#</td>
                                                            <td>{item.country_name}</td>
                                                            <td>{item.country_code}</td>
                                                            <td>{item.phone_prefix}</td>
                                                            <td>{item.phone_regex}</td>
                                                            <td>{item.currency_code}</td>
                                                            <td>{item.currency_symbol}</td>
                                                            <td>{item.status == 1 ? `Active` : ``}</td>
                                                            <td>
                                                                <span style={{ marginRight: "10px", fontSize: "0.75rem", color: "black" }} onClick={() => { seteditformOpen(true); seteditItem(item); }}>
                                                                    <i className="fa fa-edit"></i>
                                                                </span>
                                                                <Link to={{
                                                                    pathname:`${process.env.PUBLIC_URL}/settings/location-details`,
                                                                    state: { countryCode: item.country_code, countryId: item._id }
                                                                }}>
                                                                    <span style={{ marginRight: "10px", fontSize: "0.75rem", color: "black" }}>
                                                                        <i className="fa fa-plus"></i>
                                                                    </span>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                    : null
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <div className="loader-box" style={{height:"100vh",width:"100wh"}}>
                              <div className="loader" style={{ display:"flex",alignItems:"center" }}>
                              <DisappearedLoading size="medium" color="#514F4E" />
                               </div> 
                               </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <AddForm isOpen={addformOpen} onClose={(fetch_data = false) => {
                setaddformOpen(false);
                if (fetch_data) {
                    fetchData();
                }
            }} /> */}

            <EditForm isOpen={editformOpen} item={editItem} onClose={(fetch_data = false) => {
                seteditformOpen(false);
                seteditItem(null);
                if (fetch_data) {
                    fetchData();
                }
            }} />
            
        </Fragment>
    );

}

export default Countries;