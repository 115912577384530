import React, { Component, useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormGroup, Col, Row, Input, CustomInput } from 'reactstrap';
import useForm from "react-hook-form";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

let AddEntryModal = (props) => {

    let [loadingbtn, setloadingbtn] = useState(false);
    let [selectedReceiver, setselectedReceiver] = useState([]);
    let [showerrorstatus, setshowerrorstatus] = useState(false);
    let [inputList, setInputList] = useState([{ features: "", description: "" }]);

    const { handleSubmit, register, errors } = useForm();

    useEffect(() => {
    });

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    const handleAddClick = () => {
        setInputList([...inputList, { features: "", description: "" }]);
    };

    const onSubmit = form_data => {
        console.log(inputList)
        if (form_data !== '') {

            setloadingbtn(true);

            client.post(api.add_features, inputList, async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                props.toggleModal(1);
                                setInputList([{ features: "", description: "" }]);
                                toast.success("Added Successfully");
                                // setcashbackdata(response.result.data);
                            } else {
                                toast.error(response.message);
                            }
                        } catch (e) {
                            toast.error("Internal error occured. Please contact support");
                        }
                    } else {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }

                setloadingbtn(false);
            });
        }
        else {
            setshowerrorstatus(true);
            errors.showMessages();
        }
    }



    return (

        <Modal isOpen={props.isOpen} toggle={props.toggleModal} className="modal-body" centered={true}>
            <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={props.toggleModal}>Add Descriptive Features</ModalHeader>
                <ModalBody>

                    <div className="row">
                        <div className="col-md-12">
                            <FormGroup className="w-100">


                                <Label for="level_title">Feature Name</Label>
                                <Label for="level_title" style={{ marginLeft: "22%" }}>Feature Description</Label>
                                {inputList.map((x, i) => {

                                    return (
                                        <Row form>
                                            <Col md={5}>
                                                <input
                                                    name="features" className="form-control form-control-sm mb-2" type="text"
                                                    ref={register({ required: true })}
                                                    value={x.features}
                                                    onChange={e => handleInputChange(e, i)}
                                                />
                                                <span>{errors.features && 'Feature is required'}</span>
                                            </Col>
                                            <Col md={6}>
                                                <input
                                                    name="description" className="form-control form-control-sm mb-2" type="text"
                                                    ref={register()}
                                                    value={x.description}
                                                    onChange={e => handleInputChange(e, i)}
                                                />
                                                {/* <span>{errors.description && 'Feature is required'}</span> */}
                                            </Col>
                                            <Col md={1} >
                                                {inputList.length !== 1 && <button
                                                    className="btn btn-outline-danger btn-sm mb-2 pl-2 pr-2"
                                                    onClick={() => handleRemoveClick(i)}><i id="Remove" title="Remove Subcategory" className="fa fa-minus"></i></button>}
                                                {inputList.length - 1 === i && <button className="btn btn-outline-success btn-sm pl-2 pr-2" onClick={handleAddClick}><i id="Add New" title="Add New" className="fa fa-plus"></i></button>}
                                            </Col>
                                        </Row>
                                    )
                                })}
                            </FormGroup>
                        </div>
                    </div>

                </ModalBody>
                <ModalFooter>
                    {!loadingbtn ?
                        <>
                            <Button color="default" type="button" onClick={props.toggleModal}>Cancel</Button>
                            <Button color="primary" type="submit" onClick={() => setshowerrorstatus(true)} >Save</Button>
                        </>
                        :
                        <div className="loader-box pull-right" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 20, width: 20 }}></span></div>
                    }
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default AddEntryModal;