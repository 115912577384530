import React, { useState, Fragment, useEffect } from "react";
import api from "../../constant/apilist";
import client from "../../Api/HTTPClient";
import { Table, Badge, Button, Modal, ModalHeader, ModalBody, ModalFooter, } from 'reactstrap';
import moment from "moment";
import Icon from "@mdi/react";
import PaginationComponent from "react-reactstrap-pagination";
import { DisappearedLoading } from "react-loadingg";
import { toast } from "react-toastify";
import CloseIcon from "mdi-react/CloseIcon";
import BeatLoader from "react-spinners/BeatLoader";
import { mdiTrashCanOutline, mdiEye, mdiContentCopy, mdiDownloadOutline } from "@mdi/js";
import RenderReactTooltip from "./ReactTooltip.jsx";
import ViewTransaction from "./ViewTransaction.js";
import Swal from "sweetalert2";
import axios from "axios";
import fileDownload from "js-file-download";


const RequestPayments = ({ merchant_info, merchant_id }) => {
    const [data, setData] = useState(0)
    const [requestData, setRequestData] = useState();
    const [pageSize, setPageSize] = useState(15);
    const [pageIndex, setPageIndex] = useState(1);
    const [searchInputVal, setSearchInputVal] = useState(null);
    const [loading, setLoading] = useState(true);
    const [viewTransaction, setViewTransaction] = useState(false);
    const [transactionData, setTransactionData] = useState({
        merchant_id: merchant_id,
        wallet_amount: '',
        data: null,
        view_type: 0,
        disabled_refund: '',
    });
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [selectedIDs, setselectedIDs] = useState("");
    const [deleteLoyaltyCard, setDeleteLoyaltyCard] = useState(false);
    const [copyTextToolTip, setCopyTextToolTip] = useState(false);
    const [getTooltip, setGetToolTip] = useState();

    const fetchPaymentLinksData = (searchValue) => {
        const postData = {
            merchant_id: merchant_id,
            request_from: 0,
            pageSize,
            pageIndex: activePage - 1,
            searchInputVal: searchValue == "reset" ? null : searchInputVal,
        }
        client.post(api.link_requests, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    // console.log(response,'request-response')
                    setRequestData(response.result);
                    setData(response.recorsCount);
                    setLoading(false);
                } else {
                    console.log(error, 'error')
                }
            } else {
                console.log(error, 'error-1')
            }
        });
    };

    useEffect(() => {
        fetchPaymentLinksData();
    }, [pageSize, pageIndex, activePage])


    const getLinkDomain = (type = 1) => {
        const host = client.url("");
        let api_domain = host.replace(/(https?:\/\/)?(www.)?/i, '');
        if (api_domain.indexOf('/') !== -1) {
            api_domain = api_domain.split('/')[0];
        }

        if (type == 2) {
            let hostName = window?.location?.hostname.replace("dm.portal.", "");
            hostName = hostName.replace("portal.", "");

            if (api_domain == "dmapi.ipaypro.co") {

                return `https://dm.${hostName}`;
            } else {
                return `https://${hostName}`;
            }

        } else {

            if (api_domain == "dmapi.ipaypro.co") {
                return "https://demo.payments.linky.biz";
            } else {
                return "https://payments.linky.biz";
            }

        }

    }

    const getTimeZone = () => {

        try {
            const company = JSON.parse(localStorage.getItem('@_company'));

            if (company && company.timeZone) {
                return company.timeZone;
            } else {
                return "Australia/Sydney";
            }
        } catch {
            return "Australia/Sydney";
        }

    }

    const copyTextToolTipToggle = () => {
        setCopyTextToolTip(!copyTextToolTip);
    };

    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };
        return (
            <p className="text">
                {isReadMore ? text.slice(0, 20) : text}
                {text.length >= 20 ? (
                    <span
                        style={{ cursor: "pointer", color: "#D23F99", marginLeft: "3px" }}
                        onClick={toggleReadMore}
                        className="read-or-hide"
                    >
                        {isReadMore
                            ? `\n...Read More`.replace(
                                /(.{7})/g,
                                "$1\n"
                            )
                            : `\n Show Less`.replace(
                                /(.{6})/g,
                                "$1\n"
                            )}
                    </span>
                ) : null}
            </p>
        );
    };

    const ShowCurrencyNum = (amount, showSymbol) => {
        const merchantData = JSON.parse(localStorage.getItem("merchant"));
        const currency_symbol = merchantData?.country?.currency_symbol;
        if (amount == undefined) {
            return currency_symbol;
        }

        let getCurrency = !isNaN(parseFloat(amount)) ? amount : 0;

        if (merchantData && merchantData.country) {
            if (showSymbol && currency_symbol) {
                if (getCurrency < 0) {
                    getCurrency = `-${currency_symbol}${Math.abs(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                } else {
                    getCurrency = `${currency_symbol}${parseFloat(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                }
            }
            else {
                getCurrency = +`${parseFloat(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
            }
        }

        return getCurrency;
    }

    const RenderRefundStatus = ({ refund_data }) => {

        if (refund_data) {

            return (
                <>
                    {refund_data.type == 1 ?
                        <Badge color="disabled" style={{ width: '80%', display: 'flex', justifyContent: 'center' }}>Refunded</Badge>
                        :
                        <div className='d-flex flex-column align-items-center'>
                            <Badge color="disabled"
                                style={{
                                    width: '80%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                Partially refunded
                            </Badge>
                            <span>({ShowCurrencyNum(refund_data?.amount, true)})</span>
                        </div>


                    }
                </>
            )
        } else {
            return null
        }
    }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
        fetchPaymentLinksData();
    }

    const handleSearch = (event) => {
        event.preventDefault();
        if (searchInputVal == null || searchInputVal == "") {
            fetchPaymentLinksData("reset");
        }
        fetchPaymentLinksData(searchInputVal);
    }

    const toggleDeleteModal = (item) => {
        setDeleteLoyaltyCard(!deleteLoyaltyCard);
        setselectedIDs(item);
    };

    const deleteCard = (item) => {
        setDeleteLoader(true);
        let post = { link_id: item };

        client.post(api.delete_requests, post, (error, response) => {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            setLoading(false);
                            toggleDeleteModal();
                            fetchPaymentLinksData();
                            toast.success("Deleted Successfully");
                            setDeleteLoader(false);
                        } else {
                            toast.error("Internal error occured.Please contact support.");
                            setLoading(false);
                            setDeleteLoader(false);
                        }
                    } catch (e) {
                        toast.error("Internal error occured.Please contact support.");
                        setLoading(false);
                        setDeleteLoader(false);
                    }
                } else {
                    toast.error("Internal error occured.Please contact support.");
                    setLoading(false);
                    setDeleteLoader(false);
                }
            } else {
                toast.error("Internal error occured.Please contact support.");
                setLoading(false);
                setDeleteLoader(false);
            }
            setLoading(false);
            setDeleteLoader(false);
        });
    };

    const onDeleteCardModal = (item) => {
        return (
            <Modal
                isOpen={deleteLoyaltyCard}
                toggle={toggleDeleteModal}
                centered={true}
                style={{ maxWidth: "400px" }}
            >
                <form style={{ paddingInline: "" }}>
                    <ModalHeader
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "0.25rem",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <div
                                style={{
                                    width: 40,
                                    height: 40,
                                    borderRadius: "50%",
                                    border: "1px solid #E74C3C",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <CloseIcon color={"#E74C3C"} />
                            </div>
                            <span style={{ marginTop: "0.25rem", marginBottom: "0.5rem" }}>

                                <b>
                                    Attention!
                                </b>
                            </span>
                        </div>
                    </ModalHeader>
                    <ModalBody style={{ textAlign: 'center' }}>
                        Are you sure you want to delete Payment Link?
                    </ModalBody>
                    <ModalFooter
                        style={{ justifyContent: "space-evenly", borderTop: "none" }}
                    >
                        <Button
                            size={"sm"}
                            style={{ marginBottom: 0, background: "#e7e2e2" }}
                            onClick={() => toggleDeleteModal()}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="danger"
                            id="deleteBtn"
                            size={"sm"}
                            style={{ marginBottom: 0 }}
                            onClick={(e) => deleteCard(item)}
                        >
                            {deleteLoader ? (
                                <BeatLoader color={"#fff"} loading={true} size="7" />
                            ) : (
                                "Delete"
                            )}
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>
        );
    };

    const downloadTaxInvoice = (data) => {

        try {

            Swal.fire({
                title: 'Generating Invoice',
                html: ' Please Wait...!',
                width: 400,
                // allowOutsideClick: false,
                onOpen: () => { Swal.showLoading() },
            });

            axios.get(client.url(`${api.downloadInvoice}?request_id=${data._id}&action=download&type=${1}`), {
                responseType: "blob",
                headers: { "x-access-token": "ipay-access-token", },
                params: {},
            }).then((res) => {
                Swal.close();
                fileDownload(res.data, `Invoice.pdf`);

            }).catch(function (error) {
                toast.error('Error occured while generating invoice');
                Swal.close();
            });

        } catch (error) {
            console.log(error, "error");
            toast.error('Error occured while generating invoice');
            Swal.close();
        }
    }

    return (
        <Fragment>
            {onDeleteCardModal(selectedIDs)}
            {viewTransaction ?
                <ViewTransaction data={transactionData} merchant_id={merchant_info?._id} countryData={merchant_info?.country} onBackClick={() => setViewTransaction(!viewTransaction)} />
                :
                <div>
                    {loading && <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                        <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                            <DisappearedLoading size="medium" color="#514F4E" />
                        </div>
                    </div>}
                    <div className="m-1 p-1 mt-2" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div >
                            <form onBlur={handleSearch} onSubmit={(event) => handleSearch(event)}>
                                <div >
                                    <input style={{
                                        border: "none",
                                        borderBottom: "1px solid rgb(197, 189, 189)",
                                        borderRadius: "0",
                                        margin: "3px 10px",
                                        fontSize: "0.75rem",

                                    }}
                                        type="text"
                                        placeholder="Search"
                                        onChange={(e) => {
                                            setSearchInputVal(e.target.value)
                                            fetchPaymentLinksData(searchInputVal);
                                        }}
                                    />
                                </div>
                            </form>
                        </div>
                        {(activePage) * pageSize > requestData?.length ?
                            <p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>Showing {((activePage - 1) * pageSize) + 1} to {((activePage - 1) * pageSize) + requestData?.length} of {data} entries</p> :
                            <p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>Showing {((activePage - 1) * pageSize) + 1} to {(activePage) * pageSize} of {data} entries</p>}
                    </div>
                    <div className="table-responsive mx-1 px-1 my-1 py-1">
                        <Table className="table table--border-horizontal">
                            <thead>
                                <tr>
                                    <th className="border">#</th>
                                    <th className="border">Date/Time</th>
                                    <th className="border">Payment No</th>
                                    <th className="border">Links</th>
                                    <th className="border">Description</th>
                                    <th className="border">Due Date</th>
                                    <th className="border">Online Fees</th>
                                    <th className="border">Service Fees</th>
                                    <th className="border">T&C</th>
                                    <th className="border">Amount</th>
                                    <th className="border">Status</th>
                                    <th className="border">Action</th>
                                </tr>
                            </thead>
                            {
                                requestData?.length ? (
                                    <tbody>
                                        {
                                            requestData.map((item, ind) => {
                                                let recordIndex = pageSize * (activePage - 1) + (ind + 1);
                                                const convert_date = new Date(item.request_date);
                                                const added_date = moment.tz(convert_date, getTimeZone()).format("DD/MM/YYYY HH:mm");
                                                let dueDate = new Date(item.request_date);
                                                dueDate.setDate(dueDate.getDate() + 60);
                                                dueDate = moment.tz(dueDate, getTimeZone()).format("DD/MM/YYYY");
                                                return (
                                                    <tr>
                                                        <td className="border">{recordIndex}</td>
                                                        <td className="border">
                                                            {`${added_date}`.replace(
                                                                /(.{10})/g,
                                                                "$1\n"
                                                            )}
                                                        </td>
                                                        <td className="border">
                                                            {`INT-${item.token_id}`.replace(
                                                                /(.{10})/g,
                                                                "$1\n"
                                                            )}
                                                        </td>
                                                        <td className="border" style={{ width: '10px' }}>
                                                            <div
                                                                style={{
                                                                    color: "#3FA4FC",
                                                                    cursor: "pointer",
                                                                    whiteSpace: 'initial',
                                                                    wordBreak: 'break-word',
                                                                    minWidth: 218,
                                                                    maxWidth: 220,
                                                                }}
                                                                id={`Url-link-${item._id}`}
                                                                onClick={() => {
                                                                    navigator.clipboard.writeText(
                                                                        `${getLinkDomain()}/paynow/${item.token_id
                                                                        }`
                                                                    );
                                                                    copyTextToolTipToggle();
                                                                    setGetToolTip(item._id);
                                                                }}
                                                            >
                                                                {`${getLinkDomain()}/paynow/${item.token_id
                                                                    }`}
                                                                <span id={`link-copy1212-${item._id}`} style={{ cursor: 'pointer' }}>
                                                                    <Icon
                                                                        path={mdiContentCopy}
                                                                        className="mdi_icon_style"
                                                                        size={.6}
                                                                        color="#414a4c"
                                                                        onClick={() => {
                                                                            navigator.clipboard.writeText(
                                                                                `${getLinkDomain()}/paynow/${item.token_id
                                                                                }`
                                                                            );
                                                                            copyTextToolTipToggle();
                                                                            setGetToolTip(item._id);
                                                                        }}
                                                                    />
                                                                </span>
                                                            </div>

                                                            {copyTextToolTip &&
                                                                getTooltip === item._id && (
                                                                    <RenderReactTooltip
                                                                        placement="right"
                                                                        targetId={`link-copy1212-${item._id}`}
                                                                        tooltipBody={<p className="m-0 p-0">URL Copied!</p>}
                                                                        isOpen={copyTextToolTip}
                                                                        toggle={copyTextToolTipToggle}
                                                                    />
                                                                )}
                                                        </td>
                                                        <td className="border">
                                                            {
                                                                item.note
                                                                    ? `${item.note
                                                                        .charAt(0)
                                                                        .toUpperCase()}${item.note
                                                                            .slice(1)
                                                                    }`
                                                                    : ""
                                                            }
                                                        </td>
                                                        <td className="border">
                                                            {item.expire_date
                                                                ? moment
                                                                    .tz(item.expire_date, getTimeZone())
                                                                    .format("DD/MM/YYYY")
                                                                : dueDate}
                                                        </td>
                                                        <td className="border">
                                                            {item.charges_payer == 1
                                                                ? 'On Customer'
                                                                : 'On Store'}
                                                        </td>
                                                        <td className="border">
                                                            {item.ipay_charges_payer == 0
                                                                ? 'On Store'
                                                                : 'On Customer'}
                                                        </td>

                                                        <td className="border">
                                                            {item.terms_and_condition ? (
                                                                <div>
                                                                    <ReadMore>
                                                                        {item.terms_and_condition}
                                                                    </ReadMore>
                                                                </div>
                                                            ) : (
                                                                <div> - </div>
                                                            )}
                                                        </td>
                                                        <td className="border">
                                                            {
                                                                item.amount > 0
                                                                    ? `${ShowCurrencyNum(item.amount.toFixed(2), true)}`
                                                                    : `-`
                                                            }
                                                        </td>
                                                        <td className="border">
                                                            {item.refund_status == 1 ? (
                                                                <div className="d-flex justify-content-center">
                                                                    <RenderRefundStatus
                                                                        refund_data={item?.refund_data}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    {item.status == 0 ? (
                                                                        <div className="d-flex justify-content-center">
                                                                            <Badge style={{
                                                                                background: '#FFBD57',
                                                                                color: 'white',
                                                                                width: '80%',
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                            }}>Unpaid</Badge>
                                                                        </div>
                                                                    ) : item.status == 1 ? (
                                                                        <div className="d-flex justify-content-center">
                                                                            <Badge style={{
                                                                                background: 'green', color: 'white', width: '80%', display: 'flex',
                                                                                justifyContent: 'center',
                                                                            }}>Paid</Badge>
                                                                        </div>
                                                                    ) : item.status == 2 ? (
                                                                        <span
                                                                            style={{
                                                                                backgroundColor: "transparent",
                                                                                borderRadius: "20px",
                                                                                color: "red",
                                                                            }}
                                                                        >
                                                                            Expired
                                                                        </span>
                                                                    ) : item.status == 3 ? (
                                                                        <span
                                                                            style={{
                                                                                backgroundColor: "transparent",
                                                                                borderRadius: "20px",
                                                                                color: "red",
                                                                                cursor: "pointer",
                                                                            }}
                                                                        // onClick={() =>
                                                                        //     handleSuspicious(
                                                                        //         item.suspiciousReason
                                                                        //     )
                                                                        // }
                                                                        >
                                                                            Marked Suspicious
                                                                        </span>
                                                                    ) : null}
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td className="border">
                                                            <div>
                                                                {item.status == 1 ? (
                                                                    <div className='d-flex align-items-center justify-content-center'>
                                                                        <Button
                                                                            outline
                                                                            style={{
                                                                                padding: "4px 6px",
                                                                            }}
                                                                            className="d-flex align-items-start m-0"
                                                                            color="success"
                                                                            onClick={() => {
                                                                                setViewTransaction(!viewTransaction)
                                                                                setTransactionData({
                                                                                    merchant_id: merchant_id,
                                                                                    wallet_amount: '',
                                                                                    data: item,
                                                                                    view_type: 0,
                                                                                    disabled_refund: '',
                                                                                })
                                                                            }}
                                                                        >
                                                                            <Icon
                                                                                path={mdiEye}
                                                                                className="mx-auto"
                                                                                size={0.7}
                                                                                color="success"
                                                                            />
                                                                        </Button>
                                                                        <Button
                                                                            outline
                                                                            style={{ padding: "4px 6px" }}
                                                                            className="d-flex align-items-start my-0 ml-2 mr-0"
                                                                            color="success"
                                                                            onClick={() => { downloadTaxInvoice(item) }}
                                                                        >
                                                                            <Icon path={mdiDownloadOutline} className="mx-auto" size={0.7} color="success" />
                                                                        </Button>
                                                                    </div>

                                                                ) : item.status == 0 ? (
                                                                    <Button
                                                                        outline
                                                                        style={{ padding: "4px 6px" }}
                                                                        className="d-flex align-items-start m-0"
                                                                        color="danger"
                                                                        onClick={() => {
                                                                            toggleDeleteModal(item);
                                                                        }}
                                                                    >
                                                                        <Icon
                                                                            path={mdiTrashCanOutline}
                                                                            className="mx-auto"
                                                                            size={0.6}
                                                                            color="#DC3545"
                                                                        />
                                                                    </Button>
                                                                ) : null}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                ) :
                                    <h6 style={{ textAlign: "center", marginTop: "1rem" }}> No records found! </h6>

                            }
                        </Table>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <select className="custom-select" style={{ width: "35%", fontSize: "0.63rem", marginRight: "10px" }} onChange={(event) => setPageSize(event.target.value)} value={pageSize}>
                                    <option value="15">15</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="75">75</option>
                                    <option value="100">100</option>
                                </select>
                                <span style={{ fontSize: "0.63rem" }}>Records per Page</span>
                            </div>
                            <PaginationComponent
                                totalItems={data}
                                pageSize={pageSize}
                                onSelect={handlePageChange}
                                defaultActivePage={activePage}
                            />
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    )
}

export default RequestPayments