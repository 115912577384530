import React, { useState, useRef, useEffect } from 'react';
import {
    Button,
    ButtonToolbar,
    Modal,
    CardBody,
    Col,
    Input,
    ModalHeader
} from 'reactstrap';

const ViewMenuTypePopup = (props) => {
    return (

        <Modal
            centered
            isOpen={props.isOpen}
            toggle={props.toggle}
            modalClassName={`ltr-support`}
        >

            <ModalHeader className="modal__header" toggle={props.toggle}>
                {/* <button
                    className="lnr lnr-cross modal__close-btn"
                    type="button"
                    onClick={props.toggle}
                /> */}
                <h4 className="text-modal  modal__title">
                    All order types are disabled
                </h4>
            </ModalHeader>
            <div className="modal__body px-4 text-center">
                <p>
                    {props.orderType == 1 ? `Kindly enable either of the order type options( Dine-in/ Takeaway) to start accepting orders` : `Kindly go to "order type" option & enable either of the order type options to start accepting orders`}


                </p>

                <ButtonToolbar className="modal__footer" style={{ justifyContent: 'space-evenly' }}>
                    <Button
                        type="button"
                        // color="default"
                        className="modal_ok mb-3 btn-secondary btn-sm"
                        onClick={props.toggle}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="button"
                        color="primary"
                        className="modal_ok mb-3"
                        onClick={props.orderType == 1 ? props.toggle : props.goToOrderType}
                    >
                        {props.orderType == 1 ? 'Okay' : 'Go to order type'}
                    </Button>
                </ButtonToolbar>

            </div>
        </Modal>
    )
}

export default ViewMenuTypePopup;
