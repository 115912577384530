import React, { useEffect, useState, useRef } from 'react';
import * as PropTypes from 'prop-types';
import ReactCropper from 'react-cropper';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import Slider from 'rc-slider';
import 'cropperjs/dist/cropper.css';
import 'rc-slider/assets/index.css';
import './image_cropper.scss';

function CustomCropperModel(props) {
    const { file, raw } = props;
    const [cropper, setCropper] = useState(null);
    const [image, setImage] = useState(null);
    const [zoom, setZoom] = useState(props.initialZoom);
    const cropperRef = useRef(null);
    const [zoomValue, setZoomValue] = useState(0);
    const [open, setopen] = useState(false);

    useEffect(() => {
        if (file) {
            let fileReader, isCancel = false;
            fileReader = new FileReader();
            fileReader.onload = (e) => {
                const { result } = e.target;
                if (result && !isCancel) {
                    setImage(result)
                    cropper && cropper.zoomTo(props.initialZoom)

                }
            }
            fileReader.readAsDataURL(file);
            setopen(true)
        } else if (raw) {
            setImage(raw)
            setopen(true)
        } else {
            setImage(null);
            setCropper(null);
            setopen(false)
        }
    }, [props, file, raw]);

    const dataUrlToFile = (dataurl, filename) => {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    function getRoundedCrop(sourceCanvas) {
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        var width = sourceCanvas.width;
        var height = sourceCanvas.height;
        canvas.width = width;
        canvas.height = height;
        context.imageSmoothingEnabled = true;
        context.drawImage(sourceCanvas, 0, 0, width, height);
        context.globalCompositeOperation = 'destination-in';
        context.beginPath();
        context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
        context.fill();
        return canvas;
    }

    const onConfirm = () => {
        if (!cropper) {
            return;
        }

        const croppedCanvas = {
            minWidth: 854, maxWidth: 1200,
            minHeight: 480, maxHeight: 600,
            imageSmoothingQuality: 'medium',
            ...props.croppedCanvasProps,
        };

        const canvasData = cropper.getCroppedCanvas(croppedCanvas);
        let roundedCrop = getRoundedCrop(canvasData).toDataURL();
        let finalRound = dataUrlToFile(roundedCrop, "output.png");
        typeof props.onCompleted === 'function' && props.onCompleted(finalRound);

        setImage(null);
        setCropper(null);
        setopen(false)

    };

    const handleClose = () => {
        setCropper(false);
        setImage(null);
        setopen(false)
        typeof props.onDiscard === 'function' && props.onDiscard(file);
        typeof props.onCompleted === 'function' && props.onCompleted();
    };

    return (
        <Modal
            show={open}
            onHide={handleClose}
            className="p-0"
            contentClassName='cropper_modal_content'
            centered
            animation={true}
        >
            <Modal.Body className="text-center p-0" >
                {image && (
                    <ReactCropper
                        src={image}
                        aspectRatio={props.aspect ? props.aspect : 3 / 3}
                        className="circle-picker crop_box_style"
                        zoomTo={zoomValue}
                        zoomable={true}
                        zoomOnWheel={false}
                        zoomOnTouch={true}
                        wheelZoomRatio={0.1}
                        guides={false}
                        toggleDragModeOnDblclick={false}
                        ref={cropperRef}
                        dragMode='move'
                        cropBoxMovable={true}
                        cropBoxResizable={false}
                        onInitialized={(instance) => {
                            setCropper(instance);
                        }}
                    />
                )}

                <div className="" style={{ width: "100%", }}>
                    <Slider
                        style={{ marginTop: "20px", marginBottom: "20px" }}
                        trackStyle={{ backgroundColor: '#24424C' }}
                        railStyle={{ backgroundColor: '#ccc' }}
                        value={zoom}
                        onChange={(value) => {
                            setZoom(value);
                            cropper.zoomTo(value / 100);
                        }}
                    />
                </div>

                <div className="clearfix" />

                <Row className="">
                    <Col lg={12} md={12} className="" style={{ display: "flex", justifyContent: "space-evenly", marginBottom: "1rem" }}>
                        <Button variant="secondary" className="my-auto px-5" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="primary" className="my-auto" onClick={onConfirm}>
                            Crop
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}

CustomCropperModel.propTypes = {
    initialZoom: PropTypes.number,
    initialRotate: PropTypes.number,
    mime: PropTypes.string,
    quality: PropTypes.number,
    file: PropTypes.object,
    labels: PropTypes.shape({
        heading: PropTypes.string,
        confirm: PropTypes.string,
        discard: PropTypes.string,
        zoom: PropTypes.string,
        rotate: PropTypes.string,
    }),
    cropperProps: PropTypes.object,
    modalProps: PropTypes.object,
    croppedCanvasProps: PropTypes.object,
    onDiscard: PropTypes.func,
    onCompleted: PropTypes.func,
}

CustomCropperModel.defaultProps = {
    initialZoom: 0.2,
    initialRotate: 0,
    mime: null,
    quality: 70,
    labels: {
        heading: 'Crop Image',
        confirm: 'Confirm',
        discard: 'Discard',
        zoom: 'Zoom',
        rotate: 'Rotate',
    },
    modalProps: {},
    cropperProps: {},
    croppedCanvasProps: {},
    onDiscard: () => { },
    onCompleted: () => { },
}

export default CustomCropperModel;