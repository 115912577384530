import React, { Fragment } from "react";
import Breadcrumb from "../components/common/breadcrumb";
import client from "../Api/HTTPClient";
import api from "../constant/apilist";
import CustomerProfile from "./customerProfile";
import { ToastContainer, toast } from "react-toastify";
import { DisappearedLoading } from "react-loadingg";
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import {
  DollarSign,
  MapPin,
  X,
  TrendingDown,
  ArrowUp,
  ShoppingCart,
  Search,
  Activity,
  User
} from "react-feather";
import { Redirect } from "react-router-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink as NavLinkTab } from "reactstrap";
import { NavLink, Link } from "react-router-dom";
import CustomerTransaction from "./customerTransaction";
import CustomerDocuments from "./customerDocuments";
import CustomerSettlements from "./CustomerSettlements";
import CustomerHospoSafe from "./customerHospoSafe";
import CustomerRewardHistory from "./CustomerRewardHistory";
import CustomerAdminNote from "./CustomerAdminNote";
import UnverifiedCustProfile from "./UnverifiedCustProfile";
import { CustometInfo } from "./CustometInfo";


// const BorderTable = () => {
class BorderTable extends React.Component {


  // this.toggle = this.toggle.bind(this);
  state = {
    error: null,
    customer_id: this.props.match.params.customer_id,
    isLoaded: false,
    items: [],
    customer_info: null,
    wallet_info: null,
    transactions: [],
    tabIndex: 0
    //activeTab: "1"
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    console.log(nextProps, prevState)
    if (nextProps.match.params.customer_id != prevState.customer_id) {
      return {
        customer_id: nextProps.match.params.customer_id
      }
    } else {
      return null;
    }
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.customer_id != this.state.customer_id) {
      this.getCustomersWallet(this.state.customer_id);
    }
  }


  view_details = async (cust_id) => {
    await this.setState({ customer_id: cust_id });
    //console.log("customer_id ="+this.state.customer_id);
    this.getCustomers();

  }



  componentDidMount() {

    this.getCustomersWallet(this.state.customer_id);
    //console.log(this.props);
    if (this.props.location.search != "") {
      let tab;
      const query = new URLSearchParams(this.props.location.search);
      for (let params of query.entries()) {
        tab = params[1]
      }
      this.setState({ tabIndex: tab })
    }
  }

  getCustomersWallet = (customer_id) => {
    //let { customer_id } = this.state;
    let postdata = { customer_id: customer_id };
    // current.setState({ loading: true });
    client.post(api.customers_wallet, postdata, (error, response) => {
      if (!error) {
        // alert(JSON.stringify(response));
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              console.log(response, '::customer_info');
              this.setState({ customer_info: response.result.customer_info, wallet_info: response.result.wallet_info, transactions: response.result.transactions });
              // current.props.navigation.navigate("Verification");
              //console.log(response);

            } else {
              setTimeout(() => {
                toast.error(response.message);
              }, 200);
            }
          } catch (e) {
            setTimeout(() => {
              toast.error("Internal error occured. Please contact support");
            }, 200);
            console.log(e);

            // _showToast.error("Internal error occured. Please contact support.");
            // currentComponent.setState({ errormessage: "Internal error." });
          }
        } else {
          setTimeout(() => {
            toast.error("Internal error occured. Please contact support");
          }, 200);

          //   _showToast.error("Internal error occured. Please contact support.");
        }
      } else {
        setTimeout(() => {
          toast.error("Internal error occured. Please contact support");
        }, 200);


        // alert(JSON.stringify(error));
        // _showToast.error("Internal error occured. Please contact support.");
      }
      // current.setState({ loading: false });
    });
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  render_date = (transaction_date) => {

    const convert_date = new Date(transaction_date)
    let added_date = new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }).format(convert_date)
    added_date = added_date.replace(",", ";");
    return added_date;

  }

  render() {
    let { customer_info, customer_id, wallet_info, transactions } = this.state;
    console.log(customer_info);
    console.log(wallet_info);
    console.log(transactions);
    console.log(customer_id);
    customer_info && console.log(customer_info.name);


    return (
      <Fragment>
        {customer_info ? <Fragment>
          <div className="col-lg-7 col-sm-12" style={{ fontSize: "12px" }}>
            <Breadcrumb title={customer_info.name} mobile={customer_info.mobile} email={customer_info.email}
              ipay_id={customer_info.ipay_id} parent={"Customers"} />
          </div>
          {customer_id ? (
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12">
                  {customer_info.status == 1 && wallet_info || customer_info.status == 2 && wallet_info ?
                    <div className="container-fluid">
                      <div className="row theme-tab">
                        <Tabs className="col-sm-12" style={{ padding: "0", backgroundColor: "white", borderRadius: "3px", border: "1px solid rgb(223, 232, 241)", marginBottom: "0px" }} defaultIndex={this.state.tabIndex} onSelect={index => this.setState({ tabIndex: index })}>
                          <TabList className="tabs tab-title">
                            <Tab style={{ display: "flex", alignItems: "center" }}>
                              Customer Info
                            </Tab>

                            {/* <Tab style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Profile Info </Tab> */}
                            <Tab style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Transaction History </Tab>
                            <Tab style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Reward History </Tab>
                            <Tab style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}>  Hospo Registry </Tab>

                            {/* <Tab style={{display:"flex",alignItems:"center"}}>
                              Transaction History
                            </Tab>

                            <Tab style={{display:"flex",alignItems:"center"}}>
                              Reward History
                          </Tab>

                            <Tab style={{display:"flex",alignItems:"center"}}>
                              Hospo Registry
                            </Tab> */}

                            <Tab style={{ display: "flex", alignItems: "center" }}>
                              Quick bills
                            </Tab>

                            <Tab style={{ display: "flex", alignItems: "center" }}>
                              Documents
                            </Tab>

                            <Tab style={{ display: "flex", alignItems: "center" }}>
                              Settlements
                            </Tab>

                            <Tab style={{ display: "flex", alignItems: "center" }}>
                              Admin Note
                            </Tab>


                          </TabList>


                          <div className="tab-content-cls">
                            {/* <TabPanel> */}
                            {/* <CustomerProfile customerInfo={customer_info} walletInfo={wallet_info}
                                transactionsInfo={transactions} customerId={customer_id} getCustomersWallet={this.getCustomersWallet}>
                              </CustomerProfile> */}
                            {/* <CustometInfo profiledata={{ customer_info: customer_info, wallet_info: wallet_info, transactions: transactions, customer_id }} getCustomersWallet={this.getCustomersWallet} />
                            </TabPanel> */}

                            {/* <TabPanel>
                              <CustomerTransaction customerInfo={customer_info} walletInfo={wallet_info}
                                transactionsInfo={transactions} customerId={customer_id}>
                              </CustomerTransaction>
                            </TabPanel>

                            <TabPanel >
                              <CustomerRewardHistory customerId={customer_id} transactionsInfo={transactions}
                                customerInfo={customer_info} walletInfo={wallet_info}> </CustomerRewardHistory>
                            </TabPanel>

                            <TabPanel >
                              <CustomerHospoSafe customerId={customer_id} customerInfo={customer_info}></CustomerHospoSafe>
                            </TabPanel> */}

                            <TabPanel>
                              <CustomerProfile customerInfo={customer_info} walletInfo={wallet_info}
                                transactionsInfo={transactions} customerId={customer_id} getCustomersWallet={this.getCustomersWallet}>
                                {/* <CustomerProfile customerInfo={props.profiledata.customer_info} walletInfo={props.profiledata.wallet_info}
                                transactionsInfo={props.profiledata.transactions} customerId={props.profiledata.customer_id} getCustomersWallet={props.getCustomersWallet}> */}
                              </CustomerProfile>
                            </TabPanel>

                            <TabPanel>
                              <CustomerTransaction customerInfo={customer_info} walletInfo={wallet_info}
                                transactionsInfo={transactions} customerId={customer_id} getCustomersWallet={this.getCustomersWallet}>
                                {/* <CustomerTransaction customerInfo={props.profiledata.customer_info} walletInfo={props.profiledata.wallet_info}
                                transactionsInfo={props.profiledata.transactions} customerId={props.profiledata.customer_id}> */}
                              </CustomerTransaction>
                            </TabPanel>

                            <TabPanel >
                              <CustomerRewardHistory customerId={customer_id} transactionsInfo={transactions}
                                customerInfo={customer_info} walletInfo={wallet_info}> </CustomerRewardHistory>
                              {/* <CustomerRewardHistory customerId={props.profiledata.customer_id} transactionsInfo={props.profiledata.transactions}
                                customerInfo={props.profiledata.customer_info} walletInfo={props.profiledata.wallet_info}> </CustomerRewardHistory> */}
                            </TabPanel>

                            <TabPanel >
                              <CustomerHospoSafe customerId={customer_id} customerInfo={customer_info}></CustomerHospoSafe>
                              {/* <CustomerHospoSafe customerId={props.profiledata.customer_id} customerInfo={props.profiledata.customer_info}></CustomerHospoSafe> */}
                            </TabPanel>

                            <TabPanel >
                            </TabPanel>

                            <TabPanel >
                              <CustomerDocuments customerId={customer_id}
                                customerInfo={customer_info} getCustomersWallet={this.getCustomersWallet}></CustomerDocuments>
                            </TabPanel>

                            <TabPanel >
                              <CustomerSettlements customerId={customer_id}></CustomerSettlements>
                            </TabPanel>

                            <TabPanel >
                              <CustomerAdminNote customerId={customer_id}
                                customerInfo={customer_info}></CustomerAdminNote>
                            </TabPanel>

                          </div>
                        </Tabs>
                      </div>
                    </div>

                    // {/* <div >

                    //   <Nav tabs className="nav-pills nav-primary">
                    //     <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                    //       <NavLinkTab className={this.state.activeTab == '1' ? 'active nav-link' : ''} onClick={() => { this.toggle('1') }} value='1'>
                    //         Profile Info
                    //       </NavLinkTab>
                    //     </NavItem>
                    //     <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                    //       <NavLinkTab className={this.state.activeTab == '2' ? 'active nav-link' : ''} onClick={() => { this.toggle('2') }} value='2'>
                    //         Transaction History
                    //       </NavLinkTab>
                    //     </NavItem>
                    //     <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                    //       <NavLinkTab className={this.state.activeTab == '3' ? 'active nav-link' : ''} onClick={() => { this.toggle('3') }} value='3'>
                    //         Reward History
                    //       </NavLinkTab>
                    //     </NavItem>
                    //     <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                    //       <NavLinkTab className={this.state.activeTab == '4' ? 'active nav-link' : ''} onClick={() => { this.toggle('4') }} value='4'>
                    //         Hospo Registry
                    //       </NavLinkTab>
                    //     </NavItem>
                    //     <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                    //       <NavLinkTab className={this.state.activeTab == '5' ? 'active nav-link' : ''} onClick={() => { this.toggle('5') }} value='5'>
                    //         Billings 
                    //       </NavLinkTab>
                    //     </NavItem>
                    //     <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                    //       <NavLinkTab className={this.state.activeTab == '6' ? 'active nav-link' : ''} onClick={() => { this.toggle('6') }} value='6'>
                    //         Documents
                    //       </NavLinkTab>
                    //     </NavItem>
                    //     <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                    //       <NavLinkTab className={this.state.activeTab == '7' ? 'active nav-link' : ''} onClick={() => { this.toggle('7') }} value='7'>
                    //         Settlements
                    //       </NavLinkTab>
                    //     </NavItem>
                    //     <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                    //       <NavLinkTab className={this.state.activeTab == '8' ? 'active nav-link' : ''} onClick={() => { this.toggle('8') }} value='8'>
                    //         Admin Note
                    //       </NavLinkTab>
                    //     </NavItem>

                    //   </Nav>

                    //   <TabContent activeTab={this.state.activeTab} style={{ marginTop: "15px" }} >

                    //     <TabPane tabId="1">
                    //       <CustomerProfile customerInfo={customer_info} walletInfo={wallet_info}
                    //         transactionsInfo={transactions} customerId={customer_id} >
                    //       </CustomerProfile>
                    //     </TabPane>

                    //     <TabPane tabId="2">
                    //       <CustomerTransaction customerInfo={customer_info} walletInfo={wallet_info}
                    //         transactionsInfo={transactions} customerId={customer_id}>
                    //       </CustomerTransaction>
                    //     </TabPane>

                    //     <TabPane tabId="3">
                    //       <CustomerRewardHistory customerId={customer_id} transactionsInfo={transactions} 
                    //       customerInfo={customer_info} walletInfo={wallet_info}> </CustomerRewardHistory>
                    //     </TabPane>

                    //     <TabPane tabId="4">
                    //       <CustomerHospoSafe customerId={customer_id}  customerInfo={customer_info}></CustomerHospoSafe>
                    //     </TabPane>

                    //     <TabPane tabId="5">
                    //       Approved
                    //     </TabPane>

                    //     <TabPane tabId="6">
                    //       <CustomerDocuments customerId={customer_id}
                    //         customerInfo={customer_info}></CustomerDocuments>
                    //     </TabPane>

                    //     <TabPane tabId="7">
                    //       <CustomerSettlements customerId={customer_id}></CustomerSettlements>


                    //     </TabPane>
                    //     <TabPane tabId="8">
                    //       <CustomerAdminNote customerId={customer_id}
                    //         customerInfo={customer_info}></CustomerAdminNote>
                    //     </TabPane>
                    //   </TabContent>
                    // </div> */}
                    :
                    <UnverifiedCustProfile customer_id={customer_id} customer_info={customer_info}></UnverifiedCustProfile>
                  }
                </div>
              </div>
            </div>

          ) : (
            <Redirect to={`${process.env.PUBLIC_URL}/customers/all_customers`} />
          )}
        </Fragment> : <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
          <div className="loader" style={{ display: "flex", alignItems: "center" }}>
            <DisappearedLoading size="medium" color="#514F4E" />
          </div>
        </div>}
      </Fragment>
    );
  }
}

export default BorderTable;
