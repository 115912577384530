import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
// import { Link as NavLink } from "react-router-dom";
import useForm from 'react-hook-form'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';


// const BorderTable = () => {
function BorderTable() {

    const { register, handleSubmit, errors } = useForm(); // initialise the hook
    const { register: register2, handleSubmit: handleSubmit2, errors: errors2 } = useForm(); // initialise the hook
    const [loadingbtn, setloadingbtn] = useState(false);
    const [loading, setloading] = useState(false);
    const [cashbackdata, setcashbackdata] = useState(null);
    let [tabIndex, setTabIndex] = useState(0);
    const [cashbackInfoMerchant, setcashbackInfoMerchant] = useState(null);



    const onSubmit = data => {

        // console.log(data);
        let config_type = 1;
        if (tabIndex == "1")
            config_type = 2
        // return false;
        if (data !== '') {

            data.config_type = config_type;

            setloadingbtn(true);
            client.post(api.update_ashback_settings, data, async function (error, response) {
                if (!error) {
                    // alert(JSON.stringify(response));
                    //   return false;
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {

                                toast.success("Updated Successfully");
                                // setcashbackdata(response.result.data);
                            } else {
                                toast.error(response.message);
                            }
                        } catch (e) {
                            toast.error("Internal error occured. Please contact support");
                        }
                    } else {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
                setloadingbtn(false);
            });
        } else {
            if (config_type == 1)
                errors.showMessages();
            else
                errors2.showMessages();
        }
    };


    const getcashback_settings = async () => {
        let postdata = {};
        let current = this;
        // current.setState({ loading: true });
        setloading(true);
        client.post(api.cashback_settings, postdata, async function (error, response) {
            if (!error) {
                // console.log(JSON.stringify(response.result.dataMerchant));
                //   return false;
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            setcashbackdata(response.result.data);
                            setcashbackInfoMerchant(response.result.dataMerchant);

                            // current.props.navigation.navigate("Verification");
                        } else {
                            toast.error(response.message);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
                // _showToast.error("Internal error occured. Please contact support.");
            }
            // current.setState({ loading: false });
            setloading(false);

        });
    };

    useEffect(() => {
        // alert("hrer");
        getcashback_settings();
    }, [])

    const displayDate = (date) => {
        let transaction_date = new Date(date);
        return (
            <span>
                {transaction_date}
            </span>
        )
    }


    // render() {
    // let { customers } = this.state;
    return (
        <Fragment>
            <div className="row">
                <div className="col-lg-7 col-sm-12">
                    <Breadcrumb title={"Cashback Setting"} parent={"Home"} />
                </div>
            </div>
            <div className="container-fluid">
                <div className="row theme-tab">
                    <Tabs className="col-sm-12" defaultIndex={tabIndex} onSelect={index => setTabIndex(index)} style={{ padding: "0" }}>
                        <TabList className="tabs tab-title">
                            <Tab >
                                Customer
                            </Tab>
                            <Tab>
                                Merchant
                            </Tab>

                        </TabList>
                        <div className="tab-content-cls">
                            <TabPanel>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-sm-12 col-xl-12" style={{ padding: "0" }}>
                                            <div className="card">
                                                <div className="card-body" style={{ padding: "1rem" }}>
                                                    {cashbackdata != null ?
                                                        <form className="needs-validation theme-form mt-3 billing-form" style={{paddingLeft:"20px"}} onSubmit={handleSubmit(onSubmit)}>

                                                            {/* <input name="config_type" value="1" type = "hidden"/> */}
                                                            <h5 style={{fontSize:"0.9rem"}}>Add Money New User Cashback</h5>
                                                            <div className="form-row">

                                                                <div className="col-sm-3">
                                                                    <div className="form-group">
                                                                        <label className="col-form-label">Status</label>
                                                                        {/*
                                                    <input className="form-control" type="Number" placeholder="Enter contact number" /> */}
                                                                        <div className="row">
                                                                            <div className="radio ">
                                                                                <input id="status1" defaultChecked={cashbackdata.amcb_nu_status == 1 ? true : false} type="radio" name="amcb_nu_status" value="1" ref={register({ required: true })} />
                                                                                <label htmlFor="status1">Active</label>
                                                                            </div>
                                                                            <div className="radio" style={{ marginLeft: 20 }}>
                                                                                <input id="status0" type="radio" name="amcb_nu_status" defaultChecked={cashbackdata.amcb_nu_status == 0 ? true : false} value="0" ref={register({ required: true })} />
                                                                                <label htmlFor="status0">Inactive</label>
                                                                            </div>
                                                                        </div>
                                                                        {/* /> */}
                                                                        <span>{errors.amcb_nu_status && 'Select Status'}</span>
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-3">
                                                                    <div className="form-group">
                                                                        <label className="col-form-label">Cashback Type</label>
                                                                        <div className="row col-sm-12">
                                                                            <div className="radio ">
                                                                                <input id="discount_type1" defaultChecked={cashbackdata.amcb_nu_type == 1 ? true : false} type="radio" name="amcb_nu_type" value="1" ref={register({ required: true })} />
                                                                                <label htmlFor="discount_type1">Percent</label>
                                                                            </div>
                                                                            <div className="radio" style={{ marginLeft: 20 }}>
                                                                                <input id="discount_type2" type="radio" name="amcb_nu_type" defaultChecked={cashbackdata.amcb_nu_type == 2 ? true : false} value="2" ref={register({ required: true })} />
                                                                                <label htmlFor="discount_type2">Amount</label>
                                                                            </div>
                                                                        </div>
                                                                        <span>{errors.amcb_nu_type && 'Select cashback type'}</span>

                                                                    </div>
                                                                </div>
                                                                {/* </div>
                                        <div className="row"> */}
                                                                <div className="col-sm-3">
                                                                    <div className="form-group">
                                                                        <label className="col-form-label">Cashback</label>
                                                                        <input className="form-control" type="Number" name="amcb_nu_amount" defaultValue={cashbackdata.amcb_nu_amount} placeholder="Cashback" ref={register({ required: true })} />
                                                                        <span>{errors.amcb_nu_amount && 'Cashback is required'}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-3">
                                                                    <div className="form-group">
                                                                        <label className="col-form-label">Max Cashback</label>
                                                                        <input className="form-control" type="Number" name="amcb_nu_max_amount" placeholder="Max Cashback" ref={register({ required: true })} defaultValue={cashbackdata.amcb_nu_max_amount} />
                                                                        <span>{errors.amcb_nu_max_amount && 'Max Cashback is required'}</span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <hr />

                                                            <h5 style={{fontSize:"0.9rem"}}>Add Money All Users Cashback</h5>
                                                            <div className="form-row">

                                                                <div className="col-sm-3">
                                                                    <div className="form-group">
                                                                        <label className="col-form-label">Status</label>
                                                                        {/*
                                                    <input className="form-control" type="Number" placeholder="Enter contact number" /> */}
                                                                        <div className="row col-sm-12">
                                                                            <div className="radio">
                                                                                <input id="amcb_status1" type="radio" name="amcb_status" value="1" ref={register({ required: true })} defaultChecked={cashbackdata.amcb_status == 1 ? true : false} />
                                                                                <label htmlFor="amcb_status1">Active</label>
                                                                            </div>
                                                                            <div className="radio" style={{ marginLeft: 20 }}>
                                                                                <input id="amcb_status0" type="radio" name="amcb_status" value="0" ref={register({ required: true })} defaultChecked={cashbackdata.amcb_status == 0 ? true : false} />
                                                                                <label htmlFor="amcb_status0">Inactive</label>
                                                                            </div>
                                                                        </div>
                                                                        <span>{errors.amcb_status && 'Status is required'}</span>

                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-3">
                                                                    <div className="form-group">
                                                                        <label className="col-form-label">Cashback Type</label>
                                                                        <div className="row ">
                                                                            <div className="radio">
                                                                                <input id="amcb_type1" type="radio" name="amcb_type" value="1" ref={register({ required: true })} defaultChecked={cashbackdata.amcb_type == 1 ? true : false} />
                                                                                <label htmlFor="amcb_type1">Percent</label>
                                                                            </div>
                                                                            <div className="radio" style={{ marginLeft: 20 }}>
                                                                                <input id="amcb_type2" type="radio" name="amcb_type" value="2" ref={register({ required: true })} defaultChecked={cashbackdata.amcb_type == 2 ? true : false} />
                                                                                <label htmlFor="amcb_type2">Amount</label>
                                                                            </div>
                                                                        </div>
                                                                        <span>{errors.amcb_type && 'Cashback Type is required'}</span>

                                                                    </div>
                                                                </div>
                                                                {/* </div>
                                        <div className="row"> */}
                                                                <div className="col-sm-3">
                                                                    <div className="form-group">
                                                                        <label className="col-form-label">Cashback</label>
                                                                        <input className="form-control" type="Number" name="amcb_amount" defaultValue={cashbackdata.amcb_amount} placeholder="Cashback" ref={register({ required: true })} />
                                                                        <span>{errors.amcb_amount && 'Cashback is required'}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-3">
                                                                    <div className="form-group">
                                                                        <label className="col-form-label">Max Cashback</label>
                                                                        <input className="form-control" type="Number" name="amcb_max_amount" defaultValue={cashbackdata.amcb_max_amount} placeholder="Max Cashback" ref={register({ required: true })} />
                                                                        <span>{errors.amcb_max_amount && 'Max Cashback is required'}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr />

                                                            <h5 style={{fontSize:"0.9rem"}}>Referral Cashback</h5>
                                                            <div className="form-row">

                                                                <div className="col-sm-6">
                                                                    <div className="form-group">
                                                                        <label className="col-form-label">Status</label>
                                                                        {/*
                                                    <input className="form-control" type="Number" placeholder="Enter contact number" /> */}
                                                                        <div className="row col-sm-12">
                                                                            <div className="radio">
                                                                                <input id="refercb_status1" type="radio" name="refercb_status" value="1" ref={register({ required: true })} defaultChecked={cashbackdata.refercb_status == 1 ? true : false} />
                                                                                <label htmlFor="refercb_status1">Active</label>
                                                                            </div>
                                                                            <div className="radio " style={{ marginLeft: 20 }}>
                                                                                <input id="refercb_status0" type="radio" name="refercb_status" value="0" ref={register({ required: true })} defaultChecked={cashbackdata.refercb_status == 0 ? true : false} />
                                                                                <label htmlFor="refercb_status0">Inactive</label>
                                                                            </div>
                                                                        </div>
                                                                        <span>{errors.refercb_status && 'Status is required'}</span>
                                                                    </div>
                                                                </div>

                                                                {/* </div>
                                        <div className="row"> */}
                                                                <div className="col-sm-3">
                                                                    <div className="form-group">
                                                                        <label className="col-form-label">New User Cashback</label>
                                                                        <input className="form-control" type="Number" name="refercb_amount" placeholder="refercb_amount" ref={register({ required: true })} defaultValue={cashbackdata.refercb_amount} />
                                                                        <span>{errors.refercb_amount && 'New user cashback is required'}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-3">
                                                                    <div className="form-group">
                                                                        <label className="col-form-label">Referrer Cashback</label>
                                                                        <input className="form-control" type="Number" name="refercb_referrer_amount" placeholder="Max discount" ref={register({ required: true })} defaultValue={cashbackdata.refercb_referrer_amount} />
                                                                        <span>{errors.refercb_referrer_amount && 'Referrer cashback is required'}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group col-lg-12">
                                                                {!loadingbtn ?
                                                                    <button className="btn btn-primary btn-xs pull-right" type="submit" style={{fontSize:"0.75rem"}}>SAVE</button>
                                                                    :
                                                                    <div className="loader-box pull-right" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 42, width: 42 }}></span></div>
                                                                }
                                                            </div>
                                                        </form>
                                                        :
                                                        <div className="loader-box" >
                                                            <div className="loader" style={{ margin: "auto auto" }}>
                                                                <div className="line bg-primary"></div>
                                                                <div className="line bg-primary"></div>
                                                                <div className="line bg-primary"></div>
                                                                <div className="line bg-primary"></div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-sm-12 col-xl-12 xl-100" style={{ padding: "0" }}>
                                            <div className="card">
                                                <div className="card-body" style={{ padding: "1rem" }}>
                                                    {cashbackInfoMerchant != null ?
                                                        <form key="2" className="needs-validation theme-form mt-3 billing-form" style={{paddingLeft:"20px"}} onSubmit={handleSubmit2(onSubmit)}>

                                                            {/* <input name="config_type" value="2" type = "hidden"/> */}
                                                            <h5 style={{fontSize:"1rem"}}>Add Money New User Cashback</h5>
                                                            <div className="form-row">

                                                                <div className="col-sm-3">
                                                                    <div className="form-group">
                                                                        <label className="col-form-label">Status</label>
                                                                        {/*
                                                    <input className="form-control" type="Number" placeholder="Enter contact number" /> */}
                                                                        <div className="row col-sm-12">
                                                                            <div className="radio">
                                                                                <input id="merchantstatus1" defaultChecked={cashbackInfoMerchant.amcb_nu_status == 1 ? true : false} type="radio" name="amcb_nu_status" value="1" ref={register2({ required: true })} />
                                                                                <label htmlFor="merchantstatus1">Active</label>
                                                                            </div>
                                                                            <div className="radio" style={{ marginLeft: 20 }}>
                                                                                <input id="merchantstatus0" type="radio" name="amcb_nu_status" defaultChecked={cashbackInfoMerchant.amcb_nu_status == 0 ? true : false} value="0" ref={register2({ required: true })} />
                                                                                <label htmlFor="merchantstatus0">Inactive</label>
                                                                            </div>
                                                                        </div>
                                                                        {/* /> */}
                                                                        <span>{errors2.amcb_nu_status && 'Select Status'}</span>
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-3">
                                                                    <div className="form-group">
                                                                        <label className="col-form-label">Cashback Type</label>
                                                                        <div className="row col-sm-12">
                                                                            <div className="radio">
                                                                                <input id="merchantdiscount_type1" defaultChecked={cashbackInfoMerchant.amcb_nu_type == 1 ? true : false} type="radio" name="amcb_nu_type" value="1" ref={register2({ required: true })} />
                                                                                <label htmlFor="merchantdiscount_type1">Percent</label>
                                                                            </div>
                                                                            <div className="radio" style={{ marginLeft: 20 }}>
                                                                                <input id="merchantdiscount_type2" type="radio" name="amcb_nu_type" defaultChecked={cashbackInfoMerchant.amcb_nu_type == 2 ? true : false} value="2" ref={register2({ required: true })} />
                                                                                <label htmlFor="merchantdiscount_type2">Amount</label>
                                                                            </div>
                                                                        </div>
                                                                        <span>{errors2.amcb_nu_type && 'Select cashback type'}</span>

                                                                    </div>
                                                                </div>
                                                                {/* </div>
                                        <div className="row"> */}
                                                                <div className="col-sm-3">
                                                                    <div className="form-group">
                                                                        <label className="col-form-label">Cashback</label>
                                                                        <input className="form-control" type="Number" name="amcb_nu_amount" defaultValue={cashbackInfoMerchant.amcb_nu_amount} placeholder="Cashback" ref={register2({ required: true })} />
                                                                        <span>{errors.amcb_nu_amount && 'Cashback is required'}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-3">
                                                                    <div className="form-group">
                                                                        <label className="col-form-label">Max Cashback</label>
                                                                        <input className="form-control" type="Number" name="amcb_nu_max_amount" placeholder="Max Cashback" ref={register2({ required: true })} defaultValue={cashbackInfoMerchant.amcb_nu_max_amount} />
                                                                        <span>{errors2.amcb_nu_max_amount && 'Max Cashback is required'}</span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <hr />

                                                            <h5 style={{fontSize:"1rem"}}>Add Money All Users Cashback</h5>
                                                            <div className="form-row">

                                                                <div className="col-sm-3">
                                                                    <div className="form-group">
                                                                        <label className="col-form-label">Status</label>
                                                                        {/*
                                                    <input className="form-control" type="Number" placeholder="Enter contact number" /> */}
                                                                        <div className="row col-sm-12">
                                                                            <div className="radio">
                                                                                <input id="merchantamcb_status1" type="radio" name="amcb_status" value="1" ref={register2({ required: true })} defaultChecked={cashbackInfoMerchant.amcb_status == 1 ? true : false} />
                                                                                <label htmlFor="merchantamcb_status1">Active</label>
                                                                            </div>
                                                                            <div className="radio" style={{ marginLeft: 20 }}>
                                                                                <input id="merchantamcb_status0" type="radio" name="amcb_status" value="0" ref={register2({ required: true })} defaultChecked={cashbackInfoMerchant.amcb_status == 0 ? true : false} />
                                                                                <label htmlFor="merchantamcb_status0">Inactive</label>
                                                                            </div>
                                                                        </div>
                                                                        <span>{errors2.amcb_status && 'Status is required'}</span>

                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-3">
                                                                    <div className="form-group">
                                                                        <label className="col-form-label">Cashback Type</label>
                                                                        <div className="row col-sm-12">
                                                                            <div className="radio">
                                                                                <input id="merchantamcb_type1" type="radio" name="amcb_type" value="1" ref={register2({ required: true })} defaultChecked={cashbackInfoMerchant.amcb_type == 1 ? true : false} />
                                                                                <label htmlFor="merchantamcb_type1">Percent</label>
                                                                            </div>
                                                                            <div className="radio" style={{ marginLeft: 20 }}>
                                                                                <input id="merchantamcb_type2" type="radio" name="amcb_type" value="2" ref={register2({ required: true })} defaultChecked={cashbackInfoMerchant.amcb_type == 2 ? true : false} />
                                                                                <label htmlFor="merchantamcb_type2">Amount</label>
                                                                            </div>
                                                                        </div>
                                                                        <span>{errors2.amcb_type && 'Cashback Type is required'}</span>

                                                                    </div>
                                                                </div>
                                                                {/* </div>
                                        <div className="row"> */}
                                                                <div className="col-sm-3">
                                                                    <div className="form-group">
                                                                        <label className="col-form-label">Cashback</label>
                                                                        <input className="form-control" type="Number" name="amcb_amount" defaultValue={cashbackInfoMerchant.amcb_amount} placeholder="Cashback" ref={register2({ required: true })} />
                                                                        <span>{errors2.amcb_amount && 'Cashback is required'}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-3">
                                                                    <div className="form-group">
                                                                        <label className="col-form-label">Max Cashback</label>
                                                                        <input className="form-control" type="Number" name="amcb_max_amount" defaultValue={cashbackInfoMerchant.amcb_max_amount} placeholder="Max Cashback" ref={register2({ required: true })} />
                                                                        <span>{errors2.amcb_max_amount && 'Max Cashback is required'}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr />

                                                            <h5 style={{fontSize:"1rem"}}>Referral Cashback</h5>
                                                            <div className="form-row">

                                                                <div className="col-sm-6">
                                                                    <div className="form-group">
                                                                        <label className="col-form-label">Status</label>
                                                                        {/*
                                                    <input className="form-control" type="Number" placeholder="Enter contact number" /> */}
                                                                        <div className="row col-sm-12">
                                                                            <div className="radio">
                                                                                <input id="merchantrefercb_status1" type="radio" name="refercb_status" value="1" ref={register2({ required: true })} defaultChecked={cashbackInfoMerchant.refercb_status == 1 ? true : false} />
                                                                                <label htmlFor="merchantrefercb_status1">Active</label>
                                                                            </div>
                                                                            <div className="radio" style={{ marginLeft: 20 }}>
                                                                                <input id="merchantrefercb_status0" type="radio" name="refercb_status" value="0" ref={register2({ required: true })} defaultChecked={cashbackInfoMerchant.refercb_status == 0 ? true : false} />
                                                                                <label htmlFor="merchantrefercb_status0">Inactive</label>
                                                                            </div>
                                                                        </div>
                                                                        <span>{errors2.refercb_status && 'Status is required'}</span>
                                                                    </div>
                                                                </div>

                                                                {/* </div>
                                        <div className="row"> */}
                                                                <div className="col-sm-3">
                                                                    <div className="form-group">
                                                                        <label className="col-form-label">New User Cashback</label>
                                                                        <input className="form-control" type="Number" name="refercb_amount" placeholder="refercb_amount" ref={register2({ required: true })} defaultValue={cashbackInfoMerchant.refercb_amount} />
                                                                        <span>{errors2.refercb_amount && 'New user cashback is required'}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-3">
                                                                    <div className="form-group">
                                                                        <label className="col-form-label">Referrer Cashback</label>
                                                                        <input className="form-control" type="Number" name="refercb_referrer_amount" placeholder="Max discount" ref={register2({ required: true })} defaultValue={cashbackInfoMerchant.refercb_referrer_amount} />
                                                                        <span>{errors2.refercb_referrer_amount && 'Referrer cashback is required'}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group col-lg-12">
                                                                {!loadingbtn ?
                                                                    <button className="btn btn-primary btn-xs pull-right" type="submit">SAVE</button>
                                                                    :
                                                                    <div className="loader-box pull-right" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 42, width: 42 }}></span></div>
                                                                }
                                                            </div>
                                                        </form>
                                                        :
                                                        <div className="loader-box" >
                                                            <div className="loader" style={{ margin: "auto auto" }}>
                                                                <div className="line bg-primary"></div>
                                                                <div className="line bg-primary"></div>
                                                                <div className="line bg-primary"></div>
                                                                <div className="line bg-primary"></div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                        </div>
                    </Tabs>
                </div>
            </div>


        </Fragment>
    );
    // }
}

export default BorderTable;
