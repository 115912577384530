import React, { useState, useEffect } from 'react'
import { Edit } from 'react-feather';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Addprinters } from './Addprinters';
import { DefaultprinterModel } from './defaultprinterModel';
import { Trash2 } from 'react-feather';

import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";

import Switch from "react-switch";
import { toast } from 'react-toastify';

// const printers =[
//     {"_id":"printer1", "name":'printer_1',"printertype":'internet',"ipaddress":'1239.168.1.2',"isdefault":false},
//     {"_id":"printer2", "name":'printer_2',"printertype":'internet',"ipaddress":'1239.168.1.23',"isdefault":false},
//     {"_id":"printer3", "name":'printer_3',"printertype":'internet',"ipaddress":'1239.168.1.35',"isdefault":true},
// ]


export const Settingtab = (props) => {
    const [openModal, setopenModal] = useState(false)
    const toggleModal = () => setopenModal(!openModal);
    const [tabledata, settabledata] = useState([]);
    const [printers, setPrinters] = useState([]);
    const [defaultprinter, setdefaultprinter] = useState(0)
    const [printerdata, setprinterdata] = useState({})
    const [listingStatus, setListingStatus] = useState(!props.merchant_info.listing_status);

    const [openprintermodel, setOpenprintermodel] = useState(false)

    const style = {
        outline: "none",
        ':focus': {
            outlineColor: "black"
        }
    };

    useEffect(() => {
        fetchPrinters();
    }, []);

    const handleListingStatus = (checked) =>{

        let postdata = { merchant_id: props.merchant_id, listing_status: !checked };
        client.post(api.disable_listing, postdata, (error, response) => {
            console.log(response)
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            setListingStatus(checked);
                            props.refreshMerchantInfo(!checked);
                            toast.success(response.message)
                        }
                    } catch (e) {}
                } else {}
            } else {}
        });
    }

    const fetchPrinters = async() => {
        let postdata = { merchant_id: props.merchant_id };
        client.post(api.printer_fetch, postdata, (error, response) => {
            console.log(response)
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            setPrinters(response.result.data)
                        }
                    } catch (e) {}
                } else {}
            } else {}
        });
    }

    useEffect(() => {
        if (printers.length > 0){
            const getindex=printers.findIndex(item => item.isdefault === true)
            settabledata(printers)
            setdefaultprinter(getindex)
        }
    }, [printers])

    const handleModal = (e) => {
        let data = [...tabledata]
        console.log(e)
        if (e.name && e.printertype) {
            if (e.index >= 0) {
                data[e.index] = { name: e.name, printertype: e.printertype, ipaddress: e.ipaddress, isdefault: e.isdefault }
                if (e.isdefault === true) setdefaultprinter(e.index)
            }
            else {
                if (tabledata.length == 0){
                    data.push({...e,_id:'printer1'});
                    setdefaultprinter(0)
                }
                else{
                    const curruntid=tabledata.length + 1
                    data.push({...e,_id:'printer'+curruntid});
                    if (e.isdefault === true) setdefaultprinter(tabledata.length)
                }
                
            }
            settabledata(data)
        }
        if(e.action === 'Delete' && e.index >= 0){
            console.log("index",e.index)
            //let newdata = data.splice(e.index,1);
            let newdata=tabledata.filter(item=>item._id !== e._id )
            settabledata(newdata)
        }
        setopenModal(false)
    }

    console.log(tabledata)

    const handleAction = (action, data) => {
        setopenModal(true)
        setprinterdata({...data,action})
        console.log(data)
    }

    const handleselectedPrinter=(e)=>{
        console.log('selected',e)
        if(Number(e) >= 0) setdefaultprinter(e)
        setOpenprintermodel(false)
    }

    return (
        <div className="container-fluid" style={{ paddingBottom: 20 }}>
            <div className="row theme-tab" style={{ marginBottom: '0px !important' }} >
                <Tabs className="col-sm-12" defaultIndex={0} style={{ padding: "0" }}>
                    <TabList className="tabs tab-title" style={{ marginBottom: '0px !important' }}>
                        {/* <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Printer </Tab> */}
                        {/* <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Users </Tab> */}
                        {/* <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> My Website</Tab> */}
                    </TabList>
                    <div className="tab-content-cls">
                        {/* <TabPanel> <div className="container-fluid" style={{ paddingTop: '0.5rem' }} >

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <span className={'h6'}>Printers</span>
                                <button class="btn btn-outline-primary btn-sm" onClick={() => setopenModal(true)}>+ Add New Printer</button>
                            </div>
                            <hr style={{ marginTop: '0.2rem', marginBottom: '0.2rem' }} />
                            <div style={{ textAlign: 'center', paddingTop: '20px' }}>
                                {tabledata.length > 0 ? (
                                    <table class="table table-hover table-striped printers" >
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Printer Type</th>
                                                <th scope="col">IP Address</th>
                                                <th></th>
                                                <th scope="col" style={{textAlign:'left'}}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tabledata.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{item.name}</td>
                                                            <td>{item.printertype}</td>
                                                            <td>{item.ipaddress}</td>
                                                            <td style={{textAlign:'right'}}>
                                                                <span onClick={()=>setOpenprintermodel(true)} hidden={Number(index) === Number(defaultprinter) ? false : true} className="text-primary">Default</span>
                                                            </td>
                                                            <td style={{textAlign:'left'}}>
                                                                <span style={{ marhinLeft: '10px' }} onClick={() => handleAction('Delete', { ...item, index })}  ><Trash2 color={'#dc3545'} size="18" /> </span>
                                                                <span style={{ marhinLeft: '10px' }} onClick={() => handleAction('Edit', { ...item, index })}><Edit size="18" /> </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                ) : "No Printer Added"}
                            </div>
                            <Addprinters open={openModal} handleModal={(e) => handleModal(e)} printerdata={printerdata} />
                            <DefaultprinterModel open={openprintermodel} data={tabledata} getSelectedPrinter={(e)=>handleselectedPrinter(e)} />
                        </div></TabPanel> */}
                        {/* <TabPanel> <div className="container" style={{ paddingTop: '0.5rem' }} > Users Setting </div></TabPanel> */}
                        {/* <TabPanel>
                            <div className='container my-4'>
                                <label className='' style={{ display: 'flex', width: '26%', fontSize: '15px'}} htmlFor="material-switch">
                                <span className='mr-2'>Do not show listing on ipaypro.co</span>
                                <Switch
                                    checked={listingStatus}
                                    onChange={handleListingStatus}
                                    className="react-switch"
                                    handleDiameter={20}
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    height={22}
                                    width={42}
                                    id="material-switch"
                                />
                                </label>
                            </div>
                        </TabPanel> */}
                    </div>
                </Tabs>
            </div>
        </div>
    )
}
