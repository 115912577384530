import React, { useState, useEffect } from 'react'
import { Table, Button } from 'reactstrap';
import PaginationComponent from './Pagination.jsx';
import "./printer.scss";

const receipt_format = [
    { value: 1, label: "Kitchen receipt docket" },
    { value: 2, label: "Customer receipt" },
]

const PrintersTab = ({ merchant, printers, registers }) => {
    const [rowsToShow, setRowsToShow] = useState([]);
    const [itemsToShow, setItemsToShow] = useState(10);
    const [pageOfItems, setPageOfItems] = useState(1);

    const filterRows = (originalRows, pageNumber, rowsOnPage) => {
        const rowsFrom = rowsOnPage * (pageNumber - 1);
        const rowsTo = rowsFrom + rowsOnPage;
        return originalRows?.slice(rowsFrom, rowsTo);
    };

    useEffect(() => {
        const currentPageRows = filterRows(printers, 1, 10);
        setRowsToShow(currentPageRows);
    }, [printers]);

    const onChangePage = (pageOfItems) => {
        if (pageOfItems) {
            const rowsToShow = filterRows(printers, pageOfItems, itemsToShow);
            setRowsToShow(rowsToShow);
            setPageOfItems(pageOfItems);
        }
    };

    return (
        <div className='printer_table_styles'>
            <div>
                {
                    rowsToShow.length > 0 ?
                        <div
                            style={{
                                overflowX: "auto",
                                display: "block",
                                width: "100%",
                                scrollBehavior: "smooth",
                                scrollbarWidth: 'none',
                            }}
                        >
                            <Table className="table--bordered" striped>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Printer Name</th>
                                        <th>Printer Type</th>
                                        <th>Linked Register(s)</th>
                                        <th style={{ maxWidth: '150px' }}>Print</th>
                                        <th style={{ maxWidth: '150px' }}>Receipt Format</th>
                                        <th>Linked Cash Drawer</th>
                                        <th>Display Price on kitchen receipt</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        rowsToShow.map((item, index) => {
                                            return (
                                                <tr key={item._id}>
                                                    <td>{index + 1}</td>
                                                    <td style={{ textTransform: "capitalize" }}>
                                                        {/* {`${item.name}`.replace(/(.{15})/g, '$1\n')} */}
                                                        {`${item.name}`}
                                                    </td>
                                                    <td style={{ textTransform: "capitalize" }}>
                                                        {
                                                            item.type == 1 ? <div>
                                                                <p>Ethernet (LAN/IP)</p>
                                                                <p>{item.ip_address} : {item.paper_size} MM</p>
                                                            </div>
                                                                : item.type == 2 ? <div>
                                                                    <p>Bluetooth</p>
                                                                    <p>Device : {item.ip_address} : {item.paper_size} MM</p>
                                                                </div>
                                                                    : <></>
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            item.posregisters && item.posregisters.length > 0 ? registers.filter(a => {
                                                                let matchFount = false;
                                                                item.posregisters.map(b => {
                                                                    if (b == a._id) {
                                                                        matchFount = true;
                                                                    }
                                                                })
                                                                return matchFount
                                                            }).map(a => a.registerName).toString().replaceAll(",", ", ").replace(/(.{15})/g, '$1\n') : "-"
                                                        }
                                                    </td>
                                                    <td style={{ textTransform: "capitalize" }}>{item.printerfor.toString().replaceAll(",", ", ")}</td>
                                                    {/* .replace(/(.{15})/g, '$1\n') */}
                                                    <td>
                                                        {
                                                            item.receipt_format && item.receipt_format.length > 0 ? receipt_format.filter(a => {
                                                                let matchFount = false;
                                                                item.receipt_format.map(b => {
                                                                    if (b == a.value) {
                                                                        matchFount = true;
                                                                    }
                                                                })
                                                                return matchFount
                                                                {/* }).map(a => a.label).toString().replaceAll(",", ", ").replace(/(.{15})/g, '$1\n') : "-" */ }
                                                            }).map(a => a.label).toString().replaceAll(",", ", ") : "-"
                                                        }
                                                    </td>

                                                    <td style={{ textTransform: "capitalize" }}>
                                                        {/* {item.cashDrawer ? `${item.cashDrawer}`.replace(/(.{15})/g, '$1\n') : '-'} */}
                                                        {item.cashDrawer ? `${item.cashDrawer}` : '-'}
                                                    </td>

                                                    <td>
                                                        <div className="text-center">
                                                            <input
                                                                id="price"
                                                                name="price"
                                                                type="checkbox"
                                                                // defaultChecked={item.print_price ? true : false}
                                                                checked={item.print_price ? true : false}
                                                                readOnly
                                                                value="price"
                                                                style={{ height: "23px", width: "23px" }}
                                                                onChange={(event) => {
                                                                    // dispatch(printPriceAction({ print_price: event.target.checked, id: item._id, merchant_id }))
                                                                    return false
                                                                }}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div> :
                        <div style={{ textAlign: "center", marginTop: "30px" }}>
                            {/* <img src={invoice} style={{ height: "50px", width: "50px" }}></img> */}
                            <p style={{ margin: "0", marginTop: "0.5rem" }}>No record found!</p>
                        </div>
                }

                {
                    registers.length > 10 ? <PaginationComponent
                        itemsCount={registers.length}
                        itemsToShow={itemsToShow}
                        pageOfItems={pageOfItems}
                        onChangePage={onChangePage} /> : null
                }
            </div>
        </div>
    )
}

export default PrintersTab