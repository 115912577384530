import { status } from "nprogress";
import React, { Fragment, useEffect, useState } from "react";
import useForm from "react-hook-form";

const BusinessTypeProductPage = ({
    showData,
    defaultVaue,
    updatePortalCatSubCat,
    getSubCatSubData,
    otherCategory
}) => {

    const [otherCategoryData, setOtherCategoryData] = useState(null);

    useEffect(() => {
        if (showData && otherCategory) {
            let findOtherCategory = otherCategory.find((currCat) => currCat.portal_id == showData.brand_ID);
            setOtherCategoryData(findOtherCategory);
        }

    }, [showData, otherCategory])


    // let { register, errors } = useForm({ defaultValues: defaultVaue });   

    const renderPortalName = (first_URL, second_URL) => {

        if (first_URL && second_URL) {
            let setFirst_URL = first_URL.slice(8);
            let setSecond_URL = second_URL.slice(8);
            const outPut = setFirst_URL.charAt(0).toUpperCase() + setFirst_URL.slice(1);
            return `${outPut} & ${setSecond_URL}`;
        }
    }

    const updateCateSubCat = (data) => {

        updatePortalCatSubCat(data);
    }

    const catsubcatStatus = (currData) => {

        if (showData.brand_ID == 1 && typeof (currData.ipaypro_status) == "undefined" && currData.status == 1) {
            return true
        } else if (showData.brand_ID == 1 && currData.ipaypro_status == 1) {
            return true
        } else if (showData.brand_ID == 2 && typeof (currData.linky_status) == "undefined" && currData.status == 1) {
            return true
        } else if (showData.brand_ID == 2 && currData.linky_status == 1) {
            return true
        } else if (showData.brand_ID == 3 && typeof (currData.hustlerz_status) == "undefined" && currData.status == 1) {
            return true
        } else if (showData.brand_ID == 3 && currData.hustlerz_status == 1) {
            return true
        } else {
            return false
        }
    }

    return (
        <Fragment>
            <div className="container-fluid main_div px-0 m-4">
                <form noValidate="">
                    <div className="" style={{ width: "45rem" }}>
                        <h4 style={{ fontWeight: "600", fontSize: "14px", marginBottom: "5px" }}>{renderPortalName(showData?.website_URL.third_URL, showData?.website_URL.secondURL)}</h4>
                        <h4 style={{ fontSize: "14px", marginBottom: "1rem" }}>Category & Subcategory </h4>
                        <div className="table-responsive">
                            <table className="table table-border-horizontal">
                                <thead>
                                    <tr style={{ background: '#f9fafe', width: "100%" }}>
                                        <th scope="col" style={{ width: "35%" }}>Category</th>
                                        <th scope="col" style={{ width: "45%" }}>Subcategory</th>
                                        <th scope="col" style={{ width: "20%" }}>Subcategory Status</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        getSubCatSubData && getSubCatSubData.map((items, index) => {

                                            let findSubCatIndex = items.sub_category.findIndex((currSubCat) => currSubCat.status == 1);
                                            if (findSubCatIndex != -1) {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td> <p>{items.category_name}</p></td>
                                                            <td>
                                                                {
                                                                    items.sub_category.map((currnSubCat) => {
                                                                        if (currnSubCat.status != 0) {
                                                                            return (
                                                                                <><p>{currnSubCat.subCategory}</p></>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    items.sub_category.map((currnSubCat, currIndex) => {
                                                                        if (currnSubCat.status != 0) {
                                                                            return (
                                                                                <>
                                                                                    <p>
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            defaultChecked={catsubcatStatus(currnSubCat)}
                                                                                            onChange={(event) => {
                                                                                                let subCatValue = event.target.checked == true ? 1 : 0;
                                                                                                let getCurrSubData;

                                                                                                if (showData.brand_ID == 1) {
                                                                                                    getCurrSubData = { ...currnSubCat, ipaypro_status: subCatValue }
                                                                                                } else if (showData.brand_ID == 2) {
                                                                                                    getCurrSubData = { ...currnSubCat, linky_status: subCatValue }
                                                                                                } else if (showData.brand_ID == 3) {
                                                                                                    getCurrSubData = { ...currnSubCat, hustlerz_status: subCatValue }
                                                                                                }
                                                                                                let postData = { catName: items.category_name, cat_id: items._id, subCatData: getCurrSubData };
                                                                                                updateCateSubCat(postData);
                                                                                            }}
                                                                                        />
                                                                                    </p>
                                                                                </>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            }
                                        })
                                    }
                                    {otherCategoryData ?
                                        <>
                                            <tr>
                                                <td> <p>{otherCategoryData.category_name}</p></td>
                                                <td>
                                                    {
                                                        otherCategoryData.sub_category.map((currnSubCat) => {
                                                            return (
                                                                <><p>{currnSubCat.subCategory}</p></>
                                                            )
                                                        })
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        otherCategoryData.sub_category.map((currnSubCat) => {
                                                            return (
                                                                <p>
                                                                    <input
                                                                        type="checkbox"
                                                                        defaultChecked={currnSubCat.status == 1 ? true : false}
                                                                        disabled
                                                                    />
                                                                </p>
                                                            )
                                                        })
                                                    }
                                                </td>
                                            </tr>
                                        </> : null
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </form>
            </div>
        </Fragment>
    )
}

export default BusinessTypeProductPage