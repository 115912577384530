import React,{useState} from "react";
import { Fragment } from "react";
import { Button, Label, Form, FormGroup, Col, Row, Input, CustomInput } from 'reactstrap';
import useForm  from "react-hook-form";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import {Link,useHistory} from "react-router-dom";
import Breadcrumb from '../../../components/common/breadcrumb';

const EditCountryForm = (props) => {
    let [loadingbtn, setloadingbtn] = useState(false);
    let [refercb_status, setrefercb_status] = useState("1");
    let [refercb_status_merchant, setrefercb_status_merchant] = useState("1");
    let history = useHistory();
    
    const { handleSubmit, register, errors } = useForm();

    const onSubmit = form_data => {
        
        if (form_data !== '') {

            setloadingbtn(true);

            client.post(api.add_country, form_data, async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                // props.fetc_offers();
                                // props.onClose(true);
                                toast.success("Added Successfully");
                                history.push(`${process.env.PUBLIC_URL}/settings/countries`);
                                
                                // setcashbackdata(response.result.data);
                            } else {
                                toast.error(response.message);
                            }
                        } catch (e) {
                            toast.error("Internal error occured. Please contact support 2");
                        }
                    } else {
                        toast.error("Internal error occured. Please contact support 2");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support 3");
                }
                setloadingbtn(false);
            });
        }
        else {
            errors.showMessages();
        }
    }

    return (
        <Fragment>
            <Breadcrumb title="Edit Country" parent="Home" />

            <div className="form-container" >
                <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                    <div className="card countryDetails">
                        <div className="card-header b-l-primary">
                            <h3>Country Details</h3>
                        </div>
                        <div className="card-body">
                            {/* <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}> */}
                            <Row form>
                                <Col md={12}>
                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="level_title">Country name
                                            </Label>
                                                <input className="form-control" type="text" name="country_name" ref={register({ required: true })} />
                                                <span>{errors.country_name && 'Country Name is required'}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="level_title">Country code</Label>
                                                <input className="form-control" type="text" name="country_code" ref={register({ required: true })} />
                                                <span>{errors.country_code && 'Country Code is required'}</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                
                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="level_title">Phone prefix
                                            </Label>
                                                <input className="form-control" type="text" name="phone_prefix" ref={register({ required: true })} />
                                                <span>{errors.phone_prefix && 'Phone prefix is required'}</span>
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="level_title">Phone regex</Label>
                                                <input className="form-control" type="text" name="phone_regex" ref={register({ required: true })} />
                                                <span>{errors.country_code && 'Phone Regex is required'}</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="level_title">Currency code
                                            </Label>
                                                <input className="form-control" type="text" name="currency_code" ref={register({ required: true })} />
                                                <span>{errors.currency_code && 'Currency code is required'}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="level_title">Currency symbol
                                            </Label>
                                                <input className="form-control" type="text" name="currency_symbol" ref={register({ required: true })} />
                                                <span>{errors.currency_symbol && 'Currency symbol is required'}</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    
                                </Col>
                            </Row>
                            {/* </form> */}
                        </div>
                    </div>
                    {/* Merchant App settings secction */}
                    <div className="card merchantSetttings">
                        <div className="card-header b-l-primary">
                            <h3>Merchant App Settings</h3>
                        </div>
                        {/* <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}> */}
                        <div className="card-body">
                            <Row form>
                                <Col md={12}>
                                    <Row form>
                                        <Col >
                                            <FormGroup>
                                                <Label for="level_title">First time top-up</Label>
                                                <input className="form-control" type="number" name="m_first_time_topup" ref={register({ required: true })} />
                                                <span>{errors.m_first_time_topup && 'First Time Topup is required'}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="level_title">Minimum Top-up/Payment Amount</Label>
                                                <input className="form-control" type="number" name="m_minimum_topup" ref={register({ required: true })} />
                                                <span>{errors.m_minimum_topup && 'Minimum Topup is required'}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="level_title">Max i-pay account wallet limit</Label>
                                                <input className="form-control" type="number" name="m_max_account_wallet_limit" ref={register({ required: true })} />
                                                <span>{errors.m_max_account_wallet_limit && 'Max account wallet limit is required'}</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    
                                </Col>
                            </Row>
                        </div>
                        <div className="card-header">
                            <h6>Refer & Earn</h6>
                        </div>
                        <div className="card-body">

                            <Row form>
                                <Col>
                                    <FormGroup>
                                        <Label for="level_title">Status</Label>
                                        <div className="row col-sm-12" style={{ marginLeft: 10 }}>
                                            <div className="radio">
                                                <input
                                                    id="refercb_status_m1"
                                                    type="radio" name="refercb_status_merchant"
                                                    value="1"
                                                    ref={register({ required: true })} defaultChecked={true}
                                                    onChange={() => setrefercb_status_merchant("1")}
                                                />
                                                <label htmlFor="refercb_status_m1">Active</label>
                                            </div>
                                            <div className="radio" style={{ marginLeft: 20 }}>
                                                <input
                                                    id="refercb_status_m0"
                                                    type="radio" name="refercb_status_merchant"
                                                    value="0"
                                                    ref={register({ required: true })}
                                                    onChange={() => setrefercb_status_merchant("0")}
                                                />
                                                <label htmlFor="refercb_status_m0">Inactive</label>
                                            </div>
                                        </div>
                                        <span>{errors.refercb_status_merchant && 'Status is required'}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                                    
                            {refercb_status_merchant == "1" &&
                                <Row form>
                                    <Col>
                                        <Label for="level_title">Refer To Customer</Label>

                                        <Row form>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="level_title">Cashback To Referrer</Label>
                                                    <input className="form-control" type="number" name="m_refer_earn_r" ref={register({ required: true })} />
                                                    <span>{errors.m_refer_earn_r && 'Cashback amount is required'}</span>
                                                </FormGroup>
                                            </Col>

                                            <Col>
                                                <FormGroup>
                                                    <Label for="level_title">Cashback To Customer</Label>
                                                    <input className="form-control" type="number" name="m_refer_earn_nc" ref={register({ required: true })} />
                                                    <span>{errors.m_refer_earn_nc && 'Cashback amount is required'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>

                                        <Label for="level_title">Refer To Merchant</Label>
                                        <Row form>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="level_title">Cashback To Referrer</Label>
                                                    <input className="form-control" type="number" name="m_refer_earn_rm" ref={register({ required: true })} />
                                                    <span>{errors.m_refer_earn_rm && 'Cashback amount is required'}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="level_title">Cashback To Merchant</Label>
                                                    <input className="form-control" type="number" name="m_refer_earn_nm" ref={register({ required: true })} />
                                                    <span>{errors.m_refer_earn_nm && 'Cashback amount is required'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            }

                        </div>
                        
                        <div className="card-header">
                            <h6>C to M - Surcharge fee</h6>
                        </div>
                        <div className="card-body">
                            <Row form>
                                <Col md={12}>
                                    <Row form>
                                        <Col>
                                            <FormGroup>
                                                <Label for="level_title">Minimum Amount</Label>
                                                <input className="form-control" type="number" name="c_to_m_sc_comp_amount" ref={register({ required: true })} />
                                                <span>{errors.c_to_m_sc_comp_amount && 'Minimum amount is required'}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="level_title">Fee type</Label>
                                                <div className="form-group">
                                                    <select className="custom-select" name="c_to_m_sc_fee_btype" ref={register({ required: true })}>
                                                        <option value="1">Percent</option>
                                                        <option value="2">Amount</option>
                                                    </select>
                                                    <span>{errors.c_to_m_sc_fee_btype && 'Fee type is required'}</span>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="level_title">Fee below min amount</Label>
                                                <input className="form-control" type="number" name="c_to_m_sc_fee_below" ref={register({ required: true })} />
                                                <span>{errors.c_to_m_sc_fee_below && 'Fee below min. amount is required'}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="level_title">Fee type</Label>
                                                <div className="form-group">
                                                    <select className="custom-select" name="c_to_m_sc_fee_atype" ref={register({ required: true })}>
                                                        <option value="1">Percent</option>
                                                        <option value="2">Amount</option>
                                                    </select>
                                                    <span>{errors.c_to_m_sc_fee_atype && 'Fee type is required'}</span>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        
                                        <Col>
                                            <FormGroup>
                                                <Label for="level_title">Fee above min amount</Label>
                                                <input className="form-control" type="number" name="c_to_m_sc_fee_above" ref={register({ required: true })} />
                                                <span>{errors.c_to_m_sc_fee_above && 'Fee above min. is required'}</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className="card-header">
                            <h6>M to M - Surcharge fee</h6>
                        </div>
                        <div className="card-body">
                            <Row form>
                                <Col>
                                    <Row form>
                                        <Col>
                                            <FormGroup>
                                                <Label for="level_title">Minimum Amount</Label>
                                                <input className="form-control" type="number" name="m_to_m_sc_comp_amount" ref={register({ required: true })} />
                                                <span>{errors.m_to_m_sc_comp_amount && 'Minimum amount is required'}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="level_title">Fee type</Label>
                                                <div className="form-group">
                                                    <select className="custom-select" name="m_to_m_sc_fee_btype" ref={register({ required: true })}>
                                                        <option value="1">Percent</option>
                                                        <option value="2">Amount</option>
                                                    </select>
                                                    <span>{errors.m_to_m_sc_fee_btype && 'Fee type is required'}</span>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        
                                        <Col>
                                            <FormGroup>
                                                <Label for="level_title">Fee below min amount</Label>
                                                <input className="form-control" type="number" name="m_to_m_sc_fee_below" ref={register({ required: true })} />
                                                <span>{errors.m_to_m_sc_fee_below && 'Fee below min. amount is required'}</span>
                                            </FormGroup>
                                        </Col>
                                        
                                        <Col>
                                            <FormGroup>
                                                <Label for="level_title">Fee type</Label>
                                                <div className="form-group">
                                                    <select className="custom-select" name="m_to_m_sc_fee_atype" ref={register({ required: true })}>
                                                        <option value="1">Percent</option>
                                                        <option value="2">Amount</option>
                                                    </select>
                                                    <span>{errors.m_to_m_sc_fee_atype && 'Fee type is required'}</span>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        
                                        <Col>
                                            <FormGroup>
                                                <Label for="level_title">Fee above min amount</Label>
                                                <input className="form-control" type="number" name="m_to_m_sc_fee_above" ref={register({ required: true })} />
                                                <span>{errors.m_to_m_sc_fee_above && 'Fee above min. is required'}</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className="card-header">
                            <h6>Withdrawal fee</h6>
                        </div>
                        <div className="card-body">
                            <Row form>
                                <Col md={12}>
                                    <Row form>
                                        <Col>
                                            <FormGroup>
                                                <Label for="level_title">Minimum Amount</Label>
                                                <input className="form-control" type="number" name="m_wd_comp_amount" ref={register({ required: true })} />
                                                <span>{errors.m_wd_comp_amount && 'Minimum amount is required'}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="level_title">Fee type</Label>
                                                <div className="form-group">
                                                    <select className="custom-select" name="m_wd_fee_btype" ref={register({ required: true })}>
                                                        <option value="1">Percent</option>
                                                        <option value="2">Amount</option>
                                                    </select>
                                                    <span>{errors.m_wd_fee_btype && 'Fee type is required'}</span>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="level_title">Fee below min amount</Label>
                                                <input className="form-control" type="number" name="m_wd_fee_below" ref={register({ required: true })} />
                                                <span>{errors.m_wd_fee_below && 'Fee below min. amount is required'}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="level_title">Fee type</Label>
                                                <div className="form-group">
                                                    <select className="custom-select" name="m_wd_fee_atype" ref={register({ required: true })}>
                                                        <option value="1">Percent</option>
                                                        <option value="2">Amount</option>
                                                    </select>
                                                    <span>{errors.m_wd_fee_atype && 'Fee type is required'}</span>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="level_title">Fee above min amount</Label>
                                                <input className="form-control" type="number" name="m_wd_fee_above" ref={register({ required: true })} />
                                                <span>{errors.m_wd_fee_above && 'Fee above min. is required'}</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        {/* </form> */}
                    </div>

                    {/* Customer App settings */}
                    <div className="card customerSetttings">
                        <div className="card-header b-l-primary">
                            <h3>Customer App Settings</h3>
                        </div>
                        
                        <div className="card-body">
                            <Row form>
                                <Col md={12}>
                                    <Row form>
                                        <Col >
                                            <FormGroup>
                                                <Label for="level_title">First time top-up</Label>
                                                <input className="form-control" type="number" name="c_first_time_topup" ref={register({ required: true })} />
                                                <span>{errors.c_first_time_topup && 'First Time Topup is required'}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="level_title">Cashback</Label>
                                                <input className="form-control" type="number" name="c_cashback" ref={register({ required: true })} />
                                                <span>{errors.c_cashback && 'Cashback is required'}</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col>
                                            <FormGroup>
                                                <Label for="level_title">Max i-pay account wallet limit</Label>
                                                <input className="form-control" type="number" name="c_max_account_wallet_limit" ref={register({ required: true })} />
                                                <span>{errors.c_max_account_wallet_limit && 'Max account wallet limit is required'}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="level_title">Minimum Top-up/Payment Amount</Label>
                                                <input className="form-control" type="number" name="c_minimum_topup" ref={register({ required: true })} />
                                                <span>{errors.c_minimum_topup && 'Minimum Topup is required'}</span>
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                </Col>
                            </Row>

                        </div>
                        <div className="card-header">
                            <h6>Refer & Earn</h6>
                        </div>
                        <div className="card-body">

                            <Row form>
                                <Col>
                                    <FormGroup>
                                        <Label for="level_title">Status</Label>
                                        <div className="row col-sm-12" style={{ marginLeft: 10 }}>
                                            <div className="radio">
                                                <input id="refercb_status1" type="radio" name="refercb_status" value="1" ref={register({ required: true })} defaultChecked={true} onChange={()=>setrefercb_status("1")} />
                                                <label htmlFor="refercb_status1">Active</label>
                                            </div>
                                            <div className="radio" style={{ marginLeft: 20 }}>
                                                <input id="refercb_status0" type="radio" name="refercb_status" value="0" ref={register({ required: true })} defaultChecked={false} onChange={()=>setrefercb_status("0")}/>
                                                <label htmlFor="refercb_status0">Inactive</label>
                                            </div>
                                        </div>
                                        <span>{errors.refercb_status && 'Status is required'}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                                    
                            {refercb_status == "1" &&
                                <Row form>
                                    <Col>
                                        <Label for="level_title">Refer To Customer</Label>

                                        <Row form>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="level_title">Cashback To Referrer</Label>
                                                    <input className="form-control" type="number" name="c_refer_earn_r" ref={register({ required: true })} />
                                                    <span>{errors.c_refer_earn_r && 'Cashback amount is required'}</span>
                                                </FormGroup>
                                            </Col>

                                            <Col>
                                                <FormGroup>
                                                    <Label for="level_title">Cashback To Customer</Label>
                                                    <input className="form-control" type="number" name="c_refer_earn_nc" ref={register({ required: true })} />
                                                    <span>{errors.c_refer_earn_rm && 'Cashback amount is required'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>

                                        <Label for="level_title">Refer To Merchant</Label>
                                        <Row form>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="level_title">Cashback To Referrer</Label>
                                                    <input className="form-control" type="number" name="c_refer_earn_rm" ref={register({ required: true })} />
                                                    <span>{errors.c_refer_earn_nc && 'Cashback amount is required'}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="level_title">Cashback To Merchant</Label>
                                                    <input className="form-control" type="number" name="c_refer_earn_nm" ref={register({ required: true })} />
                                                    <span>{errors.c_refer_earn_nc && 'Cashback amount is required'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            }

                        </div>
                        <div className="card-header">
                            <h6>Withdrawal fee</h6>
                        </div>
                        <div className="card-body">
                            <Row form>
                                <Col>
                                    <Row form>
                                        <Col>
                                            <FormGroup>
                                                <Label for="level_title">Minimum Amount</Label>
                                                <input className="form-control" type="number" name="c_wd_comp_amount" ref={register({ required: true })} />
                                                <span>{errors.c_wd_comp_amount && 'Minimum amount is required'}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="level_title">Fee type</Label>
                                                <div className="form-group">
                                                    <select className="custom-select" name="c_wd_fee_btype" ref={register({ required: true })}>
                                                        <option value="1">Percent</option>
                                                        <option value="2">Amount</option>
                                                    </select>
                                                    <span>{errors.c_wd_fee_btype && 'Fee type is required'}</span>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="level_title">Fee below min amount</Label>
                                                <input className="form-control" type="number" name="c_wd_fee_below" ref={register({ required: true })} />
                                                <span>{errors.c_wd_fee_below && 'Fee below min. amount is required'}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="level_title">Fee type</Label>
                                                <div className="form-group">
                                                    <select className="custom-select" name="c_wd_fee_atype" ref={register({ required: true })}>
                                                        <option value="1">Percent</option>
                                                        <option value="2">Amount</option>
                                                    </select>
                                                    <span>{errors.c_wd_fee_atype && 'Fee type is required'}</span>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="level_title">Fee above min amount</Label>
                                                <input className="form-control" type="number" name="c_wd_fee_above" ref={register({ required: true })} />
                                                <span>{errors.c_wd_fee_above && 'Fee above min. is required'}</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    
                                </Col>
                            </Row>
                            <hr />
                            <div className="">
                                {!loadingbtn ?
                                    <>
                                        <Link to={`${process.env.PUBLIC_URL}/settings/countries`}>
                                            <Button color="default" type="button" onClick={props.onClose}>Cancel</Button>
                                        </Link>
                                        <Button color="primary" className="pull-right" type="submit" style={{ marginLeft: "2rem" }}>Save</Button>
                                    </>
                                    :
                                    <div className="loader-box pull-right" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 20, width: 20 }}></span></div>
                                }
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Fragment>
    )
}

export default EditCountryForm;