import * as actionTypes from "./actionTypes";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";

export const fetch_category_start = () => {
    return {
        type: actionTypes.FETCH_CATDATA_START
    }

}

export const fetch_category_success = (data) => {
    return {
        type: actionTypes.FETCH_CATDATA_SUCCESS,
        payload: data
    }
}

export const fetch_category_failed = error => {
    return {
        type: actionTypes.FETCH_CATDATA_FAILED,
        payload: error
    }
}

export const fetch_category = () => {
    return dispatch => {
        client.post(api.fetch_catsubcat, { show_all: true }, (error, response) => {
            if (!error) {
                try {
                    if (!response.error) {
                        dispatch(fetch_category_success(response.result.data))
                    } else {
                        dispatch(fetch_category_failed(response.message))
                    }
                } catch (e) {
                    dispatch(fetch_category_failed(response.message))
                }
            } else {
                dispatch(fetch_category_failed(response.message))
            }
            //   current.setState({ loading: false });
        });
    }
}