import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import displayMobile from "../../components/ui-elements/display_mobile";
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    UncontrolledTooltip,
    Input,
} from "reactstrap";
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink as NavLinkTab,
    Card, CardTitle, CardText, Row, Col ,
} from "reactstrap";
import * as moment from "moment-timezone";
import { DisappearedLoading } from "react-loadingg";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import PaginationComponent from "react-reactstrap-pagination";
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import { ChevronLeft, Shield, Trash2, Edit } from 'react-feather';
import classnames from 'classnames';
import "./googlebusiness.css";
import  useForm  from "react-hook-form";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from 'react-places-autocomplete';
import DropZoneField from "./dropzone/DropZoneField";

const convertToSlug = (Text) => {
    return Text.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
  }

// const BorderTable = () => {
function GoogleBusinessApi(props) {
    let [loadingbtn, setloadingbtn] = useState(false);
    let [loading, setloading] = useState(false);
    let [result, setresult] = useState(null);
    const [tabIndex, setTabIndex] = useState(0);
    const [stabIndex, setStabIndex] = useState('1');
    const [activePage, setActivePage] = useState(1);
    const [pageSize, setPageSize] = useState(25);

    const [importedBusiness, setImportedBusiness] = useState([]);
    const [listingEnquiries, setListingEnquiries] = useState([]);
    const [mapPos, setMapPos] = useState(null)
    const [allPlace, setAllPlace] = useState([]);
    const [businessType, setBusinessType] = useState(null);
    const [businessName, setBusinessName] = useState(null);

    //Place details
    const [placeDetails, setPlaceDetails] = useState(null);
    const [placeEditDetails, setPlaceEditDetails] = useState(null);
    const [placeDeleteID, setPlaceDeleteID] = useState(null);
    const [listingDeleteID, setListingDeleteID] = useState(null);
    const [activeTab, setActiveTab] = useState(0);

    //All Modal
    const [importModal, setImportModal] = useState(false);
    const [selectedImportModal, setSelectedImportModal] = useState(false)
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [listingDeleteModal, setListingDeleteModal] = useState(false);

    const [businessCategory, setBusinessCategory] = useState([]);
    const [subcategory, setSubcategory] = useState([]);
    const [catSubCat, setCatSubCat] = useState([]);
    const [allPlaceLoading, setAllPlaceLoading] = useState(false);
    const [address, setAddress] = useState('');
    const [latLng, selLatLng] = useState({});
    const [selectedPlace, setSelectedPlace] = useState([]);

    const [fetchLLoader, setFetchLLoader] = useState(false);
    const [fetchBLoader, setFetchBLoader] = useState(false);

    //Errors declaration
    const [addressErr, setAddressErr] = useState(false);
    const [businessTypeErr, setBusinessTypeErr] = useState(false);
    const [businessNameErr, setBusinessNameErr] = useState(false);
    const [imageData, setImageData] = useState({mime: "", data: "", imagePreviewUrl: ""});
    const handleChange = address => {
        setAddress(address);
        setAddressErr(false);
      };
     
    const handleSelect = address => {
        geocodeByAddress(address)
          .then(results => getLatLng(results[0]))
          .then(latLng => { console.log('Success', latLng); selLatLng(latLng); setAddress(address)})
          .catch(error => console.error('Error', error));
      };

    const { register, handleSubmit, errors } = useForm();
    const onSubmit = (data) => {
        setloadingbtn(true);
        let allphotoUrl = [];
        if(placeDetails.photos)
        {
            placeDetails.photos.map((row) => {
                allphotoUrl.push(row.getUrl());
            });
        }
        let postData = {
            ...data, 
            website: placeDetails.website,
            address: placeDetails.formatted_address,
            opening_hours: placeDetails.opening_hours ? placeDetails.opening_hours.weekday_text : '',
            photos: allphotoUrl,
            types: placeDetails.types,
            latitute: placeDetails.geometry.location.lat(),
            longitute: placeDetails.geometry.location.lng(),
            business_slug: convertToSlug(data.business_name),
            image_data: imageData
        }

        client.post(api.saveGoogleBusiness, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    //console.log(postData, "pos")
                    toast.success("Import Business successfully");
                    toggleImportModal();
                    setloadingbtn(false);
                } else {
                    toast.error("Something went wrong !");
                    setloadingbtn(false);
                }
            } else {
                toast.error("Something went wrong");
                setloadingbtn(false);
            }
        })
    }

    const onMultipleSubmit = (data) => {

        if(selectedPlace) {
        selectedPlace.map(placeId => {
            const {google} =  props;

            var request = {
                placeId: placeId,
                fields: ['name', 'rating', 'photo', 'type', 'formatted_address','formatted_phone_number', 'international_phone_number', 'opening_hours', 'website','geometry']
              };
              
            const service = new google.maps.places.PlacesService(mapPos);
            service.getDetails(request, function(place, status) {
                if (status == props.google.maps.places.PlacesServiceStatus.OK) {
                    try{
                    let allphotoUrl = [];
                    if(place.photos)
                    {
                        place.photos.map((row) => {
                            allphotoUrl.push(row.getUrl());
                        });
                    }
                    let postData = {
                        ...data, 
                        business_name: place.name,
                        phone_number: place.formatted_phone_number,
                        website: place.website,
                        address: place.formatted_address,
                        opening_hours: place.opening_hours ? place.opening_hours.weekday_text : '',
                        photos: allphotoUrl,
                        types: place.types,
                        latitute: place.geometry.location.lat(),
                        longitute: place.geometry.location.lng(),
                        business_slug: convertToSlug(place.name),
                    }

                    client.post(api.saveGoogleBusiness, postData, (error, response) => {
                        if (!error) {
                            if (!response.error) {
                            } else {
                                toast.error("Something went wrong !")
                            }
                        } else {
                            toast.error("Something went wrong")
                        }
                    })
                    }catch(e){
                        console.log(e)
                    }
                    }
                        });
                    });
             toast.success("Import Business successfully")
             toggleSelectedImportModal();
        }else{
            toast.warn("Select atleast one business from listing")
            toggleSelectedImportModal();
        }
    }

    const onEditSubmit = (data) => { 
        setloadingbtn(true);
        let postData = {
            ...data, 
            business_slug: convertToSlug(data.business_name),
            image_data: imageData
        }

        let edit_id = placeEditDetails ? placeEditDetails._id : null

        client.post(api.updateGoogleBusiness, { updated_data : postData , id : edit_id }, (error, response) => {
            if (!error) {
                if (!response.error) {
                    //console.log(postData, "pos")
                    setImportedBusiness(response.result.data);
                    toast.success("Updated Business successfully");
                    toggleEditModal();
                    setloadingbtn(false);
                } else {
                    toast.error("Something went wrong !");
                    setloadingbtn(false);
                }
            } else {
                toast.error("Something went wrong");
                setloadingbtn(false);
            }
        })
     };

    const onDeleteSubmit = () => {

        let edit_id = placeDeleteID ? placeDeleteID : null

        client.post(api.deleteGoogleBusiness, { id : edit_id }, (error, response) => {
            if (!error) {
                if (!response.error) {
                    //console.log(postData, "pos")
                    setImportedBusiness(response.result.data);
                    toast.success("Deleted Business successfully")
                    toggleDeleteModal();
                } else {
                    toast.error("Something went wrong !")
                }
            } else {
                toast.error("Something went wrong")
            }
        })
    }

    const onListingDeleteSubmit = () => {

        let edit_id = listingDeleteID ? listingDeleteID : null

        client.post(api.deleteListingEnquiries, { id : edit_id }, (error, response) => {
            if (!error) {
                if (!response.error) {
                    //console.log(postData, "pos")
                    setListingEnquiries(response.result.data);
                    toast.success("Deleted Listing Enquiries successfully")
                    toggleListingDeleteModal();
                } else {
                    toast.error("Something went wrong !")
                }
            } else {
                toast.error("Something went wrong")
            }
        })
    }

    const toggleImportModal = () =>{
        setImageData({mime: "", data: "", imagePreviewUrl: ""});
        setImportModal(!importModal);
    }

    const toggleSelectedImportModal = () => {
        setSelectedImportModal(!selectedImportModal);
    }

    const toggleEditModal = () => {
        setImageData({mime: "", data: "", imagePreviewUrl: ""});
        setEditModal(!editModal);
    }

    const toggleDeleteModal = () => {
        setImageData({mime: "", data: "", imagePreviewUrl: ""});
        setDeleteModal(!deleteModal);
    }

    const toggleListingDeleteModal = () => {
        setListingDeleteModal(!listingDeleteModal);
    }

    const fetchPlaces = (mapProps, map) => {
        try {
        
        const {google} = mapProps? mapProps : props;

        setMapPos(map? map : mapPos);

        let lat = latLng.lat != undefined ? latLng.lat : null;
        let lng = latLng.lng != undefined ? latLng.lng : null;
        
        var australia = new google.maps.LatLng( lat, lng);

        var request = {
            location: australia,
            radius: '50000',
            type: [businessType]
          };
        
        if(businessType)
        {
        const service = new google.maps.places.PlacesService(map? map : mapPos);

        //get list of all nearby places
        service.nearbySearch(request, fetchPlace_callback);

        }
        }
        catch(error){
            console.log(error)
        }
      }

      function fetchPlace_callback(results, status, pagination) {
        if (status == props.google.maps.places.PlacesServiceStatus.OK) {
            setAllPlaceLoading(true);
            setFetchLLoader(true);
            let places = allPlace ? allPlace : [];
            for (var i = 0; i < results.length; i++) {
                places.push(results[i]);
            }

            if(pagination && pagination.hasNextPage)
            {
                pagination.nextPage();
            }else{
                setAllPlaceLoading(false);
                setFetchLLoader(false);
            }
            setAllPlace(places);
            setActiveTab(1);
        }
      }

    const fetchPlaceDetails = (placeId) => {

        const {google} =  props;

        var request = {
            placeId: placeId,
            fields: ['name', 'rating', 'photo', 'type', 'formatted_address','formatted_phone_number', 'international_phone_number', 'opening_hours', 'website','geometry']
          };
          
        const service = new google.maps.places.PlacesService(mapPos);
        service.getDetails(request, placeDetails_callback);
    }

    function placeDetails_callback(place, status) {
        if (status == props.google.maps.places.PlacesServiceStatus.OK) {
            setPlaceDetails(place);
            setActiveTab(2);
        }
      }
    
    const fetchPlaceByName = () => {
        try {
        
            const {google} = props;
    
            setMapPos(mapPos);
            
            var australia = new google.maps.LatLng(-37.810830, 145.001470);
    
            var request = {
                location: australia,
                radius: '50000',
                query: [businessName]
              };
            
            if(businessName)
            {
            const service = new google.maps.places.PlacesService(mapPos);
    
            //get list of all nearby places using business name
            service.textSearch(request, fetchPlaceByName_callback);
    
            }
            }
            catch(error){
                console.log(error)
            }
    }

    const handleAddItem = (e,item) => {
        let items = [...selectedPlace]
        if(e.target.checked)
            items.push(item)
        else {
          var index = items.indexOf(item);
          items.splice(index,1);
        }
        setSelectedPlace(items);
      }

    const fetchPlaceByName_callback = (results, status, pagination) => {
        if (status == props.google.maps.places.PlacesServiceStatus.OK) {
            setAllPlaceLoading(true);
            setFetchBLoader(true);
            for (var i = 0; i < results.length; i++) {
                let places = allPlace ? allPlace : [];
                for (var i = 0; i < results.length; i++) {
                  places.push(results[i]);
                }

                    if(pagination && pagination.hasNextPage)
                    {
                        pagination.nextPage();
                    }else{
                        setAllPlaceLoading(false);
                        setFetchBLoader(false);
                    }

                  setAllPlace(places);
                  setActiveTab(1);
            }
          }
    }

    useEffect(() => {
        if (tabIndex == 1) {
            setresult(importedBusiness);
        }else if(tabIndex == 2) {
            setresult(listingEnquiries)
        }
    }, [tabIndex, importedBusiness, listingEnquiries]);

    useEffect(() => {
        fetchImportedBusiness();
        fetchListingEnquiries();
        fetchData();
    }, []);

    const getPhoto = async (e) => {
        e.preventDefault();
        if (e.target.files.length === 0)
            return;
        //Image upload validation
        var mimeType = e.target.files[0].type;

        if (mimeType.match(/(jpeg|jpg|png|pdf)$/) == null) {
            return;
        }

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
          setImageData({
            mime: mimeType,
            data: reader.result.split(',')[1],
            imagePreviewUrl: reader.result
          });
        }

        reader.readAsDataURL(file);
        
      }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    const fetchImportedBusiness = async () => {
        setloading(true);
        const postData = {};
        client.post(
            api.getAllImportedBusiness,
            postData,
            async function (error, response) {
                if (!error) {
                    
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                //console.log("inquiry data ", response);
                                // setresult(response.result.data);
                                setImportedBusiness(response.result.data);
                                setloading(false);
                            } else {
                                toast.error(response.message);
                            }
                        } catch (e) {
                            toast.error(
                                "Internal error occured. Please contact support"
                            );
                        }
                    } else {
                        toast.error(
                            "Internal error occured. Please contact support"
                        );
                    }
                } else {
                    //console.log(error);
                    toast.error(
                        "Internal error occured. Please contact support"
                    );
                }

                setloading(false);
            }
        );
    };

    const fetchListingEnquiries = async () => {
        setloading(true);
        const postData = {};
        client.post(
            api.getAllListingEnquiries,
            postData,
            async function (error, response) {
                if (!error) {
                    
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                console.log("inquiry data ", response);
                                // setresult(response.result.data);
                                setListingEnquiries(response.result.data);
                                setloading(false);
                            } else {
                                toast.error(response.message);
                            }
                        } catch (e) {
                            toast.error(
                                "Internal error occured. Please contact support"
                            );
                        }
                    } else {
                        toast.error(
                            "Internal error occured. Please contact support"
                        );
                    }
                } else {
                    //console.log(error);
                    toast.error(
                        "Internal error occured. Please contact support"
                    );
                }

                setloading(false);
            }
        );
    };

    const renderTable = () => {
        const filteredresult = result;
        //console.log("result==>", result);
        return (
            <Fragment>
                {result && result.length > 0 ? (
                    <Fragment>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <div>
                                {/* <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                                    <div >
                                        <input style={{
                                            border: "none",
                                            borderBottom: "1px solid rgb(197, 189, 189)",
                                            borderRadius: "0",
                                            margin: "0px 10px 6px",
                                            fontSize: "0.75rem",

                                        }}
                                            type="text"
                                            placeholder="Search"
                                            onChange={this.onSearchChangeHandler} />
                                    </div>
                                </form> */}
                            </div>
                            {activePage * pageSize > filteredresult.length ? (
                                <p
                                    style={{
                                        textAlign: "right",
                                        fontSize: "0.63rem",
                                        margin: "0",
                                    }}
                                >
                                    Showing {(activePage - 1) * pageSize + 1} to{" "}
                                    {filteredresult.length} of{" "}
                                    {filteredresult.length} entries
                                </p>
                            ) : (
                                <p
                                    style={{
                                        textAlign: "right",
                                        fontSize: "0.63rem",
                                        margin: "0",
                                    }}
                                >
                                    Showing {(activePage - 1) * pageSize + 1} to{" "}
                                    {activePage * pageSize} of{" "}
                                    {filteredresult.length} entries
                                </p>
                            )}
                        </div>
                        <div className="table-responsive">
                            <table className="table table-border-horizontal table-striped table-light table-striped table-light">
                                <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th
                                                scope="col"
                                                style={{ width: "10%" }}
                                            >
                                                <span>Date</span>
                                                <span>Time</span>
                                            </th>
                                            <th scope="col">Business Name</th>
                                            <th scope="col">Contact Number</th>
                                            <th scope="col">Address</th>
                                            <th scope="col">Category</th>
                                            <th scope="col">Sub Category</th>
                                            {/* <th scope="col">Website</th> */}
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>

                                <tbody>
                                    {result
                                        .slice(
                                            (activePage - 1) * pageSize,
                                            activePage * pageSize
                                        )
                                        .map((item, index) =>
                                             
                                                <tr key={index + 1} >
                                                    <th>{++index}</th>
                                                    <td
                                                        style={{ width: "10%" }}
                                                    >
                                                        {moment
                                                            .tz(
                                                                item.createdAt,
                                                                "Australia/Sydney"
                                                            )
                                                            .format(
                                                                "DD/MM/YYYY HH:MM:SS"
                                                            )}
                                                    </td>
                                                    <td>{item.business_name}</td>
                                                    <td>
                                                        {displayMobile(
                                                            item.phone_number
                                                        )}
                                                    </td>
                                                    <td>{item.address}</td>
                                                    <td>{item.business_category}</td>
                                                    <td>{item.business_sub_category}</td>
                                                    {/* <td>{item.website}</td> */}
                                                    <td> <Edit onClick={() => { setPlaceEditDetails(item); toggleEditModal();}} size={16}/> <Trash2 onClick={() => { setPlaceDeleteID(item._id); toggleDeleteModal();}} size={16} color={`red`}/> </td>
                                                </tr>
                                            
                                        )}{" "}
                                </tbody>
                            </table>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginTop: "20px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <select
                                        className="custom-select"
                                        style={{
                                            width: "32%",
                                            marginRight: "7px",
                                        }}
                                        onChange={(event) =>
                                            setPageSize(event.target.value)
                                        }
                                        value={pageSize}
                                    >
                                        <option value="15">15</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="75">75</option>
                                        <option value="100">100</option>
                                    </select>
                                    <span>Records per Page</span>
                                </div>
                                <PaginationComponent
                                    totalItems={result.length}
                                    pageSize={pageSize}
                                    onSelect={handlePageChange}
                                />
                            </div>
                        </div>
                    </Fragment>
                ) : (
                    <div>No records found</div>
                )}
            </Fragment>
        );
    };

    const renderListingTable = () => {
        const filteredresult = result;
        //console.log("result==>", result);
        return (
            <Fragment>
                {result && result.length > 0 ? (
                    <Fragment>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <div>
                                {/* <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                                    <div >
                                        <input style={{
                                            border: "none",
                                            borderBottom: "1px solid rgb(197, 189, 189)",
                                            borderRadius: "0",
                                            margin: "0px 10px 6px",
                                            fontSize: "0.75rem",

                                        }}
                                            type="text"
                                            placeholder="Search"
                                            onChange={this.onSearchChangeHandler} />
                                    </div>
                                </form> */}
                            </div>
                            {activePage * pageSize > filteredresult.length ? (
                                <p
                                    style={{
                                        textAlign: "right",
                                        fontSize: "0.63rem",
                                        margin: "0",
                                    }}
                                >
                                    Showing {(activePage - 1) * pageSize + 1} to{" "}
                                    {filteredresult.length} of{" "}
                                    {filteredresult.length} entries
                                </p>
                            ) : (
                                <p
                                    style={{
                                        textAlign: "right",
                                        fontSize: "0.63rem",
                                        margin: "0",
                                    }}
                                >
                                    Showing {(activePage - 1) * pageSize + 1} to{" "}
                                    {activePage * pageSize} of{" "}
                                    {filteredresult.length} entries
                                </p>
                            )}
                        </div>
                        <div className="table-responsive">
                            <table className="table table-border-horizontal table-striped table-light table-striped table-light">
                                <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th
                                                scope="col"
                                                style={{ width: "10%" }}
                                            >
                                                <span>Date</span>
                                                <span>Time</span>
                                            </th>
                                            <th scope="col">First Name</th>
                                            <th scope="col">Last Name</th>
                                            <th scope="col">Position</th>
                                            <th scope="col">Phone Number</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Business Name</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>

                                <tbody>
                                    {result
                                        .slice(
                                            (activePage - 1) * pageSize,
                                            activePage * pageSize
                                        )
                                        .map((item, index) =>
                                             
                                                <tr key={index + 1} >
                                                    <th>{++index}</th>
                                                    <td
                                                        style={{ width: "10%" }}
                                                    >
                                                        {moment
                                                            .tz(
                                                                item.createdAt,
                                                                "Australia/Sydney"
                                                            )
                                                            .format(
                                                                "DD/MM/YYYY HH:MM:SS"
                                                            )}
                                                    </td>
                                                    <td>{item.firstName}</td>
                                                    <td>{item.lastName}</td>
                                                    <td>{item.position}</td>
                                                    <td>
                                                        {displayMobile(
                                                            item.phone
                                                        )}
                                                    </td>
                                                    <td>{item.email}</td>
                                                    <td>{item.business_name}</td>
                                                    <td><Trash2 onClick={() => {  setListingDeleteID(item._id); toggleListingDeleteModal()}} size={16} color={`red`}/> </td>
                                                </tr>
                                            
                                        )}{" "}
                                </tbody>
                            </table>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginTop: "20px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <select
                                        className="custom-select"
                                        style={{
                                            width: "32%",
                                            marginRight: "7px",
                                        }}
                                        onChange={(event) =>
                                            setPageSize(event.target.value)
                                        }
                                        value={pageSize}
                                    >
                                        <option value="15">15</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="75">75</option>
                                        <option value="100">100</option>
                                    </select>
                                    <span>Records per Page</span>
                                </div>
                                <PaginationComponent
                                    totalItems={result.length}
                                    pageSize={pageSize}
                                    onSelect={handlePageChange}
                                />
                            </div>
                        </div>
                    </Fragment>
                ) : (
                    <div>No records found</div>
                )}
            </Fragment>
        );
    };

    const renderPlaceTable = (placeList) => {

        return (
            <Fragment>
                {placeList.length > 0 ? (
                    <Fragment>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <div>
                                {/* <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                                    <div >
                                        <input style={{
                                            border: "none",
                                            borderBottom: "1px solid rgb(197, 189, 189)",
                                            borderRadius: "0",
                                            margin: "0px 10px 6px",
                                            fontSize: "0.75rem",

                                        }}
                                            type="text"
                                            placeholder="Search"
                                            onChange={this.onSearchChangeHandler} />
                                    </div>
                                </form> */}
                            </div>
                            {activePage * pageSize > placeList.length ? (
                                <p
                                    style={{
                                        textAlign: "right",
                                        fontSize: "0.63rem",
                                        margin: "0",
                                    }}
                                >
                                    Showing {(activePage - 1) * pageSize + 1} to{" "}
                                    {placeList.length} of{" "}
                                    {placeList.length} entries
                                </p>
                            ) : (
                                <p
                                    style={{
                                        textAlign: "right",
                                        fontSize: "0.63rem",
                                        margin: "0",
                                    }}
                                >
                                    Showing {(activePage - 1) * pageSize + 1} to{" "}
                                    {activePage * pageSize} of{" "}
                                    {placeList.length} entries
                                </p>
                            )}
                        </div>
                        <div className="table-responsive">
                            <table className="table table-border-horizontal table-striped table-light table-striped table-light">
                              <thead>
                                    <tr style={{ lineHeight: '30px' }}>
                                        <th scope="col" style={{ width: "5%" }}>#</th>
                                        <th scope="col" style={{ width: "35%" }}>Name</th>
                                        <th scope="col" style={{ width: "45%" }}>Type</th>
                                        <th scope="col" style={{ width: "15%" }}>Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {placeList.slice(
                                            (activePage - 1) * pageSize,
                                            activePage * pageSize
                                        )
                                        .map((item, index) => {
                                            let start_index = (activePage - 1) * pageSize + 1;
                                            let ind = index+1;
                                            if(activePage > 1)
                                            {
                                                ind = start_index + index
                                            }
                                            return(
                                            <tr key={ind} >
                                                <th>{ind}</th>
                                                <td style={{ wordWrap: 'break-word',minWidth: '160px',maxWidth: '160px'}}>
                                                    {item.name}
                                                </td>
                                                <td style={{ wordWrap: 'break-word',minWidth: '160px',maxWidth: '160px'}}>{item.types.toString()}</td>
                                                <td> <button onClick={() => fetchPlaceDetails(item.place_id)} className="btn btn-link pl-0"> View Details </button> 
                                                {/* <input type="checkbox" name="place_id" onClick={(e)=> handleAddItem(e,item.place_id)}></input> */}
                                                </td>
                                            </tr>
                                            )
                                            }
                                        )}
                                </tbody>
                            </table>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginTop: "20px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <select
                                        className="custom-select"
                                        style={{
                                            width: "32%",
                                            marginRight: "7px",
                                        }}
                                        onChange={(event) =>
                                            setPageSize(event.target.value)
                                        }
                                        value={pageSize}
                                    >
                                        <option value="15">15</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="75">75</option>
                                        <option value="100">100</option>
                                    </select>
                                    <span>Records per Page</span>
                                </div>
                                <PaginationComponent
                                    totalItems={placeList.length}
                                    pageSize={pageSize}
                                    onSelect={handlePageChange}
                                />
                            </div>
                        </div>
                    </Fragment>
                ) : (
                    <div>No records found</div>
                )}
            </Fragment>
        );
    }

    const renderPlaceDetails = (placeDetails) => {

        return(
            <Fragment>
                 <div className="row">
                    <div className="col-md-2">
                        <button onClick={()=> {
                            setPlaceDetails([]); setActiveTab(1);}} type="button" class="btn btn-link"> &lt; Go Back</button>
                    </div>
                    <div className="col-md-7 text-center" style={{margin: 'auto', padding: '10px'}}>
                        <h6>Google Business Requests Details</h6>
                    </div>
                    <div className="col-md-3">
                        <button type="button" onClick={() => toggleImportModal()} className="btn btn-primary px-2" style={{ display: 'flex'}}><Shield size={18}/> <span className="ml-1">Import to Business Listing </span></button>
                    </div>
                    </div>
                    <hr/> 
                    <div className="col-md-12">
                        <Nav tabs>
                        <NavItem>
                            <NavLinkTab
                            className={classnames({ active: stabIndex === '1' })}
                            onClick={() => { setStabIndex('1'); }}
                            >
                            Business Info
                            </NavLinkTab>
                        </NavItem>
                        <NavItem>
                            <NavLinkTab
                            className={classnames({ active: stabIndex === '2' })}
                            onClick={() => { setStabIndex('2'); }}
                            >
                            Location
                            </NavLinkTab>
                        </NavItem>
                        <NavItem>
                            <NavLinkTab
                            className={classnames({ active: stabIndex === '3' })}
                            onClick={() => { setStabIndex('3'); }}
                            >
                            Opening Time
                            </NavLinkTab>
                        </NavItem>
                        <NavItem>
                            <NavLinkTab
                            className={classnames({ active: stabIndex === '4' })}
                            onClick={() => { setStabIndex('4'); }}
                            >
                            Images
                            </NavLinkTab>
                        </NavItem>
                        </Nav>
                        <TabContent className="p-5" activeTab={stabIndex}>
                        <TabPane tabId="1">
                            <Row>
                            <Col sm="2">
                              <p className="info-text">Business Name: </p>
                            </Col>
                            <Col sm="8">
                               <p className="info-result">{placeDetails.name}</p>
                            </Col>
                            </Row>
                            {placeDetails.formatted_phone_number &&
                            <Row>
                            <Col sm="2">
                              <p className="info-text">Phone Number: </p>
                            </Col>
                            <Col sm="8">
                               <p className="info-result">{placeDetails.formatted_phone_number}</p>
                            </Col>
                            </Row>
                            }
                            {placeDetails.types && 
                            <Row>
                            <Col sm="2">
                              <p className="info-text"> Types: </p>
                            </Col>
                            <Col sm="8">
                               <p className="info-result">{placeDetails.types.toString()}</p>
                            </Col>
                            </Row>
                            }
                            {placeDetails.website &&
                            <Row>
                            <Col sm="2">
                              <p className="info-text"> Website: </p>
                            </Col>
                            <Col sm="8">
                               <p className="info-result">{placeDetails.website}</p>
                            </Col>
                            </Row>
                            }
                        </TabPane>

                        <TabPane tabId="2">
                            <Row>
                            <Col sm="2">
                              <p className="info-text">Address: </p>
                            </Col>
                            <Col sm="6">
                               <p className="info-result">{placeDetails.formatted_address}</p>
                            </Col>
                            </Row>
                            {placeDetails.geometry &&
                            <Row>
                            <Col sm="2">
                              <p className="info-text">LatLng: </p>
                            </Col>
                            <Col sm="6">
                               <p className="info-result">{placeDetails.geometry.location.toString()}</p>
                            </Col>
                            </Row>
                            }
                            <Row>
                                <Col sm='12' style={{ height: '300px'}}>
                                <Map
                                center={{lat: placeDetails.geometry.location.lat(), lng: placeDetails.geometry.location.lng()}}
                                className="map"
                                google={props.google}
                                style={{ height: '100%', position: 'relative', width: '100%' }}
                                zoom={14}
                                >
                                <Marker
                                    title={placeDetails.name}
                                    name={placeDetails.name}
                                    position={{lat: placeDetails.geometry.location.lat(), lng: placeDetails.geometry.location.lng()}} />
                                </Map>
                                </Col>
                            </Row>
                        </TabPane>

                        <TabPane tabId="3">
                            {placeDetails.opening_hours && placeDetails.opening_hours.weekday_text.map((item) => {
                                return (
                                <Row>
                                    <Col sm="6">
                                    <p className="info-result">{item}</p>
                                    </Col>
                                </Row>
                                )
                            })}
                        </TabPane>
                        <TabPane tabId="4">
                            <Row>
                            {placeDetails.photos && placeDetails.photos.map((item) => {
                                    return (
                                        <Col sm="4">
                                        <img src={item.getUrl()} style={{ width: '100%' }}  className="info-result"/>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </TabPane>
                        </TabContent>
                    </div> 
                
            </Fragment>
        );
    }

    const onChangecategory = (cat) => {
        console.log(cat)
        let subcat = catSubCat.filter((person) => person.category_name == cat);

        let sbcat = subcat[0].sub_category.map(sub => {
            return sub.subCategory
        });
        setSubcategory(sbcat);   
    }

    const fetchData = async () => {
        client.post(api.fetch_catsubcat, {active_all : true}, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            
                            setCatSubCat(response.result.data);

                          let bcat = response.result.data.map(el => {
                            return el.category_name
                          });
                          let sbcat = response.result.data.map(el => {
                            return el.sub_category.map(sub => {
                              return sub.subCategory
                            })
                          }).reduce((el, nextEl) => {
                            return el.concat(nextEl)
                          })

                          setBusinessCategory(bcat);
                          setSubcategory(sbcat);
                            
                        }
                    } catch(e) {
                        console.log(e)
                    }
                }
            }
        });
    }

    return (
        <Fragment>
            <div className="col-lg-7 col-sm-12" style={{ fontSize: "12px" }}>
                <Breadcrumb title={"Google Business API"} parent={"Home"} />{" "}
            </div>
                <div className="container-fluid">
                    <div className="row theme-tab">
                        <Tabs
                            className="col-sm-12"
                            defaultIndex={tabIndex}
                            onSelect={(index) => setTabIndex(index)}
                            style={{
                                backgroundColor: "white",
                                borderRadius: "1.5%",
                                boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.16) ",
                                marginBottom: "0px",
                            }}
                        >
                            <TabList className="tabs tab-title">
                                <Tab
                                    style={{
                                        marginTop: "0.5rem",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    Business Lookup
                                </Tab>

                                <Tab
                                    style={{
                                        marginTop: "0.5rem",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    Imported Business
                                </Tab>
                                <Tab
                                    style={{
                                        marginTop: "0.5rem",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    Listing Enquiry
                                </Tab>
                            </TabList>

                            <div className="container-fluid">
                            <div className="row">
                            <div className="col-sm-12 col-xl-12 xl-100">
                            <div className="card">
                                <div
                                    className="card-body"
                                    style={{ padding: "1rem" }}
                                >
                                    <div className="tab-content-cls">
                                        <TabPanel>
                                        <Fragment>
                                        {!allPlaceLoading && allPlace && allPlace.length > 0 && activeTab == 1 ? 
                                        <>
                                        <div className="row">
                                        <div className="col-md-2">
                                            <button onClick={()=> { setBusinessCategory([]); setCatSubCat([]); setActivePage(1); setSelectedPlace([]); setBusinessName(null); setAllPlace([]); setBusinessType(null); setActiveTab(0); }} type="button" class="btn btn-link"> &lt; Go Back</button>
                                        </div>
                                        <div className="col-md-7 text-center" style={{margin: 'auto', padding: '10px'}}>
                                            <h6>Google Find Business &gt; Type - {businessType ? businessType : businessName}</h6>
                                        </div>
                                        {/* <div className="col-md-3">
                                            <button type="button" onClick={() => toggleSelectedImportModal()} className="btn btn-primary px-2" style={{ display: 'flex'}}><span className="ml-1">Import selected Business</span></button>
                                        </div> */}
                                        </div>
                                        <hr/>
                                        {renderPlaceTable(allPlace && !allPlaceLoading ? allPlace : [])}
                                        </>
                                            : placeDetails && placeDetails != null && activeTab == 2 ?
                                            <>
                                        
                                        {renderPlaceDetails(placeDetails)}
                                        </>
                                            :<>
                                            <div className="row m-4">
                                                <div className="col-md-12">
                                                    <h6>Google Find Business</h6>
                                                </div>
                                            </div>
                                            <div className="row clearfix m-4">
                                            <div className="col-md-6">
                                                <PlacesAutocomplete
                                                    value={address}
                                                    onChange={handleChange}
                                                    onSelect={handleSelect}
                                                >
                                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                    <div>
                                                        <Input
                                                        type="search"
                                                        required
                                                        {...getInputProps({
                                                        placeholder: 'Enter a location ...',
                                                        className: 'form-control location-search-input',
                                                        })}
                                                        />
                                                        <div className="autocomplete-dropdown-container">
                                                        {loading && <div style={{ padding: '8px'}}>Loading...</div>}
                                                        <ul>
                                                        {suggestions.map(suggestion => {
                                                            const className = suggestion.active
                                                            ? 'suggestion-itemm--active'
                                                            : 'suggestion-itemm';
                                                            return (
                                                            <div
                                                                {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                                })}
                                                            >
                                                                <li style={{padding: '8px'}}>{suggestion.description}</li>
                                                            </div>
                                                            );
                                                        })}
                                                        </ul>
                                                        </div>
                                                    </div>
                                                    )}
                                                </PlacesAutocomplete>
                                                {addressErr ? <span style={{color: '#ff5370'}}>Enter a location</span>: null}
                                            </div>
                                            <div className="col-md-6 p-0" >
                                                <p style={{ lineHeight: 'initial', color: 'gray'}}>Note: When you enter address and click on below fetch list button, the business under 500km vicinity will be fetched.</p>
                                            </div>
                                            </div>
                                            <div class="row clearfix m-4">
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <div class="form-line">
                                                            <select class="form-control show-tick" 
                                                            onChange={(e)=> { setBusinessType(e.target.value); setBusinessTypeErr(false); }}
                                                            name="business_type" required="">
                                                                <option value="">Business Type</option>
                                                                <option>accounting</option>
                                                                <option>airport</option>
                                                                <option>amusement_park</option>
                                                                <option>aquarium</option>
                                                                <option>art_gallery</option>
                                                                <option>atm</option>
                                                                <option>bakery</option>
                                                                <option>bank</option>
                                                                <option>bar</option>
                                                                <option>beauty_salon</option>
                                                                <option>bicycle_store</option>
                                                                <option>book_store</option>
                                                                <option>bowling_alley</option>
                                                                <option>bus_station</option>
                                                                <option>cafe</option>
                                                                <option>campground</option>
                                                                <option>car_dealer</option>
                                                                <option>car_rental</option>
                                                                <option>car_repair</option>
                                                                <option>car_wash</option>
                                                                <option>casino</option>
                                                                <option>cemetery</option>
                                                                <option>church</option>
                                                                <option>city_hall</option>
                                                                <option>clothing_store</option>
                                                                <option>convenience_store</option>
                                                                <option>courthouse</option>
                                                                <option>dentist</option>
                                                                <option>department_store</option>
                                                                <option>doctor</option>
                                                                <option>electrician</option>
                                                                <option>electronics_store</option>
                                                                <option>embassy</option>
                                                                <option>fire_station</option>
                                                                <option>florist</option>
                                                                <option>funeral_home</option>
                                                                <option>furniture_store</option>
                                                                <option>gas_station</option>
                                                                <option>gym</option>
                                                                <option>hair_care</option>
                                                                <option>hardware_store</option>
                                                                <option>hindu_temple</option>
                                                                <option>home_goods_store</option>
                                                                <option>hospital</option>
                                                                <option>insurance_agency</option>
                                                                <option>jewelry_store</option>
                                                                <option>laundry</option>
                                                                <option>lawyer</option>
                                                                <option>library</option>
                                                                <option>liquor_store</option>
                                                                <option>local_government_office</option>
                                                                <option>locksmith</option>
                                                                <option>lodging</option>
                                                                <option>meal_delivery</option>
                                                                <option>meal_takeaway</option>
                                                                <option>mosque</option>
                                                                <option>movie_rental</option>
                                                                <option>movie_theater</option>
                                                                <option>moving_company</option>
                                                                <option>museum</option>
                                                                <option>night_club</option>
                                                                <option>painter</option>
                                                                <option>park</option>
                                                                <option>parking</option>
                                                                <option>pet_store</option>
                                                                <option>pharmacy</option>
                                                                <option>physiotherapist</option>
                                                                <option>plumber</option>
                                                                <option>police</option>
                                                                <option>post_office</option>
                                                                <option>real_estate_agency</option>
                                                                <option>restaurant</option>
                                                                <option>roofing_contractor</option>
                                                                <option>rv_park</option>
                                                                <option>school</option>
                                                                <option>shoe_store</option>
                                                                <option>shopping_mall</option>
                                                                <option>spa</option>
                                                                <option>stadium</option>
                                                                <option>storage</option>
                                                                <option>store</option>
                                                                <option>subway_station</option>
                                                                <option>supermarket</option>
                                                                <option>synagogue</option>
                                                                <option>taxi_stand</option>
                                                                <option>train_station</option>
                                                                <option>transit_station</option>
                                                                <option>travel_agency</option>
                                                                <option>veterinary_care</option>
                                                                <option>zoo</option>
                                                            </select>
                                                            {businessTypeErr ? <span style={{color: '#ff5370'}}> Select business type </span>: null}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-2 pr-0">
                                                    <button type="button" onClick={() => { if(!address){setAddressErr(true);}else if(!businessType){setBusinessTypeErr(true);}else{
                                                        fetchPlaces();
                                                    }}} disabled={fetchLLoader} class="btn btn-primary waves-effect text-center" style={{ width: '100%'}}>Fetch list </button>
                                                </div>
                                                <div className="col-sm-2">
                                                {fetchLLoader ? <div class="loaderr"></div> : null}
                                                </div>
                                            </div>
                                            <div class="row clearfix m-4">
                                            <div class="col-sm-6">
                                                <div class="form-group form-float">
                                                    <div class="form-line">
                                                        <input type="text" name="business_name" class="form-control" placeholder="Type Business Name Here" required="" onChange={(e) => { setBusinessName(e.target.value); setBusinessNameErr(false); }}/>
                                                        {businessNameErr ? <span style={{color: '#ff5370'}}>Enter a business name</span>: null}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-2 pr-0">
                                                <button type="button" onClick={()=> { if(!address){setAddressErr(true);}else if(!businessName) { setBusinessNameErr(true)} else { fetchPlaceByName(businessName) }}}disabled={fetchBLoader} class="btn btn-primary waves-effect text-right">Fetch Business</button>
                                            </div>
                                            <div className="col-sm-2">
                                                {fetchBLoader ? <div class="loaderr"></div> : null}
                                                </div>
                                            </div>
                                            </>
                                        }
                                        </Fragment>
                                        </TabPanel>

                                        <TabPanel>
                                            {renderTable()}
                                        </TabPanel>
                                        <TabPanel>
                                            {renderListingTable()}
                                        </TabPanel>
                                    </div>
                                </div>
                            </div>
                            </div>
                            </div>
                            </div>
                        </Tabs>
                    </div>
                </div>

                {/* <div
                    className="loader-box"
                    style={{ height: "100vh", width: "100wh" }}
                >
                    <div
                        className="loader"
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <DisappearedLoading size="medium" color="#514F4E" />
                    </div>
                </div> */}
 
            <Map google={props.google}
                onReady={fetchPlaces}
                center={new props.google.maps.LatLng(-37.810830, 145.001470)}
                visible={false}>
            </Map>

        {importModal &&
        <Modal isOpen={importModal} size="md" toggle={toggleImportModal} className="modal-body" centered={true}>
            <form className="theme-form" noValidate="" enctype="multipart/form-data" onSubmit={handleSubmit(onSubmit)} >
              <ModalHeader toggle={toggleImportModal} style={{ textAlign: 'center' }}>Update below details to import business </ModalHeader>
              <ModalBody>
                <div className="row px-4">
                  <div className="col-sm-12 col-xl-12">
                    <div className="form-group">
                        <label className="col-form-label">Business Category</label>
                        <select className="form-control" onChange={(e) => onChangecategory(e.target.value)} name="business_category" ref={register({ required: true })}>
                            <option value={''}>Select Business Category</option>
                            {businessCategory && businessCategory.map((el, i) => {
                            return <option key={el + i} value={el}>{el}</option>
                            })}
                        </select>
                        {errors.business_category && <span className="error-text-color">Please select business category</span>}
                    </div>

                    <div className="form-group">
                        <label className="col-form-label" >Business Sub-Category</label>
                        <select className="form-control" name="business_sub_category" ref={register({ required: true })} >
                            <option value={''}>Select Business Sub-Category</option>
                            {subcategory ? subcategory.map((subcat, i) => {
                            return <option key={subcat + i} value={subcat}>{subcat}</option>
                            }) : ""}
                        </select>
                        {errors.business_sub_category && <span className="error-text-color">Please select business sub-category</span>}
                    </div>

                    <div className="form-group">
                        <label className="col-form-label" >Business Name</label>
                        <input className="form-control" id="business_name" type="text" placeholder="Business name" name="business_name" ref={register({ required: true}) }
                        defaultValue={placeDetails ? placeDetails.name : ''} />
                        {errors.business_name && <span className="error-text-color">Business name is required</span>}
                    </div>

                    <div className="form-group">
                        <label className="col-form-label" >Phone Number</label>
                        <input className="form-control" id="phone_number" type="text" placeholder="Phone number" name="phone_number" ref={register} defaultValue={placeDetails ? placeDetails.formatted_phone_number : ''}
                        />
                    </div>

                    <div className="form-group">
                        <label className="col-form-label" >Business Description</label>
                        <textarea className="form-control" id="business_description" placeholder="Business description" name="business_description" ref={register}
                        ></textarea>
                    </div>

                    <div className="form-group">
                        <label className="col-form-label">Upload Display Image</label>
                        <DropZoneField setImageData={setImageData}/>
                    </div>

                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={toggleImportModal}>Cancel</Button>
                <Button color="primary" type="submit" disabled={loadingbtn} >Import</Button>
              </ModalFooter>
            </form>
        </Modal>
        }

        {selectedImportModal &&
        <Modal isOpen={selectedImportModal} size="md" toggle={toggleSelectedImportModal} className="modal-body" centered={true}>
        <form className="theme-form" noValidate="" enctype="multipart/form-data" onSubmit={handleSubmit(onMultipleSubmit)} >
          <ModalHeader toggle={toggleSelectedImportModal} style={{ textAlign: 'center' }}>Update below details to import business </ModalHeader>
          <ModalBody>
            <div className="row px-4">
              <div className="col-sm-12 col-xl-12">
                <div className="form-group">
                    <label className="col-form-label">Business Category</label>
                    <select className="form-control" onChange={(e) => onChangecategory(e.target.value)} name="business_category" ref={register({ required: true })}>
                        <option value={''}>Select Business Category</option>
                        {businessCategory && businessCategory.map((el, i) => {
                        return <option key={el + i} value={el}>{el}</option>
                        })}
                    </select>
                    {errors.business_category && <span className="error-text-color">Please select business category</span>}
                </div>

                <div className="form-group">
                    <label className="col-form-label" >Business Sub-Category</label>
                    <select className="form-control" name="business_sub_category" ref={register({ required: true })} >
                        <option value={''}>Select Business Sub-Category</option>
                        {subcategory ? subcategory.map((subcat, i) => {
                        return <option key={subcat + i} value={subcat}>{subcat}</option>
                        }) : ""}
                    </select>
                    {errors.business_sub_category && <span className="error-text-color">Please select business sub-category</span>}
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleSelectedImportModal}>Cancel</Button>
            <Button color="primary" type="submit" >Import</Button>
          </ModalFooter>
        </form>
        </Modal>
        }

        {editModal && placeEditDetails &&
        <Modal isOpen={editModal} onClosed={()=> setPlaceEditDetails(null)} size="md" toggle={toggleEditModal} className="modal-body" centered={true}>
            <form className="theme-form" noValidate="" onSubmit={handleSubmit(onEditSubmit)} >
              <ModalHeader toggle={toggleEditModal} style={{ textAlign: 'center' }}>Edit Imported Business Details </ModalHeader>
              <ModalBody>
                <div className="row px-4">
                  <div className="col-sm-12 col-xl-12">
                    <div className="form-group">
                        <label className="col-form-label">Business Category</label>
                        <select className="form-control" onChange={(e) => onChangecategory(e.target.value)} name="business_category" ref={register({ required: true })}>
                            <option value={''}>Select Business Category</option>
                            {businessCategory && businessCategory.map((el, i) => {
                            if(el == placeEditDetails.business_category)
                            {
                            return <option key={el + i} value={el} selected>{el}</option>
                            }else{
                            return <option key={el + i} value={el}>{el}</option>
                            }
                            })}
                        </select>
                        {errors.business_category && <span className="error-text-color">Please select business category</span>}
                    </div>

                    <div className="form-group">
                        <label className="col-form-label" >Business Sub-Category</label>
                        <select className="form-control" name="business_sub_category" ref={register({ required: true })} >
                            <option value={''}>Select Business Sub-Category</option>
                            {subcategory ? subcategory.map((subcat, i) => {
                                if(subcat == placeEditDetails.business_sub_category)
                                {
                                return <option key={subcat + i} value={subcat} selected>{subcat}</option>
                                }else{
                                return <option key={subcat + i} value={subcat}>{subcat}</option>
                                }
                            }) : ""}
                        </select>
                        {errors.business_sub_category && <span className="error-text-color">Please select business sub-category</span>}
                    </div>

                    <div className="form-group">
                        <label className="col-form-label" >Business Name</label>
                        <input className="form-control" id="business_name" type="text" placeholder="Business name" name="business_name" ref={register({ required: true}) }
                        defaultValue={placeEditDetails ? placeEditDetails.business_name : ''} />
                        {errors.business_name && <span className="error-text-color">Business name is required</span>}
                    </div>

                    <div className="form-group">
                        <label className="col-form-label" >Phone Number</label>
                        <input className="form-control" id="phone_number" type="text" placeholder="Phone number" name="phone_number" ref={register} defaultValue={placeEditDetails ? placeEditDetails.phone_number : ''}
                        />
                    </div>

                    <div className="form-group">
                        <label className="col-form-label" >Business Description</label>
                        <textarea className="form-control" id="business_description" placeholder="Business description" name="business_description" ref={register}
                        >{placeEditDetails.business_description}</textarea>
                    </div>

                    <div className="form-group">
                        <label className="col-form-label">Upload Display Image</label>
                        <DropZoneField image={placeEditDetails.display_image} setImageData={setImageData}/>
                    </div>

                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={toggleEditModal}>Cancel</Button>
                <Button color="primary" type="submit" disabled={loadingbtn} >Update</Button>
              </ModalFooter>
            </form>
        </Modal>
        }

        {deleteModal && placeDeleteID && 
          <Modal isOpen={deleteModal} onClosed={()=> setPlaceDeleteID(null)} size="md" toggle={toggleDeleteModal} className="modal-body" centered={true}>
            <ModalBody>
                <div style={{ textAlign: 'center'}}>
                <h5>Are you sure you want to delete this Imported Business? </h5>
                </div>
            </ModalBody>
            <ModalFooter style={{ justifyContent : 'center'}}>
              <Button color="secondary" onClick={toggleDeleteModal}>Cancel</Button>
              <Button color="danger" onClick={() => onDeleteSubmit()} type="button" >Delete</Button>
            </ModalFooter>
        </Modal>  
        }

        {listingDeleteModal && listingDeleteID && 
          <Modal isOpen={listingDeleteModal} onClosed={()=> setListingDeleteID(null)} size="md" toggle={toggleListingDeleteModal} className="modal-body" centered={true}>
            <ModalBody>
                <div style={{ textAlign: 'center'}}>
                <h5>Are you sure you want to delete this Listing Enquiry? </h5>
                </div>
            </ModalBody>
            <ModalFooter style={{ justifyContent : 'center'}}>
              <Button color="secondary" onClick={toggleListingDeleteModal}>Cancel</Button>
              <Button color="danger" onClick={() => onListingDeleteSubmit()} type="button" >Delete</Button>
            </ModalFooter>
        </Modal>  
        }
        </Fragment>
    );
}

export default GoogleApiWrapper({ apiKey: "AIzaSyA32DBvVEChOWV7inFmaaFXA5iomaip6rg" })(GoogleBusinessApi);
