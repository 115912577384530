import React, { Fragment, useState, useEffect } from "react";
import { DisappearedLoading } from "react-loadingg";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import { toast } from "react-toastify";
import LogoAndBusinessTypeTable from "./LogoAndBusinessTypeTable";
import BrandDetailPage from "./component/DetailPage";
import { Link, useHistory, useLocation } from "react-router-dom";



const LogoAndBusinessTypePage = (props) => {

    let history = useHistory();
    const [getAllData, seGetAllData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [defaultVaue, setDefaultValue] = useState(null);
    console.log(getAllData, "getAllData");

    const fetchBusinessTypeData = () => {

        let postdata = { all: true };
        setLoading(true);

        client.post(api.fetchBusinessType, postdata, async function (error, response) {

            if (!error) {
                if (!response.error) {
                    seGetAllData(response.result);
                    setLoading(false);
                } else {
                    toast.error(response.message);
                }
            } else {
                toast.error("Internal error occured. Please contact support", { autoClose: 2000, hideProgressBar: true });
                setLoading(false);
            }
        });
    };

    const location = useLocation();

    useEffect(() => {

        if (!getAllData) {
            fetchBusinessTypeData()
        }

    }, []);

    useEffect(() => {

        if (getAllData) {

            let getData = {};

            getAllData.page_data.map((val, index) => {

                getData[`${val._id}_brand_name`] = val.brand_name;

            });

            setDefaultValue(getData);
        };

    }, [getAllData]);


    const update_Data = (data, refreshPage) => {

        let postData = { updateID: getAllData._id, updateData: data, updateType: "brandName", portal_id: data.brand_ID }

        client.put(api.updateBusinessType, postData, async function (error, response) {

            if (!error) {
                if (!response.error) {
                    seGetAllData(response.result);
                    if (refreshPage) {
                        fetchBusinessTypeData();
                    }

                    toast.success(response.message, { autoClose: 2000, hideProgressBar: true });
                } else {
                    toast.error(response.message, { autoClose: 2000, hideProgressBar: true });
                }
            } else {
                toast.error("Internal error occured. Please contact support", { autoClose: 2000, hideProgressBar: true });
            }
        });
    };

    const deleteData = () => {
        let postData = { updateID: getAllData._id };

        client.put(api.deleteBusinessType, postData, async function (error, response) {
            if (!error) {
                if (!response.error) {
                    fetchBusinessTypeData();
                    toast.success(response.message, { autoClose: 2000, hideProgressBar: true });
                } else {
                    toast.error(response.message, { autoClose: 2000, hideProgressBar: true });
                }
            } else {
                toast.error("Internal error occured. Please contact support", { autoClose: 2000, hideProgressBar: true });
            }
        });
    };

    const pageSizeFunc = () => { }

    return (
        <>
            {
                loading ?
                    <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                        <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                            <DisappearedLoading size="medium" color="#514F4E" />
                        </div>
                    </div> :
                    <>
                        <LogoAndBusinessTypeTable
                            getAllData={getAllData}
                            update_Data={update_Data}
                            pageSizeFunc={pageSizeFunc}
                            defaultVaue={defaultVaue}
                            deleteData={deleteData}
                        />
                    </>
            }
        </>
    )
}

export default LogoAndBusinessTypePage