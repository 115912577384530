import React, { Fragment, useState } from "react";
import { Button, ModalHeader, Modal, ModalBody, ModalFooter } from "reactstrap";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { toast } from "react-toastify"

const Dinein = (props) => {
    const [merchantInfo, setMerchantInfo] = useState(props.merchant_info);
    const [addTableModal, setAddTableModal] = useState(false);
    const [tableCount, setTableCount] = useState(0)
    const [tables, setTables] = useState([]);
    const [generateQrmodal, setGenerateQrModal] = useState(false);
    const [selectedTable, setSelectedTable] = useState(null)

    const toggleAddTableModal = () => {
        setAddTableModal(!addTableModal)
    };

    const onChangeTableInput = (event, index) => {
        const { name, value } = event.target;
        const updatedTablesArray = [...tables];
        const table = updatedTablesArray[index];
        const updatedTable = {
            ...table,
            [name]: value
        };
        updatedTablesArray[index] = updatedTable;
        setTables(updatedTablesArray)



    }

    const onSaveTable = () => {
        console.log(tables);
    }

    const renderAddTableModal = () => {
        const style = {
            outline: "none",
            ':focus': {
                outlineColor: "black"
            },
            width: "50%",
            fontSize: "1rem",
            padding: "2px 7px",
            marginLeft: "0.5rem"
        };

        //console.log(tables);
        return (
            <Modal isOpen={addTableModal} toggle={toggleAddTableModal} className="modal-body" centered={true}>
                <ModalHeader toggle={toggleAddTableModal}>
                    Add table
                </ModalHeader>
                <ModalBody>

                    <p>Add a custom name for the tables.Also set how many available seats can be reserved for each table.</p>
                    {tableCount > 0 && <div className="table-responsive">
                        <table className="table table-border-horizontal table-striped table-light">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Capacity</th>
                                    <th scope="col">Minimum</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    [...Array(tableCount)].map((item, index) => (

                                        <tr>
                                            <th scope="row">{index + 1}</th>
                                            <td>
                                                <input className="form-control" type="text" onChange={(event) => onChangeTableInput(event, index)}
                                                    required name="name" />
                                            </td>
                                            <td>
                                                <input className="form-control" type="number" onChange={(event) => onChangeTableInput(event, index)}
                                                    required name="capacity" />
                                            </td>
                                            <td>
                                                <input className="form-control" type="number" onChange={(event) => onChangeTableInput(event, index)}
                                                    required name="minimum" />
                                            </td>
                                            <td>
                                                <i className="icofont icofont-close"></i>
                                            </td>
                                        </tr>))
                                }

                            </tbody>
                        </table>
                    </div>}
                    <button className="btn btn-outline-primary btn-xs mt-3 pull-right" type="button" onClick={() => setTableCount(tableCount + 1)}>Add new</button>

                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit" onClick={onSaveTable}>Save</Button>
                    <Button color="secondary" onClick={toggleAddTableModal}>No</Button>
                </ModalFooter>

            </Modal>

        );
    }

    const onClickGenerateQr = () => {
        setGenerateQrModal(true);
        //setSelectedTable(table)
    }

    const toggleGenerateQrModal = () => {
        setGenerateQrModal(!generateQrmodal);
    }

    const onDownload = () => {
        const postData = { merchant_id: merchantInfo._id, posterType: "dineinTableNo" };

        client.post(api.merchant_wallet, postData, (error, response) => {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {

                        }
                    } catch (e) {
                        console.log(e);
                        setTimeout(() => {
                            toast.error("Internal error occured. Please contact support");
                        }, 200);
                    }
                } else {
                    setTimeout(() => {
                        toast.error("Internal error occured. Please contact support");
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }

        });
    }

    const renderGenerateQrModal = () => {
        const style = {
            outline: "none",
            ':focus': {
                outlineColor: "black"
            },
            width: "50%",
            fontSize: "1rem",
            padding: "2px 7px",
            marginLeft: "0.5rem"
        };

        //console.log(tables);
        return (
            <Modal isOpen={generateQrmodal} toggle={toggleGenerateQrModal} className="modal-body" centered={true}>
                <form className="theme-form"  >
                    <ModalHeader toggle={toggleGenerateQrModal}>
                        Generate Qr
                    </ModalHeader>
                    <ModalFooter>
                        <button className="btn btn-secondary btn-sm" onClick={toggleGenerateQrModal}>No</button>
                        <button className="btn btn-primary btn-sm" type="button" onClick={onDownload}  >Download only QR</button>
                        <button className="btn btn-primary btn-sm" type="button" onClick={onDownload} >Download Poster</button>
                    </ModalFooter>
                </form>
            </Modal>

        );
    }

    return (
        <Fragment>
            {renderAddTableModal()}
            {renderGenerateQrModal()}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-xl-12 xl-100">
                        <div className="card">
                            <div className="card-body" style={{ padding: "1rem" }}>
                                <h6 style={{ fontWeight: "bold" }}>Table Setting</h6>
                                <div className="row">
                                    <div className="col col-md-12">
                                        <p>Generate QR code as per table</p>
                                        <button className="btn btn-outline-primary btn-xs mb-3" onClick={toggleAddTableModal}>Add</button>
                                    </div>
                                    {<div className="table-responsive col col-sm-12">
                                        <table className="table table-border-horizontal table-striped table-light">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Table No</th>
                                                    <th scope="col">Qr code</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">{1}</th>
                                                    <td>
                                                        B20
                                                    </td>
                                                    <td>
                                                        <span onClick={() => onClickGenerateQr()} style={{ cursor: "pointer" }}>Generate QR</span>
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Dinein;