import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import useForm from 'react-hook-form'
import {
  DollarSign,
  MapPin,
  X,
  TrendingDown,
  ArrowUp,
  ShoppingCart,
  Search,
  Activity,
  User
} from "react-feather";
import { Redirect } from "react-router-dom";
import { NavLink } from "react-router-dom";

// const BorderTable = () => {
class Mail_template_details extends React.Component {

  /*    templ_user_type, templ_name,templ_test_mail,templ_from,templ_subject,templ_layout,templ_comments,templ_added_date */

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      template_id: this.props.match.params.template_id,
      isLoaded: false,
      items: [],
      template_data: null,
      templ_name: '',
      templ_test_mail: '',
      templ_from: '',

      templ_subject: '',
      templ_layout: '',
      templ_comments: '',
      templ_to: '',
      access_level: localStorage.getItem("accessLevel")

    };

  }

  componentDidMount() {
    let { template_id } = this.state;
    // alert("hrer");
    this.getTemplateDetails();
  }

  getTemplateDetails = async () => {
    let { template_id } = this.state;
    let postdata = { template_id: template_id };
    let current = this;
    current.setState({ loading: true });
    client.post(api.get_mail_templates_details, postdata, async function (error, response) {
      if (!error) {
        // alert(JSON.stringify(response));
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              //console.log(response.result.contactDetails);

              /* templ_test_mail,templ_from,templ_subject,templ_layout,templ_comments,templ_to */
              current.setState({ template_data: response.result.contactDetails });
              current.setState({ templ_name: response.result.contactDetails.templ_name });
              current.setState({ templ_test_mail: response.result.contactDetails.templ_test_mail });
              current.setState({ templ_from: response.result.contactDetails.templ_from });
              current.setState({ templ_subject: response.result.contactDetails.templ_subject });
              current.setState({ templ_layout: response.result.contactDetails.templ_layout });
              current.setState({ templ_comments: response.result.contactDetails.templ_comments });
              current.setState({ templ_to: response.result.contactDetails.templ_to });
              // current.props.navigation.navigate("Verification");
            } else {
              setTimeout(() => {
                toast.error(response.message);
              }, 200);
            }
          } catch (e) {
            setTimeout(() => {
              toast.error("Internal error occured. Please contact support");
            }, 200);
            // _showToast.error("Internal error occured. Please contact support.");
            // currentComponent.setState({ errormessage: "Internal error." });
          }
        } else {
          setTimeout(() => {
            toast.error("Internal error occured. Please contact support");
          }, 200);
          //   _showToast.error("Internal error occured. Please contact support.");
        }
      } else {
        setTimeout(() => {
          toast.error("Internal error occured. Please contact support");
        }, 200);
        // alert(JSON.stringify(error));
        // _showToast.error("Internal error occured. Please contact support.");
      }
      current.setState({ loading: false });
    });
  };




  submit_mail_details_Handler = e => {
    e.preventDefault();
    let errors = null;
    //alert(`Submitting Name ${templ_name}`)
    let data = { template_id: this.state.template_id, templ_name: this.state.templ_name, templ_test_mail: this.state.templ_test_mail, templ_from: this.state.templ_from, templ_subject: this.state.templ_subject, templ_to: this.state.templ_to };
    if (data !== '') {

      //let 
      //console.log(data);
      //  console.log(JSON.stringify(data));
      //setloadingbtn(true);
      client.post(api.update_mail_template_details, data, async function (error, response) {
        if (!error) {
          // alert(JSON.stringify(response));
          //   return false;
          if (typeof response.error !== "undefined") {
            try {
              if (!response.error) {

                toast.success("Updated Successfully");

              } else {
                toast.error(response.message);
              }
            } catch (e) {
              toast.error("Internal error occured. Please contact support");
            }
          } else {
            toast.error("Internal error occured. Please contact support");
          }
        } else {
          toast.error("Internal error occured. Please contact support");
        }
        //setloadingbtn(false);
      });
    } else {
      errors.showMessages();
    }
  }

  submit_mail_layout_Handler = e => {
    e.preventDefault();
    let errors = null;
    //alert(`Submitting Name ${templ_name}`)
    let data = {
      template_id: this.state.template_id,
      templ_layout: Buffer.from(this.state.templ_layout).toString('base64')
    };

    if (data !== '') {

      //let 
      //console.log(data);
      //  console.log(JSON.stringify(data));
      //setloadingbtn(true);
      client.post(api.update_mail_template_layout, data, async function (error, response) {
        if (!error) {
          // alert(JSON.stringify(response));
          //   return false;
          if (typeof response.error !== "undefined") {
            try {
              if (!response.error) {

                toast.success("Updated Successfully");

              } else {
                toast.error(response.message);
              }
            } catch (e) {
              toast.error("Internal error occured. Please contact support");
            }
          } else {
            toast.error("Internal error occured. Please contact support");
          }
        } else {
          toast.error("Internal error occured. Please contact support");
        }
        //setloadingbtn(false);
      });
    } else {
      errors.showMessages();
    }
  }

  submit_send_mail_Handler = e => {
    e.preventDefault();
    let errors = null;
    //alert(`Submitting Name ${templ_name}`)
    let data = {
      template_id: this.state.template_id
      // , templ_layout: this.state.templ_layout
    };
    if (data !== '') {

      //let 
      //console.log(data);
      //  console.log(JSON.stringify(data));
      //setloadingbtn(true);
      client.post(api.send_template_test_mail, data, async function (error, response) {
        if (!error) {
          // alert(JSON.stringify(response));
          //   return false;
          if (typeof response.error !== "undefined") {
            try {
              if (!response.error) {

                toast.success("Mail sent successfully");

              } else {
                toast.error(response.message);
              }
            } catch (e) {
              toast.error("Internal error occured. Please contact support");
            }
          } else {
            toast.error("Internal error occured. Please contact support");
          }
        } else {
          toast.error("Internal error occured. Please contact support");
        }
        //setloadingbtn(false);
      });
    } else {
      errors.showMessages();
    }
  }

  changeHandler = (e) => {
    console.log(e.target.name + " value = " + e.target.value);
    this.setState({ [e.target.name]: e.target.value });
    console.log(this.state);

  }



  render() {
    let { template_data, template_id } = this.state;

    const { templ_name, templ_subject, templ_test_mail, templ_from, templ_to, templ_layout } = this.state;



    return (
      <Fragment>
        <div className="col-lg-7 col-sm-12" style={{ fontSize: "12px" }}>
          <Breadcrumb title={"Mail template details"} parent={"Home"} />
        </div>
        {template_id ? (
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 col-xl-12">
                <div className="row">

                  <div className="col-sm-12">
                    <div className="card">

                      <div className="card-body">
                        {template_data ?
                          <form className="theme-form" onSubmit={this.submit_mail_details_Handler}  >

                            {/* templ_user_type, templ_name,templ_test_mail,templ_from,templ_subject,templ_layout,templ_comments,templ_added_date */}
                            <div className="form-group row">
                              <label className="col-sm-12 col-form-label" htmlFor="Header_text"><h4>Email Setting of {template_data.templ_name} </h4></label>

                            </div>

                            <div className="form-group row">
                              <label className="col-sm-1 col-form-label" htmlFor="Templatename">Name</label>
                              <div className="col-sm-5">
                                <input className="form-control" id="templ_name" type="text" value={templ_name} name="templ_name" onChange={this.changeHandler} />
                              </div>

                              <label className="col-sm-1 col-form-label" htmlFor="inputEmail3">Subject</label>
                              <div className="col-sm-5">
                                <input className="form-control" id="inputEmail3" type="text" value={templ_subject} name="templ_subject" onChange={this.changeHandler} />
                              </div>
                            </div>


                            <div className="form-group row">
                              <label className="col-sm-1 col-form-label" htmlFor="inputPassword3">To</label>
                              <div className="col-sm-5">
                                <input className="form-control" id="inputnumber" name="templ_to" type="text" onChange={this.changeHandler} />
                              </div>

                              <label className="col-sm-1 col-form-label" htmlFor="inputPassword3">Test Mail</label>
                              <div className="col-sm-5">
                                <input className="form-control" id="test_mail" name="templ_test_mail" type="text" value={templ_test_mail} onChange={this.changeHandler} />
                              </div>
                            </div>

                            {/* <div className="form-group row">
                              <div className="col-sm-3"></div>
                              <label className="col-sm-1 col-form-label" htmlFor="inputPassword3">From</label>
                              <div className="col-sm-5">
                                <input className="form-control" id="from" name="templ_from" type="text" onChange={this.changeHandler} value={templ_from} />
                              </div>
                            </div> */}

                            <div className="card-footer">
                              <div className="col-sm-3"></div>
                              <div className="col-sm-1">
                                <button className="btn btn-success mr-1" id="update_templ_details" type="submit" >Update</button>
                              </div>
                            </div>
                          </form>
                          :
                          null
                        }
                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </div>  {/* MAil Dtails  row ends */}

            <div className="row">
              <div className="col-sm-12 col-xl-12">
                <div className="row">

                  <div className="col-sm-12">
                    <div className="card">

                      <div className="card-body">
                        {template_data ?
                          <form className="theme-form" onSubmit={this.submit_mail_layout_Handler} >

                            {/* templ_user_type, templ_name,templ_test_mail,templ_from,templ_subject,templ_layout,templ_comments,templ_added_date */}
                            <div className="form-group row">
                              <label className="col-sm-12 col-form-label" htmlFor="business_name"><h4>Layout of {templ_name} </h4></label>
                              <p style={{ color: "red" }}>Short codes: Password:[code], Email id:[email_id], Mobile no:[mobile_no], Business name:[business_name], Customer name:[customer_name], Brand Name:[brand_name], Brand Logo:[portal_logo], Support Email Id:[support_mail], Brand URL:[brand_URL], Support No:[support_no], Unsubscribe Link:[unsubscribe_email], Minisite:[minisite_url], First Name:[first_name], Last name:[last_name]</p>
                            </div>

                            <div className="form-group row">


                              <textarea className="form-control" name="templ_layout" rows="10" id="business_name" type="text" placeholder="Business Name" value={templ_layout} onChange={this.changeHandler} />



                            </div>

                            <div className="card-footer">
                              <div className="col-sm-3"></div>
                              <div className="col-sm-1">
                                <button className="btn btn-success mr-1" id="update_layout"  >Update</button>
                              </div>
                            </div>
                          </form>
                          :
                          null
                        }
                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </div> {/* layout row ends */}

            <div className="row">
              <div className="col-sm-12 col-xl-12">
                <div className="row">

                  <div className="col-sm-12">
                    <div className="card">

                      <div className="card-body">
                        {template_data ?
                          <form className="theme-form" onSubmit={this.submit_send_mail_Handler}  >

                            {/* templ_user_type, templ_name,templ_test_mail,templ_from,templ_subject,templ_layout,templ_comments,templ_added_date */}
                            <div className="form-group row">
                              <label className="col-sm-12 col-form-label" htmlFor="business_name"><h4>Send Mail </h4></label>

                            </div>

                            <div className="form-group row col-sm-12">


                              <div className=" col-sm-12" dangerouslySetInnerHTML={{ __html: templ_layout }} />
                            </div>

                            <div className="card-footer ">
                              <div className="col-sm-3"></div>
                              <div className="col-sm-1">
                                <button className="btn btn-success mr-1" id="usend_test_mail_layout"  >Send</button>
                              </div>
                            </div>
                          </form>
                          :
                          null
                        }
                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </div> {/* Actual layout content */}

          </div>



        ) : (
          <div>{`data not found`}</div>

        )}
      </Fragment>
    );
  }
}

export default Mail_template_details;
