import React from 'react'
import { Row, Col } from 'reactstrap'
import './skeleton_loader.scss';

const SkeletonPageLoader = () => {

    let leftSectionStyle = {
        height: "30px",
        borderTopLeftRadius: "5px",
        borderBottomLeftRadius: "5px",
    }

    let rightSectionStyle = {
        height: "30px",
        borderTopRightRadius: "5px",
        borderBottomRightRadius: "5px",
    }

    return (
        <Row className="m-0" style={{ padding: "10px 10px" }}>

            <Col sm='12' className='m-0 p-0'>
                <Row>
                    <Col sm="3" md="3" className='pr-0'><div class="card__header header__img skeleton skeleton_rectangle mb-2" style={leftSectionStyle} /></Col>
                    <Col sm="9" md="9" style={{ paddingLeft: "5px" }}><div class="card__header header__img skeleton skeleton_rectangle mb-2" style={rightSectionStyle} /></Col>
                </Row>
            </Col>

            <hr className='w-100 mt-1 mb-2' />

            <Col sm='12' className='m-0 p-0'>
                <Row>
                    <Col sm="3" md="3" className='pr-0'><div class="card__header header__img skeleton skeleton_rectangle mb-2" style={{ height: "13px", borderRadius: "5px" }} /></Col>
                    <Col sm="11" md="11" style={{ paddingLeft: "5px" }}></Col>
                </Row>
            </Col>

            <Col sm='12' className='m-0 p-0'>
                <Row>
                    <Col sm="1" md="1" className='pr-0'><div class="card__header header__img skeleton skeleton_rectangle mb-2" style={leftSectionStyle} /></Col>
                    <Col sm="11" md="11" style={{ paddingLeft: "5px" }}><div class="card__header header__img skeleton skeleton_rectangle mb-2" style={rightSectionStyle} /></Col>
                </Row>
            </Col>

            <Col sm='12' className='m-0 p-0'>
                <Row>
                    <Col sm="1" md="1" className='pr-0'><div class="card__header header__img skeleton skeleton_rectangle mb-2" style={leftSectionStyle} /></Col>
                    <Col sm="11" md="11" style={{ paddingLeft: "5px" }}><div class="card__header header__img skeleton skeleton_rectangle mb-2" style={rightSectionStyle} /></Col>
                </Row>
            </Col>

            <Col sm='12' className='m-0 p-0'>
                <Row>
                    <Col sm="1" md="1" className='pr-0'><div class="card__header header__img skeleton skeleton_rectangle mb-2" style={leftSectionStyle} /></Col>
                    <Col sm="11" md="11" style={{ paddingLeft: "5px" }}><div class="card__header header__img skeleton skeleton_rectangle mb-2" style={rightSectionStyle} /></Col>
                </Row>
            </Col>

            <Col sm='12' className='m-0 p-0'>
                <Row>
                    <Col sm="1" md="1" className='pr-0'><div class="card__header header__img skeleton skeleton_rectangle mb-2" style={leftSectionStyle} /></Col>
                    <Col sm="11" md="11" style={{ paddingLeft: "5px" }}><div class="card__header header__img skeleton skeleton_rectangle mb-2" style={rightSectionStyle} /></Col>
                </Row>
            </Col>

            <hr className='w-100 mt-1 mb-2' />

            <Col sm='12' className='m-0 p-0'>
                <Row>
                    <Col sm="3" md="3" className='pr-0'><div class="card__header header__img skeleton skeleton_rectangle mb-2" style={{ height: "13px", borderRadius: "5px" }} /></Col>
                    <Col sm="11" md="11" style={{ paddingLeft: "5px" }}></Col>
                </Row>
            </Col>

            <Col sm='12' className='m-0 p-0'>
                <Row>
                    <Col sm="1" md="1" className='pr-0'><div class="card__header header__img skeleton skeleton_rectangle mb-2" style={leftSectionStyle} /></Col>
                    <Col sm="11" md="11" style={{ paddingLeft: "5px" }}><div class="card__header header__img skeleton skeleton_rectangle mb-2" style={rightSectionStyle} /></Col>
                </Row>
            </Col>

            <Col sm='12' className='m-0 p-0'>
                <Row>
                    <Col sm="1" md="1" className='pr-0'><div class="card__header header__img skeleton skeleton_rectangle mb-2" style={leftSectionStyle} /></Col>
                    <Col sm="11" md="11" style={{ paddingLeft: "5px" }}><div class="card__header header__img skeleton skeleton_rectangle mb-2" style={rightSectionStyle} /></Col>
                </Row>
            </Col>

            <Col sm='12' className='m-0 p-0'>
                <Row>
                    <Col sm="1" md="1" className='pr-0'><div class="card__header header__img skeleton skeleton_rectangle mb-2" style={leftSectionStyle} /></Col>
                    <Col sm="11" md="11" style={{ paddingLeft: "5px" }}><div class="card__header header__img skeleton skeleton_rectangle mb-2" style={rightSectionStyle} /></Col>
                </Row>
            </Col>
        </Row>
    )
}

export default SkeletonPageLoader