import * as actionTypes from "./actionTypes";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";

export const fetch_customerData_start = () => {
    return {
        type: actionTypes.FETCH_CUSTDATA_START
    }

}

export const fetch_customerData_success = (data) => {
    return {
        type: actionTypes.FETCH_CUSTDATA_SUCCESS,
        payload: data
    }
}

export const fetch_customerData_failed = error => {
    return {
        type: actionTypes.FETCH_CUSTDATA_FAILED,
        payload: error
    }
}

export const fetch_customerData = () => {
    return dispatch => {
        let postdata = {};
        client.post(api.getCustMerchData, postdata, (error, response) => {
            if (!error) {
                try {
                    if (!response.error) {
                        dispatch(fetch_customerData_success(response.result.data))
                    } else {
                        dispatch(fetch_customerData_failed(response.message))
                    }
                } catch (e) {
                    dispatch(fetch_customerData_failed(response.message))
                }
            } else {
                dispatch(fetch_customerData_failed(response.message))
            }
            //   current.setState({ loading: false });
        });
    }
}