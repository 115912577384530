import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { User, Mail, Lock, Settings, LogOut } from "react-feather";
import app from "../../../data/base";
import { withRouter, Redirect, NavLink } from "react-router-dom";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import defaultUserImage from "../../../assets/images/admin_images/admin.jpeg";
import * as actionCreators from "../../../store/actions/index";
import default_image from "../../../assets/images/default_image.png";

const UserMenu = (props) => {
  const [profile, setProfile] = useState(null);
  // console.log(profile, "profile")
  const [browserId, setBrowserId] = useState(localStorage.getItem("browserId"))
  // useEffect(() => {
  //   setProfile(localStorage.getItem("profileURL") || man);
  // }, []);

  useEffect(() => {
    getAdminDetails()
  }, [])

  const getAdminDetails = async () => {
    const postData = { id: localStorage.getItem('userId') };

    client.post(api.get_admin_details, postData, async (error, response) => {
      if (!error) {
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              // this.setState({ adminInfo: response.result.data[0] })
              setProfile(response.result.data[0])
            } else {
              setTimeout(() => {
                toast.error(response.message);
              }, 200);
            }
          } catch (e) {
            setTimeout(() => {
              toast.error("Internal error occured. Please contact support " + e.message);
            }, 200);

          }
        } else {
          setTimeout(() => {
            toast.error("Internal error occured. Please contact support 1");
          }, 200);
        }
      } else {
        setTimeout(() => {
          toast.error("Internal error occured");
        }, 200);
      }

    });

  }

  const logout = () => {
    //props.onLogout(profile._id, browserId,  props.token, props.history);
    props.onLogoutAll(profile._id, "logoutClick", props.history)

  }

  return (
    <Fragment>

      {profile ?
        <li className="onhover-dropdown">
          <div className="media align-items-center">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ alignSelf: 'center' }}>
                <img style={{ width: "34px", height: "34px" }}
                  className="align-self-center pull-right  rounded-circle blur-up lazyloaded"
                  src={profile.profile_image != "" ? `${client.url(`images/admin-profile/${profile.profile_image}`)}` : default_image}
                />
                <div className="dotted-animation" style={{ right: '-4px', top: '5px', display: 'none' }}>
                  <span className="animate-circle"></span>
                  <span className="main-circle"></span>
                </div>
              </div>
              <p style={{ margin: ' 0px', color: '#fff', fontSize: '12px', alignSelf: 'center' }}>
                {profile.full_name.split(/\s/).reduce((response, word) => (response += word.slice(0, 5)), "")}
              </p>
            </div>
          </div>
          <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
            { /* <li><Link to="/settings/profile" ><i data-feather="user"></i>Edit Profile</Link></li> */}
            {<li><a href="/adminusers/edit_profile"><User />Edit Profile</a></li>}
            { /* <li><a href="#javascript"><Mail />Inbox</a></li> */}
            { /*<li><a href="#javascript"><Lock />Lock Screen</a></li> */}
            { /*<li><a href="#javascript"><Settings />Settings</a></li> */}
            <li>
              <a onClick={logout}>
                <LogOut /> Log out
              </a>
            </li>
          </ul>
        </li> : null}
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    isAuthenticated: state.auth.token != null,
  }
}


const mapDispatchToProps = dispatch => {
  return {
    onLogout: (userId, browserId, token, history) => dispatch(actionCreators.logout(userId, browserId, token, history)),
    onLogoutAll: (userId, type, history) => dispatch(actionCreators.logoutAll(userId, type, history))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserMenu));
