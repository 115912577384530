import React, { Component, useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormGroup, Col, Row, Input, CustomInput } from 'reactstrap';
import useForm  from "react-hook-form";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";

let EditHelpTopicModal = (props) => {
    
    let [loadingbtn, setloadingbtn] = useState(false);
    let [inputList, setInputList] = useState([{ title: ""}]);
    let [inputCat, setInputCat] = useState('');
    let [inputDesc, setinputDesc] = useState('');
    let [editItem, seteditItem] = useState(null);
    let [firstTimeLoad, setfirstTimeLoad] = useState(true);
    let [showerrorstatus, setshowerrorstatus] = useState(false);

    const { catid, mainTopic, description, title, isOpen } = props;

    useEffect(() => {
        if(isOpen)
        {
            if(firstTimeLoad)
            {
                setInputCat(mainTopic);
                setinputDesc(description);
                if (title.length > 0) {
                    setInputList(title);
                } else {
                    setInputList([{ title: ""}]);
                }
                seteditItem(catid);
                setfirstTimeLoad(false);
            }
        }else{
            setfirstTimeLoad(true);
        }
    },[props]);

    const handleResetClick = () => {
        setInputCat('');
        setinputDesc('');
        setInputList([{ title: ""}]);
        seteditItem(null);
    };


    const handleCatInputChange = (e) => {
        const { value } = e.target;
        setInputCat(value);
    }

    const handleCatInputDesc = (e) => {
        const { value } = e.target;
        setinputDesc(value);
    }

    const handleAddClick = () => {
        setInputList([...inputList, { title: "", }]);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };
    

    const { handleSubmit, register, errors } = useForm();

    const onSubmit = form_data => {
        
        if (form_data !== '') {

            setloadingbtn(true);

                let data = { main_topic: form_data.main_topic, description: form_data.description, sub_topic: inputList, _id: editItem, type: 1};
                console.log(data);
                client.post(api.editHelptopics, data, async function (error, response) {
                    console.log(response);
                    if (!error) {
                        if (typeof response.error !== "undefined") {
                            try {
                                if (!response.error) {
                                    setfirstTimeLoad(true);
                                    handleResetClick();
                                    toast.success("Updated Successfully");
                                    props.onClose(true);
                                } else {
                                    toast.error(response.message);
                                }
                            } catch (e) {
                               //toast.error("Internal error occured. Please contact support 2");
                            }
                        } else {
                            toast.error("Internal error occured. Please contact support 2");
                        }
                    } else {
                        toast.error("Internal error occured. Please contact support 3");
                    }
                    setloadingbtn(false);
                });
        }
        else {
            setshowerrorstatus(true);
            errors.showMessages();
        }
    }

        return (
        
            <Modal isOpen={props.isOpen} toggle={props.onClose} size="md" className="modal-body" centered={true}>
               <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={props.onClose}><p className="m-0 font-weight-bold">Edit Help Topic</p></ModalHeader>
                    <ModalBody>
                        <ModalBody style={{ padding: "16px 0" }}>
                            <Row form>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="level_title">Help Topic</Label>
                                        <input className="form-control" type="text" name="main_topic" value={inputCat} onChange={e => handleCatInputChange(e)} ref={register({ required: true })}/>
                                        <span>{errors.main_topic && 'Topic Name is required'}</span>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="level_title">Description</Label>
                                        <input className="form-control" type="text" name="description" value={inputDesc} onChange={e => handleCatInputDesc(e)} ref={register({ required: true })}/>
                                        <span>{errors.description && 'Description is required'}</span>
                                    </FormGroup>
                                    <FormGroup className="w-100">
                                        <Label for="level_title">Sub Help Topic</Label>
                                        {inputList.map((x, i) => {
                                            
                                        return(
                                        <Row form>
                                            <Col md={11}>
                                            <input
                                            name="title" className="form-control mb-2" type="text"
                                            ref={register({ required: false })}
                                            value={x.title}
                                            onChange={e => handleInputChange(e, i)}
                                        />
                                        <span>{errors.title && 'title is required'}</span>
                                        </Col>
                                        
                                        <Col md={1}>
                                        {inputList.length !== 1 && <button
                                            className="btn btn-outline-danger mb-2 p-2"
                                            onClick={() => handleRemoveClick(i)}><i id="Remove" title="Remove title" className="fa fa-minus"></i></button>}
                                        {inputList.length - 1 === i && <button className="btn btn-outline-success p-2" onClick={handleAddClick}><i id="Add New" title="Add New" className="fa fa-plus"></i></button>}
                                        </Col>
                                        </Row>
                                        )
                                        })}
                                        </FormGroup>
                                    
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            {!loadingbtn ?
                                <>
                                    <Button color="default" type="button" onClick={() => props.onClose(false) }>Close</Button>
                                    <Button color="primary" type="submit" onClick={() => setshowerrorstatus(true)}>Update</Button>
                                </>
                                :
                                <div className="loader-box pull-right" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 20, width: 20 }}></span></div>
                            }
                        </ModalFooter>  
                    </ModalBody>
                    </form>
            </Modal>
        );
}

export default EditHelpTopicModal;