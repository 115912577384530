import React, { Fragment, useEffect, useState } from 'react'
import client from "../../../Api/HTTPClient";
import "./../css/profile.css"
import api from "../../../constant/apilist";
import EyeIcon from "mdi-react/EyeIcon";
import EyeOffIcon from "mdi-react/EyeOffIcon";
// import useForm from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Field, reduxForm, FieldArray, arrayPush, submit, change } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import AlertCircleIcon from "mdi-react/AlertCircleIcon";
import PulseLoader from "react-spinners/PulseLoader";

const validate = (values) => {

    let errors = {}

    if (!values.new_pin) {
        errors.new_pin = 'Required';
    } else if (values.new_pin && values.new_pin.length < 4 || values.new_pin.length > 4) {
        errors.new_pin = "PIN should be 4 digit";
    }

    if (!values.confirm_pin) {
        errors.confirm_pin = 'Required';
    } else if (values.confirm_pin && values.confirm_pin.length < 4 || values.confirm_pin.length > 4) {
        errors.confirm_pin = "PIN should be 4 digit";
    }

    return errors;
};


const CustomInput = ({
    className,
    value,
    type,
    style,
    onChange,
    onClickIcon,
    name,
    input,
    meta: { touched, error },
}) => {

    return (
        <div className="form__form-group-input-wrap position-relative">
            <div className="form__form-group-field">
                <input
                    {...input}
                    className={className}
                    value={value}
                    type={type}
                    style={style}
                    onChange={onChange}
                    name={name}
                />
                <div
                    className='d-flex align-items-center'
                    onClick={onClickIcon}
                    style={{ height: "32px", position: "absolute", top: "1px", right: "6px", border: "none", backgroundColor: "#fff" }}
                >
                    {type == "number" ? <EyeOffIcon style={{ height: "18px", width: "18px", fill: "#999999", }} /> : <EyeIcon style={{ height: "18px", width: "18px", fill: "#999999", }} />}
                </div>
            </div>
            {
                touched && error && (
                    <div className="form__form-group-error d-flex align-items-center">
                        <span style={{ padding: "2px 5px", backgroundColor: "rgb(255, 220, 224)", color: '#ad4444', fontSize: "10px" }} className="rounded my-2 d-flex align-items-center" >
                            <AlertCircleIcon className="mr-1" size={12} />
                            {error}
                        </span>
                    </div>
                )
            }
        </div>
    );
};

const normalizePosPin = (value) => {

    if (!value) {
        return value;
    }

    let onlyNums = value.replace(/[^\d]/g, "");

    if (onlyNums.length > 4) {
        onlyNums = onlyNums.slice(0, 4);
    }

    return onlyNums;
};

const MerchantUserPin = (props) => {

    let {
        handleSubmit,
        initialize,
        user_details,
        merchant_id,
        merchant_info,
        pin_type
    } = props;


    const [showNewPin, setShowNewPin] = useState(true);
    const [showConfirmPin, setShowConfirmPin] = useState(true);
    const [loading, setLoading] = useState(false);

    const toggleNewPinFunc = () => { setShowNewPin(!showNewPin) };
    const toggleConfirmPinFunc = () => { setShowConfirmPin(!showConfirmPin) };
    const [resetFormLoader, setResetFormLoader] = useState(false);

    // const MerchantUserPosPinFormData = useSelector((state) => state.form?.MerchantUserPosPinForm?.values);


    useEffect(() => {
        initialize({
            new_pin: '',
            confirm_pin: ''
        });
    }, [])


    // console.log(user_details, "user_details");
    const onSubmit = values => {
        // console.log(values, "formValues");

        if (values.new_pin === values.confirm_pin) {

            const postData = { new_pin: values.new_pin, user_id: user_details._id, pin_type: pin_type };

            setLoading(true);

            client.post(api.changePin, postData, (error, response) => {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                setLoading(false);
                                toast.success("Pin Updated.");
                                setResetFormLoader(true);
                                setResetFormLoader(false);
                            } else {
                                setLoading(false);
                                toast.error(response.message);
                            }
                        } catch (error) {
                            setLoading(false);
                            toast.error("Internal error occured.Please contact support.");
                        }
                    } else {
                        setLoading(false);
                        toast.error("Internal error occured.Please contact support.");

                    }
                } else {
                    setLoading(false);
                    toast.error("Internal error occured.Please contact support.");
                }
            });
        } else {
            toast.error("PIN didn't match!!");
        }
    };




    return (
        <Fragment>
            {!resetFormLoader && <form noValidate="" onSubmit={handleSubmit(onSubmit)} className="theme-form" style={{ width: "90%" }}>
                <div className="card mb-0" style={{ boxShadow: "none" }}>
                    <div className="card-header" style={{ padding: "20px 15px" }}>
                        <h4 className="card-title my-2" style={{ fontSize: "18px" }}>{pin_type == 1 ? "Transaction Pin" : "POS Pin"}</h4>
                    </div>
                    <div className="card-body" style={{ padding: "15px 0px" }}>
                        <div className="col-md-12">
                            <div className="form-group row d-flex align-items-center">
                                <label className="form-label col-md-5 pr-0 mb-0">NEW PIN</label>
                                <div className='col-md-7'>
                                    <div className="form__form-group-field position-relative">
                                        <Field
                                            className="form-control"
                                            name='new_pin'
                                            type={showNewPin ? "password" : "number"}
                                            style={{ borderRadius: "0px", height: "35px", fontSize: "14px", border: "1px solid gainsboro" }}
                                            autoComplete="off"
                                            autoCorrect="off"
                                            spellCheck="off"
                                            component={CustomInput}
                                            onClickIcon={toggleNewPinFunc}
                                            normalize={normalizePosPin}
                                            maxlength="4"
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group row d-flex align-items-center">
                                <label className="form-label col-md-5 mb-0 pr-0">CONFIRM PIN</label>
                                <div className='col-md-7'>
                                    <div className="form__form-group-field position-relative">
                                        <Field
                                            className="form-control"
                                            name='confirm_pin'
                                            type={showConfirmPin ? "password" : "number"}
                                            style={{ borderRadius: "0px", height: "35px", fontSize: "14px", border: "1px solid gainsboro" }}
                                            autoComplete="off"
                                            autoCorrect="off"
                                            spellCheck="off"
                                            onClickIcon={toggleConfirmPinFunc}
                                            component={CustomInput}
                                            normalize={normalizePosPin}
                                            maxlength="4"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className="form-group form-row mb-0 pull-left">
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-xs p-2 ml-2"
                                    style={{ width: loading && "100px" }}
                                >
                                    {loading ? <span> <PulseLoader color="#FFFFFF" size={10} /></span> : <span>UPDATE PIN</span>}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>}
        </Fragment>
    )
}

export default reduxForm({
    form: "MerchantUserTransPinForm",
    initialized: true,
    destroyOnUnmount: false,
    validate,
})(MerchantUserPin);

