import React, { Fragment, useEffect, useState } from 'react'
import client from "../../Api/HTTPClient";
import "./css/profile.css"
import api from "../../constant/apilist";
import no_image from "../../assets/images/No-Image.png";
import { ToastContainer, toast } from "react-toastify";
import { Nav, NavItem, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip } from "reactstrap";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from "moment-timezone";

export const VerifiedInviteUserProfile = (props) => {
//    console.log(props)
    const { merchant_user, merchant_id } = props;
    const [temp_password_reason, settemp_password_reason] = useState("");
    const [password, setpassword] = useState("");
    const [generatePasswordModal, setgeneratePasswordModal] = useState(false);
    const [textCopy, settextCopy] = useState(false);
    const [comments, setcomments] = useState([]);

    const image_url = merchant_user?.profile_image ? client.url(`images/user-profile/${merchant_user.profile_image}`) : "";

    const generatePassword = () => {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*(){}?/][",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    useEffect(() => {

        try {
            setcomments(merchant_user.comments)
        } catch {

        }

    }, [merchant_user])

    const render_date = (add_date) => {
        if (typeof add_date === "undefined") {
            return;
        }

        const convert_date = new Date(add_date);
        const added_date = moment.tz(convert_date, "Australia/Sydney").format("DD/MM/YYYY HH:mm");
        return added_date;
    };


    const onSubmitTempPassword = (event) => {
        event.preventDefault();

        if (temp_password_reason) {

            const temp_password = generatePassword();
            setpassword(temp_password)
            let adminUser = JSON.parse(localStorage.getItem("user"))

            const postData = {
                user_id: merchant_user?._id,
                temp_password: {
                    password: temp_password,
                    generated_by: localStorage.getItem("userId"),
                    temp_password_reason: temp_password_reason
                },
                // comment: { type: `Tempoarary password generated`, note: temp_password_reason, user: adminUser.full_name }
            }

            // console.log(postData);
            client.post(api.generate_temp_password, postData, (error, response) => {
                if (!error) {
                    if (!response.error) {
                        settemp_password_reason("")
                        // console.log("success");
                        let a = comments;
                        a.push({
                            type: "temp_password",
                            user: adminUser.full_name,
                            date: new Date(),
                            note: temp_password_reason
                        })
                        setcomments(a)
                        setgeneratePasswordModal(true)


                        // this.setState({ generatePasswordModal: true })
                    } else {
                        toast.error(response.message);
                    }
                } else {
                    toast.error("Internal error occured.Please contact support.");
                }
            })
        }
    }

    const renderGeneratePasswordModal = () => {
        return (
            <Modal isOpen={generatePasswordModal} toggle={() => setgeneratePasswordModal(false)} className="modal-body" centered={true}>
                <form className="theme-form" >
                    <ModalBody style={{ textAlign: "center" }}>
                        <h5>Super Password</h5>
                        <h4 id="temp-pw">{password}</h4>
                        <p style={{ color: "red" }}>* valid for 10 minutes only</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button type='button' color="secondary" onClick={() => setgeneratePasswordModal(false)}>Cancel</Button>

                        <CopyToClipboard text={`${password}`} onCopy={() => settextCopy(true)}>
                            <button className="btn btn-primary" type="button" id="link-copy">Copy to clipboard</button>
                        </CopyToClipboard>
                        <Tooltip placement="bottom" target="link-copy" isOpen={textCopy}>
                            Copied!
                        </Tooltip>

                    </ModalFooter>
                </form>
            </Modal>)
    }

    const renderComment = (getCommentData) => {

        const reversed = [...getCommentData].reverse();

        return (
            <>
                {
                    reversed.map((el) => {
                        return (
                            <li style={{ margin: "0" }}>
                                <div
                                    className="message my-message "
                                    style={{ marginBottom: "10px", paddingTop: "10px", paddingBottom: "10px" }}
                                >
                                    <p>
                                        {el.user}
                                        <span className="message-data-time pull-right">{render_date(el.date)}</span>{" "}
                                    </p>
                                    {/* {el.type == "normal" ? null : <p style={{ fontSize: "0.9rem" }}>Type : {el.type}</p>} */}
                                    {el.note}
                                </div>
                            </li>
                        );
                    })
                }
            </>
        );
    }
    // console.log(merchant_user, "user")
    return (
        <Fragment>
            {renderGeneratePasswordModal()}
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-md-9" style={{ paddingRight: "0", paddingLeft: "7.5px" }}>
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0">Account Details</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="#javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="#javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body" style={{ paddingBottom: "15px" }}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group row">
                                                <label className="form-label col-md-3">Full Name : </label>
                                                <div className='col-md-9'>
                                                    <input className="form-control" disabled={true} readOnly type="text" value={merchant_user?.name} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group row">
                                                <label className="form-label col-md-3">E-mail : </label>
                                                <div className='col-md-9'>
                                                    <input className="form-control" disabled={true} readOnly type="text" value={merchant_user?.email} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group row">
                                                <label className="form-label col-md-3">Mobile No :</label>
                                                <div className='col-md-9'>
                                                    <input className="form-control" disabled={true} readOnly type="text" value={merchant_user?.mobile} />
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-12">
                                            <div className="form-group row">
                                                <label className="form-label col-md-3">User Name :</label>
                                                <div className='col-md-9'>
                                                    <input className="form-control" disabled={true} readOnly type="text" value={merchant_user?.userName} />
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3" style={{ paddingRight: "0", paddingLeft: "7.5px" }}>
                            <div className="card">

                                <div className="card-body" style={{ paddingBottom: "15px" }}>
                                    <div className="profile_box px-0 ds_v ">
                                        <div className='' style={{ padding: "10px", backgroundColor: "#E8E8E8" }}>
                                            <div className='' style={{ backgroundColor: "white", border: "1px solid #DCDCDC" }}>
                                                <aside className="dropzone__img">
                                                    {image_url ?
                                                        <img src={`${image_url}?${Date.now()}`} className="p-0" alt="drop-img" />
                                                        :
                                                        <img src={`${no_image}`} className="p-0" alt="drop-img" />
                                                    }
                                                </aside>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6" style={{ paddingRight: "0", paddingLeft: "7.5px" }}>
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0">Generate temporary password for merchant</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="#javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="#javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body" style={{ paddingBottom: "15px" }}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <form className="theme-form" onSubmit={onSubmitTempPassword}>
                                                <div className="form-group row">
                                                    <label className="form-label col-md-12">Enter reason for your action</label>
                                                    <div className='col-md-12'>
                                                        <input required className="form-control" type="text" name="reason" value={temp_password_reason} onChange={(event) => settemp_password_reason(event.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="form-group form-row mb-0 pull-right">
                                                    <button className="btn btn-primary btn-xs" type="submit">Generate</button>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6" style={{ paddingRight: "0", paddingLeft: "7.5px" }}>
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0">Password generated history</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="#javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="#javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body" style={{ paddingBottom: "15px" }}>
                                    <div className="card-body chat-box" style={{ paddingTop: "0", paddingBottom: "0" }}>
                                        <div className="chat-right-aside bitcoin-chat">
                                            <div className="chat">
                                                <div className="chat-history chat-msg-box custom-scrollbar" style={{ padding: "0", margin: "0" }}>
                                                    <ul style={{ margin: "0" }}>
                                                        {
                                                            merchant_user?.comments?.length > 0 ?
                                                                renderComment(merchant_user?.comments)
                                                                : (
                                                                    <p className='text-center'>No comments!</p>
                                                                )
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </Fragment>
    )
}
