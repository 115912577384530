
import React, { useEffect, Fragment, useState, useRef } from "react";
import { Editor } from '@tinymce/tinymce-react';

const TextEditor = ({ initialData, setContentData, initialContent }) => {

    // console.log(initialData, "initialData");

    const editorRef = useRef(null);
    const [content, setcontent] = useState('');

    const log = () => {
        if (editorRef.current) {
            setcontent(editorRef.current.getContent());
            setContentData(editorRef.current.getContent());
        }
    };

    useEffect(() => {

        if (initialContent) {
            setcontent(initialContent.content_data);
            setContentData(initialContent.content_data);
        } else {
            setcontent("");
        }

    }, [initialContent])

    return (
        <Fragment>
            <Editor
                apiKey='ej475rfcltitcwhe6rtacdk8jt9s70vatxi4yofu0aegrpe4'
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={content}
                init={{
                    height: 500,
                    menubar: true,
                    selector: 'textarea',
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'imagetools',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount', 'media', 'image'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
                onChange={log}
                onBlur={log}
                onFocus={log}
            />
        </Fragment>
    );
}

export default TextEditor;