import React, { Component, Fragment } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";

import { Redirect } from "react-router-dom";
import Breadcrumb from "../components/common/breadcrumb";
import client from "../Api/HTTPClient";
import api from "../constant/apilist";
import displayMobile from "../components/ui-elements/display_mobile";
import { withRouter } from 'react-router-dom';

class UnverifiedCustProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {

      customer_id: this.props.customer_id,
      customer_info: this.props.customer_info,
      editDetailsInfo: null,
      send_sms_modal: false,
      access_level: localStorage.getItem("accessLevel")
    }
  }

    refreshPage() {
        window.location.reload(false);
      }

    detailsChangeHandler = (event) => {
        const { name, value } = event.target;
         
          this.setState(prevState => {
            return {
              ...prevState,
              customer_info: {
                ...prevState.customer_info,
                [name]: value
              },
              editDetailsInfo: {
                ...prevState.editDetailsInfo,
                [name]: value
              }
            }
          })
    
        }

        updateDetailsHandler = (event) => {
            event.preventDefault();
            const { customer_id, editDetailsInfo } = this.state;
            let postdata = { _id: customer_id, ...editDetailsInfo};
        
            client.put(api.update_customer, postdata, (error, response) => {
              if (!error) {
                try {
                  if (!response.error) {
                    toast.success("Customer Profile updated");
                    // this.redirect_success();
                    this.refreshPage();
                  } else {
                    // toast.error(response.message);
                    console.log(response.error);
                  }
                } catch (e) {
                  toast.error(e.message);
                }
              } else {
                toast.error("Internal error occured. Please contact support ");
              }
        
        
            });
          }

          send_sms_toggle = (event) => {
            event.preventDefault();
            this.setState(prevState => {
              return {
                ...prevState,
                send_sms_modal: !prevState.send_sms_modal
              }
            })
          }

          handle_send_sms_submit = (e) => {
            e.preventDefault();
            let { customer_id } = this.state;
            let postdata = { customer_id: customer_id };
            let current = this;
            current.setState({ loading: true });
        
            if (postdata !== '') {
              //setloadingbtn(true);
              client.post(api.unverCust_sendCredentials, postdata, async function (error, response) {
                if (!error) {
                  //alert(JSON.stringify(response));
                  //   return false;
                  if (typeof response.error !== "undefined") {
                    try {
                      if (!response.error) {
                        toast.success("Credentials sent successfully");
                        current.setState({send_sms_modal : false})
                        try {
                          current.props.history.push(`${process.env.PUBLIC_URL}/customers/all_customers`)
                        } catch {
                          
                        }

                      } else {
                        toast.error(response.message);
                      }
                    } catch (e) {
                      toast.error(e.message);
                    }
                  } else {
                    toast.error("Internal error occured. Please contact support ");
                  }
                } else {
                  toast.error("Internal error occured. Please contact support ");
                }
                //setloadingbtn(false);
              });
            }
        
          }
      

    render() {
        const { customer_id, customer_info, access_level } = this.state;
        console.log(customer_info);
        return (
            <Fragment>
                {customer_id ? (
                    <div className="container-fluid">
                        {customer_info ?

                            <div className="row">
                                <div className="col-sm-12 col-xl-12">
                                    {/* <div className="row">
                  <div className="col-sm-12"> */}
                                    <div className="card">
                                        <div className="card-body">
                                            <form className="theme-form"  >
                                                <div className="form-group row">
                                                    <label className="col-sm-3 col-form-label" >i-Pay ID</label>
                                                    <div className="col-sm-9">
                                                        <input className="form-control" type="text" name="name"
                                                            placeholder="Owner/Manager Name" value={customer_info.ipay_id} onChange={this.detailsChangeHandler} />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-sm-3 col-form-label" >Name</label>
                                                    <div className="col-sm-9">
                                                        <input className="form-control" type="text" name="name"
                                                            placeholder="Owner/Manager Name" value={customer_info.name} onChange={this.detailsChangeHandler} />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-sm-3 col-form-label" >Mobile</label>
                                                    <div className="col-sm-9">
                                                        <input className="form-control" type="text" name="mobile"
                                                            placeholder="Mobile" value={displayMobile(customer_info.mobile)} onChange={this.detailsChangeHandler} />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-sm-3 col-form-label" >Email</label>
                                                    <div className="col-sm-9">
                                                        <input className="form-control" type="email" name="email"
                                                            placeholder="Email" value={customer_info.email} onChange={this.detailsChangeHandler} />
                                                    </div>
                                                </div>


                                                 <div className="card-footer">
                                                    <button className="btn btn-success mr-2" onClick={this.updateDetailsHandler} disabled={ access_level == 1 ? true : false}>Update</button>
                                                    <button className="btn btn-secondary" onClick={this.send_sms_toggle} disabled={ access_level == 1 ? true : false}>Send  ID/Password</button>
                                                </div>

                                            </form>

                                        </div>

                                    </div>

                                    {/* </div>
                </div> */}
                                </div>

                            </div>
                            : null}
                    </div>

                ) : (
                        <Redirect to={`${process.env.PUBLIC_URL}/customers/all_customers`} />
                    )}



                <div className="card-body grid-showcase">
                    {/* <!-- Vertically centered modal--> */}
                    <Modal isOpen={this.state.send_sms_modal} toggle={this.send_sms_toggle} className="modal-body" centered={true}>
                        <form className="theme-form" noValidate="" >
                            <ModalHeader toggle={this.send_sms_toggle} style={{ textAlign: 'center' }}>Send ID/Password </ModalHeader>
                            <ModalBody>
                                <br />
                                <h5>Are you sure you want to send the credentials ?</h5>
                                <p style={{ color: "red" }}>Note: An sms will be sent with ID & temporary password to the user. User is requested to login into the app and create password to proceed. Thanks!</p>
                                <br />
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={this.send_sms_toggle} disabled={ access_level == 1 ? true : false}>Not Sure</Button>
                                <Button color="primary" type="submit" onClick={(e) => { this.handle_send_sms_submit(e) }} disabled={ access_level == 1 ? true : false}>Yes Please !</Button>
                            </ModalFooter>
                        </form>
                    </Modal>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(UnverifiedCustProfile);