import * as actionTypes from "../actions/actionTypes";

const initialState = {
    userTokens : [],
    loading: null,
    error: null,
    errorMsg: null,
    successMsg: null,
    isAuthenticated: null,
    token: null,
    resendotp : false
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.REVERTO_INITIALSTATE:
            return {
                ...initialState
            }
        case actionTypes.AUTH_START:
            return {
                ...state,
                loading: true
            }

        case actionTypes.AUTH_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                token: action.token,
                successMsg : action.successMsg,
                error : false
                
            }

        case actionTypes.AUTH_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                error: true,
                token: null,
                errorMsg : action.errorMsg
            }

        case actionTypes.VERIFY_OTP_START:
            return {
                ...state,
                loading: true
            }

        case actionTypes.VERIFY_OTP_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                token: action.token,
                error : false,
                successMsg : action.successMsg,
                resendotp : false
                
            }

        case actionTypes.VERIFY_OTP_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                error: true,
                errorMsg : action.errorMsg,
                token : null,
                resendotp : false
            }

        case actionTypes.LOGOUT_START:
            return {
                ...state,
                loading: true
            }

        case actionTypes.LOGOUT_SUCCESS:
            return {
                ...state,
                error : false,
                isAuthenticated: false,
                loading: false,
                token: null,
                successMsg: action.response

            }

        case actionTypes.LOGOUT_FAIL:
            return {
                ...state,
                error : true,
                loading: false,
                errorMsg: action.response

            }

        case actionTypes.LOGOUTDEVICE_START:
            return {
                ...state,
                loading: true
            }

        case actionTypes.LOGOUTDEVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                successMsg: action.response,

            }

        case actionTypes.LOGOUTDEVICE_FAIL:
            return {
                ...state,
                loading: false,
                errorMsg: action.response
            }

        case actionTypes.LOGOUTALL_START:
            return {
                ...state,
                loading: true
            }

        case actionTypes.LOGOUTALL_SUCCESS:
            return {
                ...state,
                loading: false,
                successMsg: action.response,
            }

        case actionTypes.LOGOUTALL_FAIL:
            return {
                ...state,
                loading: false,
                errorMsg: action.response
            }

        case actionTypes.RESENT_OTP_START:
            return {
                ...state,
                loading : true
            }

        case actionTypes.RESENT_OTP_SUCCESS:
            return{
                ...state,
                loading : false,
                error : false,
                successMsg : action.successMsg,
                resendotp : true
            }

        case actionTypes.RESENT_OTP_FAILED:
            return{
                ...state,
                loading : false,
                error : true,
                errorMsg : action.errorMsg,
                resendotp : true
            }


        default:
            return state
    }

}

export default authReducer;