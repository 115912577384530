import React, { Suspense, useState, useEffect, lazy } from 'react'
import { Edit } from 'react-feather';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import {
    Col,
    Row,
    Progress,
    Tooltip,
    Container,
    UncontrolledTooltip
} from "reactstrap";
import { toast } from "react-toastify";
import Icon from "@mdi/react";
import { mdiCheck, mdiChevronRight, mdiWeb, mdiCheckboxBlankCircle, mdiInformation, mdiContentCopy, mdiOpenInNew } from "@mdi/js";
import { useParams, useHistory } from "react-router";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import './orderOnline.scss'
import renderToggleField from './ToggleButton'
import { Field, reduxForm, getFormValues } from 'redux-form';
import ViewMenuTypePopup from './ViewMenuTypePopup.jsx'
import Switch from "react-switch";
import {DisappearedLoading} from "react-loadingg";

// import Orders from './Orders.jsx';
const Orders = lazy(()=>import("./Orders.jsx"))

const fallbackLoading = ()=>{
    return(
        <div className="loader-box" style={{height:"100vh",width:"100wh"}}>
          <div className="loader" style={{ display:"flex",alignItems:"center" }}>
            <DisappearedLoading size="medium" color="#514F4E" />
          </div> 
        </div>
    )
}

const OnlineOrderTab = (props) => {

    let { initialize, merchant, billingConfig, itemsData } = props;
    
    const [completedStepCount, setCompletedStepCount] = useState(true);
    const [menuType, setMenuType] = useState(false);
    const [tradingHoursStatus, settradingHoursStatus] = useState(true);
    const [productsStatus, setproductsStatus] = useState(false);
    const [pmStatus, setpmStatus] = useState(false);
    const [isTrial, setisTrial] = useState(true);
    const [totalSteps, setTotalSteps] = useState(5);
    const [copyTextToolTip, setCopyTextToolTip] = useState(false);
    const [web_store_url, setweb_store_url] = useState(merchant?.web_store_url);
    const [menuTypeDineIn, setMenuTypeDineIn] = useState(null);
    const [menuTypeTakeaway, setMenuTypeTakeaway] = useState(null);
    const [menuTypePopup, setMenuTypePopup] = useState(false);
    const [listingStatus, setListingStatus] = useState(!merchant.listing_status);

    const history = useHistory();
    // console.log(merchant, billingConfig, itemsData)
    const labels = {

        stores: <> <p className="m-0 mob-font">Your store's details are shown on your online store and in the iOS and Android apps, allowing customers to know more about your business</p></>,

        personal: <p className="m-0 mob-font">Personalize your instant site and your storefront's appearance to reflect your unique brand.</p>,

        trade: <p className="m-0 mob-font">Your trading hours should reflect when you are open and fulfilling orders.</p>,

        product: <p className="m-0 mob-font">Choose which product to display on the storefront.</p>,

        payment: <p className="m-0 mob-font">Choose which payment options you want to display on the storefront.</p>

    }
    useEffect(() => {
        if (itemsData?.length > 0) {
            let ps = false
            for (let index = 0; index < itemsData.length; index++) {
                const element = itemsData[index];
                if (element.saleOnline && element.categories.length >= 1) {
                    ps = true
                    break;
                }
            }
            setproductsStatus(ps)
        }

    }, [itemsData]);
    useEffect(() => {
        try {
            if (billingConfig) {
                const payment_methods = billingConfig.payment_methods;
                if (payment_methods) {

                    let cod = payment_methods.find(item => item.code == "COD");
                    let online = payment_methods.find(item => item.code == "ONLINE");
                    let ipaypro = payment_methods.find(item => item.code == "IPAYPRO");

                    if (cod.active || online.active || ipaypro.active) {
                        setpmStatus(true)
                    }
                }
            }
        } catch {
            setpmStatus(false)
        }
    }, [billingConfig])

    useEffect(() => {
        let completed = 0;
        if (((merchant?.state && merchant?.address && merchant?.postcode && merchant?.suburb) || (merchant?.web_state && merchant?.web_address && merchant?.web_postcode && merchant?.web_suburb))) {
            completed = completed + 1;
        }
        if (merchant?.Store_logo_image && merchant?.Store_Cover_image) {
            completed = completed + 1;
        }
        if (tradingHoursStatus) {
            completed = completed + 1;
        }
        if (productsStatus) {
            completed = completed + 1;
        }
        if (pmStatus) {
            completed = completed + 1;
        }

        setCompletedStepCount(completed);

    }, [merchant, tradingHoursStatus, productsStatus, pmStatus]);

    useEffect(() => {
        try {
            setisTrial(merchant.subscription.subscription_id.subscription.is_trial);
        } catch { }

        if (merchant?.default_dashboard == "events") {
            setTotalSteps(4);
        }

        let ths = false;
        let completeTrandingHour = true;
        // let completeTrandingHour = false;

        try {

            let menu_types = merchant.menu_types;

            if (menu_types && menu_types.length > 0) {
                let takeaway_menu = menu_types.find(item => item.code == "takeaway")
                let dinein_menu = menu_types.find(item => item.code == "dine-in")

                let enable_dine_in = false;
                let view_menu = false;
                let enable_takeaway = false;

                if (dinein_menu) {
                    enable_dine_in = dinein_menu.active
                    view_menu = dinein_menu.view_only_menu
                }


                if (takeaway_menu) {
                    enable_takeaway = takeaway_menu.active
                }


                menu_types.map(item => {
                    if (item.active || item.view_only_menu) {
                        ths = true
                    }
                })

                menu_types.map(item => {
                    if (item.business_hours.length > 0) {
                        completeTrandingHour = true
                    }
                })

                setMenuType(view_menu)
                setMenuTypeDineIn(enable_dine_in)
                setMenuTypeTakeaway(enable_takeaway)
                initialize({
                    view_menu: view_menu,
                });
            }
            settradingHoursStatus(completeTrandingHour)

        } catch {
            settradingHoursStatus(false)
        }
    }, [merchant]);

    const copyTextToolTipToggle = () => {
        setCopyTextToolTip(!copyTextToolTip);
    };

    const getLinkDomain = (type = 1) => {
        const host = client.url("");
        let api_domain = host.replace(/(https?:\/\/)?(www.)?/i, '');
        if (api_domain.indexOf('/') !== -1) {
            api_domain = api_domain.split('/')[0];
        }

        if (type == 2) {
            let hostName = window?.location?.hostname.replace("dm.portal.", "");
            hostName = hostName.replace("portal.", "");

            if (api_domain == "dmapi.ipaypro.co") {

                // return `https://dm.${hostName}`;
                return `https://dm.linky.biz`;
            } else {
                return `https://linky.biz`;
            }

        } else {

            if (api_domain == "dmapi.ipaypro.co") {
                return "https://demo.payments.linky.biz";
            } else {
                return "https://payments.linky.biz";
            }

        }

    }

    const updateMenuTypesAction = (postdata) => {

        // console.log(postdata, "postdata")
        let postData = {
            merchant_id: merchant,
            ...postdata
        }
        client.post(api.updateMenuTypes, postData, (error, response) => {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            if (postData.hide_message) { } else {
                                toast.success('Updated Successfully');
                            }
                            // dispatch(setActiveMerchant(response.result.data))
                            window.location.reload()
                        }
                    } catch (error) {

                    }
                }
            }
        });
    }

    const handleListingStatus = (checked) =>{

        let postdata = { merchant_id: merchant.merchant_id, listing_status: !checked };
        client.post(api.disable_listing, postdata, (error, response) => {
            console.log(response)
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            setListingStatus(checked);
                            // props.refreshMerchantInfo(!checked);
                            toast.success(response.message)
                        }
                    } catch (e) {}
                } else {}
            } else {}
        });
    }

    return (
        <div className="container-fluid order_online_tab"
        // style={{ paddingBottom: 20 }}
        >
            <div className="row theme-tab" style={{ marginBottom: '0px !important' }} >
                <Tabs className="col-sm-12" defaultIndex={0} style={{ padding: "0" }}>
                    <TabList className="tabs tab-title" style={{ marginBottom: '0px !important' }}>
                        <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Overview </Tab>
                        <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> My Website</Tab>
                        <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Orders</Tab>
                    </TabList>
                    <div className="tab-content-cls">
                        <TabPanel>
                            <div className="" style={{ paddingTop: '0.5rem' }} >
                                <div className="container-fluid">
                                    <span className="row">
                                        <span className="col-md-8 ">
                                            <p className='mt-3 px-2 font-weight-bold ' style={{ fontSize: '16px', color: 'black' }}> Store Status
                                                <span className='pl-2' style={{ color: completedStepCount == 5 ? "#35B543" : '#ca1b1b' }}>
                                                    {/* <span className='pl-2' style={{ color: completedStepCount == 5 ? "#45c7a2" : '#ca1b1b' }}> */}
                                                    <Icon className="rounded-pill p-0 iconstatus mr-1" path={mdiCheckboxBlankCircle} size={0.5} />
                                                    {completedStepCount == 5 ? "Active" : "Pending"}
                                                </span>
                                            </p>
                                        </span>
                                        <span className="col-md-4">
                                            <div className="mt-md-4 mt-2 px-2 float-md-right">
                                                <div className="form__form-group-field d-flex">
                                                    <p className="online-order-desc d-flex mr-3" style={{ alignItems: 'center', color: '#646777', }}>
                                                        {menuType ? `View Menu Only` : `Accepting online orders`}
                                                        <span id="MenuModeToolTip" className="ml-1 d-flex">
                                                            <Icon
                                                                path={mdiInformation}
                                                                size={0.5}
                                                                style={{ color: "#BEBEBE" }}
                                                            />
                                                        </span>
                                                    </p>
                                                    <Field
                                                        id="view_menu"
                                                        name="view_menu"
                                                        // disabled={disableInput}
                                                        onChange={(event) => {
                                                            if (!menuTypeDineIn && !menuTypeTakeaway) {
                                                                setMenuTypePopup(true);
                                                            } else {
                                                                setMenuType(event.target.checked)

                                                                updateMenuTypesAction({
                                                                    view_only_menu: event.target.checked,
                                                                    enable_dine_in: menuTypeDineIn,
                                                                    enable_takeaway: menuTypeTakeaway,
                                                                })
                                                            }
                                                        }}
                                                        component={renderToggleField}
                                                        opposite={true}
                                                    // custom={true}
                                                    />
                                                </div>
                                            </div>
                                        </span>
                                    </span>
                                </div>
                                <Col md={12} className={` web_overview_sec_2 home_page`} style={{ padding: "2rem", background: '#f1f1f1' }}>
                                    <div className="">
                                        <>
                                            <Row className="flex-md-row flex-column-reverse">
                                                <Col className={`py-2 pl-0 pr-0`} md={7}>
                                                    <div className={`border-overview rounded-overview mt-1 ml-0 bg-white`} style={{ borderColor: 'rgb(212,213,212)' }}>
                                                        <>
                                                            {
                                                                <Row>
                                                                    <Col md={12} className="">
                                                                        <div
                                                                            className="p-2 py-4 progress-title before-checklist-paddings "
                                                                            style={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                alignItems: "center",
                                                                                paddingBottom: "0 !important",
                                                                                borderBottom: "1px solid rgb(212,213,212)",
                                                                            }}
                                                                        >
                                                                            <h2
                                                                                className={`progress-title__title  ml-md-4 ml-2 mb-0`}
                                                                                style={{
                                                                                    flex: 1,
                                                                                    fontSize: "16px",
                                                                                    lineHeight: "22px",
                                                                                    fontWeight: "bold",
                                                                                    color: "#000",
                                                                                    //textTransform: "uppercase",
                                                                                }}
                                                                            >
                                                                                <span class="gwt-InlineLabel">
                                                                                    LAUNCH CHECKLIST
                                                                                </span>
                                                                            </h2>

                                                                            {/* {completedStepCount == 5 ?

                                                                                <div className="d-flex" style={{ width: "auto" }}>
                                                                                    <p className="ml-2 d-flex mb-0" style={{ alignSelf: 'center', color: '#646777', width: 'max-content' }}>
                                                                                        {menuType ? 'View Menu Only' : 'Accepting online orders'}
                                                                                        <span id="MenuModeToolTip" className="ml-1 d-flex">
                                                                                            <Icon
                                                                                                path={mdiInformation}
                                                                                                size={0.8}
                                                                                                style={{ color: "#BEBEBE" }}
                                                                                            />
                                                                                            <UncontrolledTooltip
                                                                                                className="p-0 text-left"
                                                                                                dir="ltr"
                                                                                                placement="top"
                                                                                                target="MenuModeToolTip"
                                                                                                style={{
                                                                                                    backgroundColor: "#93949D",
                                                                                                    lineHeight: "1.2rem",
                                                                                                }}
                                                                                            >
                                                                                                <p className="text-left" style={{ lineHeight: '1.25' }}>
                                                                                                    If your store is not accepting online orders then you can enable this option, this will notify your customer that your store is not accepting orders at the moment when they try to add item in their cart
                                                                                                </p>
                                                                                            </UncontrolledTooltip>
                                                                                        </span>
                                                                                    </p>
                                                                                </div>

                                                                                : 
                                                                                } */}
                                                                            <div className={`pr-4`} style={{ marginRight: "5px", fontSize: "25px" }}>
                                                                                <span className=" completed-percent" style={{ color: completedStepCount == 5 ? "#35B543" : '#ca1b1b' }}>
                                                                                    {completedStepCount * 20}%
                                                                                </span>
                                                                                <div className="progress-wrap progress-store-percentage progress-wrap--small p-0 m-0" style={{ marginTop: "4px", color: completedStepCount == 5 ? "#45c7a2" : '#ca1b1b' }}>
                                                                                    <Progress className={completedStepCount == 5 ? 'progress-store-comp' : 'progress-store-incomp'} color={completedStepCount == 5 ? "#45c7a2" : '#ca1b1b'} value={completedStepCount * 20} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            }

                                                            <Row>
                                                                <Col md={12}>
                                                                    <div>
                                                                        <Row>

                                                                            <Col md={12} className="step-section">
                                                                                <>
                                                                                    <div
                                                                                        className="p-3 d-flex  border-bottom-overview"
                                                                                        style={{
                                                                                            alignItems: "center",
                                                                                        }}
                                                                                    >

                                                                                        <div className={`checklist__checkmark ${((merchant?.state && merchant?.address && merchant?.postcode && merchant?.suburb) || (merchant?.web_state && merchant?.web_address && merchant?.web_postcode && merchant?.web_suburb)) ? "completed" : null}`} style={{ marginRight: "10px" }}>
                                                                                            <Icon path={mdiCheck} size={1}
                                                                                                color={((merchant?.state && merchant?.address && merchant?.postcode && merchant?.suburb) || (merchant?.web_state && merchant?.web_address && merchant?.web_postcode && merchant?.web_suburb)) ? "#fff" : "rgba(125,141,158,0.3)"} />
                                                                                        </div>
                                                                                        <div className="mr-auto orderFlex">
                                                                                            <p className=" m-0" style={{ fontSize: "14px", color: "black" }}>
                                                                                                <b className="mob-font-big">Store Profile</b>
                                                                                            </p>
                                                                                            {labels.stores}
                                                                                        </div>
                                                                                        <div className="ml-auto d-flex align-items-center">
                                                                                            <Icon path={mdiChevronRight} size={1} color="grey" />
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            </Col>

                                                                            <Col md={12} className="step-section">
                                                                                <>
                                                                                    <div
                                                                                        className="p-3 d-flex  border-bottom-overview"
                                                                                        style={{
                                                                                            alignItems: "center",
                                                                                        }}
                                                                                    >
                                                                                        <div className={`checklist__checkmark ${(merchant?.Store_logo_image && merchant?.Store_Cover_image) ? "completed" : ""}`} style={{ marginRight: "10px" }}>
                                                                                            <Icon path={mdiCheck} size={1} color={merchant?.Store_logo_image && merchant?.Store_Cover_image ? '#fff' : `rgba(125,141,158,0.3)`} />
                                                                                        </div>
                                                                                        <div className="mr-auto orderFlex">
                                                                                            <p className=" m-0" style={{ fontSize: "14px", color: "black" }}>
                                                                                                <b className="mob-font-big">Personalize your store</b>
                                                                                            </p>
                                                                                            {labels.personal}
                                                                                        </div>
                                                                                        <div className="ml-auto d-flex align-items-center">
                                                                                            <Icon path={mdiChevronRight} size={1} color="grey" />
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            </Col>

                                                                            <Col md={12} className="step-section">
                                                                                <>
                                                                                    <div
                                                                                        className="p-3 d-flex  border-bottom-overview"
                                                                                        style={{
                                                                                            alignItems: "center",
                                                                                        }}
                                                                                    >

                                                                                        <div className={`checklist__checkmark ${tradingHoursStatus && "completed"}`} style={{ marginRight: "10px" }}>
                                                                                            <Icon path={mdiCheck} size={1} color={tradingHoursStatus ? '#fff' : `rgba(125,141,158,0.3)`} />
                                                                                        </div>
                                                                                        <div className="mr-auto orderFlex">
                                                                                            <p className=" m-0" style={{ fontSize: "14px", color: "black" }}>
                                                                                                <b className="mob-font-big"> Trading hours and availability</b>
                                                                                            </p>
                                                                                            {labels.trade}
                                                                                        </div>
                                                                                        <div className="ml-auto d-flex align-items-center">
                                                                                            <Icon path={mdiChevronRight} size={1} color="grey" />
                                                                                        </div>

                                                                                    </div>
                                                                                </>
                                                                            </Col>

                                                                            <Col md={12} className="step-section">
                                                                                <>
                                                                                    <div
                                                                                        className="p-3 d-flex  border-bottom-overview"
                                                                                        style={{
                                                                                            alignItems: "center",
                                                                                        }}
                                                                                    >
                                                                                        <div className={`checklist__checkmark ${productsStatus && 'completed'}`} style={{ marginRight: "10px" }}>
                                                                                            <Icon path={mdiCheck} size={1} color={productsStatus ? '#fff' : `rgba(125,141,158,0.3)`} />
                                                                                        </div>
                                                                                        <div className="mr-auto orderFlex">
                                                                                            <p className=" m-0" style={{ fontSize: "14px", color: "black" }}>
                                                                                                <b className="mob-font-big"> Product Setup</b>
                                                                                            </p>
                                                                                            {labels.product}
                                                                                        </div>
                                                                                        <div className="ml-auto d-flex align-items-center">
                                                                                            <Icon path={mdiChevronRight} size={1} color="grey" />
                                                                                        </div>

                                                                                    </div>
                                                                                </>
                                                                            </Col>

                                                                            <Col md={12} className="step-section">
                                                                                <>
                                                                                    <div
                                                                                        className="p-3 d-flex  border-bottom-overview"
                                                                                        style={{
                                                                                            alignItems: "center",
                                                                                        }}
                                                                                    >
                                                                                        <div className={`checklist__checkmark ${pmStatus && 'completed'}`} style={{ marginRight: "10px" }}>
                                                                                            <Icon path={mdiCheck} size={1} color={pmStatus ? '#fff' : `rgba(125,141,158,0.3)`} />
                                                                                        </div>
                                                                                        <div className="mr-auto orderFlex">
                                                                                            <p className=" m-0" style={{ fontSize: "14px", color: "black" }}>
                                                                                                <b className="mob-font-big">Payments & Fees</b>
                                                                                            </p>
                                                                                            {labels.payment}
                                                                                        </div>
                                                                                        <div className="ml-auto d-flex align-items-center">
                                                                                            <Icon path={mdiChevronRight} size={1} color="grey" />
                                                                                        </div>

                                                                                    </div>
                                                                                </>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    </div>
                                                </Col>


                                                <Col className={`py-2 pl-md-2 pl-0 pr-0`} md={5}>
                                                    <div className="border-overview rounded rounded-overview mt-1 pb-2 ml-0 bg-white">

                                                        {
                                                            <Row>
                                                                <Col md={12} className="">
                                                                    <div className="p-2 progress-title before-checklist-paddings " style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', paddingBottom: '0 !important', borderBottom: "1px solid rgb(212,213,212)" }}>
                                                                        <h2 className="progress-title__title ml-md-4 ml-2" style={{ flex: 1, paddingTop: "0.9rem", paddingBottom: "0.9rem", fontSize: "16px", lineHeight: "22px", fontWeight: "bold", color: "#000", textTransform: "uppercase" }}><span class="gwt-InlineLabel">
                                                                            SHARE YOUR ONLINE MENU LINK
                                                                        </span></h2>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        }

                                                        <Row >
                                                            <Col md={12}>
                                                                <div className="pt-4 pb-2 d-flex" >
                                                                    <div className="form__form-group-field px-md-4 px-2 w-100" >
                                                                        <div style={{ backgroundColor: "#fff", display: "flex", width: "100%", borderRadius: "0.4rem", border: '1px solid rgb(212,213,212)', overflow: 'hidden', alignItems: 'center' }}>
                                                                            <div className="d-flex flex-grow-1" style={{ backgroundColor: "rgb(243,243,243)" }}
                                                                            >
                                                                                <Icon path={mdiWeb} size={1} style={{ margin: "0.75rem", fill: "#D23F99", color: "#D23F99" }} className="weblink-globe" />
                                                                                <div class="input-group">
                                                                                    <input type="text"
                                                                                        style={{ paddingInline: '10px', borderRadius: '0' }}
                                                                                        class="form-control formUsernameEdit" id="basic-url" aria-describedby="basic-addon3"
                                                                                        value={`${getLinkDomain(2)}/online/${web_store_url}`} readOnly onChange={(e) => setweb_store_url(e.target.value)}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="">
                                                                                <span id="link-copy1212">
                                                                                    <Icon
                                                                                        onClick={(e) => {
                                                                                            {
                                                                                                e.preventDefault();
                                                                                                navigator.clipboard.writeText(`${getLinkDomain(2)}/online/${merchant.web_store_url}`);
                                                                                                copyTextToolTipToggle();
                                                                                            }
                                                                                        }}
                                                                                        path={mdiContentCopy}
                                                                                        className="weblink-icon"
                                                                                        style={{
                                                                                            cursor: "pointer",
                                                                                            margin: "8px 8px 8px 8px"
                                                                                        }}
                                                                                        title="copy"
                                                                                        size={0.9}
                                                                                    />
                                                                                </span>

                                                                                {copyTextToolTip && (
                                                                                    <Tooltip placement="left" toggle={copyTextToolTipToggle} target="link-copy1212" isOpen={copyTextToolTip} style={{ color: '#fff' }}>
                                                                                        Copied!
                                                                                    </Tooltip>
                                                                                )}
                                                                            </div>
                                                                            <a
                                                                                style={{ margin: '8px' }}
                                                                                className=''
                                                                                href={`${getLinkDomain(2)}/online/${web_store_url}`} target='_blank'
                                                                            >
                                                                                <Icon
                                                                                    path={mdiOpenInNew}
                                                                                    className="weblink-icon"
                                                                                    size={0.9}
                                                                                    color="rgb(33,37,41)"
                                                                                />
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row className="my-2">
                                                            <Col md={12}>
                                                                <div className="px-4">
                                                                    <span style={{ fontSize: "14px", color: "#5e5e5e" }}>
                                                                        Share your store with customers on social media or by directly sending them a link.
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>
                                    </div>
                                </Col>
                                <UncontrolledTooltip
                                    className="p-0 text-left"
                                    dir="ltr"
                                    placement="top"
                                    target="MenuModeToolTip"
                                    style={{
                                        backgroundColor: "#93949D",
                                        lineHeight: "1.2rem",
                                    }}
                                >
                                    <p className="text-left" style={{ lineHeight: '1.25' }}>
                                        If your store is not accepting online orders then you can enable this option, this will notify your customer that your store is not accepting orders at the moment when they try to add item in their cart
                                    </p>
                                </UncontrolledTooltip>
                                {menuTypePopup && (
                                    <ViewMenuTypePopup
                                        isOpen={menuTypePopup}
                                        toggle={() => { document.getElementById("view_menu").click(); setMenuTypePopup(false); }}
                                        goToOrderType={() => history.push('/takeaway-dinein')}
                                    />
                                )}
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className='container my-4'>
                                <label className='' style={{ display: 'flex', width: '26%', fontSize: '15px'}} htmlFor="material-switch">
                                <span className='mr-2'>Do not show listing on ipaypro.co</span>
                                <Switch
                                    checked={listingStatus}
                                    onChange={handleListingStatus}
                                    className="react-switch"
                                    handleDiameter={20}
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    height={22}
                                    width={42}
                                    id="material-switch"
                                />
                                </label>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            {/* <Orders merchant_id={merchant._id}/> */}
                            <Suspense fallback={fallbackLoading}><Orders merchant={merchant} merchant_id={merchant._id}/></Suspense>
                        </TabPanel>
                    </div>
                </Tabs>
            </div>
        </div>
    )
}

// export default OnlineOrderTab

export default reduxForm({
    form: "OnlineOrderTabForm",
    // validate,
    initialized: true,
})(OnlineOrderTab);