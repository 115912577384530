import React, { useState } from 'react'

const CustomerName = ({ text }) => {
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    const truncatedName = text ? (isReadMore ? text.slice(0, 12) : text) : '';

    return (
        <>
            <p className="text">
                {truncatedName}
                {text && text.length >= 12 && (
                    <span
                        style={{ cursor: "pointer", color: "#D23F99", marginLeft: "3px" }}
                        onClick={toggleReadMore}
                        className="read-or-hide"
                    >
                        {isReadMore ? '...read more' : ' show less'}
                    </span>
                )}
            </p>
            {!isReadMore && <br />}
        </>
    )
}

export default CustomerName