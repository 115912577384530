import React, { useEffect, Fragment, useState } from "react";
import { useHistory} from "react-router-dom";
import Breadcrumb from '../../../components/common/breadcrumb';
// import AddForm from './AddForm';
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import {DisappearedLoading} from "react-loadingg";
import {Link} from "react-router-dom";
import moment from "moment-timezone";

function Legals() {

    let [loading, setloading] = useState(true);
    // let [addformOpen, setaddformOpen] = useState(false);
    let [legals, setLegals] = useState([]);

    async function fetchData() {
        client.post(api.fetch_legals, {}, async function (error, response) {
           //console.log(response, 'legals')
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            //console.log(response.result.data)
                            setLegals(response.result.data);
                            
                        }
                    } catch {
                        
                    }
                }
            }
            setloading(false);
        });
    }

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <Fragment>
            <div className="row">
                <div className="col-lg-7 col-sm-12" style={{fontSize:"12px"}}>
                    <Breadcrumb title="Legals" parent="Home" />
                </div>
                <div className="col-lg-5 col-sm-12 ">
                </div>
            </div>
            
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card" style={{ border: '1px solid rgb(223, 232, 241)', borderRadius: '3px'}}>
                            <div className="card-body" style={{ padding: "1rem" }}>
                                {!loading ?
                                    <div className="table-responsive" >
                                        <h5>Legals</h5>
                                        <table className="table table-border-horizontal">
                                            <thead>
                                                <tr style={{background: '#f9fafe'}}>
                                                    <th scope="col">Date/Time</th>
                                                    <th scope="col" >Store Name</th>
                                                    <th scope="col" >Country</th>
                                                    <th scope="col" >Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* { legals} */}
                                                {legals && legals.length > 0 ? legals.map((item, index) => {
                                                    return (
                                                        <tr>
                                                            <td>{moment(item.linkyAgreement.accept_date).format("DD/MM/YYYY hh:mm A")}</td>
                                                            <td>{item.business_name}</td>
                                                            <td>{item.country.code}</td>
                                                            <td>
                                                                <a target="_blank" href={`${client.url('')}linky_agreement/${item.linkyAgreement.agreement_copy}`}>
                                                                    <span style={{ marginRight: "10px", fontSize: "0.85rem", color: "blue" }}>
                                                                        <i className="fa fa-eye"></i>
                                                                    </span>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                    : null
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <div className="loader-box" style={{height:"100vh",width:"100wh"}}>
                              <div className="loader" style={{ display:"flex",alignItems:"center" }}>
                              <DisappearedLoading size="medium" color="#514F4E" />
                               </div> 
                               </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
   
        </Fragment>
    );

}

export default Legals;