import React, {useRef, useEffect} from 'react';
import OtpInput from 'react-otp-input';
import { isMobile } from "react-device-detect";

function VerifyOtpComp({onChange, value, prevOtp}) {

    const otpRef = useRef(null);

    useEffect(() => {
        if(value?.length < prevOtp?.length){
            otpRef.current.focusInput(value.length)
        }
    }, [value]);

    return (
        <OtpInput
            // inputStyle={{
            //     height: "4.5vw",
            //     width: "4.5vw",
            //     margin: "20px 10px",
            //     fontSize: "3vw",
            //     textAlign: "center",
            //     borderRadius: '5px',
            //     border: 'none',
            //     padding: '0vw',

            // }}
            className='forgot_inputs'
            ref={otpRef}
            containerStyle={{
                flexFlow: "wrap"
            }}
            numInputs={6}
            onChange={onChange}
            isInputNum={true}
            value={value}
        />
    )
}

export default VerifyOtpComp