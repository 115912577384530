import React, { useState, useEffect } from 'react';
import useForm from "react-hook-form";
import { toast } from "react-toastify";

const OtherSettingComp = ({
    priceData,
    updatePriceData,
    deletePriceData,
    alPriceData
}) => {

    let { handleSubmit, register, setValue, errors } = useForm({
        defaultValues: {
            price_per_store: priceData.price_per_store
        }
    });

    return (
        <div className='p-4'>
            <form noValidate="" className="w-100">
                <div className='d-flex align-items-center'>
                    <h4 className='mb-0 mr-3' style={{ fontSize: "15px" }}>Price Per Store &nbsp;: </h4>
                    <input
                        style={{
                            border: "#dfe8f1 solid 1px",
                            borderRadius: "3px",
                            padding: "10px",
                            fontSize: "0.75rem",
                            outlineColor: '#dfe8f1',
                        }}
                        type="number"
                        name={`price_per_store`}
                        ref={register}
                        onBlur={(event) => {

                            let getValue = event.target.value;
                            if (getValue == 0) {
                                toast.error("Amount Should be more than 0", { autoClose: 2000, hideProgressBar: true });
                            } else if (getValue > 0 && getValue != priceData.price_per_store) {
                                let postData = { updateId: priceData._id, price_per_store: event.target.value }
                                updatePriceData(postData);
                            }
                        }}
                    />
                    <h4 className='ml-3' style={{ fontSize: "15px" }}>A$ </h4>
                </div>
                {/* <div
                    className='p-3 border m-2'
                    style={{ cursor: "pointer", width: "155px" }}
                    onClick={() => {
                        let postData = { deleteId: priceData._id }
                        deletePriceData(postData)
                    }}
                >Delete Store Price</div> */}
            </form>
        </div>
    )
}

export default OtherSettingComp