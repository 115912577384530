import React, { Component, Fragment } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

class ImageCroper extends Component {
	constructor(props) {
		super(props);

		this.state = {
			src: this.props.src,
			crop: {
				unit: "%",
				width: 50,
				height: 50,
				aspect: 16 / 9,
			},
			croppedImagefinal: null,
		};
	}
	onSelectFile = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener("load", () => this.setState({ src: reader.result }));
			reader.readAsDataURL(e.target.files[0]);
		}
	};

	// If you setState the crop in here you should return false.
	onImageLoaded = (image) => {
		this.imageRef = image;
	};

	onVideoLoaded = (video) => {
		this.videoRef = video;
	};

	onCropComplete = (crop, pixelCrop) => {
		this.makeClientCrop(crop);
	};

	onCropChange = (crop, percentCrop) => {
		// You could also use percentCrop:
		this.setState({ crop });
	};

	async makeClientCrop(crop) {
		if (this.imageRef && crop.width && crop.height) {
			const croppedImageUrl = await this.getCroppedImg(this.imageRef, crop, "newFile.jpeg");
			this.setState({ croppedImageUrl });
		} else if (this.videoRef && crop.width && crop.height) {
			const croppedVideoUrl = await this.getCroppedVid(this.videoRef, crop, "mp4");
			this.setState({ croppedVideoUrl });
		}
	}

	getCroppedVid(video, crop, fileName) {
		const canvas = document.createElement("canvas");
		const scaleX = video.naturalWidth / video.width;
		const scaleY = video.naturalHeight / video.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext("2d");

		ctx.drawImage(
			video,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);

		return new Promise((resolve, reject) => {
			canvas.toBlob((blob) => {
				if (!blob) {
					return;
				}
				blob.name = fileName;
				window.URL.revokeObjectURL(this.fileUrl);
				this.fileUrl = window.URL.createObjectURL(blob);
				resolve(this.fileUrl);
			}, "video/mp4");
		});
	}

	getCroppedImg(image, crop, fileName) {
		const canvas = document.createElement("canvas");
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext("2d");

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);
		let imgData64 = canvas.toDataURL();
		this.setState({ croppedImagefinal: imgData64 });
		return new Promise((resolve, reject) => {
			canvas.toBlob((blob) => {
				if (!blob) {
					//reject(new Error('Canvas is empty'));
					return;
				}
				blob.name = fileName;
				window.URL.revokeObjectURL(this.fileUrl);
				this.fileUrl = window.URL.createObjectURL(blob);
				resolve(this.fileUrl);
			}, "image/jpeg");
		});
	}
	handledownload = () => {
		let imageData;
		let cropSrc = this.state.croppedImagefinal;
		imageData = cropSrc;
		var mimeType = cropSrc.split(";")[0].split(":")[1];
		imageData = imageData.split(",")[1];
		let image_data = {
			mime: mimeType,
			data: imageData,
			imageresult: cropSrc,
		};
		console.log(image_data);
		this.props.setWelcomeImage(image_data);
		// this.props.setCropsrc(this.state.croppedImagefinal);
		this.props.setImageCroper("0");
		this.props.setopenModal(false);
	};
	handleCancel = () => {
		this.props.setCropsrc(null);
		this.props.setImageCroper("0");
		this.props.setopenModal(false);
		this.props.setWelcomeImage(null);
	};

	render() {
		// console.log(this.props.src, this.props, "runned");

		const { crop, croppedImageUrl, croppedVideoUrl, src } = this.state;
		// console.log(this.state.croppedImagefinal, "crop image");

		return (
			<Fragment>
				{/* <Breadcrumb title="Image Cropper" parent="Advance" /> */}

				<div className="container-fluid">
					<div className="card">
						<div className="card-header">
							<h5>Image Cropper</h5>
						</div>
						<div className="card-body">
							{/* <div className="input-cropper">
								<input type="file" onChange={this.onSelectFile} />
							</div> */}
							{src && (
								<ReactCrop
									src={src}
									crop={crop}
									onImageLoaded={this.onImageLoaded}
									onComplete={this.onCropComplete}
									onChange={this.onCropChange}
								/>
							)}
							<h5 style={{ color: "gray", margin: "8px" }}>Preview</h5>
							{croppedImageUrl && (
								<img
									alt="Crop"
									style={{ maxWidth: "100%", display: "block", margin: "16px auto" }}
									src={croppedImageUrl}
									className="crop-portion"
								/>
							)}

							{/* {croppedVideoUrl && (
								<video controls width="250">
									<source src={croppedVideoUrl} type="video/mp4" />
								</video>
							)} */}
						</div>
						{croppedImageUrl && (
							<div className="card-footer">
								<div className="btnHolder" style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
									<button className="btn btn-danger" onClick={this.handleCancel} style={{ margin: "auto 16px" }}>
										Cancel
									</button>
									<button className="btn btn-primary" onClick={this.handledownload}>
										Save
									</button>
								</div>
							</div>
						)}
					</div>
				</div>
			</Fragment>
		);
	}
}

export default ImageCroper;
