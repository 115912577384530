import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    CardBody,
    Button,
    Badge,
    Card,
    Modal,
    ModalBody,
    Popover, PopoverBody
} from "reactstrap";
import { Link } from 'react-router-dom';
import moment from "moment-timezone";
import EyeIcon from "mdi-react/EyeIcon";
import Icon from "@mdi/react";
import { mdiCreditCardOutline, mdiSubdirectoryArrowLeft, mdiChevronLeft } from "@mdi/js";
import { Check } from "@material-ui/icons";
import client from '../../Api/HTTPClient';
import api from '../../constant/apilist';
import Swal from 'sweetalert2';
import BeatLoader from 'react-spinners/BeatLoader';
import TimeLineItem from '../../components/shared/TimelineItem';

import './css/ViewTransaction.scss';




const ViewTransaction = (props) => {
    // console.log('props::', props.onBackClick)
    const { merchant_id, data, view_type, disabled_refund, wallet_amount, countryData, onBackClick } = props

    const transaction_no = data?.data?.transaction_no ||data?.data?.customer?.transaction_no
    let transaction_noTicket;

    const [item, setitem] = useState(null);
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);
    const [refundItem, setrefundItem] = useState(null);
    const [popoverstate, setpopoverstate] = useState({});
    const [partialRefund, setPartialRefund] = useState(false);
    const [fullRefund, setFullRefund] = useState(false);
    const [refundBtn, setRefundBtn] = useState(true);
    const [refundAmountt, setRefundAmountt] = useState(0);
    const [charges, setCharges] = useState(0);
    const [timeline, setTimeline] = useState([]);
	const [transactionObject, settransactionObject] = useState(null);
    const [showReceiverInfo, setshowReceiverInfo] = useState(false);
    

    const ShowCurrencyNum = (amount, showSymbol) => {
        // const merchantData = JSON.parse(localStorage.getItem("merchant"));
        // const currency_symbol = merchantData.country.currency_symbol;
        const currency_symbol = countryData.currency_symbol;

        let getCurrency = !isNaN(parseFloat(amount)) ? amount : 0;

        // if (merchantData && merchantData.country) {
        if (countryData) {
            if (showSymbol && currency_symbol) {
                getCurrency = `${currency_symbol}${parseFloat(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
            }
            else {
                getCurrency = +`${parseFloat(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
            }
        }

        return getCurrency;
    }


    useEffect(() => {
        if (props) {
            if (typeof window != undefined) {
                transaction_noTicket = JSON.parse(sessionStorage.getItem('transcationID'))
            }
        }
    }, [props])

    const fetch_transaction_details = () => {

        setLoading(true);

        let postdata = {
            "customer_id": merchant_id,
            "transaction_no": transaction_noTicket ? transaction_noTicket : transaction_no,
        }

        client.post(api.transaction_details, postdata, (error, response) => {

            // console.log(response, "transaction_details")
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {

                            setitem(response.result.transaction)
                            setTimeline(response.result.transaction?.timeline?.reverse())
                            setcharges_function(response.result.transaction)

							create_transaction_display_object(response.result.transaction)


                        } else {
                            Swal.fire({
                                icon: "error",
                                title: "Oops....1",
                                text: response.message,
                            });
                        }
                    } catch (error) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops....2",
                            text: "Internal server error occured.Please contact support",
                        });
                    }
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops....3",
                        text: response.message,
                    });
                }
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops....4",
                    text: "Internal server error occured.Please contact support",
                });
            }
            setLoading(false)

        });
    }

    const create_transaction_display_object = (transaction) => {

		try {
			let iam_payer = merchant_id == transaction.from_customer_id;


			let t_object = {
				pay_amount: ShowCurrencyNum((iam_payer ? transaction.amount : transaction.pay_amount).toFixed(2), true),
				total: ShowCurrencyNum(transaction.amount.toFixed(2), true),
				// net: ShowCurrencyNum((iam_payer ? transaction.amount : transaction.pay_amount).toFixed(2)),
				from: ShowCurrencyNum(transaction.amount.toFixed(2), true),
				to: ShowCurrencyNum((iam_payer ? transaction.amount : transaction.pay_amount).toFixed(2), true),
			}

			if (transaction.amount_payer && transaction.amount_receiver) {
				let tap = transaction.amount_payer
				let tar = transaction.amount_receiver

				let t_a = iam_payer ?  tap : tar
				t_object.pay_amount = t_a.currency.symbol + t_a.amount.toFixed(2)
				t_object.total = tar.currency.symbol + transaction.amount.toFixed(2)
				t_object.from = tap.currency.symbol + tap.amount.toFixed(2)
				t_object.to = tar.currency.symbol + tar.amount.toFixed(2)
				t_object.to = tar.currency.symbol + tar.amount.toFixed(2)
				// t_object.net = t_a.currency.symbol + t_a.amount.toFixed(2)
			}

			// console.log(t_object, "t_object")
			settransactionObject(t_object)
		} catch(error) {
			console.log(error)
		}
		
	}


    useEffect(() => {
        fetch_transaction_details()
    }, [])

    const onSubmitRefundRequest = (selectedTransaction, refundAmount) => {
        //event.preventDefault();

        const postData = {
            transaction_no: selectedTransaction.transaction_no,
            amount: refundAmount,
        };
        setLoading(true);
        if (refundAmount <= selectedTransaction.amount) {
            // if (refundAmount <= wallet_amount) {
            client.post(api.refund_request, postData, (error, response) => {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                setLoading(false);
                                setRefundAmountt(postData.amount);
                                /* setRefundModal(false);
                                setRefundAmount(null);
                                setSelectedTransaction(null); */
                                Swal.fire({
                                    icon: "success",
                                    title: "Amount Refunded",
                                });
                                if (postData.amount == selectedTransaction.amount) {
                                    setFullRefund(true);
                                } else {
                                    setPartialRefund(true);
                                }
                                setRefundBtn(false);
                                // window.location.reload();
                                fetch_transaction_details()
                            } else {
                                setLoading(false);
                                Swal.fire({
                                    icon: "error",
                                    title: "Oops....",
                                    text: response.message,
                                });
                            }
                        } catch (error) {
                            setLoading(false);
                            Swal.fire({
                                icon: "error",
                                title: "Oops....",
                                text: "Internal server error occured.Please contact support",
                            });
                        }
                    } else {
                        setLoading(false);
                        Swal.fire({
                            icon: "error",
                            title: "Oops....",
                            text: response.message,
                        });
                    }
                } else {
                    setLoading(false);
                    Swal.fire({
                        icon: "error",
                        title: "Oops....",
                        text: "Internal server error occured.Please contact support",
                    });
                }
            });
            setLoading(false);

            // } else {
            // 	Swal.fire({
            // 		icon: "error",
            // 		title: "Insufficient Balance",
            // 		text: "Kindly topup your wallet to initiate refund",
            // 	});
            // 	setLoading(false);

            // }
        } else {

            Swal.fire({
                icon: "error",
                title: "Invalid amount",
                text: "Please enter correct amount.",
            });
            setLoading(false);

        }
    };

    const setcharges_function = (result) => {
        try {
            let charges_info = result.charges_info;
            // console.log(charges_info, "charges_info")

            if (charges_info.length > 0) {
                // charges_info = charges_info.filter(a => a.charges_payer == 0);

                // if (charges_info.length > 0) {
                let total_charges = charges_info.reduce((previousValue, currentValue) => previousValue + currentValue.amount, 0)
                setCharges(total_charges)
                // }

            }

        } catch (err) {

        }
    }

    const render_date = (add_date) => {
        if (typeof add_date === "undefined") {
            return;
        }

        const convert_date = new Date(add_date);
        const added_date = moment.tz(convert_date, 'Australia/Sydney').format("DD/MM/YYYY HH:mm:ss");
        // const added_date = moment.tz(convert_date, getTimeZone()).format("DD/MM/YYYY HH:mm:ss");
        return added_date;
    };

    const paymentMethod = (method, card_number) => {
        if (method == 1) {
            let last = card_number.substring(12);
            let encrypt_number = "**** **** ****" + last;
            return encrypt_number;
        } else if (method == 2) {
            return "iPayPro Wallet";
        } else if (method == 3) {
            return "Cashback (iPay money)";
        } else if (method == 4) {
            return "iPay-backend";
        } else if (method == 5) {
            return "Paypal";
        } else if (method == 6) {
            return "Apple pay";
        }
    };

    const convertMobileNumber = (no) => {
        if (no) {
            if (no.length > 2) {
                let first = no.slice(0, 2);
                let final_number = null;
                if (no.length == 10) {
                    let last = no.substring(8);
                    final_number = first + "********" + last;
                } else {
                    let last = no.substring(7);
                    final_number = first + "*******" + last;
                }
                return final_number;
            }
        }
        return "-";
    };

    const toggle = (item = null) => {
        setModal(!modal);
        setrefundItem(item);
    };

    const paymentStatus = () => {
        if (item.status == 1) {
            if (item.refund_data?.status == 1) {
                return (
                    <span
                        style={{
                            backgroundColor: "rgb(238 238 238)",
                            fontSize: "13px",
                            color: "#767575",
                            padding: "4px 12px",
                            borderRadius: "5px",
                            marginLeft: "7px",
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        Refunded
                        <Icon path={mdiSubdirectoryArrowLeft} style={{ fontSize: '13px', width: '19px', height: '19px', marginBottom: '4px' }} color="#767575" />
                    </span>
                );
            } else {
                return (
                    <span
                        style={{
                            backgroundColor: "rgb(201 254 201 / 58%)",
                            fontSize: "13px",
                            color: "rgb(67 168 67)",
                            padding: "4px 12px",
                            borderRadius: "5px",
                            marginLeft: "7px",
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        {/* <span>
                        </span> */}
                        Succeeded
                        <Check style={{ fontSize: '13px', width: '19px', height: '19px', marginBottom: '4px' }} />
                    </span>
                );
            }
        } else if (item.status == 2) {
            return (
                <span
                    style={{
                        backgroundColor: "rgb(255 232 232)",
                        fontSize: "13px",
                        color: "rgb(196 51 51)",
                        padding: "4px 12px",
                        borderRadius: "5px",
                        marginLeft: "7px",
                    }}
                >
                    Failed X
                </span>
            );
        } else {
            return null;
        }
    };

    const togglechargesInfo = (targetName) => {
        if (!popoverstate[targetName]) {
            setpopoverstate({
                ...popoverstate,
                [targetName]: {
                    tooltipOpen: true,
                },
            });
        } else {
            setpopoverstate({
                ...popoverstate,
                [targetName]: {
                    tooltipOpen: !popoverstate[targetName].tooltipOpen,
                },
            });
        }
    };

    const isToolTipOpen = (targetName) => {
        return popoverstate[targetName] ? popoverstate[targetName].tooltipOpen : false;
    };

    const gitReceiverName = (item) => {
        try {
            if (item.type == 3) {
                let image = "";
                let name = "";
                if (item.from_wallet_type == 2) {

                    name = item.from_mechant[0].business_name;
                } else {

                    name = item.from_customers[0].name;
                }
                return name;
            } else {
                return "-"
            }
        } catch {
            return "-"
        }
    }
    const gitSenderimage = (item) => {
        try {
            if (item.type == 3) {
                let image = "";
                let name = "";
                if (item.from_wallet_type == 2) {
                    if (item.from_mechant[0].profile_image && item.from_mechant[0].profile_image != "") {
                        // image = `images/merchant-profile/${item.from_mechant[0].profile_image}`;
                        image = item.from_mechant[0].profile_image;
                    }
                    name = item.from_mechant[0].business_name;
                } else {
                    if (item.from_customers[0].profile_image && item.from_customers[0].profile_image != "") {
                        // image = `images/customer-profile/${item.from_customers[0].profile_image}`;
                        image = item.from_customers[0].profile_image;
                    }
                    name = item.from_customers[0].name;
                }
                if (image && image != "") {
                    // return <img style={{ width: 40, height: 40, borderRadius: 20, objectFit: 'contain' }} src={client.url(image)} className="p-0" alt="drop-img" />
                    return <img style={{ width: 40, height: 40, borderRadius: 20, objectFit: 'contain' }} src={image} className="p-0" alt="drop-img" />
                } else {
                    return <img src={`${client.url(`/static-images/ipayBlue.png`)}`} style={{ marginTop: "6px", width: 40, height: 40, borderRadius: 20, objectFit: 'contain' }} className="p-0" alt="drop-img" />
                }
            }
        } catch {
            return null
        }
    }

    const gitReceiverimage = (item) => {
        // console.log(item, "item")
        try {
            if (item.type == 3 || item.type == 1) {
                let image = "";
                let name = "";
                if (item.to_wallet_type == 2) {
                    if (item.to_mechant[0].profile_image && item.to_mechant[0].profile_image != "") {
                        // image = `images/merchant-profile/${item.to_mechant[0].profile_image}`;
                        image = item.to_mechant[0].profile_image;
                    }
                    name = item.to_mechant[0].business_name;
                } else {
                    if (item.to_customers[0].profile_image && item.to_customers[0].profile_image != "") {
                        // image = `images/customer-profile/${item.to_customers[0].profile_image}`;
                        image = item.to_customers[0].profile_image;
                    }
                    name = item.to_customers[0].name;
                }
                if (image && image != "") {
                    // return <img style={{ width: 40, height: 40, borderRadius: 20, objectFit: 'contain' }} src={client.url(image)} className="p-0" alt="drop-img" />
                    return <img style={{ width: 40, height: 40, borderRadius: 20, objectFit: 'contain' }} src={image} className="p-0" alt="drop-img" />
                } else {
                    return <img src={`${client.url(`/static-images/iPayPOS.png`)}`} style={{ marginTop: "6px", width: 40, height: 40, borderRadius: 20, objectFit: 'contain' }} className="p-0" alt="drop-img" />
                }
            }
        } catch {
            return null
        }
    }

    if (!item || !transactionObject) {
		return null
	}

    // console.log(merchant_id, "merchant_id")
    // console.log(item.from_customer_id, "item.from_customer_id")



    return (
        <Row className='w-100 view_transaction_page_stylings'>
            <Col md={12} className="p-0">
                <CardBody className="py-0">
                    <Container fluid className='p-0'>
                        <Row>
                            <Col md={12}>
                                <div className="d-flex align-items-center">
                                    <div className="mr-auto">
                                        <div>
                                            <Button
                                                className="my-3 py-1 d-flex align-items-center justify-content-center"
                                                color="success"
                                                size="sm"
                                                outline
                                                type="button"
                                                style={{
                                                    borderRadius: "3px",
                                                    padding: "0px 11px 0px 6px"
                                                }}
                                                onClick={onBackClick}
                                            >
                                                <Icon path={mdiChevronLeft} size={1.2} className="m-0 go_backIcon" />
                                                <span>Go back</span>
                                            </Button>
                                            <div style={{ display: "flex", alignItems: "center", width: "100%", margin: "0.7rem 0rem" }}>
                                                <Icon path={mdiCreditCardOutline} size={1} color="grey" />
                                                <h6
                                                    style={{
                                                        fontSize: "13px", marginLeft: "6px", fontWeight: "500", marginBottom: '0'
                                                    }}> Payment</h6>
                                            </div>
                                            {/* <pre>{JSON.stringify(item, undefined, 4)}</pre> */}
                                            <div style={{ display: "block", width: "100%", marginTop: "15px" }}>
                                                <h5 style={{ fontSize: "20px", display: 'flex', alignItems: 'center' }}>
                                                    <span style={{ fontSize: "30px", fontWeight: "700", color: "black" }}>
                                                        {transactionObject.pay_amount}
                                                    </span>
                                                    {/* <span style={{ position: "relative", top: "-8px", fontWeight: "500" }}>Succeeded</span> */}
                                                    <span style={{ fontWeight: "500" }}>{paymentStatus()}</span>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </Col>
                        </Row>
                        {!loading ? (
                            <>

                                <Row className="border-top border-bottom py-4 my-4">

                                    <Col md={6} lg={6} className="m-0 px-0 pb-5" >
                                        <Card>
                                            {true ? (
                                                <CardBody>
                                                    <Row>
                                                        <Col style={{ padding: "0" }}>
                                                            <h5 style={{ fontSize: 16, fontWeight: "600", marginBottom: "26px", color: "black" }}>
                                                                Transaction Details
                                                            </h5>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ border: "1px solid #D4D4D3" }}>
                                                        <Col
                                                            md={3}
                                                            style={{ backgroundColor: "#E5E8E8", padding: "13px 13px", color: "black", fontWeight: "500", fontSize: '13px' }}
                                                        >
                                                            Transaction ID
                                                        </Col>
                                                        <Col style={{ padding: "13px 13px", fontSize: "14px", color: "black" }}>
                                                            {item.transaction_no}
                                                            {/* transac No */}
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ border: "1px solid #D4D4D3" }}>
                                                        <Col
                                                            md={3}
                                                            style={{ backgroundColor: "#E5E8E8", padding: "13px 13px", color: "black", fontWeight: "500", fontSize: '13px' }}
                                                        >
                                                            Date & Time
                                                        </Col>
                                                        <Col style={{ padding: "13px 13px", fontSize: "14px", color: "black", fontSize: '13px' }}>
                                                            {render_date(item.transaction_date)}
                                                            {/* 03-01-2023 */}
                                                        </Col>
                                                    </Row>

                                                    <Row style={{ border: "1px solid #D4D4D3" }}>
                                                        <Col
                                                            md={3}
                                                            style={{ backgroundColor: "#E5E8E8", padding: "13px 13px", color: "black", fontWeight: "500", fontSize: '13px' }}
                                                        >
                                                            Message
                                                        </Col>
                                                        <Col style={{ padding: "13px 13px", fontSize: "14px", color: "black", fontSize: '13px' }}>
                                                            {item.description?.length > 0 ? item.description : "NA"}
                                                            {/* Barbeque Junction [test] #PL-63ae9abe2e5842216fddcde9 */}
                                                        </Col>
                                                    </Row>

                                                    <Row style={{ border: "1px solid #D4D4D3" }}>
                                                        <Col
                                                            md={3}
                                                            style={{ backgroundColor: "#E5E8E8", padding: "13px 13px", color: "black", fontWeight: "500", fontSize: '13px' }}
                                                        >
                                                            Paid via
                                                        </Col>
                                                        <Col style={{ padding: "13px 13px", fontSize: "14px", color: "black", fontWeight: '500', fontSize: '13px' }}>
                                                            {paymentMethod(item.payment_method, item.card_number)}
                                                            {/* **** **** ****4242 */}
                                                        </Col>
                                                    </Row>

                                                    {charges > 0 && (
                                                        <>
                                                            <Row style={{ border: "1px solid #D4D4D3" }}>
                                                                <Col
                                                                    md={3}
                                                                    style={{ backgroundColor: "#E5E8E8", padding: "13px 13px", color: "black", fontWeight: "500", fontSize: '13px' }}
                                                                >
                                                                    Amount
                                                                </Col>
                                                                <Col style={{ padding: "13px 13px", fontSize: "14px", color: "black", fontSize: '13px' }}>
                                                                    <span>
                                                                    {transactionObject.total}
                                                                    </span>
                                                                </Col>
                                                            </Row>

                                                            <Row style={{ border: "1px solid #D4D4D3" }}>
                                                                <Col
                                                                    md={3}
                                                                    style={{ backgroundColor: "#E5E8E8", padding: "13px 13px", color: "black", fontWeight: "500", fontSize: '13px' }}
                                                                >
                                                                    Fees
                                                                </Col>
                                                                <Col style={{ padding: "13px 13px", fontSize: "14px", color: "black", fontSize: '13px' }}>
                                                                    
                                                                    {item.amount_receiver ?
                                                                        <span>
                                                                            {item.amount_receiver.currency.symbol + charges.toFixed(2)}
                                                                        </span>
                                                                        :
                                                                        <span>
                                                                    
                                                                            {ShowCurrencyNum(charges.toFixed(2), true)}
                                                                            {/* $12.5 */}
                                                                        </span>
                                                                    }
                                                                    {charges > 0 ? (
                                                                        <>
                                                                            <span
                                                                                id={`Popover${item._id}`}
                                                                                style={{ marginLeft: "5px", cursor: "pointer" }}
                                                                                onMouseEnter={() => togglechargesInfo(`Popover${item._id}`)}
                                                                                onMouseLeave={() => togglechargesInfo(`Popover${item._id}`)}
                                                                            >
                                                                                <i style={{ color: "grey" }} className="fa fa-info-circle"></i>
                                                                            </span>
                                                                            <Popover
                                                                                placement={"right"}
                                                                                isOpen={isToolTipOpen(`Popover${item._id}`)}
                                                                                target={`Popover${item._id}`}
                                                                                toggle={() => togglechargesInfo(`Popover${item._id}`)}
                                                                            >
                                                                                <PopoverBody>
                                                                                    {item.charges_info && item.charges_info.length ? (
                                                                                        <>
                                                                                            {item.charges_info.map((item) => (
                                                                                                <p style={{ marginBottom: 0 }}>
                                                                                                    {item.title} :{ShowCurrencyNum(item.amount?.toFixed(2), true)}
                                                                                                </p>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <p style={{ marginBottom: 0 }}>
                                                                                            Service charges :{ShowCurrencyNum(charges.toFixed(2), true)}
                                                                                        </p>
                                                                                    )}
                                                                                </PopoverBody>
                                                                            </Popover>
                                                                        </>
                                                                    ) : null}
                                                                </Col>
                                                            </Row>

                                                            {merchant_id == item.from_customer_id && item.amount_payer && item.amount_payer.currency.code != item.amount_receiver.currency.code &&
                                                                <Row  style={{ border: "1px solid #D4D4D3" }}>
                                                                    <Col
                                                                        md={3}
                                                                        style={{ backgroundColor: "#E5E8E8", padding: "1rem 1rem", color: "black", fontWeight: "500" }}
                                                                    >
                                                                        Total amount converted
                                                                    </Col>
                                                                    <Col style={{ padding: "1rem 1rem", fontSize: "14px", color: "black" }}>
                                                                        <span>
                                                                            {item.amount_receiver.currency?.symbol + "" + item.amount_receiver?.amount.toFixed(2)} [Exchange Rate 1{item.amount_receiver.currency?.code} = {item.amount_payer.exchange_rate}{item.amount_payer.currency?.code}]
                                                                        </span>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                        </>
                                                    )}

                                                    <Row style={{ border: "1px solid #D4D4D3" }}>
                                                        <Col
                                                            md={3}
                                                            style={{ backgroundColor: "#E5E8E8", padding: "13px 13px", color: "black", fontWeight: "500", fontSize: '13px' }}
                                                        >
                                                            Net
                                                        </Col>
                                                        <Col style={{ padding: "13px 13px", fontSize: "14px", color: "black", fontSize: '13px' }}>
                                                            <span
                                                                style={
                                                                    item.to_customer_id == merchant_id
                                                                        ? { backgroundColor: "rgba(28, 212, 104,0.2)" }
                                                                        : { backgroundColor: "rgb(254 201 201 / 70%)" }
                                                                }
                                                            >
                                                                {transactionObject.pay_amount}
                                                            </span>
                                                        </Col>
                                                    </Row>

                                                    <Row style={{ border: "1px solid #D4D4D3" }}>
                                                        <Col
                                                            md={3}
                                                            style={{ backgroundColor: "#E5E8E8", padding: "13px 13px", color: "black", fontWeight: "500" }}
                                                        >
                                                            <div style={{ position: "relative", top: "4px", fontSize: '13px' }}>Transaction Status</div>
                                                        </Col>
                                                        <Col style={{ padding: "13px 13px", fontSize: "14px", color: "black" }}>
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                                                {
                                                                    item.refund_status == 1 ?
                                                                        <div>
                                                                            {item.refund_data.amount == item.amount ? (
                                                                                <h6
                                                                                    style={{
                                                                                        color: "rgb(245 130 146)",
                                                                                        fontWeight: "500",
                                                                                        fontSize: "13px",
                                                                                        marginLeft: "0px",
                                                                                    }}
                                                                                >
                                                                                    Refunded
                                                                                </h6>
                                                                            ) : (
                                                                                <h6
                                                                                    style={{
                                                                                        fontWeight: "500",
                                                                                        fontSize: "13px",
                                                                                        marginLeft: "5px",
                                                                                    }}
                                                                                >

                                                                                    Partially refunded ({ShowCurrencyNum(item.refund_data.amount, true)})
                                                                                </h6>
                                                                            )}
                                                                        </div>
                                                                        :
                                                                        item.status == 1 ? (
                                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                                                                <Badge color="success" pill style={{ marginRight: "10px" }}>
                                                                                    <h6 style={{ fontSize: "12px", color: "white", padding: "4px 15px", margin: '0' }}>Success</h6>
                                                                                </Badge>

                                                                                <div>
                                                                                    {item?.refund_data?.status == 1 ? (
                                                                                        <div>
                                                                                            {item.refund_data.amount == item.amount ? (
                                                                                                <h6
                                                                                                    style={{
                                                                                                        color: "rgb(245 130 146)",
                                                                                                        fontWeight: "500",
                                                                                                        fontSize: "13px",
                                                                                                        marginLeft: "0px",
                                                                                                        margin: '0'
                                                                                                    }}
                                                                                                >
                                                                                                    Refunded
                                                                                                </h6>
                                                                                            ) : (
                                                                                                <h6
                                                                                                    style={{
                                                                                                        fontWeight: "500",
                                                                                                        fontSize: "13px",
                                                                                                        marginLeft: "5px",
                                                                                                        marginBottom: '0'
                                                                                                    }}
                                                                                                >
                                                                                                    Partially refunded ({ShowCurrencyNum(item?.refund_data?.amount, true)})
                                                                                                </h6>
                                                                                            )}
                                                                                        </div>
                                                                                    ) : null}
                                                                                </div>

                                                                                <div>
                                                                                    <div>
                                                                                        {fullRefund ? (
                                                                                            <h6
                                                                                                style={{
                                                                                                    color: "rgb(245 130 146)",
                                                                                                    fontWeight: "500",
                                                                                                    fontSize: "13px",
                                                                                                    marginLeft: "0px",
                                                                                                    marginBottom: '0'
                                                                                                }}
                                                                                            >
                                                                                                Refunded
                                                                                            </h6>
                                                                                        ) : null}
                                                                                        {partialRefund ? (
                                                                                            <h6
                                                                                                style={{
                                                                                                    fontWeight: "500",
                                                                                                    fontSize: "13px",
                                                                                                    marginLeft: "5px",
                                                                                                    marginBottom: '0'
                                                                                                }}
                                                                                            >
                                                                                                Partially refunded ({ShowCurrencyNum(refundAmountt, true)})
                                                                                            </h6>
                                                                                        ) : null}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ) : item.status == 2 ? (
                                                                            <Badge color="danger" pill>
                                                                                <h6 style={{ fontSize: "12px", color: "white", padding: "4px 15px", marginBottom: '0' }}> Failed</h6>
                                                                            </Badge>
                                                                        ) : item.status == 0 ? (
                                                                            <Badge color="primary" pill>
                                                                                <h6 style={{ fontSize: "12px", color: "white", padding: "4px 15px", marginBottom: '0' }}>Pending</h6>
                                                                            </Badge>
                                                                        ) : null}
                                                            </div>
                                                        </Col>
                                                    </Row>



                                                    {timeline && timeline.length > 0 &&
                                                        <div style={{ marginTop: 20 }}>
                                                            <Row>
                                                                <Col style={{ padding: "0" }}>
                                                                    <h5 style={{ fontSize: 16, fontWeight: "600", marginBottom: "6px", color: "black" }}>
                                                                        Timeline
                                                                    </h5>
                                                                </Col>
                                                            </Row>
                                                            {
                                                                <div className="timeline">
                                                                    {timeline.map((tl, index) => {
                                                                        return (
                                                                            <TimeLineItem index={index} title={tl.title} subtitle={tl.subtitle} date={moment(tl.at_date).format('DD-MM-YYYY HH:mm')}>
                                                                            </TimeLineItem>
                                                                        )
                                                                    })}
                                                                </div>
                                                            }

                                                        </div>
                                                    }
                                                    {/* {!disabled_refund ?
                                                        <Row className="mt-4 px-0">
                                                            <Col className="px-0">
                                                                {item.status == 1 ? (
                                                                    <div>
                                                                        {(merchant_id == item.to_customer_id && item.type == 3) ||
                                                                            (merchant_id == item.to_customer_id && item.type == 4) ? (
                                                                            item?.refund_status == 0 ? (
                                                                                <>
                                                                                    {refundBtn ? (
                                                                                        <Button
                                                                                            className="my-0 ml-auto"
                                                                                            color="primary"
                                                                                            size="sm"
                                                                                            outline
                                                                                            style={{
                                                                                                borderRadius: "3px",
                                                                                            }}
                                                                                            onClick={() => toggle(item)}
                                                                                        >
                                                                                            REFUND THIS TRANSACTION
                                                                                        </Button>
                                                                                    ) : null}
                                                                                </>
                                                                            ) : null
                                                                        ) : null}
                                                                    </div>
                                                                ) : null}
                                                            </Col>
                                                        </Row> : null
                                                    } */}

                                                </CardBody>
                                            ) : (
                                                {/* <Col>
                                                {" "}
                                                <Row>
                                                    <Col style={{ padding: "0" }}>
                                                        <h5 style={{ fontSize: 16, fontWeight: "600", marginBottom: "26px", color: "black" }}>
                                                            {" "}
                                                            {t('transaction.transaction_detail.transaction_details')}{" "}
                                                        </h5>
                                                    </Col>
                                                </Row>
                                                <Row style={{ border: "1px solid #D4D4D3" }}>
                                                    <Col
                                                        md={3}
                                                        style={{ backgroundColor: "#E5E8E8", padding: "13px 13px", color: "black", fontWeight: "500" }}
                                                    >
                                                        {t('transaction.transaction_detail.transaction_id')}
                                                    </Col>
                                                    <Col style={{ padding: "13px 13px", fontSize: "14px", color: "black" }}>
                                                        {" "}
                                                        {item.transaction_no}
                                                    </Col>
                                                </Row>
                                                <Row style={{ border: "1px solid #D4D4D3" }}>
                                                    <Col
                                                        md={3}
                                                        style={{ backgroundColor: "#E5E8E8", padding: "13px 13px", color: "black", fontWeight: "500" }}
                                                    >
                                                        {t('transaction.transaction_detail.date_time')}
                                                    </Col>
                                                    <Col style={{ padding: "13px 13px", fontSize: "14px", color: "black" }}>
                                                        {" "}
                                                        {render_date(item.status_update_date)}
                                                    </Col>
                                                </Row>
                                                <Row style={{ border: "1px solid #D4D4D3" }}>
                                                    <Col
                                                        md={3}
                                                        style={{ backgroundColor: "#E5E8E8", padding: "13px 13px", color: "black", fontWeight: "500" }}
                                                    >
                                                        {t('transaction.transaction_detail.message')}
                                                    </Col>
                                                    <Col style={{ padding: "13px 13px", fontSize: "14px", color: "black" }}>
                                                        {" "}
                                                        {item.note?.length > 0 ? item.note : "NA"}
                                                    </Col>
                                                </Row>
                                                <Row style={{ border: "1px solid #D4D4D3" }}>
                                                    <Col
                                                        md={3}
                                                        style={{ backgroundColor: "#E5E8E8", padding: "13px 13px", color: "black", fontWeight: "500" }}
                                                    >
                                                        <div style={{ position: "relative", top: "4px" }}>															{t('transaction.transaction_detail.transaction_status')}</div>
                                                    </Col>
                                                    <Col style={{ padding: "13px 13px", fontSize: "14px", color: "black" }}>
                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                                            {item.status == 1 ? (
                                                                <Badge color="success" pill style={{ marginRight: "10px" }}>
                                                                    <h6 style={{ fontSize: "12px", color: "white", padding: "4px 15px" }}>{t('transaction.transaction_detail.success')}</h6>
                                                                </Badge>
                                                            ) : item.status == 2 ? (
                                                                <Badge color="danger" pill>
                                                                    <h6 style={{ fontSize: "12px", color: "white", padding: "4px 15px" }}> {t('transaction.transaction_detail.Failed')}</h6>
                                                                </Badge>
                                                            ) : item.status == 0 ? (
                                                                <Badge color="primary" pill>
                                                                    <h6 style={{ fontSize: "12px", color: "white", padding: "4px 15px" }}>{t('transaction.transaction_detail.pending')}</h6>
                                                                </Badge>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col> */}
                                            )}
                                        </Card>

                                    </Col>

                                    <Col md={5} lg={5} className="m-0 px-0 pb-5 " >
                                        <Card>
                                            <CardBody style={{ flex: "unset", height: 'unset', minHeight: 'unset' }}>
                                                <Row>
                                                    <Col style={{}}>
                                                        <h5 style={{ fontSize: 16, fontWeight: "600", marginBottom: "26px", color: "black" }}>
                                                            Sender details
                                                        </h5>
                                                    </Col>
                                                </Row>
                                                <div style={{ border: '1px solid #D4D4D3' }}>
                                                    <h5 style={{ padding: '13px', fontWeight: "600", color: "black", fontSize: '14px', marginBottom: '0' }}>Received from</h5>

                                                    <div style={{ display: 'flex', }}>

                                                        <Col md={6} style={{ padding: "13px", color: "black", fontWeight: "500" }}>
                                                            <div style={{ display: 'flex' }}>
                                                                <div className='topbar__avatar-img mr-2'>
                                                                    {/* <span style={{ position: "relative", top: "6px" }}> <Icon path={mdiCreditCardOutline} size={1.3} /> </span> */}
                                                                    {item.payment_method == 1 ? <span style={{ position: "relative", top: "6px" }}> <Icon path={mdiCreditCardOutline} size={1.3} /> </span> : gitSenderimage(item)}


                                                                </div>
                                                                <span style={{ alignSelf: 'center', fontSize: '13px' }}>
                                                                    {paymentMethod(item.payment_method, item.card_number)}
                                                                    {/* METHOD */}
                                                                </span>
                                                            </div>
                                                        </Col>
                                                        <Col style={{ padding: "6px", fontSize: "14px", color: "black", alignSelf: 'center', fontWeight: "600" }}>
                                                            {transactionObject.from}
                                                            
                                                            {item.amount_receiver && item.amount_payer.currency.code != item.amount_receiver.currency.code &&
                                                                <>
                                                                    <span
                                                                        id={`Popover_received_from${item._id}`}
                                                                        style={{ marginLeft: "5px", cursor: "pointer" }}
                                                                        onMouseEnter={() => setshowReceiverInfo(true)}
                                                                        onMouseLeave={() => setshowReceiverInfo(false)}
                                                                    >
                                                                        <i style={{ color: "grey" }} className="fa fa-info-circle"></i>
                                                                    </span>
                                                                    {showReceiverInfo &&
                                                                        <Popover
                                                                            placement={"right"}
                                                                            isOpen={showReceiverInfo}
                                                                            target={`Popover_received_from${item._id}`}
                                                                            toggle={() => setshowReceiverInfo(!showReceiverInfo)}
                                                                        >
                                                                            <PopoverBody>
                                                                                <p style={{ marginBottom: 0, fontSize : 12 }}>

                                                                                    Amount converted :{transactionObject.total} [Exchange Rate 1{item.amount_receiver.currency?.code} = {item.amount_payer.exchange_rate}{item.amount_payer.currency?.code}]
															

                                                                                    {/* Amount converted: A$2.00 [Exchange rate: 1AUD= 56.17INR] */}
                                                                                </p>
                                                                                <p style={{ marginBottom: 0, fontSize : 12 }}>
                                                                                    Total paid: {item.amount_payer.currency?.code + "" + item.amount_payer?.amount.toFixed(2)} [{transactionObject.total + `x` + item.amount_payer.exchange_rate}= {item.amount_payer.currency?.symbol + "" + item.amount_payer?.amount.toFixed(2)}]
                                                                                </p>
                                                                            </PopoverBody>
                                                                        </Popover>
                                                                    }
                                                                </>
                                                            }
                                                            {/* $62.5 */}
                                                        </Col>

                                                    </div>
                                                    {item.type != 1 && <div style={{ display: 'flex', borderTop: "1px solid #D4D4D3" }}>
                                                        <Col md={6} style={{ padding: "6px 13px", color: "black", fontWeight: "500" }}>
                                                            Name
                                                        </Col>
                                                        <Col style={{ padding: "6px", fontSize: "14px", color: "black" }}>
                                                            {gitReceiverName(item)}
                                                        </Col>
                                                    </div>}

                                                    {item?.from_customer && <div style={{ display: 'flex' }}>
                                                        <Col md={6} style={{ padding: "6px 13px", color: "black", fontWeight: "500" }}>
                                                            Mobile no
                                                        </Col>
                                                        <Col style={{ padding: "6px", fontSize: "14px", color: "black" }}>
                                                            {convertMobileNumber(item?.from_customers[0]?.mobile)}
                                                        </Col>
                                                    </div>}
                                                </div>

                                            </CardBody>

                                            <CardBody>
                                                <Row>
                                                    <Col >
                                                        <h5 style={{ fontSize: 16, fontWeight: "600", marginBottom: "26px", color: "black" }}>
                                                            Recipient details
                                                        </h5>
                                                    </Col>
                                                </Row>
                                                <div style={{ border: '1px solid #D4D4D3' }}>
                                                    <h5 style={{ padding: '13px', fontWeight: "600", color: "black", fontSize: '14px', marginBottom: '0' }}>
                                                        Paid to
                                                    </h5>
                                                    <div style={{ display: 'flex', }}>
                                                        <Col md={6} style={{ padding: "13px", color: "black", fontWeight: "500" }}>
                                                            <div style={{ display: 'flex' }}>
                                                                <div className='topbar__avatar-img mr-2' >
                                                                    {gitReceiverimage(item)}
                                                                    {/* IMG */}
                                                                </div>
                                                                <span style={{ alignSelf: 'center' }}>
                                                                    {
                                                                        item.to_wallet_type == 2 ? item.to_mechant[0].business_name
                                                                            : item.to_customers?.length > 0
                                                                                ? item.to_customers[0].name
                                                                                : null
                                                                    }
                                                                    {/* NAME HERE */}
                                                                </span>
                                                            </div>
                                                        </Col>
                                                        <Col style={{ padding: "6px", fontSize: "14px", color: "black", alignSelf: 'center', fontWeight: "600" }}>
                                                            {/* {ShowCurrencyNum(item.pay_amount?.toFixed(2), true)} */}
                                                            {transactionObject.to}

                                                            {/* $50.00 */}
                                                        </Col>
                                                    </div>
                                                    {item.to_wallet_type == 2 &&
                                                        <>
                                                            {item.company_abn_number && <div style={{ display: 'flex', borderTop: "1px solid #D4D4D3" }}>
                                                                <Col md={6} style={{ padding: "6px 13px", color: "black", fontWeight: "500" }}>
                                                                    ABN
                                                                </Col>
                                                                <Col style={{ padding: "6px", fontSize: "14px", color: "black" }}>
                                                                    {item.company_abn_number ? item.company_abn_number : '-'}
                                                                </Col>
                                                            </div>}
                                                            {item.from_mechant?.length > 0 && <div style={{ display: 'flex' }}>
                                                                <Col md={6} style={{ padding: "6px 13px", color: "black", fontWeight: "500" }}>
                                                                    Address
                                                                </Col>
                                                                <Col style={{ padding: "6px", fontSize: "14px", color: "black" }}>
                                                                    {item.from_mechant?.length > 0
                                                                        ? item.from_mechant[0].address
                                                                        : item.to_mechant?.length > 0
                                                                            ? item.to_mechant[0].address
                                                                            : "-"}
                                                                </Col>
                                                            </div>}
                                                        </>
                                                    }
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>

                            </>
                        ) : (
                            <div className="w-100 h-100 text-center">
                                <BeatLoader color="#45C7A2" loading={true} size={15} />
                            </div>
                        )}
                    </Container>
                </CardBody>
            </Col>
        </Row>
    )
}

export default ViewTransaction