import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Info, DollarSign, MapPin, X, TrendingDown, ArrowUp, ShoppingCart, Search, Activity, User } from "react-feather";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import six from "../../assets/images/user/6.jpg";
import two from "../../assets/images/user/2.png";
import three from "../../assets/images/user/3.jpg";
import four from "../../assets/images/user/4.jpg";
import five from "../../assets/images/user/5.jpg";
import fifteen from "../../assets/images/user/15.png";
import {
	saleData,
	saleOptions,
	incomeData,
	incomeOptions,
	profitData,
	profitOptions,
	staticData,
	staticOptions,
} from "../../charts-data/default";
import { Line } from "react-chartjs-2";
import CountUp from "react-countup";
import { StaticRouter } from "react-router";
import { Link } from "react-router-dom";
import Countup from "react-countup";
import { Sparklines, SparklinesLine } from 'react-sparklines';
import {
	marketChartData, marketChartOptions, EarningChartOptions, EarningChartData, liveData, skillData, skillOption,
	browserData, browserOption, orderData, orderOption, liveOption, financeOption, financeData, turnoverData, turnoverOption, MonthlySaleData, MonthlySaleOption, usesData, usesOption
} from '../../charts-data/widgets';
import ChartistGraph from 'react-chartist';
import chart1 from "../../assets/images/chart-1.png";
import chart2 from "../../assets/images/chart-2.png";
import cartCustomer from "../../assets/images/cartCustomer.png";
import cartMerchant from "../../assets/images/cartMerchant.png";
import chartCustomerIcon from "../../assets/images/chartCustomerIcon.svg";
import chartMerchantIcon from "../../assets/images/chartMerchantIcon.svg";
import moment from "moment";
import MapData from "./MapData";
import { DisappearedLoading, CommonLoading } from "react-loadingg";
import { UncontrolledTooltip } from "reactstrap";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import {
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
// import faker from 'faker';
import UnderMaintenance from "./UnderMaintenane/UnderMaintenance";
import "../../assets/scss/dashboard.scss";
import SendSMSModal from "../../pages/merchants/sendSMSModal";




var Knob = require("knob"); // browserify require
var primary = localStorage.getItem("primary_color") || "#4466f2";

const Ecommerce = () => {
	const [allData, setAllData] = useState([]);
	const [customersData, setCustomersData] = useState([]);
	const [merchantsData, setMerchantsData] = useState([]);
	const [UnVerifyMerchantsData, setUnVerifyMerchantsData] = useState([]);
	const [transactionsData, setTransactionsData] = useState([]);
	const [SubscriptionData, setSubscriptionData] = useState([]);
	const [offerData, setofferData] = useState([]);
	const [LoyaltyCardData, setLoyaltyCardData] = useState([]);
	const [featuresCount, setFeaturesCount] = useState({});
	const [dashboardData, setDashboardData] = useState(null);
	const [countryData, setCountryData] = useState(null);
	let CurrentDates = moment(new Date()).format("DD-MM-YYYY");
	let [loading, setloading] = useState(true);
	const [smsModalClick, setSmsModalClick] = useState(false);

	const toggleSMSModal = () => {
		setSmsModalClick(!smsModalClick);
	};

	const [settings, setSettings] = useState({
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		// variableWidth:true,
		arrows: false,
		autoplay: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 370,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	});

	var makeDate = new Date();
	let CurntMon = new Date(makeDate.setMonth(makeDate.getMonth()));
	let curent_month = moment(CurntMon).format("MM");
	let LastMon = new Date(makeDate.setMonth(makeDate.getMonth() - 1));
	let last_month = moment(LastMon).format("MM");
	let CurentYear = moment(makeDate).format("YYYY");

	const country_code = localStorage.getItem("sessionCountryCode") || "AU";

	// const getCustomers = (fetchType, is_filter) => {
	// 	let postdata = { type: fetchType, searchValue: this.state.searchValue };
	// 	let current = this;
	// 	if (!is_filter) current.setState({ loading: true });
	// 	else {
	// 		current.setState({ searchLoading: true });
	// 	}

	// 	client.post(api.merchant_request_list, postdata, async function (error, response) {
	// 		if (!error) {
	// 			// alert(JSON.stringify(response));
	// 			if (typeof response.error !== "undefined") {
	// 				try {
	// 					if (!response.error) {
	// 						//console.log(response.result.data.reverse());
	// 						current.setState({ [fetchType]: response.result.data.reverse() });
	// 					} else {
	// 						// setTimeout(() => {
	// 						toast.error(response.message);
	// 						// }, 200);
	// 					}
	// 				} catch (e) {
	// 					// setTimeout(() => {}, 200);
	// 					toast.error("Internal error occured. Please contact support");
	// 					// _showToast.error("Internal error occured. Please contact support.");
	// 					// currentComponent.setState({ errormessage: "Internal error." });
	// 				}
	// 			} else {
	// 				// setTimeout(() => {
	// 				toast.error("Internal error occured. Please contact support");
	// 				// }, 200);
	// 				//   _showToast.error("Internal error occured. Please contact support.");
	// 			}
	// 		} else {
	// 			// setTimeout(() => {
	// 			toast.error("Internal error occured. Please contact support");
	// 			// }, 200);
	// 			// alert(JSON.stringify(error));
	// 			// _showToast.error("Internal error occured. Please contact support.");
	// 		}
	// 		current.setState({ loading: false, searchLoading: false });
	// 	});
	// };

	useEffect(() => {
		var profit = Knob({
			value: 35,
			left: 1,
			angleOffset: 90,
			className: "review",
			thickness: 0.1,
			width: 285,
			height: 285,
			fgColor: primary,
			bgColor: "#f6f7fb",
			lineCap: "round",
		});
		getAllData();

		//document.getElementById('profit').appendChild(profit)
	}, []);

	const getCurrency = (code) => {
		return countryData?.find(e => e?.country_code == code)?.currency_symbol || "A$"
	}

	var fetchCountries = () => {
		setloading(true);
		client.post(api.fetch_country, { show_all: true }, async function (error, response) {
			// console.log(response)
			if (!error) {
				if (typeof response.error !== "undefined") {
					try {
						if (!response.error) {
							setCountryData(response.result.data);
						}
					} catch {

					}
				}
			}
			// setloading(false);
		});
	}

	useEffect(() => {
		fetchCountries()
	}, [])

	const aa = (merchants, featuresData) => {
		const mergedData = merchants.map(merchant => {
			const featureData = featuresData.find(data => data?.merchant_ref === merchant._id);
			return { ...merchant, ...featureData };
		});

		console.log(mergedData, "mergedData")

		const featureUsageCount = {
			multi_user: 0,
			online_orders: 0,
			table_reservation: 0,
			offers_vouchers: 0,
			loyalty_cards: 0,
			payment_links: 0,
			price_list: 0,
			visitor_reg: 0,
			linky_scan: 0,
			invoice_quote: 0,
			sell_ticket: 0,
			linky_meet: 0,
			linky_review_booster: 0,
			xero_integration: 0
		};

		mergedData.forEach(merchant => {
			for (const feature in featureUsageCount) {
				if (merchant[feature]) {
					featureUsageCount[feature]++;
				}
			}
		});

		// const totalMerchants = mergedData.length;
		// const featureUsagePercentage = {};
		// for (const feature in featureUsageCount) {
		// 	featureUsagePercentage[feature] = ((featureUsageCount[feature] / totalMerchants) * 100).toFixed(2) + '%';
		// }

		setFeaturesCount(featureUsageCount);

		// console.log('Feature Usage Percentage:');
		// console.log(featureUsagePercentage, featureUsageCount);
	}

	const getAllDataN = () => {
		client.get(api.getAllDataN + `?country_code=${country_code}`, {}, (error, response) => {
			if (!error) {
				try {
					if (!response.error) {
						setDashboardData(response.data);
					} else {
						setTimeout(() => {
							toast.error(response.message);
						}, 200);
					}
				} catch (e) {
					setTimeout(() => {
						toast.error("Internal error occured. Please contact support" + e.message);
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
			//   current.setState({ loading: false });
			// setloading(false);
		});
	};

	useEffect(() => { if (!dashboardData) getAllDataN() }, [])

	const getAllData = () => {
		let postdata = {};
		client.post(api.getAllData, postdata, (error, response) => {
			if (!error) {
				try {
					if (!response.error) {
						setAllData(response.result.data);
						setMerchantsData(response.result.merchants);
						setCustomersData(response.result.customers);
						setTransactionsData(response.result.transactions);
						setSubscriptionData(response.result.subscription);
						setofferData(response.result.offers);
						setLoyaltyCardData(response.result.LoyaltyCard);
						setUnVerifyMerchantsData(response.result.Unverifymerchants);
						// console.log(aa(response.result.merchants, response.result.featureData))
					} else {
						setTimeout(() => {
							toast.error(response.message);
						}, 200);
					}
				} catch (e) {
					setTimeout(() => {
						toast.error("Internal error occured. Please contact support" + e.message);
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
			//   current.setState({ loading: false });
			setloading(false);
		});
	};

	const topup = transactionsData.filter((el) => {
		return el.type == 1;
	});

	const multiUser = SubscriptionData.filter((el) => {
		return el.multi_user == true;
	});

	const tableReservation = SubscriptionData.filter((el) => {
		return el.table_reservation == true;
	});

	let LoyaltyCardFinal = LoyaltyCardData.map((data) => {
		data.validTillFinal = moment(data.validTill).format("DD-MM-YYYY");
		return data;
	});

	const LoyaltyCard = LoyaltyCardFinal.filter((el) => {
		return el.validTillFinal >= CurrentDates;
	});

	const offerVouchers = offerData.filter((el) => {
		return el.status == 1 && el.verified == 1;
	});

	const totalTopupAmount = topup
		.map((el) => {
			return el.amount;
		})
		.reduce((amount, nextAmount) => {
			return amount + nextAmount;
		}, 0);

	const topupCardAmount = transactionsData
		.filter((el) => {
			return (el.type == 1 && el.topup_type != 2) || (el.type == 1 && el.topup_type != 3);
		})
		.map((topup) => {
			return topup.amount;
		})
		.reduce((amt, nextAmt) => {
			return amt + nextAmt;
		}, 0);

	const topupCardAmountChart = transactionsData
		.filter((el) => {
			return (el.type == 1 && el.topup_type != 2) || (el.type == 1 && el.topup_type != 3);
		})
		.map((topup) => {
			topup.transaction_month = moment(topup.transaction_date).format("MM");
			topup.transaction_year = moment(topup.transaction_date).format("YYYY");
			return topup;
		});

	// let topupMonthlySaleData = {
	// 	labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
	// 	series: [
	// 		[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
	// 	],
	// }

	let topupMonthlySaleData = {
		labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
		datasets: [{
			label: '',
			data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			backgroundColor: 'rgb(30, 166, 236,  1)',
		}]
	};

	topupCardAmountChart.map((data) => {
		var today = new Date();
		var yyyy = today.getFullYear();
		if (data.transaction_year == yyyy) {
			if (data.transaction_month == 1) {
				topupMonthlySaleData.datasets[0].data[0] = Math.round(topupMonthlySaleData.datasets[0].data[0] + data.amount);
			} else if (data.transaction_month == 2) {
				topupMonthlySaleData.datasets[0].data[1] = Math.round(topupMonthlySaleData.datasets[0].data[1] + data.amount);
			} else if (data.transaction_month == 3) {
				topupMonthlySaleData.datasets[0].data[2] = Math.round(topupMonthlySaleData.datasets[0].data[2] + data.amount);
			} else if (data.transaction_month == 4) {
				topupMonthlySaleData.datasets[0].data[3] = Math.round(topupMonthlySaleData.datasets[0].data[3] + data.amount);
			} else if (data.transaction_month == 5) {
				topupMonthlySaleData.datasets[0].data[4] = Math.round(topupMonthlySaleData.datasets[0].data[4] + data.amount);
			} else if (data.transaction_month == 6) {
				topupMonthlySaleData.datasets[0].data[5] = Math.round(topupMonthlySaleData.datasets[0].data[5] + data.amount);
			} else if (data.transaction_month == 7) {
				topupMonthlySaleData.datasets[0].data[6] = Math.round(topupMonthlySaleData.datasets[0].data[6] + data.amount);
			} else if (data.transaction_month == 8) {
				topupMonthlySaleData.datasets[0].data[7] = Math.round(topupMonthlySaleData.datasets[0].data[7] + data.amount);
			} else if (data.transaction_month == 9) {
				topupMonthlySaleData.datasets[0].data[8] = Math.round(topupMonthlySaleData.datasets[0].data[8] + data.amount);
			} else if (data.transaction_month == 10) {
				topupMonthlySaleData.datasets[0].data[9] = Math.round(topupMonthlySaleData.datasets[0].data[9] + data.amount);
			} else if (data.transaction_month == 11) {
				topupMonthlySaleData.datasets[0].data[10] = Math.round(topupMonthlySaleData.datasets[0].data[10] + data.amount);
			} else if (data.transaction_month == 12) {
				topupMonthlySaleData.datasets[0].data[11] = Math.round(topupMonthlySaleData.datasets[0].data[11] + data.amount);
			} else {
				console.log("something error of refund chart");
			}
		}
	});

	const topupPayIDAmount = transactionsData
		.filter((el) => {
			return el.type == 1 && el.topup_type == 3;
		})
		.map((topup) => {
			return topup.amount;
		})
		.reduce((amt, nextAmt) => {
			return amt + nextAmt;
		}, 0);

	const withdrawAmount = transactionsData
		.filter((el) => {
			return el.type == 4;
		})
		.map((topup) => {
			return topup.amount;
		})
		.reduce((amt, nextAmt) => {
			return amt + nextAmt;
		}, 0);

	const withdrawAmountChart = transactionsData
		.filter((el) => {
			return el.type == 4;
		})
		.map((topup) => {
			topup.transaction_month = moment(topup.transaction_date).format("MM");
			topup.transaction_year = moment(topup.transaction_date).format("YYYY");
			return topup;
		});

	// let withdrawMonthlySaleData = {
	// 	labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
	// 	series: [
	// 		[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
	// 	],
	// }

	let withdrawMonthlySaleData = {
		labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
		datasets: [{
			label: '',
			data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			backgroundColor: 'rgb(30, 166, 236,  1)',
		}]
	};

	withdrawAmountChart.map((data) => {
		var today = new Date();
		var yyyy = today.getFullYear();
		if (data.transaction_year == yyyy) {
			if (data.transaction_month == 1) {
				withdrawMonthlySaleData.datasets[0].data[0] = Math.round(withdrawMonthlySaleData.datasets[0].data[0] + data.amount);
			} else if (data.transaction_month == 2) {
				withdrawMonthlySaleData.datasets[0].data[1] = Math.round(withdrawMonthlySaleData.datasets[0].data[1] + data.amount);
			} else if (data.transaction_month == 3) {
				withdrawMonthlySaleData.datasets[0].data[2] = Math.round(withdrawMonthlySaleData.datasets[0].data[2] + data.amount);
			} else if (data.transaction_month == 4) {
				withdrawMonthlySaleData.datasets[0].data[3] = Math.round(withdrawMonthlySaleData.datasets[0].data[3] + data.amount);
			} else if (data.transaction_month == 5) {
				withdrawMonthlySaleData.datasets[0].data[4] = Math.round(withdrawMonthlySaleData.datasets[0].data[4] + data.amount);
			} else if (data.transaction_month == 6) {
				withdrawMonthlySaleData.datasets[0].data[5] = Math.round(withdrawMonthlySaleData.datasets[0].data[5] + data.amount);
			} else if (data.transaction_month == 7) {
				withdrawMonthlySaleData.datasets[0].data[6] = Math.round(withdrawMonthlySaleData.datasets[0].data[6] + data.amount);
			} else if (data.transaction_month == 8) {
				withdrawMonthlySaleData.datasets[0].data[7] = Math.round(withdrawMonthlySaleData.datasets[0].data[7] + data.amount);
			} else if (data.transaction_month == 9) {
				withdrawMonthlySaleData.datasets[0].data[8] = Math.round(withdrawMonthlySaleData.datasets[0].data[8] + data.amount);
			} else if (data.transaction_month == 10) {
				withdrawMonthlySaleData.datasets[0].data[9] = Math.round(withdrawMonthlySaleData.datasets[0].data[9] + data.amount);
			} else if (data.transaction_month == 11) {
				withdrawMonthlySaleData.datasets[0].data[10] = Math.round(withdrawMonthlySaleData.datasets[0].data[10] + data.amount);
			} else if (data.transaction_month == 12) {
				withdrawMonthlySaleData.datasets[0].data[11] = Math.round(withdrawMonthlySaleData.datasets[0].data[11] + data.amount);
			} else {
				console.log("something error of refund chart");
			}
		}
	});

	const withdrawAmountPendAmount = transactionsData
		.filter((el) => {
			return el.type == 4 && el.withdraw_status == 0;
		})
		.map((topup) => {
			return topup.amount;
		})
		.reduce((amt, nextAmt) => {
			return amt + nextAmt;
		}, 0);

	const withdrawAmountPending = transactionsData.filter((el) => {
		return el.type == 4 && el.withdraw_status == 0;
	});

	const EarningsAmount = transactionsData
		.filter((el) => {
			return el.charges != 0;
		})
		.map((topup) => {
			return topup.charges;
		})
		.reduce((amt, nextAmt) => {
			return amt + nextAmt;
		}, 0);

	const EarningsAmt = transactionsData
		.filter((el) => {
			return el.charges != 0;
		})
		.map((topup) => {
			topup.transaction_month = moment(topup.transaction_date).format("MM");
			topup.transaction_year = moment(topup.transaction_date).format("YYYY");
			return topup;
		});

	console.log(EarningsAmt);

	const MonthlySaleData = {
		labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
		datasets: [{
			label: '',
			data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			backgroundColor: 'rgb(30, 166, 236,  1)',
		}]
	};

	const marketChartOptions = {
		legend: {
			display: false
		},
		plugins: {
			datalabels: {
				formatter: (value, ctx) => {
					let sum = 0;
					let dataArr = ctx.chart.data.datasets[0].data;
					dataArr.map(data => {
						sum += data;
					});
					let percentage = (value * 100 / sum).toFixed(2) + "%";
					return '';
				},
				color: '#fff',
			}
		},
		height: 150,

	}

	EarningsAmt.map((data) => {
		var today = new Date();
		var yyyy = today.getFullYear();
		if (data.transaction_year == yyyy) {
			if (data.transaction_month == 1) {
				MonthlySaleData.datasets[0].data[0] = Math.round(MonthlySaleData.datasets[0].data[0] + data.charges);
			} else if (data.transaction_month == 2) {
				MonthlySaleData.datasets[0].data[1] = Math.round(MonthlySaleData.datasets[0].data[1] + data.charges);
			} else if (data.transaction_month == 3) {
				MonthlySaleData.datasets[0].data[2] = Math.round(MonthlySaleData.datasets[0].data[2] + data.charges);
			} else if (data.transaction_month == 4) {
				MonthlySaleData.datasets[0].data[3] = Math.round(MonthlySaleData.datasets[0].data[3] + data.charges);
			} else if (data.transaction_month == 5) {
				MonthlySaleData.datasets[0].data[4] = Math.round(MonthlySaleData.datasets[0].data[4] + data.charges);
			} else if (data.transaction_month == 6) {
				MonthlySaleData.datasets[0].data[5] = Math.round(MonthlySaleData.datasets[0].data[5] + data.charges);
			} else if (data.transaction_month == 7) {
				MonthlySaleData.datasets[0].data[6] = Math.round(MonthlySaleData.datasets[0].data[6] + data.charges);
			} else if (data.transaction_month == 8) {
				MonthlySaleData.datasets[0].data[7] = Math.round(MonthlySaleData.datasets[0].data[7] + data.charges);
			} else if (data.transaction_month == 9) {
				MonthlySaleData.datasets[0].data[8] = Math.round(MonthlySaleData.datasets[0].data[8] + data.charges);
			} else if (data.transaction_month == 10) {
				MonthlySaleData.datasets[0].data[9] = Math.round(MonthlySaleData.datasets[0].data[9] + data.charges);
			} else if (data.transaction_month == 11) {
				MonthlySaleData.datasets[0].data[10] = Math.round(MonthlySaleData.datasets[0].data[10] + data.charges);
			} else if (data.transaction_month == 12) {
				MonthlySaleData.datasets[0].data[11] = Math.round(MonthlySaleData.datasets[0].data[11] + data.charges);
			} else {
				console.log("something error of refund chart");
			}
		}
	});

	const IpaychargesInfo = transactionsData
		.filter((el) => {
			return el.charges_info != '';
		})
		.map((topup) => {
			const typeChange = topup.charges_info;
			return (
				typeChange
					.filter((el) => {
						return el.title == 'Online Trans Fees';
					})
					.map((typeChange) => {
						return typeChange.amount;
					})
					.reduce((amt, nextAmt) => {
						return amt + nextAmt;
					}, 0)
			)
		});

	const TransactionInfo = IpaychargesInfo
		.reduce((amt, nextAmt) => {
			return amt + nextAmt;
		}, 0)


	const chargesInfoService = transactionsData
		.filter((el) => {
			return el.charges_info != '';
		})
		.map((topup) => {
			const typeChange = topup.charges_info;
			return (
				typeChange
					.filter((el) => {
						return el.title == 'Service Fees';
					})
					.map((typeChange) => {
						return typeChange.amount;
					})
					.reduce((amt, nextAmt) => {
						return amt + nextAmt;
					}, 0)
			)
		});

	const ServiceInfoCharge = chargesInfoService
		.reduce((amt, nextAmt) => {
			return amt + nextAmt;
		}, 0)

	const chargesInfoCommission = transactionsData
		.filter((el) => {
			return el.charges_info != '';
		})
		.map((topup) => {
			const typeChange = topup.charges_info;
			return (
				typeChange
					.filter((el) => {
						return el.title == 'Commission';
					})
					.map((typeChange) => {
						return typeChange.amount;
					})
					.reduce((amt, nextAmt) => {
						return amt + nextAmt;
					}, 0)
			)
		});

	const CommissionInfoCharge = chargesInfoCommission
		.reduce((amt, nextAmt) => {
			return amt + nextAmt;
		}, 0)



	const CashbackAmount = transactionsData
		.filter((el) => {
			return el.type == 2;
		})
		.map((topup) => {
			return topup.amount;
		})
		.reduce((amt, nextAmt) => {
			return amt + nextAmt;
		}, 0);

	const CashbackAmountChart = transactionsData
		.filter((el) => {
			return el.type == 2;
		})
		.map((topup) => {
			topup.transaction_month = moment(topup.transaction_date).format("MM");
			topup.transaction_year = moment(topup.transaction_date).format("YYYY");
			return topup;
		});

	// let CashbackMonthlySaleData = {
	// 	labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
	// 	series: [
	// 		[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
	// 	],
	// }

	let CashbackMonthlySaleData = {
		labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
		datasets: [{
			label: '',
			data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			backgroundColor: 'rgb(30, 166, 236,  1)',
		}]
	};

	CashbackAmountChart.map((data) => {
		var today = new Date();
		var yyyy = today.getFullYear();
		if (data.transaction_year == yyyy) {
			if (data.transaction_month == 1) {
				CashbackMonthlySaleData.datasets[0].data[0] = Math.round(CashbackMonthlySaleData.datasets[0].data[0] + data.amount);
			} else if (data.transaction_month == 2) {
				CashbackMonthlySaleData.datasets[0].data[1] = Math.round(CashbackMonthlySaleData.datasets[0].data[1] + data.amount);
			} else if (data.transaction_month == 3) {
				CashbackMonthlySaleData.datasets[0].data[2] = Math.round(CashbackMonthlySaleData.datasets[0].data[2] + data.amount);
			} else if (data.transaction_month == 4) {
				CashbackMonthlySaleData.datasets[0].data[3] = Math.round(CashbackMonthlySaleData.datasets[0].data[3] + data.amount);
			} else if (data.transaction_month == 5) {
				CashbackMonthlySaleData.datasets[0].data[4] = Math.round(CashbackMonthlySaleData.datasets[0].data[4] + data.amount);
			} else if (data.transaction_month == 6) {
				CashbackMonthlySaleData.datasets[0].data[5] = Math.round(CashbackMonthlySaleData.datasets[0].data[5] + data.amount);
			} else if (data.transaction_month == 7) {
				CashbackMonthlySaleData.datasets[0].data[6] = Math.round(CashbackMonthlySaleData.datasets[0].data[6] + data.amount);
			} else if (data.transaction_month == 8) {
				CashbackMonthlySaleData.datasets[0].data[7] = Math.round(CashbackMonthlySaleData.datasets[0].data[7] + data.amount);
			} else if (data.transaction_month == 9) {
				CashbackMonthlySaleData.datasets[0].data[8] = Math.round(CashbackMonthlySaleData.datasets[0].data[8] + data.amount);
			} else if (data.transaction_month == 10) {
				CashbackMonthlySaleData.datasets[0].data[9] = Math.round(CashbackMonthlySaleData.datasets[0].data[9] + data.amount);
			} else if (data.transaction_month == 11) {
				CashbackMonthlySaleData.datasets[0].data[10] = Math.round(CashbackMonthlySaleData.datasets[0].data[10] + data.amount);
			} else if (data.transaction_month == 12) {
				CashbackMonthlySaleData.datasets[0].data[11] = Math.round(CashbackMonthlySaleData.datasets[0].data[11] + data.amount);
			} else {
				console.log("something error of refund chart");
			}
		}
	});

	const VerifiedCostomer = customersData.filter((el) => {
		return el.status == 1;
	});


	const topupBankTransferAmount = transactionsData
		.filter((el) => {
			return el.type == 1 && el.topup_type == 2;
		})
		.map((topup) => {
			return topup.amount;
		})
		.reduce((amt, nextAmt) => {
			return amt + nextAmt;
		}, 0);

	const withdrawRequest = transactionsData.filter((el) => {
		return el.type == 4;
	});

	const totalWithdrawRequestAmount = withdrawRequest
		.map((el) => {
			return el.amount;
		})
		.reduce((item, nextItem) => {
			return item + nextItem;
		}, 0);

	const pendingVerificationCust = customersData.filter((el) => {
		return el.status == 1 && el.is_verified == 2;
	});
	const pendingVerificationMerc = merchantsData.filter((el) => {
		return el.is_verified == 2;
	});

	const Merchmarker = merchantsData
		.filter((el) => {
			return el.location['latitude'] || el.location['longitude']
		})
		.map((el) => {
			return { key: el._id, position: [el.location.latitude, el.location.longitude], content: el.name }
		});

	const MerchantMarker = [...Merchmarker]

	const Custhmarker = customersData
		.filter((el) => {
			return el.location['latitude'] || el.location['longitude']
		})
		.map((el) => {
			return { key: el._id, position: [el.location.latitude, el.location.longitude], content: el.name }
		});
	const CustomerMarker = [...Custhmarker]

	const options = {
		// donut: true,
		// donutWidth: 40,
		// height: 300,
		// donutSolid: true,
		// showLabel: true,
	};

	const hexArray = [
		"#FF0000", // Red
		"#00FF00", // Lime
		"#0000FF", // Blue
		"#FFFF00", // Yellow
		"#FF00FF", // Magenta
		"#00FFFF", // Cyan
		"#800000", // Maroon
		"#008000", // Green
		"#000080", // Navy
		"#808000", // Olive
		"#800080", // Purple
		"#008080", // Teal
		"#C0C0C0", // Silver
		"#808080", // Gray
		// "#FFA500", // Orange
		// "#FFFF80", // Light Yellow
		// "#00FF80"  // Light Green
	];

	const featuresData = ['Multiuser', 'Online Ordering System', 'Booking Management', 'Offers & vouchers', 'Loyalty Cards', 'Visitor Registry', 'Create Payment Links', 'Price lists', 'Linky Scan', 'Invoices & Quotes', 'Events & Tickets', 'Linky Meet', 'Review Booster', 'Xero Integration'];
	const featuresDataKey = ['multi_user', 'online_orders', 'table_reservation', 'offers_vouchers', 'loyalty_cards', 'visitor_reg', 'payment_links', 'price_list', 'linky_scan', 'invoice_quote', 'sell_ticket', 'linky_meet', 'linky_review_booster', 'xero_integration'];

	const browserData = {
		// labels: ['Visitor registry', 'Multi User', 'Online Website', 'Table reservation', 'Offers & Vouchers', 'Loyalty cards', 'Payment links', 'POS'],
		datasets: [
			{
				value: featuresData,
				// value: ['Visitor registry', 'Multi User', 'Online Website', 'Table reservation', 'Offers & Vouchers', 'Loyalty cards', 'Payment links', 'POS'],
				data: featuresDataKey.map(e => featuresCount[e]),
				// [18, multiUser.length, 28, 4, offerVouchers.length, 2, 10, 7, 5, 5, 5, 5, 5, 5],
				backgroundColor: hexArray,
				borderColor: hexArray,
				color: hexArray,
				borderWidth: 1,
			},
		],
	};

	const option = {
		showLabel: false,
		// maintainAspectRatio: false,
		tooltips: {
			callbacks: {
				label: function (tooltipItem, data) {
					var dataset = data.datasets[tooltipItem.datasetIndex];
					var meta = dataset._meta[Object.keys(dataset._meta)[0]];
					var total = meta.total;
					var currentValue = dataset.data[tooltipItem.index];
					var percentage = parseFloat((currentValue / total * 100).toFixed(1));
					//return currentValue + ' (' + percentage + '%)';
					return dataset.value[tooltipItem.index] + ': ' + currentValue;
				},
				title: function (tooltipItem, data) {
					return data.labels[tooltipItem[0].index];
				}
			}
		},
		plugins: {
			datalabels: {
				formatter: (value, ctx) => {
					let sum = 0;
					let dataArr = ctx.chart.data.datasets[0].data;
					dataArr.map(data => {
						sum += data;
					});
					let percentage = (value * 100 / sum).toFixed(2) + "%";
					return '';
				},
				color: '#fff',
			}
		},
	}

	// const browserData = {
	// 	labels: ["8%", multiUser.length+"%", "30%", tableReservation.length+"%", offerVouchers.length+"%", LoyaltyCard.length+"%", "22%", "15%"],
	// 	series: [8, multiUser.length, 30, tableReservation.length, offerVouchers.length, LoyaltyCard.length, 22, 15],
	// 	color: ['#ffaec9', '#007bff', '#0dcaf0', '#00cccc', '#adb2bd', '#f7af44', '#1ab668', '#ffaec9']
	// }

	let customechartData = customersData.map((data) => {
		data.month = moment(data.added_date).format("MM");
		data.year = moment(data.added_date).format("YYYY");
		return data;
	});


	const data = {
		labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
		datasets: [
			{
				label: 'Dataset 1',
				data: [65, 59, 80, 81, 56, 55, 40],
				borderColor: 'rgb(255, 99, 132)',
				backgroundColor: 'rgba(255, 99, 132, 0.5)',
			},
		],
	};

	const optionsss = {
		responsive: true,
		plugins: {
			legend: {
				//position: 'top' as const,
			},
			title: {
				display: true,
				text: 'Chart.js Line Chart',
			},
		},
		legend: {
			display: false
		},
		plugins: {
			datalabels: {
				formatter: (value, ctx) => {
					let sum = 0;
					let dataArr = ctx.chart.data.datasets[0].data;
					dataArr.map(data => {
						sum += data;
					});
					let percentage = (value * 100 / sum).toFixed(2) + "%";
					return '';
				},
				color: '#fff',
			}
		},
		height: 150,
	};

	let CustomerliveData = {
		labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
		datasets: [
			{
				label: '',
				data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
				fill: true,
				tension: 0.1,
				backgroundColor: 'rgb(117 154 204 / 37%)',
				borderColor: 'rgb(117 154 204)',
			},
		],
	};

	const op1 = [];
	const op2 = [];
	const op3 = [];
	const op4 = [];
	const op5 = [];
	const op6 = [];
	const op7 = [];
	const op8 = [];
	const op9 = [];
	const op10 = [];
	const op11 = [];
	const op12 = [];
	customechartData.map((data, index) => {
		var today = new Date();
		var yyyy = today.getFullYear();
		if (data.year == yyyy) {
			if (data.month == 1) {
				op1.push([data.name])
				CustomerliveData.datasets[0].data[0] = op1.length;
			} else if (data.month == 2) {
				op2.push([data.name])
				CustomerliveData.datasets[0].data[1] = op2.length;
			} else if (data.month == 3) {
				op3.push([data.name])
				CustomerliveData.datasets[0].data[2] = op3.length;
			} else if (data.month == 4) {
				op4.push([data.name])
				CustomerliveData.datasets[0].data[3] = op4.length;
			} else if (data.month == 5) {
				op5.push([data.name])
				CustomerliveData.datasets[0].data[4] = op5.length;
			} else if (data.month == 6) {
				op6.push([data.name])
				CustomerliveData.datasets[0].data[5] = op6.length;
			} else if (data.month == 7) {
				op7.push([data.name])
				CustomerliveData.datasets[0].data[6] = op7.length;
			} else if (data.month == 8) {
				op8.push([data.name])
				CustomerliveData.datasets[0].data[7] = op8.length;
			} else if (data.month == 9) {
				op9.push([data.name])
				CustomerliveData.datasets[0].data[8] = op9.length;
			} else if (data.month == 10) {
				op10.push([data.name])
				CustomerliveData.datasets[0].data[9] = op10.length;
			} else if (data.month == 11) {
				op11.push([data.name])
				CustomerliveData.datasets[0].data[10] = op11.length;
			} else if (data.month == 12) {
				op12.push([data.name])
				CustomerliveData.datasets[0].data[11] = op12.length;
			} else {
				console.log("something error of refund chart");
			}
		}
	});

	let merchantsDatachartData = merchantsData.map((data) => {
		data.month = moment(data.added_date).format("MM");
		data.year = moment(data.added_date).format("YYYY");
		return data;
	});

	let merchantliveData = {
		labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
		datasets: [
			{
				label: '',
				data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
				fill: true,
				tension: 0.1,
				backgroundColor: 'rgb(210, 65, 154, 0.5)',
				borderColor: 'rgb(210, 65, 154)',
			},
		],
	};

	// let merchantliveData = {
	// 	labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
	// 	series: [
	// 		[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
	// 	]
	// }
	const mop1 = [];
	const mop2 = [];
	const mop3 = [];
	const mop4 = [];
	const mop5 = [];
	const mop6 = [];
	const mop7 = [];
	const mop8 = [];
	const mop9 = [];
	const mop10 = [];
	const mop11 = [];
	const mop12 = [];
	merchantsDatachartData.map((data, index) => {
		var today = new Date();
		var yyyy = today.getFullYear();
		if (data.year == yyyy) {
			if (data.month == 1) {
				mop1.push([data.name])
				merchantliveData.datasets[0].data[0] = mop1.length;
			} else if (data.month == 2) {
				mop2.push([data.name])
				merchantliveData.datasets[0].data[1] = mop2.length;
			} else if (data.month == 3) {
				mop3.push([data.name])
				merchantliveData.datasets[0].data[2] = mop3.length;
			} else if (data.month == 4) {
				mop4.push([data.name])
				merchantliveData.datasets[0].data[3] = mop4.length;
			} else if (data.month == 5) {
				mop5.push([data.name])
				merchantliveData.datasets[0].data[4] = mop5.length;
			} else if (data.month == 6) {
				mop6.push([data.name])
				merchantliveData.datasets[0].data[5] = mop6.length;
			} else if (data.month == 7) {
				mop7.push([data.name])
				merchantliveData.datasets[0].data[6] = mop7.length;
			} else if (data.month == 8) {
				mop8.push([data.name])
				merchantliveData.datasets[0].data[7] = mop8.length;
			} else if (data.month == 9) {
				mop9.push([data.name])
				merchantliveData.datasets[0].data[8] = mop9.length;
			} else if (data.month == 10) {
				mop10.push([data.name])
				merchantliveData.datasets[0].data[9] = mop10.length;
			} else if (data.month == 11) {
				mop11.push([data.name])
				merchantliveData.datasets[0].data[10] = mop11.length;
			} else if (data.month == 12) {
				mop12.push([data.name])
				merchantliveData.datasets[0].data[11] = mop12.length;
			} else {
				console.log("something error of refund chart");
			}
		}
	});

	const liveOption = {
		maintainAspectRatio: false,
		low: 0,
		showArea: true,
		showPoint: false,
		fullWidth: true,
		height: 150,
		chartPadding: {
			left: 0,
			bottom: -14,
			right: 5
		}
	}

	// Get different from last month
	//Earnings
	const LastMonthrevenue = EarningsAmt
		.filter((el) => {
			return el.transaction_year == CurentYear && el.transaction_month == last_month;
		})
		.map((topup) => {
			return topup.charges;
		})
		.reduce((amt, nextAmt) => {
			return amt + nextAmt;
		}, 0);

	const CurentMonthrevenue = EarningsAmt
		.filter((el) => {
			return el.transaction_year == CurentYear && el.transaction_month == curent_month;
		})
		.map((topup) => {
			return topup.charges;
		})
		.reduce((amt, nextAmt) => {
			return amt + nextAmt;
		}, 0);

	let Earningspercent = (CurentMonthrevenue - LastMonthrevenue) / LastMonthrevenue * 100;
	Earningspercent = isNaN(Earningspercent) ? 0 : Earningspercent.toFixed(1);

	//Expenses
	const CurentCashbackAmount = CashbackAmountChart
		.filter((el) => {
			return el.transaction_year == CurentYear && el.transaction_month == curent_month;
		})
		.map((topup) => {
			return topup.amount;
		})
		.reduce((amt, nextAmt) => {
			return amt + nextAmt;
		}, 0);

	const LastCashbackAmount = CashbackAmountChart
		.filter((el) => {
			return el.transaction_year == CurentYear && el.transaction_month == last_month;
		})
		.map((topup) => {
			return topup.amount;
		})
		.reduce((amt, nextAmt) => {
			return amt + nextAmt;
		}, 0);

	let Expensespercent = (CurentCashbackAmount - LastCashbackAmount) / LastCashbackAmount * 100;
	Expensespercent = isNaN(Expensespercent) ? 0 : Expensespercent.toFixed(1);


	//Total Top-up
	const CurentTransactionsData = topupCardAmountChart
		.filter((el) => {
			return el.transaction_year == CurentYear && el.transaction_month == curent_month;
		})
		.map((topup) => {
			return topup.amount;
		})
		.reduce((amt, nextAmt) => {
			return amt + nextAmt;
		}, 0);

	const LastTransactionsData = topupCardAmountChart
		.filter((el) => {
			return el.transaction_year == CurentYear && el.transaction_month == last_month;
		})
		.map((topup) => {
			return topup.amount;
		})
		.reduce((amt, nextAmt) => {
			return amt + nextAmt;
		}, 0);

	let TopUppercent = (CurentTransactionsData - LastTransactionsData) / LastTransactionsData * 100;
	TopUppercent = isNaN(TopUppercent) ? 0 : TopUppercent.toFixed(1);

	//Withdrawals
	const CurentwithdrawAmount = withdrawAmountChart
		.filter((el) => {
			return el.transaction_year == CurentYear && el.transaction_month == curent_month;
		})
		.map((topup) => {
			return topup.amount;
		})
		.reduce((amt, nextAmt) => {
			return amt + nextAmt;
		}, 0);

	const LastwithdrawAmount = withdrawAmountChart
		.filter((el) => {
			return el.transaction_year == CurentYear && el.transaction_month == last_month;
		})
		.map((topup) => {
			return topup.amount;
		})
		.reduce((amt, nextAmt) => {
			return amt + nextAmt;
		}, 0);

	let Withdrawalpercent = (CurentwithdrawAmount - LastwithdrawAmount) / LastwithdrawAmount * 100;
	Withdrawalpercent = isNaN(Withdrawalpercent) ? 0 : Withdrawalpercent.toFixed(1);

	/////////////







	/* console.log(withdrawRequest); */

	return (
		<Fragment>
			{!loading ?
				<div className="dashboard_comp">
					<div className="col-lg-12 col-sm-12 d-flex align-items-center justify-content-between" style={{ fontSize: "12px" }}>
						<Breadcrumb parent="Home" title="Dashboard" />
						<div className="d-flex justify-content-end" style={{ width: "100%" }}>
							<button
								className="btnexport custBtnAdmin"
								onClick={toggleSMSModal}
							>
								Send SMS
							</button>
							<UnderMaintenance />
						</div>
					</div>
					<SendSMSModal smsModalClick={smsModalClick} toggleSMSModal={toggleSMSModal} />
					<div className="row">

						<div className="col-xl-3 col-sm-6">
							<div className="card chart_box" style={{ borderRadius: '3px', border: '1px solid #dfe8f1' }}>
								<div className="chart-widget-top" style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
									<div className="row card-body" style={{ padding: '14px 20px' }}>
										<div className="col-12">
											<h5 className="mb-1 headingblack" style={{ fontSize: '16px', fontWeight: 'bold' }}>Earnings <Info id="earnings_info" style={{ width: '15px', verticalAlign: 'middle' }} /></h5>
											<UncontrolledTooltip placement="bottom" target="earnings_info">
												Total of iPayPro service fee + Transaction fees + Commissions
											</UncontrolledTooltip>
										</div>
										<div className="col-12 text-left font-primary">
											<h4 className="num total-value mb-2" style={{ letterSpacing: '0px' }}>$
												<span className="counter">
													{(ServiceInfoCharge + TransactionInfo + CommissionInfoCharge).toFixed(2)}
												</span></h4>
										</div>
										<div className="col-12">
											<div style={{ width: '100%' }}>
												<div className="monthly">
													<Bar options={marketChartOptions} data={MonthlySaleData} />
												</div>
											</div>
										</div>
										<div className="col-12">
											<div className="num mt-2">
												<span className="counter subheadcolor">
													<span className="spnPer" style={Earningspercent <= 0 ? { color: 'red' } : { color: 'green' }}>
														{Math.abs(Earningspercent)}%
													</span>
													higher vs previous month
												</span>
											</div>
										</div>
										<div className="col-12" style={{}}>
											<h6 className="mb-0 subheadcolor" style={{ fontSize: "0.75rem" }}>
												Linky Service Fee <Info id="Service_Fee" style={{ width: '12px', verticalAlign: 'middle' }} /> :
												<span className="counter ml-2 subheadcolor">
													{getCurrency(dashboardData?.serviceFees?.[0]?.country)}{dashboardData?.serviceFees?.[0]?.totalAmount?.toFixed()}
												</span>
												<UncontrolledTooltip placement="bottom" target="Service_Fee">
													It's total of fees we charge customers & merchants for making payment via ipaypro or ipaypos app till date.
												</UncontrolledTooltip>
											</h6>
											<h6 className="mb-0 subheadcolor" style={{ fontSize: "0.75rem" }}>
												Withdrawal Fees <Info id="Service_Fee" style={{ width: '12px', verticalAlign: 'middle' }} /> :
												<span className="counter ml-2 subheadcolor">
													{getCurrency(dashboardData?.serviceFees?.[0]?.country)}{dashboardData?.serviceFees?.[0]?.totalAmount?.toFixed()}
												</span>
												<UncontrolledTooltip placement="bottom" target="Service_Fee">
													It's total of fees we charge customers & merchants for making payment via ipaypro or ipaypos app till date.
												</UncontrolledTooltip>
											</h6>
											<h6 className="mb-0 subheadcolor" style={{ fontSize: "0.75rem" }}>
												Subscription revenue <Info id="Service_Fee" style={{ width: '12px', verticalAlign: 'middle' }} /> :
												<span className="counter ml-2 subheadcolor">
													{getCurrency(dashboardData?.invoiceTotal?.[0]?.country)}{dashboardData?.invoiceTotal?.[0]?.totalAmount?.toFixed()}
												</span>
												<UncontrolledTooltip placement="bottom" target="Service_Fee">
													It's total of fees we charge customers & merchants for making payment via ipaypro or ipaypos app till date.
												</UncontrolledTooltip>
											</h6>
											<h6 className="mb-0 subheadcolor" style={{ fontSize: "0.75rem" }}>
												Commissions <Info id="Service_Fee" style={{ width: '12px', verticalAlign: 'middle' }} /> :
												<span className="counter ml-2 subheadcolor">
													{getCurrency(dashboardData?.commissionFees?.[0]?.country)}{dashboardData?.commissionFees?.[0]?.totalAmount?.toFixed()}
												</span>
												<UncontrolledTooltip placement="bottom" target="Service_Fee">
													It's total of fees we charge customers & merchants for making payment via ipaypro or ipaypos app till date.
												</UncontrolledTooltip>
											</h6>
											<h6 className="mb-0 subheadcolor" style={{ fontSize: "0.75rem" }}>
												Online Trans. Fees <Info id="Transaction_Fee" style={{ width: '12px', verticalAlign: 'middle' }} /> :
												<span className="counter ml-2 subheadcolor">
													{getCurrency(dashboardData?.onlineTransFees?.[0]?.country)}{dashboardData?.onlineTransFees?.[0]?.totalAmount?.toFixed()}
												</span>
												<UncontrolledTooltip placement="bottom" target="Transaction_Fee">
													It's total of fees we charge customers & merchants for making payment via card, google pay, or apple pay till date.
												</UncontrolledTooltip>
											</h6>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-xl-3 col-sm-6">
							<div className="card chart_box" style={{ borderRadius: '3px', border: '1px solid #dfe8f1' }}>
								<div className="chart-widget-top" style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
									<div className="row card-body" style={{ padding: '14px 20px' }}>
										<div className="col-12">
											<h5 className="mb-1 headingblack" style={{ fontSize: '16px', fontWeight: 'bold' }}>Expenses <Info id="expenses_info" style={{ width: '15px', verticalAlign: 'middle' }} /></h5>
											<UncontrolledTooltip placement="bottom" target="expenses_info">
												It's the total cashback we have give away to promote ipaypro till date
											</UncontrolledTooltip>
										</div>
										<div className="col-12 text-left font-primary">
											<h4 className="num total-value mb-2" style={{ letterSpacing: '0px' }}>$
												<span className="counter">
													{CashbackAmount.toFixed(2)}
												</span></h4>
										</div>
										<div className="col-12">
											<div style={{ width: '100%' }}>
												<div className="monthly">
													<Bar options={marketChartOptions} data={CashbackMonthlySaleData} />
												</div>
											</div>
										</div>
										<div className="col-12">
											<div className="num mt-2">
												<span className="counter subheadcolor">
													<span className="spnPer" style={Expensespercent <= 0 ? { color: 'red' } : { color: 'green' }}>
														{Math.abs(Expensespercent)}%
													</span>
													higher vs previous month
												</span>
											</div>
										</div>
										<div className="col-12" style={{}}>
											<h6 className="mb-2 subheadcolor" style={{ fontSize: "0.75rem" }}>
												Stripe online trans. fees <Info id="Commissions_Fees" style={{ width: '12px', verticalAlign: 'middle' }} /> :
												<span className="counter ml-2 subheadcolor">
													{0}
												</span>
												<UncontrolledTooltip placement="bottom" target="Commissions_Fees">
													It's the total cashback we have give away to promote ipaypro till date
												</UncontrolledTooltip>
											</h6>
											<h6 className="mb-2 subheadcolor" style={{ fontSize: "0.75rem" }}>
												Cashbacks <Info id="Commissions_Fees" style={{ width: '12px', verticalAlign: 'middle' }} /> :
												<span className="counter ml-2 subheadcolor">
													{getCurrency("AU")}{dashboardData?.cashbacks}
												</span>
												<UncontrolledTooltip placement="bottom" target="Commissions_Fees">
													It's the total cashback we have give away to promote ipaypro till date
												</UncontrolledTooltip>
											</h6>
											<h6 className="mb-2 subheadcolor" style={{ fontSize: "0.75rem" }}>
												Paypal online trans. fees <Info id="Commissions_Fees" style={{ width: '12px', verticalAlign: 'middle' }} /> :
												<span className="counter ml-2 subheadcolor">
													{0}
												</span>
												<UncontrolledTooltip placement="bottom" target="Commissions_Fees">
													It's the total cashback we have give away to promote ipaypro till date
												</UncontrolledTooltip>
											</h6>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-xl-3 col-sm-6">
							<div className="card chart_box" style={{ borderRadius: '3px', border: '1px solid #dfe8f1' }}>
								<div className="chart-widget-top" style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
									<div className="row card-body" style={{ padding: '14px 20px' }}>
										<div className="col-12">
											<h5 className="mb-1 headingblack" style={{ fontSize: '16px', fontWeight: 'bold' }}>Total Top-up <Info id="Topup_info" style={{ width: '15px', verticalAlign: 'middle' }} /></h5>
											<UncontrolledTooltip placement="bottom" target="Topup_info">
												Total of Card + Pay ID + Bank Transfer
											</UncontrolledTooltip>
										</div>
										<div className="col-12 text-left font-primary">
											<h4 className="num total-value mb-2" style={{ letterSpacing: '0px' }}>$
												<span className="counter">
													{topupCardAmount.toFixed(2)}
												</span></h4>
										</div>
										<div className="col-12">
											<div style={{ width: '100%' }}>
												<div className="monthly">
													<Bar options={marketChartOptions} data={topupMonthlySaleData} />
													{/* <ChartistGraph data={topupMonthlySaleData} options={MonthlySaleOption} type={'Bar'} /> */}
												</div>
											</div>
										</div>
										<div className="col-12">
											<div className="num mt-2">
												<span className="counter subheadcolor">
													<span className="spnPer" style={TopUppercent <= 0 ? { color: 'red' } : { color: 'green' }}>
														{Math.abs(TopUppercent)}%
													</span>
													higher vs previous month
												</span>
											</div>
										</div>
										<div className="col-12" style={{}}>
											<h6 className="mb-0 subheadcolor" style={{ fontSize: "0.75rem" }}>
												Card <Info id="Card_info" style={{ width: '12px', verticalAlign: 'middle' }} /> :
												<span className="counter ml-2 subheadcolor">
													${topupCardAmount.toFixed(2)}
												</span>
												<UncontrolledTooltip placement="bottom" target="Card_info">
													It's the total of customers/merchants topping up their wallet via card till date
												</UncontrolledTooltip>
											</h6>
											<h6 className="mb-0 subheadcolor" style={{ fontSize: "0.75rem" }}>
												Pay Id <Info id="PayId_info" style={{ width: '12px', verticalAlign: 'middle' }} /> :
												<span className="counter ml-2 subheadcolor">
													${topupPayIDAmount.toFixed(2)}
												</span>
												<UncontrolledTooltip placement="bottom" target="PayId_info">
													Its total of customers/merchants topping up their wallet via pay id till date
												</UncontrolledTooltip>
											</h6>
											<h6 className="mb-2 subheadcolor" style={{ fontSize: "0.75rem" }}>
												Bank Transfer <Info id="Transfer_info" style={{ width: '12px', verticalAlign: 'middle' }} /> :
												<span className="counter ml-2 subheadcolor">
													${topupBankTransferAmount.toFixed(2)}
												</span>
												<UncontrolledTooltip placement="bottom" target="Transfer_info">
													It's total of customers/merchants topping up their wallet via bank transfer till date
												</UncontrolledTooltip>
											</h6>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-xl-3 col-sm-6">
							<div className="card chart_box" style={{ borderRadius: '3px', border: '1px solid #dfe8f1' }}>
								<div className="chart-widget-top" style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
									<div className="row card-body" style={{ padding: '14px 20px' }}>
										<div className="col-12">
											<h5 className="mb-1 headingblack" style={{ fontSize: '16px', fontWeight: 'bold' }}>Withdrawals <Info id="Withdrawals_info" style={{ width: '15px', verticalAlign: 'middle' }} /></h5>
											<UncontrolledTooltip placement="bottom" target="Withdrawals_info">
												Total amount  withdrawn by customer/merchant till date
											</UncontrolledTooltip>
										</div>
										<div className="col-12 text-left font-primary">
											<h4 className="num total-value mb-2" style={{ letterSpacing: '0px' }}>$
												<span className="counter">
													{withdrawAmount.toFixed(2)}
												</span></h4>
										</div>
										<div className="col-12">
											<div style={{ width: '100%' }}>
												<div className="monthly">
													<Bar options={marketChartOptions} data={withdrawMonthlySaleData} />
												</div>
											</div>
										</div>
										<div className="col-12">
											<div className="num mt-2">
												<span className="counter subheadcolor">
													<span className="spnPer" style={Withdrawalpercent <= 0 ? { color: 'red' } : { color: 'green' }}>
														{Math.abs(Withdrawalpercent)}%
													</span>
													higher vs previous month
												</span>
											</div>
										</div>
										<div className="col-12" style={{}}>
											<h6 className="mb-0 subheadcolor" style={{ fontSize: "0.75rem" }}>
												Total Withdrawals <Info id="TotalWithdrawals" style={{ width: '12px', verticalAlign: 'middle' }} /> :
												<span className="counter ml-2 subheadcolor">
													${withdrawAmount.toFixed(2)}
												</span>
												<UncontrolledTooltip placement="bottom" target="TotalWithdrawals">
													Total amount withdrawn by customer/merchant till date
												</UncontrolledTooltip>
											</h6>
											<h6 className="mb-0 subheadcolor" style={{ fontSize: "0.75rem" }}>
												No of pending request(s) <Info id="nopendingrequest" style={{ width: '12px', verticalAlign: 'middle' }} /> :
												<span className="counter ml-2 subheadcolor">
													{withdrawAmountPending.length}
												</span>
												<UncontrolledTooltip placement="bottom" target="nopendingrequest">
													Total number of requests we have received for withdrawal from merchant or customers
												</UncontrolledTooltip>
											</h6>
											<h6 className="mb-2 subheadcolor" style={{ fontSize: "0.75rem" }}>
												Pending request amount <Info id="requestamount" style={{ width: '12px', verticalAlign: 'middle' }} /> :
												<span className="counter ml-2 subheadcolor">
													${withdrawAmountPendAmount.toFixed(2)}
												</span>
												<UncontrolledTooltip placement="bottom" target="requestamount">
													Its the total value of the number of withdrawal requests received.
												</UncontrolledTooltip>
											</h6>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>

					<div className="row" style={{ padding: '0px 15px' }}>
						<div className="col-xl-6 col-sm-12">
							<div className="status-widget">
								<div className="card" style={{ borderRadius: '3px', border: '1px solid rgb(223, 232, 241)' }}>
									<div className="card-body" style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
										{false && <div style={{ width: '50%', display: 'flex' }}>
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'center',
													alignItems: 'center',
													// width: '40%',
													// backgroundColor: "#759acc"
												}}
											>
												<div className="live-products">
													{/* <ChartistGraph data={CustomerliveData} options={liveOption} type={'Line'} /> */}
													<Line data={CustomerliveData} options={optionsss} />
												</div>
											</div>
											<div className="status-details" style={{ width: '60%', display: 'flex', flexDirection: 'column', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px' }}>
												<div className="mb-3 text-center" style={{ display: 'flex', justifyContent: 'space-between' }}>
													<div style={{ display: 'flex' }}>
														<h4 className="headingblack" style={{ fontSize: '18px', alignSelf: 'center', margin: '0px' }}>CUSTOMERS</h4>
														<img src={chartCustomerIcon} style={{ width: '20%', paddingLeft: '5px' }}></img>
													</div>
													<h4 className="mb-3"
														style={{ fontSize: '12px', fontWeight: 'normal' }}
													>
														<Link
															to={{
																pathname: "/dashboard/map",
																state: {
																	MerchantMarker: CustomerMarker,
																	tital: 'iPayPro Customers'
																},
															}}
														>View Map</Link>

													</h4>
												</div>
												<div className="text-center" style={{ display: 'flex', justifyContent: 'space-between' }}>
													<h4 className="subheadcolor" style={{ fontSize: '0.75rem', fontWeight: 'normal', fontFamily: 'inherit' }}>Total:</h4>
													<h4 className="subheadcolor" style={{ fontSize: '0.75rem', fontWeight: 'normal', fontFamily: 'inherit' }}>
														<Link
															to={{
																pathname: "/customers/all_customers",
																state: {
																	activeTab: '0'
																},
															}}
														>{VerifiedCostomer.length}</Link>
													</h4>
												</div>
												<div className="text-center" style={{ display: 'flex', justifyContent: 'space-between' }}>
													<h4 className="subheadcolor" style={{ fontSize: '0.75rem', fontWeight: 'normal', fontFamily: 'inherit' }}>Verification request(s):</h4>
													<h4 className="subheadcolor" style={{ fontSize: '0.75rem', fontWeight: 'normal', fontFamily: 'inherit' }}>
														<Link
															to={{
																pathname: "/customers/all_customers",
																state: {
																	activeTab: '5'
																},
															}}
														>{pendingVerificationCust.length}</Link>
													</h4>
												</div>
											</div>
										</div>}

										<div style={{ width: '100%', display: 'flex' }}>
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'center',
													alignItems: 'center',
													// width: '40%',
													//backgroundColor: '#d2419a'
												}}
											>
												<div className="live-products">
													{/* <ChartistGraph data={merchantliveData} options={liveOption} type={'Line'} /> */}
													<Line data={merchantliveData} options={optionsss} />
												</div>
											</div>
											<div className="status-details" style={{ width: '60%', display: 'flex', flexDirection: 'column', paddingTop: '10px', paddingLeft: '10px' }}>
												<div className="mb-3 text-center" style={{ display: 'flex', justifyContent: 'space-between' }}>
													<div style={{ display: 'flex' }}>
														<h4 className="headingblack" style={{ fontSize: '18px', alignSelf: 'center', margin: '0px' }}>MERCHANTS</h4>
														<img src={chartMerchantIcon} style={{ width: '18%', paddingLeft: '5px' }}></img>
													</div>
													<h4 className="mb-3" style={{ fontSize: '12px', fontWeight: 'normal' }}>
														<Link
															to={{
																pathname: "/dashboard/map",
																state: {
																	MerchantMarker: MerchantMarker,
																	tital: 'iPayPro Merchants'
																},
															}}
														>View Map</Link>
													</h4>
												</div>
												<div className="text-center" style={{ display: 'flex', justifyContent: 'space-between' }}>
													<h4 className="subheadcolor" style={{ fontSize: '0.75rem', fontWeight: 'normal', fontFamily: 'inherit' }}>Total Linky Merchants:</h4>
													<h4 className="subheadcolor" style={{ fontSize: '0.75rem', fontWeight: 'normal', fontFamily: 'inherit' }}>
														<Link
															to={{
																pathname: "/merchants/merchant_requests",
																state: {
																	activeTab: '0'
																},
															}}
														>{merchantsData.length}
														</Link>
													</h4>
												</div>
												<div className="text-center" style={{ display: 'flex', justifyContent: 'space-between' }}>
													<h4 className="subheadcolor" style={{ fontSize: '0.75rem', fontWeight: 'normal', fontFamily: 'inherit' }}>Document verification request:</h4>
													<h4 className="subheadcolor" style={{ fontSize: '0.75rem', fontWeight: 'normal', fontFamily: 'inherit' }}>
														<Link
															to={{
																pathname: "/merchants/merchant_requests",
																state: {
																	activeTab: '11'
																},
															}}
														>
															{UnVerifyMerchantsData
																? UnVerifyMerchantsData.filter(function (element) {
																	return element.status === 1;
																}).length
																: 0}
														</Link>
													</h4>
												</div>
												<div className="text-center" style={{ display: 'flex', justifyContent: 'space-between' }}>
													<h4 className="subheadcolor" style={{ fontSize: '0.75rem', fontWeight: 'normal', fontFamily: 'inherit' }}>Number of stores purchased plan:</h4>
													<h4 className="subheadcolor" style={{ fontSize: '0.75rem', fontWeight: 'normal', fontFamily: 'inherit' }}>
														<Link
															to={{
																pathname: "/merchants/merchant_requests",
																state: {
																	activeTab: '0'
																},
															}}
														>{merchantsData.length}</Link>
													</h4>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-xl-8 col-sm-6">
							{/* <div className="donut-chart-widget"> */}
							<div className="card" style={{ borderRadius: '3px', border: '1px solid #dfe8f1' }}>
								<h4 className="headingblack" style={{ fontSize: '18px', margin: '0px', paddingLeft: '10px', paddingTop: '10px', fontWeight: 'bold' }}>MERCHANTS USING FOLLOWING FEATURES</h4>
								<div className="card-body" style={{ display: 'flex', padding: '10px' }}>
									<div className="col-xl-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "min-content" }}>
										{/* <div className="flot-chart-placeholder" id="browser-uses-chart"> */}
										<Doughnut width={800} height={800} data={browserData} options={option} />
										{/* <ChartistGraph data={browserData} options={browserOption} type={'Pie'} /> */}
										{/* </div> */}
									</div>
									{/* <div className="col-xl-4" style={{alignSelf: 'center'}}> */}
									<div className="col-xl-8" style={{ alignSelf: 'center', display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "10px 20px" }}>
										{
											featuresData.map((val, ind) => {
												return (
													<div className="pb-2 progress-showcase pt-2">
														<div style={{ display: 'flex', justifyContent: 'space-between', gap: "5px" }}>
															<p className="subheadcolor" style={{ margin: '0px' }}>{val}</p>
															<p className="subheadcolor" style={{ margin: '0px' }}>
																{`${featuresCount[featuresDataKey[ind]]} (${parseFloat((featuresCount[featuresDataKey[ind]] / merchantsData.length * 100).toFixed(1))}%)`}
															</p>
														</div>
														<div className="progress" style={{ height: "8px" }}>
															<div className="progress-bar" role="progressbar" style={{ width: parseFloat((featuresCount[featuresDataKey[ind]] / merchantsData.length * 100).toFixed(1)) + "%", backgroundColor: browserData.datasets[0].backgroundColor[ind] }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
														</div>
													</div>
												)
											})
										}
									</div>
									{/* <div className="col-xl-4" style={{ alignSelf: 'center' }}>
										<div className="pb-2 progress-showcase pt-2">
											<div style={{ display: 'flex', justifyContent: 'space-between' }}>
												<p className="subheadcolor" style={{ margin: '0px' }}>Offers & Vouchers</p>
												<p className="subheadcolor" style={{ margin: '0px' }}>
													{offerVouchers.length + ' (' + parseFloat((offerVouchers.length / merchantsData.length * 100).toFixed(1)) + '%)'}
												</p>
											</div>
											<div className="progress" style={{ height: "8px" }}>
												<div className="progress-bar bgprogressbarGray" role="progressbar" style={{ width: parseFloat((offerVouchers.length / merchantsData.length * 100).toFixed(1)) + "%" }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
										</div>
										<div className="pb-2 progress-showcase pt-2">
											<div style={{ display: 'flex', justifyContent: 'space-between' }}>
												<p className="subheadcolor" style={{ margin: '0px' }}>Loyalty cards</p>
												<p className="subheadcolor" style={{ margin: '0px' }}>
													{2 + ' (' + parseFloat((2 / merchantsData.length * 100).toFixed(1)) + '%)'}
												</p>
											</div>
											<div className="progress" style={{ height: "8px" }}>
												<div className="progress-bar bgprogressbarYellow" role="progressbar" style={{ width: parseFloat((2 / merchantsData.length * 100).toFixed(1)) + "%" }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
										</div>
										<div className="pb-2 progress-showcase pt-2">
											<div style={{ display: 'flex', justifyContent: 'space-between' }}>
												<p className="subheadcolor" style={{ margin: '0px' }}>Payment links</p>
												<p className="subheadcolor" style={{ margin: '0px' }}>
													{10 + ' (' + parseFloat((10 / merchantsData.length * 100).toFixed(1)) + '%)'}
												</p>
											</div>
											<div className="progress" style={{ height: "8px" }}>
												<div className="progress-bar bgprogressbarGreen" role="progressbar" style={{ width: parseFloat((10 / merchantsData.length * 100).toFixed(1)) + "%" }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
										</div>
										<div className="pb-2 progress-showcase pt-2">
											<div style={{ display: 'flex', justifyContent: 'space-between' }}>
												<p className="subheadcolor" style={{ margin: '0px' }}>POS</p>
												<p className="subheadcolor" style={{ margin: '0px' }}>
													{7 + ' (' + parseFloat((7 / merchantsData.length * 100).toFixed(1)) + '%)'}
												</p>
											</div>
											<div className="progress" style={{ height: "8px" }}>
												<div className="progress-bar bgprogressbarPink" role="progressbar" style={{ width: parseFloat((7 / merchantsData.length * 100).toFixed(1)) + "%" }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
										</div>
									</div> */}
									{/* </div> */}
								</div>
							</div>
							{/* </div> */}
						</div>

						<div className="col-xl-4 col-sm-6">
							<div className="card" style={{ borderRadius: '3px', border: '1px solid #dfe8f1' }}>
								<div className="card-header" style={{ padding: '15px' }}>
									<h5 className="text-uppercase headingblack" style={{ fontSize: '16px' }}>Your top countries</h5>
									<p className="subheadcolor" style={{ margin: '0px' }}>Sales performance revenue based by country</p>
								</div>
								<div className="card-body" style={{ padding: '10px' }}>
									<ul className="crm-activity">
										<li className="media">
											<span className="mr-3">
												<i className="flag-icon flag-icon-au"
													style={{
														borderRadius: '50%',
														width: '40px',
														height: '40px',
														backgroundSize: 'cover'
													}}></i>
											</span>
											<div className="align-self-center media-body">
												<div style={{ display: 'flex', justifyContent: 'space-between' }}>
													<h6 className="mt-0 mb-0 subheadcolor">Australia</h6>
													<h6 className="mt-0 mb-0 subheadcolor">${(ServiceInfoCharge + TransactionInfo + CommissionInfoCharge).toFixed(2)}</h6>
												</div>
											</div>
										</li>
										<li className="media">
											<span className="mr-3">
												<i className="flag-icon flag-icon-us"
													style={{
														borderRadius: '50%',
														width: '40px',
														height: '40px',
														backgroundSize: 'cover'
													}}></i>
											</span>
											<div className="align-self-center media-body">
												<div style={{ display: 'flex', justifyContent: 'space-between' }}>
													<h6 className="mt-0 mb-0 subheadcolor">United States</h6>
													<h6 className="mt-0 mb-0 subheadcolor">$0.00</h6>
												</div>
											</div>
										</li>
										<li className="media">
											<span className="mr-3">
												<i className="flag-icon flag-icon-bq"
													style={{
														borderRadius: '50%',
														width: '40px',
														height: '40px',
														backgroundSize: 'cover'
													}}></i>
											</span>
											<div className="align-self-center media-body">
												<div style={{ display: 'flex', justifyContent: 'space-between' }}>
													<h6 className="mt-0 mb-0 subheadcolor">Netherlands</h6>
													<h6 className="mt-0 mb-0 subheadcolor">$0.00</h6>
												</div>
											</div>
										</li>
										<li className="media">
											<span className="mr-3">
												<i className="flag-icon flag-icon-gb"
													style={{
														borderRadius: '50%',
														width: '40px',
														height: '40px',
														backgroundSize: 'cover'
													}}></i>
											</span>
											<div className="align-self-center media-body">
												<div style={{ display: 'flex', justifyContent: 'space-between' }}>
													<h6 className="mt-0 mb-0 subheadcolor">United Kingdom</h6>
													<h6 className="mt-0 mb-0 subheadcolor">$0.00</h6>
												</div>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>

					</div>
				</div>
				:
				<div className="loader-box" style={{ height: "100px" }}>
					<div className="loader" style={{ display: "flex", alignItems: "center" }}>
						<DisappearedLoading size="medium" color="#514F4E" />
					</div>
				</div>
			}
		</Fragment>
	);
};

export default Ecommerce;
