import React from "react";
import { Fragment, Component } from "react";
import { components } from "react-select";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem, NavLink as NavLinkTab } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import PaginationComponent from "react-reactstrap-pagination";
import {DisappearedLoading} from "react-loadingg";

import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";

class TransactionCustomerData extends Component {
    state = {
        addCustomerModal: false,
        newCustomer: null,
        customerList: null,
        updateCustomerModal: false,
        customerDeleteModal : false,
        selectedCustomer: null,
        access_level: localStorage.getItem("accessLevel"),
        transactionCustomers: null,
        activePage: 1,
        pageSize: 15,
    };


    componentDidMount() {


        //this.getCustomerFromTransaction();
        // console.log(this.props.transactions);
        let customers = {}
        /*  for (let transc of this.props.transactions) {
             if (customers[transc.from_customer_id] == undefined) {
                 customers[transc.from_customer_id] = transc
             } else if (customers[transc.to_customer_id] == undefined) {
                 customers[transc.to_customer_id] = transc
             }
         } */


        for (let transc of this.props.transactions) {
            if (customers[transc.from_customer_id] == undefined) {
                if (transc.from_customers.length > 0) {
                    customers[transc.from_customer_id] = transc.from_customers[0]
                } else if (transc.from_mechant.length > 0) {
                    customers[transc.from_customer_id] = transc.from_mechant[0]
                }

            } else if (customers[transc.to_customer_id] == undefined) {
                if (transc.to_customers.length > 0) {
                    customers[transc.to_customer_id] = transc.to_customers[0]
                } else if (transc.from_mechant.length > 0) {
                    customers[transc.to_customer_id] = transc.to_mechant[0]
                }
            }
        }
        const customersArray = [];
        for (let element in customers) {
            customersArray.push(customers[element])
        }
        this.setState({ transactionCustomers: customersArray });

    }

    componentDidUpdate() {
        if (this.state.customerList == null && this.state.transactionCustomers != null) {
            this.getMercCustomerList();
        }
    }

    handlePageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber });
    }

    getCustomerFromTransaction = () => {
        const postdata = { merchant_id: this.props.merchant_id }
        client.post(api.merc_transc_customer_list, postdata, (error, response) => {
            if (!error) {

                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            console.log(response);
                            this.setState({ transactionCustomers: response.result.data })
                        }

                    } catch (e) {
                        setTimeout(() => {
                            toast.error("Internal error occured. Please contact support");
                        }, 200);
                    }
                } else {
                    setTimeout(() => {
                        toast.error("Internal error occured. Please contact support");
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }

        });
    }

    getMercCustomerList = () => {
        const postdata = { merchant_id: this.props.merchant_id }
        client.post(api.merc_customer_list, postdata, (error, response) => {
            if (!error) {

                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            console.log(response);
                            this.setState({ customerList: [...response.result.data, ...this.state.transactionCustomers] })
                        }

                    } catch (e) {
                        setTimeout(() => {
                            toast.error("Internal error occured. Please contact support");
                        }, 200);
                    }
                } else {
                    setTimeout(() => {
                        toast.error("Internal error occured. Please contact support");
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }

        });
    }

    onAddNewCustomerClick = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                addCustomerModal: !prevState.addCustomerModal
            }
        })
    }

    onChangeAddCustomer = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => {
            return {
                ...prevState,
                newCustomer: {
                    ...prevState.newCustomer,
                    [name]: value
                }

            }
        })
    };

    onAddNewCustomer = (event, type) => {
        event.preventDefault();
        const { newCustomer, selectedCustomer } = this.state;
        const { merchant_id } = this.props;
        let apiroute = api.merc_add_customer
        let postData;
        if (type == "add") {
            postData = { merchant_id: merchant_id, customer_name: newCustomer.name, customer_email: newCustomer.email, customer_mobile: newCustomer.mobile };
        }

        if (type == "update") {
            postData = { merchant_id: merchant_id, customer_id: selectedCustomer._id, customer_name: selectedCustomer.name, customer_email: selectedCustomer.email, customer_mobile: selectedCustomer.mobile };
            apiroute = api.merc_update_customer
        }
        client.post(apiroute, postData, (error, response) => {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            console.log(response);
                            if (type == "update") {
                                this.setState({ updateCustomerModal: false });
                            } else {
                                this.setState({ addCustomerModal: false });
                            }
                            this.getMercCustomerList();
                        }

                    } catch (e) {
                        setTimeout(() => {
                            toast.error("Internal error occured. Please contact support");
                        }, 200);
                    }
                } else {
                    setTimeout(() => {
                        toast.error("Internal error occured. Please contact support");
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }

        });

    }

    renderAddCustomerModal = () => {
        return (
            <Fragment>
                <Modal isOpen={this.state.addCustomerModal} toggle={this.onAddNewCustomerClick} className="modal-body" centered={true}>
                    <form className="theme-form" onSubmit={(event) => this.onAddNewCustomer(event, "add")}>
                        <ModalHeader toggle={this.onAddNewCustomerClick}></ModalHeader>
                        <ModalBody >
                            <div className="col form-group col-lg-7">
                                <label >Customer Name</label>
                                <input className="form-control" type="text" name="name" onChange={this.onChangeAddCustomer}
                                    required />
                            </div>
                            <div className="col form-group col-lg-7">
                                <label >Mobile</label>
                                <input className="form-control" type="text" name="mobile" placeholder="Mobile" onChange={this.onChangeAddCustomer}
                                    required />
                            </div>
                            <div className="col form-group col-lg-7">
                                <label >Email</label>
                                <input className="form-control" type="email" name="email" onChange={this.onChangeAddCustomer}
                                    required />
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.onAddNewCustomerClick}>Not sure!</Button>
                            <Button color="success" type="submit">Yes, Please!</Button>
                        </ModalFooter>
                    </form>
                </Modal>

            </Fragment>
        )

    }

    onClickUpdateCustomer = (item) => {
        this.setState({ updateCustomerModal: true, selectedCustomer: item })
    }

    toggleUpdateCustomerModal = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                updateCustomerModal: !prevState.updateCustomerModal
            }
        })
    }

    onChangeUpdateCustomer = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => {
            return {
                ...prevState,
                selectedCustomer: {
                    ...prevState.selectedCustomer,
                    [name]: value
                }
            }
        })
    }

    renderUpdateCustomerModal = () => {
        const { selectedCustomer } = this.state;
        return (

            <Fragment>
                <Modal isOpen={this.state.updateCustomerModal} toggle={this.toggleUpdateCustomerModal} className="modal-body" centered={true}>
                    <form className="theme-form" onSubmit={(event) => this.onAddNewCustomer(event, "update")}>
                        <ModalHeader toggle={this.toggleUpdateCustomerModal}>Update Customer</ModalHeader>
                        <ModalBody >
                            <div className="col form-group col-lg-7">
                                <label >Customer Name</label>
                                <input className="form-control" type="text" name="name" onChange={this.onChangeUpdateCustomer}
                                    required value={selectedCustomer.name} />
                            </div>
                            <div className="col form-group col-lg-7">
                                <label >Mobile</label>
                                <input className="form-control" type="text" name="mobile" placeholder="Mobile" onChange={this.onChangeUpdateCustomer}
                                    required value={selectedCustomer.mobile} />
                            </div>
                            <div className="col form-group col-lg-7">
                                <label >Email</label>
                                <input className="form-control" type="email" name="email" onChange={this.onChangeUpdateCustomer}
                                    required value={selectedCustomer.email} />
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.toggleUpdateCustomerModal}>Not sure!</Button>
                            <Button color="success" type="submit">Yes, Please!</Button>
                        </ModalFooter>
                    </form>
                </Modal>

            </Fragment>

        );
    }

    onDeleteCustomerClick = (item) => {
        this.setState({ customerDeleteModal: true, selectedCustomer: item })
    };

    
    toggleDeleteCustomerModal = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                customerDeleteModal: !prevState.customerDeleteModal
            }
        })
    }

    deleteCustomer = (event) => {
        event.preventDefault();
        const {  selectedCustomer } = this.state;
        let postData = { customer_id: selectedCustomer._id, merchant_id : this.props.merchant_id };
        
        client.post(api.merc_customer_delete, postData, (error, response) => {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            this.getMercCustomerList();
                            this.setState({customerDeleteModal : false})
                        }

                    } catch (e) {
                        setTimeout(() => {
                            toast.error("Internal error occured. Please contact support");
                        }, 200);
                    }
                } else {
                    setTimeout(() => {
                        toast.error("Internal error occured. Please contact support");
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }

        });
    }

    renderDeleteCustomerModal = () => {
        const { selectedCustomer } = this.state;
        return (

            <Fragment>
                <Modal isOpen={this.state.customerDeleteModal} toggle={this.toggleDeleteCustomerModal} className="modal-body" centered={true}>
                    <form className="theme-form" onSubmit={this.deleteCustomer}>
                        <ModalHeader toggle={this.toggleDeleteCustomerModal}>Delete Customer</ModalHeader>
                        <ModalBody >
                            Are you sure you want to delete the customer record?
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.toggleDeleteCustomerModal}>Not sure!</Button>
                            <Button color="success" type="submit">Yes, Please!</Button>
                        </ModalFooter>
                    </form>
                </Modal>

            </Fragment>

        );
    }

    render() {
        const { customerList, access_level, transactionCustomers } = this.state;

        return (
            <Fragment>
                {this.renderAddCustomerModal()}
                {this.state.selectedCustomer && this.renderUpdateCustomerModal()}
                {this.state.selectedTransaction && this.renderSuccessClickModal()}
                {this.state.selectedCustomer && this.renderDeleteCustomerModal()}
                {transactionCustomers && customerList ?
                    <Fragment>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom:"0.7rem" }}>
                            <button className="btn btn-outline-primary btn-xs" onClick={this.onAddNewCustomerClick}>Add New Customer</button>
                            {(this.state.activePage) * this.state.pageSize > customerList.length ?
                                <p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>Showing {((this.state.activePage - 1) * this.state.pageSize) + 1} to {customerList.length} of {customerList.length} entries</p> :
                                <p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>Showing {((this.state.activePage - 1) * this.state.pageSize) + 1} to {(this.state.activePage) * this.state.pageSize} of {customerList.length} entries</p>}
                        </div>

                        <div className="table-responsive">
                            <table className="table table-border-horizontal">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Customer Name</th>
                                        <th scope="col"> Email</th>
                                        <th scope="col">Mobile</th>
                                        <th scope="col">Type</th>
                                        {access_level != 1 && <th scope="col" style={{ width: "14%" }}>Action</th>}

                                    </tr>
                                </thead>
                                <tbody>{
                                    customerList.slice((this.state.activePage - 1) * this.state.pageSize, (this.state.activePage) * this.state.pageSize).map((item, index) => (
                                        <tr key={item?._id} >
                                            <td scope="row">{((this.state.activePage - 1) * this.state.pageSize) + index + 1}</td>
                                            <td>{item?.name}</td>
                                            <td>{item?.email}</td>
                                            <td>{item?.mobile}</td>
                                            <td>{typeof item?.fk_merchant_id == "undefined" ? "Past transactions" : "Manually added"}</td>
                                            {access_level != 1 && typeof item?.fk_merchant_id != "undefined" ? <td style={{ width: "14%" }}>
                                                {access_level == 3 && <span style={{ marginRight: "10px", fontSize: "1.2em" }} onClick={() => this.onClickUpdateCustomer(item)}>
                                                    <i style={{ color: "red" }} title="delete" className="fa fa-edit"></i>
                                                </span>}
                                                <span style={{ marginRight: "10px", fontSize: "1.2em" }} onClick={()=>this.onDeleteCustomerClick(item)}>
                                                    <i style={{ color: item?.flag_status ? "red" : "black" }} className="fa fa-trash-o"></i>
                                                </span>

                                            </td> : <td>N.A</td>}
                                        </tr>
                                    ))

                                }
                                </tbody>
                            </table>
                            <div className="mt-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <select className="custom-select" style={{ width: "32%", marginRight: "7px" }} onChange={(event) => this.setState({ pageSize: event.target.value })} value={this.state.pageSize}>
                                        <option value="15">15</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="75">75</option>
                                        <option value="100">100</option>
                                    </select>
                                    <span>Records per Page</span>
                                </div>
                                <PaginationComponent
                                    totalItems={customerList.length}
                                    pageSize={this.state.pageSize}
                                    onSelect={this.handlePageChange} />
                            </div>
                        </div></Fragment> : <div className="loader-box" style={{height:"100vh",width:"100wh"}}>
                              <div className="loader" style={{ display:"flex",alignItems:"center" }}>
                              <DisappearedLoading size="medium" color="#514F4E" />
                               </div> 
                               </div>}
            </Fragment>
        )
    }
}

export default TransactionCustomerData;