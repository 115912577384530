import React, { useState, useEffect, Fragment, useRef } from "react";
import { toast } from "react-toastify";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import MasterSearchBarResultComp from "./search-bar-comp/MasterSearchBarResultComp";
import SreachBarInputComp from "./search-bar-comp/SearchBarInput"

const MasterSearchBar = () => {

	let getClicked = useRef();

	const [searchValue, setSearchValue] = useState('');
	const [searchResult, setSearchResult] = useState([]);
	const [loading, setLoading] = useState(false);

	const onSearchChangeHandler = () => {

		let getSessionCountry = localStorage.getItem("sessionCountryCode");
		setLoading(true);
		if (searchValue) {

			let postData = {
				searchQuery: searchValue,
				countryCode: getSessionCountry
			};
			client.post(api.masterSearchQuery, postData, (error, response) => {
				// console.log(response, "searchbar response");
				if (!error) {
					try {
						if (!response.error) {
							setSearchResult(response.result);
							setLoading(false);
						} else {
							setTimeout(() => { toast.error(response.message) }, 200);
							setLoading(false);
						}
					} catch (e) {
						setTimeout(() => { toast.error("Internal error occured. Please contact support 1") }, 200);
						setLoading(false);
					}
				} else {
					setTimeout(() => { toast.error("Internal error occured. Please contact support 2") }, 200);
					setLoading(false);
				}
			});
		} else {
			setSearchResult([]);
			setLoading(false);
		}
	}


	useEffect(() => {

		const getData = setTimeout(() => { onSearchChangeHandler() }, 1000);
		return () => clearTimeout(getData)

	}, [searchValue])

	const clearSearch = () => {
		setSearchValue("");
		// searchResult.splice(0, searchResult.length);
	}

	const onClickOutside = () => { clearSearch(); };

	const handleClickOutside = (event) => {
		if (getClicked.current && !getClicked.current.contains(event.target)) {
			onClickOutside && onClickOutside();
		}
	};

	useEffect(() => {

		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};

	}, [getClicked]);

	return (
		<Fragment>
			<div style={{ position: "relative" }} ref={getClicked}>
				<SreachBarInputComp
					searchValue={searchValue}
					setSearchValue={setSearchValue}
					clearSearch={clearSearch}
				/>
				{searchValue != "" && <MasterSearchBarResultComp
					searchValue={searchValue}
					searchResult={searchResult}
					loading={loading}
					clearSearch={clearSearch}
				/>}
			</div>
		</Fragment>
	);
}

export default MasterSearchBar;
