import React, { Fragment } from "react";
import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import PaginationComponent from "react-reactstrap-pagination";
import RenderTransactions from "../transactions/RenderTransactions";
import ViewTransaction from './ViewTransaction.js'
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink as NavLinkTab,
} from "reactstrap";

import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";

import { DollarSign, MapPin, X, TrendingDown, ArrowUp, ShoppingCart, Search, Activity, User } from "react-feather";
import { Link, Redirect, withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";
import TransactionCustomerData from "./TransactionCustomerData";
import { DisappearedLoading } from "react-loadingg";
import VerifiedMerchantSettlements from "./VerifiedMerchantSettlements";
import RewardHistory from "./RewardHistory";
import MerchantBills from "./MerchantBills";
import RequestPayments from "./RequestPayments.js";
import SellTickets from "./SellTickets.jsx";
import Tips from "./Tips.jsx";
import Portfolio from "./Portfolio.jsx"
import CreditHistory from "./CreditHistory.js";

class VerifiedMerchantTransaction extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: null,
			merchant_id: this.props.merchant_id,
			merchant_info: this.props.merchant_info,
			merchantUserDetails:null,
			wallet_info: null,
			transactions: null,
			transaction_loading: false,
			flagModal: false,
			flag_note: "",
			deleteModal: false,
			infoModal: false,
			modalId: null,
			isLoaded: false,
			items: [],
			statusModal: false,
			statusMessage: null,
			activePage: 1,
			pageSize: 15,
			access_level: localStorage.getItem("accessLevel"),
			adminUser: JSON.parse(localStorage.getItem("user")),
			successClickModal: false,
			selectedTransaction: null,
			refund_amount: null,
			successClickOption: null,
			transc_rejectReason: null,
			activeTab: "1",
			searchData: [],
			allTransaction: 0,
			viewTransaction: false,
			TransactionData: {},
			tabIndex: 0,
			sub_tab: 0,
		};
	}

	componentDidMount() {
		const { merchant_id } = this.state;
		this.getMerchantWalletInfo(this.state.merchant_id);
		this.getMerchantStoreInfo(this?.state?.merchant_info?.unique_id );

		this.getMerchantTransactions(merchant_id, 1, 15);
		//this.getMercCustomerList();
	}

	componentDidUpdate() {
		let customers = {};
		/*  if (this.state.activeTab == "2") {
			 for (let transc of this.state.transactions) {
				 if (customers[transc.from_customer_id] == undefined) {
					 customers[transc.from_customer_id] = transc
				 } else if (customers[transc.to_customer_id] == undefined) {
					 customers[transc.to_customer_id] = transc
				 }
			 }
		 } */
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// console.log(nextProps, prevState,)
		if (nextProps.merchant_id != prevState.merchant_info._id) {
			return {
				merchant_id: nextProps.match.params.merchant_id,
				merchant_info: nextProps.merchant_info,
			};
		} 
		if (nextProps.location.search != "") {

			try {
				let tab;
				const query = new URLSearchParams(nextProps.location.search);
			
				// console.log(query.get('activeTab'), "query")
				// console.log(typeof query.get('sub_tab'), "query")
				// console.log(prevState.sub_tab, "sub_tab query")
				// for (let params of query.entries()) {
				//     tab = +params[1]
				// };

				if (query.get('sub_tab') && query.get('sub_tab') != prevState.sub_tab) {
					// this.setState({sub_tab : query.get('sub_tab')})
					return {
						sub_tab : query.get('sub_tab'),
						tabIndex: parseInt(query.get('sub_tab'))
					}
				}
			}catch{}
            

		}
		else {
			return null;
		}
	}

	componentDidUpdate(prevProps, prevState) {
	    /*  console.log(prevProps, prevState);
	     console.log(this.state); */
	    // if (prevState.wallet_info.fk_customer_id != this.state.merchant_id) {
	    //     this.getMerchantWalletInfo(this.state.merchant_id);
		// }
		// this.getMerchantWalletInfo(this.state.merchant_id);
		
	}

	setPageSize = (event) => {

		let getPageSize = event.target.value;

		this.setState({ pageSize: getPageSize });
		this.setState({ activePage: 1 });

		const { merchant_id, activePage } = this.state;

		this.getMerchantTransactions(merchant_id, 1, getPageSize);

	};

	handlePageChange = (pageNumber) => {

		this.setState({ activePage: pageNumber });

		const { merchant_id, pageSize } = this.state;

		this.getMerchantTransactions(merchant_id, pageNumber, pageSize);
	};

	renderTitle(item) {
		const { merchant_id } = this.state;
		let title = "";
		let subtitle = "";
		if (item.type == 1) {
			if (item.topup_type == 2 || item.topup_type == 3) {
				if (merchant_id == item.to_customer_id) {
					title = "Topup request:";
					subtitle = `${item.topup_type == 2 ? "Direct deposit" : "Pay ID"} `;
				} else {
					let display_data = {
						from_name: "",
						from_mobile: "",
						to_name: "",
						to_mobile: "",
					};
					try {
						display_data = JSON.parse(item.display_data);
					} catch (error) { }

					title = "Paid To:";
					subtitle = display_data.to_name == "" ? display_data.to_mobile : display_data.to_name + " for receipt topup";
				}
			} else {
				title = "Added to wallet from:";
				/* subtitle = `**${item.card_number[14]}${item.card_number[15]}`; */
				subtitle = `${item.card_number.substring(0, 6)} ****** ${item.card_number.substring(12)}`;
			}
		} else if (item.type == 3) {
			let display_data = {
				from_name: "",
				from_mobile: "",
				to_name: "",
				to_mobile: "",
			};
			try {
				display_data = JSON.parse(item.display_data);
			} catch (error) { }

			if (merchant_id == item.to_customer_id) {
				title = "Received From:";
				if (item.from_customers.length > 0) {
					subtitle = item.from_customers[0].name;
				} else if (item.from_mechant.length > 0) {
					subtitle = item.from_mechant[0].business_name;
				} else if (item.card_number) {
					subtitle = "**" + item.card_number.slice(-4);
				}
			} else {
				title = "Paid To:";
				if (item.to_customers.length > 0) {
					subtitle = item.to_customers[0].name;
				} else if (item.to_mechant.length > 0) {
					subtitle = item.to_mechant[0].business_name;
				} else if (item.card_number) {
					subtitle = "**" + item.card_number.slice(-4);
				}
			}
		} else if (item.type == 4) {
			title = "Settlement Request:";
			subtitle = "";
		} else if (item.type == 2) {
			title = "Cashback from:";
			subtitle = "iPayPro";
		}

		return (
			<div>
				{item.type == 3 ? (
					<span
						style={{
							marginLeft: 5,
							fontStyle: "normal",
							letterSpacing: 0,
							textAlign: "left",
						}}
					>
						<span>
							<p>{title} </p>
							<p>
								{merchant_id == item.to_customer_id && item.from_customers.length > 0 ? (
									<Link
										to={{
											pathname: `${process.env.PUBLIC_URL}/customer/wallet/${item.from_customer_id}`,
										}}
									>
										{subtitle}
									</Link>
								) : merchant_id == item.to_customer_id && item.from_mechant.length > 0 ? (
									<Link
										to={{
											pathname: `${process.env.PUBLIC_URL}/merchants/merchantDetails/${item.from_customer_id}`,
										}}
									>
										{subtitle}
									</Link>
								) : merchant_id == item.from_customer_id && item.to_customers.length > 0 ? (
									<Link
										to={{
											pathname: `${process.env.PUBLIC_URL}/customer/wallet/${item.to_customer_id}`,
										}}
									>
										{subtitle}
									</Link>
								) : merchant_id == item.from_customer_id && item.to_mechant.length > 0 ? (
									<Link
										to={{
											pathname: `${process.env.PUBLIC_URL}/merchants/merchantDetails/${item.to_customer_id}`,
										}}
									>
										{subtitle}
									</Link>
								) : subtitle ? subtitle : null}
							</p>

						</span>
					</span>
				) : (
					<span
						style={{
							marginLeft: 5,
							fontStyle: "normal",
							letterSpacing: 0,
							textAlign: "left",
						}}
					>
						{`${title} ${subtitle}`}
					</span>
				)}
			</div>
		);
	}


	getMerchantTransactions = (merchant_id, activePage, pageSize) => {

		let skip = (parseInt(activePage) - 1) * parseInt(pageSize);

		// console.log(skip, "skip")

		let postdata = {
			merchant_id: merchant_id,
			merchantDataType: this.props.merchantDataType.type,
			limit: parseInt(pageSize),
			toSort: true,
			skip,
		};

		this.setState({ transaction_loading: true })

		client.post(api.fetch_transaction, postdata, (error, response) => {

			if (!error) {

				if (typeof response.error !== "undefined") {
					try {
						if (!response.error) {
							console.log(response.result.transactions, "fetch transsaction data")
							this.setState({
								transactions: response.result.transactions,
								searchData: response.result.transactions,
								allTransaction: response.result.allTransaction
							})
							this.setState({ transaction_loading: false })
						}

					} catch (e) {
						// console.log(e);
						setTimeout(() => {
							toast.error("Internal error occured. Please contact support");
						}, 200);
						this.setState({ transaction_loading: false })
					}
				} else {
					setTimeout(() => {
						toast.error("Internal error occured. Please contact support");
					}, 200);
				}
			} else {
				setTimeout(() => { toast.error("Internal error occured. Please contact support"); }, 200);
				this.setState({ transaction_loading: false })
			}



		});
	}

	getMerchantWalletInfo(merchant_id) {
		let postdata = {
			merchant_id: merchant_id,
			// merchantDataType: this.props.merchantDataType.type
		};


		client.post(api.merchant_wallet_data, postdata, (error, response) => {
			if (!error) {
				if (typeof response.error !== "undefined") {
					try {
						if (!response.error) {
							// console.log(response);
							this.setState({
								wallet_info: response.result.wallet_info,
								// transactions: response.result.transactions.reverse(),
								// searchData: response.result.transactions.reverse(),
							});
						}
					} catch (e) {
						setTimeout(() => {
							toast.error("Internal error occured. Please contact support");
						}, 200);
					}
				} else {
					setTimeout(() => {
						toast.error("Internal error occured. Please contact support");
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
		});

		
	}

	getMerchantStoreInfo(unique_id)
	{

		console.log(unique_id,this)

		client.post(api.merchant_details_store, { merc_unique_id: unique_id},(error, response)=> {
				if (!error) {
					if (typeof response.error !== "undefined") {
						try {
							if (!response.error) {
								try {
									this.setState({
				merchantUserDetails : response?.result?.contactDetails
			})
									
									
								} catch (error) {
									console.log(error)
								}
							} else {
								setTimeout(() => {
									toast.error(response.message);
								}, 200);
							}
						} catch (e) {
							setTimeout(() => {
								toast.error("Internal error occured. Please contact support");
							}, 200);
						}
					} else {
						setTimeout(() => {
							toast.error("Internal error occured. Please contact support");
						}, 200);
					}
				} else {
					setTimeout(() => {
						toast.error("Internal error occured. Please contact support");
					}, 200);
				}
			})

	}

	refreshPage() {
		window.location.reload(false);
	}

	render_date = (add_date) => {
		if (typeof add_date === "undefined") {
			return;
		}
		console.log(this?.state?.merchantUserDetails)
		const convert_date = new Date(add_date);
		const added_date = moment.tz(convert_date,this?.state?.merchantUserDetails?.timeZone?this?.state?.merchantUserDetails?.timeZone: "Australia/Sydney").format("DD/MM/YYYY HH:mm:ss");
		return added_date;
	};

	toggleStatusModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				statusModal: !prevState.statusModal,
			};
		});
	};

	deleteTransactionHandler = (id) => {
		const postData = { transaction_id: id };

		client.post(api.delete_transaction, postData, (error, response) => {
			if (!error) {
				if (!response.error) {
					// console.log(response);
					// this.getAllTransactions();
				} else {
					setTimeout(() => {
						toast.error(response.message);
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
		});
	};

	flagSubmitHandler = (event, id) => {
		event.preventDefault();
		let postdata = {};
		const foundTransaction = this.state.transactions.find((el) => {
			return el._id == id;
		});
		if (typeof foundTransaction.flag_status === "undefined") {
			foundTransaction.flag_status = false;
		}
		if (event.target.name == "unflag") {
			postdata = {
				transaction_id: id,
				flag_status: !foundTransaction.flag_status,
				flag_note: { type: "unflag", note: "No comments", user: this.state.adminUser.email },
			};
		} else if (event.target.name == "comment") {
			postdata = {
				transaction_id: id,
				flag_note: { type: "normal", note: this.state.flag_note, user: this.state.adminUser.email },
			};
		} else {
			postdata = {
				transaction_id: id,
				flag_status: !foundTransaction.flag_status,
				flag_note: { type: "flag", note: this.state.flag_note, user: this.state.adminUser.email },
			};
		}
		client.put(api.update_transaction, postdata, (error, response) => {
			if (!error) {
				if (!response.error) {
					this.refreshPage();
				} else {
					setTimeout(() => {
						toast.error(response.message);
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
		});
	};

	getStatus = (status) => {
		if (status == 1) {
			return "Success";
		} else if (status == 0) {
			return "Pending";
		} else {
			return "Failed";
		}
	};

	flagClick = (id) => {
		this.setState((prevState) => {
			return {
				...prevState,
				flagModal: !prevState.flagModal,
				modalId: id,
			};
		});
	};

	statusClick = (message) => {
		const failedMessage = JSON.parse(message);
		this.setState((prevState) => {
			return {
				...prevState,
				statusModal: !prevState.statusModal,
				statusMessage: failedMessage.message,
			};
		});
	};

	deleteClick = (id) => {
		this.setState((prevState) => {
			return {
				...prevState,
				deleteModal: !prevState.deleteModal,
				modalId: id,
			};
		});
	};

	infoClick = (id) => {
		this.setState((prevState) => {
			return {
				...prevState,
				infoModal: !prevState.infoModal,
				modalId: id,
			};
		});
	};

	flagNoteHandler = (event) => {
		const flagNote = event.target.value;
		this.setState((prevState) => {
			return {
				...prevState,
				flag_note: flagNote,
			};
		});
	};

	toggleFlagModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				flagModal: !prevState.flagModal,
			};
		});
	};
	toggleDeleteModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				deleteModal: !prevState.deleteModal,
			};
		});
	};
	toggleInfoModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				infoModal: !prevState.infoModal,
			};
		});
	};

	renderFlagModal = (id) => {
		const foundTransaction = this.state.transactions.find((el) => {
			return el._id == id;
		});
		const style = {
			outline: "none",
			":focus": {
				outlineColor: "black",
			},
		};

		if (!foundTransaction.flag_status) {
			return (
				<Modal isOpen={this.state.flagModal} toggle={this.toggleFlagModal} className="modal-body" centered={true}>
					<ModalBody>
						<form onSubmit={(event) => this.flagSubmitHandler(event, this.state.modalId)} className="needs-validation">
							<h5>Please enter a note to flag transaction.</h5>
							<p style={{ color: "red" }}>Note: User will no longer be able to view transaction in their application.</p>
							<label className="form-label" style={{ display: "block" }}>
								Note:
							</label>
							<input type="text" size="40" onChange={this.flagNoteHandler} style={style} required></input>

							<Button
								color="secondary"
								style={{ marginTop: "20px", marginRight: "20px" }}
								type="submit"
								onClick={this.toggleFlagModal}
							>
								Cancel
							</Button>
							<Button color="primary" style={{ marginTop: "20px", marginRight: "20px" }} name="flag">
								Update status
							</Button>
						</form>
					</ModalBody>
				</Modal>
			);
		}
		return (
			<Modal isOpen={this.state.flagModal} toggle={this.toggleFlagModal} className="modal-body" centered={true}>
				<ModalBody>
					<h5>Are you sure you want to unflag transaction ?</h5>
					<Button
						color="primary"
						style={{ marginTop: "20px", marginRight: "20px" }}
						name="unflag"
						onClick={(event) => this.flagSubmitHandler(event, this.state.modalId)}
					>
						Yes, please
					</Button>
					<Button color="secondary" style={{ marginTop: "20px", marginRight: "20px" }} onClick={this.toggleFlagModal}>
						Not Sure
					</Button>
				</ModalBody>
			</Modal>
		);
	};

	renderDeleteModal = (id) => {
		return (
			<Modal isOpen={this.state.deleteModal} toggle={this.toggleDeleteModal} className="modal-body" centered={true}>
				<form className="theme-form" noValidate="">
					<ModalBody>
						<h4>Do you really want to delete this transaction ?</h4>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={this.toggleDeleteModal}>
							No
						</Button>
						<Button color="primary" type="submit" onClick={() => this.deleteTransactionHandler(id)}>
							Yes
						</Button>
					</ModalFooter>
				</form>
			</Modal>
		);
	};

	renderInfoModal = (id) => {
		const foundTransaction = this.state.transactions.find((el) => {
			return el._id == id;
		});

		if (typeof foundTransaction.flag_note == "undefined") {
			foundTransaction.flag_note = [];
		}
		let foundTransactionNote = [...foundTransaction.flag_note].reverse();
		return (
			<Modal isOpen={this.state.infoModal} toggle={this.toggleInfoModal} className="modal-body" centered={true}>
				<h5 style={{ padding: "15px", margin: "0" }}>Comments</h5>

				<ModalBody style={{ paddingTop: "0", paddingBottom: "0", maxHeight: "calc(100vh - 370px)", overflowY: "auto" }}>
					<div className="card-body chat-box" style={{ paddingTop: "0", paddingBottom: "0" }}>
						<div className="chat-right-aside bitcoin-chat">
							<div className="chat">
								<div className="chat-history chat-msg-box custom-scrollbar" style={{ padding: "0", margin: "0" }}>
									<ul style={{ margin: "0" }}>
										{foundTransactionNote.length != 0 ? (
											foundTransactionNote.map((el) => {
												return (
													<li style={{ margin: "0" }}>
														<div
															className="message my-message"
															style={{ marginBottom: "10px", paddingTop: "10px", paddingBottom: "10px" }}
														>
															<p>
																{el.user}
																<span className="message-data-time pull-right">{this.render_date(el.date)}</span>{" "}
															</p>
															{el.type == "normal" ? null : <p style={{ fontSize: "0.9rem" }}>Type : {el.type}</p>}
															{el.note}
														</div>
													</li>
												);
											})
										) : (
											<p>No note added!</p>
										)}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter style={{ paddingTop: "10px", paddingBottom: "10px" }}>
					<div className="col-lg-12">
						<form className="theme-form">
							<div className="form-group mb-0 col-12">
								<label htmlFor="exampleFormControlTextarea4">Comment:</label>
								<textarea
									className="form-control"
									id="exampleFormControlTextarea4"
									required
									rows="3"
									onChange={this.flagNoteHandler}
								></textarea>
							</div>
							<div style={{ padding: "15px 0 0 15px" }}>
								<Button color="secondary" style={{ marginRight: "15px" }} onClick={this.toggleInfoModal}>
									Cancel
								</Button>
								<Button
									color="primary"
									type="submit"
									name="comment"
									onClick={(event) => this.flagSubmitHandler(event, this.state.modalId)}
								>
									Submit
								</Button>
							</div>
						</form>
					</div>
				</ModalFooter>
			</Modal>
		);
	};

	renderStatusModal = () => {
		return (
			<Modal isOpen={this.state.statusModal} toggle={this.toggleStatusModal} className="modal-body" centered={true}>
				<form className="theme-form" noValidate="">
					<ModalBody style={{ textAlign: "center" }}>
						<div style={{ fontSize: "2rem", textAlign: "center" }}>
							<i className="icofont icofont-warning" style={{ color: "#FF2046" }}></i>
						</div>
						<h4 style={{ color: "#FF2046" }}>Failure!</h4>
						<h6>{this.state.statusMessage}</h6>
						<Button
							className="btn btn-danger"
							style={{ width: "100%", textAlign: "center", fontSize: "1.1rem", marginTop: "10px" }}
							onClick={this.toggleStatusModal}
						>
							Dismiss
						</Button>
					</ModalBody>
				</form>
			</Modal>
		);
	};

	getReason = (transaction) => {
		if (transaction.comment == "") {
			return "";
		}

		const comment = JSON.parse(transaction.comment);
		if (typeof comment.message == "undefined") {
			return "";
		} else if (comment.message.toLowerCase().includes("card details")) {
			return "Invalid card details";
		} else if (comment.message.toLowerCase().includes("i-pay pin")) {
			return "Invalid i-Pay pin";
		} else if (
			comment.message.toLowerCase().includes("otp authentication") ||
			comment.message.toLowerCase().includes("user cancel")
		) {
			return "Pressed cancel or back button";
		} else if (comment.message.toLowerCase().includes("working days")) {
			return "Server or api error";
		} else if (comment.message.toLowerCase().includes("insufficient funds")) {
			return "Insufficient funds";
		} else if (comment.message.toLowerCase().includes("card has been declined")) {
			return "Card declined";
		} else if (comment.message.toLowerCase().includes("card type you entered isn't supported")) {
			return "Card type not supported";
		} else if (comment.message.toLowerCase().includes("payment amount is invalid")) {
			return "Invalid amount or exceeds max amount";
		} else if (
			comment.message.toLowerCase().includes("customer has exceeded the balance or credit limit available on their card")
		) {
			return "Card limit exceeded";
		} else if (comment.message.toLowerCase().includes("card does not support this type of purchase")) {
			return "Card not supported";
		} else if (comment.message.toLowerCase().includes("card has expired")) {
			return "Card expired";
		} else if (
			comment.message.toLowerCase().includes("card issuer could not be reached, so the payment could not be authorized")
		) {
			return "Card declined";
		} else {
			return "";
		}
	};

	onSuccessClick = (transaction) => {
		if (transaction.type == 1) {
			toast.error("Refund or Reject not available");
		} else if (typeof transaction.refund_transc_link != "undefined" || transaction.refund_transc_link == "") {
			toast.error("Refund or Reject not available");
			//this.setState({ successClickModal: true, selectedTransaction: transaction, successClickOption: "reject" })
		} else {
			this.setState({ successClickModal: true, selectedTransaction: transaction, successClickOption: "refund" });
		}
	};

	toggleSuccessClickModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				successClickModal: !prevState.successClickModal,
				successClickOption: " refund",
			};
		});
	};

	onSubmitRefundRequest = (event) => {
		event.preventDefault();
		let { refund_amount, selectedTransaction } = this.state;
		let postData = { transaction_no: selectedTransaction.transaction_no, amount: +refund_amount };
		if (this.state.successClickOption == "reject") {
			postData = {
				transaction_no: selectedTransaction.transaction_no,
				amount: selectedTransaction.amount.toFixed(2),
				type: "reject",
				rejectReason: this.state.transc_rejectReason,
			};
			refund_amount = selectedTransaction.amount;
		}

		//setLoading(true)
		if (+refund_amount <= selectedTransaction.amount) {
			//console.log(postData);
			client.post(api.refund_request, postData, (error, response) => {
				if (!error) {
					// console.log(response);
					try {
						if (!response.error) {
							//console.log(response)
							toast.success("Amount refunded");
							//props.fetchTransaction();
						} else {
							toast.error("Internal error occured.Please contact support.");
						}
					} catch (error) {
						toast.error("Internal error occured.Plelase contact support.");
					}
				} else {
					toast.error("Internal error occured.");
				}
			});
		} else {
			toast.error("Please enter correct amount");
		}
	};

	renderSuccessClickModal = () => {
		const { selectedTransaction, successClickModal, successClickOption } = this.state;
		return (
			<Modal
				isOpen={this.state.successClickModal}
				toggle={this.toggleSuccessClickModal}
				className="modal-body"
				centered={true}
			>
				<form className="theme-form" noValidate="" onSubmit={this.onSubmitRefundRequest}>
					<ModalHeader toggle={this.toggleSuccessClickModal}>
						<h6>{successClickOption == "refund" ? "Initiate Refund" : "Reject transaction"}</h6>
					</ModalHeader>
					<ModalBody>
						{successClickOption == "refund" ? (
							<Fragment>
								<lable>Enter amount for refund</lable>
								<input
									name="refund_amount"
									style={{
										border: "none",
										borderBottom: "1px solid rgb(197, 189, 189)",
										borderRadius: "0",
										margin: "0px 10px 6px",
										fontSize: "0.75rem",
									}}
									required
									onChange={(event) => this.setState({ refund_amount: event.target.value })}
									type="text"
								></input>
								<p style={{ color: "red" }}>Note:You can refund upto ${selectedTransaction.amount.toFixed(2)}</p>
							</Fragment>
						) : (
							<Fragment>
								<lable>Please enter note for your action</lable>
								<input
									name="transc_rejectReason"
									onChange={(event) => this.setState({ transc_rejectReason: event.target.value })}
									type="text"
									required
								></input>
							</Fragment>
						)}
					</ModalBody>
					<ModalFooter>
						{
							<Button color="secondary" onClick={this.toggleSuccessClickModal}>
								Not sure!
							</Button>
						}
						<Button color="success" type="submit">
							Yes, Please!
						</Button>
						{successClickOption == "refund" && (
							<Button color="primary" onClick={() => this.setState({ successClickOption: "reject" })}>
								Reject
							</Button>
						)}
					</ModalFooter>
				</form>
			</Modal>
		);
	};

	returnText = (data) => {
		// console.log("data", data);
		let newdata = JSON.stringify(this.renderTitle(data));
		var name = JSON.parse(newdata).props?.children?.props?.children?.props?.children[1]?.props.children?.toString();
		return name !== "" || name !== undefined || name !== null ? name : "";
	};

	onSearch = (e) => {
		//|| v.transaction_no.includes(e.target.value) || v.amount.toFixed(2)
		let searchResult = [];
		var reg = new RegExp("^[0-9]+$");
		let result =
			this.state.transactions &&
			this.state.transactions.map((v) => {
				if (
					this.returnText(v)?.toLowerCase().includes(e.target.value.toLowerCase()) ||
					v.transaction_no.includes(e.target.value) ||
					v.amount.toFixed(2).includes(e.target.value)
				) {
					return v;
				}
			});

		if (e.target.value?.length > 0) {
			searchResult = result;
		} else searchResult = this.state.transactions;
		this.setState({ searchData: searchResult.filter((v) => v !== undefined) });
		// console.log(searchResult.filter((v) => v !== undefined));
	};

	ShowCurrencyNum = (amount, showSymbol) => {
		// const merchantData = JSON.parse(localStorage.getItem("merchant"));
		const merchantData = this.state.merchant_info;
		console.log(merchantData,"merchantdata")
		const currency_symbol = merchantData.country.currency_symbol;
		// const currency_symbol = countryData.currency_symbol;

		let getCurrency = !isNaN(parseFloat(amount)) ? amount : 0;

		if (merchantData && merchantData.country) {
			// if (countryData) {
			if (showSymbol && currency_symbol) {
				getCurrency = `${currency_symbol} ${parseFloat(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
			}
			else {
				getCurrency = +`${parseFloat(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
			}
		}

		return getCurrency;
	}


	render() {
		let {
			merchant_info,
			merchant_id,
			wallet_info,
			transactions,
			access_level,
			activeTab,
			customerList,
			selectedCustomer,
			searchData,
			transaction_loading,
			allTransaction,
			pageSize
		} = this.state;
		// console.log(merchant_id);
		// console.log(transactions);
		// console.log(merchant_info, '::merchant_info');
		let amountCashback = 0;
		if (transactions) {
			let transactionCashback = this.state.transactions.filter((el) => {
				return el.type == 2 && el.status == 1;
			});

			transactionCashback.forEach((elem) => {
				amountCashback += elem.amount;
			});
		}

		return (
			<Fragment>
				{this.state.modalId ? this.renderFlagModal(this.state.modalId) : null}
				{this.state.modalId ? this.renderDeleteModal(this.state.modalId) : null}
				{this.state.modalId && transactions ? this.renderInfoModal(this.state.modalId) : null}
				{this.renderStatusModal()}

				{merchant_id && wallet_info && transactions ? (
					<div className="container-fluid" style={{paddingLeft:0}}>

						<div className="row">
							{this.state.viewTransaction ?
								<ViewTransaction merchant_id={merchant_info?._id} data={this.state.TransactionData} countryData={merchant_info?.country} onBackClick={() => this.setState({ viewTransaction: false })} />
								: <div className="col-sm-12">
									{merchant_info ? (
										<div className="card" >

											<Tabs className="col-sm-12" selectedIndex={this.state.tabIndex} onSelect={(index)=>this.setState({tabIndex:index})} style={{ padding: "0px" }}>
												<TabList className="tabs tab-title" style={{ marginBottom: '0px !important' }}>
													<Tab style={{ marginTop: "0.5rem", display: "flex", alignItems: "center" }} >Transactions</Tab>
													<Tab style={{ marginTop: "0.5rem", display: "flex", alignItems: "center" }} >Credit History</Tab>
													<Tab style={{ marginTop: "0.5rem", display: "flex", alignItems: "center" }} >Settlements</Tab>
													<Tab style={{ marginTop: "0.5rem", display: "flex", alignItems: "center" }} >Reward History</Tab>
													<Tab style={{ marginTop: "0.5rem", display: "flex", alignItems: "center" }} >Quick Bills</Tab>
													<Tab style={{ marginTop: "0.5rem", display: "flex", alignItems: "center" }} >Request Payments</Tab>
													{/* <Tab style={{ marginTop: "0.5rem", display: "flex", alignItems: "center" }} >Sell Tickets</Tab> */}
													<Tab style={{ marginTop: "0.5rem", display: "flex", alignItems: "center" }} >Tips</Tab>
													<Tab style={{ marginTop: "0.5rem", display: "flex", alignItems: "center" }} >Portfolio</Tab>
												</TabList>
												<TabPanel>
												<div className="card-header" style={{ border: "none", padding: "10px 30px" }}>
														<h6>
															Store name : {merchant_info.business_name}, Mobile : {merchant_info.mobile}
														</h6>
													</div>
													<div className="card-body updating-chart height-equal" style={{ padding: "0 25px" }}>
														<div style={{ paddingBottom: 0 }} className="upadates text-center">
															<h2 className="font-primary">
																{/* <DollarSign /> */}
																<span>
																	{" "}
																	{/* {typeof wallet_info.wallet_amount != "undefined"
																	? wallet_info.wallet_amount.toFixed(2)
																	: wallet_info.wallet_amount}{" "} */}
																	{
																		typeof wallet_info.wallet_amount != "undefined"
																			?
																			this.ShowCurrencyNum(wallet_info.wallet_amount.toFixed(2), true)
																			:
																			this.ShowCurrencyNum(wallet_info.wallet_amount, true)
																	}
																</span>
															</h2>
															<p>Current Wallet Balance</p>
														</div>
														<div className="upadates text-center" style={{ paddingBottom: 0 }}>
															<h2 className="font-primary">
																{/* <DollarSign /> */}
																{/* <span> {amountCashback.toFixed(2)} </span> */}
																<span onClick={()=>this.setState({tabIndex:1})}> {this.ShowCurrencyNum(wallet_info.credit_balance.toFixed(2), true)} </span>
															</h2>
															<p>Credit Balance</p>
														</div>
													</div>
													<div className="card-header" style={{ border: "none", padding: "10px 30px 0" }}>
														<h5 style={{ display: "inline-block" }}>Transaction History</h5>
														{this.state.activePage * this.state.pageSize > this.state.allTransaction ? (
															<p className="pull-right" style={{ display: "inline-block" }}>
																Showing {(this.state.activePage - 1) * this.state.pageSize + 1} to {this.state.allTransaction} of{" "}
																{this.state.allTransaction} entries

															</p>
														) : (
															<p className="pull-right" style={{ display: "inline-block" }}>
																Showing {(this.state.activePage - 1) * this.state.pageSize + 1} to{" "}
																{this.state.activePage * this.state.pageSize} of {this.state.allTransaction} entries
															</p>
														)}

													</div>
													<div className="py-2">
														<input
															style={{
																border: "none",
																borderBottom: "1px solid rgb(197, 189, 189)",
																borderRadius: "0",
																margin: "1px 10px",
																fontSize: "0.75rem",
																padding: "0.25rem 0.50rem",
																width: "25%",
															}}
															type="text"
															placeholder="Search "
															onChange={(e) => this.onSearch(e)}
														/>
													</div>
													{/* <RenderTransactions
                                            imageClicked={() => { }}
                                            receiptClick={() => { }}
                                            transactions={transactions}
                                            //authorizeTopupClick={() => { }}
                                            hideTo={true}
                                            hideAction={true}
                                            hideReceipt={true}
                                            hideMessage={false}
                                            hideFilter={true}
                                            hideBankDetails={true}
                                            hidetransferFee={true}
                                            hidedue={true}
                                            settlementPage={false}
                                            hideReason={false}

                                        /> */}


													{!transaction_loading ? <div className="table-responsive">
														<table className="table table-border-horizontal">
															<thead>
																<tr>
																	<th scope="col" style={{ width: "3%" }}>
																		#
																	</th>
																	<th scope="col" style={{ width: "9%" }}>
																		Date / Time
																	</th>
																	<th scope="col" style={{ width: "10%" }}>
																		Transaction ID
																	</th>
																	<th scope="col" style={{ width: "10%" }}>
																		Description
																	</th>
																	<th scope="col" style={{ width: "16%" }}>
																		Reference
																	</th>
																	<th scope="col" style={{ width: "7%" }}>
																		Credit
																	</th>
																	<th scope="col" style={{ width: "7%" }}>
																		Debit
																	</th>
																	{/* <th scope="col" style={{ width: "10%" }}>
																	Amount
																</th>
																<th scope="col" style={{ width: "10%" }}>
																	Fees
																</th> */}

																	{/* <th scope="col" style={{ width: "13%" }}>
																	Reason
																</th> */}
																	<th scope="col" style={{ width: "7%" }}>
																		Balance
																	</th>
																	<th scope="col" style={{ width: "7%" }}>
																		Status
																	</th>
																	{access_level != 1 && (
																		<th scope="col" style={{ width: "12%" }}>
																			Action
																		</th>
																	)}
																</tr>
															</thead>
															<tbody>
																{
																	searchData.length > 0 && searchData.map((item, index) => (
																		<tr key={item._id}>
																			{/* {console.log(item, 'itm')} */}
																			<td scope="row " style={{ width: "3%" }}>
																				{(this.state.activePage - 1) * this.state.pageSize + index + 1}
																			</td>
																			<td style={{ width: "9%" }}>{this.render_date(item.transaction_date)}</td>
																			<td style={{ width: "10%" }}>{item.transaction_no}</td>
																			<td style={{ width: "10%" }}>{this.renderTitle(item)} </td>
																			<td style={{ width: "16%" }}>
																				<div>
																				{item.reference == 6 ? JSON.parse(item.comment).message : item.description}
																				{item.reason_authorization != null && <p>Rejected(reason: {item.reason_authorization})</p>}
																				</div>
																			</td>

																			{/* <td style={{ width: "10%" }}>
																				{item.type == 3 || item.type == 1 ? (
																					item.from_customer_id == merchant_id ? (
																						<span style={{ color: "red" }}>- ${item.amount.toFixed(2)}</span>
																					) : (
																						<span>+ ${item.amount.toFixed(2)}</span>
																					)
																				) : (
																					<span>+ ${item.amount.toFixed(2)}</span>
																				)}
																			</td> */}
																			<td style={{ width: "7%" }}>{
																				item.to_customer_id == merchant_id ? (
																					<span style={{ color: "rgb(29, 148, 65)" }}>
																						{/* ${item.pay_amount.toFixed(2)} */}
																						{this.ShowCurrencyNum(item.pay_amount.toFixed(2), true)}
																					</span>
																				) :
																					// (trnsItem.refund_status == 1 ?
																					// <span style={{ color: "rgb(29, 148, 65)" }}>{ShowCurrencyNum(trnsItem.refund_data?.amount.toFixed(2), true)}</span>
																					// :
																					<h4>-</h4>
																				// )
																			}</td>
																			{/* <td style={{ width: "7%" }}>
																				{item.charges == 0 ? "-" : <span style={{ color: "rgb(234, 19, 46)" }}>${item.charges.toFixed(2)}</span>}
																			</td> */}
																			<td style={{ width: "7%" }}>{
																				item.from_customer_id == merchant_id ? (
																					<span style={{ color: "rgb(234, 19, 46)" }}>
																						{/* ${item.amount.toFixed(2)} */}
																						{item.amount_payer ?
																							item.amount_payer.currency.symbol + item.amount_payer.amount.toFixed(2)
																							:
																							this.ShowCurrencyNum(item.amount.toFixed(2), true)
																						}
																					</span>
																				) :
																					
																					// (trnsItem.refund_status == 1 ?
																					// <span style={{ color: "rgb(234, 19, 46)" }}>{ShowCurrencyNum(trnsItem.refund_data?.amount.toFixed(2), true)}</span>
																					// :
																					<h4>-</h4>
																				// )
																			}</td>

																			{/* <td style={{ width: "13%" }}>
																				{item.status == 2
																					? this.getReason(item)
																					: item.refund_status == 1
																						? `$${item.refund_data.amount} was refunded.`
																						: null}
																			</td> */}

																			<td style={{ width: "7%" }}>
																				<span style={{ color: "rgb(63, 72, 219)" }}>
																					{/* {
																							item.to_customer_id == merchant_id
																								? `${item.to_closing_balance.toFixed(2)}`
																								: `${item.from_closing_balance.toFixed(2)}`
																						} */}
																					{
																						item.to_customer_id == merchant_id
																							? this.ShowCurrencyNum(item.to_closing_balance.toFixed(2), true)
																							: this.ShowCurrencyNum(item.from_closing_balance.toFixed(2), true)
																					}
																				</span>
																			</td>

																			{/* <td style={{ width: "7%", color: 'rgb(63, 72, 219)' }}>
																				$
																				{item.status == 2
																					? item.to_customer_id == merchant_id
																						? (item.to_closing_balance - item.amount).toFixed(2)
																						: item.from_closing_balance.toFixed(2)
																					: item.type == 3 || item.type == 1
																						? item.to_customer_id == merchant_id
																							? item.to_closing_balance.toFixed(2)
																							: item.from_closing_balance.toFixed(2)
																						: (item.from_closing_balance - item.amount).toFixed(2)}
																			</td> */}
																			<td style={{ width: "7%" }}>
																				{this.getStatus(item.status) == "Failed" ? (
																					<span onClick={() => this.statusClick(item.comment)} style={{ color: "Red" }}>
																						{this.getStatus(item.status)}
																					</span>
																				) : item.reference == 6 && item.status == 2 ? (
																					<p>Rejected</p>
																				) : (
																					<span>{this.getStatus(item.status)}</span>
																				)}
																			</td>
																			{access_level != 1 && (
																				<td style={{ width: "12%" }}>
																					<span
																						style={{ marginRight: "10px", fontSize: "1.2em", cursor: 'pointer' }}
																						onClick={() => this.setState(prevState => {
																							return {
																								...prevState,
																								viewTransaction: !prevState.viewTransaction,
																								TransactionData: {
																									merchant_id: merchant_id,
																									wallet_amount: '',
																									data: item,
																									view_type: 0,
																									disabled_refund: '',
																								}
																							}
																						})}
																					>
																						<i
																							style={{ color: 'green' }}
																							id="eye"
																							className="fa fa-eye"
																						></i>
																					</span>
																					{access_level == 3 && (
																						<span
																							style={{ marginRight: "10px", fontSize: "1.2em", cursor: 'pointer' }}
																							onClick={() => this.deleteClick(item._id)}
																						>
																							<i
																								style={{ color: "red" }}
																								id="delete"
																								title="delete"
																								className="fa fa-trash-o"
																							></i>
																						</span>
																					)}
																					<span
																						style={{ marginRight: "10px", fontSize: "1.2em", cursor: 'pointer' }}
																						onClick={() => this.flagClick(item._id)}
																					>
																						<i
																							style={{ color: item.flag_status ? "red" : "black" }}
																							id="block"
																							className="fa fa-flag-o"
																						></i>
																					</span>
																					<span
																						style={{ marginRight: "10px", fontSize: "1.2em", cursor: 'pointer' }}
																						onClick={() => this.infoClick(item._id)}
																					>
																						<i className="fa fa-list-alt" style={{ color: "black" }}></i>
																					</span>
																				</td>
																			)}
																		</tr>
																	))
																}
															</tbody>
														</table>
														{
															searchData.length == 0 && <>
																<div style={{ height: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>
																	<p style={{ fontSize: "15px" }}>No record found!</p>
																</div>
															</>
														}
														{searchData.length > 0 && <div
															style={{
																display: "flex",
																alignItems: "center",
																justifyContent: "space-between",
																padding: "0px",
																margin: "1rem 0rem"
															}}
														>
															<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
																<select
																	className="custom-select"
																	style={{ width: "32%", marginRight: "7px" }}
																	onChange={this.setPageSize}
																	value={this.state.pageSize}
																>
																	<option value="15">15</option>
																	<option value="25">25</option>
																	<option value="50">50</option>
																	<option value="75">75</option>
																	<option value="100">100</option>
																</select>
																<span>Records per Page</span>
															</div>
															<PaginationComponent
																totalItems={allTransaction}
																pageSize={pageSize}
																onSelect={this.handlePageChange}
																defaultActivePage={this.state.activePage}
															/>
														</div>}
													</div> :
														<div className="loader-box d-flex align-item-center justify-content-center" style={{ height: "50vh", width: "100wh" }}>
															<DisappearedLoading size="medium" color="#514F4E" style={{ position: "unset" }} />
														</div>
													}
													{/* <TransactionCustomerData
															merchant_id={merchant_id}
															transactions={this.state.transactions}
													/> */}
												</TabPanel>
												<TabPanel>
													<CreditHistory merchant_info={merchant_info}/>
												</TabPanel>
												<TabPanel>
													<VerifiedMerchantSettlements 
														merchant_id={merchant_id} 
														merchantDataType={this.props.merchantDataType} 
														profiledata={{ merchant_id: merchant_id, merchant_info: merchant_info, transactions: this.state.transactions }}
													></VerifiedMerchantSettlements>
												</TabPanel>
												<TabPanel>
													<div className="container-fluid" style={{ paddingTop: '0.5rem' }}>
															<RewardHistory merchant_info={merchant_info} merchant_id={merchant_id}></RewardHistory>
													</div>
												</TabPanel>
												<TabPanel>
													<MerchantBills merchant_info={merchant_info}
                            merchant_id={merchant_id} />
												</TabPanel>
												<TabPanel>
													<RequestPayments merchant_info={merchant_info} merchant_id={merchant_id}/>
												</TabPanel>
												{/* <TabPanel>
													<SellTickets merchant_info={merchant_info} merchant_id={merchant_id}/>
												</TabPanel> */}
												<TabPanel>
													<Tips merchant_info={merchant_info} merchant_id={merchant_id}/>
												</TabPanel>
												<TabPanel>
													<Portfolio merchant_info={merchant_info} merchant_id={merchant_id}/>
												</TabPanel>
											</Tabs>

										</div>
									) : null}
								</div>}
						</div>
					</div>
				) : (
					<div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
						<div className="loader" style={{ display: "flex", alignItems: "center" }}>
							<DisappearedLoading size="medium" color="#514F4E" />
						</div>
					</div>
				)
				}
			</Fragment>
		);
	}
}

export default withRouter(VerifiedMerchantTransaction);
