import React, { Fragment, useEffect, useState, useRef } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink as NavLinkTab } from 'reactstrap';
import * as moment from 'moment-timezone';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { DisappearedLoading } from "react-loadingg";
import RenderTransactions from "../transactions/RenderTransactions";

import Dropdown from 'react-bootstrap/Dropdown';
// const BorderTable = () => {
function BorderTable(props) {

    const transMyRef = useRef();

    let [loadingbtn, setloadingbtn] = useState(false);
    let [loading, setloading] = useState(false);
    let [result, setresult] = useState(null);
    let [country, setcountry] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [countryTabIndex, setcountryTabIndex] = useState(0);
    const [defaultCountryCode, setdefaultCountryCode] = useState(localStorage.getItem("sessionCountryCode"));
    // defaultCountryCode: localStorage.getItem("sessionCountryCode"),

    //let [activeTab, setactiveTab] = useState("1");
    const [dueSettlementModal, setDueSettlementModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [access_level, setAccessLevel] = useState(localStorage.getItem("accessLevel"));
    const [adminDetails, setAdminDetails] = useState(JSON.parse(localStorage.getItem("user")));
    const [unauthorizeSettlement, setUnauthorizeSettlement] = useState(false)

    // const getwithdraw_request = async () => {
    //     let current = this;
    //     // current.setState({ loading: true });
    //     setloading(true);
    //     client.post(api.transactions_list, {type : 4}, async function (error, response) {
    //         if (!error) {
    //             //console.log(JSON.stringify(response.result.data));
    //             //   return false;
    //             if (typeof response.error !== "undefined") {
    //                 try {
    //                     if (!response.error) {
    //                         if (response.result.data) {
    //                             const unpaidsettlement = response.result.data.reverse().filter(el=>el.settlement_data.status == 0);
    //                             const remainingsettlement = response.result.data.filter(el=>el.settlement_data.status != 0);
    //                             const settlement = [...unpaidsettlement, ...remainingsettlement];
    //                             setresult(settlement)
    //                             //setresult(response.result.data.reverse());
    //                         }
    //                     } else {
    //                         toast.error(response.message);
    //                     }
    //                 } catch (e) {
    //                     toast.error("Internal error occured. Please contact support");
    //                 }
    //             } else {
    //                 toast.error("Internal error occured. Please contact support");
    //             }
    //         } else {
    //             toast.error("Internal error occured. Please contact support");
    //             // _showToast.error("Internal error occured. Please contact support.");
    //         }
    //         // current.setState({ loading: false });
    //         setloading(false);

    //     });
    // };

    const refreshPage = () => {
        window.location.reload(false);
    }


    useEffect(() => {
        fetchCountries()
        // alert("hrer");
        // getwithdraw_request();
    }, [])

    const statusClick = (id) => {
        if (access_level == 1) {
            toast.error("Not allowed to modify")
        } else {
            setSelectedId(id);
            setDueSettlementModal(!dueSettlementModal)
        }
    }

    const toggleDueSettlementModal = () => {
        setDueSettlementModal(!dueSettlementModal);
        setUnauthorizeSettlement(false)
    }

    const unauthorizeSettlementClick = () => {
        setUnauthorizeSettlement(true)
    }

    const onSettlementDueStatusChange = (event) => {
        event.preventDefault();
        let postData = { id: selectedId, status: unauthorizeSettlement ? 3 : 1, settled_by: adminDetails.full_name };
        client.post(api.dueSettlementStatus, postData, (error, response) => {
            if (!error) {
                // console.log(JSON.stringify(response.result.data));
                //   return false;
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            refreshPage();
                            toast.success(response.message)

                        } else {
                            toast.error(response.message);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
                // _showToast.error("Internal error occured. Please contact support.");
            }
            // current.setState({ loading: false });
            setloading(false);

        });
    }

    const renderDueSettlementModal = () => {

        return (
            <Modal isOpen={dueSettlementModal} toggle={toggleDueSettlementModal} className="modal-body" centered={true}>
                <form className="theme-form" noValidate="" onSubmit={onSettlementDueStatusChange}>
                    <ModalHeader toggle={toggleDueSettlementModal}><h6>Change due settlement status</h6></ModalHeader>
                    <ModalBody>
                        <ModalBody style={{ padding: "16px 0" }}>
                            {!unauthorizeSettlement && <h6>Have you settled the due amount and want to change status to paid ?</h6>}
                            {unauthorizeSettlement && <h6>Are you sure you want to unauthorize settlement requests ?</h6>}
                        </ModalBody>
                        <ModalFooter>
                            {unauthorizeSettlement && <Button color="secondary" onClick={toggleDueSettlementModal}>Not sure!</Button>}
                            {!unauthorizeSettlement && <Button color="secondary" onClick={unauthorizeSettlementClick}>Unauthorize</Button>}
                            <Button color="primary" type="submit"  >Yes Please!</Button>
                        </ModalFooter>
                    </ModalBody>
                </form>
            </Modal>
        );
    }

    const moveElement=(array)=> {

        let arrangeCountry = [...array];

        let australia = arrangeCountry.map((e) => e.country_code).indexOf('AU');
        if (australia != -1) {
            arrangeCountry.splice(0, 0, arrangeCountry.splice(australia, 1)[0]);
        }

        let japanIdx = arrangeCountry.map((e) => e.country_code).indexOf('JP');
        if (japanIdx != -1) {
            arrangeCountry.splice(1, 0, arrangeCountry.splice(japanIdx, 1)[0]);
        }

        let thailand = arrangeCountry.map((e) => e.country_code).indexOf('TH');
        if (thailand != -1) {
            arrangeCountry.splice(2, 0, arrangeCountry.splice(thailand, 1)[0]);
        }

        let unitedState = arrangeCountry.map((e) => e.country_code).indexOf('US');
        if (unitedState != -1) {
            arrangeCountry.splice(3, 0, arrangeCountry.splice(unitedState, 1)[0]);
        }

        let india = arrangeCountry.map((e) => e.country_code).indexOf('IN');
        if (india != -1) {
            arrangeCountry.splice(4, 0, arrangeCountry.splice(india, 1)[0]);
        }

        return arrangeCountry;
    }

    const fetchCountries = () => {

        client.post(api.fetch_country, { show_all: true }, async function (error, response) {

            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {

                            let getCountryCode = [];
                            let arrangeCountry = [...response.result.data];
                            arrangeCountry = await moveElement(arrangeCountry);
                            // current.setState({ country: arrangeCountry });
                            setcountry(arrangeCountry)

                            // arrangeCountry.length > 0 && arrangeCountry.map((countryData) => {
                            //     getCountryCode.push(countryData.country_code)
                            //     return countryData;
                            // });


                            // current.getCustomersCountByCountry(getCountryCode);
                        }
                    } catch (err) {
                        // console.log(err, "fetch error")
                        // toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    // toast.error("Internal error occured. Please contact support");
                }
            } else {
                // toast.error("Internal error occured. Please contact support");
            }
        });
    }

    useEffect(()=>{
        transMyRef.current.getAllTransactions(5, 1, defaultCountryCode);
    }, [defaultCountryCode])

    return (
        <Fragment>
            <div className="col-lg-7 col-sm-12" style={{ fontSize: "12px" }}>
                <Breadcrumb title={"Withdraw requests"} parent={"Home"} /></div>
            {selectedId && renderDueSettlementModal()}
            <div className="container-fluid">
                <div className="row theme-tab">
                    {
                        country.length > 0 && <Tabs
                            className="col-sm-12 merchant_req"
                            // defaultIndex={this.state.countryTabIndex}
                            style={{
                                backgroundColor: "white",
                                borderRadius: "3px",
                                border: "1px solid rgb(223, 232, 241)",
                                borderBottom: "none",
                                marginBottom: "0px",
                            }}
                            onSelect={(index) => { setcountryTabIndex(index) }}
                        >

                            <TabList className="tabs tab-title" style={{ borderBottom: "none" }}>
                                <div
                                        style={{ display: "flex", alignItems: "center", padding: "10px 15px" }}
                                        onClick={() => setdefaultCountryCode('All')}
                                        className={defaultCountryCode == "All" ? 'selectedTabStyle' : 'tabStyle'}
                                    >
                                        All
                                    </div>
                                {
                                    country.length > 0 && country.slice(0, 5).map((countryData) => {

                                        return (
                                            <>
                                                <div
                                                    className={countryData.country_code == defaultCountryCode ? 'selectedTabStyle' : 'tabStyle'}
                                                    style={{ display: "flex", alignItems: "center", padding: "10px 15px" }}
                                                    onClick={() => setdefaultCountryCode(countryData.country_code)}
                                                >
                                                    {countryData.country_name}
                                                </div>
                                            </>
                                        )
                                    })
                                }

                                <Dropdown style={{ margin: "auto 0px" }}>
                                    <Dropdown.Toggle
                                        id=""
                                        className={countryTabIndex == 6 ? 'selectDropDownBtnStyle' : 'dropDownBtnStyle'}
                                        style={{ color: "#000" }}
                                    >
                                        Others
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {
                                            country.length > 5 && country.slice(5, country.length).map((countryData) => {

                                                return (
                                                    <>
                                                        <Dropdown.Item className={countryData.country_code == defaultCountryCode ? 'selectedDropDownStyle' : "dropDownStyle"} onClick={() => setdefaultCountryCode(countryData.country_code)}>
                                                            {countryData.country_name}
                                                        </Dropdown.Item>
                                                    </>
                                                )
                                            })
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </TabList>
                        </Tabs>
                    }

                    <Tabs className="col-sm-12" defaultIndex={tabIndex} style={{ backgroundColor: "white", borderRadius: "3px", marginBottom: "0px", border: '1px solid #dfe8f1', }} onSelect={index => setTabIndex(index)}>
                        <TabList className="tabs tab-title">
                            <Tab style={{ display: "flex", alignItems: "center" }} >
                                Merchant
                            </Tab>

                            <Tab style={{ display: "flex", alignItems: "center" }}>
                                Customer
                            </Tab>
                        </TabList>

                        {/* {result ? */}
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12 col-xl-12 xl-100">
                                    <div className="card">
                                        <div className="card-body" style={{ padding: "1rem" }}>
                                            <div className="tab-content-cls">
                                                <TabPanel>
                                                    <RenderTransactions
                                                        deleteSelectedClick={() => { }}
                                                        imageClicked={() => { }}
                                                        receiptClick={() => { }}
                                                        // transactions={result.filter(el => {
                                                        //     return el.from_wallet_type == 2
                                                        // })}
                                                        filter_type={'settlement_merchant'}

                                                        authorizeTopupClick={() => { }}
                                                        hideTo={true}
                                                        hideAction={true}
                                                        hideReceipt={true}
                                                        hideMessage={true}
                                                        hideFilter={true}
                                                        hideBankDetails={false}
                                                        hidetransferFee={true}
                                                        hidedue={true}
                                                        settlementPage={true}
                                                        activeTab={5}
                                                        hideReason={true}
                                                        hidedescription={true}
                                                        queryData={props.location.state}
                                                        defaultCountryCode={defaultCountryCode}
                                                        ref={transMyRef}

                                                    />
                                                    {/* <div className="table-responsive" >
                                                            <table className="table table-border-horizontal table-striped table-light table-striped table-light">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">#</th>
                                                                        <th scope="col">Transaction Id</th>
                                                                        <th scope="col" style={{ width: "10%" }}>
                                                                            <p>Date</p>
                                                                            <p>Time</p></th>
                                                                        <th scope="col">Merchant Name</th>
                                                                        <th scope="col">Amount</th>
                                                                        <th scope="col">Tansfer Fee</th>
                                                                        <th scope="col">Due</th>
                                                                        <th scope="col" style={{ width: "13%" }}>Bank details</th>
                                                                        <th scope="col" style={{ width: "10%" }}>
                                                                            <p>Settled Date</p>
                                                                            <p>Time</p></th>
                                                                        <th scope="col">Settled By</th>
                                                                        <th scope="col">Status</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {result && result.length > 0 &&
                                                                        result.filter(item => item.account_type == 2).map((item, index) => (
                                                                            <tr key={index + 1}>
                                                                                <th>{++index}</th>
                                                                                <td>
                                                                                    {item.merchant_details.length == 1 ?
                                                                                        <NavLink to={{
                                                                                            pathname: `${process.env.PUBLIC_URL}/merchants/merchantDetails/${item.merchant_details[0]._id}`,
                                                                                            search: "?activeTab=0"
                                                                                        }}>
                                                                                            {item.token_id}
                                                                                        </NavLink>
                                                                                        : item.token_id}
                                                                                </td>
                                                                                <td style={{ width: "10%" }}>{moment.tz(item.created_date, "Australia/Sydney").format('DD/MM/YYYY HH:MM:SS')}</td>

                                                                                <td>{item.merchant_details.length == 1 ?
                                                                                    <NavLink to={{
                                                                                        pathname: `${process.env.PUBLIC_URL}/merchants/merchantDetails/${item.merchant_details[0]._id}`,
                                                                                        search: "?activeTab=0"
                                                                                    }}>
                                                                                        {item.merchant_details[0].business_name}
                                                                                    </NavLink>
                                                                                    : ""}</td>
                                                                                <td>${item.amount.toFixed(2)}
                                                                                </td>
                                                                                <td>${item.charges.toFixed(2)}</td>
                                                                                <td>${item.pay_amount.toFixed(2)}</td>
                                                                                <td style={{ width: "13%" }}>
                                                                                    <li>{item.merchant_details.length == 1 ? item.merchant_details[0].bsb_no ? item.merchant_details[0].bsb_no : <span style={{ fontSize: "0.68rem" }}>N.A</span> : <span style={{ fontSize: "0.68rem" }}>N.A</span>}</li>
                                                                                    <li>{item.merchant_details.length == 1 ? item.merchant_details[0].account_no ? item.merchant_details[0].account_no : <span style={{ fontSize: "0.68rem" }}>N.A</span> : <span style={{ fontSize: "0.68rem" }}>N.A</span>}</li>
                                                                                    <li>{item.merchant_details.length == 1 ? item.merchant_details[0].account_name ? item.merchant_details[0].account_name : <span style={{ fontSize: "0.68rem" }}>N.A</span> : <span style={{ fontSize: "0.68rem" }}>N.A</span>}</li>
                                                                                    <li>{item.merchant_details.length == 1 ? item.merchant_details[0].account_bank_name ? item.merchant_details[0].account_bank_name : <span style={{ fontSize: "0.68rem" }}>N.A</span> : <span style={{ fontSize: "0.68rem" }}>N.A</span>}</li>
                                                                                </td>
                                                                                <td style={{ width: "10%" }}>{item.status == 1 || item.status == 3 ? moment.tz(item.settled_date, "Australia/Sydney").format('DD/MM/YYYY HH:MM:SS') : null}</td>
                                                                                <td>{item.settled_by == null ? "" : new Date(item.created_date) >= new Date("09/28/2020")  ? item.settled_by : "David.M"}</td>
                                                                                <td>{item.status == 1 ? <span >Paid</span> : item.status == 3 ? <span >Unauthorized</span> : <span style={{ cursor: "pointer" }} onClick={() => statusClick(item._id)}>Unpaid</span>}</td>
                                                                            </tr>
                                                                        ))}

                                                                </tbody>
                                                            </table>
                                                        </div> */}
                                                </TabPanel>

                                                <TabPanel>
                                                    <RenderTransactions
                                                        deleteSelectedClick={() => { }}
                                                        imageClicked={() => { }}
                                                        receiptClick={() => { }}
                                                        // transactions={result.filter(el => {
                                                        //     return el.from_wallet_type == 1
                                                        // })}
                                                        filter_type={'settlement_customer'}

                                                        authorizeTopupClick={() => { }}
                                                        hideTo={true}
                                                        hideAction={true}
                                                        hideReceipt={true}
                                                        hideMessage={true}
                                                        hideFilter={true}
                                                        hideBankDetails={false}
                                                        hidetransferFee={true}
                                                        hidedue={true}
                                                        activeTab={5}
                                                        settlementPage={true}
                                                        hideReason={true}
                                                        queryData={props.location.state}
                                                        defaultCountryCode={defaultCountryCode}
                                                        ref={transMyRef}
                                                    />

                                                    {/* <div className="table-responsive" >
                                                            <table className="table table-border-horizontal table-striped table-light table-striped table-light">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">#</th>
                                                                        <th scope="col">Transaction Id</th>
                                                                        <th scope="col" style={{ width: "10%" }}>
                                                                            <p>Date</p>
                                                                            <p>Time</p>
                                                                        </th>
                                                                        <th scope="col">Customer Name</th>
                                                                        <th scope="col">Amount</th>
                                                                        <th scope="col">Tansfer Fee</th>
                                                                        <th scope="col">Due</th>
                                                                        <th scope="col" style={{ width: "13%" }}>Bank details</th>
                                                                        <th scope="col" style={{ width: "10%" }}>
                                                                            <p>Settled Date</p>
                                                                            <p>Time</p>
                                                                        </th>
                                                                        <th scope="col">Settled By</th>
                                                                        <th scope="col">Status</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {result && result.length > 0 &&
                                                                        result.filter(item => item.account_type == 1).map((item, index) => (
                                                                            <tr key={index + 1}>
                                                                                <th>{++index}</th>
                                                                                <td>
                                                                                    {item.customer_details.length == 1 ?
                                                                                        <NavLink to={{
                                                                                            pathname: `${process.env.PUBLIC_URL}/customer/wallet/${item.customer_details[0]._id}`,
                                                                                            search: "?activeTab=0"
                                                                                        }}>
                                                                                            {item.token_id}
                                                                                        </NavLink>
                                                                                        : item.token_id}
                                                                                </td>
                                                                                <td style={{ width: "10%" }}>{moment.tz(item.created_date, "Australia/Sydney").format('DD/MM/YYYY HH:MM:SS')}</td>
                                                                                <td>{item.customer_details.length == 1 ?
                                                                                    <NavLink to={{
                                                                                        pathname: `${process.env.PUBLIC_URL}/customer/wallet/${item.customer_details[0]._id}`,
                                                                                        search: "?activeTab=0"
                                                                                    }}>
                                                                                        {item.customer_details[0].name}
                                                                                    </NavLink>
                                                                                    : ""}</td>
                                                                                <td>${item.amount.toFixed(2)}
                                                                                </td>
                                                                                <td>${item.charges.toFixed(2)}</td>
                                                                                <td>${item.pay_amount.toFixed(2)}</td>
                                                                                <td style={{ width: "13%" }}>
                                                                                    <li>{item.customer_details.length == 1 ? item.customer_details[0].bsb_no ? item.customer_details[0].bsb_no : <span style={{ fontSize: "0.68rem" }}>N.A</span> : <span style={{ fontSize: "0.68rem" }}>N.A</span>}</li>
                                                                                    <li>{item.customer_details.length == 1 ? item.customer_details[0].account_no ? item.customer_details[0].account_no : <span style={{ fontSize: "0.68rem" }}>N.A</span > : <span style={{ fontSize: "0.68rem" }}>N.A</span>}</li>
                                                                                    <li>{item.customer_details.length == 1 ? item.customer_details[0].account_name ? item.customer_details[0].account_name : <span style={{ fontSize: "0.68rem" }}>N.A</span> : <span style={{ fontSize: "0.68rem" }}>N.A</span>}</li>
                                                                                    <li>{item.customer_details.length == 1 ? item.customer_details[0].account_bank_name ? item.customer_details[0].account_bank_name : <span style={{ fontSize: "0.68rem" }}>N.A</span> : <span style={{ fontSize: "0.68rem" }}>N.A</span>}</li>
                                                                                </td>
                                                                                <td style={{ width: "10%" }}>{item.status == 1 || item.status == 3 ? moment.tz(item.settled_date, "Australia/Sydney").format('DD/MM/YYYY HH:MM:SS') : null}</td>
                                                                                <td>{item.settled_by == null ? "" :  new Date(item.created_date) >= new Date("09/28/2020") ? item.settled_by : "David.M"}</td>
                                                                                <td>{item.status == 1 ? <span >Paid</span> : item.status == 3 ? <span >Unauthorized</span> : <span style={{ cursor: "pointer" }} onClick={() => statusClick(item._id)}>Unpaid</span>}</td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    */}
                                                </TabPanel>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* : <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                              <div className="loader" style={{ display:"flex",alignItems:"center" }}>
                              <DisappearedLoading size="medium" color="#514F4E" />
                               </div> 
                               </div>} */}
                    </Tabs>
                </div>
            </div>
            {/* <div className="container-fluid">
                <div className="row">
                    {selectedId && renderDueSettlementModal()}

                    <div className="col-sm-12 col-xl-12">
                        <div className="row">

                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card">
                                        <div className="card-body">
                                            <Nav tabs className="nav-pills nav-primary">
                                                <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                                                    <NavLinkTab className={activeTab == "1" ? 'active nav-link' : ''} onClick={() => { setactiveTab("1") }} value='1'>
                                                        Merchant</NavLinkTab>
                                                </NavItem>


                                                <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                                                    <NavLinkTab className={activeTab == "2" ? 'active nav-link' : ''} onClick={() => { setactiveTab("2") }} value='2'>
                                                        Customer</NavLinkTab>
                                                </NavItem>

                                            </Nav>
                                            <TabContent activeTab={activeTab}>

                                                <TabPane tabId="1">

                                                    <div className="table-responsive" style={{ marginTop: 20, marginBottom: 20 }}>
                                                        <table className="table table-border-horizontal table-striped table-light table-striped table-light">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">Transaction Id</th>
                                                                    <th scope="col" style={{ width: "12%" }}>Date/Time</th>
                                                                    <th scope="col">Merchant Name</th>
                                                                    <th scope="col">Amount</th>
                                                                    <th scope="col">Tansfer Fee</th>
                                                                    <th scope="col">Due</th>
                                                                    <th scope="col">Settled Date/Time</th>
                                                                    <th scope="col">Settled By</th>
                                                                    <th scope="col">Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {result && result.length > 0 &&
                                                                    result.filter(item => item.account_type == 2).map((item, index) => (
                                                                        <tr key={index + 1}>
                                                                            <th>{++index}</th>
                                                                            <td>{item.token_id}</td>
                                                                            <td style={{ width: "12%" }}>{moment.tz(item.created_date, "Australia/Sydney").format('DD/MM/YYYY HH:MM')}</td>

                                                                            <td>{item.merchant_details.length == 1 ? item.merchant_details[0].business_name : ""}</td>
                                                                            <td>${item.amount.toFixed(2)}
                                                                            </td>
                                                                            <td>${item.charges.toFixed(2)}</td>
                                                                            <td>${item.pay_amount.toFixed(2)}</td>
                                                                            <td>{item.status == 1 ? moment.tz(item.settled_date, "Australia/Sydney").format('DD/MM/YYYY HH:MM:SS') : null}</td>
                                                                            <td>{item.settled_by}</td>
                                                                            <td>{item.status == 1 ? <span >Paid</span> : <span style={{ cursor: "pointer" }} onClick={() => statusClick(item._id)}>Unpaid</span>}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </TabPane>
                                                <TabPane tabId="2">

                                                    <div className="table-responsive" style={{ marginTop: 20, marginBottom: 20 }}>
                                                        <table className="table table-border-horizontal table-striped table-light table-striped table-light">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">Transaction Id</th>
                                                                    <th scope="col" style={{ width: "12%" }}>Date/Time</th>
                                                                    <th scope="col">Customer Name</th>
                                                                    <th scope="col">Amount</th>
                                                                    <th scope="col">Tansfer Fee</th>
                                                                    <th scope="col">Due</th>
                                                                    <th scope="col">Settled Date/Time</th>
                                                                    <th scope="col">Settled By</th>
                                                                    <th scope="col">Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {result && result.length > 0 &&
                                                                    result.filter(item => item.account_type == 1).map((item, index) => (
                                                                        <tr key={index + 1}>
                                                                            <th>{++index}</th>
                                                                            <td>{item.token_id}</td>
                                                                            <td style={{ width: "12%" }}>{moment.tz(item.created_date, "Australia/Sydney").format('DD/MM/YYYY HH:MM:SS')}</td>
                                                                            <td>{item.customer_details.length == 1 ? item.customer_details[0].name : ""}</td>
                                                                            <td>${item.amount.toFixed(2)}
                                                                            </td>
                                                                            <td>${item.charges.toFixed(2)}</td>
                                                                            <td>${item.pay_amount.toFixed(2)}</td>
                                                                            <td>{item.status == 1 ? moment.tz(item.settled_date, "Australia/Sydney").format('DD/MM/YYYY HH:MM:SS') : null}</td>
                                                                            <td>{item.settled_by}</td>
                                                                            <td>{item.status == 1 ? <span >Paid</span> : <span style={{ cursor: "pointer" }} onClick={() => statusClick(item._id)}>Unpaid</span>}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </TabPane>
                                            </TabContent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

        </Fragment>
    );
}

export default BorderTable;
