import React, { useState } from "react";
import {
	Input,
	Label,
	Form,
	FormGroup,
	Row,
	Col,
	Button,
	Popover,
	PopoverBody,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from "reactstrap";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";

// iMAGES IMPORTS
import welcome from "../../../assets/images/quizDropdown/welcome.png";
import welcomehover from "../../../assets/images/quizDropdown/welcomehover.png";

import questionimg from "../../../assets/images/Question.png";
import emoji from "../../../assets/images/emoji5star.png";

import single from "../../../assets/images/quizDropdown/single.png";
import singlehover from "../../../assets/images/quizDropdown/singlehover.png";

import multiplechoice from "../../../assets/images/quizDropdown/multiplechoice.png";
import multiplehover from "../../../assets/images/quizDropdown/multiplehover.png";

import picturescale from "../../../assets/images/quizDropdown/picturescale.png";
import picturehover from "../../../assets/images/quizDropdown/picturehover.png";

import opinionscale from "../../../assets/images/quizDropdown/opinionscale.png";
import opinionhover from "../../../assets/images/quizDropdown/opinionhover.png";

import rating from "../../../assets/images/quizDropdown/rating.png";
import ratinghover from "../../../assets/images/quizDropdown/ratinghover.png";

import "./AppSettings.css";

const SurveyForm = (props) => {
	// UseStates

	// state to open dropdown
	const [dropdown, setDropdown] = useState(false);

	// state to open welcome screen form group
	const [welcomebtn, setWelcomebtn] = useState(false);

	//state for text inside input
	const [welcometext, setWelcometext] = useState("");

	const [singlechoiceques, setSinglechoiceques] = useState("");
	const [singlechoice1, setSinglechoice1] = useState("");
	const [singlechoice2, setSinglechoice2] = useState("");
	const [singlechoice3, setSinglechoice3] = useState("");
	const [singlechoice4, setSinglechoice4] = useState("");

	const [multiplechoiceques, setMultiplechoiceques] = useState("");
	const [multiplechoice1, setMultiplechoice1] = useState("");
	const [multiplechoice2, setMultiplechoice2] = useState("");
	const [multiplechoice3, setMultiplechoice3] = useState("");
	const [multiplechoice4, setMultiplechoice4] = useState("");

	const [picturechoiceques, setPicturechoiceques] = useState("");
	const [picturechoice1, setPicturechoice1] = useState("");
	const [picturechoice2, setPicturechoice2] = useState("");
	const [picturechoice3, setPicturechoice3] = useState("");
	const [picturechoice4, setPicturechoice4] = useState("");

	const [opinionchoiceques, setOpinionchoiceques] = useState("");

	// state for picture icon toggle
	const [pictureins, setPictureins] = useState(false);

	const [openModal, setopenModal] = useState(false);

	const [singleChoice, setSingleChoice] = useState([]);

	const [multipleChoice, setMultipleChoice] = useState([]);

	const [pictureChoice, setPictureChoice] = useState([]);

	const [opinionChoice, setOpinionChoice] = useState([]);

	// const [choiceCont,setChoiceCont] =useState([]);

	const [ratingbtn, setRatingbtn] = useState(false);

	const [ratingques, setRatingques] = useState("");

	// const [welcomeChoiceBtn,setWelcomeChoiceBtn] = useState(false)

	const [singleChoiceBtn, setSingleChoiceBtn] = useState(false);

	const [multipleChoiceBtn, setMultipleChoiceBtn] = useState(false);

	const [pictureChoiceBtn, setPictureChoiceBtn] = useState(false);

	const [opinionChoiceBtn, setOpinionChoiceBtn] = useState(false);

	// const [ratingChoiceBtn,setRatingChoiceBtn] = useState(false)

	// Funct on btn (div) click for preview
	const welcomebtnclick = () => {
		setWelcomebtn(true);
		setSingleChoiceBtn(false);
		setMultipleChoiceBtn(false);
		setPictureChoiceBtn(false);
		setOpinionChoiceBtn(false);
	};

	const singlebtnclick = () => {
		setSingleChoiceBtn(true);
		setMultipleChoiceBtn(false);
		setPictureChoiceBtn(false);
		setOpinionChoiceBtn(false);
	};

	const multiplebtnclick = () => {
		setSingleChoiceBtn(false);
		setMultipleChoiceBtn(true);
		setPictureChoiceBtn(false);
		setOpinionChoiceBtn(false);
	};

	const picturebtnclick = () => {
		setPictureChoiceBtn(true);
		setSingleChoiceBtn(false);
		setMultipleChoiceBtn(false);
		setOpinionChoiceBtn(false);
	};

	const opinionbtnclick = () => {
		setOpinionChoiceBtn(true);
		setSingleChoiceBtn(false);
		setMultipleChoiceBtn(false);
		setPictureChoiceBtn(false);
	};

	const ratingbtnclick = () => {
		setSingleChoiceBtn(false);
		setMultipleChoiceBtn(false);
		setPictureChoiceBtn(false);
		setOpinionChoiceBtn(false);
		setRatingbtn(true);
	};

	// Func to open dropdown

	const openDropdown = () => {
		setDropdown(true);
	};

	// Func to open Welcome formgroup
	const welcomeclickfunc = () => {
		setDropdown(false);
		setWelcomebtn(true);
		setSingleChoiceBtn(false);
		setMultipleChoiceBtn(false);
		setPictureChoiceBtn(false);
		setOpinionChoiceBtn(false);
	};

	// onclick functs of dropdown divs respectively
	const singleclickfunc = (e) => {
		setDropdown(false);
		setSingleChoice([...singleChoice, "single choice"]);
		setSingleChoiceBtn(true);
		setMultipleChoiceBtn(false);
		setPictureChoiceBtn(false);
		setOpinionChoiceBtn(false);
	};

	const multipleclickfunc = (e) => {
		setDropdown(false);
		setMultipleChoice([...multipleChoice, "multiple choice"]);
		setSingleChoiceBtn(false);
		setMultipleChoiceBtn(true);
		setPictureChoiceBtn(false);
		setOpinionChoiceBtn(false);
	};

	const pictureclickfunc = (e) => {
		setDropdown(false);
		setPictureChoice([...pictureChoice, "picture choice"]);
		setSingleChoiceBtn(false);
		setMultipleChoiceBtn(false);
		setPictureChoiceBtn(true);
		setOpinionChoiceBtn(false);
	};

	const opinionclickfunc = (e) => {
		setDropdown(false);
		setOpinionChoice([...opinionChoice, "opinion choice"]);
		setSingleChoiceBtn(false);
		setMultipleChoiceBtn(false);
		setPictureChoiceBtn(false);
		setOpinionChoiceBtn(true);
	};

	// Func to open Welcome formgroup
	const ratingclickfunc = () => {
		setDropdown(false);
		setRatingbtn(true);
		setSingleChoiceBtn(false);
		setMultipleChoiceBtn(false);
		setPictureChoiceBtn(false);
		setOpinionChoiceBtn(false);
	};

	// Func when user clicks picture icon
	const pictureinsert = () => {
		setPictureins(true);
		document.querySelector(".imagecard").style.display = "block";
		setopenModal(true);
	};

	const pictureinsertchoice = () => {
		setPictureins(true);
		document.querySelector(".imagecardchoice").style.display = "block";
		setopenModal(true);
	};

	//Popover
	const [popoverOpen, setPopoverOpen] = useState(false);
	const Toggle = () => {
		setPopoverOpen(!popoverOpen);
	};

	// submit func for dropdown
	// const welcomesubmit =(ev) =>{
	//     ev.preventDefault();
	//     console.log(welcometext);
	// }

	// function for Dropdowns Screen

	// Function for welcome Screen option when hovered
	const welcomefunc = () => {
		document.querySelector(".hovereffectswelcome").style.display = "block";
	};
	const welcomeleave = () => {
		document.querySelector(".hovereffectswelcome").style.display = "none";
	};

	// Function for single choice Screen option when hovered
	const singlefunc = () => {
		document.querySelector(".hovereffectssingle").style.display = "block";
	};
	const singleleave = () => {
		document.querySelector(".hovereffectssingle").style.display = "none";
	};

	// Function for Multiple Screen option when hovered
	const multiplefunc = () => {
		document.querySelector(".hovereffectsmultiple").style.display = "block";
	};
	const multipleleave = () => {
		document.querySelector(".hovereffectsmultiple").style.display = "none";
	};

	// Function for picture Screen option when hovered
	const picturefunc = () => {
		document.querySelector(".hovereffectspicture").style.display = "block";
	};
	const pictureleave = () => {
		document.querySelector(".hovereffectspicture").style.display = "none";
	};
	// Function for Opinion Screen option when hovered
	const opinionfunc = () => {
		document.querySelector(".hovereffectsopinion").style.display = "block";
	};
	const opinionleave = () => {
		document.querySelector(".hovereffectsopinion").style.display = "none";
	};

	// Function for rating Screen option when hovered
	const ratingfunc = () => {
		document.querySelector(".hovereffectsrating").style.display = "block";
	};
	const ratingleave = () => {
		document.querySelector(".hovereffectsrating").style.display = "none";
	};

	//To render Preview respectively
	const renderPreviewtext = () => {
		if (pictureins) {
			if (welcometext.length == 0) {
				return <h6 style={{ marginLeft: "30%" }}>Preview</h6>;
			} else if (welcometext.length <= 20) {
				return <h5 style={{ textAlign: "center" }}>{welcometext}</h5>;
			} else {
				return <span className="previewtext">{welcometext}</span>;
			}
		} else {
			if (welcometext.length == 0) {
				return <h6 style={{ marginLeft: "30%" }}>Preview</h6>;
			} else if (welcometext.length <= 20) {
				return <h5 style={{ textAlign: "center" }}>{welcometext}</h5>;
			} else {
				return <span className="previewtext">{welcometext}</span>;
			}
		}
	};

	// render Single choice previews
	const renderSinglequestion = () => {
		if (singlechoiceques.length > 0 && singlechoiceques.length <= 16) {
			document.querySelector(".smartphone").style.height = "550px";
			return <h6>{singlechoiceques}</h6>;
		} else if (singlechoiceques.length > 0 && singlechoiceques.length <= 36) {
			document.querySelector(".smartphone").style.height = "600px";
			return <span style={{ fontSize: "18px" }}>{singlechoiceques}</span>;
		} else if (singlechoiceques.length > 0 && singlechoiceques.length <= 55) {
			document.querySelector(".smartphone").style.height = "650px";
			return <span>{singlechoiceques}</span>;
		} else if (singlechoiceques.length > 0 && singlechoiceques.length <= 75) {
			document.querySelector(".smartphone").style.height = "700px";
			document.querySelector(".headertitle").style.top = "5%";
			return <span>{singlechoiceques}</span>;
		} else if (singlechoiceques.length > 0) {
			document.querySelector(".smartphone").style.height = "780px";
			document.querySelector(".headertitle").style.top = "4%";
			return <span>{singlechoiceques}</span>;
		} else {
			document.querySelector(".smartphone").style.height = "550px";
			return <h6>Your question here</h6>;
		}
	};

	// render Multiple choice preview
	const renderMultiplequestion = () => {
		if (multiplechoiceques.length > 0 && multiplechoiceques.length <= 16) {
			document.querySelector(".smartphone").style.height = "550px";
			return <h6>{multiplechoiceques}</h6>;
		} else if (
			multiplechoiceques.length > 0 &&
			multiplechoiceques.length <= 36
		) {
			document.querySelector(".smartphone").style.height = "600px";
			return <span style={{ fontSize: "18px" }}>{multiplechoiceques}</span>;
		} else if (
			multiplechoiceques.length > 0 &&
			multiplechoiceques.length <= 55
		) {
			document.querySelector(".smartphone").style.height = "650px";
			document.querySelector(".headertitle").style.top = "5%";

			return <span>{multiplechoiceques}</span>;
		} else if (
			multiplechoiceques.length > 0 &&
			multiplechoiceques.length <= 75
		) {
			document.querySelector(".smartphone").style.height = "700px";
			document.querySelector(".headertitle").style.top = "5%";

			return <span>{multiplechoiceques}</span>;
		} else if (multiplechoiceques.length > 0) {
			document.querySelector(".smartphone").style.height = "780px";
			document.querySelector(".headertitle").style.top = "5%";

			return <span>{multiplechoiceques}</span>;
		} else {
			document.querySelector(".smartphone").style.height = "550px";
			return <h6>Your question here.</h6>;
		}
	};

	// render Picture choice
	const renderPicturequestion = () => {
		if (picturechoiceques.length > 0 && picturechoiceques.length <= 16) {
			document.querySelector(".smartphone").style.height = "620px";
			return <h6>{picturechoiceques}</h6>;
		} else if (picturechoiceques.length > 0 && picturechoiceques.length <= 36) {
			document.querySelector(".smartphone").style.height = "630px";
			return <span style={{ fontSize: "16px" }}>{picturechoiceques}</span>;
		} else if (picturechoiceques.length > 0 && picturechoiceques.length <= 55) {
			document.querySelector(".smartphone").style.height = "650px";
			document.querySelector(".headertitle").style.top = "5%";

			return <span>{picturechoiceques}</span>;
		} else if (picturechoiceques.length > 0 && picturechoiceques.length <= 75) {
			document.querySelector(".smartphone").style.height = "730px";
			document.querySelector(".headertitle").style.top = "5%";

			return <span>{picturechoiceques}</span>;
		} else if (picturechoiceques.length > 0) {
			document.querySelector(".smartphone").style.height = "750px";
			document.querySelector(".headertitle").style.top = "5%";

			return <span>{picturechoiceques}</span>;
		} else {
			document.querySelector(".smartphone").style.height = "620px";
			return <h6>Your question here</h6>;
		}
	};

	// render Opinion
	const renderOpiniontext = () => {
		if (opinionchoiceques.length > 0 && opinionchoiceques.length < 20) {
			document.querySelector(".smartphone").style.height = "550px";
			return <h6>{opinionchoiceques}</h6>;
		} else if (opinionchoiceques.length > 0 && opinionchoiceques.length < 40) {
			document.querySelector(".smartphone").style.height = "550px";
			document.querySelector(".headertitle").style.top = "5%";

			return <span style={{ fontSize: "20px" }}>{opinionchoiceques}</span>;
		} else if (opinionchoiceques.length > 0) {
			document.querySelector(".smartphone").style.height = "600px";
			document.querySelector(".headertitle").style.top = "5%";

			return <span>{opinionchoiceques}</span>;
		} else {
			document.querySelector(".smartphone").style.height = "550px";
			return <h6>Your question here</h6>;
		}
	};

	// render Rating text
	const renderRatingtext = () => {
		if (ratingques.length > 0 && ratingques.length <= 19) {
			document.querySelector(".smartphone").style.height = "550px";

			return <h6>{ratingques}</h6>;
		} else if (ratingques.length >= 19 && ratingques.length <= 38) {
			document.querySelector(".smartphone").style.height = "550px";
			document.querySelector(".ratingstar").style.top = "60%";
			document.querySelector(".smartphone").style.height = "550px";
			document.querySelector(".emojiimg").style.top = "68%";
			document.querySelector(".emojiTitle").style.top = "80%";

			return <span style={{ fontSize: "20px" }}>{ratingques}</span>;
		} else if (ratingques.length >= 39 && ratingques.length <= 54) {
			document.querySelector(".smartphone").style.height = "630px";
			document.querySelector(".ratingstar").style.top = "65%";
			document.querySelector(".emojiimg").style.top = "73%";
			document.querySelector(".emojiTitle").style.top = "84%";
			return <span style={{ fontSize: "16px" }}>{ratingques}</span>;
		} else if (ratingques.length > 54 && ratingques.length <= 80) {
			document.querySelector(".smartphone").style.height = "650px";
			document.querySelector(".ratingstar").style.top = "66%";
			document.querySelector(".headertitle").style.top = "5%";

			return <span>{ratingques}</span>;
		} else if (ratingques.length >= 80) {
			document.querySelector(".smartphone").style.height = "650px";
			document.querySelector(".ratingstar").style.top = "66%";
			document.querySelector(".headertitle").style.top = "5%";
			document.querySelector(".ratingstar").style.height = "3rem";
			document.querySelector(".emojiTitle").style.fontSize = "18px";

			return <span>{ratingques}</span>;
		} else {
			document.querySelector(".smartphone").style.height = "550px";
			return <h6>Your question here</h6>;
		}
	};

	//-----------------------------------------------------Render Smartphone----------------------------------------------------------------
	const renderSmartphone = () => {
		if (singleChoiceBtn) {
			return (
				<div className="smartphone">
					<div className="smartphoneheader">
						<h6 className="headertitle">
							<i className="fa fa-angle-left fa-lg"></i>&nbsp;&nbsp;iPayPro Play
						</h6>
					</div>
					<div className="smartphonebody">
						<div className="timercont">
							<div className="progresscont">
								<div class="progress progressdiv">
									<div
										class="progress-bar"
										role="progressbar"
										style={{ width: "50%", backgroundColor: "#3d90ab" }}
										aria-valuenow="50"
										aria-valuemin="0"
										aria-valuemax="100"
									></div>
								</div>
							</div>
						</div>

						<div className="imagecardsingle">
							<img
								className="imagecardimagesingle"
								src={questionimg}
								alt="Quiz"
							/>
						</div>
						<div class="centerchoice">
							{/* <h5 style={{color:"grey"}}>{welcometext.length==0?<h6 style={{marginLeft:"30%"}}>Preview</h6>:<span className="previewtext">{welcometext}</span>}</h5> */}
							{/* {renderPreviewtext()} */}
							{renderSinglequestion()}
							<Row>
								<div className="previewchoicediv">
									{singlechoice1.length > 0 ? (
										<h6>{singlechoice1}</h6>
									) : (
										<h6>Choice 1</h6>
									)}
								</div>
							</Row>
							<Row>
								<div className="previewchoicediv">
									{singlechoice2.length > 0 ? (
										<h6>{singlechoice2}</h6>
									) : (
										<h6>Choice 2</h6>
									)}
								</div>
							</Row>
							<Row>
								<div className="previewchoicediv">
									{singlechoice3.length > 0 ? (
										<h6>{singlechoice3}</h6>
									) : (
										<h6>Choice 3</h6>
									)}
								</div>
							</Row>
							<Row>
								<div className="previewchoicediv">
									{singlechoice4.length > 0 ? (
										<h6>{singlechoice4}</h6>
									) : (
										<h6>Choice 4</h6>
									)}
								</div>
							</Row>
						</div>
					</div>
					<div className="smartphonefooter">
						<button className="previewcontinuebtn">Continue</button>
					</div>
				</div>
			);
		} else if (multipleChoiceBtn) {
			return (
				<div className="smartphone">
					<div className="smartphoneheader">
						<h6 className="headertitle">
							<i className="fa fa-angle-left fa-lg"></i>&nbsp;&nbsp;iPayPro Play
						</h6>
					</div>
					<div className="smartphonebody">
						<div className="timercont">
							<div className="progresscont">
								<div class="progress progressdiv">
									<div
										class="progress-bar"
										role="progressbar"
										style={{ width: "50%", backgroundColor: "#3d90ab" }}
										aria-valuenow="50"
										aria-valuemin="0"
										aria-valuemax="100"
									></div>
								</div>
							</div>
						</div>
						<div className="imagecardsingle">
							<img
								className="imagecardimagesingle"
								src={questionimg}
								alt="Quiz"
							/>
						</div>
						<div class="centerchoice">
							{/* <h5 style={{color:"grey"}}>{welcometext.length==0?<h6 style={{marginLeft:"30%"}}>Preview</h6>:<span className="previewtext">{welcometext}</span>}</h5> */}
							{/* {renderPreviewtext()} */}
							{renderMultiplequestion()}
							<Row>
								<div className="previewchoicediv">
									{multiplechoice1.length > 0 ? (
										<h6>{multiplechoice1}</h6>
									) : (
										<h6>Choice 1</h6>
									)}
								</div>
							</Row>
							<Row>
								<div className="previewchoicediv">
									{multiplechoice2.length > 0 ? (
										<h6>{multiplechoice2}</h6>
									) : (
										<h6>Choice 2</h6>
									)}
								</div>
							</Row>
							<Row>
								<div className="previewchoicediv">
									{multiplechoice3.length > 0 ? (
										<h6>{multiplechoice3}</h6>
									) : (
										<h6>Choice 3</h6>
									)}
								</div>
							</Row>
							<Row>
								<div className="previewchoicediv">
									{multiplechoice4.length > 0 ? (
										<h6>{multiplechoice4}</h6>
									) : (
										<h6>Choice 4</h6>
									)}
								</div>
							</Row>
						</div>
					</div>
					<div className="smartphonefooter">
						<button className="previewcontinuebtn">Continue</button>
					</div>
				</div>
			);
		} else if (pictureChoiceBtn) {
			return (
				<div className="smartphone">
					<div className="smartphoneheader">
						<h6 className="headertitle">
							<i className="fa fa-angle-left fa-lg"></i>&nbsp;&nbsp;iPayPro Play
						</h6>
					</div>
					<div className="smartphonebody">
						<div className="timercont">
							<div className="progresscont">
								<div class="progress progressdiv">
									<div
										class="progress-bar"
										role="progressbar"
										style={{ width: "50%", backgroundColor: "#3d90ab" }}
										aria-valuenow="50"
										aria-valuemin="0"
										aria-valuemax="100"
									></div>
								</div>
							</div>
						</div>
						<div className="imagepicturechoice">
							<img
								className="imagecardimagesingle"
								src={questionimg}
								alt="Quiz"
							/>
						</div>
						<div className="centerpicturechoice">
							{/* <h5 style={{color:"grey"}}>{welcometext.length==0?<h6 style={{marginLeft:"30%"}}>Preview</h6>:<span className="previewtext">{welcometext}</span>}</h5> */}
							{/* {renderPreviewtext()} */}
							{renderPicturequestion()}
							<table>
								<tr>
									<td>
										<img
											src={welcomehover}
											alt="choice 1"
											className="imagechoice1"
										/>
										{picturechoice1.length > 0 ? (
											<h6
												className="choicecaption1"
												style={{ marginLeft: "-20px !important" }}
											>
												{picturechoice1}
											</h6>
										) : (
											<h6
												className="choicecaption1"
												style={{ marginLeft: "-23px !important" }}
											>
												Choice 1
											</h6>
										)}
									</td>
									<td>
										<img
											src={welcomehover}
											alt="choice 2"
											className="imagechoice2"
										/>
										{picturechoice2.length > 0 ? (
											<h6 className="choicecaption2">{picturechoice2}</h6>
										) : (
											<h6
												className="choicecaption2"
												style={{ marginLeft: "-23px !important" }}
											>
												Choice 2
											</h6>
										)}
									</td>
								</tr>
								<tr>
									<td>
										<img
											src={welcomehover}
											alt="choice 3"
											className="imagechoice1"
										/>
										{picturechoice3.length > 0 ? (
											<h6
												className="choicecaption1"
												style={{ marginLeft: "-20px !important" }}
											>
												{picturechoice3}
											</h6>
										) : (
											<h6
												className="choicecaption1"
												style={{ marginLeft: "-23px !important" }}
											>
												Choice 3
											</h6>
										)}
									</td>
									<td>
										<img
											src={welcomehover}
											alt="choice 4"
											className="imagechoice2"
										/>
										{picturechoice4.length > 0 ? (
											<h6 className="choicecaption2">{picturechoice4}</h6>
										) : (
											<h6
												className="choicecaption2"
												style={{ marginLeft: "-23px !important" }}
											>
												Choice 4
											</h6>
										)}
									</td>
								</tr>
							</table>
						</div>
					</div>
					<div className="smartphonefooter">
						<button className="previewcontinuebtn">Continue</button>
					</div>
				</div>
			);
		} else if (opinionChoiceBtn) {
			return (
				<div className="smartphone">
					<div className="smartphoneheader">
						<h6 className="headertitle">
							<i className="fa fa-angle-left fa-lg"></i>&nbsp;&nbsp;iPayPro Play
						</h6>
					</div>
					<div className="smartphonebody">
						<div className="timercont">
							<div className="progresscont">
								<div class="progress progressdiv">
									<div
										class="progress-bar"
										role="progressbar"
										style={{ width: "50%", backgroundColor: "#3d90ab" }}
										aria-valuenow="50"
										aria-valuemin="0"
										aria-valuemax="100"
									></div>
								</div>
							</div>
						</div>
						<div className="imagecardsingle">
							<img
								className="imagecardimagesingle"
								src={questionimg}
								alt="Quiz"
							/>
						</div>
						<div class="centerchoice">
							{/* <h5 style={{color:"grey"}}>{welcometext.length==0?<h6 style={{marginLeft:"30%"}}>Preview</h6>:<span className="previewtext">{welcometext}</span>}</h5> */}
							{renderOpiniontext()}
							<Row className="opinioncontrow">
								<Col className="opinioncont">1</Col>
								<Col className="opinioncont">2</Col>
								<Col className="opinioncont">3</Col>
								<Col className="opinioncont">4</Col>
								<Col className="opinioncont">5</Col>
							</Row>
						</div>
					</div>
					<div className="smartphonefooter">
						<button className="previewcontinuebtn">Continue</button>
					</div>
				</div>
			);
		} else if (ratingbtn) {
			return (
				<div className="smartphone">
					<div className="smartphoneheader">
						<h6 className="headertitle">
							<i className="fa fa-angle-left fa-lg"></i>&nbsp;&nbsp;iPayPro Play
						</h6>
					</div>
					<div className="smartphonebody">
						<div className="timercont">
							<div className="progresscont">
								<div class="progress progressdiv">
									<div
										class="progress-bar"
										role="progressbar"
										style={{ width: "50%", backgroundColor: "#3d90ab" }}
										aria-valuenow="50"
										aria-valuemin="0"
										aria-valuemax="100"
									></div>
								</div>
							</div>
						</div>
						<div className="imagecardsingle">
							<img
								className="imagecardimagesingle"
								src={questionimg}
								alt="Quiz"
							/>
						</div>
						<div class="centerchoice">
							{/* <h5 style={{color:"grey"}}>{welcometext.length==0?<h6 style={{marginLeft:"30%"}}>Preview</h6>:<span className="previewtext">{welcometext}</span>}</h5> */}
							{renderRatingtext()}
						</div>
						<div>
							<span className="ratingstar">
								<i
									className="fa fa-star font-warning fa-3x"
									style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}
								></i>
								<i
									className="fa fa-star font-warning fa-3x"
									style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}
								></i>
								<i
									className="fa fa-star font-warning fa-3x"
									style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}
								></i>
								<i
									className="fa fa-star font-warning fa-3x"
									style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}
								></i>
								<i
									className="fa fa-star font-warning fa-3x"
									style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}
								></i>
							</span>
							<div className="emojidiv">
								<img className="emojiimg" src={emoji} alt="excellent" />
								<h6 className="emojiTitle">Excellent</h6>
							</div>
						</div>
					</div>
					<div className="smartphonefooter">
						<button className="previewcontinuebtn">Continue</button>
					</div>
				</div>
			);
		} else if (welcomebtn) {
			return (
				<div className="smartphone">
					<div className="smartphoneheader">
						<h6 className="headertitle">
							<i className="fa fa-angle-left fa-lg"></i>&nbsp;&nbsp;iPayPro Play
						</h6>
					</div>
					<div className="smartphonebody">
						<div className="imagecard">
							<img
								className="imagecardimage"
								src={welcomehover}
								alt="welcome"
							/>
						</div>
						<div class="center">
							{/* <h5 style={{color:"grey"}}>{welcometext.length==0?<h6 style={{marginLeft:"30%"}}>Preview</h6>:<span className="previewtext">{welcometext}</span>}</h5> */}
							{renderPreviewtext()}
						</div>
					</div>
					<div className="smartphonefooter">
						<button className="previewcontinuebtn">Continue</button>
					</div>
				</div>
			);
		} else {
			return (
				<div className="smartphone">
					<div className="smartphoneheader">
						<h6 className="headertitle">
							<i className="fa fa-angle-left fa-lg"></i>&nbsp;&nbsp;iPayPro Play
						</h6>
					</div>
					<div className="smartphonebody">
						<div className="imagecard">
							<img
								className="imagecardimage"
								src={welcomehover}
								alt="welcome"
							/>
						</div>
						<div class="center">
							{/* <h5 style={{color:"grey"}}>{welcometext.length==0?<h6 style={{marginLeft:"30%"}}>Preview</h6>:<span className="previewtext">{welcometext}</span>}</h5> */}
							{renderPreviewtext()}
						</div>
					</div>
					<div className="smartphonefooter">
						<button className="previewcontinuebtn">Continue</button>
					</div>
				</div>
			);
		}
	};

	// funcs for image upload
	const getUploadParams = ({ meta }) => {
		return { url: "https://httpbin.org/post" };
	};
	const handleChangeStatus = ({ meta, file }, status) => {
		console.log(status, meta, file);
	};
	const handleSubmit = (files) => {
		console.log(files.map((f) => f.meta));
	};

	//main return
	return (
		// <div className="card">
		// <div className="card-header">
		// <Tabs>
		//         <TabList className="tbs tab-title" >
		//         <Col md={3}>
		//         <h6 style={{fontSize:"14px",marginTop:"10px"}}><i className= "icon-arrow-left" style={{color:"black"}}></i> &nbsp;&nbsp;Survey</h6>
		//         </Col>
		//         <Col md={9}>
		//             <Tab style={{marginLeft:"22%"}}>
		//                 CREATE
		//             </Tab>
		//             <Tab>
		//                 PUBLISH
		//             </Tab>
		//             <Tab>
		//                 RESULTS
		//             </Tab>
		//         </Col>
		//         </TabList>
		//    </Tabs>
		// </div>
		<div className="card-body">
			<Row>
				<Col style={{ borderRight: "1px solid rgb(207 207 207 / 70%)" }}>
					<Row>
						<Col md={12} className="Column1">
							<FormGroup>
								{/*--------------------------------------------- WELCOME BUTTON DIVISION------------------------------------------------- */}
								{welcomebtn ? (
									<div className="card" onClick={welcomebtnclick}>
										<div className="card-body">
											<Row>
												<Col md={1}>
													<img
														src={welcome}
														alt="Welcome Screen"
														className="logomultiple"
													/>
												</Col>
												<Col>
													<input
														type="text"
														placeholder="Say hi!"
														className="multipleinput"
														maxlength="100"
														autoComplete="off"
														onChange={(event) => {
															setWelcometext(event.target.value);
														}}
													/>
												</Col>
												<Col md={2}>
													<h6 className="lengthcounter">
														{" "}
														{welcometext.length}/100
													</h6>
												</Col>
											</Row>
										</div>
										<div className="card-footer">
											<div className="iconscont">
												<i
													className="fa fa-picture-o fa-lg"
													onClick={pictureinsert}
												></i>
												&nbsp;&nbsp;
												<i
													class="fa fa-ellipsis-h fa-lg"
													aria-hidden="true"
													id="Popover1"
												></i>
												<Modal isOpen={openModal}>
													<ModalHeader>Image Uploader</ModalHeader>
													<ModalBody>
														<Dropzone
															getUploadParams={getUploadParams}
															onChangeStatus={handleChangeStatus}
															// onSubmit={handleSubmit}
															accept="image/*"
															maxFiles={1}
															multiple={false}
														/>
													</ModalBody>
													<ModalFooter>
														<Button
															className="btn btn-danger"
															onClick={() => {
																setopenModal(false);
															}}
														>
															Cancel
														</Button>
														<Button className="btn btn-primary">Proceed</Button>
													</ModalFooter>
												</Modal>
												<Popover
													placement="bottom"
													isOpen={popoverOpen}
													target="Popover1"
													toggle={Toggle}
												>
													<PopoverBody>
														<i
															style={{
																color: "red",
																fontSize: "14px",
																margin: "0 7px 0 0",
																cursor: "pointer",
															}}
															className="fa fa-trash-o"
														></i>
														Delete
													</PopoverBody>
												</Popover>
											</div>
										</div>
									</div>
								) : null}

								{/*--------------------------------------------- SINGLE CHOICE BUTTON DIVISION------------------------------------------------- */}

								{singleChoice.map(() => (
									<div className="card">
										<div className="card-body" onClick={singlebtnclick}>
											<Row>
												<Col md={1}>
													<img
														src={single}
														alt="multiple choice"
														className="logomultiple"
													/>
												</Col>
												<Col>
													<input
														type="text"
														placeholder="Your question here."
														className="multipleinput"
														onChange={(event) => {
															setSinglechoiceques(event.target.value);
														}}
														maxlength="100"
													/>
												</Col>
											</Row>
											<Row>
												<Col md={1}>
													<h6 className="hyphencss"> -</h6>
												</Col>
												<Col>
													<input
														type="text"
														placeholder="Choice 1"
														className="multipleinput"
														maxlength="20"
														onChange={(event) => {
															setSinglechoice1(event.target.value);
														}}
													/>
												</Col>
											</Row>
											<Row>
												<Col md={1}>
													<h6 className="hyphencss"> -</h6>
												</Col>
												<Col>
													<input
														type="text"
														placeholder="Choice 2"
														className="multipleinput"
														maxlength="20"
														onChange={(event) => {
															setSinglechoice2(event.target.value);
														}}
													/>
												</Col>
											</Row>
											<Row>
												<Col md={1}>
													<h6 className="hyphencss"> -</h6>
												</Col>
												<Col>
													<input
														type="text"
														placeholder="Choice 3"
														className="multipleinput"
														maxlength="20"
														onChange={(event) => {
															setSinglechoice3(event.target.value);
														}}
													/>
												</Col>
											</Row>
											<Row>
												<Col md={1}>
													<h6 className="hyphencss"> -</h6>
												</Col>
												<Col>
													<input
														type="text"
														placeholder="Choice 4"
														className="multipleinput"
														maxlength="20"
														onChange={(event) => {
															setSinglechoice4(event.target.value);
														}}
													/>
												</Col>
											</Row>
										</div>
										<div className="card-footer">
											<div className="iconscont">
												<i
													className="fa fa-picture-o fa-lg"
													onClick={pictureinsertchoice}
												></i>
												&nbsp;&nbsp;
												<i
													class="fa fa-ellipsis-h fa-lg"
													aria-hidden="true"
													id="Popover1"
												></i>
												<Modal isOpen={openModal}>
													<ModalHeader>Image Uploader</ModalHeader>
													<ModalBody>
														<Dropzone
															getUploadParams={getUploadParams}
															onChangeStatus={handleChangeStatus}
															// onSubmit={handleSubmit}
															accept="image/*"
															maxFiles={1}
															multiple={false}
														/>
													</ModalBody>
													<ModalFooter>
														<Button
															className="btn btn-danger"
															onClick={() => {
																setopenModal(false);
															}}
														>
															Cancel
														</Button>
														<Button className="btn btn-primary">Proceed</Button>
													</ModalFooter>
												</Modal>
												<Popover
													placement="bottom"
													isOpen={popoverOpen}
													target="Popover1"
													toggle={Toggle}
												>
													<PopoverBody>
														<i
															style={{
																color: "red",
																fontSize: "14px",
																margin: "0 7px 0 0",
																cursor: "pointer",
															}}
															className="fa fa-trash-o"
														></i>
														Delete
													</PopoverBody>
												</Popover>
											</div>
										</div>
									</div>
								))}

								{/*--------------------------------------------- MULTIPLE BUTTON DIVISION------------------------------------------------- */}

								{multipleChoice.map(() => (
									<div className="card" onClick={multiplebtnclick}>
										<div className="card-body">
											<Row>
												<Col md={1}>
													<img
														src={multiplechoice}
														alt="multiple choice"
														className="logomultiple"
													/>
												</Col>
												<Col>
													<input
														type="text"
														placeholder="Your question here."
														className="multipleinput"
														maxlength="110"
														onChange={(event) => {
															setMultiplechoiceques(event.target.value);
														}}
													/>
												</Col>
											</Row>
											<Row>
												<Col md={1}>
													<h6 className="hyphencss"> -</h6>
												</Col>
												<Col>
													<input
														type="text"
														placeholder="Choice 1"
														className="multipleinput"
														maxlength="18"
														onChange={(event) => {
															setMultiplechoice1(event.target.value);
														}}
													/>
												</Col>
											</Row>
											<Row>
												<Col md={1}>
													<h6 className="hyphencss"> -</h6>
												</Col>
												<Col>
													<input
														type="text"
														placeholder="Choice 2"
														className="multipleinput"
														maxlength="18"
														onChange={(event) => {
															setMultiplechoice2(event.target.value);
														}}
													/>
												</Col>
											</Row>
											<Row>
												<Col md={1}>
													<h6 className="hyphencss"> -</h6>
												</Col>
												<Col>
													<input
														type="text"
														placeholder="Choice 3"
														className="multipleinput"
														maxlength="18"
														onChange={(event) => {
															setMultiplechoice3(event.target.value);
														}}
													/>
												</Col>
											</Row>
											<Row>
												<Col md={1}>
													<h6 className="hyphencss"> -</h6>
												</Col>
												<Col>
													<input
														type="text"
														placeholder="Choice 4"
														className="multipleinput"
														maxlength="18"
														onChange={(event) => {
															setMultiplechoice4(event.target.value);
														}}
													/>
												</Col>
											</Row>
										</div>
										<div className="card-footer">
											<div className="iconscont">
												<i
													className="fa fa-picture-o fa-lg"
													onClick={pictureinsertchoice}
												></i>
												&nbsp;&nbsp;
												<i
													class="fa fa-ellipsis-h fa-lg"
													aria-hidden="true"
													id="Popover1"
												></i>
												<Modal isOpen={openModal}>
													<ModalHeader>Image Uploader</ModalHeader>
													<ModalBody>
														<Dropzone
															getUploadParams={getUploadParams}
															onChangeStatus={handleChangeStatus}
															// onSubmit={handleSubmit}
															accept="image/*"
															maxFiles={1}
															multiple={false}
														/>
													</ModalBody>
													<ModalFooter>
														<Button
															className="btn btn-danger"
															onClick={() => {
																setopenModal(false);
															}}
														>
															Cancel
														</Button>
														<Button className="btn btn-primary">Proceed</Button>
													</ModalFooter>
												</Modal>
												<Popover
													placement="bottom"
													isOpen={popoverOpen}
													target="Popover1"
													toggle={Toggle}
												>
													<PopoverBody>
														<i
															style={{
																color: "red",
																fontSize: "14px",
																margin: "0 7px 0 0",
																cursor: "pointer",
															}}
															className="fa fa-trash-o"
														></i>
														Delete
													</PopoverBody>
												</Popover>
											</div>
										</div>
									</div>
								))}

								{/*--------------------------------------------- PICTURE CHOICE BUTTON DIVISION------------------------------------------------- */}

								{pictureChoice.map(() => (
									<div className="card">
										<div className="card-body" onClick={picturebtnclick}>
											<Row>
												<Col md={1}>
													<img
														src={picturescale}
														alt="picture choice"
														className="logomultiple"
													/>
												</Col>
												<Col style={{ marginBottom: "16px" }}>
													<input
														type="text"
														placeholder="Your question here."
														className="multipleinput"
														onChange={(event) => {
															setPicturechoiceques(event.target.value);
														}}
														maxlength="100"
													/>
												</Col>
											</Row>
											<Row>
												<Col md={1}>
													<h6 className="hyphencss"> -</h6>
												</Col>
												<Col md={1.1}>
													<div className="imageinputcont">
														<img
															src={picturescale}
															alt="picture choice"
															className="logomultipleinput"
														/>
														<i
															className="icofont icofont-plus"
															style={{
																color: "rgb(176 12 176)",
																marginTop: "4px",
																marginLeft: "3px",
																cursor: "pointer",
															}}
															onClick={pictureinsert}
														></i>
													</div>
												</Col>
												<Col>
													<input
														type="text"
														placeholder="Choice label"
														className="multipleinput"
														maxlength="18"
														onChange={(event) => {
															setPicturechoice1(event.target.value);
														}}
													/>
												</Col>
											</Row>
											<Row>
												<Col md={1}>
													<h6 className="hyphencss"> -</h6>
												</Col>
												<Col md={1.1}>
													<div className="imageinputcont">
														<img
															src={picturescale}
															alt="picture choice"
															className="logomultipleinput"
														/>
														<i
															className="icofont icofont-plus"
															style={{
																color: "rgb(176 12 176)",
																marginTop: "4px",
																marginLeft: "3px",
																cursor: "pointer",
															}}
															onClick={pictureinsert}
														></i>
													</div>
												</Col>
												<Col>
													<input
														type="text"
														placeholder="Choice label"
														className="multipleinput"
														maxlength="20"
														onChange={(event) => {
															setPicturechoice2(event.target.value);
														}}
													/>
												</Col>
											</Row>
											<Row>
												<Col md={1}>
													<h6 className="hyphencss"> -</h6>
												</Col>
												<Col md={1.1}>
													<div className="imageinputcont">
														<img
															src={picturescale}
															alt="picture choice"
															className="logomultipleinput"
														/>
														<i
															className="icofont icofont-plus"
															style={{
																color: "rgb(176 12 176)",
																marginTop: "4px",
																marginLeft: "3px",
																cursor: "pointer",
															}}
															onClick={pictureinsert}
														></i>
													</div>
												</Col>
												<Col>
													<input
														type="text"
														placeholder="Choice label"
														className="multipleinput"
														maxlength="20"
														onChange={(event) => {
															setPicturechoice3(event.target.value);
														}}
													/>
												</Col>
											</Row>
											<Row>
												<Col md={1}>
													<h6 className="hyphencss"> -</h6>
												</Col>
												<Col md={1.1}>
													<div className="imageinputcont">
														<img
															src={picturescale}
															alt="picture choice"
															className="logomultipleinput"
														/>
														<i
															className="icofont icofont-plus"
															style={{
																color: "rgb(176 12 176)",
																marginTop: "4px",
																marginLeft: "3px",
																cursor: "pointer",
															}}
															onClick={pictureinsert}
														></i>
													</div>
												</Col>
												<Col>
													<input
														type="text"
														placeholder="Choice label"
														className="multipleinput"
														maxlength="20"
														onChange={(event) => {
															setPicturechoice4(event.target.value);
														}}
													/>
												</Col>
											</Row>
										</div>
										<div className="card-footer">
											<div className="iconscont">
												<i
													className="fa fa-picture-o fa-lg"
													onClick={pictureinsert}
												></i>
												&nbsp;&nbsp;
												<i
													class="fa fa-ellipsis-h fa-lg"
													aria-hidden="true"
													id="Popover1"
												></i>
												<Modal isOpen={openModal}>
													<ModalHeader>Image Uploader</ModalHeader>
													<ModalBody>
														<Dropzone
															getUploadParams={getUploadParams}
															onChangeStatus={handleChangeStatus}
															// onSubmit={handleSubmit}
															accept="image/*"
															maxFiles={1}
															multiple={false}
														/>
													</ModalBody>
													<ModalFooter>
														<Button
															className="btn btn-danger"
															onClick={() => {
																setopenModal(false);
															}}
														>
															Cancel
														</Button>
														<Button className="btn btn-primary">Proceed</Button>
													</ModalFooter>
												</Modal>
												<Popover
													placement="bottom"
													isOpen={popoverOpen}
													target="Popover1"
													toggle={Toggle}
												>
													<PopoverBody>
														<i
															style={{
																color: "red",
																fontSize: "14px",
																margin: "0 7px 0 0",
																cursor: "pointer",
															}}
															className="fa fa-trash-o"
														></i>
														Delete
													</PopoverBody>
												</Popover>
											</div>
										</div>
									</div>
								))}

								{/*--------------------------------------------- OPINION SCALE BUTTON DIVISION------------------------------------------------- */}
								{opinionChoice.map(() => (
									<div className="card">
										<div className="card-body" onClick={opinionbtnclick}>
											<Row>
												<Col md={1}>
													<img
														src={opinionscale}
														alt="Welcome Screen"
														className="logomultiple"
													/>
												</Col>
												<Col>
													<input
														type="text"
														placeholder="Your questions here"
														className="multipleinput"
														maxlength="100"
														autoComplete="off"
														onChange={(event) => {
															setOpinionchoiceques(event.target.value);
														}}
													/>
												</Col>
												<Col md={2}>
													<h6 className="lengthcounter">
														{" "}
														{opinionchoiceques.length}/100
													</h6>
												</Col>
											</Row>
										</div>
										<div className="card-footer">
											<div className="iconscont">
												<i
													className="fa fa-picture-o fa-lg"
													onClick={pictureinsert}
												></i>
												&nbsp;&nbsp;
												<i
													class="fa fa-ellipsis-h fa-lg"
													aria-hidden="true"
													id="Popover1"
												></i>
												<Modal isOpen={openModal}>
													<ModalHeader>Image Uploader</ModalHeader>
													<ModalBody>
														<Dropzone
															getUploadParams={getUploadParams}
															onChangeStatus={handleChangeStatus}
															// onSubmit={handleSubmit}
															accept="image/*"
															maxFiles={1}
															multiple={false}
														/>
													</ModalBody>
													<ModalFooter>
														<Button
															className="btn btn-danger"
															onClick={() => {
																setopenModal(false);
															}}
														>
															Cancel
														</Button>
														<Button className="btn btn-primary">Proceed</Button>
													</ModalFooter>
												</Modal>
												<Popover
													placement="bottom"
													isOpen={popoverOpen}
													target="Popover1"
													toggle={Toggle}
												>
													<PopoverBody>
														<i
															style={{
																color: "red",
																fontSize: "14px",
																margin: "0 7px 0 0",
																cursor: "pointer",
															}}
															className="fa fa-trash-o"
														></i>
														Delete
													</PopoverBody>
												</Popover>
											</div>
										</div>
									</div>
								))}

								{/*--------------------------------------------- RATING BUTTON DIVISION------------------------------------------------- */}

								{ratingbtn ? (
									<div className="card" onClick={ratingbtnclick}>
										<div className="card-body">
											<Row>
												<Col md={1}>
													<img
														src={rating}
														alt="Rating"
														className="logomultiple"
													/>
												</Col>
												<Col>
													<input
														type="text"
														placeholder="Your question here"
														className="multipleinput"
														maxlength="100"
														autoComplete="off"
														onChange={(event) => {
															setRatingques(event.target.value);
														}}
													/>
												</Col>
												<Col md={2}>
													<h6 className="lengthcounter">
														{" "}
														{ratingques.length}/100
													</h6>
												</Col>
											</Row>
										</div>
										<div className="card-footer">
											<div className="iconscont">
												<i
													className="fa fa-picture-o fa-lg"
													onClick={pictureinsert}
												></i>
												&nbsp;&nbsp;
												<i
													class="fa fa-ellipsis-h fa-lg"
													aria-hidden="true"
													id="Popover1"
												></i>
												<Modal isOpen={openModal}>
													<ModalHeader>Image Uploader</ModalHeader>
													<ModalBody>
														<Dropzone
															getUploadParams={getUploadParams}
															onChangeStatus={handleChangeStatus}
															// onSubmit={handleSubmit}
															accept="image/*"
															maxFiles={1}
															multiple={false}
														/>
													</ModalBody>
													<ModalFooter>
														<Button
															className="btn btn-danger"
															onClick={() => {
																setopenModal(false);
															}}
														>
															Cancel
														</Button>
														<Button className="btn btn-primary">Proceed</Button>
													</ModalFooter>
												</Modal>
												<Popover
													placement="bottom"
													isOpen={popoverOpen}
													target="Popover1"
													toggle={Toggle}
												>
													<PopoverBody>
														<i
															style={{
																color: "red",
																fontSize: "14px",
																margin: "0 7px 0 0",
																cursor: "pointer",
															}}
															className="fa fa-trash-o"
														></i>
														Delete
													</PopoverBody>
												</Popover>
											</div>
										</div>
									</div>
								) : null}
								<Label
									for="appType"
									onClick={openDropdown}
									style={{
										display: "flex",
										alignItems: "center",
										cursor: "pointer",
									}}
								>
									<i
										className="fa fa-plus-circle fa-3x"
										style={{ color: "#4466f2" }}
									></i>{" "}
									&nbsp;&nbsp;Add your first question{" "}
								</Label>
								<button
									className="btn btn-primary btn-block publishbtn"
									onClick={(e) => props.setTabIndex(1)}
								>
									Publish
								</button>

								{/*--------------------------------------------- DROPDOWN------------------------------------------------- */}

								{dropdown ? (
									<div className="dropcontainer">
										<Row>
											<Col>
												<div className="dropheader">
													<label
														style={{
															fontWeight: "500",
															marginLeft: "8px",
															marginTop: "4px",
														}}
													>
														&nbsp;Choose a question type
													</label>
												</div>
												<div className="buttonscont">
													<div
														className={
															!welcomebtn == false
																? "singlebutton disabledTrue"
																: "singlebutton"
														}
														id="welcomescreen"
														onMouseEnter={welcomefunc}
														onMouseLeave={welcomeleave}
														onClick={welcomeclickfunc}
													>
														<label>
															{" "}
															<img src={welcome} alt="empty" className="logo" />
															&nbsp;Welcome Screen
														</label>
													</div>
													<div
														className="singlebutton1"
														id="singlechoice"
														onMouseEnter={singlefunc}
														onMouseLeave={singleleave}
														onClick={singleclickfunc}
													>
														<label>
															{" "}
															<img src={single} alt="empty" className="logo" />
															&nbsp;Single Choice
														</label>
													</div>
													<div
														className="singlebutton2"
														id="multiplechoice"
														onMouseEnter={multiplefunc}
														onMouseLeave={multipleleave}
														onClick={multipleclickfunc}
													>
														<label>
															{" "}
															<img
																src={multiplechoice}
																alt="empty"
																className="logo"
															/>
															&nbsp;Multiple Choice{" "}
														</label>
													</div>
													<div
														className="singlebutton3"
														id="picturechoice"
														onMouseEnter={picturefunc}
														onMouseLeave={pictureleave}
														onClick={pictureclickfunc}
													>
														<label>
															{" "}
															<img
																src={picturescale}
																alt="empty"
																className="logo"
															/>
															&nbsp;Picture Choice
														</label>
													</div>
													<div
														className="singlebutton4"
														id="opinionscale"
														onMouseEnter={opinionfunc}
														onMouseLeave={opinionleave}
														onClick={opinionclickfunc}
													>
														<label>
															{" "}
															<img
																src={opinionscale}
																alt="empty"
																className="logo"
															/>
															&nbsp;Opinion Scale
														</label>
													</div>

													<div
														className={
															!ratingbtn == false
																? "singlebutton5 disabledTrue"
																: "singlebutton5"
														}
														id="rating"
														onMouseEnter={ratingfunc}
														onMouseLeave={ratingleave}
														onClick={ratingclickfunc}
													>
														<label>
															{" "}
															<img src={rating} alt="empty" className="logo" />
															&nbsp;Rating
														</label>
													</div>
												</div>
											</Col>

											{/*---------------------------------------------DROPDOWN HOVER EFFECT ------------------------------------------------- */}

											<Col>
												<div className="hovereffectswelcome">
													<img
														className="hoverimagecard"
														src={welcomehover}
														alt="welcome"
													/>
													<h4 className="hovertitle">Welcome Screen</h4>
													<span className="hoverpara">
														{" "}
														The first thing your audience will see-a great
														opportunity to engage right away
													</span>
												</div>
												<div className="hovereffectssingle">
													<img
														className="hoverimagecard"
														src={singlehover}
														alt="welcome"
													/>
													<h4
														className="hovertitle"
														style={{ paddingRight: "20px", marginTop: "4px" }}
													>
														Single choice
													</h4>
													<span
														className="hoverpara"
														style={{ marginTop: "6px" }}
													>
														Get your audience options to choose one. Good for
														getting determined option.
													</span>
												</div>
												<div className="hovereffectsmultiple">
													<img
														className="hoverimagecard"
														src={multiplehover}
														alt="multiple choice"
													/>

													<h4
														className="hovertitle"
														style={{ paddingRight: "10px", marginTop: "5px" }}
													>
														Multiple Choice
													</h4>
													<span
														className="hoverpara"
														style={{ marginTop: "6px" }}
													>
														Get your audience options to choose from. Good for
														getting quantitative data.
													</span>
												</div>
												<div className="hovereffectspicture">
													<img
														className="hoverimagecard"
														src={picturehover}
														alt="Picture choice"
													/>
													<h4
														className="hovertitle"
														style={{ paddingRight: "17px", marginTop: "5px" }}
													>
														Picture Choice
													</h4>
													<span
														className="hoverpara"
														style={{ marginTop: "7px" }}
													>
														{" "}
														Get your audience image options to choose from
														--bring questions to life
													</span>
												</div>
												<div className="hovereffectsopinion">
													<img
														className="hoverimagecard"
														src={opinionhover}
														alt="Opinion scale"
													/>
													<h4
														className="hovertitle"
														style={{
															paddingBottom: "7px",
															paddingRight: "20px",
															marginTop: "6px",
														}}
													>
														Opinion Scale
													</h4>
													<span
														className="hoverpara"
														style={{ marginTop: "12px" }}
													>
														{" "}
														Get your audience a numbered scale to choose from.
														Great for questionnaires
													</span>
												</div>
												<div className="hovereffectsrating">
													<img
														className="hoverimagecard"
														src={ratinghover}
														alt="Rating"
													/>
													<h4
														className="hovertitle"
														style={{ paddingRight: "68px", marginTop: "3px" }}
													>
														Rating
													</h4>
													<span
														className="hoverpara"
														style={{ marginTop: "6px" }}
													>
														{" "}
														Ask them to rate something. Includes shapes like
														dogs,stars even skulls.
													</span>
												</div>
											</Col>
										</Row>
									</div>
								) : null}
							</FormGroup>
						</Col>
					</Row>
				</Col>
				<Col>{renderSmartphone()}</Col>
			</Row>
		</div>
		// </div>
	);
};

export default SurveyForm;
