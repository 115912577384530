import React, { useEffect, useState } from 'react';
import { Button, Table, Tooltip } from "reactstrap";


const ReactTooltip = ({ placement, targetId, tooltipBody, isOpen, toggle,style }) => {

    const [openTooltip, setOpenTooltip] = useState(false);

    const openTooltipToggle = () => setOpenTooltip(!openTooltip);

    return (
        <Tooltip
            placement={placement}
            isOpen={isOpen}
            target={targetId}
            toggle={toggle}
            style={style}
        > {tooltipBody} </Tooltip>
    );
};


export default ReactTooltip;