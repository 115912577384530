import React, { Fragment } from "react";
import { CSVLink } from 'react-csv';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    UncontrolledTooltip,
    NavLink as NavLinkTab,
} from "reactstrap";
import moment from "moment-timezone";
import Datechoose from "../ui-elements/datePicker";
// import Pdf_render from "../hospoSafe/Pdf_render";
// import ReactPDF from '@react-pdf/renderer';
import HosposafeShareReport from "../forms/hosposafeShareReport";
import SweetAlert from "react-bootstrap-sweetalert";
import FileSaver from "file-saver";
import { DateRangePicker } from "react-date-range";
import PaginationComponent from "react-reactstrap-pagination";

import client from "../../Api/HTTPClient";
import displayMobile from "../ui-elements/display_mobile";
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DisappearedLoading } from "react-loadingg"; //loader
import "../../assets/scss/custom.scss"


class HospoSafeComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            customerId: this.props.id,
            customerInfo: this.props.info,
            visitors: [],
            filteredVisitors: null,
            visitorId: null,
            deleteModal: false,
            activePage: 1,
            pageSize: 15,
            mailModal: false,
            loadingmail: false,
            filteredDate: null,
            access_level: localStorage.getItem("accessLevel"),
            share_to_subject: "",
            alert: null,
            share_to_email: "",
            dates: [],
            // applyFilterType: 0,
            applyFilterType: 1,
            dataLoading: true,
            searchValue: null,
            searchedData: null,
            infoModal: false,
            note: null,
            adminUser: JSON.parse(localStorage.getItem("user")),
            visiterdData: [],
            lopped: 0,
            headingss: null,
            startDate: null,
            endDate: null,



        };
    }

    componentDidMount() {
        this.get_visitors();
    }


    createAndDownloadPdf = (form_data = null, type = 1) => {
        this.setState({ loadingmail: true });

        let { customerId, customerInfo } = this.state;
        const data = {
            email: form_data ? form_data.share_to_email : "",
            subject: form_data ? form_data.share_to_subject : "",
            id: customerId,
            mobile: customerInfo.mobile,
            type: type,
            applyFilterType: this.state.applyFilterType,
            filteredDate: this.state.filteredDate,
        };

        client.postGetText(api.createhospsafePdf, data, (error, response) => {
            this.setState({ loadingmail: false, mailModal: false });
            if (response) {
                if (type == 2) {
                    FileSaver.saveAs(new Blob([response], { type: "application/pdf" }), `Hospo-Registry.pdf`);
                } else {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                this.setState({
                                    alert: (
                                        <SweetAlert success title="" onConfirm={this.hideAlert}>
                                            Report send successfully
                                        </SweetAlert>
                                    ),
                                });
                                // setcashbackdata(response.result.data);
                            } else {
                                toast.error("Internal error occured. Please contact support");
                                // toast.error(response.message);
                            }
                        } catch (e) {
                            toast.error("Internal error occured. Please contact support");
                        }
                    } else {
                        toast.error("Internal error occured. Please contact support");
                    }
                }
            } else {
                console.log("Error");
            }
        });
        // }
    };

    handlePageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber });
    };

    hideAlert = () => {
        this.setState({
            alert: null,
        });
    };

    get_visitors = () => {
        let postdata;
        let apiroute;
        if (this.props.customerType == "customer") {
            postdata = {
                mobile: this.state.customerInfo.mobile,
                applyFilterType: this.state.applyFilterType,
                filteredDate: this.state.filteredDate
            };
            apiroute = api.get_cust_visits_data;
        } else if (this.props.customerType == "merchant") {
            postdata = {
                merchant_id: this.state.customerId,
                applyFilterType: this.state.applyFilterType,
                filteredDate: this.state.filteredDate,
                startDate: this.state.startDate,
                endDate: this.state.endDate
            };
            apiroute = api.get_merchant_visitors;
        }

        this.setState({ dataLoading: true });
        client.post(apiroute, postdata, (error, response) => {
            if (!error) {
                if (!response.error) {
                    // console.log(response);
                    this.setState((prevState) => {
                        console.log('neha', response.result.visitors_info);
                        return {
                            ...prevState,
                            visitors: response.result.visitors_info.reverse(),
                        };
                    });
                } else {
                    setTimeout(() => {
                        toast.error(" No visitors found");
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
            this.setState({ dataLoading: false });
        });
    };

    deleteClick = (id) => {
        console.log(id, 'delete click')
        this.setState((prevState) => {
            return {
                ...prevState,
                deleteModal: !prevState.deleteModal,
                visitorId: id,
            };
        });
    };

    mailClick = () => {
        this.setState((prevState) => {
            return {
                ...prevState,
                mailModal: !prevState.mailModal,
            };
        });
    };

    toggleMailModal = () => {
        this.setState((prevState) => {
            return {
                ...prevState,
                mailModal: !prevState.mailModal,
            };
        });
    };

    dateHandler = async (date) => {
        await this.setState({ filteredDate: date, applyFilterType: 7 });
        this.get_visitors();
    };

    toggleDeleteModal = () => {
        this.setState((prevState) => {
            return {
                ...prevState,
                deleteModal: !prevState.deleteModal,
            };
        });
    };

    deleteVisitorRegistry = (e) => {
        e.preventDefault();

        const data = { visitor_id: this.state.visitorId };

        client.post(api.delete_visitor_registry, data, (error, response) => {
            if (!error) {
                // alert(JSON.stringify(response));
                //   return false;
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            toast.success(response.message);
                            window.location.reload(true);
                        } else {
                            toast.error(response.message);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }
        });
    };

    render_date = (add_date) => {
        if (typeof add_date === "undefined") {
            return;
        }

        const convert_date = new Date(add_date);
        const added_date = moment.tz(convert_date, "Australia/Sydney").format("DD/MM/YYYY HH:mm");
        return added_date;
    };

    handleSelect(ranges) {
        console.log(ranges);
        // {
        //   selection: {
        //     startDate: [native Date Object],
        //     endDate: [native Date Object],
        //   }
        // }
    }
    onSelect = (dates) => this.setState({ dates });

    renderdatepicker = () => {
        const selectionRange = {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
        };
        return <DateRangePicker ranges={[this.state.dates]} onChange={this.onSelect} />;

        // return false;

        // let {startDate, endDate } = this.state;

        // return (
        //     <div style={{ display: "flex" }}>
        //         <div>
        //         <DatePicker
        //             selected={startDate}
        //             onChange={startDate => this.setState({ startDate })}
        //             selectsStart
        //             startDate={startDate}
        //             endDate={endDate}
        //             />
        //         </div>
        //         <div>
        //         <DatePicker
        //             selected={endDate}
        //             onChange={endDate => this.setState({ endDate })}
        //             selectsEnd
        //             startDate={startDate}
        //             endDate={endDate}
        //             minDate={startDate}
        //             />
        //             </div>
        //         </div>
        // );
    };

    onSearchChangeHandler = (event) => {
        this.setState({ searchValue: event.target.value });
    };

    handleSearchSubmit = (event) => {
        event.preventDefault();
        const { searchValue } = this.state;
        let data = null;

        if (searchValue == null || searchValue == "") {
            this.setState({ searchedData: null });
        } else {
            const filteredData = [];
            data = this.state.visitors;

            data.filter((item) => {
                if (
                    typeof item.added_date != "undefined" &&
                    typeof item.name !== "undefined" &&
                    typeof item.merchant_name !== "undefined" &&
                    typeof item.mobile !== "undefined"
                ) {
                    if (
                        item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                        item.merchant_name.toLowerCase().includes(searchValue.toLowerCase()) ||
                        item.mobile.toLowerCase().includes(searchValue.toLowerCase()) ||
                        this.render_date(item.added_date).toLowerCase().includes(searchValue.toLowerCase())
                    ) {
                        filteredData.push(item);
                        //this.setState({ searchedData: filteredData });
                    } else if (item.guests != "" && JSON.parse(item.guests).length > 0) {
                        const guests = JSON.parse(item.guests);
                        console.log(guests);
                        const foundName = guests.filter((el) => {
                            return el.value.toLowerCase().includes(searchValue.toLowerCase());
                        });
                        console.log(foundName);
                        if (foundName.length) {
                            filteredData.push(item);
                        }
                        console.log(filteredData);
                        //this.setState({ searchedData: filteredData });
                    }
                }
                this.setState({ searchedData: filteredData });
            });
            console.log(filteredData);
            //this.setState({ searchedData: filteredData });
        }
    };

    infoClick = (id) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                infoModal: !prevState.infoModal,
                visitorId: id,
            };
        });
    };

    toggleInfoModal = () => {
        this.setState((prevState) => {
            return {
                ...prevState,
                infoModal: !prevState.infoModal,
            };
        });
    };

    onChangeNoteInput = (event) => {
        const flagNote = event.target.value;
        this.setState((prevState) => {
            return {
                ...prevState,
                note: flagNote,
            };
        });
    };

    noteSubmitHandler = (event) => {
        const { adminUser, visitorId } = this.state;
        event.preventDefault();

        const postdata = {
            visitId: visitorId._id,
            comment: { type: "normal", note: this.state.note, user: adminUser._id, date: new Date() },
        };
        console.log(postdata);
        client.post(api.updateVisitEntry, postdata, (error, response) => {
            if (!error) {
                if (!response.error) {
                    console.log(response);
                    this.get_visitors();
                    this.setState((prevState) => {
                        return {
                            ...prevState,
                            infoModal: false,
                        };
                    });
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        });
    };

    renderInfoModal = () => {
        if (typeof this.state.visitorId.comment == "undefined") {
            this.state.visitorId.comment = [];
        }
        let foundComment = [...this.state.visitorId.comment].reverse();
        return (
            <Modal isOpen={this.state.infoModal} toggle={this.toggleInfoModal} className="modal-body" centered={true}>
                <h5 style={{ padding: "15px", margin: "0" }}>Comments</h5>

                <ModalBody style={{ paddingTop: "0", paddingBottom: "0", maxHeight: "calc(100vh - 370px)", overflowY: "auto" }}>
                    <div className="card-body chat-box" style={{ paddingTop: "0", paddingBottom: "0" }}>
                        <div className="chat-right-aside bitcoin-chat">
                            <div className="chat">
                                <div className="chat-history chat-msg-box custom-scrollbar" style={{ padding: "0", margin: "0" }}>
                                    <ul style={{ margin: "0" }}>
                                        {foundComment.length != 0 ? (
                                            foundComment.map((el) => {
                                                return (
                                                    <li style={{ margin: "0" }}>
                                                        <div
                                                            className="message my-message "
                                                            style={{ marginBottom: "10px", paddingTop: "10px", paddingBottom: "10px" }}
                                                        >
                                                            <p>
                                                                {el.admin_name}
                                                                <span className="message-data-time pull-right">{this.render_date(el.date)}</span>{" "}
                                                            </p>
                                                            {el.type == "normal" ? null : <p style={{ fontSize: "0.9rem" }}>Type : {el.type}</p>}
                                                            {el.note}
                                                        </div>
                                                    </li>
                                                );
                                            })
                                        ) : (
                                            <p>No comment added!</p>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                    <div className="col-lg-12">
                        <form className="theme-form">
                            <div className="form-group mb-0 col-12">
                                <label htmlFor="exampleFormControlTextarea4">Comment:</label>
                                <textarea
                                    className="form-control"
                                    id="exampleFormControlTextarea4"
                                    required
                                    rows="3"
                                    onChange={this.onChangeNoteInput}
                                ></textarea>
                            </div>
                            <div style={{ padding: "15px 0 0 15px" }}>
                                <Button color="secondary" style={{ marginRight: "15px" }} onClick={this.toggleInfoModal}>
                                    Cancel
                                </Button>
                                <Button color="primary" type="submit" name="comment" onClick={(event) => this.noteSubmitHandler(event)}>
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </div>
                </ModalFooter>
            </Modal>
        );
    };


    getFieldName = (item, index) => {
        if (this.state.lopped == 0) {
            if (index == 0) {
                if (item.guests) {
                    let guests = JSON.parse(item.guests);
                    if (guests.length > 0) {
                        let keys = Object.keys(guests).map((key) => {
                            return key;
                        })
                        this.setState({ headingss: keys });
                    }
                } else if (item.form_fields) {
                    let newguests = JSON.parse(item.form_fields);
                    let guests = [newguests]
                    // console.log(guests,"guess")
                    if (guests.length > 0) {
                        // return "abc"
                        let keys = Object.keys(guests[0]).map((key) => {
                            return key
                        })
                        this.setState({ headingss: keys });
                        this.setState({ lopped: 1 });
                    } else {
                        console.log("runned 10")

                    }
                } else {
                    return ""
                }
            }
        } else {
            return null
        }

    }
    getFieldss = (item, heading) => {
        if (item.guests) {
            let guests = JSON.parse(item.guests);
            if (guests.length > 0) {
                // console.log(guests, "guests");
                return guests.map((i) => {
                    if (heading.toLowerCase() == "name") {
                        let guestName = i.value.charAt(0).toUpperCase() + i.value.slice(1);
                        return <p>{guestName}</p>;
                    } else if (heading.toLowerCase() == "mobile_number") {
                        return <p>{i.mobile}</p>;
                    } else {
                        return "";
                    }
                });
            } else {
                return "";
            }
        } else if (item.form_fields) {
            // let guestsObj = JSON.parse(item.form_fields);
            // let guests = [guestsObj];
            // // console.log(guests, "guests fields");
            // if (guests.length > 0) {
            // 	return guests.map((i) => {
            // 		if (heading.toLowerCase() == "name") {
            // 			let guestName = i.Name.charAt(0).toUpperCase() + i.Name.slice(1);
            // 			return <p>{guestName}</p>;
            // 		} else if (heading.toLowerCase() == "mobile_number") {
            // 			return <p>{i.mobile_number}</p>;
            // 		} else if (heading.toLowerCase() == "email_id") {
            // 			return <p>{i.Email_id}</p>;
            // 		} else if (heading.toLowerCase() == "address") {
            // 			return <p>{i.Address}</p>;
            // 		} else {
            // 			return "";
            // 		}
            // 	});
            // } else {
            // 	return "";
            // }
            try {
                let form_fields = JSON.parse(item.form_fields);
                Object.keys(form_fields).map((key) => {
                    if (key.toLowerCase() == key) {
                        form_fields[key.charAt(0).toUpperCase() + key.slice(1)] = form_fields[key];
                        delete form_fields[key];
                    }
                });
                let returnData = form_fields[heading.charAt(0).toUpperCase() + heading.slice(1)];
                if (Date.parse(returnData)) {
                    return moment(returnData).format("DD/MM/YYYY")
                } else {
                    return form_fields[heading.charAt(0).toUpperCase() + heading.slice(1)];
                }

            } catch {
                return "";
            }
        } else {
            return "";
        }
    }

    renderAllTable = () => {
        let showVisitors = [];
        let { visitors, access_level, searchedData } = this.state;
        if (searchedData == null) {

            showVisitors = [...visitors];
        } else {
            showVisitors = [...searchedData];
        }
        // console.log('info', this.props.info, showVisitors)

        return (
            <Fragment>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div>
                        <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                            <div>
                                <input
                                    style={{
                                        border: "none",
                                        borderBottom: "1px solid rgb(197, 189, 189)",
                                        borderRadius: "0",
                                        margin: "0px 10px 6px",
                                        fontSize: "0.75rem",
                                    }}
                                    type="text"
                                    placeholder="Search"
                                    onChange={this.onSearchChangeHandler}
                                />
                            </div>
                        </form>
                    </div>

                    {this.state.activePage * this.state.pageSize > showVisitors.length ? (
                        <p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>
                            Showing {(this.state.activePage - 1) * this.state.pageSize + 1} to {showVisitors.length} of{" "}
                            {showVisitors.length} entries
                        </p>
                    ) : (
                        <p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>
                            Showing {(this.state.activePage - 1) * this.state.pageSize + 1} to{" "}
                            {this.state.activePage * this.state.pageSize} of {showVisitors.length} entries
                        </p>
                    )}
                </div>


                <div className="table-responsive py-2 tblHos" ref={this.mailRef}>
                    <table className="table table-border-horizontal table-striped">
                        <thead>
                            <tr>
                                <th>
                                    #
                                </th>
                                <th>
                                    Date / Time of visit
                                </th>
                                <th>
                                    Visitor Details
                                </th>
                                <th>
                                    Table number
                                </th>
                                <th>
                                    Notes
                                </th>
                                <th>
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                showVisitors.length != 0 ?
                                    (
                                        showVisitors.slice((this.state.activePage - 1) * this.state.pageSize, this.state.activePage * this.state.pageSize).map((item, index) => {

                                            const convert_date = new Date(item.added_date);
                                            var x = new moment(item.added_date)
                                            var y = new moment(item.checkout)
                                            let duration = moment.duration(y.diff(x));
                                            const show_time = convert_date ? `Check-in time: ` + moment(convert_date).format('DD-MM-yyyy') + " " + moment(convert_date).format('LT') : null
                                            const chekcout = item.checkout ? `Check-out time: ` + moment(item.checkout).format('DD-MM-yyyy') + " " + moment(item.checkout).format('LT') : null
                                            const visits = item.guests ? `No of visits:` + " " + item.guests : null
                                            const hr = duration.hours() <= 9 ? '0' + duration.hours() : duration.hours()
                                            const min = duration.minutes() <= 9 ? '0' + duration.minutes() : duration.minutes()
                                            const sec = duration.seconds() <= 9 ? '0' + duration.seconds() : duration.seconds()
                                            const du = `Duration: ` + hr + ':' + min + ":" + sec

                                            {/* console.table('tableData', item) */ }

                                            {/* const get_fields = (item) => {

                                                try {
                                                    let form_fields = JSON.parse(item.form_fields);
                                                    let data = "";
                                                    let datas = [];
                                                    Object.keys(form_fields).map((key, val) => {
                                                        data = data +
                                                            `${key.replaceAll('_', ' ')} : ${form_fields[key]} \n`

                                                    });
                                                    return data
                                                } catch (err) {

                                                    return `Name : ${item.name}\nMobile : ${item.mobile}`;
                                                }
                                            }; */}
                                            const get_fields = (item) => {
                                                try {
                                                    let form_fields = JSON.parse(item.form_fields);
                                                    let data = [];
                                                    let mobile_prefix = form_fields.country_code ? form_fields.country_code : '';
                                                    let scan_id = false;
                                                    let scan_image_name = "";
                                                    Object.keys(form_fields).map((key) => {
                                                        if (key == "country_code" || key == "faceId" || key == "scanIdImg" || key == "scanIdUri" || key == "camPhoto" || key == "Licence_type" || key == "Licence_no" || key == "camImageName" || key == "Address" || key == "Table_no" || key == "camImageFull") {
                                                        } else {
                                                            if (form_fields[key] && key != "scanImageName") {
                                                                data.push(<div><strong style={{ textTransform: 'capitalize' }}>{key.replaceAll('_', ' ')}</strong> : {key == "mobile_number" ? `${mobile_prefix}` : null} {key == "Name" ? form_fields[key].replace(/\r?\n|\r/g, " ") : form_fields[key]}</div>);
                                                            }

                                                            if (form_fields['scanImageName']) {
                                                                scan_id = true;
                                                                scan_image_name = form_fields['scanImageName'];
                                                            }
                                                        }
                                                        {/* data.push(<div>
                                                            <strong style={{ textTransform: 'capitalize' }}>{key.replaceAll('_', ' ')}</strong> : {form_fields[key]}
                                                        </div>) */}
                                                    });
                                                    return data;
                                                } catch (err) {
                                                    return (
                                                        <div>
                                                            {item?.name &&
                                                                <div>
                                                                    <strong style={{ textTransform: 'capitalize' }}>Name</strong> : {item?.name}
                                                                </div>
                                                            }
                                                            {item?.email &&
                                                                <div>
                                                                    <strong style={{ textTransform: 'capitalize' }}>Email</strong> : {item?.email}
                                                                </div>
                                                            }
                                                            {item?.mobile &&
                                                                <div>
                                                                    <strong style={{ textTransform: 'capitalize' }}>Mobile</strong> : {item?.mobile}
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                }
                                            };

                                            //let form_fields = JSON.parse(item.form_fields);

                                            return (
                                                <tr key={index} >
                                                    <td>
                                                        {(this.state.activePage - 1) * this.state.pageSize + index + 1}
                                                    </td>
                                                    <td>
                                                        <span>
                                                            <p>{show_time}</p>
                                                            {item.checkout && <p>{chekcout}</p>}
                                                            {item.checkout && <p>{du}</p>}
                                                            <p>{visits}</p>
                                                        </span>
                                                    </td>
                                                    <td style={{ whiteSpace: 'pre' }}>
                                                        {/* <p> <span style={{ fontWeight: "bold" }} >Name :</span> {item.form_fields && item.form_fields.length > 0 ? (JSON.parse(item.form_fields).Name) : (null)} </p>
                                                        <p> <span style={{ fontWeight: "bold" }} >Mobile Number :</span> {item.form_fields && item.form_fields.length > 0 ? (JSON.parse(item.form_fields).mobile_number) : (null)} </p>
                                                        {JSON.parse(item.form_fields).Email_id && <p> <span style={{ fontWeight: "bold" }} >Email Id :</span> {item.form_fields && item.form_fields.length > 0 ? (JSON.parse(item.form_fields).Email_id) : (null)} </p>}
                                                        {JSON.parse(item.form_fields).Address && <p> <span style={{ fontWeight: "bold" }} >Address :</span> {item.form_fields && item.form_fields.length > 0 ? (JSON.parse(item.form_fields).Address) : (null)} </p>}
                                                         */}
                                                        {get_fields(item)}
                                                    </td>
                                                    <td>
                                                        {item.table_no ? item.table_no : " - "}
                                                    </td>
                                                    <td>
                                                        {
                                                            typeof item.comment != "undefined" && item.comment.length > 0
                                                                ? item.comment[item.comment.length - 1].note
                                                                : "N.A"
                                                        }
                                                    </td>
                                                    <td>
                                                        {access_level == 3 && (
                                                            <span
                                                                style={{ marginRight: "10px", fontSize: "1.2em" }}
                                                                onClick={() => this.deleteClick(item)}
                                                            >
                                                                <i style={{ color: "red" }} id="delete" className="fa fa-trash-o"></i>
                                                            </span>
                                                        )}
                                                        <span style={{ marginRight: "10px", fontSize: "1.2em" }}>
                                                            <i
                                                                className="fa fa-list-alt"
                                                                style={{ color: "black" }}
                                                                id="info"
                                                                onClick={() => this.infoClick(item)}
                                                            ></i>
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        )
                                    )
                                    :
                                    (
                                        <h6 style={{ textAlign: "center" }}> No records found! </h6>
                                    )
                            }
                        </tbody>
                    </table>
                    <div className="py-2" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <select
                                className="custom-select"
                                style={{ width: "35%", fontSize: "0.63rem", marginRight: "10px" }}
                                onChange={this.setPageSize}
                                value={this.state.pageSize}
                            >
                                <option value="15">15</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="75">75</option>
                                <option value="100">100</option>
                            </select>
                            <span style={{ fontSize: "0.63rem" }}>Records per Page</span>
                        </div>
                        <PaginationComponent
                            totalItems={showVisitors.length}
                            pageSize={this.state.pageSize}
                            onSelect={this.handlePageChange}
                        />
                    </div>
                </div>
            </Fragment>
        );
    };

    shareReportApi() {
        let { share_to_subject, share_to_email } = this.state;
        alert(share_to_subject + "dasd " + share_to_email);
    }

    renderMailModal = () => {
        const { mailModal, loadingmail } = this.state;

        return (
            <Modal isOpen={mailModal} toggle={this.toggleMailModal} className="modal-body" centered={true}>
                <HosposafeShareReport
                    loadingmail={loadingmail}
                    toggleMailModal={this.toggleMailModal}
                    createAndDownloadPdf={this.createAndDownloadPdf}
                />
            </Modal>
        );
    };

    renderDeleteModal = () => {
        const { deleteModal } = this.state;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={deleteModal} toggle={this.toggleDeleteModal} className="modal-body" centered={true}>
                    <form className="theme-form" noValidate="" onSubmit={this.deleteVisitorRegistry}>
                        <ModalBody>
                            <ModalBody style={{ padding: "16px 0" }}>
                                <h4>Do you want to delete this visit record ?</h4>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={this.toggleDeleteModal}>
                                    No
                                </Button>
                                <Button color="primary" type="submit">
                                    Yes
                                </Button>
                            </ModalFooter>
                        </ModalBody>
                    </form>
                </Modal>
            </div>
        );
    };
    onChangeStartDate = async (date) => {
        if (this.state.endDate) {
            await this.setState({ startDate: date });
            // this.getVisitors();
            this.get_visitors()
        } else {
            this.setState({ startDate: date });
        }
    };

    onChangeEndDate = async (date) => {
        if (this.state.startDate) {
            await this.setState({ endDate: date });
            // this.getVisitors();
            this.get_visitors();
        } else {
            this.setState({ endDate: date });
        }
    };

    applyFilter = async (type) => {
        await this.setState({ applyFilterType: type });

        if (type != 8) {
            this.get_visitors();
        }
    };


    render() {
        let {
            visitors,
            filteredDate,
            filteredVisitors,
            customerInfo,
            visitorId,
            applyFilterType,
            searchedData,
            startDate,
            endDate
        } = this.state;

        let visitorsData = [];

        if (searchedData == null) {
            visitorsData = [...visitors];
        } else {
            visitorsData = [...searchedData];
        }

        let headersData = [
            { label: "#", key: "num" },
            { label: "Date Time Of Visit", key: "DateTimeOfVisit" },
            { label: "No Of Guests", key: "NoOfGuests" },
            { label: "Guest Name", key: "GuestName" },
            { label: "Mobile", key: "Mobile" },
            { label: "Email", key: "Email" },
            { label: "Alternative Mobile Number", key: 'AltMobileNumber' },
            { label: "Address", key: 'Address' },
            { label: "Table number", key: "TableNumber" },
            { label: "Action", key: "Action" },
            { label: "Notes", key: "Notes" }
        ]

        let getData = [];

        visitorsData.map((val, ind) => {
            //console.log(val.form_fields.length > 0 && typeof (JSON.parse(val.form_fields).mobile_number),'asd')
            getData.push(
                {
                    num: ind + 1,
                    DateTimeOfVisit: moment(val.added_date).format('DD-MM-YYYY h:mm A'),
                    NoOfGuests: val.guests,
                    GuestName: val.form_fields && val.form_fields.length > 0 ? (JSON.parse(val.form_fields).Name) : (null),
                    Mobile: val.form_fields && val.form_fields.length > 0 ? (JSON.parse(val.form_fields).mobile_number) : (null),
                    // Email: val.form_fields && val.form_fields.length > 0 ? (JSON.parse(val.form_fields).Email_id) : (null),
                    Email: val.form_fields && val.form_fields.length > 0 ?
                        ("Email_id" in JSON.parse(val.form_fields))
                            ? (JSON.parse(val.form_fields).Email_id)
                            : (JSON.parse(val.form_fields).Email)
                        : (null),
                    AltMobileNumber: val.form_fields && val.form_fields.length > 0 ? (JSON.parse(val.form_fields).alternative_mobile_number) : (null),
                    Address: val.form_fields && val.form_fields.length > 0 ? (JSON.parse(val.form_fields).Address) : (null),
                    TableNumber: val.table_no ? val.table_no : " - ",
                    Note: '',
                    Action: '',
                    Note: typeof val.comment != "undefined" && val.comment.length > 0
                        ? val.comment[val.comment.length - 1].note
                        : "N.A",
                }
            )
        });

        return (
            <Fragment>
                {visitorId ? this.renderDeleteModal() : null}
                {visitorId && this.renderInfoModal()}
                {this.state.alert}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-xl-12 xl-100">
                            <div className="card">
                                <div className="card-body" style={{ padding: "0.9rem" }}>
                                    <div className="row" style={{ margin: "0 0 10px 0" }}>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>

                                            <button
                                                className={`btn ${applyFilterType != 0 ? "btn-outline-primary" : "btn-primary"} btn-xs`}
                                                onClick={() => this.applyFilter(0)}
                                                style={{ marginLeft: 5, marginRight: 5 }}
                                                type="button"
                                            >
                                                All
                                            </button>
                                            <button
                                                className={`btn ${applyFilterType != 1 ? "btn-outline-primary" : "btn-primary"} btn-xs`}
                                                onClick={() => this.applyFilter(1)}
                                                style={{ marginLeft: 5, marginRight: 5 }}
                                                type="button"
                                            >
                                                Today
                                            </button>
                                            <button
                                                className={`btn ${applyFilterType != 2 ? "btn-outline-primary" : "btn-primary"} btn-xs`}
                                                onClick={() => this.applyFilter(2)}
                                                style={{ marginLeft: 5, marginRight: 5 }}
                                                type="button"
                                            >
                                                Yesterday
                                            </button>
                                            <button
                                                className={`btn ${applyFilterType != 3 ? "btn-outline-primary" : "btn-primary"} btn-xs`}
                                                onClick={() => this.applyFilter(3)}
                                                type="button"
                                                style={{ marginLeft: 5, marginRight: 5 }}
                                            >
                                                This Week
                                            </button>
                                            <button
                                                className={`btn ${applyFilterType != 4 ? "btn-outline-primary" : "btn-primary"} btn-xs`}
                                                onClick={() => this.applyFilter(4)}
                                                type="button"
                                                style={{ marginLeft: 5, marginRight: 5 }}
                                            >
                                                Last Week
                                            </button>
                                            <button
                                                className={`btn ${applyFilterType != 5 ? "btn-outline-primary" : "btn-primary"} btn-xs`}
                                                onClick={() => this.applyFilter(5)}
                                                type="button"
                                                style={{ marginLeft: 5, marginRight: 5 }}
                                            >
                                                This Month
                                            </button>
                                            <button
                                                className={`btn ${applyFilterType != 6 ? "btn-outline-primary" : "btn-primary"} btn-xs`}
                                                onClick={() => this.applyFilter(6)}
                                                type="button"
                                                style={{ marginLeft: 5, marginRight: 5 }}
                                            >
                                                Last Month
                                            </button>



                                            <Datechoose
                                                className="col-md-4"
                                                style={{ width: "20%", marginLeft: 5, marginRight: 5 }}
                                                onDatePicked={this.dateHandler}
                                            ></Datechoose>
                                            <div>
                                                <button
                                                    className={`btn ${applyFilterType != 8 ? "btn-outline-primary" : "btn-primary"} btn-xs`}
                                                    onClick={() => this.applyFilter(8)}
                                                    type="button"
                                                    style={{ marginLeft: 5, marginRight: 5 }}
                                                >
                                                    Select a range
                                                </button>
                                            </div>
                                            <div class="manageReport" style={{ color: "#555", float: "right", marginLeft: 5, marginRight: 5 }}>
                                                {!this.state.loadingmail ? (
                                                    <div>
                                                        {/* <i class="fa fa-file-pdf-o" style={{ marginRight: 3, color: "#a5a5a5", cursor: 'pointer' }}></i>
                                                    <a class="weaklink" style={{ marginRight: 10, textDecoration: "none", borderBottomWidth: 1, color: '#1ea6ec', cursor: 'pointer' }} onClick={() => this.createAndDownloadPdf(null, 2)}>PDF</a>
                                                 */}
                                                        <span class="shareReport">
                                                            <i class="fa fa-users" style={{ marginRight: 3, color: "#a5a5a5", cursor: "pointer" }}></i>
                                                            <a
                                                                style={{
                                                                    marginRight: 10,
                                                                    textDecoration: "none",
                                                                    borderBottomWidth: 1,
                                                                    color: "#1ea6ec",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() => this.toggleMailModal()}
                                                                class="weaklink"
                                                            >
                                                                Share Report
                                                            </a>
                                                        </span>
                                                    </div>
                                                ) : null}

                                            </div>

                                            <CSVLink
                                                headers={headersData}
                                                data={getData}
                                                filename={"Hospo-Registry.csv"}
                                                style={{ color: "#1EA6EC" }}
                                            >Export Data</CSVLink>
                                        </div>
                                        {this.state.applyFilterType == 8 && (
                                            <div className="mt-2">
                                                <DatePicker
                                                    className="date-picker"
                                                    selected={startDate}
                                                    onChange={this.onChangeStartDate}
                                                    maxDate={new Date()}
                                                    placeholderText="Select start date"
                                                    dateFormat="dd/MM/yyyy"
                                                />
                                                <DatePicker
                                                    className="date-picker"
                                                    selected={endDate}
                                                    dateFormat="dd/MM/yyyy"
                                                    onChange={this.onChangeEndDate}
                                                    minDate={startDate}
                                                    maxDate={new Date()}
                                                    placeholderText="Select end date"
                                                />
                                            </div>
                                        )}
                                    </div>

                                    {!this.state.dataLoading ? (
                                        this.renderAllTable()
                                    ) : (
                                        <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                                            <div className="loader" style={{ margin: "25%" }}>
                                                <DisappearedLoading size="large" color="#514F4E" />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div>{this.renderMailModal()}</div>
            </Fragment>
        );
    }
}

export default HospoSafeComponent;
