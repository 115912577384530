import React, { Component, Fragment } from "react";
import { DollarSign } from "react-feather";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup, Col, Row, FormFeedback, CustomInput } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink as NavLinkTab } from "reactstrap";
import { NavLink, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment-timezone";
import { Redirect } from "react-router-dom";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import PaginationComponent from "react-reactstrap-pagination";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import Breadcrumb from "../../components/common/breadcrumb";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import "../../table.css";

class TopupAccounts extends Component {

    state = {
        allTopups: null,
        topupModal: false,
        topup: { topup_type: "Direct deposit" },
        activePage: 1,
        pageSize: 15,
        isOpen: false,
        imageSrc: null,
        selectedId: null,
        authorizeModal: false,
        deleteModal: false,
        customersData: null,
        access_level: localStorage.getItem("accessLevel"),
        searchUserFilter: "name",
        filteredUserData: [],
        selectedReceiver: null,
        user: JSON.parse(localStorage.getItem("user")),
        receiptModal: false,
        receiptDetails: null

    }

    componentDidMount() {
        this.getAllTopup();
        this.getAllData();
    }


    handleChange = (selectedOptions) => {
        this.setState(prevState => {
            return {
                ...prevState,
                selectedReceiver: selectedOptions,
                topup: {
                    ...prevState.topup,
                    ipay_id: selectedOptions[0].ipay_id

                }
            }
        })

    }

    toggleTopupModal = () => {
        this.setState(prevState => {
            return {
                topupModal: !prevState.topupModal,
            }
        });
    }
    imageClicked = (src) => {
        //console.log("clicked");
        this.setState({ isOpen: true, imageSrc: src })
    }

    setPageSize = (event) => {
        this.setState({ pageSize: event.target.value })
    }

    handlePageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber });
    }

    getAllData = () => {
        const postdata = {};

        client.post(api.getCustMerchData, postdata, (error, response) => {
            if (!error) {
                if (!response.error) {
                    //console.log(response);
                    const filteredUserData = response.result.data.filter(el => {
                        return el.type == 1
                    })
                    this.setState({ customersData: response.result.data, filteredUserData: filteredUserData })
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);

                }

            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        });
    }

    getAllTopup = () => {
        const postdata = {};

        client.post(api.getAllTopup, postdata, (error, response) => {
            if (!error) {
                if (!response.error) {
                    //console.log(response);
                    this.setState({ allTopups: response.result.data.reverse() })
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);

                }

            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        });
    }

    refreshPage() {
        window.location.reload(false);
    }

    onAddTopuChange = (event) => {
        const { name, value } = event.target;

        if (event.target.type == "file" && event.target.files.length != 0) {
            let mimeType = event.target.files[0].type;

            if (mimeType.match(/(jpeg|jpg|png|pdf)$/) == null) {
                return;
            }
            // Image upload
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = (_event) => {
                let imageData;
                //this.setState({ url: reader.result });
                imageData = reader.result;
                imageData = imageData.split(",")[1];

                let image_data = { mime: mimeType, data: imageData }
                // console.log(image_data);
                this.setState(prevState => {
                    return {
                        ...prevState,
                        topup: {
                            ...prevState.topup,
                            [name]: image_data
                        }
                    }
                })
            }
        } else {
            this.setState(prevState => {
                return {
                    ...prevState,
                    topup: {
                        ...prevState.topup,
                        [name]: value
                    }

                }
            })
        }

    }

    onTopupAdd = (event) => {
        event.preventDefault();
        const postData = this.state.topup;

        client.post(api.createTopupTransaction, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    this.refreshPage();
                    console.log(response);
                    toast.success(response.message);
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);

                }

            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        });


    }

    onToFilterSelect = (event) => {
        const { searchUserFilter } = this.state;
        let updatedData = [];

        if (event.target.value == "mobile") {
            const filteredUserData = this.state.customersData.map(el => {
                return el
            });
            this.setState({ searchUserFilter: "mobile", filteredUserData: filteredUserData })
        } else if (event.target.value == "email") {
            const filteredUserData = this.state.customersData.map(el => {
                return el
            });
            this.setState({ searchUserFilter: "email", filteredUserData: filteredUserData })
        } else if (event.target.value == "customer_name") {
            const filteredUserData = this.state.customersData.filter(el => {
                return el.type == 1
            })
            this.setState({ filteredUserData: filteredUserData, searchUserFilter: "name" });

        } else if (event.target.value == "merchant_name") {
            const filteredUserData = this.state.customersData.filter(el => {
                return el.type == 2
            })
            this.setState({ filteredUserData: filteredUserData, searchUserFilter: "business_name" });

        } else {
            this.setState({ searchUserFilter: "" })
        }
    }

    renderReceiverDetails = () => {
        const { selectedReceiver } = this.state;
        return (
            <div className="table-responsive">
                <table className="table table-border-horizontal table-striped">
                    <thead>
                        <tr >
                            <th scope="col" >#</th>
                            <th scope="col" >Name</th>
                            <th scope="col" >i-Pay ID</th>
                            <th scope="col" >Email</th>
                            <th scope="col" >Mobile</th>
                            <th scope="col" >Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedReceiver.map((item, index) => {
                            return (
                                <tr >
                                    <th scope="row" >{index + 1}</th>
                                    <td >{item.displayName}</td>
                                    <td >{item.ipay_id}</td>
                                    <td >{item.email}</td>
                                    <td >{item.mobile}</td>
                                    <td >{item.country.currency_symbol}{item.wallet[0].wallet_amount}</td>
                                </tr>

                            );
                        })}


                    </tbody>
                </table>
            </div>
        )
    }

    renderTopupModal = () => {
        const { filteredUserData, searchUserFilter, customersData, selectedReceiver, topup } = this.state;
        return (
            <Modal isOpen={this.state.topupModal} toggle={this.toggleTopupModal}>
                <Form onSubmit={this.onTopupAdd}>
                    <ModalHeader toggle={this.toggleTopupModal}>Add Topup</ModalHeader>
                    <ModalBody>
                        {/* <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="ipay_id">i-Pay ID</Label>
                                    <Input type="text" id="ipay_id" name="ipay_id" required
                                        placeholder="i-Pay ID" onChange={this.onAddTopuChange} />
                                </FormGroup>
                            </Col>
                        </Row> */}
                        <div className="row" style={{ margin: "0" }}>
                            <div className="col">
                                <div className="form-group col-l-6">
                                    <label>To</label>
                                    <select className="form-control" name="from" defaultValue="customer_name" onChange={this.onToFilterSelect}>
                                        <option value='customer_name'>Customer name </option>
                                        <option value='merchant_name'>Merchant name</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group col-l-6 ">
                                    <label>To</label>
                                    <Typeahead
                                        id="public-typeahead"
                                        defaultSelected={customersData.slice(0, 0)}
                                        labelKey={searchUserFilter}
                                        options={filteredUserData}
                                        placeholder="User detail"
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row receiver" style={{ marginBottom: "15px" }}>
                            <div className="col-md-12">
                                {selectedReceiver && this.renderReceiverDetails()}
                            </div>

                        </div>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="exampleSelect">Topup type</Label>
                                    <Input type="select" name="topup_type" id="exampleSelect" defaultValue="Direct deposit" onChange={this.onAddTopuChange}>
                                        <option value="Direct deposit">Direct deposit</option>
                                        <option value="Pay ID">Pay ID</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="amount">Amount</Label>
                                    <Input type="text" id="amount" name="amount" required
                                        placeholder="Amount" onChange={this.onAddTopuChange} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="receipt">Receipt</Label>
                                    <Input type="file" id="receipt" name="receipt" disabled={topup != null && topup.receipt_details != "" && typeof (topup.receipt_details) != "undefined" ? true : false}
                                        placeholder="Receipt" onChange={this.onAddTopuChange} required={topup != null && topup.receipt_details == "" || topup != null && typeof (topup.receipt_details) == "undefined" ? true : false} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <h5>OR <span style={{ color: "red", fontSize: "0.9rem" }}>(Note:You can either attach receipt or can enter the details.)</span></h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="receipt_details">Paste your your receipt details below</Label>
                                    <Input type="text" id="receipt_details" name="receipt_details" disabled={topup != null && topup.receipt != "" && typeof (topup.receipt) != "undefined" ? true : false}
                                        placeholder="Receipt" onChange={this.onAddTopuChange} required={topup != null && topup.receipt == "" || topup != null && typeof (topup.receipt) == "undefined" ? true : false} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="description">Description</Label>
                                    <Input type="input" id="description" name="description" disabled
                                        placeholder="Description" value={selectedReceiver ? selectedReceiver[0].ipay_id : null} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="comments">Additional comments</Label>
                                    <Input type="text" id="comments" name="description"
                                        placeholder="Additional comments" onChange={this.onAddTopuChange} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggleTopupModal}>Cancel</Button>
                        <Button color="primary" type="submit">Submit</Button>
                    </ModalFooter>
                </Form>
            </Modal >
        );
    }

    authorizeTopupClick = (id) => {
        if (localStorage.getItem("userId") == id) {
            toast.error("Not allowed.")
        } else {
            this.setState(prevState => {
                return {
                    ...prevState,
                    selectedId: id,
                    authorizeModal: !prevState.authorizeModal
                }
            });
        }
    }
    
    deleteClick = (id) => {
        this.setState(prevState => {
            return {
                ...prevState,
                selectedId: id,
                deleteModal: !prevState.deleteModal
            }
        })

    }

    toggleDeleteModal = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                deleteModal: !prevState.deleteModal
            }
        });
    }

    toggleAuthorizeModal = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                authorizeModal: !prevState.authorizeModal
            }
        });
    }

    authorizeTopup = (event) => {
        event.preventDefault();
        const { user } = this.state;
        const postData = { transactionId: this.state.selectedId, authorized_by: user.email };

        client.post(api.authorizeTopup, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    this.refreshPage();
                    //console.log(response);
                    toast.success(response.message);
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);

                }

            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        });

    }

    deleteTopup = (event) => {
        event.preventDefault();
        const postData = { transactionId: this.state.selectedId };

        client.post(api.deleteTopup, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    this.refreshPage();
                    //console.log(response);
                    toast.success(response.message);
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        });

    }

    renderAuthorizeModal = () => {
        const { authorizeModal } = this.state;

        return (
            <Modal isOpen={authorizeModal} toggle={this.toggleAuthorizeModal} className="modal-body" centered={true}>
                <form className="theme-form" onSubmit={this.authorizeTopup} >
                    <ModalHeader><h5 style={{ fontSize: "1.15rem" }}>Authorize Topup</h5></ModalHeader>
                    <ModalBody>
                        <ModalBody style={{ padding: "16px 0" }}>
                            <h5 style={{ fontSize: "1.15rem" }}>Are you sure you want to authorize the topup ?</h5>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.toggleAuthorizeModal}>No thanks!</Button>
                            <Button color="primary" type="submit"  >Yes Please!</Button>
                        </ModalFooter>
                    </ModalBody>
                </form>
            </Modal>
        );
    }

    renderDeleteModal = () => {
        const { deleteModal } = this.state;

        return (
            <Modal isOpen={deleteModal} toggle={this.toggleDeleteModal} className="modal-body" centered={true}>
                <form className="theme-form" onSubmit={this.deleteTopup} >
                    <ModalHeader><h5 style={{ fontSize: "1.15rem" }}>Delete Topup</h5></ModalHeader>
                    <ModalBody>
                        <ModalBody style={{ padding: "16px 0" }}>
                            <h5 style={{ fontSize: "1.15rem" }}>Are you sure you want to delete the topup ?</h5>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.toggleDeleteModal}>No thanks!</Button>
                            <Button color="primary" type="submit"  >Yes Please!</Button>
                        </ModalFooter>
                    </ModalBody>
                </form>
            </Modal>
        );
    }

    getName(id) {
        const foundCustomer = this.state.customersData.find(el => {
            return el._id == id && el.type == 1
        });

        if (foundCustomer) {
            return <NavLink to={{
                pathname: `${process.env.PUBLIC_URL}/customer/wallet/${foundCustomer._id}`,
            }}>
                {foundCustomer.name}
            </NavLink>
        } else {
            const foundMerchant = this.state.customersData.find(el => {
                return el._id == id && el.type == 2
            });
            if (foundMerchant) {
                return <NavLink to={{
                    pathname: `${process.env.PUBLIC_URL}/merchants/merchantDetails/${foundMerchant._id}`,
                }}>
                    {foundMerchant.business_name}
                </NavLink>
            } else {
                return "";
            }
        }
    }

    receiptClick = (details) => {
        this.setState(prevState => {
            return {
                ...prevState,
                receiptModal: !prevState.receiptModal,
                receiptDetails: details
            }
        })
    }

    toggleReceiptModal = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                receiptModal: !prevState.receiptModal
            }
        })
    }

    renderReceiptModal = () => {
        return (
            <Modal isOpen={this.state.receiptModal} toggle={this.toggleReceiptModal} className="modal-body" centered={true}>
                <form className="theme-form" noValidate="" >
                    <ModalHeader>Receipt Details</ModalHeader>
                    <ModalBody style={{ textAlign: "center" }}>
                        <h6>{this.state.receiptDetails}</h6>
                        <Button className="btn btn-danger" style={{ width: "100%", textAlign: "center", fontSize: "1.1rem", marginTop: "10px" }} onClick={this.toggleReceiptModal}>Dismiss</Button>
                    </ModalBody>

                </form>
            </Modal>
        );
    }

    getIpayId(id) {
        const foundCustomer = this.state.customersData.find(el => {
            return el._id == id
        });

        if (foundCustomer) {
            return foundCustomer.ipay_id
        } else {
            return
        }
    }

    renderTable = () => {
        const { allTopups, access_level } = this.state;

        return (
            <Fragment>
                {/* {(this.state.activePage) * this.state.pageSize > allTopups.length ?
                    <p style={{ textAlign: "right" }}>Showing {((this.state.activePage - 1) * this.state.pageSize) + 1} to {allTopups.length} of {allTopups.length} entries</p> :
                    <p style={{ textAlign: "right" }}>Showing {((this.state.activePage - 1) * this.state.pageSize) + 1} to {(this.state.activePage) * this.state.pageSize} of {allTopups.length} entries</p>} */}

                <div className="table-responsive">
                    <table className="table table-border-horizontal table-striped" >
                        <thead>
                            <tr >
                                <th scope="col" >#</th>
                                <th scope="col" style={{ width: "10%" }}>
                                    <p>Date </p> 
                                    <p>Time</p></th>
                                <th scope="col" >
                                    <p style={{ margin: "0", fontSize: "0.85rem" }}>Trans. ID/</p>
                                    <p style={{ margin: "0", fontSize: "0.85rem" }}>Topup Type</p>
                                </th>
                                <th scope="col" >From</th>
                                <th scope="col" >To</th>
                                <th scope="col" >Amount</th>
                                <th scope="col">Receipt</th>
                                <th scope="col">i-Pay ID</th>
                                <th scope="col">Status</th>
                                <th scope="col">
                                    <p style={{ margin: "0", fontSize: "0.85rem" }}>Authorized By/</p>
                                    <p style={{ margin: "0", fontSize: "0.85rem" }}>Authorized on</p>
                                </th>
                                {/* <th scope="col">Authorized on</th> */}
                                {access_level == 3 && <th scope="col">Action</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {allTopups.slice((this.state.activePage - 1) * this.state.pageSize, (this.state.activePage) * this.state.pageSize).map((item, index) => {
                                return (
                                    <tr  >
                                        <th scope=" row" >{((this.state.activePage - 1) * this.state.pageSize) + index + 1}</th>
                                        <td style={{ width: "10%" }}>{moment.tz(item.transaction_date, "Australia/Sydney").format("DD/MM/YYYY HH:mm:ss")}</td>
                                        <td >
                                            <p style={{ margin: "0", fontSize: "0.85rem" }}>{item.transaction_no}</p>
                                            <p style={{ margin: "0", fontSize: "0.85rem" }}>{item.topup_type == 2 ? "Direct deposit" : item.topup_type == 3 ? "Pay ID" : null}</p>
                                        </td>
                                        <td >i-Pay Bank</td>
                                        <td >
                                            {this.getName(item.to_customer_id)}
                                        </td>
                                        <td >${item.amount.toFixed(2)}</td>
                                        <td>{item.receipt.includes("png") || item.receipt.includes("jpeg") || item.receipt.includes("jpg") || item.receipt.includes("pdf") ?
                                            <img src={`${client.url(`images/backend-topup/${item.receipt}`)}`}
                                                onClick={() => this.imageClicked(`${client.url(`images/backend-topup/${item.receipt}`)}`)} style={{ width: "35px", height: "35px", borderRadius: "50%" }} ></img>
                                            : <p style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => this.receiptClick(item.receipt)}>Receipt details</p>}
                                        </td>
                                        <td>{this.getIpayId(item.to_customer_id)}</td>
                                        <td >{item.status == 0 ? <p className="authorize" onClick={() => this.authorizeTopupClick(item._id)}>Waiting</p> : "Authorized"}</td>
                                        <td >{item.authorized_on == null ? "" :
                                            <Fragment>
                                                <p style={{ fontSize: "0.85rem", margin: "0" }}>{item.authorized_by}</p>
                                                <p style={{ fontSize: "0.85rem" }}>{moment.tz(item.authorized_on, "Australia/Sydney").format("DD/MM/YYYY HH:MM:SS")}</p>
                                            </Fragment>}
                                        </td>
                                        {/* <td >{item.authorized_on != null && moment.tz(item.authorized_on, "Australia/Sydney").format("DD/MM/YYYY HH:MM:SS")}</td> */}
                                        {access_level == 3 && <td >
                                            <span style={{ marginRight: "10px", fontSize: "0.75rem" }}
                                                onClick={() => this.deleteClick(item._id)}
                                            >
                                                <i style={{ color: "red" }} id="delete" title="delete" className="icofont icofont-ui-delete"></i>
                                            </span>

                                        </td>}
                                    </tr>)
                            })}
                            {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <select className="custom-select" style={{ width: "32%", marginRight: "7px" }} onChange={this.setPageSize} value={this.state.pageSize}>
                                        <option value="15">15</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="75">75</option>
                                        <option value="100">100</option>
                                    </select>
                                    <span>Records per Page</span>
                                </div>
                                <PaginationComponent
                                    totalItems={allTopups.length}
                                    pageSize={this.state.pageSize}
                                    onSelect={this.handlePageChange} />

                            </div> */}
                        </tbody>
                    </table>
                </div>
            </Fragment>
        );
    }


    render() {
        const { topup, customersData, access_level, selectedReceiver, allTopups } = this.state;
        //console.log(user.email);
       // console.log(customersData);
        console.log(selectedReceiver);
        console.log(topup);
        return (
            <Fragment>
                {customersData && this.renderTopupModal()}
                {this.state.selectedId && this.renderAuthorizeModal()}
                {this.state.selectedId && this.renderDeleteModal()}
                <div className="row">
                <div className="col-lg-7 col-sm-12" style={{fontSize:"12px"}}>
                        <Breadcrumb title={"Top-up accounts"} parent={"Home"} />
                    </div>
                    <div className="col-lg-5 col-sm-12 ">
                        <div className="btn-group pull-right" style={{ paddingTop: "15px" }} role="group" aria-label="Basic example">
                        {access_level != 1 && <button className="btn btn-light"
                                onClick={this.toggleTopupModal} style={{ marginBottom: "20px" }}><DollarSign style={{ verticalAlign: "middle", width:"0.65rem", height:"0.65rem" }}></DollarSign><span > Top-up </span></button>}
                            
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12 col-sm-12">
                            <div className="card">
                                <div className="card-body" style={{ padding: "1rem" }}>
                                    <div>
                                        {/* {access_level != 1 && <button className="btn btn-outline-primary"
                                            onClick={this.toggleTopupModal}
                                            style={{ marginBottom: "20px" }}><DollarSign style={{ verticalAlign: "middle" }}></DollarSign><span style={{ fontSize: "1rem" }}> Top-up </span></button>} */}
                                        {this.state.allTopups && this.state.customersData ? this.renderTable() :
                                            <div className="loader-box" >
                                                <div className="loader" style={{ margin: "auto auto" }}>
                                                    <div className="line bg-primary"></div>
                                                    <div className="line bg-primary"></div>
                                                    <div className="line bg-primary"></div>
                                                    <div className="line bg-primary"></div>
                                                </div>
                                            </div>}
                                        {this.state.receiptDetails && this.renderReceiptModal()}
                                        {this.state.isOpen && (
                                            <Lightbox
                                                mainSrc={this.state.imageSrc}
                                                onCloseRequest={() => this.setState({ isOpen: false })}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Fragment>
        );
    }

}

export default TopupAccounts;