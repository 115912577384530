import React, { useState, useEffect } from 'react'
import { Edit } from 'react-feather';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import VerifiedMerchantProfile from "./VerifiedMerchantProfile";
import {VerifiedInviteUserProfile} from "./VerifiedInviteUserProfile";
import { MerchantUserProfile } from "./MerchantUserProfile";
import { Userandroles } from "./Userandroles";

import MerchantQr from "./MerchantQr";
import VerifiedMerchantTransaction from "./VerifiedMerchantTransaction";
import RewardHistory from "./RewardHistory";
import HospoSafeRegistry from "./HospoSafeRegistry";
import StripeConnect from "./StripeConnect";
import { StoresandCompany } from './StoresandCompany';
import InvitedUserAccessTable from './InvitedUserAccessTable';
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import {toast} from 'react-toastify'


export const InviteUserProfile = (props) => {

    const { profiledata: { merchant_user } } = props;

    const [user_access_merchants,set_user_access_merchants]=useState([])
    const [user_roles,set_user_roles]=useState('')
    // console.log(props.profiledata.merchant_user, "props")

    useEffect(()=>{

        console.log(merchant_user)
   getStoresList(props.profiledata.merchant_user.email)
    },[])

  const  getStoresList = (role_email) => {
        let postdata = JSON.stringify( {
            role_email: role_email
        })

        // console.log(postdata)

        client.post(api.stores_list_access, postdata, (error, response) => {

            if (!error) {

                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {

                            set_user_access_merchants(response.result.merchants)
                            set_user_roles(response.result.role)
                            console.log(response)

                            // this.setState({
                            //     stores_list: response.result,
                            // })
                        }

                    } catch (e) {
                        console.log(e);
                        setTimeout(() => {
                            toast.error("Internal error occured. Please contact support");
                        }, 200);
                    }
                } else {
                    setTimeout(() => {
                        toast.error("Internal error occured. Please contact support");
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }

        });
    }


    return (
        <div className="container-fluid" style={{ paddingBottom: 20 }}>
            <div className="row theme-tab" style={{ marginBottom: '0px !important' }} >
                <Tabs className="col-sm-12" defaultIndex={0} style={{ padding: "0" }}>
                    <TabList className="tabs tab-title" style={{ marginBottom: '0px !important' }}>
                        <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Profile Setting </Tab>
                        <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Access </Tab>
                       

                    
                       
                        {/* {props.profiledata.merchant_info.mobile_status == 0 && props.profiledata.merchant_info.request_source == 2 || props.profiledata.merchant_info.mobile_status == 0 && props.profiledata.merchant_info.request_source != 2 ?
                            null
                            :
                            <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Transaction History </Tab>
                        } */}
                       

                       

                        {/* {
                            props.merchantDataType && props.merchantDataType.type == 'trashMerchant' ? null
                                : <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Payouts </Tab>
                        } */}
                    </TabList>
                    <div className="tab-content-cls">
                        
                        <TabPanel>
                            <div className="container-fluid" style={{ paddingTop: '0.5rem' }}>
                                <VerifiedInviteUserProfile
                                    user_details={props.profiledata.merchant_user}
                                    merchant_id={props.profiledata.merchant_id}
                                    {...props}
                                />
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div className="container-fluid" style={{ paddingTop: '0.5rem' }}>
                                <InvitedUserAccessTable user_access_merchants={user_access_merchants} user_roles={user_roles}/>
                            </div>
                        </TabPanel>
                        

                    

                        {/* {
                            props.merchantDataType && props.merchantDataType.type == 'trashMerchant' ? null
                                : <TabPanel>
                                    <div className="container" style={{ paddingTop: '0.5rem' }}>
                                        <StripeConnect merchant_id={props.profiledata.merchant_id} merchant_info={props.profiledata.merchant_info}></StripeConnect>
                                    </div>
                                </TabPanel>
                        } */}
                    </div>
                </Tabs>
            </div>
        </div>

    )
}
