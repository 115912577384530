import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { toast } from "react-toastify";
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { DisappearedLoading } from "react-loadingg";
import { useParams, useLocation, Link, useHistory } from 'react-router-dom';
import UserSettingTable from "./UserSettingCmp/UserSettingTable";
import UserLoginHistory from "./UserSettingCmp/UserLoginHistory";
import { Nav, NavItem, NavLink, TabContent, TabPane, Button } from "reactstrap";


const MerchantUserSetting = () => {

    let { user_id } = useParams();
    const location = useLocation();
    let history = useHistory();
    const [loading, seLoading] = useState(true);
    const [merchantInfo, setMerchantInfo] = useState(null);
    const [getSubUserData, setGetSubUserData] = useState([]);
    const [loginHistory, setLoginHistory] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    // console.log(getSubUserData, "getSubUserData");
    const userSettingData = typeof window !== 'undefined' ? localStorage.getItem('userSettingData') : null;
    const [userDataa, setUserDataa] = useState(location.state != undefined ? location.state.userDataa : JSON.parse(userSettingData));

    // const getMerchantDetails = async () => {

    //     let postdata = { merchant_id: merchant_id };        
    //     seLoading(true);
    //     client.post(api.merchant_details, postdata, async function (error, response) {
    //         console.log(response, "user setting response")
    //         if (!error) {
    //             try {
    //                 if (!response.error) {
    //                     try {
    //                         const other_business_data = JSON.parse(response.result.contactDetails.other_business);
    //                         const other_business = other_business_data.map(el => {
    //                             return el.business_name
    //                         }).join()

    //                         setMerchantInfo({ merchant_info: response.result.contactDetails, other_business: other_business });

    //                     } catch (error) {
    //                         setMerchantInfo({ merchant_info: response.result.contactDetails, other_business: "N.A" });
    //                     }
    //                     seLoading(false);

    //                 } else {
    //                     toast.error(response.message);
    //                 }
    //             } catch (e) {
    //                 toast.error("Internal error occured. Please contact support 1");
    //             }
    //         } else {
    //             toast.error("Internal error occured. Please contact support 2");
    //         }
    //         seLoading(false);
    //     })
    // };

    const getAllSubUsers = async () => {

        let postdata = { userId: user_id };
        seLoading(true);
        client.put(api.getAllSubUsers, postdata, async function (error, response) {
            if (!error) {
                try {
                    if (!response.error) {
                        setGetSubUserData(response.result.data);
                        seLoading(false);
                    } else {
                        toast.error(response.message);
                    }
                } catch (e) {
                    toast.error("Internal error occured. Please contact support 1");
                }
            } else {
                toast.error("Internal error occured. Please contact support 2");
            }
            seLoading(false);
        })
    };

    const getLoginHistory = async () => {

        let postdata = { merchant_id: userDataa?.storeId };
        client.put(api.getUserLoginHistory, postdata, async function (error, response) {

            if (!error) {
                try {
                    if (!response.error) {
                        setLoginHistory(response.result.data);
                    } else {
                        toast.error(response.message);
                    }
                } catch (e) {
                    toast.error("Internal error occured. Please contact support 1");
                }
            } else {
                toast.error("Internal error occured. Please contact support 2");
            }
        })
    };

    useEffect(() => {

        if (user_id) {
            getAllSubUsers();
        }

    }, [])

    useEffect(() => {
        if (!loginHistory.length || userDataa) {

            getLoginHistory();
        }
    }, [loginHistory])


    useEffect(() => {
        try {
            let tempArr = []

            if (userDataa) {
                let tempObj = {
                    storeName: userDataa.storeName,
                    storeId: userDataa.storeId,
                    adminId: userDataa.adminId,
                    profileImage: userDataa.profileImage,
                    totalUsers: userDataa.totalUsers,
                    unlimitedusers: userDataa.unlimitedusers,
                    planTitle: userDataa.planTitle,
                    usersList: [],
                    hidenUsersList: []
                }
                if (getSubUserData.length) {
                    getSubUserData.map((user) => {
                        if (user.superUser) {
                            tempObj.usersList.push(user)

                        } else {
                            if (user.access_merchants.length) {
                                user.access_merchants.map((accMerc) => {

                                    if (!accMerc.hide && accMerc.merchant_id == userDataa.storeId) {
                                        tempObj.usersList.push(user)
                                    } else if (accMerc.hide && accMerc.merchant_id == userDataa.storeId) {
                                        tempObj.hidenUsersList.push(user)
                                    }
                                })
                            }
                        }
                    })
                }
                tempObj.noOfUsers = userDataa.totalUsers < tempObj.usersList.length && userDataa.unlimitedusers == false ? userDataa.totalUsers : tempObj.usersList.length;

                if (userDataa.totalUsers < tempObj.usersList.length & userDataa.unlimitedusers == false) {
                    tempObj.usersList = tempObj.usersList.slice(0, userDataa.totalUsers)
                }

                tempArr.push(tempObj);
                setUserDataa(tempArr[0]);
            }
        } catch {

        }

    }, [getSubUserData, location])


    // console.log(userDataa,'userData')


    return (
        <Fragment>
            <div className="container-fluid">
                {
                    !loading ?
                        <div>
                            <div className="col-md-12 col-sm-12" style={{ fontSize: "12px" }}>
                                {/* <Breadcrumb
                                    title={merchantInfo.merchant_info.business_name}
                                    mobile={merchantInfo.merchant_info.mobile}
                                    email={merchantInfo.merchant_info.email}
                                    ipay_id={merchantInfo.merchant_info.ipay_id}
                                    parent={"Merchants"}
                                /> */}
                            </div>
                            <div className="row mt-4">
                                <div className="col-sm-12 col-xl-12">
                                    <div className="container-fluid" style={{ paddingBottom: 20 }}>
                                        <div className="row theme-tab" style={{ marginBottom: '0px !important' }} >
                                            <Tabs
                                                className="col-sm-12"
                                                defaultIndex={tabIndex}
                                                onSelect={(index) => { setTabIndex(index); }}
                                                style={{ padding: "0", backgroundColor: "white", borderRadius: "3px", border: "1px solid rgb(223, 232, 241)", marginBottom: "0px" }}>
                                                <TabList className="tabs tab-title" style={{ marginBottom: '0px !important' }}>
                                                    <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Users Settings </Tab>
                                                    <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}>Login History </Tab>
                                                    <div className="d-flex align-items-center ml-auto">
                                                        <Button
                                                            color='primary'
                                                            size='sm'
                                                            outline
                                                            style={{
                                                                borderRadius: '3px',
                                                                marginTop: "5px",
                                                                marginRight: "2rem",
                                                                marginBottom: "0px"
                                                            }}
                                                            onClick={() => {
                                                                history.goBack();
                                                            }}
                                                        > Go back </Button>
                                                    </div>
                                                </TabList>
                                                <div className="tab-content-cls">
                                                    <TabPanel>
                                                        <div className="container-fluid" style={{ paddingTop: '0.5rem' }}>
                                                            <UserSettingTable userDataa={userDataa} />
                                                        </div>
                                                    </TabPanel>

                                                    <TabPanel>
                                                        <div className="container-fluid" style={{ paddingTop: '0.5rem' }}>
                                                            <UserLoginHistory loginHistory={loginHistory} />
                                                        </div>
                                                    </TabPanel>
                                                </div>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                            <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                                <DisappearedLoading size="medium" color="#514F4E" />
                            </div>
                        </div>
                }
            </div>
        </Fragment>
    );
};

export default MerchantUserSetting;
