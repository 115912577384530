import React, { Fragment, useState, useEffect } from "react";
import "./logoAndBusinessTypes.scss";
import { toast } from "react-toastify";
import useForm from "react-hook-form";
import { useHistory } from "react-router-dom";
import { mdiCog } from "@mdi/js";
import Icon from "@mdi/react";

const LogoAndBusinessTypeTable = ({
    getAllData,
    update_Data,
    pageSizeFunc,
    defaultVaue,
    deleteData
}) => {


    let { register } = useForm({ defaultValues: defaultVaue });

    let history = useHistory();
    const [pageSize, setPageSize] = useState(15);

    return (
        <Fragment>
            <div className="container-fluid main_div mb-4">
                <div className="row">
                    <form noValidate="" className="w-100">
                        <div className="col-sm-12 col-xl-12 xl-100" style={{ padding: "0" }}>
                            <div className="card" style={{ marginBottom: '0px' }}>
                                <div className="card-body input_box" style={{ padding: "0rem 1rem" }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "end", margin: '1.5rem 0rem' }}>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <select
                                                className="custom-select"
                                                style={{ width: "32%", marginRight: "7px" }}
                                                onChange={pageSizeFunc}
                                                value={pageSize}
                                            >
                                                <option value="15">15</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="75">75</option>
                                                <option value="100">100</option>
                                            </select>
                                            <span>Records per Page</span>
                                        </div>
                                    </div>

                                    <div className="table-responsive">
                                        <table className="table table-border-horizontal">
                                            <thead>
                                                <tr style={{ background: '#f9fafe', width: "100%" }}>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Brand Name</th>
                                                    <th scope="col">Website URL</th>
                                                    <th scope="col">Email configurations</th>
                                                    <th scope="col">SMS Sender name</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>

                                            <tbody>

                                                {
                                                    getAllData && getAllData.page_data.map((datas, index) => {

                                                        return (
                                                            <>
                                                                <tr key={index}>
                                                                    <td scope="row">{index + 1}</td>
                                                                    <td>
                                                                        <input
                                                                            style={{
                                                                                border: "#dfe8f1 solid 1px",
                                                                                borderRadius: "3px",
                                                                                padding: "10px",
                                                                                fontSize: "0.75rem",
                                                                                outlineColor: '#dfe8f1',
                                                                                width: "90px"
                                                                            }}
                                                                            type="text"
                                                                            placeholder="Brand Name"
                                                                            name={`${datas._id}_brand_name`}
                                                                            ref={register}
                                                                            onBlur={(event) => {

                                                                                let getValue = event.target.value;
                                                                                if (getValue.length == 0) {
                                                                                    toast.error("Field Shouldn't be Empty", { autoClose: 2000, hideProgressBar: true });
                                                                                } else if (getValue.length > 0 && getValue != datas.brand_name) {
                                                                                    let postData = { ...datas, brand_name: event.target.value }

                                                                                    update_Data(postData);
                                                                                }
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td >
                                                                        <div style={{}}>
                                                                            {datas.brand_ID != 3 && <p className="portalURL" style={{ color: "#000", fontSize: "12px" }}>{datas.website_URL.first_URL}</p>}
                                                                            {datas.website_URL.secondURL && <p className="portalURL" style={{ color: "#000", fontSize: "12px" }}>{datas.website_URL.secondURL}</p>}
                                                                            <p className="portalURL" style={{ color: "#000", fontSize: "12px" }}>{datas.website_URL.third_URL}</p>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <p><span style={{ color: "#000" }}>Sender name:</span> <span style={{ fontSize: "14px" }}>{datas.email_sender_name}</span></p>
                                                                        <p><span style={{ color: "#000" }}>Sender id:</span> <span style={{ fontSize: "14px", textDecoration: "underline" }}>{datas.email_id}</span></p>
                                                                        <p style={{ width: "240px" }}><span style={{ color: "#000" }}>Support id:</span> <span style={{ fontSize: "14px", textDecoration: "underline" }}>{datas.support_email}</span></p>
                                                                        <p><span style={{ color: "#000" }}>Contact no:</span> <span style={{ fontSize: "14px" }}>{datas.support_no}</span></p>
                                                                    </td>
                                                                    <td>
                                                                        <p style={{}}><span style={{ fontSize: "14px" }}>{datas.email_sender_name}</span></p>
                                                                    </td>
                                                                    <td>
                                                                        <p
                                                                            style={{}}
                                                                            onClick={() => {
                                                                                history.push(`/white_label_merchants/${datas._id}`)
                                                                            }}>
                                                                            <Icon
                                                                                path={mdiCog}
                                                                                style={{ fontSize: '13px', width: '19px', height: '19px', marginBottom: '4px' }}
                                                                                color="#3699DB" />
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                    {/* <div
                                        className='border p-3 my-3'
                                        style={{ width: "15%" }}
                                        onClick={() => { deleteData() }}
                                    >
                                        Delete Data
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default LogoAndBusinessTypeTable