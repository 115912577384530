import React from 'react'
import {Link} from 'react-router-dom'

import './MerchantInvoiceDetailsCss.scss'

function InvitedUserAccessTable(props) {

    const {user_access_merchants,user_roles}=props

    console.log(user_access_merchants)

    return (  
        <>

        

{
user_access_merchants.length>0?
<>
<h5 className='mt-4 mb-3 ' style={{fontWeight:700}}>User has access to below stores</h5>

<div className="table-responsive mb-4">
											<table className="table table-border-horizontal">
												<thead>
													
														<tr style={{ background: '#f9fafe' }}>
															<th>#</th>
															<th scope="col">Store</th>
															<th scope="col">Role</th>
															
															<th scope="col">Action</th>
														</tr>
														
												</thead>

												<tbody>

                                                    {
                                                        user_access_merchants?.map((elem,i)=>{
                                                         return(           <tr>
                                                        <td>{i+1}</td>
                                                        <td style={{textTransform:'capitalize'}}>
                                                            <div className='d-flex align-items-center py-2'>
                                                                

                                                            <span
                                            className="border p-2 mr-2"
                                            style={{
                                                fontSize: "16px",
                                                minWidth: 40,
                                                width:40,
                                                height: 40,
                                                borderRadius: 20,
                                                display: "block",
                                                textAlign: "center",
                                            }}
                                        >
                                            <b style={{ textTransform: "uppercase" }}>
                                                {
                                                    elem.business_name?.slice(0, 2)
                                                    // val.storeName?.split(/\s/)
                                                    //     .reduce(
                                                    //         (response, word) =>
                                                    //             (response += word.slice(0, 1)),
                                                    //         ""
                                                    //     ).slice(0, 2)
                                                }
                                            </b>
                                        </span>
                                                            
                                                        {/* {elem.Store_logo_image?.length >= 0 ? (
                                    <>
                                       
                                    </>
                                ) : (
                                    <>
                                        <img
                                            className={`mr-2`}
                                            style={{
                                                minWidth: 40,
                                                width:40,
                                                height: 40,
                                                borderRadius: 20,
                                            }}
                                            // src={`${client.url(
                                            //     `images/merchant-profile/${elem.profileImage
                                            //     }`
                                            // )}`}
                                            src={''}
                                            alt="avatar"
                                        />
                                    </>
                                )} */}

                                                         <span>{elem.business_name}</span> 
                                                         </div>  </td>
                                                        <td>{elem?.roleName}</td>
                                                        <td>
                                                            <Link to={`/merchants/merchantDetails/${elem._id}`} className='view_store_btn'>View Store</Link>
                                                            
                                                        </td>
                                                    </tr>)
                                                        })
                                                    }

                                                    
                                                    
                                                </tbody>
											</table>
											
										</div>
                                        </>
                                        :
                                        <h6 className='my-3 text-center'>This User does not have access of any store</h6>

}


        
        </>
       
    );
}

export default InvitedUserAccessTable;