import React, { useEffect } from "react";
import { Progress } from 'reactstrap';
import classes from "./ImageUploadingLoader.module.css";

const Backdrop = ({ customView, bg = 'white' }) => {

    useEffect(() => {
        document.body.style.overflowY = 'hidden';
        document.body.style.pointerEvents = 'none';
        return () => {
            document.body.style.overflowY = 'auto';
            document.body.style.pointerEvents = 'auto';
        }
    }, [])

    return (
        <div className={classes.Backdrop}>
            <div style={{
                width: '40%',
                position: 'relative',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                background: bg
                // background: 'white'
            }}>
                {/* props.customView ?
                        props.customView */}
                {
                    customView ?
                        customView
                        : <div>
                            <div className="border-bottom px-4 py-3">
                                <h4 className="m-0"> <b style={{ color: 'black' }}> UPLOADING FILE(S) </b> </h4>
                            </div>

                            <div className="px-4 py-4">
                                <h5 className="mx-0 mt-0 mb-2" style={{ color: '#3E3E3E' }}> Please do no leave this page or your upload will stop. </h5>
                                <Progress animated color="warning" value={100} />
                                <small className="m-0"> <b>Progress</b>: Upload in progress. Please wait… </small>
                            </div>
                        </div>
                }
            </div>
        </div>
    );
}

export default Backdrop;