import React, { useEffect, useState } from "react"
import { Fragment } from "react"
import client from "../../Api/HTTPClient"
import api from "../../constant/apilist"
import { toast } from "react-toastify"
import moment from "moment-timezone";
import PaginationComponent from "react-reactstrap-pagination";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { DisappearedLoading } from "react-loadingg";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Table, Row, Col, Card, CardBody, Container, ButtonToolbar } from "reactstrap";

const Invoices = () => {

  const [pageSize, setPageSize] = useState(15)
  const [searchValue, setSearchValue] = useState(null)
  const [data, setData] = useState([]);
  const [fdata, setFdata] = useState(null)
  const [activePage, setActivePage] = useState(1)
  const [invoiceDetails, setInvoiceDetails] = useState()
  const [invoiceFrom, setInvoiceFrom] = useState()
  const [toggleInvoiceModal, setToggleInvoiceModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [count, setCount] = useState(0);

  let pdata = ""
  if (!searchValue) {
    pdata = fdata
  } else {
    pdata = count
  }

  const fetchData = () => {

    let skip = (parseInt(activePage) - 1) * parseInt(pageSize);
    let countryCode = localStorage.getItem("sessionCountryCode")

    if (searchValue !== '' && searchValue !== null) {
      skip = 0;
      // setActivePage(1);
    }

    let postData = {
      countryCode: countryCode,
      limit: parseInt(pageSize),
      searchValue: searchValue ? searchValue.trim() : null,
      skip: skip,
      type: "subscription_invoices"
    };

    setLoading(true)

    client.post(api.merchant_request_list, postData, async function (error, response) {
      if (!error) {
        try {
          // console.log(response, "responseresponse");
          if (!response.error) {
            setData(response.result.data)
            setCount(response.result.count)
            setLoading(false);
          }
        } catch (err) {
          toast.error(response.message)
          console.log(err)
          setLoading(false);
        }
      } else {
        toast.error(response.message)
        setLoading(false);
      }
    })
  }

  const fetchInvoice = (invoiceData) => {
    // console.log(invoiceData, "fetchinvoicedata")
    setInvoiceFrom(invoiceData)
    let invoice_id = invoiceData._id;
    if (invoice_id) {
      client.get(api.fetch_invoice, { invoice_id }, async function (error, response) {
        if (!error) {
          if (!response.error) {
            setInvoiceDetails(response.invoice_from);
            setToggleInvoiceModal(!toggleInvoiceModal);
          } else {
            toast.error("Internal error occured.Please contact support");
          }
        }
        else {
          toast.error("Internal error occured.Please contact support");
        }
      })
    }
  }


  useEffect(() => {
    let countryCode = localStorage.getItem("sessionCountryCode")
    let postData = { countryCode: countryCode }
    client.post(api.merchantTabCount, postData, async function (error, response) {
      if (!error) {
        try {
          if (!response.error) {
            // console.log(response.result.tab_data.subscription_invoices_count)
            setFdata(response.result.tab_data.subscription_invoices_count)
          }
        } catch (err) {
          toast.error(response.message)
          console.log(err)
        }
      }
    })
  }, [])


  useEffect(() => {

    const getData = setTimeout(() => {
      fetchData();
    }, 1000);

    return () => clearTimeout(getData)

  }, [searchValue, pageSize])


  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber)
    fetchData()
  }

  const renderInvoiceDetailsModal = () => {
    let invoiceData = invoiceDetails
    // let invoiceFrom = this.state.invoiceFrom;

    return (
      <Modal size="lg" isOpen={toggleInvoiceModal} toggle={() => setToggleInvoiceModal(!toggleInvoiceModal)} className="modal-body" centered={true}>
        <ModalBody>
          <Card className='merchantInvoice' style={{ margin: 0 }}>
            <CardBody style={{ padding: '5px' }}>
              {invoiceData && <Container>

                <Row className='pt-4 pb-3'>
                  <Col md={12}>
                    <div className='border rounded p-4'>
                      <Row>
                        <Col md={6} className='d-flex align-items-center justify-content-start'>
                          <div
                            className="invoice__logo"
                            style={{
                              height: '50px',
                            }}
                          />
                        </Col>
                        <Col md={6} className='d-flex align-items-center justify-content-end'>
                          <div>
                            <p className='invoice__grand-total' > Invoice #{invoiceFrom.invoice_number} </p>
                          </div>
                        </Col>
                      </Row>
                      <Row className='py-3 border-top'>
                        <Col md={8} className='text-left'>
                          <div>
                            <p className='my-0'> {invoiceData?.business_name} </p>
                            <p className='my-0'>
                              {/* {invoiceFrom.address.split(',').map((line, i) => (
													<span key={i}>
														{line}
														<br />
													</span>
												))
												} */}
                              {invoiceData?.address}
                            </p>
                            {invoiceData?.abn != "" &&
                              <p dir="ltr" className='my-0'> ABN - {invoiceData?.abn} </p>
                            }
                            {invoiceData?.website != "" &&
                              <a href={invoiceData?.website} target='_blank'> {invoiceData?.website} </a>
                            }
                            {/* <a href='https://www.store.com' target='_blank'> www.store.com </a> */}
                          </div>
                        </Col>
                        <Col md={4} className='text-right'>
                          <div>
                            <p className="invoice__date my-0"> Date/Time : {moment(invoiceFrom.payment_date).format('DD-MM-YYYY/H:mm')} </p>
                            <p className='my-0' style={{ textTransform: "capitalize" }}> {invoiceFrom?.fk_merchant_id?.business_name} </p>
                            {/* <p className='my-0'> {invoiceData?.fk_merchant_id?.address} </p>
												*/}
                            <p className='my-0'>
                              {/* {invoiceData?.fk_merchant_id?.address.split(',').map((line, i) => (
													<span key={i}>
														{line}
														<br />
													</span>
												))
												} */}
                              {invoiceData?.fk_merchant_id?.address}
                            </p>
                            {/* <p className='my-0'> Australia </p> */}
                            {invoiceData?.fk_merchant_id?.country?.code == "AU" && invoiceData?.fk_merchant_id?.company_id?.abn && invoiceData?.fk_merchant_id?.company_id?.abn != "" &&
                              <p dir="ltr" className='my-0'> ABN - {invoiceData?.fk_merchant_id?.company_id?.abn} </p>
                            }
                          </div>
                        </Col>
                      </Row>
                      <Row className='py-3'>
                        <Col md={12}>
                          <Table className="table--bordered" responsive striped>
                            <thead>
                              <tr>
                                <th> # </th>
                                <th> Plan </th>
                                <th> Quantity </th>
                                {invoiceFrom?.invoice_title?.includes("White label Enterprise plan") ? null : <th> Unit Cost </th>}
                                <th> Total </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                <tr>
                                  <td>1</td>
                                  <td>{invoiceFrom?.invoice_title}</td>
                                  <td>1</td>
                                  {invoiceFrom?.invoice_title?.includes("White label Enterprise plan") ? null : <td>{invoiceFrom?.fk_merchant_id?.country?.currency_symbol}{invoiceFrom?.sub_total?.toFixed(2)}</td>}
                                  <td>{invoiceFrom?.fk_merchant_id?.country?.currency_symbol} {invoiceFrom?.sub_total?.toFixed(2)}</td>
                                </tr>
                              }
                            </tbody>
                          </Table>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={12} className='text-right'>
                          <div className="invoice__total">
                            <p> Sub - total amount: {invoiceFrom?.fk_merchant_id?.country?.currency_symbol}{(invoiceFrom?.sub_total)?.toFixed(2)} </p>
                            {invoiceFrom?.discount > 0 ? (<p> Discount: {invoiceFrom?.fk_merchant_id?.country?.currency_symbol}{(invoiceFrom?.discount).toFixed(2)} </p>) : ('')}
                            {invoiceFrom?.promo_code_discount > 0 && <p>Promo code :{invoiceFrom?.fk_merchant_id?.country?.currency_symbol}{(invoiceFrom?.promo_code_discount).toFixed(2)}</p>}

                            {invoiceFrom?.charges && invoiceFrom?.charges.map((item) =>
                              <p> {item.title}: {invoiceFrom?.fk_merchant_id?.country?.currency_symbol}{(item.amount).toFixed(2)} </p>
                            )}
                            <p className="invoice__grand-total"> Grand Total: {invoiceFrom?.fk_merchant_id?.country?.currency_symbol}{(invoiceFrom?.total)?.toFixed(2)} </p>
                            <ButtonToolbar className="invoice__toolbar">
                              {/* <Button color="primary"> Proceed to payment </Button> */}
                              {/* <Button> Print </Button> */}
                            </ButtonToolbar>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

              </Container>}

            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    );
  }

  return (
    <Fragment>
      {true ?
        <div>
          <div className="mx-3 my-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: '5px' }}>
            <div>
              <form onSubmit={(event) => { event.preventDefault() }}
              >
                <div className="">
                  <input
                    style={{
                      border: "#dfe8f1 solid 1px",
                      borderRadius: "3px",
                      padding: "10px",
                      fontSize: "0.75rem",
                      outlineColor: '#dfe8f1',
                      width: "350px"
                    }}
                    type="text"
                    placeholder="Search"
                    value={searchValue}
                    onChange={(e) => { setSearchValue(e.target.value) }}
                  />
                </div>
              </form>
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <select
                className="custom-select"
                style={{ width: "32%", marginRight: "7px" }}
                onChange={(e) => { setPageSize(e.target.value) }}
                value={pageSize}
              >
                <option value="15">15</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="75">75</option>
                <option value="100">100</option>
              </select>
              <span>Records per Page</span>
            </div>
          </div>
          <div className="table-responsive px-3 mb-3" style={{}}>
            <table className="table table-border-horizontal">
              <thead>
                <tr style={{ background: '#f9fafe' }}>
                  <th>#</th>
                  <th scope="col">Invoice No</th>
                  <th scope="col">Invoice Date</th>
                  <th scope="col">Company</th>
                  <th scope="col">Store</th>
                  <th scope="col">Description</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {!loading ?
                  data.length > 0 ?
                    <>
                      {
                        data.map((item, index) => {
                          return (
                            <tr key={item._id}>
                              <td scope='row'>{(activePage - 1) * pageSize + 1 + index}</td>
                              <td scope=" row">{item.invoice_number}</td>
                              <td scope="row">{moment(item.createdAt).format('DD-MM-YYYY/H:mm')}</td>
                              <td scope=" row">{item.company_name}</td>
                              <td scope=" row">{item.store_name}</td>
                              <td scope=" row">{item.invoice_title}</td>
                              <td scope=" row">{item.fk_merchant_id.country.currency_code} {item.total.toFixed(2)}</td>
                              <td scope=" row">{item.invoice_title.includes("Trial Plan") ? '-' : item.status == 1 ? (<span className='text-success'>Paid</span>) : (<span className='text-danger'>Pending</span>)}</td>
                              <td>
                                <VisibilityIcon
                                  onClick={() => {
                                    setInvoiceDetails(item)
                                    fetchInvoice(item)
                                  }}
                                  style={{ fontSize: 15, margin: '8px auto', display: 'block', color: '#327ebc', cursor: 'pointer' }}
                                />
                              </td>
                            </tr>
                          )
                        })
                      }
                    </>
                    :
                    <tr>
                      <td
                        colspan="10"
                        scope='row'
                        style={{ height: "30vh", padding: 10, textAlign: 'center', fontSize: "15px" }}
                      >
                        No records found
                      </td>
                    </tr>
                  :
                  <div>
                    <div className="loader-box" style={{ height: "70vh", width: "100wh" }}>
                      <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                        <DisappearedLoading size="medium" color="#514F4E" />
                      </div>
                    </div>
                  </div>
                }
              </tbody>
            </table>
            {!loading && data.length >= pageSize ? <div className="my-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              {
                activePage * pageSize > pdata ?
                  (<p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>
                    {`Showing ${(activePage - 1) * pageSize + 1} to ${pdata} of ${pdata}`}
                  </p>)
                  :
                  (<p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>
                    {`Showing ${(activePage - 1) * pageSize + 1} to ${(activePage - 1) * pageSize + pageSize} of ${pdata}`}

                  </p>)
              }
              <PaginationComponent
                totalItems={pdata ?? 0}
                pageSize={pageSize}
                onSelect={handlePageChange}
                defaultActivePage={activePage}
              />
            </div> : null}
          </div>
        </div>
        : <div>
          <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
            <div className="loader" style={{ display: "flex", alignItems: "center" }}>
              <DisappearedLoading size="medium" color="#514F4E" />
            </div>
          </div>
        </div>
      }
      {renderInvoiceDetailsModal()}
    </Fragment>
  )
}

export default Invoices