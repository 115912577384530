import React, { useRef, useEffect } from "react";

let autoComplete;

function handleScriptLoad(handleSelectAddress, autoCompleteRef, country_code) {
    autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
        componentRestrictions: { country: country_code.toLowerCase() != "other" ? country_code : undefined },
    });
    autoComplete.addListener("place_changed", () => {
        handlePlaceSelect(handleSelectAddress);
    });
}

async function handlePlaceSelect(handleSelectAddress) {
    const addressObject = autoComplete.getPlace();
    const ac = addressObject.address_components;
    let suburb = "",
        state = "",
        country = "",
        postcode = "";
    for (let index = 0; index < ac?.length; index++) {
        const item = ac[index];
        switch (item["types"][0]) {
            case "locality":
                suburb = item["long_name"];
                break;
            case "administrative_area_level_1":
                state = item["long_name"];
                break;
            case "country":
                country = item["long_name"];
                break;
            case "postal_code":
                postcode = item["long_name"];
                break;
        }
    }

    const formatted_address = addressObject.formatted_address;
    let lat = addressObject.geometry?.location.lat();
    let lng = addressObject.geometry?.location.lng();

    handleSelectAddress({ formatted_address, lat, lng, suburb, state, country, postcode })
}

let GoogleAutoComplete = ({
    merchant_info,
    handleAddressChange,
    countryCode,
    handleSelectAddress,
}) => {
    const autoCompleteRef = useRef(null);

    useEffect(() => {
        let country_code = countryCode ? countryCode.toLowerCase() : "au";
        handleScriptLoad(handleSelectAddress, autoCompleteRef, country_code.toLowerCase());
    }, [countryCode]);

    return (
        <div className="col-lg-12">
            <div className="form-group">
                <label className="form-label">Address</label>
                <input className="form-control" ref={autoCompleteRef} type="text" value={merchant_info.address} onChange={(e) => handleAddressChange(e.target.value)} />
            </div>
        </div>
    );
};

export default GoogleAutoComplete;