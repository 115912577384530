import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Breadcrumb from "../../components/common/breadcrumb";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { DisappearedLoading } from "react-loadingg";

const PaymentGateway = () => {
	
	const [loading, setLoading] = useState(true);
	const [updateloading, setupdateLoading] = useState(false);
	const [result, setresult] = useState(false);
	const [checkedCode, setcheckedCode] = useState("");

	useEffect(() => {
		if (result.length > 0) {
			setcheckedCode(result.find(a => a.is_selected === true).code)
		}
	}, [result])

	// useEffect(() => {

	// }, [checkedCode])

	useEffect(() => {
		const postData = {};
		client.get(
			api.get_payment_gateway,
			postData,
			(error, response) => {
				if (!error) {
					if (!response.error) {
						setresult(response.result)
						
					} else {
						toast.error("Internal error occured.Please ontact support");
					}
				} else {
					toast.error("Internal error occured.Please ontact support");
				}
				setLoading(false);
			}
		);
	}, []);

	

	const onSaveChanges = (status, data) => {
		const postData = {
			payment_gateway: data,
			status: status,
		};
		// console.log({ postData });
		setcheckedCode(data)
		client.post(
			api.updateDefaultPaymentGateway,
			postData,
			(error, response) => {
				console.log(error);
				if (!error) {
					if (!response.error) {
						console.log(response);
						//setGuardSetting(response.result.data);
						setLoading(false);
						toast.success("Changes Saved");
					} else {
						setLoading(false);
						toast.error("Internal error occured.Please ontact support");
					}
				} else {
					setLoading(false);
					toast.error("Internal error occured.Please ontact support");
				}
			}
		);
	};

	if (loading) {
		return (
			<div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
				<div className="loader" style={{ display: "flex", alignItems: "center" }}>
					<DisappearedLoading size="medium" color="#514F4E" />
				</div>
			</div>
		)
	}

	return (
		<div className="container-fluid">
			<div className="row">
				
				<div className="col-sm-12 col-xl-12 xl-100">
					<div className="card" style={{ border: '1px solid rgb(223, 232, 241)', borderRadius: '3px' }}>
						<div className="card-body" style={{ padding: "1rem" }}>
							<p style={{ fontWeight: "bold" }}>Set Active Payment Gateways</p>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "flex-start",
								}}
							>
								{result && result.map(item =>
									<div className="mr-3">
										<input
											type="checkbox"
											name="payment_gateway"
											value={item.code}
											key={item._id}
											disabled={!item.active}
											id={item._id}
											defaultChecked={item.is_selected}
											// selected={item.code == checkedCode}
											onChange={(event) => {
												if (item.active) {

													onSaveChanges(event.target.checked, item.code)
													// console.log(event.target, "event.target")
													// onSaveChanges(event.target.value)
												} else {
													toast.error("Coming soon");
												}
											}
											}
											onClick={() => {
												if (!item.active) {
													toast.error("Coming soon");

												}
											}}
										/>
											<label
											id="banner-image"
											for={item._id}
											
												style={{
													paddingLeft: "10px"
												}}
											>{item.title}</label>
									</div>
								)}
								
							</div>
							{/* <div className="saveBtncont" style={{ marginTop: "2rem" }}>
								<button
									color="primary"
									className="btn btn-outline-primary btn-xs"
									type="button"
									onClick={onSaveChanges}
								>
									Save
								</button>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PaymentGateway;
