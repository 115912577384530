import React, { Fragment, useState } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { DollarSign, MapPin, X, TrendingDown, ArrowUp, ShoppingCart, Search, Activity, User, Users, Book } from 'react-feather';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import EmailTemplates from './EmailTempates';
import SmsTemplates from './SmsTemplates';
import AutoReminders from './Autoreminder/AutoReminder';
import SmsHistory from './SmsHistory';


const SpeedMails = () => {
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [modal3, setModal3] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [access_level, setAccessLevel] = useState(localStorage.getItem("accessLevel"))

    const toggle = () => {
        setModal(!modal)
    }

    const toggle2 = () => {
        setModal2(!modal2)
    }
    const toggle3 = () => {
        setModal3(!modal3)
    }

    return (
        <Fragment>

            <div className="row">
                <div className="col-lg-7 col-sm-12" style={{ fontSize: "12px" }}>
                    <Breadcrumb title={"Speed mails"} parent={"Home"} />
                </div>
                <div className="col-lg-5 col-sm-12 ">
                    <div className="btn-group" style={{ paddingTop: "10px", marginLeft: "0.4rem", marginRight: "0.6rem", marginBottom: "0rem", width: "auto", display: "flex", justifyContent: "flex-end", alignItems: "center" }} role="group" aria-label="Basic example">
                        {access_level != 1 && <button className="btnAddNewTemp custBtnAdmin"
                            onClick={tabIndex == 0 ? toggle : tabIndex == 1 ? toggle2 : tabIndex == 2 ? toggle3 : null}><i className="fa fa-plus-square-o" style={{ color: "#327ebc", margin: "0 7px 0 0" }}></i>Add new template
                        </button>}

                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row theme-tab">
                    <Tabs className="col-sm-12" defaultIndex={tabIndex} style={{ padding: "0", backgroundColor: "white", borderRadius: "3px", border: "1px solid rgb(223, 232, 241)", marginBottom: "0px" }} onSelect={index => setTabIndex(index)}>
                        <TabList className="tabs tab-title">
                            <Tab style={{ display: "flex", alignItems: "center" }}>
                                <Search />Email templates
                            </Tab>
                            <Tab style={{ display: "flex", alignItems: "center" }}>
                                <Users />SMS templates
                            </Tab>
                            <Tab style={{ display: "flex", alignItems: "center" }}>
                                <ShoppingCart />Auto reminders
                            </Tab>
                            <Tab style={{ display: "flex", alignItems: "center" }}>
                                <Book />SMS History
                            </Tab>
                        </TabList>
                        <div className="tab-content-cls">
                            <TabPanel style={{ display: "flex", alignItems: "center" }}>
                                <EmailTemplates toggle={toggle} modal={modal} tabIndex={tabIndex}></EmailTemplates>
                            </TabPanel>

                            <TabPanel style={{ display: "flex", alignItems: "center" }}>
                                <SmsTemplates toggle2={toggle2} modal2={modal2} tabIndex={tabIndex}></SmsTemplates>
                            </TabPanel>
                            <TabPanel style={{ display: "flex", alignItems: "center" }} >
                                <AutoReminders toggle3={toggle3} modal3={modal3} tabIndex={tabIndex} />
                            </TabPanel>
                            <TabPanel style={{ display: "flex", alignItems: "center" }}  >
                                <SmsHistory/>
                            </TabPanel>
                        </div>
                    </Tabs>
                </div>
            </div>
        </Fragment>
    );
}

export default SpeedMails;