import React, { Component, Fragment } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink as NavLinkTab } from "reactstrap";
import { NavLink, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from "react-router-dom";

import Breadcrumb from "../../../components/common/breadcrumb";
import Members from "./Members/Members";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import RestrictedFeatures from "./RestrictedFeatures/RestrictedFeatures";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { DisappearedLoading } from "react-loadingg";

class AccessLevel extends Component {
	state = {
		//activeTab: "1",
		tabIndex: 0,
		addMemberModal: false,
		featureModal: false,
		adminUsers: null,
		restrictedFeatures: [],
		access_level: localStorage.getItem("accessLevel"),
	};

	componentDidMount() {
		this.getAllRestFeatures();
		this.getAllAdminUsers();
	}

	toggleAddMemberModal = () => {
		this.setState((prevState) => {
			return {
				addMemberModal: !prevState.addMemberModal,
			};
		});
	};

	toggleFeatureModal = () => {
		this.setState((prevState) => {
			return {
				featureModal: !prevState.featureModal,
			};
		});
	};

	getAllAdminUsers = () => {
		const postdata = {};
		client.post(api.get_all_adminusers, postdata, (error, response) => {
			if (!error) {
				if (!response.error) {
					console.log(response);
					this.setState({ adminUsers: response.result.data });
				} else {
					setTimeout(() => {
						toast.error(response.message);
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
		});
	};

	getAllRestFeatures = () => {
		const postdata = {};
		client.post(api.get_all_restFeature, postdata, (error, response) => {
			if (!error) {
				if (!response.error) {
					console.log(response);
					this.setState({ restrictedFeatures: response.result.data });
				} else {
					setTimeout(() => {
						toast.error(response.message);
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
		});
	};

	render() {
		const { restrictedFeatures, adminUsers, access_level } = this.state;
		console.log(this.state.tabIndex);
		//console.log(adminUsers);
		return (
			<Fragment>
				<div className="row">
					<div className="col-lg-7 col-sm-12" style={{ fontSize: "12px" }}>
						<Breadcrumb title={"Access level"} parent={"Settings"} />
					</div>
					<div className="col-lg-5 col-sm-12 ">
						<div
							className="btn-group pull-right"
							style={{ paddingTop: "8px", marginLeft: "0.4rem", marginRight: "0.6rem", marginBottom: "0rem" }}
							role="group"
							aria-label="Basic example"
						>
							{access_level != 1 && this.state.tabIndex == 0 && (
								<button
									className="btnAddMember custBtnAdmin"
									onClick={this.toggleAddMemberModal} 
								>
									<i className="fa fa-plus-square-o" style={{ margin: "0 7px 0 0", color: "#327ebc" }}></i>
									Add&nbsp;new&nbsp;member
								</button>
							)}
						</div>
						<div
							className="btn-group pull-right"
							style={{ paddingTop: "8px", marginLeft: "0.4rem", marginRight: "0.6rem", marginBottom: "0rem" }}
							role="group"
							aria-label="Basic example"
						>
							{access_level != 1 && this.state.tabIndex == 1 && (
								<button
									className="btnFeature custBtnAdmin"
									onClick={this.toggleFeatureModal}
								>
									<i className="fa fa-plus-square-o" style={{ margin: "0 7px 0 0", color: "#4466f2" }}></i>
									Add&nbsp;new&nbsp;feature
								</button>
							)}
						</div>
					</div>
				</div>
				{restrictedFeatures && adminUsers ? (
					<div className="container-fluid">
						<div className="row theme-tab">
							<Tabs
								style={{
									padding: "0",
									backgroundColor: "white",
									borderRadius: "3px",
									border: "1px solid rgb(223, 232, 241)",
									marginBottom: "0px",
								}}
								className="col-sm-12"
								defaultIndex={this.state.tabIndex}
								onSelect={(index) => this.setState({ tabIndex: index })}
							>
								<TabList className="tabs tab-title">
									<Tab style={{ display: "flex", alignItems: "center" }}>
										<i className="fa fa-users" style={{ fontSize: "0.8rem" }}></i>&nbsp;&nbsp;Members
									</Tab>
									<Tab style={{ display: "flex", alignItems: "center" }}>
										<i className="fa fa-list-ul" style={{ fontSize: "0.8rem" }}></i>&nbsp;&nbsp;List of features
									</Tab>
								</TabList>

								<div className="tab-content-cls">
									<TabPanel>
										<Members
											restrictedFeatures={restrictedFeatures}
											adminUsers={adminUsers}
											addMemberModal={this.state.addMemberModal}
											toggleAddMemberModal={this.toggleAddMemberModal}
										></Members>
									</TabPanel>

									<TabPanel>
										<RestrictedFeatures
											restrictedFeatures={restrictedFeatures}
											toggleFeatureModal={this.toggleFeatureModal}
											featureModal={this.state.featureModal}
										></RestrictedFeatures>
									</TabPanel>
								</div>
							</Tabs>
						</div>
					</div>
				) : (
					// <div className="container-fluid">
					//     <div className="row">
					//         <div className="col-sm-12" >
					//             <div className="card" >
					//                 <div className="card-body" style={{ padding: "0.5rem 0.8rem" }}>
					//                     <Nav tabs className="nav-pills nav-primary">
					//                         <NavItem className="nav nav-tabs" id="myTab" role="tablist">
					//                             <NavLinkTab className={this.state.activeTab == '1' ? 'active nav-link' : ''} onClick={() => { this.toggle('1') }} value='1'>
					//                             <i className="fa fa-users" style={{ fontSize: "1.2rem" }}></i> Members
					//                         </NavLinkTab>
					//                         </NavItem>
					//                         <NavItem className="nav nav-tabs" id="myTab" role="tablist">
					//                             <NavLinkTab className={this.state.activeTab == '2' ? 'active nav-link' : ''} onClick={() => { this.toggle('2') }} value='2'>
					//                             <i className="fa fa-list-ul" style={{ fontSize: "1.2rem" }}></i> List of features
					//                         </NavLinkTab>
					//                         </NavItem>

					//                     </Nav>

					//                     <TabContent activeTab={this.state.activeTab} style={{ marginTop: "15px" }} >
					//                         <TabPane tabId="1">
					//                             <Members restrictedFeatures={restrictedFeatures} adminUsers={adminUsers}></Members>

					//                     </TabPane>
					//                         <TabPane tabId="2">
					//                             <RestrictedFeatures restrictedFeatures={restrictedFeatures}></RestrictedFeatures>
					//                     </TabPane>
					//                     </TabContent>

					//                 </div>
					//             </div>
					//         </div>
					//     </div>
					// </div>
					<div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
						<div className="loader" style={{ display: "flex", alignItems: "center" }}>
							<DisappearedLoading size="medium" color="#514F4E" />
						</div>
					</div>
				)}
			</Fragment>
		);
	}
}

export default AccessLevel;
