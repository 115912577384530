import React, { useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import api from '../../../constant/apilist';
import client from '../../../Api/HTTPClient';
import { ToastContainer, toast } from "react-toastify";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Row, Col, FormGroup
} from "reactstrap";

const EditLocations = (props) => {
    let [loadingbtn, setloadingbtn] = useState(false);
    let [showerrorstatus, setshowerrorstatus] = useState(false);

    const { states, countryCode, countryId, locationsData, editLocation, setEditLocation } = props;

    const [suburb, setSuburb] = useState("")
    const [postCode, setPostCode] = useState("")
    const [state, setState] = useState("")
    const [latitude, setLatitude] = useState("")
    const [longitude, setLongitude] = useState("")


    useEffect(() => {
        if (!props.editLocation) return;

        setSuburb(props.editLocation?.suburb)
        setPostCode(props.editLocation?.post_code)
        setState(props.editLocation?.state)
        setLatitude(props.editLocation?.latitude)
        setLongitude(props.editLocation?.longitude)
    }, [props.editLocation])

    const user = JSON.parse(window.localStorage.getItem('user'))

    const { handleSubmit, register, errors, watch } = useForm();

    const onSubmit = (form_data) => {
        if (form_data !== '') {
            setloadingbtn(true)
            form_data.id = props.editLocation._id;
            form_data.modified_by = user.full_name;
            form_data.country_code= countryCode;
            form_data.fk_country_id= countryId

            client.post(api.editLocationsData, form_data, async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                props.toggle(1);
                                toast.success("Updated Successfully")
                            } else {
                                toast.error(response.message)
                            }
                        } catch (e) {
                            toast.error(
                                "Internal error occured. Please contact support"
                            );
                        }
                    } else {
                        toast.error(
                            "Internal error occured. Please contact support"
                        );
                    }
                } else {
                    toast.error(
                        "Internal error occured. Please contact support"
                    );
                }

                setloadingbtn(false);
            }
            )
        }
        else {
            setshowerrorstatus(true)
            errors.showMessages()
        }
    }


    return (
        <Modal
            isOpen={props.isOpen}
            toggle={props.toggle}
            className="modal-body"
            centered={true}
        >
            <form
                className="needs-validation"
                noValidate=""
                onSubmit={handleSubmit(onSubmit)}
            >
                <ModalHeader toggle={props.toggle}>
                    Edit Locations Data
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12}>
                            <FormGroup className="valid_form">
                                <Label for="suburb">Suburb</Label>
                                <input
                                    name="suburb"
                                    type='text'
                                    value={suburb}
                                    onChange={(e) => {
                                        setSuburb(e.target.value)
                                    }}
                                    className='form-control'
                                    placeholder="Suburb"
                                    ref={register({ required: true })}
                                />

                                <span style={{ color: "#ff5370" }}>
                                    {errors.suburb &&
                                        "Suburb name is required"}
                                </span>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup className="valid_form">
                                <Label for="postcode">Postcode</Label>
                                <input
                                    type='text'
                                    name="postcode"
                                    className='form-control'
                                    placeholder="Postcode"
                                    ref={register({
                                        required: true, pattern: {
                                            value: /^[0-9]*$/,
                                            message: 'Enter proper value'
                                        }
                                    })}
                                    value={postCode}
                                    onChange={(e) => {
                                        setPostCode(e.target.value)
                                    }}
                                />
                                <span style={{ color: "#ff5370" }}>
                                    {errors.postcode &&
                                        "Post code is required"}
                                </span>

                            </FormGroup>
                        </Col>

                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup className="valid_form">
                                <Label for="state">State</Label>

                                <select
                                    ref={register({ required: true })}
                                    style={{
                                        width: "100%",
                                        paddingBlock: "0.375rem",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                    }}
                                    name="state"
                                    value={state}
                                    onChange={(e) => {
                                        setState(e.target.value)
                                    }}
                                >
                                    <option value="" selected disabled>Select State</option>
                                    {states.map((item) => (
                                        <option
                                            value={item.name}
                                            style={{
                                                fontSize: "16px",
                                                lineHeight: "24px",
                                            }}
                                        >
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                                <span style={{ color: "#ff5370" }}>
                                    {errors.state && "select a value"}
                                </span>

                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup className="valid_form">
                                <Label for="latitude">Latitude</Label>
                                <input
                                    className='form-control'

                                    type="text" name="latitude" placeholder="latitude"
                                    ref={register({
                                        required: true, pattern: {
                                            value: /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/,
                                            message: "This value is Required"
                                        }
                                    })}
                                    value={latitude}
                                    onChange={(e) => {
                                        setLatitude(e.target.value)
                                    }}
                                />
                                <span style={{ color: "#ff5370" }}>
                                    {errors.latitude && "Enter a proper value"}
                                </span>

                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup className="valid_form">
                                <Label for="postcode">Longitude</Label>
                                <input

                                    className='form-control'
                                    type="text" name="longitude"
                                    placeholder="Longitude"
                                    ref={register({
                                        required: true, pattern: {
                                            value: /^\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
                                            message: 'Enter a proper value'
                                        }
                                    })}
                                    value={longitude}
                                    onChange={(e) => {
                                        setLongitude(e.target.value)
                                    }}
                                />
                                <span style={{ color: "#ff5370" }}>
                                    {errors.longitude && "Enter a proper value"}
                                </span>

                            </FormGroup>
                        </Col>

                    </Row>


                </ModalBody>
                <ModalFooter>
                    {!loadingbtn ? (
                        <>
                            <Button
                                color="default"
                                type="button"
                                onClick={props.toggle}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                type="submit"
                                onClick={() => setshowerrorstatus(true)}
                            >
                                Save
                            </Button>
                        </>
                    ) : (
                        <div
                            className="loader-box pull-right"
                            style={{ height: "auto" }}
                        >
                            <span
                                className="rotate dashed"
                                style={{ height: 20, width: 20 }}
                            ></span>
                        </div>
                    )}
                </ModalFooter>
            </form>
        </Modal>
    )
}

export default EditLocations;