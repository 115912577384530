import * as actionTypes from "./actionTypes";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import Swal from "sweetalert2";

export const revertToInitialState = () => {
    return {
        type: actionTypes.MERC_INITIALSTATE
    }
}
export const updateDetails_start = () => {
    return {
        type: actionTypes.UPDATEDETAILS_START
    }
}

export const updateDetails_success = (successMsg, merchantData) => {
    return {
        type: actionTypes.UPDATEDETAILS_SUCCESS,
        //payload: merchantData,
        successMsg: successMsg

    }
}

export const updateDetails_failed = (error) => {
    return {
        type: actionTypes.UPDATEDETAILS_FAILED,
        payload: error
    }
}

export const updateDetails = (merchantData) => {
    return dispatch => {
        dispatch(updateDetails_start());
        const postData = merchantData
        console.log(postData);
        client.put(api.update_merchant_details, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    //console.log(response);
                    let message;
                    if (merchantData.guest_check_in == true) {
                        message = 'Guest check-in option ENABLED, user will be able to use i-Pay to check-in into your store.'
                    } else if (merchantData.guest_check_in == false) {
                        message = 'Guest check-in option DISABLED, user will not be able to use i-Pay to check-in into your store.'
                    } else if (merchantData.accept_payment == true) {
                        message = 'Accept Payment option ENABLED, user will be able to use i-Pay to pay at your store.'
                    } else if (merchantData.accept_payment == false) {
                        message = 'Accept Payment option DISABLED, user will not be able to use i-Pay to pay at your store.'
                    } else if (merchantData.dine_in == true) {
                        message = 'Dine-in Menu option ENABLED, user will be able to view menu online.'
                    } else if (merchantData.dine_in == false) {
                        message = 'Dine-in Menu option DISABLED, user will not be able to view menu online.'
                    } else if (merchantData.temp_password) {
                        return 
                    } else {
                        message = 'Details updated.'
                    }
                    dispatch(updateDetails_success(response.message));
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: message,
                    })
                } else {
                    dispatch(updateDetails_failed(response.message));
                    Swal.fire({
                        icon: 'error',
                        title: 'Ooops',
                        text: response.message,
                    })
                }


            } else {
                dispatch(updateDetails_failed("Internal error occured.Please contact support."));
                Swal.fire({
                    icon: 'error',
                    title: 'Ooops',
                    text: "Internal error occured.Please contact support",
                })
            }

        });
    }
};