import React, { useState, useEffect } from "react";
import logo from "../assets/images/ipay-logo-40-40.png";
import man from "../assets/images/dashboard/user.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { withRouter } from "react-router";
import app from "../data/base";

import { Link } from 'react-router-dom';


const Signin = ({ history }) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));

  useEffect(() => {
    localStorage.setItem("profileURL", value);
  }, [value]);

  const loginAuth = async () => {}

/*
  const loginAuth = async () => {
    try {
      await app.auth().signInWithEmailAndPassword(email, password);
      setValue(man);
      history.push(`${process.env.PUBLIC_URL}/dashboard/default`);
    } catch (error) {
      setTimeout(() => {
        toast.error(
          "Oopss.. The password is invalid or the user does not have a password."
        );
      }, 200);
    }
  };
  */

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid p-0">
          {/* <!-- login page start--> */}
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center">
                      <img src={logo} alt="" />
                    </div>
                    <div className="card mt-4">
                      <div className="card-body">
                        <div className="text-center">
                          <h4>MERCHANT LOGIN</h4>
                          <h6>Enter your Email and Password </h6>
                        </div>
                        <form className="theme-form">
                          <div className="form-group">
                            <label className="col-form-label pt-0">
                              Your Email
                            </label>
                            <input
                              className="form-control"
                              type="email"
                              name="email"
                              value={email}
                              onChange={e => setEmail(e.target.value)}
                              placeholder=""
                            />
                            {/* {errors.email && 'Email is required'} */}
                          </div>
                          <div className="form-group">
                            <label className="col-form-label">Password</label>
                            <input
                              className="form-control"
                              type="password"
                              name="password"
                              value={password}
                              onChange={e => setPassword(e.target.value)}
                            />
                            {/* {errors.password && 'Email is required'} */}
                          </div>
                          <div className="checkbox p-0">
                            <input id="checkbox1" type="checkbox" />
                            <label htmlFor="checkbox1">Remember me</label>

                            
                          </div>
                          
                          <div className="form-group form-row mt-3 mb-0">
                            <button
                              className="btn btn-primary btn-block"
                              type="button"
                              onClick={() => loginAuth()}
                            >
                              Login
                            </button>
                          </div>

                          <div className="form-divider"></div>

                          <div className="form-row">
                            <div className="col-sm-8">
                                <div className="text-left mt-2 m-l-20">Are you new user?</div>
                            </div> 
                                <div className="col-sm-4">  
                                  <Link to={`/ `}><button className="btn btn-primary btn-block"> Signup </button></Link>
                                </div>
                            
                          </div>

                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
          {/* <!-- login page end--> */}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Signin);
