import React, { useState, Fragment, useEffect } from "react";
import TimePicker from "react-times";
import ImageUploader from "react-images-upload";
import {
	TabContent,
	TabPane,
	Nav,
	NavItem,
	Tooltip,
	NavLink as NavLinkTab,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	CardBody,
	CardHeader,
	Col,
	Row,
	Table,
	Card,
	Container,
	Button,
} from "reactstrap";
import Switch from "react-switch";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import defaultBannerImage from "../../assets/staticImages/book-security-guard.jpg";
import ipayLogo from "../../assets/staticImages/i-Pay.png";
import googlePayLogo from "../../assets/staticImages/google-pay.png";
import applePayLogo from "../../assets/staticImages/apple-pay.png";
import mastercardVisaLogo from "../../assets/staticImages/mastercard-visa.png";
import "./timepicker.css"

import { ArrowLeft, ChevronLeft } from "react-feather";

import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { fetch_restaurantStructure_failed } from "../../store/actions/reservationStructure";

const GuardLink = (props) => {
	const { merchant_info } = props;

	console.log("merchant info", props?.merchant_info);
	const [activeTab, setActiveTab] = useState("1");
	const [guardLink, setGuardLink] = useState(null);
	const [merchantInfo, setMerchantInfo] = useState(props.merchant_info);
	const [guardSetting, setGuardSetting] = useState(null);
	const [guardBookings, setGuardBookings] = useState(null);
	const [loading, setLoading] = useState(false);
	// const [from_time, setfrom_time] = useState();
	// const [to_time, setto_time] = useState();
	const [weeklySetting, setWeeklySetting] = useState([]);
	const [taxtype, setTaxType] = useState("1");
	const [taxrate, setTaxrate] = useState("");
	const [bannerText, setBannerText] = useState("");
	const [guardsAvailable, setGuardsAvailable] = useState("");
	const [image_data, setimage_data] = useState(null);
	const [customDate, setCustomDate] = useState(new Date());
	const [customdayLength, setCustomdayLength] = useState(0);
	const [customDateData, setCustomDateData] = useState([]);
	const [instructions, setInstructions] = useState("");
	const [textCopy, setTextCopy] = useState(false);
	const [defaultImageData, setDefaultImageData] = useState(null);
	const [googlePay, setGooglePay] = useState(true);
	const [applePay, setApplePay] = useState(true);
	const [ipay, setIpay] = useState(true);
	const [visa, setVisa] = useState(true);
	const [guardLinkbtn, setguardLinkbtn] = useState(false);

	const [bookingDetails, setBookingDetails] = useState([]);

	const [globalGuardSetting, setGlobalGuardSetting] = useState(null);
	const [bookingdetailsTab, setbookingdetailsTab] = useState(false);

	useEffect(() => {
		onFetchGuardSettings();
		onFetchGuardBookings();
		getGlobalGuardData();
		fetchPaymentOptions();
		getDataUri(defaultBannerImage, (dataUri) => {
			let imageData = dataUri.split(",")[1];
			let mimeType = dataUri.split(";")[0].split(":")[1];
			let image = { mime: mimeType, data: imageData };
			setDefaultImageData(image);
		});
	}, []);
	/*  console.log(merchantInfo);
     console.log(props.merchant_info); */

	useEffect(() => {
		onFetchGuardSettings();
		getGlobalGuardData();

		//onSendBookings()
		//onFetchGuardBookings();
	}, [merchantInfo]);

	useEffect(() => {
		setMerchantInfo(merchant_info);
		setguardLinkbtn(true);
	}, [merchant_info]);

	// // console.log(defaultImageData, "defaultImageData");
	// console.log(image_data, "imagedata");
	// console.log(merchant_info, "merchant_inf");
	// console.log(weeklySetting, "weeklysetting");
	// console.log(guardSetting, "guard");

	useEffect(() => {
		if (guardSetting) {
			try {
				// const start_time = guardSetting.start_time.split(":");
				// const end_time = guardSetting.end_time.split(":");
				// setfrom_time({ hour: start_time[0], minute: start_time[1], meridiem: null });
				// setto_time({ hour: end_time[0], minute: end_time[1], meridiem: null });
				setWeeklySetting(guardSetting.weekly_charges);
				setCustomDateData(guardSetting.customised_charges);
				setTaxType(guardSetting.tax_type);
				setTaxrate(guardSetting.tax_rate);
				setCustomdayLength(guardSetting.customised_charges.length);
				setBannerText(guardSetting.banner_text);
				setGuardsAvailable(guardSetting.guards_available);
				setInstructions(guardSetting.instructions);
				setApplePay(guardSetting.apple_pay);
				setIpay(guardSetting.ipay);
				setGooglePay(guardSetting.google_pay);
				setVisa(guardSetting.visa_mastercard);
				// alert("here")

			} catch (err) {
				// alert("error")
			}
		}
	}, [guardSetting]);

	const onTabChange = (tabIndex) => {
		setActiveTab(tabIndex);
	};

	useEffect(() => {
		if (textCopy) {
			setTimeout(() => {
				setTextCopy(false);
			}, 2000);
		}
	}, [textCopy]);

	const onFetchGuardBookings = () => {
		const postData = { merchant_id: merchantInfo._id };
		setLoading(true);
		client.post(api.merc_fetchGuardBooking, postData, (error, response) => {
			//console.log(error);
			console.log(response, "response onfetchGuardBook");
			if (!error) {
				if (!response.error) {
					setGuardBookings(response.result.data);
					setLoading(false);
				} else {
					setLoading(false);
				}
			} else {
				setLoading(false);
			}
		});
	};

	const fetchPaymentOptions = () => {
		client.get("/backendapi/paymentOptions/getAllPayment", {}, (error, response) => {
			//console.log(error);
			console.log(response, "response fetch");

			if (!error) {
				if (!response.error) {
					console.log(response);
					setLoading(false);
				} else {
					setLoading(false);
					console.log(response);
				}
			} else {
				setLoading(false);
				console.log(error);
			}
		});
	};

	const onSendBookings = () => {
		console.log("unique_id", merchantInfo.unique_id);

		const newGuardBookingDetails = {
			merc_unique_id: merchantInfo.unique_id,
			order_id: "GUARD-479652",
			cust_ipay_id: "testsdjsds@i-Pay",
			transaction_id: "249303303328",
			booking_type: 1,
			name: "neha challa",
			position: "",
			email: "neha.speedfood@gmail.com",
			mobile: "444444444",
			trading_name: "",
			abn: "",
			postal_address: "",
			postcode: "",
			suburb: "",
			state: "",
			telephone: "",
			fax: "",
			gst_amount: 1.0,
			final_amount: 17.09,
			bookingData: [
				{
					location: "Dadar, Mumbai",
					address: "311a",
					job_details: "test",
					sub_total: 20,
					reserved_date: [
						{ booking_date: "04-11-2021", start_time: "01:00", end_time: "10:30", no_of_guards: 2, duration: 0, rate: 20 },
					],
				},
				{
					location: "Mumbai",
					address: "Mumbai",
					job_details: "test",
					sub_total: 20,
					reserved_date: [
						{ booking_date: "04-11-2021", start_time: "01:00", end_time: "10:30", no_of_guards: 2, duration: 0, rate: 20 },
					],
				},
			],
		};

		console.log(typeof newGuardBookingDetails.bookingData);

		setLoading(true);

		client.post(api.add_GuardBooking, newGuardBookingDetails, (error, response) => {
			console.log(response, "response addGuardBook");
			console.log(error);
			if (!error) {
				if (!response.error) {
					//setGuardSetting(response.result.data);
					// console.log("add bookings ", response);
					// console.log("merchantInfo merc_unique_id", merchantInfo.merc_unique_id);
					onFetchGuardBookings();
					setLoading(true);
				} else {
					console.log("add bookings ", response);
					setLoading(true);
				}
			} else {
				console.log("add bookings ", error);
				setLoading(true);
			}
		});
	};

	const onFetchGuardSettings = () => {
		const postData = { merchant_id: merchantInfo._id };
		setLoading(true);
		client.post(api.merchant_fetchGuardSetting, postData, (error, response) => {
			console.log(response, "response onfetchGuardSettings");
			if (!error) {
				if (!response.error) {
					setGuardSetting(response.result.data);
					console.log("onFetchGuardSettings", response);
					setguardLinkbtn(false);
					setLoading(false);
				} else {
					setLoading(false);
					setguardLinkbtn(false);
				}
			} else {
				setLoading(false);
				setguardLinkbtn(false);
			}
		});
	};

	const getGlobalGuardData = () => {
		const postData = { merchant_id: merchantInfo._id };
		client.get(api.getDefaultPaymentOption, {}, (error, response) => {
			console.log(response, "response onGlobalGuard");
			if (!error) {
				if (!response.error) {
					// console.log("onFetchGlobalGuardSettings", response);
					let getdata = response?.data;
					if (!ipay && !googlePay && !applePay && !visa) {
						if (getdata.ipay) {
							setIpay(true);
						}
						if (getdata.google_pay) {
							setGooglePay(true);
						}
						if (getdata.apple_pay) {
							setApplePay(true);
						}
						if (getdata.visa_mastercard) {
							setVisa(true);
						}
						onSubmitGuardSetting();
						setLoading(false);
					}
				} else {
					setLoading(false);
				}
			} else {
				setLoading(false);
			}
		});
	};

	const getDataUri = (url, callback) => {
		var image = new Image();

		image.onload = function () {
			var canvas = document.createElement("canvas");
			canvas.width = this.naturalWidth; // or 'width' if you want a special/scaled size
			canvas.height = this.naturalHeight; // or 'height' if you want a special/scaled size

			canvas.getContext("2d").drawImage(this, 0, 0);

			// Get raw image data
			callback(canvas.toDataURL("image/png").replace(/^data:image\/(png|jpg);base64,/, ""));

			// ... or get as Data URI
			callback(canvas.toDataURL("image/png"));
		};

		image.src = url;
	};

	const onGenerateGuardLink = async () => {
		const guardLink = `https://ipaypro.co/guards/biz/${merchantInfo.unique_id}`;
		setGuardLink(guardLink);
		const postData = {
			merchant_id: merchantInfo._id,
			guard_booking: true,
			guardLink: guardLink,
			image_data: defaultImageData,
		};
		
		client.put(api.update_merchant_details, postData, (error, response) => {
			if (!error) {
				if (!response.error) {
					toast.success("Guard link generated");
					console.log("onGenerateGuardLink", response);
					onFetchGuardSettings();
					props.getMerchantWalletInfo(merchantInfo._id);
				} else {
					setTimeout(() => {
						toast.error(response.message);
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured.Please contact support");
				}, 200);
			}
		});
	};

	const onDrop = (pictureFiles, pictureDataURLs) => {
		// setpictureso(pictureFiles);
		document.querySelector("#banner-image").style.display = "none";
		try {
			var mimeType = pictureFiles[0].type;
			// Image upload
			var reader = new FileReader();
			reader.readAsDataURL(pictureFiles[0]);
			reader.onload = (_event) => {
				let imageData;
				imageData = reader.result;
				imageData = imageData.split(",")[1];
				let image_data_temp = { mime: mimeType, data: imageData };
				setimage_data(image_data_temp);
			};
		} catch (err) {
			// console.log(pictureFiles);
			setimage_data(defaultImageData);
		}
	};

	const onBookingSettingChange = (event) => {
		const { name, value } = event.target;
		console.log(name, value);
		if (name == "banner_text") {
			setGuardSetting((prevState) => {
				return {
					...prevState,
					[name]: value,
				};
			});
		} else {
			setGuardSetting((prevState) => {
				return {
					...prevState,
					[name]: +value,
				};
			});
		}
	};

	const onCustomDataChange_time = (name, option, index) => {
		// const { name, value } = event.target;
		let value = `${option.hour}:${option.minute}`;

		const updatedSetting = [...customDateData];
		const daySetting = updatedSetting[index];
		const updatedDaySetting = {
			...daySetting,
			[name]: value,
		};
		console.log(updatedDaySetting, "updatedDaySetting")
		updatedSetting[index] = updatedDaySetting;
		setCustomDateData(updatedSetting);
	};

	function calculateTime(from, to) {
		if (from && to) {
			let hours = parseInt(to.split(':')[0], 10) - parseInt(from.split(':')[0], 10);
			return hours;
		} else {
			return 0;
		}

        //  $(".Hours").val(hours);
     }

	const onweeklyDataChange_time = (name, option, index) => {

		// console.log(name, "name")
		// console.log(option, "option")
		let value = `${option.hour}:${option.minute}`;
		// const { name, value } = event.target;
		const updatedWeeklySetting = [...weeklySetting];
		const daySetting = updatedWeeklySetting[index];
		const updatedDaySetting = {
			...daySetting,
			[name]: value,
			day:
				index == 0
					? 1
					: index == 1
					? 2
					: index == 2
					? 3
					: index == 3
					? 4
					: index == 4
					? 5
					: index == 5
					? 6
					: index == 6
					? 7
					: null,
		};
		updatedWeeklySetting[index] = updatedDaySetting;
		setWeeklySetting(updatedWeeklySetting);
		// console.log(weeklySetting, "runeddd");
	}

	const onweeklyDataChange = (event, index) => {
		const { name, value } = event.target;
		const updatedWeeklySetting = [...weeklySetting];
		const daySetting = updatedWeeklySetting[index];
		const updatedDaySetting = {
			...daySetting,
			[name]: +value,
			day:
				index == 0
					? 1
					: index == 1
					? 2
					: index == 2
					? 3
					: index == 3
					? 4
					: index == 4
					? 5
					: index == 5
					? 6
					: index == 6
					? 7
					: null,
		};
		updatedWeeklySetting[index] = updatedDaySetting;
		setWeeklySetting(updatedWeeklySetting);
		console.log(weeklySetting, "runeddd");
	};

	const onSubmitGuardSetting = (event) => {
		if (event) event.preventDefault();
		//console.log("banner Text", bannerText)
		const customisedDayCharges = customDateData.map((el, index) => {
			return {
				...el,
				date: moment(el.date).toISOString(),
			};
		});

		const postData = {
			merchant_id: merchantInfo._id,
			guardSetting: {
				...guardSetting,
				start_time: "00:00",
				end_time: "00:00",
				weekly_charges: weeklySetting,
				customised_charges: customisedDayCharges,
				guards_available: +guardsAvailable,
				banner_text: bannerText,
				tax_rate: +taxrate,
				tax_type: +taxtype,
				instructions: instructions,
				google_pay: googlePay,
				apple_pay: applePay,
				ipay: ipay,
				visa_mastercard: visa,
			},
			image_data: image_data,
		};
		console.log("postData:", postData);
		setLoading(true);
		client.post(api.merchant_updateGuardSetting, postData, (error, response) => {
			console.log(response, "response updateGuardSetting");

			if (!error) {
				if (!response.error) {
					console.log("onSubmitGuardSetting", response);
					if (event) toast.success("Guard settings changed");
					setLoading(false);
					onFetchGuardSettings();
				} else {
					setLoading(false);
					console.log(response);
				}
			} else {
				setLoading(false);
				console.log(error);
			}
		});
	};

	const onChangeCustomDate = (date, index) => {
		const updatedSetting = [...customDateData];
		const daySetting = updatedSetting[index];
		const updatedDaySetting = {
			...daySetting,
			date: date,
		};
		updatedSetting[index] = updatedDaySetting;
		setCustomDateData(updatedSetting);

		//console.log(customDateData);
	};

	const updateGuardBooing = (bookingID, paymentStatus) => {
		const postData = {
			bookingId: bookingID,
			paymentStatus: paymentStatus,
		};

		console.log(postData);

		client.post(api.update_GuardBooking, postData, (error, response) => {
			console.log(response, "updateGuard Booking");
			if (!error) {
				if (!response.error) {
					console.log(response);
					onFetchGuardBookings();
				} else {
					console.log(response.error);
				}
			}
		});
	};

	const onCustomDataChange = (event, index) => {
		const { name, value } = event.target;
		const updatedSetting = [...customDateData];
		const daySetting = updatedSetting[index];
		const updatedDaySetting = {
			...daySetting,
			[name]: +value,
		};
		updatedSetting[index] = updatedDaySetting;
		setCustomDateData(updatedSetting);
		setBannerText(bannerText);
	};

	const onRemoveCustomDate = (index) => {
		const updatedCustomDateData = customDateData.filter((el, i) => {
			return i != index;
		});
		// console.log(updatedCustomDateData);
		setCustomDateData(updatedCustomDateData);
		setCustomdayLength(customdayLength - 1);
	};

	const getTotalNoGuards = (arr) => {
		//console.log("arr", arr)
		let s = 0;
		arr.map((v, i) => v.reserved_date.map((r, i) => (s += r.no_of_guards)));
		return s;
	};

	const setpaymentStatus = (status) => {
		console.log(status);
		let _status = "";
		if (status == 0) {
			_status = "Pending";
		}
		if (status == 1) {
			_status = "Confirmed";
		}
		if (status == 2) {
			_status = "Rejected";
		}
		if (status == 3) {
			_status = "Processed";
		}
		return _status;
	};

	const renderRequestListTable = (guardBookings) => {
		return (
			guardBookings &&
			guardBookings &&
			guardBookings?.map((guard) => {
				//getTotalNoGuards(guard.booking_details)
				//console.log("guard",guard)
				return (
					<tr key={`booking-${count}`}>
						<td style={{ fontSize: "0.75rem" }}>{count}</td>
						<td style={{ fontSize: "0.75rem" }}>{moment(guard.createdAt).format("DD/MM/YYYY h:mm:ss a")}</td>
						<td style={{ fontSize: "0.75rem" }}>{guard.contact_details.name.split(" ")[0]}</td>
						<td style={{ fontSize: "0.75rem" }}>{guard.contact_details.name.split(" ")[1]}</td>
						<td style={{ fontSize: "0.75rem" }}>{guard.contact_details.email}</td>
						<td style={{ fontSize: "0.75rem" }}>{guard.contact_details.mobile}</td>
						<td style={{ fontSize: "0.75rem" }}>{getTotalNoGuards(guard.booking_details)}</td>
						<td style={{ fontSize: "0.75rem" }}>{guard.final_amount}</td>
						<td style={{ fontSize: "0.75rem" }}>
							<select
								className="form-control "
								name="paymentStatus"
								onChange={(e) => updateGuardBooing(guard._id, e.target.value)}
								style={{
									padding: 0,
									height: "25px",
									width: "85px",
									marginTop: "5px",
									marginBottom: "5px",
									fontSize: "0.75rem",
								}}
							>
								<option selected={guard.payment_status == 0} value="0">
									Pending
								</option>
								<option selected={guard.payment_status == 1} value="1">
									Confirmed
								</option>
								<option selected={guard.payment_status == 2} value="2">
									Rejected
								</option>
								<option selected={guard.payment_status == 3} value="3">
									Processed
								</option>
							</select>
						</td>
						<td style={{ fontSize: "0.75rem" }}>
							<button
								onClick={() => {
									setBookingDetails({ ...guard });
									setbookingdetailsTab(true);
								}}
								class="btn btn-primary btn-xs"
							>
								View{" "}
							</button>{" "}
						</td>
						<td style={{ fontSize: "0.75rem" }} hidden>
							{count++}
						</td>
					</tr>
				);
			})
		);
	};

	const countSubTotal = (arr) => {
		//console.log("arr", arr.booking_details)
		let s = 0;
		arr.booking_details.map((v, i) => (s += v.sub_total));
		return s;
	};

	// const get_time = (time) => {

	// 	let time_array = time.split(":");
	// 	console.log({ hour: time_array[0], minute: time_array[1], meridiem: null })
	// 	return { hour: time_array[0], minute: time_array[1], meridiem: null }

		
	// }

	const renderBookingDetails = (bookingDetails) => {
		//console.log("renderBooking UI", bookingDetails)

		return (
			bookingDetails &&
			bookingDetails && (
				<Container>
					{" "}
					<Card style={{ marginBottom: "10px" }}>
						<CardHeader style={{ paddingLeft: "20px" }}>
							<div>
								<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
									<ChevronLeft style={{ width: "20px", color: "#561AFF" }} onClick={() => setbookingdetailsTab(false)} />
									<span style={{ marginTop: "8px", marginLeft: "15px" }} className={"h6"}>
										Booking Detail
									</span>
								</div>
							</div>
						</CardHeader>
						<CardBody style={{ padding: "10px" }}>
							<Container>
								<Row style={{ marginBottom: "5px" }}>
									<Col xs={2}> First Name </Col>
									<Col xs={8}>{bookingDetails?.contact_details?.name.split(" ")[0]}</Col>
								</Row>
								<Row style={{ marginBottom: "5px" }}>
									<Col xs={2}> Last Name </Col>
									<Col xs={8}>{bookingDetails?.contact_details?.name.split(" ")[1]}</Col>
								</Row>
								<Row style={{ marginBottom: "5px" }}>
									<Col xs={2}> Email ID </Col>
									<Col xs={8}>{bookingDetails?.contact_details?.email}</Col>
								</Row>
								<Row style={{ marginBottom: "5px" }}>
									<Col xs={2}> Number </Col>
									<Col xs={8}>{bookingDetails?.contact_details?.mobile}</Col>
								</Row>
								<Row style={{ marginBottom: "5px" }}>
									<Col xs={2}> No. of Guards </Col>
									<Col xs={8}>
										{bookingDetails?.booking_details ? getTotalNoGuards(bookingDetails?.booking_details) : null}
									</Col>
								</Row>
							</Container>
						</CardBody>
					</Card>
					<Card>
						<CardBody style={{ padding: "10px" }}>
							{bookingDetails &&
								bookingDetails &&
								bookingDetails?.booking_details?.map((booking, index) => {
									return (
										<div key={`_booking${index}`} style={{ marginTop: "20px", marginBottom: "10px" }}>
											<Container>
												<div>
													{" "}
													<span className={"h6"}>Location #{index + 1}</span> <span>{booking.location}</span>
												</div>
												<hr style={{ marginTop: "0.50rem", marginBottom: "0.50rem" }} />
												<Table striped hover responsive size={"lg"} style={{ marginTop: "10px" }}>
													<thead>
														<tr>
															<th style={{ fontSize: "1rem" }}>#</th>
															<th style={{ fontSize: "1rem" }}>Booking Date</th>
															<th style={{ fontSize: "1rem" }}>Start Time</th>
															<th style={{ fontSize: "1rem" }}>End Time</th>
															<th style={{ fontSize: "1rem" }}>No. of Guards</th>
															<th style={{ fontSize: "1rem" }}>Duration</th>
															<th style={{ fontSize: "1rem" }}>Rate</th>
														</tr>
													</thead>
													<tbody style={{ marginTop: "10px" }}>
														{booking?.reserved_date?.map((reservation, index) => {
															return (
																<tr key={`booking${index}`} style={{ borderTop: "1px solid rgba(0, 0, 0, 0.1)" }}>
																	<td style={{ fontSize: "1rem" }}> {index + 1}</td>
																	<td style={{ fontSize: "1rem" }}>
																		{moment(reservation?.booking_date).format("DD/MM/YYYY h:mm:ss")}
																	</td>
																	<td style={{ fontSize: "1rem" }}>{reservation?.start_time}</td>
																	<td style={{ fontSize: "1rem" }}>{reservation?.end_time}</td>
																	<td style={{ fontSize: "1rem" }}>{reservation?.no_of_guards}</td>
																	<td style={{ fontSize: "1rem" }}>{reservation?.duration}</td>
																	<td style={{ fontSize: "1rem" }}>${reservation?.rate}</td>
																</tr>
															);
														})}
													</tbody>
												</Table>
											</Container>
										</div>
									);
								})}
							{bookingDetails && bookingDetails && bookingDetails?.booking_details && (
								<Container style={{ padding: "0.5vw 4vw", fontSize: "1rem" }}>
									<Row style={{ textAlign: "right", borderTop: "1px solid rgba(0, 0, 0, 0.1)" }}>
										<Col xs={10}>GST</Col>
										<Col xs={2}>${bookingDetails.gst_amount}</Col>
									</Row>
									<Row style={{ textAlign: "right", borderTop: "1px solid rgba(0, 0, 0, 0.1)" }}>
										<Col xs={10}>Sub Total</Col>
										<Col xs={2}>${countSubTotal(bookingDetails)}</Col>
									</Row>
									<Row style={{ textAlign: "right", borderTop: "1px solid rgba(0, 0, 0, 0.1)" }}>
										<Col xs={10}>Online Fee </Col>
										<Col xs={2}>${2.66}</Col>
									</Row>
									<Row style={{ textAlign: "right", borderTop: "1px solid rgba(0, 0, 0, 0.1)" }}>
										<Col xs={10}>Service Fee </Col>
										<Col xs={2}>${2.7}</Col>
									</Row>
									<Row style={{ textAlign: "right", borderTop: "1px solid rgba(0, 0, 0, 0.1)" }}>
										<Col xs={10}>
											<b>Total</b>{" "}
										</Col>
										<Col xs={2}>
											<b> ${bookingDetails.final_amount}</b>
										</Col>
									</Row>
								</Container>
							)}
						</CardBody>
					</Card>
				</Container>
			)
		);
	};

	//console.log(bookingDetails)

	// console.log(guardSetting);
	//console.log(guardBookings);
	// console.log(from_time);
	// console.log(to_time);
	//console.log(weeklySetting);
	console.log(weeklySetting, "weeklySetting");
	// console.log(taxtype);
	// console.log(taxrate);
	//console.log(instructions);

	let count = 1;

	return (
		<Fragment>
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12 col-xl-12 xl-100">
						<div className="card">
							<div className="card-body" style={{ padding: "1rem" }}>
								<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
									<Nav tabs className="nav-pills nav-light mb-1">
										<NavItem className="nav nav-tabs" id="myTab" role="tablist">
											<NavLinkTab
												className={activeTab == "1" ? "active nav-link" : ""}
												onClick={() => onTabChange("1")}
												value="1"
											>
												Link Settings
											</NavLinkTab>
										</NavItem>

										<NavItem className="nav nav-tabs" id="myTab" role="tablist">
											<NavLinkTab
												className={activeTab == "2" ? "active nav-link" : ""}
												onClick={() => onTabChange("2")}
												value="2"
											>
												Reservation List
											</NavLinkTab>
										</NavItem>
									</Nav>
								</div>

								<TabContent activeTab={activeTab}>
									<TabPane tabId="1">
										<form onSubmit={onSubmitGuardSetting}>
											<div className="row">
												<div className="col col-12 mb-2">
													<p className="mb-1" style={{ fontWeight: "bold" }}>
														Guard Link:
													</p>
													{!props.merchant_info.guard_booking || !guardSetting ? (
														<button
															hidden={guardLinkbtn}
															className="btn btn-outline-primary"
															onClick={onGenerateGuardLink}
															type="button"
														>
															Generate Guard link
														</button>
													) : null}
													{guardSetting && (
														<div>
															<p
																style={{
																	padding: "0.3rem 1rem",
																	border: "1px solid #f2f4f7",
																	width: "70%",
																	marginBottom: "0",
																	display: "flex",
																	alignItems: "center",
																	justifyContent: "space-between",
																}}
															>
																{`https://ipaypro.co/guards/biz/${
																	merchantInfo.unique_id
																}`}
																<CopyToClipboard
																	text={`https://ipaypro.co/guards/biz/${
																		merchantInfo.unique_id
																	}`}
																	onCopy={() => setTextCopy(true)}
																>
																	<i
																		id="link-copy"
																		className="icofont icofont-copy-alt"
																		style={{ fontSize: "1rem", marginLeft: "0.3rem", cursor: "pointer" }}
																	></i>
																</CopyToClipboard>
															</p>
															<p style={{ color: "red" }}>
																Copy paste above link & share it with your customers to book security guard.
															</p>
															<Tooltip placement="bottom" target="link-copy" isOpen={textCopy}>
																Copied!
															</Tooltip>
														</div>
													)}
													{/* <Switch
                                        //onChange={props.onEnableGuestCheckIn}
                                        checked={true}
                                        className="react-switch"
                                        height={20}
                                        width={40}
                                    /> */}
												</div>
												{guardSetting && (
													<Fragment>
														<div className="form-group col col-lg-6">
															<label htmlFor="validationCustom01">Upload Banner image:</label>

															{/* <span>{offer_title}</span> */}
															<ImageUploader
																style={{ width: "50%" }}
																withIcon={false}
																withPreview={true}
																label=""
																singleImage={true}
																onChange={onDrop}
																buttonText="Upload"
																imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".svg"]}
																maxFileSize={1048576}
																fileSizeError=" file size is too big"
																name="banner_image"
															/>
															<div
																id="banner-image"
																style={
																	guardSetting.banner_image == null || guardSetting.banner_image == ""
																		? {
																				backgroundImage: `url(${client.url("static-images/default.png")})`,
																				height: "100px",
																				width: "250px",
																				backgroundRepeat: "no-repeat",
																				backgroundSize: "contain",
																				backgroundPosition: "center",
																		  }
																		: {
																				backgroundImage: `url(${client.url(
																					`images/guard-settings/${merchant_info._id}` +
																						"." +
																						guardSetting.banner_image.split(".")[1]
																				)})`,
																				height: "100px",
																				width: "250px",
																				backgroundRepeat: "no-repeat",
																				backgroundSize: "contain",
																				backgroundPosition: "center",
																		  }
																}
															></div>
														</div>
														<div className="col form-group col-lg-6">
															<label>Edit Banner text:</label>
															<input
																className="form-control"
																name="banner_text"
																value={bannerText}
																type="text"
																placeholder="Banner text"
																onChange={(event) => setBannerText(event.target.value)}
															/>
														</div>
													</Fragment>
												)}
											</div>
											{guardSetting && (
												<div>
													<h5>Booking settings</h5>
													<div className="row">
														{/* <div className="col form-group col-lg-4">
															<label>Start time:</label>
															<TimePicker/>
														</div> */}
														{/* <div className="col form-group col-lg-4">
															<label>End time:</label>
															<TimePicker
																//timeMode="12"
																onTimeChange={(option) => setto_time(option)}
																timezone="Australia/Sydney"
																theme="classic"
																time={to_time ? `${to_time.hour}:${to_time.minute}` : null}
															/>
														</div> */}
														<div className="col form-group col-lg-4">
															<label>Total number of guards available:</label>
															<input
																className="form-control"
																type="number"
																name="guards_available"
																placeholder="Guards available"
																onChange={(event) => setGuardsAvailable(event.target.value)}
																required
																value={guardsAvailable}
															/>
														</div>
														<div className="form-group col col-lg-4">
															<label className="form-label">Select tax:</label>
															<select
																className="form-control"
																name="tax_type"
																required
																value={taxtype}
																onChange={(event) => setTaxType(event.target.value)}
															>
																<option value="1">Include GST</option>
																<option value="2">Exclude GST </option>
																<option value="3">No GST </option>
															</select>
														</div>
														{taxtype == 1 && (
															<div className="form-group col col-lg-4">
																<label className="form-label">Tax rate:</label>
																<input
																	className="form-control"
																	type="number"
																	required
																	onChange={(event) => setTaxrate(event.target.value)}
																	value={taxrate}
																	required
																	name="tax_rate"
																/>
															</div>
														)}
														<div className="col form-group col-lg-12">
															<label style={{ fontWeight: "bold" }}>Set charges for normal working day</label>
															<div className="row">
																<div className="table-responsive"  style={{overflowX : "unset"}}>
																	<table className="table table-border-horizontal table-striped table-light">
																		<thead>
																			<tr>
																				<th scope="col">#</th>
																				<th scope="col">Day</th>
																				<th scope="col">Start Time</th>
																				<th scope="col">End Time</th>
																				<th scope="col">Rate per Guard/15mins(For notice below 6hrs)</th>
																				<th scope="col">Rate per Guard/15mins(For notice above 6hrs)</th>
																				<th scope="col">Minimum hrs per booking</th>
																			</tr>
																		</thead>
																		<tbody>
																			{weeklySetting 
																				? weeklySetting.map((item, index) =>
																				
																				(
																						<tr key={index}>
																							<th scope="row">{index + 1}</th>
																							<td>
																								{item.day == 1
																									? "Sunday"
																									: item.day == 2
																									? "Monday"
																									: item.day == 3
																									? "Tuesday"
																									: item.day == 4
																									? "Wednesday"
																									: item.day == 5
																									? "Thursday"
																									: item.day == 6
																									? "Friday"
																									: item.day == 7
																									? "Saturday"
																									: null}
																						</td>
																						<td style={{ width: "20%" }}>
																							<div className="col form-group col-lg-12">
																								<TimePicker
																									withoutIcon
																									onTimeChange={(option) => onweeklyDataChange_time("start_time", option, index)}
																									timezone="Australia/Sydney"
																									theme="classic"
																									
																								time={item.start_time ? item.start_time : "00:00"}
																								/>
																								</div>
																						</td>
																						<td style={{width : "20%"}}>
																							<div className="col form-group col-lg-12">
																								<TimePicker
																								withoutIcon
																								onTimeChange={(option) => onweeklyDataChange_time("end_time", option, index)}
																								timezone="Australia/Sydney"
																								theme="classic"
																								time={item.end_time ? item.end_time : "00:00"}
																							/>
																								</div>
																						</td>

																						
																							<td>
																								<input
																									className="form-control"
																									type="number"
																									onChange={(event) => onweeklyDataChange(event, index)}
																									required
																									value={weeklySetting[index].rate}
																									name="rate"
																									min="1"
																								/>
																							</td>
																							<td>
																								<input
																									className="form-control"
																									type="number"
																									onChange={(event) => onweeklyDataChange(event, index)}
																									required
																									value={weeklySetting[index].exceed_rate}
																									// value={calculateTime(item.start_time, item.end_time)}
																									name="exceed_rate"
																									min="1"
																								/>
																							</td>
																							<td>
																								<input
																									className="form-control"
																									type="number"
																									onChange={(event) => onweeklyDataChange(event, index)}
																									required
																									value={weeklySetting[index].minimum_hrs}
																									name="minimum_hrs"
																								min="1"
																									max={calculateTime(item.start_time, item.end_time)}
																								
																								/>
																							</td>
																						</tr>
																				  ))
																				: [...Array(7)].map((item, index) => (
																						<tr key={index}>
																							<th scope="row">{index + 1}</th>
																							<td>
																								{index == 0
																									? "Sunday"
																									: index == 1
																									? "Monday"
																									: index == 2
																									? "Tuesday"
																									: index == 3
																									? "Wednesday"
																									: index == 4
																									? "Thursday"
																									: index == 5
																									? "Friday"
																									: index == 6
																									? "Saturday"
																									: null}
																							</td>
																							<td>
																								<input
																									className="form-control"
																									type="number"
																									name="rate"
																									onChange={(event) => onweeklyDataChange(event, index)}
																									required
																									min="1"
																								/>
																							</td>
																							<td>
																								<input
																									className="form-control"
																									type="number"
																									name="exceed_rate"
																									onChange={(event) => onweeklyDataChange(event, index)}
																									required
																									min="1"
																								/>
																							</td>
																							<td>
																								<input
																									className="form-control"
																									type="number"
																									name="minimum_hrs"
																									onChange={(event) => onweeklyDataChange(event, index)}
																									required
																									min="1"
																								/>
																							</td>
																						</tr>
																				  ))}
																		</tbody>
																	</table>
																</div>
																<div className="col col-sm-12">
																	<button
																		className="btn btn-outline-primary mt-4 mb-4 btn-xs ml-3"
																		type="button"
																		onClick={() => {

																			let a = customDateData;
																			a.push({date: moment().toDate(), end_time: null, exceed_rate : 0, minimum_hrs : 1, rate : 0, start_time : null})
																			setCustomDateData(a);
																			setCustomdayLength(customdayLength + 1);

																		}}
																	>
																		Add Rates for Public holidays
																	</button>

																	{customdayLength > 0 && (
																		<div className="table-responsive" style={{overflowX : "unset"}}>
																			<table className="table table-border-horizontal table-striped table-light">
																				<thead>
																					<tr>
																						<th scope="col">#</th>
																						<th scope="col">Date</th>
																						<th scope="col">Start Time</th>
																						<th scope="col">End Time</th>
																						<th scope="col">Rate per Guard/15mins(For notice below 6hrs)</th>
																						<th scope="col">Rate per Guard/15mins(For notice above 6hrs)</th>
																						<th scope="col">Minimum hrs per booking</th>
																						<th scope="col"></th>
																					</tr>
																				</thead>
																				<tbody>
																					{customDateData.length > 0
																						? customDateData.map((item, index) => (
																							<tr key={index}>
																								<th scope="row">{index + 1}</th>
																								<td>
																									<DatePicker
																										selected={
																											typeof customDateData[index] == "undefined" ||
																												typeof customDateData[index].date == "undefined"
																												? new Date()
																												: new Date(customDateData[index].date)
																										}
																										minDate={moment().toDate()}
																										onChange={(date) => onChangeCustomDate(date, index)}
																									/>
																								</td>
																								<td style={{ width: "20%" }}>
																									<div className="col form-group col-lg-12">
																										<TimePicker
																											withoutIcon
																											onTimeChange={(option) => onCustomDataChange_time("start_time", option, index)}
																											timezone="Australia/Sydney"
																											theme="classic"
																											time={item?.start_time ? item?.start_time : null}
																										/>
																									</div>
																								</td>
																								<td style={{ width: "20%" }}>
																									<div className="col form-group col-lg-12">
																										<TimePicker
																											withoutIcon
																											onTimeChange={(option) => onCustomDataChange_time("end_time", option, index)}
																											timezone="Australia/Sydney"
																											theme="classic"
																											time={item?.end_time ? item?.end_time : null}
																										/>
																									</div>
																								</td>
																								<td>
																									<input
																										className="form-control"
																										type="number"
																										onChange={(event) => onCustomDataChange(event, index)}
																										required
																										value={
																											typeof customDateData[index] == "undefined" ||
																												typeof customDateData[index].rate == "undefined"
																												? null
																												: customDateData[index].rate
																										}
																										name="rate"
																										min="1"
																									/>
																								</td>
																								<td>
																									<input
																										className="form-control"
																										type="number"
																										onChange={(event) => onCustomDataChange(event, index)}
																										required
																										value={
																											typeof customDateData[index] == "undefined" ||
																												typeof customDateData[index].exceed_rate == "undefined"
																												? null
																												: customDateData[index].exceed_rate
																										}
																										name="exceed_rate"
																										min="1"
																									/>
																								</td>
																								<td>
																									<input
																										className="form-control"
																										type="number"
																										onChange={(event) => onCustomDataChange(event, index)}
																										required
																										value={
																											typeof customDateData[index] == "undefined" ||
																												typeof customDateData[index].minimum_hrs == "undefined"
																												? null
																												: customDateData[index].minimum_hrs
																										}
																										name="minimum_hrs"
																										min="1"
																									/>
																								</td>
																								<td>
																									<i
																										className="icofont icofont-close"
																										onClick={() => onRemoveCustomDate(index)}
																									></i>
																								</td>
																							</tr>
																						))
																						: null
																					}
																				</tbody>
																			</table>
																		</div>
																	)}
																</div>
																<div className="col col-sm-6 mt-3">
																	<label className="form-label">Instructions / Terms of use:</label>
																	<textarea
																		className="form-control"
																		name="instructions"
																		rows="5"
																		cols="50"
																		onChange={(event) => setInstructions(event.target.value)}
																		value={instructions}
																	>
																		{instructions}
																	</textarea>
																</div>
																<div className="col col-sm-11 mt-3">
																	<p style={{ fontWeight: "bold" }}>Active Payment Gateways</p>
																	<div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
																		{ipay ? (
																			<div className="mr-3">
																				<input
																					type="checkbox"
																					name="ipay"
																					checked={ipay}
																					onChange={(event) => setIpay(event.target.checked)}
																				/>
																				<div
																					id="banner-image"
																					style={{
																						backgroundImage:
																							"url(https://demo.business.ipaypro.co/static/media/ipay-logo.b78c6c3f.png)",
																						height: "80px",
																						width: "160px",
																						backgroundRepeat: "no-repeat",
																						backgroundSize: "contain",
																						backgroundPosition: "center",
																					}}
																				></div>
																			</div>
																		) : (
																			<div className="mr-3">
																				<input
																					type="checkbox"
																					name="ipay"
																					checked={ipay}
																					onChange={(event) => setIpay(event.target.checked)}
																				/>
																				<div
																					id="banner-image"
																					style={{
																						backgroundImage:
																							"url(https://demo.business.ipaypro.co/static/media/ipay-logo.b78c6c3f.png)",
																						height: "80px",
																						width: "160px",
																						backgroundRepeat: "no-repeat",
																						backgroundSize: "contain",
																						backgroundPosition: "center",
																					}}
																				></div>
																			</div>
																		)}
																		{visa ? (
																			<div className="mr-3">
																				<input
																					type="checkbox"
																					name="visa"
																					checked={visa}
																					onChange={(event) => setVisa(event.target.checked)}
																				/>
																				<div
																					id="banner-image"
																					style={{
																						backgroundImage:
																							"url(https://demo.business.ipaypro.co/static/media/other_payments.14707f39.png)",
																						height: "80px",
																						width: "125px",
																						backgroundRepeat: "no-repeat",
																						backgroundSize: "contain",
																						backgroundPosition: "center",
																					}}
																				></div>
																			</div>
																		) : (
																			<div className="mr-3">
																				<input
																					type="checkbox"
																					name="visa"
																					checked={visa}
																					onChange={(event) => {
																						setVisa(event.target.checked);
																						setApplePay(event.target.checked);
																						setGooglePay(event.target.checked);
																					}}
																				/>
																				<div
																					id="banner-image"
																					style={{
																						backgroundImage:
																							"url(https://demo.business.ipaypro.co/static/media/other_payments.14707f39.png)",
																						height: "80px",
																						width: "125px",
																						backgroundRepeat: "no-repeat",
																						backgroundSize: "contain",
																						backgroundPosition: "center",
																					}}
																				></div>
																			</div>
																		)}

																		{/* <div className="mr-3" style={!googlePay ? { display: "none" } : null}>
																			<input
																				type="checkbox"
																				name="google"
																				checked={googlePay}
																				onChange={(event) => setGooglePay(event.target.checked)}
																			/>
																			<div
																				id="banner-image"
																				style={{
																					backgroundImage: `url(${googlePayLogo})`,
																					height: "60px",
																					width: "100px",
																					backgroundRepeat: "no-repeat",
																					backgroundSize: "contain",
																					backgroundPosition: "center",
																				}}
																			></div>
																		</div>
																		<div className="mr-3" style={!applePay ? { display: "none" } : null}>
																			<input
																				type="checkbox"
																				name="apple"
																				checked={applePay}
																				onChange={(event) => setApplePay(event.target.checked)}
																			/>
																			<div
																				id="banner-image"
																				style={{
																					backgroundImage: `url(${applePayLogo})`,
																					height: "60px",
																					width: "100px",
																					backgroundRepeat: "no-repeat",
																					backgroundSize: "contain",
																					backgroundPosition: "center",
																				}}
																			></div>
																		</div> */}
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											)}
											{guardSetting && (
												<button color="primary" className="btn btn-outline-primary btn-xs" type="submit">
													Save
												</button>
											)}
										</form>
									</TabPane>

									<TabPane tabId="2">
										<div hidden={bookingdetailsTab} className="table-responsive" style={{ marginTop: "20px" }}>
											<table className="table table-xs table-border-horizontal table-striped">
												<thead>
													<tr>
														<th scope="col" style={{ fontSize: "0.75rem" }}>
															{" "}
															#{" "}
														</th>
														<th scope="col" style={{ fontSize: "0.75rem" }}>
															Booking Date/Time
														</th>
														<th scope="col" style={{ fontSize: "0.75rem" }}>
															{" "}
															First Name{" "}
														</th>
														<th scope="col" style={{ fontSize: "0.75rem" }}>
															{" "}
															Last Name{" "}
														</th>
														<th scope="col" style={{ fontSize: "0.75rem" }}>
															{" "}
															Email ID{" "}
														</th>
														<th scope="col" style={{ fontSize: "0.75rem" }}>
															{" "}
															Number{" "}
														</th>
														<th scope="col" style={{ fontSize: "0.75rem" }}>
															{" "}
															No. of Guards{" "}
														</th>
														<th scope="col" style={{ fontSize: "0.75rem" }}>
															{" "}
															Paid{" "}
														</th>
														<th scope="col" style={{ fontSize: "0.75rem" }}>
															{" "}
															Status{" "}
														</th>
														<th scope="col" style={{ fontSize: "0.75rem" }}>
															{" "}
															Action{" "}
														</th>
													</tr>
												</thead>
												<tbody>
													{/* {
                                                        guardBookings && guardBookings &&
                                                        guardBookings?.map((guard) => {
                                                            return guard.booking_details.map((guardbooking) => {
                                                                return guardbooking.reserved_date.map((booking, index) => {
                                                                    return <tr key={`booking-${count}`}>
                                                                        <td>{count}</td>
                                                                        <td>{moment(booking.booking_date).format("DD/MM/YYYY h:mm:ss a")}</td>
                                                                        <td>{guard.contact_details.name.split(' ')[0]}</td>
                                                                        <td>{guard.contact_details.name.split(' ')[1]}</td>
                                                                        <td>{guard.contact_details.email}</td>
                                                                        <td>{guard.contact_details.mobile}</td>
                                                                        <td>{booking.no_of_guards}</td>
                                                                        <td>{guard.final_amount}</td>
                                                                        <td>
                                                                            <Dropdown group isOpen={dropdownOpen == count} size="sm" toggle={()=>dropdownOpen == 0 ? setDropdownOpen(count) : setDropdownOpen(0)}>
                                                                                <DropdownToggle  color="default" caret>Pending</DropdownToggle>
                                                                                <DropdownMenu>
                                                                                    <div>Pending</div>
                                                                                    <div>Confirmed</div>
                                                                                    <div>Rejected</div>
                                                                                    <div>Processed</div>
                                                                                </DropdownMenu>
                                                                            </Dropdown>
                                                                        </td>
                                                                        <td><button onClick={() => handleRequestListModal({ ...guard }, { ...guard.contact_details }, { ...guardbooking }, { ...booking })} class="btn btn-primary btn-xs">View </button> </td>
                                                                        <td hidden>{count++}</td>
                                                                    </tr>

                                                                })

                                                            })

                                                        })
                                                    } */}
													{renderRequestListTable(guardBookings)}
												</tbody>
											</table>
										</div>
										{<div hidden={!bookingdetailsTab}>{renderBookingDetails(bookingDetails)}</div>}
									</TabPane>
								</TabContent>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default GuardLink;
