import React, { Component, useEffect, useState } from "react";
import { createFolder } from "../../Api/libDropbox";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormGroup, Col, Row, Input, CustomInput } from 'reactstrap';
import useForm from "react-hook-form";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";

let AddFolderModal = (props) => {

    let [loadingbtn, setloadingbtn] = useState(false);
    let [inputList, setInputList] = useState([{ title: "" }]);
    let [showerrorstatus, setshowerrorstatus] = useState(false);
    let [firstTimeLoad, setfirstTimeLoad] = useState(true);

    const { isOpen } = props;

    const { handleSubmit, register, errors } = useForm();

    useEffect(() => {
        if(isOpen)
        {
            if(firstTimeLoad)
            {
            setfirstTimeLoad(false);
            }
        }else{
            setfirstTimeLoad(true);
        }
    },[props]);

    const handleResetClick = () => {
        setInputList([{ title: "" }]);
    };

    const handleAddClick = () => {
        setInputList([...inputList, { title: ""}]);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    const onSubmit = form_data => {

        if (form_data !== '') {

            setloadingbtn(true);
            createFolder(form_data.folder, (err, res) => {
                if (err) {
                    toast.error("Internal error occured. Please contact support");
                } else {
                    setfirstTimeLoad(true);
                    handleResetClick();
                    toast.success("Folder added successfully");
                    props.onClose(true);
                }
                setloadingbtn(false);
            })
            
        }
        else {
            setshowerrorstatus(true);
            errors.showMessages();
        }
    }
    return (

        <Modal isOpen={props.isOpen} toggle={props.onClose} size="lg" className="modal-body" centered={true}>
            <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={props.onClose}><p className="m-0 font-weight-bold">Add Folder Name</p></ModalHeader>
                <ModalBody>
                    <Row form>
                        <FormGroup className="w-100">
                            <Label for="level_title">Folder Name</Label>
                            <Row form>
                                <Col md={12}>
                                    <input  className="form-control"  type="input" id="folder" name="folder" placeholder="Folder Name" ref={register({ required: true })}/>
                                    <span style={{color: '#ff5370'}}>{errors.folder && 'Folder name is required'}</span>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {!loadingbtn ?
                        <>
                            <Button color="default" type="button" onClick={() => props.onClose(false) }>Close</Button>
                            <Button color="primary" type="submit" onClick={() => setshowerrorstatus(true)}>Save</Button>
                        </>
                        :
                        <div className="loader-box pull-right" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 20, width: 20 }}></span></div>
                    }
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default AddFolderModal;