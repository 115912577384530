import {
    Home,
    Box,
    DollarSign,
    UserPlus,
    Users,
    Volume2,
    Chrome,
    Settings,
    Airplay,
    Slack,
    FolderPlus,
    File,
    Command,
    Cloud,
    Book,
    FileText,
    Server,
    Image,
    Sliders,
    Map,
    GitPullRequest,
    Calendar,
    Edit,
    Mail,
    MessageSquare,
    UserCheck,
    Layers,
    HelpCircle,
    Database,
    Headphones,
    Mic,
    ShoppingBag,
    Search,
    AlertOctagon,
    Lock,
    Briefcase,
    BarChart,
    Key,
    ShoppingCart
} from "react-feather";

export const MENUITEMS = [

    {
        title: "Dashboard",
        path: "/dashboard/default",
        icon: Home,
        type: "link",
        active: true,
    },

    {
        title: "Access Level",
        path: "/authorization/access_level",
        icon: Key,
        type: "link",
        active: false,
    },

    {
        title: 'Requests',
        icon: FileText,
        type: 'sub',
        active: false,
        children: [
            { title: 'Documents Verification', type: 'link', path: '/merchants/document_verification', count: "pendingDocVerification" },
            { title: 'Settlement Requests', type: 'link', path: '/settlement-requests', count: "pendingSettlement" },
            { title: "Affiliate Program", type: 'link', path: "/affiliate_program", count: "affiliateProgram" },
        ]
    },

    {
        title: "Merchant",
        path: "/merchants/merchant_requests",
        icon: UserPlus,
        type: "link",
        active: false,
    },

    // {
    //     title: "Customer",
    //     path: "/customers/all_customers",
    //     icon: Users,
    //     type: "link",
    //     active: false,
    // },

    {
        title: "Visitor Registry",
        path: "/hospo-visitors",
        icon: Users,
        type: "link",
        active: false,
    },
    {
        title: "Function Bookings",
        path: "/function-bookings",
        icon: Users,
        type: "link",
        active: false,
    },

    {
        title: "Orders",
        path: "/all_orders",
        icon: FileText,
        type: "link",
        active: false,
    },

    {
        title: "Transactions",
        path: "/all_transactions",
        icon: DollarSign,
        type: "link",
        active: false,
    },

    {
        title: 'Settlement',
        path: '/settlement/withdraws',
        icon: FileText,
        type: 'link',
        active: false
    },

    {
        title: 'Marketing',
        icon: FileText,
        type: 'sub',
        active: false,
        children: [
            // {
            //     title: 'Settlement', type: 'link', path: '/settlement/withdraws'
            // },
            {
                title: "Potential M",
                path: "/sop/partnerslist",
                // icon: UserPlus,
                type: "link",
                // active: false,
            },
            {
                title: "Speed Mails",
                path: "/settings/speed_mails",
                // icon: Mail,
                type: "link",
                // active: false,
            },
            {
                title: "Contact Inquiry",
                path: "/contact-inquiry",
                // icon: Users,
                type: "link",
                // active: false,
            },
        ]
    },

    /*
        {
            title: 'Overview', icon: Image, type: 'sub', active: true, children: [
                
                { path: '/customers/all_customers', title: 'Customer Accounts ', type: 'link' },
                { path: '/merchants/merchant_requests', title: 'Merchant Accounts', type: 'link' },
    
                { path: '/merchants/merchant_requests',  title: 'Merchant Requests', type: 'link' }        
            ]
        },
        */


    /* {
        title: 'Top-up Accounts', icon: FileText, type: 'link', active: false, path: '/top-up_accounts'
    }, */

    {
        title: 'Settings',
        icon: Settings,
        type: 'sub',
        active: false,
        children: [
            /*  { path: '/settings/cashback_setting', title: 'Customer & Merchant Cashback Settings', type: 'link' }, */
            {
                title: 'Accounts Settings',
                type: 'sub',
                active: false,
                children: [
                    { path: '/settings/countries', title: 'Countries', type: 'link' },
                    { path: '/other-settings', title: 'Categories', type: 'link' },
                    { path: '/settings/app_settings', title: 'App Update', type: 'link' },
                    { path: '/subscriptions', title: 'Subscription', type: 'link' },
                    { path: "/merchants/white_label_merchants", title: "White Label Merchants", type: "link" },
                    { path: "/settings/billings", title: "Billings", type: 'link' },
                    { path: "/settings/roles", title: "Roles", type: 'link' },
                    { path: '/settings/crown_points', title: 'Crown & Points', type: 'link' },
                    { path: '/settings/merchant_portal', title: 'Merchant Portal', type: 'link' },
                    { path: 'google_business_api', title: 'Google API', type: 'link' },
                ]
            },
            {
                title: 'Content Settings',
                type: 'sub',
                active: false,
                children: [
                    { path: '/content-pages', title: 'Content Pages', type: 'link' },
                    { path: '/footer-pages', title: 'Footer Pages', type: 'link' },
                    { path: '/image-gallery', title: 'Image Gallery', type: 'link' },
                    { path: "/helptopics", title: "Help Topics", type: "link" },
                ]
            },
            {
                title: 'Others',
                type: 'sub',
                active: false,
                children: [
                    { path: '/qr/generate_qr', title: 'QR Generation', type: 'link' },
                    { path: '/settings/legals', title: 'Scan ID Contracts', type: 'link' },
                    { path: '/settings/guard-settings', title: 'Guard Settings', type: 'link' },
                    { path: '/url/shorten_url', title: 'Shorten URL', type: 'link' },
                ]
            },

            // {
            //     title: "Billings",
            //     path: "/settings/billings",
            //     icon: setting,
            //     type: 'link',
            //     active: false,
            // },
            // { path: '/settings/billings', title: 'Billings', type: 'link' },
            // { path: '/other-settings', title: 'Other Settings', type: 'link' },
            // { path: '/settings/payment_gateway', title: 'Payment Gateway', type: 'link' },
            // { path: '/settings/location-details', title: 'Location Details', type: 'link' },
        ]
    },

    // {
    //     title: 'Venues', icon: Settings, type: 'sub', active: false, children: [
    //         { path: '/venues-settings', title: 'Settings', type: 'link' },
    //     ]
    // },

    // {
    //     title: 'Stores', icon: ShoppingCart, type: 'sub', active: false, children: [
    //         { path: '/Store-settings', title: 'Home page settings', type: 'link' },        
    //     ]
    // },

    // {
    //     title: 'Dart', icon: FileText, type: 'link', active: false, path: '/dart/delivery_charges'
    // },

    // {
    //     title: 'Others', icon: Server, type: 'sub', active: false, children: [
    //         // {
    //         //     title: 'Widgets', icon: Airplay, type: 'sub', children: [
    //         //         { path: '/widgets/general', title: 'General', type: 'link' },
    //         //         { path: '/widgets/chart', title: 'Chart', type: 'link' },
    //         //     ]
    //         // },

    //         // {
    //         //     title: 'Gallery', icon: Image, type: 'sub', active: false, children: [
    //         //         { path: '/gallery/imageGallery', title: 'Gallery Grid ', type: 'link' },
    //         //         { path: '/gallery/imageWithDesc', title: 'Gallery Grid With Desc ', type: 'link' },
    //         //         { path: '/gallery/mesonryGallery', title: 'Masonry Gallery', type: 'link' },
    //         //         { path: '/gallery/mesonryDesc', title: 'Masonry With Desc', type: 'link' },
    //         //         { path: '/gallery/imageHover', title: 'Hover Effect', type: 'link' }
    //         //     ]
    //         // },
    //         // {
    //         //     title: 'Forms', icon: FileText, type: 'sub', active: false, children: [
    //         //         {
    //         //             title: ' Form Controls ', type: 'sub', children: [
    //         //                 { title: 'Form Validation', type: 'link', path: '/forms/form-validation' },
    //         //                 { title: 'Basic Input', type: 'link', path: '/forms/baseInput' },
    //         //                 { title: 'Checkbox & Radio', type: 'link', path: '/forms/radio-checkbox' },
    //         //                 { title: 'Input Groups', type: 'link', path: '/forms/inputGroup' },
    //         //                 { title: 'Mega Option', type: 'link', path: '/forms/megaOptions' },

    //         //             ]
    //         //         },
    //         //         { path: '/forms/formDefault', title: 'Form Default', type: 'link' },
    //         //         { path: '/forms/FormWizard', title: 'From Wizard', type: 'link' },
    //         //     ]
    //         // },

    //         // {
    //         //     title: 'Charts', icon: BarChart, type: 'sub', active: false, children: [
    //         //         { path: '/charts/googleChart', type: 'link', title: 'Google Chart' },
    //         //         { path: '/charts/chartJs', type: 'link', title: 'Chartjs' },
    //         //         { path: '/charts/chartistComponent', type: 'link', title: 'Chartist' },
    //         //     ]
    //         // },
    //         // {
    //         //     title: 'Maps', icon: Map, type: 'sub', active: false, children: [
    //         //         { path: '/map/googleMap', type: 'link', title: 'Google Maps ' },
    //         //         { path: '/map/LeafletMap', type: 'link', title: 'Leaflet Maps ' }
    //         //     ]
    //         // },
    //         // {
    //         //     title: 'Editor', path: '/editor/editor1', icon: GitPullRequest, type: 'link', active: false
    //         // },
    //         // {
    //         //     title: 'Users', icon: Users, type: 'sub', active: false, children: [
    //         //         { path: '/users/userProfile', type: 'link', title: 'Users Profile ' },
    //         //         { path: '/users/userEdit', type: 'link', title: 'Users Edit' },
    //         //         { path: '/users/userCards', type: 'link', title: 'Users Cards' },
    //         //     ]
    //         // },
    //         // {
    //         //     title: 'Calender', path: '/calender/calender1', icon: Calendar, type: 'sub', active: false, bookmark: true, children: [
    //         //         { path: '/calender/calender1', type: 'link', title: 'Calender', },
    //         //         { path: '/calender/calender2', type: 'link', title: 'Draggable Calender' },
    //         //     ]
    //         // },
    //         // {
    //         //     title: 'Blog', icon: Edit, type: 'sub', active: false, children: [
    //         //         { path: '/blog/blogDetail', title: 'Blog Details', type: 'link' },
    //         //         { path: '/blog/blogSingle', title: 'Blog Single', type: 'link' },
    //         //         { path: '/blog/blogPost', title: 'Add Post', type: 'link' },
    //         //     ]
    //         // },
    //         // {
    //         //     title: 'Email App', icon: Mail, type: 'link', path: '/email-app/emailDefault', active: false, bookmark: true
    //         // },
    //         // {
    //         //     title: 'Contact', icon: UserPlus, type: 'link', path: '/contact-app/contact', active: false
    //         // },
    //         // {
    //         //     title: 'Chat', icon: MessageSquare, type: 'link', path: '/chat-app/chat', active: false, bookmark: true
    //         // },

    //         // {
    //         //     title: 'FAQ', icon: HelpCircle, type: 'link', path: '/faq/faqComponent', active: false
    //         // },
    //         // {
    //         //     title: 'Knowledgebase', icon: Database, type: 'link', path: '/knowledgebase/knowledgebaseComponent', active: false
    //         // },
    //         // {
    //         //     title: 'Support Ticket', icon: Headphones, type: 'link', path: '/support-ticket/supportTicket', active: false
    //         // },
    //         // {
    //         //     title: 'To-Do', icon: Mic, type: 'link', path: '/todo-app/todo', active: false
    //         // },
    //         // {
    //         //     title: 'To-Do-Firebase', icon: Mic, type: 'link', path: '/todo-app/todo-firebase', active: false
    //         // },

    //         // {
    //         //     title: 'Pricing', icon: DollarSign, path: '/price/pricing', type: 'link', active: false
    //         // },

    //         // {
    //         //     path: '/search/searchpage', title: 'Search Pages', icon: Search, type: 'link', active: false
    //         // },

    //         // {
    //         //     title: 'Authentication', icon: Lock, type: 'sub', active: false, children: [
    //         //         { path: '/pages/login', type: 'link', title: 'Login Simple' },
    //         //         { path: '/pages/loginWithBgImg', type: 'link', title: 'Login With Bg Image' },
    //         //         { path: '/pages/loginWithVideo', type: 'link', title: 'Login With Bg Video' },
    //         //         { path: '/pages/signup', type: 'link', title: 'Register Simple ' },
    //         //         { path: '/pages/signupWithImg', type: 'link', title: 'Register With Bg Image ' },
    //         //         { path: '/pages/signupWithVideo', type: 'link', title: 'Register With Bg Video ' },
    //         //         { path: '/pages/unlockUser', type: 'link', title: 'Unlock User' },
    //         //         { path: '/pages/forgetPwd', type: 'link', title: 'Forget Password ' },
    //         //         { path: '/pages/resetPwd', type: 'link', title: 'Reset Password ' }
    //         //     ]
    //         // },


    //     ]
    // },

];
