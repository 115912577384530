import React from "react";
import QRCode from "qrcode.react";

const GenerateQrcode = ({ link, logoSrc, qrSize, logoSize }) => {

    const qrcodeSetting = {
        src: logoSrc,
        height:logoSize,
        width:logoSize,
    }

    return(
        <QRCode value={link} imageSettings={qrcodeSetting} size={qrSize} renderAs="svg"/>
    );
}

export default GenerateQrcode