import React, { Component, useEffect, useState } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
} from "reactstrap";
import useForm from "react-hook-form";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";

const EditPromoCodes = (props) => {
    let [loadingbtn, setloadingbtn] = useState(false);
    let [showerrorstatus, setshowerrorstatus] = useState(false);
    const [duplicatePromo, setDuplicatePromo] = useState(false);

    const [data, setData] = useState({
        promo_code: "",
        discount_type: "",
        amount: "",
        percentage: "",
        valid_from: "",
        valid_till: "",
    });

    const [promoCode, setPromoCode] = useState("");
    const [discountType, setDiscountType] = useState("");
    const [amount, setAmount] = useState("");
    const [percentage, setPercentage] = useState("");
    const [validFrom, setValidFrom] = useState("");
    const [validTill, setValidTill] = useState("");

    useEffect(() => {
        if (!props.editPromoData) return;

        setPromoCode(props.editPromoData?.promo_code);
        setDiscountType(props.editPromoData?.discount_type);
        setAmount(props.editPromoData?.amount);
        setPercentage(props.editPromoData?.percentage);
        setValidFrom(props.editPromoData?.valid_from);
        setValidTill(props.editPromoData?.valid_till);
    }, [props.editPromoData]);

    const { handleSubmit, register, errors, watch } = useForm();
    // {
    // defaultValues: {
    //     promo_code: promoCode,
    //     discount_type: discountType,
    //     amount: amount,
    //     percentage: percentage,
    //     valid_from: validFrom,
    //     valid_till: validTill,
    // },
    //     defaultValues: {
    //         promo_code: props.editPromoData.promo_code,
    //         discount_type: props.editPromoData.discount_type,
    //         amount: props.editPromoData.amount,
    //         percentage: props.editPromoData.percentage,
    //         valid_from: props.editPromoData.valid_from,
    //         valid_till: props.editPromoData.valid_till,
    //     },
    // }

    const onSubmit = (form_data) => {
        if (form_data !== "") {
            let duplicate = false;
            form_data.id = props.editPromoData._id;
            let tempArr = props.promoCodesData.filter((dataaa) => dataaa._id != props.editPromoData._id)


            if (tempArr.length > 0) {
                let temp = tempArr.map((dataa) => {
                    console.log(dataa.promo_code == form_data.promo_code, "as")
                    if (dataa.promo_code.toUpperCase() == form_data.promo_code.toUpperCase()) {
                        duplicate = true
                    }
                    return dataa
                })

            }

            // form_data.discount_type == "1"
            //     ? (form_data.discount_type = "Percentage based")
            //     : (form_data.discount_type = "Amount based");


            if (!duplicate) {
                setloadingbtn(true);
                form_data.promo_code = form_data.promo_code.toUpperCase()

                client.post(
                    api.editPromoData,
                    form_data,
                    async function (error, response) {
                        console.log("data2==>", response);
                        if (!error) {
                            if (typeof response.error !== "undefined") {
                                try {
                                    if (!response.error) {
                                        props.toggleModal(1);
                                        toast.success("Updated Successfully");
                                    } else {
                                        toast.error(response.message);
                                    }
                                } catch (e) {
                                    toast.error(
                                        "Internal error occured. Please contact support"
                                    );
                                }
                            } else {
                                toast.error(
                                    "Internal error occured. Please contact support"
                                );
                            }
                        } else {
                            toast.error(
                                "Internal error occured. Please contact support"
                            );
                        }

                        setloadingbtn(false);
                    }
                );
            } else {
                setDuplicatePromo(true)
                toast.error("Code already exists")
            }

            // client.post(
            //     api.savePromoCodes,
            //     form_data,
            //     async function (error, response) {
            //         if (!error) {
            //             if (typeof response.error !== "undefined") {
            //                 try {
            //                     if (!response.error) {
            //                         props.toggleModal(1);
            //                         toast.success("Added Successfully");
            //                         // setcashbackdata(response.result.data);
            //                     } else {
            //                         toast.error(response.message);
            //                     }
            //                 } catch (e) {
            //                     toast.error(
            //                         "Internal error occured. Please contact support"
            //                     );
            //                 }
            //             } else {
            //                 toast.error(
            //                     "Internal error occured. Please contact support"
            //                 );
            //             }
            //         } else {
            //             toast.error(
            //                 "Internal error occured. Please contact support"
            //             );
            //         }

            //         setloadingbtn(false);
            //     }
            // );
        } else {
            setshowerrorstatus(true);
            errors.showMessages();
        }
    };

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setData((prevState) => ({
    //         ...prevState,
    //         [name]: value,
    //     }));
    // };

    // console.log("dis", discountType);
    return (
        <Modal
            isOpen={props.isOpen}
            toggle={props.toggleModal}
            className="modal-body"
            centered={true}
        >
            <form
                className="needs-validation"
                noValidate=""
                onSubmit={handleSubmit(onSubmit)}
            >
                <ModalHeader toggle={props.toggleModal}>
                    Edit Promo Codes Data
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <Label for="promo_code">Promo code</Label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="promo_code"
                                    placeholder="Promo code"
                                    // value={data.promo_code}
                                    // onChange={handleChange}
                                    value={promoCode}
                                    onChange={(e) =>
                                        setPromoCode(e.target.value)
                                    }
                                    style={{ textTransform: "uppercase" }}
                                    ref={register({ required: true })}
                                />
                                <span style={{ color: "#ff5370" }}>
                                    {errors.promo_code &&
                                        "Promo code is required"}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <Label for="discount_type">Discount type</Label>
                                {/* remaining */}
                                <select
                                    ref={register({ required: true })}
                                    // {...register("discount_type")}
                                    style={{
                                        width: "100%",
                                        paddingBlock: "0.375rem",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                    }}
                                    name="discount_type"
                                    // value={data.discount_type}
                                    // onChange={handleChange}
                                    value={discountType}
                                    onChange={(e) =>
                                        setDiscountType(e.target.value)
                                    }
                                >
                                    <option
                                        value="0"
                                        style={{
                                            fontSize: "16px",
                                            lineHeight: "24px",
                                        }}
                                    >
                                        Amount based
                                    </option>
                                    <option
                                        value="1"
                                        style={{
                                            fontSize: "16px",
                                            lineHeight: "24px",
                                        }}
                                    >
                                        Percentage based
                                    </option>
                                </select>
                                <span style={{ color: "#ff5370" }}>
                                    {errors.discount_type && "select a value"}
                                </span>
                            </div>
                        </div>
                    </div>
                    {/* To be decide as per Discount type */}
                    {/* watch("discount_type") != "Percentage based" ?  */}
                    {discountType == "0" && (
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <Label for="amount">Amount</Label>
                                    <input
                                        className="form-control"
                                        type="number"
                                        name="amount"
                                        // value={data.amount}
                                        // onChange={handleChange}
                                        value={amount}
                                        onChange={(e) =>
                                            setAmount(e.target.value)
                                        }
                                        ref={register({ required: true })}
                                    />
                                    <span style={{ color: "#ff5370" }}>
                                        {errors.amount && "Amount is required"}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                    {discountType == "1" && (
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <Label for="percentage">
                                        Percentage (%)
                                    </Label>
                                    <input
                                        className="form-control"
                                        type="number"
                                        name="percentage"
                                        ref={register({ required: true })}
                                        // value={data.percentage}
                                        // onChange={handleChange}
                                        value={percentage}
                                        onChange={(e) =>
                                            setPercentage(e.target.value)
                                        }
                                    />
                                    <span style={{ color: "#ff5370" }}>
                                        {errors.percentage &&
                                            "Percentage is required"}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <Label for="valid_from">Valid from</Label>
                                <input
                                    className="form-control"
                                    type="date"
                                    name="valid_from"
                                    ref={register({ required: true })}
                                    // value={data.valid_from}
                                    // onChange={handleChange}
                                    value={validFrom}
                                    onChange={(e) =>
                                        setValidFrom(e.target.value)
                                    }
                                />
                                <span style={{ color: "#ff5370" }}>
                                    {errors.valid_from && "date is required"}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <Label for="valid_till">Valid till</Label>
                                <input
                                    className="form-control"
                                    type="date"
                                    name="valid_till"
                                    ref={register({ required: true })}
                                    // value={data.valid_till}
                                    // onChange={handleChange}
                                    value={validTill}
                                    onChange={(e) =>
                                        setValidTill(e.target.value)
                                    }
                                />
                                <span style={{ color: "#ff5370" }}>
                                    {errors.valid_till && "date is required"}
                                </span>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {!loadingbtn ? (
                        <>
                            <Button
                                color="default"
                                type="button"
                                onClick={props.toggleModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                type="submit"
                                onClick={() => setshowerrorstatus(true)}
                            >
                                Save
                            </Button>
                        </>
                    ) : (
                        <div
                            className="loader-box pull-right"
                            style={{ height: "auto" }}
                        >
                            <span
                                className="rotate dashed"
                                style={{ height: 20, width: 20 }}
                            ></span>
                        </div>
                    )}
                </ModalFooter>
            </form>
        </Modal>
    );
};

export default EditPromoCodes;
