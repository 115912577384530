import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import Add_creadit_earning from "./add_creadit_earning";
import { ToastContainer, toast } from "react-toastify";
// import { Link as NavLink } from "react-router-dom";
import useForm from 'react-hook-form';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { TabContent, TabPane, Nav, NavItem, NavLink, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import Switch from "react-switch";
import { MerchantSubscriptions } from './MerchantSubscriptions';
import { MerchantInvoices } from './MerchantInvoices';
import { DisappearedLoading } from 'react-loadingg';


// const BorderTable = () => {
function BorderTable(props) {

  let { register, handleSubmit, errors } = useForm(); // initialise the hook

  let [loadingbtn, setloadingbtn] = useState(false);
  let [loading, setloading] = useState(false);
  let [billingdata, setbillingdata] = useState(null);
  let [billingdatapaypal, setbillingdatapaypal] = useState(null);
  let [creditdata, setcreditdata] = useState(null);
  let [activeTab, setactiveTab] = useState("1");
  const [popoverOpen, setPopoverOpen] = useState(false); //popover useState
  const [access_level, setAccessLevel] = useState(localStorage.getItem("accessLevel"))
  const [tabIndex, setTabIndex] = useState(0);

  let [c_to_m_sc_fee_btype, setc_to_m_sc_fee_btype] = useState("1");
  let [c_to_m_sc_fee_atype, setc_to_m_sc_fee_atype] = useState("1");
  let [m_to_m_sc_fee_btype, setm_to_m_sc_fee_btype] = useState("1");
  let [m_to_m_sc_fee_atype, setm_to_m_sc_fee_atype] = useState("1");
  let [m_to_c_sc_fee_btype, setm_to_c_sc_fee_btype] = useState("1");
  let [m_to_c_sc_fee_atype, setm_to_c_sc_fee_atype] = useState("1");
  let [ticket_fee_btype, setticket_fee_btype] = useState("1");
  let [ticket_fee_atype, setticket_fee_atype] = useState("1");
  let [wd_fee_btype, setwd_fee_btype] = useState("1");
  let [wd_fee_atype, setwd_fee_atype] = useState("1");
  let [bills_fee_btype, setbills_fee_btype] = useState("1");
  let [bills_fee_atype, setbills_fee_atype] = useState("1");
  let [venue_ipay_fee_payer, setvenue_ipay_fee_payer] = useState(false);
  let [pl_ipay_fee_payer, setpl_ipay_fee_payer] = useState(false);
  let [credit_earning_modal, setcredit_earning_modal] = useState(false);

  const onSubmit = data => {
    if (data !== '') {

      // console.log(data);
      data.c_to_m_sc_fee_btype = c_to_m_sc_fee_btype;
      data.c_to_m_sc_fee_atype = c_to_m_sc_fee_atype;
      data.m_to_m_sc_fee_btype = m_to_m_sc_fee_btype;
      data.m_to_m_sc_fee_atype = m_to_m_sc_fee_atype;
      data.m_to_c_sc_fee_btype = m_to_c_sc_fee_btype;
      data.m_to_c_sc_fee_atype = m_to_c_sc_fee_atype;
      data.wd_fee_btype = wd_fee_btype;
      data.wd_fee_atype = wd_fee_atype;
      data.bills_fee_btype = bills_fee_btype;
      data.bills_fee_atype = bills_fee_atype;
      data.ticket_fee_atype = ticket_fee_atype;
      data.ticket_fee_btype = ticket_fee_btype;
      data.venue_ipay_fee_payer = venue_ipay_fee_payer ? 1 : 0;
      data.pl_ipay_fee_payer = pl_ipay_fee_payer ? 1 : 0;
      data.merchant_id = props.merchant_id;
      data.m_to_m_func_booking_spent = Number( data.m_to_m_func_booking_spent)
      data.m_to_m_func_venue_fees = Number(data.m_to_m_func_venue_fees)
      data.gateway=data.gateway?data.gateway:'STRIPE'


      // setloadingbtn(true);
      client.post(api.merchant_billing_update, data, async function (error, response) {
        if (!error) {
          // alert(JSON.stringify(response));
          //   return false;
          if (typeof response.error !== "undefined") {
            try {
              if (!response.error) {
                getbilling_settings();
                console.log(response)

                toast.success("Updated Successfully");
                // setcashbackdata(response.result.data);
              } else {
                toast.error(response.message);
              }
            } catch (e) {
              toast.error("Internal error occured. Please contact support");
            }
          } else {
            toast.error("Internal error occured. Please contact support");
          }
        } else {
          toast.error("Internal error occured. Please contact support");
        }
        setloadingbtn(false);
      });
    } else {

      errors.showMessages();
    }
  };

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen)

  }


  const getbilling_settings = async () => {
    let postdata = { merchant_id: props.merchant_id };
    let current = this;
    // current.setState({ loading: true });
    setloading(true);
    client.post(api.merchant_billing, postdata, async function (error, response) {
      if (!error) {
        console.log(response.result, "response.result.data");
        //   return false;
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              // alert(response.result.data[0])
              if (response.result.data) {
                setbillingdata(response.result.data);
                setbillingdatapaypal(response.result.data_paypal);
                setcreditdata(response.result.earnings)

              }
              // current.props.navigation.navigate("Verification");
            } else {
              toast.error(response.message);
            }
          } catch (e) {
            toast.error("Internal error occured. Please contact support");
          }
        } else {
          toast.error("Internal error occured. Please contact support");
        }
      } else {
        toast.error("Internal error occured. Please contact support");
        // _showToast.error("Internal error occured. Please contact support.");
      }
      // current.setState({ loading: false });
      setloading(false);

    });
  };

  useEffect(() => {
    // alert("hrer");
    getbilling_settings();
  }, [])

console.log(billingdata,billingdatapaypal)
  useEffect(() => {
    // alert("hrer");
    // getbilling_settings();
    let res = billingdata
    if (tabIndex == 1) {
      res = billingdatapaypal
    }
    if (res) {

      setc_to_m_sc_fee_btype(res.c_to_m_sc_fee_btype);
      setc_to_m_sc_fee_atype(res.c_to_m_sc_fee_atype);
      setm_to_m_sc_fee_btype(res.m_to_m_sc_fee_btype);
      setm_to_m_sc_fee_atype(res.m_to_m_sc_fee_atype);
      setm_to_c_sc_fee_btype(res.m_to_c_sc_fee_btype);
      setm_to_c_sc_fee_atype(res.m_to_c_sc_fee_atype);

      setticket_fee_btype(res.ticket_fee_btype);
      setticket_fee_atype(res.ticket_fee_atype);

      setwd_fee_btype(res.wd_fee_btype);
      setwd_fee_atype(res.wd_fee_atype);

      setbills_fee_btype(res.bills_fee_btype);
      setwd_fee_atype(res.wd_fee_atype);
      setvenue_ipay_fee_payer(res.venue_ipay_fee_payer);
      setpl_ipay_fee_payer(res.pl_ipay_fee_payer);
    }

  }, [tabIndex, billingdata, billingdatapaypal])

  const displayDate = (date) => {
    let transaction_date = new Date(date);
    return (
      <span>
        {transaction_date}
      </span>
    )
  }


  return (
    <Fragment>
      <div className="col-lg-7 col-sm-12" style={{ fontSize: "12px" }}>
        <Breadcrumb title={"Billing Setting"} parent={"Home"} />
      </div>
      <div className="container-fluid">
        <div className="row theme-tab" style={{ marginBottom: '0px !important' }} >
          <Tabs className="col-sm-12" defaultIndex={0} onSelect={index => setTabIndex(index)} style={{ padding: "0" }}>
            <TabList className="tabs tab-title" style={{ marginBottom: '0px !important' }}>
              <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Stripe & Linky charges </Tab>
              <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> PayPal & Linky charges </Tab>
              <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }} >
                Linky Commissions
              </Tab>
              <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Subscriptions </Tab>
              <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Invoices </Tab>
            </TabList>
            <div className="tab-content-cls">
            <TabPanel>
                <div className="container-fluid" style={{ paddingTop: '0.5rem' }}>
                  <div className="col-sm-12 col-xl-12">
                    <div className="row">

                      <div className="col-xl-12">
                        <div className="card">
                          <div className="card">
                            <div className="card-body">
                              {/* </div>
                    <div className="card-body"> */}
                              {/* <Nav tabs className="nav-pills nav-primary"> */}
                                {/* <NavItem className="nav nav-tabs nav-link" id="myTab" role="tablist">
                                  <NavLink className={activeTab == "1" ? 'active nav-link' : ''} onClick={() => { setactiveTab("1") }} value='1'>
                                    Merchant charges</NavLink>
                                </NavItem> */}


                                {/* <NavItem className="nav nav-tabs nav-link" id="myTab" role="tablist">
                                  <NavLink className={activeTab == "2" ? 'active nav-link' : ''} onClick={() => { setactiveTab("2") }} value='2'>
                                    Discount & Deduction</NavLink>
                                </NavItem>


                                <NavItem className="nav nav-tabs nav-link" id="myTab" role="tablist">
                                  <NavLink className={activeTab == "3" ? 'active nav-link' : ''} onClick={() => { setactiveTab("3") }} value='3'>
                                    Credits & Earnings</NavLink>
                                </NavItem> */}

                              {/* </Nav> */}
                              {/* <TabContent activeTab={activeTab}>
                                <TabPane tabId="1"> */}
                              {billingdata != null ?
                                <form className="needs-validation theme-form mt-3 billing-form" noValidate="" onSubmit={handleSubmit(onSubmit)}>

                                  <input name="gateway" value="STRIPE" ref={register({ required: true })} type="hidden" />
                                  
                                  {/* <input name="config_type" value="1" type = "hidden"/> */}
                                  {/* <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>iPayPro Service Fee/ C2B "Customer To Business"</h5>
                                      <small>When customer pay to a business using iPayPro eWallet</small>
                                      <div className="form-row">

                                        <div className="col-sm-2">
                                          <div className="form-group">
                                            <label className="col-form-label">Minimum Amount</label>
                                            <input className="form-control" step="0.01" min="0" disabled={access_level == 1 ? true : false} type="Number" name="c_to_m_sc_comp_amount" defaultValue={billingdata.c_to_m_sc_comp_amount} ref={register({ required: true })} />
                                            <span>{errors.c_to_m_sc_comp_amount && 'Minimum Amount is requires'}</span>
                                          </div>
                                        </div>

                                        <div className="col-sm-2">
                                          <div className="form-group">
                                            <label className="col-form-label">Fee Type</label>
                                            <select className="form-control select" name="c_to_m_sc_fee_btype" disabled={access_level == 1 ? true : false} value={c_to_m_sc_fee_btype}
                                              onChange={(e) => setc_to_m_sc_fee_btype(e.target.value)}>
                                              <option value="1">Percent</option>
                                              <option value="2">Amount</option>
                                            </select>
                                            <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                          </div>
                                        </div>
                                        <div className="col-sm-3">
                                          <div className="form-group">
                                            <label className="col-form-label">Fee below min. Amount</label>
                                            <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="c_to_m_sc_fee_below" defaultValue={billingdata.c_to_m_sc_fee_below} ref={register({ required: true })} />
                                            <span>{errors.c_to_m_sc_fee_below && 'Fee is requires'}</span>
                                          </div>
                                        </div>

                                        <div className="col-sm-2">
                                          <div className="form-group">
                                            <label className="col-form-label">Fee Type</label>
                                            <select className="form-control select" name="c_to_m_sc_fee_atype" disabled={access_level == 1 ? true : false} value={c_to_m_sc_fee_atype}
                                              onChange={(e) => setc_to_m_sc_fee_atype(e.target.value)}>
                                              <option value="1">Percent</option>
                                              <option value="2">Amount</option>
                                            </select>

                                            <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                          </div>
                                        </div>
                                        <div className="col-sm-3">
                                          <div className="form-group">
                                            <label className="col-form-label">Fee above min. Amount</label>
                                            <input className="form-control" step="0.01" min="0" type="Number" name="c_to_m_sc_fee_above" disabled={access_level == 1 ? true : false} ref={register({ required: true })} defaultValue={billingdata.c_to_m_sc_fee_above} />
                                            <span>{errors.c_to_m_sc_fee_above && 'Fee is required'}</span>
                                          </div>
                                        </div>
                                      </div>

                                      <hr /> */}

                                  <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>Linky Service Fee</h5>
                                  <small>For Payment Link/ Tips/ Linky Meet/ Make a payment button/ Online ordering/ Bookings etc</small>
                                  <div className="form-row">


                                    <div className="col-sm-2">
                                      <div className="form-group">
                                        <label className="col-form-label">Minimum Amount</label>
                                        <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="m_to_m_sc_comp_amount" defaultValue={billingdata.m_to_m_sc_comp_amount} ref={register({ required: true })} />
                                        <span>{errors.m_to_m_sc_comp_amount && 'Minimum Amount is requires'}</span>
                                      </div>
                                    </div>

                                    <div className="col-sm-2">
                                      <div className="form-group">
                                        <label className="col-form-label">Fee Type</label>
                                        <select className="form-control select" name="m_to_m_sc_fee_btype" disabled={access_level == 1 ? true : false} value={m_to_m_sc_fee_btype}
                                          onChange={(e) => setm_to_m_sc_fee_btype(e.target.value)}>
                                          <option value="1">Percent</option>
                                          <option value="2">Amount</option>
                                        </select>

                                        <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                      </div>
                                    </div>
                                    <div className="col-sm-3">
                                      <div className="form-group">
                                        <label className="col-form-label">Fee below min. Amount</label>
                                        <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="m_to_m_sc_fee_below" defaultValue={billingdata.m_to_m_sc_fee_below} ref={register({ required: true })} />
                                        <span>{errors.m_to_m_sc_fee_below && 'Fee is requires'}</span>
                                      </div>
                                    </div>

                                    <div className="col-sm-2">
                                      <div className="form-group">
                                        <label className="col-form-label">Fee Type</label>
                                        <select className="form-control select" name="m_to_m_sc_fee_atype" disabled={access_level == 1 ? true : false} value={m_to_m_sc_fee_atype}
                                          onChange={(e) => setm_to_m_sc_fee_atype(e.target.value)}>
                                          <option value="1">Percent</option>
                                          <option value="2">Amount</option>
                                        </select>

                                        <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                      </div>
                                    </div>
                                    <div className="col-sm-3">
                                      <div className="form-group">
                                        <label className="col-form-label">Fee above min. Amount</label>
                                        <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="m_to_m_sc_fee_above" ref={register({ required: true })} defaultValue={billingdata.m_to_m_sc_fee_above} />
                                        <span>{errors.m_to_m_sc_fee_above && 'Fee is required'}</span>
                                      </div>
                                    </div>
                                  </div>

                                  <hr />
                                  {/* 
                                      <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>iPayPro Service Fee/B2C "Business to Customer"</h5>
                                      <small>When business pay to a customer using iPayPro eWallet</small>
                                      <div className="form-row">


                                        <div className="col-sm-2">
                                          <div className="form-group">
                                            <label className="col-form-label">Minimum Amount</label>
                                            <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="m_to_c_sc_comp_amount" defaultValue={billingdata.m_to_c_sc_comp_amount} ref={register({ required: true })} />
                                            <span>{errors.m_to_c_sc_comp_amount && 'Minimum Amount is requires'}</span>
                                          </div>
                                        </div>

                                        <div className="col-sm-2">
                                          <div className="form-group">
                                            <label className="col-form-label">Fee Type</label>
                                            <select className="form-control select" name="m_to_c_sc_fee_btype" disabled={access_level == 1 ? true : false} value={m_to_c_sc_fee_btype}
                                              onChange={(e) => setm_to_c_sc_fee_btype(e.target.value)}>
                                              <option value="1">Percent</option>
                                              <option value="2">Amount</option>
                                            </select>

                                            <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                          </div>
                                        </div>
                                        <div className="col-sm-3">
                                          <div className="form-group">
                                            <label className="col-form-label">Fee below min. Amount</label>
                                            <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="m_to_c_sc_fee_below" defaultValue={billingdata.m_to_c_sc_fee_below} ref={register({ required: true })} />
                                            <span>{errors.m_to_c_sc_fee_below && 'Fee is requires'}</span>
                                          </div>
                                        </div>

                                        <div className="col-sm-2">
                                          <div className="form-group">
                                            <label className="col-form-label">Fee Type</label>
                                            <select className="form-control select" name="m_to_c_sc_fee_atype" disabled={access_level == 1 ? true : false} value={m_to_c_sc_fee_atype}
                                              onChange={(e) => setm_to_c_sc_fee_atype(e.target.value)}>
                                              <option value="1">Percent</option>
                                              <option value="2">Amount</option>
                                            </select>

                                            <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                          </div>
                                        </div>
                                        <div className="col-sm-3">
                                          <div className="form-group">
                                            <label className="col-form-label">Fee above min. Amount</label>
                                            <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="m_to_c_sc_fee_above" ref={register({ required: true })} defaultValue={billingdata.m_to_c_sc_fee_above} />
                                            <span>{errors.m_to_c_sc_fee_above && 'Fee is required'}</span>
                                          </div>
                                        </div>
                                      </div>

                                      <hr /> */}

                                  <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>Ticketing- Linky Service Fee</h5>
                                  <div className="form-row">


                                    <div className="col-sm-2">
                                      <div className="form-group">
                                        <label className="col-form-label">Minimum Amount</label>
                                        <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="ticket_comp_amount" defaultValue={billingdata.ticket_comp_amount} ref={register({ required: true })} />
                                        <span>{errors.ticket_comp_amount && 'Minimum Amount is requires'}</span>
                                      </div>
                                    </div>

                                    <div className="col-sm-2">
                                      <div className="form-group">
                                        <label className="col-form-label">Fee Type</label>
                                        <select className="form-control select" name="ticket_fee_btype" disabled={access_level == 1 ? true : false} value={ticket_fee_btype}
                                          onChange={(e) => setticket_fee_btype(e.target.value)}>
                                          <option value="1">Percent</option>
                                          <option value="2">Amount</option>
                                        </select>

                                        <span>{errors.ticket_fee_btype && 'Select Fee type'}</span>

                                      </div>
                                    </div>
                                    <div className="col-sm-2">
                                      <div className="form-group">
                                        <label className="col-form-label">Fee below min. Amount</label>
                                        <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="ticket_fee_below" defaultValue={billingdata.ticket_fee_below} ref={register({ required: true })} />
                                        <span>{errors.ticket_fee_below && 'Fee is requires'}</span>
                                      </div>
                                    </div>

                                    <div className="col-sm-2">
                                      <div className="form-group">
                                        <label className="col-form-label">Fee Type</label>
                                        <select className="form-control select" name="ticket_fee_atype" disabled={access_level == 1 ? true : false} value={ticket_fee_atype}
                                          onChange={(e) => setticket_fee_atype(e.target.value)}>
                                          <option value="1">Percent</option>
                                          <option value="2">Amount</option>
                                        </select>

                                        <span>{errors.ticket_fee_atype && 'Select Fee type'}</span>

                                      </div>
                                    </div>
                                    <div className="col-sm-2">
                                      <div className="form-group">
                                        <label className="col-form-label">Fee above min. Amount</label>
                                        <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="ticket_fee_above" ref={register({ required: true })} defaultValue={billingdata.ticket_fee_above} />
                                        <span>{errors.ticket_fee_above && 'Fee is required'}</span>
                                      </div>
                                    </div>

                                    <div className="col-sm-2">
                                      <div className="form-group">
                                        <label className="col-form-label">Fee Per Ticket</label>
                                        <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="fee_per_ticket" ref={register({ required: true })} defaultValue={billingdata.fee_per_ticket} />
                                        <span>{errors.fee_per_ticket && 'Fee is required'}</span>
                                      </div>
                                    </div>
                                  </div>

                                  <hr />
                                  
                                  
                                  <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>Invoice/Quotes- Linky Service Fee</h5>
                                  <div className="form-row">


                                    <div className="col-sm-2">
                                      <div className="form-group">
                                        <label className="col-form-label">Minimum Amount</label>
                                        <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="bills_comp_amount" defaultValue={billingdata.bills_comp_amount} ref={register({ required: true })} />
                                        <span>{errors.bills_comp_amount && 'Minimum Amount is requires'}</span>
                                      </div>
                                    </div>

                                    <div className="col-sm-2">
                                      <div className="form-group">
                                        <label className="col-form-label">Fee Type</label>
                                        <select className="form-control select" name="bills_fee_btype" disabled={access_level == 1 ? true : false} value={bills_fee_btype}
                                          onChange={(e) => setbills_fee_btype(e.target.value)}>
                                          <option value="1">Percent</option>
                                          <option value="2">Amount</option>
                                        </select>

                                        <span>{errors.bills_fee_btype && 'Select Fee type'}</span>

                                      </div>
                                    </div>
                                    <div className="col-sm-3">
                                      <div className="form-group">
                                        <label className="col-form-label">Fee below min. Amount</label>
                                        <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="bills_fee_below" defaultValue={billingdata.bills_fee_below} ref={register({ required: true })} />
                                        <span>{errors.bills_fee_below && 'Fee is requires'}</span>
                                      </div>
                                    </div>

                                    <div className="col-sm-2">
                                      <div className="form-group">
                                        <label className="col-form-label">Fee Type</label>
                                        <select className="form-control select" name="bills_fee_atype" disabled={access_level == 1 ? true : false} value={bills_fee_atype}
                                          onChange={(e) => setbills_fee_atype(e.target.value)}>
                                          <option value="1">Percent</option>
                                          <option value="2">Amount</option>
                                        </select>

                                        <span>{errors.bills_fee_atype && 'Select Fee type'}</span>

                                      </div>
                                    </div>
                                    <div className="col-sm-3">
                                      <div className="form-group">
                                        <label className="col-form-label">Fee above min. Amount</label>
                                        <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="bills_fee_above" ref={register({ required: true })} defaultValue={billingdata.bills_fee_above} />
                                        <span>{errors.bills_fee_above && 'Fee is required'}</span>
                                      </div>
                                    </div>
                                  </div>

                                  <hr />


                                  <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>Withdrawal Fee</h5>
                                  <small>When Business send request to withdraw their eWallet funds to their bank account</small>
                                  <div className="form-row">

                                    <div className="col-sm-2">
                                      <div className="form-group">
                                        <label className="col-form-label">Minimum Amount</label>
                                        <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="wd_comp_amount" defaultValue={billingdata.wd_comp_amount} ref={register({ required: true })} />
                                        <span>{errors.wd_comp_amount && 'Minimum Amount is requires'}</span>
                                      </div>
                                    </div>

                                    <div className="col-sm-2">
                                      <div className="form-group">
                                        <label className="col-form-label">Fee Type</label>
                                        <select className="form-control select" name="wd_fee_btype" disabled={access_level == 1 ? true : false} value={wd_fee_btype}
                                          onChange={(e) => setwd_fee_btype(e.target.value)} >
                                          <option value="1">Percent</option>
                                          <option value="2">Amount</option>
                                        </select>
                                        <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                      </div>
                                    </div>

                                    <div className="col-sm-3">

                                      <div className="form-group">
                                        <label className="col-form-label">Fee below to min. Amount</label>
                                        <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="wd_fee_below" defaultValue={billingdata.wd_fee_below} ref={register({ required: true })} />
                                        <span>{errors.wd_fee_below && 'Fee is requires'}</span>
                                      </div>
                                    </div>

                                    <div className="col-sm-2">
                                      <div className="form-group">
                                        <label className="col-form-label">Fee Type</label>
                                        <select className="form-control select"
                                          onChange={(e) => setwd_fee_atype(e.target.value)} name="wd_fee_atype" disabled={access_level == 1 ? true : false} value={wd_fee_atype}>
                                          <option value="1">Percent</option>
                                          <option value="2">Amount</option>
                                        </select>
                                        <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                      </div>
                                    </div>

                                    <div className="col-sm-3">
                                      <label className="col-form-label">Fee above min. Amount</label>
                                      <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="wd_fee_above" ref={register({ required: true })} defaultValue={billingdata.wd_fee_above} />
                                      <span>{errors.wd_fee_above && 'Fee is required'}</span>
                                    </div>
                                  </div>

                                  <hr />
                                  <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>Online Fees</h5>
                                  <span>1) For Payment Link/ Invoice/ Quotes/ Tips/ Tickets/ Linky Meet/ Make a payment button/ Online ordering/ Bookings etc</span>

                                  {
                                    <>
                                      <div className="container" style={{ marginLeft: "-2px", marginTop: "16px", marginBottom: 16 }}>

                                        {
                                          <div>
                                            <label for="radio1" style={{ fontSize: "14px !important", fontWeight: "bold", display: "flex", }}><span style={{ marginRight: 10 }}>Pass Online Fees to Customer</span> <Switch
                                              onChange={(checked) => setpl_ipay_fee_payer(checked)}
                                              checked={pl_ipay_fee_payer}
                                              className="react-switch qr-page__switch"
                                              height={17}
                                              width={40}
                                            /></label>
                                          </div>
                                        }

                                        <label style={{ fontSize: "14px !important" }}>Set the online card fees you want merchant to be able to pass on to customers</label>
                                        {
                                          <div style={{ display: 'flex', flexDirection: 'row' }}>

                                            <div className="col-sm-3">
                                              <div class="input-group">
                                                <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="pl_fee_on_amount" defaultValue={billingdata.pl_fee_on_amount} />
                                                <div class="input-group-append">
                                                  <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Percent</span>
                                                </div>
                                              </div>
                                              <span className="text-danger">{errors.pl_fee_on_amount && 'This field is requires'}</span>
                                            </div>


                                            <div className="col-sm-3">
                                              <div className="input-group">
                                                <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="pl_fixed_fee" defaultValue={billingdata.pl_fixed_fee} />
                                                <div class="input-group-append">
                                                  <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Amount</span>
                                                </div>
                                              </div>
                                              <span className="text-danger">{errors.pl_fixed_fee && 'This field is requires'}</span>
                                            </div>

                                          </div>
                                        }

                                        {
                                          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>

                                            <div className="col-sm-3">
                                              <div class="input-group">
                                                <input className="form-control" onWheel={() => document.activeElement.blur()} step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="pl_fee_on_amount_international" defaultValue={billingdata.pl_fee_on_amount_international} />
                                                <div class="input-group-append">
                                                  <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Percent</span>
                                                </div>
                                              </div>
                                              <span className="text-danger">{errors.pl_fee_on_amount_international && 'This field is requires'}</span>
                                            </div>


                                            <div className="col-sm-3">
                                              <div className="input-group">
                                                <input className="form-control" onWheel={() => document.activeElement.blur()} step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="pl_fixed_fee_international" defaultValue={billingdata.pl_fixed_fee_international} />
                                                <div class="input-group-append">
                                                  <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Amount</span>
                                                </div>

                                              </div>
                                              <span className="text-danger">{errors.pl_fixed_fee_international && 'This field is requires'}</span>
                                            </div>

                                            <div className="col-sm-3">
                                              <div className="input-group">

                                                <div class="input-group-append">
                                                  <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">(International Card)</span>
                                                </div>
                                              </div>
                                              <span className="text-danger">{errors.pl_fixed_fee_international && 'This field is requires'}</span>
                                            </div>

                                          </div>
                                        }



                                      </div>
                                      <span>2) For Online orders</span>
                                      <div className="container" style={{ marginLeft: "-2px", marginTop: "16px" }}>

                                        <div>
                                          <label for="radio1" style={{ fontSize: "14px !important", fontWeight: "bold", display: "flex", }}><span style={{ marginRight: 10 }}>Pass Online Fees to Customer</span> <Switch
                                            onChange={(checked) => setvenue_ipay_fee_payer(checked)}
                                            checked={venue_ipay_fee_payer}
                                            className="react-switch qr-page__switch"
                                            height={17}
                                            width={40}
                                          /></label>
                                        </div>

                                        <div>

                                          <label for="radio1" style={{ fontSize: "14px !important" }}>The card fee will be passed on to your customers when they pay online with debit, credit, google pay or apple pay</label>
                                        </div>

                                        {
                                          <div className="row">

                                            {/* <div className="col-sm-12">
                                          <Switch
                                            onChange={(checked) => setvenue_ipay_fee_payer(checked)}
                                            checked={venue_ipay_fee_payer}
                                            className="react-switch qr-page__switch"
                                            height={17}
                                            width={40}
                                          />
                                        </div> */}
                                            <div className="col-sm-3">
                                              <div class="input-group">
                                                <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="venue_fee_on_amount" defaultValue={billingdata.venue_fee_on_amount} />
                                                <div class="input-group-append">
                                                  <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Percent</span>
                                                </div>
                                              </div>
                                              <span className="text-danger">{errors.venue_fee_on_amount && 'This field is requires'}</span>
                                            </div>


                                            <div className="col-sm-3">
                                              <div className="input-group">
                                                <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="venue_fixed_fee" defaultValue={billingdata.venue_fixed_fee} />
                                                <div class="input-group-append">
                                                  <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Amount</span>
                                                </div>
                                              </div>
                                              <span className="text-danger">{errors.venue_fixed_fee && 'This field is requires'}</span>
                                            </div>

                                            <div className="col-sm-3">
                                              <div className="input-group">

                                                <div class="input-group-append">
                                                  <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">(Domestic Card)</span>
                                                </div>
                                              </div>
                                            </div>

                                          </div>
                                        }

                                        {
                                          <div className="row" style={{ marginTop: 20 }}>

                                            <div className="col-sm-3">
                                              <div class="input-group">
                                                <input className="form-control" onWheel={() => document.activeElement.blur()} step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="venue_fee_on_amount_international" defaultValue={billingdata.venue_fee_on_amount_international} />
                                                <div class="input-group-append">
                                                  <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Percent</span>
                                                </div>
                                              </div>
                                              <span className="text-danger">{errors.venue_fee_on_amount_international && 'This field is requires'}</span>
                                            </div>


                                            <div className="col-sm-3">
                                              <div className="input-group">
                                                <input className="form-control" onWheel={() => document.activeElement.blur()} step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="venue_fixed_fee_international" defaultValue={billingdata.venue_fixed_fee_international} />
                                                <div class="input-group-append">
                                                  <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Amount</span>
                                                </div>
                                              </div>
                                              <span className="text-danger">{errors.venue_fixed_fee_international && 'This field is requires'}</span>
                                            </div>

                                            <div className="col-sm-3">
                                              <div className="input-group">
                                                <div class="input-group-append">
                                                  <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">(International Card)</span>
                                                </div>
                                              </div>
                                            </div>

                                          </div>
                                        }

                                      </div>
                                    </>
                                  }


                                  <hr />
                                  {access_level != 1 ? <div className="form-group">
                                    {!loadingbtn ?
                                      <button className="btn btn-primary" type="submit">SAVE</button>
                                      :
                                      <div className="loader-box" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 42, width: 42 }}></span></div>
                                    }
                                  </div> : null}
                                </form>
                                :
                                
                                
                                <div className="loader-box" style={{ width: "100wh" }}>
                                  <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                                    <DisappearedLoading size="medium" color="#514F4E" />
                                  </div>
                                </div>
                                // {/* </div>
                                //     // <h5>Internal error</h5>
                                //     <div style={{justifyContent : 'center', alignItems : 'center'}}>
                                //               <div className="loader-box" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 42, width: 42 }}></span></div>

                                //     </div> */}
                              }

                                {/* </TabPane> */}

                                {/* <TabPane tabId="2">
                                  <div className="table-responsive">
                                    <table className="table table-border-horizontal table-striped table-light table-striped table-light">
                                      <thead>
                                        <tr>
                                          <th scope="col">#</th>

                                          <th scope="col">Date/Time</th>
                                          <th scope="col">Business Name</th>
                                          <th scope="col">Manager</th>
                                          <th scope="col">Landline/Mobile</th>
                                          <th scope="col">Email</th>
                                          <th scope="col">Address</th>
                                          {access_level != 1 && <th scope="col">Action</th>}
                                        </tr>
                                      </thead>
                                      <tbody>
                                      </tbody>
                                    </table>
                                  </div>

                                </TabPane>
                                <TabPane tabId="3">
                                  <button onClick={() => setcredit_earning_modal(true)} className="btn btn-primary" type="button" style={{ marginTop: 20, marginBottom: 20 }}>Credits & Earnings</button>
                                  <div className="table-responsive">
                                    <table className="table table-border-horizontal table-striped table-light table-striped table-light">
                                      <thead>
                                        <tr>
                                          <th scope="col">#</th>
                                          <th scope="col">Date/Time</th>
                                          <th scope="col">Credit Amount</th>
                                          <th scope="col">Reason</th>
                                          <th scope="col">Comment</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {creditdata && creditdata.length > 0 &&
                                          creditdata.map((item, index) => (

                                            <tr key={index + 1}>
                                              <th>{index + 1}</th>
                                              <td>{item.created_date}</td>
                                              <td>{item.amount}</td>
                                              <td>{item.reason}</td>
                                              <td>{item.comment}</td>
                                            </tr>
                                          ))
                                        }
                                      </tbody>
                                    </table>
                                  </div>

                                </TabPane>
                              </TabContent> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="container-fluid" style={{ paddingTop: '0.5rem' }}>
                  <div className="col-sm-12 col-xl-12">
                    <div className="row">

                      <div className="col-xl-12">
                        <div className="card">
                          <div className="card">
                            <div className="card-body">
                              {/* </div>
                    <div className="card-body"> */}
                              {/* <Nav tabs className="nav-pills nav-primary"> */}
                                {/* <NavItem className="nav nav-tabs nav-link" id="myTab" role="tablist">
                                  <NavLink className={activeTab == "1" ? 'active nav-link' : ''} onClick={() => { setactiveTab("1") }} value='1'>
                                    Merchant charges</NavLink>
                                </NavItem> */}


                                {/* <NavItem className="nav nav-tabs nav-link" id="myTab" role="tablist">
                                  <NavLink className={activeTab == "2" ? 'active nav-link' : ''} onClick={() => { setactiveTab("2") }} value='2'>
                                    Discount & Deduction</NavLink>
                                </NavItem>


                                <NavItem className="nav nav-tabs nav-link" id="myTab" role="tablist">
                                  <NavLink className={activeTab == "3" ? 'active nav-link' : ''} onClick={() => { setactiveTab("3") }} value='3'>
                                    Credits & Earnings</NavLink>
                                </NavItem> */}

                              {/* </Nav> */}
                              {/* <TabContent activeTab={activeTab}>
                                <TabPane tabId="1"> */}
                              {billingdatapaypal != null ?
                                <form className="needs-validation theme-form mt-3 billing-form" noValidate="" onSubmit={handleSubmit(onSubmit)}>

                                  <input name="gateway" value="PAYPAL" ref={register({ required: true })} type = "hidden"/>
                                  {/* <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>iPayPro Service Fee/ C2B "Customer To Business"</h5>
                                      <small>When customer pay to a business using iPayPro eWallet</small>
                                      <div className="form-row">

                                        <div className="col-sm-2">
                                          <div className="form-group">
                                            <label className="col-form-label">Minimum Amount</label>
                                            <input className="form-control" step="0.01" min="0" disabled={access_level == 1 ? true : false} type="Number" name="c_to_m_sc_comp_amount" defaultValue={billingdatapaypal.c_to_m_sc_comp_amount} ref={register({ required: true })} />
                                            <span>{errors.c_to_m_sc_comp_amount && 'Minimum Amount is requires'}</span>
                                          </div>
                                        </div>

                                        <div className="col-sm-2">
                                          <div className="form-group">
                                            <label className="col-form-label">Fee Type</label>
                                            <select className="form-control select" name="c_to_m_sc_fee_btype" disabled={access_level == 1 ? true : false} value={c_to_m_sc_fee_btype}
                                              onChange={(e) => setc_to_m_sc_fee_btype(e.target.value)}>
                                              <option value="1">Percent</option>
                                              <option value="2">Amount</option>
                                            </select>
                                            <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                          </div>
                                        </div>
                                        <div className="col-sm-3">
                                          <div className="form-group">
                                            <label className="col-form-label">Fee below min. Amount</label>
                                            <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="c_to_m_sc_fee_below" defaultValue={billingdatapaypal.c_to_m_sc_fee_below} ref={register({ required: true })} />
                                            <span>{errors.c_to_m_sc_fee_below && 'Fee is requires'}</span>
                                          </div>
                                        </div>

                                        <div className="col-sm-2">
                                          <div className="form-group">
                                            <label className="col-form-label">Fee Type</label>
                                            <select className="form-control select" name="c_to_m_sc_fee_atype" disabled={access_level == 1 ? true : false} value={c_to_m_sc_fee_atype}
                                              onChange={(e) => setc_to_m_sc_fee_atype(e.target.value)}>
                                              <option value="1">Percent</option>
                                              <option value="2">Amount</option>
                                            </select>

                                            <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                          </div>
                                        </div>
                                        <div className="col-sm-3">
                                          <div className="form-group">
                                            <label className="col-form-label">Fee above min. Amount</label>
                                            <input className="form-control" step="0.01" min="0" type="Number" name="c_to_m_sc_fee_above" disabled={access_level == 1 ? true : false} ref={register({ required: true })} defaultValue={billingdatapaypal.c_to_m_sc_fee_above} />
                                            <span>{errors.c_to_m_sc_fee_above && 'Fee is required'}</span>
                                          </div>
                                        </div>
                                      </div>

                                      <hr /> */}

                                  <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>Linky Service Fee</h5>
                                  <small>For Payment Link/ Tips/ Linky Meet/ Make a payment button/ Online ordering/ Bookings etc</small>
                                  <div className="form-row">


                                    <div className="col-sm-2">
                                      <div className="form-group">
                                        <label className="col-form-label">Minimum Amount</label>
                                        <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="m_to_m_sc_comp_amount" defaultValue={billingdatapaypal.m_to_m_sc_comp_amount} ref={register({ required: true })} />
                                        <span>{errors.m_to_m_sc_comp_amount && 'Minimum Amount is requires'}</span>
                                      </div>
                                    </div>

                                    <div className="col-sm-2">
                                      <div className="form-group">
                                        <label className="col-form-label">Fee Type</label>
                                        <select className="form-control select" name="m_to_m_sc_fee_btype" disabled={access_level == 1 ? true : false} value={m_to_m_sc_fee_btype}
                                          onChange={(e) => setm_to_m_sc_fee_btype(e.target.value)}>
                                          <option value="1">Percent</option>
                                          <option value="2">Amount</option>
                                        </select>

                                        <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                      </div>
                                    </div>
                                    <div className="col-sm-3">
                                      <div className="form-group">
                                        <label className="col-form-label">Fee below min. Amount</label>
                                        <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="m_to_m_sc_fee_below" defaultValue={billingdatapaypal.m_to_m_sc_fee_below} ref={register({ required: true })} />
                                        <span>{errors.m_to_m_sc_fee_below && 'Fee is requires'}</span>
                                      </div>
                                    </div>

                                    <div className="col-sm-2">
                                      <div className="form-group">
                                        <label className="col-form-label">Fee Type</label>
                                        <select className="form-control select" name="m_to_m_sc_fee_atype" disabled={access_level == 1 ? true : false} value={m_to_m_sc_fee_atype}
                                          onChange={(e) => setm_to_m_sc_fee_atype(e.target.value)}>
                                          <option value="1">Percent</option>
                                          <option value="2">Amount</option>
                                        </select>

                                        <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                      </div>
                                    </div>
                                    <div className="col-sm-3">
                                      <div className="form-group">
                                        <label className="col-form-label">Fee above min. Amount</label>
                                        <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="m_to_m_sc_fee_above" ref={register({ required: true })} defaultValue={billingdatapaypal.m_to_m_sc_fee_above} />
                                        <span>{errors.m_to_m_sc_fee_above && 'Fee is required'}</span>
                                      </div>
                                    </div>
                                  </div>

                                  <hr />
                                  {/* 
                                      <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>iPayPro Service Fee/B2C "Business to Customer"</h5>
                                      <small>When business pay to a customer using iPayPro eWallet</small>
                                      <div className="form-row">


                                        <div className="col-sm-2">
                                          <div className="form-group">
                                            <label className="col-form-label">Minimum Amount</label>
                                            <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="m_to_c_sc_comp_amount" defaultValue={billingdatapaypal.m_to_c_sc_comp_amount} ref={register({ required: true })} />
                                            <span>{errors.m_to_c_sc_comp_amount && 'Minimum Amount is requires'}</span>
                                          </div>
                                        </div>

                                        <div className="col-sm-2">
                                          <div className="form-group">
                                            <label className="col-form-label">Fee Type</label>
                                            <select className="form-control select" name="m_to_c_sc_fee_btype" disabled={access_level == 1 ? true : false} value={m_to_c_sc_fee_btype}
                                              onChange={(e) => setm_to_c_sc_fee_btype(e.target.value)}>
                                              <option value="1">Percent</option>
                                              <option value="2">Amount</option>
                                            </select>

                                            <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                          </div>
                                        </div>
                                        <div className="col-sm-3">
                                          <div className="form-group">
                                            <label className="col-form-label">Fee below min. Amount</label>
                                            <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="m_to_c_sc_fee_below" defaultValue={billingdatapaypal.m_to_c_sc_fee_below} ref={register({ required: true })} />
                                            <span>{errors.m_to_c_sc_fee_below && 'Fee is requires'}</span>
                                          </div>
                                        </div>

                                        <div className="col-sm-2">
                                          <div className="form-group">
                                            <label className="col-form-label">Fee Type</label>
                                            <select className="form-control select" name="m_to_c_sc_fee_atype" disabled={access_level == 1 ? true : false} value={m_to_c_sc_fee_atype}
                                              onChange={(e) => setm_to_c_sc_fee_atype(e.target.value)}>
                                              <option value="1">Percent</option>
                                              <option value="2">Amount</option>
                                            </select>

                                            <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                          </div>
                                        </div>
                                        <div className="col-sm-3">
                                          <div className="form-group">
                                            <label className="col-form-label">Fee above min. Amount</label>
                                            <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="m_to_c_sc_fee_above" ref={register({ required: true })} defaultValue={billingdatapaypal.m_to_c_sc_fee_above} />
                                            <span>{errors.m_to_c_sc_fee_above && 'Fee is required'}</span>
                                          </div>
                                        </div>
                                      </div>

                                      <hr /> */}

                                  <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>Ticketing- Linky Service Fee</h5>
                                  <div className="form-row">


                                    <div className="col-sm-2">
                                      <div className="form-group">
                                        <label className="col-form-label">Minimum Amount</label>
                                        <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="ticket_comp_amount" defaultValue={billingdatapaypal.ticket_comp_amount} ref={register({ required: true })} />
                                        <span>{errors.ticket_comp_amount && 'Minimum Amount is requires'}</span>
                                      </div>
                                    </div>

                                    <div className="col-sm-2">
                                      <div className="form-group">
                                        <label className="col-form-label">Fee Type</label>
                                        <select className="form-control select" name="ticket_fee_btype" disabled={access_level == 1 ? true : false} value={ticket_fee_btype}
                                          onChange={(e) => setticket_fee_btype(e.target.value)}>
                                          <option value="1">Percent</option>
                                          <option value="2">Amount</option>
                                        </select>

                                        <span>{errors.ticket_fee_btype && 'Select Fee type'}</span>

                                      </div>
                                    </div>
                                    <div className="col-sm-2">
                                      <div className="form-group">
                                        <label className="col-form-label">Fee below min. Amount</label>
                                        <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="ticket_fee_below" defaultValue={billingdatapaypal.ticket_fee_below} ref={register({ required: true })} />
                                        <span>{errors.ticket_fee_below && 'Fee is requires'}</span>
                                      </div>
                                    </div>

                                    <div className="col-sm-2">
                                      <div className="form-group">
                                        <label className="col-form-label">Fee Type</label>
                                        <select className="form-control select" name="ticket_fee_atype" disabled={access_level == 1 ? true : false} value={ticket_fee_atype}
                                          onChange={(e) => setticket_fee_atype(e.target.value)}>
                                          <option value="1">Percent</option>
                                          <option value="2">Amount</option>
                                        </select>

                                        <span>{errors.ticket_fee_atype && 'Select Fee type'}</span>

                                      </div>
                                    </div>
                                    <div className="col-sm-2">
                                      <div className="form-group">
                                        <label className="col-form-label">Fee above min. Amount</label>
                                        <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="ticket_fee_above" ref={register({ required: true })} defaultValue={billingdatapaypal.ticket_fee_above} />
                                        <span>{errors.ticket_fee_above && 'Fee is required'}</span>
                                      </div>
                                    </div>
                                    <div className="col-sm-2">
                                      <div className="form-group">
                                        <label className="col-form-label">Fee Per Ticket</label>
                                        <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="fee_per_ticket" ref={register({ required: true })} defaultValue={billingdatapaypal.fee_per_ticket} />
                                        <span>{errors.fee_per_ticket && 'Fee is required'}</span>
                                      </div>
                                    </div>
                                  </div>

                                  <hr />
                                  
                                  
                                  <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>Invoice/Quotes- Linky Service Fee</h5>
                                  <div className="form-row">


                                    <div className="col-sm-2">
                                      <div className="form-group">
                                        <label className="col-form-label">Minimum Amount</label>
                                        <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="bills_comp_amount" defaultValue={billingdatapaypal.bills_comp_amount} ref={register({ required: true })} />
                                        <span>{errors.bills_comp_amount && 'Minimum Amount is requires'}</span>
                                      </div>
                                    </div>

                                    <div className="col-sm-2">
                                      <div className="form-group">
                                        <label className="col-form-label">Fee Type</label>
                                        <select className="form-control select" name="bills_fee_btype" disabled={access_level == 1 ? true : false} value={bills_fee_btype}
                                          onChange={(e) => setbills_fee_btype(e.target.value)}>
                                          <option value="1">Percent</option>
                                          <option value="2">Amount</option>
                                        </select>

                                        <span>{errors.bills_fee_btype && 'Select Fee type'}</span>

                                      </div>
                                    </div>
                                    <div className="col-sm-3">
                                      <div className="form-group">
                                        <label className="col-form-label">Fee below min. Amount</label>
                                        <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="bills_fee_below" defaultValue={billingdatapaypal.bills_fee_below} ref={register({ required: true })} />
                                        <span>{errors.bills_fee_below && 'Fee is requires'}</span>
                                      </div>
                                    </div>

                                    <div className="col-sm-2">
                                      <div className="form-group">
                                        <label className="col-form-label">Fee Type</label>
                                        <select className="form-control select" name="bills_fee_atype" disabled={access_level == 1 ? true : false} value={bills_fee_atype}
                                          onChange={(e) => setbills_fee_atype(e.target.value)}>
                                          <option value="1">Percent</option>
                                          <option value="2">Amount</option>
                                        </select>

                                        <span>{errors.bills_fee_atype && 'Select Fee type'}</span>

                                      </div>
                                    </div>
                                    <div className="col-sm-3">
                                      <div className="form-group">
                                        <label className="col-form-label">Fee above min. Amount</label>
                                        <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="bills_fee_above" ref={register({ required: true })} defaultValue={billingdatapaypal.bills_fee_above} />
                                        <span>{errors.bills_fee_above && 'Fee is required'}</span>
                                      </div>
                                    </div>
                                  </div>

                                  <hr />


                                  <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>Withdrawal Fee</h5>
                                  <small>When Business send request to withdraw their eWallet funds to their bank account</small>
                                  <div className="form-row">

                                    <div className="col-sm-2">
                                      <div className="form-group">
                                        <label className="col-form-label">Minimum Amount</label>
                                        <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="wd_comp_amount" defaultValue={billingdatapaypal.wd_comp_amount} ref={register({ required: true })} />
                                        <span>{errors.wd_comp_amount && 'Minimum Amount is requires'}</span>
                                      </div>
                                    </div>

                                    <div className="col-sm-2">
                                      <div className="form-group">
                                        <label className="col-form-label">Fee Type</label>
                                        <select className="form-control select" name="wd_fee_btype" disabled={access_level == 1 ? true : false} value={wd_fee_btype}
                                          onChange={(e) => setwd_fee_btype(e.target.value)} >
                                          <option value="1">Percent</option>
                                          <option value="2">Amount</option>
                                        </select>
                                        <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                      </div>
                                    </div>

                                    <div className="col-sm-3">

                                      <div className="form-group">
                                        <label className="col-form-label">Fee below to min. Amount</label>
                                        <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="wd_fee_below" defaultValue={billingdatapaypal.wd_fee_below} ref={register({ required: true })} />
                                        <span>{errors.wd_fee_below && 'Fee is requires'}</span>
                                      </div>
                                    </div>

                                    <div className="col-sm-2">
                                      <div className="form-group">
                                        <label className="col-form-label">Fee Type</label>
                                        <select className="form-control select"
                                          onChange={(e) => setwd_fee_atype(e.target.value)} name="wd_fee_atype" disabled={access_level == 1 ? true : false} value={wd_fee_atype}>
                                          <option value="1">Percent</option>
                                          <option value="2">Amount</option>
                                        </select>
                                        <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                      </div>
                                    </div>

                                    <div className="col-sm-3">
                                      <label className="col-form-label">Fee above min. Amount</label>
                                      <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="wd_fee_above" ref={register({ required: true })} defaultValue={billingdatapaypal.wd_fee_above} />
                                      <span>{errors.wd_fee_above && 'Fee is required'}</span>
                                    </div>
                                  </div>

                                  <hr />
                                  <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>Online Fees</h5>
                                  <span>1) For Payment Link/ Invoice/ Quotes/ Tips/ Tickets/ Linky Meet/ Make a payment button/ Online ordering/ Bookings etc</span>

                                  {
                                    <>
                                      <div className="container" style={{ marginLeft: "-2px", marginTop: "16px", marginBottom: 16 }}>

                                        {
                                          <div>
                                            <label for="radio1" style={{ fontSize: "14px !important", fontWeight: "bold", display: "flex", }}><span style={{ marginRight: 10 }}>Pass Online Fees to Customer</span> <Switch
                                              onChange={(checked) => setpl_ipay_fee_payer(checked)}
                                              checked={pl_ipay_fee_payer}
                                              className="react-switch qr-page__switch"
                                              height={17}
                                              width={40}
                                            /></label>
                                          </div>
                                        }

                                        <label style={{ fontSize: "14px !important" }}>Set the online card fees you want merchant to be able to pass on to customers</label>
                                        {
                                          <div style={{ display: 'flex', flexDirection: 'row' }}>

                                            <div className="col-sm-3">
                                              <div class="input-group">
                                                <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="pl_fee_on_amount" defaultValue={billingdatapaypal.pl_fee_on_amount} />
                                                <div class="input-group-append">
                                                  <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Percent</span>
                                                </div>
                                              </div>
                                              <span className="text-danger">{errors.pl_fee_on_amount && 'This field is requires'}</span>
                                            </div>


                                            <div className="col-sm-3">
                                              <div className="input-group">
                                                <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="pl_fixed_fee" defaultValue={billingdatapaypal.pl_fixed_fee} />
                                                <div class="input-group-append">
                                                  <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Amount</span>
                                                </div>
                                              </div>
                                              <span className="text-danger">{errors.pl_fixed_fee && 'This field is requires'}</span>
                                            </div>

                                          </div>
                                        }

                                        {
                                          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>

                                            <div className="col-sm-3">
                                              <div class="input-group">
                                                <input className="form-control" onWheel={() => document.activeElement.blur()} step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="pl_fee_on_amount_international" defaultValue={billingdatapaypal.pl_fee_on_amount_international} />
                                                <div class="input-group-append">
                                                  <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Percent</span>
                                                </div>
                                              </div>
                                              <span className="text-danger">{errors.pl_fee_on_amount_international && 'This field is requires'}</span>
                                            </div>


                                            <div className="col-sm-3">
                                              <div className="input-group">
                                                <input className="form-control" onWheel={() => document.activeElement.blur()} step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="pl_fixed_fee_international" defaultValue={billingdatapaypal.pl_fixed_fee_international} />
                                                <div class="input-group-append">
                                                  <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Amount</span>
                                                </div>

                                              </div>
                                              <span className="text-danger">{errors.pl_fixed_fee_international && 'This field is requires'}</span>
                                            </div>

                                            <div className="col-sm-3">
                                              <div className="input-group">

                                                <div class="input-group-append">
                                                  <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">(International Card)</span>
                                                </div>
                                              </div>
                                              <span className="text-danger">{errors.pl_fixed_fee_international && 'This field is requires'}</span>
                                            </div>

                                          </div>
                                        }



                                      </div>
                                      <span>2) For Online orders</span>
                                      <div className="container" style={{ marginLeft: "-2px", marginTop: "16px" }}>

                                        <div>
                                          <label for="radio1" style={{ fontSize: "14px !important", fontWeight: "bold", display: "flex", }}><span style={{ marginRight: 10 }}>Pass Online Fees to Customer</span> <Switch
                                            onChange={(checked) => setvenue_ipay_fee_payer(checked)}
                                            checked={venue_ipay_fee_payer}
                                            className="react-switch qr-page__switch"
                                            height={17}
                                            width={40}
                                          /></label>
                                        </div>

                                        <div>

                                          <label for="radio1" style={{ fontSize: "14px !important" }}>The card fee will be passed on to your customers when they pay online with debit, credit, google pay or apple pay</label>
                                        </div>

                                        {
                                          <div className="row">

                                            {/* <div className="col-sm-12">
                                          <Switch
                                            onChange={(checked) => setvenue_ipay_fee_payer(checked)}
                                            checked={venue_ipay_fee_payer}
                                            className="react-switch qr-page__switch"
                                            height={17}
                                            width={40}
                                          />
                                        </div> */}
                                            <div className="col-sm-3">
                                              <div class="input-group">
                                                <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="venue_fee_on_amount" defaultValue={billingdatapaypal.venue_fee_on_amount} />
                                                <div class="input-group-append">
                                                  <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Percent</span>
                                                </div>
                                              </div>
                                              <span className="text-danger">{errors.venue_fee_on_amount && 'This field is requires'}</span>
                                            </div>


                                            <div className="col-sm-3">
                                              <div className="input-group">
                                                <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="venue_fixed_fee" defaultValue={billingdatapaypal.venue_fixed_fee} />
                                                <div class="input-group-append">
                                                  <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Amount</span>
                                                </div>
                                              </div>
                                              <span className="text-danger">{errors.venue_fixed_fee && 'This field is requires'}</span>
                                            </div>

                                            <div className="col-sm-3">
                                              <div className="input-group">

                                                <div class="input-group-append">
                                                  <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">(Domestic Card)</span>
                                                </div>
                                              </div>
                                            </div>

                                          </div>
                                        }

                                        {
                                          <div className="row" style={{ marginTop: 20 }}>

                                            <div className="col-sm-3">
                                              <div class="input-group">
                                                <input className="form-control" onWheel={() => document.activeElement.blur()} step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="venue_fee_on_amount_international" defaultValue={billingdatapaypal.venue_fee_on_amount_international} />
                                                <div class="input-group-append">
                                                  <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Percent</span>
                                                </div>
                                              </div>
                                              <span className="text-danger">{errors.venue_fee_on_amount_international && 'This field is requires'}</span>
                                            </div>


                                            <div className="col-sm-3">
                                              <div className="input-group">
                                                <input className="form-control" onWheel={() => document.activeElement.blur()} step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="venue_fixed_fee_international" defaultValue={billingdatapaypal.venue_fixed_fee_international} />
                                                <div class="input-group-append">
                                                  <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Amount</span>
                                                </div>
                                              </div>
                                              <span className="text-danger">{errors.venue_fixed_fee_international && 'This field is requires'}</span>
                                            </div>

                                            <div className="col-sm-3">
                                              <div className="input-group">
                                                <div class="input-group-append">
                                                  <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">(International Card)</span>
                                                </div>
                                              </div>
                                            </div>

                                          </div>
                                        }

                                      </div>
                                    </>
                                  }


                                  <hr />
                                  {access_level != 1 ? <div className="form-group">
                                    {!loadingbtn ?
                                      <button className="btn btn-primary" type="submit">SAVE</button>
                                      :
                                      <div className="loader-box" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 42, width: 42 }}></span></div>
                                    }
                                  </div> : null}
                                </form>
                                :
                                
                                
                                <div className="loader-box" style={{ width: "100wh" }}>
                                  <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                                    <DisappearedLoading size="medium" color="#514F4E" />
                                  </div>
                                </div>
                                // {/* </div>
                                //     // <h5>Internal error</h5>
                                //     <div style={{justifyContent : 'center', alignItems : 'center'}}>
                                //               <div className="loader-box" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 42, width: 42 }}></span></div>

                                //     </div> */}
                              }

                                {/* </TabPane> */}

                                {/* <TabPane tabId="2">
                                  <div className="table-responsive">
                                    <table className="table table-border-horizontal table-striped table-light table-striped table-light">
                                      <thead>
                                        <tr>
                                          <th scope="col">#</th>

                                          <th scope="col">Date/Time</th>
                                          <th scope="col">Business Name</th>
                                          <th scope="col">Manager</th>
                                          <th scope="col">Landline/Mobile</th>
                                          <th scope="col">Email</th>
                                          <th scope="col">Address</th>
                                          {access_level != 1 && <th scope="col">Action</th>}
                                        </tr>
                                      </thead>
                                      <tbody>
                                      </tbody>
                                    </table>
                                  </div>

                                </TabPane>
                                <TabPane tabId="3">
                                  <button onClick={() => setcredit_earning_modal(true)} className="btn btn-primary" type="button" style={{ marginTop: 20, marginBottom: 20 }}>Credits & Earnings</button>
                                  <div className="table-responsive">
                                    <table className="table table-border-horizontal table-striped table-light table-striped table-light">
                                      <thead>
                                        <tr>
                                          <th scope="col">#</th>
                                          <th scope="col">Date/Time</th>
                                          <th scope="col">Credit Amount</th>
                                          <th scope="col">Reason</th>
                                          <th scope="col">Comment</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {creditdata && creditdata.length > 0 &&
                                          creditdata.map((item, index) => (

                                            <tr key={index + 1}>
                                              <th>{index + 1}</th>
                                              <td>{item.created_date}</td>
                                              <td>{item.amount}</td>
                                              <td>{item.reason}</td>
                                              <td>{item.comment}</td>
                                            </tr>
                                          ))
                                        }
                                      </tbody>
                                    </table>
                                  </div>

                                </TabPane>
                              </TabContent> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
              <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12 col-xl-12 xl-100" style={{ padding: "0" }}>
                      <div className="card">
                      <div className="card-body" style={{ padding: "1rem" }}>
                      {billingdata != null ?
                      <form className="needs-validation theme-form mt-3 billing-form" noValidate="" onSubmit={handleSubmit(onSubmit)}>

<input name="gateway" value="STRIPE" type = "hidden" ref={register({ required: true })}/>

<h5 style={{ fontSize: "1rem", marginBottom: 0 }}>Function Booking Feature</h5>

<div className="form-row  p-2" style={{gap:'12px'}}>


<div className="">
  <div className="form-group">
    <label className="col-form-label">Minimum Spent (in %)</label>
    <input className="form-control" step="0.01" min="0" disabled={access_level == 1 ? true : false} type="Number" name="m_to_m_func_booking_spent" 
    defaultValue={billingdata.m_to_m_func_booking_spent}
     ref={register({ required: true })} />
    <span>{errors.m_to_m_func_booking_spent && 'Minimum Amount is requires'}</span>
  </div>
</div>
<div className="">
  
  <div className="form-group">
    <label className="col-form-label">Venue hire deposit fees (in %)</label>
    <input className="form-control" step="0.01" min="0" disabled={access_level == 1 ? true : false} type="Number" name="m_to_m_func_venue_fees" 
    defaultValue={billingdata.m_to_m_func_venue_fees}

     ref={register({ required: true })} />
    <span>{errors.m_to_m_func_veneu_fees && 'Minimum Amount is requires'}</span>
  </div>
</div>
</div>

<hr />
                                  {access_level != 1 ? <div className="form-group">
                                    {!loadingbtn ?
                                      <button className="btn btn-primary" type="submit">SAVE</button>
                                      :
                                      <div className="loader-box" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 42, width: 42 }}></span></div>
                                    }
                                  </div> : null}


</form>:

 
<div className="loader-box" style={{ width: "100wh" }}>
<div className="loader" style={{ display: "flex", alignItems: "center" }}>
  <DisappearedLoading size="medium" color="#514F4E" />
</div>
</div>
}

                        </div>
                        
                         </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="container-fluid" style={{ paddingTop: '0.5rem' }}>
                  <MerchantSubscriptions
                    merchant_info={props.merchant_info}
                    merchant_id={props.merchant_id}
                    subscriptions={props.subscriptions}
                    invoices={props.invoices}
                    wlmSubscription={props.wlm_Subscriptions}
                    userCompanyList={props.userCompanyList}
                    userStorelist={props.userStorelist}
                  />
                </div>
              </TabPanel>
              <TabPanel>
                <div className="container-fluid" style={{ paddingTop: '0.5rem' }}>
                  <MerchantInvoices merchant_info={props.merchant_info} merchant_id={props.merchant_id} invoices={props.invoices} />
                </div>
              </TabPanel>
              
            </div>
          </Tabs>
        </div>
      </div>
      {access_level != 1 && <div className="card-body ">
        <Add_creadit_earning merchant_id={props.merchant_id} credit_earning_modal={credit_earning_modal} setcredit_earning_modal={setcredit_earning_modal} getbilling_settings={() => getbilling_settings()} />
        {/* credit and earning modal */}

      </div>}
    </Fragment>
  );
}

export default BorderTable;
