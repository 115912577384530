import React, { Component, useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormGroup, Col, Row, Input, CustomInput } from 'reactstrap';
import useForm  from "react-hook-form";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import {  toast } from "react-toastify";
import 'react-bootstrap-typeahead/css/Typeahead.css';


let AddGiftVouchersModal = (props) => {
    
    let [loadingbtn, setloadingbtn] = useState(false);

    const { handleSubmit, register, errors } = useForm();


    const onSubmit = form_data => {
        
        if (form_data !== '') {

            form_data.offer_id = props.selectedOffer._id;

            setloadingbtn(true);

            client.post(api.edit_gift_vouchers, form_data, async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                props.fetch_giftvouchers();
                                props.toggleModal();
                                toast.success("Upadted Successfully");
                                // setcashbackdata(response.result.data);
                            } else {
                                toast.error(response.message);
                            }
                        } catch (e) {
                            toast.error("Internal error occured. Please contact support");
                        }
                    } else {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
                
                setloadingbtn(false);
            });
        }
        else {
            errors.showMessages();
        }
    }

    return (

        <Modal isOpen={props.isOpen} toggle={props.toggleModal} size="lg" className="modal-body" centered={true}>
            <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={props.toggleModal}>Edit Gift Voucher</ModalHeader>
                <ModalBody>
                    <ModalBody style={{ padding: "16px 0" }}>
                        <Row form>
                            <Col md={12}>
                                <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="level_title">Offer title
                                            </Label>
                                            <input className="form-control" defaultValue = {props.selectedOffer.offer_title} type="text" name="offer_title"  ref={register({ required: true })} />
                                            <span>{errors.offer_title && 'Offer title is required'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>        
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="to_points">Redeemable At</Label>
                                            <input className="form-control" defaultValue = {props.selectedOffer.redeem_at_point} type="number" name="redeem_at_point" ref={register({ required: true })} />
                                            <span>{errors.redeem_at_point && 'Redeemable At is required'}</span>
                                        </FormGroup>
                                    </Col>     
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="to_points">Cashback</Label>
                                            <input className="form-control" defaultValue = {props.selectedOffer.cashback} type="number" name="cashback" ref={register({ required: true })} />
                                            <span>{errors.cashback && 'Cashback is required'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                
                                {/* <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="level_title">Offer Description</Label>
                                            <input className="form-control" defaultValue = {props.selectedOffer.offer_description}  type="text" name="offer_description" ref={register({ required: true })} />
                                            <span>{errors.offer_description && 'Offer Description is required'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row> */}
                                
                                <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="level_title">Offer Terms & Conditions</Label>
                                            <textarea defaultValue = {props.selectedOffer.term_and_conditions} className="form-control" rows="10" name="term_and_conditions" ref={register({ required: true })} ></textarea>
                                                <span style={{color: '#ff5370'}}>{errors.term_and_conditions && 'Offer Terms & Conditions is required'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {!loadingbtn ?
                            <>
                                <Button color="default" type="button" onClick={props.toggleModal}>Cancel</Button>
                                <Button color="primary" type="submit" >Save</Button>
                            </>
                            :
                            <div className="loader-box pull-right" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 20, width: 20 }}></span></div>
                        }
                    </ModalFooter>
                </ModalBody>
            </form>
        </Modal>
    );
}

export default AddGiftVouchersModal;