import React, { useState, useEffect } from 'react'
import { Edit } from 'react-feather';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Addprinters } from './Addprinters';
import { Trash2 } from 'react-feather';
import PrintersTab from './PrintersTab.jsx'


export const POStab = ({ merchant, printers, registers }) => {

    return (
        <div className="container-fluid" style={{ paddingBottom: 20 }}>
            <div className="row theme-tab" style={{ marginBottom: '0px !important' }} >
                <Tabs className="col-sm-12" defaultIndex={0} style={{ padding: "0" }}>
                    <TabList className="tabs tab-title" style={{ marginBottom: '0px !important' }}>
                        <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Orders </Tab>
                        <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Printer </Tab>
                        {/* <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Products </Tab>
                        <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Transactions </Tab>
                        <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Analytics </Tab>
                        <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Subscription </Tab> */}
                    </TabList>
                    <div className="tab-content-cls">
                        <TabPanel> <div className="container" style={{ paddingTop: '0.5rem' }} > Orders Setting </div></TabPanel>
                        <TabPanel>
                            <div className="container-fluid" style={{ paddingTop: '0.5rem' }} >
                                <PrintersTab
                                    merchant={merchant}
                                    printers={printers}
                                    registers={registers}
                                />
                            </div></TabPanel>
                        {/* <TabPanel> <div className="container" style={{ paddingTop: '0.5rem' }} > Products Setting </div></TabPanel>
                        <TabPanel> <div className="container" style={{ paddingTop: '0.5rem' }} > Transactions Setting </div></TabPanel>
                        <TabPanel> <div className="container" style={{ paddingTop: '0.5rem' }} >  Analytics Setting </div></TabPanel>
                        <TabPanel> <div className="container" style={{ paddingTop: '0.5rem' }} >Subscription  </div></TabPanel> */}

                    </div>
                </Tabs>
            </div>
        </div>
    )
}
