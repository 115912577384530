import React, { Fragment, useEffect, useState } from 'react';
import { ToastContainer, toast } from "react-toastify";
import { Link as NavLink } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem, NavLink as NavLinkTab, Col, Row, Table, Badge } from 'reactstrap';
import PaginationComponent from "react-reactstrap-pagination";
import moment from "moment-timezone";
import axios from "axios";
import FileSaver from 'file-saver';
import ReactDatePicker from 'react-datepicker';
import { DisappearedLoading } from "react-loadingg";
import Icon from "@mdi/react";
import { mdiContentCopy, mdiTrashCanOutline, mdiEye, mdiDownloadOutline } from '@mdi/js';
import CloseIcon from "mdi-react/CloseIcon";
import { Link } from "react-router-dom";
import Breadcrumb from '../../components/common/breadcrumb';
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import HosposafeShareReport from "../../components/forms/hosposafeShareReport";
import RenderReactTooltip from './ReactTooltip';
import ViewTransaction from './ViewTransaction';
import Swal from "sweetalert2";
import fileDownload from "js-file-download";

const MerchantBills = (props) => {
    // console.log(props,'props')
    const [activeTab, setActiveTab] = useState('1');
    const [invoicesData, setInvoicesData] = useState(null);
    const [quotesData, setQuotesData] = useState(null);
    const [searchValue, setSearchValue] = useState(null);
    const [activePage, setActivePage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const [tableData, setTableData] = useState([]);
    const [searchedData, setSearchedData] = useState(null);
    const [merchantInfo, setMerchantInfo] = useState(props.merchant_info);
    const [mailModal, setMailModal] = useState(false);
    const [selectedBill, setSelectedBill] = useState(null);
    const [loadingmail, setLoadingMail] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [accessLevel, setAccessLevel] = useState(localStorage.getItem("accessLevel"));
    const [billDate, setBillDate] = useState(new Date());
    const [billDueDate, setBillDueDate] = useState(new Date());
    const [addItemModal, setAddItemModal] = useState(false);
    const [showInvoice, setshowInvoice] = useState(false)
    const [billType, setBillType] = useState(null)
    const [selectedInvoice, setselectedInvoice] = useState(null)
    const [newItem, setNewItem] = useState({
        description: null,
        quantity: null,
        price: null,
        discount_type: "1",
        discount: null
    });



    const toggleInvoice = () => setshowInvoice(!showInvoice)



    useEffect(() => {
        fetchAllBills();

    }, []);

    useEffect(() => {
        if (selectedBill) {
            setBillType(selectedBill.bill_type.toString())
            setBillDate(new Date(selectedBill.dated));
            setBillDueDate(new Date(selectedBill.due));
        }
    }, [selectedBill, editModal])

    useEffect(() => {
        const postData = { merchant_id: props.merchant_id }
        client.post(api.fetch_bills, postData, (error, response) => {
            if (!error) {
                if (typeof response.error !== "undefined") {

                    if (!response.error) {
                        console.log(response);
                        setInvoicesData(response.result.invoices);
                        setQuotesData(response.result.quotes);
                    } else {
                        console.log(response);
                    }

                } else {
                    console.log(response);
                }
            } else {
                console.log(response);
            }

        });
        if (activeTab == "1") {
            setTableData(invoicesData)

        } else {
            setTableData(quotesData)
        }
    }, [activeTab]);

    const onTabChange = (tabIndex) => {
        setActiveTab(tabIndex);
        setActivePage(1);
        setPageSize(15);
    }

    const fetchAllBills = () => {
        const postdata = { merchant_id: props.merchant_id }
        client.post(api.merchant_fetchbills, postdata, (error, response) => {
            if (!error) {
                if (!response.error) {
                    console.log(response);
                    setInvoicesData(response.result.invoices.reverse());
                    setQuotesData(response.result.quotes.reverse());
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        })
    }

    const renderInvoiceModal = (data) => {

        return (
            <Modal isOpen={showInvoice} toggle={toggleInvoice} className="modal-body" size={'lg'} centered={true}>
                <ModalHeader>Invoice No.: {selectedInvoice.bill_number}  </ModalHeader>
                <ModalBody>
                    <div style={{ fontSize: '0.75rem' }}>
                        <Row className={'border-bottom'}>
                            <Col md="2" className="py-1 px-3"> <b>Invoice No.</b> </Col>
                            <Col className="py-1 px-1" md="10"> {selectedInvoice.bill_number}  </Col>
                        </Row>
                        <Row className={'border-bottom'}>
                            <Col className="py-1 px-3" md="2"><b>Name</b> </Col>
                            <Col className="py-1 px-1" md="10">{selectedInvoice.customer.name}</Col>
                        </Row>
                        <Row className={'border-bottom'}>
                            <Col className="py-1 px-3" md="2"> <b>Created Date</b> </Col>
                            <Col className="py-1 px-1" md="2">{moment.tz(selectedInvoice.dated, "Australia/Sydney").format("DD/MM/YYYY")}  </Col>
                            <Col className="py-1 px-1" md={{ size: 2, offset: 2 }}>  </Col>
                            <Col className="py-1 px-3" md="2"> <b>Due Date</b> </Col>
                            <Col className="py-1 px-1" md="1">{moment.tz(selectedInvoice.due, "Australia/Sydney").format("DD/MM/YYYY")}  </Col>
                        </Row>
                        <Row className={'border-bottom'}>
                            <Col className="py-1 px-3" md="2"> <b>Status</b> </Col>
                            <Col className="py-1 px-1" md="10">
                                {/* {selectedInvoice.status} */}
                                {selectedInvoice.status == "0" ? <i class="text-muted">Created</i> : selectedInvoice.status == "1" ? <i class="text-primary">Accepted</i> : selectedInvoice.status == "2" ? <i class="text-danger">Rejected</i> : selectedInvoice.status == "3" ? <i class="text-success">Paid</i> : selectedInvoice.status == "4" ? <i class="text-secondary">Unpaid</i> : null}
                            </Col>
                        </Row>
                        <Row className={'border-bottom'} >
                            <Col className="py-1 px-3" md="2"> <b>Tax</b> </Col>
                            <Col className="py-1 px-1" md="10">{selectedInvoice.total_tax}</Col>
                        </Row>

                        <Row>
                            <Col className="m-0 py-1">
                                <table className="table table-border-horizontal">
                                    <thead>
                                        <tr style={{ background: '#f9fafe' }}>
                                            <th style={{ fontSize: '0.75rem' }}>#</th>
                                            <th style={{ fontSize: '0.75rem' }}>Item</th>
                                            <th style={{ fontSize: '0.75rem' }}>Quantity</th>
                                            <th style={{ fontSize: '0.75rem' }}>Price</th>
                                            <th style={{ fontSize: '0.75rem' }}>Item Amount</th>
                                            <th style={{ fontSize: '0.75rem' }}>Discounts</th>
                                            <th style={{ fontSize: '0.75rem' }}>Tax</th>
                                            <th style={{ fontSize: '0.75rem' }}>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedInvoice.items.map((v, i) => {
                                            return <tr>
                                                <td style={{ fontSize: '0.75rem' }}>{i + 1}</td>
                                                <td style={{ fontSize: '0.75rem' }}>{v.description}</td>
                                                <td style={{ fontSize: '0.75rem' }}>{v.quantity}</td>
                                                <td style={{ fontSize: '0.75rem' }}>{v.price}</td>
                                                <td style={{ fontSize: '0.75rem' }}>{v.item_amount}</td>
                                                <td style={{ fontSize: '0.75rem' }}>{v.discount_amount}  </td>
                                                <td style={{ fontSize: '0.75rem' }}>{v.tax_amount}  {v.tax}  </td>
                                                <td style={{ fontSize: '0.75rem' }}>{v.final_amount}</td>
                                            </tr>
                                        })
                                        }
                                    </tbody>
                                </table>
                            </Col>
                        </Row>

                        <Row className={'border-bottom border-top'}>
                            <Col className="py-1 px-3" md="10" style={{ textAlign: 'right' }}> <b>subtotal</b> </Col>
                            <Col className="py-1 px-1" md="2">  {selectedInvoice.subtotal} subtotal  </Col>
                        </Row>
                        <Row className={'border-bottom'}>
                            <Col className="py-1 px-3" md="10" style={{ textAlign: 'right' }}> <b>discount</b> </Col>
                            <Col className="py-1 px-1" md="2">  {selectedInvoice.discount}  </Col>
                        </Row>
                        <Row className={'border-bottom'}>
                            <Col className="py-1 px-3" md="10" style={{ textAlign: 'right' }}> <b> tax </b> </Col>
                            <Col className="py-1 px-1" md="2">  {selectedInvoice.total_tax} </Col>
                        </Row>
                        <Row className={'border-bottom'}>
                            <Col className="py-1 px-3" md="10" style={{ textAlign: 'right' }}> <b> Total </b> </Col>
                            <Col className="py-1 px-1" md="2">  {selectedInvoice.bill_amount}  </Col>
                        </Row>
                    </div>
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </Modal>
        );
    }

    const createAndShareInvoicePdf = (mailData) => {

        const data = {
            email: mailData.share_to_email,
            subject: mailData.share_to_subject,
            pdfType: activeTab == "1" ? "invoice" : "quotes",
            merchantDetails: merchantInfo,
            invoiceDetails: selectedBill
        }
        console.log(data);
        client.post(api.createInvoicePdf, data, (error, res) => {
            if (!error) {
                client.post(api.shareInvoicePdf, data, (error, response) => {
                    if (!error) {
                        if (!response.error) {
                            setMailModal(false);
                            toast.success(response.message);
                        } else {
                            setTimeout(() => {
                                toast.error(response.message);
                            }, 200);
                        }

                    } else {
                        setTimeout(() => {
                            toast.error("Internal error occured. Please contact support");
                        }, 200);
                    }
                })
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        })

    }


    const createAndDownloadPdf = (invoiceSelected) => {
        const data = {
            merchantDetails: merchantInfo,
            invoiceDetails: invoiceSelected,
            pdfType: activeTab == "1" ? "invoice" : "quotes",
        }

        client.post(api.createInvoicePdf, data, (error, response) => {
            if (!error) {
                if (!response.error) {
                    axios.get(client.url("backendapi/pdf/downloadPdf"), {
                        responseType: 'arraybuffer',
                        //responseType: "blob",
                        headers: {
                            Accept: 'application/pdf',
                            "x-access-token": "ipay-access-token"
                        },
                    }).then(response => {
                        console.log(response);
                        //Create a Blob from the PDF Stream
                        const file = new Blob([response.data], {
                            type: "application/pdf"
                        });
                        console.log(file)
                        //Build a URL from the file
                        const fileURL = URL.createObjectURL(file);
                        //Open the URL on new Window
                        window.open(fileURL);
                        /* FileSaver.saveAs(
                            new Blob([response.data], { type: 'application/pdf' }),
                            `sample.pdf`
                          ); */
                    }).catch(error => {
                        console.log(error);
                        setTimeout(() => {
                            toast.error("Error while viewing bills.");
                        }, 200);
                    })
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        })

    }

    /* const viewInvoiceClick = (invoice) => {
        setInvoiceSelected(invoice)
    } */

    const onSearchChangeHandler = (event) => {
        setSearchValue(event.target.value)

    }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)

    }

    const render_date = (add_date) => {
        if (typeof (add_date) === "undefined") {
            return;
        }
        const convert_date = new Date(add_date)
        const added_date = moment.tz(convert_date, "Australia/Sydney").format("DD/MM/YYYY HH:mm:ss");
        return added_date;
    }


    const toggleMailModal = (item) => {
        setSelectedBill(item);
        setMailModal(!mailModal);
    }

    const renderMailModal = () => {

        return (
            <Modal isOpen={mailModal} toggle={toggleMailModal} className="modal-body" centered={true}>
                <HosposafeShareReport loadingmail={loadingmail} toggleMailModal={toggleMailModal} createAndDownloadPdf={createAndShareInvoicePdf} />
            </Modal>
        );
    }

    const deleteClick = (bill) => {
        setSelectedBill(bill);
        setDeleteModal(!deleteModal)
    }

    const toggleDeleteModal = () => {
        setDeleteModal(!deleteModal)
    }

    const onDeleteBill = (event) => {
        event.preventDefault();
        const postData = { bill_id: selectedBill._id, bill_type: activeTab }
        client.post(api.merchant_deletebill, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    toast.success(response.message)
                    fetchAllBills();
                    setDeleteModal(false)

                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        })
    }

    const renderDeleteModal = () => {
        return (
            <Modal isOpen={deleteModal} toggle={toggleDeleteModal} className="modal-body" centered={true}>
                <form className="theme-form" onSubmit={onDeleteBill} >
                    <ModalHeader toggle={toggleDeleteModal}>Delete bill</ModalHeader>
                    <ModalBody>
                        <h5>Do you really want to delete this bill record?</h5>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggleDeleteModal}>No</Button>
                        <Button color="primary" type="submit" >Yes</Button>
                    </ModalFooter>
                </form>
            </Modal>

        );
    }

    const toggleEditModal = (item) => {
        setEditModal(!editModal);
    }

    const editClick = (item) => {
        setSelectedBill(item);
        setEditModal(!editModal);
    }
    const renderItemsTable = (items) => {

        return (
            <div className="table-responsive">
                <table className="table table-border-horizontal">
                    <thead>
                        <tr style={{ background: '#f9fafe' }}>
                            <th scope="col" >#</th>
                            <th scope="col" >Name</th>
                            <th scope="col" >Unit Price</th>
                            <th scope="col" >Qty</th>
                            <th scope="col" >Discount</th>
                            <th scope="col" >Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((v, i) => items[items.length - (i + 1)]).map((item, index) => {
                            return (
                                <tr >
                                    <th scope="row" >{index + 1}</th>
                                    <td >{item.description}</td>
                                    <td >${item.price}</td>
                                    <td >{item.quantity}</td>
                                    <td >{item.discount_type == "1" ? <span>{item.discount}%</span> : <span>${item.discount}</span>}</td>
                                    <td >${item.final_amount.toFixed(2)}</td>
                                </tr>

                            );
                        })}
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th>Subtotal</th>
                            <th>${selectedBill.subtotal.toFixed(2)}</th>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th>Discount</th>
                            <th>${selectedBill.discount.toFixed(2)}</th>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th>Total</th>
                            <th>${selectedBill.bill_amount.toFixed(2)}</th>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    const toggleAddItemModal = () => {
        setAddItemModal(!addItemModal);

    }

    const onAddItemChange = (event) => {
        const { name, value } = event.target;
        setNewItem(prevState => {
            return {
                ...prevState,
                [name]: value
            }
        })
    }

    const onAddItemToBill = (event) => {
        event.preventDefault();
        console.log(newItem);
        const itemDiscountAmount = newItem.discount_type == 1 ? ((parseInt(newItem.quantity)) * (parseFloat(newItem.price))) * (parseInt(newItem.discount) / 100) : parseInt(newItem.discount);
        const itemAmount = parseFloat(newItem.quantity) * parseFloat(newItem.price);
        const modifiedNewItem = {
            ...newItem,
            item_amount: itemAmount,
            discount_amount: itemDiscountAmount,
            final_amount: itemAmount - itemDiscountAmount,
            price: parseFloat(newItem.price),
            quantity: parseInt(newItem.price),
            discount: parseInt(newItem.discount),
            discount_type: parseInt(newItem.discount_type),
        }
        const modifiedSelectedBill = {
            ...selectedBill,
            bill_amount: selectedBill.bill_amount + modifiedNewItem.final_amount,
            discount: selectedBill.discount + itemDiscountAmount,
            subtotal: selectedBill.subtotal + modifiedNewItem.item_amount,
            items: [...selectedBill.items, modifiedNewItem]
        };
        console.log(modifiedSelectedBill);
        setSelectedBill(modifiedSelectedBill);
        setAddItemModal(false);
        setNewItem({ description: null, quantity: null, price: null, discount_type: "1", discount: null })

    }

    const renderAddItemModal = () => {
        return (
            <Modal isOpen={addItemModal} toggle={toggleAddItemModal} className="modal-body" centered={true}>
                <form className="theme-form" onSubmit={onAddItemToBill}>
                    <ModalHeader toggle={toggleAddItemModal} >Add an item</ModalHeader>
                    <ModalBody >
                        <div className="row">
                            <div className="col">
                                <div className="form-group col-l-6 mb-3">
                                    <label>Item description</label>
                                    <input className="form-control" type="text" onChange={onAddItemChange}
                                        placeholder="Item description" name="description" autoComplete="off" value={newItem.description} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="form-group col-l-6 mb-3">
                                    <label>Quantity</label>
                                    <input className="form-control" type="number" onChange={onAddItemChange}
                                        placeholder="Quantity" name="quantity" autoComplete="off" value={newItem.quantity} />
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group col-l-6 mb-3">
                                    <label>Unit price</label>
                                    <input className="form-control" type="text" onChange={onAddItemChange}
                                        placeholder="Unit price" name="price" autoComplete="off" value={newItem.price} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="form-group col-l-6 mb-3">
                                    <label>Discount type</label>
                                    <select className="form-control " name="discount_type" onChange={onAddItemChange} value={newItem.discount_type}>
                                        <option value="1">Percent</option>
                                        <option value="2">Amount</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group col-l-6 mb-3">
                                    <label>Discount</label>
                                    <input className="form-control" type="text" onChange={onAddItemChange}
                                        placeholder="Discount" name="discount" autoComplete="off" value={newItem.discount} />
                                </div>
                            </div>
                        </div>
                        <h6 style={{ margin: "0 auto" }}>Amount : $90</h6>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggleAddItemModal}>No</Button>
                        <Button color="primary" type="submit"  >Yes</Button>
                    </ModalFooter>
                </form>
            </Modal>
        )
    }

    const onChangeBill = (event) => {
        const { name, value } = event.target;
        console.log(value);
        if (name == "bill_type") {
            setBillType(value)
        } else {
            setSelectedBill(prevState => {
                return {
                    ...prevState,
                    [name]: value
                }
            });
        }
    }

    const onEditBillSubmit = (event) => {
        event.preventDefault();

        const postdata = {
            ...selectedBill,
            dated: moment(billDate).toISOString(),
            due: moment(billDueDate).toISOString(),
            bill_type: +billType,
            bill: activeTab
        }

        client.post(api.merchant_editBill, postdata, (error, response) => {
            if (!error) {
                //console.log(response);
                if (!response.error) {
                    toast.success(response.message)
                    fetchAllBills();
                    setEditModal(false);
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        })

    }


    const renderEditModal = () => {

        return (
            <Modal isOpen={editModal} toggle={toggleEditModal} className="modal-body" centered={true} scrollable={true} >
                <form className="theme-form" onSubmit={onEditBillSubmit}>
                    <ModalHeader toggle={toggleEditModal}>Edit {activeTab == 1 ? "Invoice" : "Quotes"}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col">
                                <div className="form-group col-l-6 mb-3">
                                    <label>For</label>
                                    <input className="form-control" type="text" onChange={onChangeBill}
                                        placeholder="For" name="bill_for" autoComplete="off" value={selectedBill.bill_for} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="form-group col-l-6 mb-3">
                                    <label>Bill date</label>
                                    <ReactDatePicker
                                        dateFormat="dd/MM/yyyy"
                                        selected={billDate}
                                        onChange={date => setBillDate(date)}
                                        minDate={new Date()}
                                        showDisabledMonthNavigation
                                    />
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group col-l-6 mb-3">
                                    <label>Due Date</label>
                                    <ReactDatePicker
                                        dateFormat="dd/MM/yyyy"
                                        selected={billDueDate}
                                        onChange={date => setBillDueDate(date)}
                                        minDate={new Date()}
                                        showDisabledMonthNavigation
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="form-group col-l-6 mb-3">
                                    <label>Bill number</label>
                                    <input className="form-control" type="text"
                                        placeholder="Bill number" name="bill_number" autoComplete="off" value={selectedBill.bill_number} />
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group col-l-6 mb-3">
                                    <label>Tax</label>
                                    <select className="form-control " name="bill_type" onChange={onChangeBill} value={billType}>
                                        <option value="1">Tax inclusive</option>
                                        <option value="2">Tax exclusive</option>
                                        <option value="3">No tax</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <p className="font-weight-bold">ITEMS</p>
                        {renderItemsTable(selectedBill.items)}
                        <p className="btn btn-xs btn-primary pull-right mt-1 mb-2" onClick={toggleAddItemModal}>Add an item</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggleEditModal}>No</Button>
                        <Button color="primary" type="submit" >Proceed</Button>
                    </ModalFooter>
                </form>
            </Modal>

        );
    }


    const hndleViewInvoice = (data) => {
        toggleInvoice();
        setselectedInvoice(data);
    }

    console.log(selectedInvoice)

    const [copyTextToolTip, setCopyTextToolTip] = useState(false);
    const [getTooltip, setGetToolTip] = useState();
    const [viewTransaction, setViewTransaction] = useState(false);
    const [transactionData, setTransactionData] = useState({
        merchant_id: props.merchant_id,
        wallet_amount: '',
        data: null,
        view_type: 0,
        disabled_refund: '',
    })
    const [modal, setmodal] = useState(false);
    const [invc, setinvc] = useState("");
    const [loadingBtn, setLoadingBtn] = useState(false);
    const getLinkDomain = (type = 1) => {
        const host = client.url("");
        let api_domain = host.replace(/(https?:\/\/)?(www.)?/i, '');
        if (api_domain.indexOf('/') !== -1) {
            api_domain = api_domain.split('/')[0];
        }

        if (type == 2) {
            let hostName = window?.location?.hostname.replace("dm.portal.", "");
            hostName = hostName.replace("portal.", "");

            if (api_domain == "dmapi.ipaypro.co") {

                return `https://dm.${hostName}`;
            } else {
                return `https://${hostName}`;
            }

        } else {

            if (api_domain == "dmapi.ipaypro.co") {
                return "https://demo.payments.linky.biz";
            } else {
                return "https://payments.linky.biz";
            }

        }

    }
    const getTimeZone = () => {

        try {
            const company = JSON.parse(localStorage.getItem('@_company'));

            if (company && company.timeZone) {
                return company.timeZone;
            } else {
                return "Australia/Sydney";
            }
        } catch {
            return "Australia/Sydney";
        }

    }

    const copyTextToolTipToggle = () => {
        setCopyTextToolTip(!copyTextToolTip);
    };

    const onDeleteInvoice = (bill_id) => {
        setinvc(bill_id);
        setmodal(!modal);
    };

    const onDelete = () => {
        if (invc) {
            let postdata = { merchant_id: props.merchant_id, bill_id: invc, bill_type: activeTab == 1 ? 1 : 2 };
            let message = "Internal error occured. Please contact support";
            let status = 0;
            setLoadingBtn(true);
            client.post(api.delete_bills, postdata, async function (error, response) {
                if (!error) {
                    // alert(JSON.stringify(response));
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                if (activeTab == 1) {
                                    status = 1;
                                    setInvoicesData(response.result.invoices);
                                    toast.success('Invoice deleted successfully')
                                } else if (activeTab == 2) {
                                    status = 1;
                                    setQuotesData(response.result.quotes);
                                    toast.success('Quotes deleted successfully')
                                }


                            } else {
                                status = 0;
                                message = response.message;
                            }
                        } catch (e) { }
                    }
                }

                setmodal(false);
                // swal.close();
                // swal(message);
                // if (status == 1) {
                // 	props.onFetchQuickBills(props.merchant._id);
                // }
            });
        }
    };

    const renderTable = (billdata) => {
        // console.log(billdata,'billdata')
        let data = null;
        if (searchedData != null) {
            data = searchedData
        } else {
            data = billdata
        }

        return (
            <Fragment>
                <div className="table-responsive" >
                    <Table className="table table-border-horizontal" >
                        <thead>
                            <tr style={{ background: '#f9fafe' }}>
                                <th scope="col" >#</th>
                                <th scope="col" >Created on</th>
                                <th scope="col" >{activeTab == '1' ? 'Invoice No.' : 'Quotes No.'}</th>
                                <th scope="col" >Link</th>
                                <th scope="col" >Description</th>
                                <th scope="col" >Due Date</th>
                                <th scope="col" >Online Fees</th>
                                <th scope="col" >Service Fees</th>
                                <th scope="col" >Amount</th>
                                <th scope="col" style={{ minWidth: '80px' }}>Status</th>
                                <th scope="col" >Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            {data.length != 0 ? data.map((v, i) => data[data.length - (i + 1)]).slice((activePage - 1) * pageSize, (activePage) * pageSize).map((item, index) => {
                                return (
                                    <tr key={item._id}>
                                        <th scope=" row" >{((activePage - 1) * pageSize) + index + 1}</th>
                                        <td >{render_date(item.added_date)}</td>
                                        <td style={{ width: '150px' }}>{item.bill_number}</td>
                                        <td >
                                            {
                                                <div
                                                    style={{
                                                        color: "#3FA4FC",
                                                        cursor: "pointer",
                                                        maxWidth: '200px'
                                                    }}
                                                    id={`Url-link-${item._id}`}
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(
                                                            `${getLinkDomain()}/invoice/${item._id
                                                            }`
                                                        );
                                                        copyTextToolTipToggle();
                                                        setGetToolTip(item._id);
                                                    }}
                                                >
                                                    {`${getLinkDomain()}/invoice/${item._id
                                                        }`}
                                                    <span id={`link-copy1212-${item._id}`} className="ml-3" style={{ cursor: 'pointer' }}>
                                                        <Icon
                                                            path={mdiContentCopy}
                                                            className="mdi_icon_style"
                                                            size={.5}
                                                            color="#414a4c"
                                                            onClick={() => {
                                                                navigator.clipboard.writeText(
                                                                    `${getLinkDomain()}/invoice/${item._id
                                                                    }`
                                                                );
                                                                copyTextToolTipToggle();
                                                                setGetToolTip(item._id);
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                            }
                                            {copyTextToolTip &&
                                                getTooltip === item._id && (
                                                    <RenderReactTooltip
                                                        placement="right"
                                                        targetId={`link-copy1212-${item._id}`}
                                                        tooltipBody={<p style={{ margin: '0px', padding: '0px' }}>URL Copied!</p>}
                                                        isOpen={copyTextToolTip}
                                                        toggle={copyTextToolTipToggle}
                                                        style={{ margin: 0 }}
                                                    />
                                                )}
                                        </td>
                                        <td className='border' style={{ overflowWrap: 'anywhere' }}>{
                                            item.items.map(a => {
                                                return a.description
                                            }).toString()?.replaceAll(',', ", ")
                                        }</td>
                                        <td className="border">
                                            {
                                                moment
                                                    .tz(item.due, getTimeZone())
                                                    .format("DD/MM/YYYY")
                                            }
                                        </td>
                                        <td className="border" style={{ width: '150px' }}>
                                            {item.charges_payer == 1
                                                ? 'On Customer'
                                                : 'On Store'}
                                        </td>
                                        <td className="border" style={{ width: '150px' }}>
                                            {item.ipay_charges_payer == 0
                                                ? 'On Store'
                                                : 'On Customer'}
                                        </td>
                                        <td style={{ width: '150px' }}>${item.bill_amount.toFixed(2)}</td>
                                        <td >
                                            {/* {item.status == "0" ? <p class="text-muted">Unpaid</p> : item.status == "1" ? <p class="text-primary">Accepted</p> : item.status == "2" ? <p class="text-danger">Rejected</p> : item.status == "3" ? <p class="text-success">Paid</p> : item.status == "4" ? <p class="text-secondary">Unpaid</p> : null} */}
                                            {item.status == "0"
                                                ?
                                                <div className="d-flex justify-content-center">
                                                    <Badge style={{
                                                        background: '#FFBD57',
                                                        color: 'white',
                                                        width: '80%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                    }}>
                                                        Unpaid
                                                    </Badge>
                                                </div>
                                                : item.status == "1"
                                                    ?
                                                    <div className="d-flex justify-content-center">
                                                        <Badge style={{
                                                            background: '#FFBD57',
                                                            color: 'white',
                                                            width: '80%',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                        }}>
                                                            Unpaid
                                                        </Badge>
                                                    </div>
                                                    : item.status == "2"
                                                        ?
                                                        <div className="d-flex justify-content-center">
                                                            <Badge style={{
                                                                background: 'red',
                                                                color: 'white',
                                                                width: '80%',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                            }}>
                                                                Rejected
                                                            </Badge>
                                                        </div>
                                                        : item.status == "3"
                                                            ?
                                                            <div className="d-flex justify-content-center">
                                                                <Badge style={{
                                                                    background: 'green', color: 'white', width: '80%', display: 'flex',
                                                                    justifyContent: 'center',
                                                                }}>Paid</Badge>
                                                            </div>
                                                            : item.status == "4"
                                                                ?
                                                                <div className="d-flex justify-content-center">
                                                                    <Badge style={{
                                                                        background: '#FFBD57',
                                                                        color: 'white',
                                                                        width: '80%',
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                    }}>
                                                                        Unpaid
                                                                    </Badge>
                                                                </div>
                                                                : null}
                                        </td>
                                        <td >
                                            {/* <span style={{ marginRight: "5px", fontSize: "0.75rem", cursor: "pointer", color: "black" }} onClick={() => editClick(item)}>
                                                <i className="fa fa-edit"></i>
                                            </span> */}
                                            {item.status == "3" ?
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <Button
                                                        outline
                                                        style={{
                                                            padding: "4px 6px",
                                                        }}
                                                        className="d-flex align-items-start m-0"
                                                        color="success"
                                                        onClick={() => {
                                                            setViewTransaction(!viewTransaction)
                                                            setTransactionData({
                                                                merchant_id: props.merchant_id,
                                                                wallet_amount: '',
                                                                data: item,
                                                                view_type: 0,
                                                                disabled_refund: '',
                                                            })
                                                        }}
                                                    >
                                                        <Icon
                                                            path={mdiEye}
                                                            className="mx-auto"
                                                            size={0.7}
                                                            color="success"
                                                        />

                                                    </Button>
                                                    <Button
                                                        outline
                                                        style={{ padding: "4px 6px" }}
                                                        className="d-flex align-items-start my-0 ml-2 mr-0"
                                                        color="success"
                                                        onClick={() => { downloadTaxInvoice(item) }}
                                                    >
                                                        <Icon path={mdiDownloadOutline} className="mx-auto" size={0.7} color="success" />
                                                    </Button>
                                                </div>
                                                :
                                                <Button
                                                    outline
                                                    style={{
                                                        padding: "4px 6px",
                                                    }}
                                                    className="d-flex align-items-start m-0 mr-2"
                                                    color="danger"
                                                    // onClick={(e) => {
                                                    // 	toggledeleteModal(val);
                                                    // }}
                                                    onClick={() => onDeleteInvoice(item)}
                                                >
                                                    <Icon path={mdiTrashCanOutline} className="mx-auto" size={0.6} color="#DC3545" />
                                                </Button>
                                            }
                                        </td>

                                    </tr>);
                            }) : <h6 style={{ textAlign: "center", marginTop: "1rem" }}> No records found! </h6>}
                        </tbody>
                    </Table>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <select className="custom-select" style={{ width: "35%", fontSize: "0.63rem", marginRight: "10px" }} onChange={(event) => setPageSize(event.target.value)} value={pageSize}>
                                <option value="15">15</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="75">75</option>
                                <option value="100">100</option>
                            </select>
                            <span style={{ fontSize: "0.63rem" }}>Records per Page</span>
                        </div>
                        <PaginationComponent
                            totalItems={data.length}
                            pageSize={pageSize}
                            onSelect={handlePageChange} />
                    </div>
                </div>
                <Modal centered isOpen={modal} toggle={onDeleteInvoice}>
                    <form style={{ paddingInline: "" }}>
                        <ModalHeader style={{ display: "flex", justifyContent: "center", padding: "0.25rem" }}>
                            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                <div
                                    style={{
                                        width: 40,
                                        height: 40,
                                        borderRadius: "50%",
                                        border: "1px solid #E74C3C",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <CloseIcon color={"#E74C3C"} />
                                </div>
                                <span style={{ marginTop: "0.25rem", marginBottom: "0.5rem" }}>
                                    {" "}
                                    <b>Attention! </b>{" "}
                                </span>
                            </div>
                        </ModalHeader>
                        <ModalBody style={{ textAlign: 'center' }}>Are you sure you want to delete the bill?</ModalBody>
                        <ModalFooter style={{ justifyContent: "space-evenly", borderTop: "none" }}>
                            <Button size={"sm"} style={{ marginBottom: 0, background: "#e7e2e2" }} onClick={onDeleteInvoice}>
                                Cancel
                            </Button>
                            <Button color="danger" size={"sm"} style={{ marginBottom: 0 }} onClick={onDelete}>
                                Delete
                            </Button>
                        </ModalFooter>
                    </form>
                </Modal>
            </Fragment>
        );
    }

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        let bills = null;
        if (searchValue == null || searchValue == "") {
            setSearchedData(null)
        } else {
            const filteredBills = [];
            if (activeTab == "1") {
                bills = invoicesData
            } else if (activeTab == "2") {
                bills = quotesData
            }
            bills.filter(item => {
                /* if (typeof item.business_name !== "undefined" && typeof item.added_date !== "undefined" && typeof item.name !== "undefined" && item.landline !== "undefined" && typeof item.mobile !== "undefined" && typeof item.email !== "undefined" && typeof item.address !== "undefined") { */
                let arr = [...item?.items]
                console.log(arr, 'arr')
                arr = arr.filter(ele => {
                    return ele?.description?.toString().toLowerCase().includes(searchValue.toLowerCase())
                })
                if (item.bill_amount.toString().includes(searchValue.toString().toLowerCase()) || item.bill_number.toLowerCase().includes(searchValue.toLowerCase()) || item.bill_for.toLowerCase().includes(searchValue.toLowerCase()) ||
                    render_date(item.added_date).toLowerCase().includes(searchValue.toLowerCase()) || arr.length > 0) {
                    filteredBills.push(item)
                }
                //}
            })
            console.log(filteredBills);
            setSearchedData(filteredBills)
        }
    }


    const downloadTaxInvoice = (data) => {

        try {

            let type = 1;

            if (Number(activeTab) == 1) {
                type = 2;
            } else if (Number(activeTab) == 2) {
                type = 3;
            }


            Swal.fire({
                title: 'Generating Invoice',
                html: ' Please Wait...!',
                width: 400,
                // allowOutsideClick: false,
                onOpen: () => { Swal.showLoading() },
            });

            axios.get(client.url(`${api.downloadInvoice}?request_id=${data._id}&action=download&type=${type}`), {
                responseType: "blob",
                headers: { "x-access-token": "ipay-access-token", },
                params: {},
            }).then((res) => {
                Swal.close();
                fileDownload(res.data, `Invoice.pdf`);

            }).catch(function (error) {
                toast.error('Error occured while generating invoice');
                Swal.close();
            });

        } catch (error) {
            console.log(error, "error");
            toast.error('Error occured while generating invoice');
            Swal.close();
        }
    }





    return (
        <Fragment>
            {renderMailModal()}
            {selectedBill && renderDeleteModal()}
            {selectedBill && renderEditModal()}
            {showInvoice && renderInvoiceModal()}
            {renderAddItemModal()}
            {viewTransaction ?
                <ViewTransaction data={transactionData} merchant_id={props.merchant_info?._id} countryData={props.merchant_info?.country} onBackClick={() => setViewTransaction(!viewTransaction)} />
                :
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-xl-12 xl-100">
                            {invoicesData != null && quotesData != null ?
                                <div className="card">
                                    <div className="card-body" style={{ padding: "1rem" }}>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <Nav tabs className="nav-pills nav-light mb-1">
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                    <div >
                                                        <form onBlur={(event) => handleSearchSubmit(event)} onSubmit={(event) => handleSearchSubmit(event)}>
                                                            <div >
                                                                <input style={{
                                                                    border: "none",
                                                                    borderBottom: "1px solid rgb(197, 189, 189)",
                                                                    borderRadius: "0",
                                                                    margin: "0px 10px 6px",
                                                                    fontSize: "0.75rem",

                                                                }}
                                                                    type="text"
                                                                    placeholder="Search"
                                                                    onChange={onSearchChangeHandler} />
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                                                    <NavLinkTab className={activeTab == '1' ? 'active nav-link' : ''} onClick={() => onTabChange('1')} value='1'>
                                                        Invoices
                                                    </NavLinkTab>
                                                </NavItem>

                                                <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                                                    <NavLinkTab className={activeTab == '2' ? 'active nav-link' : ''} onClick={() => onTabChange('2')} value='2'>
                                                        Quotes
                                                    </NavLinkTab>
                                                </NavItem>
                                            </Nav>
                                            {((activePage) * pageSize) > (activeTab == "1" ? invoicesData.length : quotesData.length) ?
                                                <p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>Showing {((activePage - 1) * pageSize) + 1} to {activeTab == "1" ? invoicesData.length : quotesData.length} of {activeTab == "1" ? invoicesData.length : quotesData.length} entries</p> :
                                                <p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>Showing {((activePage - 1) * pageSize) + 1} to {(activePage) * pageSize} of {activeTab == "1" ? invoicesData.length : quotesData.length} entries</p>}

                                        </div>

                                        {/* {tableData.length > 0 ? */}

                                        <TabContent activeTab={activeTab} >
                                            <TabPane tabId="1">
                                                {renderTable(invoicesData)}
                                            </TabPane>

                                            <TabPane tabId="2">
                                                {renderTable(quotesData)}
                                            </TabPane>

                                        </TabContent>
                                        {/* : <h6 style={{ textAlign: "center" }}> No records found! </h6>} */}
                                    </div>
                                </div> : <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                                    <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                                        <DisappearedLoading size="medium" color="#514F4E" />
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
            }
        </Fragment>

    );
    // }
}

export default MerchantBills;