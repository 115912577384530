import React, { Fragment, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import displayMobile from "../../components/ui-elements/display_mobile";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import { Link as NavLink } from "react-router-dom";
import moment from "moment-timezone";
import { TabContent, TabPane, Nav, NavItem, NavLink as NavLinkTab, UncontrolledTooltip, ButtonToolbar } from "reactstrap";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Table, Row, Col, Card, CardBody, Container } from "reactstrap";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import PaginationComponent from "react-reactstrap-pagination";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import "../../table.css";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import { DisappearedLoading } from "react-loadingg";
import axios from "axios";
import imgunverified from "../../assets/staticImages/merchant-unverified.png";
import imgverified from "../../assets/staticImages/merchant-verified.png";
import imgverificationSent from "../../assets/staticImages/merchant-underreview.png";
import MerchantDocPending from "./MerchantDocPending";
import UpdateRequests from "./UpdateRequests";
import VisibilityIcon from "@material-ui/icons/Visibility";
// import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { DivideCircle } from "react-feather";
import PendingVerification from "../PendingVerification";
// import Slider from "../../components/ui-elements/slider";
import BarLoader from "react-spinners/BarLoader";
import '../../pages/merchants/MerchantInvoiceDetailsCss.scss';
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import Dropdown from 'react-bootstrap/Dropdown';
import DocPendingVerification from "../../components/DocPendingVerification/DocPendingVerification";
import { Typeahead, AsyncTypeahead } from 'react-bootstrap-typeahead';
import Select from 'react-select';
import CustomDropDown from './customDrowpdown';
import { BsPlus } from 'react-icons/bs';
import { BiMinus } from 'react-icons/bi';
import { count } from "./smsCounter";
import NewWindow from 'react-new-window'
import "./merchantReqList.css";
import { mdiEye, mdiTrashCanOutline, mdiChartSankeyVariant, mdiCogOutline } from "@mdi/js"
import Icon from "@mdi/react";
import { withRouter } from 'react-router-dom';
import SendSMSModal from "./sendSMSModal";
import CustomPhoneSelect from "./CustomPhoneSelect";

class BorderTable extends React.Component {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);

		this.state = {
			error: null,
			isLoaded: false,
			items: [],
			customers: [],
			plan_not_selected: [],
			store_details_pending: [],
			invited_users: [],
			verified: [],
			webRequests: [],
			expired_plan: [],
			unverified: [],
			lockedCount: [],
			unverified_email: [],
			unverified_email_above_5_days: [],
			blocked: [],
			flagged: [],
			deleteModal: false,
			selectedId: null,
			tabIndex: 0,
			defaultIndexNo: this.props.history.location.state?.activeTab ? this.props.history.location.state?.activeTab : 1,
			send_mail_modal: false,
			deleteSelectedModal: false,
			selectedIDs: [],
			activePage: 1,
			pageSize: 15,
			infoModal: false,
			transferModal: false,
			mobileModal: false,
			blockModal: false,
			blockItem: null,
			flagItem: null,
			infoItem: null,
			transferItem: null,
			transf_amt: null,
			for_message: null,
			additional_notes: null,
			accountType: "store_name",
			ipay_wallet_amt: null,
			speed_food_wallet_amt: null,
			speedFood_details: null,
			ipay_details: null,
			fetchFilterResultLoading: null,
			filteredOptions: [],
			selectedReceiver: null,
			from_id: null,
			selectedPayer: null,
			ipay_pin: "kFjvFxuMY5KOPaCvCaLWPs602XQ",
			flagModal: false,
			flag_note: "",
			searchValue: null,
			searchedData: null,
			value: 100,
			sendCredOption: null,
			send_to_mer_id: "",
			mail_templates: null,
			sms_templates: null,
			bulkMail: false,
			send_templ_mail_id: "select",
			access_level: localStorage.getItem("accessLevel"),
			adminUser: JSON.parse(localStorage.getItem("user")),
			loading: false,
			tabCountLoading: false,
			smsModalClick: false,
			exportModalClick: false,
			exportModalOption: "select",
			startDate: null,
			endDate: null,
			createCsv: false,
			csvData: [],
			csvHeader: [],
			invoiceModal: false,
			invoiceFrom: null,
			invoiceDetails: null,

			searchLoading: true,
			companyData: [],

			result: [],
			allresult: [],
			underreview: [],
			subscription_invoices: [],
			fetchType: "verified",
			tab_count: { all: 0, blockedCount: 0, expiredPlan_count: 0, flaggedCount: 0, pendingveriCount: 0, plan_not_selected_count: 0, store_details_pending_count: 0, unveriCount: 0, veriCount: 0, subscription_invoices_count: 0, invited_users_count: 0, lockedCount: 0 },
			country: [],
			countryTabIndex: 0,
			selectedCommunicateOption: "email",
			userDataForPromotion: null,
			addedEmailForPromotion: "",
			email_message_error: "",
			mobOptions: [],
			sms_message_error: "",
			selected_country: { value: "+61", label: "+61" },
			sendToStoreEmail: false,
			sendToUserEmail: false,
			sendToCustomEmail: false,
			send_templ_sms_id: null,
			sendToStoreSMS: false,
			sendToCustomSMS: false,
			addedSMSForPromotion: "",
			sms_message: "",
			exchangeData: {},
			smsCharges: [],
			totalChrages: "0.00",
			total_sms: 0,
			defaultCountryCode: localStorage.getItem("sessionCountryCode"),
			merchantByCountry: [],
			storeStatus: 0,
			screenWidth: 0,
			previewEmail: false,
			selectedRow: false,
			selectedMerchant: '',
			openPortalLogin: false,
			adminPassword: '',
			isVisible: false
		};
	}

	componentDidMount() {
		// alert("hrer");
		this.merchantTabCount();
		this.getCustomers("verified", this.state.activePage);
		this.getMailTemplates();
		this.getSMSTemplates();
		this.fetchCountries();
		this.fetchMerchants();
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
		this.getCurrencyData();
		this.fetchCharges();
	}

	componentDidUpdate() {
		if (this.state.csvData.length > 0) {
			setTimeout(() => {
				document.querySelector("#csv").click();
				this.setState({
					exportModalClick: false,
					exportModalOption: "select",
					startDate: null,
					endDate: null,
					csvData: [],
					csvHeader: [],
					createCsv: false,
				});
			}, 10);
		}
		// this.setState({selectedPayer:this.state.ipay_details})
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	setIsVisible = () => {
		this.setState({ isVisible: !this.state.isVisible })
	}

	redirectToHome = () => {
		this.setState({ defaultCountryCode: "Trashed" });
		const { history } = this.props;
		if (history) history.push({ pathname: `/merchants/trash_merchant_requests` });
	}

	updateWindowDimensions = () => {
		this.setState({ screenWidth: window.innerWidth });
	}

	handlePageChange = (pageNumber) => {
		// console.log(pageNumber, "pageNumber");
		this.setState({ activePage: pageNumber });
		this.getCustomers(this.state.fetchType, pageNumber);
	};

	setPageSize = (event) => {
		this.setState({
			pageSize: event.target.value
		}, () => {
			this.getCustomers(this.state.fetchType, 1);
		});
	};

	setStatus = (event) => {

		this.setState({
			status: event.target.value
		})

	}

	pageClick(e, index) {
		e.preventDefault();
		this.setState({
			currentPage: index,
		});
	}
	displayDate(date) {
		let transaction_date = new Date(date);
		return <span>{transaction_date}</span>;
	}

	changeTab = async (type) => {

		this.setState({ storeStatus: 0 });
		this.setState(
			{ fetchType: type, activePage: 1 },
			() => {
				// alert(type);
				this.getCustomers(type, 1);
			}
		);
		this.merchantTabCount();
	};

	changeCountryTab = async (data) => {

		this.setState({ defaultCountryCode: data });
		this.setState({ storeStatus: 0 });

		let getAllCountry = this.state.country;
		let getCountryCode = [];

		getAllCountry.length > 0 && getAllCountry.map((countryData) => {
			getCountryCode.push(countryData.country_code)
			return countryData;
		});

		setTimeout(() => {
			this.getCustomers(this.state.fetchType, 1,);
			this.merchantTabCount();
			this.merchantCountByCountry(getCountryCode);
		}, 1000);

	}

	fetchInvoice = async (invoiceData) => {
		let invoice_id = invoiceData._id;
		let current = this;
		if (invoice_id) {
			client.get(api.fetch_invoice, { invoice_id }, async function (error, response) {
				if (!error) {
					if (!response.error) {
						current.setState({ invoiceFrom: response.invoice_from });
						current.toggleInvoiceModal();
					} else {
						toast.error("Internal error occured.Please contact support");
					}
				}
				else {
					toast.error("Internal error occured.Please contact support");
				}
			})
		}
	};

	merchantTabCount = async () => {

		let postdata = { countryCode: this.state.defaultCountryCode };
		let current = this;
		current.setState({ tabCountLoading: true });

		client.post(api.merchantTabCount, postdata, async function (error, response) {
			if (!error) {
				// alert(JSON.stringify(response));
				if (typeof response.error !== "undefined") {
					try {
						if (!response.error) {
							// console.log(response.result);
							current.setState({ tab_count: response.result.tab_data })
						} else {
							toast.error(response.message);
						}
					} catch (e) {
						toast.error("Internal error occured. Please contact support");
					}
				} else {
					toast.error("Internal error occured. Please contact support");
				}
			} else {
				toast.error("Internal error occured. Please contact support");
			}
			current.setState({ tabCountLoading: false });
		});
	};

	getCustomers = async (fetchType, activePage, is_filter) => {


		let { pageSize } = this.state;
		let skip = (parseInt(activePage) - 1) * parseInt(pageSize);

		if (this.state.searchValue !== '' && this.state.searchValue !== null) {
			skip = 0;
		}

		let postdata = {
			type: fetchType,
			searchValue: this.state.searchValue,
			limit: parseInt(pageSize),
			skip,
			countryCode: this.state.defaultCountryCode,
			storeStatus: this.state.storeStatus
		};
		// console.log(postdata)
		let current = this;
		if (!is_filter) current.setState({ loading: true });
		else {
			current.setState({ searchLoading: true });
		}

		client.post(api.merchant_request_list, postdata, async function (error, response) {
			if (!error) {
				// console.log(response.result.data, "fetch merch response")
				if (typeof response.error !== "undefined") {
					try {
						if (!response.error) {
							// console.log(response.result)
							// console.log(response.result, "list detail")
							current.setState({ [fetchType]: response.result.data, loading: false, searchLoading: false });
							// current.setState({ loading: false })
							// current.setState({ loading: false, searchLoading: false });
							// console.log(response.result.data.map(item=>item.merchants._id), "merchant_ids")
							// current.setState({ companyData: response.result.companyData });
						} else {
							// setTimeout(() => {
							toast.error(response.message);
							current.setState({ loading: false, searchLoading: false });
							// }, 200);
						}
					} catch (e) {
						current.setState({ loading: false, searchLoading: false });
						// setTimeout(() => {}, 200);
						toast.error("Internal error occured. Please contact support");
						// _showToast.error("Internal error occured. Please contact support.");
						// currentComponent.setState({ errormessage: "Internal error." });
					}
				} else {
					current.setState({ loading: false, searchLoading: false });
					// setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
					// }, 200);
					//   _showToast.error("Internal error occured. Please contact support.");
				}
			} else {
				current.setState({ loading: false, searchLoading: false });
				// setTimeout(() => {
				toast.error("Internal error occured. Please contact support");
				// }, 200);
				// alert(JSON.stringify(error));
				// _showToast.error("Internal error occured. Please contact support.");
			}
			// current.setState({ loading: false, searchLoading: false });
		});
	};

	moveElement(array) {

		let arrangeCountry = [...array];

		let australia = arrangeCountry.map((e) => e.country_code).indexOf('AU');
		if (australia != -1) {
			arrangeCountry.splice(0, 0, arrangeCountry.splice(australia, 1)[0]);
		}

		let japanIdx = arrangeCountry.map((e) => e.country_code).indexOf('JP');
		if (japanIdx != -1) {
			arrangeCountry.splice(1, 0, arrangeCountry.splice(japanIdx, 1)[0]);
		}

		let thailand = arrangeCountry.map((e) => e.country_code).indexOf('TH');
		if (thailand != -1) {
			arrangeCountry.splice(2, 0, arrangeCountry.splice(thailand, 1)[0]);
		}

		let unitedState = arrangeCountry.map((e) => e.country_code).indexOf('US');
		if (unitedState != -1) {
			arrangeCountry.splice(3, 0, arrangeCountry.splice(unitedState, 1)[0]);
		}

		let india = arrangeCountry.map((e) => e.country_code).indexOf('IN');
		if (india != -1) {
			arrangeCountry.splice(4, 0, arrangeCountry.splice(india, 1)[0]);
		}

		return arrangeCountry;
	}

	fetchCountries = () => {

		let current = this;
		client.post(api.fetch_country, { show_all: true }, async function (error, response) {

			if (!error) {
				if (typeof response.error !== "undefined") {
					try {
						if (!response.error) {
							let getCountryCode = [];
							let arrangeCountry = [...response.result.data];
							arrangeCountry = await current.moveElement(arrangeCountry);
							current.setState({ country: arrangeCountry })

							arrangeCountry.length > 0 && arrangeCountry.map((countryData) => {
								getCountryCode.push(countryData.country_code)
								return countryData;
							});

							current.merchantCountByCountry(getCountryCode);
						}
					} catch {
						toast.error("Internal error occured. Please contact support");
					}
				} else {
					toast.error("Internal error occured. Please contact support");
				}
			} else {
				toast.error("Internal error occured. Please contact support");
			}

		});
	}

	fetchMerchants = () => {
		let merchant_ids = ['5f12aad64595d48863177243', '5f11e6b812caca165455b085'];

		let postdata = { selectedIDs: merchant_ids }
		client.post(api.getMultipleMerchantWallet, postdata, (error, response) => {
			if (!error) {
				try {
					if (!response.error) {
						// console.log(response.result);
						if (response.result.walletDetails.length > 0)
							this.setState({ ipay_wallet_amt: response.result.walletDetails[1].wallet_amount });
						if (response.result.walletDetails.length > 0)
							this.setState({ speed_food_wallet_amt: response.result.walletDetails[0].wallet_amount });
						if (response.result.details.length > 0)
							this.setState({ ipay_details: response.result.details[1] });
						if (response.result.details.length > 0)
							this.setState({ speedFood_details: response.result.details[0] })
						//this.setState({  selectedPayer: response.result.details[1] });

					} else {
						setTimeout(() => {
							toast.error(response.message);
						}, 200);
					}
				} catch (e) {
					setTimeout(() => {
						toast.error("Internal error occured. Please contact support w" + e.message);
					}, 200);
				}

			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
			//   current.setState({ loading: false }); 
		});
	}

	merchantCountByCountry = async (getCountryCode) => {

		let postdata = { countryCode: getCountryCode };
		let current = this;


		client.post(api.merchantCountByCountry, postdata, async function (error, response) {

			if (!error) {

				if (!response.error) {
					current.setState({ merchantByCountry: response.result.tab_data })
				} else {
					toast.error("Internal error occured. Please contact support");
				}

			} else {
				toast.error("Internal error occured. Please contact support");
			}

		});
	};

	refreshPage = () => {

		this.getCustomers(this.state.fetchType, 1);
	};

	getMailTemplates = async () => {
		let postdata = { templ_user_type: "merchant", auto_reminder: false };
		let current = this;
		current.setState({ loading: true });

		client.post(api.get_all_mail_templates, postdata, async function (error, response) {
			if (!error) {
				//alert(JSON.stringify(response));
				//   return false;
				if (typeof response.error !== "undefined") {
					try {
						if (!response.error) {
							current.setState({ mail_templates: response.result.data });
						} else {
							toast.error(response.message);
						}
					} catch (e) {
						toast.error("Internal error occured. Please contact support");
					}
				} else {
					toast.error("Internal error occured. Please contact support");
				}
			} else {
				toast.error("Internal error occured. Please contact support");
				// _showToast.error("Internal error occured. Please contact support.");
			}
			current.setState({ loading: false });
		});
	};

	getSMSTemplates = async () => {
		let postdata = { templ_user_type: "merchant", auto_reminder: false };
		let current = this;
		current.setState({ loading: true });

		client.post(api.get_all_sms_templates, postdata, async function (error, response) {
			if (!error) {
				//alert(JSON.stringify(response));
				//   return false;
				if (typeof response.error !== "undefined") {
					try {
						if (!response.error) {
							current.setState({ sms_templates: response.result.data });
						} else {
							toast.error(response.message);
						}
					} catch (e) {
						toast.error("Internal error occured. Please contact support");
					}
				} else {
					toast.error("Internal error occured. Please contact support");
				}
			} else {
				toast.error("Internal error occured. Please contact support");
				// _showToast.error("Internal error occured. Please contact support.");
			}
			current.setState({ loading: false });
		});
	};

	ShowCurrencyNum = (amount) => {
		// const merchantData = JSON.parse(localStorage.getItem("merchant"));
		const merchantData = this.state.merchant_info;
		// console.log(merchantData, "merchantdata")
		const currency_symbol = merchantData.country.currency_symbol;
		// const currency_symbol = countryData.currency_symbol;

		let getCurrency = !isNaN(parseFloat(amount)) ? amount : 0;

		if (merchantData && merchantData.country) {
			// if (countryData) {
			if (currency_symbol) {
				getCurrency = `${currency_symbol} ${parseFloat(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
			}
			else {
				getCurrency = +`${parseFloat(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
			}
		}

		return getCurrency;
	}

	calculateSMSCharges = () => {
		const { smsCharges, exchangeData, userDataForPromotion, sms_message, selected_country } = this.state;
		if (smsCharges.length && Object.keys(exchangeData).length && userDataForPromotion) {
			// console.log(smsCharges, userDataForPromotion, "smsCharges")
			let findCountryTotal = smsCharges.find(val => val.country_code == userDataForPromotion.country.phone_prefix.replace("+", "")).total
			let total_sms = count(sms_message)?.messages;
			findCountryTotal = findCountryTotal * total_sms;
			// let mobileRegex = countryData?.find(val => val.country_name == formData.mobprefix.value)?.phone_regex;
			// setGetMobileRegex(mobileRegex)
			let toCurrency = userDataForPromotion.country.currency_code;
			// console.log(userDataForPromotion.country, "userDataForPromotion.country")
			let findV = exchangeData[toCurrency];
			// console.log(findV, exchangeData, "QWERTG")
			findCountryTotal = findCountryTotal * findV
			this.setState({
				total_sms: total_sms,
				totalChrages: parseFloat(findCountryTotal)?.toFixed(2)
			})
		}
	}

	// componentDidUpdate(prevState, prevProps) {
	// 	if (this.state.sms_message !== prevState.sms_message) {
	// 	  this.calculateSMSCharges();
	// 	}
	// }

	fetchCharges = () => {
		let postData = {
			all: true
		}
		client.post(api.fetchSMSCharges, postData, (error, response) => {
			if (!error) {
				if (!response.error) {
					let _ = response.result.data.map(val => {
						return { value: val.country, label: `${val.country} (+${val.country_code})`, country_code: `+${val.country_code}` }
					})
					this.setState({
						mobOptions: _,
						smsCharges: response.result.data
					});
				} else {
				}
			} else {
			}
		});
	}

	getCurrencyData = async () => {



		try {
			client.get("api/exchangerate/USD", {}, (error, response) => {
				if (response) {
					// setGetCurrData(response.conversion_rates)
					this.setState({
						exchangeData: response.conversion_rates
					})
				}
			})
		} catch {

		}
		// const response = await fetch("https://v6.exchangerate-api.com/v6/d3cfe8ebe595e6f9cefe5289/latest/AUD");
		// // const response = await fetch(`https://v6.exchangerate-api.com/v6/d3cfe8ebe595e6f9cefe5289/latest/${merchantData?.country?.currency_code}`);

		// if (response.status >= 200 && response.status <= 299) {
		// 	const jsonData = await response.json();
		// 	this.setState({
		// 		exchangeData: jsonData.conversion_rates
		// 	})
		// } else {
		// 	toast.error("Internal error occured.Please contact support");
		// }
	};

	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	}

	toggleDeleteSelectedModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				deleteSelectedModal: !prevState.deleteSelectedModal,
			};
		});
	};

	flagClick = (id, item) => {
		this.setState((prevState) => {
			return {
				...prevState,
				flagModal: !prevState.flagModal,
				selectedId: id,
				flagItem: item,
			};
		});
	};

	blockClick = (id, item) => {
		this.setState((prevState) => {
			return {
				...prevState,
				blockModal: !prevState.blockModal,
				blockItem: item,
				selectedId: id,
			};
		});
	};

	infoClick = (id, item) => {
		// console.log(this.infoModal,"infomodal")
		this.setState((prevState) => {
			return {
				...prevState,
				infoModal: !prevState.infoModal,
				infoItem: item,
				selectedId: id,
			};
		});
	};

	transferClick = (id, item) => {
		// console.log(this.transferModal,"transfermodal")
		this.setState((prevState) => {
			return {
				...prevState,
				selectedReceiver: null,
				transferModal: !prevState.transferModal,
				transferItem: item,
				selectedId: id,
			}
		})
		// console.log(this.transferModal,"transfermodal")
	}

	changeHandler = (e) => {
		// console.log(e.target.value,"changeHandlervalue");
		// console.log(this.state.ipay_details,'ipaydetails')
		// this.setState({ [e.target.name]: e.target.value });
		this.setState({ from_id: e.target.value })
		if (e.target.value == '5f12aad64595d48863177243') {
			this.setState({ ipay_pin: "kFjvFxuMY5KOPaCvCaLWPs602XQ=" });
			// console.log(props.ipay_details);
			this.setState({ selectedPayer: this.state.ipay_details })
		} else if (e.target.value == '5f11e6b812caca165455b085') {
			this.setState({ ipay_pin: "fdHH3Xy/zax/0Nl5E8IqdepcXQA=" });
			// console.log(props.speedFood_details);
			this.setState({ selectedPayer: this.state.speedFood_details })
		}
	}

	// apiCall = async (text) => {
	// 	console.log(text,"text")
	//     client.post(api.filterCustMerchData, { search_term: text, filter_type: this.state.accountType == "customer_name" ? 1 : 2 }, async function (error, response) {
	// 			// console.log(typeof this.setState)
	//       console.log(response, "response")
	// 			console.log(response.error,'response1')
	// 			console.log(error,"error")
	//       if (!error) {
	//         if (typeof response.error !== "undefined") {
	//           try {
	//             if (!response.error) {
	// 							console.log('hello')

	//             } else {
	//               console.log(typeof this.setState,"set1")
	//             }
	//           } catch(err) {
	//            console.log(err,"err")
	//           }
	//         }
	//       } else {

	//       }

	//     })

	// }

	// fetchFilterResult = async (text = 'Store002') => {

	//   try {
	//     this.setState({fetchFilterResultLoading:true});
	//     let result = await this.apiCall(text)
	//     // this.setState({filteredOptions:result});
	//     this.setState({fetchFilterResultLoading:false});
	// 		this.handleChange(result)

	//   } catch {
	//     this.setState({filteredOptions:[]});
	//     this.setState({fetchFilterResultLoading:false});

	//   }

	// }

	handleChange = (selectedOptions) => {
		this.setState({ selectedReceiver: selectedOptions })

	}

	mobileClick = (id) => {
		if (this.state.access_level == 1) {
			toast.error("Not allowed to modify");
		} else {
			this.setState((prevState) => {
				return {
					...prevState,
					mobileModal: !prevState.mobileModal,
					selectedId: id,
				};
			});
		}
	};

	deleteSelectedButtonClick = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				deleteSelectedModal: !prevState.deleteSelectedModal,
			};
		});
	};

	deleteSelectedClick = (event, id) => {
		if (this.state.selectedIDs.includes(id)) {
			const idsToKeep = this.state.selectedIDs.filter((el) => {
				return el != id;
			});
			this.setState((prevState) => {
				return {
					...prevState,
					selectedIDs: idsToKeep,
				};
			});
		} else {
			this.setState((prevState) => {
				return {
					...prevState,
					selectedIDs: [...prevState.selectedIDs, id],
				};
			});
		}
	};

	flagNoteHandler = (event) => {
		const flagNote = event.target.value;
		this.setState((prevState) => {
			return {
				...prevState,
				flag_note: flagNote,
			};
		});
	};

	toggleFlagModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				flagModal: !prevState.flagModal,
			};
		});
	};

	toggleInvoiceModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				invoiceModal: !prevState.invoiceModal,
			};
		});
	}

	toggleBlockModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				blockModal: !prevState.blockModal,
			};
		});
	};

	toggleMobileModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				mobileModal: !prevState.mobileModal,
			};
		});
	};

	toggleInfoModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				infoModal: !prevState.infoModal,
			};
		});
	};

	toggleTransferModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				transferModal: !prevState.transferModal,
			}
		})
	}

	apiCall = async (text) => {
		// console.log(text,"text")
		let current = this
		client.post(api.filterCustMerchData, { search_term: text, filter_type: this.state.accountType == "customer_name" ? 1 : 2 }, async function (error, response) {
			// console.log(typeof this.setState)
			// console.log(response, "response")
			// console.log(response.error,'response1')
			// console.log(error,"error")
			if (!error) {
				if (typeof response.error !== "undefined") {
					try {
						if (!response.error) {
							current.setState((prev) => {
								return {
									...prev,
									selectedReceiver: response.result
								}
							})
						} else {
							// console.log(typeof this.setState,"set1")
						}
					} catch (err) {
						//  console.log(err,"err")
					}
				}
			} else {

			}

		})

	}

	toggleAutoLogin = (user, item) => {

		this.setState({ openPortalLogin: !this.state.openPortalLogin, selectedMerchant: user, isVisible: false });

	}

	verifyAdminHandler = (e) => {
		this.setState({ adminPassword: e.target.value })
	}

	handleAutoLogin = async (event) => {
		event.preventDefault();
		this.toggleAutoLogin();

		const { selectedMerchant, adminPassword } = this.state

		const postData = {
			admin_id: selectedMerchant.adminId,
			merchant_id: selectedMerchant._id,
			adminUser_id: localStorage.getItem("userId"),
			adminPassword: adminPassword
		}
		let current = this
		client.post(api.adminAutoLogin, postData, async function (error, response) {
			if (!error) {
				if (typeof response.error !== "undefined") {
					try {
						if (!response.error) {
							try {
								const token = response.result.token;
								// console.log(token, "TT")
								let domain = "" // addcondiotn to check if dm then dm domain
								let host = window.location.hostname
								if (host == "localhost") {
									domain = "http://localhost:3001/"
								} else if (host == "dm.admin.ipaypro.co") {
									domain = "https://dm.portal.linky.biz/"
								} else {
									domain = "https://portal.linky.biz/"
								}
								const url = `${domain}admin-login?token=${token}&merchant_id=${response.merchant._id}&admin_id=${response.result.user._id}`;
								// console.log(url, "MAIN URL")
								window.open(url, "_blank")
							} catch (err) {
								console.log(err, "err")
							}
						} else {
							toast.error(response.message);
						}
					} catch (err) {
						toast.error(response.message);
					}
				}
			} else {

			}

		})
	}

	renderBlockModal = (id) => {
		const foundCustomer = this.state.blockItem;
		const style = {
			outline: "none",
			":focus": {
				outlineColor: "black",
			},
			fontSize: "1rem",
			padding: "2px 7px",
		};

		if (foundCustomer?.status != 5) {
			return (
				<Modal isOpen={this.state.blockModal} toggle={this.toggleBlockModal} className="modal-body" centered={true}>
					<ModalBody>
						<form onSubmit={(event) => this.blockCustomerHandler(event, this.state.selectedId)} className="needs-validation">
							<div style={{ fontSize: "3rem", textAlign: "center" }}>
								<i className="icofont icofont-exclamation-tringle" style={{ color: "red" }}></i>
							</div>
							<h3 style={{ textAlign: "center" }}>Are you sure ?</h3>
							<p style={{ color: "red", textAlign: "center" }}>Note: Once blocked, Customer will not be able to sign in!.</p>
							<label className="form-label" style={{ display: "block" }}>
								Note:
							</label>
							<input
								type="text"
								size="40"
								onChange={this.flagNoteHandler}
								style={style}
								placeholder="Please enter comment for your action"
								required
							></input>
							<Button color="default" style={{ marginTop: "20px", marginRight: "20px" }} onClick={this.toggleBlockModal}>
								Cancel
							</Button>
							<Button color="primary" style={{ marginTop: "20px", marginRight: "20px" }} name="block">
								Yes, Please!
							</Button>
						</form>
					</ModalBody>
				</Modal>
			);
		}
		return (
			<Modal isOpen={this.state.blockModal} toggle={this.toggleBlockModal} className="modal-body" centered={true}>
				<ModalBody>
					<h5>Are you sure you want to unblock customer ?</h5>

					<Button color="default" style={{ marginTop: "20px", marginRight: "20px" }} onClick={this.toggleBlockModal}>
						Not Sure
					</Button>
					<Button
						color="primary"
						style={{ marginTop: "20px", marginRight: "20px" }}
						name="unblock"
						type="submit"
						onClick={(event) => this.blockCustomerHandler(event, this.state.selectedId)}
					>
						Yes, please!{" "}
					</Button>
				</ModalBody>
			</Modal>
		);
	};

	blockCustomerHandler = (event, id) => {
		const { adminUser } = this.state;
		event.preventDefault();
		let postdata = {};

		if (event.target.name == "unblock") {
			postdata = { merchant_id: id, status: 1, comment: { type: "unblock", note: "No comments", user: adminUser.email } };
		} else {
			postdata = {
				merchant_id: id,
				status: 5,
				comment: { type: "block", note: this.state.flag_note, user: adminUser.email },
			};
		}
		client.put(api.update_merchant_details, postdata, (error, response) => {
			if (!error) {
				if (!response.error) {
					//console.log(response);
					this.refreshPage();
					this.setState((prevState) => {
						return {
							...prevState,
							blockModal: false,
						};
					});
				} else {
					setTimeout(() => {
						toast.error(response.message);
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
		});
	};

	blocMerchant = async (merchant_id) => {
		let willDelete = await swal({
			title: "Are you sure?",
			text: "Once blocked, Merchant will not be able to sign in!",
			icon: "warning",
			buttons: {
				cancel: true,
				confirm: {
					text: "Block!",
					closeModal: false,
				},
			},
			dangerMode: true,
		});

		if (willDelete) {
			let postdata = { merchant_id: merchant_id, field_type: "block_status" };
			let current = this;
			let message = "Internal error occured. Please contact support";
			let status = 0;
			current.setState({ loading: true });
			client.post(api.merchant_field_changes, postdata, async function (error, response) {
				if (!error) {
					// alert(JSON.stringify(response));
					if (typeof response.error !== "undefined") {
						try {
							if (!response.error) {
								status = 1;
								message = "Merchant blocked succefully";
							} else {
								status = 0;
								message = response.message;
							}
						} catch (e) { }
					}
				}

				swal.close();
				swal(message);
				if (status == 1) current.getCustomers(this.state.fetchType, this.state.activePage);
			});
		}
	};

	changeISVerified = async (merchant_id, email_status) => {
		if (email_status == 1) return false;

		let willDelete = await swal({
			title: "Are you sure?",
			text: "Change status to Verified Merchant",
			icon: "warning",
			buttons: {
				cancel: true,
				confirm: {
					text: "Yes, Please!",
					closeModal: false,
				},
			},
			dangerMode: true,
		});

		if (willDelete) {
			let postdata = { merchant_id: merchant_id, field_type: "email_status", email_status: 1 };
			let current = this;
			let message = "Internal error occured. Please contact support";
			let status = 0;
			current.setState({ loading: true });
			client.post(api.merchant_field_changes, postdata, async function (error, response) {
				swal.close();
				if (!error) {
					// alert(JSON.stringify(response));
					if (typeof response.error !== "undefined") {
						try {
							if (!response.error) {
								status = 1;
								message = "Merchant Verified succefully";
								toast.success("Updated Successfully!");
							} else if (response.error && response.duplicate) {

								current.setState({ loading: false, showEmailModal: true });

								await Swal.fire({
									title: 'Duplicate email found',
									text: "Please enter new email",
									input: 'email',
									inputPlaceholder: 'Enter your email address',
									showLoaderOnConfirm: true,
									preConfirm: (email) => {

										let postdata = { merchant_id: merchant_id, field_type: "email_status", email_status: 1, email, checkEmail: true };

										const config = {
											headers: {
												"x-access-token": "ipay-access-token",
												"authorization": localStorage.getItem("token"),
											}
										}

										return axios.post(`${client.url(api.merchant_field_changes)}`, postdata, config)
											.then(result => {

												let response = result.data;

												if (typeof response.error !== "undefined") {
													try {
														if (!response.error) {
															status = 1;
															message = "Merchant Verified succefully";
															toast.success("Updated Successfully!");
														} else if (response.error && response.duplicate) {

															Swal.showValidationMessage(
																"This email is already in use, Please try another."
															)
														} else {
															status = 0;
															message = response.message;
														}
													} catch (e) { }
												}
											});
									},
								}).then((result) => {
									// console.log(result, "resultresult")
									// if (result.isConfirmed) {
									// 	Swal.fire({
									// 		icon: 'success',
									// 		title: 'Email is updated',
									// 		showConfirmButton: false,
									// 		timer: 1500
									// 	})
									// }
								})

							} else {
								status = 0;
								message = response.message;
							}
						} catch (e) { }
					}
				}

				swal.close();
				// swal(message);
				if (status == 1) {
					current.getCustomers(current.state.fetchType, current.state.activePage)
				};
			});
		}
	};

	changeISMobileVerified = async (merchant_id, mobile_status) => {

		if (mobile_status == 1) {

			return false
		} else {

			let willDelete = await swal({
				title: "Are you sure?",
				text: "Change mobile number to Verified ",
				icon: "warning",
				buttons: {
					cancel: true,
					confirm: {
						text: "Yes, Please!",
						closeModal: false,
					},
				},
				dangerMode: true,
			});

			if (willDelete) {
				let postdata = { merchant_id: merchant_id, mobile_status: 1 };
				let current = this;
				let message = "Internal error occured. Please contact support";

				current.setState({ loading: true });
				client.post(api.merchant_mobileNo_verify, postdata, async function (error, response) {
					if (!error) {
						if (typeof response.error !== "undefined") {
							try {
								if (!response.error) {

									toast.success("Mobile number verified successfully!");
									swal.close();
									current.getCustomers(current.state.fetchType, current.state.activePage)
								} else {
									toast.error("Internal error occured. Please contact support");
								}
							} catch (e) { }
						} else {
							toast.error("Internal error occured. Please contact support");
						}
					} else {
						toast.error("Internal error occured. Please contact support");
					}

				});
			}

		}
	};



	renderInvoiceDetailsModal = () => {
		let invoiceData = this.state.invoiceDetails;
		let invoiceFrom = this.state.invoiceFrom;

		return (
			<Modal size="lg" isOpen={this.state.invoiceModal} toggle={this.toggleInvoiceModal} className="modal-body" centered={true}>
				<ModalBody>
					<Card className='merchantInvoice' style={{ margin: 0 }}>
						<CardBody style={{ padding: '5px' }}>
							{invoiceData && <Container>

								<Row className='pt-4 pb-3'>
									<Col md={12}>
										<div className='border rounded p-4'>
											<Row>
												<Col md={6} className='d-flex align-items-center justify-content-start'>
													<div
														className="invoice__logo"
														style={{
															height: '50px',
														}}
													/>
												</Col>
												<Col md={6} className='d-flex align-items-center justify-content-end'>
													<div>
														<p className='invoice__grand-total' > Invoice #{invoiceData.invoice_number} </p>
													</div>
												</Col>
											</Row>
											<Row className='py-3 border-top'>
												<Col md={8} className='text-left'>
													<div>
														<p className='my-0'> {invoiceFrom?.business_name} </p>
														<p className='my-0'>
															{/* {invoiceFrom.address.split(',').map((line, i) => (
													<span key={i}>
														{line}
														<br />
													</span>
												))
												} */}
															{invoiceFrom?.address}
														</p>
														{invoiceFrom?.abn != "" &&
															<p dir="ltr" className='my-0'> ABN - {invoiceFrom?.abn} </p>
														}
														{invoiceFrom?.website != "" &&
															<a href={invoiceFrom?.website} target='_blank'> {invoiceFrom?.website} </a>
														}
														{/* <a href='https://www.store.com' target='_blank'> www.store.com </a> */}
													</div>
												</Col>
												<Col md={4} className='text-right'>
													<div>
														<p className="invoice__date my-0"> Date/Time : {moment(invoiceData.payment_date).format('DD-MM-YYYY/H:mm')} </p>
														<p className='my-0' style={{ textTransform: "capitalize" }}> {invoiceData?.fk_merchant_id?.business_name} </p>
														{/* <p className='my-0'> {invoiceData?.fk_merchant_id?.address} </p>
												*/}
														<p className='my-0'>
															{/* {invoiceData?.fk_merchant_id?.address.split(',').map((line, i) => (
													<span key={i}>
														{line}
														<br />
													</span>
												))
												} */}
															{invoiceData?.fk_merchant_id?.address}
														</p>
														{/* <p className='my-0'> Australia </p> */}
														{invoiceData?.fk_merchant_id?.country?.code == "AU" && invoiceData?.fk_merchant_id?.company_id?.abn && invoiceData?.fk_merchant_id?.company_id?.abn != "" &&
															<p dir="ltr" className='my-0'> ABN - {invoiceData?.fk_merchant_id?.company_id?.abn} </p>
														}
													</div>
												</Col>
											</Row>
											<Row className='py-3'>
												<Col md={12}>
													<Table className="table--bordered" responsive striped>
														<thead>
															<tr>
																<th> # </th>
																<th> Plan </th>
																<th> Quantity </th>
																{invoiceData.invoice_title.includes("White label Enterprise plan") ? null : <th> Unit Cost </th>}
																<th> Total </th>
															</tr>
														</thead>
														<tbody>
															{
																<tr>
																	<td>1</td>
																	<td>{invoiceData.invoice_title}</td>
																	<td>1</td>
																	{invoiceData.invoice_title.includes("White label Enterprise plan") ? null : <td>${invoiceData.sub_total.toFixed(2)}</td>}
																	<td>${invoiceData.sub_total.toFixed(2)}</td>
																</tr>
															}
														</tbody>
													</Table>
												</Col>
											</Row>

											<Row>
												<Col md={12} className='text-right'>
													<div className="invoice__total">
														<p> Sub - total amount: ${(invoiceData.sub_total).toFixed(2)} </p>
														{invoiceData.discount > 0 ? (<p> Discount: ${(invoiceData.discount).toFixed(2)} </p>) : ('')}
														{invoiceData?.promo_code_discount > 0 && <p>Promo code :${(invoiceData.promo_code_discount).toFixed(2)}</p>}

														{invoiceData.charges && invoiceData.charges.map((item) =>
															<p> {item.title}: ${(item.amount).toFixed(2)} </p>
														)}
														<p className="invoice__grand-total"> Grand Total: ${(invoiceData.total).toFixed(2)} </p>
														<ButtonToolbar className="invoice__toolbar">
															{/* <Button color="primary"> Proceed to payment </Button> */}
															{/* <Button> Print </Button> */}
														</ButtonToolbar>
													</div>
												</Col>
											</Row>
										</div>
									</Col>
								</Row>

							</Container>}

						</CardBody>
					</Card>
				</ModalBody>
			</Modal>
		);
	}

	renderFlagModal = (id) => {
		const foundCustomer = this.state.flagItem;
		const style = {
			outline: "none",
			":focus": {
				outlineColor: "black",
			},
		};

		if (foundCustomer) {
			if (typeof foundCustomer != "undefined" && !foundCustomer.flag_status) {
				return (
					<Modal isOpen={this.state.flagModal} toggle={this.toggleFlagModal} className="modal-body" centered={true}>
						<ModalBody>
							<form
								onSubmit={(event) => this.flagSubmitHandler(event, this.state.selectedId, foundCustomer)}
								className="needs-validation"
							>
								<h5>Please enter a note to flag merchant.</h5>
								<p style={{ color: "red" }}>Note: User will no longer be able to view transaction in their application.</p>
								<label className="form-label" style={{ display: "block" }}>
									Note:
								</label>
								<input type="text" size="40" onChange={this.flagNoteHandler} style={style} required></input>

								<Button color="default" style={{ marginTop: "20px", marginRight: "20px" }} onClick={this.toggleFlagModal}>
									Cancel
								</Button>
								<Button color="primary" style={{ marginTop: "20px", marginRight: "20px" }} name="flag">
									Update status
								</Button>
							</form>
						</ModalBody>
					</Modal>
				);
			}
			return (
				<Modal isOpen={this.state.flagModal} toggle={this.toggleFlagModal} className="modal-body" centered={true}>
					<ModalBody>
						<h5>Are you sure you want to unflag customer ?</h5>

						<Button color="default" style={{ marginTop: "20px", marginRight: "20px" }} onClick={this.toggleFlagModal}>
							Not Sure
						</Button>
						<Button
							color="primary"
							style={{ marginTop: "20px", marginRight: "20px" }}
							name="unflag"
							onClick={(event) => this.flagSubmitHandler(event, this.state.selectedId, foundCustomer)}
						>
							Yes, please
						</Button>
					</ModalBody>
				</Modal>
			);
		} else {
			return null;
		}
	};

	flagSubmitHandler = (event, id, foundCustomer) => {
		const { adminUser } = this.state;
		event.preventDefault();
		let postdata = {};
		// const foundCustomer = this.state.flagItem;

		if (foundCustomer) {
			if (typeof foundCustomer.flag_status === "undefined") {
				foundCustomer.flag_status = false;
			}

			if (event.target.name == "unflag") {
				postdata = {
					merchant_id: id,
					flag_status: !foundCustomer.flag_status,
					comment: { type: "unflag", note: "No comments", user: adminUser.email },
				};
			} else if (event.target.name == "comment") {
				postdata = { merchant_id: id, comment: { type: "normal", note: this.state.flag_note, user: adminUser.email } };
			} else {
				postdata = {
					merchant_id: id,
					flag_status: !foundCustomer.flag_status,
					comment: { type: "flag", note: this.state.flag_note, user: adminUser.email },
				};
			}
			client.put(api.update_merchant_details, postdata, (error, response) => {
				if (!error) {
					if (!response.error) {
						//console.log(response);
						this.refreshPage();
						this.setState((prevState) => {
							return {
								...prevState,
								flagModal: false,
								infoModal: false,
							};
						});
						if (postdata.comment.type == "unflag") {
							this.setState(prvState => ({
								tab_count: {
									...prvState.tab_count,
									flaggedCount: prvState.tab_count.flaggedCount - 1,
								}
							}))
						} else if (postdata.comment.type == "flag") {
							this.setState(prvState => ({
								tab_count: {
									...prvState.tab_count,
									flaggedCount: prvState.tab_count.flaggedCount + 1,
								}
							}))
						}
					} else {
						setTimeout(() => {
							toast.error(response.message);
						}, 200);
					}
				} else {
					setTimeout(() => {
						toast.error("Internal error occured. Please contact support");
					}, 200);
				}
			});
		}
	};

	transfer_amt = (event) => {
		event.preventDefault();

		let postData = {
			to_customer_id: this.state.selectedReceiver[0]._id,
			from_customer_id: this.state.from_id,
			display_data: {
				from_name: this.state.selectedPayer?.business_name, from_mobile: this.state.selectedPayer?.mobile,
				to_name: this.state.selectedReceiver[0].type == 2 ? this.state.selectedReceiver[0].business_name : this.state.selectedReceiver[0].displayName, to_mobile: this.state.selectedReceiver[0].mobile
			},
			from_walletType: 2,
			//to_walletType: this.state.to_walletType, //  depends upon user selected
			to_walletType: this.state.selectedReceiver[0].type,
			description: this.state.for_message,
			payTotal: this.state.transf_amt,
			actual_pay_amount: this.state.transf_amt,
			pay_charges: 0,
			ipay_pin: this.state.ipay_pin,
			flag_note: { type: "normal", note: this.state.additional_notes, user: this.state.adminUser.email, date: new Date() },
			request_token: "",
		};

		// console.log(postData);
		client.post(api.transfer_to_wallet, postData, (error, response) => {
			if (!error) {
				if (!response.error) {
					// console.log(response);
					window.location.reload(false);
					toast.success(response.message);

				} else {
					// console.log(response);
					setTimeout(() => {
						toast.error(response.message);
					}, 200);
				}
			} else {
				console.log(error);
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
		})
	}

	send_mail_toggle() {
		this.setState({ send_mail_modal: !this.state.send_mail_modal });
	}

	set_user_mail_id(merchant_id) {
		this.setState({ send_to_mer_id: merchant_id._id, bulkMail: false, userDataForPromotion: merchant_id });
	}

	handle_template_select = async (e) => {
		this.setState({
			send_templ_mail_id: e.target.value,
		});
		//console.log(this.state.send_templ_mail_id);
	};

	handle_country_select = async (e) => {
		this.setState({ selected_country: { value: e.value, label: e.value } });
	};

	handle_template_select_sms = async (e) => {
		console.log(e, "sms template value ");
		let id = e.value;
		let _ = this.state.sms_templates.filter(e => e._id == id);
		let message = _?.[0]?.templ_message || "";
		if (message) {
			try {
				if (message.includes("[minisite_link]")) {
					let minisiteLink = window.location.hostname == "localhost" ? `http://localhost:3000/${this.state?.userDataForPromotion?.unique_id}` : window.location.hostname == "dm.admin.ipaypro.co" ? `https://dm.linky.biz/${this.state?.userDataForPromotion?.unique_id}` : window.location.hostname == "admin.ipaypro.co" ? `https://linky.biz/${this.state?.userDataForPromotion?.unique_id}` : ``;
					message = message.replace('[minisite_link]', minisiteLink);
				}
				if (message.includes("[store_name]")) {
					let business_name = this.state?.userDataForPromotion?.business_name || "";
					message = message.replace('[store_name]', business_name);
				}
				if (message.includes("[first_name]")) {
					let firstName = this.state?.userDataForPromotion?.name?.split(" ")?.[0] || "";
					message = message.replace('[first_name]', firstName);
				}
				if (message.includes("[last_name]")) {
					let arr = this.state?.userDataForPromotion?.name?.split(" ") || [];
					let siftedElem = arr?.shift();
					let lastName = arr?.join(" ");
					message = message.replace('[last_name]', lastName);
				}
			} catch (e) {

			}
			this.setState({
				send_templ_sms_id: { value: e.value, label: e.label },
				sms_message: message,
			});
		}
	};

	previewEmailComp = () => {
		let mailLayout = this.state.mail_templates?.filter(e => e._id == this.state.send_templ_mail_id)
		let fMail = mailLayout?.[0]?.templ_layout
		fMail = fMail?.replace(/\n/g, "")
		fMail = fMail?.replace(/\t/g, "")
		let replace_items_arr = {
			'[minisite_url]': window.location.hostname == "localhost" ? `http://localhost:3000/${this.state?.userDataForPromotion?.unique_id}` : window.location.hostname == "dm.admin.ipaypro.co" ? `https://dm.linky.biz/${this.state?.userDataForPromotion?.unique_id}` : window.location.hostname == "admin.ipaypro.co" ? `https://linky.biz/${this.state?.userDataForPromotion?.unique_id}` : ``,
			'[customer_name]': this.state?.userDataForPromotion?.userData?.name,
			'[mobile_no]': this.state?.userDataForPromotion?.mobile,
			'[email_id]': this.state?.userDataForPromotion?.userData?.email,
			'[business_name]': this.state?.userDataForPromotion?.business_name,
			'[first_name]': this.state?.userDataForPromotion?.name?.indexOf(" ") > 0 ? this.state?.userDataForPromotion?.name?.slice(0, this.state?.userDataForPromotion?.name?.indexOf(" ")) : this.state?.userDataForPromotion?.name,
		}
		// console.log(replace_items_arr, "replace_items_arr")
		// var templ_subject = mail_template_data.templ_subject;
		//console.log('mail str = '+mail_str);
		for (var key in replace_items_arr) {
			if (!replace_items_arr.hasOwnProperty(key)) {
				continue;
			}
			//mail_str = mail_str.replace(new RegExp(key, "g"), replace_items_arr[key]);
			// mail_str =  mail_str.replace(key, replace_items_arr[key]);
			fMail = fMail.split(key).join(replace_items_arr[key]);
			//   templ_subject = templ_subject.split(key).join(replace_items_arr[key]);
		}
		return (
			<NewWindow title="Email Preview" center="screen" onUnload={() => this.setState({ previewEmail: false })}>
				<div dangerouslySetInnerHTML={{ __html: fMail }} />
			</NewWindow>
		)
	}

	renderMailModal = () => {
		const { mail_templates, sms_templates } = this.state;
		return (
			<Modal
				isOpen={this.state.send_mail_modal}
				toggle={() => { this.send_mail_toggle() }}
				className="modal-body"
				centered={true}
				style={{ maxWidth: "550px" }}
			>
				<form className="theme-form">
					<ModalHeader
						toggle={() => { this.send_mail_toggle() }}
						style={{ textAlign: "center" }}
					>
						Communicate with merchant
					</ModalHeader>
					<ModalBody>
						<div style={{ borderRadius: "10px" }}>
							<style>
								{
									`.select_style {
                                    border: unset;
                                    appearance: none;
                                    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
                                    background-repeat: no-repeat;
                                    background-position: right 0.7rem top 50%;
                                    background-size: 0.65rem auto;
                                }								
								`
								}
							</style>
							<div
								className="rounded"
								style={{ backgroundColor: "#D3D3D3", padding: "20px" }}
							>
								<div className="d-flex align-items-center">
									<div>
										Please select your option
									</div>
									<div className="d-flex ml-3" style={{ gap: "50px" }}>
										<div className="d-flex" style={{ gap: "10px" }}>
											<input
												type="radio"
												id="selectedCommunicateOption-email"
												name="selectedCommunicateOption"
												value="email"
												checked={this.state.selectedCommunicateOption === "email"}
												onChange={e => this.setState({
													selectedCommunicateOption: e.target.value
												})}
											/>
											<label className="m-0" htmlFor="selectedCommunicateOption-email">Email</label>
										</div>
										<div className="d-flex" style={{ gap: "10px" }}>
											<input
												type="radio"
												name="selectedCommunicateOption"
												id="selectedCommunicateOption-sms"
												value="sms"
												checked={this.state.selectedCommunicateOption === "sms"}
												onChange={e => this.setState({
													selectedCommunicateOption: e.target.value
												})}
											// className="form-check-input"
											/>
											<label className="m-0" htmlFor="selectedCommunicateOption-sms">
												SMS
											</label>
										</div>
									</div>
								</div>
								{this.state.selectedCommunicateOption == "email" ? <div className="email-div">
									<div className="d-flex mt-3 row">
										<label className="col-4 col-form-label" htmlFor="exampleFormControlSelect9">
											Mail Template :
										</label>
										<div className="col-8">
											<select
												className="form-control digits select_style"
												id="exampleFormControlSelect9"
												name="selected_template"
												value={this.state.send_templ_mail_id}
												onChange={(e) => this.handle_template_select(e)}
												required
												style={{ height: "36px", fontSize: "14px", textTransform: "capitalize" }}
											>
												<option value="">select template</option>
												{mail_templates
													? mail_templates.filter(e => e?.showInPromotionList).map((temp_item, index) => (
														<option style={{ padding: "0.5rem" }} value={temp_item._id} key={temp_item._id}>
															{temp_item.templ_name}
														</option>
													))
													: null}
											</select>
											{
												this.state.send_templ_mail_id == "select" || this.state.send_templ_mail_id == "" ? null : <p className="mb-0" style={{ cursor: "pointer", color: "#327ebc" }} onClick={() => this.setState({ previewEmail: true })}>Preview</p>
											}
										</div>
									</div>

									<div className="mt-3 d-flex align-items-center row">
										<div className="d-flex align-items-center col-4">
											<input
												type="checkbox"
												checked={this.state.sendToStoreEmail}
												onChange={e => this.setState({ sendToStoreEmail: e.target.checked })}
												style={{ height: "17px", width: "17px", marginRight: "0.4rem" }}
											/>
											Store Email :
										</div>
										<div className="d-flex align-items-center col-8">
											<input type="text" style={{ height: "36px", padding: '0.5rem', width: "100%" }} disabled value={this.state.userDataForPromotion?.email} />
										</div>
									</div>

									<div className="mt-3 row">
										<div className="d-flex align-items-center col-4">
											<input
												type="checkbox"
												checked={this.state.sendToUserEmail}
												onChange={e => this.setState({ sendToUserEmail: e.target.checked })}
												style={{ height: "17px", width: "17px", marginRight: "0.4rem" }}
											/>
											User Email :
										</div>
										<div className="d-flex align-items-center col-8" >
											<input type="text" style={{ height: "36px", padding: '0.5rem', width: "100%" }} disabled value={this.state.userDataForPromotion?.userData?.email} />
										</div>
									</div>

									<div className="mt-3 ">
										<div className="row">
											<div className="d-flex align-items-center col-4">
												<input
													type="checkbox"
													checked={this.state.sendToCustomEmail}
													onChange={e => this.setState({ sendToCustomEmail: e.target.checked })}
													style={{ height: "17px", width: "17px", marginRight: "0.4rem" }}
												/>
												Email :
											</div>
											<div className="d-flex align-items-center col-8">
												<input
													type="text"
													value={this.state.addedEmailForPromotion}
													onChange={e => {
														if (this.state.email_message_error != "") {
															this.setState({ email_message_error: "" })
														}
														this.setState({
															addedEmailForPromotion: e.target.value
														})
													}}
													style={{ height: "36px", padding: '0.5rem', width: "100%" }}
												/>
											</div>
										</div>
										{this.state.email_message_error && <p style={{ color: "red", textAlign: "center" }}>{this.state.email_message_error}</p>}
									</div>

								</div> : <div className="sms-div">

									<div className="my-3 row">
										<div className="d-flex align-items-center col-4">
											<label style={{ fontWeight: "500", marginBottom: "0.2rem" }}>
												From (Sender ID)
											</label>
										</div>
										<div className="form__form-group-input-wrap d-flex align-items-center col-8" style={{ position: "relative" }}>
											<input
												type="text"
												disabled={true}
												value={"Linky"}
												className="sms_modal_input text-capitalize"
												style={{ height: "36px", padding: '0.5rem', width: "100%" }}
											/>
										</div>
									</div>

									<div className="mt-3 row">
										<div className="d-flex align-items-center col-4" >
											<input
												type="checkbox"
												checked={this.state.sendToStoreSMS}
												onChange={e => this.setState({ sendToStoreSMS: e.target.checked })}
												style={{ height: "17px", width: "17px", marginRight: "0.5rem" }}
											/>
											Store Number :
										</div>
										<div className="d-flex align-items-center col-8" >
											<input
												type="text"
												disabled
												value={`${this.state.userDataForPromotion?.country?.phone_prefix} ${this.state.userDataForPromotion?.mobile}`}
												style={{ height: "36px", padding: '0.5rem', width: "100%" }}
											/>
										</div>
									</div>

									<div className="mt-3">
										<div className="row">
											<div className="d-flex align-items-center col-4">
												<input
													type="checkbox"
													checked={this.state.sendToCustomSMS}
													onChange={e => this.setState({ sendToCustomSMS: e.target.checked })}
													style={{ height: "17px", width: "17px", marginRight: "0.5rem" }}
												/>
												Number :
											</div>
											<div className="d-flex align-items-center col-8 " style={{ width: "min-content" }}>
												{/* <select
													// className="col-sm-12 form-control digits"
													// id="exampleFormControlSelect9"
													name="phone_select"
													style={{ height: "27px", width: "178px" }}
													value={this.state.selected_country}
													onChange={(e) => this.handle_country_select(e)}
													required
												>
													<option value="">Select Country</option>
													{this.state.mobOptions?.length
														? this.state.mobOptions.map((temp_item) => (
															<option value={temp_item.country_code}>
																{temp_item.label}
															</option>
														))
														: null}
												</select> */}
												<CustomPhoneSelect
													name="mobile_prefix"
													custom_style={true}
													value={this.state.selected_country}
													onChange={this.handle_country_select}
													options={
														this.state.mobOptions?.length
															? this.state.mobOptions.map((temp_item) => {
																return { value: temp_item.country_code, label: temp_item.label }
															}) : []
													}
												/>
												<input
													type="text"
													className="input-wihtout-focus-change"
													value={this.state.addedSMSForPromotion}
													onChange={e => {
														if (this.state.sms_message_error != "") {
															this.setState({ sms_message_error: "" })
														}
														this.setState({ addedSMSForPromotion: e.target.value })
													}}
													style={{ height: "36px", padding: '0.5rem', width: "100%" }}

												/>
											</div>
											{this.state.sms_message_error && <p style={{ color: "red", textAlign: "center" }}>{this.state.sms_message_error}</p>}
										</div>
									</div>

									<div
										className="my-3 row"
										style={{}}
									>
										<div className="col-12 d-flex flex-column align-items-start form__form-group-input-wrap form__form-group-input-wrap--error-above" style={{ marginBottom: 0 }}>
											<div className="w-100 d-flex align-items-center justify-content-between">
												<label style={{ fontWeight: "500", marginBottom: "0.2rem" }}>Message <span style={{ color: '#ff0000' }}>*</span></label>
												<label style={{ fontWeight: "500", marginBottom: "0.2rem" }}> {this.state.sms_message.length} chars / {this.state.total_sms} SMS </label>
											</div>
											<textarea
												className="w-100 mini_site_biz_input textarea-custom-h border p-2 textarea-focus-visible-none my-1"
												defaultValue={this.state?.sms_message}
												value={this.state?.sms_message}
												onBlur={(e) => this.calculateSMSCharges()}
												onChange={(e) => {
													if (e.target.value.length <= 306) {
														this.setState({ sms_message: e.target.value })
													}
												}} maxLength={306} style={{ borderRadius: '5px', padding: '1rem', border: '1px solid #c3c3c3', color: 'rgb(49, 49, 49)' }}
											></textarea>
											<span>{this.state.sms_message.length}/306 character limit</span>
										</div>
									</div>

									<div className="my-3">
										<div className=" row d-flex align-items-center form__form-group-input-wrap form__form-group-input-wrap--error-above" style={{ marginBottom: 0 }}>
											<div className="col-4 d-flex align-items-center justify-content-between">
												<label style={{ fontWeight: "500", marginBottom: "0.2rem" }}>SMS Template <span style={{ color: '#ff0000' }}>*</span></label>
											</div>
											<div className="col-8">
												{/* <select
													className="form-control digits select_style"
													id="exampleFormControlSelect99"
													name="selected_template_sms"
													style={{ height: "36px", fontSize: "14px", textTransform: "capitalize" }}
													value={this.state.send_templ_sms_id}
													onChange={(e) => this.handle_template_select_sms(e)}
													required
												>
													<option value="">select template</option>
													{sms_templates
														? sms_templates.map((temp_item, index) => (
															<option className="select_option" value={temp_item._id} key={temp_item._id}>
																{temp_item.templ_name}
															</option>
														))
														: null}
												</select> */}
												<CustomPhoneSelect
													name="selected_template_sms"
													value={this.state.send_templ_sms_id}
													onChange={this.handle_template_select_sms}
													options={
														sms_templates && sms_templates.length > 0
															? sms_templates.map((temp_item) => {
																return { value: temp_item._id, label: temp_item.templ_name }
															}) : []
													}
												/>
											</div>

										</div>
									</div >
									<div className="row">
										<div className="col-12 w-100 d-flex align-items-center justify-content-between" style={{}}>
											<span style={{ fontWeight: 500, fontSize: '15px' }}>Total</span>
											<span style={{ fontWeight: 500, fontSize: '15px' }}> {this.state.totalChrages} </span>
											{/* <span style={{ fontWeight: 500, fontSize: '15px' }}> {this.ShowCurrencyNum(totalCharge, true)} </span> */}
										</div>
									</div>


								</div>}
								<br />
							</div>
						</div>
						<br />
						{
							this.state.selectedCommunicateOption == "email" ? <h6 style={{ fontSize: "15px" }}>Hey, Are you sure you want to continue sending the email?</h6> : <h6 style={{ fontSize: "15px" }}>Hey, Are you sure you want to continue sending the sms?</h6>
						}
					</ModalBody>
					<ModalFooter>
						<Button
							color="default"
							onClick={() => {
								this.send_mail_toggle();
							}}
						>
							No Thanks
						</Button>
						<Button
							color="primary"
							type="submit"
							onClick={(e) => {
								this.onSendMailToMerchant(e);
							}}
						>
							Yes Please
						</Button>
					</ModalFooter>
				</form>
			</Modal>
		);
	};

	onSendMailToMerchant = async (e) => {
		e.preventDefault();

		this.setState({ loading: true });
		let data = null;
		let apiPath = null;

		if (this.state.bulkMail == false && this.state.selectedCommunicateOption == "email") {

			let getSelectedTemp = this.state.mail_templates.filter((currData) => currData._id == this.state.send_templ_mail_id);
			console.log(getSelectedTemp, "getSelectedTemp");
			data = {
				templ_id: this.state.send_templ_mail_id,
				user_id: this.state.send_to_mer_id,
				addedEmailForPromotion: this.state.addedEmailForPromotion,
				sendToCustomEmail: this.state.sendToCustomEmail,
				sendToStoreEmail: this.state.sendToStoreEmail,
				sendToUserEmail: this.state.sendToUserEmail,
				storeEmail: this.state?.userDataForPromotion?.email,
				userEmail: this.state?.userDataForPromotion?.userData?.email,
				adminEmail: this.state?.adminUser?.email,
				replace_items_arr: {
					'[minisite_url]': window.location.hostname == "localhost" ? `http://localhost:3000/${this.state?.userDataForPromotion?.unique_id}` : window.location.hostname == "dm.admin.ipaypro.co" ? `https://dm.linky.biz/${this.state?.userDataForPromotion?.unique_id}` : window.location.hostname == "admin.ipaypro.co" ? `https://linky.biz/${this.state?.userDataForPromotion?.unique_id}` : ``,
					'[customer_name]': this.state?.userDataForPromotion?.userData?.name,
					'[mobile_no]': this.state?.userDataForPromotion?.mobile,
					'[email_id]': this.state?.userDataForPromotion?.userData?.email,
					'[business_name]': this.state?.userDataForPromotion?.business_name,
					'[first_name]': this.state?.userDataForPromotion?.name?.indexOf(" ") > 0 ? this.state?.userDataForPromotion?.name?.slice(0, this.state?.userDataForPromotion?.name?.indexOf(" ")) : this.state?.userDataForPromotion?.name,
				}
			};
			apiPath = api.send_mail_to_user;
			console.log(data, "mail post data")
		} else if (this.state.bulkMail == false && this.state.selectedCommunicateOption == "sms") {
			data = {
				user_id: this.state.send_to_mer_id,
				addedSMSForPromotion: this.state.addedSMSForPromotion,
				sendToCustomSMS: this.state.sendToCustomSMS,
				sendToStoreSMS: this.state.sendToStoreSMS,
				storeSMS: {
					mobile: this.state?.userDataForPromotion?.mobile,
					phone_prefix: this.state?.userDataForPromotion?.country?.phone_prefix
				},
				selected_country: this.state.selected_country.value,
				sms_message: this.state.sms_message,
				totalCharges: this.state.totalChrages,
				adminEmail: this.state?.adminUser?.email,
			};
			apiPath = api.send_sms_to_user;
		} else if (this.state.bulkMail == true) {
			data = { templ_id: this.state.send_templ_mail_id, users_id: this.state.selectedIDs };
			apiPath = api.send_bulk_mail;
		}

		if (this.state.selectedCommunicateOption == "email" && this.state.send_templ_mail_id == "select" || this.state.send_templ_mail_id == "") {
			toast.error("Please Select  a Template");
			return false;
		} else if (this.state.selectedCommunicateOption == "email" && this.state.sendToStoreEmail == false && this.state.sendToUserEmail == false && this.state.sendToCustomEmail == false) {
			toast.error("Please Select atleast one email option");
			return false;
		} else if (this.state.selectedCommunicateOption == "email" && this.state.sendToCustomEmail == true && this.state.addedEmailForPromotion == "") {
			this.setState({ email_message_error: "Enter email id" });
			return false;
		} else if (this.state.selectedCommunicateOption == "email" && this.state.sendToCustomEmail == true && this.state.addedEmailForPromotion != "" && !this.state.addedEmailForPromotion.match(/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/)) {
			this.setState({ email_message_error: "Enter valid email id" });
			return false;
		} else if (this.state.selectedCommunicateOption == "sms" && this.state.sendToStoreSMS == false && this.state.sendToCustomSMS == false) {
			toast.error("Please Select atleast one sms option");
			return false;
		} else if (this.state.selectedCommunicateOption == "sms" && this.state.sendToCustomSMS == true && this.state.addedSMSForPromotion == "") {
			this.setState({ sms_message_error: "Enter phone number" });
			return false;
		} else if (this.state.selectedCommunicateOption == "sms" && this.state.sms_message == "") {
			toast.error("SMS message is empty");
			return false;
		} else if (data !== "") {
			// console.log(apiPath);
			client.post(apiPath, data, async (error, response) => {
				if (!error) {
					if (typeof response.error !== "undefined") {
						try {
							if (!response.error) {
								this.refreshPage();
								if (this.state.selectedCommunicateOption == "sms") {
									toast.success("SMS Sent Successfully");
								} else {
									toast.success("Mail Sent Successfully");
								}
								this.setState({ send_mail_modal: false });
							} else {
								toast.error(response.message);
							}
						} catch (err) {
							toast.error(err.message);
						}
					} else {
						toast.error("Internal error occured. Please contact support 2");
					}
				} else {
					toast.error("Internal error occured. Please contact support 3");
				}
				this.setState({ loading: false });
			});
		}
	};

	renderInfoModal = (id) => {
		const foundCustomer = this.state.infoItem;
		// console.log(foundCustomer, "foundCustomer");
		try {
			if (foundCustomer) {
				if (typeof foundCustomer.comment == "undefined") {
					foundCustomer.comment = [];
				}
				let foundCustomerComment = [...foundCustomer.comment].reverse();
				return (
					<Modal isOpen={this.state.infoModal} toggle={this.toggleInfoModal} className="modal-body" centered={true}>
						<h5 style={{ padding: "15px", margin: "0" }}>Comments</h5>

						<ModalBody style={{ paddingTop: "0", paddingBottom: "0", maxHeight: "calc(100vh - 370px)", overflowY: "auto" }}>
							<div className="card-body chat-box" style={{ paddingTop: "0", paddingBottom: "0" }}>
								<div className="chat-right-aside bitcoin-chat">
									<div className="chat">
										<div className="chat-history chat-msg-box custom-scrollbar" style={{ padding: "0", margin: "0" }}>
											<ul style={{ margin: "0" }}>
												{foundCustomerComment.length != 0 ? (
													foundCustomerComment.map((el) => {
														return (
															<li style={{ margin: "0" }}>
																<div
																	className="message my-message "
																	style={{ marginBottom: "10px", paddingTop: "10px", paddingBottom: "10px" }}
																>
																	<p>
																		{el.user}
																		<span className="message-data-time pull-right">{this.render_date(el.date)}</span>{" "}
																	</p>
																	{el.type == "normal" || el.type == "status" ? null : <p style={{ fontSize: "0.9rem" }}>Type : {el.type}</p>}
																	{el.note}
																</div>
															</li>
														);
													})
												) : (
													<p>No comment added!</p>
												)}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</ModalBody>
						<ModalFooter style={{ paddingTop: "10px", paddingBottom: "10px" }}>
							<div className="col-lg-12">
								<form className="theme-form">
									<div className="form-group mb-0 col-12">
										<label htmlFor="exampleFormControlTextarea4">Comment:</label>
										<textarea
											className="form-control"
											id="exampleFormControlTextarea4"
											required
											rows="3"
											onChange={this.flagNoteHandler}
										></textarea>
									</div>
									<div style={{ padding: "15px 0 0 15px" }}>
										<Button color="default" style={{ marginRight: "15px" }} onClick={this.toggleInfoModal}>
											Cancel
										</Button>
										<Button
											color="primary"
											type="submit"
											name="comment"
											onClick={(event) => this.flagSubmitHandler(event, this.state.selectedId, foundCustomer)}
										>
											Submit
										</Button>
									</div>
								</form>
							</div>
						</ModalFooter>
					</Modal>
				);
			} else {
				return null;
			}
		} catch {
			return null;
		}
	};

	renderReceiverDetails = () => {
		// console.log('receiver',this.selectedReceiver)
		return (
			<div className="table-responsive">
				<table className="table table-border-horizontal table-striped">
					<thead>
						<tr >
							<th scope="col" >#</th>
							<th scope="col" >Name</th>
							<th scope="col" >i-Pay ID</th>
							<th scope="col" >Email</th>
							<th scope="col" >Mobile</th>
							<th scope="col" >Balance</th>
						</tr>
					</thead>
					<tbody>
						{this.state.selectedReceiver.map((item, index) => {
							return (
								<tr >
									<th scope="row" >{index + 1}</th>
									<td >{item.type == 2 ? item.business_name : item.name}</td>
									<td >{item.ipay_id}</td>
									<td >{item.email}</td>
									<td >{item.mobile}</td>
									<td >{item.country.currency_symbol}{item.wallet[0].wallet_amount.toFixed(2)}</td>
								</tr>

							);
						})}


					</tbody>
				</table>
			</div>
		)
	}

	renderTransferModal = (id) => {
		if (this.state.from_id == null) {
			// console.log(this.state.from_id,"from_id")
			this.setState({ from_id: "5f12aad64595d48863177243" })
		}
		if (this.state.transferItem) {
			// console.log(this.state.transferItem.displayName,'storename')
		}
		if (this.state.transferModal && !this.state.selectedReceiver) {
			let result = this.apiCall(this.state.transferItem?.business_name)
			// console.log(result,"richa")
		}

		// this.setState((prev)=>{
		// 	return{
		// 		...prev,
		// 		selectedReceiver:this.apiCall("Store002")
		// 	}
		// })
		// console.log(id,"renderid")
		// console.log(this.state.transferItem,"transferItem")
		// console.log(this.state.transferItem?.id,"item-transfer")

		return (
			<Modal isOpen={this.state.transferModal} toggle={this.toggleTransferModal} className="modal-body" centered={true}>
				<form className="theme-form" noValidate="" onSubmit={this.transfer_amt}>
					<ModalHeader toggle={this.toggleTransferModal}>Transfer</ModalHeader>
					<ModalBody>
						<div className="row">
							<div className="col">
								<div className="form-group col-md-12 ">
									<label>From</label>
									<select className="form-control" name="from_id" value={this.state.from_id} defaultValue="5f12aad64595d48863177243" onChange={this.changeHandler} >
										<option value='5f12aad64595d48863177243'>i-Pay Australia, Balance Remaining ${this.state.ipay_wallet_amt?.toFixed(2)}</option>
										<option value='5f11e6b812caca165455b085'>Speed Food,  Balance Remaining ${this.state.speed_food_wallet_amt?.toFixed(2)}</option>
									</select>
								</div>
							</div>
						</div>
						<div className="row" style={{ margin: "0" }}>
							<div className="col">
								<div className="form-group col-l-6">
									<label>To</label>
									{/* <select className="form-control" name="from" defaultValue="merchant_name" onChange={(event) => {
										this.setState({ accountType: event.target.value })
									}}>
										<option value='customer_name'>Customer name </option>
										<option value='merchant_name'>Store name</option>
									</select> */}
									<div style={{
										border: '1px solid #eff0f1',
										height: '37px',
										padding: '5px',
										color: 'gray',
										borderRadius: '2px',
										display: 'flex',
										alignItems: 'center',
									}}
									>Store name</div>
								</div>
							</div>
							<div className="col">
								<div className="form-group col-l-6 ">
									<label>To</label>
									{/* <Typeahead
                    id="public-typeahead"
                    defaultSelected={props.userdata.slice(0, 0)}
                    labelKey={searchUserFilter}
                    options={filteredUserData}
                    placeholder="User detail"
                    onChange={handleChange}
                  /> */}
									{/* <AsyncTypeahead
                    id="public-typeahead"
                    labelKey={this.state.accountType == "customer_name" ? `name` : `business_name`}
                    filterBy={() => true}
                    onSearch={term => {
                      // console.log(term, "term")
                      this.fetchFilterResult(term);
                      // setValue(term);
                    }}
                    onChange={(res) => {

                      // console.log(event)
                      this.handleChange(res)

                    }
                    }
                    placeholder="User detail"
                    // isLoading={this.state.fetchFilterResultLoading}
                    // options={this.state.filteredOptions}
                  /> */}
									<div style={{
										border: '1px solid #eff0f1',
										height: '37px',
										padding: '5px',
										color: 'gray',
										borderRadius: '2px',
										display: 'flex',
										alignItems: 'center',
									}}
									>{this.state.transferItem?.business_name}</div>
								</div>
							</div>
						</div>
						<div className="row receiver" style={{ marginBottom: "15px" }}>
							<div className="col-md-12">
								{this.state.selectedReceiver && this.renderReceiverDetails()}
							</div>

						</div>
						<div className="row">
							<div className="col">
								<div className="form-group col-md-12 ">
									<label>Amount</label>
									<input className="form-control" type="text" name='transf_amt' value={this.state.transf_amt} onChange={(event) => this.setState({ transf_amt: event.target.value })}></input>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<div className="form-group col-md-12 ">
									<label>Message(<span style={{ color: "red" }}>*Note:User will be able to view it in app</span>)</label>
									<input className="form-control" type="text" name='for_message' value={this.state.for_message} onChange={(event) => this.setState({ for_message: event.target.value })} ></input>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<div className="form-group col-md-12 ">
									<label>Additional Notes</label>
									<input className="form-control" type="text" name='additional_notes' value={this.state.additional_notes} onChange={(event) => this.setState(this.state.additional_notes = event.target.value)} ></input>
								</div>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button color="default" onClick={this.toggleTransferModal}>Not sure!</Button>
						<Button color="primary" type="submit"  >Yes, Please!</Button>
					</ModalFooter>
				</form>
			</Modal>

		);
	}

	renderDeleteSelectedModal = () => {
		return (
			<Modal
				isOpen={this.state.deleteSelectedModal}
				toggle={this.toggleDeleteSelectedModal}
				className="modal-body"
				centered={true}
			>
				<form className="theme-form" noValidate="" onSubmit={this.deleteSelectedHandler}>
					<ModalBody>
						<h4>Do you really want to delete these Merchants ?</h4>
					</ModalBody>
					<ModalFooter>
						<Button color="default" onClick={this.toggleDeleteSelectedModal}>
							Not sure
						</Button>
						<Button color="primary" type="submit">
							Yes, please!
						</Button>
					</ModalFooter>
				</form>
			</Modal>
		);
	};

	deleteSelectedHandler = (event) => {
		event.preventDefault();
		const postData = {
			user_id: this.state.selectedIDs,
			deletedFrom: 1,
			adminName: this.state.adminUser.full_name,
			deletedType: `delete_user`,
		}
		client.post(api.merchant_delete_account, postData, (error, response) => {
			if (!error) {
				if (!response.error) {
					toast.success(response.message);
					this.setState((prevState) => {
						return {
							...prevState,
							deleteSelectedModal: false,
							selectedIDs: []
						};
					});
					this.refreshPage();
				} else {
					setTimeout(() => {
						toast.error(response.message);
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
		});

		// const postData = { selectedIDs: this.state.selectedIDs };
		// client.post(api.delete_selected_merchant, postData, (error, response) => {
		// 	if (!error) {
		// 		if (!response.error) {
		// 			toast.success(response.message);
		// 			this.setState((prevState) => {
		// 				return {
		// 					...prevState,
		// 					deleteSelectedModal: false,
		// 				};
		// 			});
		// 			this.refreshPage();
		// 		} else {
		// 			setTimeout(() => {
		// 				toast.error(response.message);
		// 			}, 200);
		// 		}
		// 	} else {
		// 		setTimeout(() => {
		// 			toast.error("Internal error occured. Please contact support");
		// 		}, 200);
		// 	}
		// });
	};

	getComment = (item) => {
		// const customer = this.state.customers.find((el) => el._id == id);
		const comment = item?.comment?.find((item) => item.type == "block");
		//console.log(comment);
		if (comment) {
			return (
				<Fragment>
					<p>{comment.user}</p>
					<p style={{ height: "22px", overflow: "auto", margin: "0px" }}>{comment.note}</p>
				</Fragment>
			);
		} else {
			return;
		}
	};

	setStoreRefStatus = (data) => {

		let postdata = {
			merchant_id: data.storeData._id,
			storeRefData: data.storeRefStatus,
			user: this.state.adminUser.email
		};

		client.put(api.updateMerchantDetails, postdata, (error, response) => {
			if (!error) {
				if (!response.error) {
					// console.log(response);
					this.refreshPage();
				} else {
					setTimeout(() => {
						toast.error(response.message);
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
		});
	}


	toggleTableCollapse = (selectedStoreId) => {

		this.setState({
			selectedRow: !this.state.selectedRow,
			selectedMerchant: selectedStoreId
		});
	}

	rendertableRow = (user, item, index, tab, compData) => {

		const { access_level, fetchType } = this.state;
		let renderDate = user.added_date || user.createdAt//fetchType == 'store_details_pending' ||  ? user.createdAt : user.added_date;

		return (
			<>
				{user ? (
					<tr key={user._id}>
						<th scope="row">
							<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
								<p className="mb-0">{(this.state.activePage - 1) * this.state.pageSize + index + 1}</p>
								{
									this.state.screenWidth <= 1440 && <>
										<div
											className="d-flex align-items-center justify-content-center"
											style={{
												border: "1px solid #ccc",
												borderRadius: "50%",
												height: "21px",
												width: "21px",
											}}
											onClick={() => { this.toggleTableCollapse(user._id) }}
										>
											<div className="d-flex align-items-center justify-content-center"
												style={{
													borderRadius: "50%",
													height: "15px",
													width: "15px",
													backgroundColor: (this.state.selectedRow && this.state.selectedMerchant == user._id) ? "red" : "green"
												}}
											>
												{(this.state.selectedRow && this.state.selectedMerchant == user._id) ? <BiMinus style={{ color: "#fff", width: '15px', height: '15px' }} /> : <BsPlus style={{ color: "#fff", width: '15px', height: '15px' }} />}

											</div>
										</div>
									</>
								}
							</div>
						</th>
						<td style={{ width: "9%" }}>{this.render_date(renderDate)}</td>
						{
							tab != 1 ?
								<td>
									{<>
										<span>
											{user?.name?.length > 0 ? user?.name : user?.company !== undefined ? user?.company[0]?.contactInfo?.name : null}/<br />
										</span>
										<NavLink to={`/merchants/merchantDetails/${user._id}`} item={user}>
											{/* <span> {user?.ipay_id?.replace(/(.{13})/g, '$1\n')} </span> */}
											<span> {user?.ipay_id} </span>
											<br />
											<span>{user.location && user.location.suburb ? `/${user.location.suburb}` : ""} </span>
											{/* <br />
											<span>{user.country.code} </span> */}
										</NavLink>
									</>
									}
								</td>
								: null
						}
						{
							!(tab != 1) ?
								<td style={{ wordBreak: 'break-word', minWidth: '11vw' }}>
									{user?.name?.length > 0 ? user?.name : user?.company !== undefined ? user?.company[0]?.contactInfo?.name : 'NA'}
								</td> : null

						}

						{
							tab != 1 ?
								<td style={{ wordBreak: 'break-word', minWidth: '10vw' }}>
									<p>{user?.business_name}</p>
									<p>{user?.company !== undefined && user.company[0] ? user.company[0].displayName : "-"}</p>
									<span className="d-block" onClick={() => this.toggleAutoLogin(user, item)} style={{ color: '#D23F99', fontWeight: '500', textDecoration: 'underline' }}>Login to Portal</span>
									{/* <p>{user.abn}</p> */}
								</td>
								: null
						}
						{/* {
							tab != 1 ?
								<td style={{ wordBreak: 'break-word', minWidth: '13vw' }}>
									{user?.company !== undefined && user.company[0] ? user.company[0].displayName : "-"}
								</td> : null
						} */}
						{
							tab != 1 && this.state.screenWidth > 1440 ?
								<td style={{ wordBreak: 'break-word', minWidth: '10vw' }}>
									{user?.signup_source !== undefined && user?.signup_source ? user.signup_source : "-"}
								</td> : null
						}
						{
							tab != 1 && this.state.screenWidth > 1440 ?
								<td style={{ wordBreak: 'break-word', minWidth: '10vw' }}>
									{user?.signup_location?.osName && <p>{user?.signup_location?.osName == 'Apple' ? user?.signup_location?.browserName : user?.signup_location?.osName}</p>}
									{user?.signup_location?.latitude && <p>{user?.signup_location?.latitude}</p>}
									{user?.signup_location?.longitude && <p>{user?.signup_location?.longitude}</p>}
									{(user?.signup_location?.country_name) && <p>{user?.signup_location?.country_name}, {user?.signup_location?.country_code}</p>}
									{/* {(user?.signup_location?.city || user?.signup_location?.state || user?.signup_location?.country_name) && <p>{user?.signup_location?.city}, {user?.signup_location?.state}, {user?.signup_location?.country_name}, {user?.signup_location?.country_code}</p>} */}
									{user?.signup_location?.IPv4 && <p>{user?.signup_location?.IPv4}</p>}
								</td> : null
						}
						{this.state.screenWidth > 480 && <td style={{ wordBreak: 'break-word', minWidth: '15vw' }}>
							<a
								href={
									'https://' + (
										tab != 1 && user?.unique_id ?
											`${user?.brand_ID == 1 ? `${window?.location?.hostname == 'admin.ipaypro.co' ? 'ipaypro.co/' : 'dm.ipaypro.co/'}` + user.unique_id
												: user?.brand_ID == 2 ? `${window?.location?.hostname == 'admin.ipaypro.co' ? 'linky.biz/' : 'dm.linky.biz/'}` + user.unique_id
													: user?.brand_ID == 3 ? `${window?.location?.hostname == 'admin.ipaypro.co' ? 'hustlerz.co/' : 'dm.hustlerz.co/'}` + user.unique_id
														: `${window?.location?.hostname == 'admin.ipaypro.co' ? 'linky.biz/' : 'dm.linky.biz/'}` + user.unique_id}`
											: user?.linkyURL ? user.linkyURL
												: "Skipped")}

								className={(tab != 1 && user?.unique_id ? `${user?.brand_ID == 1 ? `${window?.location?.hostname == 'admin.ipaypro.co' ? 'ipaypro.co/' : 'dm.ipaypro.co/'}` + user.unique_id : user?.brand_ID == 2 ? `${window?.location?.hostname == 'admin.ipaypro.co' ? 'linky.biz/' : 'dm.linky.biz/'}` + user.unique_id : user?.brand_ID == 3 ? `${window?.location?.hostname == 'admin.ipaypro.co' ? 'hustlerz.co/' : 'dm.hustlerz.co/'}` + user.unique_id : `${window?.location?.hostname == 'admin.ipaypro.co' ? 'linky.biz/' : 'dm.linky.biz/'}` + user.unique_id}` : user?.linkyURL ? "userLink" : "SkippedLink")}
								target='_blank'>

								{
									tab != 1 && user?.unique_id ?
										`${user?.brand_ID == 1 ? `${window?.location?.hostname == 'admin.ipaypro.co' ? 'ipaypro.co/' : 'dm.ipaypro.co/'}` + user.unique_id
											: user?.brand_ID == 2 ? `${window?.location?.hostname == 'admin.ipaypro.co' ? 'linky.biz/' : 'dm.linky.biz/'}` + user.unique_id
												: user?.brand_ID == 3 ? `${window?.location?.hostname == 'admin.ipaypro.co' ? 'hustlerz.co/' : 'dm.hustlerz.co/'}` + user.unique_id
													: `${window?.location?.hostname == 'admin.ipaypro.co' ? 'linky.biz/' : 'dm.linky.biz/'}` + user.unique_id}` : user?.linkyURL ? user.linkyURL : "Skipped"}/

							</a>


							<br />
							{/* {tab != 1 || tab != 2 ? user.email.replace(/(.{23})/g, '$1\n') : user.email} */}
							{tab === 1 && fetchType == "store_details_pending" ? user?.email : user?.userData?.email}
							{
								tab == 0 && (user?.userData?.email_status == 0 || user?.userData?.email_status == 1) && (
									user?.userData?.email_status == 1 ?
										<span><i className="fa fa-thumbs-up" id="email-verified" style={{ color: "green", marginLeft: "5px" }}></i>
											<UncontrolledTooltip placement="top" target="email-verified">
												Email Verified
											</UncontrolledTooltip>
										</span> :
										<span onClick={() => this.changeISVerified(user._id, user?.userData?.email_status)}>
											<i className="fa fa-thumbs-down" id="not-verified" style={{ color: "red", marginLeft: "5px" }}></i>
											<UncontrolledTooltip placement="top" target="not-verified">
												Email not verified
											</UncontrolledTooltip>
										</span>
								)
							}
							<br />
							{/* {user?.mobile} */}
							<span style={{ letterSpacing: '0rem' }}>({user?.country?.phone_prefix})</span>{user?.mobile}
							{
								tab == 0 && (
									user?.mobile_status == 1 ?
										<span><i className="fa fa-thumbs-up" id="mobile-verified" style={{ color: "green", marginLeft: "5px" }}></i>
											<UncontrolledTooltip placement="top" target="mobile-verified">
												Mobile Verified
											</UncontrolledTooltip>
										</span> :
										<span onClick={() => this.changeISMobileVerified(user._id, user?.mobile_status)}>
											<i className="fa fa-thumbs-down" id="not-verified-mobile" style={{ color: "red", marginLeft: "5px" }}></i>
											<UncontrolledTooltip placement="top" target="not-verified-mobile">
												Mobile not verified
											</UncontrolledTooltip>
										</span>
								)
							}
						</td>}
						{this.state.screenWidth > 480 && <td>
							<div className="form__form-group my-3">
								<div className="form__form-group-field">
									<CustomDropDown
										name={`${user._id}.status`}
										defaultValue={user.storeRefStatus ? user.storeRefStatus : { value: 1, label: 'LEAD' }}
										onChange={(event) => {

											let postData = { storeData: user, storeRefStatus: event };
											this.setStoreRefStatus(postData);

										}}
										options={[
											{ value: 1, label: 'LEAD' },
											{ value: 2, label: 'CONTACTED' },
											{ value: 3, label: 'FOLLOW-UP' },
											{ value: 4, label: 'LOST' },
											{ value: 5, label: 'WON' }
										]}
										user={user}
									/>
								</div>
								<div
									className="mt-2"
									onClick={() => {
										this.send_mail_toggle();
										this.set_user_mail_id(user);
									}}
									style={{ color: '#327ebc' }}
								>
									Send email/sms
								</div>
							</div>
						</td>}
						{/* {tab != 1 && <td style={{ width: "15%" }}>
							{user.subscription_plan !== undefined && user.subscription_plan[0] ? user.subscription_plan[0].subscription.title : "-"}
						</td>} */}

						{(tab == 3 && this.state.screenWidth > 480) && <td>{typeof user.comment == "undefined" ? "" : this.getComment(user)}</td>}

						{/* {tab == 1 && (
							<td>
								{
									user.status == 0
										? "Approved"
										: user.status == 0
											? "Waiting"
											: user.status == 2
												? "Unapproved"
												: user.status == 5
													? "blocked"
													: null
								}
							</td>
						)} */}
						{/* {tab == 0 && <td>${user.wallets[0].wallet_amount.toFixed(2)}</td>} */}
						{(tab == 0 && this.state.screenWidth > 480) && <td style={{ width: '7%' }}>{user.country.currency_symbol} {user.wallets && user.wallets.length > 0 ? user.wallets[0].wallet_amount.toFixed(2) : null}</td>}
						{
							(access_level != 1 && this.state.screenWidth > 480) && (
								<td style={{ minWidth: "100px" }}>
									{/* {
									access_level == 3 && <span style={{ marginRight: "10px", fontSize: "0.75rem" }} onClick={() => this.toggleDeleteModal(user._id)}>
										<i style={{ color: "red" }} id="delete" className="fa fa-trash-o" ></i>
									</span>
								} */}
									{
										tab == 1 ?
											<NavLink to={`/merchants/inviteUserDetails/${user._id}`} item={user} className='more_btn'>
												More
											</NavLink>
											: null
									}
									{/* {tab == 1 || tab == 2 || tab == 0 ? (
										<span style={{ marginRight: "10px", cursor: "pointer", fontSize: "0.75rem" }}>
											<i
												className="fa fa-paper-plane"
												style={{ color: "#4466f2" }}
												onClick={() => {
													this.send_mail_toggle();
													this.set_user_mail_id(user);
												}}
											></i>
										</span>
									) : null} */}
									{tab == 0 || tab == 5 || tab == 3 || (tab == 4 && user.status == 5) || (tab == 2 && user.status == 5) || user.status == 1 ? (
										<span onClick={() => this.blockClick(user._id, user)} style={{ marginRight: "10px", fontSize: "0.75rem" }}>
											<i style={{ color: user.status == 5 ? "red" : "black" }} id="block" className="icofont icofont-ui-block"></i>
											<UncontrolledTooltip placement="bottom" target="block">
												{(tab == 4 && user.status == 5) || (tab == 2 && user.status == 5) ? "Unblock" : "Block"}
											</UncontrolledTooltip>
										</span>
									) : null}

									{tab == 0 || tab == 4 || tab == 3 || (tab == 4 && user.status == 1) || user.status == 5 ? (
										<span style={{ marginRight: "10px", fontSize: "0.75rem" }} onClick={() => this.flagClick(user._id, user)}>
											<i style={{ color: user.flag_status ? "red" : "black" }} id="flag" className="fa fa-flag-o"></i>
											<UncontrolledTooltip placement="bottom" target="flag">
												Flag
											</UncontrolledTooltip>
										</span>
									) : null}
									{/* {tab == 0 && (
										<img
											onClick={() => this.changeISVerified(user._id, user.email_status)}
											className="img-fluid"
											style={{
												width: "15px",
												cursor: "pointer",
												marginRight: "4px",
												boxShadow: user.is_verified == 2 ? "0px 0px 3px 3px #f4e79a" : null,
												boxShadow: user.is_verified == 2 ? "0px 0px 3px 3px #f4e79a" : null,
											}}
											// src={user.is_verified == 1 ? imgverified : user.is_verified == 2 ? imgverificationSent : imgunverified}
											src={user.email_status == 1 ? imgverified : imgunverified}
											alt={user.is_verified == 1 ? "Verified Merchant" : "Uverified Merchant"}
										/>
									)} */}
									{tab == 0 ||
										tab == 5 ||
										tab == 3 ||
										(tab == 4 && user.status == 1) ||
										(user.status == 5 && !user.business_form_filled) ? (
										<span style={{ marginRight: "10px", fontSize: "0.75rem" }} onClick={() => this.infoClick(user._id, user)}>
											<i style={{ color: "black" }} id="delete" className="fa fa-list-alt"></i>
											<UncontrolledTooltip placement="bottom" target="delete">
												Info
											</UncontrolledTooltip>
										</span>
									) : null}
									{tab == 0 || tab == 5 || tab == 3 || (tab == 4 && user.status == 1) || user.status == 5 ? (
										<span style={{ marginRight: "10px", fontSize: "0.75rem" }}>
											<NavLink to={`/merchants/merchantDetails/${user._id}`} item={user}>
												<VisibilityIcon style={{ fontSize: 15 }} />
											</NavLink>
										</span>
									) : null}
									{tab == 0 && user?.userData?.email_status == 1 &&
										<span style={{ marginRight: "10px", fontSize: "0.75rem" }} onClick={() => this.transferClick(user._id, user)}>
											<i style={{ fontSize: '1.1rem', color: '#008000' }} className="icofont icofont-exchange"></i>
										</span>
									}
									{access_level == 3 && (
										<span>
											<input
												type="checkbox"
												checked={this.state.selectedIDs.includes(user.adminId ? user.adminId : user._id)}
												onClick={(event) => this.deleteSelectedClick(event, user.adminId ? user.adminId : user._id)}
											/>
										</span>
									)}
								</td>
							)
						}
						{this.state.screenWidth > 480 && <td>
							{typeof user.comment != "undefined" && user.comment.length > 0 ? (
								<p style={{ height: "70px", minWidth: "", overflow: "auto", fontSize: "12px" }}>{user.comment[user.comment.length - 1].note}</p>
							) : (
								"N.A"
							)}
						</td>}
						{this.state.screenWidth > 1440 && <td>
							{typeof user.comment != "undefined" && user.comment.length > 0 ? (
								<>
									<p style={{ fontSize: "12px" }}>{user.comment[user.comment.length - 1].user}</p>
									<p style={{ fontSize: "12px" }}>{this.render_date(user.comment[user.comment.length - 1].date)}</p>
								</>
							) : (
								"N.A"
							)}
						</td>}
					</tr>
				) : (
					<tr key={user._id}>
						<th scope="row">{(this.state.activePage - 1) * this.state.pageSize + index + 1}</th>
						<td style={{ width: "9%" }}>{this.render_date(user.createdAt)}</td>
						<td style={{ width: "8%" }}>
							{user.name.length > 0 ? user?.name : user?.company[0]?.contactInfo?.name}
						</td>
						<td style={{ width: "15%" }}>
							{user.mobile}/<br />
							{user.email}
						</td>
						<td>
							{user.status == 0
								? "Approved"
								: user.status == 0
									? "Waiting"
									: user.status == 2
										? "Unapproved"
										: user.status == 5
											? "blocked"
											: user.status == 1
												? "Unverified"
												: null}
						</td>

						{access_level != 1 && (
							<td style={{ width: "12%" }}>
								{/* {access_level == 3 && <span style={{ marginRight: "10px",fontSize: "0.75rem"  }} onClick={() => this.toggleDeleteModal(item._id)}>
                        <i style={{ color: "red" }} id="delete" className="fa fa-trash-o" ></i>
                      </span>} */}
								{access_level == 3 && (
									<span>
										<input
											type="checkbox"
											checked={this.state.selectedIDs.includes(user.adminId)}
											onClick={(event) => this.deleteSelectedClick(event, user.adminId)}
										/>
									</span>
								)}
							</td>
						)}
						<td>
							{typeof user.comment != "undefined" && user.comment.length > 0 ? (
								<p style={{ height: "22px", overflow: "auto" }}>{user.comment[item?.user?.length - 1].note}</p>
							) : (
								"N.A"
							)}
						</td>
					</tr>
				)}

				{(this.state.selectedRow && this.state.selectedMerchant == user._id) && <>
					<tr>
						<td colSpan={10}>
							<table className="table table-border-horizontal" style={{ fontSize: "0.75rem", margin: this.state.screenWidth <= 480 ? "0.5rem" : "0.5rem 2.5rem", width: this.state.screenWidth <= 480 ? "100%" : "30%" }}>
								<thead>
									<tr style={{ background: '#f9fafe' }}>
										{/* <th scope="col" style={{ width: "2vw" }}>#</th>
										<th scope="col" style={{ width: "9%" }}>
											<p>Date</p>
											<p>Time</p>
										</th>
										{tab != 1 && <th scope="col">Admin Name/ <br />	iPayPro ID</th>}
										{!(tab != 1) && <th scope="col" style={{ width: "10%" }}>Admin Name</th>}
										{tab != 1 && <th scope="col">Store Name / Company Name</th>} */}
										{/* <th scope="col" style={{ width: "11%" }}>
														            <p>Landline</p>
														            <p>Mobile</p>
													                </th> */}
										{/* {tab != 1 && <th scope="col">Company Name</th>} */}
										<th scope="col">Updated by</th>
										{tab != 1 && <th scope="col">Source</th>}
										{tab != 1 && <th scope="col" style={{ width: "15%" }}>Device / Location / IP	</th>}
										{this.state.screenWidth <= 480 && <th scope="col" style={{ width: "15%" }}>
											<div>Minisite / User Email / Store Number</div>
										</th>}
										{this.state.screenWidth <= 480 && <th scope="col" style={{ width: "15%" }}>Status</th>}
										{/* {tab != 1 && <th scope="col" style={{ width: "15%" }}>
																Plan
															</th>} */}

										{/* {tab == 1 && <th scope="col">Status</th>} */}
										{(tab == 3 && this.state.screenWidth <= 480) && (
											<th scope="col">
												<p>Blocked by</p>
												<p>Reason</p>
											</th>
										)}
										{(tab == 0 && this.state.screenWidth <= 480) && <th scope="col">Balance</th>}
										{(access_level != 1 && this.state.screenWidth <= 480) && (
											<th scope="col" style={{ width: tab == 1 ? '20%' : '12%' }}>
												Action
											</th>
										)}
										{this.state.screenWidth <= 480 && <th scope="col">Notes</th>}
									</tr>
								</thead>

								<tbody>
									<tr key={user._id}>

										{/* {
											!(tab != 1) ?
												<td style={{ wordBreak: 'break-word', minWidth: '11vw' }}>
													{user?.name?.length > 0 ? user?.name : user?.company !== undefined ? user?.company[0]?.contactInfo?.name : 'NA'}
												</td> : null
										} */}

										{/* {
											tab != 1 && this.state.screenWidth <= 480 ?
												<td style={{ wordBreak: 'break-word', minWidth: '10vw' }}>
													<p>{user?.business_name}</p>
													<p>{user?.company !== undefined && user.company[0] ? user.company[0].displayName : "-"}</p>
												</td>
												: null
										} */}
										{/* {
							tab != 1 ?
								<td style={{ wordBreak: 'break-word', minWidth: '13vw' }}>
									{user?.company !== undefined && user.company[0] ? user.company[0].displayName : "-"}
								</td> : null
						} */}
										{<td>
											{typeof user.comment != "undefined" && user.comment.length > 0 ? (
												<>
													<p style={{ fontSize: "12px" }}>{user.comment[user.comment.length - 1].user}</p>
													<p style={{ fontSize: "12px" }}>{this.render_date(user.comment[user.comment.length - 1].date)}</p>
												</>
											) : (
												"N.A"
											)}
										</td>}
										{
											tab != 1 ?
												<td style={{ wordBreak: 'break-word', minWidth: '10vw' }}>
													{user?.signup_source !== undefined && user?.signup_source ? user.signup_source : "-"}
												</td> : null
										}
										{
											tab != 1 ?
												<td style={{ wordBreak: 'break-word', minWidth: '10vw' }}>
													{user?.signup_location?.osName && <p>{user?.signup_location?.osName == 'Apple' ? user?.signup_location?.browserName : user?.signup_location?.osName}</p>}
													{user?.signup_location?.latitude && <p>{user?.signup_location?.latitude}</p>}
													{user?.signup_location?.longitude && <p>{user?.signup_location?.longitude}</p>}
													{(user?.signup_location?.country_name) && <p>{user?.signup_location?.country_name}, {user?.signup_location?.country_code}</p>}
													{/* {(user?.signup_location?.city || user?.signup_location?.state || user?.signup_location?.country_name) && <p>{user?.signup_location?.city}, {user?.signup_location?.state}, {user?.signup_location?.country_name}, {user?.signup_location?.country_code}</p>} */}
													{user?.signup_location?.IPv4 && <p>{user?.signup_location?.IPv4}</p>}
												</td> : null
										}
										{this.state.screenWidth <= 480 && <td style={{ wordBreak: 'break-word', minWidth: '15vw' }}>
											<a
												href={
													'https://' + (
														tab != 1 && user?.unique_id ?
															`${user?.brand_ID == 1 ? `${window?.location?.hostname == 'admin.ipaypro.co' ? 'ipaypro.co/' : 'dm.ipaypro.co/'}` + user.unique_id
																: user?.brand_ID == 2 ? `${window?.location?.hostname == 'admin.ipaypro.co' ? 'linky.biz/' : 'dm.linky.biz/'}` + user.unique_id
																	: user?.brand_ID == 3 ? `${window?.location?.hostname == 'admin.ipaypro.co' ? 'hustlerz.co/' : 'dm.hustlerz.co/'}` + user.unique_id
																		: `${window?.location?.hostname == 'admin.ipaypro.co' ? 'linky.biz/' : 'dm.linky.biz/'}` + user.unique_id}`
															: user?.linkyURL ? user.linkyURL
																: "Skipped")}

												className={(tab != 1 && user?.unique_id ? `${user?.brand_ID == 1 ? `${window?.location?.hostname == 'admin.ipaypro.co' ? 'ipaypro.co/' : 'dm.ipaypro.co/'}` + user.unique_id : user?.brand_ID == 2 ? `${window?.location?.hostname == 'admin.ipaypro.co' ? 'linky.biz/' : 'dm.linky.biz/'}` + user.unique_id : user?.brand_ID == 3 ? `${window?.location?.hostname == 'admin.ipaypro.co' ? 'hustlerz.co/' : 'dm.hustlerz.co/'}` + user.unique_id : `${window?.location?.hostname == 'admin.ipaypro.co' ? 'linky.biz/' : 'dm.linky.biz/'}` + user.unique_id}` : user?.linkyURL ? "userLink" : "SkippedLink")}
												target='_blank'>

												{
													tab != 1 && user?.unique_id ?
														`${user?.brand_ID == 1 ? `${window?.location?.hostname == 'admin.ipaypro.co' ? 'ipaypro.co/' : 'dm.ipaypro.co/'}` + user.unique_id
															: user?.brand_ID == 2 ? `${window?.location?.hostname == 'admin.ipaypro.co' ? 'linky.biz/' : 'dm.linky.biz/'}` + user.unique_id
																: user?.brand_ID == 3 ? `${window?.location?.hostname == 'admin.ipaypro.co' ? 'hustlerz.co/' : 'dm.hustlerz.co/'}` + user.unique_id
																	: `${window?.location?.hostname == 'admin.ipaypro.co' ? 'linky.biz/' : 'dm.linky.biz/'}` + user.unique_id}` : user?.linkyURL ? user.linkyURL : "Skipped"}/

											</a>


											<br />
											{/* {tab != 1 || tab != 2 ? user.email.replace(/(.{23})/g, '$1\n') : user.email} */}
											{user?.userData?.email || user?.email}
											{
												tab == 0 && (user?.userData?.email_status == 0 || user?.userData?.email_status == 1) && (
													user?.userData?.email_status == 1 ?
														<span><i className="fa fa-thumbs-up" id="email-verified" style={{ color: "green", marginLeft: "5px" }}></i>
															<UncontrolledTooltip placement="top" target="email-verified">
																Email Verified
															</UncontrolledTooltip>
														</span> :
														<span onClick={() => this.changeISVerified(user._id, user?.userData?.email_status)}>
															<i className="fa fa-thumbs-down" id="not-verified" style={{ color: "red", marginLeft: "5px" }}></i>
															<UncontrolledTooltip placement="top" target="not-verified">
																Email not verified
															</UncontrolledTooltip>
														</span>
												)
											}
											<br />
											{user?.mobile}
											{
												tab == 0 && (
													user?.mobile_status == 1 ?
														<span><i className="fa fa-thumbs-up" id="mobile-verified" style={{ color: "green", marginLeft: "5px" }}></i>
															<UncontrolledTooltip placement="top" target="mobile-verified">
																Mobile Verified
															</UncontrolledTooltip>
														</span> :
														<span>
															<i className="fa fa-thumbs-down" id="not-verified-mobile" style={{ color: "red", marginLeft: "5px" }}></i>
															<UncontrolledTooltip placement="top" target="not-verified-mobile">
																Mobile not verified
															</UncontrolledTooltip>
														</span>
												)
											}
										</td>}
										{this.state.screenWidth <= 480 && <td>
											<div className="form__form-group my-3">
												<div className="form__form-group-field">
													<CustomDropDown
														name={`${user._id}.status`}
														defaultValue={user.storeRefStatus ? user.storeRefStatus : { value: 1, label: 'LEAD' }}
														onChange={(event) => {

															let postData = { storeData: user, storeRefStatus: event };
															this.setStoreRefStatus(postData);

														}}
														options={[
															{ value: 1, label: 'LEAD' },
															{ value: 2, label: 'CONTACTED' },
															{ value: 3, label: 'FOLLOW-UP' },
															{ value: 4, label: 'LOST' },
															{ value: 5, label: 'WON' }
														]}
														user={user}
													/>
												</div>
												<div
													className="mt-2"
													onClick={() => {
														this.send_mail_toggle();
														this.set_user_mail_id(user);
													}}
													style={{ color: '#327ebc' }}
												>
													Send email/sms
												</div>
											</div>
										</td>}
										{/* {tab != 1 && <td style={{ width: "15%" }}>
							{user.subscription_plan !== undefined && user.subscription_plan[0] ? user.subscription_plan[0].subscription.title : "-"}
						</td>} */}

										{(tab == 3 && this.state.screenWidth <= 480) && <td>{typeof user.comment == "undefined" ? "" : this.getComment(user)}</td>}

										{/* {tab == 1 && (
							<td>
								{
									user.status == 0
										? "Approved"
										: user.status == 0
											? "Waiting"
											: user.status == 2
												? "Unapproved"
												: user.status == 5
													? "blocked"
													: null
								}
							</td>
						)} */}
										{/* {tab == 0 && <td>${user.wallets[0].wallet_amount.toFixed(2)}</td>} */}
										{(tab == 0 && this.state.screenWidth <= 480) && <td style={{ width: '7%' }}>{user.country.currency_symbol} {user.wallets && user.wallets.length > 0 ? user.wallets[0].wallet_amount.toFixed(2) : null}</td>}
										{
											(access_level != 1 && this.state.screenWidth <= 480) && (
												<td style={{ minWidth: "100px" }}>
													{/* {
									access_level == 3 && <span style={{ marginRight: "10px", fontSize: "0.75rem" }} onClick={() => this.toggleDeleteModal(user._id)}>
										<i style={{ color: "red" }} id="delete" className="fa fa-trash-o" ></i>
									</span>
								} */}
													{
														tab == 1 ?
															<NavLink to={`/merchants/inviteUserDetails/${user._id}`} item={user} className='more_btn'>
																More
															</NavLink>
															: null
													}
													{/* {tab == 1 || tab == 2 || tab == 0 ? (
														<span style={{ marginRight: "10px", cursor: "pointer", fontSize: "0.75rem" }}>
															<i
																className="fa fa-paper-plane"
																style={{ color: "#4466f2" }}
																onClick={() => {
																	this.send_mail_toggle();
																	this.set_user_mail_id(user._id);
																}}
															></i>
														</span>
													) : null} */}
													{tab == 0 || tab == 5 || tab == 3 || (tab == 4 && user.status == 5) || user.status == 1 ? (
														<NavLink onClick={() => this.blockClick(user._id, user)} style={{ marginRight: "10px", fontSize: "0.75rem" }}>
															<i style={{ color: user.status == 5 ? "red" : "black" }} id="block" className="icofont icofont-ui-block"></i>
															<UncontrolledTooltip placement="bottom" target="block">
																Block
															</UncontrolledTooltip>
														</NavLink>
													) : null}

													{tab == 0 || tab == 4 || tab == 3 || (tab == 4 && user.status == 1) || user.status == 5 ? (
														<span style={{ marginRight: "10px", fontSize: "0.75rem" }} onClick={() => this.flagClick(user._id, user)}>
															<i style={{ color: user.flag_status ? "red" : "black" }} id="flag" className="fa fa-flag-o"></i>
															<UncontrolledTooltip placement="bottom" target="flag">
																Flag
															</UncontrolledTooltip>
														</span>
													) : null}
													{/* {tab == 0 && (
										<img
											onClick={() => this.changeISVerified(user._id, user.email_status)}
											className="img-fluid"
											style={{
												width: "15px",
												cursor: "pointer",
												marginRight: "4px",
												boxShadow: user.is_verified == 2 ? "0px 0px 3px 3px #f4e79a" : null,
												boxShadow: user.is_verified == 2 ? "0px 0px 3px 3px #f4e79a" : null,
											}}
											// src={user.is_verified == 1 ? imgverified : user.is_verified == 2 ? imgverificationSent : imgunverified}
											src={user.email_status == 1 ? imgverified : imgunverified}
											alt={user.is_verified == 1 ? "Verified Merchant" : "Uverified Merchant"}
										/>
									)} */}
													{tab == 0 ||
														tab == 5 ||
														tab == 3 ||
														(tab == 4 && user.status == 1) ||
														(user.status == 5 && !user.business_form_filled) ? (
														<span style={{ marginRight: "10px", fontSize: "0.75rem" }} onClick={() => this.infoClick(user._id, user)}>
															<i style={{ color: "black" }} id="delete" className="fa fa-list-alt"></i>
															<UncontrolledTooltip placement="bottom" target="delete">
																Info
															</UncontrolledTooltip>
														</span>
													) : null}
													{tab == 0 || tab == 5 || tab == 3 || (tab == 4 && user.status == 1) || user.status == 5 ? (
														<span style={{ marginRight: "10px", fontSize: "0.75rem" }}>
															<NavLink to={`/merchants/merchantDetails/${user._id}`} item={user}>
																<VisibilityIcon style={{ fontSize: 15 }} />
															</NavLink>
														</span>
													) : null}
													{tab == 0 && user?.userData?.email_status == 1 &&
														<span style={{ marginRight: "10px", fontSize: "0.75rem" }} onClick={() => this.transferClick(user._id, user)}>
															<i style={{ fontSize: '1.1rem', color: '#008000' }} className="icofont icofont-exchange"></i>
														</span>
													}
													{access_level == 3 && (
														<span>
															<input
																type="checkbox"
																checked={this.state.selectedIDs.includes(user.adminId ? user.adminId : user._id)}
																onClick={(event) => this.deleteSelectedClick(event, user.adminId ? user.adminId : user._id)}
															/>
														</span>
													)}
												</td>
											)
										}
										{this.state.screenWidth <= 480 && <td>
											{typeof user.comment != "undefined" && user.comment.length > 0 ? (
												<p style={{ height: "70px", minWidth: "", overflow: "auto", fontSize: "12px" }}>{user.comment[user.comment.length - 1].note}</p>
											) : (
												"N.A"
											)}
										</td>}
									</tr>
								</tbody>
							</table>
						</td>
					</tr>

				</>}
			</>
		);
	};

	unblockAccount = async (user) => {


		let process = await swal({
			title: "Account Locked",
			text: (user?.loginBlocked == true ? `Reason: Too many (5) wrong login attempts` : user?.statusReason == 2 ? `Reason: Too many (5) wrong POS PIN attempts` : `Reason: Too many (5) wrong Transaction PIN attempts`) +
				`
			Are you sure to unlock user account?`,
			icon: "warning",
			buttons: {
				cancel: true,
				confirm: {
					text: "Yes please",
					closeModal: false,
				},
			},
			dangerMode: true,
		})

		if (process) {
			// console.log("api")
			let current = this;
			let postdata = { _id: user._id, removeLoginBlocked: user?.loginBlocked == true ? true : false };

			let status
			let message
			client.post(api.unblock_user, postdata, async function (error, response) {
				if (!error) {
					// alert(JSON.stringify(response));
					if (typeof response.error !== "undefined") {
						try {
							if (!response.error) {
								status = 1;
								message = "Status updated successfully"
							} else {
								status = 0;
								message = response.message
							}
						} catch (e) {
						}
					}
				}

				swal.close();
				swal(message);
				if (status == 1) {
					current.refreshPage();
					this.changeTab("lockedCount")
				}
			});
		}

	}

	rendertableRowLocked = (user, item, index, tab, compData) => {

		const { access_level, fetchType } = this.state;
		let renderDate = user.added_date || user.createdAt//fetchType == 'store_details_pending' ||  ? user.createdAt : user.added_date;

		return (
			<>
				<tr key={user._id}>
					<th scope="row">
						<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
							<p className="mb-0">{(this.state.activePage - 1) * this.state.pageSize + index + 1}</p>

						</div>
					</th>

					<td style={{ width: "9%" }}>{this.render_date(renderDate)}</td>

					<td>
						{user?.name}
					</td>

					<td>
						{user?.email} / {user?.mobile}
					</td>

					<td>
						{user?.loginBlocked == true ?
							"Too many (5) wrong login attempts" :
							typeof user.pin_failed_log != "undefined" && user.pin_failed_log.fail_count >= 0 ?
								user?.statusReason == 2 ? "Too many (5) wrong POS PIN attempts" : "Too many (5) wrong Transaction PIN attempts" : "N.A"
						}
					</td>

					<td style={{ width: "20%" }}>
						{user?.loginBlocked == true ? <span onClick={() => {
							this.unblockAccount(user)
						}}>
							<i className="fa fa-lock" id="lock-hover-set-user" style={{ fontSize: "1.2rem", marginRight: "8px", color: '#F4BE18' }}></i>
							<UncontrolledTooltip placement="top" target="lock-hover-set-user">
								Too many wrong login attempts
							</UncontrolledTooltip>
						</span> : typeof user.pin_failed_log != "undefined" && user.pin_failed_log.fail_count >= 0 ? <span onClick={() => {
							this.unblockAccount(user)
						}}>
							<i className="fa fa-lock" id={`lock-hover-set${user._id}`} style={{ fontSize: "1.2rem", marginRight: "8px", color: '#F4BE18' }}></i>
							<UncontrolledTooltip placement="top" target={`lock-hover-set${user._id}`}>
								{user?.loginBlocked == true ?
									"Too many wrong login attempts" :
									typeof user.pin_failed_log != "undefined" && user.pin_failed_log.fail_count >= 0 ?
										user?.statusReason == 2 ? "Too many wrong POS PIN attempts" : "Too many wrong Transaction PIN attempts" : "N.A"
								}
							</UncontrolledTooltip>
						</span> : null}
						{" "}
						{user?.loginBlockedTime && <span>{`(${moment(user?.loginBlockedTime).format('DD-MM-YYYY/H:mm')})`}</span>}

					</td>

				</tr>
			</>
		);
	}

	rendertable = (tab, data, companyData) => {

		const { access_level, fetchType, tab_count, searchValue } = this.state;
		// let customers = [];	
		let data_lengh = 0;
		let fdata_lengh;

		if (fetchType == 'verified') data_lengh = tab_count.veriCount;
		else if (fetchType == 'unverified') data_lengh = tab_count.unveriCount;
		else if (fetchType == 'unverified_email') data_lengh = tab_count.unverified_email_count;
		else if (fetchType == 'unverified_email_above_5_days') data_lengh = tab_count.unverified_email_above_5_days_count;
		else if (fetchType == 'blocked') data_lengh = tab_count.blockedCount;
		else if (fetchType == 'lockedCount') data_lengh = tab_count.lockedCount;
		else if (fetchType == 'underreview') data_lengh = tab_count.pendingveriCount;
		else if (fetchType == 'flagged') data_lengh = tab_count.flaggedCount;
		else if (fetchType == 'allresult') data_lengh = tab_count.all;
		else if (fetchType == 'expired_plan') data_lengh = tab_count.expiredPlan_count;
		else if (fetchType == 'plan_not_selected') data_lengh = tab_count.plan_not_selected_count;
		else if (fetchType == 'store_details_pending') data_lengh = tab_count.store_details_pending_count;
		else if (fetchType == 'invited_users') data_lengh = tab_count.invited_users_count;
		else if (fetchType == 'subscription_invoices') data_lengh = tab_count.subscription_invoices_count;

		// console.log(data)

		if (!searchValue) {
			fdata_lengh = data_lengh;

		} else {
			fdata_lengh = data.length;
		}

		let index = 0;
		// console.log(data)
		// if (this.state.searchedData != null) {
		// 	customers = this.state.searchedData;
		// } else if (tab == 0) {
		// 	customers = this.state.verified;
		// } else if (tab == 1) {
		// 	customers = this.state.webRequests;
		// } else if (tab == 2) {
		// 	customers = this.state.unverified;
		// } else if (tab == 3) {
		// 	customers = this.state.blocked;
		// } else if (tab == 4) {
		// 	customers = this.state.flagged;
		// } else if (tab == 5) {
		// 	customers = this.state.customers;
		// }



		return (
			<Fragment>
				{true ?
					<div className="container-fluid">
						<div className="row">
							<div className="col-sm-12 col-xl-12 xl-100" style={{ padding: "0" }}>
								<div className="card">
									{this.state.searchLoading && <BarLoader height={1} width={"100%"} />}
									<div className="card-body" style={{ padding: "1rem" }}>
										<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: '5px' }}>
											<div>
												<form onSubmit={(event) => this.handleSearchSubmit(event)}>
													<div>
														<input
															style={{
																border: "#dfe8f1 solid 1px",
																borderRadius: "3px",
																padding: "10px",
																fontSize: "0.75rem",
																outlineColor: '#dfe8f1'
															}}
															type="text"
															placeholder="Search"
															value={this.state.searchValue}
															onChange={this.onSearchChangeHandler}
														/>
													</div>
												</form>
											</div>
											<div className="d-flex">
												<div className="d-flex align-items-center mr-4" style={{ fontSize: "0.75rem" }}>
													<div style={{ width: "50px" }}>Sort by:</div>
													<select
														// className="custom-select"

														className="custom-select-style"
														style={{ width: "130px" }}
														onChange={(event) => { this.sortByStatus(event) }}
														value={this.state.storeStatus}
													>
														<option value="0">Select</option>
														<option value="1">LEAD</option>
														<option value="2">CONTACTED</option>
														<option value="3">FOLLOW-UP</option>
														<option value="4">LOST</option>
														<option value="5">WON</option>
													</select>

												</div>
												<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", fontSize: "0.75rem" }}>
													<select
														className="custom-select"
														style={{ width: "32%", marginRight: "7px" }}
														onChange={this.setPageSize}
														value={this.state.pageSize}
													>
														<option value="15">15</option>
														<option value="25">25</option>
														<option value="50">50</option>
														<option value="75">75</option>
														<option value="100">100</option>
													</select>
													<span>Records per Page</span>
												</div>
											</div>

										</div>

										<div className="table-responsive">
											{tab == "lockedCount" ?
												<table className="table table-border-horizontal" style={{ fontSize: "0.75rem" }}>
													<thead>
														<tr style={{ background: '#f9fafe' }}>
															<th scope="col" style={{ width: "2vw" }}>#</th>
															<th scope="col" style={{ width: "9%" }}>
																<p>Date</p>
																<p>Time</p>
															</th>
															<th scope="col">Admin Name</th>

															<th scope="col" style={{ width: "15%" }}>
																<div>Profile Email / Number</div>
															</th>

															<th scope="col">
																<p>Blocked by</p>
																<p>Reason</p>
															</th>
															<th scope="col" style={{ width: tab == 1 ? '20%' : '12%' }}>
																Action
															</th>
														</tr>
													</thead>

													<tbody>
														{
															data.length > 0 ?
																<>
																	{
																		data
																			// .slice(
																			// 	(this.state.activePage - 1) * this.state.pageSize,
																			// 	this.state.activePage * this.state.pageSize
																			// )
																			.map((item, index) => {
																				let compData = null;
																				if (tab != 1 && companyData.length > 0) {
																					compData = companyData.find((ele) => ele.adminId == item.merchants.adminId);

																				}

																				else {
																					return this.rendertableRowLocked(item, item.merchants, index, tab, compData);
																				}
																				// if (item.merchants) {
																				// 	return (
																				// 		<>
																				// 			{
																				// 				item.merchants.map(merchant => {
																				// 					return this.rendertableRow(item, merchant, index, tab)
																				// 				})
																				// 			}
																				// 		</>
																				// 	);
																				// } else {

																				// }
																			})
																	}
																</> :
																// <p className="p-3"> No records found </p>
																<tr>
																	<td colspan="10" scope='row' style={{ padding: 10, textAlign: 'center' }}>No records found</td>
																</tr>
														}
													</tbody>
												</table>

												:
												<table className="table table-border-horizontal" style={{ fontSize: "0.75rem" }}>
													<thead>
														{
															tab == 6 ?
																<tr style={{ background: '#f9fafe' }}>
																	<th>#</th>
																	<th scope="col">Invoice No</th>
																	<th scope="col">Invoice Date</th>
																	<th scope="col">Company</th>
																	<th scope="col">Store</th>
																	<th scope="col">Description</th>
																	<th scope="col">Amount</th>
																	<th scope="col">Status</th>
																	<th scope="col">Action</th>
																</tr>
																: <tr style={{ background: '#f9fafe' }}>
																	<th scope="col" style={{ width: "2vw" }}>#</th>
																	<th scope="col" style={{ width: "9%" }}>
																		<p>Date</p>
																		<p>Time</p>
																	</th>
																	{tab != 1 && <th scope="col">Admin Name/ <br />	Linky ID</th>}
																	{!(tab != 1) && <th scope="col" style={{ width: "10%" }}>Admin Name</th>}
																	{tab != 1 && <th scope="col">Store Name / Company Name</th>}
																	{/* <th scope="col" style={{ width: "11%" }}>
														            <p>Landline</p>
														            <p>Mobile</p>
													                </th> */}
																	{/* {tab != 1 && <th scope="col">Company Name</th>} */}
																	{(tab != 1 && this.state.screenWidth > 1440) && <th scope="col">Source</th>}
																	{(tab != 1 && this.state.screenWidth > 1440) && <th scope="col" style={{ width: "15%" }}>Device / Location / IP	</th>}
																	{this.state.screenWidth > 480 && <th scope="col" style={{ width: "15%" }}>
																		<div>Minisite / User Email / Store Number</div>
																	</th>}
																	{this.state.screenWidth > 480 && <th scope="col" style={{ width: "15%" }}>
																		Status
																	</th>}
																	{/* {tab != 1 && <th scope="col" style={{ width: "15%" }}>
																Plan
															</th>} */}

																	{/* {tab == 1 && <th scope="col">Status</th>} */}
																	{(tab == 3 && this.state.screenWidth > 480) && (
																		<th scope="col">
																			<p>Blocked by</p>
																			<p>Reason</p>
																		</th>
																	)}
																	{(tab == 0 && this.state.screenWidth > 480) && <th scope="col">Balance</th>}
																	{(access_level != 1 && this.state.screenWidth > 480) && (
																		<th scope="col" style={{ width: tab == 1 ? '20%' : '12%' }}>
																			Action
																		</th>
																	)}
																	{this.state.screenWidth > 480 && <th scope="col">Notes</th>}
																	{this.state.screenWidth > 1440 && <th scope="col">Updated by</th>}
																</tr>
														}
													</thead>

													<tbody>
														{
															data.length > 0 ?
																<>
																	{
																		data
																			// .slice(
																			// 	(this.state.activePage - 1) * this.state.pageSize,
																			// 	this.state.activePage * this.state.pageSize
																			// )
																			.map((item, index) => {
																				let compData = null;
																				if (tab != 1 && companyData.length > 0) {
																					compData = companyData.find((ele) => ele.adminId == item.merchants.adminId);

																				}
																				if (tab == 6) {
																					return (
																						<tr key={item._id}>
																							<td scope='row'>{index + 1}</td>
																							<td scope=" row">{item.invoice_number}</td>
																							<td scope="row">{moment(item.createdAt).format('DD-MM-YYYY/H:mm')}</td>
																							<td scope=" row">{item.company_name}</td>
																							<td scope=" row">{item.store_name}</td>
																							<td scope=" row">{item.invoice_title}</td>
																							<td scope=" row">AUD {item.total}</td>
																							<td scope=" row">{item.invoice_title.includes("Trial Plan") ? '-' : item.status == 1 ? (<span className='text-success'>Paid</span>) : (<span className='text-danger'>Pending</span>)}</td>
																							<td>
																								<VisibilityIcon
																									onClick={() => {
																										this.setState({ invoiceDetails: item });
																										this.fetchInvoice(item);
																									}}
																									style={{ fontSize: 15, margin: '8px auto', display: 'block', color: '#327ebc', cursor: 'pointer' }}
																								/>
																							</td>
																						</tr>
																					)
																				}
																				else {
																					return this.rendertableRow(item, item.merchants, index, tab, compData);
																				}
																				// if (item.merchants) {
																				// 	return (
																				// 		<>
																				// 			{
																				// 				item.merchants.map(merchant => {
																				// 					return this.rendertableRow(item, merchant, index, tab)
																				// 				})
																				// 			}
																				// 		</>
																				// 	);
																				// } else {

																				// }
																			})
																	}
																</> :
																// <p className="p-3"> No records found </p>
																<tr>
																	<td colspan="10" scope='row' style={{ padding: 10, textAlign: 'center' }}>No records found</td>
																</tr>
														}
													</tbody>
												</table>
											}


											<div className="mt-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
												{this.state.activePage * this.state.pageSize > fdata_lengh ? (
													<p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>
														Showing {(this.state.activePage - 1) * this.state.pageSize + 1} to {fdata_lengh} of {fdata_lengh}{" "}
														entries
													</p>
												) : (
													<p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>
														Showing {(this.state.activePage - 1) * this.state.pageSize + 1} to{" "}
														{this.state.activePage * this.state.pageSize} of {fdata_lengh} entries
													</p>
												)}
												<PaginationComponent
													totalItems={fdata_lengh}
													pageSize={this.state.pageSize}
													onSelect={this.handlePageChange}
													defaultActivePage={this.state.activePage}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					:
					<div>
						<div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
							<div className="loader" style={{ display: "flex", alignItems: "center" }}>
								<DisappearedLoading size="medium" color="#514F4E" />
							</div>
						</div>
					</div>
				}
			</Fragment>
		);
	};

	render_date = (add_date) => {
		if (typeof add_date === "undefined") {
			return;
		}

		const convert_date = new Date(add_date);
		const added_date = moment.tz(convert_date, "Australia/Sydney").format("DD/MM/YYYY HH:mm");
		return added_date;
	};

	toggleDeleteModal = (id) => {
		this.setState((prevState) => {
			return {
				...prevState,
				deleteModal: !prevState.deleteModal,
				selectedId: id,
			};
		});
	};

	renderDeleteModal = () => {
		return (
			<Modal isOpen={this.state.deleteModal} toggle={this.toggleDeleteModal} className="modal-body" centered={true}>
				<form className="theme-form" noValidate="">
					<ModalBody>
						<h4>Do you really want to delete this merchant ?</h4>
					</ModalBody>
					<ModalFooter>
						<Button color="default" onClick={this.toggleDeleteModal}>
							No
						</Button>
						<Button
							color="primary"
							type="submit"
							onClick={(event) => this.deleteMerchantHandler(event, this.state.selectedId)}
						>
							Yes
						</Button>
					</ModalFooter>
				</form>
			</Modal>
		);
	};

	deleteMerchantHandler = (event, id) => {
		event.preventDefault();
		const postData = { merchant_id: id };

		client.post(api.delete_merchant, postData, (error, response) => {
			if (!error) {
				if (!response.error) {
					toast.success(response.message);
					this.refreshPage();
				} else {
					setTimeout(() => {
						toast.error(response.message);
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
		});
	};

	reload_active_data = () => { };

	handleSearchSubmit = (event) => {
		event.preventDefault();
		// fetchType
		this.getCustomers(this.state.fetchType, this.state.activePage, true);
	};

	onSearchChangeHandler = (event) => {
		if (event.target.value !== '') {
			this.setState(
				{
					searchValue: event.target.value,
				},
				() => {
					// alert(type);
					// this.getCustomers(this.state.fetchType, this.state.activePage, true);
				}
			);
		} else {
			this.setState(
				{
					searchValue: null,
				},
				() => {
					// alert(type);
					this.getCustomers(this.state.fetchType, this.state.activePage, true);
				}
			);
		}

	};

	onSendCredOptionChange = (event) => {
		// console.log(event.target.value);
		this.setState({ sendCredOption: event.target.value });
	};

	renderMobileModal = () => {
		return (
			<Modal isOpen={this.state.mobileModal} toggle={this.toggleMobileModal} className="modal-body" centered={true}>
				<form className="theme-form" onSubmit={this.handle_send_mail_submit}>
					<ModalHeader>Verify Merchant</ModalHeader>
					<ModalBody>
						<h6>Are you sure you want to change the status of the user to verified ?</h6>
						<p style={{ color: "red", margin: "0" }}>Note:</p>
						<p style={{ color: "red", margin: "0" }}>
							i) The user will be able to login to app without verifying their mobile number.
						</p>
						<p style={{ color: "red", margin: "0" }}>
							ii) Do follow up with the user to make sure user login to app and create password & pin.
						</p>
						<div className="row">
							<div className="col">
								<div className="radio radio-primary" style={{ marginTop: "10px" }}>
									<input id="sms" type="radio" name="option" value="sms" onChange={this.onSendCredOptionChange} />
									<label className="mb-0" htmlFor="sms">
										Send ID/Password via sms<span className="digits"></span>
									</label>
								</div>
								<div className="radio radio-primary" style={{ marginTop: "10px" }}>
									<input
										id="email"
										type="radio"
										name="option"
										value="email"
										onChange={this.onSendCredOptionChange}
										defaultChecked
									/>
									<label className="mb-0" htmlFor="email">
										Send ID/Password via email<span className="digits"></span>
									</label>
								</div>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button color="default" onClick={this.toggleMobileModal}>
							Not sure
						</Button>
						<Button color="primary" type="submit">
							Yes, please!
						</Button>
					</ModalFooter>
				</form>
			</Modal>
		);
	};

	handle_send_mail_submit = (e) => {
		e.preventDefault();
		let { sendCredOption } = this.state;
		// console.log(e.target);
		let postdata = { merchant_id: this.state.selectedId, option: sendCredOption };
		let current = this;
		//current.setState({ loading: true });

		if (postdata !== "") {
			//setloadingbtn(true);
			client.post(api.webMerc_sendCredentials, postdata, async function (error, response) {
				if (!error) {
					if (typeof response.error !== "undefined") {
						try {
							if (!response.error) {
								toast.success(response.message);
								current.refreshPage();
								this.setState((prevState) => {
									return {
										...prevState,
										mobileModal: false,
									};
								});
							} else {
								toast.error(response.message);
							}
						} catch (e) {
							toast.error(e.message);
						}
					} else {
						toast.error("Internal error occured. Please contact support ");
					}
				} else {
					toast.error("Internal error occured. Please contact support ");
				}
				//setloadingbtn(false);
			});
		}
	};

	onChangeStatus = (event) => {
		event.preventDefault();
		let postdata = {};
		postdata = {
			merchant_id: this.state.selectedId,
			status: 1,
			mobile_status: 1,
			comment: { type: "verify", note: "No comments", user: this.state.adminUser.email },
		};

		/* if (event.target.name == "unblock") {
	  postdata = { _id: id, status: 1, comment: { type: "unblock", note: "No comments", user: "admin@ipay.com" } }
	} else {
	  postdata = { _id: id, status: 2, comment: { type: "block", note: this.state.flag_note, user: "admin@ipay.com" } }
	} */

		client.put(api.update_merchant_details, postdata, (error, response) => {
			if (!error) {
				if (!response.error) {
					toast.success("Merchant status updated");
					this.refreshPage();
				} else {
					setTimeout(() => {
						toast.error(response.message);
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
		});
	};

	toggleSMSModal = () => {
		this.setState((prevState) => {
			return {
				smsModalClick: !prevState.smsModalClick,
			};
		});
	};

	toggleExportModal = () => {
		this.setState((prevState) => {
			return {
				exportModalClick: !prevState.exportModalClick,
				createCsv: false,
				endDate: null,
				startDate: null,
			};
		});
	};

	onClickExportButton = () => {
		this.setState((prevState) => {
			return {
				exportModalClick: !prevState.exportModalClick,
			};
		});
	};

	onExportSubmit = (event) => {
		event.preventDefault();
		const { endDate, startDate } = this.state;
		const filteredCustomers = this.state.customers.filter((el) => {
			if (!endDate) {
				return (
					moment.tz(el.added_date, "Australia/Sydney").toDate() >=
					moment.tz(startDate, "Australia/Sydney").startOf("day").toDate() &&
					moment.tz(el.added_date, "Australia/Sydney").toDate() <=
					moment.tz(startDate, "Australia/Sydney").endOf("day").toDate()
				);
			} else {
				return (
					moment.tz(el.added_date, "Australia/Sydney").toDate() >=
					moment.tz(startDate, "Australia/Sydney").startOf("day").toDate() &&
					moment.tz(el.added_date, "Australia/Sydney").toDate() <=
					moment.tz(endDate, "Australia/Sydney").endOf("day").toDate()
				);
			}
		});

		// console.log(filteredCustomers);
		const data = filteredCustomers.map((item) => {
			return {
				date: this.render_date(item.added_date),
				ipay_id: item.ipay_id,
				name: item.business_name,
				email: item.email,
				mobile: item.mobile,
				balance: item.wallet.length != 0 ? `$${Number(Math.round(item.wallet[0].wallet_amount + "e2") + "e-2")} ` : "$0",
			};
		});

		const headers = [
			{ label: "Date / Time", key: "date" },
			{ label: "i-Pay ID", key: "ipay_id" },
			{ label: "Business Name", key: "name" },
			{ label: "Email", key: "email" },
			{ label: "Mobile", key: "mobile" },
			{ label: "Balance", key: "balance" },
		];

		this.setState({ createCsv: true, csvHeader: headers, csvData: data });
	};

	onChangeExportFilter = (event) => {
		this.setState({ exportModalOption: event.target.value });
		if (event.target.name == "range") {
			this.setState({ endDate: null });
		}
	};

	renderExportModal = () => {
		const { startDate, endDate, exportModalOption } = this.state;
		return (
			<Modal isOpen={this.state.exportModalClick} toggle={this.toggleExportModal} className="modal-body">
				<form className="theme-form" onSubmit={this.onExportSubmit}>
					<ModalHeader>Export Data</ModalHeader>
					<ModalBody>
						<div className="form-group col-8 p-r-0">
							<select className="form-control" name="date" onChange={this.onChangeExportFilter}>
								<option value="select">Select filter</option>
								<option value="single">Select a date</option>
								<option value="range">Select date range</option>
							</select>
						</div>
						{exportModalOption == "single" && (
							<DatePicker selected={startDate} onChange={(date) => this.setState({ startDate: date })} />
						)}
						{exportModalOption == "range" && (
							<Fragment>
								<DatePicker
									className="date-picker"
									selected={startDate}
									onChange={(date) => this.setState({ startDate: date })}
									dateFormat="dd/MM/yyyy"
									placeholderText="Select start date"
									maxDate={new Date()}

								//selectsStart
								//startDate={startDate}
								//endDate={endDate}
								/>
								<DatePicker
									placeholderText="Select end date"
									maxDate={new Date()}
									minDate={startDate}
									className="date-picker"
									selected={endDate}
									onChange={(date) => this.setState({ endDate: date })}
								//selectsEnd
								//startDate={startDate}
								//endDate={endDate}
								//minDate={startDate}
								/>
							</Fragment>
						)}
					</ModalBody>
					<ModalFooter>
						{
							<Fragment>
								<Button color="default" onClick={this.onClickExportButton}>
									Cancel
								</Button>
								<Button color="primary" type="submit">
									Export
								</Button>
							</Fragment>
						}
					</ModalFooter>
				</form>
			</Modal>
		);
	};

	renderPortalLoginModal = () => {

		const { openPortalLogin, selectedMerchant } = this.state;
		return (
			<Modal isOpen={openPortalLogin} toggle={this.toggleAutoLogin} className="modal-body" centered={true}>
				<ModalBody>
					<form onSubmit={(event) => this.handleAutoLogin(event)} className="needs-validation">
						<div className="my-4" style={{ fontSize: "3rem", textAlign: "center" }}>
							<h5>Enter your admin password to proceed.</h5>
						</div>
						<div className="d-flex flex-column">
							<div className="rounded form-control d-flex align-items-center justify-content-between">
								<input
									className="align-self-center"
									type={`${this.state.isVisible ? 'text' : 'password'}`}
									size="40"
									onChange={this.verifyAdminHandler}
									style={{ width: '80%', border: 'none' }}
									placeholder="Please enter admin password"
									required
								></input>
								{this.state.isVisible ? <VisibilityIcon width={1.5} onClick={this.setIsVisible} style={{ cursor: 'pointer' }} /> :
									<VisibilityOffIcon width={1.5} onClick={this.setIsVisible} style={{ cursor: 'pointer' }} />}
							</div>
							<div className="d-flex justify-content-around">
								<Button color="default" style={{ marginTop: "20px", marginRight: "20px" }} onClick={this.toggleAutoLogin}>
									Cancel
								</Button>
								<Button color="primary" style={{ marginTop: "20px", marginRight: "20px" }} name="block">
									Yes, Please!
								</Button>
							</div>
						</div>
					</form>
				</ModalBody>
			</Modal>
		)
	}

	sortByStatus = (event) => {
		// console.log(event.target.value, "event status")
		if (event.target.value != 0) {
			this.setState({
				storeStatus: Number(event.target.value)
			},
				() => {
					this.getCustomers(this.state.fetchType, this.state.activePage, true);
				}
			);
		}

	}


	render() {
		let {
			result: customers,
			verified,
			expired_plan,
			unverified,
			unverified_email,
			unverified_email_above_5_days,
			allresult,
			blocked,
			flagged,
			lockedCount,
			sendCredOption,
			access_level,
			underreview,
			plan_not_selected,
			store_details_pending,
			invited_users,
			tab_count,
			companyData,
			subscription_invoices,
			country,
			countryTabIndex,
			defaultCountryCode,
			merchantByCountry,
			screenWidth
		} = this.state;

		const { history } = this.props;

		// console.log(screenWidth, "screenWidth");


		return (
			<Fragment>
				{/* <Slider></Slider> */}
				{this.renderDeleteSelectedModal()}
				{this.state.selectedId ? this.renderFlagModal(this.state.selectedId) : null}
				{this.state.selectedId ? this.renderInfoModal(this.state.selectedId) : null}
				{/* {this.state.selectedId ? this.renderTransferModal(this.state.selectedId) : null} */}
				{this.state.selectedId ? this.renderMobileModal() : null}
				{this.state.selectedId && this.renderBlockModal(this.state.selectedId)}
				{this.renderDeleteModal()}
				{this.renderTransferModal()}
				{this.renderInvoiceDetailsModal()}
				{this.state.send_to_mer_id && this.renderMailModal()}
				{this.state.previewEmail && this.state.send_templ_mail_id && this.state.send_templ_mail_id !== "select" && this.previewEmailComp()}
				{this.renderExportModal()}
				{this.renderPortalLoginModal()}
				{this.state.smsModalClick && <SendSMSModal smsModalClick={this.state.smsModalClick} toggleSMSModal={this.toggleSMSModal} />}
				<CSVLink data={this.state.csvData} headers={this.state.csvHeader} id="csv" style={{ display: "none" }}>
					Download me
				</CSVLink>
				<div className="row">
					<div className="col-lg-7 col-sm-12" style={{ fontSize: "12px" }}>
						<Breadcrumb title={"I-Pay merchants"} parent={"Home"} />
					</div>
					<div className="col-lg-5 col-sm-12 ">
						<div
							className="btn-group pull-right"
							style={{ paddingTop: "8px", marginLeft: "0.4rem", marginRight: "0.6rem", marginBottom: "0rem" }}
							role="group"
							aria-label="Basic example"
						>
							<button
								className="btnexport custBtnAdmin"
								onClick={this.toggleSMSModal}
							>
								Send SMS
							</button>
							{access_level == 3 && (
								<button
									className="btnexport custBtnAdmin"
									onClick={this.toggleExportModal}
								>
									<i style={{ color: "#327ebc", margin: "0 7px 0 0" }} className="icofont icofont-download-alt"></i>Export
								</button>
							)}
							{access_level == 3 && (
								<button
									className="btn custBtnAdmin"
									onClick={this.deleteSelectedButtonClick}
									disabled={this.state.selectedIDs.length == 0 ? "disabled" : null}
								>
									{this.state.selectedIDs.length == 0 ? (
										<i
											style={{ color: "#327ebc", fontSize: "15px", margin: "0 7px 0 0" }}
											id="delete"
											title="delete"
											className="fa fa-trash-o"
										></i>
									) : (
										<i
											style={{ color: "#327ebc", fontSize: "15px", margin: "0 7px 0 0" }}
											id="delete"
											title="delete"
											className="fa fa-trash-o"
										></i>
									)}
									Delete selected
								</button>
							)}
						</div>
					</div>
				</div>

				<div className="container-fluid">
					<div className="row theme-tab">
						<style>
							{`.theme-tab .tab-title li {padding: 10px 8px;white-space: nowrap;}`}
						</style>

						{
							country.length > 0 && <Tabs
								className="col-sm-12 merchant_req"
								// defaultIndex={this.state.countryTabIndex}
								style={{
									backgroundColor: "white",
									borderRadius: "3px",
									border: "1px solid rgb(223, 232, 241)",
									borderBottom: "none",
									marginBottom: "0px",
								}}
								onSelect={(index) => { this.setState({ countryTabIndex: index }) }}
							>

								<TabList className="tabs tab-title">
									<div
										style={{ display: "flex", alignItems: "center", padding: "10px 15px" }}
										onClick={() => this.changeCountryTab('ALL')}
										className={defaultCountryCode == "ALL" ? 'selectedTabStyle' : 'tabStyle'}
									>
										All {`(${merchantByCountry.find((merchCount) => merchCount.code == 'ALL')?.data})`}
									</div>
									{
										country.length > 0 && country.slice(0, 5).map((countryData) => {
											let merchantCount = merchantByCountry.find((merchCount) => merchCount.code == countryData.country_code);
											return (
												<>
													<div
														className={countryData.country_code == defaultCountryCode ? 'selectedTabStyle' : 'tabStyle'}
														style={{ display: "flex", alignItems: "center", padding: "10px 15px" }}
														onClick={() => this.changeCountryTab(countryData.country_code)}
													>
														{countryData.country_name} {merchantCount ? `(${merchantCount.data})` : `(0)`}
														{/* {console.log(merchantByCountry, "merchantByCountry")} */}
													</div>
												</>
											)
										})
									}

									<Dropdown style={{ margin: "auto 0px" }}>
										<Dropdown.Toggle
											id=""
											className={this.state.countryTabIndex == 6 ? 'selectDropDownBtnStyle' : 'dropDownBtnStyle'}
											style={{ color: "#000" }}
										// onClick={() => this.setState({ countryTabIndex: 5 })}
										>
											Others
										</Dropdown.Toggle>

										<Dropdown.Menu>
											<style>
												{`.dropdown-menu.show {min-height:145px;max-height:400px;overflow:auto}`}
											</style>
											{
												country.length > 5 && country.slice(5, country.length).map((countryData) => {
													let merchantCount = merchantByCountry.find((merchCount) => merchCount.code == countryData.country_code);
													return (
														<>
															<Dropdown.Item className={countryData.country_code == defaultCountryCode ? 'selectedDropDownStyle' : "dropDownStyle"} onClick={() => this.changeCountryTab(countryData.country_code)}>
																{countryData.country_name} {merchantCount ? `(${merchantCount.data})` : `(0)`}
															</Dropdown.Item>
														</>
													)
												})
											}
										</Dropdown.Menu>
									</Dropdown>

									<div
										style={{ display: "flex", alignItems: "center", padding: "10px 15px" }}
										// onClick={() => this.changeCountryTab('ALL')}
										className={`py-0 ${defaultCountryCode == "Trashed" ? 'selectedTabStyle' : 'tabStyle'}`}
									>
										<span
											className=""
											href=""
											onClick={this.redirectToHome}
										>
											Trashed

											{/* <Icon
												path={mdiTrashCanOutline}
												size={1}
												color="black"
											/> */}
										</span>
									</div>
								</TabList>
							</Tabs>
						}

						<Tabs
							className="col-sm-12"
							defaultIndex={this.state.defaultIndexNo}
							style={{
								backgroundColor: "white",
								borderRadius: "3px",
								border: "1px solid rgb(223, 232, 241)",
								marginBottom: "0px",
							}}
							onSelect={(index) => {
								this.setState({ tabIndex: index });
								this.setState({ searchValue: "" });
								this.setState({ searchedData: null });
							}}
						>
							<TabList className="tabs tab-title">
								<Tab
									style={{ display: "flex", alignItems: "center" }}
									onClick={() => this.changeTab("allresult")}
								>
									{/* <i className="icofont icofont-business-man-alt-1" style={{ fontSize: "0.9rem", marginRight: "8px" }}></i> */}
									All ({tab_count.all})
								</Tab>

								<Tab
									style={{ display: "flex", alignItems: "center" }}
									onClick={() => this.changeTab("verified")}
								>
									{/* <i className="icofont icofont-business-man-alt-1" style={{ fontSize: "0.9rem", marginRight: "8px" }}></i> */}
									Live ({tab_count.veriCount})
								</Tab>

								<Tab
									style={{ display: "flex", alignItems: "center" }}
									onClick={() => this.changeTab("underreview")}
								>
									{/* <i className="fa fa-clock-o" style={{ fontSize: "0.9rem", marginRight: "8px" }}></i> */}
									{/* Documents Under Review */}
									Review Documents
									({tab_count.pendingveriCount})
								</Tab>

								<Tab
									style={{ display: "flex", alignItems: "center" }}
									onClick={() => this.changeTab("unverified_email")}
								>
									Unverified (under 5 days) ({tab_count.unverified_email_count})
								</Tab>

								<Tab
									style={{ display: "flex", alignItems: "center" }}
									onClick={() => this.changeTab("unverified_email_above_5_days")}
								>
									Unverified (above 5 days) ({tab_count.unverified_email_above_5_days_count})
								</Tab>
								<Tab
									style={{ display: "flex", alignItems: "center" }}
									onClick={() => this.changeTab("plan_not_selected")}
								>
									{/* <i className="icofont icofont-business-man-alt-1" style={{ fontSize: "0.9rem", marginRight: "8px" }}></i> */}
									Plan not purchased ({tab_count.plan_not_selected_count})
								</Tab>
								<Tab
									style={{ display: "flex", alignItems: "center" }}
									onClick={() => this.changeTab("store_details_pending")}
								>
									{/* <i className="icofont icofont-business-man-alt-1" style={{ fontSize: "0.9rem", marginRight: "8px" }}></i> */}
									Store details pending ({tab_count.store_details_pending_count})
								</Tab>
								{/* <Tab
									style={{ display: "flex", alignItems: "center" }}
									onClick={() => this.changeTab("expired_plan")}
								>
									<i className="icofont icofont-business-man-alt-1" style={{ fontSize: "0.9rem", marginRight: "8px" }}></i>
									Expired Plan ({tab_count.expiredPlan_count})
								</Tab> */}
								<Tab
									style={{ display: "flex", alignItems: "center" }}
									onClick={() => this.changeTab("flagged")}
								>
									{/* <i className="fa fa-flag" style={{ fontSize: "0.9rem", marginRight: "8px" }}></i> */}
									Flagged (
									{tab_count.flaggedCount})
								</Tab>
								{/* {<Tab style={{ display: "flex", alignItems: "center" }} onClick={() => this.changeTab("invited_users")}>
									<i className="fa fa-clock-o" style={{ fontSize: "0.9rem", marginRight: "8px" }}></i>
									Invited Users ({tab_count.invited_users_count})
								</Tab>}	 */}

								{/* <Tab style={{ marginTop: "0.5rem", display: "flex", alignItems: "center" }}>
									<i className="fa fa-globe" style={{ fontSize: "0.9rem", marginRight: "8px" }}></i> Web requests (
									{customers
										? customers.filter(function (element) {
												return (
													element.request_source == 2 &&
													element.status !== 5 &&
													element.flag_status !== true &&
													element.mobile_status != 1
												);
										  }).length
										: 0}
									)
								</Tab> */}

								<Tab
									style={{ display: "flex", alignItems: "center" }}
									onClick={() => this.changeTab("blocked")}
								>
									{/* <i className="fa fa-ban" style={{ fontSize: "0.9rem", marginRight: "8px" }}></i> */}
									Blocked (
									{tab_count.blockedCount})
								</Tab>

								<Tab
									style={{ display: "flex", alignItems: "center" }}
									onClick={() => this.changeTab("lockedCount")}
								>
									{/* <i className="fa fa-ban" style={{ fontSize: "0.9rem", marginRight: "8px" }}></i> */}
									LOCKED (
									{tab_count.lockedCount})
								</Tab>



								{/* <Tab
									style={{ display: "flex", alignItems: "center" }}
									onClick={() => this.changeTab("allresult")}
								>
									<i className="fa fa-check-square" style={{ fontSize: "0.9rem", marginRight: "8px" }}></i> All (
									{tab_count.all})
								</Tab> */}

							</TabList>

							{!this.state.loading && !this.state.tabCountLoading ? (
								<div className="tab-content-cls">
									<TabPanel>{this.rendertable(0, allresult, companyData)}</TabPanel>
									<TabPanel>{this.rendertable(0, verified, companyData)}</TabPanel>
									<TabPanel>
										<MerchantDocPending defaultCountryCode={defaultCountryCode} />
									</TabPanel>


									{/* <TabPanel>{this.rendertable(0, unverified, companyData)}</TabPanel> */}
									<TabPanel>{this.rendertable(0, unverified_email, companyData)}</TabPanel>
									<TabPanel>{this.rendertable(0, unverified_email_above_5_days, companyData)}</TabPanel>

									<TabPanel>{this.rendertable(0, plan_not_selected, companyData)}</TabPanel>
									<TabPanel>{this.rendertable(1, store_details_pending, companyData)}</TabPanel>

									{/* <TabPanel>{this.rendertable(0, expired_plan, companyData)}</TabPanel> */}
									<TabPanel>{this.rendertable(3, flagged, companyData)}</TabPanel>

									{/* <TabPanel>{this.rendertable(4, allresult, companyData)}</TabPanel> */}

									{/* <TabPanel>{this.rendertable(5, underreview, companyData)}</TabPanel> */}
									{/* 

									<TabPanel>{this.rendertable(5)}</TabPanel> */}

									{/* <TabPanel>{this.rendertable(1, invited_users, companyData)}</TabPanel> */}
									{/* <TabPanel>
										{this.rendertable(6, subscription_invoices, companyData)}
									</TabPanel> */}
									<TabPanel>{this.rendertable(2, blocked, companyData)}</TabPanel>
									<TabPanel>{this.rendertable("lockedCount", lockedCount, companyData)}</TabPanel>


								</div>
							) : (
								<>
									{/* {this.state.loading ? ( */}
									<div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
										<div className="loader" style={{ display: "flex", alignItems: "center" }}>
											<DisappearedLoading size="medium" color="#514F4E" />
										</div>
									</div>
									{/* ) : (
									)} */}
									{/* <div className="loader-box" style={{ height: "auto", padding: 20 }}>
											<div className="loader" style={{ display: "flex", alignItems: "center" }}>
												No Data
											</div>
										</div> */}
								</>
							)}
						</Tabs>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default withRouter(BorderTable);
