import React, { Component, Fragment } from "react";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment-timezone";
import { NavLink } from "react-router-dom";
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	TabContent,
	TabPane,
	Nav,
	NavItem,
	UncontrolledTooltip,
	NavLink as NavLinkTab,
} from "reactstrap";
import { Image } from "react-bootstrap";
import PaginationComponent from "react-reactstrap-pagination";
import HosposafeShareReport from "../../components/forms/hosposafeShareReport";
import SweetAlert from "react-bootstrap-sweetalert";
import FileSaver from "file-saver";
import { CSVLink } from "react-csv";
import Icon from "@mdi/react";
import { mdiChevronDown, mdiWindowClose, mdiEye, mdiClose } from '@mdi/js';
import Breadcrumb from "../../components/common/breadcrumb";
import { DisappearedLoading } from "react-loadingg";
import displayMobile from "../../components/ui-elements/display_mobile";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import DatePicker from "react-datepicker";
import Datechoose from "../../components/ui-elements/datePicker";
import ImageIcon from "@material-ui/icons/Image";
import PulseLoader from "react-spinners/PulseLoader";
import { HiDownload } from "react-icons/hi";

class HosposafeVisitors extends Component {
	state = {
		visitorsData: [],
		deleteModal: false,
		access_level: localStorage.getItem("accessLevel"),
		visitId: null,
		pageSize: 15,
		activePage: 1,
		searchValue: null,
		searchedData: null,
		deleteSelectedModal: false,
		selectedIDs: [],
		infoModal: false,
		viewModal: false,
		note: null,
		applyFilterType: this.props.location.state ? 0 : 1,
		filteredDate: null,
		mailModal: false,
		loadingmail: false,
		adminUser: JSON.parse(localStorage.getItem("user")),
		csvData: [],
		csvHeader: [],
		startDate: null,
		endDate: null,
		dataLoading: false,
		openAddImage: false,
		imageData: { imageData: "", error: false, msg: "" },
		selectedVisitorID: null,
		// dataCSV:[],
		// dataHeader:[]
		visitorLog: [],
		viewIdModal: false,
		selectedScanID: null,
		refuseData: null,
		refuseModal: false,
		refuseStatus: false,
		flagStatus: false,
		viewData: [],
		viewCardImage: null,
		downloading: false
	};

	myRef = React.createRef();

	componentDidMount() {
		this.getVisitors();
	}

	componentDidUpdate() {
		if (this.state.csvData.length > 0) {
			setTimeout(() => {
				document.querySelector("#csv").click();
				this.setState({ csvData: [], csvHeader: [] });
			}, 10);
		}
		// if (this.props.location.state) {
		// 	this.getVisitors();
		// }
	}

	getTimeZone = () => {

		try {
				const company = JSON.parse(localStorage.getItem('@_company'));

				if (company && company.timeZone) {
						return company.timeZone;
				} else {
						return "Australia/Sydney";
				}
		} catch {
				return "Australia/Sydney";
		}

}

	get_export_fields = (item) => {
		try {
			let form_fields = JSON.parse(item.form_fields);
			let data = [];
			Object.keys(form_fields).map((key) => {
				data.push(<div><strong style={{ textTransform: 'capitalize' }}>{key.replaceAll('_', ' ')}</strong> : {form_fields[key]}</div>)
			});
			return data;
		} catch (err) {
			return (
				<div>
					{item?.name &&
						<div>
							<strong style={{ textTransform: 'capitalize' }}>Name</strong> : {item?.name}
						</div>
					}
					{item?.email &&
						<div>
							<strong style={{ textTransform: 'capitalize' }}>Email</strong> : {item?.email}
						</div>
					}
					{item?.mobile &&
						<div>
							<strong style={{ textTransform: 'capitalize' }}>Mobile</strong> : {item?.mobile}
						</div>
					}
				</div>
			)
		}
	};

	onExportSubmit = (event) => {
		event.preventDefault();
		const { visitorsData } = this.state;

		//console.log(filteredCustomers);
		const data = this.state.visitorsData.map((item) => {
			return {
				date: this.render_date(item.added_date),
				business: item.merchant_name,
				guests_no: item.guestno,
				guest_name:
					item.guests != "" && JSON.parse(item.guests).length > 0
						? item.name + ", " + JSON.parse(item.guests).map((el) => el.value)
						: item.name,
				email:
					item.email == ""
						? item.customer.length > 0
							? item.customer[0].email == ""
								? "N.A"
								: item.customer[0].email
							: "N.A"
						: item.email,


				mobile:
					item.guests != "" && JSON.parse(item.guests).length > 0
						? displayMobile(item.mobile) + ", " + JSON.parse(item.guests).map((el) => displayMobile(el.mobile))
						: displayMobile(item.mobile),
			};
		});

		const headers = [
			{ label: "Date / Time", key: "date" },
			{ label: "Business Name", key: "business" },
			{ label: "No. of guests", key: "guests_no" },
			{ label: "Guest Name", key: "guest_name" },
			{ label: "Email", key: "email" },
			{ label: "Mobile", key: "mobile" },
		];

		this.setState({ csvHeader: headers, csvData: data });
	};

	renderdatepicker = () => {
		let { startDate, endDate } = this.state;

		return (
			<div style={{ display: "flex" }}>
				<div>
					<DatePicker
						selected={startDate}
						onChange={(startDate) => this.setState({ startDate })}
						selectsStart
						startDate={startDate}
						endDate={endDate}
					/>
				</div>
				<div>
					<DatePicker
						selected={endDate}
						onChange={(endDate) => this.setState({ endDate })}
						selectsEnd
						startDate={startDate}
						endDate={endDate}
						minDate={startDate}
					/>
				</div>
			</div>
		);
	};

	dateHandler = async (date) => {
		await this.setState({ filteredDate: date, applyFilterType: 7 });
		this.getVisitors();
	};

	applyFilter = async (type) => {
		await this.setState({ applyFilterType: type });

		if (type != 8) {
			this.getVisitors();
		}
	};

	toggleMailModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				mailModal: !prevState.mailModal,
			};
		});
	};

	getVisitors = () => {

		// console.log(this.props.location.state, "this.props.location.state");
		const postdata = {
			applyFilterType: this.props.location.state ? 0 : this.state.applyFilterType,
			filteredDate: this.state.filteredDate,
			startDate: this.state.startDate,
			endDate: this.state.endDate,
			fetchByVisitorId: this.props.location.state ? this.props.location.state.visitor_id : null
		};
		this.setState({ dataLoading: true });
		client.post(api.get_all_visitors, postdata, (error, response) => {
			if (!error) {
				if (!response.error) {
					// console.log(response, "response");
					this.setState((prevState) => {
						return {
							...prevState,
							visitorsData: response.result.data.reverse(),
						};
					});
				} else {
					setTimeout(() => {
						toast.error(" No visitors found");
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
			this.setState({ dataLoading: false });
		});
	};

	renderMailModal = () => {
		const { mailModal, loadingmail } = this.state;

		return (
			<Modal isOpen={mailModal} toggle={this.toggleMailModal} className="modal-body" centered={true}>
				<HosposafeShareReport
					loadingmail={loadingmail}
					toggleMailModal={this.toggleMailModal}
					createAndDownloadPdf={this.createAndDownloadPdf}
				/>
			</Modal>
		);
	};

	refreshPage = () => {
		this.getVisitors();
	};

	handlePageChange = (pageNumber) => {
		this.setState({ activePage: pageNumber });
	};

	deleteClick = (id) => {
		this.setState((prevState) => {
			return {
				...prevState,
				deleteModal: !prevState.deleteModal,
				visitId: id,
			};
		});
	};

	onSearchChangeHandler = (event) => {
		this.setState({ searchValue: event.target.value }, () => {
			this.handleSearchSubmit(event)
		});
	};

	handleSearchSubmit = (event) => {
		event.preventDefault();
		const { searchValue } = this.state;
		let data = null;

		this.handlePageChange(1)

		if (searchValue == null || searchValue == "") {
			this.setState({ searchedData: null });
		} else {
			const filteredData = [];
			data = this.state.visitorsData;

			data.filter((item) => {

				if (
					item.table_no?.toLowerCase().includes(searchValue.toLowerCase()) ||
					item.form_fields?.toLowerCase().includes(searchValue.toLowerCase()) ||
					item.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
					item.merchant_name?.toLowerCase().includes(searchValue.toLowerCase()) ||
					item.mobile?.toLowerCase().includes(searchValue.toLowerCase()) ||
					this.render_date(item.added_date).toLowerCase().includes(searchValue.toLowerCase())
				) {
					filteredData.push(item);
					//this.setState({ searchedData: filteredData });
				}
				// else if (item.guests != "" && JSON.parse(item.guests).length > 0) {
				// 	// const guests = JSON.parse(item.guests);
				// 	// // console.log(guests);
				// 	// const foundName = guests.filter((el) => {
				// 	// 	return el.value.toLowerCase().includes(searchValue.toLowerCase());
				// 	// });
				// 	// // console.log(foundName);
				// 	// if (foundName.length) {
				// 	// 	filteredData.push(item);
				// 	// }
				// 	// console.log(filteredData);
				// 	//this.setState({ searchedData: filteredData });
				// }
				this.setState({ searchedData: filteredData });
			});
			// console.log(filteredData);
			//this.setState({ searchedData: filteredData });
		}

		// this.setState({activePage : 1})
	};

	render_date = (add_date) => {
		if (typeof add_date === "undefined") {
			return;
		}

		const convert_date = new Date(add_date);
		const added_date = moment.tz(convert_date, "Australia/Sydney").format("DD/MM/YYYY HH:mm");
		return added_date;
	};

	setPageSize = (event) => {
		this.setState({ pageSize: event.target.value });
	};

	get_fields = (item) => {
		try {
			let form_fields = JSON.parse(item.form_fields);
			let data = [];
			Object.keys(form_fields).map((key) => {
				data.push(<div style={{ width: '300px' }}><strong style={{ textTransform: 'capitalize' }}>{key.replaceAll('_', ' ')}</strong> : {form_fields[key]}</div>)
			});
			return data;
		} catch (err) {
			return (
				<div>
					{item?.name &&
						<div style={{ width: '300px' }}>
							<strong style={{ textTransform: 'capitalize' }}>Name</strong> : {item?.name}
						</div>
					}
					{item?.email &&
						<div style={{ width: '300px' }}>
							<strong style={{ textTransform: 'capitalize' }}>Email</strong> : {item?.email}
						</div>
					}
					{item?.mobile &&
						<div style={{ width: '300px' }}>
							<strong style={{ textTransform: 'capitalize' }}>Mobile</strong> : {item?.mobile}
						</div>
					}
				</div>
			)
		}
	};

	onRefuseFlagClick = (refuse_data) => {

		let postData = {
			vID: refuse_data._id,
			refuse: refuse_data.refuse,
			flag: refuse_data.flag
		};
		let current = this;
		client.post(api.getRefuse_visitorRegistry, postData, (error, response) => {
			if (!error) {
				if (typeof response.error !== "undefined") {
					current.setState({ refuseModal: true });
					current.setState({ refuseStatus: refuse_data.refuse });
					current.setState({ flagStatus: refuse_data.flag });
					current.setState({ refuseData: response.result });
				} else {
					console.log('Internal Error')
				}
			} else {
				console.log('Internal Error')
			}
		});
	}

	infoClick = (id) => {
		this.setState((prevState) => {
			return {
				...prevState,
				infoModal: !prevState.infoModal,
				visitId: id,
			};
		});
	};
	viewClick = (id) => {
		let postData = { vID: id._id };

		client.post(api.getById_visitorRegistry, postData, (error, response) => {
			if (!error) {
				if (typeof response.error !== "undefined") {
					this.setState((prevState) => {
						return {
							...prevState,
							viewModal: !prevState.viewModal,
							visitId: id,
							viewData: response.result,
							viewCardImage: response.profileBase
						};
					});
				} else {
					console.log('Internal Error')
				}
			} else {
				console.log('Internal Error')
			}
		});
	};

	toggleInfoModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				infoModal: !prevState.infoModal,
			};
		});
	};

	toggleAddImageModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				openAddImage: !prevState.openAddImage,
			};
		});
	};

	onChangeNoteInput = (event) => {
		const flagNote = event.target.value;
		this.setState((prevState) => {
			return {
				...prevState,
				note: flagNote,
			};
		});
	};

	noteSubmitHandler = (event) => {
		const { adminUser, visitId } = this.state;
		event.preventDefault();

		const postdata = {
			visitId: visitId._id,
			comment: {
				type: "normal",
				note: this.state.note,
				user: adminUser._id,
				date: new Date(),
			},
		};
		// console.log(postdata);
		client.post(api.updateVisitEntry, postdata, (error, response) => {
			if (!error) {
				if (!response.error) {
					// console.log(response);
					this.refreshPage();
					this.setState((prevState) => {
						return {
							...prevState,
							infoModal: false,
						};
					});
				} else {
					setTimeout(() => {
						toast.error(response.message);
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
		});
	};

	renderRefuseModal = () => {
		return (
			<Modal
				isOpen={this.state.refuseModal}
				toggle={() => this.setState({ refuseModal: !this.state.refuseModal })}
				className="modal-body"
				centered={true}
				style={{ maxWidth: "500px", textAlign: "left" }}
			>

				<ModalBody
					style={{
						textAlign: "left",
						paddingTop: "0",
						paddingBottom: "0"
					}}
				>
					<Icon
						style={{ position: 'absolute', right: '0' }}
						path={mdiWindowClose}
						size={1}
						onClick={() => this.setState({ refuseModal: !this.state.refuseModal })}
					/>

					<div style={{ padding: 16, backgroundColor: "#fff", display: 'flex', flexDirection: 'column' }}>
						<h4 style={{ textDecoration: 'underline', marginBottom: '1rem' }}>{'VISITOR LOGS'} </h4>
						<br />
						{this.state.refuseData && this.state.refuseData.map((item) => {

							if (item.log_text) {
								return (
									<li style={{ listStyle: 'none' }}><strong>{moment.tz(item.added_date, "Australia/Sydney").format('DD-MM-YYYY hh:mm:ss A')}</strong> {item.log_text}</li>
								)
							} else {
								return (
									<>
										{item.refuse_status ?
											<li style={{ listStyle: 'none' }}><strong>{moment.tz(item.added_date, "Australia/Sydney").format('DD-MM-YYYY hh:mm:ss A')}</strong> Refused by {`${item.merchant_name}`} for {item.refuse_reason} {item.refuse_additional_info ? `(${item.refuse_additional_info})` : null}</li>
											: item.flag_status ?
												<li style={{ listStyle: 'none' }}><strong>{moment.tz(item.added_date, "Australia/Sydney").format('DD-MM-YYYY hh:mm:ss A')}</strong> Banned by {`${item.merchant_name}`} for {item.flag_reason} {item.flag_additional_info ? `(${item.flag_additional_info})` : null}</li>
												: null
										}
									</>
								)
							}
						})}
						<br />
					</div>
					<div style={{ display: 'flex', marginBottom: "1rem", flexDirection: 'row', justifyContent: 'center' }}>
						<Button onClick={() => this.setState({ refuseModal: !this.state.refuseModal })} className="btn btn-primary" style={{ margin: 0 }} type="button">
							Close
						</Button>
					</div>
				</ModalBody>
			</Modal>
		);
	}

	renderInfoModal = () => {
		if (typeof this.state.visitId.comment == "undefined") {
			this.state.visitId.comment = [];
		}
		let foundComment = [...this.state.visitId.comment].reverse();
		return (
			<Modal isOpen={this.state.infoModal} toggle={this.toggleInfoModal} className="modal-body" centered={true}>
				<h5 style={{ padding: "15px", margin: "0" }}>Comments</h5>

				<ModalBody
					style={{
						paddingTop: "0",
						paddingBottom: "0",
						maxHeight: "calc(100vh - 370px)",
						overflowY: "auto",
					}}
				>
					<div className="card-body chat-box" style={{ paddingTop: "0", paddingBottom: "0" }}>
						<div className="chat-right-aside bitcoin-chat">
							<div className="chat">
								<div className="chat-history chat-msg-box custom-scrollbar" style={{ padding: "0", margin: "0" }}>
									<ul style={{ margin: "0" }}>
										{foundComment.length != 0 ? (
											foundComment.map((el) => {
												return (
													<li style={{ margin: "0" }}>
														<div
															className="message my-message "
															style={{
																marginBottom: "10px",
																paddingTop: "10px",
																paddingBottom: "10px",
															}}
														>
															<p>
																{el.admin_name}
																<span className="message-data-time pull-right">{this.render_date(el.date)}</span>{" "}
															</p>
															{el.type == "normal" ? null : <p style={{ fontSize: "0.9rem" }}>Type : {el.type}</p>}
															{el.note}
														</div>
													</li>
												);
											})
										) : (
											<p>No comment added!</p>
										)}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter style={{ paddingTop: "10px", paddingBottom: "10px" }}>
					<div className="col-lg-12">
						<form className="theme-form">
							<div className="form-group mb-0 col-12">
								<label htmlFor="exampleFormControlTextarea4">Comment:</label>
								<textarea
									className="form-control"
									id="exampleFormControlTextarea4"
									required
									rows="3"
									onChange={this.onChangeNoteInput}
								></textarea>
							</div>
							<div style={{ padding: "15px 0 0 15px" }}>
								<Button color="secondary" style={{ marginRight: "15px" }} onClick={this.toggleInfoModal}>
									Cancel
								</Button>
								<Button color="primary" type="submit" name="comment" onClick={(event) => this.noteSubmitHandler(event)}>
									Submit
								</Button>
							</div>
						</form>
					</div>
				</ModalFooter>
			</Modal>
		);
	};
	toggleViewModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				viewModal: !prevState.viewModal,
			};
		});
	}
	renderViewModal = () => {
		let formData = JSON.parse(this.state.viewData.form_fields)
		let data = this.state.viewData
		// console.log(data,"data")
		// console.log(this.state.viewCardImage, "viewCardImage")
		let convert_date = new Date(data.added_date);
		let scan_convert_date = new Date(data.scanid_time);
		let x = new moment.tz(data.added_date, this.getTimeZone())
		let y = new moment.tz(data.checkout, this.getTimeZone())
		let duration = moment.duration(y.diff(x));
		const show_time = convert_date ? moment.tz(convert_date, this.getTimeZone()).format('DD-MM-YYYY hh:mm:ss A') : null
		const scan_time = scan_convert_date ? moment.tz(scan_convert_date, this.getTimeZone()).format('DD-MM-YYYY hh:mm:ss A') : null
		const chekcout = data.checkout ? moment.tz(data.checkout, this.getTimeZone()).format('DD-MM-YYYY hh:mm:ss A') : null
		const hr = duration.hours() <= 9 ? '0' + duration.hours() : duration.hours()
		const min = duration.minutes() <= 9 ? '0' + duration.minutes() : duration.minutes()
		const sec = duration.seconds() <= 9 ? '0' + duration.seconds() : duration.seconds()
		const du = hr + ':' + min + ":" + sec

		let isImage = false;

		if(formData['camImageFull'] || formData['camImageName']){
			isImage = true;
		}

		let tableData = null;

		// if(data.table_no && storeTableData.length > 0){
		// 	tableData = storeTableData.find((item) => item.table_name.toLowerCase())
		// }

		return (
			<Modal isOpen={this.state.viewModal} toggle={this.toggleViewModal} className="modal-body" centered={true} style={{maxWidth: '650px'}}>
				{/* <ModalHeader toggle={this.toggleViewModal}>
					<h5 style={{ padding: "15px", margin: "0", textTransform: 'uppercase', textAlign: 'center' }}>Visitor Details</h5>
				</ModalHeader> */}

				<ModalBody
					style={{
						// paddingTop: "0",
						// paddingBottom: "0",
						// maxHeight: "calc(100vh - 370px)",
						// overflowY: "auto",
					}}
				>
					<Icon
							style={{
								position: 'absolute',
								right:  '10px',
								top: "1rem"
							}}
							path={mdiWindowClose}
							size={1}
							onClick={this.toggleViewModal}
						/>
					<h5 style={{textAlign: 'center',padding: '1rem',textDecoration: 'underline'}}>VISITOR CARD DETAILS</h5>
					<div className="card-body chat-box d-flex" style={{ paddingTop: "0", paddingBottom: "0" }}>
						{
							isImage && 
							<div style={{ padding: 6, border: '1px solid #eee', width: "50%" }}>
							<Image
								style={{ width: "100%", height: "25rem", borderRadius: "0.5rem", objectFit: "cover" }}
								src={`data:image/png;base64, ${this.state.viewCardImage}`} 
							/>
						</div>
						}
						<div className="px-4">
						<div>
							{data.table_no ? <><strong>Function/Table</strong> : {data.table_no} </> : null}</div>
							<div><strong>No of Visits</strong>: {data.guests}</div>
							{this.get_fields(data)}
							<div><strong>Time of QR Registry</strong> : {`${show_time}`}</div>
							<div>{scan_time ? <><strong>Time of ID Scanned</strong>: {scan_time}</> : null}</div>
							<div><strong>Check-in Time</strong> : {`${show_time}`}</div>
							<div>{data.checkout ?
								<>
									<strong>Check-out Time</strong>: {chekcout}
								</> : null}
							</div>
							<div>{data.checkout ?
								<>
									<strong>Duration</strong>: {du}
								</> : null}
							</div>
						</div>
					</div>
					{/* <div className="d-flex justify-content-center mt-4">
						<Button>
								{this.state.downloading ? <PulseLoader color="#D5F333" size={11}/> : <span><HiDownload style={{color:"white"}}/>DOWNLOAD</span>}
						</Button>
					</div> */}
				</ModalBody>
				{/* <ModalFooter style={{ paddingTop: "10px", paddingBottom: "10px" }}>
					<div className="col-lg-12">

					</div>
				</ModalFooter> */}
			</Modal>
		);
	};

	deleteSelectedClick = (event, id) => {
		if (event.target.checked) {
			this.setState((prevState) => {
				return {
					...prevState,
					selectedIDs: [...prevState.selectedIDs, id],
				};
			});
		} else {
			if (this.state.selectedIDs.includes(id)) {
				const idsToKeep = this.state.selectedIDs.filter((el) => {
					return el != id;
				});
				this.setState((prevState) => {
					return {
						...prevState,
						selectedIDs: idsToKeep,
					};
				});
			} else {
				return;
			}
		}
	};

	readUrl = async (event) => {
		event.preventDefault();
		event.persist();
		let targetName = await event.target.name;
		let files = await event.target?.files;
		var mimeType = files[0].type;
		if (files) {
			if (mimeType.includes("image")) {
				if (files[0].size <= 102400) {
					var reader = new FileReader();
					reader.readAsDataURL(files[0]);
					reader.onload = (_event) => {
						let imageData;
						imageData = reader.result;
						imageData = imageData.split(",")[1];
						let image_data = {
							mime: mimeType,
							data: imageData,
							imageresult: reader.result,
						};
						this.setState({
							imageData: { imageData: image_data, error: false, msg: "" },
						});
					};
				} else {
					this.setState({
						imageData: {
							imageData: "",
							error: true,
							msg: "Please insert image size less than 100Kb",
						},
					});
				}
			} else
				this.setState({
					imageData: { imageData: "", error: true, msg: "Invalid file format" },
				});
		}
	};

	saveImageServer = (e) => {
		e.preventDefault();

		const postdata = {
			id: this.state.selectedVisitorID,
			newImageData: this.state.imageData.imageData,
		};
		//const postdata = { id: "1234", newImageData :this.state.imageData.imageData }
		// console.log("postdata", postdata);
		client.post(api.addVisitorImage, postdata, (error, response) => {
			if (!error) {
				if (!response.error) {
					// console.log(response);
					this.toggleAddImageModal();
				} else {
					setTimeout(() => {
						toast.error(response.message);
					}, 200);
					// console.log(response.message);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);

				console.log(error);
			}
		});
	};

	renderaddImageModal = () => {
		// console.log(this.state.imageData);
		return (
			<Modal isOpen={this.state.openAddImage} toggle={this.toggleAddImageModal} className="modal-body" centered={true}>
				<ModalHeader>Add Visitor Image</ModalHeader>
				<ModalBody>
					<div onClick={() => this.myRef.current.click()}>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<div
								hidden={this.state.imageData?.imageData.imageresult}
								style={{
									width: "200px",
									height: "200px",
									background: "lightgrey",
									borderRadius: "0.25rem",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<ImageIcon style={{ fontSize: 30 }} />
							</div>
							{this.state.imageData?.imageData.imageresult ? (
								<img src={this.state.imageData.imageData.imageresult} style={{ width: "200px", height: "200px" }} />
							) : null}
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={this.toggleAddImageModal} style={{ marginRight: "15px" }}>
						Cancel
					</Button>
					<Button color="primary" type="submit" name="comment" onClick={(e) => this.saveImageServer(e)}>
						Submit
					</Button>
				</ModalFooter>
			</Modal>
		);
	};

	renderVisitorDetails = (item) => {
		let convert_date = new Date(item.added_date);
		let x = new moment.tz(item.added_date, "Australia/Sydney")
		let y = new moment.tz(item.checkout, "Australia/Sydney")
		let duration = moment.duration(y.diff(x));
		const show_time = convert_date ? `Check-in time : \n ` + moment.tz(convert_date, "Australia/Sydney").format('DD-MM-YYYY hh:mm:ss A') : null
		const chekcout = item.checkout ? `Check-out time: \n` + moment.tz(item.checkout, "Australia/Sydney").format('DD-MM-YYYY hh:mm:ss A') : null
		const visits = `No of visits: ${item.guests}`
		const hr = duration.hours() <= 9 ? '0' + duration.hours() : duration.hours()
		const min = duration.minutes() <= 9 ? '0' + duration.minutes() : duration.minutes()
		const sec = duration.seconds() <= 9 ? '0' + duration.seconds() : duration.seconds()
		const du = `Duration: ` + hr + ':' + min + ":" + sec
		return (
			<>
				<div style={{ width: '150px' }}>{`${show_time}`}</div>
				<div style={{ width: '150px' }}>{item.checkout ? `${chekcout} \n` : ''}</div>
				<div style={{ width: '150px' }}>{item.checkout ? `${du}\n` : ''}</div>
				<div style={{ width: '150px' }}>{visits}</div>
			</>
		)
	}
	get_fields = (item) => {
		try {
			let form_fields = JSON.parse(item.form_fields);
			let data = [];
			let mobile_prefix = form_fields.country_code ? form_fields.country_code : '';
			let scan_id = false;
			let scan_image_name = "";
			Object.keys(form_fields).map((key) => {
				// console.log(key)
				if (key == "country_code" || key == "faceId" || key == "scanIdImg" || key == "scanIdUri" || key == "camPhoto" || key == "Licence_type" || key == "Licence_no" || key == "camImageName" || key == "Address" || key == "Table_no" || key == "camImageFull") {

				} else {
					if (form_fields[key] && key != "scanImageName") {
						data.push(<div><strong style={{ textTransform: 'capitalize' }}>{key.replaceAll('_', ' ')}</strong> : {key == "mobile_number" ? `${mobile_prefix}` : null} {key == "Name" ? form_fields[key].replace(/\r?\n|\r/g, " ") : form_fields[key]}</div>);
					}

					if (form_fields['scanImageName']) {
						scan_id = true;
						scan_image_name = form_fields['scanImageName'];
					}
				}

			});

			if (scan_id) {
				data.push(<div onClick={() => this.viewIdClick(scan_image_name)} className="mt-1"><a style={{ textDecoration: 'underline', color: '#007bff', cursor: 'pointer', fontWeight: '500' }}>View ID</a></div>);
			}
			return data;
		} catch (err) {
			return (
				<div>
					{item?.name &&
						<div>
							<strong style={{ textTransform: 'capitalize' }}>Name</strong> : {item?.name}
						</div>
					}
					{item?.email &&
						<div>
							<strong style={{ textTransform: 'capitalize' }}>Email</strong> : {item?.email}
						</div>
					}
					{item?.mobile &&
						<div>
							<strong style={{ textTransform: 'capitalize' }}>Mobile</strong> : {item?.mobile}
						</div>
					}
				</div>
			)
		}
	};

	get_image = (item) => {
		try {

			let form_fields = JSON.parse(item.form_fields);
			let data = [];
			let no_image = true;
			Object.keys(form_fields).map((key) => {

				if (key == "camImageName") {
					data.push(<div style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden', position: 'relative', height: 100, width: 80 }} ><Image src={`${form_fields[key]}`} style={{
						width: '100%', padding: 1,
						paddingVertical: 40,
						position: 'absolute',
						top: -10
					}} /></div>);
					no_image = false;
				}

			});
			if (no_image) {
				data.push(<div style={{ display: 'flex', justifyContent: 'center', alignSelf: 'center' }}><Image src={require("../../assets/images/No-Image.png")} style={{ width: '80px' }} /></div>);
			}
			return data;

		} catch (e) {

		}
	}

	renderViewIDModal = () => {
		return (
			<Modal
				isOpen={this.state.viewIdModal}
				toggle={() => this.setState({ viewIdModal: !this.state.viewIdModal })}
				className="modal-body"
				centered={true}
				style={{ maxWidth: "600px", textAlign: "left" }}
			>
				<ModalHeader><div style={{ textAlign: "left", fontWeight: "600" }}> Visitor Scan ID Image </div> </ModalHeader>

				<ModalBody
					style={{
						textAlign: "left",
						paddingTop: "0",
						paddingBottom: "0"
					}}
				>
					<div style={{ paddingTop: "0", paddingBottom: "0" }}>
						<Image style={{ width: '100%', height: '100%' }} src={`${this.state.selectedScanID}`} />
					</div>
				</ModalBody>
			</Modal>
		);
	}

	viewIdClick = (scanIDName) => {
		this.setState({ selectedScanID: scanIDName });
		this.setState({ viewIdModal: true });
	}

	renderAllTable = () => {
		let showVisits = [];
		let { visitorsData, searchedData, access_level, applyFilterType } = this.state;
		if (searchedData == null) {
			showVisits = [...visitorsData];
		} else {
			showVisits = [...searchedData];
		}

		let totalCustomers = 0;
		let test = []
		showVisits.forEach(val => {
			if (test.indexOf(val.mobile) == -1) {
				test.push(val.mobile);
				totalCustomers++
			} else {
			}

		})

		// const totalCustomers = showVisits.map((el) => +el.mobile).reduce((el, nextEl) => el + nextEl, 0);

		// console.log(showVisits);

		return (
			<Fragment>
				<p>{("Modal stateus", this.state.openAddImage)}</p>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						marginBottom: '5px'
					}}
				>
					<div>
						<form onSubmit={(event) => this.handleSearchSubmit(event)}>
							<div>
								<input
									style={{
										border: "#dfe8f1 solid 1px",
										borderRadius: "3px",
										padding: "10px",
										fontSize: "0.75rem",
										outlineColor: '#dfe8f1'
									}}
									type="text"
									placeholder="Search"
									onChange={this.onSearchChangeHandler}
								/>
							</div>
						</form>
					</div>

					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<p className="mb-0 mr-2">Total Visits : {showVisits.length},</p>
						<p>Total Customers : {totalCustomers}</p>
					</div>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<select
							className="custom-select"
							style={{
								width: "35%",
								fontSize: "0.63rem",
								marginRight: "10px",
							}}
							onChange={this.setPageSize}
							value={this.state.pageSize}
						>
							<option value="15">15</option>
							<option value="25">25</option>
							<option value="50">50</option>
							<option value="75">75</option>
							<option value="100">100</option>
						</select>
						<span style={{ fontSize: "0.63rem" }}>Records per Page</span>
					</div>

				</div>

				<div className="table-responsive">
					<table className="table table-border-horizontal"
					// style={{ tableLayout: 'fixed' }}
					>
						<thead>
							<tr style={{ background: '#f9fafe' }}>
								<th scope="col">#</th>
								{/* <th scope="col">Image</th> */}
								<th scope="col" style={{ width: '80px' }}>Photo</th>
								<th scope="col" style={{ width: '150px' }}>Date / Time of visit</th>
								<th scope="col" style={{ width: "150px" }}>Business Name</th>
								<th scope="col" style={{ width: '300px' }}>Visitor Details</th>
								{/* <th scope="col">No. of Guests</th> */}
								{/* <th scope="col">Guest Name</th>
								<th scope="col" style={{ width: "16%" }}>
									Mobile
								</th> */}
								<th scope="col" >Table no.</th>
								{/* <th scope="col">Email</th> */}
								{/* <th scope="col">Last Note</th> */}
								<th scope="col">Notes</th>
								<th scope="col">Status</th>
								{access_level != 1 && <th scope="col">Action</th>}
								{/* <th scope="col" >Notes</th> */}
							</tr>
						</thead>
						<tbody>
							{showVisits.length != 0 ? (
								showVisits
									.slice((this.state.activePage - 1) * this.state.pageSize, this.state.activePage * this.state.pageSize)
									.map((item, index) => {
										var guestObj = [];
										if (item.guests) {
											guestObj = JSON.parse(item.guests);
											{/* console.log(guestObj, "itmmmm"); */ }
										}
										let scan_id = false;
										let form_fields = item.form_fields ? JSON.parse(item.form_fields) : {};
										if (form_fields['scanImageName']) {
											scan_id = true;
										}
										const convert_date = new Date(item.added_date ? item.added_date : item.createdAt);
										const scanned_date = item.scanid_time ? new Date(item.scanid_time) : item.added_date && scan_id ? new Date(item.added_date) : null;
										var x = new moment.tz(item.added_date, this.getTimeZone())
										var y = new moment.tz(item.checkout, this.getTimeZone())
										let duration = moment.duration(y.diff(x));
										const show_time = convert_date ? moment.tz(convert_date, this.getTimeZone()).format('DD-MM-YYYY hh:mm:ss A') : null
										const scan_time = scanned_date ? `Time of ID scanned : \n ` + moment.tz(scanned_date, this.getTimeZone()).format('DD-MM-YYYY hh:mm:ss A') : null
										const chekcout = item.checkout ? `Check-out time: \n` + moment.tz(item.checkout, this.getTimeZone()).format('DD-MM-YYYY hh:mm:ss A') : null
										const visits = `No of visits: ${item.guests == "[]" ? 0 : item.guests}`
										const hr = duration.hours() <= 9 ? '0' + duration.hours() : duration.hours()
										const min = duration.minutes() <= 9 ? '0' + duration.minutes() : duration.minutes()
										const sec = duration.seconds() <= 9 ? '0' + duration.seconds() : duration.seconds()
										const du = `Duration: ` + hr + ':' + min + ":" + sec
										return (
											<tr key={item._id}>
												<th scope=" row">{(this.state.activePage - 1) * this.state.pageSize + index + 1}</th>
												<td>{this.get_image(item)}</td>
												{/* <td>{this.render_date(item.added_date)}</td> */}
												<td>
													
														{item.entry_source == 0 ?
															<div style={{ whiteSpace: 'pre-line' }}>
																{`Time of QR Registry : \n `}
																<span style={{ }}>{`${show_time}`}</span>
															</div> : null}
														{scan_time ? <div style={{ whiteSpace: 'pre-line' }}>{`${scan_time}`}</div> : null}
														<div style={{ whiteSpace: 'pre-line' }}>{item.checkout ? `${chekcout} \n` : ''}</div>
														<div style={{ whiteSpace: 'pre-line' }}>{item.checkout ? `${du}\n` : ''}</div>
														<div>{visits}</div>
														{item.scanned_by ? <div>{`Scanned by: ${item.scanned_by}`}</div> : null}
													
												</td>
												<td>
													<NavLink
														to={{
															pathname: `${process.env.PUBLIC_URL}/merchants/merchantDetails/${item.merchant_id}`,
														}}
														style={{ color: item.status == 2 && "red" }}
													>
														{item.merchant_name}
													</NavLink>
												</td>
												<td>{this.get_fields(item)}</td>

												<td>{item.table_no ? item.table_no : ""}</td>

												{/* <td>
													{
														//item.image

														item.image ? (
															<div
																onClick={() =>
																	this.setState({
																		openAddImage: true,
																		selectedVisitorID: item._id,
																		imageData: {
																			imageData: {
																				imageresult: `${client.url(`images/visitors/${item.image}`)}`,
																			},
																		},
																	})
																}
																style={{
																	width: "25px",
																	height: "25px",
																	margin: "0.25rem 0",
																}}
															>
																<img
																	src={`${client.url(`images/visitors/${item.image}`)}`}
																	style={{ width: "100%", height: "100%" }}
																/>
															</div>
														) : (
															<div style={{ width: '80px' }}>
																<input
																	ref={this.myRef}
																	type="file"
																	accept="image/*"
																	onChange={(e) => this.readUrl(e)}
																	hidden
																/>
																<ImageIcon
																	onClick={() =>
																		this.setState({
																			openAddImage: true,
																			selectedVisitorID: item._id,
																		})
																	}
																	style={{ fontSize: 20, height: '80px', width: '80px', color: "lightgrey" }}
																/>
															</div>
														)
													}
												</td>
												<td>
													
													{this.renderVisitorDetails(item)}</td>
												<td> 
													<NavLink
														to={{
															pathname: `${process.env.PUBLIC_URL}/merchants/merchantDetails/${item.merchant_id}`,
														}}
														style={{ color: item.status == 2 && "red", width: '150px' }}
													>
														{item.merchant_name}
													</NavLink>
												</td>
												<td>{this.get_fields(item)}</td>

												<td >{item.table_no ? item.table_no : ""}</td>*/}
												<td>
													{item.comment && Array.isArray(item.comment) && item.comment.length
														? item.comment[item.comment.length - 1].note
														: "N.A"}
												</td>
												<td>
													<span style={{ color: 'green', textAlign: 'center' }}>
														{item.refuse_status ? <span onClick={() => { this.onRefuseFlagClick({ _id: item._id, refuse: true, flag: false }) }} style={{ textDecoration: 'underline', cursor: 'pointer', color: '#B70000', textAlign: 'center' }}>Refused</span>
															: item.flag_status ?
																<span onClick={() => { this.onRefuseFlagClick({ _id: item._id, refuse: false, flag: true }) }} style={{ textDecoration: 'underline', cursor: 'pointer', color: '#E3890F', textAlign: 'center' }}>Banned</span>
																: <span style={{ color: 'green', textAlign: 'center' }}>Welcomed</span>}</span>
												</td>

												{access_level != 1 && (
													<td>
														<div className="d-flex"><input
															type="checkbox"
															style={{ marginRight: "4px" }}
															onClick={(event) => this.deleteSelectedClick(event, item._id)}
														/>
															<span
																style={{
																	marginRight: "10px",
																	fontSize: "0.75rem",
																}}
															>
																<i
																	style={{ color: "black" }}
																	id="delete"
																	className="fa fa-list-alt"
																	onClick={() => this.infoClick(item)}
																></i>
															</span>
															<span
																style={{
																	marginRight: "10px",
																	fontSize: "0.75rem",
																}}
															>
																<i className="fa fa-eye" id="view"
																	onClick={() => this.viewClick(item)}></i>
																	{this.state.viewModal && this.renderViewModal()}
															</span></div>
													</td>
												)}
												{/* <td >{typeof item.comment != "undefined" && item.comment.length > 0 ? item.comment[item.comment.length - 1].note : "N.A"}</td> */}
												{/* {access_level != 1 && <td style={{ width: "18%" }}>
                                            {access_level == 3 && <span style={{ marginRight: "10px", fontSize: "1.2em" }} onClick={() => this.deleteClick(item._id)}>
                                                <i style={{ color: "red" }} id="delete" className="fa fa-trash-o"></i>
                                            </span>}
                                            <span style={{ marginRight: "10px", fontSize: "1.2em" }}>
                                                <i className="fa fa-list-alt" style={{ color: "black" }} id="info"></i>
                                                <UncontrolledTooltip placement="bottom" target="info">
                                                    Info
                                                </UncontrolledTooltip>
                                            </span> */}
											</tr>
										);
									})
							) : (
								<h6 style={{ textAlign: "center" }}> No records found! </h6>
							)}

						</tbody>
					</table>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							marginTop: '12px'
						}}
					>

						{this.state.activePage * this.state.pageSize > showVisits.length ? (
							<p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>
								Showing {(this.state.activePage - 1) * this.state.pageSize + 1} to {showVisits.length} of {showVisits.length}{" "}
								entries
							</p>
						) : (
							<p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>
								Showing {(this.state.activePage - 1) * this.state.pageSize + 1} to{" "}
								{this.state.activePage * this.state.pageSize} of {showVisits.length} entries
							</p>
						)}
						<PaginationComponent
							defaultActivePage={this.state.activePage}
							totalItems={showVisits.length}
							pageSize={this.state.pageSize}
							onSelect={this.handlePageChange}
						/>
					</div>
				</div>
			</Fragment>
		);
	};

	deleteSelectedButtonClick = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				deleteSelectedModal: !prevState.deleteSelectedModal,
			};
		});
	};

	toggleDeleteSelectedModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				deleteSelectedModal: !prevState.deleteSelectedModal,
			};
		});
	};

	deleteSelectedHandler = (event) => {
		event.preventDefault();
		const postData = { selectedIDs: this.state.selectedIDs };
		client.post(api.delete_select_visit, postData, (error, response) => {
			if (!error) {
				if (!response.error) {
					toast.success(response.message);
					this.refreshPage();
					this.setState({ deleteSelectedModal: false });
				} else {
					setTimeout(() => {
						toast.error(response.message);
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
		});
	};

	renderDeleteSelectedModal = () => {
		return (
			<Modal
				isOpen={this.state.deleteSelectedModal}
				toggle={this.toggleDeleteSelectedModal}
				className="modal-body"
				centered={true}
			>
				<form className="theme-form" noValidate="" onSubmit={this.deleteSelectedHandler}>
					<ModalHeader toggle={this.toggleDeleteSelectedModal}>Delete visit records</ModalHeader>
					<ModalBody>
						<h6>Do you really want to delete the visit records ?</h6>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={this.toggleDeleteSelectedModal}>
							Not sure
						</Button>
						<Button color="primary" type="submit">
							Yes, please!
						</Button>
					</ModalFooter>
				</form>
			</Modal>
		);
	};

	onChangeStartDate = async (date) => {
		if (this.state.endDate) {
			await this.setState({ startDate: date });
			this.getVisitors();
		} else {
			this.setState({ startDate: date });
		}
	};

	onChangeEndDate = async (date) => {
		if (this.state.startDate) {
			await this.setState({ endDate: date });
			this.getVisitors();
		} else {
			this.setState({ endDate: date });
		}
	};

	render() {
		const {
			visitorsData,
			access_level,
			searchedData,
			selectedIDs,
			customersData,
			visitId,
			applyFilterType,
			startDate,
			endDate,
			selectedScanID,
			viewIdModal,
			refuseModal,
			refuseData
		} = this.state;
		//console.log(visitId);

		//console.log(filteredCustomers);

		let visitorLog = [...visitorsData];

		const headersData = [
			{ label: "Date / Time", key: "date" },
			{ label: "Business Name", key: "business" },
			// { label: "No. of guests", key: "guests_no" },
			// { label: "Guest Name", key: "guest_name" },
			// { label: "Mobile", key: "mobile" },
			// { label: "Email", key: "email" },
			// { label: "Alternative Mobile Number", key: 'AltMobileNumber' },
			{ label: "Visitor Details", key: 'VisitorDetails' },
			{ label: "Table number", key: "TableNumber" },
			{ label: "Image", key: "image" },
			{ label: "Last Note", key: "lastNote" },
			// { label: "Action", key: "action" },
		];

		const getData = [];
		visitorLog.map((val, ind) => {

			let VisitorDetails = "";


			try {
				let form_fields = JSON.parse(val.form_fields);
				// let data = "";
				Object.keys(form_fields).map((key) => {
					VisitorDetails = VisitorDetails + "\n" + key.replaceAll('_', ' ') + ": " + form_fields[key]
					// data.push(<div><strong style={{ textTransform: 'capitalize' }}>{key.replaceAll('_', ' ')}</strong> : {form_fields[key]}</div>)
				});
				// return data;
			} catch (err) {

				if (val?.name) {
					VisitorDetails = "Name:" + val.name
				}
				if (val?.email) {
					VisitorDetails = "\nEmail:" + val.email
				}
				if (val?.mobile) {
					VisitorDetails = "\nMobile:" + val.mobile
				}

			}

			getData.push({
				num: ind + 1,
				date: moment(val.added_date).format("DD-MM-YYYY h:mm A"),
				business: val.merchant_name,
				VisitorDetails: VisitorDetails,
				// guest_name: val.form_fields && val.form_fields.length > 0 ? (JSON.parse(val.form_fields).Name) : (null),
				// mobile: val.form_fields && val.form_fields.length > 0 ? (JSON.parse(val.form_fields).mobile_number) : (null),
				// email: val.form_fields && val.form_fields.length > 0 ? (JSON.parse(val.form_fields).Email_id) : (null),
				// AltMobileNumber: val.form_fields && val.form_fields.length > 0 ? (JSON.parse(val.form_fields).alternative_mobile_number) : (null),
				// Address: val.form_fields && val.form_fields.length > 0 ? (JSON.parse(val.form_fields).Address) : (null),
				TableNumber: val.table_no ? val.table_no : " - ",
				image: "",
				lastNote: "",
				// Action: "",
			});
		});

		// console.log(visitorsData, 'visitorsData');

		return (
			<div>
				{/* <input ref={this.myRef} type="file" accept="image/*" onChange={(e) => this.readUrl(e)} hidden />
                 <Button onClick={()=> this.setState({openAddImage:true})} style={{ fontSize: 20, color: 'lightgrey' }} ></Button> */}
				<div className="row">
					<div className="col-lg-7 col-sm-12" style={{ fontSize: "12px" }}>
						<Breadcrumb title={"Hospo visitors"} parent={"Home"} />
					</div>
					<div className="col-lg-5 col-sm-12 ">
						<div
							className="btn-group pull-right"
							style={{
								paddingTop: "13px",
								marginLeft: "0.4rem",
								marginRight: "0.6rem",
								marginBottom: "0rem",
							}}
							role="group"
							aria-label="Basic example"
						>
							{access_level == 3 && (
								<button
									className="btn custBtnAdmin"
									onClick={this.deleteSelectedButtonClick}
									disabled={this.state.selectedIDs.length == 0 ? "disabled" : null}
								>
									{this.state.selectedIDs.length == 0 ? (
										<i
											style={{
												color: "#327ebc",
												fontSize: "15px",
												margin: "0 7px 0 0",
											}}
											id="delete"
											title="delete"
											className="fa fa-trash-o"
										></i>
									) : (
										<i
											style={{
												color: "#327ebc",
												fontSize: "15px",
												margin: "0 7px 0 0",
											}}
											id="delete"
											title="delete"
											className="fa fa-trash-o"
										></i>
									)}
									Delete selected
								</button>
							)}
						</div>
					</div>
				</div>

				<div className="container-fluid">
					{selectedIDs && this.renderDeleteSelectedModal()}
					{visitId && this.renderInfoModal()}
					{viewIdModal && selectedScanID && this.renderViewIDModal()}
					{this.state.mailModal && this.renderMailModal()}
					{this.state.openAddImage && this.renderaddImageModal()}
					{refuseModal && refuseData && this.renderRefuseModal()}

					<div className="row">
						<div className="col-sm-12 col-xl-12 xl-100">
							<div className="card" style={{ border: '1px solid rgb(223, 232, 241)', borderRadius: '3px' }}>
								<div className="card-body" style={{ padding: "0.9rem" }}>
									<div style={{ margin: "0 0 10px 0" }}>
										<div
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "flex-start",
											}}
										>
											<button
												className={`btn ${applyFilterType != 1 ? "btn-outline-primary" : "btn-primary"} btn-xs`}
												onClick={() => this.applyFilter(1)}
												style={{ marginLeft: 5, marginRight: 5 }}
												type="button"
											>
												Today
											</button>
											<button
												className={`btn ${applyFilterType != 2 ? "btn-outline-primary" : "btn-primary"} btn-xs`}
												onClick={() => this.applyFilter(2)}
												style={{ marginLeft: 5, marginRight: 5 }}
												type="button"
											>
												Yesterday
											</button>
											<button
												className={`btn ${applyFilterType != 3 ? "btn-outline-primary" : "btn-primary"} btn-xs`}
												onClick={() => this.applyFilter(3)}
												type="button"
												style={{ marginLeft: 5, marginRight: 5 }}
											>
												This Week
											</button>
											<button
												className={`btn ${applyFilterType != 4 ? "btn-outline-primary" : "btn-primary"} btn-xs`}
												onClick={() => this.applyFilter(4)}
												type="button"
												style={{ marginLeft: 5, marginRight: 5 }}
											>
												Last Week
											</button>
											<button
												className={`btn ${applyFilterType != 5 ? "btn-outline-primary" : "btn-primary"} btn-xs`}
												onClick={() => this.applyFilter(5)}
												type="button"
												style={{ marginLeft: 5, marginRight: 5 }}
											>
												This Month
											</button>
											<button
												className={`btn ${applyFilterType != 6 ? "btn-outline-primary" : "btn-primary"} btn-xs`}
												onClick={() => this.applyFilter(6)}
												type="button"
												style={{ marginLeft: 5, marginRight: 5 }}
											>
												Last Month
											</button>
											<button
												className={`btn ${applyFilterType != 0 ? "btn-outline-primary" : "btn-primary"} btn-xs`}
												onClick={() => this.applyFilter(0)}
												type="button"
												style={{ marginLeft: 5, marginRight: 5 }}
											>
												All
											</button>

											<Datechoose
												className="col-md-4"
												style={{ width: "20%", marginLeft: 5, marginRight: 5 }}
												onDatePicked={this.dateHandler}
											></Datechoose>
											<div>
												<button
													className={`btn ${applyFilterType != 8 ? "btn-outline-primary" : "btn-primary"} btn-xs`}
													onClick={() => this.applyFilter(8)}
													type="button"
													style={{ marginLeft: 5, marginRight: 5 }}
												>
													Select a range
												</button>
											</div>

											<div
												class="manageReport"
												style={{
													color: "#555",
													float: "right",
													marginLeft: 5,
													marginRight: 5,
												}}
											>
												{!this.state.loadingmail ? (
													<div>
														{/* <i class="fa fa-file-pdf-o" style={{ marginRight: 3, color: "#a5a5a5", cursor: 'pointer' }}></i>
                                                    <a class="weaklink" style={{ marginRight: 10, textDecoration: "none", borderBottomWidth: 1, color: '#1ea6ec', cursor: 'pointer' }} onClick={() => this.createAndDownloadPdf(null, 2)}>PDF</a>
                                                 */}

														<button className="btn btn-outline-primary btn-xs" onClick={this.onExportSubmit}>
															Download Me
														</button>

														<CSVLink
															data={getData}
															headers={headersData}
															filename={"Hospo Visitor.csv"}
															style={{ color: "#327ebc" }}
															className="mx-3 "
														>
															Export Data
														</CSVLink>
													</div>
												) : null}
											</div>
										</div>
										{this.state.applyFilterType == 8 && (
											<div className="mt-2">
												<DatePicker
													className="date-picker"
													selected={startDate}
													onChange={this.onChangeStartDate}
													maxDate={new Date()}
													placeholderText="Select start date"
													dateFormat="dd/MM/yyyy"
												/>
												<DatePicker
													className="date-picker"
													selected={endDate}
													dateFormat="dd/MM/yyyy"
													onChange={this.onChangeEndDate}
													minDate={startDate}
													maxDate={new Date()}
													placeholderText="Select end date"
												/>
											</div>
										)}
									</div>
									<div>
										{!this.state.dataLoading ? (
											this.renderAllTable()
										) : (
											<div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
												<div className="loader" style={{ display: "flex", alignItems: "center" }}>
													<DisappearedLoading size="medium" color="#514F4E" />
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default HosposafeVisitors;
