import React, { Fragment, useState, useEffect } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import LogoAndFevicon from "./LogoAndFevicon";
import BusinessTypeAndProductPage from "./BusinessTypeProductPage";
import CatgoryAndSubCategory from "./CategoryAndSubCategory";
import Breadcrumb from "../../../../components/common/breadcrumb";
import client from "../../../../Api/HTTPClient";
import api from "../../../../constant/apilist";
import { toast } from "react-toastify";
import { mdiChevronLeft } from "@mdi/js";
import Icon from "@mdi/react";
import { Link, useHistory } from "react-router-dom";
import { DisappearedLoading } from "react-loadingg";

const DetailPage = (props) => {

  let getPortalId = props.match.params.brand_id;

  let history = useHistory();
  const [tabIndex, setTabIndex] = useState(0);
  const [showData, setShowData] = useState(null);
  const [getAllData, seGetAllData] = useState(null);
  const [defaultVaue, setDefaultValue] = useState(null);
  const [getSubCatSubData, setGetSubCatSubData] = useState(null);
  const [otherCat, setOtherCat] = useState(null);
  const [loading, setLoading] = useState(false);


  const fetchBusinessTypeData = () => {

    let postdata = { all: true };
    setLoading(true);
    client.post(api.fetchBusinessType, postdata, async function (error, response) {

      if (!error) {
        if (!response.error) {
          seGetAllData(response.result);
          setLoading(false);
        } else {
          toast.error(response.message);
          setLoading(false);
        }
      } else {
        toast.error("Internal error occured. Please contact support", { autoClose: 2000, hideProgressBar: true });
        setLoading(false);
      }
    });
  };

  const update_Data = (data, updateType) => {
    setLoading(true);
    let postData = { updateID: getAllData._id, updateData: data, updateType: updateType, portal_id: showData.brand_ID };
    client.put(api.updateBusinessType, postData, async function (error, response) {

      if (!error) {
        if (!response.error) {
          seGetAllData(response.result);
          setLoading(false);
          toast.success(response.message, { autoClose: 2000, hideProgressBar: true });
        } else {
          toast.error(response.message, { autoClose: 2000, hideProgressBar: true });
        }
      } else {
        setLoading(false);
        toast.error("Internal error occured. Please contact support", { autoClose: 2000, hideProgressBar: true });
      }
    });
  };

  const fetchOtherCatData = () => {
    // setLoading(true);
    let postData = { show_all: true };
    client.post(api.fetch_other_cat, postData, async function (error, response) {
      console.log(response, "response");
      if (!error) {
        if (!response.error) {
          setOtherCat(response.result);
          // setLoading(false);
          // toast.success(response.message, { autoClose: 2000, hideProgressBar: true });
        } else {
          toast.error(response.message, { autoClose: 2000, hideProgressBar: true });
        }
      } else {
        // setLoading(false);
        toast.error("Internal error occured. Please contact support", { autoClose: 2000, hideProgressBar: true });
      }
    });
  };



  useEffect(() => {

    if (!getAllData) {
      fetchBusinessTypeData();
    }

    if (!getSubCatSubData) {
      fetchPortalCatSubCat();
    }

    if (!otherCat) {
      fetchOtherCatData();
    }

  }, []);

  useEffect(() => {

    if (getAllData && getPortalId) {

      let filterData;
      let getData = {};

      let allData = getAllData.page_data;
      filterData = allData.find((currElem) => currElem._id == getPortalId);

      if (filterData) {

        setShowData(filterData);

        filterData.business_types && filterData.business_types.map((item, indx) => {
          getData[`${item._id}_catName`] = item.enable;

          item.subBusinessType.map((showData) => {
            getData[`${showData._id}-subCateName`] = showData.enable;
          })
        })

        getData[`${filterData._id}-pages`] = filterData.web_Pages?.enable;

        filterData.web_Pages && filterData.web_Pages && filterData.web_Pages.pages.map((elements) => {
          getData[`${elements._id}-pageName`] = elements.enable;
        })

        filterData.logo_fevicon && filterData.logo_fevicon.map((elements) => {
          getData[`${elements._id}-meta_title`] = elements.meta_title;
          getData[`${elements._id}-description`] = elements.description;
        })

        setDefaultValue(getData);

      }
    };

  }, [getAllData])


  const fetchPortalCatSubCat = () => {

    setLoading(true);
    client.post(api.fetch_catsubcat, { show_all: true }, async function (error, response) {

      if (!error) {
        if (!response.error) {
          setGetSubCatSubData(response.result.data);
          setLoading(false);
        } else {
          toast.error(response.message);
          setLoading(false);
        }
      } else {
        toast.error("Internal error occured. Please contact support", { autoClose: 2000, hideProgressBar: true });
        setLoading(false);
      }
    });
  };

  const updatePortalCatSubCat = (data) => {

    setLoading(true);
    client.put(api.updatePortalCatSubCat, data, async function (error, response) {

      if (!error) {
        if (!response.error) {
          setGetSubCatSubData(response.result);
          setLoading(false);
          toast.success(response.message, { autoClose: 2000, hideProgressBar: true });
        } else {
          toast.error(response.message);
          setLoading(false);
        }
      } else {
        toast.error("Internal error occured. Please contact support", { autoClose: 2000, hideProgressBar: true });
        setLoading(false);
      }
    });
  };

  const renderLoader = () => {
    return (
      <div className="loader-box" style={{ height: "70vh", width: "100wh" }}>
        <div className="loader" style={{ display: "flex", alignItems: "center" }}>
          <DisappearedLoading size="medium" color="#514F4E" />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-sm-12" style={{ fontSize: "12px" }}>
          <Breadcrumb title={"WHITE LABEL MERCHANTS"} parent={"Home"} brandName={showData?.brand_name} />
        </div>
      </div>
      <div className="container-fluid">
        <div className="row theme-tab">
          <Tabs
            className="col-sm-12"
            defaultIndex={tabIndex}
            style={{ backgroundColor: "white", borderRadius: "3px", marginBottom: "0px" }}
            onSelect={(index) => { setTabIndex(index); }}
          >
            <TabList className="tabs tab-title">

              <Tab style={{ display: "flex", alignItems: "center" }}  >
                Customization
              </Tab>

              {showData && showData.business_types ? <Tab style={{ display: "flex", alignItems: "center" }} >
                Business Types & Product Pages
              </Tab> : null}

              <Tab
                style={{ display: "flex", alignItems: "center" }}
                onClick={() => fetchPortalCatSubCat()}
              >
                Category & Subcategory
              </Tab>

              <div className="ml-auto"
                style={{ display: "flex", alignItems: "center", cursor: "pointer", padding: "10px" }}
                onClick={() => {
                  history.push({
                    pathname: `/merchants/white_label_merchants`,
                    state: { secondTabe: true }
                  });
                }}
              >
                <Icon
                  path={mdiChevronLeft}
                  style={{ fontSize: '13px', width: '19px', height: '19px', marginBottom: '1px' }}
                  color="#3699DB" />
                <p className="mb-0" style={{ color: "#3699DB", fontSize: "15px" }}>Go Back</p>
              </div>

            </TabList>

            <div className="tab-content-cls">
              <TabPanel>
                {
                  loading ? renderLoader() :
                    <LogoAndFevicon
                      showData={showData}
                      getAllData={getAllData}
                      fetchBusinessTypeData={fetchBusinessTypeData}
                      defaultVaue={defaultVaue}
                      update_Data={update_Data}
                    />
                }
              </TabPanel>
            </div>

            {showData && showData.business_types ? <div className="tab-content-cls">
              <TabPanel>
                {
                  loading ? renderLoader() :
                    <BusinessTypeAndProductPage
                      showData={showData}
                      defaultVaue={defaultVaue}
                      loading={loading}
                      fetchBusinessTypeData={fetchBusinessTypeData}
                      update_Data={update_Data}
                    />
                }
              </TabPanel>
            </div> : null}

            <div className="tab-content-cls">
              <TabPanel>
                {
                  loading ? renderLoader() :
                    <CatgoryAndSubCategory
                      showData={showData}
                      getSubCatSubData={getSubCatSubData}
                      updatePortalCatSubCat={updatePortalCatSubCat}
                      otherCategory={otherCat}
                    />
                }
              </TabPanel>
            </div>

          </Tabs>
        </div>
      </div>
    </>
  )
}

export default DetailPage