import React, { useEffect, useState } from "react";
import useForm from 'react-hook-form'
import { withRouter } from "react-router-dom";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";

const Step1 = props => {
  const { register, handleSubmit, errors } = useForm();
  let [loading, setloading] = useState(true);
  let [buttonloading, setbuttonloading] = useState(false);
  
  let [min, setMin] = useState(0);
  let [max, setMax] = useState(0);

  const [access_level, setAccessLevel] = useState(localStorage.getItem("accessLevel"))

  useEffect(() => {

      client.post(api.fetch_country, { country_code : localStorage.getItem("sessionCountryCode") }, async (error, response) => {
        if (!error) {
          try {
            if (!response.error) {
              let r = response.result.data;
              setMin(r.verify_card_charges.min)
              setMax(r.verify_card_charges.max)

            }
          } catch (e) { }
        } else {
          // toast.error("Internal error occured.Please contact support");
        }
        setloading(false)
      });
  
    
  }, [])

  const onSubmit = data => {

    if (data !== '') {

      data.min = min;
      data.max = max;
      data.country_code = localStorage.getItem("sessionCountryCode");

      setbuttonloading(true);
      client.post(api.update_other_charges, data, async function (error, response) {
        if (!error) {
          // alert(JSON.stringify(response));
          //   return false;
          if (typeof response.error !== "undefined") {
            try {
              if (!response.error) {

                toast.success("Updated Successfully");
                // setcashbackdata(response.result.data);
              } else {
                toast.error(response.message);
              }
            } catch (e) {
              toast.error("Internal error occured. Please contact support");
            }
          } else {
            toast.error("Internal error occured. Please contact support");
          }
        } else {
          toast.error("Internal error occured. Please contact support");
        }
        setbuttonloading(false);
      });
    } else {
      errors.showMessages();
    }
  };

  if (loading) {
    return <div className="loader-box" style={{ height: 'auto', display : 'flex', justifyContent : "center", margin : 40 }}><span className="rotate dashed" style={{ height: 42, width: 42 }}></span></div>
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12 col-xl-12 xl-100" style={{ padding: "0" }}>
          <div className="card">
            <div className="card-body" style={{ padding: "1rem" }}>

              <form className="needs-validation billing-form" noValidate="" onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 20 }}>
                <h5 style={{fontSize:"1rem"}}>Card Verification Charges</h5>
                {/* <span>{JSON.stringify(custbillingdata)}</span> */}
                <div className="form-row">

                  <div className="col-sm-3">
                    <div className="form-group">
                      <label className="col-form-label">Min</label>
                      <input className="form-control" step="0.01" min="0" type="Number" onChange={(e) => setMin(e.target.value)} disabled={access_level == 1 ? true : false} name="min" defaultValue={min} ref={register({ required: true })} />
                      <span>{errors.min && 'Minimum Amount is requires'}</span>
                    </div>
                  </div>

                  <div className="col-sm-3">
                    <div className="form-group">
                      <label className="col-form-label">Max</label>
                      <input className="form-control" step="0.01" min="0" type="Number" onChange={(e) => setMax(e.target.value)} disabled={access_level == 1 ? true : false} name="max" defaultValue={max} ref={register({ required: true })} />
                      <span>{errors.max && 'Fee is requires'}</span>
                    </div>
                  </div>

                </div>

                <hr />
                {access_level != 1 && <div className="form-group col-lg-12">
                  {!buttonloading ?
                    <button className="btn btn-primary btn-xs pull-right" type="submit">SAVE</button>
                    :
                    <div className="loader-box pull-right" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 42, width: 42 }}></span></div>
                  }
                </div>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Step1);