import React, { useEffect, useState, useRef } from "react";
import { Button, Card, CardBody, CardTitle, Col, Row, Modal, ModalBody, Tooltip, NavLink, CardSubtitle, CardText, Collapse } from "reactstrap";
import "./QRCodePage.scss"; 
import { useDispatch, useSelector } from "react-redux";
import { Field, reduxForm, initialize, FieldArray } from "redux-form";
import AlertCircleIcon from "mdi-react/AlertCircleIcon";
import CalendarBlankIcon from "mdi-react/CalendarBlankIcon";
import client from "../../../Api/HTTPClient.js"
import renderTimePicker from "./TimePicker";
import { changePageNumber } from "../../../store/actions/phoneViewPageActions";
import { IoIosArrowBack } from 'react-icons/io';
import { MdOutlineClose } from 'react-icons/md';
import { TfiWorld } from 'react-icons/tfi';
import { FiLink2 } from 'react-icons/fi';
import { toLower } from 'lodash';
import Icon from '@mdi/react'
import {
  mdiArrowLeft,
  mdiInformation,
} from '@mdi/js'
import ReactStars from "react-stars";
import "./PhoneViewPages.scss"
import { Popover, PopoverBody } from "reactstrap";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
// import 'react-multi-carousel/lib/styles.css';
import Select from 'react-select';
import { imageSelector } from './DefaultImageList';
import QRCode from "qrcode.react";
import { getBizLinkDomain, getPortalId } from "../../../helpers.js";
import { SocialIcon } from 'react-social-icons';
import { BsGlobe, BsLine, BsClock } from 'react-icons/bs'
// import { useTranslation } from "react-i18next";
import './CarouselStyles.css'
import { isMobile } from "react-device-detect";
import { array_of_social_handles_phone as array_of_social_handles, defaultDataForSocialMedia } from "./socialMediaData"
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import LanguageIcon from '@material-ui/icons/Language';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { ReactComponent as LinkyLogoSVG } from '../../../assets/icons/logo-svg/linky-logo.svg'
import { ReactComponent as IPayProSVG } from '../../../assets/icons/logo-svg/ipaypro-logo.svg'
import { ReactComponent as HustlerzLogoSVG } from '../../../assets/icons/logo-svg/hustlerz-logo.svg'
// import QRDisplayComp from "./QRDisplayComp.jsx"; 
import { FaEyeSlash } from "react-icons/fa";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel as CarouselR } from 'react-responsive-carousel';
import { FiArrowUpRight } from "react-icons/fi";

const Linkedin = "/assets/images/linky-biz-images/social-media/Linkedin.svg";
const defaultImageHeaderType3 = "/assets/images/default-profile.png";

const zoomout = '/assets/icons/Zoomouticon.svg'
const zoomin = '/assets/icons/Zoominicon.svg'

const GiftImg = "/assets/images/linky-biz-images/GiftGIF.gif";

let fontSizeButtonPrimary = "14px";
let fontSizeButtonSecondary = "12px";


const PreviewButton = "/assets/images/view_minisite_icon.svg";
const transparentBackgrount = "/assets/images/transparent-backgrount.png";


const defaultSaffIage = "/assets/icons/staff_pic_default.jpg";
const defaultAvtarImage = "/assets/icons/staff_pic_default.jpg"


const sexoptions = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
  { value: 'Other', label: 'Other' },
];

const capitalizePlaceholder = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

const renderField = ({
  input,
  placeholder,
  min,
  type,
  style,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    {placeholder === "Mobile Number" ?
      <div className=" d-flex container" style={style}>
        <label className="py-2  m-0" style={{ fontFamily: "Montserrat, sans-serif", fontSize: "0.9rem", color: "#A0A0A0", lineHeight: "25px" }}>+61</label>
        <input
          {...input}
          placeholder=""
          min={min}
          type={type}
          style={style}
        />
      </div>
      :
      <input
        {...input}
        placeholder={capitalizePlaceholder(placeholder)}
        min={min}
        type={type}
        style={style}
      />}

    {touched && error && (
      <div className="form__form-group-error d-flex align-items-center">
        <span
          style={{ padding: "2px 5px", backgroundColor: "#ffdce0" }}
          className="rounded mb-2"
        >
          <AlertCircleIcon className="mr-1" size={14} />
          {error}
        </span>
      </div>
    )}
  </div>
);

const renderFormFields = ({ fields, meta: { error, submitFailed }, qrFrontSideData, selectedOption, setSelectedOption }) => (
  <div>
    {fields.map((value, index) => (
      <>
        {qrFrontSideData.visitor_fields[index]?.endb == true ?
          <span key={index}>
            {qrFrontSideData.visitor_fields[index]?.labelType.toLowerCase() === "text" ? (
              <div className="form__form-group my-3">
                <div className="form__form-group-field">
                  <Field
                    name={`${value}.value`}
                    component={renderField}
                    type="text"
                    placeholder={capitalizePlaceholder(qrFrontSideData.visitor_fields[index]?.labelName)}
                    style={{ backgroundColor: "#F4F4F4", border: "none", borderRadius: "10px" }}
                  />
                </div>
              </div>
            ) : qrFrontSideData.visitor_fields[index]?.labelType.toLowerCase() === "number" ? (
              <div className="form__form-group my-3">
                <div className="form__form-group-field">
                  <Field
                    name={`${value}.value`}
                    component={renderField}
                    type="number"
                    placeholder={capitalizePlaceholder(qrFrontSideData.visitor_fields[index]?.labelName)}
                    style={{ backgroundColor: "#F4F4F4", border: "none", borderRadius: "10px" }}
                  />
                </div>
              </div>
            ) : qrFrontSideData.visitor_fields[index]?.labelType.toLowerCase() === "email" ? (
              <div className="form__form-group my-3">
                <div className="form__form-group-field">
                  <Field
                    name={`${value}.value`}
                    component={renderField}
                    type="email"
                    placeholder={capitalizePlaceholder(qrFrontSideData.visitor_fields[index]?.labelName)}
                    style={{ backgroundColor: "#F4F4F4", border: "none", borderRadius: "10px" }}
                  />
                </div>
              </div>
            ) : qrFrontSideData.visitor_fields[index]?.labelType.toLowerCase() === "dropdown" ? (
              <div className="form__form-group my-3">
                <div className="form__form-group-field">
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        width: '100%',
                        fontSize: 12,
                        borderRadius: "10px",
                        outline: 'none',
                        backgroundColor: "#F4F4F4",
                        border: "none !important"
                      }),
                      indicatorSeparator: base => ({
                        ...base,
                        display: 'none'
                      }),
                      dropdownIndicator: base => ({
                        ...base,
                        color: "#000000", // Custom colour.
                        height: "35px",
                        width: "40px",
                      })
                    }}
                    isSearchable={false}
                    className="sex-select-container"
                    classNamePrefix="sex-select"
                    name={`${value}.value`}
                    placeholder={`Select ${qrFrontSideData.visitor_fields[index]?.labelName}`}
                    defaultValue={selectedOption}
                    onChange={(e) => setSelectedOption(e)}
                    options={sexoptions}
                  />
                </div>
              </div>
            ) : qrFrontSideData.visitor_fields[index]?.labelType.toLowerCase() === "datepicker" ? (
              <div className="form__form-group my-3">
                <div className="form__form-group-field">
                  <Field name={`${value}.value`}
                    component={renderField}
                    placeholder={capitalizePlaceholder(qrFrontSideData.visitor_fields[index]?.labelName)}
                    style={{ backgroundColor: "#F4F4F4", border: "none", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }}
                  />
                  <div className="d-flex align-items-center" style={{ backgroundColor: "#F4F4F4", borderTopRightRadius: "10px", borderBottomRightRadius: "10px" }}>
                    <CalendarBlankIcon className="mr-2" color="#000000" size={20} />
                  </div>
                </div>
              </div>
            ) : qrFrontSideData.visitor_fields[index]?.labelType.toLowerCase() === "date" ? (
              <div className="form__form-group my-3">
                <div className="form__form-group-field">
                  <Field name={`${value}.value`}
                    component={renderField}
                    placeholder={capitalizePlaceholder(qrFrontSideData.visitor_fields[index]?.labelName)}
                    style={{ backgroundColor: "#F4F4F4", border: "none", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }}
                  />
                  <div className="d-flex align-items-center" style={{ backgroundColor: "#F4F4F4", borderTopRightRadius: "10px", borderBottomRightRadius: "10px" }}>
                    <CalendarBlankIcon className="mr-2" color="#000000" size={20} />
                  </div>
                </div>
              </div>
            ) : qrFrontSideData.visitor_fields[index]?.labelType.toLowerCase() === "time" ? (
              <div className="form__form-group my-3">
                <div className="form__form-group-field">
                  <Field name={`${value}.value`} component={renderTimePicker} />
                </div>
              </div>
            ) : qrFrontSideData.visitor_fields[index]?.labelType.toLowerCase() === "textarea" ? (
              <div className="form__form-group my-3">
                <div className="form__form-group-field">
                  <Field
                    name={`${value}.value`}
                    component="textarea"
                    placeholder={capitalizePlaceholder(qrFrontSideData.visitor_fields[index]?.labelName)}
                    style={{ backgroundColor: "#F4F4F4", border: "none", borderRadius: "10px" }}
                  />
                </div>
              </div>
            ) : null}

          </span>
          :
          null
        }

      </>
    ))}

  </div>
);

const PreviewNotAvailable = ({ body, footer, qrFrontSideData }) => {
  return (
    <span
      // key={index}
      className="spn col-12"
      style={{ textIndent: "0", gridColumn: 'span 12' }}
    >
      <div
        // id={qrBtnVal.id}
        className={`d-flex flex-column justify-content-center overflow-hidden`}
        style={{
          // padding: '11px 11px 8px 11px',
          margin: "8px 0",
          borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "30px" : "10px",
          // background: qrFrontSideData?.biz_link_button_bg_color ? rgbaHexCalculate(qrFrontSideData?.biz_link_button_bg_color) : rgbaHexCalculate('#FFFFFF'),
          boxSizing: "border-box",
          position: "relative",
          zIndex: 7,
          height: "max-content",
          opacity: 1,
          cursor: "pointer",
          // border: qrFrontSideData?.biz_link_button_border && `2px solid ${qrFrontSideData?.biz_link_button_border_color}`,
          boxShadow: qrFrontSideData?.biz_link_button_shadow ? qrFrontSideData?.biz_link_button_shadow_color ? `${qrFrontSideData.biz_link_button_shadow_color} 4px 4px 0px 0px` : "#ffffff 4px 4px 0px 0px" : 'none',
          lineHeight: 1.5,
          textAlign: 'left',
        }}
      >
        <div style={{ backgroundColor: "rgb(231, 231, 231)" }} className="px-2 pt-2 d-flex flex-column align-items-center justify-content-center">
          <div className="p-2 bg-white" style={{ color: "black", borderRadius: "5px" }}>{body}</div>
          <div style={{ color: "black", fontWeight: 700 }} className="py-2">{footer}</div>
        </div>
      </div>
    </span>
  )
}

const PhoneViewPage = (props) => {

  const {
    qrFrontSideData,
    merchantData,
    combineAllBUttons,
    qrBtn
  } = props

  // const { t, i18n } = useTranslation('my_store');
  // let getDir = i18n.dir();

  // const merchantData = useSelector((state) => state.auth.registeredUser);
  // const qrFrontSideData = useSelector((state) => state.form.QRCodePageForm?.values);
  // const merchFeatData = useSelector((state) => state.merchantFeatureData.merchantFeaturesData);
  const hostName = typeof window !== 'undefined' && window.location.host ? window.location.host : '';


  // const seatMapTableData = useSelector((state) => state.seatMapTablesReducer.tables);
  const currentPage = useSelector((state) => state.phoneViewPageReducer.currentPage);
  // const qrFrontSideData = useSelector((state) => state.qrFrontSideReducer.qrFrontSideData)
  const merchantProfileImage = `${merchantData?.profile_image}`;
  const business_card_cover_image = `${client.url(`images/business-card/${merchantData?.business_card_cover_image}`)}`;
  const business_card_company_image = `${merchantData?.business_card_company_image?.includes("dropbox") ? merchantData?.business_card_company_image : client.url(`images/business-card/${merchantData?.business_card_company_image}`)}`;
  const business_card_profile_image = `${merchantData?.business_card_profile_image?.includes("dropbox") ? merchantData?.business_card_profile_image : client.url(`images/business-card/${merchantData?.business_card_profile_image}`)}`;
  const [selectTableModal, setSelectTableModal] = useState(false);
  const [selectedTable, setSelectedTable] = useState("SELECT TABLE");
  const [themeColor, setthemeColor] = useState("#343A40")
  const [starRating, setstarRating] = useState(5)
  const [amount, setamount] = useState()
  const [roll, setroll] = useState()
  const [showinfopopover, setshowinfopopover] = useState(false);
  const dispatch = useDispatch();
  const [chechStaff, setCheckStaff] = useState(false);
  const [staffDetails, setStaffDetails] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [checkboxState, setCheckboxState] = useState(3)
  const [aboutUsData, setAboutUsData] = useState(null)
  const [showLess, setShowLess] = useState(true);
  const [zoom, setZoom] = useState(false);
  const [timeStamp, setTimeStamp] = useState(0);

  useEffect(() => {
    setTimeStamp(Date.now());
  }, []);

  const selectTableModalToggle = () => { setSelectTableModal(!selectTableModal); };

  useEffect(() => {

    let refrssh = Math.floor(Math.random() * 9)
    initialize(
      {
        vsOrder: { indexNm: qrBtn.findIndex((e) => e.id == "visitor"), indexId: "visitor" },
        tblOrder: { indexNm: qrBtn.findIndex((e) => e.id == "table"), indexId: "table" },
        orOrder: { indexNm: qrBtn.findIndex((e) => e.id == "order"), indexId: "order" },
        ofrOrder: { indexNm: qrBtn.findIndex((e) => e.id == "offer"), indexId: "offer" },
        pymOrder: { indexNm: qrBtn.findIndex((e) => e.id == "payment"), indexId: "payment" },
        tipOrder: { indexNm: qrBtn.findIndex((e) => e.id == "tip"), indexId: "tip" },
        wtrOrder: { indexNm: qrBtn.findIndex((e) => e.id == "waiter"), indexId: "waiter" },
        clnOrder: { indexNm: qrBtn.findIndex((e) => e.id == "clean"), indexId: "clean" },
        otrOrder: { indexNm: qrBtn.findIndex((e) => e.id == "other"), indexId: "other" },
        sellOrder: { indexNm: qrBtn.findIndex((e) => e.id == "sell"), indexId: "sell" },
        donationOrder: { indexNm: qrBtn.findIndex((e) => e.id == "donation"), indexId: "donation" },
        indexlen: qrBtn.length,
        visitor_form_fields: qrFrontSideData?.visitor_fields,
        enable_redirect: qrFrontSideData?.enable_redirect,
      });

    try {

      if (qrFrontSideData?.tip_structures) {
        let ind = qrFrontSideData?.tip_structures?.findIndex(v => v.isSelected === true)
        setamount(qrFrontSideData?.tip_structures[ind]?.amount[0])
      }

      if (qrFrontSideData?.other_fields) {
        setroll(refrssh)
      }

    } catch { }


  }, [qrFrontSideData, qrBtn, qrFrontSideData]);


  const [enableVisitorField, setEnableVisitorField] = useState([]);

  useEffect(() => {


    if (qrFrontSideData) {

      let allStaffDetails = qrFrontSideData.tips_staff_details;

      if (allStaffDetails?.length > 0) {

        let findIndex = allStaffDetails.findIndex((items) => items.staff_image != null);
        let getValue = false;

        if (findIndex == -1) {
          getValue = true;
        } else {
          getValue = false;
        }
        setCheckStaff(getValue)
      }

      let dataToShow = qrFrontSideData.enable_store_about_details
      if (dataToShow) {
        let words = '<div class="snapshot"><label label ></label> ' + dataToShow.replace(/\n/g, '</div> <div class="snapshot"><label label ></label>') + "</div>"
        setAboutUsData(words)
      } else {
        setAboutUsData(null)
      }

      if (qrFrontSideData.visitor_form_fields && qrFrontSideData.visitor_form_fields.length > 0) {

        let getVisitorField = qrFrontSideData.visitor_form_fields.filter((items) => items.endb == true);
        setEnableVisitorField(getVisitorField);

      }

    }
  }, [qrFrontSideData])

  const presetRender = () => {
    if (qrFrontSideData?.store_social_preset === 1) {
      return (qrFrontSideData?.store_social_media_link.map((val, index) => {
        return (
          val.social_handles_name === 'Website' ?
            <a href={val.social_handles_link}><BsGlobe style={{ color: "#3E9DE8", background: '#B3D3EF', border: "1px solid #B3D3EF", padding: "5px", width: '30px', height: '30px', borderRadius: "15px", marginRight: '2px', marginLeft: '2px' }} /></a>
            : val.social_handles_name === 'Line' ?
              <a href={val.social_handles_link}><BsLine style={{ color: "white", background: '#069231', border: "1px solid #069231", padding: "5px", width: '30px', height: '30px', borderRadius: "15px", marginRight: '2px', marginLeft: '2px' }} /></a>
              : <SocialIcon url={val.social_handles_link} fgColor="white" network={val.social_handles_name.toLowerCase()} style={{ marginRight: '2px', marginLeft: '2px', width: '30px', height: '30px' }} />
        )
      }))
    } else if (qrFrontSideData?.store_social_preset === 2) {
      return (
        qrFrontSideData?.store_social_media_link.map((val, index) => {
          return (
            val.social_handles_name === 'Website' ?
              <a href={val.social_handles_link}><BsGlobe style={{ color: "white", background: '#dddddd', border: "2px solid #dddddd", padding: "5px", width: '30px', height: '30px', borderRadius: "15px", marginRight: '2px', marginLeft: '2px' }} /></a>
              : val.social_handles_name === 'Line' ?
                <a href={val.social_handles_link}><BsLine style={{ color: "white", background: '#dddddd', border: "2px solid #dddddd", padding: "5px", width: '30px', height: '30px', borderRadius: "15px", marginRight: '2px', marginLeft: '2px' }} /></a>
                : <SocialIcon url={val.social_handles_link} fgColor="white" bgColor='#dddddd' network={val.social_handles_name.toLowerCase()} style={{ marginRight: '2px', marginLeft: '2px', width: '30px', height: '30px', border: '2px solid white', borderRadius: '15px' }} />
          )
        }))
    } else if (qrFrontSideData?.store_social_preset === 3) {
      return (
        qrFrontSideData?.store_social_media_link.map((val, index) => {
          return (
            val.social_handles_name === 'Website' ?
              <a href={val.social_handles_link}><BsGlobe style={{ color: "black", background: '#dddddd', border: "2px solid black", padding: "5px", width: '30px', height: '30px', borderRadius: "15px", marginRight: '2px', marginLeft: '2px' }} /></a>
              : val.social_handles_name === 'Line' ?
                <a href={val.social_handles_link}><BsLine style={{ color: "black", background: '#dddddd', border: "2px solid black", padding: "5px", width: '30px', height: '30px', borderRadius: "15px", marginRight: '2px', marginLeft: '2px' }} /></a>
                : <SocialIcon url={val.social_handles_link} fgColor="black" bgColor='#dddddd' network={val.social_handles_name.toLowerCase()} style={{ marginRight: '2px', marginLeft: '2px', width: '30px', height: '30px', border: '2px solid black', borderRadius: '15px' }} />
          )
        })
      )
    } else if (qrFrontSideData?.store_social_preset === 4) {
      return (
        qrFrontSideData?.store_social_media_link.map((val, index) => {
          return (
            val.social_handles_name === 'Website' ?
              <a href={val.social_handles_link}><BsGlobe style={{ color: "white", background: '#bcc3c8', border: "1px solid #bcc3c8", padding: "5px", width: '30px', height: '30px', borderRadius: "15px", marginRight: '2px', marginLeft: '2px' }} /></a>
              : val.social_handles_name === 'Line' ?
                <a href={val.social_handles_link}><BsLine style={{ color: "white", background: '#bcc3c8', border: "1px solid #bcc3c8", padding: "5px", width: '30px', height: '30px', borderRadius: "15px", marginRight: '2px', marginLeft: '2px' }} /></a>
                : <SocialIcon url={val.social_handles_link} fgColor="white" bgColor='#bcc3c8' network={val.social_handles_name.toLowerCase()} style={{ marginRight: '2px', marginLeft: '2px', width: '30px', height: '30px' }} />
          )
        })
      )
    } else if (qrFrontSideData?.store_social_preset === 5) {
      return (
        qrFrontSideData?.store_social_media_link.map((val, index) => {
          return (
            val.social_handles_name === 'Website' ?
              <a href={val.social_handles_link}><BsGlobe style={{ color: "#3E9DE8", background: 'white', border: "1px solid white", padding: "5px", width: '30px', height: '30px', borderRadius: "15px", marginRight: '2px', marginLeft: '2px' }} /></a>
              : val.social_handles_name === 'Line' ?
                <a href={val.social_handles_link}><BsLine style={{ color: "#069231", background: 'white', border: "1px solid white", padding: "5px", width: '30px', height: '30px', borderRadius: "15px", marginRight: '2px', marginLeft: '2px' }} /></a>
                : <SocialIcon url={val.social_handles_link} fgColor={val.social_handles_name === 'Pinterest' ? '#CB2128' : val.social_handles_name === 'Facebook' ? '#3B5998' : val.social_handles_name === 'Instagram' ? '#E94475' : val.social_handles_name === 'Twitter' ? '#00ACED' : val.social_handles_name === 'Linkedin' ? '#007FB1' : val.social_handles_name === 'Youtube' ? '#FF3333' : 'black'} bgColor='white' network={val.social_handles_name.toLowerCase()} style={{ marginRight: '2px', marginLeft: '2px', width: '30px', height: '30px' }} />
          )
        })
      )
    }
  }
  const presetStoreRender = () => {
    if (qrFrontSideData?.store_info_social_preset === 1) {
      return (qrFrontSideData?.store_social_media_link.map((val, index) => {
        return (
          val.social_handles_name === 'Website' ?
            <a key={index} href={val.social_handles_link} target='_blank'><BsGlobe style={{ color: "#3E9DE8", background: '#B3D3EF', border: "1px solid #B3D3EF", padding: "5px", width: '28px', height: '28px', borderRadius: "15px", margin: '3px' }} /></a>
            : val.social_handles_name === 'Line' ?
              <a key={index} href={val.social_handles_link} target='_blank'><BsLine style={{ color: "white", background: '#069231', border: "1px solid #069231", padding: "5px", width: '28px', height: '28px', borderRadius: "15px", margin: '3px' }} /></a>
              : <SocialIcon key={index} url={val.social_handles_link} target='_blank' fgColor="white" network={val.social_handles_name.toLowerCase()} style={{ margin: '3px', width: '28px', height: '28px' }} />
        )
      }))
    } else if (qrFrontSideData?.store_info_social_preset === 2) {
      return (
        qrFrontSideData?.store_social_media_link.map((val, index) => {
          return (
            val.social_handles_name === 'Website' ?
              <a key={index} href={val.social_handles_link} target='_blank'><BsGlobe style={{ color: "white", background: '#dddddd', border: "2px solid #dddddd", padding: "5px", width: '28px', height: '28px', borderRadius: "15px", margin: '3px' }} /></a>
              : val.social_handles_name === 'Line' ?
                <a key={index} href={val.social_handles_link} target='_blank'><BsLine style={{ color: "white", background: '#dddddd', border: "2px solid #dddddd", padding: "5px", width: '28px', height: '28px', borderRadius: "15px", margin: '3px' }} /></a>
                : <SocialIcon key={index} url={val.social_handles_link} target='_blank' fgColor="white" bgColor='#dddddd' network={val.social_handles_name.toLowerCase()} style={{ margin: '3px', width: '28px', height: '28px', border: '2px solid white', borderRadius: '15px' }} />
          )
        }))
    } else if (qrFrontSideData?.store_info_social_preset === 3) {
      return (
        qrFrontSideData?.store_social_media_link.map((val, index) => {
          return (
            val.social_handles_name === 'Website' ?
              <a key={index} href={val.social_handles_link} target='_blank'><BsGlobe style={{ color: "black", background: '#dddddd', border: "2px solid black", padding: "5px", width: '28px', height: '28px', borderRadius: "15px", margin: '3px' }} /></a>
              : val.social_handles_name === 'Line' ?
                <a key={index} href={val.social_handles_link} target='_blank'><BsLine style={{ color: "black", background: '#dddddd', border: "2px solid black", padding: "5px", width: '28px', height: '28px', borderRadius: "15px", margin: '3px' }} /></a>
                : <SocialIcon key={index} url={val.social_handles_link} target='_blank' fgColor="black" bgColor='#dddddd' network={val.social_handles_name.toLowerCase()} style={{ margin: '3px', width: '28px', height: '28px', border: '2px solid black', borderRadius: '15px' }} />
          )
        })
      )
    } else if (qrFrontSideData?.store_info_social_preset === 4) {
      return (
        qrFrontSideData?.store_social_media_link.map((val, index) => {
          return (
            val.social_handles_name === 'Website' ?
              <a key={index} href={val.social_handles_link} target='_blank'><BsGlobe style={{ color: "white", background: '#bcc3c8', border: "1px solid #bcc3c8", padding: "5px", width: '28px', height: '28px', borderRadius: "15px", margin: '3px' }} /></a>
              : val.social_handles_name === 'Line' ?
                <a key={index} href={val.social_handles_link} target='_blank'><BsLine style={{ color: "white", background: '#bcc3c8', border: "1px solid #bcc3c8", padding: "5px", width: '28px', height: '28px', borderRadius: "15px", margin: '3px' }} /></a>
                : <SocialIcon key={index} url={val.social_handles_link} target='_blank' fgColor="white" bgColor='#bcc3c8' network={val.social_handles_name.toLowerCase()} style={{ margin: '3px', width: '28px', height: '28px' }} />
          )
        })
      )
    } else if (qrFrontSideData?.store_info_social_preset === 5) {
      return (
        qrFrontSideData?.store_social_media_link.map((val, index) => {
          return (
            val.social_handles_name === 'Website' ?
              <a key={index} href={val.social_handles_link} target='_blank'><BsGlobe style={{ color: "#3E9DE8", background: 'white', border: "1px solid white", padding: "5px", width: '28px', height: '28px', borderRadius: "15px", margin: '3px' }} /></a>
              : val.social_handles_name === 'Line' ?
                <a key={index} href={val.social_handles_link} target='_blank'><BsLine style={{ color: "#069231", background: 'white', border: "1px solid white", padding: "5px", width: '28px', height: '28px', borderRadius: "15px", margin: '3px' }} /></a>
                : <SocialIcon key={index} url={val.social_handles_link} target='_blank' fgColor={val.social_handles_name === 'Pinterest' ? '#CB2128' : val.social_handles_name === 'Facebook' ? '#3B5998' : val.social_handles_name === 'Instagram' ? '#E94475' : val.social_handles_name === 'Twitter' ? '#00ACED' : val.social_handles_name === 'Linkedin' ? '#007FB1' : val.social_handles_name === 'Youtube' ? '#FF3333' : 'black'} bgColor='white' network={val.social_handles_name.toLowerCase()} style={{ margin: '3px', width: '28px', height: '28px' }} />
          )
        })
      )
    }
  }

  const get_form_fields = () => {
    let row = [];
    try {
      let form_fields = {
        "Name": "John Doe",
        "mobile_number": "123456789",
        "Email": "john@doe.com"
      };
      let mobile_prefix = "+1";
      delete form_fields.countries
      delete form_fields.country_code
      delete form_fields.redirectFromQR
      delete form_fields.show_ol_button
      delete form_fields.logTime
      let keys = []
      for (var k in form_fields) keys.push(k);
      if (form_fields) {
        row.push(keys.map(function (key, index) {
          let str = form_fields[key]
          const arr = str.split(" ");
          for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0) + arr[i].slice(1);
          }
          let str2 = arr.join(" ");
          if (str2 && key == "Name") {
            str2 = str2.split(" ")[0];
          }
          return <div style={{ background: "#F4F4F4", display: "flex", borderTopLeftRadius: index === 0 && "10px", borderTopRightRadius: index === 0 && "10px", borderBottomLeftRadius: (index == keys.length - 1) && "10px", borderBottomRightRadius: (index == keys.length - 1) && "10px" }} className={`border-2 w-100`}>
            <div className="col-6 border-r-2" style={{ padding: "0.5rem", textTransform: 'capitalize', width: "50%", fontWeight: "350", paddingLeft: "0.5rem" }}><b>{key.replaceAll("_", ' ')}</b></div>
            <div className="col-6" style={{ padding: "0.5rem", whiteSpace: "nowrap", width: "50%", overflow: "hidden", textOverflow: "ellipsis", paddingLeft: "0.5rem", textTransform: toLower(key) == "email" ? 'lowercase' : toLower(key) == "name" ? 'capitalize' : "none" }}>
              {toLower(key) == "mobile_number" ? `(${mobile_prefix}) ` + form_fields[key].charAt(0).toUpperCase() + form_fields[key].slice(1) :
                //form_fields[key].charAt(0).toUpperCase() + form_fields[key].slice(1)
                str2
              }
            </div>
          </div>;
        }))
      }
    } catch (err) {
      console.log(err, "fields table error")
    }
    return row
  }

  function useHover() {
    const [hovering, setHovering] = useState(false)
    const onHoverProps = {
      onMouseEnter: () => setHovering(true),
      onMouseLeave: () => setHovering(false),
    }
    return [hovering, onHoverProps]
  }

  const [viewsHovering, viewsHoverProps] = useHover()

  const viewsCalculator = (views) => {
    if (views <= 1) {
      return views
    } else if (views < 1000) {
      return views
    } else if (views <= 999999) {
      let s = views / 1000
      return Number(s.toFixed(1)) + "k"
    } else if (views >= 1000000) {
      let s = views / 1000000
      return Number(s.toFixed(1)) + "M"
    }
  }

  const checkGoogleAddressIncludes = (data) => {
    if (qrFrontSideData?.business_card_address?.toLowerCase()?.includes(data?.toLowerCase())) {
      return '';
    } else {
      return data + ", ";
    }
  }


  useEffect(() => {
    let termState = qrFrontSideData?.visitor_terms?.active && qrFrontSideData?.visitor_terms?.term?.length > 0
    let privacyState = qrFrontSideData?.visitor_privacy?.active && qrFrontSideData?.visitor_privacy?.privacy?.length > 0

    if (termState && privacyState) {
      setCheckboxState(0)
    }
    else if (termState && !privacyState) {
      setCheckboxState(1)
    }
    else if (!termState && privacyState) {
      setCheckboxState(2)
    }
    else {
      setCheckboxState(3)
    }
  }, [qrFrontSideData])

  const getYouTubeVideoID = (myString) => {
    var myRegexp = new RegExp("(?:[?&]v=|\/embed\/|\/1\/|\/v\/|https:\/\/(?:www\.)?youtu\.be\/)([^&\n?#]+)", "g");
    var matches = myRegexp.exec(myString);
    if (matches && matches.length) {
      return matches[1];
    } else {
      return "";
    }
  }

  const showSaveContact = () => {
    const getDataOfSocialFields = qrFrontSideData?.business_card_social_handles?.map((social) => {
      let iconURL = array_of_social_handles.find((val => val.name === social.name))
      return `URL;TYPE=${iconURL.name}:${iconURL?.redirectURL ? iconURL.redirectURL + social?.handle : social?.handle}`
    }).join('\n')
    const emails = qrFrontSideData?.business_card_email?.flatMap((mail) => {
      if (mail?.emailID) {
        return [`EMAIL:${mail.emailID}`]
      } else {
        return []
      }
    }).join('\n')
    const phoneNumbers = qrFrontSideData?.business_card_phone?.flatMap((phone) => {
      if (phone?.phoneNo) {
        return [`TEL;TYPE=cell:${phone.phoneNo}`]
      } else {
        return []
      }
    }).join('\n')
    const websites = qrFrontSideData?.business_card_website?.flatMap((site) => {
      if (site?.URL) {
        return [`URL;type=WORK:${site.URL}`]
      } else {
        return []
      }
    }).join('\n')
    const address = `${qrFrontSideData?.business_card_address ? qrFrontSideData.business_card_address + ", " : ''}`.replace(/,\s*$/, "")
    if (getDataOfSocialFields || emails || phoneNumbers || websites || address) {
      return true
    } else {
      return false
    }
  }

  const getSpotifyURL = (stringURL) => {
    let spotifyURL = "";
    try {
      if (stringURL && stringURL.includes("spotify.com/")) {
        spotifyURL = stringURL.split("spotify.com/")[1]
      }
    } catch (e) {
      console.log(e, "Error")
    }
    return spotifyURL;
  }

  const getAppleMusicURL = (stringURL) => {
    let appleMusicURL = "";
    try {
      if (stringURL && stringURL.includes("music.apple.com")) {
        appleMusicURL = stringURL.split("music.apple.com/")[1]
      }
    } catch (e) {
      console.log(e, "Error")
    }
    return appleMusicURL;
  }

  const renderLogo = (data) => {

    if (hostName == "dm.portal.ipaypro.co" || hostName == "portal.ipaypro.co") {
      return client.url(`static-images/portal-logos/${data == '#ffffff' ? 'ipaypro_black_logo' : 'ipaypro_white_logo'}.png`);

    } else if (hostName == "dm.portal.linky.biz" || hostName == "portal.linky.biz") {
      return client.url(`static-images/portal-logos/${data == '#ffffff' ? 'linky_black_logo' : 'linky_white_logo'}.png`);

    } else if (hostName == "dm.portal.hustlerz.co" || hostName == "portal.hustlerz.co") {
      return client.url(`static-images/portal-logos/${data == '#ffffff' ? 'hustlerz_black_logo' : 'hustlerz_white_logo'}.png`);

    } else {
      return client.url(`static-images/portal-logos/${data == '#ffffff' ? 'linky_black_logo' : 'linky_white_logo'}.png`);
    }

  }

  const redirectFunc = () => {
    if (window) {
      window.location.href = `${getPortalId() == 1 ? 'https://portal.ipaypro.co/' : getPortalId() == 2 ? 'https://portal.linky.biz/' : getPortalId() == 3 ? 'https://portal.hustlerz.co/' : 'https://portal.ipaypro.co/'}`
    }
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const rgbaHexCalculate = (color) => {
    if (qrFrontSideData?.biz_link_button_transparency === "75") {
      return `${color}bb`;
    } else if (qrFrontSideData?.biz_link_button_transparency === "100") {
      return color;
    } else {
      return `${color}44`;
    }
  }

  const renderShifts = (shift) => {
    return shift.map(e => {
      return <div className="text-right">{e.start} - {e.end}</div>
    })
  }

  const getVisitorButtons = (qrFrontSideData) => {
    let btns = [];
    btns = [
      ...(qrFrontSideData?.minisite_order_online?.active === true && qrFrontSideData?.order_online == true ? [qrFrontSideData.minisite_order_online] : []),
      ...(qrFrontSideData?.minisite_accept_payments?.active === true && qrFrontSideData?.accept_payments == true ? [qrFrontSideData.minisite_accept_payments] : []),
      ...(qrFrontSideData?.minisite_visitor_reg?.hasOwnProperty("inner_buttons") && qrFrontSideData?.minisite_visitor_reg?.inner_buttons?.length >= 1 ? [...qrFrontSideData?.minisite_visitor_reg?.inner_buttons] : []),
    ]
    return btns;
  }

  const getRearrangedMinisiteButtons = (qrFrontSideData) => {
    let btns = [...(qrFrontSideData.biz_link_button),
    ...(qrFrontSideData.minisite_music_link),
    ...(qrFrontSideData.minisite_youtube_link),
    ...(qrFrontSideData.minisite_portfolio),
    ...(qrFrontSideData?.minisite_portfolio_video ? qrFrontSideData.minisite_portfolio_video : []),
    ...(qrFrontSideData?.minisite_accept_payments?.active === true ? [qrFrontSideData.minisite_accept_payments] : []),
    ...(qrFrontSideData?.minisite_review_booster?.active === true ? [qrFrontSideData.minisite_review_booster] : []),
    ...(qrFrontSideData?.minisite_business_card?.active === true ? [qrFrontSideData.minisite_business_card] : []),
    ...(qrFrontSideData?.minisite_tips?.active === true ? [qrFrontSideData.minisite_tips] : []),
    ...(qrFrontSideData?.minisite_booking?.active === true ? [qrFrontSideData.minisite_booking] : []),
    ...(qrFrontSideData?.minisite_meet?.active === true ? [qrFrontSideData.minisite_meet] : []),
    ...(qrFrontSideData?.minisite_order_online?.active === true ? [qrFrontSideData.minisite_order_online] : []),
    ...(qrFrontSideData?.minisite_call_waiter?.active === true ? [qrFrontSideData.minisite_call_waiter] : []),
    ...(qrFrontSideData?.minisite_clean_table?.active === true ? [qrFrontSideData.minisite_clean_table] : []),
    ...(qrFrontSideData?.minisite_events?.active === true ? [qrFrontSideData.minisite_events] : []),
    ...(qrFrontSideData?.minisite_visitor_reg?.active === true ? [qrFrontSideData.minisite_visitor_reg] : []),
    ...(qrFrontSideData?.minisite_social_media),
    ...(qrFrontSideData?.minisite_text_section),
    ...(qrFrontSideData?.minisite_opening_hours?.slice(0, 1)),
    ...(qrFrontSideData?.minisite_contact_form),
    ...(qrFrontSideData?.minisite_maps?.length ? qrFrontSideData?.minisite_maps : []),
    ...(qrFrontSideData?.minisite_qr_code),
    ...(qrFrontSideData?.minisite_make_money),
    ...(qrFrontSideData?.minisite_document),
    ]
    if (qrFrontSideData?.minisiteButtonSequence?.length) {
      const b = qrFrontSideData?.minisiteButtonSequence;
      const c = b.map((i) => btns.find((j) => j?.btnID === i || j._id === i || j.btnType === i)).filter(e => e);
      let leftOver = btns.filter(val => !b.includes(val?.btnID || val._id || val.btnType))
      c.push(...leftOver);
      return c
    } else {
      return btns
    }
  }

  const checkArrayMergeOne = (currentObj, tempArr) => {
    if ((currentObj.btnType == "MinisiteQRCode") || (currentObj.btnType == "TextSection" && currentObj?.displayType == "2") || (currentObj?.displayType == "4" && currentObj.btnType != "TextSection") || (currentObj?.displayType == "1" && currentObj.btnType == "Portfolio" && currentObj.imgs?.length < 3 && (currentObj.imgs?.length <= (3 - tempArr.length)))) {
      return true;
    }
    return false;
  }

  function modifyArray(arr) {
    const modifiedArr = [];
    let tempArr = [];

    for (let i = 0; i < arr.length; i++) {
      const currentObj = arr[i];
      const nextObj = arr[i + 1];

      if (checkArrayMergeOne(currentObj, tempArr) && nextObj && checkArrayMergeOne(nextObj, tempArr) && tempArr.length < 2) {
        if (currentObj.btnType == "Portfolio") {
          if (currentObj?.imgs?.length == 1) {
            tempArr.push(currentObj);
          } else if (currentObj?.imgs?.length == 2) {
            tempArr.push(currentObj);
            tempArr.push(null);
          }
        } else {
          tempArr.push(currentObj);
        }
      } else if (checkArrayMergeOne(currentObj, tempArr) && tempArr.length > 0) {
        if (currentObj.btnType == "Portfolio") {
          if (currentObj?.imgs?.length == 1) {
            tempArr.push(currentObj);
          } else if (currentObj?.imgs?.length == 2) {
            tempArr.push(currentObj);
            tempArr.push(null);
          }
        } else {
          tempArr.push(currentObj);
        }
        modifiedArr.push(tempArr);
        tempArr = [];
      } else {
        modifiedArr.push(currentObj);
      }
    }
    return modifyArrayForSocials(modifiedArr);
  }

  function modifyArrayForSocials(arr) {
    const modifiedArr = [];
    let tempArr = [];

    for (let i = 0; i < arr.length; i++) {
      const currentObj = arr[i];
      const nextObj = arr[i + 1];

      if ((currentObj?.btnType == "SocialMedia" && currentObj?.identifier !== "Document") && currentObj?.largeIconBody == true && nextObj && (nextObj?.btnType == "SocialMedia" && currentObj?.identifier !== "Document") && currentObj?.largeIconBody == true) {
        tempArr.push(currentObj);
      } else if (tempArr.length > 0) {
        if ((currentObj?.btnType == "SocialMedia" && currentObj?.identifier !== "Document") && currentObj?.largeIconBody == true) {
          tempArr.push(currentObj);
          modifiedArr.push(tempArr);
          tempArr = [];
        } else {
          modifiedArr.push(tempArr);
          modifiedArr.push(currentObj);
          tempArr = [];
        }
      } else {
        modifiedArr.push(currentObj);
      }
    }
    return modifyArrayForOthers(modifiedArr);
  }

  const checkArrayMerge = (currentObj) => {
    if ((currentObj?.displayType == "3" && currentObj.btnType == "Portfolio") || (currentObj?.displayType == "1" && currentObj.btnType == "Maps") || (currentObj?.displayType == "1" && currentObj.btnType == "ContactForm") || (currentObj.btnType == "TextSection" && currentObj?.displayType == "1") || (currentObj?.displayType == "2" && currentObj.btnType == "Document") || (currentObj?.btnType == "MinisiteQRCode")) {
      return true;
    }
    return false;
  }

  function modifyArrayForOthers(arr) {
    const modifiedArr = [];
    let tempArr = [];
    for (let i = 0; i < arr.length; i++) {
      const currentObj = arr[i];
      const nextObj = arr[i + 1];
      if (checkArrayMerge(currentObj) && nextObj && checkArrayMerge(nextObj) && tempArr.length < 1) {
        tempArr.push(currentObj);
      } else if (checkArrayMerge(currentObj) && tempArr.length > 0) {
        tempArr.push(currentObj);
        modifiedArr.push(tempArr);
        tempArr = [];
      } else {
        modifiedArr.push(currentObj);
      }
    }
    return modifiedArr;
  }

  const facebookShare = "/assets/images/social-media-logos/facebook.svg";
  const twitterShare = "/assets/images/social-media-logos/twitter.svg";
  const whatsappShare = "/assets/images/social-media-logos/whatsapp.svg";
  const messengerShare = "/assets/images/social-media-logos/messenger.svg";
  const googleShare = "/assets/images/social-media-logos/google.svg";
  const snapchatShare = "/assets/images/social-media-logos/snapchat.svg";
  const moreOptionShare = "/assets/images/social-media-logos/view-more.svg";

  const isLinkyFeatureButton = (linkyFeatureButtonType) =>
    linkyFeatureButtonType === "AcceptPayments" ||
    linkyFeatureButtonType === "MinisiteReviewBooster" ||
    linkyFeatureButtonType === "BusinessCard" ||
    linkyFeatureButtonType === "Tips" ||
    linkyFeatureButtonType === "OrderOnline" ||
    linkyFeatureButtonType === "CallWaiter" ||
    linkyFeatureButtonType === "CleanTable" ||
    linkyFeatureButtonType === "SellTicket" ||
    linkyFeatureButtonType === "VisitorReg" ||
    linkyFeatureButtonType === "MinisiteBooking" ||
    linkyFeatureButtonType === "MinisiteMeet" ||
    linkyFeatureButtonType === "MakeMoneyWithLinky";

  const [showSharePopUp, setShowSharePopUp] = useState(false);
  const toggleSharePopUp = () => { setShowSharePopUp(!showSharePopUp) };
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleToolTip = () => setTooltipOpen(!tooltipOpen);
  // const [hostName, setHostName] = useState(null);

  let shareLinkData = [
    { name: "Facebook", icon: facebookShare, value: "facebook" },
    { name: "Twitter", icon: twitterShare, value: "twitter" },
    { name: "WhatsApp", icon: whatsappShare, value: "whatsapp" },
    { name: "LinkedIn", icon: Linkedin, value: "linkedIn" },
    { name: "Snap Chat", icon: snapchatShare, value: "snapchat" },
    { name: "Messenger", icon: messengerShare, value: "messenger" },
    { name: "Gmail", icon: googleShare, value: "gmail" },
    { name: "More Option", icon: moreOptionShare, value: "more_option" }
  ];

  let getShareURL = getBizLinkDomain(merchantData?.business_sub_category);

  const redirectToSocialMediaPage = (data) => {

    let window_location_encoded = getShareURL;
    let share_link = '';

    if (data.value == "facebook") {

      share_link = "https://www.facebook.com/sharer/sharer.php?u=" + window_location_encoded;
      window.open(share_link, '_blank');

    } else if (data.value == "twitter") {

      share_link = "http://www.twitter.com/share?url=" + window_location_encoded;
      window.open(share_link, '_blank');

    } else if (data.value == "whatsapp") {

      share_link = "https://web.whatsapp.com/send?text=" + window_location_encoded;
      window.open(share_link, '_blank');

    } else if (data.value == "linkedIn") {

      share_link = "https://www.linkedin.com/sharing/share-offsite/?url=" + window_location_encoded;
      window.open(share_link, '_blank');

    } else if (data.value == "snapchat") {

      let url = "https://www.snapchat.com/scan?attachmentUrl=" + window_location_encoded;
      window.open(url, 'sharer', 'width=800,height=600');

    } else if (data.value == "messenger") {

      window.open("https://www.messenger.com/login.php?next=" + window_location_encoded, '_blank');

    } else if (data.value == "gmail") {

      let url = 'https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=&body=' + window_location_encoded;
      window.open(url, 'sharer', 'toolbar=0,status=0,width=800,height=600');

    } else if (data.value == "more_option") {

      let shareData = { url: window_location_encoded };
      navigator.share(shareData).then(() => { console.log() }).catch((e) => { console.log(e) })
    }
  }


  const [getFrameSize, setGetFrameSize] = useState(0);

  const updateDimensions = (getFrameHeight) => {
    setGetFrameSize(getFrameHeight);
  };

  const buttonRefs = useRef({});

  useEffect(() => {

    let getFrameHeight = document.getElementById('mobile_frames')?.clientHeight;

    window.addEventListener("resize", updateDimensions(getFrameHeight));
    return () => window.removeEventListener("resize", updateDimensions(getFrameHeight));

  }, []);

  function handleSubmit(){

  }

  return (
    <div className="h-100 height-mobile-300px qr_codePage_form_user_select" style={{ overflow: isMobile && !zoom && 'hidden' }}>

      {qrFrontSideData &&
        <>

          {currentPage === 0 ?

            <div className="mobile-display-only " style={{ display: 'none' }}>
              <div className={`phone-zoom-in-out ${zoom ? 'phone-zoom' : ''}`} onClick={() => setZoom(!zoom)}>
                <img draggable={false} src={zoom ? zoomout : zoomin} style={{ display: "block", margin: "0px", borderRadius: "100px" }} />
              </div>
            </div> : null
          }


          <div
            className="qr_code_page"
            style={{
              height: isMobile ? '650px' : "95vh",
              width: isMobile ? "310px" : "45vh",
              position: "sticky",
              top: "0",
              transform: isMobile ? zoom ? 'scale(0.85)' : 'scale(0.55)' : 'none',
              marginTop: isMobile ? !zoom && '10px' : "15px",
              transformOrigin: 'top'
            }}
            onClick={(e) => {
              // if (displayColorPicker) {
              //   setDisplayColorPicker(!displayColorPicker);
              // }
            }}
          >

            <div
              className="mobile-display-only"
              style={{ display: "none", color: '#d23f99', fontSize: zoom ? "15px" : "25px", fontWeight: 600, letterSpacing: "1px", justifyContent: "center" }}
              onClick={() => openInNewTab(getBizLinkDomain(merchantData?.business_sub_category))}
            >
              PREVIEW
            </div>

            <div className="d-flex align-items-center justify-content-center hide-480px">
              <img draggable={false}
                src={PreviewButton}
                alt="PreviewButton"
                style={{
                  width: "10vw",
                  position: "absolute",
                  top: "-4%",
                  zIndex: 9999,
                  cursor: "pointer",
                  // transform: getDir == "rtl" && 'scaleX(-1)'
                }}
                onClick={() => { window.open(getBizLinkDomain(merchantData?.business_sub_category), '_blank'); return false; }}
              />
            </div>





            <div
              style={{
                position: "absolute",
                // top: "10.3%",
                left: "50%",
                transform: "translate(-50%)",
                width: isMobile ? '82%' : "84%",
                maxWidth: !isMobile && "262px",
                minWidth: !isMobile && "262px",
                height: isMobile ? '74%' : "80%",
                maxHeight: !isMobile && "500px",
                border: '10px solid black',
                borderRadius: '40px',
              }}
              id="mobile_frames"
            >
              <div
                id="main-mobile-screen"
                className={`h-100  text-center mobile_screen ${currentPage === 3 ? "p-2" : ''}`}
                style={{
                  color: qrFrontSideData?.biz_link_button_font_color && currentPage === 0 ? qrFrontSideData.biz_link_button_font_color : '#000000',
                  fontFamily: qrFrontSideData?.biz_link_font_family ? qrFrontSideData.biz_link_font_family : 'Roboto',
                  overflowX: 'hidden',
                  // width: '262px',
                  position: "relative",
                  overflowY: 'auto',
                  borderRadius: '30px',
                  ...(qrFrontSideData?.background_type === "flat" && (currentPage === 0 || currentPage === 7 || currentPage === 6) ?
                    {
                      background: qrFrontSideData?.flat_color ? qrFrontSideData.flat_color : "white",
                    } : qrFrontSideData?.background_type === "gradient" && (currentPage === 0 || currentPage === 7 || currentPage === 6) ?
                      {
                        background: `${qrFrontSideData?.gradientColor?.color_one && qrFrontSideData?.gradientColor?.color_two ? `linear-gradient(${qrFrontSideData.gradientColor.color_one}, ${qrFrontSideData.gradientColor.color_two})` : "white"}`,
                      } : (qrFrontSideData?.background_type === "theme" || qrFrontSideData?.background_type === "custom_image") && (currentPage === 0 || currentPage === 7 || currentPage === 6) ? {
                        backgroundImage: `url(${qrFrontSideData?.background_type === "custom_image" ? qrFrontSideData?.background_image_name.replace('?', `?${timeStamp}&`) : qrFrontSideData?.theme_main_page?.theme_image.replace('?', `?${timeStamp}&`)})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center"
                      } : {
                        backgroundColor: currentPage === 1 ? "#D5E4E8" : "white"
                      }
                  )
                }}
              >
                {
                  currentPage === 0 ? (
                    <div className="w-100 ">

                      <div style={{
                        position: "fixed",
                        zIndex: 0,
                        top: "0px",
                        overflowY: "auto",
                        left: "0px",
                        width: "100%",
                        height: "100%",
                        boxSizing: "border-box",
                        borderRadius: "25px",
                        opacity: 1,
                        backgroundColor: showSharePopUp && "#000",
                        opacity: showSharePopUp && "0.5",
                        background: (qrFrontSideData?.header_layout == "2" || qrFrontSideData?.header_layout == "1") && (qrFrontSideData?.background_type === "custom_image" || qrFrontSideData?.background_type === "theme") ? qrFrontSideData?.background_opacity === "dark" ? "none rgba(81, 81, 81, 0.4)" : qrFrontSideData?.background_opacity === "light" ? "none rgba(228, 228, 228, 0.3)" : "none transparent" : "none transparent",
                      }} />

                      {
                        currentPage === 0 && (qrFrontSideData?.background_blur?.value === "medium" || qrFrontSideData?.background_blur?.value === "full") ? <div
                          style={{
                            width: "100%",
                            height: "100%",
                            pointerEvents: "none",
                            top: 0,
                            left: 0,
                            borderRadius: "25px",
                            position: "fixed",
                            backdropFilter: qrFrontSideData?.background_blur?.value === "medium" ? "blur(5px)" : qrFrontSideData?.background_blur?.value === "full" ? "blur(10px)" : "unset",
                            WebkitBackdropFilter: qrFrontSideData?.background_blur?.value === "medium" ? "blur(5px)" : qrFrontSideData?.background_blur?.value === "full" ? "blur(10px)" : "unset",
                            background: "none",
                          }}
                        /> : null
                      }

                      <div className="mb-2">
                        

                        <div
                          className={`d-flex w-100 align-items-center ${qrFrontSideData?.minisite_display_views ? 'justify-content-between' : 'justify-content-start'}`}
                          style={{
                            position: qrFrontSideData?.header_layout == "3" ? 'absolute' : "relative",
                            marginTop: qrFrontSideData?.header_layout !== "3" && "15px",
                            marginBottom: qrFrontSideData?.header_layout !== "3" && '10px',
                            top: qrFrontSideData?.header_layout == "3" && "5px",
                            // right: qrFrontSideData?.header_layout == "3" && "8px",
                            padding: '5px 10px',
                            borderRadius: '20px',
                            boxSizing: "border-box",
                            zIndex: 999,
                            width: 'max-content',
                            height: "auto",
                            opacity: 1,
                            lineHeight: 1.5,
                            fontFamily: qrFrontSideData?.biz_link_font_family ? qrFrontSideData.biz_link_font_family : 'Roboto',
                            color: qrFrontSideData?.biz_link_button_font_color ? qrFrontSideData.biz_link_button_font_color : '#ffffff',
                            letterSpacing: (qrFrontSideData?.biz_link_font_family?.trim()?.toLowerCase() == 'courier' || qrFrontSideData?.biz_link_font_family?.trim()?.toLowerCase() == 'inconsolata') ? '0.4px' : '1px'
                          }}
                          {...viewsHoverProps}
                        >
                          <div className="d-flex align-items-center bg-white"
                            style={{
                              padding: '6px 6px',
                              borderRadius: "35px",
                              fontWeight: 600, color: 'rgb(10, 11 , 13)',
                              cursor: "pointer"
                            }}
                            onClick={() => { setShowSharePopUp(true) }}
                          >
                            <MoreHorizIcon style={{ fontSize: "15px", fontWeight: 600, color: 'inherit' }} />
                          </div>
                          {qrFrontSideData?.minisite_display_views && <div className="d-flex align-items-center bg-white" style={{
                            padding: '6px 14px',
                            borderRadius: "35px",
                            fontWeight: 600, color: 'rgb(10, 11 , 13)',
                          }}>
                            <VisibilityIcon style={{ fontSize: "14px", fontWeight: 600, color: 'inherit' }} />
                            <p className="mt-0 ml-2" style={{ fontSize: "11px", fontWeight: 600, color: 'inherit' }}>{viewsHovering ? `${qrFrontSideData?.store_views} ${qrFrontSideData?.store_views > 1 ? "views" : "view"}` : `${viewsCalculator(qrFrontSideData?.store_views)}`}</p>
                          </div>}
                        </div>

                        {
                          qrFrontSideData?.header_layout == "2" ?
                            <div
                              className="header-main mx-auto px-2 py-0"
                              style={{
                                color: qrFrontSideData?.biz_link_header_font_color ? qrFrontSideData.biz_link_header_font_color : '#ffffff',
                              }}
                            >
                              <div className="d-flex">
                                {
                                  merchantData?.minisite_image && <div className="col-3 p-0">
                                    <div className={`mt-3 ${merchantData?.minisite_image ? 'd-initial' : 'd-none'}`}>
                                      <div className="d-inline-block text-center">
                                        {
                                          merchantData?.minisite_image && <img draggable={false}
                                            src={merchantData?.minisite_image.includes('dropbox') ? merchantData?.minisite_image.replace('?', `?${timeStamp}&`) : client.url(`images/store-logo/${merchantData?.minisite_image}`)}
                                            style={{
                                              height: "55px",
                                              width: "55px",
                                              borderRadius: "50%",
                                              objectFit: 'cover'
                                            }}
                                          />
                                        }
                                      </div>
                                    </div>
                                  </div>
                                }
                                <div className={`d-flex flex-column align-items-start justify-content-center p-0 text-left ${merchantData?.minisite_image ? 'col-9 pl-2' : 'col-12 px-1'}`}>
                                  {
                                    (qrFrontSideData?.profile_name || qrFrontSideData?.business_display_name) && <p className="p-0 m-0" style={{ color: 'inherit', fontSize: qrFrontSideData?.biz_link_header_font_size?.primary ? `${qrFrontSideData.biz_link_header_font_size.primary - 3}px` : '13px', fontWeight: 600 }}>
                                      {qrFrontSideData?.profile_name || qrFrontSideData?.business_display_name}
                                    </p>
                                  }
                                  {
                                    qrFrontSideData?.profile_subtitle && <p className="p-0 m-0" style={{ color: 'inherit', fontSize: qrFrontSideData?.biz_link_header_font_size?.secondary ? `${qrFrontSideData.biz_link_header_font_size.secondary - 2}px` : '12px', fontWeight: 400 }}>
                                      {qrFrontSideData.profile_subtitle}
                                    </p>
                                  }
                                  <div className='d-flex flex-wrap '>
                                    {
                                      qrFrontSideData?.minisite_social_media?.filter(e => e?.smallIconHeader == true && e?.publish == true)?.map((icon) => {
                                        let iconURL = array_of_social_handles.find((val => val.name === icon.identifier || val.altName === icon.identifier))
                                        return (
                                          <>
                                            {
                                              iconURL ?
                                                <div
                                                  className="d-flex align-items-center justify-content-center m-1"
                                                  style={{
                                                    padding: '4px',
                                                    borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "30px" : "10px",
                                                    background: qrFrontSideData?.biz_link_button_bg_color ? rgbaHexCalculate(qrFrontSideData?.biz_link_button_bg_color) : rgbaHexCalculate('#FFFFFF'),
                                                    color: qrFrontSideData?.biz_link_button_font_color ? qrFrontSideData.biz_link_button_font_color : '#ffffff',
                                                    cursor: 'pointer',
                                                    height: "25px",
                                                    width: "25px",
                                                  }}
                                                >{iconURL?.URL}</div> : null
                                            }
                                          </>
                                        )
                                      })
                                    }
                                  </div>
                                </div>
                              </div>
                            </div> :
                            qrFrontSideData?.header_layout == "3" ?
                              <div
                                className="header-main mx-auto p-0 mx-0"
                                style={{
                                  color: qrFrontSideData?.biz_link_header_font_color ? qrFrontSideData.biz_link_header_font_color : '#ffffff',
                                }}
                              >
                                <div className="d-flex flex-column align-items-center position-relative" style={{ zIndex: 555 }}>
                                  <div className={`w-100`}>
                                    <div className="w-100" style={{ height: '240px' }}>
                                      {
                                        merchantData?.minisite_image ?
                                          <div
                                            className="w-100 h-100"
                                            style={{
                                              backgroundImage: `url(${merchantData?.minisite_image.includes('dropbox') ? merchantData?.minisite_image.replace('?', `?${timeStamp}&`) : client.url(`images/store-logo/${merchantData?.minisite_image}`)})`,
                                              backgroundSize: "cover",
                                              backgroundRepeat: "no-repeat",
                                              backgroundPosition: "center",
                                            }}
                                          >
                                          </div> : <div
                                            className="w-100 h-100 bg-light"
                                            style={{
                                              backgroundImage: `url(${defaultImageHeaderType3})`,
                                              backgroundSize: "cover",
                                              backgroundRepeat: "no-repeat",
                                              backgroundPosition: "center",
                                            }}
                                          >
                                          </div>
                                      }
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      width: "200%",
                                      boxShadow: `0px 0px 20px 20px ${qrFrontSideData?.flat_color ? qrFrontSideData.flat_color : "#95d8d1"}`
                                    }}
                                  ></div>

                                </div>
                                <div
                                  className="d-flex flex-column align-items-start justify-content-center px-2"
                                  id="main-info-div"
                                  style={{
                                    // position: 'absolute',
                                    // left: 0,
                                    // top: '200px'
                                    position: 'relative',
                                    marginTop: '-10px',
                                    zIndex: '900',
                                  }}
                                >
                                  {
                                    (qrFrontSideData?.profile_name || qrFrontSideData?.business_display_name) && <p className="p-0 m-0 text-left" style={{ color: 'inherit', fontSize: qrFrontSideData?.biz_link_header_font_size?.primary ? `${qrFrontSideData.biz_link_header_font_size.primary - 3}px` : '13px', fontWeight: 600 }}>
                                      {qrFrontSideData?.profile_name || qrFrontSideData?.business_display_name}
                                    </p>
                                  }
                                  {
                                    qrFrontSideData?.profile_subtitle && <p className="p-0 m-0 text-left" style={{ color: 'inherit', fontSize: qrFrontSideData?.biz_link_header_font_size?.secondary ? `${qrFrontSideData.biz_link_header_font_size.secondary - 2}px` : '12px', fontWeight: 400 }}>
                                      {qrFrontSideData.profile_subtitle}
                                    </p>
                                  }

                                  <div className='d-flex flex-wrap'>
                                    {
                                      qrFrontSideData?.minisite_social_media?.filter(e => e?.smallIconHeader == true && e?.publish == true)?.map((icon) => {
                                        let iconURL = array_of_social_handles.find((val => val.name === icon.identifier || val.altName === icon.identifier))
                                        return (
                                          <>
                                            {
                                              iconURL ?
                                                <div
                                                  className="d-flex align-items-center justify-content-center m-1"
                                                  style={{
                                                    padding: '4px',
                                                    borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "30px" : "10px",
                                                    background: qrFrontSideData?.biz_link_button_bg_color ? rgbaHexCalculate(qrFrontSideData?.biz_link_button_bg_color) : rgbaHexCalculate('#FFFFFF'),
                                                    color: qrFrontSideData?.biz_link_button_font_color ? qrFrontSideData.biz_link_button_font_color : '#ffffff',
                                                    cursor: 'pointer',
                                                    height: "25px",
                                                    width: "25px",
                                                  }}
                                                >{iconURL?.URL}</div> : null
                                            }
                                          </>
                                        )
                                      })
                                    }
                                  </div>
                                </div>
                              </div> :
                              <div
                                className="header-main mx-auto p-0"
                                style={{
                                  color: qrFrontSideData?.biz_link_header_font_color ? qrFrontSideData.biz_link_header_font_color : '#ffffff',
                                }}
                              >
                                <div className="d-flex flex-column align-items-center position-relative" style={{ zIndex: 555 }}>
                                  <div className="">
                                    <div className={`mt-3 mb-2 ${merchantData?.minisite_image ? 'd-initial' : 'd-none'}`}>
                                      <div className="d-inline-block text-center">
                                        {
                                          merchantData?.minisite_image && <img draggable={false}
                                            src={merchantData?.minisite_image.includes('dropbox') ? merchantData?.minisite_image.replace('?', `?${timeStamp}&`) : client.url(`images/store-logo/${merchantData?.minisite_image}`)}
                                            style={{
                                              height: "60px",
                                              width: "60px",
                                              borderRadius: "50%",
                                              objectFit: 'cover'
                                            }}
                                          />
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-column align-items-center justify-content-center px-3">
                                    {
                                      (qrFrontSideData?.profile_name || qrFrontSideData?.business_display_name) && <p className="p-0 m-0" style={{ color: 'inherit', fontSize: qrFrontSideData?.biz_link_header_font_size?.primary ? `${qrFrontSideData.biz_link_header_font_size.primary - 3}px` : '13px', fontWeight: 600 }}>
                                        {qrFrontSideData?.profile_name || qrFrontSideData?.business_display_name}
                                      </p>
                                    }
                                    {
                                      qrFrontSideData?.profile_subtitle && <p className="p-0 m-0 text-center" style={{ color: 'inherit', fontSize: qrFrontSideData?.biz_link_header_font_size?.secondary ? `${qrFrontSideData.biz_link_header_font_size.secondary - 2}px` : '12px', fontWeight: 400 }}>
                                        {qrFrontSideData.profile_subtitle}
                                      </p>
                                    }
                                    <div className='d-flex flex-wrap '>
                                      {
                                        qrFrontSideData?.minisite_social_media?.filter(e => e?.smallIconHeader == true && e?.publish == true).map((icon) => {
                                          let iconURL = array_of_social_handles.find((val => val.name === icon.identifier || val.altName === icon.identifier))
                                          return (
                                            <>
                                              {
                                                iconURL ? <div
                                                  className="d-flex align-items-center justify-content-center m-1"
                                                  style={{
                                                    padding: '4px',
                                                    borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "30px" : "10px",
                                                    background: qrFrontSideData?.biz_link_button_bg_color ? rgbaHexCalculate(qrFrontSideData?.biz_link_button_bg_color) : rgbaHexCalculate('#FFFFFF'),
                                                    color: qrFrontSideData?.biz_link_button_font_color ? qrFrontSideData.biz_link_button_font_color : '#ffffff',
                                                    cursor: 'pointer',
                                                    height: "25px",
                                                    width: "25px",
                                                  }}
                                                >{iconURL?.URL}</div> : null
                                              }
                                            </>
                                          )
                                        })
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                        }
                      </div>

                      <div
                        className="row w-100 mx-auto px-2"
                        style={{
                          marginTop: '0.5rem',
                        }}
                        id='phone_home'
                      >
                        <div style={{ display: "grid", gap: "10px", gridTemplateColumns: "repeat(12, 1fr)" }}>
                          {
                            getRearrangedMinisiteButtons(qrFrontSideData)?.filter(val => val?.publish === true)?.map((val, index) => {
                              if (val.btnType === 'AcceptPayments' || val.btnType === 'MinisiteReviewBooster' || val.btnType === 'BusinessCard' || val.btnType === 'Tips' || val.btnType === 'OrderOnline' || val.btnType === 'CallWaiter' || val.btnType === 'CleanTable' || val.btnType === 'SellTicket' || val.btnType === 'VisitorReg' || val.btnType === "Link" || val.btnType === "MakeMoneyWithLinky" || val.btnType === "MinisiteBooking" || val.btnType === "MinisiteMeet") {
                                return (
                                  val.displayType == "1" ?
                                    <span
                                      key={index}
                                      onClick={() => {
                                        if (val?.btnType === 'BusinessCard') {
                                          dispatch(changePageNumber(7))
                                        } else if (val?.btnType === 'Tips') {
                                          dispatch(changePageNumber(3))
                                        } else if (val?.btnType === 'VisitorReg') {
                                          dispatch(changePageNumber(1))
                                        }
                                      }}
                                      style={{ gridColumn: "span 12" }}
                                      className={`spn col-${qrFrontSideData?.visitor_button_props?.visitor_button_size?.size == "" ? "12" : qrFrontSideData?.visitor_button_props?.visitor_button_size?.size} `}
                                    >
                                      <div
                                        // id={qrBtnVal.id}
                                        className={`overflow-hidden d-flex flex-column justify-content-center`}
                                        style={{
                                          padding: qrFrontSideData?.biz_link_button_type === "round" ? '11px 11px 11px 18px' : '11px',
                                          margin: "8px 0",
                                          borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "30px" : "10px",
                                          background: qrFrontSideData?.biz_link_button_bg_color ? rgbaHexCalculate(qrFrontSideData?.biz_link_button_bg_color) : rgbaHexCalculate('#FFFFFF'),
                                          boxSizing: "border-box",
                                          position: "relative",
                                          zIndex: 7,
                                          height: "max-content",
                                          minHeight: '65px',
                                          opacity: 1,
                                          cursor: "pointer",
                                          border: qrFrontSideData?.biz_link_button_border && `2px solid ${qrFrontSideData?.biz_link_button_border_color}`,
                                          boxShadow: qrFrontSideData?.biz_link_button_shadow ? qrFrontSideData?.biz_link_button_shadow_color ? `${qrFrontSideData.biz_link_button_shadow_color} 4px 4px 0px 0px` : "#ffffff 4px 4px 0px 0px" : 'none',
                                          lineHeight: 1.5,
                                          textAlign: val.alignment || 'left'
                                        }}
                                      >
                                        <p
                                          className="p-0 m-0 whitespace-pre-line"
                                          style={{
                                            fontSize: qrFrontSideData?.biz_link_button_font_size?.primary ? `${qrFrontSideData.biz_link_button_font_size.primary - 3}px` : '13px',
                                            fontWeight: 600,
                                            color: 'inherit'
                                          }}
                                        >
                                          {val?.title}
                                        </p>
                                        <p
                                          className="p-0 m-0 whitespace-pre-line"
                                          style={{
                                            fontSize: qrFrontSideData?.biz_link_button_font_size?.secondary ? `${qrFrontSideData.biz_link_button_font_size.secondary - 2}px` : '12px',
                                            fontWeight: 400,
                                            color: 'inherit'
                                          }}
                                        >
                                          {val?.subtitle}
                                        </p>
                                      </div>
                                    </span> : val.displayType == "2" ?
                                      <span
                                        onClick={() => {
                                          if (val?.btnType === 'BusinessCard') {
                                            dispatch(changePageNumber(7))
                                          } else if (val?.btnType === 'Tips') {
                                            dispatch(changePageNumber(3))
                                          } else if (val?.btnType === 'VisitorReg') {
                                            dispatch(changePageNumber(1))
                                          }
                                        }}
                                        key={index}
                                        style={{ gridColumn: "span 12" }}
                                        className={`spn col-${qrFrontSideData?.visitor_button_props?.visitor_button_size?.size == "" ? "12" : qrFrontSideData?.visitor_button_props?.visitor_button_size?.size} `}
                                      >
                                        <div
                                          // id={qrBtnVal.id}
                                          className={`d-flex justify-content-center overflow-hidden align-items-center`}
                                          style={{
                                            padding: '11px',
                                            margin: "8px 0",
                                            borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "30px" : "10px",
                                            background: qrFrontSideData?.biz_link_button_bg_color ? rgbaHexCalculate(qrFrontSideData?.biz_link_button_bg_color) : rgbaHexCalculate('#FFFFFF'),
                                            boxSizing: "border-box",
                                            position: "relative",
                                            zIndex: 7,
                                            height: "max-content",
                                            minHeight: '65px',
                                            opacity: 1,
                                            cursor: "pointer",
                                            border: qrFrontSideData?.biz_link_button_border && `2px solid ${qrFrontSideData?.biz_link_button_border_color}`,
                                            boxShadow: qrFrontSideData?.biz_link_button_shadow ? qrFrontSideData?.biz_link_button_shadow_color ? `${qrFrontSideData.biz_link_button_shadow_color} 4px 4px 0px 0px` : "#ffffff 4px 4px 0px 0px" : 'none',
                                            lineHeight: 1.5,
                                            textAlign: val.alignment || 'left'
                                          }}
                                        >
                                          {
                                            val?.image || (isLinkyFeatureButton(val.btnType) && val?.defaultImage?.status == true) ? <img draggable={false}
                                              className="mr-2"
                                              style={{ height: '40px', width: "40px", borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "100px" : "10px", objectFit: 'cover' }}
                                              src={
                                                (isLinkyFeatureButton(val.btnType) && val?.defaultImage?.status == true && typeof val?.defaultImage?.value == "string") ? imageSelector(val.btnType, false, val?.defaultImage?.value)
                                                  : val.image.includes("dropbox") ? val.image.replace('?', `?${timeStamp}&`) : client.url(`images/qr-img/button_images_path/${val.image}`)
                                              }
                                            /> :
                                              <div
                                                className="mr-2"
                                                style={{ height: '40px', width: "40px", borderRadius: '5px' }}
                                              />
                                          }
                                          <div style={{ height: "max-content", width: '-webkit-fill-available', textAlign: val.alignment || 'left' }}>
                                            <p className="p-0 m-0 whitespace-pre-line" style={{ fontSize: qrFrontSideData?.biz_link_button_font_size?.primary ? `${qrFrontSideData.biz_link_button_font_size.primary - 3}px` : '13px', fontWeight: 600, color: 'inherit' }}>
                                              {val?.title}
                                            </p>
                                            <p className="p-0 m-0 whitespace-pre-line" style={{ fontSize: qrFrontSideData?.biz_link_button_font_size?.secondary ? `${qrFrontSideData.biz_link_button_font_size.secondary - 2}px` : '12px', fontWeight: 400, color: 'inherit' }}>
                                              {val?.subtitle}
                                            </p>
                                          </div>
                                        </div>
                                      </span> : val.displayType == "3" ?
                                        <span
                                          onClick={() => {
                                            if (val?.btnType === 'BusinessCard') {
                                              dispatch(changePageNumber(7))
                                            } else if (val?.btnType === 'Tips') {
                                              dispatch(changePageNumber(3))
                                            } else if (val?.btnType === 'VisitorReg') {
                                              dispatch(changePageNumber(1))
                                            }
                                          }}
                                          key={index}
                                          style={{ gridColumn: "span 12" }}
                                          className={`spn col-${qrFrontSideData?.visitor_button_props?.visitor_button_size?.size == "" ? "12" : qrFrontSideData?.visitor_button_props?.visitor_button_size?.size} `}
                                        >
                                          <div
                                            // id={qrBtnVal.id}
                                            className={`d-flex flex-column justify-content-center overflow-hidden`}
                                            style={{
                                              padding: val.fullImage === true ? "unset" : '11px 11px 8px 11px',
                                              margin: "8px 0",
                                              borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "30px" : "10px",
                                              background: qrFrontSideData?.biz_link_button_bg_color ? rgbaHexCalculate(qrFrontSideData?.biz_link_button_bg_color) : rgbaHexCalculate('#FFFFFF'),
                                              boxSizing: "border-box",
                                              position: "relative",
                                              zIndex: 7,
                                              height: "max-content",
                                              opacity: 1,
                                              cursor: "pointer",
                                              border: qrFrontSideData?.biz_link_button_border && `2px solid ${qrFrontSideData?.biz_link_button_border_color}`,
                                              boxShadow: qrFrontSideData?.biz_link_button_shadow ? qrFrontSideData?.biz_link_button_shadow_color ? `${qrFrontSideData.biz_link_button_shadow_color} 4px 4px 0px 0px` : "#ffffff 4px 4px 0px 0px" : 'none',
                                              lineHeight: 1.5,
                                              textAlign: val.alignment || 'left'
                                            }}
                                          >
                                            {
                                              val.image || (isLinkyFeatureButton(val.btnType) && val?.defaultImage?.status == true) ? <img draggable={false}
                                                className="mb-2"
                                                style={{ aspectRatio: "9/4", width: "100%", borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "25px" : "10px", objectFit: 'cover' }}
                                                src={
                                                  (isLinkyFeatureButton(val.btnType) && val?.defaultImage?.status == true && typeof val?.defaultImage?.value == "string") ? imageSelector(val.btnType, true, val?.defaultImage?.value)
                                                    : val.image.includes("dropbox") ? val.image.replace('?', `?${timeStamp}&`) : client.url(`images/qr-img/button_images_path/${val.image}`)
                                                }
                                              /> :
                                                <div
                                                  className="mb-2"
                                                  style={{ aspectRatio: "9/4", width: "100%", borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "25px" : "10px" }}
                                                />
                                            }
                                            {val?.title && <p className="m-0 whitespace-pre-line" style={{ fontSize: qrFrontSideData?.biz_link_button_font_size?.primary ? `${qrFrontSideData.biz_link_button_font_size.primary - 3}px` : '13px', fontWeight: 600, color: 'inherit', padding: val?.fullImage == true ? "0 11px 0 11px" : "unset" }}>
                                              {val.title}
                                            </p>}
                                            {val?.subtitle && <p className="m-0 whitespace-pre-line" style={{ fontSize: qrFrontSideData?.biz_link_button_font_size?.secondary ? `${qrFrontSideData.biz_link_button_font_size.secondary - 2}px` : '12px', fontWeight: 400, color: 'inherit', padding: val?.fullImage == true ? "0 11px 8px 11px" : "unset" }}>
                                              {val.subtitle}
                                            </p>}
                                          </div>
                                        </span> : val.displayType == "4" ?
                                          <span
                                            onClick={() => {
                                              if (val?.btnType === 'BusinessCard') {
                                                dispatch(changePageNumber(7))
                                              } else if (val?.btnType === 'Tips') {
                                                dispatch(changePageNumber(3))
                                              } else if (val?.btnType === 'VisitorReg') {
                                                dispatch(changePageNumber(1))
                                              }
                                            }}
                                            key={index}
                                            className={`d-flex justify-content-start spn col-${qrFrontSideData?.visitor_button_props?.visitor_button_size?.size == "" ? "12" : qrFrontSideData?.visitor_button_props?.visitor_button_size?.size} `}
                                            style={{ gap: '10px', gridColumn: "span 4" }}
                                          >
                                            <div
                                              // id={qrBtnVal.id}
                                              className={`d-flex flex-column justify-content-start overflow-hidden`}
                                              style={{
                                                padding: val.fullImage === true ? "unset" : '11px 11px 8px 11px',
                                                margin: "8px 0",
                                                borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "20px" : "10px",
                                                background: qrFrontSideData?.biz_link_button_bg_color ? rgbaHexCalculate(qrFrontSideData?.biz_link_button_bg_color) : rgbaHexCalculate('#FFFFFF'),
                                                boxSizing: "border-box",
                                                position: "relative",
                                                zIndex: 7,
                                                width: '100%',
                                                minHeight: '100px',
                                                height: "auto",
                                                opacity: 1,
                                                cursor: "pointer",
                                                border: qrFrontSideData?.biz_link_button_border && `2px solid ${qrFrontSideData?.biz_link_button_border_color}`,
                                                boxShadow: qrFrontSideData?.biz_link_button_shadow ? qrFrontSideData?.biz_link_button_shadow_color ? `${qrFrontSideData.biz_link_button_shadow_color} 4px 4px 0px 0px` : "#ffffff 4px 4px 0px 0px" : 'none',
                                                lineHeight: 1.5,
                                                textAlign: val.alignment || 'left'
                                              }}
                                            >
                                              {
                                                val.image || (isLinkyFeatureButton(val.btnType) && val?.defaultImage?.status == true) ? <div className="flex justify-center"><img draggable={false}
                                                  className="mb-2"
                                                  style={{ width: "100%", borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "15px" : "10px", objectFit: 'cover' }}
                                                  src={
                                                    (isLinkyFeatureButton(val.btnType) && val?.defaultImage?.status == true && typeof val?.defaultImage?.value == "string") ? imageSelector(val.btnType, false, val?.defaultImage?.value, true)
                                                      : val.image.includes("dropbox") ? val.image.replace('?', `?${timeStamp}&`) : client.url(`images/qr-img/button_images_path/${val.image}`)
                                                  }
                                                /></div> :
                                                  <div
                                                    className="mb-2"
                                                    style={{ width: "100%", borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "15px" : "10px" }}
                                                  />
                                              }
                                              {val?.title && <p className="m-0 whitespace-pre-line" style={{ fontSize: qrFrontSideData?.biz_link_button_font_size?.primary ? `${qrFrontSideData.biz_link_button_font_size.primary - 3}px` : '13px', fontWeight: 600, color: 'inherit', padding: val?.fullImage == true ? "0 11px 0 11px" : "unset" }}>
                                                {val.title}
                                              </p>}
                                              {val?.subtitle && <p className="m-0 whitespace-pre-line" style={{ display: "-webkit-box", WebkitLineClamp: 3, WebkitBoxOrient: "vertical", overflow: "hidden", textOverflow: "ellipsis", fontSize: qrFrontSideData?.biz_link_button_font_size?.secondary ? `${qrFrontSideData.biz_link_button_font_size.secondary - 2}px` : '12px', fontWeight: 400, color: 'inherit', padding: val?.fullImage == true ? "0 11px 4px 11px" : "unset" }}>
                                                {val.subtitle}
                                              </p>}
                                            </div>
                                          </span> : null
                                )
                              } else if (val.btnType === "TextSection") {
                                return (
                                  <span
                                    key={index}
                                    style={{ gridColumn: val?.displayType == "1" ? "span 6" : val?.displayType == "2" ? "span 4" : "span 12" }}
                                  // className="w-100"
                                  >
                                    <div style={{ position: "relative" }}>
                                      <div
                                        // className={`overflow-hidden text-section-styles w-100 ${buttonRefs?.current[val._id]?.scrollHeight < buttonRefs?.current[val._id]?.offsetHeight ? "flex items-center" : ""}`}
                                        // ref={(buttonRef) => (buttonRefs.current[val._id] = buttonRef)}
                                        // dangerouslySetInnerHTML={{
                                        //   __html: `<div ${buttonRefs?.current[val._id]?.scrollHeight > buttonRefs?.current[val._id]?.offsetHeight ? `style="max-height: inherit; mask-image: linear-gradient(white 50%, transparent 100%); width: 100%; word-break: break-word;"` : `style="max-height: 100%;"`} >${val?.data}</div>`
                                        // }}
                                        style={{
                                          padding: !val?.hideBackground ? qrFrontSideData?.biz_link_button_type === "round" ? '18px 11px 18px 18px' : '18px 11px 18px 18px' : "unset",
                                          margin: "8px 0",
                                          borderRadius: !val?.hideBackground ? qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "20px" : "10px" : "unset",
                                          background: !val?.hideBackground ? qrFrontSideData?.biz_link_button_bg_color ? rgbaHexCalculate(qrFrontSideData?.biz_link_button_bg_color) : rgbaHexCalculate('#FFFFFF') : "unset",
                                          boxSizing: "border-box",
                                          position: "relative",
                                          textAlign: "left",
                                          zIndex: 7,
                                          opacity: !val?.hideBackground && 1,
                                          border: !val?.hideBackground ? qrFrontSideData?.biz_link_button_border && `2px solid ${qrFrontSideData?.biz_link_button_border_color}` : "unset",
                                          boxShadow: !val?.hideBackground ? qrFrontSideData?.biz_link_button_shadow ? qrFrontSideData?.biz_link_button_shadow_color ? `${qrFrontSideData.biz_link_button_shadow_color} 4px 4px 0px 0px` : "#ffffff 4px 4px 0px 0px" : 'none' : "unset",
                                          lineHeight: 1.5,
                                          // width: val?.displayType == "1" ? "50%" : val?.displayType == "2" ? "33.33%" : val?.displayType == "4" ? "100%" : "100%",
                                          // height: val?.displayType == "1" ? "150px" : val?.displayType == "2" ? "175px" : val?.displayType == "4" ? "60px" : "",
                                          // maxHeight: val?.displayType == "3" && "200px",
                                        }}
                                      // />
                                      // {
                                      //   buttonRefs?.current[val._id]?.scrollHeight > buttonRefs?.current[val._id]?.offsetHeight && <div style={{ bottom: 0 }} className="position-absolute d-flex w-100 align-items-center justify-content-start">
                                      //     <span style={{ padding: "0 18px 10px 18px", textDecoration: "underline", fontSize: "11px", zIndex: 10 }}>View more</span>
                                      >  
                                      <div
                                          className={`overflow-hidden text-section-styles w-100 ${buttonRefs?.current[val._id]?.scrollHeight < buttonRefs?.current[val._id]?.offsetHeight ? "flex items-center" : ""}`}
                                          style={{
                                            height: val?.displayType == "1" ? "150px" : val?.displayType == "2" ? "175px" : val?.displayType == "4" ? "60px" : "",
                                            maxHeight: val?.displayType == "3" ? "250px" : ""
                                          }}
                                          ref={(buttonRef) => (buttonRefs.current[val._id] = buttonRef)}
                                          dangerouslySetInnerHTML={{
                                            __html: `<div ${buttonRefs?.current[val._id]?.scrollHeight > buttonRefs?.current[val._id]?.offsetHeight ? `style="max-height: inherit; mask-image: linear-gradient(white 50%, transparent 100%); width: 100%; word-break: break-word;"` : `style="max-height: 100%;"`} >${val?.data}</div>`
                                          }}
                                        ></div>
                                        {
                                          buttonRefs?.current[val._id]?.scrollHeight > buttonRefs?.current[val._id]?.offsetHeight && <div style={{ bottom: 0 }} className="position-absolute d-flex w-100 align-items-center justify-content-start">
                                            <span style={{ padding: "0 0 10px 0", textDecoration: "underline", fontSize: "11px", zIndex: 10 }}>View more</span>
                                          </div>
                                        }
                                      </div>
                                      {/* } */}
                                    </div>
                                  </span>
                                )
                              } else if (val.btnType === "YouTube") {
                                return (
                                  <PreviewNotAvailable
                                    qrFrontSideData={qrFrontSideData}
                                    body={`${val?.store?.value} Post preview will not be visible here, kindly open your minisite link on browser to view your post`}
                                    footer={`${val?.store?.value} Post`}
                                  />
                                )
                              } else if (val.btnType === "OpeningHours") {
                                return (
                                  <span
                                    key={index}
                                    className="p-0"
                                    style={{ textIndent: "0", gridColumn: 'span 12' }}
                                  >
                                    <div
                                      // id={qrBtnVal.id}
                                      className={`d-flex flex-column justify-content-center overflow-hidden`}
                                      style={{
                                        padding: '11px 11px 8px 11px',
                                        margin: "8px 0",
                                        borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "30px" : "10px",
                                        background: qrFrontSideData?.biz_link_button_bg_color ? rgbaHexCalculate(qrFrontSideData?.biz_link_button_bg_color) : rgbaHexCalculate('#FFFFFF'),
                                        boxSizing: "border-box",
                                        position: "relative",
                                        zIndex: 7,
                                        height: "max-content",
                                        opacity: 1,
                                        cursor: "pointer",
                                        border: qrFrontSideData?.biz_link_button_border && `2px solid ${qrFrontSideData?.biz_link_button_border_color}`,
                                        boxShadow: qrFrontSideData?.biz_link_button_shadow ? qrFrontSideData?.biz_link_button_shadow_color ? `${qrFrontSideData.biz_link_button_shadow_color} 4px 4px 0px 0px` : "#ffffff 4px 4px 0px 0px" : 'none',
                                        lineHeight: 1.5,
                                        textAlign: 'left',
                                      }}
                                    >
                                      <div className="d-flex justify-content-between flex-column">
                                        <div className="px-2 m-0 whitespace-pre-line" style={{ fontSize: qrFrontSideData?.biz_link_button_font_size?.primary ? `${qrFrontSideData.biz_link_button_font_size.primary - 2}px` : '14px', fontWeight: 600, color: 'inherit' }}>
                                          Opening Hour
                                        </div>
                                        <div>
                                          {
                                            val?.workingTime?.map(data => {
                                              return (
                                                <div className="d-flex justify-content-between" style={{ gap: "10px" }}>
                                                  <div className="px-2 m-0 whitespace-pre-line" style={{ fontSize: qrFrontSideData?.biz_link_button_font_size?.secondary ? `${qrFrontSideData.biz_link_button_font_size.secondary - 2}px` : '12px', fontWeight: 400, color: 'inherit' }}>{data.day_name}</div>
                                                  <div className="px-2 m-0 whitespace-pre-line" style={{ fontSize: qrFrontSideData?.biz_link_button_font_size?.secondary ? `${qrFrontSideData.biz_link_button_font_size.secondary - 2}px` : '12px', fontWeight: 400, color: 'inherit' }}>{data.openStore == false ? "Closed" : data.open_twentyfour_hour ? "Open 24 hour" : renderShifts(data.shift)}</div>
                                                </div>
                                              )
                                            })
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </span >
                                )
                              } else if (val.btnType === "Music") {
                                return (
                                  <span
                                    key={index}
                                    className="spn col-12"
                                    style={{ textIndent: "0", gridColumn: 'span 12' }}
                                  >
                                    <div
                                      // id={qrBtnVal.id}
                                      className={`d-flex flex-column justify-content-center overflow-hidden`}
                                      style={{
                                        margin: "8px 0",
                                        borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "30px" : "10px",
                                        boxSizing: "border-box",
                                        position: "relative",
                                        zIndex: 7,
                                        height: "max-content",
                                        opacity: 1,
                                        cursor: "pointer",
                                        border: qrFrontSideData?.biz_link_button_border && `2px solid ${qrFrontSideData?.biz_link_button_border_color}`,
                                        boxShadow: qrFrontSideData?.biz_link_button_shadow ? qrFrontSideData?.biz_link_button_shadow_color ? `${qrFrontSideData.biz_link_button_shadow_color} 4px 4px 0px 0px` : "#ffffff 4px 4px 0px 0px" : 'none',
                                        lineHeight: 1.5,
                                      }}
                                    >
                                      {
                                        val.url && val.store.value === "Spotify" ?
                                          <div className={`rounded button_effect d-flex flex-column overflow-hidden`}>
                                            <iframe style={{ borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "15px" : "10px" }} src={`https://open.spotify.com/embed/${getSpotifyURL(val.url)}?utm_source=generator`} width="100%" height={val.url.includes("track") ? "232" : "152"} frameBorder="0" allowFullScreen allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                                          </div>
                                          : val.url && val.store.value === "SoundCloud" ?
                                            <div className={`rounded button_effect d-flex flex-column overflow-hidden`}>
                                              <iframe style={{ borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "15px" : "10px" }} width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src={`https://w.soundcloud.com/player/?url=${val.url}`}></iframe>
                                            </div>
                                            : val.url && val.store.value === "Apple Music" ?
                                              <div className={`rounded button_effect d-flex flex-column overflow-hidden`}>
                                                <iframe scrolling="no" allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write" frameBorder="0" style={{ width: "100%", maxWidth: "660px", overflow: "hidden", borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "15px" : "10px" }} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src={`https://embed.music.apple.com/${getAppleMusicURL(val.url)}`}></iframe>
                                              </div>
                                              : val.url && val.store.value === "163" ?
                                                <div className={`rounded button_effect d-flex flex-column overflow-hidden`}>
                                                  <iframe frameborder="no" border="0" marginwidth="0" marginheight="0" height={86} src={`//music.163.com/outchain/player?type=2&id=${val.url}&auto=1&height=66`}></iframe>
                                                </div>
                                                : null
                                      }
                                    </div>
                                  </span >
                                )
                              } else if (val.btnType === "Portfolio") {
                                return (
                                  <span
                                    key={index}
                                    style={{ gridColumn: val?.displayType == "3" ? "span 6" : val?.displayType == "2" ? "span 12" : "span 12" }}
                                    className={`spn col-${qrFrontSideData?.visitor_button_props?.visitor_button_size?.size == "" ? "12" : qrFrontSideData?.visitor_button_props?.visitor_button_size?.size} `}
                                  >
                                    <div
                                      className={`justify-content-center overflow-hidden`}
                                      style={{
                                        padding: (val?.displayType == "1" && val?.imgs?.length <= 3 && !val?.title) || (val?.displayType == "2" && val?.imgs?.length <= 1 && !val?.title) || (val?.displayType == "3" && val?.imgs?.length <= 1 && !val?.title) ? "unset" : val?.displayType == "1" ? "11px 11px" : "11px 11px 0 11px",
                                        margin: "8px 0",
                                        borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "20px" : "10px",
                                        background: (val?.displayType == "1" && val?.imgs?.length <= 3 && !val?.title) || (val?.displayType == "2" && val?.imgs?.length <= 1 && !val?.title) || (val?.displayType == "3" && val?.imgs?.length <= 1 && !val?.title) ? "unset" : qrFrontSideData?.biz_link_button_bg_color ? rgbaHexCalculate(qrFrontSideData?.biz_link_button_bg_color) : rgbaHexCalculate('#FFFFFF'),
                                        boxSizing: "border-box",
                                        position: "relative",
                                        zIndex: 7,
                                        height: "max-content",
                                        minHeight: '68px',
                                        opacity: 1,
                                        cursor: "pointer",
                                        border: (val?.displayType == "1" && val?.imgs?.length <= 3 && !val?.title) || (val?.displayType == "2" && val?.imgs?.length <= 1 && !val?.title) || (val?.displayType == "3" && val?.imgs?.length <= 1 && !val?.title) ? "unset" : qrFrontSideData?.biz_link_button_border && `2px solid ${qrFrontSideData?.biz_link_button_border_color}`,
                                        boxShadow: (val?.displayType == "1" && val?.imgs?.length <= 3 && !val?.title) || (val?.displayType == "2" && val?.imgs?.length <= 1 && !val?.title) || (val?.displayType == "3" && val?.imgs?.length <= 1 && !val?.title) ? "unset" : qrFrontSideData?.biz_link_button_shadow ? qrFrontSideData?.biz_link_button_shadow_color ? `${qrFrontSideData.biz_link_button_shadow_color} 4px 4px 0px 0px` : "#ffffff 4px 4px 0px 0px" : 'none',
                                        lineHeight: 1.5
                                      }}
                                      onClick={() => {
                                        // setloader({ type: "donation", value: true })
                                        // router.push(`/collect-payments/${merchant_id}/${val._id}`)
                                        setStaffDetails(val);
                                        dispatch(changePageNumber(6));
                                      }}
                                    >
                                      {val?.displayType == "1" && val?.title && <div className="d-flex justify-content-between mb-3">
                                        <p className="m-0 whitespace-pre-line" style={{ fontSize: qrFrontSideData?.biz_link_button_font_size?.primary ? `${qrFrontSideData.biz_link_button_font_size.primary - 3}px` : '13px', fontWeight: 600, color: 'inherit', textAlign: 'left' }}> {val?.title} </p>
                                      </div>}
                                      <div className={`d-flex ${val?.imgs?.length > 2 ? "justify-content-between" : "justify-content-start"}`} style={{ gap: '8px' }}>
                                        {
                                          val?.displayType == "1" ? [...val.imgs].slice(0, 3).map(img => {
                                            if (img.name) {
                                              return <div className="" style={{ position: 'relative', overflow: 'hidden', borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "10px" : "5px", maxWidth: '30%' }}>
                                                <div className="d-flex align-items-center justify-content-center" style={{ color: img.imageType === "R" ? 'red' : 'green', position: 'absolute', borderRadius: '50%', top: '3px', right: '3px', background: 'white', zIndex: 5, width: "20px", height: "20px", textAlign: "center" }}>{img.imageType}</div>
                                                <img draggable={false}
                                                  className=""
                                                  style={{ width: '70px', height: "85px", aspectRatio: "1 / 1", borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "10px" : "5px", objectFit: 'cover', WebkitFilter: img.imageType === "R" && "blur(3px)" }}
                                                  src={`${img?.name?.includes("dropbox") ? img.name.replace('?', `?${timeStamp}&`) : client.url(`images/qr-img/portfolio/${img?.name}`)}`}
                                                />
                                                {
                                                  img.imageType !== "G" && <div style={{ position: "absolute", top: "30px", left: "20px" }}>
                                                    <FaEyeSlash style={{ color: "white", fontSize: "20px" }} />
                                                  </div>
                                                }
                                              </div>
                                            }
                                          }) : val?.displayType == "3" ? <div
                                            className="w-100"
                                            style={{
                                              position: 'relative', overflow: 'hidden',
                                              borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "30px" : "10px"
                                            }}
                                          >
                                            {/* <CarouselR
                                              autoPlay={true}
                                              interval={3000}
                                              infiniteLoop={true}
                                              showThumbs={false}
                                              showIndicators={false}
                                              showStatus={false}
                                              showArrows={[...val.imgs].length > 1 ? true : false}
                                              renderArrowPrev={(clickHandler, hasPrev) => {
                                                if (hasPrev) {
                                                  return (
                                                    <div style={{ top: 0, left: 0 }} className="px-2 h-100 d-flex align-items-center absolute">
                                                      <FaChevronLeft
                                                        style={{
                                                          borderRadius: "100%",
                                                          fontSize: "20px",
                                                          padding: "7px 7px 7px 4px",
                                                          background: "#d5d1d0",
                                                          color: "#fff",
                                                          zIndex: 10,
                                                          cursor: "pointer",
                                                          userSelect: "none",
                                                        }}
                                                        onClick={(e) => {
                                                          e.stopPropagation()
                                                          clickHandler()
                                                        }}
                                                      />
                                                    </div>
                                                  )
                                                } else {
                                                  return <></>
                                                }
                                              }}
                                              renderArrowNext={(clickHandler, hasNext) => {
                                                if (hasNext) {
                                                  return (
                                                    <div style={{ top: 0, right: 0 }} className="px-2 h-100 d-flex align-items-center absolute">
                                                      <FaChevronRight
                                                        style={{
                                                          borderRadius: "100%",
                                                          fontSize: "20px",
                                                          padding: "7px 4px 7px 7px",
                                                          background: "#d5d1d0",
                                                          color: "#fff",
                                                          zIndex: 10,
                                                          cursor: "pointer",
                                                          userSelect: "none",
                                                        }}
                                                        onClick={(e) => {
                                                          e.stopPropagation()
                                                          clickHandler()
                                                        }}
                                                      />
                                                    </div>
                                                  )
                                                } else {
                                                  return <></>
                                                }
                                              }}
                                            >
                                              {[...val.imgs].map((slide, index) => {
                                                return (
                                                  <div className="" style={{ position: 'relative', overflow: 'hidden', borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "10px" : "5px", width: "100%" }}>
                                                    <img draggable={false}
                                                      className=""
                                                      style={{ height: "120px", borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "10px" : "5px", objectFit: 'cover', WebkitFilter: slide.imageType === "R" && "blur(3px)" }}
                                                      src={`${slide?.name?.includes("dropbox") ? slide.name.replace('?', `?${timeStamp}&`) : client.url(`images/qr-img/portfolio/${slide?.name}`)}`}
                                                    />
                                                    {
                                                      slide.imageType !== "G" && <div style={{ position: "absolute", top: "45%", left: "45%" }}>
                                                        <FaEyeSlash style={{ color: "white", fontSize: "26px" }} />
                                                      </div>
                                                    }
                                                  </div>
                                                );
                                              })}
                                            </CarouselR> */}
                                          </div> : val?.displayType == "2" ? <div className="w-100">
                                            {/* <CarouselR
                                              autoPlay={true}
                                              interval={3000}
                                              infiniteLoop={true}
                                              showThumbs={false}
                                              showIndicators={false}
                                              showStatus={false}
                                              showArrows={[...val.imgs].length > 1 ? true : false}
                                              renderArrowPrev={(clickHandler, hasPrev) => {
                                                if (hasPrev) {
                                                  return (
                                                    <div style={{ top: 0, left: 0 }} className="px-2 h-100 d-flex align-items-center absolute">
                                                      <FaChevronLeft
                                                        style={{
                                                          borderRadius: "100%",
                                                          fontSize: "20px",
                                                          padding: "7px 7px 7px 4px",
                                                          background: "#d5d1d0",
                                                          color: "#fff",
                                                          zIndex: 10,
                                                          cursor: "pointer",
                                                          userSelect: "none",
                                                        }}
                                                        onClick={(e) => {
                                                          e.stopPropagation()
                                                          clickHandler()
                                                        }}
                                                      />
                                                    </div>
                                                  )
                                                } else {
                                                  return <></>
                                                }
                                              }}
                                              renderArrowNext={(clickHandler, hasNext) => {
                                                if (hasNext) {
                                                  return (
                                                    <div style={{ top: 0, right: 0 }} className="px-2 h-100 d-flex align-items-center absolute">
                                                      <FaChevronRight
                                                        style={{
                                                          borderRadius: "100%",
                                                          fontSize: "20px",
                                                          padding: "7px 4px 7px 7px",
                                                          background: "#d5d1d0",
                                                          color: "#fff",
                                                          zIndex: 10,
                                                          cursor: "pointer",
                                                          userSelect: "none",
                                                        }}
                                                        onClick={(e) => {
                                                          e.stopPropagation()
                                                          clickHandler()
                                                        }}
                                                      />
                                                    </div>
                                                  )
                                                } else {
                                                  return <></>
                                                }
                                              }}
                                            >
                                              {[...val.imgs].map((slide, index) => {
                                                return (
                                                  <div className="" style={{ position: 'relative', overflow: 'hidden', borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "10px" : "5px", width: "100%" }}>
                                                    <img draggable={false}
                                                      className=""
                                                      style={{ height: "110px", borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "10px" : "5px", objectFit: 'cover', WebkitFilter: slide.imageType === "R" && "blur(3px)" }}
                                                      src={`${slide?.name?.includes("dropbox") ? slide.name.replace('?', `?${timeStamp}&`) : client.url(`images/qr-img/portfolio/${slide?.name}`)}`}
                                                    />
                                                    {
                                                      slide.imageType !== "G" && <div style={{ position: "absolute", top: "45%", left: "45%" }}>
                                                        <FaEyeSlash style={{ color: "white", fontSize: "26px" }} />
                                                      </div>
                                                    }
                                                  </div>
                                                );
                                              })}
                                            </CarouselR> */}
                                          </div> : null
                                        }
                                      </div>
                                      {(val?.imgs?.length > 1 || val?.title) ? (val?.displayType == "2" || val?.displayType == "3") && <div className="d-flex justify-content-between" style={{ padding: "8px" }}>
                                        <p className="m-0 whitespace-pre-line" style={{ fontSize: qrFrontSideData?.biz_link_button_font_size?.primary ? `${qrFrontSideData.biz_link_button_font_size.primary - 3}px` : '13px', fontWeight: 600, color: 'inherit', textAlign: 'left' }}> {val?.title} </p>
                                      </div> : null}
                                    </div>
                                  </span>
                                )
                              } else if (val.btnType === "PortfolioVideo") {
                                return (
                                  
                                  <PreviewNotAvailable
                                    qrFrontSideData={qrFrontSideData}
                                    body={`${val?.title || "Portfolio Gallery"} Preview will not be visible here, kindly open your minisite link on browser to view your`}
                                    footer={`${val?.title || "Portfolio Gallery"}`}
                                  />
                                )
                              } else if (val.btnType === "SocialMedia") {
                                if (val?.largeIconBody == true) {
                                  return (
                                    <span
                                      // key={index}
                                      className="d-flex justify-content-center p-0 spn col-12"
                                      style={{ gap: '10px', gridColumn: "span 3" }}
                                    >
                                      <div
                                        // id={qrBtnVal.id}
                                        className={`d-flex w-100 flex-column justify-content-start overflow-hidden`}
                                        style={{
                                          margin: "8px 0",
                                          boxSizing: "border-box",
                                          position: "relative",
                                          zIndex: 7,
                                          width: "calc(25% - 10px)",
                                          height: "auto",
                                          opacity: 1,
                                          cursor: "pointer",
                                          lineHeight: 1.5,
                                          textAlign: 'center'
                                        }}
                                      >
                                        {
                                          val.image ? <div className="flex justify-center">
                                            <img draggable={false}
                                              style={{ aspectRatio: "1 / 1", width: "100%", maxWidth: '70px', borderRadius: "10px", objectFit: 'cover' }}
                                              src={val.image.includes("dropbox") ? val.image.replace('?', `?${timeStamp}&`) : client.url(`images/qr-img/social-media/${val.image}`)}
                                            /></div> :
                                            <div className="flex justify-center"><img draggable={false}
                                              style={{ aspectRatio: "1 / 1", width: "100%", maxWidth: '70px', borderRadius: "10px", objectFit: 'contain' }}
                                              src={defaultDataForSocialMedia(val.identifier)?.image}
                                            /></div>
                                        }
                                        {val?.title && <p className="mt-2" style={{ fontSize: qrFrontSideData?.biz_link_button_font_size?.primary == 15 ? "10px" : qrFrontSideData?.biz_link_button_font_size?.primary == 16 ? "11px" : qrFrontSideData?.biz_link_button_font_size?.primary == 18 ? '12px' : '11px', color: 'inherit', overflowWrap: 'break-word' }}>
                                          {val.title}
                                        </p>}
                                      </div>
                                    </span>
                                  )
                                } else {
                                  return null;
                                }
                              } else if (val.btnType === 'MinisiteQRCode') {
                                return (
                                  <span
                                    key={index}
                                    className={`d-flex justify-content-center`}
                                    style={{ gap: '10px', gridColumn: "span 4" }}
                                  >
                                    <div
                                      // id={qrBtnVal.id}
                                      className={`d-flex flex-column ${val?.title ? "justify-content-between" : "justify-content-center"} overflow-hidden`}
                                      style={{
                                        padding: '11px 11px 8px 11px',
                                        margin: "8px 0",
                                        borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "20px" : "10px",
                                        background: qrFrontSideData?.biz_link_button_bg_color ? rgbaHexCalculate(qrFrontSideData?.biz_link_button_bg_color) : rgbaHexCalculate('#FFFFFF'),
                                        boxSizing: "border-box",
                                        position: "relative",
                                        zIndex: 7,
                                        width: '100%',
                                        minHeight: '170px',
                                        height: "auto",
                                        opacity: 1,
                                        cursor: "pointer",
                                        border: qrFrontSideData?.biz_link_button_border && `2px solid ${qrFrontSideData?.biz_link_button_border_color}`,
                                        boxShadow: qrFrontSideData?.biz_link_button_shadow ? qrFrontSideData?.biz_link_button_shadow_color ? `${qrFrontSideData.biz_link_button_shadow_color} 4px 4px 0px 0px` : "#ffffff 4px 4px 0px 0px" : 'none',
                                        lineHeight: 1.5,
                                        textAlign: 'center'
                                      }}
                                    >
                                      {val?.title && <p className="m-0 whitespace-pre-line" style={{ visibility: "hidden", fontSize: qrFrontSideData?.biz_link_button_font_size?.primary ? `${qrFrontSideData.biz_link_button_font_size.primary - 3}px` : '13px', fontWeight: 600, color: 'inherit' }}>
                                        {val.title}
                                      </p>}
                                      <div className="flex justify-center">
                                        {/* {merchantData && <QRDisplayComp merchantData={merchantData} qrSize={"80px"} />} */}
                                      </div>
                                      {val?.title && <p className="m-0 whitespace-pre-line" style={{ fontSize: qrFrontSideData?.biz_link_button_font_size?.primary ? `${qrFrontSideData.biz_link_button_font_size.primary - 3}px` : '13px', fontWeight: 600, color: 'inherit' }}>
                                        {val.title}
                                      </p>}
                                    </div>
                                  </span>
                                )
                              } else if (val.btnType === "ContactForm") {
                                return (
                                  val.displayType == "1" || val.displayType == "2" ?
                                    <span
                                      key={index}
                                      className={`p-0 d-flex justify-content-center`}
                                      style={{ gridColumn: val.displayType == "1" ? "span 6" : "span 12" }}
                                    >
                                      <div
                                        // id={qrBtnVal.id}
                                        className={`w-100 overflow-hidden d-flex flex-column text-left`}
                                        style={{
                                          padding: qrFrontSideData?.biz_link_button_type === "round" ? '11px 11px 11px 18px' : '11px 11px',
                                          margin: "8px 0",
                                          borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "30px" : "10px",
                                          background: qrFrontSideData?.biz_link_button_bg_color ? rgbaHexCalculate(qrFrontSideData?.biz_link_button_bg_color) : rgbaHexCalculate('#FFFFFF'),
                                          boxSizing: "border-box",
                                          position: "relative",
                                          zIndex: 7,
                                          height: "max-content",
                                          minHeight: '68px',
                                          opacity: 1,
                                          cursor: "pointer",
                                          border: qrFrontSideData?.biz_link_button_border && `2px solid ${qrFrontSideData?.biz_link_button_border_color}`,
                                          boxShadow: qrFrontSideData?.biz_link_button_shadow ? qrFrontSideData?.biz_link_button_shadow_color ? `${qrFrontSideData.biz_link_button_shadow_color} 4px 4px 0px 0px` : "#ffffff 4px 4px 0px 0px" : 'none',
                                          lineHeight: 1.5,
                                          textAlign: val.alignment
                                        }}
                                      >
                                        <div className="d-flex flex-column" style={{ gap: "30px" }}>
                                          <div className="d-flex flex-column">
                                            {/* <p className="p-0 m-0 whitespace-pre-line font-weight-bold biz_select_design_title" style={{ fontSize: qrFrontSideData?.biz_link_button_font_size?.primary ? `${Number(qrFrontSideData.biz_link_button_font_size.primary) + 10}px` : '26px', fontWeight: 600, color: qrFrontSideData?.biz_link_button_font_color ? qrFrontSideData.biz_link_button_font_color : '#ffffff', }}>{val?.title}</p>
                                          <p className="p-0 m-0 whitespace-pre-line biz_select_design_description" style={{ fontSize: qrFrontSideData?.biz_link_button_font_size?.secondary ? `${qrFrontSideData.biz_link_button_font_size.secondary}px` : '15px', fontWeight: 400, color: qrFrontSideData?.biz_link_button_font_color ? qrFrontSideData.biz_link_button_font_color : '#ffffff', }}>{val?.subtitle}</p> */}
                                            {val?.title && <p className="m-0 whitespace-pre-line" style={{ textAlign: "left", fontSize: qrFrontSideData?.biz_link_button_font_size?.primary ? `${qrFrontSideData.biz_link_button_font_size.primary - 3}px` : '13px', fontWeight: 600, color: 'inherit', padding: val?.fullImage == true ? "0 11px 0 11px" : "unset" }}>
                                              {val.title}
                                            </p>}
                                            {val?.subtitle && <p className="m-0 whitespace-pre-line" style={{ textAlign: "left", fontSize: qrFrontSideData?.biz_link_button_font_size?.secondary ? `${qrFrontSideData.biz_link_button_font_size.secondary - 2}px` : '12px', fontWeight: 400, color: 'inherit', padding: val?.fullImage == true ? "0 11px 8px 11px" : "unset", display: "-webkit-box", WebkitLineClamp: 3, WebkitBoxOrient: "vertical", overflow: "hidden" }}>
                                              {val.subtitle}
                                            </p>}
                                          </div>
                                          <div className="text-center" style={{ background: "#ffffff", color: "black", borderRadius: "15px", padding: "8px 0" }}>
                                            Start
                                          </div>
                                        </div>
                                      </div>
                                    </span> : null
                                )
                              } else if (val.btnType === "Maps") {
                                return (
                                  val.displayType == "1" ?
                                    <span
                                      key={index}
                                      className="p-0 d-flex justify-content-center"
                                      style={{ gridColumn: "span 6" }}
                                    >
                                      <div
                                        // id={qrBtnVal.id}
                                        className={`w-100 overflow-hidden d-flex flex-column justify-content-center`}
                                        style={{
                                          // padding: qrFrontSideData?.biz_link_button_type === "round" ? '11px 11px 11px 18px' : '11px 22px',
                                          margin: "8px 0",
                                          borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "25px" : "10px",
                                          // background: qrFrontSideData?.biz_link_button_bg_color ? rgbaHexCalculate(qrFrontSideData?.biz_link_button_bg_color) : rgbaHexCalculate('#FFFFFF'),
                                          boxSizing: "border-box",
                                          position: "relative",
                                          height: "110px",
                                          zIndex: 7,
                                          minHeight: '68px',
                                          opacity: 1,
                                          cursor: "pointer",
                                          // border: qrFrontSideData?.biz_link_button_border && `2px solid ${qrFrontSideData?.biz_link_button_border_color}`,
                                          // boxShadow: qrFrontSideData?.biz_link_button_shadow ? qrFrontSideData?.biz_link_button_shadow_color ? `${qrFrontSideData.biz_link_button_shadow_color} 4px 4px 0px 0px` : "#ffffff 4px 4px 0px 0px" : 'none',
                                          lineHeight: 1.5,
                                          textAlign: val.alignment
                                        }}
                                      >
                                        <img draggable={false} style={{ objectFit: "cover", height: "100%" }} src={`https://api.mapbox.com/styles/v1/matthewblode/clix8w21k00ux01qi5r472wau/static/${val.lng},${val.lat},14,0/296x220@2x?access_token=pk.eyJ1IjoibWF0dGhld2Jsb2RlIiwiYSI6ImNsaXg4dXZnODA0c3Uzc2wyNmNncTlibzkifQ.s4oJ2Ha9_yYNFf7vGOXvXg&logo=false&attribution=false`} />
                                      </div>
                                    </span> : val.displayType == "2" ?
                                      <span
                                        key={index}
                                        className="w-100 p-0 d-flex justify-content-center"
                                        style={{ gridColumn: "span 12" }}
                                      >
                                        <div
                                          // id={qrBtnVal.id}
                                          className={`w-100 overflow-hidden d-flex flex-column justify-content-center`}
                                          style={{
                                            // padding: qrFrontSideData?.biz_link_button_type === "round" ? '11px 11px 11px 18px' : '11px 22px',
                                            margin: "8px 0",
                                            borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "25px" : "10px",
                                            // background: qrFrontSideData?.biz_link_button_bg_color ? rgbaHexCalculate(qrFrontSideData?.biz_link_button_bg_color) : rgbaHexCalculate('#FFFFFF'),
                                            boxSizing: "border-box",
                                            position: "relative",
                                            zIndex: 7,
                                            opacity: 1,
                                            cursor: "pointer",
                                            // border: qrFrontSideData?.biz_link_button_border && `2px solid ${qrFrontSideData?.biz_link_button_border_color}`,
                                            // boxShadow: qrFrontSideData?.biz_link_button_shadow ? qrFrontSideData?.biz_link_button_shadow_color ? `${qrFrontSideData.biz_link_button_shadow_color} 4px 4px 0px 0px` : "#ffffff 4px 4px 0px 0px" : 'none',
                                            lineHeight: 1.5,
                                            textAlign: val.alignment
                                          }}
                                        >
                                          <img draggable={false} style={{ height: "140px", objectFit: "cover", width: "100%" }} src={`https://api.mapbox.com/styles/v1/matthewblode/clix8w21k00ux01qi5r472wau/static/${val.lng},${val.lat},14,0/296x220@2x?access_token=pk.eyJ1IjoibWF0dGhld2Jsb2RlIiwiYSI6ImNsaXg4dXZnODA0c3Uzc2wyNmNncTlibzkifQ.s4oJ2Ha9_yYNFf7vGOXvXg&logo=false&attribution=false`} />
                                        </div>
                                      </span> : val.displayType == "3" ?
                                        <span
                                          key={index}
                                          className="p-0 d-flex justify-content-center"
                                          style={{ gridColumn: "span 12" }}
                                        >
                                          <div
                                            // id={qrBtnVal.id}
                                            className={`w-100 overflow-hidden d-flex flex-column justify-content-between`}
                                            style={{
                                              // padding: qrFrontSideData?.biz_link_button_type === "round" ? '11px 11px 11px 18px' : '11px',
                                              margin: "8px 0",
                                              borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "25px" : "10px",
                                              // background: qrFrontSideData?.biz_link_button_bg_color ? rgbaHexCalculate(qrFrontSideData?.biz_link_button_bg_color) : rgbaHexCalculate('#FFFFFF'),
                                              boxSizing: "border-box",
                                              position: "relative",
                                              zIndex: 7,
                                              height: '200px',
                                              opacity: 1,
                                              cursor: "pointer",
                                              // border: qrFrontSideData?.biz_link_button_border && `2px solid ${qrFrontSideData?.biz_link_button_border_color}`,
                                              // boxShadow: qrFrontSideData?.biz_link_button_shadow ? qrFrontSideData?.biz_link_button_shadow_color ? `${qrFrontSideData.biz_link_button_shadow_color} 4px 4px 0px 0px` : "#ffffff 4px 4px 0px 0px" : 'none',
                                              lineHeight: 1.5,
                                              textAlign: val.alignment
                                            }}
                                          >
                                            <div className="d-flex justify-content-end">
                                              <img draggable={false} style={{ height: "200px", objectFit: "cover" }} src={`https://api.mapbox.com/styles/v1/matthewblode/clix8w21k00ux01qi5r472wau/static/${val.lng},${val.lat},14,0/296x220@2x?access_token=pk.eyJ1IjoibWF0dGhld2Jsb2RlIiwiYSI6ImNsaXg4dXZnODA0c3Uzc2wyNmNncTlibzkifQ.s4oJ2Ha9_yYNFf7vGOXvXg&logo=false&attribution=false`} />
                                            </div>
                                          </div>
                                        </span> : null
                                )
                              } else if (val.btnType === "Document") {
                                return (
                                  <span
                                    key={index}
                                    className={`p-0`}
                                    style={{ gridColumn: val.displayType == "2" ? "span 6" : "span 12" }}
                                  >
                                    <div
                                      // id={qrBtnVal.id}
                                      className={`overflow-hidden d-flex flex-column justify-content-center`}
                                      style={{
                                        padding: qrFrontSideData?.biz_link_button_type === "round" ? '11px 11px 11px 18px' : '11px',
                                        margin: "8px 0",
                                        minHeight: val.displayType == "2" && "110px",
                                        borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "30px" : "10px",
                                        background: qrFrontSideData?.biz_link_button_bg_color ? rgbaHexCalculate(qrFrontSideData?.biz_link_button_bg_color) : rgbaHexCalculate('#FFFFFF'),
                                        boxSizing: "border-box",
                                        position: "relative",
                                        zIndex: 7,
                                        opacity: 1,
                                        cursor: "pointer",
                                        border: qrFrontSideData?.biz_link_button_border && `2px solid ${qrFrontSideData?.biz_link_button_border_color}`,
                                        boxShadow: qrFrontSideData?.biz_link_button_shadow ? qrFrontSideData?.biz_link_button_shadow_color ? `${qrFrontSideData.biz_link_button_shadow_color} 4px 4px 0px 0px` : "#ffffff 4px 4px 0px 0px" : 'none',
                                        lineHeight: 1.5,
                                        textAlign: val.alignment
                                      }}
                                    >
                                      {
                                        val.displayType == "1" ? <div className="d-flex align-items-center justify-content-between" style={{ gap: "5px" }}>
                                          <div>
                                            {val?.title && <p className="m-0 whitespace-pre-line" style={{ textAlign: "left", fontSize: qrFrontSideData?.biz_link_button_font_size?.primary ? `${qrFrontSideData.biz_link_button_font_size.primary - 3}px` : '13px', fontWeight: 600, color: 'inherit', padding: val?.fullImage == true ? "0 11px 0 11px" : "unset" }}>
                                              {val.title}
                                            </p>}
                                            {val?.subtitle && <p className="m-0 whitespace-pre-line" style={{ textAlign: "left", fontSize: qrFrontSideData?.biz_link_button_font_size?.secondary ? `${qrFrontSideData.biz_link_button_font_size.secondary - 2}px` : '12px', fontWeight: 400, color: 'inherit', padding: val?.fullImage == true ? "0 11px 8px 11px" : "unset", display: "-webkit-box", WebkitLineClamp: 3, WebkitBoxOrient: "vertical", overflow: "hidden" }}>
                                              {val.subtitle}
                                            </p>}
                                          </div>
                                          <div className="text-center d-flex align-items-center justify-content-center px-4" style={{ background: "rgb(255, 255, 255)", color: "black", borderRadius: "15px", padding: "8px 0px" }}>
                                            View <FiArrowUpRight />
                                          </div>
                                        </div> : val.displayType == "2" ? <div className="d-flex flex-column align-items-left justify-content-between" style={{ gap: "30px" }}>
                                          <div>
                                            {val?.title && <p className="m-0 whitespace-pre-line" style={{ textAlign: "left", fontSize: qrFrontSideData?.biz_link_button_font_size?.primary ? `${qrFrontSideData.biz_link_button_font_size.primary - 3}px` : '13px', fontWeight: 600, color: 'inherit', padding: val?.fullImage == true ? "0 11px 0 11px" : "unset" }}>
                                              {val.title}
                                            </p>}
                                            {val?.subtitle && <p className="m-0 whitespace-pre-line" style={{ textAlign: "left", fontSize: qrFrontSideData?.biz_link_button_font_size?.secondary ? `${qrFrontSideData.biz_link_button_font_size.secondary - 2}px` : '12px', fontWeight: 400, color: 'inherit', padding: val?.fullImage == true ? "0 11px 8px 11px" : "unset", display: "-webkit-box", WebkitLineClamp: 2, WebkitBoxOrient: "vertical", overflow: "hidden" }}>
                                              {val.subtitle}
                                            </p>}
                                          </div>
                                          <div className="text-center d-flex align-items-center justify-content-center" style={{ background: "rgb(255, 255, 255)", color: "black", borderRadius: "15px", padding: "4px 0px" }}>
                                            View <FiArrowUpRight />
                                          </div>
                                        </div> : <div className="d-flex flex-column align-items-left justify-content-between" style={{ gap: "30px" }}>
                                          <div>
                                            {val?.title && <p className="m-0 whitespace-pre-line" style={{ textAlign: "left", fontSize: qrFrontSideData?.biz_link_button_font_size?.primary ? `${qrFrontSideData.biz_link_button_font_size.primary - 3}px` : '13px', fontWeight: 600, color: 'inherit', padding: val?.fullImage == true ? "0 11px 0 11px" : "unset" }}>
                                              {val.title}
                                            </p>}
                                            {val?.subtitle && <p className="m-0 whitespace-pre-line" style={{ textAlign: "left", fontSize: qrFrontSideData?.biz_link_button_font_size?.secondary ? `${qrFrontSideData.biz_link_button_font_size.secondary - 2}px` : '12px', fontWeight: 400, color: 'inherit', padding: val?.fullImage == true ? "0 11px 8px 11px" : "unset", display: "-webkit-box", WebkitLineClamp: 3, WebkitBoxOrient: "vertical", overflow: "hidden" }}>
                                              {val.subtitle}
                                            </p>}
                                          </div>
                                          <div className="text-center d-flex align-items-center justify-content-center" style={{ background: "rgb(255, 255, 255)", color: "black", borderRadius: "15px", padding: "8px 0px" }}>
                                            View <FiArrowUpRight />
                                          </div>
                                        </div>
                                      }
                                    </div>
                                  </span>
                                )
                              }
                            })
                          }
                        </div>

                        <div className='d-flex flex-wrap justify-content-center w-100 mt-3'>
                          {
                            qrFrontSideData?.minisite_social_media?.filter(e => e?.smallIconFooter == true && e?.publish == true).map((icon) => {
                              let iconURL = array_of_social_handles.find((val => val.name === icon.identifier || val.altName === icon.identifier))
                              return (
                                <>
                                  {
                                    iconURL ?
                                      <div
                                        className="d-flex align-items-center justify-content-center m-1"
                                        style={{
                                          padding: '4px',
                                          borderRadius: qrFrontSideData?.biz_link_button_type === "square" ? "0px" : qrFrontSideData?.biz_link_button_type === "round" ? "30px" : "10px",
                                          background: qrFrontSideData?.biz_link_button_bg_color ? rgbaHexCalculate(qrFrontSideData?.biz_link_button_bg_color) : rgbaHexCalculate('#FFFFFF'),
                                          color: qrFrontSideData?.biz_link_button_font_color ? qrFrontSideData.biz_link_button_font_color : '#ffffff',
                                          cursor: 'pointer',
                                          height: "25px",
                                          width: "25px",
                                        }}
                                      >{iconURL?.URL}</div> : null
                                  }
                                </>
                              )
                            })
                          }
                        </div>
                      </div>


                      {
                        !qrFrontSideData?.enable_ipaylogo ?
                          <div
                            className="text-center  px-2 pt-3 pb-5"
                            style={{
                              color: qrFrontSideData?.biz_link_header_font_color ? qrFrontSideData.biz_link_header_font_color : '#ffffff',
                            }}
                          >
                            <div
                              className="rounded"
                              style={{ marginBottom: getPortalId() == 3 ? '-15px' : getPortalId() == 2 ? '-7px' : '0px', cursor: "pointer" }}
                              onClick={() => { redirectFunc(); }}
                            >
                              {

                                getPortalId() == 1 ?
                                  <IPayProSVG
                                    fill={qrFrontSideData?.biz_link_header_font_color ? qrFrontSideData.biz_link_header_font_color : '#ffffff'}
                                    style={{ width: "auto", height: "3rem" }}
                                  />
                                  : getPortalId() == 2 ?
                                    <LinkyLogoSVG
                                      fill={qrFrontSideData?.biz_link_header_font_color ? qrFrontSideData.biz_link_header_font_color : '#ffffff'}
                                      style={{ width: "auto", height: "3rem" }}
                                    />
                                    : getPortalId() == 3 ?
                                      <HustlerzLogoSVG
                                        fill={qrFrontSideData?.biz_link_header_font_color ? qrFrontSideData.biz_link_header_font_color : '#ffffff'}
                                        style={{ width: "auto", height: "3rem" }}
                                      />
                                      : <IPayProSVG
                                        fill={qrFrontSideData?.biz_link_header_font_color ? qrFrontSideData.biz_link_header_font_color : '#ffffff'}
                                        style={{ width: "auto", height: "3rem" }}
                                      />
                              }
                            </div>
                            <p
                              style={{
                                cursor: "pointer",
                                color: 'inherit',
                                // fontSize: qrFrontSideData?.biz_link_header_font_size?.secondary ? `${qrFrontSideData.biz_link_header_font_size.secondary - 2}px` : '14px'
                                fontSize: '12px'
                              }}
                              onClick={() => { redirectFunc() }}
                            >{`Get your ${getPortalId() == 2 ? "Linky" : "Link"} for free`}</p>
                          </div> : null
                      }

                      {
                        showSharePopUp && <>
                          <div
                            style={{
                              height: "auto",
                              width: "95%",
                              position: "absolute",
                              top: "8%",
                              left: "2%",
                              background: "#ffff",
                              zIndex: "1001",
                              borderRadius: "5px"
                            }}
                            className="border p-3"
                          >
                            <div className="w-100 position-relative">
                              <div className="" style={{ cursor: "pointer", position: "absolute", top: "-9px", right: "-10px" }} onClick={() => { toggleSharePopUp() }} >
                                <MdOutlineClose style={{ color: "#000000", width: '20px', height: '20px' }} />
                              </div>
                              <p className="mb-3 mt-0 text-left" style={{ fontSize: "15px", fontWeight: "600", color: "#000000" }}>Share</p>
                              <div className="d-flex align-items-center justify-content-between my-2">
                                <div className="d-flex align-item-center">
                                  <TfiWorld style={{ color: "#000000", width: '17px', height: '17px', marginTop: "2px" }} />
                                  <p className="ml-2 mt-0 text-[14px] text-[#000000]">{getShareURL && getShareURL.substring(0, 15)}...</p>
                                </div>
                                <div className="flex items-center">
                                  <FiLink2 style={{ color: "#0D6EFD", width: '13px', height: '13px' }} />
                                  <div
                                    id="linkcopiedtooltip"
                                    className="text-[14px] ml-2 mt-0"
                                    style={{ color: "#0D6EFD", cursor: "pointer" }}
                                    onClick={() => {
                                      navigator.clipboard.writeText(getShareURL);
                                      setTooltipOpen(true);
                                    }}
                                  >Copy link</div>
                                  {tooltipOpen && <Tooltip
                                    isOpen={tooltipOpen}
                                    target="linkcopiedtooltip"
                                    toggle={toggleToolTip}
                                  >
                                    Copied!
                                  </Tooltip>}
                                </div>
                              </div>
                              <hr className="m-0" />

                              <div className="my-2 shareLinkToSocialMedia">
                                <p className="my-3 mt-[1.3rem] text-left" style={{ fontSize: "14px", fontWeight: "500", color: "#000000" }}>Share with others</p>
                                <Row className="" style={{ margin: "0px" }}>
                                  {shareLinkData.map((item) => {
                                    return (<>
                                      <Col xs={4} sm={4} md={4} className="mb-3 mt-2 px-2">
                                        <div
                                          className="d-flex flex-column items-center"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            redirectToSocialMediaPage(item)
                                          }}
                                        >
                                          <img draggable={false} style={{ height: item.value == "more_option" ? "24px" : "25px", width: item.value == "more_option" ? "23px" : "25px" }} src={item.icon} alt={item.name} />
                                          <div className="text-[12px] text-center mt-1 socialMediaName" style={{ color: "rgb(43,43,43)" }}>{item.name}</div>
                                        </div>
                                      </Col>
                                    </>)
                                  })}
                                </Row>
                              </div>

                              <hr className="m-0" />

                              <div className="mb-2">
                                <p className="my-3 text-left" style={{ fontSize: "14px", fontWeight: "500", color: "#000000" }}>Create your own linky</p>
                                <div className=" w-100 flex items-center justify-between">
                                  <div
                                    className="border bg-white rounded-pill cursor-pointer flex items-center justify-center"
                                    style={{ height: "40px", width: "47%" }}
                                    onClick={() => {

                                      let getHostName = window.location.origin;
                                      let getRedirectURl = '';

                                      if (getHostName == "https://dm.portal.ipaypro.co") {
                                        getRedirectURl = 'https://dm.ipaypro.co';
                                      } else if (getHostName == "https://portal.ipaypro.co") {
                                        getRedirectURl = 'https://ipaypro.co';
                                      } else if (getHostName == "https://dm.portal.linky.biz") {
                                        getRedirectURl = 'https://dm.linky.biz';
                                      } else if (getHostName == "https://portal.linky.biz") {
                                        getRedirectURl = 'https://linky.biz';
                                      } else {
                                        getRedirectURl = 'https://dm.ipaypro.co'
                                      }

                                      window.open(getRedirectURl, '_blank');
                                    }}
                                  >
                                    <p style={{ fontSize: "13px", fontWeight: "500", color: "#000000" }}>Find out more</p>
                                  </div>
                                  <NavLink
                                    href='/sign-up'
                                    className="flex items-center justify-center border rounded-pill cursor-pointer"
                                    style={{ backgroundColor: "#000000", height: "40px", width: "49%" }}
                                    target={'_blank'}>
                                    <p className="text-white" style={{ fontSize: "13px", fontWeight: "500" }}>Sign up free</p>
                                  </NavLink>
                                </div>
                              </div>

                            </div>
                          </div>
                        </>
                      }

                    </div>
                  ) : null
                }
              </div>

            </div>
          </div>


        </>}
    </div >
  );
};

export default reduxForm({
  form: "PhoneViewPageForm",
  initialized: true,
  destroyOnUnmount: false,
  // validate,
})(PhoneViewPage);
