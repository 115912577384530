
import React, { useEffect, Fragment, useState, useRef } from "react";
import Breadcrumb from '../../components/common/breadcrumb';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { useHistory } from "react-router-dom";
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Table,
    Progress
} from "reactstrap";
import noImageIcon from '../../assets/images/no-image-icon.png'
import axios from 'axios';
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import ImageUploadingLoader from '../../components/ImageUploadingLoader/ImageUploadingLoader'


const ContentPages = () => {

    const history = useHistory();
    const [fetchLoading, setFetchLoading] = useState(false);
    const [allLanguages, setAllLanguages] = useState([]);
    const [selectedLang, setSelectedLang] = useState('english');
    const [carryImageData, setCarryImageData] = useState(null);
    const [contentPageData, setContentPageData] = useState([])
    const [imageNum, setImageNum] = useState(null)

    const fileInputRef = useRef(null);

    const fetchContentImages = (formData = {}) => {

        const config = { headers: { "x-access-token": "ipay-access-token" } }

        setFetchLoading(true)
        axios.put(client.url(api.get_content_page_data), formData, config).then(response => {
            if (response) {
                if (response.data) {
                    if (!response.data.error) {
                        setAllLanguages(response.data.languages)
                        setContentPageData(response.data.data)
                        setFetchLoading(false)
                    }
                    else {
                        setFetchLoading(false)
                        toast.error(response.data.message);
                    }
                }
                else {
                    setFetchLoading(false)
                    toast.error('Internal error!');
                }
            }
            else {
                setFetchLoading(false)
                toast.error('Internal error!');
            }
        }).catch(error => {
            setFetchLoading(false)
            toast.error(error);
        })

        if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    }

    const imageMapData = () => {
        let imageData = [];
        if (
            contentPageData[carryImageData.folderNum].pages
            && contentPageData[carryImageData.folderNum]?.pages[carryImageData.pageNum].categories
            && contentPageData[carryImageData.folderNum]?.pages[carryImageData.pageNum]?.categories[carryImageData.categoriesNum]?.images
        ) {
            imageData = contentPageData[carryImageData.folderNum]?.pages[carryImageData.pageNum]?.categories[carryImageData.categoriesNum]?.images;
        }
        else if (contentPageData[carryImageData.folderNum]?.pages && contentPageData[carryImageData.folderNum]?.pages[carryImageData.pageNum]?.images) {
            imageData = contentPageData[carryImageData.folderNum]?.pages[carryImageData.pageNum]?.images;
        }

        return imageData
    }

    useEffect(() => {
        if (!contentPageData.length) {
            fetchContentImages()
        }
    }, [])

    return (
        <Fragment>
            {
                fetchLoading && contentPageData.length ?
                    <ImageUploadingLoader customView={
                        <div>
                            <div className="border-bottom px-4 py-3">
                                <h4 className="m-0"> <b style={{ color: 'black' }}> SAVING CHANGE(S) </b> </h4>
                            </div>

                            <div className="px-4 py-4">
                                <h5 className="mx-0 mt-0 mb-2" style={{ color: '#3E3E3E' }}> Please do not leave this page or your changes will not get saved. </h5>
                                <Progress animated color="warning" value={100} />
                            </div>
                        </div>
                    } />
                    : null
            }

            <>
                <div className="row">
                    <div className="col-xl-auto col-sm-12" style={{ fontSize: "12px" }}>
                        <Breadcrumb title="Content Pages" parent="Home" />
                    </div>
                </div>

                <div className="container-fluid">
                    {
                        carryImageData ?
                            <div className="row">
                                <div className="col-sm-12 col-xl-12 xl-100">
                                    <div className="container-fluid">
                                        <div className="card">
                                            <div className="card-body" style={{ padding: "1rem", cursor: 'default' }}>
                                                <p
                                                    className="mb-2 text-primary"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => setCarryImageData(null)}
                                                > {`<- ${contentPageData[carryImageData.folderNum].folderName} < ${contentPageData[carryImageData.folderNum].pages[carryImageData.pageNum].pageName} < ${contentPageData[carryImageData.folderNum].pages[carryImageData.pageNum].categories?.length === undefined?'': contentPageData[carryImageData.folderNum].pages[carryImageData.pageNum].categories[carryImageData.categoriesNum].categoriesName}`} </p>
                                                <Table>
                                                    <thead>
                                                        <tr className="bg-light">
                                                            <th className="border"> <div className="p-1"> Section </div> </th>
                                                            <th className="border"> <div className="p-1"> Image </div> </th>
                                                            <th className="border"> <div className="p-1"> Image URL </div> </th>
                                                            <th className="border"> <div className="p-1"> Action </div> </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            imageMapData().map((imgVal, imgInd) => {
                                                                return (
                                                                    <tr key={imgInd}>
                                                                        <td className="border"> <div className="p-1"> {imgInd + 1} Section </div> </td>
                                                                        <td className="border"> <div className="p-1">
                                                                            <div>
                                                                                <img
                                                                                    src={
                                                                                        imgVal[selectedLang] ?
                                                                                            contentPageData[carryImageData.folderNum].pages && contentPageData[carryImageData.folderNum].pages[carryImageData.pageNum].categories
                                                                                                ? client.url(`${contentPageData[carryImageData.folderNum].pages[carryImageData.pageNum].categories[carryImageData.categoriesNum].images[imgInd][selectedLang]}?${Date.now()}`)
                                                                                                : contentPageData[carryImageData.folderNum].pages
                                                                                                    ? client.url(`${contentPageData[carryImageData.folderNum].pages[carryImageData.pageNum].images[imgInd][selectedLang]}?${Date.now()}`)
                                                                                                    : noImageIcon
                                                                                            : noImageIcon}
                                                                                    style={{
                                                                                        height: '50px',
                                                                                        cursor: imgVal[selectedLang] ? 'default' : 'pointer'
                                                                                    }}
                                                                                    // onError={({ currentTarget }) => {
                                                                                    //     currentTarget.src = noImageIcon;
                                                                                    // }}
                                                                                    onClick={evnt => {
                                                                                        if (!imgVal[selectedLang]) {
                                                                                            setImageNum(imgInd)
                                                                                            fileInputRef.current.click()
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div> </td>
                                                                        <td className="border"> <div className="p-1">
                                                                            {
                                                                                imgVal[selectedLang] ?
                                                                                    <a href={client.url(imgVal[selectedLang])} target={'_blank'}> {client.url(imgVal[selectedLang])} </a>
                                                                                    : '-'
                                                                            }
                                                                        </div> </td>
                                                                        <td className="border text-center">
                                                                            {
                                                                                imgVal[selectedLang] ?
                                                                                    <div
                                                                                        className="p-1"
                                                                                        style={{ fontSize: "20px", color: "red", cursor: 'pointer' }}
                                                                                        onClick={() => {
                                                                                            const folderName = contentPageData[carryImageData.folderNum].folderName;
                                                                                            const pageName = contentPageData[carryImageData.folderNum].pages[carryImageData.pageNum].pageName;
                                                                                            let imageUrl = `${folderName}_${pageName}`;

                                                                                            if (
                                                                                                contentPageData[carryImageData.folderNum].pages
                                                                                                && contentPageData[carryImageData.folderNum].pages[carryImageData.pageNum].categories
                                                                                                && contentPageData[carryImageData.folderNum].pages[carryImageData.pageNum].categories[carryImageData.categoriesNum].categoriesName
                                                                                            ) {
                                                                                                imageUrl = `${imageUrl}_${contentPageData[carryImageData.folderNum].pages[carryImageData.pageNum].categories[carryImageData.categoriesNum].categoriesName}`
                                                                                            }

                                                                                            imageUrl = `${imageUrl}_${selectedLang}_${imgInd + 1}.svg`.replace(/ /g, '').toLocaleLowerCase();

                                                                                            const formData = new FormData();
                                                                                            formData.append('imageUrl', imageUrl);
                                                                                            formData.append('carryImageData', JSON.stringify(carryImageData));
                                                                                            formData.append('operationType', 2);

                                                                                            fetchContentImages(formData)
                                                                                        }}
                                                                                    >   <i className="fa fa-trash"></i> </div>
                                                                                    : null
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>

                                                <input
                                                    ref={fileInputRef}
                                                    type="file"
                                                    name='contImage'
                                                    accept="image/*"
                                                    className="d-none"
                                                    onChange={evnt => {
                                                        const imageBlobData = evnt.target.files[0];
                                                        const imageQuality = 0.5;

                                                        if (imageBlobData && imageBlobData.type.includes('image')) {
                                                            let fileExtn = imageBlobData.name.split('.');
                                                            fileExtn = fileExtn[fileExtn.length - 1]

                                                            if (fileExtn) {
                                                                if (imageBlobData.size <= 1000000) {

                                                                    const folderName = contentPageData[carryImageData.folderNum].folderName;
                                                                    const pageName = contentPageData[carryImageData.folderNum].pages[carryImageData.pageNum].pageName;
                                                                    let imageUrl = `${folderName}_${pageName}`;

                                                                    if (
                                                                        contentPageData[carryImageData.folderNum].pages
                                                                        && contentPageData[carryImageData.folderNum].pages[carryImageData.pageNum].categories
                                                                        && contentPageData[carryImageData.folderNum].pages[carryImageData.pageNum].categories[carryImageData.categoriesNum].categoriesName
                                                                    ) {
                                                                        imageUrl = `${imageUrl}_${contentPageData[carryImageData.folderNum].pages[carryImageData.pageNum].categories[carryImageData.categoriesNum].categoriesName}`
                                                                    }

                                                                    imageUrl = `${imageUrl}_${selectedLang}_${imageNum + 1}.svg`.replace(/ /g, '').toLocaleLowerCase();

                                                                    let formData = new FormData();
                                                                    formData.append('imageUrl', imageUrl);
                                                                    formData.append('fileExtn', fileExtn);
                                                                    formData.append('carryImageData', JSON.stringify(carryImageData));
                                                                    formData.append('operationType', 1);
                                                                    formData.append('imageFile', imageBlobData);
                                                                    fetchContentImages(formData)

                                                                    // if (imageBlobData) {
                                                                    //     const reader = new FileReader();
                                                                    //     reader.readAsDataURL(imageBlobData);
                                                                    //     reader.onloadend = () => {
                                                                    //         const base64data = reader.result;
                                                                    //         let mainImg = new Image();
                                                                    //         mainImg.onload = function () {
                                                                    //             let mainCanvas = document.createElement("canvas");
                                                                    //             mainCanvas.width = mainImg.width;
                                                                    //             mainCanvas.height = mainImg.height;
                                                                    //             let mainCtx = mainCanvas.getContext("2d");
                                                                    //             mainCtx.imageSmoothingQuality = "high";
                                                                    //             mainCtx.drawImage(
                                                                    //                 mainImg,
                                                                    //                 0,
                                                                    //                 0,
                                                                    //                 mainImg.width,
                                                                    //                 mainImg.height,
                                                                    //             );

                                                                    //             mainCanvas.toBlob(
                                                                    //                 (mainBlob) => {
                                                                    //                     if (mainBlob) {

                                                                    //                         let formData = new FormData();
                                                                    //                         formData.append('imageUrl', imageUrl);
                                                                    //                         formData.append('carryImageData', JSON.stringify(carryImageData));
                                                                    //                         formData.append('operationType', 1);
                                                                    //                         formData.append('imageFile', mainBlob);
                                                                    //                         fetchContentImages(formData)
                                                                    //                     }
                                                                    //                 },
                                                                    //                 "image/jpeg",
                                                                    //                 imageQuality
                                                                    //             );
                                                                    //         }

                                                                    //         mainImg.src = base64data;
                                                                    //     }
                                                                    // }
                                                                }
                                                                else {
                                                                    toast.error('Large file!');
                                                                }
                                                            }
                                                            else {
                                                                toast.error('Invalid file extension!');
                                                            }
                                                        }
                                                        else {
                                                            toast.error('Invalid file type!');
                                                        }
                                                    }
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : <div className="row">
                                <div className="container-fluid">
                                    <div className="row theme-tab">
                                        <Tabs className="col-sm-12" selectedIndex={allLanguages.findIndex(lngItem => lngItem.value == selectedLang)} style={{ padding: "0", backgroundColor: "white", borderRadius: "3px", border: "1px solid rgb(223, 232, 241)", marginBottom: "0px" }} >
                                            <TabList className="tabs tab-title">
                                                {
                                                    allLanguages.map((langVal, langInd) => {
                                                        return (
                                                            <Tab
                                                                key={langInd}
                                                                style={{ display: "flex", alignItems: "center" }}
                                                                onClick={evnt => {
                                                                    setSelectedLang(langVal.value)
                                                                }}
                                                            >
                                                                {langVal.label}
                                                            </Tab>
                                                        )
                                                    })
                                                }
                                            </TabList>
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-sm-12 col-xl-12 xl-100">
                                                        <div className="card">
                                                            <div className="card-body" style={{ padding: "1rem", cursor: 'default' }}>
                                                                <Table>
                                                                    <thead>
                                                                        <tr className="bg-light">
                                                                            <th className="border"> <div className="py-1"> Folder </div></th>
                                                                            <th className="border"> <div className="py-1"> Pages </div></th>
                                                                            <th className="border"> <div className="py-1">  </div></th>
                                                                            <th className="border"> <div className="py-1">  </div></th>
                                                                            <th className="border"> <div className="py-1">  </div></th>
                                                                            <th className="border"> <div className="py-1">  </div></th>
                                                                            <th className="border"> <div className="py-1">  </div></th>
                                                                            <th className="border"> <div className="py-1">  </div></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            contentPageData.map((cntVal, cntInd) => {
                                                                                return cntVal.pages.map((pgVal, pgInd) => {
                                                                                    return (
                                                                                        <tr key={pgInd}>
                                                                                            <td className="border"> {pgInd == 0 ? <div className="py-1 font-weight-bold"> {cntVal.folderName} </div> : null} </td>
                                                                                            <td key={pgInd} className="border"> <div
                                                                                                className="py-1"
                                                                                                style={{
                                                                                                    cursor: pgVal?.images ? 'pointer' : 'default',
                                                                                                    textDecoration: pgVal?.images ? 'underline' : 'initial'
                                                                                                }}
                                                                                                onClick={() => {
                                                                                                    if (pgVal?.images) {
                                                                                                        setCarryImageData({
                                                                                                            folderNum: cntInd,
                                                                                                            pageNum: pgInd,
                                                                                                            ...pgVal
                                                                                                        })
                                                                                                    }
                                                                                                }}
                                                                                            > {pgVal.pageName} </div></td>
                                                                                            {
                                                                                                [...Array(6)].map((arrVal, arrInd) => {
                                                                                                    if (pgVal.categories && pgVal.categories[arrInd]?.categoriesName) {
                                                                                                        return <td key={arrInd} className="border">
                                                                                                            <div className="py-1 d-inline-block">
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        cursor: pgVal.categories[arrInd] && pgVal.categories[arrInd].images ? 'pointer' : 'default',
                                                                                                                        textDecoration: pgVal.categories[arrInd] && pgVal.categories[arrInd].images ? 'underline' : 'initial'
                                                                                                                    }}
                                                                                                                    onClick={() => {
                                                                                                                        if (pgVal.categories[arrInd] && pgVal.categories[arrInd].images) {
                                                                                                                            setCarryImageData({
                                                                                                                                folderNum: cntInd,
                                                                                                                                pageNum: pgInd,
                                                                                                                                categoriesNum: arrInd,
                                                                                                                                ...pgVal.categories[arrInd]
                                                                                                                            })
                                                                                                                        }
                                                                                                                    }}
                                                                                                                > {pgVal.categories[arrInd].categoriesName} </span>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    }
                                                                                                    else {
                                                                                                        return <td key={arrInd} className="border"> <div className="py-1">  </div></td>
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </Table>

                                                                {/* <Table className="mt-5">
                                                                <thead>
                                                                    <tr className="bg-light">
                                                                        <th className="border"> <div className="py-1"> Folder </div></th>
                                                                        <th className="border"> <div className="py-1"> Pages </div></th>
                                                                        <th className="border"> <div className="py-1">  </div></th>
                                                                        <th className="border"> <div className="py-1">  </div></th>
                                                                        <th className="border"> <div className="py-1">  </div></th>
                                                                        <th className="border"> <div className="py-1">  </div></th>
                                                                        <th className="border"> <div className="py-1">  </div></th>
                                                                        <th className="border"> <div className="py-1">  </div></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="border"> <div className="py-1 font-weight-bold"> Business Types </div></td>
                                                                        <td className="border"> <div className="py-1"> Tradies </div></td>
                                                                        <td className="border"> <div className="py-1"> Plumbers </div></td>
                                                                        <td className="border"> <div className="py-1"> Electricians </div></td>
                                                                        <td className="border"> <div className="py-1"> Hairdressers </div></td>
                                                                        <td className="border"> <div className="py-1"> Gardeners </div></td>
                                                                        <td className="border"> <div className="py-1"> Cleaners </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1"> Health & Well-being </div></td>
                                                                        <td className="border"> <div className="py-1"> Physios </div></td>
                                                                        <td className="border"> <div className="py-1"> Counselors </div></td>
                                                                        <td className="border"> <div className="py-1"> Massage Therapist </div></td>
                                                                        <td className="border"> <div className="py-1"> Tutors </div></td>
                                                                        <td className="border"> <div className="py-1"> Fitness Instructors </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1"> Club & Bars </div></td>
                                                                        <td className="border"> <div className="py-1"> Models </div></td>
                                                                        <td className="border"> <div className="py-1"> Dancers </div></td>
                                                                        <td className="border"> <div className="py-1"> Bartenders </div></td>
                                                                        <td className="border"> <div className="py-1"> Waitresses </div></td>
                                                                        <td className="border"> <div className="py-1"> Hosts </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1"> Creative Arts </div></td>
                                                                        <td className="border"> <div className="py-1"> Buskers </div></td>
                                                                        <td className="border"> <div className="py-1"> Magician </div></td>
                                                                        <td className="border"> <div className="py-1"> Musicians </div></td>
                                                                        <td className="border"> <div className="py-1"> Painters </div></td>
                                                                        <td className="border"> <div className="py-1"> Crafters </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1"> Hospitality </div></td>
                                                                        <td className="border"> <div className="py-1"> Cafes </div></td>
                                                                        <td className="border"> <div className="py-1"> Restaurants </div></td>
                                                                        <td className="border"> <div className="py-1"> Bars </div></td>
                                                                        <td className="border"> <div className="py-1"> Pubs </div></td>
                                                                        <td className="border"> <div className="py-1"> Night Clubs </div></td>
                                                                        <td className="border"> <div className="py-1"> Functions </div></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1"> Others </div></td>
                                                                        <td className="border"> <div className="py-1"> Market Stall Holders </div></td>
                                                                        <td className="border"> <div className="py-1"> Meal Delivery Business </div></td>
                                                                        <td className="border"> <div className="py-1"> Takeway Shop </div></td>
                                                                        <td className="border"> <div className="py-1"> Lash Extensions </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="border"> <div className="py-1 font-weight-bold"> Products </div></td>
                                                                        <td className="border"> <div className="py-1"> All In One POS </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1"> All In One QR Code </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1"> Payment Solution </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1"> iPayPro Watches And Terminals </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1"> Visitor Registry </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1"> Linky Scan </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1"> Online Ordering System </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1"> QR Code Table Ordering </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1"> QR Code Tipping </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1"> Loyalty Marketing </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1"> Booking Management </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="border"> <div className="py-1 font-weight-bold"> Testimonials </div></td>
                                                                        <td className="border"> <div className="py-1"> Tradies </div></td>
                                                                        <td className="border"> <div className="py-1"> Plumbers </div></td>
                                                                        <td className="border"> <div className="py-1"> Electricians </div></td>
                                                                        <td className="border"> <div className="py-1"> Hairdressers </div></td>
                                                                        <td className="border"> <div className="py-1"> Gardeners </div></td>
                                                                        <td className="border"> <div className="py-1"> Cleaners </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1"> Health & Well-being </div></td>
                                                                        <td className="border"> <div className="py-1"> Physios </div></td>
                                                                        <td className="border"> <div className="py-1"> Counselors </div></td>
                                                                        <td className="border"> <div className="py-1"> Massage Therapist </div></td>
                                                                        <td className="border"> <div className="py-1"> Tutors </div></td>
                                                                        <td className="border"> <div className="py-1"> Fitness Instructors </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1"> Club & Bars </div></td>
                                                                        <td className="border"> <div className="py-1"> Models </div></td>
                                                                        <td className="border"> <div className="py-1"> Dancers </div></td>
                                                                        <td className="border"> <div className="py-1"> Bartenders </div></td>
                                                                        <td className="border"> <div className="py-1"> Waitresses </div></td>
                                                                        <td className="border"> <div className="py-1"> Hosts </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1"> Creative Arts </div></td>
                                                                        <td className="border"> <div className="py-1"> Buskers </div></td>
                                                                        <td className="border"> <div className="py-1"> Magician </div></td>
                                                                        <td className="border"> <div className="py-1"> Musicians </div></td>
                                                                        <td className="border"> <div className="py-1"> Painters </div></td>
                                                                        <td className="border"> <div className="py-1"> Crafters </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1"> Others </div></td>
                                                                        <td className="border"> <div className="py-1"> Market Stall Holders </div></td>
                                                                        <td className="border"> <div className="py-1"> Meal Delivery Business </div></td>
                                                                        <td className="border"> <div className="py-1"> Takeway Shop </div></td>
                                                                        <td className="border"> <div className="py-1"> Lash Extensions </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                        <td className="border"> <div className="py-1">  </div></td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </>
        </Fragment>
    );

}

export default ContentPages;