import React from 'react'
import Select from 'react-select';
import { components } from "react-select";

const customDrowpdown = ({ name, defaultValue, onChange, options, user }) => {

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator
                {...props}
                style={{
                    ...props.style,
                    arrow: {
                        thickness: '4px',
                    },
                }}
            />
        );
    };


    return (
        <Select
            styles={{
                control: (baseStyles, state, isFocused) => ({
                    ...baseStyles,
                    width: '103px',
                    minHeight: '28px',
                    fontSize: '11px',
                    borderRadius: "5px",
                    outline: 'none !important',
                    backgroundColor: user.storeRefStatus && user.storeRefStatus.value == 1 ? "#EFEFEF !important"
                        : user.storeRefStatus && user.storeRefStatus.value == 2 ? "#dfdfff !important"
                            : user.storeRefStatus && user.storeRefStatus.value == 3 ? "#ffffca !important"
                                : user.storeRefStatus && user.storeRefStatus.value == 4 ? "#ffdfdf !important"
                                    : user.storeRefStatus && user.storeRefStatus.value == 5 ? "#dfffdf !important"
                                        : "#EFEFEF !important",
                    border: user.storeRefStatus && user.storeRefStatus.value == 1 ? "1px solid #ccc !important"
                        : user.storeRefStatus && user.storeRefStatus.value == 2 ? "1px solid #0000FF !important"
                            : user.storeRefStatus && user.storeRefStatus.value == 3 ? "1px solid #FFC300 !important"
                                : user.storeRefStatus && user.storeRefStatus.value == 4 ? "1px solid #ff0000 !important"
                                    : user.storeRefStatus && user.storeRefStatus.value == 5 ? "1px solid #008000 !important"
                                        : "1px solid #ccc !important",
                }),
                indicatorSeparator: base => ({
                    ...base,
                    display: 'none'
                }),
                dropdownIndicator: base => ({
                    ...base,
                    padding: "0px 5px 0px 0px",
                    "svg": {
                        fill: "#000000",
                        height: "11px !important",
                        width: "11px !important"
                    }
                }),
                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                    return {
                        ...styles,
                        color: '#000000',
                        borderRadius: "5px",
                        margin: "0.3rem 0.3rem 0.3rem 0.4rem !important",
                        padding: "5px 10px",
                        fontSize: "11px !important",
                        backgroundColor: data.value == 1 ? '#EFEFEF'
                            : data.value == 2 ? '#dfdfff'
                                : data.value == 3 ? '#ffffca'
                                    : data.value == 4 ? '#ffdfdf'
                                        : data.value == 5 ? '#dfffdf'
                                            : '#ccc',
                        border: data.value == 1 ? "1px solid #ccc !important"
                            : data.value == 2 ? "1px solid #0000FF !important"
                                : data.value == 3 ? "1px solid #FFC300 !important"
                                    : data.value == 4 ? "1px solid #ff0000 !important"
                                        : data.value == 5 ? "1px solid #008000 !important"
                                            : data.value == 6 ? "1px solid #000000 !important"
                                                : "1px solid #ccc !important",
                    }
                },
                menu: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                    ...styles,
                    width: "120px !important",
                    padding: "0px !important",
                    overflow: "unset !important",
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px !important',
                    left: "-6px !important"
                }),
                menuList: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                    ...styles,
                    width: "105px !important",
                    overflow: "unset !important"
                }),
                valueContainer: (provided, state) => ({
                    ...provided,
                    height: '28px !important'
                }),
            }}
            isSearchable={false}
            className="sex-select-container"
            classNamePrefix="sex-select"
            name={name}
            defaultValue={defaultValue}
            onChange={onChange}
            options={options}
            components={{ DropdownIndicator }}
        />
    )
}

export default customDrowpdown