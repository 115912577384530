import React, { useState, useEffect, createRef } from 'react';
import Dropzone, { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import CropperModel from './TestCropper';
import { mdiPencil, mdiTrashCanOutline, mdiChartSankeyVariant, mdiCogOutline } from "@mdi/js"
import Icon from "@mdi/react";
import { Button } from 'reactstrap';
import api from "../../../../constant/apilist";
import client from "../../../../Api/HTTPClient";
import "../logoAndBusinessTypes.scss";
import axios from 'axios';


const DropZoneField = (props) => {

  const dispatch = useDispatch();
  const [filec, setFile] = useState(null)
  const [resizedImage, setResizedImage] = useState(null)
  const dropzoneRef = createRef()

  const category = useSelector(state => state.category)
  const onDrop = (acceptedFiles, file) => {
    setFile(acceptedFiles[0])
  }

  const crModel = (croppedFile) => {

    console.log(filec, "filec");

    let formData = new FormData();
    formData.append('file', filec);

    const config = { headers: { "x-access-token": "ipay-access-token", "authorization": localStorage.getItem("token"), } };


    axios.put(client.url(api.add_image), formData, config).then(response => {
      if (response) {
        if (response.data) {
          if (!response.data.error) {
            // setAllLanguages(response.data.languages)
            // setContentPageData(response.data.data)
            // setFetchLoading(false)
          }
          else {
            // setFetchLoading(false)
            // toast.error(response.data.message);
          }
        }
        else {
          // setFetchLoading(false)
          // toast.error('Internal error!');
        }
      }
      else {
        // setFetchLoading(false)
        // toast.error('Internal error!');
      }
    }).catch(error => {
      // setFetchLoading(false)
      // toast.error(error);
    })

    setFile(null)

  }

  const handleImageUpload = async (event) => {
    event.preventDefault();
    event.persist();
    let files = await event.target?.files
    // console.log(files[0], "svg image data")
    setFile(files[0])
  }

  return (
    <div className="container border p-0 m-0"
      style={{
        height: '3rem',
        width: '3rem',
        // borderColor: "silver",
        // border: "1px solid black"
      }}>
      <input
        // hidden
        name="productImage"
        type="file"
        accept="image/*"
        style={{ width: "500px" }}
        // ref={inputFileTrigger}
        onChange={(e) => handleImageUpload(e)}
      />

      <CropperModel file={filec}
        onConfirm={(croppedFile) => {

          if (croppedFile) {
            setResizedImage(croppedFile)
          }
        }}
        onCompleted={(croppedFile) => crModel(croppedFile)} />
    </div>
  );
};


export default DropZoneField;
