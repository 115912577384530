import React, { useEffect, Fragment, useState } from "react";
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormInput, FormGroup, Col, Row, Input, CustomInput } from 'reactstrap';
import AddHelpQuestionModal from './AddHelpQuestionModal';
import { Toast } from "react-bootstrap";
import useForm from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { DisappearedLoading } from "react-loadingg";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index"
import ReactDragListView from "react-drag-listview";
import EditHelpQuestion from "./EditHelpQuestion";
import { Link as NavLink } from "react-router-dom";

const HelpTopicQuestion = (props) => {

    let [loading, setloading] = useState(true);
    let [loadingbtn, setloadingbtn] = useState(false);
    let [editformOpen, seteditformOpen] = useState(false);
    let [editmodalOpen, seteditmodalOpen] = useState(false);
    let [addmodalOpen, setaddmodalOpen] = useState(false);
    let [editcatmodalOpen, seteditcatmodalOpen] = useState(false);
    let [statusDetails, setStatusDetails] = useState(null);
    let [helpTopic, sethelpTopic] = useState([]);
    let history = useHistory();
    let [inputList, setInputList] = useState([{ status: 1 }]);
    let [inputCat, setInputCat] = useState('');
    let [editItem, seteditItem] = useState(null);
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState(false);
    const [redirectData, setRedirectData] = useState(null);
    const [subTopicId, setsubTopicId] = useState(props.match.params.merchant_id);
    const [deleteModal, setdeleteModal] = useState(false);
    let [deleteItem, setdeleteItem] = useState(null);

  const slider = helpTopic
  { slider && console.log(slider) }
  const tableDragProps = {
    onDragEnd(fromIndex, toIndex) {
        let NewStoreTableData = slider;
        let item = NewStoreTableData.splice(fromIndex, 1)[0];
        NewStoreTableData.splice(toIndex, 0, item);
        NewStoreTableData = NewStoreTableData;
        client.post(api.editHelpquestion, { order: NewStoreTableData, type: 2 }, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    fetchData();
                    toast.success(response.message);
                }
            }
        })
    },
    nodeSelector: "tr",
  };
    
  const handleRedirect = (id) => {
    helpTopic.map((item) => {
      if (item._id == id) {
        setRedirectData(item);
        setRedirect(true);

        // console.log(redirectData, "redirectData");
      }
      console.log(item, "item");
    });
  };

  const handleCatDelete = (e) => {
    e.preventDefault();
    setloadingbtn(true);

    client.post(api.deleteHelpquestion, { _id: deleteItem }, async function (error, response) {
      if (!error) {
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              toast.success("Deleted Successfully");
              toggleDeleteModal();
              fetchData();
            } else {
              toast.error(response.message);
            }
          } catch (e) {
            toast.error("Internal error occured. Please contact support 2");
          }
        } else {
          toast.error("Internal error occured. Please contact support 2");
        }
      } else {
        toast.error("Internal error occured. Please contact support 3");
      }
      setloadingbtn(false);
    });

  }

  async function fetchData() {
    client.post(api.fetchHelpquestion, { subTopicId: subTopicId }, async function (error, response) {
      // console.log(response)
      if (!error) {
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              sethelpTopic(response.result.data);
            }
          } catch {

          }
        }
      }
      setloading(false);
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  const deleteClick = (id) => {
    setdeleteItem(id);
    setdeleteModal(true);
  }
  
  const toggleDeleteModal = () => {
    setdeleteModal(!deleteModal)
  }

  const renderDeleteModal = () => {
    return (
      <Modal isOpen={deleteModal} toggle={toggleDeleteModal} className="modal-body" centered={true}>
          <form className="theme-form" noValidate="" onSubmit={(e) => handleCatDelete(e)}>
              <ModalHeader><h5 style={{ fontSize: "1.15rem" }}>Delete Question</h5></ModalHeader>
              <ModalBody>
                  <ModalBody style={{ padding: "16px 0" }}>
                      <h5 style={{ fontSize: "1.15rem" }}>Are you sure you want to delete the question?</h5>
                  </ModalBody>
                  <ModalFooter>
                      <Button color="default" onClick={toggleDeleteModal}>Not sure!</Button>
                      <Button color="primary" type="submit"  >Yes Please!</Button>
                  </ModalFooter>
              </ModalBody>
          </form>
      </Modal>
    );
  }

  if (redirect) {
    return (
      <EditHelpQuestion
        toggleRedirect={(fetch_data = false) => {
          setRedirect(false);
          if (fetch_data) {
            fetchData();
          }
          // getAllData();
        }}
        redirectData={redirectData}
      />
    );
  } else {
    return (
      <Fragment>
        {deleteItem ? renderDeleteModal() : null}

        <div className="row">
          <div className="col-lg-7 col-sm-12" style={{ fontSize: "12px" }}>
            <Breadcrumb title="Help Topic Questions" parent="Home" />
          </div>
          <div className="col-lg-5 col-sm-12 ">
            <div className="btn-group" style={{ paddingTop: "10px", marginLeft: "0.4rem", marginRight: "0.6rem", marginBottom: "0rem", width: "auto", display: "flex", justifyContent: "flex-end", alignItems: "center" }} role="group" aria-label="Basic example">
              {true && <button className="btnCashback custBtnAdmin" onClick={() => setaddmodalOpen(true)} ><i style={{ margin: "0 7px 0 0", color: "#327ebc" }} id="Add New" title="Add New" className="fa fa-plus"></i>Add Questions</button>}
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card merchantSetttings" style={{ border: '1px solid rgb(223, 232, 241)', borderRadius: '3px' }}>
                <div className="card-header">
                  <NavLink to={`/helptopics`}>
                    <h7 className="font-weight-bold">
                      <i className="icon-arrow-left" style={{ paddingRight: '5px' }}></i>
                      Help Topic Questions
                    </h7>
                  </NavLink>
                </div>
                <div className="card-body" style={{ padding: "1rem" }}>

                  {!loading ?
                    <div className="table-responsive custom-scrollbar">
                      <ReactDragListView {...tableDragProps}>
                        <table className="table table-border-horizontal" >
                          <thead>
                            <tr style={{ background: '#f9fafe' }}>
                              <th scope="col">#</th>
                              <th scope="col" >Question</th>
                              <th scope="col" >Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* countries{} */}
                            {helpTopic && helpTopic.length > 0 ? helpTopic.map((item, index) => {
                              console.log(item);
                              return (
                                <tr key={index}>
                                  <td width={'5%'}>#</td>
                                  <td>{item.questions}</td>
                                  <td>
                                    <span style={{ marginRight: "10px", fontSize: "1.2rem", color: "black" }} onClick={() => {
                                      handleRedirect(item._id);
                                    }}>
                                      <i className="fa fa-edit"></i>
                                    </span>
                                    <span style={{ marginRight: "10px", fontSize: "1.2rem", color: "red" }} onClick={() => deleteClick(item._id)}>
                                      <i className="fa fa-trash"></i>
                                    </span>
                                  </td>
                                </tr>
                              )
                            })
                              : null
                            }
                          </tbody>
                        </table>
                      </ReactDragListView>
                    </div>
                    :
                    <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                      <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                        <DisappearedLoading size="medium" color="#514F4E" />
                      </div>
                    </div>}
                </div>
              </div>
            </div>
          </div>
        </div>

        <AddHelpQuestionModal isOpen={addmodalOpen} subTopicId={subTopicId} onClose={(fetch_data = false) => {
          setaddmodalOpen(false);
          setInputCat('');
          seteditItem(null);
          if (fetch_data) {
            fetchData();
          }
        }} />

      </Fragment>
    );
  }
}

export default HelpTopicQuestion;