import React, { useState } from "react";
import { Tabs, TabList, TabPane, Tab, TabPanel } from "react-tabs";
import {
	Input,
	Label,
	Form,
	FormGroup,
	Row,
	Col,
	Button,
	Popover,
	PopoverBody,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from "reactstrap";
import PublishSurvey from "./PublishSurvey";
import ResultSurvey from "./ResultSurvey";
import SurveyForm from "./SurveyForm";

const SurveyTabs = (props) => {
	const [tabIndex, setTabIndex] = useState(0);
	return (
		<div className="card">
			<div className="card-header">
				<Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
					<TabList className="tbs tab-title">
						<Col md={3}>
							<h6 style={{ fontSize: "14px", marginTop: "10px" }}>
								<i className="icon-arrow-left" style={{ color: "black" }}></i>{" "}
								&nbsp;&nbsp;Survey - {props.campaignName}
							</h6>
						</Col>
						<Col md={9}>
							<Tab style={{ marginLeft: "22%" }}>CREATE</Tab>
							<Tab>PUBLISH</Tab>
							<Tab>RESULTS</Tab>
						</Col>
					</TabList>
					<TabPanel>
						<SurveyForm setTabIndex={setTabIndex}></SurveyForm>
					</TabPanel>
					<TabPanel>
						<PublishSurvey setTabIndex={setTabIndex}></PublishSurvey>
					</TabPanel>
					<TabPanel>
						<ResultSurvey></ResultSurvey>
					</TabPanel>
				</Tabs>
			</div>
		</div>
	);
};

export default SurveyTabs;
