import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import displayMobile from "../../components/ui-elements/display_mobile";
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    UncontrolledTooltip,
    Input,
} from "reactstrap";
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink as NavLinkTab,
} from "reactstrap";
import * as moment from "moment-timezone";
import { DisappearedLoading } from "react-loadingg";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import PaginationComponent from "react-reactstrap-pagination";
import DeleteIcon from '@material-ui/icons/Delete';
import CustomTabs from "./CustomTabs";

// const BorderTable = () => {
function ContactInquiry() {
    let [loadingbtn, setloadingbtn] = useState(false);
    let [loading, setloading] = useState(false);
    let [result, setresult] = useState(null);
    const [tabIndex, setTabIndex] = useState(0);
    const [messageModal, setMessageModal] = useState(false);
    const [selectedInquiry, setSelectedInquiry] = useState(null);
    const [activePage, setActivePage] = useState(1);
    const [pageSize, setPageSize] = useState(25);

    const [contactUs, setContactUs] = useState([]);
    const [contactInquiry, setContactInquiry] = useState([]);
    const [callUsData, setCallUsData] = useState([]);
    const [hustlerzData, setHustlerzData] = useState([]);
    const [newsletterData, setNewsletterData] = useState([]);
    const [unsubscribedData, setUnsubscribedData] = useState([]);
    useEffect(() => {
        // if (tabIndex == 0) {
        //     setresult(contactInquiry);
        // } else
        if (tabIndex == 0) {
            setresult(contactInquiry);
        } else if (tabIndex == 2) {
            setresult(callUsData);
        } else if (tabIndex == 3) {
            setresult(hustlerzData);
        } else if (tabIndex == 4) {
            setresult(newsletterData);
        } else if (tabIndex == 5) {
            setresult(unsubscribedData);
        } else {
            setresult(contactUs);
        }
    }, [tabIndex]);

    useEffect(() => {
        fetchContactUsData();
        fetchContactInquiry();
        fetchCallUsData();
        fetchHustlerzContactUsData();
        fetchSubscribersData();
        fetchUnsubscribedData();
    }, []);

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    const fetchContactInquiry = async () => {
        setloading(true);
        const postData = {};
        client.post(
            api.getAllContactInquiry,
            postData,
            async function (error, response) {
                if (!error) {
                    console.log(response);
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                console.log("inquiry data ", response);
                                // setresult(response.result.data);
                                setContactInquiry(response.result.data);
                            } else {
                                toast.error(response.message);
                            }
                        } catch (e) {
                            toast.error(
                                "Internal error occured. Please contact support"
                            );
                        }
                    } else {
                        toast.error(
                            "Internal error occured. Please contact support"
                        );
                    }
                } else {
                    console.log(error);
                    toast.error(
                        "Internal error occured. Please contact support"
                    );
                }

                setloading(false);
            }
        );
    };

    const [activeTab, setActiveTab] = useState(0);

    const filterTabData = (activeTab, showOnlyLength = false) => {
        let filteredArray;
        if (activeTab != 0) filteredArray = newsletterData.filter(item => item.customer_entry_type == activeTab);
        else filteredArray = newsletterData;
        if (showOnlyLength) return filteredArray.length;
        return filteredArray;
    }

    useEffect(() => {
        setresult(filterTabData(activeTab));
    }, [activeTab])

    const onTabChange = (tab) => {
        setActivePage(1);
        setActiveTab(tab);
    }

    const tabDetails = [
        { tabNo: 0, tabName: 'All', totalData: filterTabData(0, true) },
        { tabNo: 3, tabName: 'Events & Tickets', totalData: filterTabData(3, true) },
        { tabNo: 2, tabName: 'Vistor registry', totalData: filterTabData(2, true) },
        { tabNo: 4, tabName: 'Online order', totalData: filterTabData(4, true) },
        { tabNo: 6, tabName: 'POS Order', totalData: filterTabData(6, true) },
        { tabNo: 8, tabName: 'Make a Payment', totalData: filterTabData(8, true) },
        { tabNo: 7, tabName: 'Private Gallery', totalData: filterTabData(7, true) },
        { tabNo: 9, tabName: 'Payment Link', totalData: filterTabData(9, true) },
        { tabNo: 5, tabName: 'Tips', totalData: filterTabData(5, true) },
        { tabNo: 10, tabName: 'Newsletter', totalData: filterTabData(10, true) },
    ]

    const getSource = (val) => {
        try {
            if (val.customer_entry_type == 1) {
                return "Online website via mystery discount popup"
            } else if (val.customer_entry_type == 2) {
                return "Vistor registry feature of Minisite"
            } else if (val.customer_entry_type == 3) {
                return "Sell ticket feature of Minisite"
            } else if (val.customer_entry_type == 4) {
                return "Online order"
            } else if (val.customer_entry_type == 5) {
                return "Tips feature of Minisite"
            } else if (val.customer_entry_type == 6) {
                return "POS orders"
            } else if (val.customer_entry_type == 7) {
                return "Private Gallery feature of Minisite"
            } else if (val.customer_entry_type == 8) {
                return "Make a Payment"
            } else if (val.customer_entry_type == 9) {
                return "Payment Link"
            } else {
                return ""
            }
        } catch (err) {
            return "";
        }
    }

    const fetchContactUsData = async () => {
        setloading(true);
        const postData = {};
        client.post(
            api.getAllContactUsData,
            postData,
            async function (error, response) {
                if (!error) {
                    console.log("Contact Us Data", response);
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                console.log(response);
                                // setresult(response.result.data);
                                setContactUs(response.result.data);
                            } else {
                                toast.error(response.message);
                                console.log("current error ==>", error);
                            }
                        } catch (e) {
                            toast.error(
                                "Internal error occured. Please contact support"
                            );
                        }
                    } else {
                        toast.error(
                            "Internal error occured. Please contact support"
                        );
                    }
                } else {
                    console.log(error);
                    toast.error(
                        "Internal error occured. Please contact support"
                    );
                }

                setloading(false);
            }
        );
    };
    const fetchHustlerzContactUsData = async () => {
        setloading(true);
        const postData = {};
        client.post(
            api.hustlerz_contact_info_all,
            postData,
            async function (error, response) {
                if (!error) {
                    console.log("Hustlerz Contact Us Data", response);
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                console.log(response);
                                // setresult(response.result.data);
                                setHustlerzData(response.result.data);
                            } else {
                                toast.error(response.message);
                                console.log("current error ==>", error);
                            }
                        } catch (e) {
                            toast.error(
                                "Internal error occured. Please contact support"
                            );
                        }
                    } else {
                        toast.error(
                            "Internal error occured. Please contact support"
                        );
                    }
                } else {
                    console.log(error);
                    toast.error(
                        "Internal error occured. Please contact support"
                    );
                }

                setloading(false);
            }
        );
    };

    const fetchCallUsData = async () => {
        setloading(true);
        const postData = {};
        client.post(
            api.getAllCallUsData,
            postData,
            async function (error, response) {
                if (!error) {
                    console.log("Call Us Data", response);
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                console.log(response);
                                // setresult(response.result.data);
                                setCallUsData(response.result.data);
                            } else {
                                toast.error(response.message);
                                console.log("current error ==>", error);
                            }
                        } catch (e) {
                            toast.error(
                                "Internal error occured. Please contact support"
                            );
                        }
                    } else {
                        toast.error(
                            "Internal error occured. Please contact support"
                        );
                    }
                } else {
                    console.log(error);
                    toast.error(
                        "Internal error occured. Please contact support"
                    );
                }

                setloading(false);
            }
        );
    };

    const fetchSubscribersData = async () => {
        setloading(true);
        const postData = {};
        client.post(
            api.getAllSubscribersN,
            postData,
            async function (error, response) {
                if (!error) {
                    console.log("Subscribers Data", response);
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                console.log(response);
                                // setresult(response.result.data);
                                let newTotalData = [...response.result.data]
                                const key = 'email';
                                const uniqueValues = [...new Map(newTotalData.map(item => [item[key], item])).values()];
                                let uniqueValuesSorted = uniqueValues.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                                setNewsletterData(uniqueValuesSorted)
                                // setNewsletterData(response.result.data);
                            } else {
                                toast.error(response.message);
                                console.log("current error ==>", error);
                            }
                        } catch (e) {
                            toast.error(
                                "Internal error occured. Please contact support"
                            );
                        }
                    } else {
                        toast.error(
                            "Internal error occured. Please contact support"
                        );
                    }
                } else {
                    console.log(error);
                    toast.error(
                        "Internal error occured. Please contact support"
                    );
                }

                setloading(false);
            }
        );
    };

    const fetchUnsubscribedData = async () => {
        setloading(true);
        const postData = {};
        client.get(
            api.getAllUnsubscribeEmail,
            postData,
            async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                setUnsubscribedData(response.result.data);
                            } else {
                                toast.error(response.message);
                            }
                        } catch (e) {
                            toast.error(
                                "Internal error occured. Please contact support"
                            );
                        }
                    } else {
                        toast.error(
                            "Internal error occured. Please contact support"
                        );
                    }
                } else {
                    toast.error(
                        "Internal error occured. Please contact support"
                    );
                }
                setloading(false);
            }
        );
    };

    const handleDeleteUnsubscribe = (item) => {
        setloading(true);
        const postData = { deleteID: item._id };
        client.post(
            api.deleteUnsubscribeEmail,
            postData,
            async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                setUnsubscribedData(response.result.data);
                                if (tabIndex == 6) {
                                    setresult(response.result.data);
                                }
                                toast.success("Data deleted")
                            } else {
                                toast.error(response.message);
                            }
                        } catch (e) {
                            toast.error(
                                "Internal error occured. Please contact support"
                            );
                        }
                    } else {
                        toast.error(
                            "Internal error occured. Please contact support"
                        );
                    }
                } else {
                    toast.error(
                        "Internal error occured. Please contact support"
                    );
                }
                setloading(false);
            }
        );
    }

    const handleUpdateUnsubscribe = (comment, item) => {
        setloading(true);
        const postData = { updateData: { comment }, updateID: item._id, };
        client.post(
            api.updateUnsubscribeEmail,
            postData,
            async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                setUnsubscribedData(response.result.data);
                                if (tabIndex == 6) {
                                    setresult(response.result.data);
                                }
                                toast.success("Comment updated")
                            } else {
                                toast.error(response.message);
                            }
                        } catch (e) {
                            toast.error(
                                "Internal error occured. Please contact support"
                            );
                        }
                    } else {
                        toast.error(
                            "Internal error occured. Please contact support"
                        );
                    }
                } else {
                    toast.error(
                        "Internal error occured. Please contact support"
                    );
                }
                setloading(false);
            }
        );
    }

    const onMessageClick = (inquiry) => {
        setMessageModal(true);
        setSelectedInquiry(inquiry);
    };

    const toggleMessageModal = () => {
        setMessageModal(!messageModal);
    };

    const renderMessageModal = () => {
        return (
            <Modal
                isOpen={messageModal}
                toggle={toggleMessageModal}
                className="modal-body"
                centered={true}
            >
                <ModalBody style={{ textAlign: "center" }}>
                    <h6>{selectedInquiry.message}</h6>
                    <Button
                        className="btn btn-danger"
                        style={{
                            width: "100%",
                            textAlign: "center",
                            fontSize: "1.1rem",
                            marginTop: "10px",
                        }}
                        onClick={toggleMessageModal}
                    >
                        Dismiss
                    </Button>
                </ModalBody>
            </Modal>
        );
    };

    const submitData = (data, item) => {
        if (data.length > 0) {
            if (item.comment != data) {
                let postData = {
                    ...item
                }
                postData.comment = data;

                client.post(api.saveCallUsData, postData, (error, response) => {
                    if (!error) {
                        if (!response.error) {
                            setCallUsData(response.result.data)
                            console.log(postData, "pos")
                            toast.success("Comment saved successfully")
                        } else {
                            toast.error("Something went wrong !")
                        }
                    } else {
                        toast.error("Something went wrong")
                    }
                })
            }
        }

    }

    const submitContactData = (comment, item) => {
        setloading(true);
        const postData = { updateData: { comment }, updateID: item._id, };
        client.post(
            api.saveContactUsMailData,
            postData,
            async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                setContactUs(response.result.data);
                                if (tabIndex == 2) {
                                    setresult(response.result.data);
                                }
                                toast.success("Comment updated")
                            } else {
                                toast.error(response.message);
                            }
                        } catch (e) {
                            toast.error(
                                "Internal error occured. Please contact support"
                            );
                        }
                    } else {
                        toast.error(
                            "Internal error occured. Please contact support"
                        );
                    }
                } else {
                    toast.error(
                        "Internal error occured. Please contact support"
                    );
                }
                setloading(false);
            }
        );
    }

    const renderTable = (type) => {
        let filteredresult = [];
        if (type == 6) filteredresult = result;
        else filteredresult = result.filter((item) => item.type == type);
        return (
            <Fragment>
                {type == 6 ? <div>
                    <div className="mb-0 mt-4 meet_tabs">
                        <CustomTabs
                            tabDetails={tabDetails}
                            activeTab={activeTab}
                            setActiveTab={onTabChange}
                        />
                    </div>
                </div> : null}
                {result.length > 0 ? (
                    <Fragment>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <div>
                                {/* <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                                    <div >
                                        <input style={{
                                            border: "none",
                                            borderBottom: "1px solid rgb(197, 189, 189)",
                                            borderRadius: "0",
                                            margin: "0px 10px 6px",
                                            fontSize: "0.75rem",

                                        }}
                                            type="text"
                                            placeholder="Search"
                                            onChange={this.onSearchChangeHandler} />
                                    </div>
                                </form> */}
                            </div>
                            {activePage * pageSize > filteredresult.length ? (
                                <p
                                    style={{
                                        textAlign: "right",
                                        fontSize: "0.63rem",
                                        margin: "0",
                                    }}
                                >
                                    Showing {(activePage - 1) * pageSize + 1} to{" "}
                                    {filteredresult.length} of{" "}
                                    {filteredresult.length} entries
                                </p>
                            ) : (
                                <p
                                    style={{
                                        textAlign: "right",
                                        fontSize: "0.63rem",
                                        margin: "0",
                                    }}
                                >
                                    Showing {(activePage - 1) * pageSize + 1} to{" "}
                                    {activePage * pageSize} of{" "}
                                    {filteredresult.length} entries
                                </p>
                            )}
                        </div>
                        <div className="table-responsive">
                            <table className="table table-border-horizontal table-striped table-light table-striped table-light">
                                {type == 4 ? <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th
                                            scope="col"
                                            style={{ width: "10%" }}
                                        >
                                            <span>Date</span>
                                            <span>Time</span>
                                        </th>
                                        <th scope="col">Number</th>
                                        <th scope="col">Store Name</th>
                                        <th scope="col">Comments</th>
                                    </tr>
                                </thead> : type == 3 ? (
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th
                                                scope="col"
                                                style={{ width: "10%" }}
                                            >
                                                <span>Date</span>
                                                <span>Time</span>
                                            </th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Mobile</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Website</th>
                                            {/* <th scope="col">Bus. Name</th>
                                            <th scope="col">Bus. Type</th>
                                            <th scope="col">POS System</th> */}
                                            <th scope="col">Message</th>
                                            <th scope="col">Comments</th>
                                        </tr>
                                    </thead>
                                )
                                    : type == 5 ? <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th
                                                scope="col"
                                                style={{ width: "10%" }}
                                            >
                                                <span>Date & </span>
                                                <span>Time</span>
                                            </th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Mobile</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Message</th>
                                        </tr>
                                    </thead>
                                        : type == 6 ? <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th
                                                    scope="col"
                                                    style={{ width: "150px" }}
                                                >
                                                    Date & Time
                                                </th>
                                                <th scope="col">Name</th>
                                                <th style={{ width: "150px" }} scope="col">Store Name</th>
                                                <th scope="col">Mobile No</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Address</th>
                                                <th scope="col">DOB</th>
                                                <th scope="col">Source</th>
                                                {/* <th scope="col">Action</th> */}
                                            </tr>
                                        </thead> : type == 7 ? <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th
                                                    scope="col"
                                                    style={{ width: "20%" }}
                                                >
                                                    <span>Date & </span>
                                                    <span>Time</span>
                                                </th>
                                                <th scope="col">Unsubscribed email requests</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead> :
                                            (
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th
                                                            scope="col"
                                                            style={{ width: "10%" }}
                                                        >
                                                            <span>Date</span>
                                                            <span>Time</span>
                                                        </th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Mobile</th>
                                                        <th scope="col">Email</th>
                                                        {type == 2 && (
                                                            <th scope="col">
                                                                Business Name
                                                            </th>
                                                        )}
                                                        <th scope="col">Website</th>
                                                        <th scope="col">Message</th>
                                                    </tr>
                                                </thead>
                                            )}

                                <tbody>
                                    {
                                        type == 6 ? filteredresult.slice(
                                            (activePage - 1) * pageSize,
                                            activePage * pageSize
                                        ).map((item, index) => (
                                            <tr key={index + 1}>
                                                <td>{++index}</td>
                                                <td>{`${moment(item?.createdAt).format('DD-MM-YYYY H:mm')}`}</td>
                                                <td>{item?.name || "-"}</td>
                                                <td>{item?.merchants_info?.business_name}</td>
                                                <td>{(item?.showMobilePrefix?.label || "") + (item?.mobile || "")}</td>
                                                <td>{item?.email || "-"}</td>
                                                <td>{item?.address || "-"}</td>
                                                <td>{item?.dob ? `${moment(item?.dob).format('DD-MM-YYYY H:mm')}` : "-"}</td>
                                                <td>{getSource(item) || "-"}</td>
                                            </tr>
                                        )
                                        ) : result
                                            .filter((item) => item.type == type)
                                            .slice(
                                                (activePage - 1) * pageSize,
                                                activePage * pageSize
                                            )
                                            .map((item, index) =>
                                                type == 4 ? <tr key={index + 1} >
                                                    <th>{++index}</th>
                                                    <td
                                                        style={{ width: "10%" }}
                                                    >
                                                        {moment
                                                            .tz(
                                                                item.createdAt,
                                                                "Australia/Sydney"
                                                            )
                                                            .format(
                                                                "DD/MM/YYYY HH:MM:SS"
                                                            )}
                                                    </td>

                                                    <td>
                                                        {displayMobile(
                                                            item.mobile
                                                        )}
                                                    </td>
                                                    <td>{item.storeName}</td>

                                                    <td style={{ padding: "5px 1rem !important" }} > <Input type="text" defaultValue={item.comment} onBlur={(event) => submitData(event.target.value, item)} /> </td>
                                                </tr> : type == 3 ? (
                                                    <tr key={index + 1} >
                                                        <th>{++index}</th>
                                                        <td
                                                            style={{ width: "10%" }}
                                                        >
                                                            {moment
                                                                .tz(
                                                                    item.createdAt,
                                                                    "Australia/Sydney"
                                                                )
                                                                .format(
                                                                    "DD/MM/YYYY HH:MM:SS"
                                                                )}
                                                        </td>
                                                        <td>{item.name}</td>
                                                        <td>
                                                            {displayMobile(
                                                                item.mobile
                                                            )}
                                                        </td>
                                                        <td>{item.email}</td>
                                                        {type == 2 && (
                                                            <td>
                                                                {item.business_name}
                                                            </td>
                                                        )}
                                                        <td>{item.website}</td>
                                                        {/* <td>{item.businessName}</td> */}
                                                        {/* <td>
                                                        {item.businessType ==
                                                            "My business type is not mentioned here"
                                                            ? item.otherBusinessType
                                                            : item.businessType}
                                                    </td>
                                                    <td>
                                                        {item.posSystem ==
                                                            "My POS company name is not mention here"
                                                            ? item.otherPosSystem
                                                            : item.posSystem}
                                                    </td> */}
                                                        <td
                                                            style={{
                                                                textDecoration:
                                                                    "underline",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() =>
                                                                onMessageClick(item)
                                                            }
                                                        >
                                                            View
                                                        </td>
                                                        <td style={{ padding: "5px 1rem !important" }} > <Input type="text" defaultValue={item.comment} onBlur={(event) => submitContactData(event.target.value, item)} /> </td>
                                                    </tr>
                                                ) : type == 5 ? (
                                                    <tr key={index + 1} >
                                                        <th>{++index}</th>
                                                        <td
                                                        // style={{ width: "10%" }}
                                                        >
                                                            {moment
                                                                .tz(
                                                                    item.createdAt,
                                                                    "Australia/Sydney"
                                                                )
                                                                .format(
                                                                    "DD/MM/YYYY HH:MM:SS"
                                                                )}
                                                        </td>
                                                        <td>{item.name}</td>
                                                        <td>
                                                            {displayMobile(
                                                                item.mobile
                                                            )}
                                                        </td>
                                                        <td>{item.email}</td>
                                                        <td
                                                            style={{
                                                                textDecoration:
                                                                    "underline",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() =>
                                                                onMessageClick(item)
                                                            }
                                                        >
                                                            View
                                                        </td>
                                                    </tr>
                                                ) : type == 7 ? (
                                                    <tr key={index + 1} >
                                                        <th>{++index}</th>
                                                        <td>
                                                            {moment
                                                                .tz(
                                                                    item.updatedAt,
                                                                    "Australia/Sydney"
                                                                )
                                                                .format(
                                                                    "DD/MM/YYYY HH:MM:SS"
                                                                )}
                                                        </td>
                                                        <td>{item.user_email} {item?.resubscribed && <span style={{ color: "green" }}>(Resubscribed)</span>}</td>
                                                        <td style={{ padding: "5px 1rem !important" }} className="d-flex align-items-center">
                                                            <DeleteIcon style={{ color: 'red', fontSize: '20px' }} onClick={() => handleDeleteUnsubscribe(item)} />
                                                            <Input
                                                                type="text"
                                                                className="m-1"
                                                                style={{ padding: "3px 5px", fontSize: '15px' }}
                                                                onBlur={(event) => {
                                                                    if (event.target.value !== item.comment) {
                                                                        handleUpdateUnsubscribe(event.target.value, item)
                                                                    }
                                                                }}
                                                                defaultValue={item.comment}
                                                                placeholder="Comment"
                                                            />
                                                        </td>
                                                    </tr>
                                                ) :

                                                    (
                                                        <tr key={index + 1}>
                                                            <th>{++index}</th>
                                                            <td
                                                                style={{ width: "10%" }}
                                                            >
                                                                {moment
                                                                    .tz(
                                                                        item.created_date,
                                                                        "Australia/Sydney"
                                                                    )
                                                                    .format(
                                                                        "DD/MM/YYYY HH:MM:SS"
                                                                    )}
                                                            </td>
                                                            <td>{item.name}</td>
                                                            <td>
                                                                {displayMobile(
                                                                    item.mobile
                                                                )}
                                                            </td>
                                                            <td>{item.email}</td>
                                                            {type == 2 && (
                                                                <td>
                                                                    {item.business_name}
                                                                </td>
                                                            )}
                                                            <td>{item.website}</td>
                                                            <td
                                                                style={{
                                                                    textDecoration:
                                                                        "underline",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() =>
                                                                    onMessageClick(item)
                                                                }
                                                            >
                                                                View
                                                            </td>
                                                        </tr>
                                                    )
                                            )
                                    }
                                </tbody>
                            </table>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginTop: "20px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <select
                                        className="custom-select"
                                        style={{
                                            width: "32%",
                                            marginRight: "7px",
                                        }}
                                        onChange={(event) =>
                                            setPageSize(event.target.value)
                                        }
                                        value={pageSize}
                                    >
                                        <option value="15">15</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="75">75</option>
                                        <option value="100">100</option>
                                    </select>
                                    <span>Records per Page</span>
                                </div>
                                <PaginationComponent
                                    totalItems={result.length}
                                    pageSize={pageSize}
                                    onSelect={handlePageChange}
                                />
                            </div>
                        </div>
                    </Fragment>
                ) : (
                    <div>No records found</div>
                )}
            </Fragment>
        );
    };

    return (
        <Fragment>
            <div className="col-lg-7 col-sm-12" style={{ fontSize: "12px" }}>
                <Breadcrumb title={"Contact inquiry"} parent={"Home"} />{" "}
            </div>
            {selectedInquiry && renderMessageModal()}
            {result ? (
                <div className="container-fluid">
                    <div className="row theme-tab">
                        <Tabs
                            className="col-sm-12"
                            defaultIndex={tabIndex}
                            onSelect={(index) => setTabIndex(index)}
                            style={{
                                backgroundColor: "white",
                                borderRadius: "1.5%",
                                boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.16) ",
                                marginBottom: "0px",
                            }}
                        >
                            <TabList className="tabs tab-title">
                                {/* <Tab
                                    style={{
                                        marginTop: "0.5rem",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    Customer
                                </Tab> */}

                                <Tab
                                    style={{
                                        marginTop: "0.5rem",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    Merchant
                                </Tab>
                                <Tab
                                    style={{
                                        marginTop: "0.5rem",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    linky.biz/contact-us
                                </Tab>
                                <Tab
                                    style={{
                                        marginTop: "0.5rem",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    Merchant Call Back Request
                                </Tab>
                                <Tab
                                    style={{
                                        marginTop: "0.5rem",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    Hustlerz Enquiry
                                </Tab>
                                <Tab
                                    style={{
                                        marginTop: "0.5rem",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    Merchants Marketing Customer
                                </Tab>
                                <Tab
                                    style={{
                                        marginTop: "0.5rem",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    Unsubscribe
                                </Tab>
                            </TabList>

                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-12 col-xl-12 xl-100">
                                        <div className="card">
                                            <div
                                                className="card-body"
                                                style={{ padding: "1rem" }}
                                            >
                                                <div className="tab-content-cls">
                                                    {/* <TabPanel>
                                                        {renderTable(1)}
                                                    </TabPanel> */}

                                                    <TabPanel>
                                                        {renderTable(2)}
                                                    </TabPanel>
                                                    <TabPanel>
                                                        {renderTable(3)}
                                                    </TabPanel>
                                                    <TabPanel>
                                                        {renderTable(4)}
                                                    </TabPanel>
                                                    <TabPanel>
                                                        {renderTable(5)}
                                                    </TabPanel>
                                                    <TabPanel>
                                                        {renderTable(6)}
                                                    </TabPanel>
                                                    <TabPanel>
                                                        {renderTable(7)}
                                                    </TabPanel>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
            ) : (
                <div
                    className="loader-box"
                    style={{ height: "100vh", width: "100wh" }}
                >
                    <div
                        className="loader"
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <DisappearedLoading size="medium" color="#514F4E" />
                    </div>
                </div>
            )}
        </Fragment>
    );
}

export default ContactInquiry;
