import React, { useState, useEffect } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { toast } from "react-toastify";
import { Table } from "reactstrap";
import moment from 'moment';
import HashLoader from 'react-spinners/HashLoader';

const Form = (props) => {

    const { merchant_info, merchant_id } = props;
    const [tabIndex, setTabIndex] = useState(0);
    const [apiData, setApiData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tab, selectTab] = useState(null);
    const [allTab, setAllTab] = useState(null);
    const [qrFrontSideData, setQrFrontSideData] = useState(null);
    console.log(apiData, "apiData")

    const fetchQrFrontSideData = () => {

        let postData = { merchant_ref: merchant_info._id };
        client.put(api.fetchQrFronside, postData, (error, response) => {

            if (!error) {
                if (!response.error) {
                    setQrFrontSideData(response.result.data)
                } else {
                    toast.error("Internal error occured.Please contact support");
                }
            } else {
                toast.error("Internal error occured.Please contact support");
            }
        });
    }

    const groupBy = (values, keyFinder) => {
        // using reduce to aggregate values
        return values.reduce((a, b) => {
            // depending upon the type of keyFinder
            // if it is function, pass the value to it
            // if it is a property, access the property
            const key = typeof keyFinder === 'function' ? keyFinder(b) : b[keyFinder];

            // aggregate values based on the keys
            if (!a[key]) {
                a[key] = [b];
            } else {
                a[key] = [...a[key], b];
            }

            return a;
        }, {});
    };

    useEffect(() => {
        if (!qrFrontSideData) {
            fetchQrFrontSideData();
        }
    }, [])

    const getAllData = () => {
        client.post(api.fetchForms, { merchant_id: merchant_id }, (error, response) => {
            if (!error && !response.error) {
                const result = groupBy(response.data || [], ({ formId }) => formId);
                const aaap = qrFrontSideData?.minisite_contact_form?.map(e => e._id);
                const finalKeys = [...new Set([...Object.keys(result), ...aaap])];
                const aaal = finalKeys?.map(e => {
                    return {
                        id: e,
                        name: qrFrontSideData?.minisite_contact_form?.filter(ee => ee._id == e)?.[0]?.title || "Deleted"
                    }
                })
                setAllTab(aaal);
                selectTab(finalKeys?.[0] || null);
                setApiData(result);
                setTimeout(() => {
                    setLoading(false)
                }, 1000)
            }
        });
    }

    useEffect(() => {
        if (qrFrontSideData) { // add condition
            getAllData();
        }
    }, [qrFrontSideData])

    function mergeCountryCode(data) {
        data.forEach(entry => {
            if (entry.formData && entry.formData["Mobile Number"] && entry.formData["country_code"]) {
                entry.formData["Mobile Number"] = entry.formData["country_code"] + entry.formData["Mobile Number"];
                delete entry.formData["country_code"];
            }
        });
        return data;
    }

    const renderTable = () => {
        const data = mergeCountryCode(apiData[tab]);
        return <div className='m-4'>
            {/* <p className='mb-3 font-weight-bold' style={{ fontSize: '16px', color: 'black' }}> {t('contact_form.contact_us')} </p> */}
            <p className='mb-3 font-weight-bold' style={{ fontSize: '16px', color: 'black' }}> {qrFrontSideData?.minisite_contact_form?.filter(e => e._id == tab)?.[0]?.title} </p>
            <Table className='table--bordered' responsive>
                <thead>
                    <tr>
                        <th style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", backgroundColor: '', color: '' }}>Date & Time</th>
                        {
                            Object.keys(data[0].formData).map(e => <th style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px", backgroundColor: '', color: '' }}>{e}</th>)
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        data?.sort((a, b) => {
                            return (moment(b?.createdAt) - moment(a?.createdAt))
                        })?.map(e => <tr>
                            <td style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px" }}>{moment(e?.createdAt).format('DD-MM-YYYY h:mm A')}</td>
                            {
                                Object.values(e.formData).map(a => <td style={{ border: "1px solid #dddddd", textAlign: "left", padding: "8px" }}>{a}</td>)
                            }
                        </tr>)
                    }
                </tbody>
            </Table>
        </div>
    }

    return (
        <>
            {
                loading ?
                    <div
                        className="w-100 text-center flex align-items-center justify-content-center"
                        style={{ marginTop: "10%", marginBottom: "10%", height: '30vh' }}
                    >
                        <HashLoader color="#45C7A2" loading={loading} size={50} />
                    </div> :
                    <div>
                        <div>
                            <Tabs>
                                <TabList className="d-flex tabs tab-title">
                                    {
                                        allTab?.map((val, ind) => {
                                            return (
                                                <>
                                                    {
                                                        val ? (
                                                            <TabList
                                                                onClick={() => {
                                                                    selectTab(val.id)
                                                                }}
                                                                // className={tab == val.id ? 'selectedTabStyle' : 'tabStyle'}
                                                                key={ind}
                                                            >
                                                                <Tab className={`px-3 ${tab == val.id ? "react-tabs__tab--selected" : ""}`}>
                                                                    {val.name}
                                                                </Tab>
                                                            </TabList>
                                                        ) : null
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </TabList>
                                <TabPanel>
                                    {
                                        apiData && tab && apiData[tab]?.length >= 1 && renderTable()
                                    }
                                </TabPanel>
                            </Tabs>

                            <div className='p-3 d-flex align-items-center justify-content-center w-100'>
                                {
                                    apiData && tab && apiData[tab]?.length >= 1 ? <div className='w-100 flex flex-column' style={{ gap: "10px" }}></div> :
                                        <div className="d-flex flex-column align-items-center py-3 justify-content-center" style={{ marginTop: "7rem" }}>
                                            <img src="/assets/images/no_meetings.png" alt="" style={{ width: "100px" }} />
                                            <h5 style={{ fontWeight: 500, color: "black", marginBottom: "0.3rem" }}>No record!</h5>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default Form