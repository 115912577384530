import React, { useEffect, Fragment, useState, useSearchParams } from "react";
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormInput, FormGroup, Col, Row, Input, CustomInput } from 'reactstrap';
import AddFolderModal from './AddFolderModal';
import AddImageModal from './AddImageModal';
import RenderLinkView from './RenderLinkView';
import EditHelpTopicModal from './EditHelpTopicModal';
import { Toast } from "react-bootstrap";
import useForm from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import client from "../../Api/HTTPClient";
import {getFolderContent, doDelete, filesSearch} from "../../Api/libDropbox";
import api from "../../constant/apilist";
import { DisappearedLoading } from "react-loadingg";
import { Link, useHistory } from "react-router-dom";
import Dropzone from "../../components/dropzone/DropZoneFieldHelp";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index"
import ReactDragListView from "react-drag-listview";
import { Link as NavLink } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useLocation } from "react-router";

const ImageGalleryFolder = (props) => {

  let location = useLocation();


    let [loading, setloading] = useState(true);
    let [searchLoading, setSearchLoading] = useState(false);
    let [loadingbtn, setloadingbtn] = useState(false);
    let [editformOpen, seteditformOpen] = useState(false);
    let [editmodalOpen, seteditmodalOpen] = useState(false);
    let [addmodalOpen, setaddmodalOpen] = useState(false);
    let [addimagemodalOpen, setaddimagemodalOpen] = useState(false);
    let [editcatmodalOpen, seteditcatmodalOpen] = useState(false);
    let [statusDetails, setStatusDetails] = useState(null);
    let [folderList, setfolderList] = useState([]);
    let history = useHistory();
    let [inputList, setInputList] = useState([]);
    let [inputCat, setInputCat] = useState('');
    let [inputDesc, setinputDesc] = useState('');
    let [searchText, setSearchText] = useState('');
    let [editItem, seteditItem] = useState(null);
    let [deleteItem, setdeleteItem] = useState(null);
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState(false);
    const [redirectData, setRedirectData] = useState(null);
    const [deleteModal, setdeleteModal] = useState(false);
    const [folderPath, setFolderPath] = useState(null);
  // console.log(folderPath, "folderPath")
  
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    
    if (query.get('path')) {
      setFolderPath(query.get('path'))
    } else {
      setFolderPath('')
    }
    // setloading(false)
  }, [location])

  const slider = folderList
  { slider && console.log(slider) }
  const tableDragProps = {
      onDragEnd(fromIndex, toIndex) {
          let NewStoreTableData = slider;
          let item = NewStoreTableData.splice(fromIndex, 1)[0];
          NewStoreTableData.splice(toIndex, 0, item);
          NewStoreTableData = NewStoreTableData;
          client.post(api.edit_cuisines, { order: NewStoreTableData, type: 2 }, async function (error, response) {
              if (!error) {
                  if (typeof response.error !== "undefined") {
                      fetchData();
                      toast.success(response.message);
                  }
              }
          })
      },
      nodeSelector: "tr",
    };
    
    const handleRedirect = (id) => {
		console.log(id, "id");
		// console.log(getData, "getData");
		// getData.map((item) => {
		// 	if (item.id == id) {
				setRedirectData(id);
				setRedirect(true);

				// console.log(redirectData, "redirectData");
			// }
			// console.log(item, "item");
		// });
		// history.push(`${process.env.PUBLIC_URL}/settings/QuizEdit/${id}`);
	};

  const handleCatDelete = (e) => {
    e.preventDefault();
    setloadingbtn(true);

    doDelete(deleteItem, (success) => {
      if (success) {
        toast.success("Deleted Successfully");
        toggleDeleteModal();
        fetchData();
      } else {
        toast.error("Internal error occured. Please contact support 2");
        
      }
      setloadingbtn(false);
    })
    // client.post(api.deleteimgfolder, { folder: deleteItem }, async function (error, response) {
    //   if (!error) {
    //     if (typeof response.error !== "undefined") {
    //       try {
    //         if (!response.error) {
    //           toast.success("Deleted Successfully");
    //           toggleDeleteModal();
    //           fetchData();
    //         } else {
    //           toast.error(response.message);
    //         }
    //       } catch (e) {
    //         toast.error("Internal error occured. Please contact support 2");
    //       }
    //     } else {
    //       toast.error("Internal error occured. Please contact support 2");
    //     }
    //   } else {
    //     toast.error("Internal error occured. Please contact support 3");
    //   }
    //   setloadingbtn(false);
    // });

  }

  // async function fetchData() {
  //   client.post(api.fetchimgfolder, { show_all: true }, async function (error, response) {
  //     // console.log(response)
  //     if (!error) {
  //       if (typeof response.error !== "undefined") {
  //         try {
  //           if (!response.error) {
  //             sethelpTopic(response.result.data);
  //           }
  //         } catch {

  //         }
  //       }
  //     }
  //     setloading(false);
  //   });
  // }
    
  const renderDeleteModal = () => {
    return (
      <Modal isOpen={deleteModal} toggle={toggleDeleteModal} className="modal-body" centered={true}>
        <form className="theme-form" noValidate="" onSubmit={(e) => handleCatDelete(e)}>
          <ModalHeader><h5 style={{ fontSize: "1.15rem" }}>Delete Folder</h5></ModalHeader>
            <ModalBody>
              <ModalBody style={{ padding: "16px 0" }}>
              <h5 style={{ fontSize: "1.15rem" }}>
                {}
                Are you sure you want to delete folder?
              </h5>
              </ModalBody>
              <ModalFooter>
                <Button color="default" onClick={toggleDeleteModal}>Not sure!</Button>
                <Button color="primary" type="submit"  >Yes Please!</Button>
              </ModalFooter>
            </ModalBody>
        </form>
      </Modal>
    );
  }

  const handleEditClick = (main_topic, description, subtopic, catid) => {
    setInputCat(main_topic);
    setinputDesc(description);
    setInputList(subtopic);
    seteditItem(catid);
    seteditcatmodalOpen(true);
  }

  const deleteClick = (id) => {
    setdeleteItem(id);
    setdeleteModal(true);
  }
  
  const toggleDeleteModal = () => {
    setdeleteModal(!deleteModal)
  }

  const fetchData = () => {
    setSearchText("")
    getFolderContent(folderPath, (error, res) => {

      if (!error) {
        setfolderList(res)
        // console.log(res, "folderList")
      }
      setloading(false);
      
    })
  }

  useEffect(() => { 
    if (folderPath != null) {
      setloading(true);

      setfolderList([])
      fetchData();
    }

  }, [folderPath]);

  const handleSearchSubmit = async (event) => {
    event.preventDefault();
    
    // console.log(searchText, "searchText")
    if (searchText) {
      setSearchLoading(true)

      let result = await filesSearch(searchText)
      setfolderList(result)

      // console.log(result, "result")
      setSearchLoading(false)
    } else {
      fetchData()
    }

	};

  return (
      <Fragment>
        {deleteItem ? renderDeleteModal() : null}
          
      <div className="row">
        <div className="" style={{ fontSize: "12px" }}>
          <Breadcrumb title="Image Gallery" parent="Home" />
          
        </div>
        <div className="col-lg-8 col-sm-12 row justify-content-end" >
          <div className="btn-group" style={{ paddingTop: "10px", marginLeft: "0.4rem", marginRight: "0.6rem", marginBottom: "0rem", width: "auto", display: "flex", justifyContent: "flex-end", alignItems: "center" }} role="group" aria-label="Basic example">
            {true && <button className="btnCashback custBtnAdmin" onClick={() => setaddmodalOpen(true)} ><i style={{ margin: "0 7px 0 0", color: "#327ebc" }} id="Add New" title="Add New" className="fa fa-plus"></i>Add Folder</button>}
          </div>
          <div className="btn-group" style={{ paddingTop: "10px", marginLeft: "0.4rem", marginRight: "0.6rem", marginBottom: "0rem", width: "auto", display: "flex", justifyContent: "flex-end", alignItems: "center" }} role="group" aria-label="Basic example">
            {true && <button className="btnCashback custBtnAdmin" onClick={() => setaddimagemodalOpen(true)} ><i style={{ margin: "0 7px 0 0", color: "#327ebc" }} id="Add New" title="Add New" className="fa fa-plus"></i>Add Image</button>}
          </div>
        </div>
        {/* <div className="col-lg-5 col-sm-12 ">
          <div className="btn-group" style={{ paddingTop: "10px", marginLeft: "0.4rem", marginRight: "0.6rem", marginBottom: "0rem", width: "auto", display: "flex", justifyContent: "flex-end", alignItems: "center" }} role="group" aria-label="Basic example">
            {true && <button className="btnCashback custBtnAdmin" onClick={() => setaddimagemodalOpen(true)} ><i style={{ margin: "0 7px 0 0", color: "#327ebc" }} id="Add New" title="Add New" className="fa fa-plus"></i>Add Image</button>}
          </div>
        </div> */}
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card merchantSetttings" style={{ border: '1px solid rgb(223, 232, 241)', borderRadius: '3px'}}>
              <div className="card-header " style={{display : 'flex'}}>

          <div >
                {folderPath == "" ?
                  <h7 className="font-weight-bold">Images Folder</h7>
                  :
                  <h7 className="font-weight-bold">
                    <span style={{color : '#3498db', cursor : "pointer"}} onClick={() => {
                      history.goBack()
                    }}>
                      <i className="icon-arrow-left" style={{ paddingRight: '5px' }}></i>
                    </span>
                    Image Gallery
                  </h7>
                  }
                  </div>
                  <div style={{ display: "flex", justifyContent : 'flex-end', flex : 1 }}>
                  {
                  
                    <form
                      onSubmit={(event) => handleSearchSubmit(event)}
                      >
                      <div>
                        <input
                          style={{
                            border: "#dfe8f1 solid 1px",
                            borderRadius: "3px",
                            padding: "10px",
                            fontSize: "0.75rem",
                            outlineColor: '#dfe8f1'
                          }}
                          type="text"
                          placeholder="Search"
                          value={searchText}
                          onChange={(event)=>setSearchText(event.target.value)}
                        />

                        {/* {this.state.searchValue && */}

                        <Button disabled={searchLoading} color="warning" style={{ marginLeft: "10px", marginRight: "10px" }} name="search">
                          {!searchLoading ? 'Search' : <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>}
                        </Button>

                        {/* } */}
                      </div>
                    </form>
                  }
                </div>

              </div>
              <div className="card-body" style={{ padding: "1rem" }}>

                {!loading ?
                  <div className="table-responsive custom-scrollbar">
                    {/* <ReactDragListView {...tableDragProps}> */}
                      <table className="table table-border-horizontal" >
                        <thead>
                          <tr style={{background: '#f9fafe'}}>
                            <th scope="col" >Folder</th>
                            <th scope="col" >Action</th>
                          </tr>
                        </thead>
                        <tbody>
                        {folderList && folderList.length > 0 ? folderList.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <RenderLinkView item={item} />
                              </td>
                              <td>
                                {item[".tag"] == "folder" &&
                                  <NavLink to={`image-gallery?path=${folderPath.replace(/^\/+/, '')}/${item.name}`} item={item} style={{ verticalAlign: 'middle' }}>
                                    <VisibilityIcon title="Add Question" style={{ marginRight: '6px', fontSize: '1.2rem', color: '#327ebc', verticalAlign: 'middle' }} />
                                  </NavLink>
                                }
                                <span style={{ fontSize: "1.2rem", color: "red", verticalAlign: 'middle' }} onClick={() => deleteClick(item.path_lower)}>
                                  <i className="fa fa-trash"></i>
                                </span>
                              </td>
                            </tr>
                          )
                        })
                          :
                          (
                            <tr key={1}>
                              <td colSpan={2}>
                                No Data Found
                              </td>
                            </tr>
                          )
                            
                        }
                        </tbody>
                      </table>
                    {/* </ReactDragListView> */}
                  </div>
                  :
                  <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                    <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                      <DisappearedLoading size="medium" color="#514F4E" />
                    </div>
                  </div>}
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddFolderModal isOpen={addmodalOpen} onClose={(fetch_data = false) => {
        setaddmodalOpen(false);
        setInputCat('');
        seteditItem(null);
        if (fetch_data) {
          fetchData();
        }
      }} />
      <AddImageModal folderName={folderPath} isOpen={addimagemodalOpen} onClose={(fetch_data = false) => {
        setaddimagemodalOpen(false);
        setInputCat('');
        seteditItem(null);
        if (fetch_data) {
          fetchData();
        }
      }} />

    </Fragment>
  );

}

export default ImageGalleryFolder;