import React, { useState, useRef  } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormGroup, Col, Row, Input, CustomInput } from 'reactstrap';
import useForm from "react-hook-form";
import CKEditors from "react-ckeditor-component";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Editor } from '@tinymce/tinymce-react';


const EditHelpQuestion = (props) => {
	let propsData = props.redirectData;
	const [tabIndex, setTabIndex] = useState(0);
	const [postData, setPostData] = useState(null);
	const [content, setcontent] = useState(propsData.answer);
	let [inputQUE, setinputQUE] = useState(propsData.questions);
	let [loadingbtn, setloadingbtn] = useState(false);
	let [showerrorstatus, setshowerrorstatus] = useState(false);

	const { handleSubmit, register, errors } = useForm();


	//console.log(props.redirectData.id, "redirectdata");
	//console.log(propsData, "props");


	const updateContent = (newContent) => {
		setcontent(newContent);
    }

    const onChange = (evt) => {
		var newContent = evt.editor.getData();
		setcontent(newContent);
		console.log(evt);
	}
	
	const handleCatInputChange = (e) => {
        const { value } = e.target;
        setinputQUE(value);
    }

	const onSubmit = form_data => {

        if (form_data !== '') {

            setloadingbtn(true);
			let data = { questionId: propsData._id, questions: form_data.questions, answer: content, type: 1 };

            client.post(api.editHelpquestion, data, async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
								toast.success("Updated Successfully");
								//props.toggleRedirect(true);

                            } else {
                                toast.error(response.message);
                            }
                        } catch (e) {
                            toast.error("Internal error occured. Please contact support 2");
                        }
                    } else {
                        toast.error("Internal error occured. Please contact support 2");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support 3");
                }
                setloadingbtn(false);
            });
        }
        else {
            setshowerrorstatus(true);
            errors.showMessages();
        }
	}
	
	const editorRef = useRef(null);
	const log = () => {
		if (editorRef.current) {
			console.log(editorRef.current.getContent());
			setcontent(editorRef.current.getContent());
		}
	};

	return (
		<div className="container-fluid" style={{paddingTop: '50px'}}>
          <div className="row">
            <div className="col-sm-12">
              <div className="card merchantSetttings" style={{ border: '1px solid rgb(223, 232, 241)', borderRadius: '3px' }}>
                <div className="card-header">
					<h6 onClick={() => props.toggleRedirect(true)} className="font-weight-bold" style={{cursor: 'pointer'}}>
						<i className="icon-arrow-left" style={{ color: "black", paddingRight: '5px' }}></i>
						Edit {propsData?.questions}
					</h6>
                </div>
				<div className="card-body" style={{ padding: "1.5rem" }}>
					<form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
						<Row form>
							<FormGroup className="w-100">
								<Label for="level_title">Question</Label>
								<Row form>
									<Col md={12}>
										<input  className="form-control"  type="input" id="questions" value={inputQUE} name="questions" placeholder="Questions" onChange={e => handleCatInputChange(e)} ref={register({ required: true })}/>
										<span style={{color: '#ff5370'}}>{errors.main_topic && 'Topic name is required'}</span>
									</Col>
								</Row>
							</FormGroup>
							<FormGroup className="w-100">
								<Label for="level_title">Answer</Label>
								{/* <Row form>
									<Col md={12}>
										<CKEditors
											activeclassName="p10"
											content={content}
											events={{
											// "blur": onBlur,
											// "afterPaste": afterPaste,
												"change": onChange.bind(this)
											}}
										/>	
									</Col>
								</Row> */}
								<Row>
									<Col md={12}>
										<Editor
											apiKey='ej475rfcltitcwhe6rtacdk8jt9s70vatxi4yofu0aegrpe4'
											onInit={(evt, editor) => editorRef.current = editor}
											initialValue={content}
											init={{
											height: 500,
											menubar: true,
											selector: 'textarea',
											plugins: [
												'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'imagetools',
												'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
												'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount', 'media', 'image'
											],
											toolbar: 'undo redo | blocks | ' +
												'bold italic forecolor | alignleft aligncenter ' +
												'alignright alignjustify | bullist numlist outdent indent | ' +
												'removeformat | help',
											content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
											}}
											onChange={log}
											onBlur={log}
											onFocus={log}
										/>
									</Col>
								</Row>
							</FormGroup>
						</Row>
						<div className="modal-footer">
						{!loadingbtn ?
							<>
								<Button color="primary" type="submit" onClick={() => setshowerrorstatus(true)}>Save</Button>
							</>
							:
							<div className="loader-box pull-right" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 20, width: 20 }}></span></div>
						}
						</div>	
					</form>			
                </div>
              </div>
            </div>
          </div>
        </div>
	);
};

export default EditHelpQuestion;
