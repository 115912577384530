import React, { useEffect, useState, useRef } from 'react';
import * as PropTypes from 'prop-types';
import ReactCropper from 'react-cropper';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import Slider from 'rc-slider';
import 'cropperjs/dist/cropper.css';
import 'rc-slider/assets/index.css';

/** CropperModel functional component */
function CropperModel(props) {

	const { labels, file } = props;
	const [cropper, setCropper] = useState(null);
	const [image, setImage] = useState(null);
	const [zoom, setZoom] = useState(props.initialZoom);
	const cropperRef = useRef(null);
	const [zoomValue, setZoomValue] = useState(0);
	const [width, setwidth] = useState(40)
	const [height, setheight] = useState(40)
	// const [cropper, setCropper] = useState();

	useEffect(() => {
		if (file !== null) {
			const reader = new FileReader();
			reader.addEventListener('load', () => {
				setImage(reader.result);
				cropper && cropper
					.zoomTo(props.initialZoom)
			});
			reader.readAsDataURL(file);
		} else {
			setImage(null);
			setCropper(null);
		}
	}, [props, file, cropper]);

	const onConfirm = () => {
		if (!cropper) {
			return;
		}

		const croppedCanvas = {
			minWidth: 854, maxWidth: 1200,
			minHeight: 480, maxHeight: 600,
			imageSmoothingQuality: 'medium',
			...props.croppedCanvasProps,
		};

		const canvasData = cropper.getCroppedCanvas(croppedCanvas);
		const croppedFile = canvasData.toDataURL()
		typeof props.onConfirm === 'function' && props.onConfirm(croppedFile);
		typeof props.onCompleted === 'function' && props.onCompleted(croppedFile);
		setImage(null);
		setCropper(null);

	};

	const handleClose = () => {
		setCropper(false);
		setImage(null);
		typeof props.onDiscard === 'function' && props.onDiscard(file);
		typeof props.onCompleted === 'function' && props.onCompleted();
	};

	return (
		<Modal show={(!!file && !!image)} onHide={handleClose} className="p-0" centered
			animation={true} >
			{/* <Modal.Header closeButton>
				<Modal.Title>{labels.heading}</Modal.Title>
			</Modal.Header> */}
			<Modal.Body className="text-center p-0" >
				{image && (
					<ReactCropper

						src={image}
						style={{ height: '300px', width: "100%" }}
						aspectRatio={width / height}
						zoomTo={zoomValue}
						zoomable={true}
						zoomOnWheel={true}
						zoomOnTouch={true}
						wheelZoomRatio={0.1}
						guides={false}
						toggleDragModeOnDblclick={false}
						// crop={onCrop}
						ref={cropperRef}
						dragMode='move'
						cropBoxMovable={true}
						cropBoxResizable={false}
						// cropend={data => console.log(data)}
						preview=".img-preview"
						onInitialized={(instance) => {
							setCropper(instance);
						}}

					// {...props.cropperProps}
					/>
				)}

				<div className="" style={{ width: "100%", }}>

					<Slider max={3} style={{ marginTop: "20px", marginBottom: "20px" }}

						step={0.1}
						trackStyle={{ backgroundColor: '#24424C' }}
						railStyle={{ backgroundColor: '#ABE2FB' }}
						value={zoom}
						onChange={(value) => {
							setZoom(value);
							cropper.zoomTo(value);
						}} />
				</div>

				<div className="clearfix" />

				<Row className="">

					<Col lg={6} md={6}>
						<div
							className="img-preview border rounded bg-light shadow-sm"
							style={{ width: "300px", height: "150px", overflow: 'hidden' }}
						/>
					</Col>
					<Col lg={6} md={6} className="d-flex align-items-center flex-column">

						<Button variant="primary" className="my-auto" onClick={onConfirm}>
							{/* {labels.Crop Image} */}
							Crop Image
						</Button>

						<Button variant="secondary" className="my-auto px-5" onClick={handleClose}>
							{/* {labels.Cencel} */}
							Cancel
						</Button>
					</Col>
				</Row>

			</Modal.Body>
			{/* <Modal.Footer className="d-block">

			</Modal.Footer> */}
		</Modal>
	);
}

CropperModel.propTypes = {
	initialZoom: PropTypes.number,
	initialRotate: PropTypes.number,
	mime: PropTypes.string,
	quality: PropTypes.number,
	file: PropTypes.object,
	labels: PropTypes.shape({
		heading: PropTypes.string,
		confirm: PropTypes.string,
		discard: PropTypes.string,
		zoom: PropTypes.string,
		rotate: PropTypes.string,
	}),
	cropperProps: PropTypes.object,
	modalProps: PropTypes.object,
	croppedCanvasProps: PropTypes.object,
	onDiscard: PropTypes.func,
	onCompleted: PropTypes.func,
}

CropperModel.defaultProps = {
	initialZoom: 0.3,
	initialRotate: 0,
	mime: null,
	quality: 70,
	labels: {
		heading: 'Crop Image',
		confirm: 'Confirm',
		discard: 'Discard',
		zoom: 'Zoom',
		rotate: 'Rotate',
	},
	modalProps: {},
	cropperProps: {},
	croppedCanvasProps: {},
	onDiscard: () => { },
	onCompleted: () => { },
}

export default CropperModel;