import React, { useState,forwardRef } from "react";
import DatePicker from 'react-datepicker';
import {mdiCalendarMonthOutline} from "@mdi/js";
import Icon from "@mdi/react";

const DateRangePicker = (props) => {
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="d-flex" style={{border:'1px solid #ccc',width:'fit-content',borderRadius:'5px'}}>
        <div className="d-flex">
        <Icon path={mdiCalendarMonthOutline} size={1} color={"#646777"} style={{ cursor: "pointer", margin:'0.4rem 0.49rem' }} />
        </div>
        <div style={{borderRight:'1px solid #ccc'}}></div>
        <button className="example-custom-input" onClick={onClick} ref={ref} style={{background:'transparent',border:'none'}}>
            {value}
        </button>
    </div>
  ));

    return (
        <div style={{ flexDirection: 'row', display: 'flex', gap:'10px' }}>
            <DatePicker
                selected={props.startDate}
                //onChange={date => setStartDate(date)}
                onChange={props.onStartDateChange}
                selectsStart
                startDate={props.startDate}
                minDate={props.minDate}
                endDate={props.endDate}
                dateFormat="dd/MM/yyyy"
                placeholderText="From"
                dropDownMode="select"
                customInput={<CustomInput/>}
                popperPlacement="right"
            />
            <DatePicker
                selected={props.endDate}
                //onChange={date => setEndDate(date)}
                onChange={props.onEndDateChange}
                selectsEnd
                startDate={props.startDate}
                endDate={props.endDate}
                minDate={props.startDate}
                dateFormat="dd/MM/yyyy"
                placeholderText="To"
                dropDownMode="select"
                customInput={<CustomInput />}
                popperPlacement="right"
            />
        </div >

    );

}

export default DateRangePicker;