import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup, Col, Row } from "reactstrap";
import QuizTabs from "./QuizTabs";
import SurveyTabs from "./SurveyTabs";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import { DisappearedLoading } from "react-loadingg";
import { toast } from "react-toastify";
import QuizEditTabs from "./QuizEditTabs";

const QuizTable = () => {
	const [openModal, setOpenModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [campaignName, setCampaignName] = useState("");
	const [quizform, setQuizform] = useState("select");
	const [continued, setContinued] = useState(false);
	const [getData, setGetData] = useState([]);
	const [subState, setsubState] = useState(false);
	const [deleteid, setDeleteid] = useState(0);
	const [redirect, setRedirect] = useState(false);
	const [redirectData, setRedirectData] = useState(null);

	useEffect(() => {
		getAllData();
	}, []);

	const getAllData = async () => {
		setLoading(true);
		const postData = {};
		// setLoading(false);

		await client.get(api.getQuizdata, postData, (error, response) => {
			if (!error) {
				if (!response.error) {
					// console.log(response.result.data, "response");
					setGetData(response.result.data);
					console.log(getData, "getData");
					setLoading(false);
				}
			} else {
				console.log(response, "response");
			}
		});
	};

	const continueFunc = () => {
		console.log(campaignName.length, "length");
		setsubState(true);
		if (campaignName.length > 0) {
			setContinued(true);
		} else {
			console.log("error validation");
		}
	};
	console.log(getData, "getData");

	const renderModal = () => {
		return (
			<Modal
				isOpen={openModal}
				// toggle={this.toggleAddVersionModal}
			>
				<ModalHeader>New Quiz/Survey</ModalHeader>
				<ModalBody>
					<Row>
						<Col md={12}>
							<FormGroup>
								<Label for="appVersion">Give it a name</Label>
								<Input
									type="text"
									name="campaignName"
									required
									placeholder=""
									onChange={(event) => {
										setCampaignName(event.target.value);
									}}
								/>
								{campaignName.length == 0 && subState ? (
									<h6
										style={{
											color: "red",
											fontSize: "13px",
											marginLeft: "2%",
											marginTop: "2%",
										}}
									>
										Required
									</h6>
								) : null}
							</FormGroup>
						</Col>
					</Row>

					<Row>
						<Col md={12}>
							<FormGroup>
								<Label for="appType">What are you creating? </Label>
								<Input
									type="select"
									name="app_type"
									required
									defaultValue="select"
									onChange={(event) => {
										setQuizform(event.target.value);
									}}
								>
									<option disabled value="select">
										Select Play Type
									</option>
									<option value="quiz">Quiz</option>
									<option value="survey">Survey</option>
								</Input>
								{quizform == "select" && subState ? (
									<h6
										style={{
											color: "red",
											fontSize: "13px",
											marginLeft: "2%",
											marginTop: "2%",
										}}
									>
										Please select from dropdown
									</h6>
								) : null}
							</FormGroup>
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button color="default" onClick={() => setOpenModal(false)}>
						Cancel
					</Button>
					<Button color="primary" onClick={continueFunc}>
						Continue
					</Button>
				</ModalFooter>
			</Modal>
		);
	};

	const handleDelete = async (id, defaultquiz) => {
		console.log(id, "id");
		if (defaultquiz == true) {
			toast.error("Default Quiz can't deleted");
		} else {
			setDeleteModal(!deleteModal);
			setDeleteid(id);
		}
	};
	const handleRedirect = (id) => {
		console.log(id, "id");
		// console.log(getData, "getData");
		getData.map((item) => {
			if (item.id == id) {
				setRedirectData(item);
				setRedirect(true);

				// console.log(redirectData, "redirectData");
			}
			console.log(item, "item");
		});
		// history.push(`${process.env.PUBLIC_URL}/settings/QuizEdit/${id}`);
	};

	const handleDeletebtn = async () => {
		console.log(deleteid, "deleteid");
		const postData = {
			id: deleteid,
		};
		await client.post(api.deleteQuizdata, postData, (error, response) => {
			console.log(response, "delete response");
			if (!error) {
				if (!response.error) {
					toast.success("Deleted Successfully");
					setDeleteModal(false);
					getAllData();
				} else {
					toast.error("Server error");
				}
			} else {
				toast.error("Server error,please contact support");
			}
		});
	};
	const renderTable = () => {
		return (
			<div className="table-responsive">
				<div
					className="btn-group pull-right"
					style={{ paddingBottom: "0.5rem", paddingTop: "0.5rem" }}
					role="group"
					aria-label="Basic example"
				>
					<button
						className="btnAddNewVersion custBtnAdmin"
						onClick={() => {
							setOpenModal(true);
						}}
					>
						<i className="fa fa-plus-square-o" style={{ margin: "0 7px 0 0", color: "#4466f2" }}></i> New&nbsp;Quiz/Survey{" "}
					</button>
				</div>
				<table className="table table-border-horizontal">
					<thead>
						<tr style={{background: '#f9fafe'}}>
							<th scope="col" style={{ width: "7%" }}>
								#
							</th>
							<th scope="col" style={{ width: "10%" }}>
								<p>Added Date</p>
								<p>Time</p>
							</th>
							<th scope="col" style={{ width: "8%" }}>
								Type
							</th>
							<th scope="col" style={{ width: "8%" }}>
								App Type
							</th>
							<th scope="col" style={{ width: "10%" }}>
								Campaign Name
							</th>
							<th scope="col" style={{ width: "8%" }}>
								No of questions
							</th>
							<th scope="col" style={{ width: "8%" }}>
								Total Cashback
							</th>
							<th scope="col" style={{ width: "10%" }}>
								Start Date
							</th>
							<th scope="col" style={{ width: "10%" }}>
								Expire on
							</th>
							<th scope="col" style={{ width: "9%" }}>
								Status
							</th>
							<th scope="col" style={{ width: "8%" }}>
								Action
							</th>
						</tr>
					</thead>
					<tbody>
						{!loading ? (
							<>
								{getData.length !== 0
									? getData
											.map((item, index) => {
												const singleQues = item.singleQues;
												const yesnoQues = item.yesnoQues;
												const multipleQues = item.multipleQues;
												const pictureQues = item.pictureQues;
												let singleQuesTotal = 0;
												let yesnoQuesTotal = 0;
												let multipleQuesTotal = 0;
												let pictureQuesTotal = 0;

												if(singleQues.length > 0){
													singleQuesTotal = singleQues.reduce(function(prev, current) {
														return prev + +current.points
													}, 0);
												}

												if(yesnoQues.length > 0) {
													yesnoQuesTotal = yesnoQues.reduce(function(prev, current) {
														return prev + +current.points
													}, 0);
												}

												if(multipleQues.length > 0){
													multipleQuesTotal = multipleQues.reduce(function(prev, current) {
														return prev + +current.points
													}, 0);
												}

												if(pictureQues.length > 0){
													pictureQuesTotal = pictureQues.reduce(function(prev, current) {
														return prev + +current.points
													}, 0);
												}

												let total_cashback = singleQuesTotal+yesnoQuesTotal+multipleQuesTotal+pictureQuesTotal;

												return (
													<tr key={item.id}>
														<th scope=" row" style={{ width: "7%" }}>
															{" "}
															{getData.length - index}
														</th>
														<td style={{ width: "10%" }}>
															{item.date} {item.time}
														</td>
														<td style={{ width: "8%" }}>Quiz</td>
														<td style={{ width: "8%" }}>{item.appType}</td>
														<td style={{ width: "10%", color: "#327ebc" }} onClick={() => handleRedirect(item.id)}>
															{item.campaignName}
														</td>
														<td style={{ width: "8%" }}>{item.questionLength}</td>
														<td style={{ width: "8%" }}>A${total_cashback}</td>
														<td style={{ width: "10%" }}>
															{item.date} {item.time}
														</td>
														<td style={{ width: "10%" }}>{item.expiryDateTime}</td>
														<td style={{ width: "9%", color: "#327ebc" }}>{item.status}</td>
														<td style={{ width: "8%" }}>
															<i
																style={{
																	color: "red",
																	fontSize: "15px",
																	margin: "0 7px 0 0",
																}}
																className="fa fa-trash-o"
																onClick={() => handleDelete(item.id, item.defaultquiz)}
															>
																{" "}
															</i>
															{item.defaultquiz == false ? (
																item.status == "Publish" ? (
																	<i className="fa fa-eye" onClick={() => handleRedirect(item.id)}></i>
																) : (
																	<i className="fa fa-edit" onClick={() => handleRedirect(item.id)}></i>
																)
															) : (
																<i className="fa fa-edit" onClick={() => handleRedirect(item.id)}></i>
															)}
														</td>
													</tr>
												);
											})
											.reverse()
									: null}
							</>
						) : (
							<div className="loader-box">
								<div className="loader" style={{ display: "flex", alignItems: "center" }}>
									<DisappearedLoading size="medium" color="#514F4E" />
								</div>
							</div>
						)}
					</tbody>
				</table>
			</div>
		);
	};

	if (quizform == "quiz" && continued) {
		return <QuizTabs campaignName={campaignName} />;
	} else if (quizform == "survey" && continued) {
		return <SurveyTabs campaignName={campaignName} />;
	} else if (redirect) {
		return (
			<QuizEditTabs
				toggleRedirect={() => {
					setRedirect(false);
					getAllData();
				}}
				redirectData={redirectData}
			/>
		);
	} else {
		return (
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12 col-xl-12">
						<div className="row">
							<div className="col-xl-12">
								<div className="card">
									<div className="card-body" style={{ paddingTop: "0px" }}>
										<div>
											{/* {access_level != 1 && <button className="btn btn-outline-primary" onClick={this.toggleAddVersionModal} style={{ marginBottom: "15px" }}><i className="fa fa-plus" style={{ margin: "0px 7px" }}></i> Add New Version </button>} */}
											{renderTable()}
											<Modal
												isOpen={deleteModal}
												// toggle={this.toggleAddVersionModal}
											>
												<ModalHeader>Delete campaign</ModalHeader>
												<ModalBody>Are you sure you want to delete campign?</ModalBody>
												<ModalFooter>
													<Button color="danger" onClick={handleDeletebtn}>
														Yes,please!
													</Button>
													<Button color="primary" onClick={() => setDeleteModal(false)}>
														Not sure
													</Button>
												</ModalFooter>
											</Modal>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					{renderModal()}
				</div>
			</div>
		);
	}
};
export default QuizTable;
