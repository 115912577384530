import * as actionTypes from "../actions/actionTypes";

const initialState = {
    notifications : []
}

const headerReducer = (state = initialState, action) =>{
    switch(action.type){
        case actionTypes.SET_NOTIFICATIONS:
            return{
                ...state,
                notifications : action.payload
            }

        default:
            return state;

    }
}

export default headerReducer;