import React, { useState, useEffect } from "react";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import Dropdown from 'react-bootstrap/Dropdown';
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { Table, Progress } from "reactstrap";
import PaginationComponent from "react-reactstrap-pagination";
import { toast } from "react-toastify";
import moment from 'moment';
import { useTimer, useStopwatch } from 'react-timer-hook';
import { DisappearedLoading } from "react-loadingg";
import Icon from '@mdi/react';
import { mdiMagnify, mdiReply, mdiEye, mdiChevronDown } from '@mdi/js';
import OrderDetails from "../merchants/OrderDetails";

const ProgresCol = (value) => {
	if (value) {
		if (value => 0 && value <= 100) {
			if (value > 60) {
				return 'success'
			} else if (value >= 25 && value <= 60) {
				return 'warning'
			} else {
				return 'danger'
			}
		} else {
			return 'danger'
		}
	} else {
		return 'danger'
	}

}

function MyTimer({ expiryTimestamp, totalSec, acceptedTime, index, trans }) {
	const [showStopWatch, setShowStopWatch] = useState(false)
	const {
		seconds,
		minutes,
		hours,
		days,
		isRunning,
		start,
		pause,
		resume,
		restart,
	} = useTimer({
		expiryTimestamp, onExpire: () => {
			setShowStopWatch(true);

		}
	});

	let total_seconds = hours * 3600 + minutes * 60 + seconds;

	let progressValue = 0;
	let getTotalTimeMilliSec = new Date(expiryTimestamp).getTime() - new Date(acceptedTime).getTime();
	let getCurrentSec = new Date().getTime() - new Date(acceptedTime).getTime();
	let remainingTotalTime = getTotalTimeMilliSec - getCurrentSec;
	progressValue = Math.round((remainingTotalTime * 100) / getTotalTimeMilliSec);
	return (
		<>
			{!showStopWatch ? <div style={{ textAlign: 'center' }} >
				<span className="timerSpan">{days > 0 ? days.toString().padStart(2, 0) + ":" : null}<span>{hours.toString().padStart(2, 0)}</span>:<span>{minutes.toString().padStart(2, 0)}</span>:<span>{seconds}</span></span>
				{days > 0 ? <><p style={{ fontSize: "10px" }}>Day Hour Minute Second</p></> : null}
				<div className="progressBarDiv">
					<div className="progress-wrap progress-wrap--middle">
						<Progress value={progressValue} className="rounded-0" color={ProgresCol(progressValue)} />
					</div>
				</div>
				<div className="actionTitleDiv" style={{ color: "red" }}>
					Action Needed
				</div>
			</div> :
				<div>
					<MyStopwatch totalSec={totalSec} />
				</div>}
		</>
	);
}

function MyStopwatch({ totalSec, trans }) {
	const stopwatchOffset = new Date();
	let addStopTime = totalSec * -1;
	stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + addStopTime);
	const {
		seconds,
		minutes,
		hours,
		isRunning,
		start,
		pause,
		reset
	} = useStopwatch({ autoStart: true, offsetTimestamp: stopwatchOffset });
	const hourTime = hours < 10 ? `0${hours}` : `${hours}`;
	const secondTime = seconds < 10 ? `0${seconds}` : `${seconds}`;
	const minuteTime = minutes < 10 ? `0${minutes}` : `${minutes}`;
	return (
		<div style={{ textAlign: "center", fontWeight: "600", color: "tomato" }}>
			<p className="delayedText">Order delayed by</p>
			<span className="delayedSpan"><span>{hourTime}</span>:<span>{minuteTime}</span>:<span>{secondTime}</span></span>
		</div>
	);
}

const Orders = (props) => {

	let order_id = props.location.state?.order_id;

	const [totalOrder, setTotalOrder] = useState();
	const [data, setData] = useState();
	const [country, setCountry] = useState([]);
	const [countryTab, setCountryTab] = useState("All");
	const [tabIndex, setTabIndex] = useState(0);
	const [defCountry, setDefCountry] = useState("All");
	const [loading, setLoading] = useState(true);
	const [activePage, setActivePage] = useState(1);
	const [pageSize, setPageSize] = useState(15);
	const [viewOrder, setViewOrder] = useState(false);
	const [orderItem, setorderItem] = useState(null);
	const [merchant, setMerchant] = useState(null)


	const fetchOnlineOrders = () => {

		const postData = {
			country: countryTab,
			skip: (activePage - 1) * pageSize,
			limit: pageSize,
			fetchByOrderId: props.location.state ? props.location.state.order_id : null
		};

		client.post(api.orders_by_country, postData, async (error, response) => {
			// console.log(response, "order response");
			if (!error) {
				if (!response.error) {
					setTotalOrder(response.result.totalOrderCount);
					setData(response.result.data);

					setLoading(false);
				} else {
					toast.error(response.message);
				}
			} else {
				toast.error("Internal Error Occured. Please contact internal support.")
			}
		});
	}



	function moveElement(array) {

		let arrangeCountry = [...array];

		let australia = arrangeCountry.map((e) => e.country_code).indexOf('AU');
		if (australia != -1) {
			arrangeCountry.splice(0, 0, arrangeCountry.splice(australia, 1)[0]);
		}

		let japanIdx = arrangeCountry.map((e) => e.country_code).indexOf('JP');
		if (japanIdx != -1) {
			arrangeCountry.splice(1, 0, arrangeCountry.splice(japanIdx, 1)[0]);
		}

		let thailand = arrangeCountry.map((e) => e.country_code).indexOf('TH');
		if (thailand != -1) {
			arrangeCountry.splice(2, 0, arrangeCountry.splice(thailand, 1)[0]);
		}

		let unitedState = arrangeCountry.map((e) => e.country_code).indexOf('US');
		if (unitedState != -1) {
			arrangeCountry.splice(3, 0, arrangeCountry.splice(unitedState, 1)[0]);
		}

		let india = arrangeCountry.map((e) => e.country_code).indexOf('IN');
		if (india != -1) {
			arrangeCountry.splice(4, 0, arrangeCountry.splice(india, 1)[0]);
		}
		return arrangeCountry;
	}

	const fetchCountries = () => {

		client.post(api.fetch_country, { show_all: true }, async function (error, response) {

			if (!error) {
				if (typeof response.error !== "undefined") {
					try {
						if (!response.error) {
							let arrangeCountry = [...response.result.data];
							arrangeCountry = moveElement(arrangeCountry);
							setCountry(arrangeCountry)
						}
					} catch {
						toast.error("Internal error occured. Please contact support");
					}
				} else {
					toast.error("Internal error occured. Please contact support");
				}
			} else {
				toast.error("Internal error occured. Please contact support");
			}

		});
	}

	const handlePageChange = (pageNumber) => {
		setActivePage(pageNumber)
	}

	const ShowCurrencyNum = (amount, showSymbol, merchant) => {
		const currency_symbol = merchant[0]?.country?.currency_symbol;
		if (amount == undefined) {
			return currency_symbol;
		}

		let getCurrency = !isNaN(parseFloat(amount)) ? amount : 0;

		if (merchant[0] && merchant[0].country) {
			if (showSymbol && currency_symbol) {
				if (getCurrency < 0) {
					getCurrency = `-${currency_symbol}${Math.abs(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
				} else {
					getCurrency = `${currency_symbol}${parseFloat(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
				}
			}
			else {
				getCurrency = +`${parseFloat(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
			}
		}

		return getCurrency;
	}
	const getOderType = (type) => {
		let res = "";
		if (type == 1) {
			res = "TAKEAWAY"
		} else if (type == 2) {
			res = "DINE-IN"
		} else if (type == 3) {
			res = "DELIVERY"
		}
		return res.toLowerCase()
	}
	const getOrderColor = (status) => {
		if (status == 0) {
			return '#00A653'
		} else if (status == 1) {
			return '#ea9815'
		} else if (status == 2) {
			return '#0080b0'
		} else if (status == 3) {
			return '#000'
		} else if (status == 4) {
			return '#000'
		} else {
			return '#00A653'
		}
	}
	const getOderStatus = (status, type) => {
		if (status == 0) {
			return "NEW ORDER"
		} else if (status == 1) {
			return "ACCEPTED"
		} else if (status == 2) {
			return "READY TO PICK"
		} else if (status == 3) {
			return "ORDER PROCESSED"
		} else if (status == 4) {
			return "REJECTED"
		} else {
			// return "NEW ORDER"
		}
		// 0. Order Received , 1 : accepted, 2:ready to pickup, 3 : Collected
	}

	useEffect(() => {
		fetchOnlineOrders();
		setLoading(true);
	}, [countryTab, activePage, pageSize, order_id])

	useEffect(() => {
		fetchCountries();
	}, [])

	return (
		<div style={{ background: '#ffffff' }}>
			<div className="container-fluid">
				<div className="row theme-tab">
					<style>
						{`.theme-tab .tab-title li {padding: 10px 8px;white-space: nowrap;}`}
					</style>

					{
						country.length > 0 && <Tabs
							className="col-sm-12 merchant_req"
							style={{
								backgroundColor: "white",
								borderRadius: "3px",
								border: "1px solid rgb(223, 232, 241)",
								borderBottom: "none",
								marginBottom: "0px",
							}}
							onSelect={(index) => setTabIndex(index)}
						>

							<TabList className="tabs tab-title">
								<div
									style={{ display: "flex", alignItems: "center", padding: "10px 15px" }}
									onClick={() => setCountryTab('All')}
									className={defCountry == countryTab ? 'selectedTabStyle' : 'tabStyle'}
								>
									All
								</div>
								{
									country.length > 0 && country.slice(0, 5).map((countryData, index) => {
										return (
											<>
												<div
													className={countryData.country_code == countryTab ? 'selectedTabStyle' : 'tabStyle'}
													style={{ display: "flex", alignItems: "center", padding: "10px 15px" }}
													onClick={() => { setCountryTab(countryData.country_code); setTabIndex(index + 1) }}
												>
													{countryData.country_name}
												</div>
											</>
										)
									})
								}

								<Dropdown style={{ margin: "auto 0px" }}>
									<Dropdown.Toggle
										id=""
										className={tabIndex == 6 ? 'selectDropDownBtnStyle' : 'dropDownBtnStyle'}
										style={{ color: "#000" }}
									>
										Others
									</Dropdown.Toggle>

									<Dropdown.Menu>
										<style>
											{`.dropdown-menu.show {min-height:145px;max-height:400px;overflow:auto}`}
										</style>
										{
											country.length > 5 && country.slice(5, country.length).map((countryData) => {
												return (
													<>
														<Dropdown.Item className={countryData.country_code == defCountry ? 'selectedDropDownStyle' : "dropDownStyle"} onClick={() => setCountryTab(countryData.country_code)}>
															{countryData.country_name}
														</Dropdown.Item>
													</>
												)
											})
										}
									</Dropdown.Menu>
								</Dropdown>
							</TabList>
						</Tabs>
					}
				</div>
			</div>
			<div className="m-4">
				{!loading ?
					viewOrder ?
						<OrderDetails item={orderItem} merchant={merchant} setViewOrder={setViewOrder} />
						:
						<div style={{ background: '#ffffff' }}>
							<Table className="table table-border-horizontal">
								<thead>
									<tr style={{ background: '#f9fafe' }}>
										<th>#</th>
										<th>Date/Time</th>
										{countryTab == "All" && <th>Store</th>}
										<th>Other Details</th>
										<th>Customer Details</th>
										<th>Status</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{
										data?.length > 0 ?
											data?.map((item, index) => {
												const date = `${moment(item.addedDate).format("DD-MM-YYYY")}`
												const time = `${moment(item.addedDate).format("HH:mm:ss")}`
												const time2 = new Date();
												let totalSec = 0;
												if (item.order_status == 1) {
													if (item.acceptedDate) {
														var startTime = moment(item.acceptedDate).format(
															"DD/MM/YYYY HH:mm:ss"
														);
													} else {
														var startTime = moment(item.updatedAt).format(
															"DD/MM/YYYY HH:mm:ss"
														);
													}
													var endTime = moment().format("DD/MM/YYYY HH:mm:ss");
													var duration = moment
														.utc(
															moment(endTime, "DD/MM/YYYY HH:mm:ss").diff(
																moment(startTime, "DD/MM/YYYY HH:mm:ss")
															)
														)
														.format("HH:mm:ss");
													var sss = moment.duration(duration).asSeconds();
													totalSec = parseFloat(item.eta * 60);
													if (sss > 1) {
														totalSec = totalSec - sss;
													}
													if (!totalSec) {
														totalSec = 0;
													}

													time2.setSeconds(time2.getSeconds() + totalSec);
												}
												return (
													<tr>
														<td>{(activePage - 1) * pageSize + index + 1}</td>
														<td>{date} <br /> {time}</td>
														{countryTab == "All" && <td>{item.merchantsData[0]?.business_name}</td>}
														<td>
															<p>{item.order_number}</p>
															<p>{ShowCurrencyNum((item.final_total).toFixed(2), true, item.merchantsData)}</p>
															<p>Paid via: {item.payment_method == "COD" ? `Cash` : item.payment_method == "ONLINE" ? `Card` : item.payment_method == "IPAYPRO" ? "iPayPro" : item.payment_method}</p>
															<p style={{ textTransform: "capitalize" }}>{getOderType(item.orderType)}{item.tableInfo.tableName ? ` (${item.tableInfo.tableName})` : null}</p>
														</td>
														<td >
															{item.isguest && item.merchantsData[0].guestCheckout.allfield ? "NA" :
																<ul>
																	{item.customerInfo.customerName && <li><span>Name</span> : &nbsp;{item.customerInfo.customerName}</li>}
																	{item.customerInfo.customerMobile && <li><span>Mobile no</span> : &nbsp;{item.customerInfo.customerMobilePrefix && `(${item.customerInfo.customerMobilePrefix})`} {item.customerInfo.customerMobile}</li>}
																	{item.customerInfo.customerEmail && <li><span>Email id</span> : &nbsp;{item.customerInfo.customerEmail}</li>}
																</ul>
															}
														</td>
														<td className='orderSts'>
															{item.order_status == 1 && item.orderType != 1 ?
																<div className="timerCont">
																	<div className="timeSpan" key={Date.now()}>
																		{totalSec > 0 ?
																			<div>
																				<MyTimer expiryTimestamp={time2} totalSec={totalSec} acceptedTime={item.updatedAt} key={index} />
																			</div>
																			:
																			<div>
																				<MyStopwatch totalSec={totalSec} />
																			</div>
																		}
																	</div>
																</div> : item.order_status == 1 && item.orderType == 1 ?
																	<div>
																		<p style={{ textAlign: 'center', fontWeight: 500 }}>Pickup date/time</p>
																		{/* <p style={{ margin: 0, color: 'orangered' }}>{moment(item.pickupdate).format('DD-MM-YYYY hh:mm:ss A')}</p> */}
																		<p style={{ margin: 0, color: 'orangered', textAlign: 'center', fontWeight: 500 }}>{item.pickupdate}</p>
																		<div className="actionTitleDiv" style={{ textAlign: 'center', marginBottom: '5px', color: "red" }}>
																			Action Needed
																		</div>
																	</div> : item.order_status == 2 && item.orderType == 1 ?
																		<div>
																			<p style={{ textAlign: 'center', fontWeight: 500 }}>Pickup date/time</p>
																			{/* <p style={{ margin: 0, color: 'orangered', marginBottom: '5px' }}>{moment(item.pickupdate).format('DD-MM-YYYY hh:mm:ss A')}</p> */}
																			<p style={{ margin: 0, color: 'orangered', marginBottom: '5px', textAlign: 'center', fontWeight: 500 }}>{item.pickupdate}</p>
																			<p style={{ color: getOrderColor(item.order_status), textAlign: 'center', fontWeight: 500 }} > {`READY TO PICK`} </p>
																		</div> :
																		<p style={{ color: getOrderColor(item.order_status), textAlign: 'center', fontWeight: 500 }} > {getOderStatus(item.order_status, item.orderType)} </p>
															}
														</td>
														<td className='orderSts'>
															<span className='cur rounded' onClick={(e) => { e.preventDefault(); setViewOrder(!viewOrder); setorderItem(item); setMerchant(item.merchantsData[0]) }}>
																<Icon path={mdiEye} className="" style={{ marginBottom: "0.1rem" }} size={0.8} color="#646777" />
															</span>
														</td>
													</tr>
												)
											}) :
											<tr>
												<td style={{ textAlign: 'center' }} colSpan="4">No records found</td>
											</tr>
									}
								</tbody>
							</Table>
							<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
								<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
									<select className="custom-select" style={{ width: "35%", fontSize: "0.63rem", marginRight: "10px" }} onChange={(event) => setPageSize(event.target.value)} value={pageSize}>
										<option value="15">15</option>
										<option value="25">25</option>
										<option value="50">50</option>
										<option value="75">75</option>
										<option value="100">100</option>
									</select>
									<span style={{ fontSize: "0.63rem" }}>Records per Page</span>
								</div>
								<PaginationComponent
									totalItems={totalOrder}
									pageSize={pageSize}
									onSelect={handlePageChange}
								/>
							</div>
						</div>
					:
					<div>
						<div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
							<div className="loader" style={{ display: "flex", alignItems: "center" }}>
								<DisappearedLoading size="medium" color="#514F4E" />
							</div>
						</div>
					</div>
				}
			</div>
		</div>
	)
}

export default Orders