import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Breadcrumb from "../../components/common/breadcrumb";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";

const GuardSetting = () => {
	const [googlePay, setGooglePay] = useState(true);
	const [applePay, setApplePay] = useState(true);
	const [ipay, setIpay] = useState(true);
	const [visa, setVisa] = useState(true);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const postData = {};
		console.log("default payment start to fetch..")
		client.get(
			api.getDefaultPaymentOption,
			postData,
			(error, response) => {
				if (!error) {
					if (!response.error) {
						// console.log(response.data[0].google_pay, "response");
						console.log("alll merchant payent",response)
						try {
							if (!response.data.google_pay) {
								setApplePay(false);
								setGooglePay(false);
								setVisa(false);
							} else {
								setApplePay(true);
								setGooglePay(true);
								setVisa(true);
							}
							// console.log(response.data[0].ipay);
							if (!response.data.ipay) {
								setIpay(false);
							} else {
								setIpay(true);
							}
						} catch (error) {
							console.log(error);
						}
					} else {
						setLoading(false);
						toast.error("Internal error occured.Please ontact support");
					}
				} else {
					setLoading(false);
					toast.error("Internal error occured.Please ontact support");
					console.log(error, "error");
				}
			}
		);
	}, []);

	const onSaveChanges = () => {
		const postData = {
			google_pay: googlePay,
			apple_pay: applePay,
			ipay: ipay,
			visa_mastercard: visa,
		};
		console.log({ postData });
		client.post(
			api.updateDefaultPaymentOption,
			postData,
			(error, response) => {
				console.log(error);
				if (!error) {
					if (!response.error) {
						console.log(response);
						//setGuardSetting(response.result.data);
						setLoading(false);
						toast.success("Changes Saved");
					} else {
						setLoading(false);
						toast.error("Internal error occured.Please ontact support");
					}
				} else {
					setLoading(false);
					toast.error("Internal error occured.Please ontact support");
				}
			}
		);
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-lg-7 col-sm-12" style={{ fontSize: "12px" }}>
					<Breadcrumb title={"Guard settings"} parent={"Home"} />
				</div>
				<div className="col-sm-12 col-xl-12 xl-100">
					<div className="card" style={{ border: '1px solid rgb(223, 232, 241)', borderRadius: '3px'}}>
						<div className="card-body" style={{ padding: "1rem" }}>
							<p style={{ fontWeight: "bold" }}>Active Payment Gateways</p>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "flex-start",
								}}
							>
								<div className="mr-3">
									<input
										type="checkbox"
										name="ipay"
										checked={ipay}
										onChange={(event) => setIpay(event.target.checked)}
									/>
									<div
										id="banner-image"
										style={{
											backgroundImage:
												"url(https://demo.business.ipaypro.co/static/media/ipay-logo.b78c6c3f.png)",
											height: "80px",
											width: "160px",
											backgroundRepeat: "no-repeat",
											backgroundSize: "contain",
											backgroundPosition: "center",
										}}
									></div>
								</div>
								<div className="mr-3">
									<input
										type="checkbox"
										name="visa"
										checked={visa}
										onChange={(event) => {
											setApplePay(event.target.checked);
											setGooglePay(event.target.checked);
											setVisa(event.target.checked);
										}}
									/>
									<div
										id="banner-image"
										style={{
											backgroundImage:
												"url(https://demo.business.ipaypro.co/static/media/other_payments.14707f39.png)",
											height: "80px",
											width: "125px",
											backgroundRepeat: "no-repeat",
											backgroundSize: "contain",
											backgroundPosition: "center",
										}}
									></div>
								</div>
							</div>
							<div className="saveBtncont" style={{ marginTop: "2rem" }}>
								<button
									color="primary"
									className="btn btn-outline-primary btn-xs"
									type="button"
									onClick={onSaveChanges}
								>
									Save
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GuardSetting;
