import React, { useState, useEffect, Fragment } from 'react'
import { Row, Col, Container, Button, Popover, Collapse, PopoverBody, Tooltip, UncontrolledTooltip, CardBody, Card, Modal } from "reactstrap"

import client from "../../Api/HTTPClient"
import api from "../../constant/apilist"
import { useDispatch, useSelector } from "react-redux";
import "./SubscriptionPlanNewCss.scss";
import { mdiCheckBold, mdiCloseThick, mdiMenuDown, mdiArrowLeft, mdiInformationOutline, mdiChevronLeft, mdiChevronDown, mdiChevronUp, } from '@mdi/js';
import Icon from "@mdi/react";
import { Link, useHistory } from "react-router-dom"
import HashLoader from 'react-spinners/HashLoader';
import { toast } from 'react-toastify';
import { useParams } from "react-router";
// import { check_promo_status } from "../../utils/helpers";
import moment from "moment-timezone";

// import { useTimer } from 'react-timer-hook';
// import { fetchAllCatData } from '../../store/categoryData';
// import { fetchMerchFeatureData } from "../../store/merchantFeatureData";
// import { fetchLogoAndBusinessTypeDatas } from "../../store/manageLogoAndBusinessType"
// import { useTranslation } from "react-i18next";
// import { switchBackend, switchFrontend, } from "../../store/actions/panelSwitchActions";
import { BiInfoCircle, BiCheck } from 'react-icons/bi'
import { HiOutlineChevronLeft } from 'react-icons/hi'
import { ImCross } from 'react-icons/im'
import { FaCheck } from 'react-icons/fa'

// import ToggleSwitch from '../../containers/customComponents/toggleButton/ToggleButton';
import ToggleSwitch from './customToggle';

const check_promo_status = (item) => {

    if (item.promo_rate > 0) {
        if (moment(moment(item.promo_end_date).format("YYYY-MM-DD")).isAfter(moment().format("YYYY-MM-DD"))) {
            return true;
        } else {
            return false;
        }
  
    } else {
        return false;
    }
  
}


const SubscriptionPlanCompNew = ({merchantId,setShowallSubscriptions }) => {


    const [result, setResult] = useState([]);
    const [featDesc, setFeatDesc] = useState([]);
    const [merchFeatData, setmerchFeatData] = useState([]);
    const [featDescriptiveDesc, setFeatDescriptiveDesc] = useState([]);
    const [catFea, setcatFea] = useState([]);
    const [logoDatas, setlogoDatas] = useState([]);
    // const SessionmerchantId = useSelector((state) => state.auth.merchantId);
    // const merchants = useSelector((state) => state.auth.user_merchants);
    // const catFea = useSelector((state) => state.categoryData.catData)
    // const merchFeatData = useSelector((state) => state.merchantFeatureData.merchantFeaturesData);
    // const { t, i18n } = useTranslation('pricing_page');
    const t = (ad) => {
        return ad
    }

    const [hasPopular, setHasPopular] = useState(false)

    // const logoDatas = useSelector((state) => state.manageLogoAndBusinessType.logoData);


    // console.log(i18n.language)
    // console.log(merchFeatData, featDescriptiveDesc, "merchFeatData");
    const [allSubsData, setAllSubsData] = useState([]);
    const [customTemplate, setCustomTemplate] = useState({})
    const [subsCategory, setSubsCategory] = useState([]);
    const [selectedData, setSelectedData] = useState(null);
    const [filterdData, setFilterdData] = useState([]);
    const [duration, setDuration] = useState(true);
    const [checkIsTrial, setCheckIstrial] = useState(false);
    const [featuresHeight, setFeaturesHeight] = useState({
        minisite: '',
        payments: '',
        event_management: '',
        pos: '',
        ecommerce: '',
        others: ''
    })


    const dispatch = useDispatch()

    console.log(merchantId, "merchantId")

    const merchant_id  =merchantId;
    const [state, setState] = useState(false);
    const [yearly, setYearly] = useState(true);
    const [monthly, setMonthly] = useState(true)
    const [periods, setperiods] = useState(null)
    const [yearlyPeriodObj, setYearlyPeriodObj] = useState(null)
    const [merchantDetails, setMerchantDetails] = useState(null);
    const [objData, setObjData] = useState({
        invoicepara: "",
        visitorpara: "",
        customReportspara: "",
        helpSupportpara: "",
        developerspara: "",
        linkyScanPara: "",

    });
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [popoverOpenMob, setPopoverOpenMob] = useState(false);
    const [popoverInfoOpen, setPopoverInfoOpen] = useState(false);
    const [ptargetId, setPTargetId] = useState("cloudBasedP");
    const [selected, setSelected] = useState(null);
    const [currentSubscription, setcurrentSubscription] = useState(null);
    const [currentSub_id, setcurrentSub_id] = useState(null);
    const [isTrial, setIsTrial] = useState(false);
    const [hideBackButton, sethideBackButton] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState([]);
    const [currentPlan, setCurrentPlan] = useState([]);
    const [packageLoading, setPackageLoading] = useState(true);

    const [feaLoading, setFeaLoading] = useState(true);
    const [feaDescLoading, setFeaDescLoading] = useState(false);
    const [subLoading, setSubLoading] = useState(false);
    const [perLoading, setPerLoading] = useState(false);
    const [defaultLoading, setDefaultLoading] = useState(false);
    const [downgrade, setDowngrade] = useState(false);
    const [upgrade, setUpgrade] = useState(false);
    const [promoAvail, setPromoAvail] = useState(false);
    const [toolTipS, setTooltipS] = useState({
        cloudBased: false,
        register: false,
        userRole: false,
        vRegistry: false,
        linkyScan: false,
        online: false,
        table_reservation: false,
        offers: false,
        loyalty: false,
        payment: false,
        custom: false,
        help: false,
        developer: false,
        priceList: false,
    })
    const [collapseStates, setCollapseStates] = useState({});
    const [checkedPlan, setCheckedPlan] = useState(null)
    const [index, setIndex] = useState(null)
    const [merchantLostDataModal, setMerchantLostDataModal] = useState(false);

    const [featureTitles, setFeatureTitles] = useState(null)



    let suspend = localStorage.getItem('@suspendStatus');

    const history = useHistory();

    const handleCollapseToggle = (index) => {
        const newCollapseStates = { ...collapseStates };
        newCollapseStates[index] = !newCollapseStates[index];
        setCollapseStates(newCollapseStates);
    };

     const fetchMerchFeatureData = postData  => {

    
        client.post(api.fetch_merchant_enable_features, postData, (error, response) => {
            // console.log(response, "feature data")
    
            if (!error) {
                if (!response.error) {
                    setmerchFeatData(response.result.feature_data)
                } else {
                }
            } else {
            }
    
        });
    };


     const fetchLogoAndBusinessTypeDatas = (hostname, postData)  => {

        // dispatch(loadLogoAndBusinessTypeData());
  
        client.post(api.fetchBusinessType, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    let getData = response.result?.page_data?.find((items) => items.brand_ID == 2);
  
                    if (getData) {
                        let getLogoData;
  
                        if (getData.logo_fevicon) {
                            getLogoData = getData.logo_fevicon.find((logoData) => logoData.portalName == "portal");
                        }
  
                        setlogoDatas(getLogoData)
                        // dispatch(
                        //     successLogoAndBusinessTypeData({ message: response.message, data: getData, logoData: getLogoData, }));
                    }
                } else {
                    // dispatch(failedLogoAndBusinessTypeData({ message: response.message }));
                    // toast.error(response.message);
                }
            } else {
                // dispatch(failedLogoAndBusinessTypeData({ message: error.message }));
                // toast.error(error.message);
            }
        });
    }

    const fetchAllCatData = (postData) => {

        client.post(api.fetch_cat_single, postData, (error, response) => {

            // console.log(error, response, "err res")

            if (!error) {
                if (!response.error) {
                    // console.log(response.result.data.length, response.result.data, "sa")
                    if (response.result.data.length > 0) {
                        // console.log(response, "Res")
                        setcatFea(response.result.data)
                    }
                } else {
                    // dispatch(failedCatData({ message: response.message }));
                }
            } else {
                // dispatch(failedCatData({ message: response }));
            }

        });
    }

    const handleCheckboxToggle = (data, ind) => {
        // console.log(data, ind, 'selected_plan and Index')
        setIndex(ind)
        if (checkedPlan?._id === data._id) {
            setCheckedPlan(null);
        } else {
            setCheckedPlan(data);
        }
    }

    useEffect(() => {
        let minisite_height = document.getElementById('minisite_features')?.offsetHeight
        let payments_height = document.getElementById('payments_features')?.offsetHeight
        let event_management_height = document.getElementById('event_management_features')?.offsetHeight
        let pos_height = document.getElementById('pos_features')?.offsetHeight
        let ecommerce_height = document.getElementById('ecommerce_features')?.offsetHeight
        let others_height = document.getElementById('others_features')?.offsetHeight

        setFeaturesHeight(prev => {
            return {
                prev,
                minisite: minisite_height,
                payments: payments_height,
                event_management: event_management_height,
                pos: pos_height,
                ecommerce: ecommerce_height,
                others: others_height
            }
        })
    }, [document, featDescriptiveDesc, selectedData])

    const handleProceed = () => {
        if (merchant_id) {
            if (!is_current_plan(checkedPlan._id)) {
                localStorage.setItem('yearly', JSON.stringify(!monthly));
                if (currentSub_id) {
                    if (index < selectedIndex) {
                        if (!suspend) {
                            setSelectedPlan(checkedPlan);
                            toggleDowngradeModal();
                        } else {
                            setSelected(checkedPlan._id);
                        }
                    } else {

                        if (!suspend) {
                            setSelectedPlan(checkedPlan);
                            toggleUpgradeModal();
                        } else {
                            setSelected(checkedPlan._id);
                        }
                    }
                    setDefaultLoading(false);
                } else {
                    setSelected(checkedPlan._id);
                }
            }
        } else {
            history.push("/login")
        }
        sessionStorage.removeItem('Ipaybranding')
    }

    const toggletooltip = (key) => {
        let temp = { ...toolTipS }
        // console.log(key, "key")
        if (key == 1) {
            temp.cloudBased = !temp.cloudBased
        }
        if (key == 2) {
            temp.register = !temp.register
        }
        if (key == 3) {
            temp.userRole = !temp.userRole
        }
        if (key == 4) {
            temp.vRegistry = !temp.vRegistry
        }
        if (key == 5) {
            temp.online = !temp.online
        }
        if (key == 6) {
            temp.table_reservation = !temp.table_reservation
        }
        if (key == 7) {
            temp.offers = !temp.offers

        }
        if (key == 8) {
            temp.loyalty = !temp.loyalty
        }
        if (key == 9) {
            temp.payment = !temp.payment
        }
        if (key == 10) {
            temp.custom = !temp.custom
        }
        if (key == 11) {
            temp.help = !temp.help
        }
        if (key == 12) {
            temp.developer = !temp.developer
        }
        if (key == 13) {
            temp.priceList = !temp.priceList
        }
        if (key == 14) {
            temp.linkyScan = !temp.linkyScan
        }

        setTooltipS(temp)
    }

    // console.log(featDesc, "featDesc")
    const toggleDowngradeModal = () => setDowngrade(!downgrade);
    const toggleUpgradeModal = () => setUpgrade(!upgrade);


    const togglePopover = () => {
        setPopoverOpen(prevState => !prevState);
    };

    const togglePopoverMob = () => {
        setPopoverOpenMob(prevState => !prevState);
    };

    const toggleInfoPopover = () => {
        setPopoverInfoOpen(prevState => !prevState);
    };

    useEffect(() => {
        if (filterdData?.length > 1) {
            let temp = filterdData.map((subData) => {
                if (check_promo_status(subData)) {
                    setPromoAvail(true);
                }
            })
        }
    }, [filterdData])

    useEffect(() => {
        if (merchantDetails) {

            // console.log("runned")
            let postdata = {
                category_name: merchantDetails.business_category,
                category_id: merchantDetails.businessCatId,
            }
            // console.log(merchantDetails, "runned")
            fetchAllCatData(postdata)

        }
        // console.log(catFea, 'category');
        // console.log(merchFeatData, 'features');
    }, [merchantDetails])

    useEffect(() => {
        let postData = { all: true };
        // let hostName;

        // if (typeof window !== "undefined") {
        //     hostName = window.location.host;
        // }

        // if (!logoDatas && hostName) {
            fetchLogoAndBusinessTypeDatas("linky.biz", postData);
        // }
    }, []);

    useEffect(() => {
        let postdata = { merchantId: merchant_id }
        fetchMerchFeatureData(postdata)
    }, [])

    // fetch current subsription plan of merchant
    useEffect(() => {
        setSubLoading(true)
        if (merchant_id) {
            client.get(api.fetch_subscription, { merchant_id, active: true }, (error, response) => {
                // console.log(response, "Resp")
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                setResult(response.result);
                                setAllSubsData(response.result);
                                setCustomTemplate(response.result[0],
                                    'asd')
                                if (response.merchant) {
                                    setMerchantDetails(response.merchant)
                                }
                                // setMerchant(response.merchant)
                                setSubLoading(false)
                                try {
                                    if (response.merchant.subscription.subscription_id) {
                                        let s = response.merchant?.subscription?.subscription_id;
                                        setcurrentSubscription(s);
                                        setcurrentSub_id(s.subscription.id);
                                        setIsTrial(s.subscription.is_trial);

                                        // for changing the id key
                                        let updated_subs = s.subscription
                                        updated_subs._id = updated_subs.id
                                        setCheckedPlan(updated_subs)

                                        response.result.map((respo, index) => {
                                            if (respo._id == s.subscription.id) {
                                                setSelectedIndex(index);
                                                setCurrentPlan(respo);
                                            }
                                        });
                                    }
                                } catch {
                                    sethideBackButton(true);

                                }
                            } else {
                                toast.error("Something went wrong!")
                                setSubLoading(false)
                            }
                        } catch (error) {
                            toast.error("Something went wrong!")
                            setSubLoading(false)
                        }
                    } else {
                    }
                } else {
                }
                setPackageLoading(false);
            });
        } else {
            client.get(api.fetch_plans, {}, (error, response) => {
                // console.log(response, "res", error)
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                setResult(response.result);
                                setAllSubsData(response.result);
                                setSubLoading(false)
                            } else {
                                toast.error("Something went wrong!")
                                setSubLoading(false)


                            }
                        } catch (error) {
                            toast.error("Something went wrong!")
                            setSubLoading(false)

                        }
                    } else {
                        toast.error("Something went wrong!")
                        setSubLoading(false)

                    }
                } else {
                    toast.error("Something went wrong!")
                    setSubLoading(false)

                }

            });
        }

        client.post(api.fetchSubscriptionCategory, {}, (error, response) => {
            // console.log(response, "fetch subcats plans ")
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            setSubsCategory(response.result);
                            // setResult(response.result);
                            // setSubLoading(false)
                        } else {
                            toast.error("Something went wrong!")
                            // setSubLoading(false)

                        }
                    } catch (error) {
                        toast.error("Something went wrong!")
                        // setSubLoading(false)
                    }
                } else {
                    toast.error("Something went wrong!")
                    // setSubLoading(false)

                }
            } else {
                toast.error("Something went wrong!")
                // setSubLoading(false)

            }

        });

    }, []);


    useEffect(() => {

        if (allSubsData.length > 0 && subsCategory.length > 0) {

            let defaultCat = subsCategory.find((currElem) => currElem.enableCategory == true);
            setSelectedData(defaultCat);

            let filteredArray = allSubsData.filter((element) => element.subscription_category.some((subElement) => subElement.id == defaultCat.cat_Id && subElement.enable == true));

            // if (!currentPlan && currentSubscription) {
            //     setFilterdData([currentSubscription.subscription, ...filteredArray])
            // }
            // else {
                setFilterdData(filteredArray)
            // }

        } else if (allSubsData.length > 0 && subsCategory.length == 0 || subsCategory.length == 0) {
            // setFilterdData(allSubsData);
            // if (!currentPlan && currentSubscription) {
            //     setFilterdData([currentSubscription.subscription, ...allSubsData])
            // }
            // else {
                setFilterdData(allSubsData)
            // }
        }

        if (allSubsData.length > 0) {

            let checkIsTrial = allSubsData.findIndex((data) => data.is_trial == true);
            if (checkIsTrial != -1) {
                setCheckIstrial(true);
            }
        }

    }, [allSubsData, subsCategory])

    const handleSubscriptionCateChange = (data) => {

        setSelectedData(data);

        let filteredArray = allSubsData.filter((element) => element.subscription_category.some((subElement) => subElement.id == data.cat_Id && subElement.enable == true));
        
        // if (!currentPlan && currentSubscription) {
        //     setFilterdData([currentSubscription.subscription, ...filteredArray])
        // }
        // else {
            setFilterdData(filteredArray)
        // }
        // setFilterdData(filteredArray);
    }

    useEffect(() => {
        let hasPopular = false;

        for (const obj of filterdData) {
            if (obj.popular === true) {
                setHasPopular(true)
                break;
            }
        }
    }, [filterdData])

    useEffect(() => {
        if (selected) {
            // if (is_current_plan(selected)) {
            //     window.location.href = `/subscription-plans-checkout/${merchant_id}/${selected}`;
            // } else {
            //     console.log("runned")
            // }
            // window.location.href = `/subscription-plans-checkout/${merchant_id}/${selected}`;
            history.push(`/subscription-plans-checkout/${merchant_id}/${selected}`)
        }
    }, [selected]);

    const is_current_plan = (plan_id) => {
        if (currentSub_id) {
            if (currentSub_id == plan_id) {
                if (suspend) {
                    return false
                } else {
                    return true;
                }
            } else {
                return false;
            }
        } else {
            return false
        }

    };


    useEffect(() => {
        const fetchfeatures = () => {
            setFeaLoading(true);
            client.post(api.fetch_featureDesc, {}, async function (error, response) {

                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                setFeatDesc(response.result)
                                setFeaLoading(false);
                            } else {
                                toast.error("Something went wrong!")
                                setFeaLoading(false);

                            }
                        } catch (e) {
                            toast.error("Something went wrong!")
                            setFeaLoading(false);
                        }
                    } else {
                        toast.error("Something went wrong!")
                        setFeaLoading(false);
                    }
                } else {
                    toast.error("Something went wrong!")
                    setFeaLoading(false);
                }

                // setloading(false);
            });

        }
        const fetchDescriptiveDetails = async () => {
            setFeaDescLoading(true);
            await client.get(api.fetch_features, {}, async function (error, response) {
                // console.log(response, "Res")
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                setFeatDescriptiveDesc(response.result)
                                let objDataTemp = { ...objData }
                                const tempData = response.result?.map((item) => {
                                    // console.log(response, "response")
                                    // console.log(item.title.toLowerCase().includes("developer's"), item.title.toLowerCase(), "item", objData)
                                    if (item.title.toLowerCase().includes("invoices")) {
                                        objDataTemp.invoicepara = item.description
                                        // setObjData({ ...objData, invoicepara: item.description })
                                    }
                                    if (item.title.toLowerCase().includes("visitor")) {
                                        objDataTemp.visitorpara = item.description
                                        // setObjData({ ...objData, visitorpara: item.description })
                                    }
                                    if (item.title.toLowerCase().includes("custom")) {
                                        objDataTemp.customReportspara = item.description
                                        // setObjData({ ...objData, customReportspara: item.description })
                                    }
                                    if (item.title.toLowerCase().includes("help")) {
                                        objDataTemp.helpSupportpara = item.description
                                        // setObjData({ ...objData, helpSupportpara: item.description })
                                    }
                                    if (item.title.toLowerCase().includes("developer's")) {
                                        objDataTemp.developerspara = item.description
                                        // setObjData({ ...objData, developerspara: item.description })
                                    }

                                })
                                // console.log(objDataTemp, "2", objData)
                                setObjData({ ...objDataTemp })
                                setFeaDescLoading(false);
                            } else {
                                toast.error("Something went wrong!")
                                setFeaDescLoading(false);

                            }
                        } catch (e) {
                            toast.error("Something went wrong!")
                            setFeaDescLoading(false);

                        }
                    } else {
                        toast.error("Something went wrong!")
                        setFeaDescLoading(false);

                    }
                } else {
                    toast.error("Something went wrong!")
                    setFeaDescLoading(false);

                }

                // setloading(false);
            });
        }

        const fetchFeatureTitles = async () => {
            await client.get(api.fetch_features_titles, {}, async function (error, response) {
                // console.log(response, "Res")
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                setFeatureTitles(response.result[0], 'titles')
                                // console.log(response.result[0], 'titles')
                            } else {
                                toast.error("Something went wrong!")

                            }
                        } catch (e) {
                            toast.error("Something went wrong!")

                        }
                    } else {
                        toast.error("Something went wrong!")

                    }
                } else {
                    toast.error("Something went wrong!")

                }

                // setloading(false);
            });
        }

        const fetchperiod = async () => {
            setPerLoading(true)
            await client.get(api.fetchperiod, {}, async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                setperiods(response.result)
                                if (response.result.length > 0) {
                                    const temp = response.result.map((item) => {
                                        if (item.period == 12) {
                                            setYearlyPeriodObj(item)
                                            setPerLoading(false)
                                        }
                                    })
                                }

                            } else {

                                toast.error("Something went wrong!")
                                setPerLoading(false)
                            }
                        } catch (e) {
                            toast.error("Something went wrong!")
                            setPerLoading(false)

                        }
                    } else {
                        toast.error("Something went wrong!")
                        setPerLoading(false)

                    }
                } else {
                    toast.error("Something went wrong!")
                    setPerLoading(false)

                }

                // setloading(false);
            });

        }

        // fetch_subscription();
        fetchfeatures();
        fetchDescriptiveDetails();
        fetchperiod()
        fetchFeatureTitles()
    }, [])

    const calcPrice = (fees) => {
        if (yearly) {
            if (yearlyPeriodObj) {
                let totalPerc = (fees / 100) * yearlyPeriodObj.discount_percent
                return fees - totalPerc.toFixed(2)
            } else {
                return fees
            }
        } else {
            return fees
        }


    }
    const redirectBack = () => {
        // console.log("redirect")
        // if (currentSubscription) {
        //     let suspend = localStorage.getItem("@suspendStatus");
        //     if (suspend) {
        //         localStorage.removeItem('@suspendStatus');
        //         localStorage.removeItem('@selectedStoreID');
        //         history.push('/select-store');

        //     } else {
        //         if (history?.location?.state?.redirectedFrom == 'pos') {
        //             dispatch(switchFrontend(true))
        //         }

        //         if (history?.location?.state?.isRedirected) {
        //             history.go(-3)
        //         }
        //         else {
        //             history.goBack()
        //         }
        //     }
        // }
        // else {
        //     setMerchantLostDataModal(true);
        // }
    }

    // const MyTimer = ({ expiryTimestamp }) => {
    //     const {
    //         seconds,
    //         minutes,
    //         hours,
    //         days,
    //         isRunning,
    //         start,
    //         pause,
    //         resume,
    //         restart
    //     } = useTimer({
    //         expiryTimestamp,
    //         onExpire: () => console.warn("onExpire called")
    //     });
    //     // console.log(seconds, minutes, hours, days, isRunning, expiryTimestamp, "s,m,h,d,is,exp")

    //     return (
    //         <div className='timer' style={{ textAlign: "center", fontSize: "13px" }}>
    //             <div className='row timeRow'>
    //                 <div className='col timCol' style={{ padding: "7px 4px 0px 4px", borderRight: "none" }}>
    //                     <h5 className='timTitle'>{days <= 9 && 0}{days}</h5>
    //                     <p className='pTag'>Days</p>
    //                 </div>
    //                 <div className='col timCol' style={{ padding: "7px 4px 0px 4px", borderRight: "none" }}>
    //                     <h5 className='timTitle'>{hours <= 9 && 0}{hours}</h5>
    //                     <p className='pTag'>Hrs</p>
    //                 </div>
    //                 <div className='col timCol' style={{ padding: "7px 4px 0px 4px", borderRight: "none" }}>
    //                     <h5 className='timTitle'>{minutes <= 9 && 0}{minutes}</h5>
    //                     <p className='pTag'>Mins</p>
    //                 </div>
    //                 <div className='col timCol' style={{ padding: "7px 4px 0px 4px" }}>
    //                     <h5 className='timTitle'>{seconds <= 9 && 0}{seconds}</h5>
    //                     <p className='pTag'>Sec</p>
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // }

    const checkValid = (itemTrial) => {

        if (isTrial && itemTrial) {
            return true
        } else if (suspend && itemTrial) {
            return true
        } else {
            return false
        }

    }



    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const updateDimensions = () => {
        setScreenWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const checkFeatureValue = (feature, subData) => {
        if (screenWidth > 800) {
            if (!feature.descriptive_feature) {
                if (feature.feature_id == 'price_list') {
                    return subData.price_list ? <div className='percDiv' style={{ height: '21px' }}>
                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.1} />
                    </div> : <Icon path={mdiCloseThick} color={`#d15353`} size={1.1} />
                } else if (feature.feature_id == 'sales_per_day') {
                    return <div className={`percDiv`} style={{ height: '21px' }}>{subData.unlimited_sales ? <p className={`feature_values`} style={{ lineHeight: 'normal' }}>Unlimited sales</p> : subData.sales_per_day == 0 || !subData.sales_per_day ? <></> : <p className={`feature_values`} style={{ lineHeight: 'normal' }}>{subData.sales_per_day} {subData.sales_per_day > 1 ? `sales` : `sale`}</p>}</div>
                } else if (feature.feature_id == 'number_of_registers') {
                    return <div className={`percDiv`} style={{ height: '21px' }}>{subData.unlimited_registration ? <p className={`feature_values`} style={{ lineHeight: 'normal' }}>Unlimited Devices</p> : subData.number_of_registers == 0 || !subData.number_of_registers ? <></> : <p className={`feature_values`} style={{ lineHeight: 'normal' }}>{subData.number_of_registers} {subData.number_of_registers > 1 ? `Devices` : `Device`}</p>}</div>
                } else if (feature.feature_id == 'number_of_users') {
                    return <div className={`percDiv`} style={{ height: '21px' }}>{subData.unlimited_users ? <p className={`feature_values`} style={{ lineHeight: 'normal' }}>Unlimited Users</p> : subData.number_of_users == 0 || !subData.number_of_users ? <></> : <p className={`feature_values`} style={{ lineHeight: 'normal' }}>{subData.number_of_users} {subData.number_of_users > 1 ? "users" : "user"}</p>}</div>
                } else if (feature.feature_id == 'guest_visitor_registry') {
                    return <div className={`percDiv`} style={{ height: '21px' }}>{subData.unlimited_vregistries ? <p className={`feature_values`} style={{ lineHeight: 'normal' }}>Unlimited visitors per day</p> : subData.number_of_vregistries == 0 || !subData.number_of_vregistries ? <></> : <p className={`feature_values`} style={{ lineHeight: 'normal' }}>{subData.number_of_vregistries} {subData.number_of_vregistries > 1 ? "visitors per day" : "visitor per day"}</p>}</div>
                } else if (feature.feature_id == 'linky_scan_id') {
                    return <div className={`percDiv`} style={{ height: '21px' }}>{subData.unlimited_linkyScan ? <p className={`feature_values`} style={{ lineHeight: 'normal' }}>{"Unlimited scans per day"}</p> : subData.number_of_linkyScan == 0 || !subData.number_of_linkyScan ? <></> : <p className={`feature_values`} style={{ lineHeight: 'normal' }}>{subData.number_of_linkyScan} {subData.number_of_linkyScan > 1 ? "scans per day" : "scan per day"}</p>}</div>
                } else if (feature.feature_id == 'ecommerce_website') {
                    return subData.online_orders ? <div className='percDiv' style={{ height: '21px' }}>
                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.1} />
                        {/* ✔ */}
                    </div> : <Icon path={mdiCloseThick} color={`#d15353`} size={1.1} />
                } else if (feature.feature_id == 'offers_vouchers') {
                    return subData.offers_vouchers ? <div className='percDiv' style={{ height: '21px' }}>
                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.1} />
                        {/* ✔ */}
                    </div> : <Icon path={mdiCloseThick} color={`#d15353`} size={1.1} />
                } else if (feature.feature_id == 'bookings_reservations') {
                    return subData.table_reservation ? <div className='percDiv' style={{ height: '21px' }}>
                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.1} />
                        {/* ✔ */}
                    </div> : <Icon path={mdiCloseThick} color={`#d15353`} size={1.1} />
                } else if (feature.feature_id == 'loyalty_cards') {
                    return subData.loyalty_cards ? <div className='percDiv' style={{ height: '21px' }}>
                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.1} />
                        {/* ✔ */}
                    </div> : <Icon path={mdiCloseThick} color={`#d15353`} size={1.1} />
                } else if (feature.feature_id == 'payment_links') {
                    return subData.payment_links ? <div className='percDiv' style={{ height: '21px' }}>
                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.1} />
                        {/* ✔ */}
                    </div> : <Icon path={mdiCloseThick} color={`#d15353`} size={1.1} />
                } else if (feature.feature_id == 'remove_logo') {
                    return subData.remove_logo ? <div className='percDiv' style={{ height: '21px' }}>
                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.1} />
                        {/* ✔ */}
                    </div> : <Icon path={mdiCloseThick} color={`#d15353`} size={1.1} />
                } else if (feature.feature_id == 'tips_reviews') {
                    return subData.tips_reviews ? <div className='percDiv' style={{ height: '21px' }}>
                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.1} />
                        {/* ✔ */}
                    </div> : <Icon path={mdiCloseThick} color={`#d15353`} size={1.1} />
                } else if (feature.feature_id == 'call_waiter') {
                    return subData.call_waiter ? <div className='percDiv' style={{ height: '21px' }}>
                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.1} />
                        {/* ✔ */}
                    </div> : <Icon path={mdiCloseThick} color={`#d15353`} size={1.1} />
                } else if (feature.feature_id == 'clean_table') {
                    return subData.clean_table ? <div className='percDiv' style={{ height: '21px' }}>
                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.1} />
                        {/* ✔ */}
                    </div> : <Icon path={mdiCloseThick} color={`#d15353`} size={1.1} />
                } else if (feature.feature_id == 'customize_themes_fonts') {
                    return subData.customize_themes_fonts ? <div className='percDiv' style={{ height: '21px' }}>
                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.1} />
                        {/* ✔ */}
                    </div> : <Icon path={mdiCloseThick} color={`#d15353`} size={1.1} />
                }
                else {
                    return <Icon path={mdiCloseThick} color={`#d15353`} size={1.1} />
                }
            }
            else {
                return <div className='percDiv' style={{ height: '21px' }}>
                    <Icon path={mdiCheckBold} color={`#4db055`} size={1.1} />
                    {/* ✔ */}
                </div>
            }
        } else {
            if (!feature.descriptive_feature) {
                if (feature.feature_id == 'price_list') {
                    return subData.price_list ?
                        <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title}</li> :
                        <li className='off'><ImCross color='#d15353' className='mr-2' style={{ fontWeight: 'bold', marginTop: '5px', width: '16px' }} /> {feature.title}</li>
                } else if (feature.feature_id == 'sales_per_day') {
                    return <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title} ({subData.unlimited_sales ? "Unlimited sales" : subData.sales_per_day == 0 || !subData.sales_per_day ? <></> : `${subData.sales_per_day} ${subData.sales_per_day > 1 ? `sales` : `sale`}`})</li>
                } else if (feature.feature_id == 'number_of_registers') {
                    return <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title} ({subData.unlimited_registration ? "Unlimited Devices" : subData.number_of_registers == 0 || !subData.number_of_registers ? <></> : `${subData.number_of_registers} ${subData.number_of_registers > 1 ? `devices` : "device"}`})</li>
                } else if (feature.feature_id == 'number_of_users') {
                    return <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title} ({subData.unlimited_users ? "Unlimited Users" : subData.number_of_users == 0 || !subData.number_of_users ? <></> : `${subData.number_of_users} ${subData.number_of_users > 1 ? "users" : "user"}`})</li>
                } else if (feature.feature_id == 'guest_visitor_registry') {
                    return <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title} ({subData.unlimited_vregistries ? "Unlimited visitors per day" : subData.number_of_vregistries == 0 || !subData.number_of_vregistries ? <></> : `${subData.number_of_vregistries} ${subData.number_of_vregistries > 1 ? "visitors per day" : "visitor per day"}`})</li>
                } else if (feature.feature_id == 'linky_scan_id') {
                    return <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title} ({subData.unlimited_linkyScan ? "Unlimited scans per day" : subData.number_of_linkyScan == 0 || !subData.number_of_linkyScan ? <></> : `${subData.number_of_linkyScan} ${subData.number_of_linkyScan > 1 ? "scans per day" : "scan per day"}`})</li>
                } else if (feature.feature_id == 'ecommerce_website') {
                    return subData.online_orders ? <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title}</li> : <li className='off'><ImCross color='#d15353' className='mr-2' style={{ fontWeight: 'bold', marginTop: '5px', width: '16px' }} /> {feature.title}</li>
                } else if (feature.feature_id == 'offers_vouchers') {
                    return subData.offers_vouchers ? <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title}</li> : <li className='off'><ImCross color='#d15353' className='mr-2' style={{ fontWeight: 'bold', marginTop: '5px', width: '16px' }} /> {feature.title}</li>
                } else if (feature.feature_id == 'bookings_reservations') {
                    return subData.table_reservation ? <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title}</li> : <li className='off'><ImCross color='#d15353' className='mr-2' style={{ fontWeight: 'bold', marginTop: '5px', width: '16px' }} /> {feature.title}</li>
                } else if (feature.feature_id == 'loyalty_cards') {
                    return subData.loyalty_cards ? <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title}</li> : <li className='off'><ImCross color='#d15353' className='mr-2' style={{ fontWeight: 'bold', marginTop: '5px', width: '16px' }} /> {feature.title}</li>
                } else if (feature.feature_id == 'payment_links') {
                    return subData.payment_links ? <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title}</li> : <li className='off'><ImCross color='#d15353' className='mr-2' style={{ fontWeight: 'bold', marginTop: '5px', width: '16px' }} /> {feature.title}</li>
                } else if (feature.feature_id == 'remove_logo') {
                    return subData.remove_logo ? <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title}</li> : <li className='off'><ImCross color='#d15353' className='mr-2' style={{ fontWeight: 'bold', marginTop: '5px', width: '16px' }} /> {feature.title}</li>
                } else if (feature.feature_id == 'tips_reviews') {
                    return subData.tips_reviews ? <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title}</li> : <li className='off'><ImCross color='#d15353' className='mr-2' style={{ fontWeight: 'bold', marginTop: '5px', width: '16px' }} /> {feature.title}</li>
                } else if (feature.feature_id == 'call_waiter') {
                    return subData.call_waiter ? <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title}</li> : <li className='off'><ImCross color='#d15353' className='mr-2' style={{ fontWeight: 'bold', marginTop: '5px', width: '16px' }} /> {feature.title}</li>
                } else if (feature.feature_id == 'clean_table') {
                    return subData.clean_table ? <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title}</li> : <li className='off'><ImCross color='#d15353' className='mr-2' style={{ fontWeight: 'bold', marginTop: '5px', width: '16px' }} /> {feature.title}</li>
                } else if (feature.feature_id == 'customize_themes_fonts') {
                    return subData.customize_themes_fonts ? <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title}</li> : <li className='off'><ImCross color='#d15353' className='mr-2' style={{ fontWeight: 'bold', marginTop: '5px', width: '16px' }} /> {feature.title}</li>
                }
                else {
                    return ' '
                }
            }
            else {
                return <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title}</li>
            }
        }
    }

    const checkCustomFeatureValue = (feature) => {
        if (screenWidth > 800) {
            if (!feature.descriptive_feature) {
                if (feature.feature_id == 'price_list') {
                    return <div className='percDiv' style={{ height: '21px' }}>
                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.1} />
                    </div>
                } else if (feature.feature_id == 'sales_per_day') {
                    return <div className={`percDiv`} style={{ height: '21px' }}><p className={`feature_values`} style={{ lineHeight: 'normal' }}>Custom</p></div>
                } else if (feature.feature_id == 'number_of_registers') {
                    return <div className={`percDiv`} style={{ height: '21px' }}><p className={`feature_values`} style={{ lineHeight: 'normal' }}>Custom</p></div>
                } else if (feature.feature_id == 'number_of_users') {
                    return <div className={`percDiv`} style={{ height: '21px' }}><p className={`feature_values`} style={{ lineHeight: 'normal' }}>Custom</p> </div>
                } else if (feature.feature_id == 'guest_visitor_registry') {
                    return <div className={`percDiv`} style={{ height: '21px' }}><p className={`feature_values`} style={{ lineHeight: 'normal' }}>Custom</p></div>
                } else if (feature.feature_id == 'linky_scan_id') {
                    return <div className={`percDiv`} style={{ height: '21px' }}><p className={`feature_values`} style={{ lineHeight: 'normal' }}>Custom</p></div>
                } else if (feature.feature_id == 'ecommerce_website') {
                    return <div className='percDiv' style={{ height: '21px' }}>
                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.1} />

                    </div>
                } else if (feature.feature_id == 'offers_vouchers') {
                    return <div className='percDiv' style={{ height: '21px' }}>
                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.1} />
                    </div>
                } else if (feature.feature_id == 'bookings_reservations') {
                    return <div className='percDiv' style={{ height: '21px' }}>
                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.1} />
                    </div>
                } else if (feature.feature_id == 'loyalty_cards') {
                    return <div className='percDiv' style={{ height: '21px' }}>
                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.1} />
                    </div>
                } else if (feature.feature_id == 'payment_links') {
                    return <div className='percDiv' style={{ height: '21px' }}>
                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.1} />
                    </div>
                } else if (feature.feature_id == 'remove_logo') {
                    return <div className='percDiv' style={{ height: '21px' }}>
                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.1} />
                    </div>
                } else if (feature.feature_id == 'tips_reviews') {
                    return <div className='percDiv' style={{ height: '21px' }}>
                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.1} />
                    </div>
                } else if (feature.feature_id == 'call_waiter') {
                    return <div className='percDiv' style={{ height: '21px' }}>
                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.1} />
                    </div>
                } else if (feature.feature_id == 'clean_table') {
                    return <div className='percDiv' style={{ height: '21px' }}>
                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.1} />
                    </div>
                } else if (feature.feature_id == 'customize_themes_fonts') {
                    return <div className='percDiv' style={{ height: '21px' }}>
                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.1} />
                    </div>
                }
                else {
                    return <div className='percDiv' style={{ height: '21px' }}>
                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.1} />
                    </div>
                }
            }
            else {
                return <div className='percDiv' style={{ height: '21px' }}>
                    <Icon path={mdiCheckBold} color={`#4db055`} size={1.1} />
                </div>
            }
        } else {
            if (!feature.descriptive_feature) {
                if (feature.feature_id == 'price_list') {
                    return <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title}</li>
                } else if (feature.feature_id == 'sales_per_day') {
                    return <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title} (Custom)</li>
                } else if (feature.feature_id == 'number_of_registers') {
                    return <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title} (Custom)</li>
                } else if (feature.feature_id == 'number_of_users') {
                    return <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title} (Custom)</li>
                } else if (feature.feature_id == 'guest_visitor_registry') {
                    return <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title} (Custom)</li>
                } else if (feature.feature_id == 'linky_scan_id') {
                    return <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title} (Custom)</li>
                } else if (feature.feature_id == 'ecommerce_website') {
                    return <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title}</li>
                } else if (feature.feature_id == 'offers_vouchers') {
                    return <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title}</li>
                } else if (feature.feature_id == 'bookings_reservations') {
                    return <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title}</li>
                } else if (feature.feature_id == 'loyalty_cards') {
                    return <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title}</li>
                } else if (feature.feature_id == 'payment_links') {
                    return <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title}</li>
                } else if (feature.feature_id == 'remove_logo') {
                    return <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title}</li>
                } else if (feature.feature_id == 'tips_reviews') {
                    return <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title}</li>
                } else if (feature.feature_id == 'call_waiter') {
                    return <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title}</li>
                } else if (feature.feature_id == 'clean_table') {
                    return <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title}</li>
                } else if (feature.feature_id == 'customize_themes_fonts') {
                    return <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title}</li>
                }
                else {
                    return <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title}</li>
                }
            }
            else {
                return <li className='on'><FaCheck className='mr-2' style={{ marginTop: '5px', minWidth: '16px' }} color='#4db055' />{feature.title}</li>
            }
        }
    }


    // console.log(merchant_id, "merchant_id");
    // console.log(isTrial, "isTrial");
    // console.log(currentSubscription?.subscription, "currentSubscription?.subscription");
    return (
        <div className='subscriptionPlanNewCss p-0'>

            {!feaLoading && !feaDescLoading && !subLoading && !perLoading ?
                <>
                    {screenWidth > 800 ?
                        <div className="pcView">
                            
                            <div className=" container">
                            <div className='text-right'>

                            {/* <span className="mr-4 font-weight-bold" style={{ cursor: 'pointer', color: 'gray' }}>
                                <Icon path={mdiDownload} size={1.2} /> PDF</span> */}
                            <Button
                                className='my-0'
                                type='button'
                                size='sm'
                                color='primary'
                                outline

                                        onClick={() => {
                                            // history.goBack();
                                            setShowallSubscriptions({
                                                show: false,
                                                merchant_id: null
                                            })
                                        }
                                }
                            > Go Back </Button>

                            </div>
                                <Row style={{ marginTop: !merchant_id ? "4rem" : "0rem" }}>
                                    <Col className="d-flex" style={{ padding: "0rem 3rem" }}>
                                        {!merchant_id && <div className='secondFUllDiv' style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <p className='para1'>{"Please select a plan below that fits your organization's needs"}.<span className='learnMoreSpan'> <a
                                                href="https://help.linky.biz/step-1-create-account"
                                                target="_blank"
                                            >{"Learn More"}</a></span></p>
                                        </div>}
                                        {/* {subsCategory.length > 0 && <div className='priceDivMain justify-content-center' style={promoAvail ? { marginBottom: "8rem" } : { marginBottom: "40px" }}> */}
                                        {subsCategory.length > 0 && <div className='priceDivMain justify-content-center' style={promoAvail ? { marginBottom: "8rem" } : { marginBottom: "0" }}>
                                            <div
                                                className="priceOuter"
                                                // style={localStorage.getItem('setfirst_registration') ? { justifyContent: "end", paddingRight: "10rem" } : { justifyContent: "center" }}>
                                                style={{ justifyContent: "center" }}>
                                                <div className="priceSecondDiv" style={{
                                                    width: "auto",
                                                    // background: "#fff"
                                                }}>
                                                    {
                                                        subsCategory.map((element) => {
                                                            return (
                                                                <>
                                                                    <span className={selectedData?.cat_Id == element.cat_Id ? "active yearDiv text-capitalize" : "yearDiv text-capitalize"}
                                                                        onClick={() => {
                                                                            handleSubscriptionCateChange(element);
                                                                        }}>{element.subCatTitle}</span>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                    {/* <span className={yearly ? "yearDiv active" : "yearDiv"} onClick={() => {
                                                    setYearly(true)
                                                    setPopoverOpen(false)
                                                }}>{t('pricing.yearly')} {yearly && '(25% off)'}</span>
                                                <span id="PopoverTop" className={!yearly ? "monthDiv active" : "monthDiv"} onClick={() => {
                                                    setYearly(false)
                                                    setPopoverOpen(true)
                                                }}>{t('pricing.monthly')}</span> */}
                                                </div>
                                                {/* <Popover
                                                placement={merchant_id ? "top" : "bottom"}
                                                isOpen={popoverOpen}
                                                target="PopoverTop"
                                                toggle={togglePopover}
                                                dir={'ltr'}
                                                hideArrow={false}
                                                className="popoverStyle"
                                            >                                                
                                                <PopoverBody>
                                                    <div style={{ fontSize: "13px", textAlign: "center", color: "#d23f99", fontWeight: "500" }}>
                                                        {t('pricing.popup_message')} {yearlyPeriodObj?.discount_percent}%                                                       
                                                    </div>
                                                </PopoverBody>
                                            </Popover> */}
                                            </div>
                                        </div>}
                                    </Col>
                                </Row>
                            </div>
                            {localStorage.getItem('setfirst_registration') &&
                                <Row className='justify-content-center bg-white'>
                                    <div className="d-flex align-items-start justify-content-end pt-5" style={{ width: "250px" }} >
                                        <p style={{ fontSize: "15px", fontWeight: "600", color: "#000", opacity: yearly ? '0.5' : '1' }}>Monthly</p>
                                        <div style={{ margin: "0px 15px" }} className=''>
                                            <ToggleSwitch
                                                label="duration_toggle"
                                                value={duration}
                                                checked={duration}
                                                onChange={(event) => {

                                                    let getValue = event.target.checked;

                                                    if (getValue == true) {
                                                        setYearly(true)
                                                        setDuration(getValue)
                                                        setPopoverOpen(true)
                                                    } else {
                                                        setYearly(false)
                                                        setDuration(getValue)
                                                        setPopoverOpen(false)
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className='position-relative'>
                                            <p style={{ fontSize: "15px", fontWeight: "600", color: "#000", opacity: yearly ? '1' : '.5' }}>Annually</p>
                                            <p className='mt-0 position-absolute' style={{
                                                fontSize: "13px", color: "#d23f99", fontWeight: "600",
                                                left: '115%', width: '85px', top: '0',
                                                padding: '2px', borderRadius: '3px', background: "#da3e9d2e",
                                                textAlign: 'center'
                                            }}>Save 25%</p>
                                        </div>
                                    </div>
                                </Row>
                            }
                            {/* ${filterdData.length > 4 && "setMarginNew"} */}
                            <div
                                className='mx-auto container-fluid px-5  py-5 bg-white'
                                style={{
                                    // width: `${screenWidth - 74} px`,
                                    // overflowX: "auto",
                                    display: "flex",
                                    justifyContent: 'center'
                                }} >
                                <Row
                                    className={`containerTable  `}
                                    style={{
                                        marginTop: promoAvail ? "6rem" : "0rem",
                                        width: (filterdData.length > 4 && !checkIsTrial) && `${screenWidth - 74} px`,
                                        // overflowX: "auto",
                                        flexWrap: "unset",
                                        background: "#fff",
                                        // borderRadius: '25px'
                                    }}>
                                    <Col className='tableCols '
                                        // style={{ padding: "0px", maxWidth: "215px", minWidth: "215px" }}
                                        style={{ padding: "0px", maxWidth: "315px", minWidth: "315px" }}
                                    >
                                        <Col className='tableCellCol' style={{ height: '225px' }}>
                                            <div className='d-flex pb-2 divCont1'>
                                                {/* <h5 className='priceFEa'>{t('pricing.features_price')}</h5> */}
                                            </div>
                                        </Col>

                                        <Col className='tableCellCol' id='minisite_features' style={{ height: featuresHeight.minisite, padding: '0 15px' }}>
                                            <h3 className='feature_title'>{featureTitles && featureTitles.minisite.custom_title}</h3>
                                            <ul className='features_list'>
                                                {featDescriptiveDesc &&
                                                    featDescriptiveDesc.map((feature) => {
                                                        if (feature.title_id == 'minisite') {
                                                            return (
                                                                <li className='justify-content-between' >{feature.title}
                                                                    {/* <span
                                                                        id={`feature_${ feature._id } `}
                                                                        className='d-flex justify-content-center align-items-center ml-2 ibutton'
                                                                        style={{ height: '15px', width: '15px' }}
                                                                    >
                                                                        i
                                                                    </span> */}
                                                                    {(feature.description.length !== 0) && <>
                                                                        {/* <i className="fa fa-info-circle ml-2" id={`feature_${ feature._id } `}></i> */}
                                                                        <BiInfoCircle className='ml-2 circle_info_tooltip' id={`feature_${feature._id}`} style={{ fontSize: '15px', cursor: 'help', outline: 'none' }} />
                                                                        <UncontrolledTooltip placement="right" target={`feature_${feature._id}`}>
                                                                            {feature.description && feature.description?.length > 0 && feature.description}
                                                                        </UncontrolledTooltip>
                                                                    </>}
                                                                </li>
                                                            )
                                                        }
                                                    })}
                                            </ul>
                                        </Col>
                                        <Col className='tableCellCol special_cell' id='payments_features' style={{ height: featuresHeight.payments, padding: '0 15px' }}>
                                            <h3 className='feature_title'>{featureTitles && featureTitles.payments.custom_title}</h3>
                                            <ul className='features_list'>
                                                {featDescriptiveDesc &&
                                                    featDescriptiveDesc.map((feature) => {
                                                        if (feature.title_id == 'payments') {
                                                            return (
                                                                <li className='justify-content-between'>{feature.title}
                                                                    {/* <span
                                                                        id={`feature_${ feature._id } `}
                                                                        className='d-flex justify-content-center align-items-center ml-2 ibutton'
                                                                        style={{ height: '15px', width: '15px' }}
                                                                    >
                                                                        i
                                                                    </span> */}
                                                                    {/* <i className="fa fa-info-circle ml-2" id={`feature_${ feature._id } `}></i>
                                                                    <UncontrolledTooltip placement="right" target={`feature_${ feature._id } `}>
                                                                        {feature.description && feature.description?.length > 0 && feature.description}
                                                                    </UncontrolledTooltip> */}
                                                                    {(feature.description.length !== 0) && <>
                                                                        <BiInfoCircle className='ml-2 circle_info_tooltip' id={`feature_${feature._id}`} style={{ fontSize: '15px', cursor: 'help', outline: 'none' }} />
                                                                        <UncontrolledTooltip placement="right" target={`feature_${feature._id}`}>
                                                                            {feature.description && feature.description?.length > 0 && feature.description}
                                                                        </UncontrolledTooltip>
                                                                    </>}
                                                                </li>
                                                            )
                                                        }
                                                    })}
                                            </ul>
                                        </Col>
                                        <Col className='tableCellCol special_cell' id='event_management_features' style={{ height: featuresHeight.event_management, padding: '0 15px' }}>
                                            <h3 className='feature_title'>{featureTitles && featureTitles.event_management.custom_title}</h3>
                                            <ul className='features_list'>
                                                {featDescriptiveDesc &&
                                                    featDescriptiveDesc.map((feature) => {
                                                        if (feature.title_id == 'event_management') {
                                                            return (
                                                                <li className='justify-content-between'>{feature.title}
                                                                    {/* <span
                                                                        id={`feature_${ feature._id } `}
                                                                        className='d-flex justify-content-center align-items-center ml-2 ibutton'
                                                                        style={{ height: '15px', width: '15px' }}
                                                                    >
                                                                        i
                                                                    </span> */}
                                                                    {/* <i className="fa fa-info-circle ml-2" id={`feature_${ feature._id } `}></i>
                                                                    <UncontrolledTooltip placement="right" target={`feature_${ feature._id } `}>
                                                                        {feature.description && feature.description?.length > 0 && feature.description}
                                                                    </UncontrolledTooltip> */}
                                                                    {(feature.description.length !== 0) && <>
                                                                        <BiInfoCircle className='ml-2 circle_info_tooltip' id={`feature_${feature._id}`} style={{ fontSize: '15px', cursor: 'help', outline: 'none' }} />
                                                                        <UncontrolledTooltip placement="right" target={`feature_${feature._id}`}>
                                                                            {feature.description && feature.description?.length > 0 && feature.description}
                                                                        </UncontrolledTooltip>
                                                                    </>}
                                                                </li>
                                                            )
                                                        }
                                                    })}
                                            </ul>
                                        </Col>
                                        <Col className='tableCellCol special_cell' id='pos_features' style={{ height: featuresHeight.pos, padding: '0 15px' }}>
                                            <h3 className='feature_title'>{featureTitles && featureTitles.pos.custom_title}</h3>
                                            <ul className='features_list'>
                                                {featDescriptiveDesc &&
                                                    featDescriptiveDesc.map((feature) => {
                                                        if (feature.title_id == 'pos') {
                                                            return (
                                                                <li className='justify-content-between'>{feature.title}
                                                                    {/* <span
                                                                        id={`feature_${ feature._id } `}
                                                                        className='d-flex justify-content-center align-items-center ml-2 ibutton'
                                                                        style={{ height: '15px', width: '15px' }}
                                                                    >
                                                                        i
                                                                    </span> */}
                                                                    {/* <i className="fa fa-info-circle ml-2" id={`feature_${ feature._id } `}></i>
                                                                    <UncontrolledTooltip placement="right" target={`feature_${ feature._id } `}>
                                                                        {feature.description && feature.description?.length > 0 && feature.description}
                                                                    </UncontrolledTooltip> */}
                                                                    {(feature.description.length !== 0) && <>
                                                                        <BiInfoCircle className='ml-2 circle_info_tooltip' id={`feature_${feature._id}`} style={{ fontSize: '15px', cursor: 'help', outline: 'none' }} />
                                                                        <UncontrolledTooltip placement="right" target={`feature_${feature._id}`}>
                                                                            {feature.description && feature.description?.length > 0 && feature.description}
                                                                        </UncontrolledTooltip>
                                                                    </>}
                                                                </li>
                                                            )
                                                        }
                                                    })}
                                            </ul>
                                        </Col>
                                        <Col className='tableCellCol special_cell' id='ecommerce_features' style={{ height: featuresHeight.ecommerce, padding: '0 15px' }}>
                                            <h3 className='feature_title'>{featureTitles && featureTitles.ecommerce.custom_title}</h3>
                                            <ul className='features_list'>
                                                {featDescriptiveDesc &&
                                                    featDescriptiveDesc.map((feature) => {
                                                        if (feature.title_id == 'ecommerce') {
                                                            return (
                                                                <li className='justify-content-between'>{feature.title}
                                                                    {/* <span
                                                                        id={`feature_${ feature._id } `}
                                                                        className='d-flex justify-content-center align-items-center ml-2 ibutton'
                                                                        style={{ height: '15px', width: '15px' }}
                                                                    >
                                                                        i
                                                                    </span> */}
                                                                    {/* <i className="fa fa-info-circle ml-2" id={`feature_${ feature._id } `}></i>
                                                                    <UncontrolledTooltip placement="right" target={`feature_${ feature._id } `}>
                                                                        {feature.description && feature.description?.length > 0 && feature.description}
                                                                    </UncontrolledTooltip> */}
                                                                    {(feature.description.length !== 0) && <>
                                                                        <BiInfoCircle className='ml-2 circle_info_tooltip' id={`feature_${feature._id}`} style={{ fontSize: '15px', cursor: 'help', outline: 'none' }} />
                                                                        <UncontrolledTooltip placement="right" target={`feature_${feature._id}`}>
                                                                            {feature.description && feature.description?.length > 0 && feature.description}
                                                                        </UncontrolledTooltip>
                                                                    </>}
                                                                </li>
                                                            )
                                                        }
                                                    })}
                                            </ul>
                                        </Col>
                                        <Col className='tableCellCol special_cell' id='others_features' style={{ height: featuresHeight.others, padding: '0 15px' }}>
                                            <h3 className='feature_title'>{featureTitles && featureTitles.others.custom_title}</h3>
                                            <ul className='features_list'>
                                                {featDescriptiveDesc &&
                                                    featDescriptiveDesc.map((feature) => {
                                                        if (feature.title_id == 'others') {
                                                            return (
                                                                <li className='justify-content-between'>{feature.title}
                                                                    {/* <span
                                                                        id={`feature_${ feature._id } `}
                                                                        className='d-flex justify-content-center align-items-center ml-2 ibutton'
                                                                        style={{ height: '15px', width: '15px' }}
                                                                    >
                                                                        i
                                                                    </span> */}
                                                                    {/* <i className="fa fa-info-circle ml-2" id={`feature_${ feature._id } `}></i>
                                                                    <UncontrolledTooltip placement="right" target={`feature_${ feature._id } `}>
                                                                        {feature.description && feature.description?.length > 0 && feature.description}
                                                                    </UncontrolledTooltip> */}
                                                                    {(feature.description.length !== 0) && <>
                                                                        <BiInfoCircle className='ml-2 circle_info_tooltip' id={`feature_${feature._id}`} style={{ fontSize: '15px', cursor: 'help', outline: 'none' }} />
                                                                        <UncontrolledTooltip placement="right" target={`feature_${feature._id}`}>
                                                                            {feature.description && feature.description?.length > 0 && feature.description}
                                                                        </UncontrolledTooltip>
                                                                    </>}
                                                                </li>
                                                            )
                                                        }
                                                    })}
                                            </ul>
                                        </Col>
                                        
                                    </Col>

                                    {filterdData.map((subData, ind) => {

                                        console.log(subData, "subData")
                                        let temp = {
                                            visitor: false,
                                            custom: false,
                                            help: false,
                                            developers: false,
                                        }
                                        if (subData.descriptive_features?.length > 0) {
                                            subData.descriptive_features.map((textt) => {

                                                if (textt.toLowerCase().includes("visitor")) {
                                                    temp.visitor = true
                                                }
                                                if (textt.toLowerCase().includes("custom")) {
                                                    temp.custom = true
                                                }
                                                if (textt.toLowerCase().includes("help")) {
                                                    temp.help = true
                                                }
                                                if (textt.toLowerCase().includes("developer's")) {
                                                    temp.developers = true
                                                }

                                            })
                                        }

                                        let date2 = new Date()
                                        if (subData.promo_end_date) {
                                            const isoStr1 = subData.promo_end_date;
                                            {/* console.log(isoStr1, "iso") */ }
                                            date2 = new Date(isoStr1.slice(0, -1));
                                        }

                                        let subFees = subData.fees;
                                        if (subData.promo_rate) {
                                            subFees = subData.promo_rate
                                        }
                                        {/* console.log(isTrial, subData.is_trial, isTrial == subData.is_trial && suspend, suspend) */ }
                                        {/* console.log(subData, subData.title, "Sub") */ }
                                        {/* console.log(temp, "temp") */ }
                                        return (
                                            <>
                                                {
                                                    checkValid(subData.is_trial) ? null :
                                                        <Col className={subData.popular ? `tableCols popularCss p - 0 ` : `tableCols `} style={{ padding: "0px", maxWidth: "235px", minWidth: "235px", position: 'relative' }}>
                                                            {/* <Col className={subData.popular ? `tableCols popularCss p - 0 ${is_current_plan(subData._id) && "selectedCard"} ` : `tableCols ${is_current_plan(subData._id) && "selectedCard"} `} style={{ padding: "0px", maxWidth: "215px", minWidth: "215px" }}> */}
                                                            {subData.popular ? <>
                                                                {/* <div className='popularName'>{t('pricing.popular')}</div> */}
                                                                {/* <div className='popular_container'>
                                                                    </div> */}
                                                                <div className='popularName' style={{ position: "absolute", left: '0', right: '0', top: "-15px", zIndex: '5' }}>
                                                                    Most Popular
                                                                </div>

                                                                {/* <div className='triangleDiv'>
                                                                        <Icon path={mdiMenuDown} color="#d23f99" size={3} style={{ margin: "-24px" }} />
                                                                    </div> */}
                                                            </> : subData.is_trial ? <div style={{ background: "#99d9ea", fontSize: '1.2rem', fontWeight: 500, borderRadius: '5px' }} className='mt-3 p-2 text-center'>
                                                                Card Not Required*
                                                            </div> : null}
                                                            <Col className={subData.popular ? 'tableCellCol p-0 ' : "tableCellCol"} style={{ height: '225px' }}>
                                                                {/* {subData.popular ? <> <div className='popularName'>Popular</div> */}
                                                                {/* {hasPopular ? <>
                                                                </> : <div style={{ height: '25px' }}></div>} */}

                                                                {/* </div> : <div className='emptyDiv'></div>} */}


                                                                <div className='priceDivsCol'
                                                                    style={subData.popular ? { padding: "0px 15px", marginTop: '25px' } : { marginTop: '25px' }}
                                                                >
                                                                    <h6 className={`priceTitle`}>{subData.title}</h6>
                                                                    {check_promo_status(subData) && (
                                                                        <div
                                                                            className="text-center timerDiv"
                                                                        >
                                                                            <h5 className='offerExp' style={{ textAlign: "center", fontSize: "15px" }}> Offer expires in </h5>
                                                                            {/* <MyTimer expiryTimestamp={date2} /> */}
                                                                        </div>
                                                                    )}
                                                                    <p className={`priceDesc `}>{subData.description}</p>
                                                                    <h5 className={`monthtit`} ><span className={`monthFees`}>{merchantDetails?.country?.currency_symbol}{yearly ? calcPrice(subFees).toFixed(2) : subFees} </span>{subData.promo_rate && !subData.is_trial && <s className='strikedd'>{calcPrice(subData.fees).toFixed(2)}</s>} <span
                                                                        style={{ color: '#000', fontSize: '13px' }}
                                                                        className='m-0 text-center'>/month</span></h5>
                                                                    
                                                                    {is_current_plan(subData._id) &&
                                                                        <div className='butonDiv newDiv' style={{ width: '65%' }}>
                                                                            <button size='md' style={is_current_plan(subData._id) ? { color: "#fff", backgroundColor: "darkgray", fontSize: '14px', padding: '8px', borderRadius: '6px', cursor: 'not-allowed' } : { color: "#fff", backgroundColor: "#d23f99", fontSize: '14px', padding: '8px', borderRadius: '6px' }}
                                                                                className='btn m-0 w-100'
                                                                                onClick={() => {
                                                                                    // if (merchant_id) {
                                                                                    //     if (!is_current_plan(subData._id)) {
                                                                                    //         localStorage.setItem('yearly', JSON.stringify(yearly));
                                                                                    //         if (currentSub_id) {
                                                                                    //             // if (ind < selectedIndex) {
                                                                                    //             if (subData.fees < currentPlan.fees) {
                                                                                    //                 if (!suspend) {
                                                                                    //                     setSelectedPlan(subData);
                                                                                    //                     toggleDowngradeModal();
                                                                                    //                 } else {
                                                                                    //                     setSelected(subData._id);
                                                                                    //                 }
                                                                                    //             } else {

                                                                                    //                 if (!suspend) {
                                                                                    //                     setSelectedPlan(subData);
                                                                                    //                     toggleUpgradeModal();
                                                                                    //                 } else {
                                                                                    //                     setSelected(subData._id);
                                                                                    //                 }
                                                                                    //             }
                                                                                    //             setDefaultLoading(false);
                                                                                    //         } else {
                                                                                    //             // setDefaultLoading(true);
                                                                                    //             setSelected(subData._id);
                                                                                    //             // setDefaultLoading(false);
                                                                                    //         }
                                                                                    //     }
                                                                                    // } else {
                                                                                    //     history.push("/login")
                                                                                    // }
                                                                                    // sessionStorage.removeItem('Ipaybranding')
                                                                                    // history.push(`/ subscription - plans - checkout / ${ merchant_id } /${subData._id}`)

                                                                                }
                                                                                } > {is_current_plan(subData._id) ? "Current Plan" : "Get started"
                                                                                }
                                                                            </button>
                                                                        </div>
                                                                    }

                                                                </div>
                                                            </Col>
                                                            {/* <Col className='tableCellCol' style={{ height: "80px" }}><div className='percDiv'>1.9%*</div></Col> */}

                                                            <Col className='tableCellCol' style={{ height: featuresHeight.minisite, padding: '0 15px' }}>
                                                                <h3 className='feature_title'>
                                                                </h3>
                                                                <ul className='features_list'>
                                                                    {featDescriptiveDesc &&
                                                                        featDescriptiveDesc.map((feature) => {
                                                                            if (feature.title_id == 'minisite') {
                                                                                return (
                                                                                    <li className='justify-content-center'>
                                                                                        {checkFeatureValue(feature, subData)}
                                                                                    </li>
                                                                                )
                                                                            }
                                                                        })}
                                                                </ul>
                                                            </Col>
                                                            <Col className='tableCellCol special_cell' style={{ height: featuresHeight.payments, padding: '0 15px' }}>
                                                                <h3 className='feature_title'>
                                                                </h3>
                                                                <ul className='features_list'>
                                                                    {featDescriptiveDesc &&
                                                                        featDescriptiveDesc.map((feature) => {
                                                                            if (feature.title_id == 'payments') {
                                                                                return (
                                                                                    <li className='justify-content-center'>
                                                                                        {checkFeatureValue(feature, subData)}
                                                                                    </li>
                                                                                )
                                                                            }
                                                                        })}
                                                                </ul>
                                                            </Col>
                                                            <Col className='tableCellCol special_cell' style={{ height: featuresHeight.event_management, padding: '0 15px' }}>
                                                                <h3 className='feature_title'>
                                                                </h3>
                                                                <ul className='features_list'>
                                                                    {featDescriptiveDesc &&
                                                                        featDescriptiveDesc.map((feature) => {
                                                                            if (feature.title_id == 'event_management') {
                                                                                return (
                                                                                    <li className='justify-content-center'>
                                                                                        {checkFeatureValue(feature, subData)}
                                                                                    </li>
                                                                                )
                                                                            }
                                                                        })}
                                                                </ul>
                                                            </Col>
                                                            <Col className='tableCellCol special_cell' style={{ height: featuresHeight.pos, padding: '0 15px' }}>
                                                                <h3 className='feature_title'>
                                                                </h3>
                                                                <ul className='features_list'>
                                                                    {featDescriptiveDesc &&
                                                                        featDescriptiveDesc.map((feature) => {
                                                                            if (feature.title_id == 'pos') {
                                                                                return (
                                                                                    <li className='justify-content-center'>
                                                                                        {checkFeatureValue(feature, subData)}
                                                                                    </li>
                                                                                )
                                                                            }
                                                                        })}
                                                                </ul>
                                                            </Col>
                                                            <Col className='tableCellCol special_cell' style={{ height: featuresHeight.ecommerce, padding: '0 15px' }}>
                                                                <h3 className='feature_title'>
                                                                </h3>
                                                                <ul className='features_list'>
                                                                    {featDescriptiveDesc &&
                                                                        featDescriptiveDesc.map((feature) => {
                                                                            if (feature.title_id == 'ecommerce') {
                                                                                return (
                                                                                    <li className='justify-content-center'>
                                                                                        {checkFeatureValue(feature, subData)}
                                                                                    </li>
                                                                                )
                                                                            }
                                                                        })}
                                                                </ul>
                                                            </Col>
                                                            <Col className='tableCellCol special_cell' style={{ height: featuresHeight.others, padding: '0 15px' }}>
                                                                <h3 className='feature_title'>
                                                                </h3>
                                                                <ul className='features_list'>
                                                                    {featDescriptiveDesc &&
                                                                        featDescriptiveDesc.map((feature) => {
                                                                            if (feature.title_id == 'others') {
                                                                                return (
                                                                                    <li className='justify-content-center'>
                                                                                        {checkFeatureValue(feature, subData)}
                                                                                    </li>
                                                                                )
                                                                            }
                                                                        })}
                                                                </ul>
                                                            </Col>
                                                            
                                                        </Col>
                                                } </>
                                        )

                                    })}

                                    {
                                        selectedData?.cat_Id == "003" &&
                                        <Col
                                            className={`tableCols p-0`}
                                            style={{ padding: "0px", maxWidth: "235px", minWidth: "235px" }}
                                        >
                                            <Col className={"tableCellCol"} style={{ height: '225px' }}>
                                                <div className='priceDivsCol' style={{ marginTop: '25px' }} >
                                                    {/* {hasPopular ? <div className='emptyDiv'></div> : <div style={{ height: '25px' }}></div>} */}
                                                    <h6 className={`priceTitle`}>Custom</h6>
                                                    {/* <p style={{ minHeight: 'auto' }} className={`priceDesc `}>Premium team onboarding, education and personal video, success coach </p> */}
                                                    <p style={{ minHeight: 'auto' }} className={`priceDesc `}>Custom solution for teams of 5 or more users</p>
                                                    {/* <h5 className={`h5Billed`} style={{ textAlign: "center" }}>Custom solution for teams of 5 or more users  </h5> */}
                                                    <h5 className={`monthtit`} style={{ color: '#000' }}>
                                                        <span className="monthFees" style={{ fontSize: '18px' }}>Let's Talk</span>
                                                    </h5>
                                                    <div className='butonDiv newDiv' style={{ width: '65%' }}>
                                                        <a href='https://linky.biz/contact-us/' target='_blank' className='w-100'>
                                                            <Button
                                                                size='md'
                                                                style={{ color: "#fff", backgroundColor: "#d23f99", fontSize: '14px', borderRadius: '6px', padding: '8px' }}
                                                                className='m-0 w-100'
                                                                onClick={() => { }} >
                                                                Contact Us
                                                            </Button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </Col>
                                            {/* <Col className='tableCellCol' style={{ height: "80px" }}><div className={`percDiv`}><p className={`textLine`}> Unlimited sales</p> </div> </Col>
                                            <Col className='tableCellCol' style={{ height: "80px" }}><div className={`percDiv`}>{<p className={`textLine`}>Unlimited device</p>}</div></Col>
                                            <Col className='tableCellCol' style={{ height: "80px" }}><div className={`percDiv`}>{<p className={`textLine`}>Unlimited users</p>}</div></Col>
                                            <Col className='tableCellCol' style={{ height: "80px" }}><div className={`percDiv`}>{<p className={`textLine`}>Custom</p>}</div></Col>
                                            <Col className='tableCellCol' style={{ height: "80px" }}><div className={`percDiv`}> <p className={`textLine`}>Custom</p></div></Col>

                                            <Col className='tableCellCol' style={{ height: "80px" }}>
                                                <div className='percDiv'><Icon path={mdiCheckBold} color={`#4db055`} size={1.7} /></div>
                                            </Col>

                                            <Col className='tableCellCol' style={{ height: "80px" }}>
                                                <div className='percDiv'>
                                                    <Icon path={mdiCheckBold} color={`#4db055`} size={1.7} />
                                                </div>
                                            </Col>

                                            <Col className='tableCellCol' style={{ height: "80px" }}>
                                                <div className='percDiv'>
                                                    <Icon path={mdiCheckBold} color={`#4db055`} size={1.7} />
                                                </div>
                                            </Col>

                                            <Col className='tableCellCol' style={{ height: "80px" }}>
                                                <div className='percDiv'>
                                                    <Icon path={mdiCheckBold} color={`#4db055`} size={1.7} />
                                                </div>
                                            </Col>

                                            <Col className='tableCellCol' style={{ height: "80px" }}>{<div className='percDiv'>
                                                <Icon path={mdiCheckBold} color={`#4db055`} size={1.7} />
                                            </div>}</Col>

                                            <Col className='tableCellCol' style={{ height: "80px" }}>
                                                <div className='percDiv'>
                                                    <Icon path={mdiCheckBold} color={`#4db055`} size={1.7} />
                                                </div>
                                            </Col>

                                            <Col className='tableCellCol' style={{ height: "80px" }}>
                                                <div className='percDiv'>
                                                    <><Icon path={mdiCheckBold} color={`#4db055`} size={1.7} /></>
                                                </div>
                                            </Col>


                                            <Col className='tableCellCol' style={{ height: "80px" }}>
                                                {<div className='percDiv'>
                                                    <Icon path={mdiCheckBold} color={`#4db055`} size={1.7} />
                                                </div>}
                                            </Col>

                                            <Col className='tableCellCol' style={{ height: "80px", borderBottom: "none" }}>
                                                {<div className='percDiv'>
                                                    {t('pricing.talk_to_us')}
                                                </div>}

                                            </Col> */}
                                            <Col className='tableCellCol' style={{ height: featuresHeight.minisite, }}>
                                                <h3 className='feature_title'>
                                                </h3>
                                                <ul className='features_list'>
                                                    {featDescriptiveDesc &&
                                                        featDescriptiveDesc.map((feature) => {
                                                            if (feature.title_id == 'minisite') {
                                                                return (
                                                                    <li className='justify-content-center'>
                                                                        {checkCustomFeatureValue(feature)}
                                                                    </li>
                                                                )
                                                            }
                                                        })}
                                                </ul>

                                            </Col>
                                            <Col className='tableCellCol special_cell' style={{ height: featuresHeight.payments, }}>
                                                <h3 className='feature_title'>
                                                </h3>
                                                <ul className='features_list'>
                                                    {featDescriptiveDesc &&
                                                        featDescriptiveDesc.map((feature) => {
                                                            if (feature.title_id == 'payments') {
                                                                return (
                                                                    <li className='justify-content-center'>
                                                                        {checkCustomFeatureValue(feature)}
                                                                    </li>
                                                                )
                                                            }
                                                        })}
                                                </ul>
                                            </Col>
                                            <Col className='tableCellCol special_cell' style={{ height: featuresHeight.event_management, }}>
                                                <h3 className='feature_title'>
                                                </h3>
                                                <ul className='features_list'>
                                                    {featDescriptiveDesc &&
                                                        featDescriptiveDesc.map((feature) => {
                                                            if (feature.title_id == 'event_management') {
                                                                return (
                                                                    <li className='justify-content-center'>
                                                                        {checkCustomFeatureValue(feature)}
                                                                    </li>
                                                                )
                                                            }
                                                        })}
                                                </ul>
                                            </Col>
                                            <Col className='tableCellCol special_cell' style={{ height: featuresHeight.pos, }}>
                                                <h3 className='feature_title'>
                                                </h3>
                                                <ul className='features_list'>
                                                    {featDescriptiveDesc &&
                                                        featDescriptiveDesc.map((feature) => {
                                                            if (feature.title_id == 'pos') {
                                                                return (
                                                                    <li className='justify-content-center'>
                                                                        {checkCustomFeatureValue(feature)}
                                                                    </li>
                                                                )
                                                            }
                                                        })}
                                                </ul>
                                            </Col>
                                            <Col className='tableCellCol special_cell' style={{ height: featuresHeight.ecommerce, }}>
                                                <h3 className='feature_title'>
                                                </h3>
                                                <ul className='features_list'>
                                                    {featDescriptiveDesc &&
                                                        featDescriptiveDesc.map((feature) => {
                                                            if (feature.title_id == 'ecommerce') {
                                                                return (
                                                                    <li className='justify-content-center'>
                                                                        {checkCustomFeatureValue(feature)}
                                                                    </li>
                                                                )
                                                            }
                                                        })}
                                                </ul>
                                            </Col>
                                            <Col className='tableCellCol special_cell' style={{ height: featuresHeight.others, }}>
                                                <h3 className='feature_title'>
                                                </h3>
                                                <ul className='features_list'>
                                                    {featDescriptiveDesc &&
                                                        featDescriptiveDesc.map((feature) => {
                                                            if (feature.title_id == 'others') {
                                                                return (
                                                                    <li className='justify-content-center'>
                                                                        {checkCustomFeatureValue(feature)}
                                                                    </li>
                                                                )
                                                            }
                                                        })}
                                                </ul>
                                            </Col>
                                        </Col>
                                    }

                                </Row >
                            </div >

                        </div>
                        :
                        <>
                            <div className='mobileView d-none testmob'>
                                <Row style={!merchant_id ? { marginTop: "13vh" } : { marginTop: "0rem" }}>
                                    <Col>
                                        <div className='secondFUllDiv' style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            {/* <p className='para1 mobileViewP1'>Please select a plan below that fits your organization's needs.<span className='learnMoreSpan'> <a */}
                                            <p className='para1 mobileViewP1'>{"Please select a plan below that fits your organization's needs"}. <span className='learnMoreSpan'> <a

                                                href="https://help.linky.biz/step-1-create-account"
                                                target="_blank"
                                            >{"Learn More"} </a></span></p>
                                        </div>

                                        {/* {subsCategory.length > 0 && <div className='priceDivMain' style={promoAvail ? { marginBottom: "8rem" } : { marginBottom: "40px" }}>
                                            <div
                                                className="priceOuter"
                                                style={{ justifyContent: "center" }}>
                                                <div className="priceSecondDiv" style={{ width: "auto", background: "#fff" }}>
                                                    {
                                                        subsCategory.map((element) => {
                                                            return (
                                                                <>
                                                                    <span className={selectedData?.cat_Id == element.cat_Id ? "active yearDiv text-capitalize" : "yearDiv text-capitalize"}
                                                                        onClick={() => {
                                                                            handleSubscriptionCateChange(element);
                                                                        }}>{element.subCatTitle}</span>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>} */}

                                        <div className='priceDivMain mobViewPrice'>
                                            <div className="priceOuter">
                                                <div className="priceSecondDiv">
                                                    <span className={yearly ? "yearDiv active" : "yearDiv"} onClick={() => {
                                                        setYearly(true)
                                                        setPopoverOpenMob(false)
                                                    }}>{"Yearly"} {yearly && '(25% off)'}</span>
                                                    <span id="PopoverTopMob" className={!yearly ? "monthDiv active" : "monthDiv"} onClick={() => {
                                                        setYearly(false)
                                                        setPopoverOpenMob(true)
                                                    }}>{"Monthly"}</span>
                                                </div>
                                                <Popover
                                                    placement={merchant_id ? "top" : "bottom"}
                                                    isOpen={popoverOpenMob}
                                                    target="PopoverTopMob"
                                                    toggle={togglePopoverMob}
                                                    dir={'ltr'}
                                                    hideArrow={false}
                                                    className="popoverStyle"
                                                >
                                                    {/* <PopoverHeader><h6 style={{ fontSize: "16px", fontWeight: "600" }}>Users List</h6></PopoverHeader> */}
                                                    <PopoverBody>
                                                        <div style={{ fontSize: "13px", textAlign: "center", color: "#d23f99", fontWeight: "500" }}>
                                                            {"Pay annually and save up to"} {yearlyPeriodObj?.discount_percent}%
                                                            {/* Pay annually and save up to {yearlyPeriodObj?.discount_percent}% */}
                                                        </div>
                                                    </PopoverBody>
                                                </Popover>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                {
                                    filterdData.map((subData, ind) => {
                                        let temp = {
                                            visitor: false,
                                            custom: false,
                                            help: false,
                                            developers: false,
                                        }
                                        if (subData.descriptive_features?.length > 0) {
                                            subData.descriptive_features.map((textt) => {

                                                if (textt.toLowerCase().includes("visitor")) {
                                                    temp.visitor = true
                                                }
                                                if (textt.toLowerCase().includes("custom")) {
                                                    temp.custom = true
                                                }
                                                if (textt.toLowerCase().includes("help")) {
                                                    temp.help = true
                                                }
                                                if (textt.toLowerCase().includes("developer's")) {
                                                    temp.developers = true
                                                }

                                            })
                                        }

                                        let date2 = new Date()
                                        if (subData.promo_end_date) {
                                            const isoStr1 = subData.promo_end_date;
                                            {/* console.log(isoStr1, "iso") */ }
                                            date2 = new Date(isoStr1.slice(0, -1));
                                        }

                                        let subFees = subData.fees;
                                        if (subData.promo_rate) {
                                            subFees = subData.promo_rate
                                        }

                                        {/* console.log(subData, "Sub") */ }
                                        {/* console.log(temp, "temp") */ }
                                        return (
                                            <Row className={`containerTable contMob ${check_promo_status(subData) ? "marginTopMob" : "axd"}`}>
                                                <Col className='tableCols ' style={{ padding: "0px", maxWidth: "215px", minWidth: "215px" }}>
                                                    <Col className='tableCellCol' style={subData.is_trial ? { height: "375px" } : subData.popular ? { height: "415px" } : subData.description.length < 65 ? { height: "330px" } : { height: "380px" }} >
                                                        <div className='d-flex divCont1'>
                                                            <h5 className='pricee'>Price</h5>
                                                            <h5 className='priceFEa'>Features/</h5>
                                                        </div>
                                                    </Col>
                                                    {/* <Col className='tableCellCol' style={{ height: "150px" }}>
                        <div className='integDiv'>
                            <h5 className='integTitle'>Integrated Payment Processing</h5>
                            <p className='integDesc'>Reduce errors and save time with iPayPOS's integrated payments</p>
                            <p className='gst'>*ex. GST</p>
                        </div>
                    </Col> */}
                                                    {catFea[0]?.pos == 1 && merchFeatData?.pos == 1 && <Col className='tableCellCol' style={{ height: "80px" }}>
                                                        <div className='integDiv'>
                                                            <h5 className='integTitle'>Cloud-based POS <button id="cloudBasedP" onClick={() => {
                                                                if (featDesc.length) {
                                                                    if (featDesc[0]?.cloud_based_desc) {
                                                                        setPTargetId(featDesc[0]?.cloud_based_desc)
                                                                    }
                                                                }
                                                            }} onBlur={() => {
                                                                setPopoverInfoOpen(false);
                                                            }}>i</button>  </h5>
                                                            {/* <p className='integDesc'>{featDesc.length && featDesc[0]?.cloud_based_desc}</p> */}
                                                        </div>
                                                    </Col>}
                                                    {catFea[0]?.registers == 1 && merchFeatData?.registers == 1 && <Col className='tableCellCol' style={{ height: "80px" }}>
                                                        <div className='integDiv'>
                                                            <h5 className='integTitle'>Register <button id="cloudBasedP" onClick={() => {
                                                                if (featDesc.length) {
                                                                    if (featDesc[0]?.register_desc) {
                                                                        setPTargetId(featDesc[0]?.register_desc)
                                                                    }
                                                                }
                                                            }} onBlur={() => {
                                                                setPopoverInfoOpen(false);
                                                            }}>i</button> </h5>
                                                            {/* <p className='integDesc'>{featDesc.length && featDesc[0]?.register_desc}</p> */}
                                                        </div>
                                                    </Col>}
                                                    <Col className='tableCellCol' style={{ height: "80px" }}>
                                                        <div className='integDiv'>
                                                            <h5 className='integTitle'>Users & Roles
                                                                <button id="cloudBasedP" onClick={() => {
                                                                    if (featDesc.length) {
                                                                        if (featDesc[0]?.users_roles_desc) {
                                                                            setPTargetId(featDesc[0]?.users_roles_desc)
                                                                        }
                                                                    }
                                                                }} onBlur={() => {
                                                                    setPopoverInfoOpen(false);
                                                                }}>i</button>
                                                            </h5>
                                                            {/* <p className='integDesc'>{featDesc.length && featDesc[0]?.users_roles_desc}</p> */}
                                                        </div>
                                                    </Col>

                                                    {catFea[0]?.visitor_registry == 1 && merchFeatData?.visitor_registry == 1 && <Col className='tableCellCol' style={{ height: "80px" }}>
                                                        <div className='integDiv'>
                                                            <h5 className='integTitle'>Visitor registry
                                                                <button id="cloudBasedP" onClick={() => {
                                                                    if (featDesc.length) {
                                                                        if (featDesc[0]?.visitor_registry_desc) {
                                                                            setPTargetId(featDesc[0]?.visitor_registry_desc)
                                                                        }
                                                                    }
                                                                }} onBlur={() => {
                                                                    setPopoverInfoOpen(false);
                                                                }}>i</button>
                                                            </h5>
                                                            {/* <p className='integDesc'>{featDesc.length && featDesc[0]?.visitor_registry_desc}</p> */}
                                                        </div>
                                                    </Col>}
                                                    <Col className='tableCellCol' style={{ height: "80px" }}>
                                                        <div className='integDiv'>
                                                            <h5 className='integTitle'>Linky Scan
                                                                <button id="cloudBasedP" onClick={() => {
                                                                    if (featDesc.length) {
                                                                        if (featDesc[0]?.linky_scan_desc) {
                                                                            setPTargetId(featDesc[0]?.linky_scan_desc)
                                                                        }
                                                                    }
                                                                }} onBlur={() => {
                                                                    setPopoverInfoOpen(false);
                                                                }}>i</button>
                                                            </h5>
                                                            {/* <p className='integDesc'>{featDesc.length && featDesc[0]?.visitor_registry_desc}</p> */}
                                                        </div>
                                                    </Col>
                                                    {/* <Col className='tableCellCol' style={{ height: "80px" }}>
                            <div className='integDiv'>
                                <h5 className='integTitle'>Visitor Registry</h5>
                                <p className='integDesc'>{objData.visitorpara.length > 0 && objData.visitorpara}</p>

                            </div>
                        </Col> */}
                                                    {catFea[0]?.online_ordering_system == 1 && merchFeatData?.online_ordering_system == 1 && <Col className='tableCellCol' style={{ height: "80px" }}>
                                                        <div className='integDiv'>
                                                            <h5 className='integTitle'>Online & QR Ordering
                                                                <button id="cloudBasedP" onClick={() => {
                                                                    if (featDesc.length) {
                                                                        if (featDesc[0]?.online_order_desc) {
                                                                            setPTargetId(featDesc[0]?.online_order_desc)
                                                                        }
                                                                    }
                                                                }} onBlur={() => {
                                                                    setPopoverInfoOpen(false);
                                                                }}>i</button>
                                                            </h5>
                                                            {/* <p className='integDesc'>{featDesc.length && featDesc[0]?.online_order_desc}</p> */}
                                                        </div>
                                                    </Col>}
                                                    {catFea[0]?.table_reservation == 1 && merchFeatData?.table_reservation == 1 && <Col className='tableCellCol' style={{ height: "80px" }}>
                                                        <div className='integDiv'>
                                                            <h5 className='integTitle'>Booking Management
                                                                <button id="cloudBasedP" onClick={() => {
                                                                    if (featDesc.length) {
                                                                        if (featDesc[0]?.table_reservation_desc) {
                                                                            setPTargetId(featDesc[0]?.table_reservation_desc)
                                                                        }
                                                                    }
                                                                }} onBlur={() => {
                                                                    setPopoverInfoOpen(false);
                                                                }}>i</button>
                                                            </h5>
                                                            {/* <p className='integDesc'>{featDesc.length && featDesc[0]?.table_reservation_desc}</p> */}
                                                        </div>
                                                    </Col>}
                                                    {catFea[0]?.offers_and_vouchers == 1 && merchFeatData?.offers_and_vouchers == 1 && <Col className='tableCellCol' style={{ height: "80px" }}>
                                                        <div className='integDiv'>
                                                            <h5 className='integTitle'>Offers & Vouchers
                                                                <button id="cloudBasedP" onClick={() => {
                                                                    if (featDesc.length) {
                                                                        if (featDesc[0]?.offers_vouchers_desc) {
                                                                            setPTargetId(featDesc[0]?.offers_vouchers_desc)
                                                                        }
                                                                    }
                                                                }} onBlur={() => {
                                                                    setPopoverInfoOpen(false);
                                                                }}>i</button>
                                                            </h5>
                                                            {/* <p className='integDesc'>{featDesc.length && featDesc[0]?.offers_vouchers_desc}</p> */}
                                                        </div>
                                                    </Col>}
                                                    {catFea[0]?.loyalty_cards == 1 && merchFeatData?.loyalty_cards == 1 && <Col className='tableCellCol' style={{ height: "80px" }}>
                                                        <div className='integDiv'>
                                                            <h5 className='integTitle'>Loyalty Cards
                                                                <button id="cloudBasedP" onClick={() => {
                                                                    if (featDesc.length) {
                                                                        if (featDesc[0]?.loyalty_card_desc) {
                                                                            setPTargetId(featDesc[0]?.loyalty_card_desc)
                                                                        }
                                                                    }
                                                                }} onBlur={() => {
                                                                    setPopoverInfoOpen(false);
                                                                }}>i</button>
                                                            </h5>
                                                            {/* <p className='integDesc'>{featDesc.length && featDesc[0]?.loyalty_card_desc}</p> */}
                                                        </div>
                                                    </Col>}
                                                    {catFea[0]?.payment_links == 1 && merchFeatData?.payment_links == 1 && <Col className='tableCellCol' style={{ height: "80px" }}>
                                                        <div className='integDiv'>
                                                            <h5 className='integTitle'>Payment Links
                                                                <button id="cloudBasedP" onClick={() => {
                                                                    if (featDesc.length) {
                                                                        if (featDesc[0]?.payment_link_desc) {
                                                                            setPTargetId(featDesc[0]?.payment_link_desc)
                                                                        }
                                                                    }
                                                                }} onBlur={() => {
                                                                    setPopoverInfoOpen(false);
                                                                }}>i</button>
                                                            </h5>
                                                            {/* <p className='integDesc'>{featDesc.length && featDesc[0]?.payment_link_desc}</p> */}
                                                        </div>
                                                    </Col>}
                                                    {<Col className='tableCellCol' style={{ height: "80px" }}>
                                                        <div className='integDiv'>
                                                            <h5 className='integTitle'>Price list
                                                                <button id="cloudBasedP" onClick={() => {
                                                                    if (featDesc.length) {
                                                                        if (featDesc[0]?.price_list_desc) {
                                                                            setPTargetId(featDesc[0]?.price_list_desc)
                                                                        }
                                                                    }
                                                                }} onBlur={() => {
                                                                    setPopoverInfoOpen(false);
                                                                }}>i</button>
                                                            </h5>
                                                            {/* <p className='integDesc'>{featDesc.length && featDesc[0]?.payment_link_desc}</p> */}
                                                        </div>
                                                    </Col>}
                                                    {featDescriptiveDesc && featDescriptiveDesc.map((item, index) => {
                                                        if (item.title !== "Developer's  API" && item.title !== "24/7 Help & Support" && item.status == 1) {
                                                            return (
                                                                <>

                                                                    <Col className='tableCellCol' style={{ height: "80px" }}>
                                                                        <div className='integDiv'>
                                                                            <h5 className='integTitle'>{item.title}
                                                                                <button id="cloudBasedP" onClick={() => {
                                                                                    if (item.description?.length > 0) {

                                                                                        setPTargetId(item.description)

                                                                                    }
                                                                                }} onBlur={() => {
                                                                                    setPopoverInfoOpen(false);
                                                                                }}>i</button>
                                                                            </h5>
                                                                        </div>
                                                                    </Col>
                                                                </>
                                                            )
                                                        }
                                                    })}


                                                    <Col className='tableCellCol' style={{ height: "80px" }}>
                                                        <div className='integDiv'>
                                                            <h5 className='integTitle'>24/7 Help & Support
                                                                <button id="cloudBasedP" onClick={() => {
                                                                    if (objData.helpSupportpara?.length > 0) {

                                                                        setPTargetId(objData.helpSupportpara)

                                                                    }
                                                                }} onBlur={() => {
                                                                    setPopoverInfoOpen(false);
                                                                }}>i</button>
                                                            </h5>
                                                            {/* <p className='integDesc'>{objData.helpSupportpara.length > 0 && objData.helpSupportpara}</p> */}
                                                        </div>
                                                    </Col>

                                                    <Col className='tableCellCol' style={{ height: "83px" }}>
                                                        <div className='integDiv'>
                                                            <h5 className='integTitle'>Developer's API
                                                                <button id="cloudBasedP" onClick={() => {
                                                                    if (objData.developerspara?.length > 0) {


                                                                        setPTargetId(objData.developerspara)

                                                                    }
                                                                }} onBlur={() => {
                                                                    setPopoverInfoOpen(false);
                                                                }}>i</button>
                                                            </h5>
                                                            {/* <p className='integDesc'>{objData.developerspara.length > 0 && objData.developerspara}</p> */}
                                                        </div>
                                                    </Col>
                                                    <Popover
                                                        placement={"right"}
                                                        isOpen={popoverInfoOpen}
                                                        target="cloudBasedP"
                                                        toggle={toggleInfoPopover}
                                                        dir={'ltr'}
                                                        hideArrow={true}
                                                        className="popoverInfoStyle"
                                                    >
                                                        <PopoverBody>
                                                            <div style={{ fontSize: "12px", color: "#fff", fontWeight: "500" }}>
                                                                {ptargetId}
                                                            </div>
                                                        </PopoverBody>
                                                    </Popover>

                                                </Col>
                                                <Col className={subData.popular ? `tableCols popularCss p-0 ${is_current_plan(subData._id) && "selectedCard"} ` : `tableCols ${is_current_plan(subData._id) && "selectedCard"}`} style={{ padding: "0px", maxWidth: "215px", minWidth: "215px" }}>
                                                    <Col className={subData.popular ? 'tableCellCol p-0 border-top-0 border-right-0 border-left-0' : "tableCellCol"} style={subData.is_trial ? { height: "375px" } : subData.popular ? { height: "415px" } : subData.description.length < 65 ? { height: "330px" } : { height: "380px" }}>
                                                        {subData.popular && <> <div className='popularName'>Popular</div>
                                                            <div className='triangleDiv'>
                                                                <Icon path={mdiMenuDown} color="#d23f99" size={3} style={{ margin: "-24px" }} />
                                                            </div>
                                                        </>}
                                                        {
                                                            subData.is_trial ? <div style={{ background: "#99d9ea", fontSize: '1.2rem', fontWeight: 500 }} className='mt-3 p-2 text-center'>
                                                                Card Not Required*
                                                            </div> : null
                                                        }
                                                        <div className='priceDivsCol' style={subData.popular ? { padding: "0px 15px" } : {}} >
                                                            <h6 className={`priceTitle`}>{subData.title}</h6>
                                                            {check_promo_status(subData) && (
                                                                <div
                                                                    className="text-center timerDiv"
                                                                >
                                                                    <h5 className='offerExp' style={{ textAlign: "center" }}> Offer expires in </h5>
                                                                    {/* <MyTimer expiryTimestamp={date2} /> */}
                                                                </div>
                                                            )}
                                                            <p className={`priceDesc`}>{subData.description}</p>
                                                            <h5 className={`monthtit`} style={{ whiteSpace: 'pre' }}><span className={`monthFees`}>$ {yearly ? calcPrice(subFees).toFixed(2) : subFees} </span>{subData.promo_rate && !subData.is_trial && <s className='strikedd'>{calcPrice(subData.fees).toFixed(2)}</s>} mo</h5>
                                                            <p className={`audDet`}>per store (monthly)</p>
                                                            <h5 className={`h5Billed`}>{yearly ? "BILLED YEARLY" : "BILLED MONTHLY"}</h5>
                                                            <div className='butonDiv newDiv'>
                                                                <Button size='md' style={is_current_plan(subData._id) ? { color: "#fff", backgroundColor: "darkgray" } : { color: "#fff", backgroundColor: "#d23f99" }} onClick={() => {
                                                                    if (merchant_id) {
                                                                        if (!is_current_plan(subData._id)) {
                                                                            localStorage.setItem('yearly', JSON.stringify(yearly));
                                                                            if (currentSub_id) {

                                                                                // if (ind < selectedIndex) {
                                                                                if (subData.fees < currentPlan.fees) {
                                                                                    // setDefaultLoading(true);
                                                                                    setSelectedPlan(subData);
                                                                                    // setTimeout(() => {
                                                                                    //     setDefaultLoading(false);
                                                                                    toggleDowngradeModal();
                                                                                    // }, 5000)

                                                                                } else {
                                                                                    // setSelected(subData._id);
                                                                                    setSelectedPlan(subData);
                                                                                    toggleUpgradeModal();
                                                                                }
                                                                                setDefaultLoading(false);
                                                                            } else {
                                                                                // setDefaultLoading(true);
                                                                                setSelected(subData._id);
                                                                                // setDefaultLoading(false);
                                                                            }
                                                                        }
                                                                    } else {
                                                                        history.push("/login")
                                                                    }
                                                                    sessionStorage.removeItem('Ipaybranding')
                                                                    // history.push(`/subscription-plans-checkout/${merchant_id}/${subData._id}`)

                                                                }} >{is_current_plan(subData._id) ? "Current Plan" : "Get started"}</Button>

                                                            </div>
                                                        </div>
                                                    </Col>
                                                    {/* <Col className='tableCellCol' style={{ height: "80px" }}><div className='percDiv'>1.9%*</div></Col> */}
                                                    {catFea[0]?.pos == 1 && merchFeatData?.pos == 1 && <Col className='tableCellCol' style={{ height: "80px" }}><div className={`percDiv`}>{subData.unlimited_sales ? <p className={`textLine`}>Unlimited sales</p> : subData.sales_per_day == 0 || !subData.sales_per_day ? <></> : <p className={`textLine`}>{subData.sales_per_day} {subData.sales_per_day > 1 ? "sales" : "sale"}</p>}</div></Col>}
                                                    {catFea[0]?.registers == 1 && merchFeatData?.registers == 1 && <Col className='tableCellCol' style={{ height: "80px" }}><div className={`percDiv`}>{subData.unlimited_registration ? <p className={`textLine`}>Unlimited Devices</p> : subData.number_of_registers == 0 || !subData.number_of_registers ? <></> : <p className={`textLine`}>{subData.number_of_registers} {subData.number_of_registers > 1 ? "devices" : "device"}</p>}</div></Col>}
                                                    <Col className='tableCellCol' style={{ height: "80px" }}><div className={`percDiv`}>{subData.unlimited_users ? <p className={`textLine`}>Unlimited Users</p> : subData.number_of_users == 0 || !subData.number_of_users ? <></> : <p className={`textLine`}>{subData.number_of_users} {subData.number_of_users > 1 ? "users" : "user"}</p>}</div></Col>
                                                    {catFea[0]?.visitor_registry == 1 && merchFeatData?.visitor_registry == 1 && <Col className='tableCellCol' style={{ height: "80px" }}><div className={`percDiv`}>{subData.unlimited_vregistries ? <p className={`textLine`}>Unlimited visitors per day</p> : subData.number_of_vregistries == 0 || !subData.number_of_vregistries ? <></> : <p className={`textLine`}>{subData.number_of_vregistries} {subData.number_of_vregistries > 1 ? "visitors per day" : "visitor per day"}</p>}</div></Col>}
                                                    <Col className='tableCellCol' style={{ height: "80px" }}><div className={`percDiv`}>{subData.number_of_linkyScan == 0 || !subData.number_of_linkyScan ? <></> : <p className={`textLine`}>{subData.number_of_linkyScan} {subData.number_of_linkyScan > 1 ? "devices per day" : "device per day"}</p>}</div></Col>

                                                    {/* <Col className='tableCellCol' style={{ height: "80px" }}>

                                    {temp.visitor && <div className='percDiv'>
                                        <Icon path={mdiCheckBold} color="#4db055" size={2} />
                                    </div>}
                                </Col> */}
                                                    {catFea[0]?.online_ordering_system == 1 && merchFeatData?.online_ordering_system == 1 && <Col className='tableCellCol' style={{ height: "80px" }}>{subData.online_orders && <div className='percDiv'>
                                                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.7} />
                                                    </div>}</Col>}
                                                    {catFea[0]?.table_reservation == 1 && merchFeatData?.table_reservation == 1 && <Col className='tableCellCol' style={{ height: "80px" }}>{subData.table_reservation && <div className='percDiv'>
                                                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.7} />
                                                    </div>}</Col>}
                                                    {catFea[0]?.offers_and_vouchers == 1 && merchFeatData?.offers_and_vouchers == 1 && <Col className='tableCellCol' style={{ height: "80px" }}>{subData.offers_vouchers && <div className='percDiv'>
                                                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.7} />
                                                    </div>}</Col>}
                                                    {catFea[0]?.loyalty_cards == 1 && merchFeatData?.loyalty_cards == 1 && <Col className='tableCellCol' style={{ height: "80px" }}>{subData.loyalty_cards && <div className='percDiv'>
                                                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.7} />
                                                    </div>}</Col>}
                                                    {catFea[0]?.payment_links == 1 && merchFeatData?.payment_links == 1 && <Col className='tableCellCol' style={{ height: "80px" }}>{subData.payment_links && <div className='percDiv'>
                                                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.7} />
                                                    </div>}</Col>}
                                                    {<Col className='tableCellCol' style={{ height: "80px" }}>{subData.price_list && <div className='percDiv'>
                                                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.7} />
                                                    </div>}</Col>}
                                                    {featDescriptiveDesc && featDescriptiveDesc.map((item, index) => {
                                                        if (item.title !== "Developer's  API" && item.title !== "24/7 Help & Support" && item.status == 1) {
                                                            return (
                                                                <>
                                                                    <Col className='tableCellCol' style={{ height: "80px" }}>
                                                                        <div className='percDiv'>
                                                                            <Icon path={mdiCheckBold} color={`#4db055`} size={1.7} />
                                                                        </div>
                                                                    </Col>
                                                                </>
                                                            )
                                                        }


                                                    })}
                                                    {/* <Col className='tableCellCol' style={{ height: "80px" }}>
                                    <div className='percDiv'>
                                    <Icon path={mdiCheckBold} color={`#4db055`} size={2} />
                                </div>
                                </Col> */}

                                                    <Col className='tableCellCol' style={{ height: "80px" }}>
                                                        {temp.help && <div className='percDiv'>
                                                            <Icon path={mdiCheckBold} color={`#4db055`} size={1.7} />
                                                        </div>}
                                                    </Col>

                                                    <Col className='tableCellCol' style={{ height: "80px", borderBottom: "none" }}>
                                                        {temp.developers && <div className='percDiv'>
                                                            Talk to us
                                                        </div>}

                                                    </Col>
                                                    {/* <Col className='tableCellCol' style={{ height: "80px", borderBottom: "none" }}>
                                    <div className='butonDiv'>
                                        <Button size='md' disabled={is_current_plan(subData._id)} color={is_current_plan(subData._id) ? "secondary" : "success"} style={{ color: "white" }} onClick={() => {
                                            // history.push(`/subscription-plans-checkout/${merchant_id}/${subData._id}`)
                                            localStorage.setItem('yearly', JSON.stringify(yearly));
                                            if (currentSub_id) {

                                                if (ind < selectedIndex) {
                                                    // setDefaultLoading(true);
                                                    setSelectedPlan(subData);
                                                    // setTimeout(() => {
                                                    //     setDefaultLoading(false);
                                                    toggleDowngradeModal();
                                                    // }, 5000)

                                                } else {
                                                    // setSelected(subData._id);
                                                    setSelectedPlan(subData);
                                                    toggleUpgradeModal();
                                                }
                                                setDefaultLoading(false);
                                            } else {
                                                // setDefaultLoading(true);
                                                setSelected(subData._id);
                                                // setDefaultLoading(false);
                                            }
                                        }} >{is_current_plan(subData._id) ? "Current Plan" : "Get started"}</Button>

                                    </div>
                                </Col> */}

                                                    {/* <Popover
                                                    placement={"right"}
                                                    isOpen={popoverInfoOpen}
                                                    target={"cloudBasedP" + ind}
                                                    toggle={toggleInfoPopover}
                                                    dir={'ltr'}
                                                    hideArrow={true}
                                                    className="popoverInfoStyle"

                                                >
                                                    <PopoverBody>
                                                        <div style={{ fontSize: "12px", color: "#fff", fontWeight: "500" }}>
                                                            {ptargetId}
                                                        </div>
                                                    </PopoverBody>
                                                </Popover> */}


                                                </Col>
                                            </Row>
                                        )
                                    })
                                }
                            </div>
                            <Container>

                                
                                {subsCategory.length > 0 && <div className='priceDivMain justify-content-start' style={promoAvail ? { marginBottom: "8rem", marginInline: '-15px' } : { marginBottom: "30px", marginInline: '-15px' }}>
                                    <div
                                        className="priceOuter"
                                        style={{ margin: '0' }}>
                                        <div className="priceSecondDiv mx-auto" style={{ background: "#fff", width: 'auto', maxWidth: '90vw' }}>
                                            {
                                                subsCategory.map((element) => {
                                                    return (
                                                        <>
                                                            <span className={selectedData?.cat_Id == element.cat_Id ? "active yearDiv text-capitalize" : "yearDiv text-capitalize"}
                                                                onClick={() => {
                                                                    handleSubscriptionCateChange(element);
                                                                }}>{element.subCatTitle}</span>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>}
                                <div className="mob_tab_container">

                                    <Row className='mob_tabTitle_container m-0'>
                                        <Col className={monthly ? 'mob_tab_title tab_active' : 'mob_tab_title'} onClick={() => {
                                            setMonthly(true)
                                            setYearly(false)
                                        }}>Monthly</Col>
                                        <Col className={!monthly ? 'mob_tab_title tab_active' : 'mob_tab_title'} onClick={() => {
                                            setMonthly(false)
                                            setYearly(true)
                                        }}>Annual (25% OFF)</Col>
                                    </Row>

                                    <div className='mt-4'>
                                        {
                                            filterdData.map((subData, ind) => {
                                                let temp = {
                                                    visitor: false,
                                                    custom: false,
                                                    help: false,
                                                    developers: false,
                                                }
                                                if (subData.descriptive_features?.length > 0) {
                                                    subData.descriptive_features.map((textt) => {

                                                        if (textt.toLowerCase().includes("visitor")) {
                                                            temp.visitor = true
                                                        }
                                                        if (textt.toLowerCase().includes("custom")) {
                                                            temp.custom = true
                                                        }
                                                        if (textt.toLowerCase().includes("help")) {
                                                            temp.help = true
                                                        }
                                                        if (textt.toLowerCase().includes("developer's")) {
                                                            temp.developers = true
                                                        }

                                                    })
                                                }

                                                let date2 = new Date()
                                                if (subData.promo_end_date) {
                                                    const isoStr1 = subData.promo_end_date;
                                                    date2 = new Date(isoStr1.slice(0, -1));
                                                }

                                                let subFees = subData.fees;
                                                if (subData.promo_rate) {
                                                    subFees = subData.promo_rate
                                                }

                                                {/* console.log("::::DATA::::", subData, subData.title, catFea) */ }
                                                return (
                                                    <Row className='mx-0'>
                                                        {/* <Col className={`card_container ${subData.popular ? 'popular_container' : ""} ${is_current_plan(subData._id) ? "selected_container" : ""}`}> */}
                                                        <Col className={`card_container ${subData.popular ? 'popular_container' : ""} ${checkedPlan?._id === subData._id ? "selected_container" : ""}`}>
                                                            {subData.popular && <div className='popular_item'>Most Popular</div>}
                                                            <div className="plan_card_header">
                                                                <div class="w-100">
                                                                    <div className="plan_title">
                                                                        <h3>
                                                                            {subData.title}
                                                                        </h3>
                                                                        {
                                                                            subData.is_trial ? <div style={{ color: "#04a9f4", fontSize: '1.1rem', fontWeight: 500 }} className='mx-2'>
                                                                                (Card Not Required*)
                                                                            </div> : null
                                                                        }
                                                                    </div>
                                                                    <div className="plan_price">{merchantDetails?.country?.currency_symbol}{!monthly ? calcPrice(subFees).toFixed(2) : subFees.toFixed(2)} {subData.promo_rate && !subData.is_trial && <span className='strikedd'>{calcPrice(subData.fees).toFixed(2)}</span>}</div>
                                                                </div>
                                                                {/* <div>
                                                                    <input type="checkbox"
                                                                        name={subData.title} id={subData._id}
                                                                        onChange={() => handleCheckboxToggle(subData, ind)}
                                                                        checked={checkedPlan && checkedPlan._id === subData._id} />
                                                                </div> */}
                                                            </div>
                                                            <div className="plan_card_body">
                                                                {subData.description}
                                                            </div>
                                                            <div className='d-flex align-items-center my-3'>
                                                                <Button
                                                                    className='mb-0'
                                                                    size='md' style={is_current_plan(subData._id) ? { color: "#fff", backgroundColor: "darkgray" } : { color: "#fff", backgroundColor: "#d23f99" }}
                                                                    onClick={() => {
                                                                        if (merchant_id) {
                                                                            if (!is_current_plan(subData._id)) {
                                                                                localStorage.setItem('yearly', JSON.stringify(yearly));
                                                                                if (currentSub_id) {
                                                                                    // if (ind < selectedIndex) {
                                                                                    if (subData.fees < currentPlan.fees) {
                                                                                        if (!suspend) {
                                                                                            setSelectedPlan(subData);
                                                                                            toggleDowngradeModal();
                                                                                        } else {
                                                                                            setSelected(subData._id);
                                                                                        }
                                                                                    } else {

                                                                                        if (!suspend) {
                                                                                            setSelectedPlan(subData);
                                                                                            toggleUpgradeModal();
                                                                                        } else {
                                                                                            setSelected(subData._id);
                                                                                        }
                                                                                    }
                                                                                    setDefaultLoading(false);
                                                                                } else {
                                                                                    setSelected(subData._id);
                                                                                }
                                                                            }
                                                                        } else {
                                                                            history.push("/login")
                                                                        }
                                                                        sessionStorage.removeItem('Ipaybranding')
                                                                    }} >{is_current_plan(subData._id) ? "Current Plan" : "Get started"}</Button>
                                                            </div>
                                                            <div className="plan_card_footer">
                                                                <div className='d-flex align-items-center' onClick={() => handleCollapseToggle(ind)}><p>Included Features:</p> {collapseStates[ind] ? <Icon path={mdiChevronUp} color="#d23f99" size={2} className='ml-auto' /> : <Icon path={mdiChevronDown} color="#d23f99" size={2} className='ml-auto' />}</div>
                                                                <Collapse isOpen={collapseStates[ind]}>
                                                                    <Card className='p-0'>
                                                                        <CardBody className='pt-0 bg-transparent'>
                                                                            {/* <ul className='mob_feature_container'>
                                                                                {catFea[0]?.pos == 1 && merchFeatData?.pos == 1 &&
                                                                                    <li>Cloud-based POS ({subData.unlimited_sales ?
                                                                                        <span>Unlimited sales</span> :
                                                                                        subData.sales_per_day == 0 || !subData.sales_per_day ?
                                                                                            <></> :
                                                                                            <span>{subData.sales_per_day} {subData.sales_per_day > 1 ? "sales" : "sale"}</span>})
                                                                                    </li>
                                                                                }
                                                                                {catFea[0]?.registers == 1 && merchFeatData?.registers == 1 &&
                                                                                    <li>
                                                                                        Register ({subData.unlimited_registration ?
                                                                                            <span>Unlimited Devices</span> :
                                                                                            subData.number_of_registers == 0 || !subData.number_of_registers ?
                                                                                                <></> :
                                                                                                <span>{subData.number_of_registers} {subData.number_of_registers > 1 ? "devices" : "device"}</span>})</li>
                                                                                }
                                                                                <li>
                                                                                    Users & Roles ({subData.unlimited_users ?
                                                                                        <span>Unlimited Users</span> :
                                                                                        subData.number_of_users == 0 || !subData.number_of_users ?
                                                                                            <></> :
                                                                                            <span>{subData.number_of_users} {subData.number_of_users > 1 ? "users" : "user"}</span>})
                                                                                </li>
                                                                                {catFea[0]?.visitor_registry == 1 && merchFeatData?.visitor_registry == 1 &&
                                                                                    <li>
                                                                                        Visitor Registry ({subData.unlimited_vregistries ?
                                                                                            <span>Unlimited visitors per day</span> :
                                                                                            subData.number_of_vregistries == 0 || !subData.number_of_vregistries ?
                                                                                                <></> :
                                                                                                <span>{subData.number_of_vregistries} {subData.number_of_vregistries > 1 ? "visitors per day" : "visitor per day"}</span>})
                                                                                    </li>
                                                                                }
                                                                                {catFea[0]?.online_ordering_system == 1 && merchFeatData?.online_ordering_system == 1 && subData.online_orders && <li>Online & QR Ordering</li>}
                                                                                {catFea[0]?.table_reservation == 1 && merchFeatData?.table_reservation == 1 && subData.table_reservation && <li>Booking Management</li>}
                                                                                {catFea[0]?.offers_and_vouchers == 1 && merchFeatData?.offers_and_vouchers == 1 && subData.offers_vouchers && <li>Offers & Vochers</li>}
                                                                                {catFea[0]?.loyalty_cards == 1 && merchFeatData?.loyalty_cards == 1 && subData.loyalty_cards && <li>Loyalty Cards</li>}
                                                                                {catFea[0]?.payment_links == 1 && merchFeatData?.payment_links == 1 && subData.payment_links && <li>Payment Links</li>}
                                                                                {subData.price_list && <li>Price List</li>}
                                                                                {catFea[0]?.developers == 1
                                                                                    && merchFeatData?.developers == 1
                                                                                    && featDescriptiveDesc
                                                                                    && featDescriptiveDesc.find(fetVal => fetVal.title == "Developer's  API")
                                                                                    && featDescriptiveDesc.find(fetVal => fetVal.title == "Developer's  API").status == 1
                                                                                    && temp.developers && <li>Developer's API</li>}
                                                                                {featDescriptiveDesc && featDescriptiveDesc.map((item, index) => {
                                                                                    if (item.title !== "Developer's  API" && item.title !== "24/7 Help & Support" && item.status == 1) {
                                                                                        return (
                                                                                            <>
                                                                                                {(subData.descriptive_features.includes(item.title)) && <li>Custom Reports</li>}
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                })}
                                                                                {<li>Digital Business Card</li>}
                                                                                {temp.help && <li>24/7 Help & Support</li>}
                                                                            </ul> */}
                                                                            <h3 className='feature_title'>
                                                                                {featureTitles && featureTitles.minisite.custom_title}
                                                                            </h3>
                                                                            <ul className="mob_feature_container">
                                                                                {featDescriptiveDesc &&
                                                                                    featDescriptiveDesc.map((feature) => {
                                                                                        if (feature.title_id == 'minisite') {
                                                                                            return (
                                                                                                <>
                                                                                                    {checkFeatureValue(feature, subData)}
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                    })}
                                                                            </ul>
                                                                            <h3 className='feature_title'>
                                                                                {featureTitles && featureTitles.payments.custom_title}
                                                                            </h3>
                                                                            <ul className="mob_feature_container">
                                                                                {featDescriptiveDesc &&
                                                                                    featDescriptiveDesc.map((feature) => {
                                                                                        if (feature.title_id == 'payments') {
                                                                                            {/* <li>
                                                                                                </li> */}
                                                                                            {/* {feature.title} */ }
                                                                                            return (
                                                                                                <>{checkFeatureValue(feature, subData)}</>
                                                                                            )
                                                                                        }
                                                                                    })}
                                                                            </ul>
                                                                            <h3 className='feature_title'>
                                                                                {featureTitles && featureTitles.event_management.custom_title}
                                                                            </h3>
                                                                            <ul className="mob_feature_container">
                                                                                {featDescriptiveDesc &&
                                                                                    featDescriptiveDesc.map((feature) => {
                                                                                        if (feature.title_id == 'event_management') {
                                                                                            return (
                                                                                                <>
                                                                                                    {checkFeatureValue(feature, subData)}
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                    })}
                                                                            </ul>
                                                                            <h3 className='feature_title'>
                                                                                {featureTitles && featureTitles.pos.custom_title}
                                                                            </h3>
                                                                            <ul className="mob_feature_container">
                                                                                {featDescriptiveDesc &&
                                                                                    featDescriptiveDesc.map((feature) => {
                                                                                        if (feature.title_id == 'pos') {
                                                                                            {/* <li>
                                                                                                </li> */}
                                                                                            return (
                                                                                                <>
                                                                                                    {checkFeatureValue(feature, subData)}
                                                                                                </>

                                                                                            )
                                                                                        }
                                                                                    })}
                                                                            </ul>
                                                                            <h3 className='feature_title'>
                                                                                {featureTitles && featureTitles.ecommerce.custom_title}
                                                                            </h3>
                                                                            <ul className="mob_feature_container">
                                                                                {featDescriptiveDesc &&
                                                                                    featDescriptiveDesc.map((feature) => {
                                                                                        if (feature.title_id == 'ecommerce') {
                                                                                            return (
                                                                                                <>
                                                                                                    {checkFeatureValue(feature, subData)}
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                    })}
                                                                            </ul>
                                                                            <h3 className='feature_title'>
                                                                                {featureTitles && featureTitles.others.custom_title}
                                                                            </h3>
                                                                            <ul className="mob_feature_container">
                                                                                {featDescriptiveDesc &&
                                                                                    featDescriptiveDesc.map((feature) => {
                                                                                        if (feature.title_id == 'others') {
                                                                                            return (
                                                                                                <>
                                                                                                    {checkFeatureValue(feature, subData)}
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                    })}
                                                                            </ul>
                                                                        </CardBody>
                                                                    </Card>
                                                                </Collapse>
                                                            </div>
                                                        </Col>
                                                        <Row className={`d-none containerTable contMob ${check_promo_status(subData) ? "marginTopMob" : "axd"}`}>
                                                            <Col className='tableCols ' style={{ padding: "0px", maxWidth: "215px", minWidth: "215px" }}>
                                                                <Col className='tableCellCol' style={subData.is_trial ? { height: "375px" } : subData.popular ? { height: "415px" } : subData.description.length < 65 ? { height: "330px" } : { height: "380px" }} >
                                                                    <div className='d-flex divCont1'>
                                                                        <h5 className='pricee'>Price</h5>
                                                                        <h5 className='priceFEa'>Features/</h5>
                                                                    </div>
                                                                </Col>
                                                                {catFea[0]?.pos == 1 && merchFeatData?.pos == 1 && <Col className='tableCellCol' style={{ height: "80px" }}>
                                                                    <div className='integDiv'>
                                                                        <h5 className='integTitle'>Cloud-based POS <button id="cloudBasedP" onClick={() => {
                                                                            if (featDesc.length) {
                                                                                if (featDesc[0]?.cloud_based_desc) {
                                                                                    setPTargetId(featDesc[0]?.cloud_based_desc)
                                                                                }
                                                                            }
                                                                        }} onBlur={() => {
                                                                            setPopoverInfoOpen(false);
                                                                        }}>i</button>  </h5>
                                                                    </div>
                                                                </Col>}
                                                                {catFea[0]?.registers == 1 && merchFeatData?.registers == 1 && <Col className='tableCellCol' style={{ height: "80px" }}>
                                                                    <div className='integDiv'>
                                                                        <h5 className='integTitle'>Register <button id="cloudBasedP" onClick={() => {
                                                                            if (featDesc.length) {
                                                                                if (featDesc[0]?.register_desc) {
                                                                                    setPTargetId(featDesc[0]?.register_desc)
                                                                                }
                                                                            }
                                                                        }} onBlur={() => {
                                                                            setPopoverInfoOpen(false);
                                                                        }}>i</button> </h5>
                                                                    </div>
                                                                </Col>}
                                                                <Col className='tableCellCol' style={{ height: "80px" }}>
                                                                    <div className='integDiv'>
                                                                        <h5 className='integTitle'>Users & Roles
                                                                            <button id="cloudBasedP" onClick={() => {
                                                                                if (featDesc.length) {
                                                                                    if (featDesc[0]?.users_roles_desc) {
                                                                                        setPTargetId(featDesc[0]?.users_roles_desc)
                                                                                    }
                                                                                }
                                                                            }} onBlur={() => {
                                                                                setPopoverInfoOpen(false);
                                                                            }}>i</button>
                                                                        </h5>
                                                                    </div>
                                                                </Col>

                                                                {catFea[0]?.visitor_registry == 1 && merchFeatData?.visitor_registry == 1 && <Col className='tableCellCol' style={{ height: "80px" }}>
                                                                    <div className='integDiv'>
                                                                        <h5 className='integTitle'>Visitor registry
                                                                            <button id="cloudBasedP" onClick={() => {
                                                                                if (featDesc.length) {
                                                                                    if (featDesc[0]?.visitor_registry_desc) {
                                                                                        setPTargetId(featDesc[0]?.visitor_registry_desc)
                                                                                    }
                                                                                }
                                                                            }} onBlur={() => {
                                                                                setPopoverInfoOpen(false);
                                                                            }}>i</button>
                                                                        </h5>
                                                                    </div>
                                                                </Col>}
                                                                <Col className='tableCellCol' style={{ height: "80px" }}>
                                                                    <div className='integDiv'>
                                                                        <h5 className='integTitle'>Linky Scan
                                                                            <button id="cloudBasedP" onClick={() => {
                                                                                if (featDesc.length) {
                                                                                    if (featDesc[0]?.linky_scan_desc) {
                                                                                        setPTargetId(featDesc[0]?.linky_scan_desc)
                                                                                    }
                                                                                }
                                                                            }} onBlur={() => {
                                                                                setPopoverInfoOpen(false);
                                                                            }}>i</button>
                                                                        </h5>
                                                                    </div>
                                                                </Col>
                                                                {catFea[0]?.online_ordering_system == 1 && merchFeatData?.online_ordering_system == 1 && <Col className='tableCellCol' style={{ height: "80px" }}>
                                                                    <div className='integDiv'>
                                                                        <h5 className='integTitle'>Online & QR Ordering
                                                                            <button id="cloudBasedP" onClick={() => {
                                                                                if (featDesc.length) {
                                                                                    if (featDesc[0]?.online_order_desc) {
                                                                                        setPTargetId(featDesc[0]?.online_order_desc)
                                                                                    }
                                                                                }
                                                                            }} onBlur={() => {
                                                                                setPopoverInfoOpen(false);
                                                                            }}>i</button>
                                                                        </h5>
                                                                    </div>
                                                                </Col>}
                                                                {catFea[0]?.table_reservation == 1 && merchFeatData?.table_reservation == 1 && <Col className='tableCellCol' style={{ height: "80px" }}>
                                                                    <div className='integDiv'>
                                                                        <h5 className='integTitle'>Booking Management
                                                                            <button id="cloudBasedP" onClick={() => {
                                                                                if (featDesc.length) {
                                                                                    if (featDesc[0]?.table_reservation_desc) {
                                                                                        setPTargetId(featDesc[0]?.table_reservation_desc)
                                                                                    }
                                                                                }
                                                                            }} onBlur={() => {
                                                                                setPopoverInfoOpen(false);
                                                                            }}>i</button>
                                                                        </h5>
                                                                    </div>
                                                                </Col>}
                                                                {catFea[0]?.offers_and_vouchers == 1 && merchFeatData?.offers_and_vouchers == 1 && <Col className='tableCellCol' style={{ height: "80px" }}>
                                                                    <div className='integDiv'>
                                                                        <h5 className='integTitle'>Offers & Vouchers
                                                                            <button id="cloudBasedP" onClick={() => {
                                                                                if (featDesc.length) {
                                                                                    if (featDesc[0]?.offers_vouchers_desc) {
                                                                                        setPTargetId(featDesc[0]?.offers_vouchers_desc)
                                                                                    }
                                                                                }
                                                                            }} onBlur={() => {
                                                                                setPopoverInfoOpen(false);
                                                                            }}>i</button>
                                                                        </h5>
                                                                    </div>
                                                                </Col>}
                                                                {catFea[0]?.loyalty_cards == 1 && merchFeatData?.loyalty_cards == 1 && <Col className='tableCellCol' style={{ height: "80px" }}>
                                                                    <div className='integDiv'>
                                                                        <h5 className='integTitle'>Loyalty Cards
                                                                            <button id="cloudBasedP" onClick={() => {
                                                                                if (featDesc.length) {
                                                                                    if (featDesc[0]?.loyalty_card_desc) {
                                                                                        setPTargetId(featDesc[0]?.loyalty_card_desc)
                                                                                    }
                                                                                }
                                                                            }} onBlur={() => {
                                                                                setPopoverInfoOpen(false);
                                                                            }}>i</button>
                                                                        </h5>
                                                                    </div>
                                                                </Col>}
                                                                {catFea[0]?.payment_links == 1 && merchFeatData?.payment_links == 1 && <Col className='tableCellCol' style={{ height: "80px" }}>
                                                                    <div className='integDiv'>
                                                                        <h5 className='integTitle'>Payment Links
                                                                            <button id="cloudBasedP" onClick={() => {
                                                                                if (featDesc.length) {
                                                                                    if (featDesc[0]?.payment_link_desc) {
                                                                                        setPTargetId(featDesc[0]?.payment_link_desc)
                                                                                    }
                                                                                }
                                                                            }} onBlur={() => {
                                                                                setPopoverInfoOpen(false);
                                                                            }}>i</button>
                                                                        </h5>
                                                                    </div>
                                                                </Col>}
                                                                {<Col className='tableCellCol' style={{ height: "80px" }}>
                                                                    <div className='integDiv'>
                                                                        <h5 className='integTitle'>Price list
                                                                            <button id="cloudBasedP" onClick={() => {
                                                                                if (featDesc.length) {
                                                                                    if (featDesc[0]?.price_list_desc) {
                                                                                        setPTargetId(featDesc[0]?.price_list_desc)
                                                                                    }
                                                                                }
                                                                            }} onBlur={() => {
                                                                                setPopoverInfoOpen(false);
                                                                            }}>i</button>
                                                                        </h5>
                                                                    </div>
                                                                </Col>}
                                                                {featDescriptiveDesc && featDescriptiveDesc.map((item, index) => {
                                                                    if (item.title !== "Developer's  API" && item.title !== "24/7 Help & Support" && item.status == 1) {
                                                                        return (
                                                                            <>

                                                                                <Col className='tableCellCol' style={{ height: "80px" }}>
                                                                                    <div className='integDiv'>
                                                                                        <h5 className='integTitle'>{item.title}
                                                                                            <button id="cloudBasedP" onClick={() => {
                                                                                                if (item.description?.length > 0) {

                                                                                                    setPTargetId(item.description)

                                                                                                }
                                                                                            }} onBlur={() => {
                                                                                                setPopoverInfoOpen(false);
                                                                                            }}>i</button>
                                                                                        </h5>
                                                                                    </div>
                                                                                </Col>
                                                                            </>
                                                                        )
                                                                    }
                                                                })}


                                                                <Col className='tableCellCol' style={{ height: "80px" }}>
                                                                    <div className='integDiv'>
                                                                        <h5 className='integTitle'>24/7 Help & Support
                                                                            <button id="cloudBasedP" onClick={() => {
                                                                                if (objData.helpSupportpara?.length > 0) {

                                                                                    setPTargetId(objData.helpSupportpara)

                                                                                }
                                                                            }} onBlur={() => {
                                                                                setPopoverInfoOpen(false);
                                                                            }}>i</button>
                                                                        </h5>
                                                                    </div>
                                                                </Col>

                                                                <Col className='tableCellCol' style={{ height: "83px" }}>
                                                                    <div className='integDiv'>
                                                                        <h5 className='integTitle'>Developer's API
                                                                            <button id="cloudBasedP" onClick={() => {
                                                                                if (objData.developerspara?.length > 0) {


                                                                                    setPTargetId(objData.developerspara)

                                                                                }
                                                                            }} onBlur={() => {
                                                                                setPopoverInfoOpen(false);
                                                                            }}>i</button>
                                                                        </h5>
                                                                    </div>
                                                                </Col>
                                                                <Popover
                                                                    placement={"right"}
                                                                    isOpen={popoverInfoOpen}
                                                                    target="cloudBasedP"
                                                                    toggle={toggleInfoPopover}
                                                                    dir={'ltr'}
                                                                    hideArrow={true}
                                                                    className="popoverInfoStyle"
                                                                >
                                                                    <PopoverBody>
                                                                        <div style={{ fontSize: "12px", color: "#fff", fontWeight: "500" }}>
                                                                            {ptargetId}
                                                                        </div>
                                                                    </PopoverBody>
                                                                </Popover>
                                                            </Col>
                                                            <Col className={subData.popular ? `tableCols popularCss p-0 ${is_current_plan(subData._id) && "selectedCard"} ` : `tableCols ${is_current_plan(subData._id) && "selectedCard"}`} style={{ padding: "0px", maxWidth: "215px", minWidth: "215px" }}>
                                                                <Col className={subData.popular ? 'tableCellCol p-0 border-top-0 border-right-0 border-left-0' : "tableCellCol"} style={subData.is_trial ? { height: "375px" } : subData.popular ? { height: "415px" } : subData.description.length < 65 ? { height: "330px" } : { height: "380px" }}>
                                                                    {subData.popular && <> <div className='popularName'>Popular</div>
                                                                        <div className='triangleDiv'>
                                                                            <Icon path={mdiMenuDown} color="#d23f99" size={3} style={{ margin: "-24px" }} />
                                                                        </div>
                                                                    </>}
                                                                    {
                                                                        subData.is_trial ? <div style={{ background: "#99d9ea", fontSize: '1.2rem', fontWeight: 500 }} className='mt-3 p-2 text-center'>
                                                                            Card Not Required*
                                                                        </div> : null
                                                                    }
                                                                    <div className='priceDivsCol' style={subData.popular ? { padding: "0px 15px" } : {}} >
                                                                        <h6 className={`priceTitle`}>{subData.title}</h6>
                                                                        {check_promo_status(subData) && (
                                                                            <div
                                                                                className="text-center timerDiv"
                                                                            >
                                                                                <h5 className='offerExp' style={{ textAlign: "center" }}> Offer expires in </h5>
                                                                                {/* <MyTimer expiryTimestamp={date2} /> */}
                                                                            </div>
                                                                        )}
                                                                        <p className={`priceDesc`}>{subData.description}</p>
                                                                        <h5 className={`monthtit`} style={{ whiteSpace: 'pre' }}><span className={`monthFees`}>$ {yearly ? calcPrice(subFees).toFixed(2) : subFees} </span>{subData.promo_rate && !subData.is_trial && <s className='strikedd'>{calcPrice(subData.fees).toFixed(2)}</s>} mo</h5>
                                                                        <p className={`audDet`}>per store (monthly)</p>
                                                                        <h5 className={`h5Billed`}>{yearly ? "BILLED YEARLY" : "BILLED MONTHLY"}</h5>
                                                                        <div className='butonDiv newDiv'>
                                                                            <Button size='md' style={is_current_plan(subData._id) ? { color: "#fff", backgroundColor: "darkgray" } : { color: "#fff", backgroundColor: "#d23f99" }} onClick={() => {
                                                                                if (merchant_id) {
                                                                                    if (!is_current_plan(subData._id)) {
                                                                                        localStorage.setItem('yearly', JSON.stringify(yearly));
                                                                                        if (currentSub_id) {
                                                                                            // if (ind < selectedIndex) {
                                                                                            if (subData.fees < currentPlan.fees) {
                                                                                                setSelectedPlan(subData);
                                                                                                toggleDowngradeModal();

                                                                                            } else {
                                                                                                setSelectedPlan(subData);
                                                                                                toggleUpgradeModal();
                                                                                            }
                                                                                            setDefaultLoading(false);
                                                                                        } else {
                                                                                            setSelected(subData._id);
                                                                                        }
                                                                                    }
                                                                                } else {
                                                                                    history.push("/login")
                                                                                }
                                                                                sessionStorage.removeItem('Ipaybranding')

                                                                            }} >{is_current_plan(subData._id) ? "Current Plan" : "Get started"}</Button>

                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                {catFea[0]?.pos == 1 && merchFeatData?.pos == 1 && <Col className='tableCellCol' style={{ height: "80px" }}><div className={`percDiv`}>{subData.unlimited_sales ? <p className={`textLine`}>Unlimited sales</p> : subData.sales_per_day == 0 || !subData.sales_per_day ? <></> : <p className={`textLine`}>{subData.sales_per_day} {subData.sales_per_day > 1 ? "sales" : "sale"}</p>}</div></Col>}
                                                                {catFea[0]?.registers == 1 && merchFeatData?.registers == 1 && <Col className='tableCellCol' style={{ height: "80px" }}><div className={`percDiv`}>{subData.unlimited_registration ? <p className={`textLine`}>Unlimited Devices</p> : subData.number_of_registers == 0 || !subData.number_of_registers ? <></> : <p className={`textLine`}>{subData.number_of_registers} {subData.number_of_registers > 1 ? "devices" : "device"}</p>}</div></Col>}
                                                                <Col className='tableCellCol' style={{ height: "80px" }}><div className={`percDiv`}>{subData.unlimited_users ? <p className={`textLine`}>Unlimited Users</p> : subData.number_of_users == 0 || !subData.number_of_users ? <></> : <p className={`textLine`}>{subData.number_of_users} {subData.number_of_users > 1 ? "users" : "user"}</p>}</div></Col>
                                                                {catFea[0]?.visitor_registry == 1 && merchFeatData?.visitor_registry == 1 && <Col className='tableCellCol' style={{ height: "80px" }}><div className={`percDiv`}>{subData.unlimited_vregistries ? <p className={`textLine`}>Unlimited visitors per day</p> : subData.number_of_vregistries == 0 || !subData.number_of_vregistries ? <></> : <p className={`textLine`}>{subData.number_of_vregistries} {subData.number_of_vregistries > 1 ? "visitors per day" : "visitor per day"}</p>}</div></Col>}
                                                                <Col className='tableCellCol' style={{ height: "80px" }}><div className={`percDiv`}>{subData.number_of_linkyScan == 0 || !subData.number_of_linkyScan ? <></> : <p className={`textLine`}>{subData.number_of_linkyScan} {subData.number_of_linkyScan > 1 ? "devices per day" : "device per day"}</p>}</div></Col>
                                                                {catFea[0]?.online_ordering_system == 1 && merchFeatData?.online_ordering_system == 1 && <Col className='tableCellCol' style={{ height: "80px" }}>{subData.online_orders && <div className='percDiv'>
                                                                    <Icon path={mdiCheckBold} color={`#4db055`} size={1.7} />
                                                                </div>}</Col>}
                                                                {catFea[0]?.table_reservation == 1 && merchFeatData?.table_reservation == 1 && <Col className='tableCellCol' style={{ height: "80px" }}>{subData.table_reservation && <div className='percDiv'>
                                                                    <Icon path={mdiCheckBold} color={`#4db055`} size={1.7} />
                                                                </div>}</Col>}
                                                                {catFea[0]?.offers_and_vouchers == 1 && merchFeatData?.offers_and_vouchers == 1 && <Col className='tableCellCol' style={{ height: "80px" }}>{subData.offers_vouchers && <div className='percDiv'>
                                                                    <Icon path={mdiCheckBold} color={`#4db055`} size={1.7} />
                                                                </div>}</Col>}
                                                                {catFea[0]?.loyalty_cards == 1 && merchFeatData?.loyalty_cards == 1 && <Col className='tableCellCol' style={{ height: "80px" }}>{subData.loyalty_cards && <div className='percDiv'>
                                                                    <Icon path={mdiCheckBold} color={`#4db055`} size={1.7} />
                                                                </div>}</Col>}
                                                                {catFea[0]?.payment_links == 1 && merchFeatData?.payment_links == 1 && <Col className='tableCellCol' style={{ height: "80px" }}>{subData.payment_links && <div className='percDiv'>
                                                                    <Icon path={mdiCheckBold} color={`#4db055`} size={1.7} />
                                                                </div>}</Col>}
                                                                {<Col className='tableCellCol' style={{ height: "80px" }}>{subData.price_list && <div className='percDiv'>
                                                                    <Icon path={mdiCheckBold} color={`#4db055`} size={1.7} />
                                                                </div>}</Col>}
                                                                {featDescriptiveDesc && featDescriptiveDesc.map((item, index) => {
                                                                    if (item.title !== "Developer's  API" && item.title !== "24/7 Help & Support" && item.status == 1) {
                                                                        return (
                                                                            <>
                                                                                <Col className='tableCellCol' style={{ height: "80px" }}>
                                                                                    <div className='percDiv'>
                                                                                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.7} />
                                                                                    </div>
                                                                                </Col>
                                                                            </>
                                                                        )
                                                                    }
                                                                })}
                                                                <Col className='tableCellCol' style={{ height: "80px" }}>
                                                                    {temp.help && <div className='percDiv'>
                                                                        <Icon path={mdiCheckBold} color={`#4db055`} size={1.7} />
                                                                    </div>}
                                                                </Col>

                                                                <Col className='tableCellCol' style={{ height: "80px", borderBottom: "none" }}>
                                                                    {temp.developers && <div className='percDiv'>
                                                                        Talk to us
                                                                    </div>}
                                                                </Col>
                                                            </Col>
                                                        </Row>
                                                    </Row>
                                                )
                                            })
                                        }

                                        {
                                            selectedData?.cat_Id == "003" &&
                                            <Row>
                                                <Col className={`card_container`}>
                                                    <div className="plan_card_header">
                                                        <div class="w-100">
                                                            <div className="plan_title">
                                                                <h3>Custom</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="plan_card_body my-2">
                                            Premium team onboarding, education and personal video, success coach
                                        </div> */}

                                                    <div className="plan_card_body my-2" style={{ color: "#000" }}>
                                                        Custom solution for teams of 5 or more users
                                                    </div>

                                                    <div className='mt-2 flex items-center'>
                                                        <Button
                                                            size='md'
                                                            style={{ color: "#fff", backgroundColor: "#d23f99" }}
                                                            // onClick={() => { router.push('/contact-us') }}
                                                        >
                                                            Contact Us
                                                        </Button>

                                                    </div>
                                                    <div className="plan_card_footer">
                                                        <div className='d-flex align-items-center'
                                                            onClick={() => handleCollapseToggle(result.length + 1)}
                                                        >
                                                            <p>Included Features:</p>
                                                            {
                                                                collapseStates[result.length + 1] ?
                                                                    <Icon path={mdiChevronUp} color="#d23f99" size={2} className='ml-auto' />
                                                                    : <Icon path={mdiChevronDown} color="#d23f99" size={2} className='ml-auto' />
                                                            }
                                                        </div>
                                                        <Collapse isOpen={collapseStates[result.length + 1]}>
                                                            <Card className='p-0'
                                                                style={{
                                                                    boxShadow: 'none',
                                                                    border: 'none',
                                                                    background: 'transparent'
                                                                }}>
                                                                <CardBody className='pt-0 bg-transparent'>
                                                                    {/* <ul className='mob_feature_container'>
                                                            <li>Cloud-based POS ({<span>Unlimited sales</span>})</li>
                                                            <li>Register ({<span>Unlimited Devices</span>})</li>
                                                            <li>Users & Roles ({<span>Unlimited Users</span>})</li>
                                                            <li> Visitor Registry ({<span>Unlimited visitors per day</span>})</li>
                                                            <li>Online & QR Ordering</li>
                                                            <li>Booking Management</li>
                                                            <li>Offers & Vochers</li>
                                                            <li>Loyalty Cards</li>
                                                            <li>Payment Links</li>
                                                            <li>Price List</li>
                                                            <li>Developer's API</li>
                                                            <li>Custom Reports</li>
                                                            <li>Digital Business Card</li>
                                                            <li>24/7 Help & Support</li>
                                                        </ul> */}
                                                                    <h3 className='feature_title'>
                                                                        {featureTitles && featureTitles.minisite.custom_title}
                                                                    </h3>
                                                                    <ul className="mob_feature_container">
                                                                        {featDescriptiveDesc &&
                                                                            featDescriptiveDesc.map((feature) => {
                                                                                if (feature.title_id == 'minisite') {
                                                                                    return (
                                                                                        <>
                                                                                            {checkCustomFeatureValue(feature)}
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            })}
                                                                    </ul>
                                                                    <h3 className='feature_title'>
                                                                        {featureTitles && featureTitles.payments.custom_title}
                                                                    </h3>
                                                                    <ul className="mob_feature_container">
                                                                        {featDescriptiveDesc &&
                                                                            featDescriptiveDesc.map((feature) => {
                                                                                if (feature.title_id == 'payments') {
                                                                                    {/* <li>
                                                                                                </li> */}
                                                                                    {/* {feature.title} */ }
                                                                                    return (
                                                                                        <>{checkCustomFeatureValue(feature)}</>
                                                                                    )
                                                                                }
                                                                            })}
                                                                    </ul>
                                                                    <h3 className='feature_title'>
                                                                        {featureTitles && featureTitles.event_management.custom_title}
                                                                    </h3>
                                                                    <ul className="mob_feature_container">
                                                                        {featDescriptiveDesc &&
                                                                            featDescriptiveDesc.map((feature) => {
                                                                                if (feature.title_id == 'event_management') {
                                                                                    return (
                                                                                        <>
                                                                                            {checkCustomFeatureValue(feature)}
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            })}
                                                                    </ul>
                                                                    <h3 className='feature_title'>
                                                                        {featureTitles && featureTitles.pos.custom_title}
                                                                    </h3>
                                                                    <ul className="mob_feature_container">
                                                                        {featDescriptiveDesc &&
                                                                            featDescriptiveDesc.map((feature) => {
                                                                                if (feature.title_id == 'pos') {
                                                                                    {/* <li>
                                                                                                </li> */}
                                                                                    return (
                                                                                        <>
                                                                                            {checkCustomFeatureValue(feature)}
                                                                                        </>

                                                                                    )
                                                                                }
                                                                            })}
                                                                    </ul>
                                                                    <h3 className='feature_title'>
                                                                        {featureTitles && featureTitles.ecommerce.custom_title}
                                                                    </h3>
                                                                    <ul className="mob_feature_container">
                                                                        {featDescriptiveDesc &&
                                                                            featDescriptiveDesc.map((feature) => {
                                                                                if (feature.title_id == 'ecommerce') {
                                                                                    return (
                                                                                        <>
                                                                                            {checkCustomFeatureValue(feature)}
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            })}
                                                                    </ul>
                                                                    <h3 className='feature_title'>
                                                                        {featureTitles && featureTitles.others.custom_title}
                                                                    </h3>
                                                                    <ul className="mob_feature_container">
                                                                        {featDescriptiveDesc &&
                                                                            featDescriptiveDesc.map((feature) => {
                                                                                if (feature.title_id == 'others') {
                                                                                    return (
                                                                                        <>
                                                                                            {checkCustomFeatureValue(feature)}
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            })}
                                                                    </ul>
                                                                </CardBody>
                                                            </Card>
                                                        </Collapse>
                                                    </div>
                                                </Col>
                                            </Row>
                                        }
                                    </div>
                                </div>

                            </Container>
                            {/* <div style={{ width: '100%', position: 'sticky', bottom: '0' }}>
                                <div className="proceed_button_container">
                                    <Button size='md' className='m-0 btn block w-100'
                                        style={{ fontSize: '16px', color: '#fff', background: "#d23f99", height: '55px' }}
                                        disabled={is_current_plan(checkedPlan?._id)}
                                        onClick={handleProceed}
                                    >Proceed</Button>
                                </div>
                            </div> */}
                            {/* {downgrade && <PlanDowngradeModal selected={selectedPlan} current={currentPlan} toggle={() => toggleDowngradeModal()} />}
                            {upgrade && <PlanUpgradeModal selected={selectedPlan} current={currentPlan} toggle={() => toggleUpgradeModal()} />} */}
                        </>
                    }



                </> :
                // <div className='loadingDiv'>
          <div className="d-flex justify-content-center mt-4" style={{ display: "flex", alignItems: "center"}}>

                    <HashLoader color="#36D7B7" />
                </div>
            }


            <Modal
                isOpen={merchantLostDataModal}
                toggle={() => setMerchantLostDataModal(!merchantLostDataModal)}
                centered
                modalClassName={`ltr-support`}
            >
                <div className="d-flex flex-column align-items-center justify-content-center p-2">
                    <Icon
                        path={mdiInformationOutline}
                        size={3}
                        color="#FF4861"
                    />
                    {/* <h5 className="mb-0 my-2 font-weight-bold"> Confirm Deletion Of </h5> */}
                </div>

                <div className="text-center p-2">
                    <p className="my-2"> Are you sure you want to go back? </p>
                    <p className="my-2"> All details you previously entered will be lost. </p>
                </div>

                <div className="text-center p-2 mb-3">
                    <Button
                        size="sm"
                        className="my-0 mx-2"
                        type="button"
                        onClick={() => setMerchantLostDataModal(!merchantLostDataModal)}
                    >
                        Cancel
                    </Button>

                    <Button
                        size="sm"
                        className="my-0 mx-2"
                        type="button"
                        color="danger"
                        onClick={() => {
                            history.goBack()
                            setMerchantLostDataModal(false)
                        }}
                    >
                        Continue
                    </Button>
                </div>
            </Modal>
        </div>
    )
}

export default SubscriptionPlanCompNew