import React from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router";
import * as actionCreators from "../store/actions/index";
import { useEffect } from "react";

const Signout = ({isAuthenticated, history}) => {

    useEffect(() => {
        if (!isAuthenticated) {
            history.replace(`${process.env.PUBLIC_URL}/login`)
        }
    },[isAuthenticated])


    let logoutRedirect = null;
    if (!isAuthenticated) {
        logoutRedirect = <Redirect path="/login"></Redirect>
    }

    return (
        <div>
            {logoutRedirect}
        </div>

    );
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token != null,
        authenticate: state.auth.isAuthenticated
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLogout: (userId, browserId, token) => dispatch(actionCreators.logout(userId, browserId, token))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Signout));
    