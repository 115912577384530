import React, { Component, Fragment } from "react";
import { toast } from "react-toastify";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import moment from "moment-timezone"

import client from "../Api/HTTPClient";
import api from "../constant/apilist";
import "../table.css"
import swal from 'sweetalert';


class CustomerDocuments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customer_id: this.props.customerId,
            customer_info: this.props.customerInfo,
            documentsDetails: null,
            access_level: localStorage.getItem("accessLevel"),
            documentChange: null,
            isOpen: false,
            imageSrc: null,
            inputKey: "input",
            verifyModal: false,
            rejectStatusReason: "",
            verifyStatus: null,
            adminUser: JSON.parse(localStorage.getItem("user"))

        }

    }

    componentDidMount() {
        const { documentsDetails } = this.state;
        this.getDocuments();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        console.log(nextProps, prevState)
        if (nextProps.customerInfo.is_verified != prevState.customer_info.is_verified) {
            return {
                customer_info: nextProps.customerInfo,

            }
        }
    }

    getDocuments() {
        const { customer_id, documentsDetails } = this.state;
        const postData = { customer_id: customer_id }

        client.post(api.get_customer_documents, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    //console.log(response);
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            documentsDetails: response.result.data,
                        }
                    })

                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        })
    }

    refreshPage() {
        //window.location.reload(false);
        this.getDocuments();
    }

    imageClicked = (src) => {
        //console.log("clicked");
        this.setState({ isOpen: true, imageSrc: src })
    }

    documentChangeHandler = async (event, doc_no) => {
        console.log(event.target.files)
        if (event.target.files.length === 0)
            return;
        //Image upload validation
        var mimeType = event.target.files[0].type;

        if (mimeType.match(/(jpeg|jpg|png|pdf)$/) == null) {
            return;
        }
        // Image upload
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (_event) => {
            let imageData;
            imageData = reader.result;
            imageData = imageData.split(",")[1];

            let image_data = { mime: mimeType, data: imageData };
            console.log(image_data);
            const postData = { customer_id: this.state.customer_id, image_data: image_data, document_no: doc_no }
            client.post(api.upload_customer_document, postData, (error, response) => {
                if (!error) {
                    console.log(response)
                    if (!response.error) {
                        toast.success(response.message);
                        this.setState({ inputKey: Math.random().toString(36) });
                        this.refreshPage();

                    } else {
                        setTimeout(() => {
                            toast.error(response.message);
                        }, 200);
                    }
                } else {
                    setTimeout(() => {
                        toast.error("Internal error occured. Please contact support");
                    }, 200);


                }
            })


        }
    }


    informationChangeHandler = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => {
            return {
                ...prevState,
                documentsDetails: {
                    ...prevState.documentsDetails,
                    [name]: value
                }
            }
        });

    }

    render_date = (add_date) => {

        if (typeof (add_date) === "undefined") {
            return;
        }

        const convert_date = new Date(add_date);
        const added_date = moment.tz(convert_date, "Australia/Sydney").format("DD/MM/YYYY HH:mm");
        return added_date;
    }

    informationSubmitHandler = (event) => {
        event.preventDefault();
        const { customer_id, documentsDetails } = this.state;
        const postData = { customer_id: customer_id, ...this.state.documentsDetails }

        client.post(api.update_customer_addInfo, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    toast.success(response.message);
                    this.refreshPage();
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        })
    }

    onVerifyStatusClick = (type) => {
        this.setState({ verifyModal: true, verifyStatus: type })
    }

    toggleVerifyModal = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                verifyModal: !prevState.verifyModal
            }
        })
    }

    onVerifydocuments = (event) => {
        event.preventDefault();
        let { customer_id, customer_info, verifyStatus, rejectStatusReason } = this.state;

        /*  if (customer_info.is_verified == 1) {
             swal("Aleady verified");
             return false;
         } else { */
        const postData = {
            customer_id: customer_id, admin_id: this.state.adminUser._id,
            verification_status: verifyStatus == "reject" ? 3 : 1, rejection_reason: rejectStatusReason, admin: this.state.adminUser.full_name
        }
        client.post(api.verify_customer, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    this.getDocuments();
                    this.setState({ verifyModal: false })
                    verifyStatus == "reject" ? swal("Rejected") : swal("Verified Successfully");
                    this.props.getCustomersWallet(this.state.customer_id)
                } else {
                    // setTimeout(() => {
                    swal(response.message);
                    // }, 200);
                }
            } else {
                // setTimeout(() => {
                swal("Internal error occured. Please contact support");
                console.log(error);

                // }, 200);
            }
        })
        /*  } */
    }

    renderVerifyModal = () => {
        const { verifyStatus } = this.state;
        const style = {
            outline: "none",
            border: "1px solid #ebecf1",
            borderRadius: "4px",
            ':focus': {
                outlineColor: "#7C4DFF"
            }
            , padding: "3px 10px",
            fontSize: "1rem",

        }
        return (
            <Modal isOpen={this.state.verifyModal} toggle={this.toggleVerifyModal} className="modal-body" centered={true}>
                <form className="theme-form" noValidate="" onSubmit={this.onVerifydocuments}>
                    <ModalHeader toggle={this.toggleVerifyModal}>{verifyStatus == "reject" ? "Reject verification" : "Verify Documents"}</ModalHeader>
                    <ModalBody>
                        {verifyStatus == "reject" ?
                            <Fragment>
                                <h6 className="mb-3">Kindly enter a note for user</h6>
                                <input style={style} type="text" size="40" value={this.state.rejectStatusReason} onChange={(event) => this.setState({ rejectStatusReason: event.target.value })}
                                    placeholder="Enter a reason for your action"
                                    required></input>
                            </Fragment> :
                            <Fragment>
                                <h6 className="mb-3">Please confirm that you have verified the following details:</h6>
                                <div>
                                    <p><input required type='checkbox' /> Verified email address</p>
                                    <p><input required type='checkbox' /> Verified authentication over the phone</p>
                                </div>
                            </Fragment>}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggleVerifyModal}>Not sure</Button>
                        <Button color="primary" type="submit"  >Yes, please!</Button>
                    </ModalFooter>
                </form>
            </Modal>

        );
    }

    /*     verifycustomer = async () => {
    
            let { customer_info } = this.state;
            if (customer_info.is_verified == 1) {
                swal("aleady verified");
                return false;
            }
            let willDelete = await swal({
                title: "Are you sure?",
                text: "Have you verify documents, additional information & bank details of user??",
                icon: "warning",
                buttons: {
                    cancel: true,
                    confirm: {
                        text: "Verify!",
                        closeModal: false,
                    },
                },
                dangerMode: true,
            })
    
    
            if (willDelete) {
                let { customer_id } = this.state;
    
                client.post(api.verify_customer, { customer_id: customer_id }, (error, response) => {
                    swal.close();
                    if (!error) {
                        if (!response.error) {
                            swal("Verified Successfully");
                            this.refreshPage();
                        } else {
                            // setTimeout(() => {
                            swal(response.message);
                            // }, 200);
                        }
                    } else {
                        // setTimeout(() => {
                        swal("Internal error occured. Please contact support");
                        // }, 200);
                    }
                })
            }
        } */

    renderAllTable = () => {
        const { documentsDetails, access_level, inputKey } = this.state;
        return (
            <Fragment>
                <div className="table-responsive">
                    <table className="table table-border-horizontal table-striped" >
                        <thead>
                            <tr >
                                <th scope="col" style={{ width: "6%" }}>#</th>
                                <th scope="col" style={{ width: "22%" }}>Primary documents</th>
                                <th scope="col" style={{ width: "12%" }}>Points</th>
                                {access_level != 1 && <th scope="col" style={{ width: "30%" }}>Upload </th>}
                                <th scope="col" style={{ width: "30%" }}>Attachments </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th style={{ width: "6%" }}>1</th>
                                <td style={{ width: "22%" }}>Birth Certificate </td>
                                <td style={{ width: "12%" }}>50</td>
                                {access_level != 1 && <td style={{ width: "30%" }}>
                                    <input type="file" name="birth_certificate" key={inputKey} onChange={(event) => this.documentChangeHandler(event, 1)} /></td>}
                                <td style={{ width: "30%" }}>{documentsDetails.birth_certificate != null ? documentsDetails.birth_certificate.includes(".pdf") ?
                                    <a href={`${client.url(`images/customer-documents/${documentsDetails.birth_certificate}`)}`}>{documentsDetails.birth_certificate}</a> :
                                    <img src={`${client.url(`images/customer-documents/${documentsDetails.birth_certificate}`)}`}
                                        onClick={() => this.imageClicked(`${client.url(`images/customer-documents/${documentsDetails.birth_certificate}`)}`)} style={{ width: "25px", height: "25px", borderRadius: "50%" }}></img>
                                    : <p>No attachments</p>} </td>
                            </tr>
                            <tr>
                                <th style={{ width: "6%" }}>2</th>
                                <td style={{ width: "22%" }}>Driving License </td>
                                <td style={{ width: "12%" }}>50</td>
                                {access_level != 1 && <td style={{ width: "30%" }}><input type="file" key={inputKey} name="driving_license" onChange={(event) => this.documentChangeHandler(event, 2)} /></td>}
                                <td style={{ width: "30%" }}>{documentsDetails.driving_license != null ? documentsDetails.driving_license.includes(".pdf") ?
                                    <a href={`${client.url(`images/customer-documents/${documentsDetails.driving_license}`)}`}>{documentsDetails.driving_license} </a> :
                                    <img src={`${client.url(`images/customer-documents/${documentsDetails.driving_license}`)}`}
                                        onClick={() => this.imageClicked(`${client.url(`images/customer-documents/${documentsDetails.driving_license}`)}`)} style={{ width: "25px", height: "25px", borderRadius: "50%" }}></img>
                                    : <p>No attachments</p>} </td>
                            </tr>
                            <tr>
                                <th style={{ width: "6%" }}>3</th>
                                <td style={{ width: "22%" }}>Passport </td>
                                <td style={{ width: "12%" }}>50</td>
                                {access_level != 1 && <td style={{ width: "30%" }}><input type="file" key={inputKey} name="passport" onChange={(event) => this.documentChangeHandler(event, 3)} /></td>}
                                <td style={{ width: "30%" }}>{documentsDetails.passport != null ? documentsDetails.passport.includes(".pdf") ?
                                    <a href={`${client.url(`images/customer-documents/${documentsDetails.passport}`)}`}>{documentsDetails.passport} </a> :
                                    <img src={`${client.url(`images/customer-documents/${documentsDetails.passport}`)}`}
                                        onClick={() => this.imageClicked(`${client.url(`images/customer-documents/${documentsDetails.passport}`)}`)} style={{ width: "25px", height: "25px", borderRadius: "50%" }}></img>
                                    : <p>No attachments!</p>} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="table-responsive">
                    <table className="table table-border-horizontal table-striped" >
                        <thead>
                            <tr >
                                <th scope="col" style={{ width: "6%" }}>#</th>
                                <th scope="col" style={{ width: "22%" }}>Secondary documents</th>
                                <th scope="col" style={{ width: "12%" }}>Points</th>
                                {access_level != 1 && <th scope="col" style={{ width: "30%" }}>Upload </th>}
                                <th scope="col" style={{ width: "30%" }}>Attachments </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row" style={{ width: "6%" }}>1</th>
                                <td style={{ width: "22%" }}>Certificate Of Australian Citizenship</td>
                                <td style={{ width: "12%" }}>40</td>
                                {access_level != 1 && <td style={{ width: "30%" }}><input type="file" key={inputKey} name="cert_of_aus_citizenship" onChange={(event) => this.documentChangeHandler(event, 4)} /></td>}
                                <td style={{ width: "30%" }}>{documentsDetails.cert_of_aus_citizenship != null ? documentsDetails.cert_of_aus_citizenship.includes(".pdf") ?
                                    <a href={`${client.url(`images/customer-documents/${documentsDetails.cert_of_aus_citizenship}`)}`}>{documentsDetails.cert_of_aus_citizenship} </a> :
                                    <img src={`${client.url(`images/customer-documents/${documentsDetails.cert_of_aus_citizenship}`)}`}
                                        onClick={() => this.imageClicked(`${client.url(`images/customer-documents/${documentsDetails.cert_of_aus_citizenship}`)}`)} style={{ width: "25px", height: "25px", borderRadius: "50%" }}></img>
                                    : <p>No attachments!</p>}</td>
                            </tr>
                            <tr>
                                <th scope="row" style={{ width: "6%" }}>2</th>
                                <td style={{ width: "22%" }}>Australian Visa </td>
                                <td style={{ width: "12%" }}>40</td>
                                {access_level != 1 && <td style={{ width: "30%" }}><input type="file" key={inputKey} name="australia_visa" onChange={(event) => this.documentChangeHandler(event, 5)} /></td>}
                                <td style={{ width: "30%" }}>{documentsDetails.australia_visa != null ? documentsDetails.australia_visa.includes(".pdf") ?
                                    <a href={`${client.url(`images/customer-documents/${documentsDetails.australia_visa}`)}`}>{documentsDetails.australia_visa}</a> :
                                    <img src={`${client.url(`images/customer-documents/${documentsDetails.australia_visa}`)}`}
                                        onClick={() => this.imageClicked(`${client.url(`images/customer-documents/${documentsDetails.australia_visa}`)}`)} style={{ width: "25px", height: "25px", borderRadius: "50%" }}></img>
                                    : <p>No attachments!</p>} </td>
                            </tr>
                            <tr>
                                <th scope="row" style={{ width: "6%" }}>3</th>
                                <td style={{ width: "22" }}>Marriage Certificate </td>
                                <td style={{ width: "12%" }}>40</td>
                                {access_level != 1 && <td style={{ width: "30%" }}><input type="file" key={inputKey} name="marriage_certificate" onChange={(event) => this.documentChangeHandler(event, 6)} /></td>}
                                <td style={{ width: "30%" }}>{documentsDetails.marriage_certificate != null ? documentsDetails.marriage_certificate.includes(".pdf") ?
                                    <a href={`${client.url(`images/customer-documents/${documentsDetails.marriage_certificate}`)}`}>{documentsDetails.marriage_certificate}</a> :
                                    <img src={`${client.url(`images/customer-documents/${documentsDetails.marriage_certificate}`)}`}
                                        onClick={() => this.imageClicked(`${client.url(`images/customer-documents/${documentsDetails.marriage_certificate}`)}`)} style={{ width: "25px", height: "25px", borderRadius: "50%" }}></img>
                                    : <p>No attachments!</p>} </td>
                            </tr>
                            <tr>
                                <th scope="row" style={{ width: "6%" }}>4</th>
                                <td style={{ width: "22%" }}>Medicare Card </td>
                                <td style={{ width: "12%" }}>40</td>
                                {access_level != 1 && <td style={{ width: "30%" }}><input type="file" key={inputKey} name="medicare_card" onChange={(event) => this.documentChangeHandler(event, 7)} /></td>}
                                <td style={{ width: "30%" }}>{documentsDetails.medicare_card != null ? documentsDetails.medicare_card.includes(".pdf") ?
                                    <a href={`${client.url(`images/customer-documents/${documentsDetails.medicare_card}`)}`}>{documentsDetails.medicare_card}</a> :
                                    <img src={`${client.url(`images/customer-documents/${documentsDetails.medicare_card}`)}`}
                                        onClick={() => this.imageClicked(`${client.url(`images/customer-documents/${documentsDetails.medicare_card}`)}`)} style={{ width: "25px", height: "25px", borderRadius: "50%" }}></img>
                                    : <p>No attachments!</p>}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="table-responsive">
                    <table className="table table-border-horizontal table-striped">
                        <thead>
                            <tr >
                                <th scope="col" style={{ width: "6%" }}>#</th>
                                <th scope="col" style={{ width: "22%" }}>Total Points</th>
                                <th scope="col" >{documentsDetails.total_points}</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </Fragment>
        );
    }



    render() {
        const { documentsDetails, customer_id, customer_info, access_level } = this.state;
        console.log(documentsDetails);
        /* console.log(customer_id);
        console.log(customer_info)  */


        return (
            <div className="container-fluid">
                {documentsDetails ?

                    <div className="row">
                        {this.renderVerifyModal()}
                        <div className="col-sm-12 col-xl-12">

                            <div className="ribbon-wrapper card">
                                <div className="card-body" style={{ padding: "0.9rem" }}>
                                    <div className="ribbon ribbon-bookmark ribbon-primary">Upload Documents</div>
                                    <h5 style={{ margin: "10px 0 20px 0" }}>Kindly attach the following documents to Proceed :</h5>
                                    {this.renderAllTable()}
                                </div>
                                {/* <div className="card-footer" style={{ paddingLeft: "12px" }}>
                                    <button className="btn btn-primary btn-xs pull-right" style={{ margin: "0 15px 0 0" }} disabled={access_level == 1 ? true : false} onClick={this.documentSubmitHandler} type="submit">Save</button>
                                </div> */}
                            </div>

                        </div>
                        <div className="col-sm-12 col-xl-12">
                            <form className="ribbon-wrapper card" onSubmit={this.informationSubmitHandler}>

                                <div className="card-body" style={{ paddingBottom: "15px" }}>
                                    <div className="ribbon ribbon-bookmark ribbon-primary">Additional Information</div>
                                    <div className="row">

                                        <div className="col-lg-12">
                                            <label className="col-lg-8">Your full name,date of birth, and permanent residential address, including country(not a PO box)</label>
                                            <input className="form-control col-lg-5" type="text" required placeholder="Name" style={{ display: "inline", margin: "10px 15px 10px 0" }} name="full_name"
                                                onChange={this.informationChangeHandler} value={documentsDetails.full_name} readOnly={access_level == 1 ? true : false} />
                                            <input className="form-control col-lg-5" type="text" required placeholder="Date of Birth" style={{ display: "inline", margin: "10px 15px 10px 0" }} name="dob"
                                                onChange={this.informationChangeHandler} value={documentsDetails.dob} readOnly={access_level == 1 ? true : false} />
                                            {/* <input className="form-control col-lg-5" type="text" required placeholder="Position" style={{ display: "inline", margin: "10px 15px 10px 0" }} name="position"
                                                onChange={this.informationChangeHandler} value={documentsDetails.position} readOnly={access_level == 1 ? true : false} /> */}
                                            <input className="form-control col-lg-8" type="text" required placeholder="Permanent Address" style={{ display: "inline", margin: "10px 15px 10px 0" }} name="permanent_adsress"
                                                onChange={this.informationChangeHandler} value={documentsDetails.permanent_adsress} readOnly={access_level == 1 ? true : false} />

                                        </div>
                                        <div className="col-lg-12" style={{ marginTop: "15px" }}>
                                            <h5>By providing my digital signature, I do understand the following :</h5>
                                            <p><input disabled={access_level == 1 ? true : false} required defaultChecked type="checkbox" />  Giving partial or misleading information could cause delays in my application to be processed and my funds
                                                to be released to my nominated bank account.
                                            </p>
                                            <p> <input disabled={access_level == 1 ? true : false} defaultChecked required type="checkbox" />  I am bound by the terms of use as outlined in terms and conditions of i-Pay and all of its associated APPs and websites.

                                            </p>
                                            <p> <input disabled={access_level == 1 ? true : false} defaultChecked required type="checkbox" />  I do solemnly declares all the information in this application is true and correct to the best of my knowledge
                                                and I am the authorised person to provide a digital signature for this application.
                                            </p>

                                        </div>

                                        <div className="card-footer col-lg-12" style={{ paddingLeft: "12px", display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                            <div className="pull-right">
                                                {customer_info.is_verified == 2 || customer_info.is_verified == 1 ?
                                                    <button onClick={() => this.onVerifyStatusClick("reject")} className="btn btn-danger btn-xs" style={{ margin: "0 15px 0 0" }}
                                                        type="button" disabled={access_level == 1 ? true : false}>Reject</button>
                                                    :
                                                    null
                                                }
                                                {customer_info.is_verified == 2 || customer_info.is_verified == 3 ?
                                                    <button onClick={() => this.onVerifyStatusClick("accept")} className="btn btn-secondary btn-xs" style={{ margin: "0 15px 0 0" }}
                                                        type="button" disabled={access_level == 1 ? true : false}>Verify</button>
                                                    :
                                                    null
                                                }
                                                <button className="btn btn-primary btn-xs" style={{ margin: "0 15px 0 0" }} type="submit" disabled={access_level == 1 ? true : false}>Save</button>

                                            </div>
                                            <div className="pull-right">
                                                {customer_info.is_verified == 1 && <p className="mt-2">Verification approved by {typeof customer_info.is_verified_data == "undefined" ? "David M" : customer_info.is_verified_data.verified_by_id.full_name}
                                                    <br /> {typeof customer_info.is_verified_data == "undefined" ? null : `on ${this.render_date(customer_info.is_verified_data.date)}`}</p>}
                                                {customer_info.is_verified == 3 && <p className="mt-2">Verification rejected by {typeof customer_info.is_verified_data == "undefined" ? "David M" : customer_info.is_verified_data.verified_by_id.full_name}
                                                    <br /> {typeof customer_info.is_verified_data == "undefined" ? null : `on ${this.render_date(customer_info.is_verified_data.date)}`}</p>}
                                                {customer_info.is_verified == 2 && <p className="mt-2">Documents verification under review</p>}
                                                {customer_info.is_verified == 0 && <p className="mt-2">Documents not uploaded</p>}
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </form>
                            {this.state.isOpen && (
                                <Lightbox
                                    mainSrc={this.state.imageSrc}
                                    onCloseRequest={() => this.setState({ isOpen: false })}
                                />
                            )}
                        </div>
                    </div> : null}
            </div>


        );
    }
}

export default CustomerDocuments;