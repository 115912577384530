import React, { Fragment, useState, useEffect } from "react";
import { } from "reactstrap";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { toast } from "react-toastify"
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import FeaturePage from "./features/Features";
import HideAndUnhideFeature from "./features/HideAndUnhideFeature";

const EnableFeatures = (props) => {


    const iPayFeatures = [
        {
            featureLabel: "POS",
            name: "pos",
            value: false
        },
        {
            featureLabel: "Registers",
            name: "registers",
            value: false
        },
        {
            featureLabel: "Printer & Cash drawer",
            name: "printer_and_cash_drawer",
            value: false
        },
        {
            featureLabel: "Visitor Registry",
            name: "visitor_registry",
            value: false
        },
        {
            featureLabel: "Online ordering system",
            name: "online_ordering_system",
            value: false
        },
        {
            featureLabel: "Table reservation",
            name: "table_reservation",
            value: false
        },
        {
            featureLabel: "Offers & Vouchers",
            name: "offers_and_vouchers",
            value: false
        },
        {
            featureLabel: "Loyalty cards",
            name: "loyalty_cards",
            value: false
        },
        {
            featureLabel: "Payment links",
            name: "payment_links",
            value: false
        },
        {
            featureLabel: "All in one QR code - Hospo tools",
            name: "hospo_tools",
            value: false
        },
        {
            featureLabel: "Developers",
            name: "developers",
            value: false
        },
        {
            featureLabel: "Marketing",
            name: "marketing",
            value: false
        },
        {
            featureLabel: "My website - Reviews",
            name: "mywebsite_reviews",
            value: false
        },
        {
            featureLabel: "My website - Offers",
            name: "mywebsite_offers",
            value: false
        },
        {
            featureLabel: "Price list",
            name: "price_list",
            value: false
        },
    ]

    const [tabIndex, setTabIndex] = useState(0);
    const [featureEnableData, setFeatureEnableData] = useState({
        loyalty_cards: false,
        multi_user: false,
        offers_vouchers: false,
        online_orders: false,
        payment_links: false,
        table_reservation: false,
    });
    const [catsubcat, setcatsubcat] = useState();
    const [merchantData, setMerchantData] = useState(props.merchant_info);
    const [merchantFeature, setMerchantFeature] = useState(null);
    const [defaultVaue, setDefaultValue] = useState(null);
    const [loading, setLoading] = useState(false);
    console.log(merchantFeature, "merchantFeature");

    const fetchfeatureData = () => {

        const postData = { merchantId: props.merchant_info };

        client.post(api.fetchFeatures, postData, (error, response) => {

            if (!error) {
                if (!response.error) {
                    let getFeatureData = response.result;

                    setFeatureEnableData({
                        loyalty_cards: getFeatureData.loyalty_cards,
                        multi_user: getFeatureData.multi_user,
                        offers_vouchers: getFeatureData.offers_vouchers,
                        online_orders: getFeatureData.online_orders,
                        payment_links: getFeatureData.payment_links,
                        table_reservation: getFeatureData.table_reservation,
                    })

                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }

        });

    }

    const fetchCatData = () => {
        client.post(api.fetch_catsubcat, { show_all: true }, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            let getData = response.result.data;
                            let findData = getData.find((item) => item.category_name == merchantData.business_category);
                            setcatsubcat(findData);
                        } else {
                            toast.error("Internal error occured. Please contact support");
                        }
                    } catch {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }
        });
    }


    const fetchMerchantFeature = () => {

        const postData = { merchantId: props.merchant_info._id };
        setLoading(true);
        client.post(api.fetch_merchant_enable_features, postData, (error, response) => {

            if (!error) {
                if (!response.error) {
                    setMerchantFeature(response.result);
                    setLoading(false);
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }

        });

    }

    useEffect(() => {

        fetchfeatureData();
        fetchCatData();
        fetchMerchantFeature();

    }, [])

    useEffect(() => {

        if (merchantFeature) {

            let getNewData = {};

            merchantFeature.feature_data.map((item, ind) => {
                getNewData[`${ind}_${item.featureName}`] = item.featureValue == 1 ? true : false;

            })
            setDefaultValue(getNewData);
        }

    }, [merchantFeature])


    const updateFeatureData = (data) => {

        let postData = { updateData: data, update_ID: merchantFeature._id }
        client.put(api.update_merchant_enable_features, postData, (error, response) => {

            if (!error) {
                if (!response.error) {
                    setMerchantFeature(response.result);
                    toast.success("Updated Successfully");
                    setLoading(false);
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }

        });

    }


    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-xl-12 xl-100">
                        <Tabs
                            className="col-sm-12"
                            style={{ padding: "0", backgroundColor: "white", borderRadius: "3px", marginBottom: "0px" }}
                            defaultIndex={tabIndex}
                            onSelect={index => { setTabIndex(index) }}>
                            <TabList className="tabs tab-title" style={{ display: "flex", alignItems: "center", marginBottom: "-10px !important" }}>

                                <Tab style={{ display: "flex", alignItems: "center" }} >
                                    Features
                                </Tab>

                                <Tab style={{ display: "flex", alignItems: "center" }} >
                                    Hide / Unhide Features
                                </Tab>

                            </TabList>


                            <div className="tab-content-cls">
                                <TabPanel >
                                    <FeaturePage
                                        merchant_info={props.merchant_info}
                                        featureEnableData={featureEnableData}
                                    />
                                </TabPanel>


                                <TabPanel >
                                    <HideAndUnhideFeature
                                        merchant_info={props.merchant_info}
                                        featureEnableData={featureEnableData}
                                        catsubcat={catsubcat}
                                        iPayFeatures={iPayFeatures}
                                        defaultVaue={defaultVaue}
                                        merchFeature={merchantFeature?.feature_data}
                                        loading={loading}
                                        updateFeatureData={updateFeatureData}
                                    />
                                </TabPanel>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </div>
            {/* <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-xl-12 xl-100">
                        <div className="card">
                            <div className="card-body" style={{ padding: "1rem" }}>
                                <h6 style={{ fontWeight: "bold", marginBottom: "10px" }}>Features</h6>
                                <div className="row" style={{ margin: "0rem" }}>
                                    {
                                        <div className="table-responsive col col-sm-12">
                                            <table className="table table-border-horizontal table-striped table-light">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style={{ width: '5%' }}>#</th>
                                                        <th scope="col" style={{ width: '40%' }}>Features Name</th>
                                                        <th scope="col" style={{ width: '55%' }}>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">{1}</th>
                                                        <td>
                                                            Multiuser
                                                        </td>
                                                        <td>
                                                            {featureEnableData.multi_user == true ? "Enabled" : "Disabled"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">{2}</th>
                                                        <td>
                                                            Online Ordering System
                                                        </td>
                                                        <td>
                                                            {featureEnableData.online_orders == true ? "Enabled" : "Disabled"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">{3}</th>
                                                        <td>
                                                            Table Reservation
                                                        </td>
                                                        <td>
                                                            {featureEnableData.table_reservation == true ? "Enabled" : "Disabled"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">{4}</th>
                                                        <td>
                                                            Offers & vouchers
                                                        </td>
                                                        <td>
                                                            {featureEnableData.offers_vouchers == true ? "Enabled" : "Disabled"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">{5}</th>
                                                        <td>
                                                            Loyalty Cards
                                                        </td>
                                                        <td>
                                                            {featureEnableData.loyalty_cards == true ? "Enabled" : "Disabled"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">{6}</th>
                                                        <td>
                                                            Create Payment Links
                                                        </td>
                                                        <td>
                                                            {featureEnableData.payment_links == true ? "Enabled" : "Disabled"}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </Fragment>
    )
}

export default EnableFeatures;