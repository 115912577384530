import React, { useState } from "react";
import useForm from 'react-hook-form'
import { withRouter } from "react-router-dom";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";

const Step1 = props => {
  const { register, handleSubmit, errors } = useForm();
  let [loading, setloading] = useState(false);
  let [custbillingdata, setcustbillingdata] = useState(props.custbillingdata ? props.custbillingdata : []);
  let [wd_fee_btype, setwd_fee_btype] = useState(props.custbillingdata ? props.custbillingdata.wd_fee_btype : 1);
  let [wd_fee_atype, setwd_fee_atype] = useState(props.custbillingdata ? props.custbillingdata.wd_fee_atype : 1);
  const [access_level, setAccessLevel] = useState(localStorage.getItem("accessLevel"))
  const onSubmit = data => {

    if (data !== '') {

      data.wd_fee_btype = wd_fee_btype;
      data.wd_fee_atype = wd_fee_atype;

      setloading(true);
      client.post(api.update_cbilling_data, data, async function (error, response) {
        if (!error) {
          // alert(JSON.stringify(response));
          //   return false;
          if (typeof response.error !== "undefined") {
            try {
              if (!response.error) {

                props.getcashback_settings()

                toast.success("Updated Successfully");
                // setcashbackdata(response.result.data);
              } else {
                toast.error(response.message);
              }
            } catch (e) {
              toast.error("Internal error occured. Please contact support");
            }
          } else {
            toast.error("Internal error occured. Please contact support");
          }
        } else {
          toast.error("Internal error occured. Please contact support");
        }
        setloading(false);
      });
    } else {
      errors.showMessages();
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12 col-xl-12 xl-100" style={{ padding: "0" }}>
          <div className="card">
            <div className="card-body" style={{ padding: "1rem" }}>

              <form className="needs-validation billing-form" noValidate="" onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 20 }}>
                <h5 style={{fontSize:"1rem"}}>Withdrawal Fee</h5>
                {/* <span>{JSON.stringify(custbillingdata)}</span> */}
                <div className="form-row">

                  <div className="col-sm-2">
                    <div className="form-group">
                      <label className="col-form-label">Minimum Amount</label>
                      <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="wd_comp_amount" defaultValue={custbillingdata.wd_comp_amount} ref={register({ required: true })} />
                      <span>{errors.wd_comp_amount && 'Minimum Amount is requires'}</span>
                    </div>
                  </div>

                  <div className="col-sm-2">
                    <div className="form-group">
                      <label className="col-form-label">Fee Type</label>
                      <select className="form-control select" name="wd_fee_btype" disabled={access_level == 1 ? true : false} value={wd_fee_btype}
                        onChange={(e) => setwd_fee_btype(e.target.value)} >
                        <option value="1">Percent</option>
                        <option value="2">Amount</option>
                      </select>
                      <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                    </div>
                  </div>

                  <div className="col-sm-3">

                    <div className="form-group">
                      <label className="col-form-label">Fee below to min. Amount</label>
                      <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="wd_fee_below" defaultValue={custbillingdata.wd_fee_below} ref={register({ required: true })} />
                      <span>{errors.wd_fee_below && 'Fee is requires'}</span>
                    </div>
                  </div>

                  <div className="col-sm-2">
                    <div className="form-group">
                      <label className="col-form-label">Fee Type</label>
                      <select className="form-control select"
                        onChange={(e) => setwd_fee_atype(e.target.value)} name="wd_fee_atype" disabled={access_level == 1 ? true : false} value={wd_fee_atype}>
                        <option value="1">Percent</option>
                        <option value="2">Amount</option>
                      </select>
                      <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                    </div>
                  </div>

                  <div className="col-sm-3">
                    <label className="col-form-label">Fee above min. Amount</label>
                    <input className="form-control" step="0.01" min="0" type="Number" name="wd_fee_above" disabled={access_level == 1 ? true : false} ref={register({ required: true })} defaultValue={custbillingdata.wd_fee_above} />
                    <span>{errors.wd_fee_above && 'Fee is required'}</span>
                  </div>
                </div>

                <hr />
                {access_level != 1 && <div className="form-group col-lg-12">
                  {!loading ?
                    <button className="btn btn-primary btn-xs pull-right" type="submit">SAVE</button>
                    :
                    <div className="loader-box pull-right" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 42, width: 42 }}></span></div>
                  }
                </div>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Step1);