import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../components/common/breadcrumb';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Table } from 'reactstrap';
import moment from 'moment';
import client from "../Api/HTTPClient";
import api from "../constant/apilist";
import { toast } from "react-toastify";

import './affiliateTier.scss'
import PaginationComponent from 'react-reactstrap-pagination';
import { Delete } from '@material-ui/icons';
import { DisappearedLoading } from 'react-loadingg';
import VerifiedMerchantProfile from './merchants/VerifiedMerchantProfile';
import { Link as NavLink } from "react-router-dom";

function AffiliateProgramTierPage() {
	const [tabIndex, setTabIndex] = useState(0);
	const [totalData, setTotalData] = useState(0);

	const [activePage, setActivePage] = useState(1);
	const [pageSize, setPageSize] = useState(25);
	const [data, setData] = useState([]);
	const [Loading, setLoading] = useState(true)
	const [fetchData, setFetchData] = useState(false)
	const [showStore, setShowStore] = useState(false);
	const [merchantInfo, setMerchantInfo] = useState([]);
	const [merchantId, setMerchantId] = useState(null);

	// console.log(data, "data");

	const handlePageChange = (pageNumber) => {
		console.log(typeof (pageNumber))
		setActivePage(pageNumber)
	}

	function handleStore(id) {
		setMerchantId(id);
		let postdata = { merchant_id: id };
		client.post(api.merchant_details, postdata, async function (error, response) {
			if (!error) {
				if (typeof response.error !== "undefined") {
					try {
						if (!response.error) {
							try {
								setMerchantInfo(response.result.contactDetails);
								setShowStore(true);
							} catch (error) {
								console.log(error)
							}
						} else {
							setTimeout(() => {
								toast.error(response.message);
							}, 200);
						}
					} catch (e) {
						setTimeout(() => {
							toast.error("Internal error occured. Please contact support");
						}, 200);
					}
				} else {
					setTimeout(() => {
						toast.error("Internal error occured. Please contact support");
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
		})
	}

	function AddCommentInRequest(id, value, prevValue) {
		if (value !== prevValue) {

			let postData = {
				request_id: id,
				comment: value
			}

			client.post(api.update_comment_tier_request, postData, async (error, response) => {
				if (!error) {
					if (!response.error) {
						toast.success('Updated SuccessFully')
						fetchTierRequest()
					} else {
						toast.error(response.message);
					}
				} else {
					toast.error("Internal Error Occured. Please contact internal support.")
				}
			});
		}
	}

	function deleteTierRequest(id, merchant_id) {

		let postData = {
			request_id: id,
			merchant_id
		}

		client.post(api.delete_tier_request, postData, async (error, response) => {
			if (!error) {
				if (!response.error) {
					setFetchData(!fetchData)

				} else {
					toast.error(response.message);
				}
			} else {
				toast.error("Internal Error Occured. Please contact internal support.")
			}
		});

	}


	function fetchTierRequest() {

		let postData = {
			skip: (activePage - 1) * pageSize,
			pageSize: parseInt(pageSize),
		}

		client.post(api.get_tier_request, postData, async (error, response) => {
			if (!error) {
				if (!response.error) {

					console.log(response)
					setData(response.data)
					setTotalData(response.allRecord)

					setLoading(false)

				} else {
					toast.error(response.message);
				}
			} else {
				toast.error("Internal Error Occured. Please contact internal support.")
			}
		});
	}


	useEffect(() => {

		setLoading(true)
		fetchTierRequest()

	}, [activePage, pageSize, fetchData])


	return (
		<>
			{/* {
				true ? */}
			<>
				<div className="col-lg-7 px-0 col-sm-12" style={{ fontSize: "12px" }}>
					<Breadcrumb title={"Affiliate Program"} parent={"Home"} />
				</div>

				<div className="container-fluid">
					<div className="row theme-tab">
						<Tabs
							className="col-sm-12"
							defaultIndex={tabIndex}
							onSelect={(index) => setTabIndex(index)}
							style={{
								backgroundColor: "white",
								borderRadius: "1.5%",
								boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.16) ",
								marginBottom: "0px",
							}}
						>
							<TabList className="tabs tab-title">
								<Tab
									style={{
										marginTop: "0.5rem",
										display: "flex",
										alignItems: "center",
									}}
								>
									Tier Update Request
								</Tab>

							</TabList>

							<div className="container-fluid">
								<div className="row">
									<div className="col-sm-12 col-xl-12 xl-100">
										<div className="card mb-0">
											<div
												className="card-body "
												style={{ padding: "1rem" }}
											>
												<div className="tab-content-cls">

													<TabPanel>
														{
															!Loading ?

																<div style={{ background: '#ffffff' }}>
																	<h5 style={{ fontSize: '.7rem', textAlign: 'end' }}>
																		Showing {(activePage - 1) * pageSize + 1} to {((activePage - 1) * pageSize) + data?.length} of{" "}
																		{totalData} entries
																	</h5>
																	<Table className="table table-border-horizontal affiliate-tier-table" striped>
																		<thead>
																			<tr style={{ background: '#ffffff' }}>
																				<th>#</th>
																				<th>Date & Time</th>
																				<th>Store</th>
																				<th>Tier Update</th>
																				<th>Action</th>
																			</tr>
																		</thead>
																		<tbody>
																			{
																				data?.length > 0 ? data?.map((item, index) => {

																					return (
																						<tr>
																							<td>{(activePage - 1) * pageSize + index + 1}</td>
																							<td>{moment(item?.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
																							{/* <td onClick={() => handleStore(item.merchant_ref)}> {item?.store_name}</td> */}
																							<td><NavLink to={`/merchants/merchantDetails/${item.merchant_ref}`}>{item?.merchant_info[0]?.business_name}</NavLink></td>
																							<td>{`Tier ${item?.current_tier}`} to {item?.update_tier?.slice(0, item?.update_tier?.indexOf(':'))}</td>
																							<td>
																								<div className='d-flex align-items-center'>
																									<span onClick={() => { deleteTierRequest(item?._id, item?.merchant_ref) }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
																										<Delete style={{ color: 'red', fontSize: '16px' }} /></span>
																									<input placeholder='Comment' className='comment_status_info' defaultValue={item?.additional_comment} onBlur={e => AddCommentInRequest(item?._id, e.target.value, item?.additional_comment)} />
																								</div>
																							</td>
																						</tr>
																					)
																				}) :
																					<tr>
																						<td style={{ textAlign: 'center' }} colSpan="5">No records found</td>
																					</tr>

																			}
																		</tbody>
																	</Table>
																	<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} className='mt-3'>
																		<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
																			<select className="custom-select" style={{ width: "35%", fontSize: "0.73rem", marginRight: "10px" }} onChange={(event) => setPageSize(event.target.value)} value={pageSize}>
																				<option value="15">15</option>
																				<option value="25">25</option>
																				<option value="50">50</option>
																				<option value="75">75</option>
																				<option value="100">100</option>
																			</select>
																			<span style={{ fontSize: "0.73rem" }}>Records per Page</span>
																		</div>
																		<PaginationComponent
																			totalItems={totalData}
																			pageSize={pageSize}
																			onSelect={handlePageChange}
																		/>
																	</div>
																</div>

																:
																<div>
																	<div className="loader-box" >
																		<div className="loader" style={{ display: "flex", alignItems: "center" }}>
																			<DisappearedLoading size="medium" color="#514F4E" />
																		</div>
																	</div>
																</div>
														}
													</TabPanel>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Tabs>
					</div>
				</div>
			</>
			{/* :
					<>
						<div className='m-2 p-2' onClick={() => setShowStore(false)} style={{ cursor: 'pointer' }}>Go Back</div>
						<VerifiedMerchantProfile merchant_info={merchantInfo} merchant_id={merchantId} />
					</>
			} */}

		</>
	);
}

export default AffiliateProgramTierPage;