import React, { Component, useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormGroup, Col, Row, Input, CustomInput } from 'reactstrap';
import useForm  from "react-hook-form";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import {Link,useHistory} from "react-router-dom";

let AddOfferModal = (props) => {
    
    let [loadingbtn, setloadingbtn] = useState(false);

    const { handleSubmit, register, errors } = useForm();

    let history = useHistory();

    // const onSubmit = form_data => {
        
    //     if (form_data !== '') {

    //         setloadingbtn(true);

    //         client.post(api.disable_subcat, form_data, async function (error, response) {
    //             if (!error) {
    //                 if (typeof response.error !== "undefined") {
    //                     try {
    //                         if (!response.error) {
    //                             // props.fetc_offers();
    //                             props.onClose(true);
    //                             history.push(`${process.env.PUBLIC_URL}/other-settings`);
    //                             toast.success("Updated Successfully");
    //                             // setcashbackdata(response.result.data);
    //                         } else {
    //                             toast.error(response.message);
    //                         }
    //                     } catch (e) {
    //                         toast.error("Internal error occured. Please contact support");
    //                     }
    //                 } else {
    //                     toast.error("Internal error occured. Please contact support");
    //                 }
    //             } else {
    //                 toast.error("Internal error occured. Please contact support");
    //             }
                
    //             setloadingbtn(false);
    //         });
    //     }
    //     else {
    //         errors.showMessages();
    //     }
    // }
    
    if (props.items) {
        return (
        
            <Modal isOpen={props.isOpen} toggle={props.onClose} size="md" className="modal-body" centered={true}>
                <ModalHeader toggle={props.onClose}><p className="m-0">Below stores are using the category and subcategory you are trying to update, Kindly update their category/subcategory to update the settings</p></ModalHeader>
                    <ModalBody>
                        <ModalBody style={{ padding: "16px 0" }}>
                            <Row form>
                                <Col md={12}>
                                <div className="table-responsive" >
                                    <table className="table table-border-horizontal table-striped" >
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col" >Store Name</th>
                                                <th scope="col" >IPayPro Mobile</th>
                                                <th scope="col" >ID</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* countries{} */}
                                            {props.items && props.items.length > 0 ? props.items.map((row, index) => {
                                                return (
                                                    <tr>
                                                        <td>#</td>
                                                        <td>{row.business_name}</td>
                                                        <td>{row.mobile}</td>
                                                        <td>{row.unique_id}</td>
                                                    </tr>
                                                )
                                            })
                                                : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            {!loadingbtn ?
                                <>
                                    <Button color="primary" type="button"  onClick={props.onClose}>Okay</Button>
                                </>
                                :
                                <div className="loader-box pull-right" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 20, width: 20 }}></span></div>
                            }
                        </ModalFooter>
                    </ModalBody>
            </Modal>
        );
    } else {
        return null;
    }
}

export default AddOfferModal;