import React, { Component } from "react";
import moment from "moment-timezone";

import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";

class MerchantAdminNote extends Component {
    state = {
        merchant_id: this.props.merchant_id,
        merchant_info: this.props.merchant_info,
        note: null,
        access_level: localStorage.getItem("accessLevel"),
        adminUser: JSON.parse(localStorage.getItem("user"))
    }

    componentDidMount() {
        this.getMerchantDetails();
    }

    getMerchantDetails = async () => {
        let current = this;
          let { merchant_id } = this.state;
          let postdata = { merchant_id: merchant_id };
          client.post(api.merchant_details, postdata, async function (error, response) {
            if (!error) {
              if (typeof response.error !== "undefined") {
                try {
                  if (!response.error) {
                    try {
                      current.setState({ merchant_info: response.result.contactDetails });
                        localStorage.removeItem('isChange');
                    } catch (error) {
                        console.log(error)
                    }
                  } else {
                    setTimeout(() => {
                      toast.error(response.message);
                    }, 200);
                  }
                } catch (e) {
                  setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                  }, 200);
                }
              } else {
                setTimeout(() => {
                  toast.error("Internal error occured. Please contact support");
                }, 200);
              }
            } else {
              setTimeout(() => {
                toast.error("Internal error occured. Please contact support");
              }, 200);
            }
          })
      };

    refreshPage() {
        window.location.reload(false);
    }

    render_date = (add_date) => {

        if (typeof (add_date) === "undefined") {
            return;
        }

        const convert_date = new Date(add_date);
        const added_date = moment.tz(convert_date, "Australia/Sydney").format("DD/MM/YYYY HH:mm");
        return added_date;
    }

    noteChangeHandler = (event) => {
        const note = event.target.value;
        this.setState(prevState => {
            return {
                ...prevState,
                note: note
            }
        })
    }

    noteSubmitHandler = (event) => {
        event.preventDefault();

        const postdata = { merchant_id: this.state.merchant_id, comment: { type: "normal", note: this.state.note, user: this.state.adminUser.email } }

        client.put(api.update_merchant_details, postdata, (error, response) => {
            if (!error) {
                if (!response.error) {
                    this.refreshPage();
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        })
    }

    render() {
        const { merchant_info, access_level } = this.state;
        if(localStorage.getItem('isChange')){
            this.getMerchantDetails();
        }
        let comments = merchant_info?.comment?.map((v, i) => merchant_info.comment[merchant_info.comment.length - (i + 1)]);
        console.log(merchant_info);
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-xl-12 xl-100">
                        <div className="card">
                            <div className="card-header" style={{ padding: "20px" }}>
                                <h5>Comments</h5>
                            </div>
                            <div className="card-body" style={{ padding: "20px" }}>
                                <div className="chat-box" style={{ paddingTop: "0", paddingBottom: "0" }}
                                >
                                    <div className="chat-right-aside bitcoin-chat">
                                        <div className="chat">
                                            <div className="chat-history chat-msg-box custom-scrollbar" style={{ padding: "0", margin: "0" }} >
                                                <ul style={{ margin: "0" }}>
                                                    {merchant_info.comment.length != 0 ? comments.map(el => {
                                                        return (<li style={{ margin: "0" }}>
                                                            <div className="message my-message " style={{ marginBottom: "10px" }}>
                                                                <h6>{el.user}<span className="message-data-time pull-right">{this.render_date(el.date)}</span> </h6>
                                                                {el.type == "normal" || el.type == "status" || el.type == "sendemail" || el.type == "sendsms" ? null : <h6>Type : {el.type}</h6>}
                                                                {el.note}
                                                            </div>
                                                        </li>)
                                                    }) : <p>No comment added!</p>}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {access_level != 1 && <div className="card-footer" style={{ padding: "20px" }}>
                                <form className="theme-form" >
                                    <div className="form-group">
                                        <label >Comment:</label>
                                        <textarea className="form-control" required rows="3" onChange={this.noteChangeHandler}></textarea>
                                    </div>
                                    <div style={{ padding: "15px 0 0 15px" }}>
                                        <button className="btn btn-primary btn-xs pull-right" type="submit" name="comment"
                                            onClick={(event) => this.noteSubmitHandler(event)}
                                        >Save</button>
                                    </div>
                                </form>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default MerchantAdminNote;