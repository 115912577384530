import React, { Fragment, useState } from "react";
import { X } from "react-feather";
import { Button } from "reactstrap"
import Backdrop from "../Backdrop/Backdrop";
import "./customModal.css"

const CustomModal = ({ modalClose, children, }) => {

    return (
        <Fragment>
            {<div >
                <Backdrop backdropClicked={modalClose} />
                <div className="custom-sidemodal">
                    <div className="sidemodal__close">
                        <h6 style={{fontSize:"0.9rem"}}>This is how your coupon will look to your customer...</h6>
                       {/*  <X onClick={modalClose} style={{ cursor: "pointer" }} /> */}
                    </div>
                    <div className="sidemodal__body">
                        {children}
                    </div>
                </div>
            </div>}
        </Fragment>


    );
}

export default CustomModal;