import React, { Component, Fragment } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink as NavLinkTab } from "reactstrap";
import { NavLink, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';

import Breadcrumb from "../../../components/common/breadcrumb";
import CustomerCrownsPoints from "./CustomerCrownsPoints";
import MerchantCrownsPoints from "./MerchantCrownsPoints";
import Offers from "./Offers";
import GiftVouchers from "./GiftVouchers";
import Referrals from "./Referrals";

class CrownsPoints extends Component {
    state = {
        //activeTab: "1"
        tabIndex: 0,
        modal: false,
        modal2: false,
        access_level: localStorage.getItem("accessLevel"),
    }

    toggle = () => {
        this.setState(prevState => {
            return {
                modal: !prevState.modal,
            }
        });
    }

    toggle2 = () => {
        this.setState(prevState => {
            return {
                modal2: !prevState.modal2,
            }
        });
    }



    render() {
        const { tabIndex } = this.state;
        return (
            <Fragment>
                <div className="row">
                    <div className="col-lg-7 col-sm-12" style={{fontSize:"12px"}}>
                        <Breadcrumb title={"Crown & Points"} parent={"Settings"} />
                    </div>
                    <div className="col-lg-5 col-sm-12 ">
                        <div className="btn-group pull-right" style={{ paddingTop: "15px",marginRight: "0.6rem" }} role="group" aria-label="Basic example">
                            {this.state.access_level != 1 &&
                                <button className="btn custBtnAdmin"
                                    onClick={tabIndex == 0 ? this.toggle : tabIndex == 1 ? this.toggle2 : null}><i className="fa fa-plus-square-o" style={{ margin: "0px 7px 0 0", color: "#327ebc" }}></i> Add new level
                                </button>}
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row theme-tab">
                        <Tabs className="col-sm-12" defaultIndex={this.state.tabIndex} onSelect={index => this.setState({ tabIndex: index })} style={{backgroundColor:"white",borderRadius:"3px",border:"1px solid rgb(223, 232, 241)",marginBottom:"0px"}}>
                            <TabList className="tabs tab-title" >
                                {/* <Tab style={{ display:"flex",alignItems:"center"}} >
                                    Customer
                            </Tab> */}
                                <Tab style={{ display:"flex",alignItems:"center"}}>
                                    Merchant
                                </Tab>
                                
                                <Tab style={{ display:"flex",alignItems:"center"}}>
                                    Offers
                                </Tab>
                                
                                <Tab style={{ display:"flex",alignItems:"center"}}>
                                    Gift Voucher
                                </Tab>

                                <Tab style={{ display:"flex",alignItems:"center"}}>
                                    Referrals
                                </Tab>


                            </TabList>

                            {/* {customers ? */}
                            <div className="tab-content-cls">
                                {/* <TabPanel>
                                    <CustomerCrownsPoints modal={this.state.modal} toggle={this.toggle}></CustomerCrownsPoints>
                                </TabPanel> */}
                                <TabPanel>
                                    <MerchantCrownsPoints modal={this.state.modal2} toggle={this.toggle2}></MerchantCrownsPoints>
                                </TabPanel>
                                
                                <TabPanel>
                                    <Offers merchant_id={null} offer_level={1}/>
                                </TabPanel>
                                
                                <TabPanel>
                                    <GiftVouchers merchant_id={null} />
                                </TabPanel>

                                <TabPanel>
                                    <Referrals />
                                </TabPanel>

                            </div>
                            {/* : <div className="loader-box" >
                                    <div className="loader" style={{ margin: "auto auto" }}>
                                        <div className="line bg-primary"></div>
                                        <div className="line bg-primary"></div>
                                        <div className="line bg-primary"></div>
                                        <div className="line bg-primary"></div>
                                    </div>
                                </div>} */}
                        </Tabs>
                    </div>
                </div>
                {/* <Nav tabs className="nav-pills nav-primary">
                        <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                            <NavLinkTab className={this.state.activeTab == '1' ? 'active nav-link' : ''} onClick={() => { this.toggle('1') }} value='1'>

                                Customer
                                                    </NavLinkTab>
                        </NavItem>
                        <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                            <NavLinkTab className={this.state.activeTab == '2' ? 'active nav-link' : ''} onClick={() => { this.toggle('2') }} value='2'>

                                Merchant
                                                    </NavLinkTab>
                        </NavItem>

                    </Nav>

                    <TabContent activeTab={this.state.activeTab} style={{ marginTop: "15px" }} >
                        <TabPane tabId="1">
                            <CustomerCrownsPoints></CustomerCrownsPoints>

                        </TabPane>
                        <TabPane tabId="2">
                            <MerchantCrownsPoints></MerchantCrownsPoints>
                        </TabPane>
                    </TabContent> */}
            </Fragment>


        );
    }
}

export default CrownsPoints;