import React, {useState} from "react";
import useForm from 'react-hook-form'
import { withRouter } from "react-router-dom";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
const Step1 = props => {
  const { register, handleSubmit, errors } = useForm();
  let [loading, setloading] = useState(false);

  const onSubmit = data => {

    if (data !== '') {

    data.merchant_id = props.merchant_id
    console.log(data)
    setloading(true);
      client.post(api.add_mcredit_earning, data, async function (error, response) {
        if (!error) {
          // alert(JSON.stringify(response));
          //   return false;
          if (typeof response.error !== "undefined") {
            try {
              if (!response.error) {
                
                toast.success("Updated Successfully");
                // setcashbackdata(response.result.data);
              } else {
                toast.error(response.message);
              }
            } catch (e) {
              toast.error("Internal error occured. Please contact support");
            }
          } else {
            toast.error("Internal error occured. Please contact support");
          }
        } else {
          toast.error("Internal error occured. Please contact support");
        }
        setloading(false);
        props.setcredit_earning_modal(false)
        props.getbilling_settings()
      });
    } else {

      errors.showMessages();
    }
  };

  return (
    
    <Modal isOpen={props.credit_earning_modal} className="modal-body" centered={true}>
      <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader  style={{ textAlign: 'center' }}>i-Pay </ModalHeader>
        <ModalBody>
          <div className="form-row">
          <div className="col-sm-12">
            <div className="form-group">
              <label className="col-form-label">Credit Amount</label>
              <input className="form-control" step="0.1" min="0" type="Number" name="amount" ref={register({ required: true })} />
              <span>{errors.amount && 'Amount is requires'}</span>
            </div>
          </div>
          
          <div className="col-sm-12">
            <div className="form-group">
              <label className="col-form-label">Reason</label>
              <textarea  className="form-control" name="reason" ref={register({ required: true })} >
              </textarea>
              
              <span>{errors.reason && 'Reason is requires'}</span>
            </div>
            </div>
            
            <div className="col-sm-12">
            <div className="form-group">
              <label className="col-form-label">Comment</label>
              <textarea  className="form-control" name="comment" ref={register({ required: true })} >
              </textarea>
              
              <span>{errors.comment && 'Comment is requires'}</span>
            </div>
          </div>

        </div>
        </ModalBody>
        {!loading ?
          <ModalFooter>
            <Button color="primary" type="submit">Save</Button>
            <Button color="secondary" onClick={() => props.setcredit_earning_modal(false)}>Cancel</Button>
          </ModalFooter>
          :
          <ModalFooter>
            <div className="loader-box" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 42, width: 42 }}></span></div>
          </ModalFooter>
        }  
      </form>
    </Modal>
  );
}

export default withRouter(Step1);