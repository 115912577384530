import React, { Component, Fragment, useEffect, useState } from "react";
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label,
    Form, FormGroup, Col, Row, FormFeedback, CustomInput
} from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import { Link as NavLink } from "react-router-dom";
import moment from "moment-timezone";
import useForm from "react-hook-form";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";

import AddLocations from "./addLocations";
import EditLocations from "./editLocations";
import PaginationComponent from "react-reactstrap-pagination";
import { DisappearedLoading } from "react-loadingg";

const LocationSettings = (props) => {

    let [loadingbtn, setloadingbtn] = useState(false);

    const [toggleModal, setToggleModal] = useState(false);
    const [states, setStates] = useState([]);

    const [locationsData, setLocationsData] = useState([]) // whole Location data
    const [deleteLocationId, setDeleteLocationId] = useState(null)
    const [editLocation, setEditLocation] = useState(null)

    const [deleteLocationModal, setDeleteLocationModal] = useState(false)
    const [editLocationModal, setEditLocationModal] = useState(false)

    const [searchValue, setsearchValue] = useState(null);
    const [searchedData, setsearchedData] = useState(null);
    const [activePage, setactivePage] = useState(1);
    const [pageSize, setpageSize] = useState(15);
    const [loading, setloading]= useState(true);

    const { handleSubmit, register, errors, watch } = useForm();

    const [formData, setFormdata] = useState({
        suburb: '',
        postcode: '',
        country_state: '',
        latitude: '',
        longitude: ''
    });

    const [errorState, setErrorState] = useState({
        suburb: null,
        postcode: null,
        country_state: null,
        latitude: null,
        longitude: null
    });


    useEffect(() => {
        fetch_country_details();
    }, [])

    const fetchLocationsData = (activePage) => {
		let skip = (parseInt(activePage) - 1) * parseInt(pageSize);
        let postdata = {
            country_code: props.countryCode,
            fk_country_id: props.countryId,
            limit: parseInt(pageSize),
            skip
        }
        client.post(api.getAllLocationsData, postdata, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            setLocationsData(response.result.data)
                            // console.log('promoCodes ==>', response.result)
                        } else {
                        }
                    } catch (e) {
                    }
                } else {
                }
            } else {
            }
            setloading(false);
        })
    }
    useEffect(() => {
        fetchLocationsData(activePage)
    })


    const onSubmit = (e, form_data) => {
        e.preventDefault();
        const newState = { ...formData };
        const newErrorState = { ...errorState };
        const latRegex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/;
        const longRegex = /^\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
        if (newState.suburb === '') {
            newErrorState.suburb = "This Value is Required";
            // console.log();
        }
        if (newState.postcode === '') {
            newErrorState.postcode = "This Value is Required";
        }
        else if (!/^[0-9]*$/.test(formData.postcode)) {
            newErrorState.postcode = "This Value should be digits";
        }
        if (formData.postcode.length > 4) {
            newErrorState.postcode = "This value is too long. it should have 4 characters or fewer.";
        }

        if (newState.country_state === '') {
            newErrorState.country_state = "This value is Required";
        }

        if (newState.latitude === '') {
            newErrorState.latitude = "This value is Required";
        }
        //  else if(!/^[0-9]*$/.test(formData.latitude)) {
        //     newErrorState.latitude = "This value should be digits";
        // }
        else if (!latRegex.test(formData.latitude)) {
            newErrorState.latitude = "Enter a valid Latitude value";
        }

        if (newState.longitude === '') {
            newErrorState.longitude = "This value is Required";
        }
        // else if(!/^[0-9]*$/.test(formData.longitude)) {
        //     newErrorState.longitude = "This value should be digits";
        // }
        else if (!longRegex.test(formData.longitude)) {
            newErrorState.longitude = "Enter a valid Longitude value";
        }

        setFormdata(newState);
        setErrorState(newErrorState);
        console.log(newErrorState, errorState);
        if (!newErrorState) {
            setToggleModal(false)
        }
    }

    const modalToggleHandler = () => {
        setToggleModal(!toggleModal);
    }

    const onSearchChangeHandler = () => {

    }

    const handlePageChange = (pageNumber) => {
        setactivePage(pageNumber);
		fetchLocationsData(pageNumber);
	};

    const setPageSize = (event) => {
        console.log(event, 'event')
		setpageSize(event.target.value);
		fetchLocationsData(1);
	};

    const fetch_country_details = async () => {

        let country_code;
        try {
            country_code = props.countryCode

        } catch { }

        let postdata = {
            "country_code": country_code
        }
        client.post(api.countries_list, postdata, async function (
            error,
            response
        ) {

            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {

                        if (!response.error) {
                            let country_data = response.result["data"];
                            if (country_data) {
                                setStates(country_data.states)
                            }
                            console.log(response.result["data"]);
                        }
                    } catch (e) {
                        // alert(e)

                    }
                }
            }
        });
    }


    const onChangeHandler = (e, identifier) => {

        if (e.target.value) {
            setErrorState({
                suburb: null,
                postcode: null,
                country_state: null,
                latitude: null,
                longitude: null
            });
        }

        setFormdata({
            ...formData,
            [identifier]: e.target.value.trimStart()
        })
        // const [errorState,setErrorState] = useState({
        //     suburb:null,
        //     postcode:null,
        //     country_state:null,
        //     latitude:null,
        //     longitude:null
        // });
    }

    //delete Location data func
    const handleLocationsDataDelete = () => {
        let postData = {
            locationsData_Id: deleteLocationId
        }
        client.post(api.deleteLocationsData, postData, async function (error, response) {
            console.log(postData, response, error, "loc delete")
            if (!error) {
                if (!response.error) {
                    toast.success("Deleted Successfully")
                    // forceReloadFunc()
                    fetchLocationsData()
                    // setfeatures(response.result)
                } else {
                    toast.error("Something went wrong")
                }
            } else {
                toast.error("Something went wrong")

            }
            setDeleteLocationModal(false)
        })
    }

    const deleteModalToggle = () => {
        setDeleteLocationModal(!deleteLocationModal)
    }

    const renderLocationDeleteModal = () => {

        return (
            <Modal isOpen={deleteLocationModal} toggle={deleteModalToggle} className="modal-body" centered={true}>

                <ModalHeader><h5 style={{ fontSize: "1.15rem" }}>Delete Locations Data</h5></ModalHeader>
                <ModalBody>
                    <ModalBody style={{ padding: "16px 0" }}>
                        <h5 style={{ fontSize: "1rem" }}>Are you sure you want to delete this Location Data?</h5>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" onClick={deleteModalToggle}>Not sure!</Button>
                        <Button color="primary" onClick={handleLocationsDataDelete}  >Yes Please!</Button>
                    </ModalFooter>
                </ModalBody>

            </Modal>
        );
    }

    const renderTable = () => {
        return (
            <>
                { loading ? 
                    <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                        <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                            <DisappearedLoading size="medium" color="#514F4E" />
                        </div>
                    </div> 
                : 
                    <div>
                        <div className="btn-group pull-right" style={{ paddingBottom: "0.5rem", paddingTop: "0.5rem" }} role="group" aria-label="Basic example">
                            <button className="btnAddNewVersion custBtnAdmin" onClick={setToggleModal}><i className="fa fa-plus-square-o" style={{ margin: "0 7px 0 0", color: "#4466f2" }} ></i> Add&nbsp;new&nbsp;location </button>
                        </div> 
                        {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: '5px' }}>
                            <div>
                                <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                                    <div>
                                        <input
                                            style={{
                                                border: "#dfe8f1 solid 1px",
                                                borderRadius: "3px",
                                                padding: "10px",
                                                fontSize: "0.75rem",
                                                outlineColor: '#dfe8f1'
                                            }}
                                            type="text"
                                            placeholder="Search"
                                            value={searchValue}
                                            onChange={onSearchChangeHandler}
                                        />
                                    </div>
                                </form>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <select
                                    className="custom-select"
                                    style={{ width: "32%", marginRight: "7px" }}
                                    onChange={setPageSize}
                                    value={pageSize}
                                >
                                    <option value="15">15</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="75">75</option>
                                    <option value="100">100</option>
                                </select>
                                <span>Records per Page</span>
                            </div>
                        </div> */}
                        <div className="table-responsive">
                            
                            
                            <table className="table table-border-horizontal ">
                                <thead>
                                    <tr style={{ background: '#f9fafe' }}>
                                        <th scope="col" style={{ width: "2%" }}>#</th>
                                        <th scope="col" style={{ width: "10%" }} >Suburb</th>
                                        <th scope="col" style={{ width: "4%" }}>Postcode</th>
                                        <th scope="col" style={{ width: "16%" }}>State</th>
                                        <th scope="col" style={{ width: "4%" }}>Latitude</th>
                                        <th scope="col" style={{ width: "5%" }}>Longitude</th>
                                        <th scope="col" style={{ width: "13%" }}>Modify Date</th>
                                        <th scope="col" style={{ width: "8%" }}>Modify By</th>
                                        <th scope="col" style={{ width: "5%" }}>
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        locationsData && locationsData.length > 0 ? locationsData.slice(0, 100).map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <th >{index + 1}</th>
                                                    <td >{item.suburb}</td>
                                                    <td >{item.post_code}</td>
                                                    <td >{item.state}</td>
                                                    <td >{item.latitude}</td>
                                                    <td >{item.longitude}</td>
                                                    <td >{moment(item.updatedAt).format('DD-MM-YYYY/H:mm')}</td>
                                                    <td >{item.modified_by}</td>
                                                    <td >
                                                        <span style={{ marginRight: "10px", fontSize: "0.75rem", color: "black" }}
                                                            onClick={() => {
                                                                setEditLocation(item)
                                                                setEditLocationModal(true)
                                                            }}
                                                        >
                                                            <i className="fa fa-edit"></i>
                                                        </span>
                                                        <span style={{ marginLeft: "10px", fontSize: "0.75rem", color: "red" }}
                                                            onClick={() => {
                                                                setDeleteLocationId(item._id)
                                                                setDeleteLocationModal(true)
                                                            }}
                                                        >
                                                            <i className="fa fa-trash"></i>
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                            : null
                                    }
                                </tbody>
                            </table>
                            {/* <div className="mt-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                {activePage * pageSize > locationsData.length ? (
                                    <p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>
                                        Showing {(activePage - 1) * pageSize + 1} to {locationsData.length} of {locationsData.length}{" "}
                                        entries
                                    </p>
                                ) : (
                                    <p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>
                                        Showing {(activePage - 1) * pageSize + 1} to{" "}
                                        {activePage * pageSize} of {locationsData.length} entries
                                    </p>
                                )}
                                <PaginationComponent
                                    totalItems={locationsData.length}
                                    pageSize={pageSize}
                                    onSelect={handlePageChange}
                                    defaultActivePage={activePage}
                                />
                            </div> */}
                        </div>
                    </div>
                }
            </>
        )
    }

    return (
        <Fragment>
            {deleteLocationModal && renderLocationDeleteModal()}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-xl-12">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card">

                                    <div className="card-body" style={{ paddingTop: "0px" }}>
                                        <div>
                                            {renderTable()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal
                        // isOpen={toggleModal}
                        toggle={modalToggleHandler}>
                        <form onSubmit={onSubmit}>
                            <ModalHeader >Add New Location</ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup className="valid_form">
                                            <Label for="suburb">Suburb</Label>
                                            <Input className={errorState.suburb !== null && 'invalid_field'} value={formData.suburb} onChange={(e) => onChangeHandler(e, 'suburb')} type="text" name="suburb"
                                                placeholder="Suburb" />
                                            {errorState.suburb !== null && (<span className="errorMessage">
                                                {errorState.suburb}
                                            </span>)}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup className="valid_form">
                                            <Label for="postcode">Postcode</Label>
                                            <Input className={errorState.postcode !== null && 'invalid_field'} onChange={(e) => onChangeHandler(e, 'postcode')} value={formData.postcode} type="text" name="postcode"
                                                placeholder="Postcode" />
                                            {errorState.postcode !== null && (<span className="errorMessage">
                                                {errorState.postcode}
                                            </span>)}
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup className="valid_form">
                                            <Label for="appType">State</Label>
                                            <Input className={errorState.country_state !== null && 'invalid_field'} onChange={(value) => {
                                                if (value) {
                                                    setErrorState({
                                                        suburb: null,
                                                        postcode: null,
                                                        country_state: null,
                                                        latitude: null,
                                                        longitude: null
                                                    });
                                                }
                                                setFormdata({ ...formData, country_state: value })
                                            }} type="select" name="state">
                                                <option selected disabled value="">Select State</option>
                                                {states.map(item => (
                                                    <>
                                                        <option value={formData.country_state}>{item.name}</option>
                                                    </>
                                                ))}
                                            </Input>
                                            {errorState.country_state !== null && (<span className="errorMessage">
                                                {errorState.country_state}
                                            </span>)}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup className="valid_form">
                                            <Label for="latitude">Latitude</Label>
                                            <Input className={errorState.latitude !== null && 'invalid_field'} value={formData.latitude} onChange={(e) => onChangeHandler(e, 'latitude')} type="text" name="latitude" placeholder="latitude" />
                                            {errorState.latitude !== null && (<span className="errorMessage">
                                                {errorState.latitude}
                                            </span>)}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup className="valid_form">
                                            <Label for="postcode">Longitude</Label>
                                            <Input className={errorState.longitude !== null && 'invalid_field'} value={formData.longitude} onChange={(e) => onChangeHandler(e, 'longitude')} type="text" name="longitude"
                                                placeholder="Longitude" />
                                            {errorState.longitude !== null && (<span className="errorMessage">
                                                {errorState.longitude}
                                            </span>)}
                                        </FormGroup>
                                    </Col>

                                </Row>


                            </ModalBody>
                            <ModalFooter>
                                <Button color="default" onClick={modalToggleHandler}>Cancel</Button>
                                <Button color="primary" type="submit" >Add</Button>

                            </ModalFooter>
                        </form>
                    </Modal>
                    <AddLocations
                        states={states}
                        countryCode={props.countryCode}
                        countryId= {props.countryId}
                        isOpen={toggleModal}
                        toggle={(reload = false) => {
                            setToggleModal(!toggleModal)
                            if (reload) {
                                fetchLocationsData()
                            }
                        }}
                    />

                    <EditLocations
                        isOpen={editLocationModal}
                        locationsData={locationsData}
                        states={states}
                        countryCode={props.countryCode}
                        countryId= {props.countryId}
                        toggle={(reload = false) => {
                            setEditLocationModal(!editLocationModal)
                            if (reload) {
                                fetchLocationsData()
                            }
                        }}
                        editLocation={editLocation}
                        setEditLocation={setEditLocation}
                    />
                </div>
            </div>
        </Fragment>
    );
}


export default LocationSettings;