import React from 'react';
import "./CustomTabs.scss";

const CustomTabs = ({
    tabDetails,
    activeTab,
    setActiveTab
}) => {

    return (
        <div className='tab_page_styles_cus'>
            {
                tabDetails.length > 0 && tabDetails.map((currData, currIndx) => {
                    const isLast = currIndx == tabDetails.length - 1;
                    return (
                        <>
                            <div
                                className={`tab_style_cus  ${activeTab == currData.tabNo ? 'tab_style_active_cus' : 'tab_style_cus'}`}
                                style={{ borderRight: isLast && "none" }}
                                onClick={() => setActiveTab(currData.tabNo)}
                            >
                                {`${currData.tabName} (${currData?.totalData})`}
                            </div>

                        </>
                    )
                })
            }
        </div>
    )
}

export default CustomTabs;