import React, { Fragment, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Collapse } from 'reactstrap';
import useForm from "react-hook-form";

const BusinessTypeProductPage = ({
    showData,
    defaultVaue,
    fetchBusinessTypeData,
    update_Data
}) => {

    let { register, errors } = useForm({ defaultValues: defaultVaue });

    const [selectedCat, setSelectedCat] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);

    const updateBusinessType = (data, updateType) => {

        update_Data(data, updateType);
    }

    const toggle = () => setIsOpen(!isOpen);
    const toggle2 = () => setIsOpen2(!isOpen2);

    const renderPortalName = (data) => {

        if (data) {
            let str1 = data.slice(8);
            const str2 = str1.charAt(0).toUpperCase() + str1.slice(1);
            return str2;
        }
    }

    return (
        <Fragment>
            <div className="container-fluid main_div px-0 m-4">
                <form noValidate="">
                    <div className="" style={{ width: "28rem" }}>
                        <h4 style={{ fontWeight: "600", fontSize: "14px", marginBottom: "5px" }}>{renderPortalName(showData?.website_URL.first_URL)}</h4>
                        <h4 style={{ fontSize: "14px", marginBottom: "1rem" }}>Business Type & Product Page </h4>
                        <div className="table-responsive">
                            <table className="table table-border-horizontal">
                                <thead>
                                    <tr style={{ background: '#f9fafe', width: "100%" }}>
                                        <th scope="col" style={{ width: "50%" }}>Business Types</th>
                                        <th scope="col" style={{ width: "50%" }}>Pages</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td >
                                            {
                                                showData && showData.business_types.map((items, index) => {

                                                    return (
                                                        <>
                                                            <div>
                                                                <div
                                                                    className="my-1 p-1 d-flex align-items-center justify-content-start"
                                                                    style={{ fontSize: "12px", fontWeight: "600", backgroundColor: "#E4E4E4" }}
                                                                >
                                                                    <div onClick={() => { setSelectedCat(items); toggle() }}>
                                                                        <i style={{ color: "black", marginRight: "0.5rem" }}
                                                                            id={`${isOpen && (selectedCat && selectedCat._id == items._id) ? "minus" : "plus"}`}
                                                                            className={`${isOpen && (selectedCat && selectedCat._id == items._id) ? "fa fa-minus" : "fa fa-plus"}`}>
                                                                        </i>
                                                                    </div>

                                                                    <input
                                                                        type="checkbox"
                                                                        id={`${items._id}`}
                                                                        name={`${items._id}_catName`}
                                                                        ref={register}
                                                                        onChange={(event) => {

                                                                            let currentSubCat = items.subBusinessType;
                                                                            currentSubCat.forEach((data, index) => { data.enable = event.target.checked; });
                                                                            let newData = { ...items, subBusinessType: currentSubCat, enable: event.target.checked };

                                                                            showData.business_types.forEach((element, index) => {
                                                                                if (element._id == newData._id) {
                                                                                    showData.business_types[index] = newData;
                                                                                }
                                                                            });

                                                                            let getBusienessTypeData = showData.business_types.findIndex((ele) => ele.enable == true);
                                                                            if (getBusienessTypeData == -1) {
                                                                                toast.error("You cannot unselect all business types, Atleast one should be selected", { autoClose: 4000, hideProgressBar: true });
                                                                                fetchBusinessTypeData();
                                                                            } else {
                                                                                let updateType = "businessType";
                                                                                updateBusinessType(showData.business_types, updateType);
                                                                            }
                                                                        }}
                                                                    />
                                                                    <label className="mb-0 ml-2 text-capitalize" for={`${items._id}`}>{items.categoryName}</label>
                                                                </div>
                                                                {
                                                                    selectedCat && selectedCat._id == items._id ?
                                                                        <>
                                                                            <Collapse isOpen={isOpen} className="ml-4 my-2">
                                                                                {
                                                                                    items.subBusinessType.map((element) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div
                                                                                                    className="my-1 p-1 d-flex align-items-center justify-content-start"
                                                                                                    style={{ fontSize: "12px", fontWeight: "600", backgroundColor: "#E4E4E4" }}
                                                                                                >
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        id={`${element._id}`}
                                                                                                        name={`${element._id}-subCateName`}
                                                                                                        ref={register}
                                                                                                        onChange={(event) => {

                                                                                                            let newData = { ...element, enable: event.target.checked };

                                                                                                            selectedCat.subBusinessType.forEach((showData, index) => {
                                                                                                                if (showData._id == newData._id) {
                                                                                                                    selectedCat.subBusinessType[index] = newData;
                                                                                                                }
                                                                                                            });

                                                                                                            showData.business_types.forEach((ele, index) => {
                                                                                                                if (ele._id == selectedCat._id) {
                                                                                                                    showData.business_types[index] = selectedCat;
                                                                                                                }
                                                                                                            });
                                                                                                            let updateType = "businessType";
                                                                                                            updateBusinessType(showData.business_types, updateType);
                                                                                                        }}
                                                                                                    />
                                                                                                    <label className="mb-0 ml-2 text-capitalize" for={`${element._id}`}>{element.subCateName}</label>
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </Collapse>

                                                                        </> : null
                                                                }
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </td>
                                        <td >
                                            {
                                                showData && showData.web_Pages.pages.length > 0 ?
                                                    <div onClick={() => { setSelectedPage(showData) }}>
                                                        <div
                                                            className="my-1 p-1 d-flex align-items-center justify-content-start"
                                                            style={{ fontSize: "12px", fontWeight: "600", backgroundColor: "#E4E4E4" }}
                                                        >
                                                            <div onClick={() => { toggle2() }}>
                                                                <i
                                                                    style={{
                                                                        color: "black",
                                                                        marginRight: "0.5rem"
                                                                    }}
                                                                    id={`${isOpen2 && (selectedPage && selectedPage._id == showData._id) ? "minus" : "plus"}`}
                                                                    className={`${isOpen2 && (selectedPage && selectedPage._id == showData._id) ? "fa fa-minus" : "fa fa-plus"}`}>
                                                                </i>
                                                            </div>

                                                            <input
                                                                type="checkbox"
                                                                id={`${showData._id}-pages`}
                                                                name={`${showData._id}-pages`}
                                                                ref={register}
                                                                onChange={(event) => {

                                                                    let getWebPageData = showData.web_Pages;
                                                                    let postData = { ...getWebPageData, enable: event.target.checked };

                                                                    let updateType = "businessTypePages";
                                                                    updateBusinessType(postData, updateType);
                                                                }}
                                                            />
                                                            <label className="mb-0 ml-2 text-capitalize" for={`${showData._id}-pages`}>{showData.web_Pages.name}</label>
                                                        </div>
                                                        {
                                                            selectedPage && selectedPage._id == showData._id ?
                                                                <Collapse isOpen={isOpen2} className="ml-4">
                                                                    {
                                                                        showData.web_Pages.pages.map((element) => {
                                                                            return (
                                                                                <>
                                                                                    <div
                                                                                        className="my-1 p-1 d-flex align-items-center justify-content-start"
                                                                                        style={{ fontSize: "12px", fontWeight: "600", backgroundColor: "#E4E4E4" }}
                                                                                    >
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            id={`${element.pageName}`}
                                                                                            name={`${element._id}-pageName`}
                                                                                            ref={register}
                                                                                            onChange={(event) => {

                                                                                                let getWebPageData = showData.web_Pages;
                                                                                                let newData = { ...element, enable: event.target.checked };
                                                                                                getWebPageData.pages.forEach((element, index) => {
                                                                                                    if (element._id == newData._id) {
                                                                                                        showData.web_Pages.pages[index] = newData;
                                                                                                    }
                                                                                                });
                                                                                                let updateType = "businessTypePages";
                                                                                                updateBusinessType(getWebPageData, updateType);
                                                                                            }}
                                                                                        />
                                                                                        <label className="mb-0 ml-2 text-capitalize" for={`${element.pageName}`}>{element.pageName}</label>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }

                                                                </Collapse> : null
                                                        }
                                                    </div> : "-"
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </form>
            </div>
        </Fragment>
    )
}

export default BusinessTypeProductPage