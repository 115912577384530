import React, { Component } from "react";
import moment from "moment-timezone";

import client from "../Api/HTTPClient";
import api from "../constant/apilist";
import { ToastContainer, toast } from "react-toastify";

class CustomerAdminNote extends Component {
    state = {
        customerId: this.props.customerId,
        customerInfo: this.props.customerInfo,
        note: null,
        access_level : localStorage.getItem("accessLevel"),
        adminUser : JSON.parse(localStorage.getItem("user"))
    }

    refreshPage() {
        window.location.reload(false);
      }

    render_date = (add_date) => {

        if (typeof (add_date) === "undefined") {
            return;
        }

        const convert_date = new Date(add_date);
        const added_date = moment.tz(convert_date, "Australia/Sydney").format("DD/MM/YYYY HH:mm");
        return added_date;
    }

    noteChangeHandler = (event) => {
        const note = event.target.value;
        this.setState(prevState => {
            return {
                ...prevState,
                note: note
            }
        })
    }

    noteSubmitHandler = (event) => {
        event.preventDefault();
        const postdata = { customer_id: this.state.customerId, comment: { type: "normal", note: this.state.note, user: this.state.adminUser.email} }
        client.put(api.update_customer, postdata, (error, response) => {
            if (!error) {
                if (!response.error) {
                    this.refreshPage();
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        })
    }

    render() {
        const { customerInfo, access_level } = this.state;
        //console.log(customerInfo);
        let comments=customerInfo.comment.map((v,i)=> customerInfo.comment[customerInfo.comment.length-(i+1)]);
        // let sortDateTimeWiseArray = customerInfo.comment.slice().sort((a,b) => {return new Date(this.render_date(b.date)) - new Date(this.render_date(a.date)); })
        // let sortedArray2 =[].concat(sortDateTimeWiseArray).reverse()
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-xl-12 xl-100">
                        <div className="card">
                            <div className="card-header" style={{padding:"20px"}}>
                                <h5>Comments</h5>
                            </div>
                            <div className="card-body" style={{padding:"20px"}}>
                                <div className="chat-box" style={{ paddingTop: "0", paddingBottom: "0" }}>
                                    <div className="chat-right-aside bitcoin-chat">
                                        <div className="chat">
                                            <div className="chat-history chat-msg-box custom-scrollbar" style={{padding:"0",margin:"0"}} >
                                                <ul style={{ margin: "0" }}>
                                                    {customerInfo.comment.length != 0 ? comments.map((el,i) => {
                                                        return (<li key={`comment-${i}`} style={{ margin: "0" }}>
                                                            <div className="message my-message " style={{ marginBottom: "10px" }}>
                                                                <h6>{el.user}<span className="message-data-time pull-right">{this.render_date(el.date)}</span> </h6>
                                                                {el.type == "normal" ? null : <h6>Type : {el.type}</h6>}
                                                                {el.note}
                                                            </div>
                                                        </li>)
                                                    }) : <p>No comment added!</p>}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {access_level != 1 && <div className="card-footer" style={{padding:"20px"}}>
                                <form className="theme-form" >
                                    <div className="form-group">
                                        <label >Comment:</label>
                                        <textarea className="form-control" required rows="3" onChange={this.noteChangeHandler}></textarea>
                                    </div>
                                    <div style={{ padding: "15px 0 0 15px" }}>
                                        <button className="btn btn-primary btn-xs pull-right" type="submit" name="comment"
                                            onClick={(event) => this.noteSubmitHandler(event)}
                                        >Save</button>
                                    </div>
                                </form>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default CustomerAdminNote;