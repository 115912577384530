import React, { Fragment, useEffect, useRef, useState } from "react"
import Breadcrumb from "../../components/common/breadcrumb"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import client from "../../../src/Api/HTTPClient";
import api from "../../../src/constant/apilist";
import "./merchantPortal.css";
import Compressor from 'compressorjs';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Button, Modal, ModalBody } from 'reactstrap';

const ImageCroperModal = (props) => {
    console.log("props",props)
    const [zoomValue, setZoomValue] = useState(0);
    const [cropper, setCropper] = useState();

    const cropperRef = useRef(null);

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            const canvas = cropper.getCroppedCanvas();
            const dataUrl = canvas.toDataURL('image/png', 0.5)
            fetch(dataUrl)
                .then(res => res.blob())
                .then(blob => {
                    props.setCroppedImgData({
                        ...props.imageData,
                        imageData: blob,
                        imageResult: cropper?.getCroppedCanvas().toDataURL(),
                    });
                    // props.setSectionChange((prev)=>({
                    //     ...prev,
                    //     imgSrc:cropper?.getCroppedCanvas().toDataURL()
                    // }))
                    props.setOpenModal(false);
                })
        }
    };


    const onChange = (data) => {
        setZoomValue(data / 10)
    }

    return (
        <Modal isOpen={props.openModal} centered>
            <ModalBody className="p-0 m-0">
                <div className='p-1 bg-white rounded'>
                    <div>
                        <Cropper
                            src={props.imageData?.imageResult}
                            style={{ height: '300px', width: "100%" }}
                            aspectRatio={props.aspectRatio ? props.aspectRatio : 3 / 3}
                            zoomTo={zoomValue}
                            zoomable={true}
                            zoomOnWheel={false}
                            zoomOnTouch={true}
                            wheelZoomRatio={0.1}
                            guides={false}
                            toggleDragModeOnDblclick={false}
                            ref={cropperRef}
                            dragMode='move'
                            cropBoxMovable={false}
                            cropBoxResizable={false}
                            preview=".img-preview"
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                        />
                    </div>

                    <div className='py-3 px-2 px-sm-4'>
                        <Slider
                            min={2}
                            max={20}
                            className='m-0'
                            defaultValue={zoomValue}
                            onChange={onChange}
                            trackStyle={{ backgroundColor: '#24424C' }}
                            railStyle={{ backgroundColor: '#ABE2FB' }}
                        />

                        <div className='mt-3'>
                            <div className='d-flex align-items-center'>
                                <div
                                    className="img-preview border rounded bg-light shadow-sm"
                                    style={{ width: "150px", height: "150px", overflow: 'hidden' }}
                                />

                                <div className='d-flex flex-column mx-auto align-items-start'>
                                    <h3 className='m-0'> Preview </h3>
                                    <Button
                                        className='rn-button-style--2 btn-solid my-2'
                                        onClick={getCropData}
                                    > Crop Image </Button>
                                    <Button
                                        onClick={() => {
                                            props.setOpenModal(false);
                                        }}
                                    > Cancel </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}

const MerchantPortal = ()=>{
    const [advertise,setAdvertise] = useState({
        image:null,url:null,imgSrc:null
    })
    const [advertiseM,setAdvertiseM] = useState({
        image:null,url:null,imgSrc:null
    })
    const fileInputRef = useRef(null)
    const fileInputRefM = useRef(null)
    const [openModal,setOpenModal] = useState(false)
    const [croppedImage,setCroppedImage] = useState()
    const [imageData, setImageData] = useState()
    const [section,setSection] = useState("")
    // console.log(imageData,"imageData")

    if(croppedImage){
        // console.log(croppedImage,"image")
    }

    useEffect(()=>{
        if(section=="section1"){
            setSection1((prev) => ({
                ...prev,
                imgSrc:croppedImage?.imageResult
            }))
        }else if(section=="section2"){
            setSection2((prev) => ({
                ...prev,
                imgSrc:croppedImage?.imageResult
            }))
        }else if(section=="section3"){
            setSection3((prev) => ({
                ...prev,
                imgSrc:croppedImage?.imageResult
            }))
        }else if(section=="section4"){
            setSection4((prev) => ({
                ...prev,
                imgSrc:croppedImage?.imageResult
            }))
        }
    }, [croppedImage])

    useEffect(()=>{
        fetchAdvertise()
    },[])

    const handleAdvertise = (e)=>{
        if(e.target.name=='image'){
                new Compressor(e.target.files[0],{
                    quality: 0.7,
                    maxWidth: 1920,
                    maxHeight: 1920,
                    convertSize: 500000,
                    async success(result){
                        const toBase64 = result => new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.readAsDataURL(result);
                            reader.onload = () => resolve(reader.result);
                            reader.onerror = reject;
                        });
                        const base64Image =  await toBase64(result)
                        // console.log("base64", base64Image)
                        setAdvertise(prev => ({
                            ...prev,
                            imgSrc:base64Image
                        }))
                                            },
                    error(err){
                        console.log(err.message)
                    }
                })
        }else{
            setAdvertise(prev => ({
                ...prev,
                [e.target.name]:e.target.value
            }))
        }
        
        // console.log(advertise)
    }
    const handleAdvertiseM = (e)=>{
        if(e.target.name=='image'){
                new Compressor(e.target.files[0],{
                    quality: 0.7,
                    maxWidth: 1920,
                    maxHeight: 1920,
                    convertSize: 500000,
                    async success(result){
                        const toBase64 = result => new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.readAsDataURL(result);
                            reader.onload = () => resolve(reader.result);
                            reader.onerror = reject;
                        });
                        const base64Image =  await toBase64(result)
                        // console.log("base64", base64Image)
                        setAdvertiseM(prev => ({
                            ...prev,
                            imgSrc:base64Image
                        }))
                    },
                    error(err){
                        console.log(err.message)
                    }
                })
        }else{
            setAdvertiseM(prev => ({
                ...prev,
                [e.target.name]:e.target.value
            }))
        }
        
        // console.log(advertiseM)
    }
    const handleSubmitAdvertise = ()=>{
        if(advertise.imgSrc==null||advertise.url==null){
            if(!advertise.image){
                toast.error('Image(desktop) is required!')
            }else{
                toast.error("URL is required!")
            }
        }else{
            const postData = {
                image:advertise.imgSrc,
                url:advertise.url
            }
            client.post(api.saveMerchantAdvertise,postData,(error,response)=>{
                if(!error){
                    if(!response.error){
                        toast.success(response.message)
                    }else{
                        toast.error(response.message)
                    }
                }else{
                    toast.error(`There's some error while saving data`)
                }
            })
        }
    }
    const handleSubmitAdvertiseM = ()=>{
        if(advertiseM.imgSrc==null||advertiseM.url==null){
            if(!advertiseM.imgSrc){
                toast.error('Image(for mobile) is required!')
            }else{
                toast.error("URL is required!")
            }
        }else{
            const postData = {
                image:advertiseM.imgSrc,
                url:advertiseM.url
            }
            client.post(api.saveMerchantAdvertiseM,postData,(error,response)=>{
                if(!error){
                    if(!response.error){
                        toast.success(response.message)
                        fetchAdvertise()
                    }else{
                        toast.error(response.message)
                    }
                }else{
                    toast.error(`There's some error while saving data`)
                }
            })
        }
    }

    const [section1,setSection1] = useState({
        image:null,feature:null,description:null,url:null,imgSrc:null
    })
    const [section2,setSection2] = useState({
        image:null,feature:null,description:null,url:null,imgSrc:null
    })
    const [section3,setSection3] = useState({
        image:null,feature:null,description:null,url:null,imgSrc:null
    })
    const [section4,setSection4] = useState({
        image:null,feature:null,description:null,url:null,imgSrc:null
    })
    
    const imageRef1 = useRef(null)
    const imageRef2 = useRef(null)
    const imageRef3 = useRef(null)
    const imageRef4 = useRef(null)

    const handleURL = (e)=>{
        const URL = e.target.value
        const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')  
        if(!regex.test(URL)){
            toast.error('Enter valid URL!')
        }
    }

    
    const handleSection1 = (e)=>{
        if(e.target.name=='image'){
                new Compressor(e.target.files[0],{
                    quality: 0.7,
                    maxWidth: 1920,
                    maxHeight: 1920,
                    convertSize: 500000,
                    async success(result){
                        const toBase64 = result => new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.readAsDataURL(result);
                            reader.onload = () => resolve(reader.result);
                            reader.onerror = reject;
                        });
                        const base64Image =  await toBase64(result)
                        // console.log("base64", base64Image)
                        let img_data = {imageData:base64Image.split(",")[1], imageResult:base64Image}
                        setImageData(img_data)
                        setOpenModal(true)
                        setSection("section1")
                        // setSection1(prev => ({
                        //     ...prev,
                        //     imgSrc:base64Image
                        // }))
                    },
                    error(err){
                        console.log(err.message)
                    }
                })
        }else{
            setSection1(prev => ({
                ...prev,
                [e.target.name]:e.target.value
            }))
        }
        // console.log(section1)
    }
    const handleSection2 = (e)=>{
        if(e.target.name=='image'){
                new Compressor(e.target.files[0],{
                    quality: 0.7,
                    maxWidth: 1920,
                    maxHeight: 1920,
                    convertSize: 500000,
                    async success(result){
                        const toBase64 = result => new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.readAsDataURL(result);
                            reader.onload = () => resolve(reader.result);
                            reader.onerror = reject;
                        });
                        const base64Image =  await toBase64(result)
                        // console.log("base64", base64Image)
                        let img_data = {imageData:base64Image.split(",")[1], imageResult:base64Image}
                        setImageData(img_data)
                        setOpenModal(true)
                        setSection("section2")
                        // setSection2(prev => ({
                        //     ...prev,
                        //     imgSrc:base64Image
                        // }))
                    },
                    error(err){
                        console.log(err.message)
                    }
                })
        }else{
            setSection2(prev => ({
                ...prev,
                [e.target.name]:e.target.value
            }))
        }
        // console.log(section2)
    }
    const handleSection3 = (e)=>{
        if(e.target.name=='image'){
                new Compressor(e.target.files[0],{
                    quality: 0.7,
                    maxWidth: 1920,
                    maxHeight: 1920,
                    convertSize: 500000,
                    async success(result){
                        const toBase64 = result => new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.readAsDataURL(result);
                            reader.onload = () => resolve(reader.result);
                            reader.onerror = reject;
                        });
                        const base64Image =  await toBase64(result)
                        // console.log("base64", base64Image)
                        let img_data = {imageData:base64Image.split(",")[1], imageResult:base64Image}
                        setImageData(img_data)
                        setOpenModal(true)
                        setSection("section3")
                        // setSection3(prev => ({
                        //     ...prev,
                        //     imgSrc:base64Image
                        // }))
                    },
                    error(err){
                        console.log(err.message)
                    }
                })
        }else{
            setSection3(prev => ({
                ...prev,
                [e.target.name]:e.target.value
            }))
        }
        // console.log(section3)
    }
    const handleSection4 = (e)=>{
        if(e.target.name=='image'){
                new Compressor(e.target.files[0],{
                    quality: 0.7,
                    maxWidth: 1920,
                    maxHeight: 1920,
                    convertSize: 500000,
                    async success(result){
                        const toBase64 = result => new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.readAsDataURL(result);
                            reader.onload = () => resolve(reader.result);
                            reader.onerror = reject;
                        });
                        const base64Image =  await toBase64(result)
                        // console.log("base64", base64Image)
                        let img_data = {imageData:base64Image.split(",")[1], imageResult:base64Image}
                        setImageData(img_data)
                        setOpenModal(true)
                        setSection("section4")
                        // setSection4(prev => ({
                        //     ...prev,
                        //     imgSrc:base64Image
                        // }))
                    },
                    error(err){
                        console.log(err.message)
                    }
                })
        }else{
            setSection4(prev => ({
                ...prev,
                [e.target.name]:e.target.value
            }))
        }
        // console.log(section4)
    }

    
    const handleSubmitSection1 = ()=>{
        if(section1.imgSrc==null){
            toast.error("Image is required!")
        }else if(section1.feature==null){
            toast.error('Feature is required!')
        }else if(section1.description==null){
            toast.error("Description is required!")
        }else if(section1.url==null){
            toast.error("URL is required!")
        }else{
            const postData = {
                image:section1.imgSrc,
                feature:section1.feature,
                description:section1.description,
                url:section1.url
            }
            client.post(api.saveMerchantSection1,postData,(error,response)=>{
                if(!error){
                    if(!response.error){
                        toast.success(response.message)
                    }else{
                        toast.error(response.message)
                    }
                }else{
                    toast.error(`There's some error while saving data`)
                }
            })
        }
        // console.log(postData)
    }
    const handleSubmitSection2 = ()=>{
        if(section2.imgSrc==null){
            toast.error("Image is required!")
        }else if(section2.feature==null){
            toast.error('Feature is required!')
        }else if(section2.description==null){
            toast.error("Description is required!")
        }else if(section2.url==null){
            toast.error("URL is required!")
        }else{
            const postData = {
                image:section2.imgSrc,
                feature:section2.feature,
                description:section2.description,
                url:section2.url
            }
            client.post(api.saveMerchantSection2,postData,(error,response)=>{
                if(!error){
                    if(!response.error){
                        toast.success(response.message)
                    }else{
                        toast.error(response.message)
                    }
                }else{
                    toast.error(`There's some error while saving data`)
                }
            })
        }
        // console.log(postData)
    }
    const handleSubmitSection3 = ()=>{
        if(section3.imgSrc==null){
            toast.error("Image is required!")
        }else if(section3.feature==null){
            toast.error('Feature is required!')
        }else if(section3.description==null){
            toast.error("Description is required!")
        }else if(section3.url==null){
            toast.error("URL is required!")
        }else{
            const postData = {
                image:section3.imgSrc,
                feature:section3.feature,
                description:section3.description,
                url:section3.url
            }
            client.post(api.saveMerchantSection3,postData,(error,response)=>{
                if(!error){
                    if(!response.error){
                        toast.success(response.message)
                    }else{
                        toast.error(response.message)
                    }
                }else{
                    toast.error(`There's some error while saving data`)
                }
            })
        }
        // console.log(postData)
    }
    const handleSubmitSection4 = ()=>{
        if(section4.imgSrc==null){
            toast.error("Image is required!")
        }else if(section4.feature==null){
            toast.error('Feature is required!')
        }else if(section4.description==null){
            toast.error("Description is required!")
        }else if(section4.url==null){
            toast.error("URL is required!")
        }else{
            const postData = {
                image:section4.imgSrc,
                feature:section4.feature,
                description:section4.description,
                url:section4.url
            }
            client.post(api.saveMerchantSection4,postData,(error,response)=>{
                if(!error){
                    if(!response.error){
                        toast.success(response.message)
                    }else{
                        toast.error(response.message)
                    }
                }else{
                    toast.error(`There's some error while saving data`)
                }
            })
        } 
        // console.log(postData)
    }
    
    const fetchAdvertise = ()=>{
        let timeStamp = Date.now();
        client.post(api.getMerchantDashboard,{},(error,response)=>{
            if(!error){
                if(!response.error){
                    let adData = response.data[0].advertise?response.data[0].advertise:null
                    let adMData = response.data[0].advertiseM?response.data[0].advertiseM:null
                    let sec1Data = response.data[0].whats_new1?response.data[0].whats_new1:null
                    let sec2Data = response.data[0].whats_new2?response.data[0].whats_new2:null
                    let sec3Data = response.data[0].whats_new3?response.data[0].whats_new3:null
                    let sec4Data = response.data[0].whats_new4?response.data[0].whats_new4:null

                    adData?setAdvertise(prev=>({
                        ...prev,
                        url:adData.url,
                        imgSrc: adData.image.includes("dropbox") ? adData.image : client.url(`images/merchant-dashboard/${adData.image}?${timeStamp}`)
                        // imgSrc:`http://localhost:5222/images/merchant-dashboard/${advertise.image}`
                    })):setAdvertise(prev=>({...prev}))
                    

                    adMData?setAdvertiseM(prev=>({
                        ...prev,
                        url:adMData.url,
                        // imgSrc:`http://localhost:5222/images/merchant-dashboard/${adMData.image}`,
                        imgSrc: adMData.image.includes("dropbox")?adMData.image:client.url(`images/merchant-dashboard/${adMData.image}?${timeStamp}`)
                    })):setAdvertiseM(prev=>({...prev}))

                    sec1Data?
                    setSection1({
                        feature:sec1Data.feature,
                        description:sec1Data.description,
                        url:sec1Data.url,
                        // imgSrc:`http://localhost:5222/images/merchant-dashboard/${sec1Data.image}`,
                        imgSrc: sec1Data.image.includes("dropbox")?sec1Data.image:client.url(`images/merchant-dashboard/${sec1Data.image}?${timeStamp}`)
                    }):setSection1(prev=>({...prev}))

                    sec2Data?
                    setSection2({
                        feature:sec2Data.feature,
                        description:sec2Data.description,
                        url:sec2Data.url,
                        // imgSrc:`http://localhost:5222/images/merchant-dashboard/${sec2Data.image}`,
                        imgSrc: sec2Data.image.includes("dropbox")?sec2Data.image:client.url(`images/merchant-dashboard/${sec2Data.image}?${timeStamp}`)
                    }):setSection2(prev=>({...prev}))

                    sec3Data?
                    setSection3(prev=>({
                        ...prev,
                        feature:sec3Data.feature,
                        description:sec3Data.description,
                        url:sec3Data.url,
                        // imgSrc:`http://localhost:5222/images/merchant-dashboard/${sec3Data.image}`,
                        imgSrc: sec3Data.image.includes("dropbox")?sec3Data.image:client.url(`images/merchant-dashboard/${sec3Data.image}?${timeStamp}`)
                    })):setSection3(prev=>({...prev}))
                    
                    sec4Data?
                    setSection4(prev=>({
                        ...prev,
                        feature:sec4Data.feature,
                        description:sec4Data.description,
                        url:sec4Data.url,
                        // imgSrc:`http://localhost:5222/images/merchant-dashboard/${sec4Data.image}`,
                        imgSrc: sec4Data.image.includes("dropbox")?sec4Data.image:client.url(`images/merchant-dashboard/${sec4Data.image}?${timeStamp}`)
                    })):setSection4(prev=>({...prev}))
                }
            }
        })
    }
    
    return (
        <Fragment>
            {openModal == true ?
                <ImageCroperModal 
                    imageData={imageData}
                    setCroppedImgData={setCroppedImage}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    productName={'test'}
                    aspectRatio={40 / 40}
                />:null}
        <div className="col-lg-7 col-sm-12" style={{fontSize:"12px"}}>
            <Breadcrumb title={"Merchant Dashboard"} parent={"settings"} />
        </div>
        <div className="d-flex">
        <div className="d-flex flex-column m-1 rounded" style={{width:'37%'}}>
        <div className="bg-white p-4 d-flex flex-column rounded" style={{width: '100%', height:'80%'}}>
            <div className="mb-1" style={{fontWeight:'bolder', marginLeft:'10px'}}>Advertisement Section (for Desktop)</div>
            <div 
                className="border m-1 rounded d-flex justify-content-center align-items-center"
                onClick={()=>fileInputRef.current.click()}
            >
                {(advertise.imgSrc&& <img src ={advertise.imgSrc} width = "100%" height="100%"/>)||
                <div 
                    className="border m-1 rounded d-flex justify-content-center align-items-center flex-column"
                    style={{width:'100%', height:'12rem', background:'#eeeeee'}}
                >
                    <div className="bg-secondary-subtle d-flex flex-column align-items-center" style={{color:'#c3bfc1',fontWeight:'700'}}>
                        <p style={{marginBottom:'-5px'}}>Image</p>
                        <p>800px X 300px</p>
                    </div>
                </div>}
                <input 
                    name="image"
                    value={advertise.image}
                    type="file" 
                    accept="image/*" 
                    ref={fileInputRef}
                    onChange={event => handleAdvertise(event)}
                    hidden
                />
            </div>
            <div className="d-flex flex-column align-items-start">
            <input 
                name="url"
                value={advertise.url}
                type="text" 
                className="mt-4 p-1 rounded form-control" 
                placeholder="Add URL"
                style={{width:'60%',borderColor:'#ced4da',padding:'0.375rem 0.75rem'}}
                onChange={event => handleAdvertise(event)}
                onBlur={(event)=>handleURL(event)}
            />
            <button className="btn btn-primary mt-4" onClick={handleSubmitAdvertise}>Save</button>
            </div>
        </div>
        <div className="bg-white mt-1 p-4 d-flex flex-column rounded" style={{width: '100%', height:'80%'}}>
            <div className="mb-1" style={{fontWeight:'bolder', marginLeft:'10px'}}>Advertisement Section (for Mobile)</div>
            <div 
                className="border m-1 rounded d-flex justify-content-center align-items-center"
                onClick={()=>fileInputRefM.current.click()}
            >
                {(advertiseM.imgSrc&& <img src ={advertiseM.imgSrc} width = "100%" height="100%"/>)||
                <div 
                    className="border m-1 rounded d-flex justify-content-center align-items-center flex-column"
                    style={{width:'100%', height:'12rem', background:'#eeeeee'}}
                >
                    <div className="bg-secondary-subtle d-flex flex-column align-items-center" style={{color:'#c3bfc1',fontWeight:'700'}}>
                        <p style={{marginBottom:'-5px'}}>Image</p>
                        <p>800px X 300px</p>
                    </div>
                </div>}
                <input 
                    name="image"
                    value={advertiseM.image}
                    type="file" 
                    accept="image/*" 
                    ref={fileInputRefM}
                    onChange={event => handleAdvertiseM(event)}
                    hidden
                />
            </div>
            <div className="d-flex flex-column align-items-start">
            <input 
                name="url"
                value={advertiseM.url}
                type="text" 
                className="mt-4 p-1 rounded form-control" 
                placeholder="Add URL"
                style={{width:'60%',borderColor:'#ced4da',padding:'0.375rem 0.75rem'}}
                onChange={event => handleAdvertiseM(event)}
                onBlur={(event)=>handleURL(event)}
            />
            <button className="btn btn-primary mt-4" onClick={handleSubmitAdvertiseM}>Save</button>
            </div>
        </div>
        </div>
        <div className="bg-white m-1 p-4 d-flex flex-column rounded" style={{width:'63%',gap:'20px',height:'100%'}}>
            <div className="mb-1" style={{fontWeight:'bolder', marginLeft:'10px'}}>What's New Section</div>
            <div className="mb-1 d-flex" style={{gap:'10px'}}>
                <div onClick={()=>imageRef1.current.click()}>
                {(section1.imgSrc && <img src ={section1.imgSrc} width = "70px" height="70px" style={{borderRadius:'100%'}}/>)||<div 
                    style={{
                        borderRadius:'100%',
                        // padding:'10px', 
                        background:'#eeeeee',
                        textAlign:'-webkit-center',
                        lineHeight:'1',
                        color:'#c3bfc1',
                        fontWeight:'700',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        height:'80px',
                        width:'80px'
                    }}
                    
                    >
                    {/* <p style={{
                        marginBottom: '0px',
                        lineHeight: '10px',
                        marginTop: '10px'
                    }}>Feature Image</p>  */}
                    <p style={{
                        fontSize:'8px', 
                        margin:'4px 2px 0px',
                    }}>100px X 100px</p>
                </div>}
                <input 
                    name="image"
                    value={section1.image}
                    type="file" 
                    accept="image/*" 
                    ref={imageRef1}
                    onChange={event => handleSection1(event)}
                    hidden
                />
                </div>
                <div className="d-flex flex-wrap justify-content-start" style={{gap:'5px'}}>
                    <input 
                        maxLength={25}
                        className="rounded form-control"
                        type="text" 
                        placeholder="Feature/service name"
                        name="feature"
                        value={section1.feature}
                        style={{width:'47%',borderColor:'#ced4da',padding:'0.375rem 0.75rem'}}
                        onChange={event => handleSection1(event)}
                    />
                    <input 
                        className="rounded form-control"
                        type="text" 
                        placeholder="Add URL"
                        name="url"
                        value={section1.url}
                        style={{width:'47%',borderColor:'#ced4da',padding:'0.375rem 0.75rem'}}
                        onChange={event => handleSection1(event)}
                        onBlur={(event)=>handleURL(event)}
                    />
                    <input 
                        maxLength={80}
                        className="rounded form-control"
                        type="text" 
                        placeholder="Short Description"
                        name="description"
                        value={section1.description}
                        style={{width:'47%',borderColor:'#ced4da',padding:'0.375rem 0.75rem'}}
                        onChange={event => handleSection1(event)}
                    />
                </div>
                <div><button className="btn btn-primary mt-4" onClick={handleSubmitSection1}>Save</button></div>
            </div>
            <div className="mb-1 d-flex" style={{gap:'10px'}}>
                <div onClick={()=>imageRef2.current.click()}>
                {(section2.imgSrc && 
                    <img src ={section2.imgSrc} 
                        width = "70px" 
                        height="70px"
                        style={{
                            borderRadius:'100%',
                        }}/>)||
                    <div 
                    style={{
                        borderRadius:'100%',
                        // padding:'10px', 
                        background:'#eeeeee',
                        textAlign:'-webkit-center',
                        lineHeight:'1',
                        color:'#c3bfc1',
                        fontWeight:'700',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        height:'80px',
                        width:'80px'
                    }}
                    
                    >
                    {/* <p style={{
                        marginBottom: '0px',
                        lineHeight: '10px',
                        marginTop: '10px'
                    }}>Feature Image</p>  */}
                    <p style={{
                        fontSize:'8px', 
                        margin:'4px 2px 0px',
                    }}>100px X 100px</p>
                </div>}
                    <input 
                    name="image"
                    value={section2.image}
                    type="file" 
                    accept="image/*" 
                    ref={imageRef2}
                    onChange={event => handleSection2(event)}
                    hidden
                />
                </div>
                
                <div className="d-flex flex-wrap justify-content-start" style={{gap:'5px'}}>
                    <input 
                        maxLength={25}
                        className="rounded form-control"
                        type="text" 
                        placeholder="Feature/service name"
                        name="feature"
                        value={section2.feature}
                        style={{width:'47%',borderColor:'#ced4da',padding:'0.375rem 0.75rem'}}
                        onChange={event => handleSection2(event)}
                    />
                    <input 
                        className="rounded form-control"
                        type="text" 
                        placeholder="Add URL"
                        name="url"
                        value={section2.url}
                        style={{width:'47%',borderColor:'#ced4da',padding:'0.375rem 0.75rem'}}
                        onChange={event => handleSection2(event)}
                        onBlur={(event)=>handleURL(event)}
                    />
                    <input 
                        maxLength={80}
                        className="rounded form-control"
                        type="text" 
                        placeholder="Short Description"
                        name="description"
                        value={section2.description}
                        style={{width:'47%',borderColor:'#ced4da',padding:'0.375rem 0.75rem'}}
                        onChange={event => handleSection2(event)}
                    />
                </div>
                <div><button className="btn btn-primary mt-4" onClick={handleSubmitSection2}>Save</button></div>
            </div>
            <div className="mb-1 d-flex" style={{gap:'10px'}}>
                <div onClick={()=>imageRef3.current.click()} >
                {(section3.imgSrc && 
                    <img 
                        src ={section3.imgSrc} 
                        width = "70px" 
                        height="70px"
                        style={{
                            borderRadius:'100%',
                        }}
                        />)||
                    <div 
                    style={{
                        borderRadius:'100%',
                        // padding:'10px', 
                        background:'#eeeeee',
                        textAlign:'-webkit-center',
                        lineHeight:'1',
                        color:'#c3bfc1',
                        fontWeight:'700',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        height:'80px',
                        width:'80px'
                    }}
                    
                    >
                    {/* <p style={{
                        marginBottom: '0px',
                        lineHeight: '10px',
                        marginTop: '10px'
                    }}>Feature Image</p>  */}
                    <p style={{
                        fontSize:'8px', 
                        margin:'4px 2px 0px',
                    }}>100px X 100px</p></div>}
                    <input 
                    name="image"
                    value={section3.image}
                    type="file" 
                    accept="image/*" 
                    ref={imageRef3}
                    onChange={event => handleSection3(event)}
                    hidden
                />
                </div>
                
                <div className="d-flex flex-wrap justify-content-start" style={{gap:'5px'}}>
                    <input 
                        maxLength={25}
                        className="rounded form-control"
                        type="text" 
                        placeholder="Feature/service name"
                        name="feature"
                        value={section3.feature}
                        style={{width:'47%',borderColor:'#ced4da',padding:'0.375rem 0.75rem'}}
                        onChange={event => handleSection3(event)}
                    />
                    <input 
                        className="rounded form-control"
                        type="text" 
                        placeholder="Add URL"
                        name="url"
                        value={section3.url}
                        style={{width:'47%',borderColor:'#ced4da',padding:'0.375rem 0.75rem'}}
                        onChange={event => handleSection3(event)}
                        onBlur={(event)=>handleURL(event)}
                    />
                    <input 
                        maxLength={80}
                        className="rounded form-control"
                        type="text" 
                        placeholder="Short Description"
                        name="description"
                        value={section3.description}
                        style={{width:'47%',borderColor:'#ced4da',padding:'0.375rem 0.75rem'}}
                        onChange={event => handleSection3(event)}
                    />
                </div>
                <div><button className="btn btn-primary mt-4" onClick={handleSubmitSection3}>Save</button></div>
            </div>
            <div className="mb-1 d-flex" style={{gap:'10px'}}>
                <div onClick={()=>imageRef4.current.click()}>
                {(section4.imgSrc && 
                    <img 
                        src ={section4.imgSrc} 
                        width = "70px" 
                        height="70px"
                        style={{
                            borderRadius:'100%',
                        }}
                    />)||
                    <div 
                    style={{
                        borderRadius:'100%',
                        // padding:'10px', 
                        background:'#eeeeee',
                        textAlign:'-webkit-center',
                        lineHeight:'1',
                        color:'#c3bfc1',
                        fontWeight:'700',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        height:'80px',
                        width:'80px'
                    }}
                    
                    >
                    {/* <p style={{
                        marginBottom: '0px',
                        lineHeight: '10px',
                        marginTop: '10px'
                    }}>Feature Image</p>  */}
                    <p style={{
                        fontSize:'8px', 
                        margin:'4px 2px 0px'
                    }}>100px X 100px</p></div>}
                    <input 
                    name="image"
                    value={section4.image}
                    type="file" 
                    accept="image/*" 
                    ref={imageRef4}
                    onChange={event => handleSection4(event)}
                    hidden
                />
                </div>
                
                <div className="d-flex flex-wrap justify-content-start" style={{gap:'5px'}}>
                    <input 
                        maxLength={25}
                        className="rounded form-control"
                        type="text" 
                        placeholder="Feature/service name"
                        name="feature"
                        value={section4.feature}
                        style={{width:'47%',borderColor:'#ced4da',padding:'0.375rem 0.75rem'}}
                        onChange={event => handleSection4(event)}
                    />
                    <input 
                        className="rounded form-control"
                        type="text" 
                        placeholder="Add URL"
                        name="url"
                        value={section4.url}
                        style={{width:'47%',borderColor:'#ced4da',padding:'0.375rem 0.75rem'}}
                        onChange={event => handleSection4(event)}
                        onBlur={(event)=>handleURL(event)}
                    />
                    <input 
                        maxLength={80}
                        className="rounded form-control"
                        type="text" 
                        placeholder="Short Description"
                        name="description"
                        value={section4.description}
                        style={{width:'47%',borderColor:'#ced4da',padding:'0.375rem 0.75rem'}}
                        onChange={event => handleSection4(event)}
                    />
                </div>
                <div><button className="btn btn-primary mt-4" onClick={handleSubmitSection4}>Save</button></div>
            </div>
        </div>
        </div>

        {/* <div onClick={()=>fileInputRef.current.click()}>
        {(advertiseFile&&<img src = {advertiseImgSrc} width = "100" />)||"image"}
        
        </div> */}
        {/* <input 
          type="file" 
          accept="image/*" 
          ref={fileInputRef}
          onChange={event => fileChange(event)}
          hidden
        /> */}

        </Fragment>
    )
}

export default MerchantPortal