import React, { Fragment, useEffect, useState } from 'react';
import { ShoppingBag, Download, AlertCircle, Circle } from 'react-feather';
import { toast, ToastContainer } from "react-toastify";
import moment from "moment-timezone";
import { connect } from "react-redux";

import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";

const Notification = props => {
    /* const [notificationsData, setNotificationsData] = useState([]); */

    useEffect(() => {
        //getAllNotifications();

    }, [])

    const getAllNotifications = () => {
        let postdata = {};

        client.post(api.get_all_notifications, postdata, (error, response) => {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            // console.log(response);
                           // setNotificationsData(response.result.data)
                        }
                        else {
                            setTimeout(() => {
                                toast.error(response.message);
                            }, 200);
                        }
                    } catch (e) {
                        setTimeout(() => {
                            toast.error("Internal error occured. Please contact support");
                        }, 200);
                    }
                } else {
                    setTimeout(() => {
                        toast.error("Internal error occured. Please contact support");
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        });
    };

    return (
        <Fragment>
            <div>
                
                    <ul className="notification-dropdown onhover-show-div p-0">
                        <li>Notifications 
                            {/* <span className="badge badge-pill badge-primary pull-right">3</span> */}
                            </li>
                        {props.notificationsData.length > 0 ? props.notificationsData.slice(0,3).map(el => {
                            return (
                                <li key={el._id}>
                                    <div className="media">
                                        <div className="media-body">
                                            {/* <h6>{moment.tz(el.createdAt,"Australia/Sydney").format("DD/MM/YYYY HH:mm")}</h6> */}
                                            <h6 className="mt-0" style={{color: '#000'}}><span className="mr-2" style={{fontSize:"0.6rem"}}><i className="fa fa-circle"></i></span>{el.title}</h6>
                                            <p className="mb-0">{el.description}<strong style={{color:"#327ebc"}}>{moment(el.createdAt).fromNow()}</strong></p>
                                        </div>
                                    </div>
                                </li>
                            );
                        }) : <h6 style={{textAlign:"center"}}>No notifications!</h6>}
                        <li className="txt-dark"><a href={`${process.env.PUBLIC_URL}/notifications`}>All notifications</a></li>
                    </ul>
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) =>{
    return {
        notificationsData : state.header.notifications
    }
}

export default connect(mapStateToProps)(Notification);
