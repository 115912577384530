import React, { useState, useEffect, createRef } from 'react';
import Dropzone, { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import CropperModel from './RectCropperModel';
import { mdiPencil, mdiTrashCanOutline, mdiChartSankeyVariant, mdiCogOutline } from "@mdi/js"
import Icon from "@mdi/react";
import { Button } from 'reactstrap';
import api from "../../../../constant/apilist"
import client from "../../../../Api/HTTPClient"



const DropZoneField = (props) => {
  const dispatch = useDispatch();
  const [filec, setFile] = useState(null)
  const [resizedImage, setResizedImage] = useState(null)
  const dropzoneRef = createRef()

  const category = useSelector(state => state.category)
  const onDrop = (acceptedFiles, file) => {
    setFile(acceptedFiles[0])
  }

  const crModel = (croppedFile) => {

    var reader = new FileReader();
    reader.readAsDataURL(filec);
    var mimeType = filec.type;

    if (croppedFile) {
      reader.onload = (_event) => {
        let image_data;
        image_data = reader.result;

        image_data = croppedFile.split(",")[1];

        image_data = { mime: mimeType, data: image_data, imageName: props.name, prev_Image: props.imageData };

        let postData = {

          updateID: props.getAllData._id,
          portal_id: props.currentPortalData.brand_ID,
          logoName: props.logoName,
          currData_ID: props.currData._id,
          image_data: image_data,
          updateType: "Logo"
        };

        client.put(api.updateBusinessType, postData, async function (error, response) {
          if (!error) {
            if (typeof response.error !== "undefined") {
              try {
                if (!response.error) {
                  props.fetchBusinessTypeData();
                  toast.success(response.message, { autoClose: 2000, hideProgressBar: true });
                } else {
                  toast.error(response.message);
                }
              } catch (e) {
                toast.error("Internal error occured. Please contact support");
              }
            } else {
              toast.error("Internal error occured. Please contact support");
            }
          } else {
            toast.error("Internal error occured. Please contact support");
          }
        });

      };
    }
    setFile(null)

  }
  return (
    <div className="container border p-0 m-0 "
      style={{
        height: '2.8rem',
        width: '7rem',
        // borderColor: "silver",
        // border: "1px solid black"
      }}>
      <div className={`dropzone dropzone--single${false ? ' dropzone--custom-height' : ''} d-flex align-items-center justify-content-center`}
        style={{
          height: '100%',
          width: '100%',
          overflow: 'hidden',
        }}>
        <Dropzone
          ref={dropzoneRef}
          accept="image/jpeg, image/png"
          name={"file"}
          multiple={false}
          onDrop={(fileToUpload) => {
            onDrop(fileToUpload);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="dropzone__input d-flex align-items-center"
              style={
                {
                  minHeight: "unset"
                }
              }
            >
              {/* display profile */}

              {typeof props.imageData !== "undefined" && props.imageData != null || resizedImage ?

                <aside className="dropzone__img p-0" style={{ backgroundColor: 'gainsboro' }}>
                  {resizedImage ?
                    <img src={resizedImage} className="img-fluid p-0" alt="drop-img1" />
                    :
                    <img src={client.url(`images/logo-business-type/${props.imageData}?${Date.now()}`)} className="img-fluid p-0" alt="drop-img" />
                  }
                </aside>
                :
                <>
                  <div className="text-center d-flex align-items-center flex-column">
                    <p class="" style={{ color: "silver" }}>Image</p>
                  </div>
                  <br />

                </>

              }
              {/* take input */}
              <input {...getInputProps()} />
            </div>
          )}
        </Dropzone>

        <CropperModel file={filec}
          onConfirm={(croppedFile) => {

            if (croppedFile) {
              setResizedImage(croppedFile)
            }
          }}
          onCompleted={(croppedFile) => crModel(croppedFile)} />
      </div>
    </div>
  );
};


export default DropZoneField;
