import React, { Component, Fragment } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup, Col, Row, FormFeedback, CustomInput } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import { Link as NavLink } from "react-router-dom";
import moment from "moment-timezone";

import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";

class VersionSettings extends Component {

    state = {
        addVersionModal: false,
        appVersions: [],
        appVerisonInfo: null,
        access_level : localStorage.getItem("accessLevel")

    }

    componentDidMount() {
        this.get_all_appVersion();
    }

    get_all_appVersion = () => {
        const postdata = {};
        console.log(postdata);
        client.post(api.get_all_appVersion, postdata, (error, response) => {
            if (!error) {
                if (!response.error) {
                    console.log(response);
                    this.setState({ appVersions: response.result.data })
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);

                }

            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        });
    }

    render_date = (add_date) => {

        if (typeof (add_date) === "undefined") {
            return;
        }

        const convert_date = new Date(add_date);
        const added_date = moment.tz(convert_date, "Australia/Sydney").format("DD/MM/YYYY HH:mm");
        return added_date;
    }

    getAppType = (type) => {
        if (type == 0) {
            return "Android"
        } else {
            return "iOS"
        }
    }

    getVersionType =    (type) => {
        if (type == 0) {
            return "Optional Updates"
        } else {
            return "Force Updates"
        }
    }



    toggleAddVersionModal = () => {
        this.setState(prevState => {
            return {
                addVersionModal: !prevState.addVersionModal,
            }
        });
    }

    onAppVersionChange = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => {
            return {
                ...prevState,
                appVerisonInfo: {
                    ...prevState.appVerisonInfo,
                    [name]: value
                }
            }
        })
    }

    refreshPage() {
        window.location.reload(false);
    }

    onAppVersionSubmit = (event) => {
        event.preventDefault();
        const postdata = { ...this.state.appVerisonInfo };
        console.log(postdata);
        client.post(api.add_appVersion, postdata, (error, response) => {
            if (!error) {
                if (!response.error) {
                    this.refreshPage();
                    console.log(response);
                    toast.success(response.message);
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);

                }

            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        });
    }

    renderTable = () => {
        return (
            <div className="table-responsive">
                        <div className="btn-group pull-right" style={{ paddingBottom : "0.5rem",paddingTop:"0.5rem" }} role="group" aria-label="Basic example">
                            {this.state.access_level != 1 && <button className="btnAddNewVersion custBtnAdmin" onClick={this.toggleAddVersionModal}><i className="fa fa-plus-square-o" style={{ margin: "0 7px 0 0",color: "#4466f2" }} ></i> Add&nbsp;new&nbsp;version </button>}
                        </div>
                <table className="table table-border-horizontal">
                    <thead>
                        <tr style={{background: '#f9fafe'}}>
                            <th scope="col" style={{ width: "8%" }}>#</th>
                            <th scope="col" style={{ width: "16%" }} >App version</th>
                            <th scope="col" style={{ width: "16%" }}>App Type</th>
                            <th scope="col" style={{ width: "22%" }}>Url</th>
                            <th scope="col" style={{ width: "22%" }}>Version Type</th>
                            <th scope="col" style={{ width: "22%" }}>Whats New?</th>
                            <th scope="col" style={{ width: "8%" }}>
                                <p>Added Date</p>
                                <p>Time</p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.appVersions.length != 0 ? this.state.appVersions.map((item, index) => {
                            console.log("item",item)
                            return (
                                
                                <tr key={item._id}>
                                    <th scope=" row" style={{ width: "8%" }} > {index + 1}</th>
                                    <td style={{ width: "16%" }}>{item.app_version}</td>
                                    <td style={{ width: "16%" }}>{this.getAppType(item.app_type)}</td>
                                    <td style={{ width: "22%" }}>{item.url}</td>
                                    <td style={{ width: "22%" }}>{this.getVersionType(item.version_type)}</td>
                                    <td style={{ width: "22%" }}>{item.whatsnew}</td>
                                    <td style={{ width: "8%" }}>{this.render_date(item.createdAt)}</td>
                                </tr>
                            );
                        }).reverse() : null
                        // <div className="loader-box" >
                        //         <div className="loader" style={{ margin: "auto auto" }}>
                        //             <div className="line bg-primary"></div>
                        //             <div className="line bg-primary"></div>
                        //             <div className="line bg-primary"></div>
                        //             <div className="line bg-primary"></div>
                        //         </div>
                        //     </div>
                            }

                    </tbody>
                </table>
            </div>

        )
    }



    renderAddAppVersionModal = () => {
        return (
            <Modal isOpen={this.state.addVersionModal} toggle={this.toggleAddVersionModal}>
                <Form
                    onSubmit={this.onAppVersionSubmit}
                >
                    <ModalHeader toggle={this.props.toggleAddVersionModal}>Add New Version</ModalHeader>
                    <ModalBody>

                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="appVersion">App Verison</Label>
                                    <Input type="text" name="app_version" required
                                        placeholder="App Verison" onChange={this.onAppVersionChange} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="appType">App Type </Label>
                                    <Input type="select" name="app_type" required defaultValue="select" onChange={this.onAppVersionChange}>
                                        <option disabled value="select">Select Type</option>
                                        <option value="android">Android</option>
                                        <option value="ios">iOS</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="url">Url</Label>
                                    <Input type="text" name="url" required placeholder="Url" onChange={this.onAppVersionChange} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="versionType">Version Type</Label>
                                    <Input type="select" name="version_type" required defaultValue="select" onChange={this.onAppVersionChange}>
                                        <option disabled value="select">Select Type</option>
                                        <option value="optional">Optional Updates</option>
                                        <option value="force">Force Updates</option>
                                    </Input>
                                </FormGroup>
                            </Col>

                        </Row>
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="url">Whats New?</Label>
                                    <Input type="text" name="whatsnew" required placeholder="" onChange={this.onAppVersionChange} />
                                </FormGroup>
                            </Col>
                        </Row>

                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" onClick={this.toggleAddVersionModal}>Cancel</Button>
                        <Button color="primary" type="submit">Add</Button>

                    </ModalFooter>
                </Form>
            </Modal>
        );

    }
    toggleAddVersionModal = () => {
        this.setState(prevState => {
            return {
                addVersionModal: !prevState.addVersionModal,
            }
        });
    }
    
    render() {
        const { access_level } =this.state
        //console.log(this.state.appVerisonInfo);
        return (
            <Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-xl-12">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">

                                        <div className="card-body" style={{paddingTop:"0px"}}>
                                            <div>
                                                {/* {access_level != 1 && <button className="btn btn-outline-primary" onClick={this.toggleAddVersionModal} style={{ marginBottom: "15px" }}><i className="fa fa-plus" style={{ margin: "0px 7px" }}></i> Add New Version </button>} */}
                                                {this.renderTable()}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {this.renderAddAppVersionModal()}
                    </div>
                </div>
            </Fragment>
        );
    }
}


export default VersionSettings;