import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom"
import { useIdleTimer } from 'react-idle-timer';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup, Col, Row, FormFeedback, CustomInput } from 'reactstrap';
import { connect } from "react-redux";
import socketIOClient from "socket.io-client";
import { Notifications } from 'react-push-notification';
import addNotification from 'react-push-notification';
import "../components/dashboard/App.css";

import * as actionCreators from "../store/actions/index"
import Header from './common/header-component/header';
import Sidebar from './common/sidebar-component/sidebar';
import RightSidebar from './common/right-sidebar';
import Footer from './common/footer';
import ThemeCustomizer from './common/theme-customizer'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './common/loader';
import client from "../Api/HTTPClient";
import api from "../constant/apilist";
import notificationSound from "../assets/audio/my_sound.mp3";


const AppLayout = (props) => {
    const { children } = props;
    const [isTimeout, setIsTimeout] = useState(false);
    const [idleTimeoutModal, setIdleTimeoutModal] = useState(false);
    const [ipay_wallet_amt, setIpay_wallet_amt] = useState(0);
    const [speed_food_wallet_amt, setSpeed_food_wallet_amt] = useState(0);
    const [ipay_details, setIpay_details] = useState(null);
    const [speedFood_details, setSpeedFood_details] = useState(null);
    const [userdata, setUserdata] = useState([]);
    const [merchantData, setMerchantData] = useState(null);
    const [filteredUserData, setFilteredUserData] = useState([]);

    // console.log(speed_food_wallet_amt);
    // console.log(ipay_wallet_amt);

    const toggleIdleTimeoutModal = () => {
        setIdleTimeoutModal(!idleTimeoutModal)
    }

    const handleOnAction = () => {
        //console.log('user did something')
        setIsTimeout(false);
    }

    const handleOnActive = () => {
        //console.log('user is active')
        setIsTimeout(false)
    }

    const handleOnIdle = () => {
        // console.log('user is idle')
        if (isTimeout) {
            logOut();
        } else {
            /* setIdleTimeoutModal(true)
            reset(); */
            setIsTimeout(true);

        }
    }

    const logOut = () => {
        const userId = localStorage.getItem("userId");
        const browserId = localStorage.getItem("browserId");
        const token = localStorage.getItem("token")
        props.onLogout(userId, browserId, token, props.history)
    };

    const { getRemainingTime, getLastActiveTime, reset } = useIdleTimer({
        timeout: 1000 * 60 * 60 * 3,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
    });
    useEffect(() => {
        /* props.onFetchCustomersData() */
        getAllData();
        getMultipleMerchantWallet();

        let socket = socketIOClient(`${client.url("")}`);
        // let socket = socketIOClient('https://api.ipaypro.co');
        // let socket = socketIOClient("http://localhost:5000");
        try {
            console.log(socket);
            socket.on('connect', () => {
                console.log("Socket Connected");
                socket.on("notifications", data => {
                    /* console.log(data); */
                    /* if(data.length > 0){ */
                    addNotification({
                        title: data.title,
                        subtitle: data.title,
                        message: data.description,
                        theme: 'darkblue',
                        native: true // when using native, your OS will handle theming.
                    });
                    let audio = new Audio(notificationSound);
                    // console.log(audio)
                    if (audio)
                        audio.play();
                    //}
                });
                socket.on("sendAllNotification", data => {
                    props.onSetNotificationsData(data)
                })
            });
            socket.on('disconnect', () => {
                socket.removeAllListeners();
                console.log("Socket Disconnected");
            });
        } catch {

        }

        //document.querySelector(".customizer-links").style.display = "none";


    }, []);

    useEffect(() => {
        props.onTryAutoSignup(props.history);
        document.querySelectorAll(".customizer-mix li").forEach((item) => {
            item.classList.remove('active');
        });
        document.body.className = "light-only";
        props.onSettingTheme()

    }, [children]);

    const getMultipleMerchantWallet = () => {
        let merchant_ids = ['5f12aad64595d48863177243', '5f11e6b812caca165455b085'];

        let postdata = { selectedIDs: merchant_ids }
        client.post(api.getMultipleMerchantWallet, postdata, (error, response) => {
            if (!error) {
                try {
                    if (!response.error) {
                        // console.log(response.result);
                        if (response.result.walletDetails.length > 0)
                            setIpay_wallet_amt(response.result.walletDetails[1].wallet_amount);
                        if (response.result.walletDetails.length > 0)
                            setSpeed_food_wallet_amt(response.result.walletDetails[0].wallet_amount);
                        if (response.result.details.length > 0)
                            setIpay_details(response.result.details[1]);
                        if (response.result.details.length > 0)
                            setSpeedFood_details(response.result.details[0])
                        //this.setState({  selectedPayer: response.result.details[1] });

                    } else {
                        setTimeout(() => {
                            toast.error(response.message);
                        }, 200);
                    }
                } catch (e) {
                    setTimeout(() => {
                        toast.error("Internal error occured. Please contact support w" + e.message);
                    }, 200);
                }

            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
            //   current.setState({ loading: false }); 
        });
    }

    const getAllData = () => {
        let postdata = { type: 1 };
        client.post(api.getCustMerchData, postdata, (error, response) => {
            if (!error) {
                try {
                    if (!response.error) {
                        // console.log(response);
                        const filteredUserData = response.result.customers.filter(el => {
                            return el.status == 1 || el.status == 2
                        });
                        const allData = [...response.result.customers, ...response.result.merchants]
                        setMerchantData(response.result.merchants)
                        setUserdata(allData);
                        setFilteredUserData(filteredUserData)
                    } else {
                        setTimeout(() => {
                            toast.error(response.message);
                        }, 200);
                    }
                } catch (e) {
                    setTimeout(() => {
                        toast.error("Internal error occured. Please contact support" + e.message);
                    }, 200);
                }

            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
            //   current.setState({ loading: false });
        });
        // client.post(api.validateCustData, postdata, (error, response) => {
        //     if (!error) {
        //         try {
        //             if (!response.error) {
        //                 // console.log(response);
        //                 const filteredUserData = response.result.customers.filter(el => {
        //                     return el.status == 1 || el.status == 2
        //                 });
        //                 const allData = [...response.result.customers, ...response.result.merchants]
        //                 setUserdata(allData);
        //                 setFilteredUserData(filteredUserData)
        //             } else {
        //                 setTimeout(() => {
        //                     toast.error(response.message);
        //                 }, 200);
        //             }
        //         } catch (e) {
        //             setTimeout(() => {
        //                 toast.error("Internal error occured. Please contact support" + e.message);
        //             }, 200);
        //         }

        //     } else {
        //         setTimeout(() => {
        //             toast.error("Internal error occured. Please contact support");
        //         }, 200);
        //     }
        //     //   current.setState({ loading: false });
        // });
    };

    const renderIdleTimeoutModal = () => {
        return (
            <Modal isOpen={idleTimeoutModal} toggle={toggleIdleTimeoutModal} className="modal-body" >
                <form className="theme-form" onSubmit={logOut}>
                    <ModalHeader><h5 style={{ fontSize: "1.15rem" }}>You Have Been Idle!</h5></ModalHeader>
                    <ModalBody>
                        <ModalBody style={{ padding: "16px 0" }}>
                            <h5 style={{ fontSize: "1.15rem" }}>You Will Get Logged Out.Do You want to stay?</h5>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" type="submit"> Logout </Button>
                            <Button color="secondary" onClick={toggleIdleTimeoutModal}>Stay</Button>
                        </ModalFooter>
                    </ModalBody>
                </form>
            </Modal>

        );
    }

    return (
        <div>
            <Loader />
            {userdata && filteredUserData && ipay_details &&
                <div className="page-wrapper">
                    <div className="page-body-wrapper" id="body-cont"  >
                        <Header ipay_wallet_amt={ipay_wallet_amt} speed_food_wallet_amt={speed_food_wallet_amt}
                            userdata={userdata} ipay_details={ipay_details} speedFood_details={speedFood_details}
                            filteredUser={filteredUserData} merchantData={merchantData} />

                        <Sidebar />
                        <RightSidebar />
                        <div className="page-body-above" style={{ marginLeft: "210px" }}>
                            <div className="page-body"  >
                                {children}
                                {renderIdleTimeoutModal()}
                            </div>
                        </div>
                        {/* <Footer />  */}
                        {/* <ThemeCustomizer /> */}
                    </div>
                </div>}
            <ToastContainer />
            <Notifications />
        </div>
    );
}

const mapStateToProps = state => {
    return {
        customersData: state.app.customersData,
        customizer: state.Customizer.customizer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: (history) => dispatch(actionCreators.authCheckState(history)),
        onLogout: (userId, browserId, token, history) => dispatch(actionCreators.logout(userId, browserId, token, history)),
        onSetNotificationsData: (notifications) => dispatch(actionCreators.setNotifications(notifications)),
        onFetchCustomersData: () => dispatch(actionCreators.fetch_customerData()),
        onSettingTheme: () => dispatch({ type: 'ADD_MIXlAYOUT', payload: "light-only" })

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppLayout));