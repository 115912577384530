import React, { Component, Fragment } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup, Col, Row, FormFeedback, CustomInput } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink as NavLinkTab } from "reactstrap";
import { NavLink, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { Redirect } from "react-router-dom";

import client from "../../../../Api/HTTPClient";
import api from "../../../../constant/apilist"; 

class RestrictedFeatures extends Component {

    state = {
        featureModal : false,
        featureName : null,
        deleteModal: false,
        selectedId: null,
        access_level : localStorage.getItem("accessLevel")
    }



    refreshPage() {
        window.location.reload(false);
    }

    deleteClick = (id) => {
        this.setState(prevState => {
            return {
                ...prevState,
                deleteModal: !prevState.deleteModal,
                selectedId: id
            }
        });
    }

    toggleDeleteModal = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                deleteModal: !prevState.deleteModal
            }
        });
    }

    renderDeleteModal = () => {
        const { deleteModal } = this.state;

            return (
                <Modal isOpen={deleteModal} toggle={this.toggleDeleteModal} className="modal-body" centered={true}>
                    <form className="theme-form" onSubmit={this.deleteFeature}>
                        <ModalHeader><h5 style={{ fontSize: "1.15rem" }}>Delete Feature</h5></ModalHeader>
                        <ModalBody>
                            <ModalBody style={{ padding: "16px 0" }}>
                                <h5 style={{ fontSize: "1.15rem" }}>Are you sure you want to delete this feature ?</h5>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={this.toggleDeleteModal}>Not sure!</Button>
                                <Button color="primary" type="submit"  >Yes Please!</Button>
                            </ModalFooter>
                        </ModalBody>
                    </form>
                </Modal>
            );

    }

    deleteFeature = (e) => {
        e.preventDefault();
        const postData = { featureId: this.state.selectedId }

        client.post(api.delete_restFeature, postData, (error, response) => {
            if (!error) {
                // alert(JSON.stringify(response));
                //   return false;
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            this.refreshPage()
                            toast.success(response.message);

                        } else {
                            // toast.error(response.message);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }

        });
    }

    onNameChange= (event) =>{
        const { name, value } = event.target;
        this.setState(prevState=>{
            return {
                ...prevState,
                featureName : value
            }
        })
    }

    toggleFeatureModal = () => {
        this.setState(prevState => {
            return {
                featureModal: !prevState.featureModal,
            }
        });
    }

    onFeatureAdd = () =>{
        const postData = { name: this.state.featureName}
        client.post(api.add_restFeature, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    //console.log(response);
                    toast.success(response.message);
                    this.refreshPage();
                    
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);

                }

            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        });
    }

    renderFeatureModal = () => {
        return (
            <Modal isOpen={this.props.featureModal} toggle={this.props.toggleFeatureModal}>
                <Form onSubmit={this.onFeatureAdd}>
                    <ModalHeader toggle={this.props.toggleFeatureModal}>Add Feature</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="ipay_id">Feature name</Label>
                                    <Input type="text" id="ipay_id" name="name" required
                                        placeholder="Name" onChange={this.onNameChange} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.props.toggleFeatureModal}>Cancel</Button>
                        <Button color="primary" type="submit">Submit</Button>
                    </ModalFooter>
                </Form>
            </Modal >
        );
    }
    render() {
        const { restrictedFeatures, access_level } = this.props;

        return (
            <Fragment>
                {this.renderFeatureModal()}
                {this.state.selectedId && this.renderDeleteModal()}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12 col-sm-12">
                            <div className="card">
                                {/* <div className="card-header">
                                    {access_level != 1 && <div className="add-task-btn-wrapper"><span className="add-task-btn">
                                        <button className="btn btn-outline-primary" onClick={this.toggleFeatureModal} >
                                            <i className="icon-plus"></i> Add new Feature</button></span>
                                    </div>}
                                </div> */}
                                <div className="card-body">
                                    <div className="todo">
                                        <div className="todo-list-wrapper">
                                            <div className="todo-list-container">
                                                <div >
                                                    <ul id="todo-list">
                                                        {restrictedFeatures.length > 0 ?
                                                            restrictedFeatures.map((el, index) =>
                                                                <li className={"task " + el.status} key={index} >
                                                                    <div className="task-container">
                                                                        <h4 className="task-label" style={{fontSize:"0.9rem"}}>{el.name}</h4>
                                                                        {access_level== 3 && <span className="task-action-btn">
                                                                            <span className="action-box large delete-btn" title="Delete Task" onClick={()=>this.deleteClick(el._id)}>
                                                                                <i className="icon"><i className="icon-trash"></i></i></span>
                                                                        </span>}
                                                                    </div>
                                                                </li>
                                                            ) : ''}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default RestrictedFeatures;