import React, { Component, useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormGroup, Col, Row, Input, CustomInput } from 'reactstrap';
import useForm from "react-hook-form";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";

let AddCatSubcatModal = (props) => {

    const { handleSubmit, register, errors } = useForm();

    const { catid, category, subCategory, isOpen, fetchData } = props;

    let [loadingbtn, setloadingbtn] = useState(false);
    let [inputList, setInputList] = useState([{ subCategory: "", status: 1, ipaypro_status: 1, linky_status: 1, hustlerz_status: 1 }]);
    let [inputCat, setInputCat] = useState('');
    let [editItem, seteditItem] = useState(null);
    let [firstTimeLoad, setfirstTimeLoad] = useState(true);

    useEffect(() => {
        if (isOpen) {
            if (firstTimeLoad) {
                setInputCat(category);
                setInputList(subCategory);
                seteditItem(catid);
                setfirstTimeLoad(false);
            }
        } else {
            setfirstTimeLoad(true);
        }
    }, [props]);

    const handleResetClick = () => {
        setInputCat('');
        setInputList([{ subCategory: "", status: 1, ipaypro_status: 1, linky_status: 1, hustlerz_status: 1 }]);
        seteditItem(null);
    };

    const handleAddClick = () => {
        setInputList([...inputList, { subCategory: "", status: 1, ipaypro_status: 1, linky_status: 1, hustlerz_status: 1 }]);
    };

    const handleCatInputChange = (e) => {
        const { value } = e.target;
        setInputCat(value);
    }

    // handle click event of the Remove button
    const handleRemoveClick = index => {

        let list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };


    const onSubmit = form_data => {

        if (form_data !== '') {

            setloadingbtn(true);

            let data = { category_name: form_data.category_name, subcategory: inputList, _id: editItem, type: 1 };

            client.post(api.edit_catsubcat, data, async function (error, response) {
                console.log(response, "edit catsubcat response");
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                setfirstTimeLoad(true);
                                handleResetClick();
                                toast.success("Updated Successfully");
                                fetchData();
                            } else {
                                toast.error(response.message);
                            }
                        } catch (e) {
                            //toast.error("Internal error occured. Please contact support 2");
                        }
                    } else {
                        toast.error("Internal error occured. Please contact support 2");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support 3");
                }
                setloadingbtn(false);
            });

        }
        else {
            errors.showMessages();
        }
    }

    return (

        <Modal
            isOpen={props.isOpen}
            toggle={props.onClose}
            size="md"
            className="modal-body"
            centered={true}
        >
            <form
                className="needs-validation"
                noValidate=""
                onSubmit={handleSubmit(onSubmit)}
            >
                <ModalHeader toggle={props.onClose}><p className="m-0 font-weight-bold">Edit Category & Subcategory</p></ModalHeader>
                <ModalBody>
                    <ModalBody style={{ padding: "16px 0" }}>
                        <Row form>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="level_title">Category</Label>
                                    <input className="form-control" type="text" name="category_name" value={inputCat} onChange={e => handleCatInputChange(e)} ref={register({ required: true })} />
                                    <span>{errors.category_name && 'Category Name is required'}</span>
                                </FormGroup>
                            </Col>
                            <FormGroup className="w-100">
                                <Label for="level_title">SubCategory</Label>
                                {inputList.map((x, i) => {

                                    return (
                                        <Row form>
                                            <Col md={11}>
                                                <input
                                                    name="subCategory" className="form-control mb-2" type="text"
                                                    ref={register({ required: true })}
                                                    value={x.subCategory}
                                                    onChange={e => handleInputChange(e, i)}
                                                />
                                                <span>{errors.subCategory && 'SubCategory is required'}</span>
                                            </Col>

                                            <Col md={1}>
                                                {inputList.length !== 1 && <button
                                                    className="btn btn-outline-danger mb-2 p-2"
                                                    type="button"
                                                    onClick={() => handleRemoveClick(i)}>
                                                    <i id="Remove" title="Remove Subcategory" className="fa fa-minus"></i>
                                                </button>
                                                }
                                                {inputList.length - 1 === i && <button
                                                    className="btn btn-outline-success p-2"
                                                    type="button"
                                                    onClick={handleAddClick}>
                                                    <i id="Add New" title="Add New" className="fa fa-plus"></i>
                                                </button>
                                                }
                                            </Col>
                                        </Row>
                                    )
                                })}
                            </FormGroup>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {!loadingbtn ?
                            <>
                                <Button color="default" type="button" onClick={handleResetClick}>Reset</Button>
                                <Button color="primary" type="submit" onClick={() => props.onClose(false)}>Update</Button>
                            </>
                            :
                            <div className="loader-box pull-right" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 20, width: 20 }}></span></div>
                        }
                    </ModalFooter>
                </ModalBody>
            </form>
        </Modal>
    );
}

export default AddCatSubcatModal;