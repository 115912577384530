import React from 'react'

import useForm from 'react-hook-form';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem, UncontrolledTooltip, NavLink as NavLinkTab } from 'reactstrap';

const HosposafeShareReport = (props) => {

    const { register, handleSubmit, errors } = useForm();
    const onSubmit = data => {
        //console.log(data);
        if (data !== '') {

            props.createAndDownloadPdf(data);
            // alert(data.share_to_email)
        //   alert('You submitted the form and stuff!');
        } else {
          errors.showMessages();
        }
      };
    console.log(errors);

    return (
            <form className="needs-validation theme-form"  onSubmit={handleSubmit(onSubmit)} >
                <ModalBody>
                    <div id="pills-darkhome" role="tabpanel" aria-labelledby="pills-darkhome-tab">
                        <div className="email-compose">
                            <div className="email-top compose-border">
                                <div className="row">
                                    <div className="col-sm-8 xl-50">
                                        <h4 className="mb-0">New Message</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="email-wrapper">
                                <form className="theme-form">
                                    <div className="form-group">
                                        <label className="col-form-label pt-0" htmlFor="exampleInputEmail1">To</label>
                                    <input className="form-control" id="exampleInputEmail1" name="share_to_email" type="email" ref={register({ required: true })} />
                                    {errors.share_to_email && <p className="text-danger">This field is required</p>}
                                    
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1">Subject</label>
                                    <input className="form-control" id="exampleInputPassword1" name="share_to_subject" type="text" ref={register({ required: true })} />
                                    {errors.share_to_subject && <p className="text-danger">This field is required</p>}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" disabled={props.loadingmail} onClick={props.toggleMailModal}>No</Button>
                    <Button disabled={props.loadingmail} color="primary" type="submit" >Yes</Button>
                </ModalFooter>
            </form>
    )
}

export default HosposafeShareReport;
