import React, { Component, Fragment } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup, Col, Row, FormFeedback, CustomInput } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import { Link as NavLink } from "react-router-dom";
import moment from "moment-timezone";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'

import Breadcrumb from "../../../components/common/breadcrumb";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import "../../../table.css";

class CustomerCrownsPoints extends Component {

    state = {
        modal: false,
        crownPointLevels: null,
        url: null,
        crownPointsChangeInfo: null,
        editModal: false,
        deleteModal: false,
        level: null,
        editLevelInfo: null,
        isOpen: false,
        imageSrc: null,
        levelId: null,
        access_level: localStorage.getItem("accessLevel")
    }

    componentDidMount() {
        this.get_all_crownLevel();
    }

    toggle = () => {
        this.setState(prevState => {
            return {
                modal: !prevState.modal,
            }
        });
    }

    onEditClick = (level) => {
        this.setState(prevState => {
            return {
                editModal: !prevState.editModal,
                level: level
            }
        });
    }
    toggleEditModal = () => {
        this.setState(prevState => {
            return {
                editModal: !prevState.editModal,
            }
        });
    }

    toggleDeleteModal = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                deleteModal: !prevState.deleteModal
            }
        });
    }

    render_date = (add_date) => {
        if (typeof (add_date) === "undefined") {
            return;
        }

        const convert_date = new Date(add_date)
        const added_date = moment.tz(convert_date, "Australia/Sydney").format("DD-MM-YYYY HH:mm");
        return added_date;
    }

    refreshPage() {
        window.location.reload(false);
    }

    get_all_crownLevel = () => {
        const postdata = {};
        // console.log(postdata);
        client.post(api.get_Cust_allCrownLevel, postdata, (error, response) => {
            if (!error) {
                if (!response.error) {
                    //console.log(response);
                    this.setState({ crownPointLevels: response.result.data })
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);

                }

            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        });
    }

    onCheckboxChange = (event, id) => {
        console.log(event.target.name);
        let postData = "";

        const foundLevel = this.state.crownPointLevels.find(el => {
            return el._id == id
        });
        console.log(foundLevel);

        if (event.target.name == `point${id}`) {
            postData = { _id: id, point_status: !foundLevel.point_status }
        } else if (event.target.name == `cb${id}`) {
            postData = { _id: id, cb_status: !foundLevel.cb_status }
        } else if (event.target.name == `level${id}`) {
            postData = { _id: id, level_status: !foundLevel.level_status }
        }


        client.post(api.edit_Cust_Crown_level, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    this.refreshPage();
                    console.log(response);
                    toast.success(response.message);
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);

                }

            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        });
    }

    onlevelFieldChange = (event) => {
        const { name, value } = event.target;
        if (event.target.type == "file") {
            var mimeType = event.target.files[0].type;

            if (mimeType.match(/(jpeg|jpg|png|pdf)$/) == null) {
                return;
            }
            // Image upload
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = (_event) => {
                let imageData;
                this.setState({ url: reader.result });
                imageData = reader.result;
                imageData = imageData.split(",")[1];

                let image_data = { mime: mimeType, data: imageData }
                console.log(image_data);
                this.setState(prevState => {
                    return {
                        ...prevState,
                        crownPointsChangeInfo: {
                            ...prevState.crownPointsChangeInfo,
                            [name]: image_data
                        }
                    }
                })
            }
        } else {
            this.setState(prevState => {
                return {
                    ...prevState,
                    crownPointsChangeInfo: {
                        ...prevState.crownPointsChangeInfo,
                        [name]: value
                    }
                }
            })
        }
    }

    onEditCrownLevel = (event) => {
        const { name, value } = event.target;
        if (event.target.type == "file") {
            var mimeType = event.target.files[0].type;

            if (mimeType.match(/(jpeg|jpg|png|pdf)$/) == null) {
                return;
            }
            // Image upload
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = (_event) => {
                let imageData;
                this.setState({ url: reader.result });
                imageData = reader.result;
                imageData = imageData.split(",")[1];

                let image_data = { mime: mimeType, data: imageData }
                // console.log(image_data);
                this.setState(prevState => {
                    return {
                        ...prevState,
                        level: {
                            ...prevState.level,
                            [name]: image_data
                        },
                        editLevelInfo: {
                            ...prevState.editLevelInfo,
                            [name]: image_data
                        }
                    }
                })
            }
        } else {
            this.setState(prevState => {
                return {
                    ...prevState,
                    level: {
                        ...prevState.level,
                        [name]: value

                    },
                    editLevelInfo: {
                        ...prevState.editLevelInfo,
                        [name]: value
                    }
                }
            })

        }

    }

    newLevelSubmitHandler = (event) => {
        event.preventDefault();
        const postdata = { ...this.state.crownPointsChangeInfo, point_status: 1, cb_status: 1, level_status: 1 };
        console.log(postdata);

        client.post(api.add_New_Level_Cust_Crown, postdata, (error, response) => {
            if (!error) {
                if (!response.error) {
                    this.refreshPage();
                    console.log(response);
                    toast.success(response.message);
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);

                }

            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        });

    }

    editLevelSubmitHandler = (event) => {
        event.preventDefault();
        const postdata = { _id: this.state.level._id, ...this.state.editLevelInfo };
        console.log(postdata);

        client.post(api.edit_Cust_Crown_level, postdata, (error, response) => {
            if (!error) {
                if (!response.error) {
                    this.refreshPage();
                    console.log(response);
                    toast.success(response.message);
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);

                }

            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        });

    }

    imageClicked = (src) => {
        //console.log("clicked");
        this.setState({ isOpen: true, imageSrc: src })
    }

    deleteClick = (id) => {
        this.setState(prevState => {
            return {
                ...prevState,
                deleteModal: !prevState.deleteModal,
                levelId: id
            }
        });
    }

    deleteCrownLevel = (e) => {
        e.preventDefault();

        const data = { levelId: this.state.levelId }

        client.post(api.del_Cust_Crown_level, data, (error, response) => {
            if (!error) {
                // alert(JSON.stringify(response));
                //   return false;
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            toast.success(response.message);
                            window.location.reload(true);
                        } else {
                            // toast.error(response.message);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }

        });
    }

    renderDeleteModal = () => {
        const { deleteModal } = this.state;

        return (
            <Modal isOpen={deleteModal} toggle={this.toggleDeleteModal} className="modal-body" centered={true}>
                <form className="theme-form" noValidate="" onSubmit={this.deleteCrownLevel}>
                    <ModalHeader><h5 style={{ fontSize: "1.15rem" }}>Delete Level</h5></ModalHeader>
                    <ModalBody>
                        <ModalBody style={{ padding: "16px 0" }}>
                            <h5 style={{ fontSize: "1.15rem" }}>Are you sure you want to delete this level ?</h5>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="default" onClick={this.toggleDeleteModal}>Not sure!</Button>
                            <Button color="primary" type="submit"  >Yes Please!</Button>
                        </ModalFooter>
                    </ModalBody>
                </form>
            </Modal>
        );
    }

    renderTable = () => {
        const { access_level } = this.state;
        return (
            <div className="table-responsive">
                <table className="table table-border-horizontal">
                    <thead>
                        <tr style={{background: '#f9fafe'}}>
                            <th scope="col" style={{ width: "45px" }}>#</th>
                            <th scope="col" style={{ width: "90px" }}>
                                <p>Date</p>
                                <p>Time</p>
                            </th>
                            <th scope="col" style={{ width: "80px" }}>Level Title</th>
                            <th scope="col" style={{ width: "80px" }}>From (points)</th>
                            <th scope="col" style={{ width: "80px" }}>To (points)</th>
                            <th scope="col" style={{ width: "80px" }}>Point status</th>
                            <th scope="col" style={{ width: "110px" }}>Points (per $ on topup via card)</th>
                            <th scope="col" style={{ width: "110px" }}>Points (per $ on topup via bank)</th>
                            <th scope="col" style={{ width: "100px" }}>Cashback status</th>
                            <th scope="col" style={{ width: "130px" }}>Cashback (per $ on topup via card)</th>
                            <th scope="col" style={{ width: "130px" }}>Cashback (per $ on topup via bank)</th>
                            <th scope="col" style={{ width: "100px" }}>Cashback limit</th>
                            <th scope="col" style={{ width: "110px" }}>Crown Title</th>
                            <th scope="col" style={{ width: "80px" }}>Crown icon</th>
                            {access_level != 1 && <th scope="col" style={{ width: "120px" }}>Action</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.crownPointLevels ? this.state.crownPointLevels.map((item, index) => {
                            return (
                                <tr key={item._id}>
                                    <th scope="row" style={{ width: "45px" }} >{index + 1}</th>
                                    <td style={{ width: "90px" }}>{this.render_date(item.createdAt)}</td>
                                    <td style={{ width: "80px" }}>{item.level_title}</td>
                                    <td style={{ width: "80px" }}>{item.from_points}</td>
                                    <td style={{ width: "80px" }}>{item.to_points}</td>
                                    <td style={{ width: "80px" }}>
                                        <input type="checkbox" onChange={(event) => this.onCheckboxChange(event, item._id)} name={`point${item._id}`}
                                            disabled={access_level == 1 ? true : false} checked={item.point_status == true ? "checked" : null}></input>
                                    </td>
                                    <td style={{ width: "110px" }}>{item.pnts_per_$topup_card}</td>
                                    <td style={{ width: "110px" }}>{item.pnts_per_$topup_bank}</td>
                                    <td style={{ width: "100px" }}>
                                        <input type="checkbox" onChange={(event) => this.onCheckboxChange(event, item._id)} name={`cb${item._id}`}
                                            disabled={access_level == 1 ? true : false} checked={item.cb_status == true ? "checked" : null}></input>
                                    </td>
                                    <td style={{ width: "130px" }}>{item.cb_per_$topup_card}</td>
                                    <td style={{ width: "130px" }}>{item.cb_per_$topup_bank}</td>
                                    <td style={{ width: "100px" }}>{item.cb_limit}</td>
                                    <td style={{ width: "110px" }}>{item.crown_title}</td>
                                    <td style={{ width: "80px" }}>
                                        <img src={`${client.url(`images/crown-level/customer/${item.crown_icon}`)}`}
                                            onClick={() => this.imageClicked(`${client.url(`images/crown-level/customer/${item.crown_icon}`)}`)} style={{ width: "35px", height: "35px" }}></img>
                                    </td>
                                    {access_level != 1 && <td style={{ width: "120px" }}>
                                        {access_level == 3 && <span style={{ marginRight: "10px", fontSize: "0.75rem" }} onClick={() => this.deleteClick(item._id)}>
                                            <i style={{ color: "red" }} id="delete" className="fa fa-trash-o"></i>
                                        </span>}
                                        <span style={{ marginRight: "10px", fontSize: "0.75rem", color: "black" }} onClick={() => this.onEditClick(item)}>
                                            <i className="fa fa-edit"></i>
                                        </span>
                                        <span >
                                            <input type="checkbox" onChange={(event) => this.onCheckboxChange(event, item._id)} name={`level${item._id}`}
                                                checked={item.level_status == true ? "checked" : null}></input>
                                        </span>
                                    </td>}
                                </tr>

                            );
                        }) : null}
                    </tbody>
                </table>
            </div>

        )
    }

    renderAddNewLevelModal = () => {
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggle}>
                <Form onSubmit={this.newLevelSubmitHandler}>
                    <ModalHeader toggle={this.props.toggle}>Add New Level</ModalHeader>
                    <ModalBody>
                        <Row form>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="level_title">Level title</Label>
                                    <Input type="text" name="level_title" required
                                        placeholder="Level title" onChange={this.onlevelFieldChange} />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="from_points">From (points)</Label>
                                    <Input className="number" step="any" type="number" name="from_points"
                                        required placeholder="From" onChange={this.onlevelFieldChange} />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="to_points">To (points)</Label>
                                    <Input className="number" step="any" type="number" name="to_points"
                                        required placeholder="To" onChange={this.onlevelFieldChange} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="points_per_topup">Points (per $ on topup via card)</Label>
                                    <Input className="number" step="any" type="number" name="pnts_per_$topup_card" required
                                        placeholder="Points on topup via card" onChange={this.onlevelFieldChange} />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="cb_type">Cashback %(on topup via card) </Label>
                                    <Input className="number" step="any" type="number" name="cb_per_$topup_card" required
                                        placeholder="Cashback on topup via card" onChange={this.onlevelFieldChange} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="points_per_topup">Points (per $ on topup via bank)</Label>
                                    <Input className="number" step="any" type="number" name="pnts_per_$topup_bank" required
                                        placeholder="Points on topup via bank" onChange={this.onlevelFieldChange} />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="cb_type">Cashback %(on topup via bank) </Label>
                                    <Input className="number" step="any" type="number" name="cb_per_$topup_bank" required
                                        placeholder="Cashback on topup via bank" onChange={this.onlevelFieldChange} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <FormGroup>
                                    <Label for="cb_limit">Cashback limit</Label>
                                    <Input className="number" step="any" type="number" name="cb_limit" required
                                        placeholder="Cashback limit" onChange={this.onlevelFieldChange} >
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="crown_title">Crown title</Label>
                                    <Input type="text" name="crown_title" required placeholder="Crown title" required
                                        onChange={this.onlevelFieldChange} />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="crown_icon">Crown icon</Label>
                                    <Input type="file" name="crown_icon" onChange={this.onlevelFieldChange} required>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>


                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" onClick={this.props.toggle}>Cancel</Button>
                        <Button color="primary">Submit</Button>
                    </ModalFooter>
                </Form>
            </Modal >
        );

    }

    renderEditLevelModal = () => {
        const { crownPointLevels, level } = this.state;

        return (
            <Modal isOpen={this.state.editModal} toggle={this.toggleEditModal}>
                <Form onSubmit={this.editLevelSubmitHandler}>
                    <ModalHeader toggle={this.toggleEditModal}>Edit Level</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="level_title">Level title</Label>
                                    <Input type="text" name="level_title" required
                                        placeholder="Level title" onChange={this.onEditCrownLevel} value={level.level_title} />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="from_points">From (points)</Label>
                                    <Input className="number" step="any" type="number" name="from_points"
                                        required placeholder="From" onChange={this.onEditCrownLevel} value={level.from_points} />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="to_points">To (points)</Label>
                                    <Input className="number" step="any" type="number" name="to_points"
                                        required placeholder="To" onChange={this.onEditCrownLevel} value={level.to_points} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="points_per_topup">Points (per $ on topup via card)</Label>
                                    <Input className="number" step="any" type="number" name="pnts_per_$topup_card"
                                        placeholder="Points on topup via card" onChange={this.onEditCrownLevel} value={level.pnts_per_$topup_card} />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="cb_type">Cashback %(on topup via card) </Label>
                                    <Input className="number" step="any" type="number" name="cb_per_$topup_card"
                                        placeholder="Cashback on topup via card" onChange={this.onEditCrownLevel} value={level.cb_per_$topup_card} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="points_per_topup">Points (per $ on topup via bank)</Label>
                                    <Input className="number" step="any" type="number" name="pnts_per_$topup_bank"
                                        placeholder="Points on topup via bank" onChange={this.onEditCrownLevel} value={level.pnts_per_$topup_bank} />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="cb_type">Cashback %(on topup via bank) </Label>
                                    <Input className="number" step="any" type="number" name="cb_per_$topup_bank"
                                        placeholder="Cashback on topup via bank" onChange={this.onEditCrownLevel} value={level.cb_per_$topup_bank} />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={8}>
                                <FormGroup>
                                    <Label for="cb_limit">Cashback limit</Label>
                                    <Input className="number" step="any" type="number" name="cb_limit"
                                        placeholder="Cashback limit" onChange={this.onEditCrownLevel} value={level.cb_limit}>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="crown_title">Crown title</Label>
                                    <Input type="text" name="crown_title" required placeholder="Crown title"
                                        onChange={this.onEditCrownLevel} value={level.crown_title} />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="crown_icon">Crown icon</Label>
                                    <Input type="file" name="crown_icon" onChange={this.onEditCrownLevel} >
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>


                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" onClick={this.toggleEditModal}>Cancel</Button>
                        <Button color="primary">Save</Button>
                    </ModalFooter>
                </Form>
            </Modal >
        );

    }
    render() {
        const { crownPointsChangeInfo, crownPointLevels, level, isOpen, imageSrc, levelId, access_level } = this.state;
        // console.log(crownPointsChangeInfo);
         console.log(crownPointLevels);
        // console.log(level);
        return (
            <Fragment>
                {levelId ? this.renderDeleteModal() : null}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-xl-12">
                            <div className="card">
                                <div className="card-body" style={{ padding: "1rem" }}>
                                    <div>
                                        {/* {access_level != 1 && <button className="btn btn-outline-primary" onClick={this.toggle} style={{ marginBottom: "20px" }}><i className="fa fa-plus" style={{ margin: "0px 7px" }}></i> Add New Level </button>} */}

                                        {this.renderTable()}
                                        {isOpen && (
                                            <Lightbox
                                                mainSrc={imageSrc}
                                                onCloseRequest={() => this.setState({ isOpen: false })}
                                            />
                                        )}

                                    </div>
                                </div>

                            </div>
                        </div>

                        {this.renderAddNewLevelModal()}
                        {this.state.level && this.renderEditLevelModal()}
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default CustomerCrownsPoints;