import React, { Component, useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormGroup, Col, Row, Input, CustomInput } from 'reactstrap';
import useForm  from "react-hook-form";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

let AddEntryModal = (props) => {
    
    let [loadingbtn, setloadingbtn] = useState(false);
    let [selectedReceiver, setselectedReceiver] = useState([]);
    let [showerrorstatus, setshowerrorstatus] = useState(false);

    const { handleSubmit, register, errors } = useForm();

    useEffect(() => {
    });

    const onSubmit = form_data => {
        
        if (form_data !== '') {

            setloadingbtn(true);

            client.post(api.add_period, form_data, async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                props.toggleModal(1);
                                toast.success("Added Successfully");
                                // setcashbackdata(response.result.data);
                            } else {
                                toast.error(response.message);
                            }
                        } catch (e) {
                            toast.error("Internal error occured. Please contact support");
                        }
                    } else {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
                
                setloadingbtn(false);
            });
        }
        else {
            setshowerrorstatus(true);
            errors.showMessages();
        }
    }

    return (

        <Modal isOpen={props.isOpen} toggle={props.toggleModal} className="modal-body" centered={true}>
            <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={props.toggleModal}>Add Subscription Period</ModalHeader>
                <ModalBody>
                        
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <Label for="amount">Title</Label>
                                <input className="form-control" type="text" name="title" placeholder="Title" ref={register({ required: true })} />
                                <span style={{ color: '#ff5370' }}>{errors.title && 'Title is required'}</span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <Label for="amount">Period</Label>
                                <input className="form-control" type="text" name="period" ref={register({ required: true })} />
                                <span style={{ color: '#ff5370' }}>{errors.title && 'Period is required'}</span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <Label for="amount">Discount Percent (%)</Label>
                                <input className="form-control" type="text" name="discount_percent" ref={register({ required: true })} />
                                <span style={{ color: '#ff5370' }}>{errors.discount_percent && 'Discount Percent is required'}</span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <Label for="amount">Renewal Discount (%)</Label>
                                <input className="form-control" type="text" name="renewal_discount" ref={register({ required: true })} />
                                <span style={{ color: '#ff5370' }}>{errors.renewal_discount && 'Renewal Discount is required'}</span>
                            </div>
                        </div>
                    </div>

                </ModalBody>
                <ModalFooter>
                    {!loadingbtn ?
                        <>
                            <Button color="default" type="button" onClick={props.toggleModal}>Cancel</Button>
                            <Button color="primary" type="submit" onClick={() => setshowerrorstatus(true)} >Save</Button>
                        </>
                        :
                        <div className="loader-box pull-right" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 20, width: 20 }}></span></div>
                    }
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default AddEntryModal;