import React, { Component, useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormGroup, Col, Row, Input, CustomInput } from 'reactstrap';
import DatePicker from "react-datepicker";
import ImageUploader from 'react-images-upload';
import useForm from "react-hook-form";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import TimePicker from 'react-times';
// use material theme
import 'react-times/css/material/default.css';
// or you can use classic theme

let DatePickerInput = ({ value, onClick }) => {
    return (
        <Input type="text" value={value} onFocus={onClick} onClick={onClick} />
    );
}

let input_sub_title_style = { fontSize: '10px', color: "#98a6ad", marginBottom: 0 };

let AddOfferModal = (props) => {

    let [valid_to, setvalid_to] = useState(null);
    let [valid_from, setvalid_from] = useState(null);
    let [loadingbtn, setloadingbtn] = useState(false);
    let [pictures, setpictureso] = useState([]);
    let [image_data, setimage_data] = useState(null);
    let [activate_type, setactivate_type] = useState(1);
    let [offer_category, setoffer_category] = useState(1);
    let [offer_level, setoffer_level] = useState(props.offer_level);
    let [disable_offer_level, setdisable_offer_level] = useState(props.offer_level == 2 ? true : false);
    let [offermerchant, setoffermerchant] = useState([]);
    let [offer_type, setoffer_type] = useState(0);
    let [offer_title, setoffer_title] = useState("");
    let [showerrorstatus, setshowerrorstatus] = useState(false);
    let [offer_redeem_type, setoffer_redeem_type] = useState(1);
    let [to_time, setto_time] = useState();
    let [from_time, setfrom_time] = useState();



    const { handleSubmit, register, errors } = useForm();


    useEffect(() => {
        if (disable_offer_level) {
            setoffermerchant(props.merchants);
        }

    }, []);

    const onSubmit = form_data => {

        console.log(form_data, "form_data")

        if (form_data !== '') {
            if (!moment(valid_from).isValid()) {
                console.log(valid_from);
                console.log("invalid from date");
                return false;
            }
            if (!moment(valid_to).isValid()) {
                console.log(valid_to);
                console.log("invalid to date");
                return false;
            }

            if (image_data == null) {
                console.log("invalid Image");
                return false;
            }

            let merchant_id = "";
            if (offer_level == 2) {
                try {
                    if (offermerchant.length == 0) {
                        console.log("select merchant");
                        return false;
                    } else {
                        merchant_id = offermerchant[0]._id;
                    }
                } catch {
                    console.log("select merchant");
                    return false;
                }
            }

            form_data.offer_category = offer_category;
            form_data.offer_redeem_type = offer_redeem_type;

            valid_from.setHours(from_time.hour);
            valid_from.setMinutes(from_time.minute);

            valid_to.setHours(to_time.hour);
            valid_to.setMinutes(to_time.minute);

            form_data.valid_from = valid_from;
            form_data.valid_to = valid_to;
            form_data.image_data = image_data;
            form_data.offer_level = parseInt(offer_level);
            form_data.merchant_id = merchant_id;
            form_data.activate_type = activate_type;

            console.log(form_data, "form_data")

            setloadingbtn(true);

            client.post(api.add_offers, form_data, async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                props.fetc_offers();
                                props.toggleModal();
                                toast.success("Added Successfully");
                                // setcashbackdata(response.result.data);
                            } else {
                                toast.error(response.message);
                            }
                        } catch (e) {
                            toast.error("Internal error occured. Please contact support");
                        }
                    } else {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }

                setloadingbtn(false);
            });
        }
        else {
            setshowerrorstatus(true);
            errors.showMessages();
        }
    }
    let onDrop = (pictureFiles, pictureDataURLs) => {
        setpictureso(pictureFiles);
        try {
            var mimeType = pictureFiles[0].type;
            // Image upload
            var reader = new FileReader();
            reader.readAsDataURL(pictureFiles[0]);
            reader.onload = (_event) => {
                let imageData;
                imageData = reader.result;
                imageData = imageData.split(",")[1];
                let image_data_temp = { mime: mimeType, data: imageData }
                console.log("image set");
                setimage_data(image_data_temp);
            }
        } catch (err) {
            // console.log(pictureFiles);
            console.log("error image not set");
            setimage_data(null);
        }
    }

    return (


        <Modal isOpen={props.isOpen} toggle={props.toggleModal} size="lg" className="modal-body" centered={true}>
            {
                // console.log(from_time)
            }
            <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={props.toggleModal}>Add Offer</ModalHeader>
                <ModalBody>
                    <ModalBody style={{ padding: "16px 0" }}>
                        <Row form>
                            <Col md={8}>
                                <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="level_title">Select Offer Category</Label>
                                            <div className="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                                <div className="radio radio-primary">
                                                    <input className="radio_animated" id="radioinline1" type="radio" defaultChecked={offer_category == 1 ? true : false} name="offer_category" required value="1" onChange={e => setoffer_category(e.target.value)} />
                                                    <label className="mb-0" for="radioinline1">Online Shopping</label>
                                                </div>
                                                <div className="radio radio-primary">
                                                    <input className="radio_animated" id="radioinline2" type="radio" required name="offer_category" value="2" defaultChecked={offer_category == 2 ? true : false} onChange={e => {
                                                        setoffer_category(e.target.value)
                                                        // setactivate_type(1);

                                                    }} />
                                                    <label className="mb-0" for="radioinline2">In-Store Purchase</label>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="level_title">Select Merchant type</Label>
                                            <div className="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                                <div className="radio radio-primary">
                                                    <input className="radio_animated" id="offer_level1" type="radio" defaultChecked={offer_level == 1 ? true : false} name="offer_level" disabled={disable_offer_level} required value="1" onChange={e => setoffer_level(e.target.value)} />
                                                    <label className="mb-0" for="offer_level1">All Merchants</label>
                                                </div>
                                                <div className="radio radio-primary">
                                                    <input className="radio_animated" id="offer_level2" type="radio" defaultChecked={offer_level == 2 ? true : false} required name="offer_level" value="2" onChange={e => {
                                                        setoffer_level(e.target.value);
                                                    }
                                                    } />
                                                    <label className="mb-0" for="offer_level2">Particular Merchant</label>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {

                                }
                                {offer_level == 1 ?
                                    null
                                    :
                                    <Row form>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="level_title">Select Merchant</Label>
                                                <Typeahead
                                                    id="merchant_id"
                                                    defaultSelected={disable_offer_level == true ? props.merchants : ''}
                                                    labelKey={'business_name'}
                                                    options={props.merchants}
                                                    disabled={disable_offer_level}
                                                    // placeholder="User detail"
                                                    onChange={(selected) => setoffermerchant(selected)}
                                                />
                                                <span style={{ color: '#ff5370' }}>{(showerrorstatus && offermerchant.length == 0) ? 'Merchant is required' : showerrorstatus}</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                }
                                <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="level_title">Offer title
                                                <p style={input_sub_title_style}>You can give a title to your deal based on the kind of promotion you want to run.</p>
                                            </Label>

                                            <input className="form-control" type="text" name="offer_title" onChange={e => setoffer_title(e.target.value)} ref={register({ required: true })} />
                                            <span>{errors.offer_title && 'Offer title is required'}</span>

                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="level_title">Offer Description</Label>
                                            <input className="form-control" type="text" name="offer_description" ref={register({ required: true })} />
                                            <span>{errors.offer_description && 'Offer Description is required'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                {offer_category == 1 && <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="level_title">Order Online URL</Label>
                                            <input className="form-control" type="text" name="order_online_url" ref={register({ required: true })} />
                                            <span>{errors.order_online_url && 'Order Online Url is required'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>}

                                {/* <Row form>
                                    <Col md={6}>
                                <Row form>
                                    {/* <Col md={6}>
                                        <FormGroup>
                                            <Label for="level_title">Activate Offer</Label>
                                            <select className="form-control select" name="activate_type" id="activate_type" onChange={e => setactivate_type(e.target.value)} ref={register({ required: true })}>
                                                <option value="1">Automatic</option>
                                                {offer_category == 1 &&
                                                    <option value="2">Apply Coupon</option>
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col> 
                                    {activate_type == 2 ?
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="to_points">Coupon Code</Label>
                                                <input className="form-control" type="text" name="coupon_code" ref={register({ required: true })} />
                                                <span>{errors.coupon_code && 'Coupon Code is required'}</span>
                                            </FormGroup>
                                        </Col>
                                        : null
                                    }
                                </Row> */}

                                <Row form>

                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="level_title">Offer Type</Label>
                                            <select className="form-control select" name="offer_type" id="offer_type" onChange={e => setoffer_type(e.target.value)} ref={register({ required: true })}>
                                                <option value="1">Free Item</option>
                                                <option value="2">Cashback</option>
                                            </select>
                                        </FormGroup>
                                    </Col>

                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="level_title">Category</Label>
                                            <select className="form-control select" name="filter_category" id="filter_category" ref={register({ required: true })}>
                                                <option value="">Select Category</option>
                                                {
                                                    props.categories && props.categories.map((item) => {
                                                        return (
                                                            <option value={item.filter_title}>{item.filter_title}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <span style={{ color: 'red' }}>{errors.filter_category && 'Category is required'}</span>

                                        </FormGroup>
                                    </Col>

                                </Row>
                                {offer_type == 2 ?
                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="level_title">Cashback Type</Label>
                                                <select className="form-control select" name="cashback_type" id="cashback_type" ref={register({ required: true })}>
                                                    <option value="1">Percent Based</option>
                                                    <option value="2">Amount Based</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="to_points">Cashback</Label>
                                                <input className="form-control" type="number" name="cashback" ref={register({ required: true })} />
                                                <span>{errors.cashback && 'Cashback is required'}</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    :
                                    <Row form>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="level_title">Free Item</Label>
                                                <input className="form-control" type="text" name="offer_item" ref={register({ required: true })} />
                                                <span>{errors.offer_item && 'Offer Item is required'}</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                }

                                <Row form>
                                    <p style={input_sub_title_style}>You can decide on which date you want the coupon to get activated. You can also adjust the timing.</p>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="level_title">Valid From
                                            </Label>
                                            <DatePicker
                                                customInput={(<DatePickerInput />)}
                                                selected={valid_from}
                                                minDate={new Date()}
                                                onChange={date => {
                                                    console.log("date")
                                                    console.log(date)

                                                    setvalid_from(date)
                                                }}

                                            />
                                            <span style={{ color: '#ff5370' }}>{(showerrorstatus == true && !moment(valid_from).isValid()) ? 'Valid From is required' : ""}</span>
                                        </FormGroup>
                                    </Col>

                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="to_points">From Time</Label>
                                            <TimePicker
                                                // timeMode="12"
                                                onTimeChange={(option) => setfrom_time(option)}
                                                timezone="Australia/Sydney"
                                                theme="classic"
                                                time={from_time ? `${from_time.hour}:${from_time.minute}` : null}
                                            />

                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <p style={input_sub_title_style}>This section allows you to decide the duration of your coupon code; along with the end time.</p>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="to_points">Valid To

                                            </Label>
                                            <DatePicker
                                                customInput={<DatePickerInput />}
                                                selected={valid_to}
                                                minDate={valid_from ? valid_from : new Date()}
                                                onChange={date => setvalid_to(date)
                                                }
                                            />
                                            <span style={{ color: '#ff5370' }}>{(showerrorstatus == true && !moment(valid_to).isValid()) ? 'Valid To is required' : ""}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="to_points">To Time</Label>
                                            <TimePicker
                                                // timeMode="12"
                                                onTimeChange={(option) => setto_time(option)}
                                                theme="classic"
                                                timezone="Australia/Sydney"
                                                time={to_time ? `${to_time.hour}:${to_time.minute}` : null}
                                            />

                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row form>
                                    <Label for="level_title">Redempltion Limit</Label>
                                    <div className="col-sm-12">
                                        <div className="card">
                                            <div className="media p-20" style={{ paddingBottom: 3, paddingTop: 3 }}>
                                                <div className="radio radio-secondary mr-3 ml-2">
                                                    <input id="radio1" type="radio" name="offer_redeem_type" defaultChecked value="1" onChange={e => setoffer_redeem_type(e.target.value)} />
                                                    <label htmlFor="radio1"></label>
                                                </div>
                                                <div className="media-body" htmlFor="radio1">
                                                    <h6 className="mt-0 mega-title-badge">Yes</h6>
                                                    <p>Limit redemption to one per customer</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="card">
                                            <div className="media p-20" style={{ paddingBottom: 3, paddingTop: 3 }}>
                                                <div className="radio radio-secondary mr-3 ml-2">
                                                    <input id="radio2" type="radio" name="offer_redeem_type" value="2" onChange={e => setoffer_redeem_type(e.target.value)} />
                                                    <label htmlFor="radio2"></label>
                                                </div>
                                                <div className="media-body" htmlFor="radio2">
                                                    <h6 className="mt-0 mega-title-badge">No</h6>
                                                    <p>Allow my offer to be redeemed multiple times by same customer</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="card">
                                            <div className="media p-20" style={{ paddingBottom: 3, paddingTop: 3 }}>
                                                <div className="radio radio-secondary mr-3 ml-2">
                                                    <input id="radio3" type="radio" name="offer_redeem_type" value="3" onChange={e => setoffer_redeem_type(e.target.value)} />
                                                    <label htmlFor="radio3"></label>
                                                </div>
                                                <div className="media-body" htmlFor="radio3">
                                                    <h6 className="mt-0 mega-title-badge">For n transactions</h6>
                                                    <p>Select "Number of time" offer can be redeemed by customer{`\n`}Eg. Offer can be redeemed maximum "3" times</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="card">
                                            <div className="media p-20">
                                                <div className="radio radio-secondary mr-3 ml-2">
                                                    <input id="radio4" type="radio" name="offer_redeem_type" value="4" onChange={e => setoffer_redeem_type(e.target.value)} />
                                                    <label htmlFor="radio4"></label>
                                                </div>
                                                <div className="media-body" htmlFor="radio4">
                                                    <h6 className="mt-0 mega-title-badge">After n transactions</h6>
                                                    <p>Select after how many transactions, offer can be redeemed by customer Eg. Offer can be redeemed after "3" transactions</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <FormGroup>
                                            <Label for="level_title">Offer Redeem</Label>
                                            <select className="form-control select" name="offer_redeem_type" id="offer_redeem_type" ref={register({ required: true })}>
                                                <option value="1">Yes, limit redemption to one per customer</option>
                                                <option value="2">No, allow my offer to be redeemed multiple times by same customer</option>
                                                <option value="3">Select "Number of time" offer can be redeemed by customer{`\n`}Eg. Offer can be redeemed maximum "3" times</option>
                                                <option value="4">Select after how many transactions, offer can be redeemed by customer Eg. Offer can be redeemed after "3" transactions</option>
                                            </select>
                                        </FormGroup> */}
                                    {offer_redeem_type == 3 || offer_redeem_type == 4 ?
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="level_title">
                                                    {offer_redeem_type == 3 ?
                                                        `Number of time offer can be redeemed by customer` :
                                                        `After how many transactions offer can be redeemed by customer`}
                                                </Label>
                                                <select className="form-control select" name="min_transaction" id="min_transaction" ref={register({ required: true })}>
                                                    <option value="2">2 times</option>
                                                    <option value="3">3 times</option>
                                                    <option value="4">4 times</option>
                                                    <option value="5">5 times</option>
                                                    <option value="6">6 times</option>
                                                    <option value="7">7 times</option>
                                                    <option value="8">8 times</option>
                                                    <option value="9">9 times</option>
                                                    <option value="10">10 times</option>
                                                </select>
                                                {/* <input className="form-control" type="number" name="min_transaction" ref={register({ required: true })} />
                                                <span>{errors.min_transaction && 'Minimum Transactions is required'}</span> */}
                                            </FormGroup>
                                        </Col>
                                        : null
                                    }

                                </Row>

                                <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="level_title">Minimum eligible order amount
                                                <p style={input_sub_title_style}>This lets you decide the total minimum order for the promotional discount to be applicable. In case you do not want to specify any particular order amount, you can leave this field empty</p>
                                            </Label>
                                            <input className="form-control" type="number" name="min_order_amount" ref={register({ required: true })} />
                                            <span>{errors.min_order_amount && 'Minimum eligible order amount is required'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="level_title">Offer Terms & Conditions</Label>
                                            <textarea className="form-control" rows="10" name="description" ref={register({ required: true })} ></textarea>
                                            <span style={{ color: '#ff5370' }}>{errors.description && 'Offer Terms & Conditions is required'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    {/* <span>{offer_title}</span> */}
                                    <ImageUploader
                                        withIcon={false}
                                        withPreview={true}
                                        label=''
                                        singleImage={true}
                                        buttonText="Offer Images"
                                        onChange={onDrop}
                                        imgExtension={[".jpg", ".gif", ".png", ".gif", ".svg"]}
                                        maxFileSize={1048576}
                                        fileSizeError=" file size is too big"
                                    />
                                    <span style={{ color: '#ff5370', justifyContent: 'center', display: 'flex' }}>{(showerrorstatus == true && image_data == null) ? 'Offer Image is required' : ""}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {!loadingbtn ?
                            <>
                                <Button color="default" type="button" onClick={props.toggleModal}>Cancel</Button>
                                <Button color="primary" type="submit" onClick={() => setshowerrorstatus(true)} >Save Offer</Button>
                            </>
                            :
                            <div className="loader-box pull-right" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 20, width: 20 }}></span></div>
                        }
                    </ModalFooter>
                </ModalBody>
            </form>
        </Modal>
    );
}

export default AddOfferModal;