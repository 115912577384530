import React, { Component, useEffect, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormGroup, Col, Row, Input, CustomInput } from 'reactstrap';
import useForm from "react-hook-form";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import * as actionCreators from "../../store/actions/index"
import { useDispatch, useSelector } from "react-redux";

const Editmodal = (props) => {

    let [urledit, seturledit] = useState('')
    const dispatch = useDispatch();
    const [id, setid] = useState('')
    let { handleSubmit, register, errors } = useForm();

    let handleCatInputChange = (e) => {
        let { value } = e.target;
        seturledit(value)
    }

    let onSubmit = form_data => {
        if (form_data != "") {
            console.log(form_data)
            client.post(api.edit_slider, { data: id, form_data: form_data.URL, type: 1 }, async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        dispatch(actionCreators.fetch_slider());
                        toast.success(response.message);
                    }
                }
            })
        }
        props.toggle()
    }

    useEffect(() => {
        if (props.open) {
            seturledit(props.url)
            setid(props.id)
        }
    }, [props])

    return (
        <div>
            <Modal isOpen={props.open} toggle={props.toggle} size="md" className="modal-body" centered={true}>
                <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={props.toggle}><p className="m-0 font-weight-bold">Edit Slider URL</p></ModalHeader>
                    <ModalBody>
                        <input className="form-control" type="text" name="URL" value={urledit} onChange={e => handleCatInputChange(e)} ref={register({ required: true })} />
                        <span>{errors.URL && 'URL is required'}</span>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" type="button" onClick={(e) => { seturledit('') }}>Reset</Button>
                        <Button color="primary" type="submit" onClick={(e) => { }}>Save</Button>
                    </ModalFooter>
                </form>
            </Modal>
        </div>
    )
}

export default Editmodal
