import React, { useEffect, Fragment, useState } from 'react'
import { toast } from 'react-toastify';
import useForm from "react-hook-form";
import { DisappearedLoading, CommonLoading } from "react-loadingg";
import { Col, Row, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { ImBin } from "react-icons/im";

const FeatureHeadingTable = (props) => {

    const {
        features,
        featureTitles,
        defaultTitleNames,
        updateFeatureTitle,
        result,
        deleteFeatureTitle,
        featureTitleLoading
    } = props;

    let { register } = useForm({ defaultValues: defaultTitleNames });

    return (
        <Fragment>
            <div className="row table_class">
                <div className="col-8">
                    <div className="table-responsive" >
                        <table className="table table-border-horizontal" >
                            <thead>
                                <tr style={{ background: '#f9fafe' }}>
                                    <th scope="col" style={{ width: "50%" }}>Feature title</th>
                                    <th scope="col" style={{ width: "85%" }}>Features</th>
                                    <th scope="col" style={{}}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {featureTitles.length > 0 ? featureTitles.map((currElem, currIndx) => {
                                    return (
                                        <tr>
                                            <td>
                                                <input
                                                    style={{
                                                        border: "#dfe8f1 solid 1px",
                                                        borderRadius: "3px",
                                                        padding: "10px",
                                                        fontSize: "0.75rem",
                                                        outlineColor: '#dfe8f1',
                                                        width: '80%'
                                                    }}
                                                    className="m-2"
                                                    type="text"
                                                    placeholder="Feature category name"
                                                    ref={register}
                                                    name={`${currElem._id}_${currElem.title_id}`}
                                                    onBlur={(event) => {
                                                        let inputValue = event.target.value
                                                        if (inputValue.length == 0) {
                                                            toast.error("Field Shouldn't be Empty", { autoClose: 2000, hideProgressBar: true });
                                                        } else if (inputValue.length > 0 && inputValue != currElem.custom_title) {
                                                            let postData = { ...currElem, custom_title: inputValue };
                                                            updateFeatureTitle(postData, 1)
                                                        }
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                {features.map(item => {
                                                    if (item.status == 1)
                                                        return (
                                                            <div className='d-flex align-items-start'>
                                                                <input
                                                                    type="checkbox"
                                                                    ref={register}
                                                                    name={`${currElem.title_id}_${item._id}`}
                                                                    id={`${item._id}_featureId`}
                                                                    className='mr-3 mt-1'
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            let postData = { feature_id: item._id, title: item.title, title_id: currElem.title_id }
                                                                            updateFeatureTitle(postData, 2)
                                                                        } else {
                                                                            let postData = { feature_id: item._id, title: item.title, title_id: '' }
                                                                            updateFeatureTitle(postData, 2)
                                                                        }
                                                                    }}
                                                                />
                                                                <span>{item.title}</span>
                                                            </div>
                                                        )
                                                })}
                                            </td>
                                            <td>
                                                <div
                                                    className='cursor-pointer d-flex justify-content-center'
                                                    onClick={() => { deleteFeatureTitle(currElem) }}
                                                >
                                                    <ImBin className="mt-0" color={"red"} size={15} />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }) : null
                                }
                            </tbody>
                            {/*  */}

                            {/* <tr>
                                    <td>
                                        <input
                                            style={{
                                                border: "#dfe8f1 solid 1px",
                                                borderRadius: "3px",
                                                padding: "10px",
                                                fontSize: "0.75rem",
                                                outlineColor: '#dfe8f1',
                                                width: '80%'
                                            }}
                                            className="m-2"
                                            type="text"
                                            placeholder="Feature category name"
                                            ref={register}
                                            name={featureTitles?.minisite?.title_id}
                                            onBlur={(e) => {

                                                let value = e.target.value
\                                                if (value.length == 0) {
                                                    toast.error("Field Shouldn't be Empty", { autoClose: 2000, hideProgressBar: true });
                                                } else if (value.length > 0 && value != featureTitles?.minisite?.custom_title) {
                                                    let postData = { ...featureTitles }
                                                    postData.minisite.custom_title = e.target.value
                                                    updateFeatureTitle(postData, 1)
                                                }
                                            }
                                            }
                                        />
                                    </td>
                                    <td>
                                        {features.map(item => {
                                            if (item.status == 1)
                                                return (
                                                    <div className='d-flex align-items-start'>
                                                        <input
                                                            type="checkbox"
                                                            ref={register}
                                                            name={`${featureTitles?.minisite?.title_id}_${item._id}`}
                                                            id={`${item._id}_featureId`}
                                                            className='mr-3 mt-1'
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    let postData = { feature_id: item._id, title: item.title, title_id: featureTitles?.minisite?.title_id }
                                                                    updateFeatureTitle(postData, 2)
                                                                } else {
                                                                    let postData = { feature_id: item._id, title: item.title, title_id: '' }
                                                                    updateFeatureTitle(postData, 2)
                                                                }
                                                            }}
                                                        />
                                                        <span>{item.title}</span>
                                                    </div>
                                                )
                                        })}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <input
                                            style={{
                                                border: "#dfe8f1 solid 1px",
                                                borderRadius: "3px",
                                                padding: "10px",
                                                fontSize: "0.75rem",
                                                outlineColor: '#dfe8f1',
                                                width: '80%'
                                            }}
                                            className="m-2"
                                            type="text"
                                            placeholder="Feature category name"
                                            ref={register}
                                            name={featureTitles?.ecommerce?.title_id}
                                            onBlur={(e) => {

                                                let value = e.target.value
                                                if (value.length == 0) {
                                                    toast.error("Field Shouldn't be Empty", { autoClose: 2000, hideProgressBar: true });
                                                } else if (value.length > 0 && value != featureTitles?.ecommerce?.custom_title) {
                                                    let postData = { ...featureTitles }
                                                    postData.ecommerce.custom_title = e.target.value
                                                    updateFeatureTitle(postData, 1)
                                                }
                                            }
                                            }
                                        />
                                    </td>
                                    <td>
                                        {features.map(item => {
                                            if (item.status == 1)
                                                return (
                                                    <div className='d-flex align-items-start'>
                                                        <input
                                                            type="checkbox"
                                                            ref={register}
                                                            name={`${featureTitles?.ecommerce?.title_id}_${item._id}`}
                                                            id={`${item._id}_featureId`}
                                                            className='mr-3 mt-1'
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    let postData = { feature_id: item._id, title: item.title, title_id: featureTitles?.ecommerce?.title_id }
                                                                    updateFeatureTitle(postData, 2)
                                                                } else {
                                                                    let postData = { feature_id: item._id, title: item.title, title_id: '' }
                                                                    updateFeatureTitle(postData, 2)
                                                                }
                                                            }}
                                                        />
                                                        <span>{item.title}</span>
                                                    </div>
                                                )
                                        })}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <input
                                            style={{
                                                border: "#dfe8f1 solid 1px",
                                                borderRadius: "3px",
                                                padding: "10px",
                                                fontSize: "0.75rem",
                                                outlineColor: '#dfe8f1',
                                                width: '80%'
                                            }}
                                            className="m-2"
                                            type="text"
                                            placeholder="Feature category name"
                                            ref={register}
                                            name={featureTitles?.payments?.title_id}
                                            onBlur={(e) => {

                                                let value = e.target.value
                                                if (value.length == 0) {
                                                    toast.error("Field Shouldn't be Empty", { autoClose: 2000, hideProgressBar: true });
                                                } else if (value.length > 0 && value != featureTitles?.payments?.custom_title) {
                                                    let postData = { ...featureTitles }
                                                    postData.payments.custom_title = e.target.value
                                                    updateFeatureTitle(postData, 1)
                                                }
                                            }
                                            }
                                        />
                                    </td>
                                    <td>
                                        {features.map(item => {
                                            if (item.status == 1)
                                                return (
                                                    <div className='d-flex align-items-start'>
                                                        <input
                                                            type="checkbox"
                                                            ref={register}
                                                            name={`${featureTitles?.payments?.title_id}_${item._id}`}
                                                            id={`${item._id}_featureId`}
                                                            className='mr-3 mt-1'
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    let postData = { feature_id: item._id, title: item.title, title_id: featureTitles?.payments?.title_id }
                                                                    updateFeatureTitle(postData, 2)
                                                                } else {
                                                                    let postData = { feature_id: item._id, title: item.title, title_id: '' }
                                                                    updateFeatureTitle(postData, 2)
                                                                }
                                                            }}
                                                        />
                                                        <span>{item.title}</span>
                                                    </div>
                                                )
                                        })}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <input
                                            style={{
                                                border: "#dfe8f1 solid 1px",
                                                borderRadius: "3px",
                                                padding: "10px",
                                                fontSize: "0.75rem",
                                                outlineColor: '#dfe8f1',
                                                width: '80%'
                                            }}
                                            className="m-2"
                                            type="text"
                                            placeholder="Feature category name"
                                            ref={register}
                                            name={featureTitles?.event_management?.title_id}
                                            onBlur={(e) => {

                                                let value = e.target.value
                                                if (value.length == 0) {
                                                    toast.error("Field Shouldn't be Empty", { autoClose: 2000, hideProgressBar: true });
                                                } else if (value.length > 0 && value != featureTitles?.event_management?.custom_title) {
                                                    let postData = { ...featureTitles }
                                                    postData.event_management.custom_title = e.target.value
                                                    updateFeatureTitle(postData, 1)
                                                }
                                            }
                                            }
                                        />
                                    </td>
                                    <td>
                                        {features.map(item => {
                                            if (item.status == 1)
                                                return (
                                                    <div className='d-flex align-items-start'>
                                                        <input
                                                            type="checkbox"
                                                            ref={register}
                                                            name={`${featureTitles?.event_management?.title_id}_${item._id}`}
                                                            id={`${item._id}_featureId`}
                                                            className='mr-3 mt-1'
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    let postData = { feature_id: item._id, title: item.title, title_id: featureTitles?.event_management?.title_id }
                                                                    updateFeatureTitle(postData, 2)
                                                                } else {
                                                                    let postData = { feature_id: item._id, title: item.title, title_id: '' }
                                                                    updateFeatureTitle(postData, 2)
                                                                }
                                                            }}
                                                        />
                                                        <span>{item.title}</span>
                                                    </div>
                                                )
                                        })}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <input
                                            style={{
                                                border: "#dfe8f1 solid 1px",
                                                borderRadius: "3px",
                                                padding: "10px",
                                                fontSize: "0.75rem",
                                                outlineColor: '#dfe8f1',
                                                width: '80%'
                                            }}
                                            className="m-2"
                                            type="text"
                                            placeholder="Feature category name"
                                            ref={register}
                                            name={featureTitles?.pos?.title_id}
                                            onBlur={(e) => {

                                                let value = e.target.value
                                                if (value.length == 0) {
                                                    toast.error("Field Shouldn't be Empty", { autoClose: 2000, hideProgressBar: true });
                                                } else if (value.length > 0 && value != featureTitles?.pos?.custom_title) {
                                                    let postData = { ...featureTitles }
                                                    postData.pos.custom_title = e.target.value
                                                    updateFeatureTitle(postData, 1)
                                                }
                                            }
                                            }
                                        />
                                    </td>
                                    <td>
                                        {features.map(item => {
                                            if (item.status == 1)
                                                return (
                                                    <div className='d-flex align-items-start'>
                                                        <input
                                                            type="checkbox"
                                                            ref={register}
                                                            name={`${featureTitles?.pos?.title_id}_${item._id}`}
                                                            id={`${item._id}_featureId`}
                                                            className='mr-3 mt-1'
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    let postData = { feature_id: item._id, title: item.title, title_id: featureTitles?.pos?.title_id }
                                                                    updateFeatureTitle(postData, 2)
                                                                } else {
                                                                    let postData = { feature_id: item._id, title: item.title, title_id: '' }
                                                                    updateFeatureTitle(postData, 2)
                                                                }
                                                            }}
                                                        />
                                                        <span>{item.title}</span>
                                                    </div>
                                                )
                                        })}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <input
                                            style={{
                                                border: "#dfe8f1 solid 1px",
                                                borderRadius: "3px",
                                                padding: "10px",
                                                fontSize: "0.75rem",
                                                outlineColor: '#dfe8f1',
                                                width: '80%'
                                            }}
                                            className="m-2"
                                            type="text"
                                            placeholder="Feature category name"
                                            ref={register}
                                            name={featureTitles?.others?.title_id}
                                            onBlur={(e) => {

                                                let value = e.target.value
                                                if (value.length == 0) {
                                                    toast.error("Field Shouldn't be Empty", { autoClose: 2000, hideProgressBar: true });
                                                } else if (value.length > 0 && value != featureTitles?.others?.custom_title) {
                                                    let postData = { ...featureTitles }
                                                    postData.others.custom_title = e.target.value
                                                    updateFeatureTitle(postData, 1)
                                                }
                                            }
                                            }
                                        />
                                    </td>
                                    <td>
                                        {features.map(item => {
                                            if (item.status == 1)
                                                return (
                                                    <div className='d-flex align-items-start'>
                                                        <input
                                                            type="checkbox"
                                                            ref={register}
                                                            name={`${featureTitles?.others?.title_id}_${item._id}`}
                                                            id={`${item._id}_featureId`}
                                                            className='mr-3 mt-1'
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    let postData = { feature_id: item._id, title: item.title, title_id: featureTitles?.others?.title_id }
                                                                    updateFeatureTitle(postData, 2)
                                                                } else {
                                                                    let postData = { feature_id: item._id, title: item.title, title_id: '' }
                                                                    updateFeatureTitle(postData, 2)
                                                                }
                                                            }}
                                                        />
                                                        <span>{item.title}</span>
                                                    </div>
                                                )
                                        })}
                                    </td>
                                </tr> */}
                            {/*  */}

                            {/* <tbody>
                                {
                                    result && result.length > 0 ? result.map((item, index) => {
                                        return (
                                            <tr key={index + 1}>
                                                <td>
                                                    <input
                                                        style={{
                                                            border: "#dfe8f1 solid 1px",
                                                            borderRadius: "3px",
                                                            padding: "10px",
                                                            fontSize: "0.75rem",
                                                            outlineColor: '#dfe8f1',
                                                            textTransform: "capitalize"
                                                            // width: "90px"
                                                        }}
                                                        className="m-2"
                                                        type="text"
                                                        placeholder="Category name"
                                                        name={`${item._id}_subCatTitle`}
                                                        ref={register}
                                                        onBlur={(event) => {

                                                            let getValue = event.target.value;
                                                            if (getValue.length == 0) {
                                                                toast.error("Field Shouldn't be Empty", { autoClose: 2000, hideProgressBar: true });
                                                            } else if (getValue.length > 0 && getValue != item.subCatTitle) {
                                                                let postData = { ...item, subCatTitle: event.target.value, updateType: 1 }
                                                                update_subscriprion_cat(postData);
                                                            }
                                                        }}
                                                    />
                                                </td>
                                                <td>

                                                    {
                                                        subsResult.map((currnSubCat, currIndex) => {

                                                            return (
                                                                <>
                                                                    <div className="d-flex align-items-center">
                                                                        <input
                                                                            className="mr-3"
                                                                            type="checkbox"
                                                                            id={`${item.cat_Id}_${currnSubCat._id}_subs`}
                                                                            name={`${item.cat_Id}_${currnSubCat._id}_subscriptionName`}
                                                                            ref={register}
                                                                            onChange={(event) => {

                                                                                currnSubCat.subscription_category.forEach((currElem, currIndex) => {

                                                                                    if (currElem.id == item.cat_Id) {
                                                                                        currnSubCat.subscription_category[currIndex].enable = event.target.checked;
                                                                                    }
                                                                                })
                                                                                console.log(currnSubCat.subscription_category, "current upadte data")
                                                                                let postData = { subscriptionData: currnSubCat, updateType: 2 }
                                                                                update_subscriprion_cat(postData);
                                                                            }}
                                                                        />
                                                                        <label for={`${item.cat_Id}_${currnSubCat._id}_subs`} className="mb-0">{currnSubCat.title}</label>
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }

                                                </td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <span
                                                            style={{ marginRight: "1rem", fontSize: "1rem", color: "red" }}
                                                            onClick={() => {

                                                                let postData = { ...item, updateType: 3 }
                                                                setSelectedData(postData);
                                                                setDeleteCategory(true);

                                                            }}>
                                                            <i className="fa fa-trash"></i>
                                                        </span>
                                                        {
                                                            loadingItemp && loadingItemp == item._id ?
                                                                <ClipLoader size={20} color="#36D7B7" loading={true} />
                                                                : <Switch
                                                                    className="react-switch"
                                                                    height={20}
                                                                    width={40}
                                                                    onChange={(checked) => {
                                                                        let postData = { ...item, enableCategory: checked, updateType: 4 }
                                                                        update_subscriprion_cat(postData);
                                                                    }}
                                                                    checked={item.enableCategory == true ? true : false}
                                                                />
                                                        }
                                                    </div>
                                                </td>

                                            </tr>
                                        )
                                    })
                                        : null
                                }
                            </tbody> */}
                        </table>
                    </div>

                    {/* {
                        result.length == 0 && <div style={{ height: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <p style={{ fontSize: "15px", fontWeight: "500" }}>No record found !</p>
                        </div>
                    } */}

                </div>
            </div>
        </Fragment>
    )
}

export default FeatureHeadingTable;