import { Checkbox, FormControlLabel, IconButton } from "@material-ui/core";
import React, { useRef } from "react";
import { Input, Table } from "reactstrap";
import { withStyles } from "@material-ui/core/styles";
import { teal, grey } from "@material-ui/core/colors";
// import "../createRoleModal/createRoleModal.css";
import "./rolesTable.scss";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";


const RolesTable = ({ Roles, toggleEdit, toggleDelete }) => {

	const arrangedRoles = [...Roles.filter((val) => val.notEditable === true), ...Roles.filter((val) => val.notEditable === false)]
	// const classes = useStyles();
	const ReverseRoles = Roles; //Roles.reverse()
	const ref = useRef(null);


	const TealCheckbox = withStyles({
		root: {
			color: grey[500],
			"&$checked": {
				color: teal["A400"],
			},
		},
		checked: {},
	})((props) => <Checkbox color="default" style={{ height: "25px" }} {...props} />);

	const tableHeader = arrangedRoles.map((item) => {
		// Roles.reverse().map((item) => {
		return (
			<th key={item.roleName} className="roles_table_header" style={{ borderBottom: 'none' }}>
				{item.notEditable ? (
					<div className="py-2 mx-auto" style={{ textAlign: "center", fontWeight: 500, color: 'black', display: "flex", height: '40px', alignItems: "center", justifyContent: "center", fontSize: '14px', width: arrangedRoles.length > 4 ? '8vw' : '100%' }}>
						{item.roleName}
					</div>
				) : (
					<div className="py-2 mx-auto" style={{ textAlign: "center", fontWeight: 500, color: 'black', display: "flex", height: '40px', alignItems: "center", justifyContent: "center", fontSize: '14px', width: arrangedRoles.length > 4 ? '8vw' : '100%' }}>
						{item.roleName}
						<IconButton
							style={{ padding: "4px" }}
							onClick={() => { toggleEdit(item); }}
						>
							<EditIcon style={{ fontSize: "17px" }} />
						</IconButton>
						<IconButton
							style={{ padding: "4px" }}
							onClick={() => { toggleDelete(item); }}
						>
								<DeleteIcon style={{ fontSize: "17px" }} />
								
						</IconButton>
					</div>
				)}
			</th>
		);
	});
	// console.log(arrangedRoles, "arrangedRoles");

	const tableBodyUpdated = arrangedRoles[0]?.accessibleFeatures?.map((val, index) => {
		// let d = `<tr key=${index} style="border: 1px solid lightgrey;"><td align="left" style="font-size: 14px; color: black; background-color: #ECEEF2; font-weight: 500; border-right: 0; padding-left: 20px;" className="w-100 font-weight-bold">${val.optionName}</td>`
		let d = `<tr key=${index} style="border: 1px solid lightgrey;"><td align="left" style="font-size: 14px; color: black; background-color: #f1f1f1; font-weight: 500; border-right: 0; padding: 20px !important;" className="w-100 font-weight-bold">${val.optionName}</td>`
		arrangedRoles.forEach((val) => {
			d += `<td style="background-color: #f1f1f1;"></td>`
		})
		d += `</tr>`;
		val.action.forEach((valUnique) => {
			// console.log(val)
			let rendered = false
			// console.log(valUnique.name.toLowerCase().replace(/\s/g, '_'),'my_link')
			// let c = `<tr><td align="left" style="color: black; padding-left: 20px;"><div style="width: 200px; white-space: normal;">${valUnique.name}</div></td>`;
			let c = `<tr><td class='roles_table_body' align="left" style="color: black; padding: 10px;"><div style="width: 200px; white-space: normal;">${valUnique.name}</div></td>`;
			arrangedRoles.forEach((value) => {
				let keyW = false;
				if (value.accessibleFeatures.filter(a => a.optionCode == val.optionCode).length > 0) {

					value.accessibleFeatures.forEach((val1) => {

						// c += `<td>${JSON.stringify(val1)}</td>`

						if (!keyW && val1.optionCode === val.optionCode) {
							if (val1.action.filter(a => a.code == valUnique.code).length > 0) {
								val1.action.forEach((val2, index) => {

									// if(val.action)
									if (val2.code === valUnique.code) {
										let isChecked = "";
										if (val2.check) {
											isChecked = "checked";
										}
										// <input type="checkbox" style="height: 18px; width: 18px; accent-color: #008000; vertical-align: middle;" ${isChecked} onclick="onchageInput('${value.roleName}', '${val1.optionCode}', '${valUnique.code}');"/>
										let b = `<td style="text-align: center; vertical-align: middle; ${arrangedRoles.length > 4 ? 'width:8vw;' : 'width:100%;'}" key=${index}>
									${isChecked ? `<div onclick="onchageInput('${value.roleName}', '${val1.optionCode}', '${valUnique.code}')"><svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" style="
    color: #018001;
"><path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path></svg></div>` :  `<div onclick="onchageInput('${value.roleName}', '${val1.optionCode}', '${valUnique.code}')"><svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path></svg></div>`}
									
										</td>`;
										c += b;
										keyW = true;
										rendered = true
									}
								});
							} else {
								let b = `<td style="text-align: center; vertical-align: middle; ${arrangedRoles.length > 4 ? 'width:8vw;' : 'width:100%;'}" ><input type="checkbox" style="height: 18px; width: 18px; accent-color: #008000; vertical-align: middle;"  onclick="onchageInput('${value.roleName}', '${val1.optionCode}', '${valUnique.code}')/></td>`;
								c += b;
							}
						}
					});
				} else {
					let b = `<td style="text-align: center; vertical-align: middle; ${arrangedRoles.length > 4 ? 'width:8vw;' : 'width:100%;'}" ><input type="checkbox" style="height: 18px; width: 18px; accent-color: #008000; vertical-align: middle;"  onclick="return false;"/></td>`;
					c += b;
				}
			});

			d += `</tr>`;
			d += c;
		});
		return <tbody style={{ borderTop: 'none' }} dangerouslySetInnerHTML={{ __html: d }} />;
	})

	const onForwardClick = (offsetAmount) => {
		ref.current.scrollLeft += offsetAmount;
	};

	return (
		<div>
			<div className="component" style={{ width: "100%" }}>
				
				<div
					style={{
						overflowX: "scroll",
						display: "block",
						width: "100%",
						scrollBehavior: "smooth",
					}}
					ref={ref}
				>
					<Table className="table--bordered" style={{ border: '1px solid lightgrey' }}>
						<thead>
							<tr>
								<th style={{ borderBottom: 'none' }}>

									{arrangedRoles.length > 5 && <div className="d-flex justify-content-end" style={{ marginTop: '-33px' }}>
										<IconButton
											className="p-2"
											onClick={() => { onForwardClick(-240); }}
										>
											<ArrowBackIosIcon style={{ fontSize: "12px" }} />
										</IconButton>
										<IconButton
											className="p-2"
											onClick={() => { onForwardClick(240); }}
										>
											<ArrowForwardIosIcon style={{ fontSize: "12px" }} />
										</IconButton>
									</div>}

									
								</th>

								{tableHeader}
							</tr>
							
						</thead>
						{tableBodyUpdated}
					</Table>
				</div>
			</div>
		</div>
	);
};

export default RolesTable;
