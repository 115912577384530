import React, { Fragment, useState, useEffect } from "react";
import SquareDropzone from "../dropzone/SquareDropZoneField";
import RectDropZone from "../dropzone/RectDropZoneField";
import FevIcon from "../dropzone/FeviconZoneField";
import { DisappearedLoading } from "react-loadingg";
import useForm from "react-hook-form";
import { toast } from "react-toastify";
import TestInputType from "../../logoAndBusinessComp/dropzone/TestInputType";

const LogoAndFevicon = ({
    showData,
    getAllData,
    fetchBusinessTypeData,
    defaultVaue,
    update_Data
}) => {

    const [getData, seGetData] = useState(null);
    let { register } = useForm({ defaultValues: defaultVaue });

    console.log(showData, "showData");

    const [files, setFile] = useState(null);

    const updateMetaDescription = (data, updateType) => {
        update_Data(data, updateType);
    }

    return (
        <Fragment>
            {
                showData ?
                    <div className="container-fluid main_div px-0 m-4">
                        <form noValidate="" className="w-100">
                            <div className="">
                                <h4 style={{ fontWeight: "600", fontSize: "14px", marginBottom: "1rem" }}>Logo & Fevicon</h4>
                                <div className="table-responsive" style={{ width: '80%' }}>
                                    <table className="table table-border-horizontal">
                                        <thead>
                                            <tr style={{ background: '#f9fafe', width: "100%" }}>
                                                <th scope="col">Square Logo</th>
                                                <th scope="col">Square Logo</th>
                                                <th scope="col">Rectangle Logo</th>
                                                <th scope="col">Fevicon</th>
                                                <th scope="col">Meta Title</th>
                                                <th scope="col">Description</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                showData && showData.logo_fevicon.map((currElem, currInd) => {

                                                    if (showData.brand_ID == 3 && currElem.portalName == "front_side") {

                                                    } else {
                                                        return (
                                                            <>
                                                                <tr key={currInd}>
                                                                    <td scope="row">
                                                                        {
                                                                            currInd == 0 ? showData?.website_URL?.third_URL?.slice(8)
                                                                                : currInd == 1 ? showData?.website_URL?.first_URL?.slice(8)
                                                                                    : currInd == 2 ? showData?.website_URL?.secondURL?.slice(8)
                                                                                        : null
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <SquareDropzone
                                                                            name={`${currInd}_square_logo`}
                                                                            logoName={`square_logo`}
                                                                            imageData={currElem.squre_logo}
                                                                            currData={currElem}
                                                                            currentPortalData={showData}
                                                                            getAllData={getAllData}
                                                                            fetchBusinessTypeData={fetchBusinessTypeData}
                                                                        />
                                                                    </td>
                                                                    <td >

                                                                        {
                                                                            currElem.portalName == "portal" ?
                                                                                <RectDropZone
                                                                                    name={`${currInd}_rectangle_logo`}
                                                                                    logoName={`rectangle_logo`}
                                                                                    imageData={currElem.rectangle_logo}
                                                                                    currData={currElem}
                                                                                    currentPortalData={showData}
                                                                                    getAllData={getAllData}
                                                                                    fetchBusinessTypeData={fetchBusinessTypeData}
                                                                                /> : "---"
                                                                        }

                                                                    </td>
                                                                    <td >
                                                                        <FevIcon
                                                                            name={`${currInd}_fev_icon`}
                                                                            logoName={`fev_icon`}
                                                                            imageData={currElem.fev_icon}
                                                                            currData={currElem}
                                                                            currentPortalData={showData}
                                                                            getAllData={getAllData}
                                                                            fetchBusinessTypeData={fetchBusinessTypeData}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            style={{
                                                                                border: "#dfe8f1 solid 1px",
                                                                                borderRadius: "3px",
                                                                                padding: "10px",
                                                                                fontSize: "0.75rem",
                                                                                outlineColor: '#dfe8f1',
                                                                                width: "9rem"
                                                                            }}
                                                                            type="text"
                                                                            placeholder=""
                                                                            name={`${currElem._id}-meta_title`}
                                                                            ref={register}
                                                                            onBlur={(event) => {

                                                                                let getValue = event.target.value;
                                                                                if (getValue.length == 0) {
                                                                                    toast.error("Field Shouldn't be Empty", { autoClose: 2000, hideProgressBar: true });
                                                                                } else if (getValue.length > 0 && getValue != currElem.meta_title) {
                                                                                    let postData = { ...currElem, meta_title: event.target.value }
                                                                                    let updateType = "metaTitle_desc";
                                                                                    updateMetaDescription(postData, updateType)
                                                                                    // update_Data(postData);
                                                                                }
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <textarea
                                                                            className="form-control textAreaa"
                                                                            maxlength="200"
                                                                            placeholder=""
                                                                            rows={4} cols={60}
                                                                            name={`${currElem._id}-description`}
                                                                            ref={register}
                                                                            onBlur={(event) => {

                                                                                let getValue = event.target.value;
                                                                                if (getValue.length == 0) {
                                                                                    toast.error("Field Shouldn't be Empty", { autoClose: 2000, hideProgressBar: true });
                                                                                } else if (getValue.length > 0 && getValue != currElem.description) {
                                                                                    let postData = { ...currElem, description: event.target.value }
                                                                                    let updateType = "metaTitle_desc";
                                                                                    updateMetaDescription(postData, updateType)
                                                                                }
                                                                            }}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    }

                                                })
                                            }
                                            {/* <td>
                                                <TestInputType />
                                            </td> */}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </form>
                    </div>
                    : <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                        <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                            <DisappearedLoading size="medium" color="#514F4E" />
                        </div>
                    </div>
            }
        </Fragment>
    )
}

export default LogoAndFevicon