import React, { Fragment } from "react";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import {  toast } from "react-toastify";
import { DisappearedLoading } from "react-loadingg";
import { Col, Container, Row, Table, Button, Input, Label, Card, CardBody, ButtonToolbar, Modal } from "reactstrap";
import StripeLogo from "../../assets/images/stripe-logo.png";
import CheckIcon from "@material-ui/icons/Check";
import bankIcon from "../../assets/images/bank-icon.png";

class StripeConnect extends React.Component {
    constructor(props) {
        super(props);
        console.log('neha', this.props.merchant_info.adminId);
        this.state = {
            merchant_id: this.props.merchant_id,
            adminId: this.props.merchant_info.adminId,
            company_id: this.props.merchant_info.company_id,
            merchant_info: null,
            loading: true,
            connect_status: 0,
            stripeAccount: null,
            accountCreated: false,
            payoutEnabled: false,
            payoutSetting: null,
            accountData: [],
            bankDetails: false,
            defaultLoading: '',
        };
    }

    componentDidMount() {
         
        this.getMerchantInfo();
        this.fetchConnectAccount();
        this.fetchAllPayoutData();
    }

    setConnectStatus(stripe_connect) {

        try{
            if (stripe_connect && stripe_connect.status == 1) {
                this.setState({connect_status : 1})
            }
            
        } catch {
            this.setState({connect_status : 0})
        }
        
    }

    fetchAllPayoutData = async () => {
	    let postdata = { companyId: this.state.company_id };
	    let current = this;
        current.setState({ loading: true }); 

	    client.put(api.fetchBankAccount, postdata, (error, response) => {
		    if (!error) {
			    if (!response.error) {
                  //  dispatch(successPayoutData({ message: response.message, data: response.result.data }));
                    current.setState({ accountData: response.result.data });
			    } else {
				   // dispatch(failedPayoutData({ message: response.message }));
			    }
		    } else {
			   // dispatch(failedPayoutData({ message: response.message }));
            }
            current.setState({ loading: false });
	    });
    };

    fetchConnectAccount = async () => {

        let postdata = { user_id: this.state.adminId };
	    let current = this;
        current.setState({ loading: true }); 

		client.post(api.stripe_account_user, postdata, (error, response) => {
				if (!error) {
					if (typeof response.error !== "undefined") {
						try {
							if (!response.error) {
								current.setState({ accountCreated: true });
                current.setState({ payoutSetting: response.payout_setting });

								if (!response.set_setting) {
								//	setPayoutEnabled(response.result.payouts_enabled);
								//	props.setPayoutStatus(response.result.payouts_enabled);
								//	setstripeAccount(response.result.account);
                //  if (response.result.requirements) setstripeAccountReq(response.result.requirements);
                  
                  current.setState({ payoutEnabled: response.result.payouts_enabled });
                  
                  current.setState({ stripeAccount: response.result.account });
								}
							} else {
								// console.log(response);
							}
						} catch (error) {
							// alert("error")
						}
					} else {
						//console.log();
					}
				} else {
					//console.log(response);
				}
				current.setState({ loading: false });
			}
		);
	};



    getMerchantInfo = () => {

        let postdata = { merchant_id: this.state.merchant_id };

        client.post(api.stripe_account, postdata, (error, response) => {
            if (!error) {
                console.log(response);
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            this.setState({
                                merchant_info: response.result.contactDetails
                            })
                            if(response.result.contactDetails)
                            this.setConnectStatus(response.result.contactDetails.stripe_connect)
                        }

                    } catch (e) {
                        console.log(e);
                        setTimeout(() => {
                            toast.error("Internal error occured. Please contact support");
                        }, 200);
                    }
                } else {
                    setTimeout(() => {
                        toast.error("Internal error occured. Please contact support");
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
            this.setState({ loading: false})

        });
    };

    fetch_country = (country_code) => {
		client.post(api.countries_list, { country_code }, async (error, response) => {
			if (!error) {
				try {
					if (!response.error) {
						return response.result.data;
					}
				} catch (e) { }
			} else {
				// toast.error("Internal error occured.Please contact support");
			}
		});

	}

    render() {
        
        let { loading, connect_status, stripeAccount, accountCreated, payoutEnabled, payoutSetting, accountData, defaultLoading} = this.state;

        return (
            <Fragment>
                {!loading ?
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-xl-12 xl-100">
                                <div className="card">
                                    {payoutEnabled ? (
                                        <Col lg={12} md={9} sm={12} className={"border pt-2 rounded mr-2"}>
										<div>
											<div className={"d-flex px-3 py-2 align-items-center"}>
												<Col sm={2} xs={2} className={"p-0 "} style={{ maxWidth: "14.66%" }}>
													<div
														style={{
															width: "80px",
															height: "80px",
															display: "flex",
															justifyContent: "center",
															alignItems: "center",
														}}
													>
														<img
															src={StripeLogo}
															style={{
																width: "100%",
																height: "100%",
																objectFit: "cover",
																borderRadius: 12,
															}}
															alt=""
														/>
													</div>
												</Col>
												<Col sm={8} xs={8} style={{ paddingLeft: 0 }}>
													<p className={"bold-text"} style={{margin: '0px'}}>
														{`${payoutSetting?.bank_details?.account_number?.slice(
															0,
															2
														)}XXXX${payoutSetting?.bank_details?.account_number?.slice(-4)}`}
													</p>
													{/* <div style={{ color: "grey" }}>
															Account is under review
														</div> */}
													<a style={{ color: "#4ce1b6" }} href=" https://stripe.com/docs/payouts">
														<u>https://stripe.com/docs/payouts</u>
													</a>
												</Col>
												<Col sm={2} xs={2} className={"p-0"}>
													<div style={{ color: "#4ce1b6" }}>
														<b> Linked</b>
													</div>
												</Col>
											</div>
											<div style={{ backgroundColor: "#f5f5f5" }} className={" px-3 py-3 align-items-center "}>
												
												<Col sm={5} xs={5} className={"p-0"}>
													<p style={{margin: '0px'}}>
														<CheckIcon style={{ color: "green", marginRight: "2px", verticalAlign: 'middle' }} />
														Payout Enabled
													</p>
												</Col>
											</div>
										</div>
                                        </Col>
									) : (
										null
                                    )} 
                                    
                                
                                    
                                    <Col lg={12} md={9} sm={12} >
                                        {accountData && accountData.length > 0 ? (
                                            <div className={"mt-2 pt-2 pb-2"}>
								        <Col lg={8} md={8} sm={12} className=" d-flex justify-content-between px-0 mx-0">
									        <p className={"bold-text"} style={{ fontSize: "15px", margin: '0px' }}>
										        BANK ACCOUNT
									        </p>
								        </Col>
                                    </div>
                                        )
                                        : (null)}
                                      
                                    {accountData && accountData.length > 0 ? (
								<>
									{accountData.map((acc) => {

										let accountNumber = acc.bank_details.account_number;
										var lastFourNumber = accountNumber.substring(accountNumber.length - 4);

										const country = this.fetch_country(acc.bank_details.country);
										return (
											<div className="pb-3">
												<Col lg={12} md={8} sm={12} className="py-3 border rounded">
													<div
														style={{
															display: "flex",
															flexDirection: "row",
															width: "100%",
															alignItems: "center",
                                                            justifyContent: "space-between",
                                                            padding: '5px'
														}}
													>
														<div className="" style={{ width: "75%" }}>
															<div
																style={{
																	width: "40px",
																	height: "40px",
																	display: "inline-block",
																}}
															>
																<img
																	src={bankIcon}
																	style={{
																		width: "100%",
																		height: "100%",
																		objectFit: "contain",
																		borderRadius: 12,
																	}}
																	alt=""
																/>
															</div>
															<span style={{ marginLeft: "10px", verticalAlign: 'middle' }}>
																{acc?.bank_details.bank_name} &nbsp;{accountNumber} / {acc?.bank_details.routing_number}
																&nbsp;&nbsp;{country?.country_flag}
															</span>
														</div>
														{
															<div className="d-flex" style={{ width: "25%" }}>
																<div style={{ marginRight: "20px", background: "#e5e5e5", padding: "0 5px" }}>{acc?.bank_details.currency}</div>
																<div>
																	<div>
																					{
																						acc.bank_details.default ? (
																							<span>Default</span>
																						) : (
																							null
																						)
																					}
																				</div>
																</div>

															</div>
														}
													</div>
												</Col>
												<br />
											</div>
										);
									})}
								</>) : (
								null
							)}
                                </Col>
                                    

                                
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                        <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                            <DisappearedLoading size="medium" color="#514F4E" />
                        </div>
                    </div>
                }

            </Fragment>
        );
    }
}

export default StripeConnect;


