import * as actionTypes from "../actions/actionTypes";

const initState = {
    restaurantStructure: null,
    loading: null,
    error: null,
    successMsg: null,
    errorMsg: null
};


const reservationStructure = (state = initState, actions) => {

    switch (actions.type) {
        case actionTypes.FETCH_RESTAURANT_STRUCTURE_START:
            return {
                ...state,
                loading: true
            }

        case actionTypes.FETCH_RESTAURANT_STRUCTURE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                successMsg: actions.successMsg,
                restaurantStructure: actions.restaurantStructure
            }

        case actionTypes.FETCH_RESTAURANT_STRUCTURE_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
                errorMsg: actions.errorMsg
            }

        default:
            return state;
    }

}

export default reservationStructure;