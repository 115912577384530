import { MenuItem, Select } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";


function AffiliateTier(props) {

    const [selectedTier,setSelectedTier]=useState(props?.merchant_info?.tierLevel)

    

    useEffect(()=>{

        if(sessionStorage.getItem('tierLevel'))
        {
            setSelectedTier(sessionStorage.getItem('tierLevel'))
        }

    },[])


    function updateStoretier(e)
    {
        e.preventDefault()

        let postData={
			tierLevel:selectedTier,
            merchant_id:props?.merchant_info?._id,


		}

        

		client.post(api.update_tier_level, postData, async (error, response) => {
			if (!error) {
				if (!response.error) {
			
                    toast.success('Updated SuccessFully')
					sessionStorage.setItem('tierLevel',selectedTier)
					
				} else {
					toast.error(response.message);
				}
			} else {
				toast.error("Internal Error Occured. Please contact internal support.")
			}
		});



    }
    console.log(props)





    const tierOptions=[
        {
            label:'Community Ambassador 20%',
            bg:'#D3F4F8',
            value:4
        },
        {
            label:'Influencer Collaborator 30%',
            bg:'#D8D8D8',

            value:3
        },
        {
            label:'Strategic Partner 40%',
            bg:'#FBD5B3',

            value:2
        },
        {
            label:'Premier Partner 50%',
            bg:'#FFECB9',

            value:1
        },
    ]


    return ( 
        <>
        <div className="container-fluid pb-5">
            <div className="row">

                <div className="col-12">
                    <form onSubmit={e=>updateStoretier(e)} className="py-4 px-2">

                        <div className="d-flex align-items-center" style={{gap:'30px'}}>
                           
                           <span>Store Tier</span>

                           <Select
                           value={selectedTier}
                           onChange={e=>setSelectedTier(e.target.value)}
                           style={{background:tierOptions?.filter(elem=>elem.value==selectedTier)[0].bg}}
              className={"selectTier"}
>
                            
                            {
                                tierOptions?.map((elem,i)=>{
                                    return(
                                        <MenuItem value={elem.value} className={selectedTier==elem.value && `d-none`} style={{background:elem.bg,padding:'.4rem',fontSize:'.8rem'}}> <span style={{fontWeight:600,marginRight:'.3rem'}}>Tier {elem.value}:</span>{elem.label}</MenuItem>
                                    )
                                })
                            }
                            
                            </Select>
                           </div>

                           <button type="Submit" className="mt-4 update_tier_btn " style={{padding:'.4rem 1rem',background:'#327ebc',color:'white',border:'none',fontSize:'.9rem',borderRadius:'5px'}}>Update</button>
                    </form>
                </div>

            </div>
        </div>
        </>
     );
}

export default AffiliateTier;