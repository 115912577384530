import React, { Component, Fragment } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink as NavLinkTab } from "reactstrap";
import { NavLink, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup, Col, Row, FormFeedback, CustomInput } from 'reactstrap';

import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import "../../table.css";
import Breadcrumb from "../../components/common/breadcrumb";
import swal from 'sweetalert';

class CrownsPoints extends Component {
    state = {
        //activeTab: "1"
        booking_fees: 0,
        tabIndex: 0,
        modal: false,
        addWeightModal: false,
        total_charges: 0,
        loadingbtn: false,
        
        weight_charges_charges: "",
        weight_charges_title: "",
        weight_charges_weight: "",
        distance: "",
        pick_up_charges: "",
        drop_off_charges: "",
        night_charges: "",
        charges_data: [],
        
        access_level: localStorage.getItem("accessLevel"),
    }

    componentDidMount() {
        this.refreshPage();
    }

    toggle = () => {
        this.setState(prevState => {
            return {
                modal: !prevState.modal,
            }
        });
    }

    toggleWeightModal = () => {
        this.setState(prevState => {
            return {
                addWeightModal: !prevState.addWeightModal,
            }
        });
    }

    oninputchange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value })
    }

   async delete_delivery_charges(id, charge_type) {
        

    let willDelete = await swal({
        title: "Are you sure?",
        text: "",
        icon: "warning",
        buttons: {
          cancel: true,
          confirm: {
            text: "Yes, Please!",
            closeModal: false,
          },
        },
        dangerMode: true,
      })
  
      if (willDelete) {
        let postdata = { id: id, charge_type : charge_type };
        let current = this;
        let message = "Internal error occured. Please contact support";
        let status = 0;
        current.setState({ loading: true });
        client.post(api.delete_dartcharges, postdata, async function (error, response) {
          if (!error) {
            // alert(JSON.stringify(response));
            if (typeof response.error !== "undefined") {
              try {
                if (!response.error) {
                  status = 1;
                  message = "Charges deleted succefully"
                } else {
                  status = 0;
                  message = response.message
                }
              } catch (e) {
              }
            }
          }
  
          swal.close();
          swal(message);
          if (status == 1)
            current.refreshPage();
        });
      }
    }

    submitHandler = (event) => {
        event.preventDefault();

        this.setState({ loadingbtn: true })
        const postdata = {
            distance: this.state.distance,
            pickup_charges: this.state.pick_up_charges,
            dropoff_charges: this.state.drop_off_charges,
            night_charges: this.state.night_charges
        };

        client.post(api.add_delivery_charges, postdata, (error, response) => {
            if (!error) {
                if (!response.error) {
                    this.toggle();
                    this.refreshPage();
                    toast.success(response.message);
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);
                }

            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
            this.setState({ loadingbtn: false })
        });
    }

    saveWeightCharges = (event) => {
        event.preventDefault();

        this.setState({ loadingbtn: true })
        const postdata = {
            charges: this.state.weight_charges_charges,
            title: this.state.weight_charges_title,
            weight: this.state.weight_charges_weight,
        };

        client.post(api.add_weight_charges, postdata, (error, response) => {
            if (!error) {
                if (!response.error) {
                    this.toggleWeightModal();
                    this.refreshPage();
                    toast.success(response.message);
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);
                }

            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
            this.setState({ loadingbtn: false })
        });
    }

    refreshPage() {
        
        client.post(api.get_all_charges, {}, (error, response) => {
            if (!error) {
                if (!response.error) {

                    this.setState({ charges_data: response.result.data, weight_charges_data : response.result.weightdata })
                    // this.refreshPage();
                    // toast.success(response.message);
                } else {
                    // setTimeout(() => {
                    //     toast.error(response.message);
                    // }, 200);
                }

            } else {
                // setTimeout(() => {
                //     toast.error("Internal error occured. Please contact support");
                // }, 200);
            }
        });
    }
    
    renderAddNewLevelModal = () => {
        return (
            <Modal isOpen={this.state.modal} toggle={this.toggle}>
                <Form onSubmit={this.submitHandler}>
                    <ModalHeader toggle={this.props.toggle}>Add New Charges</ModalHeader>
                    <ModalBody>
                        <Row form>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="distance">Distance (km)</Label>
                                    <Input type="number" name="distance" required
                                        placeholder="Distance (km)" onChange={this.oninputchange} />
                                </FormGroup>
                            </Col>
                            
                        </Row>
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="pick_up_charges">Pick-up Charges</Label>
                                    <Input className="number" step="any" type="number" name="pick_up_charges"
                                        required placeholder="Pick-up" onChange={this.oninputchange} />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="drop_off_charges">Drop off Charges</Label>
                                    <Input className="number" step="any" type="number" name="drop_off_charges"
                                        required placeholder="Drop off" onChange={this.oninputchange} />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="night_charges">Late Night Charges</Label>
                                    <Input className="number" step="any" type="number" name="night_charges"
                                        required placeholder="Late Night" onChange={this.oninputchange} />
                                </FormGroup>
                            </Col>
                        </Row>
                        {/*  */}
                        {/* <div>{this.state}</div> */}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" type="button" onClick={this.toggle}>Cancel</Button>
                        <Button disabled={this.state.loadingbtn} color="primary" >Submit</Button>
                    </ModalFooter>
                </Form>
            </Modal >
        );

    }

    
    renderAddWeightChargesModal = () => {
        return (
            <Modal isOpen={this.state.addWeightModal} toggle={this.toggleWeightModal}>
                <Form onSubmit={this.saveWeightCharges}>
                    <ModalHeader toggle={this.props.toggle}>Add Weight Charges</ModalHeader>
                    <ModalBody>
                        <Row form>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="weight_charges_title">Title</Label>
                                    <Input type="text" name="weight_charges_title" required
                                        placeholder="eg. up to 5 Kg" onChange={this.oninputchange} />
                                </FormGroup>
                            </Col>
                            
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="weight_charges_weight">Weight</Label>
                                    <Input className="number" step="any" type="number" name="weight_charges_weight"
                                        required placeholder="weight" onChange={this.oninputchange} />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="weight_charges_charges">charges</Label>
                                    <Input className="number" step="any" type="number" name="weight_charges_charges"
                                        required placeholder="charges" onChange={this.oninputchange} />
                                </FormGroup>
                            </Col>
                        </Row>
                        {/*  */}
                        {/* <div>{this.state}</div> */}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" type="button" onClick={this.toggleWeightModal}>Cancel</Button>
                        <Button disabled={this.state.loadingbtn} color="primary" >Submit</Button>
                    </ModalFooter>
                </Form>
            </Modal >
        );

    }

    render() {
        const { tabIndex } = this.state;
        return (
            <Fragment>
                <div className="row">
                <div className="col-lg-7 col-sm-12" style={{fontSize:"12px"}}>
                        <Breadcrumb title={"Dart"} parent={"Home"} />
                    </div>
                    
                </div>

                <div className="container-fluid">
                    <div className="row theme-tab">

                        <Tabs className="col-sm-12" defaultIndex={this.state.tabIndex} onSelect={index => this.setState({ tabIndex: index })} style={{ padding: "0" }}>
                            
                            <TabList className="tabs tab-title" >
                                <Tab>
                                    Delivery Charges
                                </Tab>
                                <Tab>
                                    Weight Charges
                                </Tab>
                            </TabList>
                            <div className="row">

                                {tabIndex == 0 ?
                                    <div className="col-lg-12 col-sm-12 ">
                                        <div className="btn-group pull-right" style={{}} role="group" aria-label="Basic example">
                                            {this.state.access_level != 1 && <button className="btn btn-light" onClick={this.toggle} ><i className="fa fa-plus-square-o" style={{ margin: "0px 7px 0 0" }}></i> Add New Charges </button>}
                                        </div>
                                    </div>
                                    : null
                                }
                                {tabIndex == 1 ?
                                    <div className="col-lg-12 col-sm-12 ">
                                        <div className="btn-group pull-right" style={{}} role="group" aria-label="Basic example">
                                            {this.state.access_level != 1 && <button className="btn btn-light" onClick={this.toggleWeightModal} ><i className="fa fa-plus-square-o" style={{ margin: "0px 7px 0 0" }}></i> Add Weight Charges </button>}
                                        </div>
                                    </div>
                                    : null
                                }
                            </div>
                            
                            <div className="card">
                            
                                <div className="card-body" style={{ padding: "1rem" }}>
                                
                                    <TabPanel>
                                    
                                        <div className="table-responsive">
                                            <table className="table table-border-horizontal">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Distance</th>
                                                        <th scope="col">Pick Up Charges</th>
                                                        <th scope="col">Drop Off Charges</th>
                                                        <th scope="col">Night Charges</th>
                                                        <th scope="col">Booking Charges</th>
                                                        <th scope="col">Total Charges</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.charges_data ? this.state.charges_data.map((item, index) => {
                                                        return (
                                                            <tr key={item._id}>
                                                                <td>{index + 1}</td>
                                                                <td>{item.distance} km</td>
                                                                <td>${item.pickup_charges}</td>
                                                                <td>${item.dropoff_charges}</td>
                                                                <td>${item.night_charges}</td>
                                                                <td>${item.booking_charges}</td>
                                                                <td>${item.total_charges}</td>
                                                                <td>
                                                                {this.state.access_level == 3 && <i className="btn fa fa-trash" style={{ color: "rgb(228, 86, 110)", maxWidth: "30px", padding: "0px" }} onClick={() => {
                                                                    this.delete_delivery_charges(item._id, 1);
                                                                    // set_del_id(item._id);
                                                                }} ></i>}
                                                                </td>
                                                            </tr>
                                                        );
                                                    }) : null}
                                                </tbody>
                                            </table>
                                        </div>

                                        
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="table-responsive">
                                            <table className="table table-border-horizontal">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Title</th>
                                                        <th scope="col">Weight</th>
                                                        <th scope="col">Charges</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.weight_charges_data ? this.state.weight_charges_data.map((item, index) => {
                                                        return (
                                                            <tr key={item._id}>
                                                                <td>{index + 1}</td>
                                                                <td>{item.title}</td>
                                                                <td>{item.weight} kg</td>
                                                                <td>${item.charges}</td>
                                                                <td>
                                                                    {this.state.access_level == 3 && <i className="btn fa fa-trash" style={{ color: "rgb(228, 86, 110)", maxWidth: "30px",  padding: "0px" }} onClick={() => {
                                                                        // delete_toggle();
                                                                        this.delete_delivery_charges(item._id, 2);
                                                                        // set_del_id(item._id);
                                                                    }} ></i>}
                                                                </td>
                                                            </tr>
                                                        );
                                                    }) : null}
                                                </tbody>
                                            </table>
                                        </div>
                                    </TabPanel>
                                </div>
                            </div>
                        </Tabs>
                 
                    </div>
                </div>
                {this.renderAddNewLevelModal()}
                {this.renderAddWeightChargesModal()}
                
            </Fragment>
        );
    }
}

export default CrownsPoints;