import React, { Component, useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormGroup, Col, Row, Input, CustomInput } from 'reactstrap';
import useForm from "react-hook-form";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

const AddSubsCategory = (props) => {

    let { setCatLoading } = props;
    let [loadingbtn, setloadingbtn] = useState(false);
    let [showerrorstatus, setshowerrorstatus] = useState(false);

    const { handleSubmit, register, errors } = useForm();

    const onSubmit = (form_data) => {

        if (form_data !== '') {

            setloadingbtn(true);
            setCatLoading(true);

            client.post(api.addSubscriptionCategory, form_data, async function (error, response) {

                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {

                                toast.success(response.message);
                                props.setSubCatResult(response.result);
                                props.toggleModal();
                                setCatLoading(false);

                            } else {
                                toast.error(response.message);
                            }
                        } catch (e) {
                            toast.error("Internal error occured. Please contact support");
                        }
                    } else {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {

                    toast.error("Internal error occured. Please contact support");
                }

                setloadingbtn(false);
                setCatLoading(false);

            });
        }
        else {
            setshowerrorstatus(true);

            errors.showMessages();
        }
    }

    return (

        <Modal
            isOpen={props.isOpen}
            toggle={props.toggleModal}
            className="modal-body"
            centered={true}
        >
            <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={props.toggleModal}>Add Category</ModalHeader>
                <ModalBody>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <input
                                    className="form-control"
                                    type="text"
                                    name="title"
                                    placeholder="Category Name"
                                    ref={register({ required: true })}
                                />
                                <span style={{ color: '#ff5370' }}>{errors.title && 'Category name is required'}</span>
                            </div>
                        </div>
                    </div>

                </ModalBody>
                <ModalFooter>
                    {!loadingbtn ?
                        <>
                            <Button color="default" type="button" onClick={props.toggleModal}>Cancel</Button>
                            <Button color="primary" type="submit" onClick={() => setshowerrorstatus(true)} >Save</Button>
                        </>
                        :
                        <div
                            className="loader-box pull-right"
                            style={{ height: 'auto' }}
                        >
                            <span
                                className="rotate dashed"
                                style={{ height: 20, width: 20 }}
                            >
                            </span>
                        </div>
                    }
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default AddSubsCategory;