import React, { useState, useEffect, Fragment } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Accordion, AccordionItem } from 'react-light-accordion';
import { Container, Collapse, Card, CardBody } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import 'react-light-accordion/demo/css/index.css';
import { TabContent, TabPane, Nav, NavItem } from "reactstrap";
import { NavLink, Link } from "react-router-dom";
import useForm  from "react-hook-form";

import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import catsubcat from "../../CatSubcat";

const businessCategory = catsubcat.map(el => el.categoryTitle);

const subcategory = catsubcat.map(el => el.subCategory.map(sub => sub.subcatTitle))
    .reduce((el, nextEl) => el.concat(nextEl));

const AddPotentialMerchantModal = (props) => {
    const [potentialMerchant, setPotentialMerchant] = useState({
        name: "",
        mobile: "",
        email: "",
        business_name: "",
        abn: "",
        business_mobile: "",
        landline: "",
        business_category: "Automobiles and Vehicles",
        business_sub_category: "",
        website: "",
        address: "",
        postcode: "",
        suburb: "",
        street_name: "",
        type: +props.activeTab,
        country: props.countries[0],
        state: props.countryState[0],
        phone_prefix: '+61',
        edit_id: ''
    });

    const { handleSubmit, register, errors } = useForm();


    const [countryState, setCountryState] = useState(props.countryState);
    const [countries, setCountries] = useState(props.countries);

    useEffect(() => {
        if (props.countries.length > 0) {
            if (!potentialMerchant.country) {
                setPotentialMerchant({
                    name: "",
                    mobile: "",
                    email: "",
                    business_name: "",
                    abn: "",
                    business_mobile: "",
                    landline: "",
                    business_category: "Automobiles and Vehicles",
                    business_sub_category: "",
                    website: "",
                    address: "",
                    postcode: "",
                    suburb: "",
                    street_name: "",
                    type: +props.activeTab,
                    country: props.countries[0],
                    state: props.countryState[0],
                    phone_prefix: props.countries[0].phone_prefix,
                    edit_id: ''
                })
            }
        }
    }, [props.countries])
    useEffect(() => {
        if (props.potentialMerchantItem) {
            const merch_item = props.potentialMerchantItem;
            console.log(props, props?.countries?.filter(elem => elem.country_code === merch_item?.country?.code));

            setPotentialMerchant(prevState => ({
                ...prevState,
                name: merch_item?.name,
                mobile: merch_item?.mobile,
                email: merch_item?.email,
                business_name: merch_item?.business_name,
                abn: merch_item?.abn,
                source: merch_item?.source,
                business_mobile: merch_item?.business_mobile,
                landline: merch_item?.landline,
                business_category: merch_item?.business_category,
                business_sub_category: merch_item?.business_sub_category,
                website: merch_item?.website,
                address: merch_item?.address,
                postcode: merch_item?.postcode,
                suburb: merch_item?.suburb,
                type: merch_item?.type,
                street_name: merch_item?.street_name,
                country: props?.countries?.filter(elem => elem.country_code === merch_item?.country?.code)[0],
                state: merch_item?.state,
                phone_prefix: merch_item?.country?.phone_prefix,
                edit_id: merch_item?._id
            }));
        }
    }, [props]);


    

    useEffect(() => {
        if (props.activeTab !== potentialMerchant.type) {
            setPotentialMerchant(prevState => ({
                ...prevState,
                type: +props.activeTab
            }));
        }
    }, [props.activeTab, potentialMerchant.type]);

    const changeCategory = (event) => {
        setPotentialMerchant(prevState => ({
            ...prevState,
            business_category: event.target.value
        }));
    };

    const onInputChangeHandler = (event) => {
        const { name, value } = event.target;
        if (name === "type") {
            let typeValue = value;
            switch (value) {
                case "SOP Partners":
                    typeValue = 1;
                    break;
                case "Hidden Secret":
                    typeValue = 2;
                    break;
                case "Other Hospo":
                    typeValue = 3;
                    break;
                case "All Other Merchants":
                    typeValue = 0;
                    break;
                default:
                    break;
            }
            setPotentialMerchant(prevState => ({
                ...prevState,
                type: typeValue
            }));
        } else if (name === 'country' || name=="phone_prefix") {

            let country = props.countries.find(elem=>(elem.country_code === value || elem.phone_prefix == value))
            props.fetch_country_details(country.country_code);
            // console.log(value)
            setTimeout(() => {
                setPotentialMerchant(prevState => ({
                    ...prevState,
                    state: props.countryState[0].name,
                    phone_prefix: country?.phone_prefix,
                    country: country,
                }));
            }, 1000);
        } else {
            setPotentialMerchant(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const refreshPage = () => {
        window.location.reload(false);
    };

    const addPotentialMerchant = (data) => {
        
        const postData = {
            ...potentialMerchant,
            country: potentialMerchant.country ? potentialMerchant.country?.country_code : props.countries[0]?.country_code,
            state: potentialMerchant.state ? potentialMerchant.state : props.countryState[0]?.name,
            check_mobile: false,
            edit: props.potentialMerchantItem ? true : false
        };

        client.post(api.add_potential_merchant, postData, (error, response) => {
            if (!error) {
                if (response.error === undefined || !response.error) {
                    refreshPage();
                } else {
                    console.log(response)
                    toast.error(response.message);
                }
            } else {
                toast.error("Internal error occurred. Please contact support");
            }
        });
    };

    console.log(potentialMerchant?.country, "new RegExp(potentialMerchant?.country?.phone_regex)")


    return (
        <Fragment>

            
            <Modal isOpen={props.modal} toggle={props.toggleModal} className="modal-body" centered={true} scrollable={true}>
                <form className="needs-validation" onSubmit={handleSubmit(addPotentialMerchant)} >
                    <ModalHeader toggle={props.toggleModal}>{props?.potentialMerchantItem != null ? 'Edit Potential Merchant' : 'Add Potential Merchant'}</ModalHeader>
                    <ModalBody style={{ padding: "17px" }}>
                
                        <div className="default-according panel-accordion" id="accordionclose">
                            <div className="" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordionoc">
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group col-l-6 mb-3">
                                            <label>Full Name :</label>
                                            <input ref={register({ required: true })} className="form-control" value={potentialMerchant?.name} type="text" placeholder="Full Name" name="name" onChange={onInputChangeHandler} autoComplete="off" />
                                            {errors.name && <span className="text-danger" style={{fontSize : 12}}>Name is required.</span>}

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group col-l-6 mb-3">
                                            <label>Mobile no. :</label>
                                            <div className="d-flex">
                                                <select style={{ width: '60px', paddingLeft: '5px', paddingRight: '5px' }} className="form-control" defaultValue={potentialMerchant.phone_prefix} value={potentialMerchant.phone_prefix} name="phone_prefix" onChange={onInputChangeHandler} autoComplete="false">
                                                    {props.countries.map(elem => (
                                                        <option value={elem.phone_prefix}>{elem.phone_prefix}</option>
                                                    ))}
                                                </select>
                                                <input ref={register({ required: true,  pattern: new RegExp(potentialMerchant?.country?.phone_regex)  })} className="form-control" type="text" maxLength="10" placeholder="Mobile no." name="mobile" onChange={onInputChangeHandler} autoComplete="off" value={potentialMerchant?.mobile} />
                                            </div>
                                            {errors.mobile && <span className="text-danger" style={{fontSize : 12}}>Invalid Mobile no.</span>}

                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group col-l-6 mb-3">
                                            <label>Email :</label>
                                            <input ref={register({ required: true })} className="form-control" type="email" placeholder="Email" name="email" onChange={onInputChangeHandler} autoComplete="off" value={potentialMerchant?.email} />
                                            {errors.email && <span className="text-danger" style={{fontSize : 12}}>Email is required.</span>}
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div className="" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordionoc">
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group col-l-6">
                                            <label>Username / Storename :</label>
                                            <input ref={register()} className="form-control" type="text" placeholder="Username / Storename" name="business_name" onChange={onInputChangeHandler} autoComplete="off" value={potentialMerchant?.business_name} />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group col-l-6">
                                            <label>Source :</label>
                                            <input ref={register()} className="form-control" type="text" placeholder="eg: Friend, Google, Linkedin etc." name="source" onChange={onInputChangeHandler} autoComplete="off" value={potentialMerchant?.source} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group col-l-6">
                                            <label>Landline :</label>
                                            <input ref={register()} className="form-control" type="text" maxLength="10" placeholder="Landline" name="landline" onChange={onInputChangeHandler} autoComplete="off" value={potentialMerchant?.landline} />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group col-l-6">
                                            <label>Website :</label>
                                            <input ref={register()} className="form-control" type="text" placeholder="Website" name="website" onChange={onInputChangeHandler} autoComplete="off" value={potentialMerchant?.website} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group col-l-6">
                                            <label>Country :</label>
                                            <select ref={register()} className="form-control" defaultValue={'AU'} value={potentialMerchant?.country?.country_code} name="country" onChange={onInputChangeHandler} autoComplete="false">
                                                {props.countries.map(elem => (
                                                    <option value={elem.country_code}>{elem.country_name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group col-l-6">
                                            <label>State :</label>
                                            <select ref={register()} className="form-control" value={potentialMerchant.state} name="state" onChange={onInputChangeHandler} autoComplete="false" >
                                                {props.countryState.map(elem => (
                                                    <option value={elem.name}>{elem.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                
                                    <div className="col">
                                        <div className="form-group col-l-6">
                                            <label>Suburb / City :</label>
                                            <input ref={register()} className="form-control" value={potentialMerchant?.suburb} type="text" placeholder="Suburb" name="suburb" onChange={onInputChangeHandler} autoComplete="off" />
                                        </div>

                                    </div>
                                    <div className="col">
                                        <div className="form-group col-l-6">
                                            <label>Postcode / Pincode :</label>
                                            <input ref={register()} className="form-control" value={potentialMerchant?.postcode} type="text" placeholder="Postcode" name="postcode" onChange={onInputChangeHandler} autoComplete="off" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                               
                                    <div className="col">
                                        <div className="form-group col-l-6">
                                            <label>Address :</label>
                                            <input ref={register()} className="form-control" value={potentialMerchant?.address} type="text" placeholder="Address" name="address" onChange={onInputChangeHandler} autoComplete="off" />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group col-l-6">
                                            <label>Street Name :</label>
                                            <input ref={register()} className="form-control" value={potentialMerchant?.street_name} type="text" placeholder="Street Name" name="street_name" onChange={onInputChangeHandler} autoComplete="off" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="button" color="secondary" onClick={props.toggleModal}>Close</Button>
                        <Button type="submit" color="primary">Save</Button>
                    </ModalFooter>
                </form>
            </Modal>
            <ToastContainer />
        </Fragment>
    );
}

export default AddPotentialMerchantModal;
