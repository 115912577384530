import React, { Component, useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormGroup, Col, Row, Input, CustomInput } from 'reactstrap';
import DatePicker from "react-datepicker";
import ImageUploader from 'react-images-upload';
import useForm  from "react-hook-form";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

let DatePickerInput = ({ value, onClick }) => {
    return(
        <Input type="text" value={value} onFocus={onClick} onClick={onClick}/>
    );
}
let input_sub_title_style = { fontSize: '10px', color: "#98a6ad", marginBottom: 0 };

let AddOfferModal = (props) => {
    
    let [loadingbtn, setloadingbtn] = useState(false);
    let [selectedReceiver, setselectedReceiver] = useState([]);
    let [showerrorstatus, setshowerrorstatus] = useState(false);

    const { handleSubmit, register, errors } = useForm();

    useEffect(() => {
    });

    const onSubmit = form_data => {
        
        if (form_data !== '') {
            let customer_id = "";
            try {
                if (selectedReceiver.length == 0) {
                    console.log("select merchant");
                    return false;
                } else {
                    customer_id = selectedReceiver[0]._id;
                }
            } catch {
                console.log("select merchant");
                return false;
            }
            
            form_data.customer_id = customer_id;

            setloadingbtn(true);

            client.post(api.add_cashback, form_data, async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                setselectedReceiver([])
                                props.toggleModal(1);
                                toast.success("Added Successfully");
                                // setcashbackdata(response.result.data);
                            } else {
                                toast.error(response.message);
                            }
                        } catch (e) {
                            toast.error("Internal error occured. Please contact support");
                        }
                    } else {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
                
                setloadingbtn(false);
            });
        }
        else {
            setshowerrorstatus(true);
            errors.showMessages();
        }
    }

    return (

        <Modal isOpen={props.isOpen} toggle={props.toggleModal} size="lg" className="modal-body" centered={true}>
            <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={props.toggleModal}>Send Cashback</ModalHeader>
                <ModalBody>
                        
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <Label for="amount">Amount</Label>
                                <input className="form-control"  type="number" step="any" id="amount" name="cashback_amount" placeholder="Amount" ref={register({ required: true })} />
                                <span style={{color: '#ff5370'}}>{errors.cashback_amount && 'Amount is required'}</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>To</label>
                                <Typeahead
                                    id="public-typeahead"
                                    // defaultSelected={customersData.slice(0, 0)}
                                    labelKey={"name"}
                                    options={props.all_customers}
                                    placeholder="Customer Name"
                                    onChange={(selected)=>setselectedReceiver(selected)}
                                />
                                <span style={{color: '#ff5370'}}>{(showerrorstatus && selectedReceiver.length == 0) ? 'Customer is required' : showerrorstatus }</span>

                            </div>
                        </div>
                    </div>
                    {selectedReceiver.length > 0 &&
                        <div className="row receiver" style={{ marginBottom: "15px" }}>
                            <div className="col-md-12">
                                {props.renderReceiverDetails(selectedReceiver)}
                            </div>
                        </div>
                    }
                                            
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <Label for="description">Description</Label>
                                <input  className="form-control"  type="input" id="description" name="display_data" placeholder="Description" ref={register({ required: true })}/>
                                <span style={{color: '#ff5370'}}>{errors.display_data && 'Description is required'}</span>
                                
                            </div>
                        </div>
                    </div>
                       
                </ModalBody>
                <ModalFooter>
                    {!loadingbtn ?
                        <>
                            <Button color="default" type="button" onClick={props.toggleModal}>Cancel</Button>
                            <Button color="primary" type="submit" onClick={() => setshowerrorstatus(true)} >Send Cashback</Button>
                        </>
                        :
                        <div className="loader-box pull-right" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 20, width: 20 }}></span></div>
                    }
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default AddOfferModal;