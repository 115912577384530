const square = "/assets/images/qrcode_shapes/qr_code_square.png";
const circle = "/assets/images/qrcode_shapes/qr_code_circle.png";
const heart = "/assets/images/qrcode_shapes/qr_code_heart.png";
const hex = "/assets/images/qrcode_shapes/qr_code_hex.png";
const brain = "/assets/images/qrcode_shapes/qr_code_brain.png";
const cart = "/assets/images/qrcode_shapes/qr_code_cart.png";
const cloud = "/assets/images/qrcode_shapes/qr_code_cloud.png";
const shape1 = "/assets/images/qrcode_shapes/qr_code_shape1.png";
const mind = "/assets/images/qrcode_shapes/qr_code_mind.png";
const coffee = "/assets/images/qrcode_shapes/qr_code_coffee.png";
const chat = "/assets/images/qrcode_shapes/qr_code_chat.png";
const clothing = "/assets/images/qrcode_shapes/qr_code_clothing.png";
const drop = "/assets/images/qrcode_shapes/qr_code_drop.png";
const food = "/assets/images/qrcode_shapes/qr_code_food.png";
const idea = "/assets/images/qrcode_shapes/qr_code_idea.png";
const tech = "/assets/images/qrcode_shapes/qr_code_tech.png";
const shape2 = "/assets/images/qrcode_shapes/qr_code_shape2.png";
const shape3 = "/assets/images/qrcode_shapes/qr_code_shape3.png";
const guitar = "/assets/images/qrcode_shapes/qr_code_guitar.png";
const egg = "/assets/images/qrcode_shapes/qr_code_egg.png";
const star = "/assets/images/qrcode_shapes/qr_code_star.png";
const gift = "/assets/images/qrcode_shapes/qr_code_gift.png";
const cake = "/assets/images/qrcode_shapes/qr_code_cake.png";
const shield = "/assets/images/qrcode_shapes/qr_code_shield.png";
const leaf = "/assets/images/qrcode_shapes/qr_code_leaf.png";
const heartdevil = "/assets/images/qrcode_shapes/qr_code_heartdevil.png";
const balloonsparty = "/assets/images/qrcode_shapes/qr_code_balloonsparty.png";
const drink = "/assets/images/qrcode_shapes/qr_code_drink.png";
const beauty = "/assets/images/qrcode_shapes/qr_code_beauty.png";
const dress = "/assets/images/qrcode_shapes/qr_code_dress.png";
const wine = "/assets/images/qrcode_shapes/qr_code_wine.png";
const beer = "/assets/images/qrcode_shapes/qr_code_beer.png";
const like = "/assets/images/qrcode_shapes/qr_code_like.png";
const popsicle = "/assets/images/qrcode_shapes/qr_code_popsicle.png";
const popsicle2 = "/assets/images/qrcode_shapes/qr_code_popsicle2.png";
const restaurant = "/assets/images/qrcode_shapes/qr_code_restaurant.png";
const soup = "/assets/images/qrcode_shapes/qr_code_soup.png";
const lunch = "/assets/images/qrcode_shapes/qr_code_lunch.png";
const coffee4 = "/assets/images/qrcode_shapes/qr_code_coffee4.png";
const user = "/assets/images/qrcode_shapes/qr_code_user.png";
const megaphone = "/assets/images/qrcode_shapes/qr_code_megaphone.png";
const diamond = "/assets/images/qrcode_shapes/qr_code_diamond.png";
const sshape = "/assets/images/qrcode_shapes/qr_code_sshape.png";
const slogo2 = "/assets/images/qrcode_shapes/qr_code_slogo2.png";
const tube = "/assets/images/qrcode_shapes/qr_code_tube.png";
const truck = "/assets/images/qrcode_shapes/qr_code_truck.png";
const cross = "/assets/images/qrcode_shapes/qr_code_cross.png";
const mask = "/assets/images/qrcode_shapes/qr_code_mask.png";
const wheelchair = "/assets/images/qrcode_shapes/qr_code_wheelchair.png";
const flower = "/assets/images/qrcode_shapes/qr_code_flower.png";
const book = "/assets/images/qrcode_shapes/qr_code_book.png";
const diamond2 = "/assets/images/qrcode_shapes/qr_code_diamond2.png";
const location = "/assets/images/qrcode_shapes/qr_code_location.png";
const hospital = "/assets/images/qrcode_shapes/qr_code_hospital.png";
const paw = "/assets/images/qrcode_shapes/qr_code_paw.png";
const cat = "/assets/images/qrcode_shapes/qr_code_cat.png";
const dog = "/assets/images/qrcode_shapes/qr_code_dog.png";
const butterfly = "/assets/images/qrcode_shapes/qr_code_butterfly.png";
const fish = "/assets/images/qrcode_shapes/qr_code_fish.png";
const turtle = "/assets/images/qrcode_shapes/qr_code_turtle.png";
const light = "/assets/images/qrcode_shapes/qr_code_light.png";
const home = "/assets/images/qrcode_shapes/qr_code_home.png";
const christmastree = "/assets/images/qrcode_shapes/qr_code_christmastree.png";
const camera = "/assets/images/qrcode_shapes/qr_code_camera.png";
const tree = "/assets/images/qrcode_shapes/qr_code_tree.png";
const clove = "/assets/images/qrcode_shapes/qr_code_clove.png";
const bike = "/assets/images/qrcode_shapes/qr_code_bike.png";
const sport_bike = "/assets/images/qrcode_shapes/qr_code_sport_bike.png";
const plane = "/assets/images/qrcode_shapes/qr_code_plane.png";
const car = "/assets/images/qrcode_shapes/qr_code_car.png";
const triangle1 = "/assets/images/qrcode_shapes/qr_code_triangle1.png";
const triangle2 = "/assets/images/qrcode_shapes/qr_code_triangle2.png";


let qrcode_shapes_images = [
    { _id: "001", imageSrc: square, alt: 'square', shapeName: 'square', xAxis: 119, yAxis: 90, size: 275 },
    { _id: "002", imageSrc: circle, alt: 'circle', shapeName: 'circle', xAxis: 90, yAxis: 90, size: 335 },
    { _id: "003", imageSrc: heart, alt: 'heart', shapeName: 'heart', xAxis: 119, yAxis: 90, size: 275 },
    { _id: "004", imageSrc: hex, alt: 'hex', shapeName: 'hex', xAxis: 102, yAxis: 103, size: 307 },
    { _id: "005", imageSrc: brain, alt: 'brain', shapeName: 'brain', xAxis: 140, yAxis: 63, size: 230 },
    { _id: "006", imageSrc: cart, alt: 'cart', shapeName: 'cart', xAxis: 88, yAxis: 140, size: 188 },
    { _id: "007", imageSrc: cloud, alt: 'cloud', shapeName: 'cloud', xAxis: 130, yAxis: 175, size: 250 },
    { _id: "008", imageSrc: shape1, alt: 'shape1', shapeName: 'shape1', xAxis: 103, yAxis: 100, size: 308 },
    { _id: "009", imageSrc: mind, alt: 'mind', shapeName: 'mind', xAxis: 93, yAxis: 63, size: 270 },
    { _id: "010", imageSrc: coffee, alt: 'coffee', shapeName: 'coffee', xAxis: 90, yAxis: 200, size: 225 },
    { _id: "011", imageSrc: chat, alt: 'chat', shapeName: 'chat', xAxis: 107, yAxis: 78, size: 295 },
    { _id: "012", imageSrc: clothing, alt: 'clothing', shapeName: 'clothing', xAxis: 120, yAxis: 148, size: 272 },
    { _id: "013", imageSrc: drop, alt: 'drop', shapeName: 'drop', xAxis: 137, yAxis: 202, size: 237 },
    { _id: "014", imageSrc: food, alt: 'food', shapeName: 'food', xAxis: 154, yAxis: 272, size: 200 },
    { _id: "015", imageSrc: idea, alt: 'idea', shapeName: 'idea', xAxis: 165, yAxis: 145, size: 180 },
    { _id: "016", imageSrc: tech, alt: 'tech', shapeName: 'tech', xAxis: 44, yAxis: 132, size: 258 },
    { _id: "017", imageSrc: shape2, alt: 'shape2', shapeName: 'shape2', xAxis: 104, yAxis: 104, size: 302 },
    { _id: "018", imageSrc: shape3, alt: 'shape3', shapeName: 'shape3', xAxis: 104, yAxis: 104, size: 303 },
    { _id: "019", imageSrc: guitar, alt: 'guitar', shapeName: 'guitar', xAxis: 188, yAxis: 320, size: 135 },
    { _id: "020", imageSrc: egg, alt: 'egg', shapeName: 'egg', xAxis: 122, yAxis: 167, size: 268 },
    { _id: "021", imageSrc: star, alt: 'star', shapeName: 'star', xAxis: 132, yAxis: 175, size: 245 },
    { _id: "022", imageSrc: gift, alt: 'gift', shapeName: 'gift', xAxis: 120, yAxis: 186, size: 270 },
    { _id: "023", imageSrc: cake, alt: 'cake', shapeName: 'cake', xAxis: 118, yAxis: 190, size: 270 },
    { _id: "024", imageSrc: shield, alt: 'shield', shapeName: 'shield', xAxis: 120, yAxis: 148, size: 270 },
    { _id: "025", imageSrc: leaf, alt: 'leaf', shapeName: 'leaf', xAxis: 156, yAxis: 232, size: 198 },
    { _id: "026", imageSrc: heartdevil, alt: 'heartdevil', shapeName: 'heartdevil', xAxis: 127, yAxis: 107, size: 258 },
    { _id: "027", imageSrc: balloonsparty, alt: 'balloonsparty', shapeName: 'balloonsparty', xAxis: 143, yAxis: 60, size: 225 },
    { _id: "028", imageSrc: drink, alt: 'drink', shapeName: 'drink', xAxis: 143, yAxis: 212, size: 223 },
    { _id: "029", imageSrc: beauty, alt: 'beauty', shapeName: 'beauty', xAxis: 102, yAxis: 50, size: 250 },
    { _id: "030", imageSrc: dress, alt: 'dress', shapeName: 'dress', xAxis: 151, yAxis: 280, size: 213 },
    { _id: "031", imageSrc: wine, alt: 'wine', shapeName: 'wine', xAxis: 159, yAxis: 18, size: 193 },
    { _id: "032", imageSrc: beer, alt: 'beer', shapeName: 'beer', xAxis: 101, yAxis: 265, size: 220 },
    { _id: "033", imageSrc: like, alt: 'like', shapeName: 'like', xAxis: 168, yAxis: 195, size: 250 },
    { _id: "034", imageSrc: popsicle, alt: 'popsicle', shapeName: 'popsicle', xAxis: 155, yAxis: 95, size: 197 },
    { _id: "035", imageSrc: popsicle2, alt: 'popsicle2', shapeName: 'popsicle2', xAxis: 155, yAxis: 95, size: 205 },
    { _id: "036", imageSrc: restaurant, alt: 'restaurant', shapeName: 'restaurant', xAxis: 71, yAxis: 140, size: 148 },
    { _id: "037", imageSrc: soup, alt: 'soup', shapeName: 'soup', xAxis: 132, yAxis: 210, size: 250 },
    { _id: "038", imageSrc: lunch, alt: 'lunch', shapeName: 'lunch', xAxis: 135, yAxis: 175, size: 242 },
    { _id: "039", imageSrc: coffee4, alt: 'coffee4', shapeName: 'coffee4', xAxis: 147, yAxis: 215, size: 214 },
    { _id: "040", imageSrc: user, alt: 'user', shapeName: 'user', xAxis: 157, yAxis: 70, size: 195 },
    { _id: "041", imageSrc: megaphone, alt: 'megaphone', shapeName: 'megaphone', xAxis: 250, yAxis: 120, size: 237 },
    { _id: "042", imageSrc: diamond, alt: 'diamond', shapeName: 'diamond', xAxis: 133, yAxis: 56, size: 270 },
    { _id: "043", imageSrc: sshape, alt: 'sshape', shapeName: 'sshape', xAxis: 104, yAxis: 103, size: 306 },
    { _id: "044", imageSrc: slogo2, alt: 'slogo2', shapeName: 'slogo2', xAxis: 132, yAxis: 117, size: 245 },
    { _id: "045", imageSrc: tube, alt: 'tube', shapeName: 'tube', xAxis: 138, yAxis: 245, size: 230 },
    { _id: "046", imageSrc: truck, alt: 'truck', shapeName: 'truck', xAxis: 35, yAxis: 97, size: 260 },
    { _id: "047", imageSrc: cross, alt: 'cross', shapeName: 'cross', xAxis: 145, yAxis: 150, size: 220 },
    { _id: "048", imageSrc: mask, alt: 'mask', shapeName: 'mask', xAxis: 143, yAxis: 150, size: 225 },
    { _id: "049", imageSrc: wheelchair, alt: 'wheelchair', shapeName: 'wheelchair', xAxis: 68, yAxis: 182, size: 250 },
    { _id: "050", imageSrc: flower, alt: 'flower', shapeName: 'flower', xAxis: 138, yAxis: 65, size: 234 },
    { _id: "051", imageSrc: book, alt: 'book', shapeName: 'book', xAxis: 120, yAxis: 100, size: 275 },
    { _id: "052", imageSrc: diamond2, alt: 'diamond2', shapeName: 'diamond2', xAxis: 148, yAxis: 145, size: 220 },
    { _id: "053", imageSrc: location, alt: 'location', shapeName: 'location', xAxis: 146, yAxis: 65, size: 220 },
    { _id: "054", imageSrc: hospital, alt: 'hospital', shapeName: 'hospital', xAxis: 120, yAxis: 205, size: 275 },
    { _id: "055", imageSrc: paw, alt: 'paw', shapeName: 'paw', xAxis: 164, yAxis: 270, size: 179 },
    { _id: "056", imageSrc: cat, alt: 'cat', shapeName: 'cat', xAxis: 148, yAxis: 178, size: 214 },
    { _id: "057", imageSrc: dog, alt: 'dog', shapeName: 'dog', xAxis: 122, yAxis: 186, size: 202 },
    { _id: "058", imageSrc: butterfly, alt: 'butterfly', shapeName: 'butterfly', xAxis: 190, yAxis: 189, size: 211 },
    { _id: "059", imageSrc: fish, alt: 'fish', shapeName: 'fish', xAxis: 197, yAxis: 174, size: 194 },
    { _id: "060", imageSrc: turtle, alt: 'turtle', shapeName: 'turtle', xAxis: 150, yAxis: 174, size: 210 },
    { _id: "061", imageSrc: light, alt: 'light', shapeName: 'light', xAxis: 150, yAxis: 245, size: 210 },
    { _id: "062", imageSrc: home, alt: 'home', shapeName: 'home', xAxis: 120, yAxis: 165, size: 270 },
    { _id: "063", imageSrc: christmastree, alt: 'christmastree', shapeName: 'christmastree', xAxis: 159, yAxis: 225, size: 188 },
    { _id: "064", imageSrc: camera, alt: 'camera', shapeName: 'camera', xAxis: 156, yAxis: 205, size: 200 },
    { _id: "065", imageSrc: tree, alt: 'tree', shapeName: 'tree', xAxis: 128, yAxis: 92, size: 216 },
    { _id: "066", imageSrc: clove, alt: 'clove', shapeName: 'clove', xAxis: 143, yAxis: 110, size: 248 },
    { _id: "067", imageSrc: bike, alt: 'bike', shapeName: 'bike', xAxis: 157, yAxis: 198, size: 198 },
    { _id: "068", imageSrc: sport_bike, alt: 'sport_bike', shapeName: 'sport_bike', xAxis: 214, yAxis: 198, size: 125 },
    { _id: "069", imageSrc: plane, alt: 'plane', shapeName: 'plane', xAxis: 183, yAxis: 160, size: 179 },
    { _id: "070", imageSrc: car, alt: 'car', shapeName: 'car', xAxis: 165, yAxis: 196, size: 179 },
    { _id: "071", imageSrc: triangle1, alt: 'triangle1', shapeName: 'triangle1', xAxis: 158, yAxis: 219, size: 194 },
    { _id: "072", imageSrc: triangle2, alt: 'triangle2', shapeName: 'triangle2', xAxis: 177, yAxis: 215, size: 157 }
];

export default qrcode_shapes_images;