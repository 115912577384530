// import React, { useState } from 'react';

function displayMobile(mobile){
    if(typeof mobile =="undefined"){
        mobile="NA"
    }else if(mobile.length == 10)
        mobile = mobile.replace(/(\d{4})(\d{3})(\d{3})/, "$1 $2 $3")
    else if(mobile.length == 9)
        mobile = mobile.replace(/(\d{3})(\d{3})(\d{3})/, "0$1 $2 $3")
    else if(mobile == ""){
        mobile = "NA"
    }

    return mobile;
        // return (
        //         <span>{mobile}</span>
        // );
}

export default displayMobile;