import React, { useRef, useState, useEffect } from "react";
import qrcode_shapes_images from "./qrcodeshapes";
import { Row, Col, Table } from 'reactstrap';
import { GiCheckMark } from "react-icons/gi";
import { toast } from 'react-toastify';
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import Compressor from 'compressorjs';
import axios from 'axios';
import moment from "moment";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {Download} from 'react-feather';
import CustomCropperModel from "./CropperModal";
import validator from 'validator'
import swal from "sweetalert";
import FileSaver from "file-saver";
import './generateQr.css'

const addImg = '/assets/images/add_image.png';

const GenerateQrcode = () => {
  const inputFileRef = useRef(null);
  const imgRef = useRef(null);
  const [name, setName] = useState("");
  const [webUrl, setWebUrl] = useState("");
  const [logo, setLogo] = useState(null);
  const [color, setColor] = useState("#000000");
  const [shape, setShape] = useState(qrcode_shapes_images[0]);
  const [data, setData] = useState([]);
  const [enableShapeModal, setEnableShapeModal] = useState(false);
  const [qrShow, setQrShow] = useState('');
  const [loading, setLoading] = useState(false);
  const [filec, setFile] = useState(null);

  const handleCancel = () => {
    setName("");
    setWebUrl("");
    setColor("#000000");
    setShape(qrcode_shapes_images[0]);
    setQrShow("");
    imgRef.current.src = addImg;
  }
  
  
  const toggleQrCodeShapeModal = () => { setEnableShapeModal(!enableShapeModal) };

  const handleFile = (e) => {
    // console.log(e.target.files[0])
    try {
      imgRef.current.src = URL.createObjectURL(e)
    new Compressor(e, {
      quality: 0.7,
      maxWidth: 1920,
      maxHeight: 1920,
      convertSize: 500000,
      async success(result) {
        // const toBase64 = result => new Promise((resolve, reject) => {
        //     const reader = new FileReader();
        //     reader.readAsDataURL(result);
        //     reader.onload = () => resolve(reader.result);
        //     reader.onerror = reject;
        // });
        // const base64Image =  await toBase64(result)
        // console.log("base64", base64Image)
        setLogo(result);
      },
      error(err) {
        console.log(err.message)
      }
    })
    setFile(null)
    } catch (err) {
      console.log(err);
    setFile(null)
    }
  }

  // console.log(name, webUrl, color);
  // console.log(logo);
  // console.log(shape);

  const handleSave = () => {
    console.log(webUrl, "webUrl")

    const re = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
    if (name == "") {
      toast.error("Name is required");
    } else if (webUrl == "" || webUrl == null) {
      toast.error("URL is required");
    } else if (!validator.isURL(webUrl)) {
      toast.error("Invalid url 12");
    } else {
      const postData = {
        name: name,
        url: webUrl,
        qrColor: color,
        qrShapeData: shape,
        show: "false"
      }
      // console.log(JSON.stringify(postData), "AASSDD")
      let formData = new FormData();
  
      formData.append('qrcodeFormData', JSON.stringify(postData));
      formData.append('file', logo)
  
      // console.log(formData, "formData")
      const config = {
        headers: {
          'x-access-token': 'ipay-access-token'
        }
      }
  
      axios.post(`${client.url(api.generate_shape_qrcode)}`, formData, config)
        .then(res => {
          getQrData()
        }).catch(err => console.log(err))
    }
    setName("");
    setWebUrl("");
    setLogo(null);
    setColor('#000000');
    setShape(qrcode_shapes_images[0]);
    setQrShow("");
    imgRef.current.src = addImg;
  }

  const getQrData = () => {
    client.get(api.get_qrAdmindata, {}, (error, response) => {
      try {
        if (!response.error) {
          // console.log(response.data, "data");
          setData(response.data);
        }
      } catch (err) {
        console.log(err);
      }
    })
  }

  async function handleDownload(imageSrc) {
    // const image = await fetch(imageSrc)
    // const imageBlog = await image.blob()
    // const imageURL = URL.createObjectURL(imageBlog)
    // const link = document.createElement('a')
    // link.href = imageURL
    // link.download = 'qrcode.jpg'
    // document.body.appendChild(link)
    // link.click()
    // document.body.removeChild(link)
    window.open(`${client.url(`${api.getQrImage}?fileName=${imageSrc}`)}`);

  }

  const handleDelete = async (id, imgName) => {
    let willDelete = await swal({
			title: "Delete",
			text: "Are you sure you want to delete?",
			icon: "warning",
			buttons: {
				cancel: true,
				confirm: {
					text: "Yes, Please!",
					closeModal: false,
				},
			},
			dangerMode: true,
		});
    if(willDelete){
      client.post(api.deleteQrAdmin, { id, imgName }, (error, response) => {
        try {
          if (!response.error) {
            // console.log(response, "data hhj");
            setData(response.data);
            // getQrData();
          }
        } catch (err) {
          console.log(err);
        }
      })
    }
    swal.close();
  }

  useEffect(() => {
    getQrData();
  }, [])

  const handleUrl = () => {
    console.log(webUrl, "webUrl")
    const re = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
    if (webUrl == "" || webUrl == null) {
      toast.error("URL is required");
    } else if (!validator.isURL(webUrl)) {
      toast.error("Invalid url");
    } else {
      const postData = {
        name: name,
        url: webUrl,
        qrColor: color,
        qrShapeData: shape,
        show: 'true'
      }
      // console.log(JSON.stringify(postData), "AASSDD")
      let formData = new FormData();
  
      formData.append('qrcodeFormData', JSON.stringify(postData));
      formData.append('file', logo)
  
      // console.log(formData, "formData")
      const config = {
        headers: {
          'x-access-token': 'ipay-access-token'
        }
      }
  
      setLoading(true);
  
      axios.post(`${client.url(api.generate_shape_qrcode)}`, formData, config)
        .then(res => {
          // console.log(res,"responsedata")
          setQrShow(res.data.qr)
          setLoading(false);
        }).catch(err => {
          console.log(err)
          setLoading(false);
        })
    }
  }

  useEffect(() => {
    if (webUrl) {
      handleUrl()
    }
  }, [shape, logo])

  return (
    <>
      <div>
        <div className="p-4" style={{ background: '#fff' }}>
          <h5 style={{fontSize:'0.9rem',fontWeight:'600'}}>Complete the content of QR Code</h5>
          <div className="d-flex">
          <div style={{width:'70%'}}>
            <form>
              <Row className="my-3 mx-0 d-flex align-items-center">
                <Col xs={12} sm={12} md={3}>
                  <div className="" style={{ color: '#000', fontWeight: '500', whiteSpace: 'nowrap', maxWidth: 'fit-content', width: 'fit-content', fontSize: "13px" }}>
                    Website URL:
                  </div>
                </Col>
                <Col xs={12} sm={12} md={9} style={{ marginTop: "0rem" }}>
                  <div className="d-flex align-items-center" style={{ width: '100%', border: '1px solid grey', borderRadius: '0.2rem', background: '#fff' }} onBlur={()=>handleUrl()}>
                    <input type="text" value={webUrl} onChange={(e) => setWebUrl(e.target.value)} style={{ border: '0', margin: '0.25rem',width:'100%' }} />
                  </div>
                </Col>
              </Row>
              <Row className="my-3 mx-0 d-flex align-items-center">
                <Col xs={12} sm={12} md={3}>
                  <div className="" style={{ color: '#000', fontWeight: '500', whiteSpace: 'nowrap', maxWidth: 'fit-content', width: 'fit-content', fontSize: "13px" }}>
                    Name of QR Code:
                  </div>
                </Col>
                <Col xs={12} sm={12} md={9} style={{ marginTop: "0rem" }}>
                  <div className="d-flex align-items-center" style={{ width: '100%', border: '1px solid grey', borderRadius: '0.2rem', background: '#fff' }}>
                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} style={{ border: '0', margin: '0.25rem',width:'100%' }} />
                  </div>
                </Col>
              </Row>
              <Row className="my-3 mx-0 d-flex align-items-center">
                <Col xs={12} sm={12} md={3}>
                  <div className="" style={{ color: '#000', fontWeight: '500', whiteSpace: 'nowrap', maxWidth: 'fit-content', width: 'fit-content', fontSize: "13px" }}>
                    Add logo:<br />(Optional)
                  </div>
                </Col>
                <Col xs={12} sm={12} md={9} style={{ marginTop: "0rem" }}>
                  <div className="d-flex flex-column">
                    <div
                      style={{
                        width: "fit-content",
                        border: '2px solid lightgray',
                        borderRadius: "50%",
                        // padding: '0.5rem'
                      }}
                    >
                      <img src={addImg} width={50} height={50} ref={imgRef} onClick={() => inputFileRef.current.click()} style={{borderRadius:'50%'}}/>
                    </div>
                  </div>
                  <input type="file" ref={inputFileRef} onChange={(e) => setFile(e.target.files[0])} hidden />
                </Col>
              </Row>
              <Row className="my-3 mx-0 d-flex align-items-center">
                <Col xs={12} sm={12} md={3}>
                  <div className="" style={{ color: '#000', fontWeight: '500', whiteSpace: 'nowrap', maxWidth: 'fit-content', width: 'fit-content', fontSize: "13px" }}>
                    Color:
                  </div>
                </Col>
                <Col xs={12} sm={12} md={9} style={{ marginTop: "0rem" }}>
                  <div className="d-flex align-items-center" style={{ border: '1px solid grey', width: '50%', background: '#fff', borderRadius: '0.2rem', display: 'flex', justifyContent: 'space-between', padding: '0 0.5rem' }}>
                    <input type="text" value={color} onBlur={()=>handleUrl()} onChange={(e) => setColor(e.target.value)} style={{ border: '0', margin: '0.25rem' }} />
                    <input style={{ height: '20px', width: '20px', borderRadius: '0.2rem', background:`${color}` }} type="color" value={color} onBlur={()=>handleUrl()} onChange={(e) => setColor(e.target.value)} />
                  </div>
                </Col>
              </Row>
            </form>
            <Row className="my-3 mx-0 d-flex align-items-center">
              <Col xs={12} sm={12} md={3}>
                <div className="" style={{ color: '#000', fontWeight: '500', whiteSpace: 'nowrap', maxWidth: 'fit-content', width: 'fit-content', fontSize: "13px" }}>
                  Shape:
                </div>
              </Col>
              <Col xs={12} sm={12} md={9} style={{ marginTop: "0rem" }}>
                <div className="d-flex align-items-center">
                  {
                    qrcode_shapes_images.slice(0, 4).map((qrData) => {
                      return (
                        <>
                          <div
                            className="mr-2"
                            style={{
                              border: shape?._id == qrData._id ? "2px solid #327ebc" : "",
                              borderRadius: shape?._id == qrData._id && "20%",
                              cursor: "pointer",
                              position: shape?._id == qrData._id && "relative",
                            }}
                            onClick={() => setShape(qrData)}
                          >
                            {qrData._id == shape._id && <div
                              style={{
                                position: "absolute",
                                top: "-10px",
                                right: "-10px",
                                height: "23px",
                                width: "23px",
                                borderRadius: '50%',
                                backgroundColor: "#327ebc",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <GiCheckMark color={"#fff"} size={13} />
                            </div>}
                            <img
                              src={qrData.imageSrc}
                              alt={qrData.alt}
                              style={{
                                height: "50px",
                                width: "50px",
                                margin: "0.5rem"
                              }}
                            />
                          </div>
                        </>
                      )
                    })
                  }
                  <div
                    style={{
                      height: "60px",
                      width: "60px",
                      padding: "0px",
                      borderRadius: "20%",
                      backgroundColor: "#ccc",
                      display: "flex",
                      alignItems: 'center',
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    className="ml-3"
                    onClick={() => { toggleQrCodeShapeModal() }}
                  >
                    <p className="m-0">View<br />More</p>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="d-flex mx-4" style={{ justifyContent: 'space-evenly', width: '40%',gap:'20px' }}>
              <button
                style={{
                  border: '0',
                  width: '50%',
                  color: '#fff',
                  background: 'darkgray',
                  padding: '0.5rem',
                  borderRadius: '0.2rem'
                }}
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                style={{
                  border: '0',
                  width: '50%',
                  color: '#fff',
                  background: '#327ebc',
                  padding: '0.5rem',
                  borderRadius: '0.2rem'
                }}
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center" style={{width:'30%'}}>
            {loading ? <div>Generating QR...</div> : qrShow ? <img src={`data:image/jpeg;base64,${qrShow}`} width={200} height={200} /> : null}
          </div>
          </div>
        </div>
        <div>
          
          <div className="my-4 py-4">
            My QR Codes:
            <div className="py-4">
              <Table className="table table-border-horizontal">
                <thead>
                  <tr style={{ background: '#f9fafe' }}>
                    <th>#</th>
                    <th>Date / Time</th>
                    <th>QR Code Name</th>
                    <th>Website URL</th>
                    <th>QR</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data?.map((ele, ind) => {
                      let date = moment(ele.createdAt);
                      return (
                        <tr style={{background:'#ffffff'}}>
                          <td>{ind + 1}</td>
                          <td>{date.format('DD-MM-YYYY')} <br />{date.format('LT')}</td>
                          <td>{ele.name}</td>
                          <td>{ele.url}</td>
                          <td>
                            <div className="d-flex align-items-center">
                              {ele.qr && <img src={`${client.url(`images/merchant-qrcode/backend/${ele.qr}`)}`} width={50} height={50} />}
                              <button className="d-flex align-items-center" onClick={() => handleDownload(ele.qr)} style={{ border: '0', background: '#327ebc', color: '#fff', padding: '0.3rem', margin: '1rem', borderRadius: '0.2rem' }}><Download style={{padding:'0.2rem'}}/>DOWNLOAD</button>
                              <button onClick={() => handleDelete(ele?._id, ele?.qr)} style={{ border: '0', background: 'red', color: '#fff', padding: '0.3rem', margin: '1rem', borderRadius: '0.2rem' }} >DELETE</button>
                            </div>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <Modal
                isOpen={enableShapeModal}
                centered
                toggle={toggleQrCodeShapeModal}
                style={{ width: "510px", maxWidth: '510px' }}
            >
                <ModalHeader style={{ display: "flex", justifyContent: "center", padding: "0.25rem", marginBottom: "0.5rem" }}>
                    <span
                        className="first_close_icon"
                        style={{ position: "absolute", right: "15px" }}
                    >
                        <Icon
                            path={mdiClose}
                            onClick={toggleQrCodeShapeModal}
                            size={1.4}
                            style={{ color: "#646777", cursor: "pointer" }}
                        />
                    </span>
                    <p style={{ fontSize: "18px", fontWeight: "500", marginTop: "0rem", marginBottom: "0.5rem" }}> Choose Shape</p>
                </ModalHeader>

                <ModalBody style={{ height: "400px", overflowY: "scroll", padding: "0px" }}>
                    <Col md={12} className="p-0">
                        <Row className='m-0 p-3' style={{}}>
                            {qrcode_shapes_images.map((qrData) => {
                                return (
                                    <>
                                        <Col xs={3} sm={3} md={2} className={`m-0 p-0 d-flex align-items-center justify-content-center`}>
                                            <div
                                                className='w-90'
                                                style={{
                                                  border: shape?._id == qrData._id ? "2px solid #008fe2" : "",
                                                  borderRadius: shape?._id == qrData._id && "20%",
                                                  cursor: "pointer",
                                                  position: shape?._id == qrData._id && "relative",
                                                }}
                                                onClick={() => setShape(qrData)}
                                            >
                                                {shape?._id == qrData._id && <div
                                                    style={{
                                                        position: "absolute",
                                                        top: "-9px",
                                                        right: "-10px",
                                                        height: "23px",
                                                        width: "23px",
                                                        backgroundColor: "#D23F99",
                                                        borderRadius: "50%",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        zIndex: '1'
                                                    }}
                                                >
                                                    <GiCheckMark color={"#fff"} size={13} />
                                                </div>}
                                                <img
                                                    src={qrData.imageSrc}
                                                    alt={qrData.alt}
                                                    style={{
                                                        height: "50px",
                                                        width:  "50px",
                                                        margin: "0.5rem"
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </>
                                )
                            })}
                        </Row>
                    </Col>
                </ModalBody>
                <ModalFooter className={`d-flex align-items-center justify-content-center pb-0`}>
                    <Button
                        variant="primary"
                        className='mb-0'
                        style={{ background: '#d23f99', color: 'white', fontSize: "14px", padding: "10px 40px " }}
                        onClick={() => {
                            toggleQrCodeShapeModal();
                        }}
                        id="qr_shape_select_btn_id"
                    >
                        Select Shape
                    </Button>
                </ModalFooter>
            </Modal>
            <CustomCropperModel
                        file={filec}
                        onCompleted={(croppedFile) => {
                            if (croppedFile) {
                              handleFile(croppedFile)
                            }
                        }}
                        onDiscard={() => {
                            setFile(null);
                        }}
                    />
      </div>
    </>
  )
}
export default GenerateQrcode