import React, { Component, useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormGroup, Col, Row, Input, CustomInput } from 'reactstrap';
import useForm from "react-hook-form";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import ImageUploader from 'react-images-upload';
import 'react-times/css/material/default.css';
import { doUpload } from "../../Api/libDropbox";

let AddImageModal = (props) => {

    let [loadingbtn, setloadingbtn] = useState(false);
    let [inputList, setInputList] = useState([{ title: "" }]);
    let [showerrorstatus, setshowerrorstatus] = useState(false);
    let [firstTimeLoad, setfirstTimeLoad] = useState(true);
    let [pictures, setpictureso] = useState([]);
    let [image_data, setimage_data] = useState(null);
    let [getfolderName, setfolderName] = useState('');

    const { isOpen, folderName } = props;

    useEffect(() => {
        if(isOpen)
        {
            if(firstTimeLoad)
            {
                setfolderName(folderName);
            }
        }else{
            setfirstTimeLoad(true);
        }
    },[props]);

    const { handleSubmit, register, errors } = useForm();

    useEffect(() => {
        if(isOpen)
        {
            if(firstTimeLoad)
            {
            setfirstTimeLoad(false);
            }
        }else{
            setfirstTimeLoad(true);
        }
    },[props]);

    const handleResetClick = () => {
        setInputList([{ title: "" }]);
    };

    const handleAddClick = () => {
        setInputList([...inputList, { title: ""}]);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    let onDrop = (pictureFiles, pictureDataURLs) => {
        setpictureso(pictureFiles[0]);
        // try {
        //     var mimeType = pictureFiles[0].type;
        //     // Image upload
        //     var reader = new FileReader();
        //     reader.readAsDataURL(pictureFiles[0]);
        //     reader.onload = (_event) => {
        //         let imageData;
        //         imageData = reader.result;
        //         // console.log(imageData, "imageData")
        //         console.log(pictureFiles, "pictureFiles")
        //         imageData = imageData.split(",")[1];
        //         let image_data_temp = { mime: mimeType, data: imageData }
        //         // console.log("image set");
        //         setimage_data(image_data_temp);
        //     }
        // } catch (err) {
        //     // console.log(pictureFiles);
        //     console.log("error image not set");
        //     setimage_data(null);
        // }
    }

    const onSubmit = form_data => {

        if (pictures) {

            // if (image_data == null) {
            //     console.log("invalid Image");
            //     return false;
            // }

            setloadingbtn(true);
            doUpload(folderName + '/' + pictures.name, pictures, (error, res) => {
                if (error) {
                    console.log(error, "error")
                        toast.error("Internal error occured. Please contact support 2");

                } else {
                    setfirstTimeLoad(true);
                    handleResetClick();
                    toast.success("Added Successfully");
                    props.onClose(true);
                }
                setloadingbtn(false);
            })
            // form_data.image_data = image_data;

            // form_data.folder = getfolderName;
            // let data = { folder: form_data.folder, image_data: image_data };

            // client.post(api.addimage, form_data, async function (error, response) {
            //     if (!error) {
            //         if (typeof response.error !== "undefined") {
            //             try {
            //                 if (!response.error) {
            //                     setfirstTimeLoad(true);
            //                     handleResetClick();
            //                     toast.success("Added Successfully");
            //                     props.onClose(true);

            //                 } else {
            //                     toast.error(response.message);
            //                 }
            //             } catch (e) {
            //                 toast.error("Internal error occured. Please contact support 2");
            //             }
            //         } else {
            //             toast.error("Internal error occured. Please contact support 2");
            //         }
            //     } else {
            //         toast.error("Internal error occured. Please contact support 3");
            //     }
            //     setloadingbtn(false);
            // });
        }
        else {
            setshowerrorstatus(true);
            errors.showMessages();
        }
    }
    return (

        <Modal isOpen={props.isOpen} toggle={props.onClose} size="lg" className="modal-body" centered={true}>
            <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={props.onClose}><p className="m-0 font-weight-bold">Add Image</p></ModalHeader>
                <ModalBody>
                    <Row form>
                        <FormGroup className="w-100">
                            <Row form>
                                <Col md={12}>
                                    <ImageUploader
                                        withIcon={false}
                                        withPreview={true}
                                        label=''
                                        singleImage={true}
                                        buttonText="Upload Images"
                                        onChange={onDrop}
                                        imgExtension={[".jpg", ".gif", ".png", ".gif", ".svg"]}
                                        // maxFileSize={1048576}
                                        fileSizeError=" file size is too big"
                                    />
                                    <span style={{ color: '#ff5370', justifyContent: 'center', display: 'flex' }}>{(showerrorstatus == true && !pictures) ? 'Image is required' : ""}</span>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {!loadingbtn ?
                        <>
                            <Button color="default" type="button" onClick={() => props.onClose(false) }>Close</Button>
                            <Button color="primary" type="submit" onClick={() => setshowerrorstatus(true)}>Upload</Button>
                        </>
                        :
                        <div className="loader-box pull-right" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 20, width: 20 }}></span></div>
                    }
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default AddImageModal;