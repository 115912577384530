
import React, { useEffect, Fragment, useState } from "react";
import Breadcrumb from '../../components/common/breadcrumb';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import client from "../../Api/HTTPClient";
import axios from 'axios';
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import TermsAndCondition from "./TermAndCondition";
import PrivacyAndPolicy from "./PrivacyAndPolicy";
import Security from "./Security";
import RefundReturns from "./RefundReturns";

const languages = [
    { label: 'English', value: 'en' },
    { label: 'Arabic', value: 'ar' },
    { label: 'Chinese', value: 'zh' },
    { label: 'Hindi', value: 'hi' },
    { label: 'Japanese', value: 'jp' },
    { label: 'Thai', value: 'th' },
    { label: 'Spanish', value: 'es' },
    { label: 'Persian', value: 'fa' },
]

const FooterPages = () => {

    const [tabIndex, setTabIndex] = useState(0);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [selectedLang, setSelectedLang] = useState('en')

    const host = client.url("");
    let api_domain = host.replace(/(https?:\/\/)?(www.)?/i, '');
    if (api_domain.indexOf('/') !== -1) {
        api_domain = api_domain.split('/')[0];
    }



    const fetchContentAllData = (formData = {}) => {

        const config = { headers: { "x-access-token": "ipay-access-token" } }

        setFetchLoading(true)
        axios.put(client.url(api.fetch_footer_page_data), formData, config).then(response => {

            if (response) {
                if (response.data) {
                    if (!response.data.error) {
                        setData(response.data.data)
                        setFetchLoading(false)
                    }
                    else {
                        setFetchLoading(false)
                        toast.error(response.data.message);
                    }
                }
                else {
                    setFetchLoading(false)
                    toast.error('Internal error!');
                }
            }
            else {
                setFetchLoading(false)
                toast.error('Internal error!');
            }
        }).catch(error => {
            setFetchLoading(false)
            toast.error(error);
        })
    }

    const submitContentData = (form_data) => {

        const config = { headers: { "x-access-token": "ipay-access-token" } };
        const formData = { type: form_data.type, conten_data: form_data.contentDatas, language: selectedLang };

        setLoading(true);
        axios.put(client.url(api.update_footer_page_data), formData, config).then(response => {

            if (response) {
                if (response.data) {
                    if (!response.data.error) {
                        setData(response.data.data)
                        setLoading(false)
                        toast.success('Updated Successfully');
                    }
                    else {
                        setLoading(false)
                        toast.error(response.data.message);
                        toast.error('Internal error!');
                    }
                }
                else {
                    setLoading(false)
                    toast.error('Internal error!');
                }
            }
            else {
                setLoading(false)
                toast.error('Internal error!');
            }
        }).catch(error => {
            setLoading(false)
            toast.error(error);
        })

    }

    useEffect(() => {
        fetchContentAllData();
    }, [])

    return (
        <Fragment>
            <div className="row">
                <div className="col-xl-auto col-sm-12" style={{ fontSize: "12px" }}>
                    <Breadcrumb title="Footer Pages" parent="Home" />
                </div>
            </div>

            <div className="container-fluid">
                <div className="row theme-tab">
                    <Tabs className="col-sm-12" selectedIndex={languages.findIndex(lngItem => lngItem.value == selectedLang)} style={{ padding: "0", backgroundColor: "white", borderRadius: "3px", border: "1px solid rgb(223, 232, 241)", marginBottom: "0px" }} >
                        <TabList className="tabs tab-title">
                            {
                                languages.map((langVal, langInd) => {
                                    return (
                                        <Tab
                                            key={langInd}
                                            style={{ display: "flex", alignItems: "center" }}
                                            onClick={evnt => {
                                                setSelectedLang(langVal.value)
                                            }}
                                        >
                                            {langVal.label}
                                        </Tab>
                                    )
                                })
                            }
                        </TabList>
                    </Tabs>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="container-fluid">
                        <div className="row theme-tab">
                            <Tabs
                                className="col-sm-12"
                                defaultIndex={tabIndex}
                                onSelect={(index) => setTabIndex(index)}
                                style={{ padding: "0", backgroundColor: "white", borderRadius: "3px", border: "1px solid rgb(223, 232, 241)", marginBottom: "0px" }}
                            >
                                <TabList className="tabs tab-title">
                                    <Tab style={{ display: "flex", alignItems: "center" }}                                                >
                                        Terms & Condition
                                    </Tab>
                                    <Tab style={{ display: "flex", alignItems: "center" }}                                                >
                                        Privacy Policy
                                    </Tab>
                                    <Tab style={{ display: "flex", alignItems: "center" }}                                                >
                                        Security
                                    </Tab>
                                    <Tab style={{ display: "flex", alignItems: "center" }}                                                >
                                        Refund & Returns
                                    </Tab>
                                </TabList>

                                <TabPanel style={{ padding: tabIndex == 0 ? "22px" : "0px" }}>
                                    <TermsAndCondition
                                        api_domain={api_domain}
                                        initialContent={data.find((currData) => currData.type == 1 && currData.language == selectedLang)}
                                        submitContentData={submitContentData}
                                        loading={loading}
                                        fetchLoading={fetchLoading}
                                        language={selectedLang}
                                    />
                                </TabPanel>
                                <TabPanel style={{ padding: tabIndex == 1 ? "22px" : "0px" }}>
                                    <PrivacyAndPolicy
                                        api_domain={api_domain}
                                        initialContent={data.find((currData) => currData.type == 2 && currData.language == selectedLang)}
                                        submitContentData={submitContentData}
                                        loading={loading}
                                        fetchLoading={fetchLoading}
                                        language={selectedLang}
                                    />
                                </TabPanel>
                                <TabPanel style={{ padding: tabIndex == 2 ? "22px" : "0px" }}>
                                    <Security
                                        api_domain={api_domain}
                                        initialContent={data.find((currData) => currData.type == 3 && currData.language == selectedLang)}
                                        submitContentData={submitContentData}
                                        loading={loading}
                                        fetchLoading={fetchLoading}
                                        language={selectedLang}
                                    />
                                </TabPanel>
                                <TabPanel style={{ padding: tabIndex == 3 ? "22px" : "0px" }}>
                                    <RefundReturns
                                        api_domain={api_domain}
                                        initialContent={data.find((currData) => currData.type == 4 && currData.language == selectedLang)}
                                        submitContentData={submitContentData}
                                        loading={loading}
                                        fetchLoading={fetchLoading}
                                        language={selectedLang}
                                    />
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default FooterPages;