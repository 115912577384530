import React, { Fragment } from "react";
import HospoSafeComponent from "../components/hospoSafeRegistry/HosposafeComponent";

class CustomerHospoSafe extends React.Component { 
    render(){
        return(
            <Fragment>
                <HospoSafeComponent id={this.props.customerId} info={this.props.customerInfo} customerType="customer"/>
            </Fragment>
        );
    }
}

export default CustomerHospoSafe;


// import React, { Fragment } from "react";
// import { Button, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem, UncontrolledTooltip, NavLink as NavLinkTab } from 'reactstrap';
// import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
// import moment from "moment-timezone";
// import Datechoose from "../components/ui-elements/datePicker";
// import DatePicker from "react-datepicker";


// import client from "../Api/HTTPClient";
// import displayMobile from "../components/ui-elements/display_mobile";
// import api from "../constant/apilist";
// import { ToastContainer, toast } from "react-toastify";
// import { saveAs } from "file-saver";

// class CustomerHospoSafe extends React.Component {
//     constructor(props) {
//         super(props);

//         this.state = {
//             customerId: this.props.customerId,
//             customerInfo: this.props.customerInfo,
//             visits: [],
//             filteredVisits: null,
//             visitId: null,
//             deleteModal: false,
//             mailModal: false,
//             filteredDate: null,
//             access_level: localStorage.getItem("accessLevel"),
//             startDate : new Date("2014/02/08"),
//             endDate : new Date("2014/02/10"),
//         };


//     }

//     componentDidMount() {
//         this.getCustomerVisitInfo();
//     }

//     renderdatepicker = () => {

//         let {startDate, endDate } = this.state;
        
//         return (
//             <>
//                 <DatePicker
//                     selected={startDate}
//                     onChange={startDate => this.setState({ startDate })}
//                     selectsStart
//                     startDate={startDate}
//                     endDate={endDate}
//                 />
//                 <DatePicker
//                     selected={endDate}
//                     onChange={endDate => this.setState({ endDate })}
//                     selectsEnd
//                     startDate={startDate}
//                     endDate={endDate}
//                     minDate={startDate}
//                 />
//             </>
//         );
//     }

//     // createAndDownloadPdf = () => {

//     //     if (this.state.filteredVisitors == null) {
//     //         return;
//     //     } else {
//     //         const data = {
//     //             name: this.state.merchantInfo.name,
//     //             date: this.state.filteredDate,
//     //             total_visits: this.state.filteredVisitors.length,
//     //             visitors: this.state.filteredVisitors
//     //         }

//     //         return client.post(api.createPdf, data, (error, response) => {
//     //             if (!error) {
//     //                 client.get(api.fetchPdf,  (error, res) => {
//     //                     if(error){
//     //                          console.log(error);
                            
//     //                     }else{
//     //                         console.log("sccess");
//     //                     }
                         
//     //                     /* const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
//     //                     saveAs(pdfBlob, 'newPdf.pdf'); */

//     //                 })

//     //             } else {
//     //                 console.log("Error");

//     //             }
//     //         })
//     //     }

//     // }

//     getCustomerVisitInfo() {
//         const postdata = { mobile: this.state.customerInfo.mobile };
//         client.post(api.get_cust_visits_data, postdata, (error, response) => {
//             if (!error) {
//                 if (!response.error) {
//                     console.log(response);
//                     this.setState(prevState => {
//                         return {
//                             ...prevState,
//                             visits: response.result.data.reverse()
//                         }
//                     })
//                 } else {
//                     setTimeout(() => {
//                         toast.error(" No visitors found");
//                     }, 200);
//                 }
//             } else {
//                 setTimeout(() => {
//                     toast.error("Internal error occured. Please contact support");
//                 }, 200);
//             }
//         })

//     }
    
//     deleteClick = (id) => {
//         this.setState(prevState => {
//             return {
//                 ...prevState,
//                 deleteModal: !prevState.deleteModal,
//                 visitId: id
//             }
//         });
//     }

//     mailClick = () => {
//         this.setState(prevState => {
//             return {
//                 ...prevState,
//                 mailModal: !prevState.mailModal,
//             }
//         });
//     }

//     toggleMailModal = () => {
//         this.setState(prevState => {
//             return {
//                 ...prevState,
//                 mailModal: !prevState.mailModal
//             }
//         })
//     }


//     dateHandler = (date) => {
//         this.setState({ filteredDate: date })
//         const findDate = moment(date).format("L");
//         // this.setState({filteredDate:findDate})
//         const filteredVisits = this.state.visits.filter(el => {
//             return (moment(el.added_date).format("L")) == findDate

//         });
//         /* console.log(date);
//         console.log(filteredVisitors); */

//         if (date != null) {
//             this.setState({ filteredVisits: filteredVisits })
//         } else {
//             this.setState({ filteredVisits: null });
//         }
//     }

//     toggleDeleteModal = () => {
//         this.setState(prevState => {
//             return {
//                 ...prevState,
//                 deleteModal: !prevState.deleteModal
//             }
//         });
//     }

//     deleteVisitorRegistry = (e) => {
//         e.preventDefault();

//         const data = { visitor_id: this.state.visitId }

//         client.post(api.delete_visitor_registry, data, (error, response) => {
//             if (!error) {
//                 // alert(JSON.stringify(response));
//                 //   return false;
//                 if (typeof response.error !== "undefined") {
//                     try {
//                         if (!response.error) {
//                             toast.success(response.message);
//                             window.location.reload(true);
//                         } else {
//                            // toast.error(response.message);
//                         }
//                     } catch (e) {
//                         toast.error("Internal error occured. Please contact support");
//                     }
//                 } else {
//                     toast.error("Internal error occured. Please contact support");
//                 }
//             } else {
//                 toast.error("Internal error occured. Please contact support");
//             }

//         });
//     }

//     render_date = (add_date) => {

//         if (typeof (add_date) === "undefined") {
//             return;
//         }

//         const convert_date = new Date(add_date);
//         const added_date = moment.tz(convert_date, "Australia/Sydney").format("DD/MM/YYYY HH:mm");
//         return added_date;
//     }

//     renderAllTable = () => {
//         let showVisits = []
//         let { visits, filteredVisits, access_level } = this.state;
//         if (filteredVisits == null) {
//             showVisits = [...visits]
//         } else {
//             showVisits = [...filteredVisits]
//         }

//         return (
//             <Fragment>
//                 {this.renderdatepicker()}
//                 <div className="table-responsive" ref={this.mailRef}>
//                     <table className="table table-border-horizontal table-striped" >
//                         <thead>
//                             <tr className="d-flex">
//                                 <th scope="col" style={{ width: "4%" }}>#</th>
//                                 <th scope="col" style={{ width: "14%" }}>Date / Time of visit</th>
//                                 <th scope="col" style={{ width: "20%" }}>Business Name</th>
//                                 <th scope="col" style={{ width: "20%" }}>Number of Guests</th>
//                                 <th scope="col" style={{ width: "20%" }}>Guest Name</th>
//                                 <th scope="col" style={{ width: "18%" }}>Mobile</th>
//                                 {access_level != 1 && <th scope="col" style={{ width: "18%" }}>Action</th>}
//                             </tr>
//                         </thead>
//                         <tbody>

//                             {showVisits.length != 0 ? showVisits.map((item, index) => {
//                                 return (
//                                     <tr className="d-flex" key={item._id}>
//                                         <th scope=" row" style={{ width: "4%" }}>{index + 1}</th>
//                                         <td style={{ width: "14%" }}>{this.render_date(item.added_date)}</td>
//                                         <td style={{ width: "20%" }}>{item.merchant_name}</td>
//                                         <td style={{ width: "20%" }}>{item.guestno}</td>
//                                         <td style={{ width: "20%" }}>{JSON.parse(item.guests).length!=0?
//                                         JSON.parse(item.guests).map(el => {
//                                             return <li key={el.index}>{el.value}</li>
//                                         }):<li>{item.name}</li>}</td>
//                                         <td style={{ width: "18%" }}>{displayMobile(item.mobile)}</td>
//                                         {access_level != 1 && <td style={{ width: "18%" }}>
//                                             {access_level == 3 && <span style={{ marginRight: "10px", fontSize: "1.2em" }} onClick={() => this.deleteClick(item._id)}>
//                                                 <i style={{ color: "red" }} id="delete" className="fa fa-trash-o"></i>
//                                             </span>}
//                                             <span style={{ marginRight: "10px", fontSize: "1.2em" }}>
//                                                 <i className="fa fa-list-alt" style={{ color: "black" }} id="info"></i>
//                                                 <UncontrolledTooltip placement="bottom" target="info">
//                                                     Info
//                                                 </UncontrolledTooltip>
//                                             </span>
//                                         </td>}
//                                     </tr>);
//                             }) : <h6 style={{ textAlign: "center" }}> No records found! </h6>};
//             </tbody>
//                     </table>
//                 </div>
//             </Fragment>
//         );
//     }

//  /*    renderMailModal = () => {
//         const { mailModal } = this.state;

//         return (
//             <div className="card-body btn-showcase">
//                 <Modal isOpen={mailModal} toggle={this.toggleMailModal} className="modal-body" centered={true}>
//                     <form className="theme-form" noValidate="" >
//                         <ModalBody>
//                             <div id="pills-darkhome" role="tabpanel" aria-labelledby="pills-darkhome-tab">
//                                 <div className="email-compose">
//                                     <div className="email-top compose-border">
//                                         <div className="row">
//                                             <div className="col-sm-8 xl-50">
//                                                 <h4 className="mb-0">New Message</h4>
//                                             </div>
//                                         </div>
//                                  </div>
//                                     <div className="email-wrapper">
//                                         <form className="theme-form">
//                                             <div className="form-group">
//                                                 <label className="col-form-label pt-0" htmlFor="exampleInputEmail1">To</label>
//                                                 <input className="form-control" id="exampleInputEmail1" type="email" />
//                                             </div>
//                                             <div className="form-group">
//                                                 <label htmlFor="exampleInputPassword1">Subject</label>
//                                                 <input className="form-control" id="exampleInputPassword1" type="text" />
//                                             </div>
//                                         </form>
//                                     </div>
//                                 </div>
//                             </div>
//                         </ModalBody>
//                         <ModalFooter>
//                             <Button color="primary" type="submit" >Yes</Button>
//                             <Button color="secondary" onClick={this.toggleMailModal}>No</Button>
//                         </ModalFooter>
//                     </form>
//                 </Modal>
//             </div>
//         );
//     } */

//     renderDeleteModal = () => {
//         const { deleteModal } = this.state;

//         return (
//                 <Modal isOpen={deleteModal} toggle={this.toggleDeleteModal} className="modal-body" centered={true}>
//                     <form className="theme-form" noValidate="" onSubmit={this.deleteVisitorRegistry}>
//                         <ModalHeader><h6>Delete visit</h6></ModalHeader>
//                         <ModalBody>
//                             <ModalBody style={{ padding: "16px 0" }}>
//                                 <h6>Do you want to delete this visit record ?</h6>
//                             </ModalBody>
//                             <ModalFooter>
//                                 <Button color="secondary" onClick={this.toggleDeleteModal}>Not sure!</Button>
//                                 <Button color="primary" type="submit"  >Yes Please!</Button>
//                             </ModalFooter>
//                         </ModalBody>
//                     </form>
//                 </Modal>
//         );
//     }



//     render() {
//         let { visits, filteredDate, filteredVisits, customerInfo, visitId, customerId, access_level } = this.state;
//          /* console.log(visits);
//          console.log(filteredVisits);
//          console.log(filteredDate);
//         console.log(customerInfo);
//         console.log(customerId); */
//          console.log(visits);

//         return (
           
//             <Fragment>
//                 {visitId ? this.renderDeleteModal() : null}
//                 <div className="container-fluid">
//                     <div className="row">
//                         <div className="col-sm-12 col-xl-12 xl-100">
//                             <div className="card">
//                                 <div className="card-body" style={{ padding: "0.9rem" }}>
//                                     <div className="row" style={{margin:"0 0 10px 0"}}>
//                                         <Datechoose className="col-md-4" style={{ width: "20%" }} onDatePicked={this.dateHandler}></Datechoose>
//                                         <a style={{ fontSize: "1.8em" }} className="col-md-4"
//                                         // href={`${client.url(`uploads/result.pdf`)}`}
//                                             // onClick={this.createAndDownloadPdf}
//                                             >
//                                                 <i className="fa fa-cloud-download" style={{color:"#4466f2"}}></i>
                                           
//                                         </a>
//                                         {/* <a style={{fontSize:"1.8em"}} className="pull-right" href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=someone@gmail.com" target="_blank"><i className="icofont icofont-email"></i></a> */}
//                                     </div>
//                                     <div>

//                                     </div>
//                                     {this.renderAllTable()}
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 {/* <div className="card-body">
//                     {this.renderMailModal()}
//                 </div> */}
//             </Fragment>
//         );
//     }
// }

// export default CustomerHospoSafe;


