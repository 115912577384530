import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../constant/apilist";
import client from "../Api/HTTPClient";
import { DisappearedLoading } from "react-loadingg";

import DocPendingVerification from "../components/DocPendingVerification/DocPendingVerification"


const CustomerDocPending = () => {

    const [customersData, setCustomersData] = useState(null)

    useEffect(() => {
        // alert("hrer");
        getAllData()
    }, []);

    const getAllData = () => {
        let postdata = { pendingCustomer: true, type: 2 };
        client.post(api.getCustMerchData, postdata, (error, response) => {
            if (!error) {
                try {
                    if (!response.error) {
                        const customers = response.result.customers.filter(el => el.status == 1 && el.is_verified == 2)
                        setCustomersData(customers);

                    } else {
                        setTimeout(() => {
                            toast.error(response.message);
                        }, 200);
                    }
                } catch (e) {
                    setTimeout(() => {
                        toast.error("Internal error occured. Please contact support" + e.message);
                    }, 200);
                }

            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
            //   current.setState({ loading: false });
        });
    };
    //console.log(customersData);


    return (
        <div>
            {customersData ?
                <DocPendingVerification customersData={customersData} type="customers" /> : <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                    <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                        <DisappearedLoading size="medium" color="#514F4E" />
                    </div>
                </div>}
        </div>
    );
}

export default CustomerDocPending;