export const REVERTO_INITIALSTATE = "REVERTO_INITIALSTATE";
export const MERC_INITIALSTATE = "MERC_INITIALSTATE";

export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";

export const VERIFY_OTP_START = "VERIFY_OTP_START";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAIL = "VERIFY_OTP_FAIL";

export const LOGOUT = "LOGOUT";

export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const LOGOUTDEVICE_START = "LOGOUTDEVICE_START";
export const LOGOUTDEVICE_SUCCESS = "LOGOUTDEVICE_SUCCESS";
export const LOGOUTDEVICE_FAIL = "LOGOUTDEVICE_FAIL";

export const LOGOUTALL_START = "LOGOUTALL_START";
export const LOGOUTALL_SUCCESS = "LOGOUTALL_SUCCESS";
export const LOGOUTALL_FAIL = "LOGOUTALL_FAIL";

export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";

export const FETCH_CUSTDATA_START = "FETCH_CUST_DATA";
export const FETCH_CUSTDATA_SUCCESS = "FETCH_CUSTDATA_SUCCESS";
export const FETCH_CUSTDATA_FAILED = "FETCH_CUSTDATA_FAILED";

export const FETCH_CATDATA_START = "FETCH_CAT_DATA";
export const FETCH_CATDATA_SUCCESS = "FETCH_CATDATA_SUCCESS";
export const FETCH_CATDATA_FAILED = "FETCH_CATDATA_FAILED";

export const FETCH_SLIDATA_START = "FETCH_SLI_DATA";
export const FETCH_SLIDATA_SUCCESS = "FETCH_SLIDATA_SUCCESS";
export const FETCH_SLIDATA_FAILED = "FETCH_SLIDATA_FAILED";

export const RESENT_OTP_START = "RESENT_OTP_START";
export const RESENT_OTP_SUCCESS = "RESENT_OTP_SUCCESS";
export const RESENT_OTP_FAILED = "RESENT_OTP_FAILED";


export const UPDATEDETAILS_START = "UPDATEDETAILS_START";
export const UPDATEDETAILS_SUCCESS ="UPDATEDETAILS_SUCCESS";
export const UPDATEDETAILS_FAILED = "UPDATEDETAILS_FAILED";

export const FETCH_RESTAURANT_STRUCTURE_START = "FETCH_RESTAURANT_STRUCTURE_START";
export const FETCH_RESTAURANT_STRUCTURE_SUCCESS = "FETCH_RESTAURANT_STRUCTURE_SUCCESS";
export const FETCH_RESTAURANT_STRUCTURE_FAILED = "FETCH_RESTAURANT_STRUCTURE_FAILED";