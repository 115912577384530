import React, { useState } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { Col } from "reactstrap";
import QuizForm from "./QuizForm";
import PublishQuiz from "./PublishQuiz";
import ResultQuiz from "./ResultQuiz";

const QuizTabs = (props) => {
	const [tabIndex, setTabIndex] = useState(0);
	const [postData, setPostData] = useState(null);
	const [quizId, setQuizId] = useState(null);

	return (
		<div className="card">
			<div className="card-header">
				<Tabs selectedIndex={tabIndex}>
					<TabList className="tbs tab-title">
						<Col md={3}>
							<h6 style={{ fontSize: "15px", marginTop: "10px" }}>
								<i className="icon-arrow-left" style={{ color: "black" }}></i> &nbsp;&nbsp;Quiz - {props.campaignName}
							</h6>
						</Col>
						<Col md={9}>
							<Tab style={{ marginLeft: "22%" }}>CREATE</Tab>
							<Tab>PUBLISH</Tab>
							<Tab>RESULTS</Tab>
						</Col>
					</TabList>
					<TabPanel>
						<QuizForm setTabIndex={setTabIndex} setPostData={setPostData}></QuizForm>
					</TabPanel>
					<TabPanel>
						<PublishQuiz
							setTabIndex={setTabIndex}
							setPostData={setPostData}
							postData={postData}
							setQuizId={setQuizId}
							campaignName={props.campaignName}
						></PublishQuiz>
					</TabPanel>
					<TabPanel>
						<ResultQuiz quizId={quizId}></ResultQuiz>
					</TabPanel>
				</Tabs>
			</div>
		</div>
	);
};

export default QuizTabs;
