import React, { Fragment, useState, useEffect } from "react";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import Breadcrumb from "../../components/common/breadcrumb";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";

const Notifications = (props) => {
    /* const [notificationsData, setNotificationsData] = useState([]); */

    useEffect(() => {
        //getAllNotifications();

    }, [])

    const getAllNotifications = () => {
        let postdata = {};

        client.post(api.get_all_notifications, postdata, (error, response) => {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            // console.log(response);
                            //setNotificationsData(response.result.data)
                        }
                        else {
                            setTimeout(() => {
                                toast.error(response.message);
                            }, 200);
                        }
                    } catch (e) {
                        setTimeout(() => {
                            toast.error("Internal error occured. Please contact support");
                        }, 200);
                    }
                } else {
                    setTimeout(() => {
                        toast.error("Internal error occured. Please contact support");
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        });
    };

    const render_date = (add_date) => {

        if (typeof (add_date) === "undefined") {
            return;
        }

        const convert_date = new Date(add_date);
        const added_date = moment.tz(convert_date, "Australia/Sydney").format("DD/MM/YYYY HH:mm");
        return added_date;
    }

    return (
        <Fragment>
            <Breadcrumb title={"Notifications"} parent={"Home"} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="upcoming-event">
                                        {props.notificationsData.length > 0 ? props.notificationsData.map(el => {
                                            return (<div className="upcoming-innner media" key={el._id}>
                                                <div className=" left">
                                                <span className="mr-2" style={{fontSize:"0.6rem", color:"#4466f2"}}><i className="fa fa-circle"></i></span>
                                                </div>
                                                <div className="media-body">
                                                    <p className="mb-0"><span className="pull-right">{render_date(el.createdAt)}</span></p>
                                                    <h6 className="f-w-600" style={{color:"#4466f2"}}>{el.title}</h6>
                                                    <p className="mb-0">{el.description}</p>
                                                </div>
                                            </div>)
                                        }) : <h5 style={{textAlign:"center"}}>No notifications!</h5>}


                                    </div>
                                    {/* {props.notificationsData.length > 0 ? props.notificationsData.map(el => {
                                        return (
                                            <div className="media">
                                                <h6>{render_date(el.createdAt)}</h6>
                                                <div className="media-body"><span>{el.title}</span>
                                                    <p className="f-12">{el.description}</p>
                                                </div>
                                            </div>
                                        );

                                    }) : <p>No notifications!</p>} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </Fragment>


    );
}

const mapStateToProps = (state) => {
    return {
        notificationsData: state.header.notifications
    }
}

export default connect(mapStateToProps)(Notifications);