import React, { useEffect, useState } from "react";
import api from "../../../constant/apilist";
import client from "../../../Api/HTTPClient";
import { Table } from "reactstrap";
import moment from 'moment-timezone';
import PaginationComponent from "react-reactstrap-pagination";
import Icon from "@mdi/react";
import { mdiEye, mdiCloseThick } from '@mdi/js';
import { Modal, ModalBody } from "reactstrap";

const getTimeZone = () => {

    try {
        const company = JSON.parse(localStorage.getItem('@_company'));

        if (company && company.timeZone) {
            return company.timeZone;
        } else {
            return "Australia/Sydney";
        }
    } catch {
        return "Australia/Sydney";
    }

}

const SmsHistory = () => {

    const [smsData, setSmsData] = useState([]);
    const [pageSize, setPageSize] = useState(15);
    const [activePage, setActivePage] = useState(1);
    const [showData, setShowData] = useState([]);
    const [openModal, setOpenModal] = useState({ value: false, data: {} })

    const toggleModal = (item) => {
        setOpenModal({ value: !openModal.value, data: item })

    }

    useEffect(() => {
        if (openModal.value === true && !openModal?.delivery_stats) {
            client.post(api.fetchSmsDetails, { message_id: openModal.data?.message_id }, (error, response) => {
                if (!response.error) {
                    setOpenModal({ ...openModal, delivery_stats: response.result.data })
                }
            })
        }
    }, [openModal])

    const fetchSmsData = () => {
        try {
            client.get(api.getSmsData, {}, (error, response) => {
                if (!response.error) {
                    if (response.result) {
                        setSmsData(response.result.data);
                    }
                } else {
                    console.log('Error occured.')
                }
            })
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        fetchSmsData();
    }, [])

    useEffect(() => {
        let startIndex = (activePage - 1) * pageSize;
        let endIndex = activePage * pageSize;
        let data = smsData.slice(startIndex, endIndex);
        setShowData(data);
    }, [smsData, activePage, pageSize])

    return (
        <div className="w-100 p-4">
            <Table className='border text-left' striped>
                <thead>
                    <th className="border">#</th>
                    <th className="border">Date/Time</th>
                    <th className="border">From</th>
                    <th className="border">To</th>
                    <th className="border">Message Text</th>
                    <th className="border">Status</th>
                    <th className="border">Action</th>
                </thead>
                <tbody>
                    {
                        showData.length > 0 ? showData?.map((item, ind) => {
                            return (
                                <tr>
                                    <td className="border">{ind + 1}</td>
                                    <td className="border">{moment.tz(item.updatedAt, getTimeZone()).format('DD/MM/YYYY')}</td>
                                    <td className="border">{item.brandID == 2 ? 'Linky' : 'TwoHandsBar'}</td>
                                    <td className="border">{"+" + item.country_code + "" + item.mobile}</td>
                                    <td className="border">{item.message}</td>
                                    <td className="border">{item.status == 1 ? 'Sent' : 'Pending'}</td>
                                    <td className="border">
                                        <div onClick={() => toggleModal(item)}>
                                            <Icon
                                                path={mdiEye}
                                                className="mx-auto"
                                                size={0.7}
                                                color="success"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            )
                        }) :
                            <td> No records found</td>
                    }
                </tbody>
            </Table>
            <div>
                <div className="mt-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <select className="custom-select" style={{ width: "32%", marginRight: "7px" }} onChange={(event) => { setPageSize(event.target.value); setActivePage(1) }} value={pageSize}>
                            <option value="15">15</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="75">75</option>
                            <option value="100">100</option>
                        </select>
                        <span>Records per Page</span>
                    </div>
                    <PaginationComponent
                        totalItems={smsData.length}
                        pageSize={pageSize}
                        onSelect={(page) => setActivePage(page)} />
                </div>
            </div>

            <Modal
                isOpen={openModal.value}
                toggle={() => setOpenModal({ ...openModal, value: !openModal.value })}
                centered
                className="mx-auto"
            >
                <ModalBody className="p-0">
                    <div className="p-0 px-md-3 py-2">
                        <div className="d-flex align-items-center justify-content-between pt-1 pb-3">
                            <p className="m-0 font-weight-bold" style={{ fontSize: '18px' }}>
                                Details
                            </p>
                            <Icon
                                path={mdiCloseThick}
                                size={1.2}
                                color="grey"
                                onClick={() => setOpenModal({ ...openModal, value: !openModal.value })}
                                style={{ cursor: 'pointer' }}
                            />
                        </div>

                        <div className='border text-left p-2' style={{ borderRadius: '8px' }}>
                            <p className='mb-0' style={{ color: "gray" }}>
                                Sent at: {moment(openModal.data.createdAt).format('h:mma dddd Do MMMM YYYY')}
                            </p>
                            <p className='mb-0' style={{ color: "gray" }}>
                                To: {`+${openModal.data.country_code} - ${openModal.data.mobile}`}
                            </p>
                            <p className='mb-0' style={{ color: "gray" }}>
                                From: {openModal.data.brandID == 2 ? 'Linky' : 'TwoHandsBar'}
                            </p>
                            <p className='mb-0' style={{ color: "black", fontWeight: '400' }}>{openModal.data.message}</p>
                        </div>

                        {
                            openModal?.delivery_stats && <div className='d-flex align-items-center justify-content-center my-4' style={{ gap: '5px' }}>
                                <div className='' style={{ background: '#d5d3d4', borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px" }}>
                                    <div className='p-3 pr-xs-0 pr-sm-0 pr-md-5 d-flex flex-column align-items-start'>
                                        <p style={{ fontSize: "15px", fontWeight: 500, color: '#505154' }}>
                                            DELIVERED
                                        </p>
                                        <div>
                                            <span style={{ color: 'green', fontSize: '22px' }}>{openModal.delivery_stats.delivered}</span><span className='ml-2' stye={{ fontSize: '10px', color: '#505154' }}>{`${(openModal.delivery_stats.delivered / (openModal.delivery_stats.delivered + openModal.delivery_stats.pending + openModal.delivery_stats.bounced)) * 100}%`}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className='' style={{ background: '#d5d3d4' }}>
                                    <div className='p-3 pr-xs-0 pr-sm-0 pr-md-5 d-flex flex-column align-items-start'>
                                        <p style={{ fontSize: "15px", fontWeight: 500, color: '#505154' }}>
                                            PENDING
                                        </p>
                                        <div>
                                            <span style={{ color: '#ff8a00', fontSize: '22px' }}>{openModal.delivery_stats.pending}</span><span className='ml-2' stye={{ fontSize: '10px', color: '#505154' }}>{`${(openModal.delivery_stats.pending / (openModal.delivery_stats.delivered + openModal.delivery_stats.pending + openModal.delivery_stats.bounced)) * 100}%`}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className='' style={{ background: '#d5d3d4', borderTopRightRadius: "5px", borderBottomRightRadius: "5px" }}>
                                    <div className='p-3 pr-xs-0 pr-sm-0 pr-md-5 d-flex flex-column align-items-start'>
                                        <p style={{ fontSize: "15px", fontWeight: 500, color: '#505154' }}>
                                            BOUNCED
                                        </p>
                                        <div>
                                            <span style={{ color: 'red', fontSize: '22px' }}>{openModal.delivery_stats.bounced}</span><span className='ml-2' stye={{ fontSize: '10px', color: '#505154' }}>{`${(openModal.delivery_stats.bounced / (openModal.delivery_stats.delivered + openModal.delivery_stats.pending + openModal.delivery_stats.bounced)) * 100}%`}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )

}

export default SmsHistory