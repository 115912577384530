import React from "react";
import "./ToggleSwitch.scss";

const ToggleSwitch = ({ label, onChange, value, checked }) => {
    // console.log(value, "value");
    return (
        <div className="px-0 toggle_style">
            {/* {label}{" "} */}
            <div className="toggle-switch">
                <input
                    type="checkbox"
                    className="checkbox"
                    name={label}
                    id={label}
                    onChange={onChange}
                    value={value}
                    checked={checked}
                />
                <label className="label" htmlFor={label}>
                    <span className="inner" />
                    <span className="switch" />
                </label>
            </div>
        </div>
    );
};

export default ToggleSwitch;