import React, { Component, useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormGroup, Col, Row, Input, CustomInput } from 'reactstrap';
import useForm from "react-hook-form";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";

let AddcatCuisineModal = (props) => {

    let [loadingbtn, setloadingbtn] = useState(false);
    let [inputList, setInputList] = useState([{ cuisine_name: "" }]);
    let [inputCat, setInputCat] = useState('');
    let [editItem, seteditItem] = useState(null);

    const handleResetClick = () => {
        setInputCat('');
        setInputList([{ cuisine_name: "" }]);
    };

    const handleAddClick = () => {
        setInputList([...inputList, { cuisine_name: ""}]);
    };

    const handleCatInputChange = (e) => {
        const { value } = e.target;
        setInputCat(value);
    }

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    const { handleSubmit, register, errors } = useForm();

    const onSubmit = form_data => {

        if (form_data !== '') {

            setloadingbtn(true);

            let data = { cuisine_name: inputList };

            client.post(api.add_cuisines, data, async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                handleResetClick();
                                toast.success("Added Successfully");
                                props.onClose(true);

                            } else {
                                toast.error(response.message);
                            }
                        } catch (e) {
                            toast.error("Internal error occured. Please contact support 2");
                        }
                    } else {
                        toast.error("Internal error occured. Please contact support 2");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support 3");
                }
                setloadingbtn(false);
            });
        }
        else {
            errors.showMessages();
        }
    }
    return (

        <Modal isOpen={props.isOpen} toggle={props.onClose} size="md" className="modal-body" centered={true}>
            <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={props.onClose}><p className="m-0 font-weight-bold">Add Cuisines</p></ModalHeader>
                <ModalBody>
                    <Row form>
                        <FormGroup className="w-100">
                            <Label for="level_title">Cuisines</Label>
                            {inputList.map((x, i) => {

                                return (
                                    <Row form>
                                        <Col md={11}>
                                            <input
                                                name="cuisine_name" className="form-control mb-2" type="text"
                                                ref={register({ required: true })}
                                                value={x.cuisine_name}
                                                onChange={e => handleInputChange(e, i)}
                                            />
                                            <span>{errors.cuisine_name && 'cuisine_name is required'}</span>
                                        </Col>

                                        <Col md={1}>
                                            {inputList.length !== 1 && <button
                                                className="btn btn-outline-danger mb-2 p-2"
                                                onClick={() => handleRemoveClick(i)}><i id="Remove" title="Remove cuisine_name" className="fa fa-minus"></i></button>}
                                            {inputList.length - 1 === i && <button className="btn btn-outline-success p-2" onClick={handleAddClick}><i id="Add New" title="Add New" className="fa fa-plus"></i></button>}
                                        </Col>
                                    </Row>
                                )
                            })}
                        </FormGroup>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {!loadingbtn ?
                        <>
                            <Button color="default" type="button" onClick={handleResetClick}>Reset</Button>
                            <Button color="primary" type="submit" onClick={() => props.onClose(false)}>Save</Button>
                        </>
                        :
                        <div className="loader-box pull-right" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 20, width: 20 }}></span></div>
                    }
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default AddcatCuisineModal;