import React, { useEffect, useState, Fragment} from "react";
import { toast } from "react-toastify";
import api from "../../constant/apilist";
import client from "../../Api/HTTPClient";
import {DisappearedLoading} from "react-loadingg";
import PaginationComponent from "react-reactstrap-pagination";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import useForm from 'react-hook-form';

import catsubcat from "../../CatSubcat";

const businessCategory = catsubcat.map(el => {
    return el.categoryTitle
});



const UpdateRequests = () => {

    const [data, setData] = useState([])
    const [activePage, setactivePage] = useState(1)
    const [pageSize, setpageSize] = useState(25)
    const [showModal, setshowModal] = useState(false);
    const [activeitem, setActiveItem] = useState(null);
    const { handleSubmit, register, errors } = useForm();


    const approveRequest = (data) => {
        if (data != '') {
            alert('You submitted the form and stuff!');
            // props.history.push('/ecommerce/invoice');
        } else {
            errors.showMessages();
        }
    }

    useEffect(() => {
        // alert("hrer");
        getAllData()
    }, []);

    const getAllData = () => {
        let postdata = {};
        client.post(api.merchant_update_request, postdata, (error, response) => {
            
            if (!error) {
                try {
                    if (!response.error) {
                        setData(response.result.data);

                    } else {
                        setTimeout(() => {
                            toast.error(response.message);
                        }, 200);
                    }
                } catch (e) {
                    setTimeout(() => {
                        toast.error("Internal error occured. Please contact support" + e.message);
                    }, 200);
                }

            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
            //   current.setState({ loading: false });
        });
    };
    //console.log(customersData);

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-xl-12 xl-100" style={{ padding: "0" }}>
                        <div className="card">
                            <div className="card-body" style={{ paddingTop: "0rem", paddingLeft: "1rem", paddingRight: "1rem", paddingBottom: "1rem" }}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <div >
                                        {/* <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                                            <div >
                                                <input style={{
                                                    border: "none",
                                                    borderBottom: "1px solid rgb(197, 189, 189)",
                                                    borderRadius: "0",
                                                    margin: "1px 10px",
                                                    fontSize: "0.75rem",

                                                }}
                                                    type="text"
                                                    placeholder="Search"
                                                    onChange={this.onSearchChangeHandler} />
                                            </div>
                                        </form> */}
                                    </div>
                                    {(activePage) * pageSize > data.length ?
                                        <p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>Showing {((activePage - 1) * pageSize) + 1} to {data.length} of {data.length} entries</p> :
                                        <p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>Showing {((activePage - 1) * pageSize) + 1} to {(activePage) * pageSize} of {data.length} entries</p>}
                                </div>


                                <div className="table-responsive">
                                    <table className="table table-border-horizontal table-striped table-striped table-light">
                                        <thead>
                                            <tr >
                                                <th scope="col" >#</th>
                                                <th scope="col" >Request Date</th>
                                                <th scope="col" >Merchant</th>
                                                <th scope="col" >Email/Phone</th>
                                                <th scope="col" >Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.slice((activePage - 1) * pageSize, (activePage) * pageSize).map((item, index) => {
                                                return (
                                                    <tr key={item._id}>
                                                        <td >#</td>
                                                        <td >{item.merchant?.request_date}</td>ß
                                                        <td >{item.merchant?.business_name}</td>
                                                        <td >Email : {item.merchant?.email}<br />Mobile : {item.merchant?.mobile}</td>
                                                        <td >
                                                            {/* <div onClick={() => setshowModal(true)}>View</div> */}
                                                            <i className="fa fa-paper-plane" style={{ color: '#4466f2' }} onClick={() => {
                                                                setActiveItem(item);
                                                                setshowModal(true);
                                                            }} ></i>
                                                        </td>
                                                    </tr>
                                                );

                                            })}

                                        </tbody>
                                    </table>
                                    <div className="mt-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <select className="custom-select" style={{ width: "32%", marginRight: "7px" }}
                                                // onChange={this.setPageSize}
                                                value={pageSize}>
                                                <option value="15">15</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="75">75</option>
                                                <option value="100">100</option>
                                            </select>
                                            <span>Records per Page</span>
                                        </div>
                                        <PaginationComponent
                                            totalItems={data.length}
                                            pageSize={pageSize}
                                        // onSelect={this.handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {activeitem &&
                <Modal size="lg" isOpen={showModal} toggle={() => setshowModal(false)} className="modal-body" centered={true}>
                    <ModalBody>
                        <form className="needs-validation" onSubmit={handleSubmit(approveRequest)} >
                        
                            <div className="row" style={{ padding: 20 }}>
                                <div className="col-md-12" style={{ padding: "0" }}>

                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group col-l-6 ">
                                                <label>Current</label>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group col-l-6">
                                                <label>Updated</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12" style={{ padding: "0" }}>
                                    <label>Business name</label>

                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group col-l-6 ">
                                                <input className="form-control" type="text" value={activeitem.merchant?.business_name} readOnly />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group col-l-6">
                                                <input ref={register({ required: true })} className="form-control" type="text" placeholder="Business name" name="business_name" autoComplete="off" defaultValue={activeitem.business_name} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12" style={{ padding: "0" }}>
                                    <label>ABN</label>

                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group col-l-6 ">
                                                <input className="form-control" type="text" value={activeitem.merchant?.abn} readOnly />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group col-l-6">
                                                <input ref={register({ required: true })} className="form-control" type="text" placeholder="ABN" name="abn_number"
                                                    defaultValue={activeitem.abn}
                                                    autoComplete="off" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12" style={{ padding: "0" }}>
                                    <label>ACN :</label>

                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group col-l-6 ">
                                                <input className="form-control" type="text" value={activeitem.merchant?.acn} readOnly />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group col-l-6">
                                                <input ref={register({ required: true })} className="form-control" type="text" placeholder="ACN" name="acn_number"
                                                    defaultValue={activeitem.acn} autoComplete="off" />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-12" style={{ padding: "0" }}>
                                    <label>Landline</label>

                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group col-l-6 ">
                                                <input className="form-control" type="text" value={activeitem.merchant?.landline} readOnly />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group col-l-6">
                                                <input ref={register({ required: true })} className="form-control" type="text"
                                                    defaultValue={activeitem.landline}
                                                    placeholder="Landline" name="landline" autoComplete="off" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12" style={{ padding: "0" }}>
                                    <label>Business Category</label>

                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group col-l-6 ">
                                                <input className="form-control" type="text" value={activeitem.merchant?.business_category} readOnly />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group col-l-6">
                                                <select ref={register({ required: true })} defaultValue={activeitem.business_category} className="form-control" name="business_category"
                                                    // onChange={this.onInputChangeHandler}
                                                    defaultValue="Automobiles and Vehicles">
                                                    {businessCategory.map(el => {
                                                        return <option key={el} value={el}>{el}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12" style={{ padding: "0" }}>
                                    <label>Business sub-category</label>

                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group col-l-6 ">
                                                <input className="form-control" type="text" value={activeitem.merchant?.business_sub_category} readOnly />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group col-l-6">
                                                <select ref={register({ required: true })} defaultValue={activeitem.business_sub_category} className="form-control" name="business_sub_category"
                                                // onChange={this.onInputChangeHandler}
                                                >
                                                    {catsubcat.find(cat => {
                                                        return cat.categoryTitle == activeitem.business_category
                                                    }).subCategory.map((subcat, i) => {
                                                        return <option key={subcat.subcatTitle + i}>{subcat.subcatTitle}</option>
                                                    })}
                                                    {/* {subcategory.map(el => {
                                                return <option key={el} >{el}</option>
                                            })} */}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-12" style={{ padding: "0" }}>
                                    <label>Website</label>

                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group col-l-6 ">
                                                <input className="form-control" type="text" value={activeitem.merchant?.website} readOnly />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group col-l-6">
                                                <input ref={register({ required: true })}  className="form-control" type="text"
                                                    defaultValue={activeitem.website}
                                                    placeholder="Website" name="website" autoComplete="off" />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-12" style={{ padding: "0" }}>
                                    <label>Address</label>

                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group col-l-6 ">
                                                <input className="form-control" type="text" value={activeitem.merchant?.address} readOnly />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group col-l-6">
                                                <input ref={register({ required: true })}  className="form-control" type="text" defaultValue={activeitem.address} placeholder="Address" name="address" autoComplete="off" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12" style={{ padding: "0" }}>
                                    <label>Postcode</label>

                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group col-l-6 ">
                                                <input className="form-control" type="text" value={activeitem.merchant?.postcode} readOnly />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group col-l-6">
                                                <input ref={register({ required: true })} className="form-control" type="text" defaultValue={activeitem.postcode} placeholder="Postcode" name="postcode" autoComplete="off" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12" style={{ padding: "0" }}>
                                    <label>Suburb</label>

                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group col-l-6 ">
                                                <input className="form-control" type="text" value={activeitem.merchant?.suburb} readOnly />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group col-l-6">
                                                <input ref={register({ required: true })} defaultValue={activeitem.suburb} className="form-control" type="text" placeholder="Suburb" name="suburb" autoComplete="off" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="col-md-12" style={{ padding: "0" }}>
                                <label>State</label>

                                <div className="row">
                                    <div className="col">
                                        <div className="form-group col-l-6 ">
                                            <input className="form-control" type="text" value={activeitem.merchant?.state} readOnly />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group col-l-6">
                                            <input className="form-control" type="text" defaultValue={activeitem.state} placeholder="State" name="state" autoComplete="off" />
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                                <div className="col-md-6" style={{ padding: "0" }}>
                                    <div>

                                    </div>
                                </div>
                            </div>
                        
                            <Button color="warning" style={{ marginTop: "20px", marginRight: "20px" }} onClick={() => setshowModal(false)}>Cancel</Button>
                            <Button color="primary" style={{ marginTop: "20px", marginRight: "20px" }} name="block">Approve</Button>
                        </form>
                    </ModalBody>
                </Modal>
            }
        </Fragment>
  
    );
}

export default UpdateRequests;