
import {
    CHANGEPAGENUMBER,
} from '../actions/phoneViewPageActions';


const intialState = {
    currentPage: 0
}

const phoneViewPageReducer = (state = intialState, action) => {
    switch (action.type) {

        case CHANGEPAGENUMBER: {
            return {
                currentPage: action.data
            };
        }

        default: {
            return {
                ...state
            };
        }
    }
}
export default phoneViewPageReducer;