import React, { Component, Fragment } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup, Col, Row, FormFeedback, CustomInput } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";

import AddGiftVouchersModal from './AddGiftVouchersModal';
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import moment from "moment-timezone";
import EditGiftVouchersModal from "./EditGiftVouchersModal";
import swal from 'sweetalert';

class GiftVouchers extends Component {

    state = {
        merchant_id: this.props.merchant_id,
        offer_level: this.props.offer_level,
        addOfferModal: false,
        giftoffers: [],
        activePage: 1,
        pageSize: 50,
        isOpen: false,
        imageSrc: '',
        deleteModal: false,
        editModal: false,
        selectedOffer: null,
        selectedOfferMerchant: null,
        access_level: localStorage.getItem("accessLevel"),
        adminUser: JSON.parse(localStorage.getItem("user"))
    }

    toggleAddOfferModal = () => {
        this.setState({ addOfferModal: !this.state.addOfferModal });
    }

    componentWillMount() {
        this.fetch_giftvouchers();
    }

    fetch_giftvouchers = () => {

        let current = this;

        client.post(api.fetch_giftvouchers, {}, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            // console.log(response.result.data);
                            current.setState({ giftoffers: response.result.data.reverse() });
                        } else {
                            // toast.error(response.message);
                        }
                    } catch (e) {
                        // toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    // toast.error("Internal error occured. Please contact support");
                }
            } else {
                // toast.error("Internal error occured. Please contact support");
            }
        });
    }

    toggleEditModal = (item, flag) => {
        this.setState({ editModal: flag, selectedOffer: item })
    }

    deleteClick = async (item) => {

        let willDelete = await swal({
            title: "Are you sure?",
            text: "Gift Voucher will be deleted permanently",
            icon: "warning",
            buttons: {
                cancel: true,
                confirm: {
                    text: "Yes, Please!",
                    closeModal: false,
                },
            },
            dangerMode: true,
        })
      
        if (willDelete) {
            let postdata = { offer_id: item._id };
            let current = this;
            let message = "";
            let status = 0;
            current.setState({ loading: true });
            client.post(api.delete_gift_vouchers, postdata, async function (error, response) {
                if (!error) {
                    if (!response.error) {
                        status = 1;
                        message = "Deleted succefully"
                    } else {
                        status = 0;
                        message = response.message
                    }
                }
      
                swal.close();
                swal(message);
                if (status == 1)
                    current.fetch_giftvouchers();
            });
        }
    }

    render() {

        let { giftoffers, merchants, access_level, selectedOffer, editModal, selectedOfferMerchant } = this.state;

        return (
            <Fragment>
                <div className="container-fluid">
                    {/* {selectedOffer && this.renderDeleteModal()} */}
                    {editModal && <EditGiftVouchersModal selectedOffer={selectedOffer} isOpen={editModal} fetch_giftvouchers={this.fetch_giftvouchers} toggleModal={this.toggleEditModal} />}
                    <div className="row">
                        <div className="col-sm-12 col-xl-12">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body" style={{ padding: "1rem" }}>
                                            <div className="btn-group pull-right" role="group" aria-label="Basic example" style={{ marginBottom: "10px" }}>
                                                {<button onClick={() => this.toggleAddOfferModal()} className="btn btn-light btn-xs" ><i className="fa fa-plus-square-o" style={{ margin: "0px 7px 0 0" }}></i> Add New </button>}
                                            </div>

                                            <div className="table-responsive" >
                                                <table className="table table-border-horizontal" >
                                                    <thead>
                                                        <tr style={{background: '#f9fafe'}}>
                                                            <th scope="col" style={{width:"5%"}}>#</th>
                                                            <th scope="col" style={{width:"20%"}}>Offer Title</th>
                                                            <th scope="col" style={{width:"10%"}}>Redeemable At</th>
                                                            <th scope="col" style={{width:"10%"}}>Cashback</th>
                                                            {/* <th scope="col" style={{width:"35%"}}>Offer Description</th> */}
                                                            <th scope="col" style={{width:"10%"}}>Added ON</th>
                                                            <th scope="col" style={{width:"10%"}}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {giftoffers && giftoffers.length > 0 ? giftoffers.map((item, index) => {
                                                            return (
                                                                <tr key={item._id}>
                                                                    <th scope="row">{index + 1}</th>
                                                                    <td style={{width:"11%"}}>{item.offer_title}</td>
                                                                    <td style={{width:"11%"}}>{item.redeem_at_point} Points</td>
                                                                    <td style={{width:"11%"}}>${item.cashback}</td>
                                                                    {/* <td style={{width:"11%"}}>{item.offer_description}</td> */}
                                                                    <td style={{width:"11%"}}>{moment.tz(item.added_date, "Australia/Sydney").format("DD/MM/YYYY hh:mm A")}</td>
                                                                    {access_level != 1 &&<td>
                                                                        {access_level == 3 && <span style={{ marginRight: "10px", fontSize: "0.75rem" }} onClick={() => this.deleteClick(item)}>
                                                                            <i style={{ color: "red" }} id="delete" className="fa fa-trash-o"></i>
                                                                        </span>}
                                                                        <span style={{ marginRight: "10px", fontSize: "0.75rem", color: "black" }} onClick={() => this.toggleEditModal(item, true)}>
                                                                            <i className="fa fa-edit"></i>
                                                                        </span>
                                                                    </td>}
                                                                </tr>
                                                            );
                                                        })
                                                            : null}
                                                    </tbody>
                                                </table>
                                                {giftoffers && giftoffers.length > 0 ? null :
                                                    <h6 style={{ textAlign: "center" }}> No records found! </h6>}
                                                {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                            <select className="custom-select" style={{ width: "35%", fontSize: "0.63rem", marginRight: "10px" }} onChange={this.setPageSize} value={this.state.pageSize}>
                                                                <option value="15">15</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="75">75</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                            <span style={{ fontSize: "0.63rem" }}>Records per Page</span>
                                                        </div>
                                                        <PaginationComponent
                                                            totalItems={showVisitors.length}
                                                            pageSize={this.state.pageSize}
                                                            onSelect={this.handlePageChange} />
                                                    </div> */}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AddGiftVouchersModal isOpen={this.state.addOfferModal} fetch_giftvouchers={this.fetch_giftvouchers} toggleModal={() => this.toggleAddOfferModal()} />
                
            </Fragment>
        );
    }
}

export default GiftVouchers;