import React, { Component, useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormGroup, Col, Row, Input, CustomInput } from 'reactstrap';
import useForm  from "react-hook-form";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import {  toast } from "react-toastify";
import 'react-bootstrap-typeahead/css/Typeahead.css';

let AddOfferCatModal = (props) => {
    
    let [loadingbtn, setloadingbtn] = useState(false);

    const { handleSubmit, register, errors } = useForm();

    const onSubmit = form_data => {
        
        if (form_data !== '') {

            form_data.filter_range = 0;
            form_data.added_by = 0;

            setloadingbtn(true);

            let api_url = api.add_filter_category;
            if (props.editcat) {
                form_data.id = props.selectedOfferCat._id;
                api_url = api.edit_filter_category;
            }

            client.post(api_url, form_data, async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                props.fetc_offers();
                                props.toggleModal();
                                toast.success("Added Successfully");
                                // setcashbackdata(response.result.data);
                            } else {
                                toast.error(response.message);
                            }
                        } catch (e) {
                            toast.error("Internal error occured. Please contact support");
                        }
                    } else {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
                
                setloadingbtn(false);
            });
        }
        else {
            errors.showMessages();
        }
    }

    return (

        <Modal isOpen={props.isOpen} toggle={props.toggleModal} className="modal-body" centered={true}>
            <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={props.toggleModal}>{props.editcat ? `Edit Voucher Category` :`Add Voucher Category`}</ModalHeader>
                <ModalBody>
                    <ModalBody style={{ padding: "16px 0" }}>
                        <Row form>
                            <Col md={12}>
                                <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="level_title">Title
                                            </Label>
                                            <input className="form-control" type="text" name="filter_title" defaultValue={props.editcat ? props.selectedOfferCat.filter_title : ""}  ref={register({ required: true })} />
                                            <span>{errors.offer_title && 'Title is required'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {!loadingbtn ?
                            <>
                                <Button color="default" type="button" onClick={props.toggleModal}>Cancel</Button>
                                <Button color="primary" type="submit" >Save</Button>
                            </>
                            :
                            <div className="loader-box pull-right" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 20, width: 20 }}></span></div>
                        }
                    </ModalFooter>
                </ModalBody>
            </form>
        </Modal>
    );
}

export default AddOfferCatModal;