import React, { Fragment, useEffect, useState } from 'react'
import client from "../../Api/HTTPClient";
import "./css/profile.css"
import api from "../../constant/apilist";
import no_image from "../../assets/images/No-Image.png";
import { ToastContainer, toast } from "react-toastify";
import { Nav, NavItem, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip } from "reactstrap";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from "moment-timezone";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link as NavLink } from "react-router-dom";
import MerchantInvoiceDetails from "./MerchantInvoiceDetails";
import PaginationComponent from "react-reactstrap-pagination";

export const MerchantInvoices = (props) => {

    const { merchant_info, invoices, merchant_id } = props;

    const [invoiceDetails, setInvoiceDetails] = useState(false);
    const [invoiceData, setInvoiceData] = useState(null);
    const [activePage, setActivePage] = useState(1);
    const [pageSize, setPageSize] = useState(15);

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)

    }

    return (
        <Fragment>
            <div className="container-fluid">
                {!invoiceDetails ?
                    <>
                        {invoices && invoices.length > 0 ?
                            <div className="table-responsive pt-4">
                                <table className="table table-border-horizontal table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th scope="col">Invoice No</th>
                                            <th scope="col">Invoice Date</th>
                                            <th scope="col">Company</th>
                                            <th scope="col">Store</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            invoices.length != 0 && invoices.map((v, i) => invoices[invoices.length - (i + 1)]).slice((activePage - 1) * pageSize, (activePage) * pageSize).map((item, index) => (
                                                <tr key={item?._id}>
                                                    <td scope='row'>{((activePage - 1) * pageSize) + index + 1}</td>
                                                    <td scope=" row">{item?.invoice_number}</td>
                                                    <td scope="row">{moment(item?.createdAt).format('DD-MM-YYYY/H:mm')}</td>
                                                    <td scope=" row">{item?.company_name}</td>
                                                    <td scope=" row">{item?.invoice_title.includes("White label Enterprise plan") ? "-" : item.store_name}</td>
                                                    <td scope=" row">{item?.invoice_title}</td>
                                                    {/* <td scope=" row">AUD {item.total}</td> */}
                                                    <td scope=" row">{item?.fk_merchant_id?.country.currency_code} {item.total}</td>
                                                    <td scope=" row">{item?.invoice_title.includes("Trial Plan") ? '-' : item?.status == 1 ? (<span className='text-success'>Paid</span>) : (<span className='text-danger'>Pending</span>)}</td>
                                                    <td onClick={() => {
                                                        setInvoiceDetails(true)
                                                        setInvoiceData(item)
                                                    }}><VisibilityIcon style={{ fontSize: 15, margin: '8px auto', display: 'block', color: '#327ebc', cursor: 'pointer' }} /></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <div className='mt-3'
                                    style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>

                                    <PaginationComponent
                                        totalItems={invoices.length}
                                        pageSize={pageSize}
                                        onSelect={handlePageChange} />
                                </div>
                            </div>
                            :
                            <div className="row">
                                <h6 style={{ textAlign: "center" }}> No records found! </h6>
                            </div>
                        }
                    </> :
                    <MerchantInvoiceDetails setInvoiceDetails={setInvoiceDetails} invoiceData={invoiceData} />
                }
            </div>
        </Fragment >
    )
}
