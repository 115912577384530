import React, { Fragment, useEffect, useState } from 'react'
import client from "../../Api/HTTPClient";
import "./css/profile.css"
import api from "../../constant/apilist";
import no_image from "../../assets/images/No-Image.png";
import { ToastContainer, toast } from "react-toastify";
import { Nav, NavItem, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, ButtonToolbar, Row, Col, Table } from "reactstrap";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from "moment-timezone";
import MerchantInvoiceDetails from "./MerchantInvoiceDetails";
import ShowSubscriptionSelectScreen from "./ShowSubscriptionSelectScreen";
import { Link, useHistory } from 'react-router-dom';

export const MerchantSubscriptions = (props) => {

    const { merchant_info, subscriptions, merchant_id, invoices, wlmSubscription, userCompanyList, userStorelist } = props;

    const [invoiceDetails, setInvoiceDetails] = useState(false);
    const [invoiceData, setInvoiceData] = useState(null);
    const [showallSubscriptions, setShowallSubscriptions] = useState({
        show: false,
        merchant_id : null
    });
    const [whitelLabelInvoive, setWhilteLabelInvoice] = useState(wlmSubscription);


    const showInvoiceFunc = (data) => {

        if (data.type == 1) {
            let invoice_id = data.selectedInvoiceData.subscription.invoice_id._id;
            let invoiceData = invoices?.find(item => item?._id == invoice_id);
            setInvoiceData(invoiceData)
            setInvoiceDetails(true)
        } else if (data.type == 2) {
            let invoice_id = data.selectedInvoiceData.fk_invoice_id;
            let invoiceData = invoices?.find(item => item?._id == invoice_id);
            setInvoiceData(invoiceData)
            setInvoiceDetails(true)
        }
    }

    const get_subscription = (item) => {
        if (item.subscription?.subscription_id) {
            return item.subscription.subscription_id;
        } else {
            return null
        }
    }

    const get_invoice = (item) => {
        if (item.subscription?.invoice_id) {
            return item.subscription.invoice_id;
        } else {
            return null
        }
    }

    const renderCompany = (item_c, item_c_Index) => {

        let merchant_data = "";

        merchant_data = userStorelist && userStorelist.map(item => {

            let subscription = get_subscription(item);
            let invoice = get_invoice(item);

            console.log(item, "item")
            return (
                item.company_id == item_c._id ?
                    (
                        <tr key={item._id} className="p-1 py-0" >
                            <td className='border '>{item.business_name}</td>
                            <td className='border text-uppercase '>{subscription ? subscription.subscription.title : "-"}</td>
                            <td className='border text-uppercase '>{subscription?.period?.title}</td>
                            <td className='border' >{subscription ? moment(subscription.end_date).format('DD-MM-YYYY') : "-"}</td>
                            <td className='border text-center' >
                                <button
                                    onClick={() => {
                                        let a = {
                                            show: true,
                                            merchant_id : item._id 
                                        };
                                        setShowallSubscriptions(a)
                                    }}
                                    style={{ textAlign: 'center', margin: '8px auto', display: 'block' }}
                                    class="btn btn-primary btn-sm" type="button">Change plan
                                </button>
                                <button
                                    onClick={() => {
                                        let invoiceData = { type: 1, selectedInvoiceData: item };
                                        showInvoiceFunc(invoiceData)
                                    }}
                                    style={{ textAlign: 'center', margin: '8px auto', display: 'block' }}
                                    class="btn btn-primary btn-sm" type="button">View invoice
                                </button>
                            </td>
                        </tr>
                    ) : null
            )
        })

        merchant_data = merchant_data.filter(function (el) { return el != null; });

        if (merchant_data && merchant_data.length > 0) {

            return (

                <Row key={item_c._id} className={`${item_c_Index == 0 ? 'mt-3' : ''} mb-4`}>

                    <Col md={12} className='p-0 mb-1'>
                        <p style={{ fontSize: '15px', fontWeight: "bold", marginBottom: "5px" }} className='pl-2 text-uppercase'>
                            {item_c.companyName}
                        </p>
                    </Col>

                    <Col md={12} className='p-2'>
                        <div>
                            <Table striped responsive borderless>
                                <thead>
                                    <tr className="p-1 m-0">
                                        <th style={{ width: "30%", backgroundColor: "#777b87" }} className=' text-white m-0 border '>Store name</th>
                                        <th style={{ width: "20%", backgroundColor: "#777b87" }} className=' text-white m-0 border '>Current plans</th>
                                        <th style={{ width: "18%", backgroundColor: "#777b87" }} className=' text-white m-0 border '>Billing cycle</th>
                                        {/* <th style={{ width: "13%", backgroundColor: "#777b87" }} className=' text-white m-0 border '> Expire On </th> */}
                                        <th style={{ width: "18%", backgroundColor: "#777b87" }} className=' text-white m-0 border '>Next invoice date</th>
                                        <th style={{ width: "14%", backgroundColor: "#777b87" }} className=' text-white m-0 border '>Action</th>
                                    </tr>
                                </thead>
                                <tbody className="border-top">
                                    {merchant_data}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            )
        } else {
            return null;
        }
    }


    return (

        <Fragment>
            {showallSubscriptions.show == true ?
                <ShowSubscriptionSelectScreen
                    merchantId={showallSubscriptions.merchant_id}
                    setShowallSubscriptions={(a) => {
                        // let a = {
                        //     show: true,
                        //     merchant_id : item._id 
                        // };
                        setShowallSubscriptions(a)
                    }}
                
                />
                :
                <>
            
                    {!invoiceDetails ?
                        <div className="container-fluid">
                            {/* {subscriptions ?
                        <div className="table-responsive pt-4">
                            <table className="table table-border-horizontal table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Store Name</th>
                                        <th scope="col">Current Plans</th>
                                        <th scope="col">Billing Cycle</th>
                                        <th scope="col">Next Invoice date</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {subscriptions &&
                                        <tr key={merchant_id}>
                                            <td scope=" row">{merchant_info.business_name}</td>
                                            <td scope="row">{subscriptionss ? subscriptionss.subscription.title : "-"}</td>
                                            <td scope=" row">{subscriptionss ? subscriptionss.period.title : "-"}</td>                                            
                                            <td scope=" row">{moment(subscriptionss.end_date).format("DD-MM-YYYY")}</td>
                                            <td scope=" row">
                                                <button
                                                    onClick={() => { showInvoiceFunc(1) }}
                                                    style={{ textAlign: 'center', margin: '8px auto', display: 'block' }}
                                                    class="btn btn-primary btn-sm" type="button">View invoice
                                                </button>
                                            </td>
                                        </tr>
                                    }

                                    {whitelLabelInvoive &&
                                        <tr key={whitelLabelInvoive._id}>
                                            <td scope=" row">{whitelLabelInvoive.store_name}</td>
                                            <td scope="row">{whitelLabelInvoive.subscription.title}</td>
                                            <td scope=" row">{whitelLabelInvoive.period.title}</td>
                                            <td scope=" row">{moment(whitelLabelInvoive.end_date).format("DD-MM-YYYY")}</td>
                                            <td scope=" row">
                                                <button
                                                    onClick={() => { showInvoiceFunc(2) }}
                                                    style={{ textAlign: 'center', margin: '8px auto', display: 'block' }}
                                                    class="btn btn-primary btn-sm" type="button">View invoice
                                                </button>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        :
                        <div className="row">
                            <h6 style={{ textAlign: "center" }}> No records found! </h6>
                        </div>
                    } */}
                            {
                                userCompanyList.length > 0 && userCompanyList.map((item_c, item_c_Index) =>
                                    renderCompany(item_c, item_c_Index)
                                )
                            }

                            {whitelLabelInvoive && <Row className='mb-4'>

                                <Col md={12} className='p-0 mb-1'>
                                    <p style={{ fontSize: '15px', fontWeight: "bold", marginBottom: "5px" }} className='pl-2 text-uppercase'>
                                        White label merchant
                                    </p>
                                </Col>

                                <Col md={12} className='p-2'>
                                    <div>
                                        <Table striped responsive>

                                            <thead>
                                                <tr className="p-1 m-0">
                                                    <th style={{ width: "30%", backgroundColor: "#777b87" }} className=' text-white m-0 border '>Store name</th>
                                                    <th style={{ width: "20%", backgroundColor: "#777b87" }} className=' text-white m-0 border '>Current plans</th>
                                                    <th style={{ width: "18%", backgroundColor: "#777b87" }} className=' text-white m-0 border '>Billing cycle</th>
                                                    {/* <th style={{ width: "13%", backgroundColor: "#777b87" }} className=' text-white m-0 border '> Expire On </th> */}
                                                    <th style={{ width: "18%", backgroundColor: "#777b87" }} className=' text-white m-0 border '>Next invoice date</th>
                                                    <th style={{ width: "14%", backgroundColor: "#777b87" }} className=' text-white m-0 border '>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="border-top">

                                                <tr key={1} className="p-1 py-0" >
                                                    <td className='border '>{whitelLabelInvoive.store_name}</td>
                                                    <td className='border text-uppercase '>{whitelLabelInvoive.subscription.title}</td>
                                                    <td className='border text-uppercase '>{whitelLabelInvoive.period.title}</td>
                                                    <td className='border' >{moment(whitelLabelInvoive.end_date).format("DD-MM-YYYY")}</td>
                                                    <td className='border text-center' >
                                                        <button
                                                            onClick={() => {
                                                                let invoiceData = { type: 2, selectedInvoiceData: whitelLabelInvoive };
                                                                showInvoiceFunc(invoiceData);
                                                            }}
                                                            style={{ textAlign: 'center', margin: '8px auto', display: 'block' }}
                                                            class="btn btn-primary btn-sm" type="button">View invoice
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>}

                        </div>
                        :
                        <MerchantInvoiceDetails setInvoiceDetails={setInvoiceDetails} invoiceData={invoiceData} />
                    }
                </>
            }
            
        </Fragment>
    )
}
