import React,{useState,useEffect} from "react";
import Icon from '@mdi/react';
import { mdiMagnify, mdiReply, mdiArrowLeft, mdiChevronDown, mdiInformationOutline} from '@mdi/js';
import moment from 'moment';
import { groupBy } from "lodash";
import { Row, Col, Input, Button, Table, Spinner } from 'reactstrap';
import { Popover,PopoverBody } from "reactstrap";
export const posorder_merchant_total = (item) => {
  let totalAmount = 0;
  totalAmount = +parseFloat(parseFloat(item.final_total) - parseFloat(item.total_charges)).toFixed(2)
  if (item.entryType == 2 && item.taxCalculateType && item.taxCalculateType == 2) {
    totalAmount = +totalAmount + item.total_charges
  }
  return parseFloat(totalAmount).toFixed(2);
}



const showPaymentStatus = (order) => {
  if (order) {
      if (order.payment_method == "ONLINE" || order.payment_method?.toUpperCase() == "IPAYPRO") {
          return <span style={{ color: "green",fontSize:'16px' }}>Paid</span>
      } else if (order.order_status <= 3 && order.payment_method == "Paid") {
          return <span style={{ color: "green",fontSize:'16px' }}>Paid</span>
      }
      else if (order.order_status < 3) {
          return <span style={{ color: "red", fontSize:'16px' }}>Collect Cash</span>
      } else if (order.order_status == 3) {
          return <span style={{ color: "green",fontSize:'16px' }}>Paid</span>
      } else {
          return "Unpaid"
      }
  }
}

const OrderDetails = (props)=>{
  const {item,merchant,setViewOrder} = props
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [popoverOpenEarning, setPopoverOpenEarning] = React.useState(false);
  const [takeaway_label, settakeaway_label] = useState(null);
  const [dinein_label, setdinein_label] = useState(null);
  console.log(item,"items")
  const roundUp = (num, precision) => {
    precision = Math.pow(10, precision)
    return Math.ceil(num * precision) / precision
}
const togglePopover = () => {
  setPopoverOpen(
      !popoverOpen
  );
}
const togglePopoverEarning = () => {
  setPopoverOpenEarning(
      !popoverOpenEarning
  );
}
  const totalPaymentFees = () => {
    let totalFeex = 0;
    item.paymentHistory.map(item => {
        totalFeex = totalFeex + parseFloat(item.paymentMode.paymentFeeAmt)
    })
    return parseFloat(totalFeex).toFixed(2);
  }
  const ShowCurrencyNum = (amount, showSymbol) => {
    const currency_symbol = merchant?.country?.currency_symbol;
    if (amount == undefined) {
      return currency_symbol;
    }
  
    let getCurrency = !isNaN(parseFloat(amount)) ? amount : 0;
  
    if (merchant && merchant.country) {
      if (showSymbol && currency_symbol) {
        if (getCurrency < 0) {
          getCurrency = `-${currency_symbol}${Math.abs(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
        } else {
          getCurrency = `${currency_symbol}${parseFloat(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
        }
      }
      else {
        getCurrency = +`${parseFloat(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
      }
    }
  
    return getCurrency;
  }
  useEffect(() => {

    let menu_types = merchant.menu_types;

    if (menu_types && menu_types.length > 0) {

        let takeaway_menu = menu_types.find(item => item.code == "takeaway")
        let dinein_menu = menu_types.find(item => item.code == "dine-in")

        if (dinein_menu) {
            setdinein_label(dinein_menu?.label)
        }

        if (takeaway_menu) {
            settakeaway_label(takeaway_menu?.label)
        }
    }

}, [merchant])
  return(
    <div>
      <div onClick={()=>setViewOrder(false)}>
        <Icon
          path={mdiArrowLeft}
          color='black'
          title="Back"
          size={0.9}
        />
      </div>
      <div style={{fontSize:'16px',fontWeight:"600",margin:'0.5rem 0'}}>
        Order No.:{item.order_number}
      </div>
      <div style={{display:'grid',gridTemplateColumns:'2fr 1fr',gridColumnGap:'1rem'}}>
        <div style={{border:'1px solid #dee2e6'}}>
          <div className="border-bottom" style={{background:'#f7f8f9',padding:'0.5rem'}}>
            <h5 className="m-0" style={{fontSize:'16px'}}>
              {ShowCurrencyNum(posorder_merchant_total(item), true)} - {moment(item.createdAt).format("DD/MM/YYYY")}
              &nbsp;{moment(item.createdAt).format("HH:mm")}
            </h5>
          </div>
          <div style={{display:'grid',gridTemplateColumns:'repeat(2,1fr)'}}>
            <div style={{alignSelf:'center',margin:'1rem'}}>
              <p className="m-0">Payment Status</p>
              <h5 className="m-0">{showPaymentStatus(item)}</h5>
            </div>
            <div style={{margin:'1rem'}}>
              <p className="m-0">Order Status</p>
              {
                item.order_status == 0
                  ? <h5 className="m-0" style={{fontSize:'16px'}}>Order Received</h5>
                  : item.order_status == 1
                  ? <h5 className="m-0" style={{fontSize:'16px',color:'#ea9815'}}>Order Accepted</h5>
                  : item.order_status == 2
                  ? item.orderType ==3 ?  <h5 className="m-0" style={{fontSize:'16px',color:'#0080b0'}}>In transit</h5> : <h5>Ready to Pickup</h5>
                  : item.order_status == 3
                  ? <h5 className="m-0" style={{fontSize:'16px'}}>Processed</h5>
                  : null
              }
            </div>
          </div>
        </div>
        <div style={{border:'1px solid #dee2e6'}}>
          <div className="border-bottom" style={{background:"#f7f8f9",padding:'0.5rem'}}>
            <h5 className="m-0" style={{fontSize:'16px', fontWeight:'600'}}>Tax Invoices</h5>
          </div>
          <div style={{margin:'0.5rem 1rem'}}>
            <p className="m-0" style={{fontSize:'12px'}}>Regular Tax Invoice{item.order_number}</p>
            <p className="m-0" style={{fontSize:'12px'}}> {moment(item.createdAt).format('MMMM Do, YYYY')} </p>
            <span style={{display: 'grid',gridTemplateColumns: 'repeat(2,1fr)',fontSize:'13px'}}>
              {/* <span onClick={handlePrint}>Download PDF</span>
              <span style={{justifySelf:'end'}}>Print</span> */}
            </span>
          </div>
        </div>
      </div>

      <div style={{display: 'grid',gridTemplateColumns: '2fr 1fr',gridColumnGap: '1rem'}}>
        <div style={{margin:'1rem 0'}}>
          <div className='border col8width rounded'>
          <div className='divup border-bottom' style={{background:'#f7f8f9', padding:'0.5rem'}}>
            <h5 className="m-0" style={{fontSize:'16px', fontWeight:'600',color:'#313131'}}>
              Order Item(s) {item.cartItemList.length}
            </h5>
          </div>
          <div className='divdown'>
            <Row className="m-0" style={{padding:'0 0.5rem'}}>
              <Col className="col-6 px-0" >
                <p className="my-1 font-weight-bold" style={{ color: 'black', }}>Item(s)</p>
              </Col>

              <Col className="col-3 px-0">
                <p className="my-1 font-weight-bold text-center" style={{ color: 'black', float: "right" }}>Price Per Qty</p>
              </Col>

              <Col className="col-3 px-0" >
                <p className="my-1 font-weight-bold text-right" style={{ color: 'black', }}> Total </p>
              </Col>
            </Row>
              {
                item.cartItemList.map((itemVal) => {
                  let addons_item = {}
                  if (itemVal.addonItems) {
                    let label = itemVal.addonItems?.groupLabel ? 'groupLabel' : 'groupName'
                    addons_item = groupBy(itemVal.addonItems, label);
                  }
                  return (
                    <Row className="m-0 border-top py-2" key={itemVal._id} style={{padding:'0 0.5rem'}}>
                      <Col className="col-6" style={{ paddingLeft: "0rem" }}>
                        <p className="detail1 text-capitalize m-0">
                            x{itemVal.quantity} {itemVal.name}
                        </p>

                        {
                          itemVal.charges_info[0] && item?.priceListData?.adjustPricesType == 2 ?
                              <p className={`w-100 m-0 ${itemVal.charges_info[0].comment?.toLowerCase().includes('discount') ? 'text-success' : 'text-danger'}`} style={{fontSize:'14px'}}> {itemVal.charges_info[0].comment} </p>
                              : null
                        }
                      </Col>

                      <Col className="col-3 text-right">
                        <p className="detail1 text-capitalize m-auto">
                            {ShowCurrencyNum(Number(itemVal.productPrice).toFixed(2), true)}
                        </p>
                        {/* {
                          Object.keys(addons_item).length <= 0 && itemVal.charges_info[0] && item?.priceListData?.adjustPricesType == 2 ?
                              <p className={`w-100 m-0 text-right ${itemVal.charges_info[0].comment?.toLowerCase().includes('discount') ? 'text-success' : 'text-danger'}`} style={{fontSize:'14px'}}> {parseFloat(itemVal.charges_info[0].amount) >= 0 ? `${merchant.country.currency_symbol}${parseFloat(itemVal.charges_info[0].amount * itemVal.quantity).toFixed(2)}` : `-${merchant.country.currency_symbol}${parseFloat(Math.abs(itemVal.charges_info[0].amount * itemVal.quantity)).toFixed(2)}`} </p>
                              : null
                        } */}
                        <p className="mt-0">
                        {
                          itemVal.charges_info[0] && item?.priceListData?.adjustPricesType == 2 ?
                              <h5 className={`w-100 text-right ${item.priceListData?.priceListType == 3 ? 'text-success' : 'text-danger'}`} style={{fontSize:'14px'}}> {parseFloat(roundUp(itemVal.charges_info[0].amount, 2)) >= 0 ? `${merchant.country.currency_symbol}${parseFloat(roundUp(itemVal.charges_info[0].amount, 2) * itemVal.quantity).toFixed(2)}` : `-${merchant.country.currency_symbol}${parseFloat(Math.abs(roundUp(itemVal.charges_info[0].amount, 2) * itemVal.quantity)).toFixed(2)}`} </h5>
                              : null
                        }
                        </p>
                      </Col>
                        {
                          Object.keys(addons_item).length > 0 &&
                          <>
                            <Col className="col-9">
                              {
                                Object.keys(addons_item).map((groupname, index) => {
                                let grpName = ""
                                return (
                                  <>
                                    <span className='detail1 font-weight-bold' style={{ color: 'gray' }}>{groupname}</span>
                                    <div className="row">
                                      {
                                        addons_item[groupname].map((addons, ind) => {
                                        grpName = addons.groupName
                                        return (
                                          <>
                                            <span className='col-10' style={{ color: 'gray' }}> {addons.title} </span>
                                            <span className='col-2 detail1 text-right' style={{ color: 'gray' }}>{ShowCurrencyNum(addons.price.toFixed(2), true)} </span>
                                          </>
                                        )})
                                      }
                                    </div>
                                  </>
                                )})
                              }
                            </Col>

                            <Col className="col-3  text-right" style={{ paddingRight: "0rem" }}>
                              <p className="pricee"
                                style={{
                                position: "absolute",
                                right: "0",
                                bottom: "0"
                              }}>

                              {ShowCurrencyNum(parseFloat(
                                 parseFloat(itemVal.itemTotal)
                              ).toFixed(2), true)}
                              </p>
                            </Col>
                          </>
                        }

                        {!Object.keys(addons_item).length > 0 && <Col className="col-3 text-right d-flex align-items-end justify-content-end" style={{ paddingRight: "0rem" }}>
                          <p className="pricee">
                              {ShowCurrencyNum(parseFloat(
                                  parseFloat(itemVal.itemTotal)
                              ).toFixed(2), true)}
                          </p>
                        </Col>}
                    </Row>
                  );
                  })
              }
              <Row className="m-0 border-top" style={{display:'flex',justifyContent:'end'}}>
                <Col md={4} style={{display:'flex',justifyContent:'end',paddingRight:0,marginRight:'-1.5rem'}}>
                  <div className="divLabel my-2">
                    <p className="boldd">Subtotal</p>
                      {item.charges_info.filter((e) => e.title != 'Commission' || (e.title == "Tax" && item.taxCalculateType != 1)).map((chargeVal) => {
                        if (chargeVal.charges_payer != 0) {
                          return (
                            <p key={chargeVal._id} className="boldd" style={{ color: chargeVal.title.toLowerCase().includes('discount') ? 'green' : chargeVal.title.toLowerCase().includes('surcharge') ? 'red' : 'inherit' }}>
                                {/* {chargeVal.title} */}
                              {chargeVal.title.toLowerCase() == 'service fees' ? 'Service Fees' : chargeVal.title}
                                {
                                  chargeVal.info &&
                                  <>
                                    {
                                      <span
                                        id="PopoverTop1"
                                        style={{
                                          position: "absolute",
                                          // right: "-46px",
                                          background: "#fff",
                                          zIndex: "90",
                                          marginLeft: 8
                                        }}
                                      >
                                      <Icon
                                        path={mdiInformationOutline}
                                        width={`1rem`}
                                        height={`1rem`}
                                        color="#646777"
                                      />
                                      </span>
                                    }
                                    <Popover
                                      placement={"top"}
                                      isOpen={popoverOpen}
                                      target="PopoverTop1"
                                      toggle={togglePopover}
                                      dir={'ltr'}
                                      hideArrow={false}
                                    >
                                    <PopoverBody>
                                    {
                                      chargeVal.info.filter(a => a.charges_payer != 0).map(a => (
                                        <div style={{ fontSize: "13px", textAlign: "center", color: "#d23f99", fontWeight: "500" }}>
                                          <span>{a.title.toLowerCase() == 'service fees' ? 'Service Fees': a.title}</span> <span>{ShowCurrencyNum(parseFloat(a.amount).toFixed(2), true)}</span>
                                        </div>
                                      ))
                                    }
                                    </PopoverBody>
                                    </Popover>
                                  </>
                                }
                            </p>
                          );
                        }
                      })}
                      {
                        item.codeOffer ?
                          <p className="boldd">Discount Applied ({item.codeOffer})</p>
                          : null
                      }
                      <p className="boldd darkbold">Total {item.taxCalculateType == 1 ? <span> (incl Tax) </span> : null}</p>
                  </div>
                </Col>

                  <Col md={3} className="pr-0" style={{display:'flex',justifyContent:'end',paddingRight:'0.5rem'}}>
                      <div className="divPrice my-2" style={{paddingRight:'0.5rem'}}>
                        <p className="boldd"> {ShowCurrencyNum(parseFloat(item.cart_total).toFixed(2), true)} </p>
                        {item.charges_info.filter((e) => e.title != 'Commission' || (e.title == "Tax" && item.taxCalculateType != 1)).map((chargeVal) => {
                          if (chargeVal.charges_payer != 0) {
                            return (
                              <p key={chargeVal._id} className="boldd" style={{ color: chargeVal.title?.toLowerCase().includes('discount') ? 'green' : chargeVal.title?.toLowerCase().includes('surcharge') ? 'red' : 'inherit' }}>
                                {ShowCurrencyNum(parseFloat(chargeVal.info ? chargeVal.info.reduce((total, num) => total + (num.charges_payer == 1 ? num.amount : 0), 0) : chargeVal.amount).toFixed(2), true)}
                              </p>
                            ); 
                          }
                        })}
                        {
                          item.saveOffer || item.freeOffer ?
                          <p className="boldd">{item.freeOffer ? `Free ${item.freeOffer}` : `- ${ShowCurrencyNum((item.saveOffer).toFixed(2), true)}`}</p>
                          :null
                        }
                        <p className="boldd darkbold"> {ShowCurrencyNum(item.final_total, true)} </p>
                      </div>
                  </Col>
              </Row>
          </div>
          <div className='divup mt-5'style={{padding: "1.5rem", backgroundColor: "#F7F8F9"}}>
            <p className='notep m-0' style={{background:'f7f8f9', padding:'0.5rem',fontSize: '13px', fontWeight: 'bold',color: "black"}}> Note for staff</p>
            <textarea
              className="inputt textAreaHeight"
              // value={staffNote}
              placeholder="Add a note to this order. Only you and your staff members can see this note"
              style={{width: '100%',
                borderRadius: '2px',
                border: '1px solid #b6b6b6',
                background: 'white',
                outline: 'none',
                padding: '8px 15px',
                marginTop: '0.5rem'}}
            />
          </div>
      </div>
      <div className='border mt-3 col8width rounded'>
        <div className='divup border-bottom' style={{background:'#f7f8f9', padding:'0.5rem'}}>
          <h5 className="m-0" style={{fontSize:'16px',fontWeight:'600',color:'#313131'}}>Earning after deduction</h5>
        </div>
        <div className='divdown'>
          <Row className="m-0">
            <Col md={9}>
                <div className="pr-0 my-2">
                  <p className="boldd" style={{ fontWeight: "bold", color: "black", fontSize: 16 }}>Subtotal</p>
                  {
                    item.charges_info.filter((e) => e.title == 'Delivery Fees').map((item) => {
                      return (
                        <p key={item._id} className="boldd">
                          {item.title}
                        </p>
                      );
                    })
                  }
                    {item.charges_info.filter((e) => e.title != 'Commission' || (e.title == "Tax" && item.taxCalculateType != 1)).map((chargeVal) => {
                      if (chargeVal.charges_payer != 1) {
                        return (
                          <p key={chargeVal._id} className="boldd">
                            {chargeVal.title.toLowerCase() == 'service fees' ? 'Service Fees' : chargeVal.title}

                              {
                                chargeVal.info &&
                                <>
                                  {
                                    <span
                                      id="PopoverTop"
                                      style={{
                                        position: "absolute",
                                        // right: "-46px",
                                        background: "#fff",
                                        zIndex: "90",
                                        marginLeft: 8
                                      }}
                                    >
                                    <Icon
                                      path={mdiInformationOutline}
                                      width={`1rem`}
                                      height={`1rem`}
                                      color="#646777"
                                    />
                                    </span>
                                  }
                                  <Popover
                                    placement={"top"}
                                    isOpen={popoverOpenEarning}
                                    target="PopoverTop"
                                    toggle={togglePopoverEarning}
                                    dir={'ltr'}
                                    hideArrow={false}
                                  >
                                  <PopoverBody>
                                    {
                                      chargeVal.info.filter(a => a.charges_payer != 1).map(a => (
                                        <div style={{ fontSize: "13px", textAlign: "center", color: "#d23f99", fontWeight: "500" }}>
                                          <span>{a.title}</span> <span>{ShowCurrencyNum(parseFloat(a.amount).toFixed(2), true)}</span>
                                        </div>
                                      ))
                                    }
                                  </PopoverBody>
                                  </Popover>
                                </>
                              }
                          </p>
                        );
                      }
                    })}
                    {
                      item.charges_info.filter((e) => e.title == 'Commission').map((item) => {
                        return (
                          <p key={item._id} className="boldd">
                            {item.title.toLowerCase() == 'commission' ? 'Commission' : item.title}
                          </p>
                        );
                      })
                    }
                    <p className="boldd darkbold" style={{ fontWeight: "bold", color: "black", fontSize: 16 }}>Total</p>
                </div>
            </Col>
            <Col md={3} className="pr-0">
              <div className="pr-0 my-2" style={{ float: 'left' }}>
                <p className="boldd darkbold" style={{ fontWeight: "bold", color: "black", fontSize: 16 }}> {ShowCurrencyNum(parseFloat(item.cart_total).toFixed(2), true)} </p>
                {
                  item.charges_info.filter((e) => e.title == 'Delivery Fees').map((chargeVal) => {
                  return (
                    <p key={chargeVal._id} className="boldd"> {ShowCurrencyNum(parseFloat(chargeVal.amount).toFixed(2), true)} </p>
                  );
                  })
                }
                {item.charges_info.filter((e) => e.title != 'Commission' || (e.title == "Tax" && item.taxCalculateType != 1)).map((chargeVal) => {
                  if (chargeVal.charges_payer != 1) {
                    return (
                      <p key={chargeVal._id} className="boldd"> {ShowCurrencyNum(parseFloat(chargeVal.info ? chargeVal.info.reduce((total, num) => total + (num.charges_payer != 1 ? num.amount : 0), 0) : chargeVal.amount).toFixed(2), true)} </p>
                    );
                  }
                })}
                {
                  item.charges_info.filter((e) => e.title == 'Commission').map((chargeVal) => {
                    return (
                      <p key={chargeVal._id} className="boldd"> {ShowCurrencyNum(parseFloat(chargeVal.amount).toFixed(2), true)} </p>
                    );
                  })
                }
                <p className="boldd darkbold" style={{ fontWeight: "bold", color: "black", fontSize: 16 }}> {ShowCurrencyNum(item.final_total - item.total_charges, true)} </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
          </div>
          <div style={{margin:'1rem 0'}}>
          <div className='border col4width rounded' style={{ height: item.order_status == 3 ? `max-content` : `auto` }}>
            <div className='divup border-bottom' style={{background:'#f7f8f9', padding:'0.5rem'}}>
              <h5 className="m-0" style={{fontSize:'16px', fontWeight:'600'}}>Customer Details</h5>
            </div>
            <div className='divdown cusdt' style={{padding:'0.5rem'}}>
            <p className=''>
            <span className='spnfix' style={{
                  width: '25%',
                  clear: 'left',
                  float: 'left',
                  textAlign: 'left',
                  paddingRight: '2px'
                }}> Name  </span> : &nbsp;
            {item.customerInfo.customerName ? item.customerInfo.customerName : " NA"}
            </p>
            <p>
            <span className='spnfix'style={{
              width: '25%',
              clear: 'left',
              float: 'left',
              textAlign: 'left',
              paddingRight: '2px'
            }}>Email id </span> : &nbsp;
            <span className="c3mail" style={{

            }}><u>{item.customerInfo.customerEmail ? item.customerInfo.customerEmail : " NA"}</u></span></p>
            <p>
            <span className='spnfix' style={{
              width: '25%',
              clear: 'left',
              float: 'left',
              textAlign: 'left',
              paddingRight: '2px'
            }}> Mobile no. </span> : &nbsp;
            {item.customerInfo.customerMobile ? <span>{item.customerInfo.customerMobilePrefix ? `(${item.customerInfo.customerMobilePrefix})` : null} {item.customerInfo.customerMobile}</span> : " NA"}</p>
            {item.orderType == 3 &&
            <p className='flex'>
            <span className='spnfix' style={{
              width: '25%',
              clear: 'left',
              float: 'left',
              textAlign: 'left',
              paddingRight: '2px'
            }}>Delivery Address</span>
            <span style={{ textTransform: "capitalize", flex: 1 }}>: &nbsp; {item.delivery_address?.address ? `${item.delivery_address?.address}, `: ''}{item.delivery_address?.address_details}
            </span>
            </p>
            }
            </div>

            <div className='divdown border-top cusdt' style={{padding:'0.5rem'}}>
              <p>
              <span className='spnfix' style={{
                width: '25%',
                clear: 'left',
                float: 'left',
                textAlign: 'left',
                paddingRight: '2px'
              }}>Order Type</span> : &nbsp;
              <span style={{ textTransform: "capitalize" }}>
              {
                item.orderType == 1 ? takeaway_label ? takeaway_label + " (" + item?.pickupdate.toString() + ")" : `Takeaway` + " (" + item?.pickupdate.toString() + ")"
                : item.orderType == 2 ? dinein_label ? dinein_label : `Dine-in`
                : item.orderType == 3 ? `Delivery`
                : item.orderType == 4 ? `Quick Sale`
                : null
              }
              </span>
              {item.orderType == 3 ?
              <span>
                &nbsp;&nbsp;({moment(item?.addedDate).add(item.delivery_address?.country == "Australia" ? 7 : 15, 'days').format('dddd, MMM DD')})
              </span>
              :
              <span>{item.tableInfo.tableName ? `[${item.tableInfo.tableName}]` : null}</span>
              }
              </p>
            </div>

            {(item.order_status == 3 && item.processed_date) && <> <div className='divup border-bottom'>
            <h5 className="">
              Additional Details
            </h5>
            </div>
              <div className='divdown cusdt'>
                <p className=''>
                <span className='' style={{ whiteSpace: "nowrap" }}>Customer Choice</span> : &nbsp;
                {
                  item.orderType == 1 ? takeaway_label ? takeaway_label : `Takeaway`
                  : item.orderType == 2 ? dinein_label ? dinein_label : `Dine-in`
                  : item.orderType == 3 ? `Delivery`
                  : item.orderType == 4 ? `Quick Sale`
                  : null
                }
                {item.orderType == 2 && item.tableInfo.tableName ? `[${item.tableInfo.tableName}]` : null}
                </p>
                <p className=''>
                <span className='' style={{ whiteSpace: "nowrap" }}>Order Process Time </span> : &nbsp;
                <span style={{ color: "#D23F99" }}>{moment(item.processed_date).format("DD/MM/YYYY")}&nbsp;
                {moment(item.processed_date).format("HH:mm")}</span>
                </p>
              </div> </>}
            {(item.order_status == 3 && item.ipay_transaction_id) && <><div className='divup border-bottom'>
              <h5 className="" style={{fontSize:'16px'}}>
                Payment Details
              </h5>
            </div>
            <div className='divdown cusdt'>
              <p className='m-0'>
              <span className='' style={{ whiteSpace: "nowrap" }}>Payment Details </span> : &nbsp;
              Paid by {item.payment_method.replace("COD", "CASH")}
              <br />
              <p>Transaction Id: &nbsp;
                {item.ipay_transaction_id}</p>
              <p style={{ cursor: "pointer" }} className="transId">Transaction Details</p>
              </p>
            </div>
            </>}
        </div>
            <div className='border rounded my-8 col4width' style={{ height: "auto", margin: "2rem 0rem" }}>
              <div className='divup border-bottom' style={{background:'#f7f8f9', padding:'0.5rem'}}>
                <h5 className="m-0" style={{fontSize:'16px', fontWeight:'600'}}>
                  Payment Method
                </h5>
              </div>
              <div className='divdown cusdt' style={{ minHeight: "4rem",padding:'0.5rem' }}>
                <p className='m-0'>
                  <span className='spnfix'>  {item.payment_method == "COD" || item.payment_method == "Paid" ? `Cash` : item.payment_method == "ONLINE" ? `Card` : `IpayPro`}  </span>
                </p>
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}

export default OrderDetails