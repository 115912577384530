import React, { Fragment, useEffect, useState } from 'react';
import { Button, Table, Popover, PopoverBody, PopoverHeader, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { DisappearedLoading } from "react-loadingg";


export const Userandroles = ({
    merchant_info,
    merchant_id,
    subUsers,
    storesList,
    user_details,
    merchant_user
}) => {

    let history = useHistory();
    const [tableData, setTableData] = useState([]);
    const [popOverData, setPopoverData] = useState([]);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [loading, seLoading] = useState(false);
    const [getSubUserData, setGetSubUserData] = useState(subUsers);
    const [user_access_stores, setuser_access_stores] = useState([]);
    const [staff_item,set_staff_item]=useState({})
    const [isModalOpen,setIsModalOpen]=useState(false)


    const getUsersAccess = async () => {

        let postdata = { email: merchant_user.email };
        // console.log(merchant_info)
        seLoading(true);
        client.put(api.getUsersAccess, postdata, async function (error, response) {
            console.log(response,error)
            if (!error) {
                try {
                    if (!response.error) {
                        console.log(response)
                        setuser_access_stores(response.result.data);
                        seLoading(false);
                    } else {
                        toast.error(response.message);
                    }
                } catch (e) {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }
            seLoading(false);
        })
    };
    const deleteUsersAccess = async () => {

        let postdata = { email: merchant_user.email,item_id:staff_item?._id };
        // console.log(merchant_info)
        seLoading(true);
        client.put(api.deleteUsersAccess, postdata, async function (error, response) {
            console.log(response,error)
            if (!error) {
                try {
                    if (!response.error) {
                        console.log(response)
                        getUsersAccess()
                        toggleRevokeModal()
                        seLoading(false)
                        
                    } else {
                        toast.error(response.message);
                    }
                } catch (e) {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }
            seLoading(false);
        })
    };

    useEffect(() => {
        
        getUsersAccess();

    }, [])  


    console.log(user_access_stores,'user_access_stores')


    useEffect(() => {
        try {

            let tempArr = []
            storesList.merchants.filter(a => a.adminId == user_details._id).map((item) => {
                let tempObj = {
                    storeName: item.business_name[0].toUpperCase() + item.business_name.slice(1),
                    storeId: item._id,
                    adminId: item.adminId,
                    profileImage: item.profile_image,
                    totalUsers: item.subscription.subscription_id.subscription.number_of_users,
                    oldUsers: item.subscription.old_users,
                    newUsers: item.subscription.new_users,
                    unlimitedusers: item.subscription.subscription_id.subscription.unlimited_users,
                    planTitle: item.subscription.subscription_id.subscription.title,
                    lastSignIn: "2 hours ago",
                    location: "Abbortsford,Australia",
                    deviceDetails: "windows",
                    ipAddress: "192.168.0.1",
                    usersList: [{
                        emailId: user_details.email,
                        userData: user_details,
                        login_location: user_details.login_location,
                    }],
                    hidenUsersList: [],
                    unique_id: Math.floor(100000 + Math.random() * 900000),
                }
                if (getSubUserData.length) {
                    getSubUserData.map((user) => {
                        if (user.superUser) {
                            tempObj.usersList.push({
                                emailId: user.email,
                                userData: user,
                                login_location: user.login_location
                            })

                        } else {
                            if (user.access_merchants.length) {
                                user.access_merchants.map((accMerc) => {


                                    if (!accMerc.hide && accMerc.merchant_id == item._id) {
                                        tempObj.usersList.push({
                                            emailId: user.email,
                                            userData: user,
                                            login_location: user.login_location,
                                        })
                                    } else if (accMerc.hide && accMerc.merchant_id == item._id) {
                                        tempObj.hidenUsersList.push({
                                            emailId: user.email,
                                            userData: user,
                                            login_location: user.login_location,
                                        })
                                    }
                                })
                            }
                        }
                    })
                }
                tempObj.noOfUsers = item.subscription.subscription_id.subscription.number_of_users < tempObj.usersList.length && item.subscription.subscription_id.subscription.unlimited_users == false ? item.subscription.subscription_id.subscription.number_of_users : tempObj.usersList.length;
                tempArr.push(tempObj);
                setTableData(tempArr);
            });
            

        } catch (error) {
            // console.log(error, "useffect error");
        }

    }, [getSubUserData]);

    useEffect(() => {

        if (popoverOpen) {
            setTimeout(() => {
                setPopoverOpen(false);
            }, 5000);
        }
    }, [popoverOpen])


    const togglePopover = () => {
        setPopoverOpen(prevState => !prevState);
    };


    const toggleRevokeModal=(item)=>{
        setIsModalOpen(!isModalOpen)
        if(item!==undefined)
        {
            set_staff_item(item)

        }
    }


    console.log(tableData, "tableData");

    return (
        
        <Fragment>
            {!loading ?

               <>
                <div className="container-fluid">
                    <div className="row my-3">
                        <div className="col-12">
                            <h6 style={{ fontWeight: "600", fontSize: "18px" }}>Users & Roles</h6>
                        </div>
                    </div>
                    <div className='main_div'>
                        {tableData && tableData.length > 0 ?
                            <div className="table-responsive">
                                <table className="table table-border-horizontal table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Store</th>
                                            <th scope="col">No. of users</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData &&
                                            tableData.map((item, index) => {
                                                // console.log(item, "table data")
                                                return (
                                                    <>
                                                        <tr key={index + 1}>
                                                            <th scope=" row">{index + 1}</th>
                                                            <td>
                                                                <div className="d-flex align-items-center ml-3">
                                                                    {item.profileImage == "" ? (
                                                                        <>
                                                                            <span
                                                                                className="border p-2 mr-2"
                                                                                style={{
                                                                                    fontSize: "16px",
                                                                                    width: 40,
                                                                                    height: 40,
                                                                                    borderRadius: 20,
                                                                                    display: "block",
                                                                                    textAlign: "center",
                                                                                }}
                                                                            >
                                                                                <b style={{ textTransform: "uppercase" }}>
                                                                                    {item.storeName?.slice(0, 2)}
                                                                                </b>
                                                                            </span>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <img
                                                                                className={`mr-2`}
                                                                                style={{ width: 40, height: 40, borderRadius: 20 }}
                                                                                src={item.profileImage.includes('dropbox') ? item.profileImage : `${client.url(`images/merchant-profile/${item.profileImage}`)}`}
                                                                                alt="avatar"
                                                                            />
                                                                        </>
                                                                    )}
                                                                    <h6 className='my-0' style={{ fontSize: "14px", marginLeft: "0.5rem" }}>{item.storeName}</h6>
                                                                    <div
                                                                        id={`PopoverRight`}
                                                                        style={{ cursor: "pointer", marginLeft: "1rem", border: "1px solid #00a8ff", borderRadius: "40%", padding: "4px 8px", color: "#00a8ff", backgroundColor: "white" }}
                                                                        onClick={() => { setPopoverData(item.usersList) }}
                                                                        onBlur={() => { setPopoverOpen(false); }}
                                                                    >
                                                                        +{item.noOfUsers}
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <th scope=" row">
                                                                <span>{`${item.noOfUsers}/${item.unlimitedusers ? 'Unlimited' : item.totalUsers}`} {" "}
                                                                    {/* {item.noOfUsers == item.totalUsers ? <a style={{ textDecoration: 'underline', color: '#d24099', cursor: 'pointer', marginLeft: '15px' }} >Upgrade</a> : null} */}
                                                                </span>
                                                            </th>
                                                            <th scope=" row">
                                                                <div className="" style={{ width: '70px' }}>
                                                                    <Button
                                                                        id="PopoverBottom"
                                                                        outline
                                                                        style={{ padding: "3px", width: "130px" }}
                                                                        className="d-flex align-items-center justify-content-center mt-0 mb-0 mr-2 ml-2 px-2"
                                                                        color="primary"
                                                                        onClick={() => {
                                                                            localStorage.setItem('userSettingData', JSON.stringify(item));
                                                                            history.push({
                                                                                pathname: `/userSetting/${item.adminId}`,
                                                                                state: { userDataa: item }
                                                                            })
                                                                        }}
                                                                    >
                                                                        Manage users
                                                                    </Button>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </>
                                                );
                                            })}
                                    </tbody>
                                    <Popover
                                        placement="right"
                                        isOpen={popoverOpen}
                                        target={`PopoverRight`}
                                        toggle={togglePopover}
                                        dir={'ltr'}
                                        hideArrow={false}
                                    >
                                        <PopoverHeader><h6 style={{ fontSize: "16px", fontWeight: "600" }}>Users List</h6></PopoverHeader>
                                        <PopoverBody>
                                            <div style={{ maxHeight: "210px", overflowY: "auto" }} >
                                                {popOverData.map((data) => {
                                                    return (<p style={{ fontSize: "14px" }}>{data.emailId}</p>)
                                                })}
                                            </div>
                                        </PopoverBody>
                                    </Popover>
                                </table>

                            </div>
                            :
                            <div className="row">
                                <h6 style={{ textAlign: "center" }}> No records found! </h6>
                            </div>
                        }
                    </div>
                </div>
                <div className="container-fluid my-4">
                    <div className="row my-3">
                        <div className="col-12">
                            <h6 style={{ fontWeight: "600", fontSize: "18px" }}>Store Access</h6>
                        </div>
                    </div>
                    <div className='main_div'>
                        {user_access_stores && user_access_stores.length > 0 ?
                            <div className="table-responsive">
                                <table className="table table-border-horizontal table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Store</th>
                                            <th scope="col">Role</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {user_access_stores &&
                                            user_access_stores.map((item, index) => {
                                                // console.log(item, "table data")
                                                return (
                                                    <>
                                                        <tr key={index + 1}>
                                                            <th scope=" row">{index + 1}</th>
                                                            <td>
                                                                <div className="d-flex align-items-center ml-3">
                                                                    {item?.merchant_info?.Store_logo_image== "" || item?.merchant_info?.Store_logo_image==undefined ? (
                                                                        <>
                                                                            <span
                                                                                className="border p-2 mr-2"
                                                                                style={{
                                                                                    fontSize: "16px",
                                                                                    width: 40,
                                                                                    height: 40,
                                                                                    borderRadius: 20,
                                                                                    display: "block",
                                                                                    textAlign: "center",
                                                                                }}
                                                                            >
                                                                                <b style={{ textTransform: "uppercase" }}>
                                                                                    {item?.merchant_info.business_name?.slice(0, 2)}
                                                                                </b>
                                                                            </span>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <img
                                                                                className={`mr-2`}
                                                                                style={{ width: 40, height: 40, borderRadius: 20 }}
                                                                                src={item?.merchant_info?.Store_logo_image?.includes('dropbox') ? item?.merchant_info?.Store_logo_image : `${client.url(`images/merchant-profile/${item?.merchant_info?.Store_logo_image}`)}`}
                                                                                alt="avatar"
                                                                            />
                                                                        </>
                                                                    )}
                                                                    <h6 className='my-0' style={{ fontSize: "14px", marginLeft: "0.5rem" }}>{item?.merchant_info.business_name}</h6>
                                                                    

                                                                </div>
                                                            </td>
                                                            <th scope=" row">
                                                                <span>{item?.jobProfile} {" "}
                                                                    {/* {item.noOfUsers == item.totalUsers ? <a style={{ textDecoration: 'underline', color: '#d24099', cursor: 'pointer', marginLeft: '15px' }} >Upgrade</a> : null} */}
                                                                </span>
                                                            </th>
                                                            <th scope=" row">
                                                                <div className="" style={{ width: '70px' }}>
                                                                    <Button
                                                                        id="PopoverBottom"
                                                                        outline
                                                                        style={{ padding: "3px", width: "130px" }}
                                                                        className="d-flex align-items-center justify-content-center mt-0 mb-0 mr-2 ml-2 px-2"
                                                                        color="danger"
                                                                        onClick={()=>{toggleRevokeModal(item)}}
                                                                    >
                                                                        Revoke Access
                                                                    </Button>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </>
                                                );
                                            })}
                                    </tbody>
                                    <Popover
                                        placement="right"
                                        isOpen={popoverOpen}
                                        target={`PopoverRight`}
                                        toggle={togglePopover}
                                        dir={'ltr'}
                                        hideArrow={false}
                                    >
                                        <PopoverHeader><h6 style={{ fontSize: "16px", fontWeight: "600" }}>Users List</h6></PopoverHeader>
                                        <PopoverBody>
                                            <div style={{ maxHeight: "210px", overflowY: "auto" }} >
                                                {popOverData.map((data) => {
                                                    return (<p style={{ fontSize: "14px" }}>{data.emailId}</p>)
                                                })}
                                            </div>
                                        </PopoverBody>
                                    </Popover>
                                </table>

                            </div>
                            :
                            <div className="row">
                                <h6 style={{ textAlign: "center" }}> No records found! </h6>
                            </div>
                        }
                    </div>
                </div>
                

                <Modal isOpen={isModalOpen} centered toggle={toggleRevokeModal} className="modal-body" >
                    <ModalBody>
                        <ModalBody style={{ padding: "16px 0" }}>
                            <h5 style={{ fontSize: "1.15rem" }}>Do you want to revoke access?</h5>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={deleteUsersAccess}> Yes </Button>
                            <Button color="secondary" onClick={toggleRevokeModal}>No</Button>
                        </ModalFooter>
                    </ModalBody>
            </Modal>


                </>

                


                : <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                    <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                        <DisappearedLoading size="medium" color="#514F4E" />
                    </div>
                </div>
            }
        </Fragment>
    )
}
