import React, { useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import api from '../../../constant/apilist';
import client from '../../../Api/HTTPClient';
import { ToastContainer, toast } from "react-toastify";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Row, Col, Input, FormGroup
} from "reactstrap";

const AddLocations = (props) => {
    let [loadingbtn, setloadingbtn] = useState(false);
    let [showerrorstatus, setshowerrorstatus] = useState(false);
    const { states, countryCode, countryId } = props

    const user = JSON.parse(window.localStorage.getItem('user'))

    const { handleSubmit, register, errors, watch } = useForm();

    const onSubmit = (form_data) => {
        if (form_data !== '') {
            setloadingbtn(true)
            let postData = {
                suburb: form_data.suburb,
                post_code: form_data.postcode,
                state: form_data.state,
                latitude: form_data.latitude,
                longitude: form_data.longitude,
                modified_by: user.full_name,
                country_code: countryCode,
                fk_country_id: countryId
            }
            client.post(
                api.saveLocationsData,
                postData,
                async function (error, response){
                    if (!error) {
                        if (typeof response.error !== "undefined") {
                            try {
                                if (!response.error) {
                                    props.toggle(1);
                                    toast.success("Added Successfully");
                                    // setcashbackdata(response.result.data);
                                } else {
                                    toast.error(response.message);
                                }
                            } catch (e) {
                                toast.error(
                                    "Internal error occured. Please contact support"
                                );
                            }
                        } else {
                            toast.error(
                                "Internal error occured. Please contact support"
                            );
                        }
                    } else {
                        toast.error(
                            "Internal error occured. Please contact support"
                        );
                    }

                    setloadingbtn(false);
                }
            )
        }
        else {
            setshowerrorstatus(true)
            errors.showMessages()
        }
    }


    return (
        <Modal
            isOpen={props.isOpen}
            toggle={props.toggle}
            className="modal-body"
            centered={true}
        >
            <form
                className="needs-validation"
                noValidate=""
                onSubmit={handleSubmit(onSubmit)}
            >
                <ModalHeader toggle={props.toggle}>
                    Add New Location Data
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12}>
                            <FormGroup className="valid_form">
                                <Label for="suburb">Suburb</Label>
                                <input
                                    // className={errorState.suburb !== null && 'invalid_field'} value={formData.suburb} onChange={(e) => onChangeHandler(e, 'suburb')} type="text" 
                                    name="suburb"
                                    type='text'
                                    className='form-control'
                                    placeholder="Suburb"
                                    ref={register({ required: true })}
                                />
                                {/* {errorState.suburb !== null && (<span className="errorMessage">
                                    {errorState.suburb}
                                </span>)} */}
                                <span style={{ color: "#ff5370" }}>
                                    {errors.suburb &&
                                        "Suburb name is required"}
                                </span>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup className="valid_form">
                                <Label for="postcode">Postcode</Label>
                                <input
                                    // className={errorState.postcode !== null && 'invalid_field'} onChange={(e) => onChangeHandler(e, 'postcode')} value={formData.postcode} type="text" 
                                    type='text'
                                    name="postcode"
                                    className='form-control'
                                    placeholder="Postcode"
                                    ref={register({
                                        required: true, pattern: {
                                            value: /^[0-9]*$/,
                                            message: 'Enter proper value'
                                        }
                                    })}
                                />
                                <span style={{ color: "#ff5370" }}>
                                    {errors.postcode &&
                                        "Post code is required"}
                                </span>
                                {/* {errorState.postcode !== null && (<span className="errorMessage">
                                    {errorState.postcode}
                                </span>)} */}
                            </FormGroup>
                        </Col>

                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup className="valid_form">
                                <Label for="state">State</Label>
                                {/* <Input
                                    // className={errorState.country_state !== null && 'invalid_field'}
                                    //  onChange={(value) => {
                                    //     if (value) {
                                    //         setErrorState({
                                    //             suburb: null,
                                    //             postcode: null,
                                    //             country_state: null,
                                    //             latitude: null,
                                    //             longitude: null
                                    //         });
                                    //     }
                                    //     setFormdata({ ...formData, country_state: value })
                                    // }} 
                                    ref={register({ required: true })}
                                    style={{
                                        width: "100%",
                                        paddingBlock: "0.375rem",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                    }}
                                    type="select" name="state">
                                    <option selected disabled value="">Select State</option>
                                    {states.map(item => (
                                        <>
                                            <option
                                                value={item.name}
                                            // value={formData.country_state}
                                            >{item.name}</option>
                                        </>
                                    ))}
                                </Input> */}
                                <select
                                    ref={register({ required: true })}
                                    style={{
                                        width: "100%",
                                        paddingBlock: "0.375rem",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                    }}
                                    name="state"
                                >
                                    <option value="" selected disabled>Select State</option>
                                    {states.map((item) => (
                                        <option
                                            value={item.name}
                                            style={{
                                                fontSize: "16px",
                                                lineHeight: "24px",
                                            }}
                                        >
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                                <span style={{ color: "#ff5370" }}>
                                    {errors.state && "select a value"}
                                </span>
                                {/* {errorState.country_state !== null && (<span className="errorMessage">
                                    {errorState.country_state}
                                </span>)} */}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup className="valid_form">
                                <Label for="latitude">Latitude</Label>
                                <input
                                    className='form-control'
                                    // className={errorState.latitude !== null && 'invalid_field'} value={formData.latitude} onChange={(e) => onChangeHandler(e, 'latitude')} 
                                    type="text" name="latitude" placeholder="latitude"
                                    ref={register({
                                        required: true, pattern: {
                                            value: /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/,
                                            message: "This value is Required"
                                        }
                                    })}
                                />
                                <span style={{ color: "#ff5370" }}>
                                    {errors.latitude && "Enter a proper value"}
                                </span>
                                {/* {errorState.latitude !== null && (<span className="errorMessage">
                                    {errorState.latitude}
                                </span>)} */}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup className="valid_form">
                                <Label for="postcode">Longitude</Label>
                                <input
                                    // className={errorState.longitude !== null && 'invalid_field'} value={formData.longitude} onChange={(e) => onChangeHandler(e, 'longitude')} 
                                    className='form-control'
                                    type="text" name="longitude"
                                    placeholder="Longitude"
                                    ref={register({
                                        required: true, pattern: {
                                            value: /^\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
                                            message: 'Enter a proper value'
                                        }
                                    })}
                                />
                                <span style={{ color: "#ff5370" }}>
                                    {errors.longitude && "Enter a proper value"}
                                </span>
                                {/* {errorState.longitude !== null && (<span className="errorMessage">
                                    {errorState.longitude}
                                </span>)} */}
                            </FormGroup>
                        </Col>

                    </Row>


                </ModalBody>
                <ModalFooter>
                    {!loadingbtn ? (
                        <>
                            <Button
                                color="default"
                                type="button"
                                onClick={props.toggle}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                type="submit"
                                onClick={() => setshowerrorstatus(true)}
                            >
                                Save
                            </Button>
                        </>
                    ) : (
                        <div
                            className="loader-box pull-right"
                            style={{ height: "auto" }}
                        >
                            <span
                                className="rotate dashed"
                                style={{ height: 20, width: 20 }}
                            ></span>
                        </div>
                    )}
                </ModalFooter>
            </form>
        </Modal>
    )
}

export default AddLocations;