import React, { Fragment } from "react";
import Breadcrumb from "../components/common/breadcrumb";
import client from "../Api/HTTPClient";
import displayMobile from "../components/ui-elements/display_mobile";
import api from "../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import { Link as NavLink } from "react-router-dom";
import PaginationComponent from "react-reactstrap-pagination";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import BarLoader from "react-spinners/BarLoader";
import _debounce from 'lodash/debounce';

import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	TabContent,
	TabPane,
	Nav,
	NavItem,
	UncontrolledTooltip,
	NavLink as NavLinkTab,
} from "reactstrap";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import moment from "moment-timezone";
import moment2 from "moment";
import swal from "sweetalert";
import wallet_icon from "../assets/images/wallet-icon.png";
import imgunverified from "../assets/staticImages/merchant-unverified.png";
import imgverified from "../assets/staticImages/merchant-verified.png";
import imgverificationSent from "../assets/staticImages/merchant-underreview.png";
import "../table.css";
import CustomerDocPending from "./customerDocPending";
import { DisappearedLoading } from "react-loadingg";
import Dropdown from 'react-bootstrap/Dropdown';

// const BorderTable = () => {
class BorderTable extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			error: null,
			isLoaded: false,
			items: [],
			customers: null,
			notify: null,
			tabIndex: 0,
			defaultIndexNo: this.props.history.location.state?.activeTab ? this.props.history.location.state?.activeTab : 1,
			// activeTab: "2",
			del_cust_id: "",
			delete_modal: false,
			activePage: 1,
			pageSize: 15,
			infoModal: false,
			mobileModal: false,
			flagModal: false,
			blockModal: false,
			flag_note: "",
			modalId: null,
			deleteSelectedModal: false,
			selectedIDs: [],
			searchValue: null,
			access_level: localStorage.getItem("accessLevel"),
			adminUser: JSON.parse(localStorage.getItem("user")),
			exportModalClick: false,
			exportModalOption: "select",
			startDate: null,
			endDate: null,
			createCsv: false,
			csvData: [],
			csvHeader: [],
			fetchType: "verified",
			searchLoading: false,
			tab_count: { all: 0, veriCount: 0, unveriCount: 0, blockedCount: 0, pendingveriCount: 0, flaggedCount: 0, notify: 0 },
			data_count_search: 0,
			country: [],
			countryTabIndex: 0,
			defaultCountryCode: localStorage.getItem("sessionCountryCode"),
			customerCountByCountry: [],
		};
	}

	componentDidMount() {
		// alert("hrer");
		this.getCustomers("verified", this.state.activePage);
		// this.getNotify();
		this.fetchCountries();
	}

	componentDidUpdate() {
		if (this.state.csvData.length > 0) {
			setTimeout(() => {
				document.querySelector("#csv").click();
				this.setState({
					exportModalClick: false,
					exportModalOption: "select",
					startDate: null,
					endDate: null,
					csvData: [],
					csvHeader: [],
					createCsv: false,
				});
			}, 10);
		}
	}

	// setPageSize = (event) => {
	// 	this.setState({ pageSize: event.target.value });
	// };

	setPageSize = (event) => {
		this.setState(
			{
				pageSize: event.target.value,
				activePage: 1,
			},
			() => {
				this.getCustomers(this.state.fetchType, 1);
			}
		);
	};

	displayDate(date) {
		let transaction_date = new Date(date);
		return <span>{transaction_date}</span>;
	}
	set_del_id(del_id) {
		this.setState({ del_cust_id: del_id });
	}

	handlePageChange = (pageNumber) => {
		this.setState({ activePage: pageNumber });
		this.getCustomers(this.state.fetchType, pageNumber);
	};

	delete_toggle() {
		this.setState({ delete_modal: !this.state.delete_modal });
	}

	toggleDeleteSelectedModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				deleteSelectedModal: !prevState.deleteSelectedModal,
			};
		});
	};

	toggleMobileModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				mobileModal: !prevState.mobileModal,
			};
		});
	};

	getCustomersWalletInfo(customer_id) {
		let postdata = { customer_id: customer_id };

		client.post(api.customers_wallet, postdata, (error, response) => {
			if (!error) {
				if (typeof response.error !== "undefined") {
					try {
						if (!response.error) {
							const foundCustomerIndex = this.state.customers.findIndex((element) => {
								return element._id == customer_id;
							});
							const updatedCustomers = this.state.customers;
							const updatedCustomer = {
								...this.state.customers[foundCustomerIndex],
								wallet: { ...response.result.wallet_info },
							};
							updatedCustomers[foundCustomerIndex] = updatedCustomer;
							this.setState({ customers: updatedCustomers });
						}
					} catch (e) {
						setTimeout(() => {
							toast.error("Internal error occured. Please contact support");
						}, 200);
					}
				} else {
					setTimeout(() => {
						toast.error("Internal error occured. Please contact support");
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
		});
	}

	handleDeleteSubmit = async (e) => {
		e.preventDefault();
		let current = this;
		current.setState({ loading: true });
		const data = { customer_id: this.state.del_cust_id };

		if (data !== "") {
			client.post(api.delete_customer_data, data, async function (error, response) {
				if (!error) {
					// alert(JSON.stringify(response));
					//   return false;
					if (typeof response.error !== "undefined") {
						try {
							if (!response.error) {
								toast.success("Customer Deleted Successfully");
								window.location.reload(true);
							} else {
								toast.error(response.message);
							}
						} catch (e) {
							toast.error("Internal error occured. Please contact support");
						}
					} else {
						toast.error("Internal error occured. Please contact support");
					}
				} else {
					toast.error("Internal error occured. Please contact support");
				}
				current.setState({ loading: false });
			});
		}
	};

	refreshPage() {
		window.location.reload(false);
	}

	changeTab = async (type) => {
		this.setState(
			{
				fetchType: type,
				activePage: 1
			},
			() => {
				// alert(type);
				this.getCustomers(type, 1);
				// this.getNotify();
			}
		);
	};

	changeCountryTab = async (data) => {

		this.setState({ defaultCountryCode: data });

		let getAllCountry = this.state.country;
		let getCountryCode = [];

		getAllCountry.length > 0 && getAllCountry.map((countryData) => {
			getCountryCode.push(countryData.country_code)
			return countryData;
		});

		setTimeout(() => {
			this.getCustomers(this.state.fetchType, this.state.activePage, true);
			this.getCustomersCountByCountry(getCountryCode);
		}, 1000);

	}

	getCustomers = async (fetchType, activePage) => {
		let { pageSize } = this.state;
		let skip = (parseInt(activePage) - 1) * parseInt(pageSize);

		let postdata = {
			type: fetchType,
			searchValue: this.state.searchValue,
			limit: parseInt(pageSize),
			skip,
			countryCode: this.state.defaultCountryCode == "All" ? null : this.state.defaultCountryCode
		};
		this.setState({ searchLoading: true });

		// console.log(postdata)

		client.post(api.get_all_customers_data, postdata, (error, response) => {
			if (!error) {
				// alert(JSON.stringify(response));
				if (typeof response.error !== "undefined") {
					try {
						if (!response.error) {
							// console.log(response.result.data.result)
							this.setState({ customers: response.result.data.result });
							this.setState({ tab_count: response.result.tab_data })

							// console.log(response.result.tab_data)
							if (this.state.searchValue) {
								this.setState({ data_count_search: response.result.data.result.length })
							} else {
								this.setState({ data_count_search: 0 })
							}
						} else {
							setTimeout(() => {
								toast.error(response.message);
							}, 200);
						}
					} catch (e) {
						setTimeout(() => {
							toast.error("Internal error occured. Please contact support");
						}, 200);
					}
				} else {
					setTimeout(() => {
						toast.error("Internal error occured. Please contact support");
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
			// current.setState({ loading: false });
			this.setState({ searchLoading: false })

		});
	}


	getCustomersCountByCountry = async (country_codes) => {


		let postdata = { countryCode: country_codes };

		client.post(api.customerCountByCountry, postdata, (error, response) => {

			if (!error) {

				if (!response.error) {

					this.setState({ customerCountByCountry: response.result.tab_data });

				} else {
					toast.error("Internal error occured. Please contact support");
				}
			} else {
				toast.error("Internal error occured. Please contact support");
			}

		});
	}


	moveElement(array) {

		let arrangeCountry = [...array];

		let australia = arrangeCountry.map((e) => e.country_code).indexOf('AU');
		if (australia != -1) {
			arrangeCountry.splice(0, 0, arrangeCountry.splice(australia, 1)[0]);
		}

		let japanIdx = arrangeCountry.map((e) => e.country_code).indexOf('JP');
		if (japanIdx != -1) {
			arrangeCountry.splice(1, 0, arrangeCountry.splice(japanIdx, 1)[0]);
		}

		let thailand = arrangeCountry.map((e) => e.country_code).indexOf('TH');
		if (thailand != -1) {
			arrangeCountry.splice(2, 0, arrangeCountry.splice(thailand, 1)[0]);
		}

		let unitedState = arrangeCountry.map((e) => e.country_code).indexOf('US');
		if (unitedState != -1) {
			arrangeCountry.splice(3, 0, arrangeCountry.splice(unitedState, 1)[0]);
		}

		let india = arrangeCountry.map((e) => e.country_code).indexOf('IN');
		if (india != -1) {
			arrangeCountry.splice(4, 0, arrangeCountry.splice(india, 1)[0]);
		}

		return arrangeCountry;
	}

	fetchCountries = () => {

		let current = this;
		client.post(api.fetch_country, { show_all: true }, async function (error, response) {

			if (!error) {
				if (typeof response.error !== "undefined") {
					try {
						if (!response.error) {

							let getCountryCode = [];
							let arrangeCountry = [...response.result.data];
							arrangeCountry = await current.moveElement(arrangeCountry);
							current.setState({ country: arrangeCountry });

							arrangeCountry.length > 0 && arrangeCountry.map((countryData) => {
								getCountryCode.push(countryData.country_code)
								return countryData;
							});


							current.getCustomersCountByCountry(getCountryCode);
						}
					} catch (err) {
						// console.log(err, "fetch error")
						toast.error("Internal error occured. Please contact support");
					}
				} else {
					toast.error("Internal error occured. Please contact support");
				}
			} else {
				toast.error("Internal error occured. Please contact support");
			}
		});
	}




	getNotify = async (activePage) => {

		client.post(api.get_all_notify_data, {}, (error, response) => {
			if (!error) {
				// alert(JSON.stringify(response));
				if (typeof response.error !== "undefined") {
					try {
						if (!response.error) {
							this.setState({ notify: response.result.data.reverse() });
							this.setState({ tab_count: response.result.tab_data })
						} else {
							setTimeout(() => {
								toast.error(response.message);
							}, 200);
						}
					} catch (e) {
						setTimeout(() => {
							toast.error("Internal error occured. Please contact support");
						}, 200);
					}
				} else {
					setTimeout(() => {
						toast.error("Internal error occured. Please contact support");
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
			// current.setState({ loading: false });
		});
	}

	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	}

	render_date = (add_date) => {
		if (typeof add_date === "undefined") {
			return;
		}

		const convert_date = new Date(add_date);
		const added_date = moment.tz(convert_date, "Australia/Sydney").format("DD/MM/YYYY HH:mm");
		return added_date;
	};

	flagClick = (id) => {
		this.setState((prevState) => {
			return {
				...prevState,
				flagModal: !prevState.flagModal,
				modalId: id,
			};
		});
	};

	blockClick = (id) => {
		this.setState((prevState) => {
			return {
				...prevState,
				blockModal: !prevState.blockModal,
				modalId: id,
			};
		});
	};

	mobileClick = (id) => {
		if (this.state.access_level == 1) {
			toast.error("Not allowed to modify.");
		} else {
			this.setState((prevState) => {
				return {
					...prevState,
					mobileModal: !prevState.mobileModal,
					modalId: id,
				};
			});
		}
	};

	infoClick = (id) => {
		this.setState((prevState) => {
			return {
				...prevState,
				infoModal: !prevState.infoModal,
				modalId: id,
			};
		});
	};

	deleteSelectedButtonClick = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				deleteSelectedModal: !prevState.deleteSelectedModal,
			};
		});
	};

	deleteSelectedClick = (event, id) => {
		if (event.target.checked) {
			this.setState((prevState) => {
				return {
					...prevState,
					selectedIDs: [...prevState.selectedIDs, id],
				};
			});
		} else {
			if (this.state.selectedIDs.includes(id)) {
				const idsToKeep = this.state.selectedIDs.filter((el) => {
					return el != id;
				});
				this.setState((prevState) => {
					return {
						...prevState,
						selectedIDs: idsToKeep,
					};
				});
			} else {
				return;
			}
		}
	};

	flagNoteHandler = (event) => {
		const flagNote = event.target.value;
		this.setState((prevState) => {
			return {
				...prevState,
				flag_note: flagNote,
			};
		});
	};

	toggleFlagModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				flagModal: !prevState.flagModal,
			};
		});
	};

	toggleInfoModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				infoModal: !prevState.infoModal,
			};
		});
	};

	toggleBlockModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				blockModal: !prevState.blockModal,
			};
		});
	};

	renderFlagModal = (id) => {
		const foundCustomer = this.state.customers.find((el) => {
			return el._id == id;
		});
		const style = {
			outline: "none",
			":focus": {
				outlineColor: "black",
			},
			fontSize: "1rem",
		};

		if (!foundCustomer.flag_status) {
			return (
				<Modal isOpen={this.state.flagModal} toggle={this.toggleFlagModal} className="modal-body" centered={true}>
					<ModalBody>
						<form onSubmit={(event) => this.flagSubmitHandler(event, this.state.modalId)} className="needs-validation">
							<h5>Please enter a note to flag customer.</h5>
							<p style={{ color: "red" }}>Note: User will no longer be able to view transaction in their application.</p>
							<label className="form-label" style={{ display: "block" }}>
								Note:
							</label>
							<input
								type="text"
								size="40"
								onChange={this.flagNoteHandler}
								style={style}
								placeholder="Please enter comment for your action"
								required
							></input>
							<Button color="primary" style={{ marginTop: "20px", marginRight: "20px" }} name="flag">
								Update status
							</Button>
							<Button color="default" style={{ marginTop: "20px", marginRight: "20px" }} onClick={this.toggleFlagModal}>
								Cancel
							</Button>
						</form>
					</ModalBody>
				</Modal>
			);
		}
		return (
			<Modal isOpen={this.state.flagModal} toggle={this.toggleFlagModal} className="modal-body" centered={true}>
				<ModalBody>
					<h5>Are you sure you want to unflag customer ?</h5>
					<Button
						color="primary"
						style={{ marginTop: "20px", marginRight: "20px" }}
						name="unflag"
						onClick={(event) => this.flagSubmitHandler(event, this.state.modalId)}
					>
						Yes, please
					</Button>
					<Button color="default" style={{ marginTop: "20px", marginRight: "20px" }} onClick={this.toggleFlagModal}>
						Not Sure
					</Button>
				</ModalBody>
			</Modal>
		);
	};

	renderBlockModal = (id) => {
		const foundCustomer = this.state.customers.find((el) => {
			return el._id == id;
		});
		const style = {
			outline: "none",
			":focus": {
				outlineColor: "black",
			},
			fontSize: "1rem",
			padding: "2px 7px",
		};

		if (foundCustomer.status != 2) {
			return (
				<Modal isOpen={this.state.blockModal} toggle={this.toggleBlockModal} className="modal-body" centered={true}>
					<ModalBody>
						<form onSubmit={(event) => this.blockCustomerHandler(event, this.state.modalId)} className="needs-validation">
							<div style={{ fontSize: "3rem", textAlign: "center" }}>
								<i className="icofont icofont-exclamation-tringle" style={{ color: "red" }}></i>
							</div>
							<h3 style={{ textAlign: "center" }}>Are you sure ?</h3>
							<p style={{ color: "red", textAlign: "center" }}>Note: Once blocked, Customer will not be able to sign in!.</p>
							<label className="form-label" style={{ display: "block" }}>
								Note:
							</label>
							<input
								type="text"
								size="40"
								onChange={this.flagNoteHandler}
								style={style}
								placeholder="Please enter comment for your action"
								required
							></input>
							<Button color="default" style={{ marginTop: "20px", marginRight: "20px" }} onClick={this.toggleBlockModal}>
								Cancel
							</Button>
							<Button color="primary" style={{ marginTop: "20px", marginRight: "20px" }} name="block">
								Yes, Please!
							</Button>
						</form>
					</ModalBody>
				</Modal>
			);
		}
		return (
			<Modal isOpen={this.state.blockModal} toggle={this.toggleBlockModal} className="modal-body" centered={true}>
				<ModalBody>
					<h5>Are you sure you want to unblock customer ?</h5>
					<Button
						color="primary"
						style={{ marginTop: "20px", marginRight: "20px" }}
						name="unblock"
						type="submit"
						onClick={(event) => this.blockCustomerHandler(event, this.state.modalId)}
					>
						Yes, please!{" "}
					</Button>
					<Button color="default" style={{ marginTop: "20px", marginRight: "20px" }} onClick={this.toggleBlockModal}>
						Not Sure
					</Button>
				</ModalBody>
			</Modal>
		);
	};

	renderInfoModal = (id) => {
		const foundCustomer = this.state.customers.find((el) => {
			return el._id == id;
		});

		if (typeof foundCustomer.comment == "undefined") {
			foundCustomer.comment = [];
		}
		let foundCustomerComment = [...foundCustomer.comment].reverse();
		return (
			<Modal isOpen={this.state.infoModal} toggle={this.toggleInfoModal} className="modal-body" centered={true}>
				<h5 style={{ padding: "15px", margin: "0" }}>Comments</h5>

				<ModalBody style={{ paddingTop: "0", paddingBottom: "0", maxHeight: "calc(100vh - 370px)", overflowY: "auto" }}>
					<div className="card-body chat-box" style={{ paddingTop: "0", paddingBottom: "0" }}>
						<div className="chat-right-aside bitcoin-chat">
							<div className="chat">
								<div className="chat-history chat-msg-box custom-scrollbar" style={{ padding: "0", margin: "0" }}>
									<ul style={{ margin: "0" }}>
										{foundCustomerComment.length != 0 ? (
											foundCustomerComment.map((el) => {
												return (
													<li style={{ margin: "0" }}>
														<div
															className="message my-message "
															style={{ marginBottom: "10px", paddingTop: "10px", paddingBottom: "10px" }}
														>
															<p>
																{el.user}
																<span className="message-data-time pull-right">{this.render_date(el.date)}</span>{" "}
															</p>
															{el.type == "normal" ? null : <p style={{ fontSize: "0.9rem" }}>Type : {el.type}</p>}
															{el.note}
														</div>
													</li>
												);
											})
										) : (
											<p>No comment added!</p>
										)}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter style={{ paddingTop: "10px", paddingBottom: "10px" }}>
					<div className="col-lg-12">
						<form className="theme-form">
							<div className="form-group mb-0 col-12">
								<label htmlFor="exampleFormControlTextarea4">Comment:</label>
								<textarea
									className="form-control"
									id="exampleFormControlTextarea4"
									required
									rows="3"
									onChange={this.flagNoteHandler}
								></textarea>
							</div>
							<div style={{ padding: "15px 0 0 15px" }}>
								<Button color="default" onClick={this.toggleInfoModal} style={{ marginRight: "15px" }}>
									Cancel
								</Button>
								<Button
									color="primary"
									type="submit"
									name="comment"
									onClick={(event) => this.flagSubmitHandler(event, this.state.modalId)}
								>
									Submit
								</Button>
							</div>
						</form>
					</div>
				</ModalFooter>
			</Modal>
		);
	};

	blockCustomerHandler = (event, id) => {
		event.preventDefault();
		let postdata = {};
		const foundCustomer = this.state.customers.find((el) => {
			return el._id == id;
		});

		if (event.target.name == "unblock") {
			postdata = {
				customer_id: id,
				status: 1,
				comment: { type: "unblock", note: "No comments", user: this.state.adminUser.email },
			};
		} else {
			postdata = {
				customer_id: id,
				status: 2,
				comment: { type: "block", note: this.state.flag_note, user: this.state.adminUser.email },
			};
		}
		client.put(api.update_customer, postdata, (error, response) => {
			if (!error) {
				if (!response.error) {
					//console.log(response);
					this.refreshPage();
				} else {
					setTimeout(() => {
						toast.error(response.message);
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
		});
	};

	flagSubmitHandler = (event, id) => {
		event.preventDefault();
		let postdata = {};
		const foundCustomer = this.state.customers.find((el) => {
			return el._id == id;
		});

		if (typeof foundCustomer.flag_status === "undefined") {
			foundCustomer.flag_status = false;
		}

		if (event.target.name == "unflag") {
			postdata = {
				customer_id: id,
				flag_status: !foundCustomer.flag_status,
				comment: { type: "unflag", note: "No comments", user: this.state.adminUser.email },
			};
		} else if (event.target.name == "comment") {
			postdata = {
				customer_id: id,
				comment: { type: "normal", note: this.state.flag_note, user: this.state.adminUser.email },
			};
		} else {
			postdata = {
				customer_id: id,
				flag_status: !foundCustomer.flag_status,
				comment: { type: "flag", note: this.state.flag_note, user: this.state.adminUser.email },
			};
		}
		client.put(api.update_customer, postdata, (error, response) => {
			if (!error) {
				if (!response.error) {
					this.refreshPage();
				} else {
					setTimeout(() => {
						toast.error(response.message);
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
		});
	};

	renderDeleteSelectedModal = () => {
		return (
			<Modal
				isOpen={this.state.deleteSelectedModal}
				toggle={this.toggleDeleteSelectedModal}
				className="modal-body"
				centered={true}
			>
				<form className="theme-form" noValidate="" onSubmit={this.deleteSelectedHandler}>
					<ModalBody>
						<h4>Do you really want to delete these Customers ?</h4>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" type="submit">
							Yes, please!
						</Button>
						<Button color="default" onClick={this.toggleDeleteSelectedModal}>
							Not sure
						</Button>
					</ModalFooter>
				</form>
			</Modal>
		);
	};

	deleteSelectedHandler = (event) => {
		event.preventDefault();
		const postData = { selectedIDs: this.state.selectedIDs };
		client.post(api.delete_selected_customer, postData, (error, response) => {
			if (!error) {
				if (!response.error) {
					toast.success(response.message);
					this.refreshPage();
				} else {
					setTimeout(() => {
						toast.error(response.message);
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
		});
	};

	renderMobileModal = () => {
		return (
			<Modal isOpen={this.state.mobileModal} toggle={this.toggleMobileModal} className="modal-body" centered={true}>
				<form className="theme-form" onSubmit={this.handle_send_sms_submit}>
					<ModalHeader>Verify customer</ModalHeader>
					<ModalBody>
						<h6>Are you sure you want to change the status of the user to verified ?</h6>
						<p style={{ color: "red", margin: "0" }}>Note:</p>
						<p style={{ color: "red", margin: "0" }}>i) An sms will be sent with ID & Password.</p>
						<p style={{ color: "red", margin: "0" }}>
							ii)The user will be able to login to app without verifying their mobile number.
						</p>
						<p style={{ color: "red", margin: "0" }}>
							iii) Do follow up with the user to make sure user login to app and create password & pin.
						</p>
					</ModalBody>
					<ModalFooter>
						<Button color="default" onClick={this.toggleMobileModal}>
							Not sure
						</Button>
						<Button color="primary" type="submit">
							Yes, please!
						</Button>
					</ModalFooter>
				</form>
			</Modal>
		);
	};

	handle_send_sms_submit = (e) => {
		e.preventDefault();
		let { modalId } = this.state;
		let postdata = { customer_id: modalId };
		let current = this;
		current.setState({ loading: true });

		if (postdata !== "") {
			//setloadingbtn(true);
			client.post(api.unverCust_sendCredentials, postdata, async function (error, response) {
				if (!error) {
					//alert(JSON.stringify(response));
					//   return false;
					if (typeof response.error !== "undefined") {
						try {
							if (!response.error) {
								toast.success(response.message);
								current.refreshPage();
							} else {
								toast.error(response.message);
							}
						} catch (e) {
							toast.error(e.message);
						}
					} else {
						toast.error("Internal error occured. Please contact support ");
					}
				} else {
					toast.error("Internal error occured. Please contact support ");
				}
				//setloadingbtn(false);
			});
		}
	};

	getStatus = (status) => {
		if (status == 1) {
			return "Success";
		} else if (status == 0) {
			return "Pending";
		} else {
			return "Failed";
		}
	};

	changeISVerified = async (customer_id, is_verified) => {
		if (is_verified == 1) return false;

		let willDelete = await swal({
			title: "Are you sure?",
			text: "Change status to Verified Customer",
			icon: "warning",
			buttons: {
				cancel: true,
				confirm: {
					text: "Yes, Please!",
					closeModal: false,
				},
			},
			dangerMode: true,
		});

		if (willDelete) {
			let postdata = { customer_id: customer_id, is_verified: 1 };
			let current = this;
			let message = "";
			let status = 0;
			current.setState({ loading: true });
			client.put(api.update_customer, postdata, async function (error, response) {
				if (!error) {
					if (!response.error) {
						status = 1;
						message = "Customer Verified succefully";
					} else {
						status = 0;
						message = response.message;
					}
				}

				swal.close();
				swal(message);
				if (status == 1) current.getCustomers(this.state.fetchType, this.state.activePage);
			});
		}
	};

	getComment = (id) => {
		const customer = this.state.customers.find((el) => el._id == id);
		const comment = customer.comment.find((item) => item.type == "block");

		if (comment) {
			return (
				<Fragment>
					<p>{comment.user}</p>
					<p>{comment.note}</p>
				</Fragment>
			);
		} else {
			return;
		}
	};

	renderAllTable = (data_lengh) => {
		let { customers, access_level } = this.state;

		return (
			<Fragment>
				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12 col-xl-12 xl-100" style={{ padding: "0" }}>
							<div className="card">
								{this.state.searchLoading && <BarLoader height={1} width={"100%"} />}
								<div className="card-body" style={{ padding: "1rem" }}>
									<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: '5px' }}>
										<div>
											<form onSubmit={(event) => this.handleSearchSubmit(event)}>
												<div>
													<input
														style={{
															border: "#dfe8f1 solid 1px",
															borderRadius: "3px",
															padding: "10px",
															fontSize: "0.75rem",
															outlineColor: '#dfe8f1'
														}}
														type="text"
														placeholder="Search"
														onChange={this.onSearchChangeHandler}
													/>
												</div>
											</form>
										</div>
										<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
											<select
												className="custom-select"
												style={{ width: "35%", fontSize: "0.63rem", marginRight: "7px" }}
												onChange={this.setPageSize}
												value={this.state.pageSize}
											>
												<option value="15">15</option>
												<option value="25">25</option>
												<option value="50">50</option>
												<option value="75">75</option>
												<option value="100">100</option>
											</select>
											<span style={{ fontSize: "0.63rem" }}>Records per Page</span>
										</div>
									</div>

									<div className="table-responsive">
										<table className="table table-border-horizontal">
											<thead>
												<tr className="" style={{ background: '#f9fafe' }}>
													<th scope="col">#</th>
													<th scope="col" style={{ width: "12%" }}>
														<p>i-Pay ID</p>
													</th>
													<th scope="col" style={{ width: "9%" }}>
														<p style={{ margin: "0", fontSize: "0.75rem" }}>Date</p>
														<p style={{ margin: "0", fontSize: "0.75rem" }}>Time</p>
													</th>
													<th scope="col" style={{ width: "11%" }}>
														Name
													</th>
													<th scope="col" style={{ width: "22%" }}>
														<p style={{ margin: "0", fontSize: "0.75rem" }}>Email / Mobile</p>
													</th>
													<th scope="col" style={{ width: "12%" }}>
														<p style={{ margin: "0", fontSize: "0.75rem" }}>Latest Trans.</p>
														<p style={{ margin: "0", fontSize: "0.75rem" }}>Status</p>
													</th>
													<th scope="col" style={{ width: "8%" }}>
														Balance
													</th>
													{access_level != 1 && (
														<th scope="col" style={{ width: "13%" }}>
															Action
														</th>
													)}
													<th scope="col" style={{ width: "13%" }}>
														Notes
													</th>
												</tr>
											</thead>
											<tbody>
												{customers
													// .slice(
													// 	(this.state.activePage - 1) * this.state.pageSize,
													// 	this.state.activePage * this.state.pageSize
													// )
													.map((item, index) => {
														return (
															<tr className="" key={item._id}>
																<th scope="row">
																	{(this.state.activePage - 1) * this.state.pageSize + index + 1}
																</th>
																<td style={{ width: "12%" }}>
																	<NavLink to={`/customer/wallet/${item._id}`} item={item}>
																		{item.ipay_id}
																		<br />
																		{item.location && item.location.suburb ? `/${item.location.suburb}` : ""}
																	</NavLink>
																</td>
																<td style={{ width: "9%" }}>{this.render_date(item.added_date)}</td>
																<td style={{ width: "11%" }}>{item.name}</td>
																<td style={{ width: "22%" }}>
																	<p style={{ margin: "0", fontSize: "0.75rem" }}>
																		{typeof item.email == "undefined" ? "N.A" : item.email != "" ? item.email : "N.A"}
																	</p>
																	<p style={{ margin: "0", fontSize: "0.75rem" }}>
																		{item.status == 0 ? (
																			<span>
																				{displayMobile(item.mobile)}
																				<span onClick={() => this.mobileClick(item._id)}>
																					<i className="fa fa-thumbs-down" style={{ color: "red", marginLeft: "5px" }}></i>
																				</span>
																			</span>
																		) : item.status == 1 ? (
																			<span>
																				{displayMobile(item.mobile)}
																				<span>
																					<i className="fa fa-thumbs-up" style={{ color: "green", marginLeft: "5px" }}></i>
																				</span>
																			</span>
																		) : (
																			displayMobile(item.mobile)
																		)}
																	</p>
																</td>

																{item.transactions.length > 0 ? (
																	<td style={{ width: "12%" }}>
																		<p style={{ margin: "0", fontSize: "0.75rem" }}>
																			${item.transactions[item.transactions.length - 1].amount}
																		</p>
																		<p style={{ margin: "0", fontSize: "0.75rem" }}>
																			{this.getStatus(item.transactions[item.transactions.length - 1].status) == "Failed" ? (
																				<span style={{ color: "red", fontSize: "0.75rem" }}>
																					{this.getStatus(item.transactions[item.transactions.length - 1].status)}
																				</span>
																			) : (
																				this.getStatus(item.transactions[item.transactions.length - 1].status)
																			)}
																		</p>
																	</td>
																) : (
																	<td style={{ width: "12%" }}>N.A</td>
																)}
																{item.wallet.length != 0 ? (
																	<td style={{ width: "8%" }}>
																		${Number(Math.round(item.wallet[0].wallet_amount + "e2") + "e-2")}
																	</td>
																) : (
																	<td style={{ width: "8%" }}>$0</td>
																)}
																{access_level != 1 && (
																	<td style={{ width: "13%" }}>
																		{/* {access_level == 3 && <span style={{ marginRight: "10px", fontSize: "1rem" }}>
                                  <i style={{ color: "red" }} id="delete" className="fa fa-trash-o" onClick={() => {
                                    this.delete_toggle();
                                    this.set_del_id(item._id);
                                  }}></i> */}
																		{/* <UncontrolledTooltip placement="bottom" target="delete">
                          Delete
                        </UncontrolledTooltip> */}
																		{/* </span>} */}
																		{item.status == 1 || item.status == 2 ? (
																			<span
																				style={{ marginRight: "10px", fontSize: "0.75rem" }}
																				onClick={() => this.blockClick(item._id)}
																			>
																				<i
																					style={{ color: item.status == 2 ? "red" : "black" }}
																					id="block"
																					className="icofont icofont-ui-block"
																				></i>
																				{/* <UncontrolledTooltip placement="bottom" target="block">
                          Block
                        </UncontrolledTooltip> */}
																			</span>
																		) : null}

																		{item.status == 1 || item.status == 2 ? (
																			<span
																				style={{ marginRight: "10px", fontSize: "0.75rem" }}
																				onClick={() => this.flagClick(item._id)}
																			>
																				<i
																					className="fa fa-flag-o"
																					style={{ color: item.flag_status ? "red" : "black" }}
																				></i>
																				{/* <UncontrolledTooltip placement="bottom" target="flag">
                          Flag
                        </UncontrolledTooltip> */}
																			</span>
																		) : null}
																		{/* <NavLink to={{
                        pathname: `/customer/walletTransactions/${item._id}`,
                        search: "?activeTab=2"

                      }} item={item} style={{ marginRight: "10px", fontSize: "1.2em" }}>
                        <img className="img-fluid" id="Wallet" src={wallet_icon} alt={"View Wallet"} /> */}
																		{/* <UncontrolledTooltip placement="bottom" target="Wallet">
                          Wallet
                        </UncontrolledTooltip> */}
																		{/* </NavLink> */}
																		{item.status == 1 || item.status == 2 ? (
																			<span
																				style={{ marginRight: "10px", fontSize: "0.75rem" }}
																				onClick={() => this.infoClick(item._id)}
																			>
																				<i
																					className="fa fa-list-alt"
																					style={
																						Array.isArray(item.comment) && item.comment.length
																							? { color: "green" }
																							: { color: "black" }
																					}
																				></i>
																			</span>
																		) : null}
																		{access_level == 3 && (
																			<span>
																				<input
																					type="checkbox"
																					onClick={(event) => this.deleteSelectedClick(event, item._id)}
																				/>
																			</span>
																		)}
																	</td>
																)}
																<td style={{ width: "13%" }}>
																	{Array.isArray(item.comment) && item.comment.length
																		? item.comment[item.comment.length - 1].note
																		: "N.A"}
																</td>
															</tr>
														);
													})}
											</tbody>
										</table>
										<div class="mt-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
											{this.state.activePage * this.state.pageSize > data_lengh ? (
												<p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>
													Showing {(this.state.activePage - 1) * this.state.pageSize + 1} to {data_lengh} of{" "}
													{data_lengh} entries
												</p>
											) : (
												<p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>
													Showing {(this.state.activePage - 1) * this.state.pageSize + 1} to{" "}
													{this.state.activePage * this.state.pageSize} of {data_lengh} entries
												</p>
											)}
											<PaginationComponent
												totalItems={data_lengh}
												defaultActivePage={this.state.activePage}
												pageSize={this.state.pageSize}
												onSelect={this.handlePageChange}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	};

	renderNotifyTable = (data_lengh) => {
		let { notify, access_level } = this.state;

		return (
			<Fragment>
				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12 col-xl-12 xl-100" style={{ padding: "0" }}>
							<div className="card">
								<div className="card-body" style={{ padding: "1rem" }}>
									<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: '5px' }}>
										<div>
											<form onSubmit={(event) => this.handleSearchSubmit(event)}>
												<div>
													<input
														style={{
															border: "#dfe8f1 solid 1px",
															borderRadius: "3px",
															padding: "10px",
															fontSize: "0.75rem",
															outlineColor: '#dfe8f1'
														}}
														type="text"
														placeholder="Search"
														onChange={this.onSearchChangeHandler}
													/>
												</div>
											</form>
										</div>
										<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
											<select
												className="custom-select"
												style={{ width: "35%", fontSize: "0.63rem", marginRight: "7px" }}
												onChange={this.setPageSize}
												value={this.state.pageSize}
											>
												<option value="15">15</option>
												<option value="25">25</option>
												<option value="50">50</option>
												<option value="75">75</option>
												<option value="100">100</option>
											</select>
											<span style={{ fontSize: "0.63rem" }}>Records per Page</span>
										</div>
									</div>

									<div className="table-responsive">
										<table className="table table-border-horizontal">
											<thead>
												<tr className="" style={{ background: '#f9fafe' }}>
													<th scope="col" style={{ width: '5%' }}>#</th>
													<th scope="col" style={{ width: "12%" }}>
														<p>Email</p>
													</th>
													<th scope="col" style={{ width: "11%" }}>
														<p>Location</p>
													</th>
													<th scope="col" style={{ width: "13%" }}>
														<p>Created Date</p>
													</th>
												</tr>
											</thead>
											<tbody>
												{notify
													// .slice(
													// 	(this.state.activePage - 1) * this.state.pageSize,
													// 	this.state.activePage * this.state.pageSize
													// )
													?.map((item, index) => {
														return (
															<tr className="" key={item._id}>
																<td>{index}</td>
																<td>{item.email}</td>
																<td>{item.location}</td>
																<td>{item.createdAt}</td>
															</tr>
														);
													})}
											</tbody>
										</table>
										<div class="mt-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
											{this.state.activePage * this.state.pageSize > data_lengh ? (
												<p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>
													Showing {(this.state.activePage - 1) * this.state.pageSize + 1} to {data_lengh} of{" "}
													{data_lengh} entries
												</p>
											) : (
												<p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>
													Showing {(this.state.activePage - 1) * this.state.pageSize + 1} to{" "}
													{this.state.activePage * this.state.pageSize} of {data_lengh} entries
												</p>
											)}
											<PaginationComponent
												totalItems={data_lengh}
												defaultActivePage={this.state.activePage}
												pageSize={this.state.pageSize}
												onSelect={this.handlePageChange}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	};

	// onSearchChangeHandler = (event) => {
	// 	//this.setState({ searchValue: event.target.value })
	// 	this.setState({ searchValue: event.target.value });
	// 	var text = event.target.value;
	// 	let data = this.state.customers.filter((el) => el.status == 1);

	// 	let filterData = data.filter((item) => {
	// 		//console.log("item.name", item.name, "item.email", item.email, "item.bussines name", item.business_name)
	// 		if (/^[a-zA-Z ]/.test(text)) {
	// 			if (item.name && item.name.toLowerCase().includes(text.toLowerCase())) {
	// 				return item;
	// 			} else if (item.email && item.email.toLowerCase().includes(text.toLowerCase())) {
	// 				return item;
	// 			}
	// 		} else {
	// 			if (item.ipay_id.includes(text) || item.mobile?.includes(text)) if (item !== undefined) return item;
	// 		}
	// 	});

	// 	this.setState({ searchedData: filterData });  
	// };

	debounceFn = _debounce(() => {
		this.getCustomers(this.state.fetchType, this.state.activePage, true)
	}, 500)

	onSearchChangeHandler = (event) => {
		if (event.target.value !== '') {
			this.setState(
				{
					searchValue: event.target.value,
				},
				() => {
					// alert(type);
					this.debounceFn();
				}
			);
		} else {
			this.setState(
				{
					searchValue: null,
				},
				() => {
					// alert(type);
					this.getCustomers(this.state.fetchType, this.state.activePage, true);
				}
			);
		}

	};

	rendertable = (status, data_lengh, search_text = "") => {
		const { access_level, customers } = this.state;

		return (
			<Fragment>
				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12 col-xl-12 xl-100" style={{ padding: "0" }}>
							<div className="card">
								{this.state.searchLoading && <BarLoader height={1} width={"100%"} />}
								<div className="card-body" style={{ padding: "1rem" }}>
									<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: '5px' }}>
										<div>
											<form onSubmit={(event) => this.handleSearchSubmit(event)}>
												<div>
													<input
														style={{
															border: "#dfe8f1 solid 1px",
															borderRadius: "3px",
															padding: "10px",
															fontSize: "0.75rem",
															outlineColor: '#dfe8f1'
														}}
														type="text"
														placeholder="Search"
														value={this.state.searchValue}
														onChange={this.onSearchChangeHandler}
													/>
												</div>
											</form>
										</div>
										<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
											<select
												className="custom-select"
												style={{ width: "35%", fontSize: "0.63rem", marginRight: "10px" }}
												onChange={this.setPageSize}
												value={this.state.pageSize}
											>
												<option value="15">15</option>
												<option value="25">25</option>
												<option value="50">50</option>
												<option value="75">75</option>
												<option value="100">100</option>
											</select>
											<span style={{ fontSize: "0.63rem" }}>Records per Page</span>
										</div>
									</div>

									<div className="table-responsive">
										<table className="table table-border-horizontal">
											<thead>
												<tr style={{ background: '#f9fafe' }}>
													<th scope="col">#</th>
													<th scope="col" style={{ width: "13%" }}>
														<p>i-Pay ID</p>
													</th>
													<th scope="col" style={{ width: "9%" }}>
														<p style={{ margin: "0", fontSize: "0.75rem" }}>Date</p>
														<p style={{ margin: "0", fontSize: "0.75rem" }}>Time</p>
													</th>
													<th scope="col">Name</th>
													<th scope="col">
														<p style={{ margin: "0", fontSize: "0.75rem" }}>Email / Mobile</p>
													</th>
													{status == 2 && (
														<th scope="col">
															<p>Blocked by</p>
															<p>Reason</p>
														</th>
													)}

													<th scope="col" style={{ width: "11%" }}>
														<p style={{ margin: "0", fontSize: "0.75rem" }}>Latest Trans.</p>
														<p style={{ margin: "0", fontSize: "0.75rem" }}>Status</p>
													</th>
													<th scope="col">Balance</th>
													{access_level != 1 && (
														<th scope="col" style={{ width: "12%" }}>
															Action
														</th>
													)}
													<th scope="col">Notes</th>
												</tr>
											</thead>
											<tbody>
												{customers
													// .slice(
													// 	(this.state.activePage - 1) * this.state.pageSize,
													// 	this.state.activePage * this.state.pageSize
													// )
													.map((item, index) => {
														return (
															<tr key={item._id}>
																<th scope=" row">{(this.state.activePage - 1) * this.state.pageSize + index + 1}</th>
																<td style={{ width: "13%" }}>
																	<NavLink to={`/customer/wallet/${item._id}`} item={item}>
																		{item.ipay_id}
																		<br />
																		{item.location && item.location.suburb ? `/${item.location.suburb}` : ""}
																	</NavLink>
																</td>
																<td style={{ width: "9%" }}>{this.render_date(item.added_date)}</td>
																<td s>{item.name}</td>
																<td>
																	<p style={{ margin: "0", fontSize: "0.75rem" }}>
																		{typeof item.email == "undefined" ? "N.A" : item.email != "" ? item.email : "N.A"}
																	</p>
																	<p style={{ margin: "0", fontSize: "0.75rem" }}>
																		{item.status == 0 ? (
																			<span>
																				{displayMobile(item.mobile)}
																				<span onClick={() => this.mobileClick(item._id)}>
																					<i className="fa fa-thumbs-down" style={{ color: "red", marginLeft: "5px" }}></i>
																				</span>
																			</span>
																		) : item.status == 1 ? (
																			<span>
																				{displayMobile(item.mobile)}
																				<span>
																					<i className="fa fa-thumbs-up" style={{ color: "green", marginLeft: "5px" }}></i>
																				</span>
																			</span>
																		) : (
																			displayMobile(item.mobile)
																		)}
																	</p>
																</td>
																{status == 2 && (
																	<td style={{ width: "11%" }}>
																		{typeof item.comment == "undefined" ? "" : this.getComment(item._id)}
																	</td>
																)}
																{item.transactions.length > 0 ? (
																	<td>
																		<p style={{ margin: "0", fontSize: "0.75rem" }}>
																			${item.transactions[item.transactions.length - 1].amount}
																		</p>
																		<p style={{ margin: "0", fontSize: "0.75rem" }}>
																			{this.getStatus(item.transactions[item.transactions.length - 1].status) == "Failed" ? (
																				<span style={{ color: "red", fontSize: "0.75rem" }}>
																					{this.getStatus(item.transactions[item.transactions.length - 1].status)}
																				</span>
																			) : (
																				this.getStatus(item.transactions[item.transactions.length - 1].status)
																			)}
																		</p>
																	</td>
																) : (
																	<td>N.A</td>
																)}

																{item.wallet.length != 0 ? (
																	<td>${Number(Math.round(item.wallet[0].wallet_amount + "e2") + "e-2")}</td>
																) : (
																	<td>$0</td>
																)}

																{access_level != 1 && (
																	<td style={{ width: "12%" }}>
																		{/* {access_level == 3 && <span style={{ marginRight: "10px", fontSize: "1rem" }}>
                                  <i className="fa fa-trash-o" style={{ color: "red" }} onClick={() => {
                                    this.delete_toggle();
                                    this.set_del_id(item._id);
                                  }} ></i> */}
																		{/* <UncontrolledTooltip placement="bottom" target="delete">
                          Delete
                 </UncontrolledTooltip> */}
																		{/* </span>} */}
																		{status == 1 || status == 2 || status == 3 ? (
																			<span
																				style={{ marginRight: "10px", fontSize: "0.75rem" }}
																				onClick={() => this.blockClick(item._id)}
																			>
																				<i
																					style={{ color: item.status == 2 ? "red" : "black" }}
																					id="block"
																					className="icofont icofont-ui-block"
																				></i>
																				{/* <UncontrolledTooltip placement="bottom" target="block">
                          Block
                        </UncontrolledTooltip> */}
																			</span>
																		) : null}

																		{status == 1 || status == 2 || status == 3 ? (
																			<span
																				style={{ marginRight: "10px", fontSize: "0.75rem" }}
																				onClick={() => this.flagClick(item._id)}
																			>
																				<i
																					className="fa fa-flag-o"
																					style={{ color: item.flag_status ? "red" : "black" }}
																				></i>

																				{/* <UncontrolledTooltip placement="bottom" target="flag">
                          Flag
                        </UncontrolledTooltip> */}
																			</span>
																		) : null}
																		{/* <NavLink to={{
                        pathname: `/customer/walletTransactions/${item._id}`,
                        search: "?activeTab=2"

                      }} item={item} style={{ marginRight: "10px", fontSize: "1rem" }}>
                        <img className="img-fluid" id="Wallet" src={wallet_icon} alt={"View Wallet"} /> */}
																		{/* <UncontrolledTooltip placement="bottom" target="Wallet">
                          Wallet
                 </UncontrolledTooltip> */}
																		{/* </NavLink> */}
																		{status == 1 || status == 2 || status == 3 ? (
																			<span
																				style={{ marginRight: "7px", fontSize: "0.75rem" }}
																				onClick={() => this.infoClick(item._id)}
																			>
																				{Array.isArray(item.comment) && item.comment.length ? (
																					<i className="fa fa-list-alt" style={{ color: "green" }}>
																						{" "}
																					</i>
																				) : (
																					<i className="fa fa-list-alt" style={{ color: "black" }}>
																						{" "}
																					</i>
																				)}
																			</span>
																		) : null}

																		{status == 1 && (
																			<img
																				onClick={() => this.changeISVerified(item._id, item.is_verified)}
																				className="img-fluid"
																				style={{
																					width: "15px",
																					cursor: "pointer",
																					marginRight: "7px",
																					boxShadow: item.is_verified == 2 ? "0px 0px 3px 3px #f4e450" : null,
																					boxShadow: item.is_verified == 2 ? "0px 0px 3px 3px #f4e450" : null,
																				}}
																				src={
																					item.is_verified == 1
																						? imgverified
																						: item.is_verified == 2
																							? imgverificationSent
																							: imgunverified
																				}
																				alt={item.is_verified == 1 ? "Verified " : "Uverified "}
																			/>
																		)}
																		{access_level == 3 && (
																			<span>
																				<input
																					type="checkbox"
																					onClick={(event) => this.deleteSelectedClick(event, item._id)}
																				/>
																			</span>
																		)}
																	</td>
																)}
																<td>
																	{Array.isArray(item.comment) && item.comment.length
																		? item.comment[item.comment.length - 1].note
																		: "N.A"}
																</td>
															</tr>
														);
													})}
											</tbody>
										</table>
										<div className="mt-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
											{this.state.activePage * this.state.pageSize > data_lengh ? (
												<p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>
													Showing {(this.state.activePage - 1) * this.state.pageSize + 1} to {data_lengh} of{" "}
													{data_lengh} entries
												</p>
											) : (
												<p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>
													Showing {(this.state.activePage - 1) * this.state.pageSize + 1} to{" "}
													{this.state.activePage * this.state.pageSize} of {data_lengh} entries
												</p>
											)}
											<PaginationComponent
												totalItems={data_lengh}
												pageSize={this.state.pageSize}
												defaultActivePage={this.state.activePage}
												onSelect={this.handlePageChange}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	};

	handleSearchSubmit = (event) => {
		event.preventDefault();
		this.getCustomers(this.state.fetchType, this.state.activePage, true);
		// const { searchValue, tabIndex } = this.state;
		// let customers = null;

		// if (searchValue == null || searchValue == "") {
		// 	this.setState({ searchedData: null });
		// } else {
		// 	const filteredCustomers = [];
		// 	if (tabIndex == 4) {
		// 		customers = this.state.customers;
		// 	} else if (tabIndex == 0) {
		// 		customers = this.state.customers.filter((el) => {
		// 			return el.status == 1;
		// 		});
		// 	} else if (tabIndex == 1) {
		// 		customers = this.state.customers.filter((el) => {
		// 			return el.status == 0;
		// 		});
		// 	} else if (tabIndex == 2) {
		// 		customers = this.state.customers.filter((el) => {
		// 			return el.status == 2;
		// 		});
		// 	} else if (tabIndex == 3) {
		// 		customers = this.state.customers.filter((el) => {
		// 			return el.flag_status == true;
		// 		});
		// 	}

		// 	customers.filter((item) => {
		// 		if (
		// 			typeof item.ipay_id !== "undefined" &&
		// 			item.added_date != "undefined" &&
		// 			typeof item.name !== "undefined" &&
		// 			typeof item.mobile !== "undefined" &&
		// 			typeof item.email !== "undefined" &&
		// 			typeof item.wallet !== "undefined"
		// 		) {
		// 			if (
		// 				item.ipay_id.toLowerCase().includes(searchValue.toLowerCase()) ||
		// 				item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
		// 				item.mobile.toLowerCase().includes(searchValue.toLowerCase()) ||
		// 				this.render_date(item.added_date).toLowerCase().includes(searchValue.toLowerCase()) ||
		// 				item.email.toLowerCase().includes(searchValue.toLowerCase()) ||
		// 				(item.wallet.length != 0 &&
		// 					item.wallet[0].wallet_amount.toString().toLowerCase().includes(searchValue.toLowerCase()))
		// 			) {
		// 				filteredCustomers.push(item);
		// 			}
		// 		}
		// 	});

		// 	this.setState({ searchedData: filteredCustomers });
		// }
		// this.getCustomers(this.state.fetchType, 1);
	};

	toggleExportModal = () => {
		this.setState((prevState) => {
			return {
				exportModalClick: !prevState.exportModalClick,
				createCsv: false,
				endDate: null,
				startDate: null,
			};
		});
	};

	onClickExportButton = () => {
		this.setState((prevState) => {
			return {
				exportModalClick: !prevState.exportModalClick,
			};
		});
	};

	onExportSubmit = (event) => {
		event.preventDefault();
		const { endDate, startDate } = this.state;
		const filteredCustomers = this.state.customers.filter((el) => {
			if (!endDate) {
				return (
					moment.tz(el.added_date, "Australia/Sydney").toDate() >=
					moment.tz(startDate, "Australia/Sydney").startOf("day").toDate() &&
					moment.tz(el.added_date, "Australia/Sydney").toDate() <=
					moment.tz(startDate, "Australia/Sydney").endOf("day").toDate()
				);
			} else {
				return (
					moment.tz(el.added_date, "Australia/Sydney").toDate() >=
					moment.tz(startDate, "Australia/Sydney").startOf("day").toDate() &&
					moment.tz(el.added_date, "Australia/Sydney").toDate() <=
					moment.tz(endDate, "Australia/Sydney").endOf("day").toDate()
				);
			}
		});

		//console.log(filteredCustomers);
		const data = filteredCustomers.map((item) => {
			return {
				date: this.render_date(item.added_date),
				ipay_id: item.ipay_id,
				name: item.name,
				email: item.email,
				mobile: item.mobile,
				balance: item.wallet.length != 0 ? `$${Number(Math.round(item.wallet[0].wallet_amount + "e2") + "e-2")} ` : "$0",
			};
		});

		const headers = [
			{ label: "Date / Time", key: "date" },
			{ label: "i-Pay ID", key: "ipay_id" },
			{ label: "Name", key: "name" },
			{ label: "Email", key: "email" },
			{ label: "Mobile", key: "mobile" },
			{ label: "Balance", key: "balance" },
		];

		this.setState({ createCsv: true, csvHeader: headers, csvData: data });
	};

	onChangeExportFilter = (event) => {
		this.setState({ exportModalOption: event.target.value });
		if (event.target.name == "range") {
			this.setState({ endDate: null });
		}
	};

	renderExportModal = () => {
		const { startDate, endDate, exportModalOption } = this.state;
		return (
			<Modal isOpen={this.state.exportModalClick} toggle={this.toggleExportModal} className="modal-body">
				<form className="theme-form" onSubmit={this.onExportSubmit}>
					<ModalHeader>Export Data</ModalHeader>
					<ModalBody>
						<div className="form-group col-8 p-r-0">
							<select className="form-control" name="date" onChange={this.onChangeExportFilter}>
								<option value="select">Select filter</option>
								<option value="single">Select a date</option>
								<option value="range">Select date range</option>
							</select>
						</div>
						{exportModalOption == "single" && (
							<DatePicker selected={startDate} onChange={(date) => this.setState({ startDate: date })} />
						)}
						{exportModalOption == "range" && (
							<Fragment>
								<DatePicker
									className="date-picker"
									selected={startDate}
									onChange={(date) => this.setState({ startDate: date })}
									dateFormat="dd/MM/yyyy"
									placeholderText="Select start date"
									maxDate={new Date()}
								//selectsStart
								//startDate={startDate}
								//endDate={endDate}
								/>
								<DatePicker
									selected={endDate}
									className="date-picker"
									onChange={(date) => this.setState({ endDate: date })}
									dateFormat="dd/MM/yyyy"
									placeholderText="Select end date"
									maxDate={new Date()}
									minDate={startDate}

								//selectsEnd
								//startDate={startDate}
								//endDate={endDate}
								//minDate={startDate}
								/>
							</Fragment>
						)}
					</ModalBody>
					<ModalFooter>
						{
							<Fragment>
								<Button color="default" onClick={this.onClickExportButton}>
									Cancel
								</Button>
								<Button color="primary" type="submit">
									Export
								</Button>
							</Fragment>
						}
					</ModalFooter>
				</form>
			</Modal>
		);
	};

	render() {
		let {
			customers,
			customersWallet,
			access_level,
			tabIndex,
			csvData,
			csvHeader,
			tab_count,
			fetchType,
			country,
			countryTabIndex,
			defaultCountryCode,
			customerCountByCountry,

		} = this.state;
		// console.log(this.state.customers);
		// console.log(this.state.tabIndex, "tabindex");
		// console.log(tabIndex);
		// console.log(csvData);
		// console.log(csvHeader);
		// console.log(tab_count)
		let data_lengh = 0;

		if (this.state.data_count_search !== 0 && this.state.searchValue) data_lengh = this.state.data_count_search
		else if (fetchType == 'verified') data_lengh = tab_count.veriCount;
		else if (fetchType == 'Unverified') data_lengh = tab_count.unveriCount;
		else if (fetchType == 'Blocked') data_lengh = tab_count.blockedCount;
		else if (fetchType == 'pending_verification') data_lengh = tab_count.pendingveriCount;
		else if (fetchType == 'Flagged') data_lengh = tab_count.flaggedCount;
		else if (fetchType == 'All') data_lengh = tab_count.all;
		else if (fetchType == 'Notify') data_lengh = tab_count.notify;

		return (
			<Fragment>
				{this.state.modalId ? this.renderFlagModal(this.state.modalId) : null}
				{this.state.modalId ? this.renderInfoModal(this.state.modalId) : null}
				{this.state.modalId ? this.renderBlockModal(this.state.modalId) : null}
				{this.state.modalId ? this.renderMobileModal() : null}
				{this.renderDeleteSelectedModal()}
				{this.renderExportModal()}
				<CSVLink data={this.state.csvData} headers={this.state.csvHeader} id="csv" style={{ display: "none" }}>
					Download me
				</CSVLink>
				<div className="row">
					<div className="col-lg-7 col-sm-12" style={{ fontSize: "12px" }}>
						<Breadcrumb title={"I-Pay customers"} parent={"Home"} />
					</div>
					<div className="col-lg-5 col-sm-12 ">
						<div
							className="btn-group pull-right"
							style={{ paddingTop: "8px", marginBottom: "0rem", marginLeft: "0.4rem", marginRight: "0.6rem" }}
							role="group"
							aria-label="Basic example"
						>
							{access_level == 3 && (
								<button
									className="btnexport custBtnAdmin"
									onClick={this.toggleExportModal}
								>
									<i style={{ color: "#327ebc", margin: "0 7px 0 0" }} className="icofont icofont-download-alt"></i>Export
								</button>
							)}
							{access_level == 3 && (
								<button
									className="btn custBtnAdmin"

									onClick={this.deleteSelectedButtonClick}
									disabled={this.state.selectedIDs.length == 0 ? "disabled" : null}
								>
									{this.state.selectedIDs.length == 0 ? (
										<i
											style={{ color: "#327ebc", fontSize: "16px", margin: "0 7px 0 0" }}
											id="delete"
											title="delete"
											className="fa fa-trash-o"
										></i>
									) : (
										<i
											style={{ color: "#327ebc", fontSize: "16px", margin: "0 7px 0 0" }}
											id="delete"
											title="delete"
											className="fa fa-trash-o"
										></i>
									)}
									Delete selected
								</button>
							)}
						</div>
					</div>
				</div>

				<div className="container-fluid">
					<div className="row theme-tab">

						{
							country.length > 0 && <Tabs
								className="col-sm-12 merchant_req"
								// defaultIndex={this.state.countryTabIndex}
								style={{
									backgroundColor: "white",
									borderRadius: "3px",
									border: "1px solid rgb(223, 232, 241)",
									borderBottom: "none",
									marginBottom: "0px",
								}}
								onSelect={(index) => { this.setState({ countryTabIndex: index }) }}
							>

								<TabList className="tabs tab-title">
									<div
										style={{ display: "flex", alignItems: "center", padding: "10px 15px" }}
										onClick={() => this.changeCountryTab('All')}
										className={defaultCountryCode == "All" ? 'selectedTabStyle' : 'tabStyle'}
									>
										All {`(${customerCountByCountry.find((customer) => customer.code == 'ALL')?.data})`}
									</div>
									{
										country.length > 0 && country.slice(0, 5).map((countryData) => {

											let customerCount = customerCountByCountry.find((customer) => customer.code == countryData.country_code);

											return (
												<>
													<div
														className={countryData.country_code == defaultCountryCode ? 'selectedTabStyle' : 'tabStyle'}
														style={{ display: "flex", alignItems: "center", padding: "10px 15px" }}
														onClick={() => this.changeCountryTab(countryData.country_code)}
													>
														{countryData.country_name} {customerCount ? `(${customerCount.data})` : '(0)'}
													</div>
												</>
											)
										})
									}

									<Dropdown style={{ margin: "auto 0px" }}>
										<Dropdown.Toggle
											id=""
											className={this.state.countryTabIndex == 6 ? 'selectDropDownBtnStyle' : 'dropDownBtnStyle'}
											style={{ color: "#000" }}
										// onClick={() => this.setState({ countryTabIndex: 5 })}
										>
											Others
										</Dropdown.Toggle>

										<Dropdown.Menu>
											{
												country.length > 5 && country.slice(5, country.length).map((countryData) => {

													let customerCount = customerCountByCountry.find((customer) => customer.code == countryData.country_code);

													return (
														<>
															<Dropdown.Item className={countryData.country_code == defaultCountryCode ? 'selectedDropDownStyle' : "dropDownStyle"} onClick={() => this.changeCountryTab(countryData.country_code)}>
																{countryData.country_name} {customerCount ? `(${customerCount.data})` : '(0)'}
															</Dropdown.Item>
														</>
													)
												})
											}
										</Dropdown.Menu>
									</Dropdown>
								</TabList>
							</Tabs>
						}

						<Tabs
							className="col-sm-12"
							defaultIndex={this.state.defaultIndexNo}
							onSelect={(index) => this.setState({ tabIndex: index })}
							style={{
								backgroundColor: "white",
								borderRadius: "3px",
								border: '1px solid #dfe8f1',
								marginBottom: "0px",
							}}
						>
							<TabList className="tabs tab-title">
								<Tab style={{ display: "flex", alignItems: "center" }}
									onClick={() => this.changeTab("All")}
								>
									{/* <i className="icofont icofont-business-man-alt-1" style={{ fontSize: "0.9rem", marginRight: "8px" }}></i> */}
									All ({tab_count.all})
								</Tab>

								<Tab style={{ display: "flex", alignItems: "center" }}
									onClick={() => this.changeTab("verified")}
								>
									{/* <i className="icofont icofont-business-man-alt-1" style={{ fontSize: "0.8rem", marginRight: "8px" }}></i> */}
									Verified ({tab_count.veriCount})
								</Tab>

								<Tab style={{ display: "flex", alignItems: "center" }}
									onClick={() => this.changeTab("Unverified")}
								>
									{/* <i className="fa fa-question-circle" style={{ fontSize: "0.9rem", marginRight: "8px" }}></i> */}
									Unverified ({tab_count.unveriCount})
								</Tab>

								<Tab style={{ display: "flex", alignItems: "center" }}
									onClick={() => this.changeTab("Blocked")}
								>
									{/* <i className="fa fa-ban" style={{ fontSize: "0.8rem", marginRight: "8px" }}></i> */}
									Blocked ({tab_count.blockedCount})
								</Tab>

								<Tab style={{ display: "flex", alignItems: "center" }}
									onClick={() => this.changeTab("Flagged")}
								>
									{/* <i className="fa fa-flag" style={{ fontSize: "0.8rem", marginRight: "8px" }}></i> */}
									Flagged({tab_count.flaggedCount})
								</Tab>

								<Tab style={{ display: "flex", alignItems: "center" }}
									onClick={() => this.changeTab("pending_verification")}
								>
									{/* <i className="fa fa-clock-o" style={{ fontSize: "0.8rem", marginRight: "8px" }}></i> */}
									Doc pending verification({tab_count.pendingveriCount})
								</Tab>

								{/* <Tab style={{ display: "flex", alignItems: "center" }}
									onClick={() => this.changeTab("notify")}
								>
									<i className="fa fa-bell" style={{ fontSize: "0.8rem", marginRight: "8px" }}></i>
									Notify ({tab_count.notify})
								</Tab> */}
							</TabList>

							{customers ? (
								<div className="tab-content-cls">
									<TabPanel>{this.renderAllTable(data_lengh)}</TabPanel>

									<TabPanel>{this.rendertable(1, data_lengh)}</TabPanel>

									<TabPanel>{this.rendertable(0, data_lengh)}</TabPanel>

									<TabPanel>{this.rendertable(2, data_lengh)}</TabPanel>

									<TabPanel>{this.rendertable(3, data_lengh)}</TabPanel>

									<TabPanel>
										<CustomerDocPending />
									</TabPanel>

									{/* <TabPanel>
										{this.renderNotifyTable(data_lengh)}
									</TabPanel> */}
								</div>
							) : (
								<div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
									<div className="loader" style={{ display: "flex", alignItems: "center" }}>
										<DisappearedLoading size="medium" color="#514F4E" />
									</div>
								</div>
							)}
						</Tabs>
					</div>
				</div>
				{/* <div className="container-fluid">
          <div className="row">
            {
              <div className="col-sm-12 col-xl-12 xl-100">
                <div className="card">
                  <div className="card-body" style={{ padding: "0.9rem" }}>
                    <Nav tabs className="nav-pills nav-primary" >
                      <NavItem className="nav nav-tabs" id="myTab" role="tablist" style={{ padding: "8px 5px", marginLeft: "10px" }}>
                        <NavLinkTab className={this.state.activeTab == '2' ? 'active nav-link' : ''} style={{ padding: "8px 10px" }} onClick={() => { this.toggle('2') }} value='2'>
                          <i className="icofont icofont-business-man-alt-1" style={{ fontSize: "1.2rem" }}></i>Verified ({customers ? customers.filter(function (element) {
                            return element.status === 1
                          }).length : 0})
                        </NavLinkTab>
                      </NavItem>
                      <NavItem className="nav nav-tabs" id="myTab" role="tablist" style={{ padding: "8px 5px" }}>
                        <NavLinkTab className={this.state.activeTab == '3' ? 'active nav-link' : ''} style={{ padding: "8px 10px" }} onClick={() => { this.toggle('3') }} value='3'>
                          <i className="fa fa-mobile-phone" style={{ fontSize: "1.3rem" }}></i> Unverified ({customers ? customers.filter(function (element) {
                            return element.status === 0;
                          }).length : 0})
                        </NavLinkTab>

                      </NavItem>
                      <NavItem className="nav nav-tabs" id="myTab" role="tablist" style={{ padding: "8px 5px" }}>
                        <NavLinkTab className={this.state.activeTab == '4' ? 'active nav-link' : ''} style={{ padding: "8px 10px" }} onClick={() => { this.toggle('4') }} value='4'>
                          <i className="fa fa-ban" style={{ fontSize: "1.2rem" }}></i>Blocked ({customers ? customers.filter(function (element) {
                            return element.status === 2;
                          }).length : 0})
                        </NavLinkTab>
                      </NavItem>
                      <NavItem className="nav nav-tabs" id="myTab" role="tablist" style={{ padding: "8px 5px" }}>
                        <NavLinkTab className={this.state.activeTab == '5' ? 'active nav-link' : ''} style={{ padding: "8px 10px" }} onClick={() => { this.toggle('5') }} value='5'>
                          <i className="fa fa-flag" style={{ fontSize: "1.2rem" }}></i>Flagged ({customers ? customers.filter(function (element) {
                            return element.flag_status == true;
                          }).length : 0})
                        </NavLinkTab>
                      </NavItem>
                      <NavItem className="nav nav-tabs" id="myTab" role="tablist" style={{ padding: "8px 5px" }}>
                        <NavLinkTab className={this.state.activeTab == '1' ? 'active nav-link' : ''} onClick={() => { this.toggle('1') }} value='1'>
                          <i className="fa fa-check-square" style={{ fontSize: "1.2rem" }}></i>All ({customers ? customers.filter(function (element) {
                            return element.status !== "null"
                          }).length : 0})
                                      </NavLinkTab>
                      </NavItem>

                    </Nav>


                    {customers ?
                      <TabContent activeTab={this.state.activeTab} >

                        <TabPane tabId="1">
                          {this.renderAllTable()}
                        </TabPane>


                        <TabPane tabId="2">
                          {this.rendertable(1)}
                        </TabPane>

                        <TabPane tabId="3">
                          {this.rendertable(0)}
                        </TabPane>

                        <TabPane tabId="4">
                          {this.rendertable(2)}
                        </TabPane>
                        <TabPane tabId="5">
                          {this.rendertable(3)}
                        </TabPane>

                      </TabContent>
                      : <div className="loader-box" >
                        <div className="loader" style={{ margin: "auto auto" }}>
                          <div className="line bg-primary"></div>
                          <div className="line bg-primary"></div>
                          <div className="line bg-primary"></div>
                          <div className="line bg-primary"></div>
                        </div>
                      </div>}


                  </div>


                </div>
              </div>
            }


          </div>
        </div > */}

				<Modal isOpen={this.state.delete_modal} toggle={this.state.delete_toggle} className="modal-body" centered={true}>
					<form className="theme-form" noValidate="">
						<ModalHeader toggle={this.state.delete_toggle}>Delete Customer ?</ModalHeader>
						<ModalBody>
							<h4>Do you really want to delete this Customer ?</h4>
						</ModalBody>
						<ModalFooter>
							<Button
								color="primary"
								type="submit"
								onClick={(e) => {
									this.handleDeleteSubmit(e);
								}}
							>
								Yes
							</Button>
							<Button
								color="default"
								onClick={() => {
									this.delete_toggle();
								}}
							>
								No
							</Button>
						</ModalFooter>
					</form>
				</Modal>
			</Fragment >
		);
	}
}

export default BorderTable;
