import React, { useEffect, Fragment, useState } from "react";
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormInput, FormGroup, Col, Row, Input, CustomInput } from 'reactstrap';
import AddImageModal from './AddImageModal';
import EditHelpTopicModal from './EditHelpTopicModal';
import { Toast } from "react-bootstrap";
import useForm from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { DisappearedLoading } from "react-loadingg";
import { Link, useHistory } from "react-router-dom";
import Dropzone from "../../components/dropzone/DropZoneFieldHelp";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index"
import ReactDragListView from "react-drag-listview";
import { Link as NavLink } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Lightbox from "react-image-lightbox";

const ImageGallery = (props) => {

    let [loading, setloading] = useState(true);
    let [loadingbtn, setloadingbtn] = useState(false);
    let [editformOpen, seteditformOpen] = useState(false);
    let [editmodalOpen, seteditmodalOpen] = useState(false);
    let [addmodalOpen, setaddmodalOpen] = useState(false);
    let [editcatmodalOpen, seteditcatmodalOpen] = useState(false);
    let [statusDetails, setStatusDetails] = useState(null);
    let [helpTopic, sethelpTopic] = useState([]);
    let history = useHistory();
    let [inputList, setInputList] = useState([]);
    let [inputCat, setInputCat] = useState('');
    let [inputDesc, setinputDesc] = useState('');
    let [editItem, seteditItem] = useState(null);
    let [deleteItem, setdeleteItem] = useState(null);
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState(false);
    const [redirectData, setRedirectData] = useState(null);
    const [deleteModal, setdeleteModal] = useState(false);
    const [isOpen, setisOpen] = useState(false);
    const [photoIndex, setphotoIndex] = useState(0);
    const [folderName, setfolderName] = useState(props.match.params.folder_name);

  const handleCatDelete = (e) => {
    e.preventDefault();
    setloadingbtn(true);

    client.post(api.deleteimg, { folder: folderName, image_data: deleteItem }, async function (error, response) {
      if (!error) {
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              toast.success("Deleted Successfully");
              toggleDeleteModal();
              fetchData();
            } else {
              toast.error(response.message);
            }
          } catch (e) {
            toast.error("Internal error occured. Please contact support 2");
          }
        } else {
          toast.error("Internal error occured. Please contact support 2");
        }
      } else {
        toast.error("Internal error occured. Please contact support 3");
      }
      setloadingbtn(false);
    });

  }

  async function fetchData() {
    client.post(api.getImages, { folder: folderName }, async function (error, response) {
      // console.log(response)
      if (!error) {
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              console.log(response.result.data)
              sethelpTopic(response.result.data);
            }
          } catch {

          }
        }
      }
      setloading(false);
    });
  }
    
  const renderDeleteModal = () => {
    return (
      <Modal isOpen={deleteModal} toggle={toggleDeleteModal} className="modal-body" centered={true}>
        <form className="theme-form" noValidate="" onSubmit={(e) => handleCatDelete(e)}>
          <ModalHeader><h5 style={{ fontSize: "1.15rem" }}>Delete Image</h5></ModalHeader>
            <ModalBody>
              <ModalBody style={{ padding: "16px 0" }}>
                <h5 style={{ fontSize: "1.15rem" }}>Are you sure you want to delete image?</h5>
              </ModalBody>
              <ModalFooter>
                <Button color="default" onClick={toggleDeleteModal}>Not sure!</Button>
                <Button color="primary" type="submit"  >Yes Please!</Button>
              </ModalFooter>
            </ModalBody>
        </form>
      </Modal>
    );
  }

  const deleteClick = (id) => {
    setdeleteItem(id);
    setdeleteModal(true);
  }
  
  const toggleDeleteModal = () => {
    setdeleteModal(!deleteModal)
  }

  useEffect(() => { 
    fetchData();
  }, []);


  return (
      <Fragment>
        {deleteItem ? renderDeleteModal() : null}
          
      <div className="row">
        <div className="col-lg-7 col-sm-12" style={{ fontSize: "12px" }}>
          <Breadcrumb title="Image Gallery" parent="Home" />
        </div>
        <div className="col-lg-5 col-sm-12 ">
          <div className="btn-group" style={{ paddingTop: "10px", marginLeft: "0.4rem", marginRight: "0.6rem", marginBottom: "0rem", width: "auto", display: "flex", justifyContent: "flex-end", alignItems: "center" }} role="group" aria-label="Basic example">
            {true && <button className="btnCashback custBtnAdmin" onClick={() => setaddmodalOpen(true)} ><i style={{ margin: "0 7px 0 0", color: "#327ebc" }} id="Add New" title="Add New" className="fa fa-plus"></i>Add Image</button>}
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card merchantSetttings" style={{ border: '1px solid rgb(223, 232, 241)', borderRadius: '3px'}}>
              <div className="card-header">
                <NavLink to={`/image-gallery`}>
                  <h7 className="font-weight-bold">
                    <i className="icon-arrow-left" style={{ paddingRight: '5px' }}></i>
                    Image Gallery
                  </h7>
                </NavLink>
              </div>
              <div className="card-body" style={{ padding: "1rem" }}>

                {!loading ?
                  <div className="table-responsive custom-scrollbar" >
                    <table className="table table-border-horizontal" >
                      <thead>
                        <tr style={{background: '#f9fafe'}}>
                          <th scope="col" >Image</th>
                          <th scope="col" >Image url</th>
                          <th scope="col" >Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {helpTopic && helpTopic.length > 0 ? helpTopic.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div className="container border p-0 mb-1 ml-0 mt-1"
                                  style={{
                                    height: '3.5rem',
                                    width: '3.5rem',
                                  }}>
                                  <div className="dropzone__input align-items-center">
                                  <aside className="dropzone__img p-0">
                                    <img src={client.url(`ipayImages/${folderName}/${item.img}`)} className="img-fluid p-0" alt="drop-img" />
                                  </aside>
                                </div>
                              </div>
                                
                              </td>
                              <td>{client.url(`ipayImages/${folderName}/${item.img}`)}</td>
                              <td>
                                <span style={{ fontSize: "1.2rem", color: "red", verticalAlign: 'middle' }} onClick={() => deleteClick(item.img)}>
                                  <i className="fa fa-trash"></i>
                                </span>
                              </td>
                            </tr>
                          )
                        })
                          : null
                        }
                      </tbody>
                    </table>
                  </div>
                  :
                  <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                    <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                      <DisappearedLoading size="medium" color="#514F4E" />
                    </div>
                  </div>}
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddImageModal folderName={folderName} isOpen={addmodalOpen} onClose={(fetch_data = false) => {
        setaddmodalOpen(false);
        setInputCat('');
        seteditItem(null);
        if (fetch_data) {
          fetchData();
        }
      }} />

    </Fragment>
  );

}

export default ImageGallery;