import React, { Fragment, useEffect, useState } from 'react';
import moment from "moment";
import client from "../../../Api/HTTPClient";
import CustomerName from './CustomerName';


const UserSettingTable = ({ userDataa }) => {
    console.log(userDataa, "userDataa");

    const [tableData, setTableData] = useState([]);

    const userName = (val) => {

        if (val.customersInfo) {
            return (
                <div>
                    <div className="d-flex align-items-center">
                        {val.customersInfo.profile_image == "" || typeof val.customersInfo.profile_image === "undefined" ? (
                            <>
                                <span
                                    className="border p-2 mr-2"
                                    style={{
                                        fontSize: "16px",
                                        width: 40,
                                        height: 40,
                                        borderRadius: 20,
                                        display: "block",
                                        textAlign: "center",
                                    }}
                                >
                                    <b style={{ textTransform: "uppercase" }}>
                                        {
                                            val.customersInfo.name ? val.customersInfo.name?.slice(0, 1) : val.customersInfo.email?.slice(0, 1)
                                        }
                                    </b>
                                </span>
                            </>
                        ) : (
                            <>
                                <img
                                    className={`mr-2`}
                                    style={{ width: 40, height: 40, borderRadius: 20 }}
                                    src={val.customersInfo.profile_image.includes('dropbox') ? val.customersInfo.profile_image
                                        : `${client.url(`images/user-profile/${val.customersInfo.profile_image}`)}`}
                                    alt="avatar"
                                />
                            </>
                        )}
                        <h6 style={{ width: '100px', textTransform: 'capitalize', fontSize: "14px", wordWrap: 'break-word', marginLeft: "0.5rem", marginBottom: "0px" }}>
                            {/* {val?.customersInfo?.name} */}
                            <CustomerName text={val?.customersInfo?.name} />
                        </h6>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div className="d-flex align-items-center">
                        {val.profile_image == "" || typeof val.profile_image === "undefined" ? (
                            <>
                                <span
                                    className="border p-2 mr-2"
                                    style={{
                                        fontSize: "16px",
                                        width: 40,
                                        height: 40,
                                        borderRadius: 20,
                                        display: "block",
                                        textAlign: "center",
                                    }}
                                >
                                    <b style={{ textTransform: "uppercase" }}>
                                        {
                                            val.name ? val.name?.slice(0, 1) : val.email?.slice(0, 1)
                                        }
                                    </b>
                                </span>
                            </>
                        ) : (
                            <>
                                <img
                                    className={`mr-2`}
                                    style={{
                                        width: 40,
                                        height: 40,
                                        borderRadius: 20,
                                    }}
                                    src={val.profile_image.includes('dropbox') ? val.profile_image
                                        : `${client.url(`images/user-profile/${val.profile_image}`)}`}
                                    alt="avatar"
                                />
                            </>
                        )}
                        <h6 style={{ width: '100px', fontSize: "14px", marginLeft: "0.5rem", textTransform: 'capitalize' }}>{val.name}</h6>
                    </div>
                </div>
            )
        }
    };

    const lastLogin = (val) => {

        try {
            if (val.customersInfo) {
                if (val?.customersInfo?.login_location) {
                    return moment.tz(val?.customersInfo?.login_location).format('DD/MM/YYYY HH:mm:ss');
                }

            } else if (val.login_location) {
                return moment.tz(val.login_location.updated_on).format('DD/MM/YYYY HH:mm:ss');
            }
        } catch { }
    };

    const locationShow = (val) => {
        if (val.customersInfo) {
            return `${val.customersInfo.login_location ? val.customersInfo.login_location.city + ',' + val.customersInfo.login_location.state + ' / ' + val.customersInfo.login_location.country_name : ""}`;
        } else if (val.login_location) {
            return `${val.login_location ? val.login_location.city + ',' + val.login_location.state + ' / ' + val.login_location.country_name : ""}`;
        }
    }

    const deviceDetails = (val) => {

        if (val.customersInfo) {
            return `${val.customersInfo.login_location ? val.customersInfo.login_location.browserName + ',' + val.customersInfo.login_location.osName + ' / ' + val.customersInfo.login_location.IPv4 : ""}`;
        } else if (val.login_location) {
            return `${val.login_location ? val.login_location.browserName + ',' + val.login_location.osName + ' / ' + val.login_location.IPv4 : ""}`;
        }

    };

    return (
        <Fragment className='p-3'>
            <div className='main_div p-3'>
                <div className="row mb-3">
                    <div className="col-12">
                        <h6 style={{ fontWeight: "600", fontSize: "18px" }}>Users Settings</h6>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table table-border-horizontal table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Date/time</th>
                                <th scope="col">Name</th>
                                <th scope="col">Role</th>
                                <th scope="col">Email </th>
                                <th scope="col">Last Sign in </th>
                                <th scope="col">Location</th>
                                <th scope="col">Device Details & IP</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                userDataa && userDataa.usersList.map((val, ind) => {
                                    let date = moment.tz(val.createdAt).format("DD-MM-YYYY");
                                    return (
                                        <>
                                            <tr key={ind + 1}>
                                                <th scope=" row">{ind + 1}</th>
                                                <td scope=" row"> {date}</td>
                                                <td scope=" row"> {userName(val)}</td>
                                                <td scope=" row"> {`${val.roleAccess ? val.roleAccess.roleName : ""}`}</td>
                                                <td scope=" row"> {val.email}</td>
                                                <td scope=" row"> {lastLogin(val)}</td>
                                                <td scope=" row"> {locationShow(val)}</td>
                                                <td scope=" row">{deviceDetails(val)}</td>
                                                <td scope=" row"> <p style={{ color: `${val.superUser || val.customersInfo ? 'green' : 'red'}` }}>{val.superUser || val.customersInfo ? "Active" : "Inactive"}</p></td>
                                            </tr>
                                        </>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        </Fragment>
    )
}

export default UserSettingTable