import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import api from "../../../constant/apilist";
import client from "../../../Api/HTTPClient";
import "./Result.css";
import QuizEdit from "./QuizEdit";

const ResultQuiz = (props) => {
	const [result, setResult] = useState([]);
	const [totalSpent, setTotalSpent] = useState(0);

	useEffect(() => {
		getResult();
		totalSpentCounter();
	}, []);

	useEffect(() => {
		totalSpentCounter();
	});

	const getResult = async () => {
		let postdata = {
			id: props.quizId,
		};
		console.log(props.quizId, "propsID");
		await client.post(api.getResultdata, postdata, (error, response) => {
			console.log(response);
			if (!error) {
				if (!response.error) {
					console.log(response.result.data);
					setResult(response.result.data);
					// setLoading(false)
				} else {
					// setLoading(false)
					console.log(response);
				}
			} else {
				// setLoading(false)
				console.log(error);
			}
		});
	};
	const totalSpentCounter = () => {
		var length = 0;
		if (result.length > 0) {
			result.map((item) => {
				if (item.allAnsCorrect == true) {
					length = length + 1;
					console.log("true", length);
				}
			});
			console.log(length, "length");
			setTotalSpent(length * 1.5);
		}
	};

	console.log(result, "result");
	console.log(totalSpent, "totalspent");

	return (
		<div className="card">
			<div className="card-body">
				<Row>
					<Col className="tabCont">
						<h6 className="upperNumber">3083</h6>
						<h6 className="lowerTitle">Sent</h6>
					</Col>
					<Col className="tabCont">
						<h6 className="upperNumber">21</h6>
						<h6>Opened</h6>
					</Col>
					<Col className="tabCont">
						<h6 className="upperNumber">3061</h6>
						<h6>Unopened</h6>
					</Col>
					<Col className="tabCont">
						<h6 className="upperNumber">{result.length}</h6>
						<h6>Response</h6>
					</Col>
					<Col className="tabCont">
						<h6 className="upperNumber">{totalSpent}</h6>
						<h6>Total Spent</h6>
					</Col>
				</Row>
			</div>
			<div className="card-body">
				<div className="table-responsive">
					<table className="table table-hover table-border-horizontal">
						<thead>
							<tr>
								<th className="tableheading " scope="col">
									#
								</th>
								<th className="tableheading" scope="col">
									Date / Time
								</th>
								<th className="tableheading" scope="col">
									Name
								</th>
								<th className="tableheading" scope="col">
									iPay ID
								</th>
								<th className="tableheading" scope="col">
									Points
								</th>
							</tr>
						</thead>
						<tbody>
							{result.map((item, index) => {
								console.log(item, "itemmm");
								return (
									<tr key={index}>
										<th>{index + 1}</th>
										<td className="tablecell">
											{item.date} &nbsp; &nbsp;{item.time}
										</td>
										<td className="tablecell">{item.name}</td>
										<td className="tablecell">{item.ipayId}</td>
										<td className="tablecell">{item.totalPoints}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default ResultQuiz;
