export const getBizLinkDomain = () => {
    return "";
}

export const getPortalId = () => {

    const host = typeof window !== 'undefined' && window.location.host ? window.location.host : null;
  
    if (host) {
  
      if (host == "dm.portal.ipaypro.co" || host == "portal.ipaypro.co") {
        return 1;
  
      } else if (host == "dm.portal.linky.biz" || host == "portal.linky.biz") {
        return 2;
  
      } else if (host == "dm.portal.hustlerz.co" || host == "portal.hustlerz.co") {
        return 3;
  
      } else {
        return 2;
      }
  
    }
  }