import React, { Component, Fragment } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import Breadcrumb from "../../../components/common/breadcrumb";
import VersionSettings from "./VersionSettings";
import SilentUpdateSetting from "./SilentUpdateSetting";
import SilentUpdateSettingIOS from "./SilentUpdateSettingIOS";
import SliderSettings from "./SliderSettings";
import QuizTable from "./QuizTable";
import TopupSettingMerchant from "./TopupSettingMerchant";
import TopupSettingCust from "./TopupSettingCust";
import PublishQuiz from "./PublishQuiz";
import MerchantNotification from "./notifications/Merchant";
import CustomerNotification from "./notifications/Customer";

class AppSettings extends Component {
	state = {
		//activeTab: "1",
		tabIndex: 0,
		access_level: localStorage.getItem("accessLevel"),
		addVersionModal: false,
	};

	render() {
		return (
			<Fragment>
				<div className="row">
					<div className="col-lg-7 col-sm-12" style={{ fontSize: "12px" }}>
						<Breadcrumb title={"App settings"} parent={"Home"} />
					</div>
				</div>

				<div className="container-fluid">
					<div className="row theme-tab">
						<Tabs
							className="col-sm-12"
							defaultIndex={this.state.tabIndex}
							onSelect={(index) => this.setState({ tabIndex: index })}
							style={{
								padding: "0",
								backgroundColor: "white",
								borderRadius: "3px",
								border: "1px solid rgb(223, 232, 241)",
								marginBottom: "0px",
							}}
						>
							<TabList className="tbs tab-title">
								{/* <Tab style={{marginTop:"0.5rem",display:"flex",alignItems:"center"}} >
                                    Version Settings
                            </Tab> */}
								<Tab
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									Version Settings
								</Tab>
								<Tab
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									Slider Settings
								</Tab>
								<Tab
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									Quiz/Survey
								</Tab>
								{/* <Tab
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									Top-up
								</Tab> */}
								{/* <Tab
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									Notifications
								</Tab> */}
								{/* <Tab
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									Publish
								</Tab> */}
							</TabList>

							{/* {customers  */}
							<div className="tab-content-cls">
								{/* <TabPanel>
                                    <VersionSettings toggleAddVersionModal={this.toggleAddVersionModal} addVersionModal={this.state.addVersionModal}></VersionSettings>
                                </TabPanel> */}

								<TabPanel>
									<Tabs
										className="col-sm-12"
										defaultIndex={1}
										style={{ padding: "0" }}
									>
										<TabList className="tabs tab-title">
											<Tab>Android LinkyPro App</Tab>
											{/* <Tab>Customer App</Tab> */}
											<Tab>IOS LinkyPro App</Tab>
										</TabList>
										<TabPanel>
											<SilentUpdateSetting></SilentUpdateSetting>
										</TabPanel>
										{/* <TabPanel>
											<VersionSettings></VersionSettings>
										</TabPanel> */}
										<TabPanel>
											<SilentUpdateSettingIOS></SilentUpdateSettingIOS>
										</TabPanel>
									</Tabs>
								</TabPanel>
								<TabPanel>
									<SliderSettings />
								</TabPanel>
								<TabPanel>
									<QuizTable></QuizTable>
								</TabPanel>
								{/* <TabPanel>
									<Tabs
										className="col-sm-12"
										// defaultIndex={1}
										style={{ padding: "0" }}
									>
										<TabList className="tabs tab-title">
											<Tab>Merchant</Tab>
											<Tab>Customer</Tab>
										</TabList>
										<TabPanel>
											<TopupSettingMerchant></TopupSettingMerchant>
										</TabPanel>
										<TabPanel>
											<TopupSettingCust></TopupSettingCust>
										</TabPanel>
									</Tabs>
								</TabPanel> */}
								{/* <TabPanel>
									<Tabs
										className="col-sm-12"
										// defaultIndex={1}
										style={{ padding: "0" }}
									>
										<TabList className="tabs tab-title">
											<Tab>Merchant</Tab>
											<Tab>Customer</Tab>
										</TabList>
										<TabPanel>
											<MerchantNotification />
										</TabPanel>
										<TabPanel>
											<CustomerNotification />
										</TabPanel>
									</Tabs>
								</TabPanel> */}
								{/* <TabPanel>
									<PublishQuiz></PublishQuiz>
								</TabPanel> */}
							</div>
						</Tabs>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default AppSettings;
