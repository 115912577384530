import React, { useState, useEffect, Fragment } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "./table.css"
import app from "./data/base";
import "./gutter.css";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import * as serviceWorker from "./serviceWorker";

// ** Import custom components for redux**
import { Provider } from "react-redux";
import store from "./store/index";
import App from "./components/app";

import Root from "./Root";

// import Signin from "./auth/signin";
// import merchantSignin from "./auth/merchantSignin";

// import Customerwallet from "./pages/customerwallet";
// import CustomerwalletTransactions from "./pages/customerwallettransactions";
// import cashback_setting from "./pages/settings/cashback_setting2";
// import billing_setting from "./pages/settings/billing_setting";

// import speed_mails_setting from "./pages/settings/speed_mails";
// import mail_template_details from "./pages/settings/speed_mails/mail_template_details";
// // Import custom Components
// //import Dashboard from "./pages/dashboard";

// import Dashboard from './components/dashboard/defaultCompo/default';


// //customers
// import Customers from "./pages/customerList";


// import General from './components/widgets/general';
// import Chart from './components/widgets/chart';

// // Merchants
// import MerchantsReq from "./pages/merchants/merchantReqList";
// import MerchantsDetails from "./pages/merchants/merchantDetails";
// import MerchantsApproval from "./pages/merchants/merchantApproval";

// // SopPartners
// // import SopPartners from "./pages/sop/partnerslist";
// import PotentialMerchants from "./pages/sop/PotentialMerchants";
// import PotentialMerchantDetail from "./pages/sop/PotentialMerchantDetail";

// //Ui-elements
// import Avatar from './components/ui-elements/avatar';
// import UIBreadCrumb from './components/ui-elements/uibreadcrumb';
// import Grid from './components/ui-elements/grid';
// import HelperClass from './components/ui-elements/helperclass';
// import List from './components/ui-elements/lists/list';
// import Ribbon from './components/ui-elements/ribbon';
// import Shadow from './components/ui-elements/shadow';
// import Spinner from './components/ui-elements/spinner';
// import Statecolor from './components/ui-elements/statecolor';
// import Steps from './components/ui-elements/steps';
// import TagsandPills from './components/ui-elements/tagsandpills';
// import Typography from './components/ui-elements/typography';

// //Base
// import Accordion from './components/base/accordionComponent/accordion';
// import AlertComponent from './components/base/alertComponent/alert';
// import CarouselComponent from './components/base/Carousels/carouselComponent';
// import CollapseComponent from './components/base/collapseComponent';
// import DatepickerComponent from './components/base/datepickerComponent';
// import DropdownComponent from './components/base/dropdownComponent';
// import ModalComponent from './components/base/modalComponent';
// import Pagination from './components/base/pagination';
// import PopoverComponent from './components/base/popover/popoverComponent';
// import ProgressBar from './components/base/progressBar';
// import RatingComponent from './components/base/ratingComponent';
// import TabsSet from './components/base/tabs-set';
// import TooltipsComponent from './components/base/tooltipsComponent';
// import TimePickerWrapper from './components/base/timepickerComponent/timepicker';
// import TypeaheadComp from './components/base/typeaheadComponent/typeahead';

// // Advance
// import DragNDropComp from './components/advance/drag-n-drop/dragNDropComp';
// import DropzoneComponent from './components/advance/dropzone';
// import ImageCropper from './components/advance/imageCropper';
// import Toastr from './components/advance/toastr';
// import Carousel from './components/advance/carousel';
// import RangeSlider from './components/advance/rangeSlider';
// import Scrollable from './components/advance/scrollable';
// import StickyNotes from './components/advance/stickyNotes';
// import SweetAlert from './components/advance/sweetAlert';
// import TourComponent from './components/advance/tourComponent';
// import UploadImage from './components/advance/uploadImage';
// import FlagIcons from './components/icons/flagIcons';
// import FontAwsomeIcon from './components/icons/fontAwsomeIcon';
// import IcoIcons from './components/icons/icoIcons';
// import ThemifyIcons from './components/icons/themifyIcons';
// import FeatherIcons from './components/icons/featherIcons';
// import WeatherIcons from './components/icons/weatherIcons';
// import DefaultBtn from './components/buttons/default-btn';
// import FlatBtn from './components/buttons/flatBtn';
// import EdgeBtn from './components/buttons/edgeBtn';
// import RaisedBtn from './components/buttons/raisedBtn';
// import GroupBtn from './components/buttons/groupBtn';
// import ImageGallery from './components/gallery/imageGallery';
// import ImageHover from './components/gallery/imageHover';
// import ImageWithDesc from './components/gallery/imageWithDesc';
// import MesonryGallery from './components/gallery/mesonryGallery';
// import FormValidation from './components/forms/form-control/form-validation';
// import BaseInput from './components/forms/form-control/baseInput';
// import RadioCheckbox from './components/forms/form-control/radio-checkbox';
// import InputGroupComp from './components/forms/form-control/inputGroup';
// import MegaOptions from './components/forms/form-control/megaOptions';
// import FormDefault from './components/forms/formDefault';
// import FormWizard from './components/forms/wizard/form-wizard';
// import BasicTable from './components/tables/bootstrap/basicTable';
// import DataTableComponent from './components/tables/dataTableComponent';
// import BasicCards from './components/cards/basicCards';
// import CreativeCards from './components/cards/creativeCards';
// import TabCard from './components/cards/tabCard';
// import DraggingCards from './components/cards/draggingCards';
// import Timeline2 from './components/timelines/timeline2';
// import Timeline from './components/timelines/timeline';
// import GoogleChart from './components/charts/googleChart';
// import ChartJs from './components/charts/chartJs';
// import ChartistComponent from './components/charts/chartistComponent';
// import GoogleMap from './components/map/googleMap';
// import LeafletMapComp from './components/map/leafletMap';
// import Editor1 from './components/editor/editor1';
// import UserProfile from './components/users/userProfile';
// import UserEdit from './components/users/userEdit';
// import UserCards from './components/users/user-cards';
// import Calender1 from './components/calender/calender1';
// import Calender2 from './components/calender/calender2';
// import BlogDetail from './components/blog/blogDetail';
// import BlogSingle from './components/blog/blogSingle';
// import BlogPost from './components/blog/blogPost';
// import SocialApp from './components/social-app/socialApp';
// import CardView from './components/jobSearch/cardView';
// import JobList from './components/jobSearch/job-list';
// import JobDetail from './components/jobSearch/job-detail';
// import JobApply from './components/jobSearch/job-apply';
// import LearningList from './components/learning/learning-list';
// import LearningDeatil from './components/learning/learning-deatil';
// import FaqComponent from './components/faq/faqComponent';
// import KnowledgebaseComponent from './components/knowledgebase/knowledgebaseComponent';
// import SupportTicket from './components/support-ticket/supportTicket';
// import Login from './pages/login';
// import MerchantLogin from './pages/merchants/merchantLogin';

// import LoginWithBgImg from './pages/loginWithBgImg';
// import LoginWithVideo from './pages/loginWithVideo';
// import Signup from './pages/signup';
// import SignupWithImg from './pages/signupWithImg';
// import SignupWithVideo from './pages/signupWithVideo';
// import UnlockUser from './pages/unlockUser';
// import ForgetPwd from './pages/forgetPwd';
// import ResetPwd from './pages/resetPwd';
// import ComingSoon from './pages/comingsoon';
// import ComingSoonImg from './pages/comingsoonImg';
// import ComingSoonVideo from './pages/comingsoonVideo';
// import Maintenance from './pages/maintenance';
// import Error400 from './pages/errors/error400';
// import Error401 from './pages/errors/error401';
// import Error403 from './pages/errors/error403';
// import Error404 from './pages/errors/error404';
// import Error500 from './pages/errors/error500';
// import Error503 from './pages/errors/error503';

// // Import Applications Components

// import Todo from './components/applications/todo-app/todo';
// import EmailDefault from './components/applications/email-app/emailDefault';
// import Chat from './components/applications/chat-app/chat';
// import EcommerceApp from './components/applications/ecommerce-app/product';
// import AddToCart from './components/applications/ecommerce-app/add-to-cart';
// import WishlistComponent from './components/applications/ecommerce-app/wishlist';
// import ProductDetail from './components/applications/ecommerce-app/product-detail/product-detail';
// import Invoice from './components/applications/ecommerce-app/invoice';
// import Checkout from './components/applications/ecommerce-app/checkout';
// import todoFirebase from './components/applications/todo-firebase-app/todo-firebase';
// //import Signin from './auth/signin';
// import ContactApp from './components/applications/contact-app/contactApp';
// import NewUser from './components/applications/contact-app/new-user';
// import EditUser from './components/applications/contact-app/edit-user';
// import ProductList from './components/applications/ecommerce-app/product-list';
// import Payment from './components/applications/ecommerce-app/payment';
// import History from './components/applications/ecommerce-app/history';


// // search page
// import Searchpage from './components/search/searchpage';

// // sample page
// import Samplepage from './components/sample/samplepage';

// // Pricing
// import Pricing from './components/price/pricing';
// import StylingTable from './components/tables/bootstrap/stylingTable';
// import BorderTable from './components/tables/bootstrap/borderTable';
// import SizingTable from './components/tables/bootstrap/sizingTable';
// import MesonryDesc from './components/gallery/mesonryDesc';
// import Transactions from "./pages/transactions";

// // Admin User
// import AdminUserEdit from "./pages/adminusers/edit_profile";
// import CrownsPoints from "./pages/settings/CrownsPoints/Crown&Points";

// ///Settlement
// import Withdraws from "./pages/settlement/withdraws";
// import AppSettings from "./pages/settings/AppSettings/AppSettings";
// import AccessLevel from "./pages/settings/AccessLevel/AccessLevel";
// import TopupAccounts from "./pages/TopupAccounts/TopupAccounts";
// import VerifyOtp from "./auth/verifyOtp";

// import Fingerprint2 from 'fingerprintjs2';



// //firebase Auth
// function Root() {
//   const abortController = new window.AbortController();
//   const [currentUser, setCurrentUser] = useState(null);
//   const [browserId, setBrowserId] = useState(null)

//   useEffect(() => {
//     const themeColor = localStorage.getItem("theme-color");
//     const layout = localStorage.getItem("layout_version");
//     //console.log(currentUser);
//     const options = {};
//     Fingerprint2.get(options, (components) => {
//       var values = components.map((component) => { return component.value });
//       var hashId = Fingerprint2.x64hash128(values.join(''), 31);
//       //console.log("Fingerprint", hashId);
//       //this.setState({browserId : hashId})
//       setBrowserId(hashId)
//     })
//     //console.log(browserId);

//     // alert(layout)
//     //app.auth().onAuthStateChanged(setCurrentUser);
//     /* if(localStorage.getItem('loggedIn') == true){  
//             console.log("SETCURRENTUSER");        
//             setCurrentUser(true);
//          } */

//     document
//       .getElementById("color")
//       .setAttribute(
//         "href",
//         `${process.env.PUBLIC_URL}/assets/css/${themeColor}.css`
//       );
//     document.body.classList.add(layout);

//     return function cleanup() {
//       abortController.abort();
//     };
//   }, [browserId]);
//   // console.log("IN INDEX");

//   return (
//     <div className="App">
//       <Provider store={store}>
//         <BrowserRouter basename={`/`}>
//           <ScrollContext>
//             <Switch>

//               <Route exact
//                 path={`${process.env.PUBLIC_URL}/login`}
//                 //component={Signin}
//                 render={() => {
//                   return (
//                     <Signin browserId={browserId} ></Signin>
//                   )
//                 }}
//               />

//               <Route exact
//                 path={`${process.env.PUBLIC_URL}/verify`}
//                 //component={VerifyOtp}
//                 render={() => <VerifyOtp browserId={browserId}></VerifyOtp>}
//               />
//               <Route
//                 exact
//                 path={`${process.env.PUBLIC_URL}/`}
//                 render={localStorage.getItem("token") ? () => {
//                   return (
//                     <Redirect
//                       to={`${process.env.PUBLIC_URL}/dashboard/default`}
//                     />
//                   );
//                 } : () => {
//                   return (
//                     <Redirect
//                       to={`${process.env.PUBLIC_URL}/login`}
//                     />
//                   );
//                 }
//                 }
//               />


//               {/* <Route
//                 path={`${process.env.PUBLIC_URL}/merchantlogin`}
//                 component={merchantSignin}
//               /> */}


//               {/*alert(localStorage.getItem('isLoggedIn'))*/}
//               {/*alert('current user status = '+ currentUser)*/}



//               {/*alert(currentUser)*/}
//               {localStorage.getItem('token') &&
//                 <App>
//                   <Fragment>


//                     <Route
//                       path={`${process.env.PUBLIC_URL}/dashboard/default`}
//                       component={Dashboard}
//                     />
//                     {/* Merchant Route */}
//                     <Route
//                       path={`${process.env.PUBLIC_URL}/adminusers/edit_profile`}
//                       component={AdminUserEdit}
//                     />

//                     {/* dashboard menu */}
//                     <Route
//                       path={`${process.env.PUBLIC_URL}/all_transactions`}
//                       component={Transactions}
//                     />

//                     {/* customer rout */}
//                     <Route
//                       path={`${process.env.PUBLIC_URL}/customers/all_customers`}
//                       component={Customers}
//                     />

//                     <Route
//                       path={`${process.env.PUBLIC_URL}/customer/wallet/:customer_id`}
//                       component={Customerwallet}
//                     />

//                     <Route
//                       path={`${process.env.PUBLIC_URL}/customer/walletTransactions/:customer_id`}
//                       component={CustomerwalletTransactions}
//                     />

//                     {/* Merchant Route */}

//                     <Route
//                       path={`${process.env.PUBLIC_URL}/merchants/merchant_requests`}
//                       component={MerchantsReq}
//                     />

//                     <Route
//                       path={`${process.env.PUBLIC_URL}/merchants/merchantDetails/:merchant_id`}
//                       component={MerchantsDetails}
//                     />

//                     {/* SOP Route */}

//                     <Route
//                       path={`${process.env.PUBLIC_URL}/sop/partnerslist`}
//                       component={PotentialMerchants}
//                     />

//                     <Route
//                       path={`${process.env.PUBLIC_URL}/potential-merchants/details/:id`}
//                       component={PotentialMerchantDetail}
//                     ></Route>

//                     <Route
//                       path={`${process.env.PUBLIC_URL}/top-up_accounts`}
//                       component={TopupAccounts}
//                     ></Route>




//                     {/* setting route */}
//                     <Route
//                       path={`${process.env.PUBLIC_URL}/authorization/access_level`}
//                       component={AccessLevel}
//                     />


//                     <Route
//                       path={`${process.env.PUBLIC_URL}/settings/cashback_setting`}
//                       component={cashback_setting}
//                     />

//                     <Route
//                       path={`${process.env.PUBLIC_URL}/settings/crown_points`}
//                       component={CrownsPoints}
//                     />

//                     <Route
//                       path={`${process.env.PUBLIC_URL}/settings/app_settings`}
//                       component={AppSettings}
//                     />

//                     <Route
//                       path={`${process.env.PUBLIC_URL}/settings/billings`}
//                       component={billing_setting}
//                     />



//                     <Route
//                       path={`${process.env.PUBLIC_URL}/settings/speed_mails`}
//                       component={speed_mails_setting}
//                     />

//                     <Route
//                       path={`${process.env.PUBLIC_URL}/settings/mail_template_details/:template_id`}
//                       component={mail_template_details}
//                     />

//                     <Route path={`${process.env.PUBLIC_URL}/settlement/withdraws`} component={Withdraws} />





//                     {/* Widgets Menu */}
//                     <Route path={`${process.env.PUBLIC_URL}/widgets/general`} component={General} />
//                     <Route path={`${process.env.PUBLIC_URL}/widgets/chart`} component={Chart} />



//                     {/* ui-elements */}
//                     <Route path={`${process.env.PUBLIC_URL}/ui-element/avatar`} component={Avatar} />
//                     <Route path={`${process.env.PUBLIC_URL}/ui-element/uibreadcrumb`} component={UIBreadCrumb} />
//                     <Route path={`${process.env.PUBLIC_URL}/ui-element/grid`} component={Grid} />
//                     <Route path={`${process.env.PUBLIC_URL}/ui-element/helperclass`} component={HelperClass} />
//                     <Route path={`${process.env.PUBLIC_URL}/ui-element/list`} component={List} />
//                     <Route path={`${process.env.PUBLIC_URL}/ui-element/ribbon`} component={Ribbon} />
//                     <Route path={`${process.env.PUBLIC_URL}/ui-element/shadow`} component={Shadow} />
//                     <Route path={`${process.env.PUBLIC_URL}/ui-element/spinner`} component={Spinner} />
//                     <Route path={`${process.env.PUBLIC_URL}/ui-element/statecolor`} component={Statecolor} />
//                     <Route path={`${process.env.PUBLIC_URL}/ui-element/steps`} component={Steps} />
//                     <Route path={`${process.env.PUBLIC_URL}/ui-element/tagsandpills`} component={TagsandPills} />
//                     <Route path={`${process.env.PUBLIC_URL}/ui-element/typography`} component={Typography} />



//                     {/* gallery */}
//                     <Route path={`${process.env.PUBLIC_URL}/gallery/imageGallery`} component={ImageGallery} />
//                     <Route path={`${process.env.PUBLIC_URL}/gallery/imageWithDesc`} component={ImageWithDesc} />
//                     <Route path={`${process.env.PUBLIC_URL}/gallery/imageHover`} component={ImageHover} />
//                     <Route path={`${process.env.PUBLIC_URL}/gallery/mesonryGallery`} component={MesonryGallery} />
//                     <Route path={`${process.env.PUBLIC_URL}/gallery/mesonryDesc`} component={MesonryDesc} />

//                     {/* Forms */}
//                     <Route path={`${process.env.PUBLIC_URL}/forms/form-validation`} component={FormValidation} />
//                     <Route path={`${process.env.PUBLIC_URL}/forms/baseInput`} component={BaseInput} />
//                     <Route path={`${process.env.PUBLIC_URL}/forms/radio-checkbox`} component={RadioCheckbox} />
//                     <Route path={`${process.env.PUBLIC_URL}/forms/inputGroup`} component={InputGroupComp} />
//                     <Route path={`${process.env.PUBLIC_URL}/forms/megaOptions`} component={MegaOptions} />
//                     <Route path={`${process.env.PUBLIC_URL}/forms/formDefault`} component={FormDefault} />
//                     <Route path={`${process.env.PUBLIC_URL}/forms/FormWizard`} component={FormWizard} />


//                     {/* Charts */}
//                     <Route path={`${process.env.PUBLIC_URL}/charts/googleChart`} component={GoogleChart} />
//                     <Route path={`${process.env.PUBLIC_URL}/charts/chartJs`} component={ChartJs} />
//                     <Route path={`${process.env.PUBLIC_URL}/charts/chartistComponent`} component={ChartistComponent} />

//                     {/* Map */}
//                     <Route path={`${process.env.PUBLIC_URL}/map/googleMap`} component={GoogleMap} />
//                     <Route path={`${process.env.PUBLIC_URL}/map/leafletMap`} component={LeafletMapComp} />

//                     {/* Editor */}
//                     <Route path={`${process.env.PUBLIC_URL}/editor/editor1`} component={Editor1} />

//                     {/* Users */}
//                     <Route path={`${process.env.PUBLIC_URL}/users/userProfile`} component={UserProfile} />
//                     <Route path={`${process.env.PUBLIC_URL}/users/userEdit`} component={UserEdit} />
//                     <Route path={`${process.env.PUBLIC_URL}/users/userCards`} component={UserCards} />

//                     {/* Calender */}
//                     <Route path={`${process.env.PUBLIC_URL}/calender/calender1`} component={Calender1} />
//                     <Route path={`${process.env.PUBLIC_URL}/calender/calender2`} component={Calender2} />

//                     {/* Blog */}
//                     <Route path={`${process.env.PUBLIC_URL}/blog/blogDetail`} component={BlogDetail} />
//                     <Route path={`${process.env.PUBLIC_URL}/blog/blogSingle`} component={BlogSingle} />
//                     <Route path={`${process.env.PUBLIC_URL}/blog/blogPost`} component={BlogPost} />



//                     {/* FAQ */}
//                     <Route path={`${process.env.PUBLIC_URL}/faq/faqComponent`} component={FaqComponent} />

//                     {/* Knowledgebase */}
//                     <Route path={`${process.env.PUBLIC_URL}/knowledgebase/knowledgebaseComponent`} component={KnowledgebaseComponent} />

//                     {/* Support Ticket */}
//                     <Route path={`${process.env.PUBLIC_URL}/support-ticket/supportTicket`} component={SupportTicket} />

//                     {/* Applications */}
//                     <Route path={`${process.env.PUBLIC_URL}/todo-app/todo`} component={Todo} />
//                     <Route path={`${process.env.PUBLIC_URL}/email-app/emailDefault`} component={EmailDefault} />
//                     <Route path={`${process.env.PUBLIC_URL}/chat-app/chat`} component={Chat} />

//                     {/* Ecommerce App */}
//                     <Route path={`${process.env.PUBLIC_URL}/ecommerce/product`} component={EcommerceApp} />
//                     <Route path={`${process.env.PUBLIC_URL}/ecommerce/cart/:id`} component={AddToCart} />
//                     <Route path={`${process.env.PUBLIC_URL}/ecommerce/wishlist/:id`} component={WishlistComponent} />
//                     <Route path={`${process.env.PUBLIC_URL}/ecommerce/product-detail/:id`} component={ProductDetail} />
//                     <Route path={`${process.env.PUBLIC_URL}/ecommerce/checkout`} component={Checkout} />
//                     <Route path={`${process.env.PUBLIC_URL}/ecommerce/invoice`} component={Invoice} />
//                     <Route path={`${process.env.PUBLIC_URL}/ecommerce/product-list`} component={ProductList} />
//                     <Route path={`${process.env.PUBLIC_URL}/ecommerce/payment`} component={Payment} />
//                     <Route path={`${process.env.PUBLIC_URL}/ecommerce/history`} component={History} />

//                     {/* To-Do-Firebase */}
//                     <Route path={`${process.env.PUBLIC_URL}/todo-app/todo-firebase`} component={todoFirebase} />

//                     {/* CONTACT APP */}
//                     <Route path={`${process.env.PUBLIC_URL}/contact-app/contact`} component={ContactApp} />
//                     <Route path={`${process.env.PUBLIC_URL}/contact-app/new-user`} component={NewUser} />
//                     <Route path={`${process.env.PUBLIC_URL}/contact-app/edit-user/:id`} component={EditUser} />

//                     {/* Search page */}
//                     <Route path={`${process.env.PUBLIC_URL}/search/searchpage`} component={Searchpage} />

//                     {/* Search page */}
//                     <Route path={`${process.env.PUBLIC_URL}/pages/loginWithBgImg`} component={LoginWithBgImg} />
//                     <Route path={`${process.env.PUBLIC_URL}/pages/loginWithVideo`} component={LoginWithVideo} />
//                     <Route path={`${process.env.PUBLIC_URL}/pages/signup`} component={Signup} />
//                     <Route path={`${process.env.PUBLIC_URL}/pages/signupWithImg`} component={SignupWithImg} />
//                     <Route path={`${process.env.PUBLIC_URL}/pages/signupWithVideo`} component={SignupWithVideo} />
//                     <Route path={`${process.env.PUBLIC_URL}/pages/unlockUser`} component={UnlockUser} />
//                     <Route path={`${process.env.PUBLIC_URL}/pages/forgetPwd`} component={ForgetPwd} />
//                     <Route path={`${process.env.PUBLIC_URL}/pages/resetPwd`} component={ResetPwd} />
//                     <Route path={`${process.env.PUBLIC_URL}/pages/comingsoon`} component={ComingSoon} />
//                     <Route path={`${process.env.PUBLIC_URL}/pages/comingsoonImg`} component={ComingSoonImg} />
//                     <Route path={`${process.env.PUBLIC_URL}/pages/comingsoonVideo`} component={ComingSoonVideo} />

//                     {/* Sample page */}
//                     <Route path={`${process.env.PUBLIC_URL}/sample/samplepage`} component={Samplepage} />

//                     {/* Pricing */}
//                     <Route path={`${process.env.PUBLIC_URL}/price/pricing`} component={Pricing} />

//                   </Fragment>
//                 </App>
//               }


//             </Switch>
//           </ScrollContext>
//         </BrowserRouter>
//       </Provider>
//     </div>
//   );
// }



ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename={`/`}>
      <Root />
    </BrowserRouter>
  </Provider>
  , document.getElementById("root"));

serviceWorker.unregister();

