import React from 'react';
import PropTypes from 'prop-types';
import { CardBody } from 'reactstrap';

const TimeLineIcon = ({ type, index }) => {
    let Icon;
    Icon = <span className="lnr lnr-play" />;


    // switch (type) {
    //   case 'work':
    //     Icon = <span className="lnr lnr-briefcase" />;
    //     break;
    //   case 'video':
    //     Icon = <span className="lnr lnr-film-play" />;
    //     break;
    //   case 'file':
    //     Icon = <span className="lnr-play" />;
    //     break;
    //   default:
    //     break;
    // }

    return (
        <div style={index == 0 ? { backgroundColor: "#D23F99" } : {}} className={`timeline__icon ${type}`}>
            {Icon}
        </div>
    );
};

TimeLineIcon.propTypes = {
    type: PropTypes.string,
};

TimeLineIcon.defaultProps = {
    type: '',
};

const TimeLineItem = ({
    type, img, title, date, children, index, subtitle
}) => (
    <div className="timeline__item">
        {/* <div className="timeline__icon"><img src={img} alt="img" /></div> */}
        <TimeLineIcon index={index} type={type} />
        <div className="timeline__content">
            <h5 style={{ color: "#020202", fontSize: '14px', margin: '0' }} className="">{title}</h5>
            {subtitle && <h4 className="subhead timeline__date">{subtitle}</h4>}
            <h4 className="subhead timeline__date">{date}</h4>
            {children}
        </div>
    </div>
);

TimeLineItem.propTypes = {
    type: PropTypes.string,
    img: PropTypes.string,
    title: PropTypes.string,
    date: PropTypes.string,
    children: PropTypes.element.isRequired,
};

TimeLineItem.defaultProps = {
    type: '',
    img: '',
    title: '',
    date: '',
};

export default TimeLineItem;
