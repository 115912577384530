import React, { Component, useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormGroup, Col, Row, Input, CustomInput } from 'reactstrap';
import useForm from "react-hook-form";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

let AddFeatureTitle = (props) => {

    const { handleSubmit, register, errors } = useForm();
    let [loadingbtn, setloadingbtn] = useState(false);

    const onSubmit = (form_data) => {

        setloadingbtn(true);
        let postData = { ...form_data };

        client.post(api.add_feature_title, postData, async function (error, response) {

            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            toast.success("Added Successfully");
                            props.toggleModal(1);
                            props.fetchFeaturesTitles();
                        } else {
                            toast.error(response.message);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }
            setloadingbtn(false);
        });

    }

    return (
        <>
            <Modal isOpen={props.isOpen} toggle={props.toggleModal} className="modal-body" centered={true}>
                <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader toggle={props.toggleModal}>Add Features Title</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-md-12">
                                <FormGroup className="w-100">
                                    <Row form>
                                        <Col md={6}>
                                            <Label for="level_title">Feature Title Name</Label>
                                            <input
                                                name="title"
                                                className="form-control form-control-sm mb-2"
                                                type="text"
                                                ref={register({ required: true })}
                                            />
                                            <span>{errors.title && 'Title is required'}</span>
                                        </Col>
                                        <Col md={6}>
                                            <Label for="level_title" style={{}}>Feature Title Id</Label>
                                            <input
                                                name="title_id"
                                                className="form-control form-control-sm mb-2"
                                                type="text"
                                                ref={register({ required: true })}
                                            />
                                            <span>{errors.title_id && 'feature id is required'}</span>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {!loadingbtn ?
                            <>
                                <Button color="default" type="button" onClick={props.toggleModal}>Cancel</Button>
                                <Button color="primary" type="submit">Save</Button>
                            </>
                            :
                            <div className="loader-box pull-right" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 20, width: 20 }}></span></div>
                        }
                    </ModalFooter>
                </form>
            </Modal>
        </>
    );
}

export default AddFeatureTitle;