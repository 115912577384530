import React, { useEffect, Fragment, useState } from "react";
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormInput, FormGroup, Col, Row, Input, CustomInput } from 'reactstrap';
import AddcatCuisineModal from './AddcatCuisineModal';
import EditCuisineModal from './EditCuisineModal';
import { Toast } from "react-bootstrap";
import useForm from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { DisappearedLoading } from "react-loadingg";
import { Link, useHistory } from "react-router-dom";
import Dropzone from "../../components/dropzone/DropZoneFieldCuisines";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index"
import ReactDragListView from "react-drag-listview";

const VenuesSettings = (props) => {

  let [loading, setloading] = useState(true);
  let [loadingbtn, setloadingbtn] = useState(false);
  let [editformOpen, seteditformOpen] = useState(false);
  let [editmodalOpen, seteditmodalOpen] = useState(false);
  let [addmodalOpen, setaddmodalOpen] = useState(false);
  let [editcatmodalOpen, seteditcatmodalOpen] = useState(false);
  let [statusDetails, setStatusDetails] = useState(null);
  let [catCuisine, setcatCuisine] = useState([]);
  let history = useHistory();
  let [categoryId, setCategoryId] = useState(null);
  let [catDeleteId, setCatDeleteId] = useState(null);
  let [categoryName, setCategoryName] = useState(null);
  let [inputList, setInputList] = useState([{ status: 1 }]);
  let [inputCat, setInputCat] = useState('');
  let [editItem, seteditItem] = useState(null);
  const dispatch = useDispatch();

  const slider = catCuisine
  { slider && console.log(slider) }
  const tableDragProps = {
      onDragEnd(fromIndex, toIndex) {
          let NewStoreTableData = slider;
          let item = NewStoreTableData.splice(fromIndex, 1)[0];
          NewStoreTableData.splice(toIndex, 0, item);
          NewStoreTableData = NewStoreTableData;
          client.post(api.edit_cuisines, { order: NewStoreTableData, type: 2 }, async function (error, response) {
              if (!error) {
                  if (typeof response.error !== "undefined") {
                      fetchData();
                      toast.success(response.message);
                  }
              }
          })
      },
      nodeSelector: "tr",
  };

  const handleCatDelete = (id) => {

    setloadingbtn(true);

    client.post(api.delete_cuisines, { _id: id }, async function (error, response) {
      if (!error) {
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              toast.success("Deleted Successfully");
              fetchData();
            } else {
              toast.error(response.message);
            }
          } catch (e) {
            toast.error("Internal error occured. Please contact support 2");
          }
        } else {
          toast.error("Internal error occured. Please contact support 2");
        }
      } else {
        toast.error("Internal error occured. Please contact support 3");
      }
      setloadingbtn(false);
    });

  }

  async function fetchData() {
    client.post(api.fetch_cuisines, { show_all: true }, async function (error, response) {
      // console.log(response)
      if (!error) {
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              setcatCuisine(response.result.data);
            }
          } catch {

          }
        }
      }
      setloading(false);
    });
  }

  const handleEnableCatMode = (cuisine, id, status) => {
  
    let changestatus;
    if (status == 1) { changestatus = 0; } else { changestatus = 1 }

    let postdata = { cuisine_name: cuisine, _id: id, status: changestatus }

    if (postdata !== '') {
      setloadingbtn(true);

      client.post(api.disable_cuisines, postdata, async function (error, response) {
        if (!error) {
          if (typeof response.error !== "undefined") {
            try {
              if (!response.error) {
                toast.success("Updated Successfully");
                // setcashbackdata(response.result.data);
              } else {
                toast.error(response.message);
              }
            } catch (e) {
              toast.error("Internal error occured. Please contact support");
            }
          } else {
            toast.error("Internal error occured. Please contact support");
          }
        } else {
          toast.error("Internal error occured. Please contact support");
        }

        setloadingbtn(false);
      });
    }
    else {
      //errors.showMessages();
    }
  }

  const handleEditClick = (category, catid) => {

    let postdata = { business_category: category, show: 'category' }
    setloadingbtn(true);
    client.post(api.merchant_list, postdata, async function (error, response) {
      if (!error) {
        // alert(JSON.stringify(response));
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              if (response.result.merchantDetails.length > 0) {
                seteditmodalOpen(true);
                setStatusDetails(response.result.merchantDetails);
              } else {
                setInputCat(category);
                //setInputList(subcategory);
                seteditItem(catid);
                seteditcatmodalOpen(true);
              }
            } else {
              setTimeout(() => {
                toast.error(response.message);
              }, 200);
            }
          } catch (e) {
            setTimeout(() => {
              toast.error("Internal error occured. Please contact support");
            }, 200);
            // _showToast.error("Internal error occured. Please contact support.");
            // currentComponent.setState({ errormessage: "Internal error." });
          }
        } else {
          setTimeout(() => {
            toast.error("Internal error occured. Please contact support");
          }, 200);
          //   _showToast.error("Internal error occured. Please contact support.");
        }
      } else {
        setTimeout(() => {
          toast.error("Internal error occured. Please contact support");
        }, 200);
        // alert(JSON.stringify(error));
        // _showToast.error("Internal error occured. Please contact support.");
      }
      setloadingbtn(false);
    })
  }

  useEffect(() => {
    fetchData();
  }, []);


  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-7 col-sm-12" style={{ fontSize: "12px" }}>
          <Breadcrumb title="Venues Setting" parent="Home" />
        </div>
        <div className="col-lg-5 col-sm-12 ">
          <div className="btn-group" style={{ paddingTop: "10px", marginLeft: "0.4rem", marginRight: "0.6rem", marginBottom: "0rem", width: "auto", display: "flex", justifyContent: "flex-end", alignItems: "center" }} role="group" aria-label="Basic example">
            {true && <button className="btnCashback custBtnAdmin" onClick={() => setaddmodalOpen(true)} ><i style={{ margin: "0 7px 0 0", color: "#327ebc" }} id="Add New" title="Add New" className="fa fa-plus"></i>Add Cuisines</button>}
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card merchantSetttings" style={{ border: '1px solid rgb(223, 232, 241)', borderRadius: '3px'}}>
              <div className="card-header">
                <h7 class="font-weight-bold">Cuisines</h7>
              </div>
              <div className="card-body" style={{ padding: "1rem" }}>

                {!loading ?
                  <div className="table-responsive custom-scrollbar">
                    <ReactDragListView {...tableDragProps}>
                      <table className="table table-border-horizontal" >
                        <thead>
                          <tr style={{background: '#f9fafe'}}>
                            <th scope="col">#</th>
                            <th scope="col" >Cuisine</th>
                            <th scope="col">Icon</th>
                            <th scope="col" >Status</th>
                            <th scope="col" >Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* countries{} */}
                          {catCuisine && catCuisine.length > 0 ? catCuisine.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>#</td>
                                <td>{item.cuisine_name}</td>
                                <td>
                                  <Dropzone
                                    name={`${index}`}
                                    cat={item.cuisine_name}
                                    profile={item.profile}
                                  />
                                </td>
                                <td>
                                  <p><input
                                      type="checkbox"
                                      value={item.status}
                                      defaultChecked={item.status == 0 ? "true" : ""}
                                      onChange={() => {
                                        handleEnableCatMode(item.cuisine_name, item._id, item.status);
                                        setCategoryId(item._id);
                                      }
                                      }
                                    /></p>
                                </td>
                                <td>
                                  <span style={{ marginRight: "10px", fontSize: "0.75rem", color: "black" }} onClick={() => {
                                    handleEditClick(item.cuisine_name, item._id);
                                  }}>
                                    <i className="fa fa-edit"></i>
                                  </span>
                                  <span style={{ marginRight: "10px", fontSize: "0.75rem", color: "red" }} onClick={() => handleCatDelete(item._id)}>
                                    <i className="fa fa-trash"></i>
                                  </span>
                                </td>
                              </tr>
                            )
                          })
                            : null
                          }
                        </tbody>
                      </table>
                    </ReactDragListView>
                  </div>
                  :
                  <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                    <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                      <DisappearedLoading size="medium" color="#514F4E" />
                    </div>
                  </div>}
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddcatCuisineModal isOpen={addmodalOpen} onClose={(fetch_data = false) => {
        setaddmodalOpen(false);
        setInputCat('');
        seteditItem(null);
        if (fetch_data) {
          fetchData();
        }
      }} />

      <EditCuisineModal isOpen={editcatmodalOpen} category={inputCat} subCategory={inputList} catid={editItem} onClose={(fetch_data = false) => {
        seteditcatmodalOpen(false);
        setInputCat('');
        seteditItem(null);
        if (fetch_data) {
          fetchData();
        }
      }} />

    </Fragment>
  );

}

export default VenuesSettings;