import React, { useEffect, Fragment, useState } from "react";
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormInput, FormGroup, Col, Row, Input, CustomInput } from 'reactstrap';
import AddHelpTopicModal from './AddHelpTopicModal';
import EditHelpTopicModal from './EditHelpTopicModal';
import { Toast } from "react-bootstrap";
import useForm from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { DisappearedLoading } from "react-loadingg";
import { Link, useHistory } from "react-router-dom";
import Dropzone from "../../components/dropzone/DropZoneFieldHelp";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index"
import ReactDragListView from "react-drag-listview";
import { Link as NavLink } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { MdOutlineDragIndicator } from "react-icons/md";

const HelpTopic = (props) => {

  let [loading, setloading] = useState(true);
  let [loadingbtn, setloadingbtn] = useState(false);
  let [editformOpen, seteditformOpen] = useState(false);
  let [editmodalOpen, seteditmodalOpen] = useState(false);
  let [addmodalOpen, setaddmodalOpen] = useState(false);
  let [editcatmodalOpen, seteditcatmodalOpen] = useState(false);
  let [statusDetails, setStatusDetails] = useState(null);
  let [helpTopic, sethelpTopic] = useState([]);
  let history = useHistory();
  let [inputList, setInputList] = useState([]);
  let [inputCat, setInputCat] = useState('');
  let [inputDesc, setinputDesc] = useState('');
  let [editItem, seteditItem] = useState(null);
  let [deleteItem, setdeleteItem] = useState(null);
  const dispatch = useDispatch();
  const [redirect, setRedirect] = useState(false);
  const [redirectData, setRedirectData] = useState(null);
  const [deleteModal, setdeleteModal] = useState(false);

  console.log(helpTopic, "helpTopic");


  const slider = helpTopic
  { slider && console.log(slider) }

  const tableDragProps = {
    onDragEnd(fromIndex, toIndex) {
      let NewStoreTableData = slider;
      let item = NewStoreTableData.splice(fromIndex, 1)[0];
      NewStoreTableData.splice(toIndex, 0, item);
      NewStoreTableData = NewStoreTableData;
      client.post(api.editHelptopics, { order: NewStoreTableData, type: 2 }, async function (error, response) {
        if (!error) {
          if (typeof response.error !== "undefined") {
            fetchData();
            toast.success(response.message);
          }
        }
      })
    },
    nodeSelector: "tr",
  };




  const handleCatDelete = (e) => {
    e.preventDefault();
    setloadingbtn(true);

    client.post(api.deleteHelptopics, { _id: deleteItem }, async function (error, response) {
      if (!error) {
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              toast.success("Deleted Successfully");
              toggleDeleteModal();
              fetchData();
            } else {
              toast.error(response.message);
            }
          } catch (e) {
            toast.error("Internal error occured. Please contact support 2");
          }
        } else {
          toast.error("Internal error occured. Please contact support 2");
        }
      } else {
        toast.error("Internal error occured. Please contact support 3");
      }
      setloadingbtn(false);
    });

  }

  async function fetchData() {
    client.post(api.fetchHelptopics, { show_all: true }, async function (error, response) {
      // console.log(response)
      if (!error) {
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              sethelpTopic(response.result.data);
            }
          } catch {

          }
        }
      }
      setloading(false);
    });
  }

  const handleEnableCatMode = (cuisine, id, status) => {

    let changestatus;
    if (status == 1) { changestatus = 0; } else { changestatus = 1 }

    let postdata = { main_topic: cuisine, _id: id, status: changestatus }

    if (postdata !== '') {
      setloadingbtn(true);

      client.post(api.disable_cuisines, postdata, async function (error, response) {
        if (!error) {
          if (typeof response.error !== "undefined") {
            try {
              if (!response.error) {
                toast.success("Updated Successfully");
                // setcashbackdata(response.result.data);
              } else {
                toast.error(response.message);
              }
            } catch (e) {
              toast.error("Internal error occured. Please contact support");
            }
          } else {
            toast.error("Internal error occured. Please contact support");
          }
        } else {
          toast.error("Internal error occured. Please contact support");
        }

        setloadingbtn(false);
      });
    }
    else {
      //errors.showMessages();
    }

  }

  const renderDeleteModal = () => {
    return (
      <Modal isOpen={deleteModal} toggle={toggleDeleteModal} className="modal-body" centered={true}>
        <form className="theme-form" noValidate="" onSubmit={(e) => handleCatDelete(e)}>
          <ModalHeader><h5 style={{ fontSize: "1.15rem" }}>Delete Help Topic</h5></ModalHeader>
          <ModalBody>
            <ModalBody style={{ padding: "16px 0" }}>
              <h5 style={{ fontSize: "1.15rem" }}>Are you sure you want to delete the help topic?</h5>
            </ModalBody>
            <ModalFooter>
              <Button color="default" onClick={toggleDeleteModal}>Not sure!</Button>
              <Button color="primary" type="submit"  >Yes Please!</Button>
            </ModalFooter>
          </ModalBody>
        </form>
      </Modal>
    );
  }

  const handleEditClick = (main_topic, description, subtopic, catid) => {
    setInputCat(main_topic);
    setinputDesc(description);
    setInputList(subtopic);
    seteditItem(catid);
    seteditcatmodalOpen(true);
  }

  const deleteClick = (id) => {
    setdeleteItem(id);
    setdeleteModal(true);
  }

  const toggleDeleteModal = () => {
    setdeleteModal(!deleteModal)
  }

  useEffect(() => {
    fetchData();
  }, []);

  const dragSubItems = (fromIndex, toIndex, currData) => {

    let NewStoreTableData = currData.sub_topic;
    let item = NewStoreTableData.splice(fromIndex, 1)[0];
    NewStoreTableData.splice(toIndex, 0, item);
    let postData = { ...currData, sub_topic: NewStoreTableData };

    client.post(api.updateHelpSubtopicsRank, postData, async function (error, response) {
      if (!error) {
        if (typeof response.error !== "undefined") {
          fetchData();
          toast.success(response.message);
        }
      }
    })

  }


  return (
    <Fragment>
      {deleteItem ? renderDeleteModal() : null}

      <div className="row">
        <div className="col-lg-7 col-sm-12" style={{ fontSize: "12px" }}>
          <Breadcrumb title="Help Topics" parent="Home" />
        </div>
        <div className="col-lg-5 col-sm-12 ">
          <div className="btn-group" style={{ paddingTop: "10px", marginLeft: "0.4rem", marginRight: "0.6rem", marginBottom: "0rem", width: "auto", display: "flex", justifyContent: "flex-end", alignItems: "center" }} role="group" aria-label="Basic example">
            {true && <button className="btnCashback custBtnAdmin" onClick={() => setaddmodalOpen(true)} ><i style={{ margin: "0 7px 0 0", color: "#327ebc" }} id="Add New" title="Add New" className="fa fa-plus"></i>Add Topic</button>}
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card merchantSetttings" style={{ border: '1px solid rgb(223, 232, 241)', borderRadius: '3px' }}>
              <div className="card-header">
                <h7 className="font-weight-bold">Help Topics</h7>
              </div>
              <div className="card-body" style={{ padding: "1rem" }}>

                {!loading ?
                  <div className="table-responsive custom-scrollbar">
                    <ReactDragListView {...tableDragProps}>
                      <table className="table table-border-horizontal" >
                        <thead>
                          <tr style={{ background: '#f9fafe' }}>
                            <th scope="col">#</th>
                            <th scope="col" >Icon</th>
                            <th scope="col">Help Topic</th>
                            <th scope="col" >Description</th>
                            <th scope="col" >Sub Topic</th>
                            <th scope="col" >Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* countries{} */}
                          {helpTopic && helpTopic.length > 0 ? helpTopic.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>#</td>
                                <td style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                                  <Dropzone
                                    name={`${index}`}
                                    cat={item._id}
                                    profile={item.icon_image}
                                  />
                                </td>
                                <td>{item.main_topic}</td>
                                <td>
                                  {item.description}
                                </td>
                                <td width={'30%'}>
                                  <>
                                    <ReactDragListView
                                      nodeSelector=".subItems"
                                      lineClassName="dragLine"
                                      onDragEnd={(fromIndex, toIndex) =>
                                        dragSubItems(fromIndex, toIndex, item)
                                      }

                                    >
                                      {item.sub_topic.map((sat, st) => {
                                        return (
                                          <NavLink to={`/helptopics-question/${sat._id}`} item={item} className="subItems">
                                            <div style={{ display: 'flex', textDecoration: 'underline', alignItems: "center" }}>
                                              <MdOutlineDragIndicator style={{ fontSize: "1.2rem" }} />
                                              <p className="ml-2">{sat.title}</p>
                                              <p>
                                                {/* <i style={{ color: '#4466f2', paddingLeft: '6px' }} className="fa fa-edit"></i> */}
                                                <VisibilityIcon title="Add Question" style={{ marginLeft: '6px', fontSize: '20px', color: '#327ebc', verticalAlign: 'middle' }} />
                                              </p>
                                            </div>
                                          </NavLink>
                                        )
                                      })}
                                    </ReactDragListView>
                                    {/* <ReactDragListView {...tableSubDataDragProps}>
                                    {item.sub_topic.map((sat, st) => {
                                      return (
                                        <NavLink to={`/helptopics-question/${sat._id}`} item={item}>
                                          <div style={{ display: 'flex', textDecoration: 'underline' }}>
                                            <p>{sat.title}</p>
                                            <p>
                                              <VisibilityIcon title="Add Question" style={{ marginLeft: '6px', fontSize: '20px', color: '#327ebc', verticalAlign: 'middle' }} />
                                            </p>
                                          </div>
                                        </NavLink>
                                      )
                                    })}
                                  </ReactDragListView> */}
                                  </>
                                </td>
                                <td>

                                  <span style={{ marginRight: "5px", fontSize: "1.2rem", color: "black", verticalAlign: 'middle' }} onClick={() => {
                                    handleEditClick(item.main_topic, item.description, item.sub_topic, item._id);
                                  }}>
                                    <i className="fa fa-edit"></i>
                                  </span>
                                  <NavLink to={`/helptopics-question/${item._id}`} item={item} style={{ verticalAlign: 'middle' }}>
                                    <VisibilityIcon title="Add Question" style={{ marginRight: '6px', fontSize: '1.2rem', color: '#327ebc', verticalAlign: 'middle' }} />
                                  </NavLink>
                                  <span style={{ fontSize: "1.2rem", color: "red", verticalAlign: 'middle' }} onClick={() => deleteClick(item._id)}>
                                    <i className="fa fa-trash"></i>
                                  </span>
                                </td>
                              </tr>
                            )
                          })
                            : null
                          }
                        </tbody>
                      </table>
                    </ReactDragListView>
                  </div>
                  :
                  <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                    <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                      <DisappearedLoading size="medium" color="#514F4E" />
                    </div>
                  </div>}
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddHelpTopicModal isOpen={addmodalOpen} onClose={(fetch_data = false) => {
        setaddmodalOpen(false);
        setInputCat('');
        seteditItem(null);
        if (fetch_data) {
          fetchData();
        }
      }} />

      <EditHelpTopicModal isOpen={editcatmodalOpen} mainTopic={inputCat} description={inputDesc} title={inputList} catid={editItem} onClose={(fetch_data = false) => {
        seteditcatmodalOpen(false);
        setInputCat('');
        seteditItem(null);
        if (fetch_data) {
          fetchData();
        }
      }} />

    </Fragment>
  );

}

export default HelpTopic;