import React, { useState,Fragment,useEffect } from "react";
import api from "../../constant/apilist";
import client from "../../Api/HTTPClient";
import {Table, Badge, Button,Modal,ModalHeader,ModalBody, ModalFooter,Tooltip,Nav, NavItem, NavLink} from 'reactstrap';
import moment from "moment";
import Icon from "@mdi/react";
import PaginationComponent from "react-reactstrap-pagination";
import { DisappearedLoading } from "react-loadingg";
import { toast } from "react-toastify";
import CloseIcon from "mdi-react/CloseIcon";
import BeatLoader from "react-spinners/BeatLoader";
import {
  mdiTrashCanOutline,
  mdiEye,
  mdiOpenInNew,
  mdiContentCopy,
  mdiChevronDown,
  mdiDownloadOutline
} from "@mdi/js";
import ViewTransaction from "./ViewTransaction.js";
import { Tabs,Tab, TabList, TabPanel,  } from "react-tabs";
import Swal from "sweetalert2";
import axios from "axios";
import fileDownload from "js-file-download";
import EventTableComp from "./EventTableComp.jsx";
import './sellTicket.css';
import momentT from 'moment-timezone';

const RequestPayments = ({merchant_info,merchant_id})=>{
  const [data, setData] = useState(0)
  const [requestData, setRequestData] = useState([]);
  const [pageSize, setPageSize] = useState(15);
  const [searchValue, setSearchValue] = useState(null);
  const [pageIndex,setPageIndex] = useState(1);
  const [merchantUserTimezone,setMerchantUserTimezone]=useState('Australia/Sydney')
  console.log(merchant_info)
  const [searchInputVal,setSearchInputVal] = useState(null);
  const [filterDuration, setFilterDuration] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [loading,setLoading] = useState(true);
  const [viewTransaction,setViewTransaction] = useState(false);
  const [transactionData,setTransactionData] = useState({
    merchant_id: merchant_id,
    wallet_amount: '',
    data: null,
    view_type: 0,
    disabled_refund: '',
  });
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [selectedIDs, setselectedIDs] = useState("");
  const [deleteLoyaltyCard, setDeleteLoyaltyCard] = useState(false);
  const [redirectLink, setRedirectLink] = useState('');
  const [copyTextToolTip, setCopyTextToolTip] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedTicketID, setSelectedTicketID] = useState(null);
  const [activeTab, setActiveTab] = useState('1')
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const[allEvents, setAllEvents] = useState([]);
  const [archiveEvents, setArchiveEvents] = useState([]);

    const fetchEvents = () => {

        let postData = { merchant_id: merchant_info._id };
        // setIsLoading(true);
        client.post(api.fetchEvents, postData, (error, response) => {
    
            if (!error) {
                if (!response.error) {
                    // console.log(response)
                    setAllEvents(response.data)
                    setLoading(false);
                } else {
                    // setIsLoading(false);
                    toast.error("Internal error occured.Please contact support");
                }
            } else {
                toast.error("Internal error occured.Please contact support");
                // setIsLoading(false);
            }
        });
    }

    useEffect(()=>{
        fetchEvents();
    },[])

    function getHighestDate(dates) {
        if (dates.length === 0) return null;
        let highestDate = dates[0];
        for (let i = 1; i < dates.length; i++) {
            if (dates[i] > highestDate) highestDate = dates[i];
        }
        return highestDate;
    }

    function getEventActualTimeWithHigestCompare(elem) {
        let eventTimeEnd = elem?.session_end_hour?.value + ":" + elem?.session_end_minutes?.value + " " + elem?.session_end_meridiem?.value
        let bookingTimeEnd = elem?.booking_end_hour?.value + ":" + elem?.booking_end_minutes?.value + " " + elem?.booking_end_meridiem?.value
        let eventActualtime
        if (elem.session_type == "single_session") {
            eventActualtime = Math.max(momentT(momentT(Number(elem?.sessions_end_date)).format('DD-MM-YYYY') + " " + eventTimeEnd, 'DD-MM-YYYY hh:mm A').unix(), momentT(momentT(Number(elem?.booking_end_date)).format('DD-MM-YYYY') + " " + bookingTimeEnd, 'DD-MM-YYYY hh:mm A').unix())
        } else {
            if (elem?.occurrence?.value == "multiple") {
                eventActualtime = Math.max(momentT(momentT(Number(getHighestDate(elem?.multiple_sessions_date))).format('DD-MM-YYYY') + " " + eventTimeEnd, 'DD-MM-YYYY hh:mm A').unix(), momentT(momentT(Number(elem?.booking_end_date)).format('DD-MM-YYYY') + " " + bookingTimeEnd, 'DD-MM-YYYY hh:mm A').unix())
            } else {
                eventActualtime = Math.max(momentT(momentT(Number(elem?.sessions_end_date)).format('DD-MM-YYYY') + " " + eventTimeEnd, 'DD-MM-YYYY hh:mm A').unix(), momentT(momentT(Number(elem?.booking_end_date)).format('DD-MM-YYYY') + " " + bookingTimeEnd, 'DD-MM-YYYY hh:mm A').unix())
            }
        }
        return eventActualtime;
    }

    useEffect(() => {
        let timeZone = merchant_info?.timeZone

        setUpcomingEvents(() => {
            return (
                allEvents?.filter(elem => {
                    return getEventActualTimeWithHigestCompare(elem) > momentT().tz(timeZone).unix()
                })?.filter(e => e?.isArchive !== true)?.sort((a, b) => {
                    let eventTimeEndA = a?.session_end_hour?.value + ":" + a?.session_end_minutes?.value + " " + a?.session_end_meridiem?.value
                    let eventActualtimeA
                    if (a.session_type == "single_session") {
                        eventActualtimeA = momentT(momentT(Number(a?.sessions_end_date)).format('DD-MM-YYYY') + " " + eventTimeEndA, 'DD-MM-YYYY hh:mm A').unix()
                    } else {
                        if (a?.occurrence?.value == "multiple") {
                            eventActualtimeA = momentT(momentT(Number(getHighestDate(a?.multiple_sessions_date))).format('DD-MM-YYYY') + " " + eventTimeEndA, 'DD-MM-YYYY hh:mm A').unix()
                        } else {
                            eventActualtimeA = momentT(momentT(Number(a?.sessions_end_date)).format('DD-MM-YYYY') + " " + eventTimeEndA, 'DD-MM-YYYY hh:mm A').unix()
                        }
                    }

                    let eventTimeEndB = b?.session_end_hour?.value + ":" + b?.session_end_minutes?.value + " " + b?.session_end_meridiem?.value
                    let eventActualtimeB
                    if (b.session_type == "single_session") {
                        eventActualtimeB = momentT(momentT(Number(b?.sessions_end_date)).format('DD-MM-YYYY') + " " + eventTimeEndB, 'DD-MM-YYYY hh:mm A').unix();
                    } else {
                        if (b?.occurrence?.value == "multiple") {
                            eventActualtimeB = momentT(momentT(Number(getHighestDate(b?.multiple_sessions_date))).format('DD-MM-YYYY') + " " + eventTimeEndB, 'DD-MM-YYYY hh:mm A').unix()
                        } else {
                            eventActualtimeB = momentT(momentT(Number(b?.sessions_end_date)).format('DD-MM-YYYY') + " " + eventTimeEndB, 'DD-MM-YYYY hh:mm A').unix()
                        }
                    }
                    return eventActualtimeA - eventActualtimeB
                })
            )
        })

        function getHighestDate(dates) {
            if (dates.length === 0) return null;
            let highestDate = dates[0];
            for (let i = 1; i < dates.length; i++) {
                if (dates[i] > highestDate) highestDate = dates[i];
            }
            return highestDate;
        }

        setPastEvents(() => {
            return (
                allEvents?.filter(elem => {
                    return getEventActualTimeWithHigestCompare(elem) < momentT().tz(timeZone).unix()
                })?.filter(e => e?.isArchive !== true)?.sort((a, b) => {
                    let eventTimeEndA = a?.session_end_hour?.value + ":" + a?.session_end_minutes?.value + " " + a?.session_end_meridiem?.value
                    let eventTimeEndB = b?.session_end_hour?.value + ":" + b?.session_end_minutes?.value + " " + b?.session_end_meridiem?.value

                    let eventActualtimeA
                    if (a.session_type == "single_session") {
                        eventActualtimeA = momentT(momentT(Number(a?.sessions_end_date)).format('DD-MM-YYYY') + " " + eventTimeEndA, 'DD-MM-YYYY hh:mm A').unix()
                    } else {
                        if (a?.occurrence?.value == "multiple") {
                            eventActualtimeA = momentT(momentT(Number(a?.multiple_sessions_date?.[a?.multiple_sessions_date?.length - 1])).format('DD-MM-YYYY') + " " + eventTimeEndB, 'DD-MM-YYYY hh:mm A').unix()
                        } else {
                            eventActualtimeA = momentT(momentT(Number(a?.sessions_end_date)).format('DD-MM-YYYY') + " " + eventTimeEndA, 'DD-MM-YYYY hh:mm A').unix()
                        }
                    }

                    let eventActualtimeB
                    if (b.session_type == "single_session") {
                        eventActualtimeB = momentT(momentT(Number(b?.sessions_end_date)).format('DD-MM-YYYY') + " " + eventTimeEndB, 'DD-MM-YYYY hh:mm A').unix()
                    } else {
                        if (b?.occurrence?.value == "multiple") {
                            eventActualtimeB = momentT(momentT(Number(b?.multiple_sessions_date?.[b?.multiple_sessions_date?.length - 1])).format('DD-MM-YYYY') + " " + eventTimeEndB, 'DD-MM-YYYY hh:mm A').unix()
                        } else {
                            eventActualtimeB = momentT(momentT(Number(b?.sessions_end_date)).format('DD-MM-YYYY') + " " + eventTimeEndB, 'DD-MM-YYYY hh:mm A').unix()
                        }
                    }
                    return eventActualtimeB - eventActualtimeA
                })
            )
        })

        setArchiveEvents(() => {
            return allEvents?.filter(event => event?.isArchive === true)
        })

    }, [activeTab, allEvents])

  const toggle = () => setModal(!modal);

      useEffect(() => {
    if (typeof window !== 'undefined') {
        if (window.location.hostname == "dm.admin.ipaypro.co") {
            setRedirectLink(`https://dm.linky.biz/${merchant_info?.unique_id}/events`)
        } else if (window.location.hostname == "admin.ipaypro.co") {
            setRedirectLink(`https://linky.biz/${merchant_info?.unique_id}/events`)
        } else {// update host as per need 
            setRedirectLink(`http://localhost:3000/${merchant_info?.unique_id}/events`)
        }
    }
}, [redirectLink])

const copyTextToolTipToggle = () => { setCopyTextToolTip(!copyTextToolTip) };

const downloadTaxInvoice = (data) => {

    Swal.fire({
        width: 300,
        title: 'Generating Invoice',
        html: ' Please Wait...!',
        // allowOutsideClick: false,
        onOpen: () => { Swal.showLoading() },
    });

    axios.get(client.url(`${api.downloadInvoice}?request_id=${data._id}&type=${4}&ticket_type=${'owner'}`), {
        responseType: "blob",
        headers: { "x-access-token": "ipay-access-token", },
        params: {},
    }).then((res) => {
        Swal.close();
        fileDownload(res.data, `Invoice.pdf`);
    }).catch(function (error) {
        toast.error('Error occured while generating invoice');
        Swal.close();
    });
}

const updatePaymentStatusFun = (ticket_id) => {
    // console.log(ticket_id)
    let postdata = {
        request_id: ticket_id
    }

    client.post(api.update_ticket_payment_status, postdata, (error, response) => {
        console.log(response,postdata,'::res')
        if (!error) {
            if (!response.error) {
                console.log(response)
                toggle()
            } else {
                console.log('else1 error');
            }
        } else {
            console.log('else2 error')
        }
    });
}

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber)
    fetchPaymentLinksData();
  }

  const fetchPaymentLinksData = (searchValue) => {
    const postData = {
      merchant_id: merchant_id,
      request_from: 3,
        pageSize,
        pageIndex: activePage - 1,
        dateFilterType: filterDuration.value,
        startDate,
        endDate,
        paidOnly: true,
        searchInputVal: searchValue == "reset" ? null : searchInputVal,
    }
    client.post(api.link_requests, postData, (error, response) => {
        if (!error) {
            if (!response.error) {
                // console.log(response,'request-response')
                setRequestData(response.result);
                setData(response.recorsCount);
                setLoading(false);
            } else {
                console.log(error, 'error')
            }
        } else {
            console.log(error,'error-1')
        }
    });
  };

  useEffect(()=>{
    if(requestData.length==0)
    fetchPaymentLinksData();
  },[pageSize, pageIndex,activePage,updatePaymentStatusFun])

  console.log(requestData)

  const getTimeZone = () => {

      try {
        const company = JSON.parse(localStorage.getItem('@_company'));
    
        if (company && company.timeZone) {
          return company.timeZone;
        } else {
          return "Australia/Sydney";
        }
      } catch {
        return "Australia/Sydney";
      }
    
  }  

  const ShowCurrencyNum = (amount, showSymbol) => {
    const merchantData = JSON.parse(localStorage.getItem("merchant"));
    const currency_symbol = merchant_info?.country?.currency_symbol;
    if (amount == undefined) {
      return currency_symbol;
    }

    let getCurrency = !isNaN(parseFloat(amount)) ? amount : 0;
    
    if (merchant_info && merchant_info.country) {
      if (showSymbol && currency_symbol) {
        if (getCurrency < 0) {
          getCurrency = `-${currency_symbol}${Math.abs(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
        } else {
          getCurrency = `${currency_symbol}${parseFloat(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
        }
      }
      else {
        getCurrency = +`${currency_symbol}${parseFloat(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
      }
    }

    return getCurrency;
  }

  const RenderRefundStatus = ({ refund_data }) => {

    if (refund_data) {
        
        return (
            <>
                {refund_data.type == 1 ?
                    <Badge color="disabled" style={{width:'80%',display:'flex',justifyContent:'center'}}>Refunded</Badge>
                    :
                    // <p>
                    //     Partially refunded ({ShowCurrencyNum(refund_data?.amount, true)})
                    // </p>
                    <div className='d-flex flex-column align-items-center'>
                        <Badge color="disabled" 
                            style={{
                                width:'80%',
                                display:'flex',
                                justifyContent:'center',
                            }}
                        >
                            Partially refunded
                        </Badge>
                        <span>({ShowCurrencyNum(refund_data?.amount, true)})</span>
                    </div>
                    
                    
                }
            </>
        )
    } else {
        return null
    }
  }

  const handleSearch = (event)=>{
    event.preventDefault();
    if(searchInputVal==null || searchInputVal==""){
      fetchPaymentLinksData("reset");
  }
    fetchPaymentLinksData(searchInputVal);
  }
  
  const toggleDeleteModal = (item) => {
      setDeleteLoyaltyCard(!deleteLoyaltyCard);
      setselectedIDs(item);
  };

 function getMerchantStoreInfo(unique_id)
	{


		client.post(api.merchant_details_store, { merc_unique_id: unique_id},(error, response)=> {
				if (!error) {
					if (typeof response.error !== "undefined") {
						try {
							if (!response.error) {
								try {
									setMerchantUserTimezone(response?.result?.contactDetails?.timeZone)
									
									
								} catch (error) {
									console.log(error)
								}
							} else {
								setTimeout(() => {
									toast.error(response.message);
								}, 200);
							}
						} catch (e) {
							setTimeout(() => {
								toast.error("Internal error occured. Please contact support");
							}, 200);
						}
					} else {
						setTimeout(() => {
							toast.error("Internal error occured. Please contact support");
						}, 200);
					}
				} else {
					setTimeout(() => {
						toast.error("Internal error occured. Please contact support");
					}, 200);
				}
			})

	}

    useEffect(()=>{

        getMerchantStoreInfo(merchant_info?.unique_id)
    },[merchant_info])

  const deleteCard = (item) => {
    setDeleteLoader(true);
    let post = { link_id: item };

    client.post(api.delete_requests, post, (error, response) => {
        if (!error) {
            if (typeof response.error !== "undefined") {
                try {
                    if (!response.error) {
                        setLoading(false);
                        toggleDeleteModal();
                        fetchPaymentLinksData();
                        toast.success("Deleted Successfully");
                        setDeleteLoader(false);
                    } else {
                        toast.error("Internal error occured.Please contact support.");
                        setLoading(false);
                        setDeleteLoader(false);
                    }
                } catch (e) {
                    toast.error("Internal error occured.Please contact support.");
                    setLoading(false);
                    setDeleteLoader(false);
                }
            } else {
                toast.error("Internal error occured.Please contact support.");
                setLoading(false);
                setDeleteLoader(false);
            }
        } else {
            toast.error("Internal error occured.Please contact support.");
            setLoading(false);
            setDeleteLoader(false);
        }
        setLoading(false);
        setDeleteLoader(false);
    });
  };

  const onDeleteCardModal = (item) => {
    return (
        <Modal
            isOpen={deleteLoyaltyCard}
            toggle={toggleDeleteModal}
            centered={true}
            style={{ maxWidth: "400px" }}
        >
            <form style={{ paddingInline: "" }}>
                <ModalHeader
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "0.25rem",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                width: 40,
                                height: 40,
                                borderRadius: "50%",
                                border: "1px solid #E74C3C",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <CloseIcon color={"#E74C3C"} />
                        </div>
                        <span style={{ marginTop: "0.25rem", marginBottom: "0.5rem" }}>

                            <b>
                                Attention!
                            </b>
                        </span>
                    </div>
                </ModalHeader>
                <ModalBody style={{textAlign:'center'}}>
                    Are you sure you want to delete Payment Link?
                </ModalBody>
                <ModalFooter
                    style={{ justifyContent: "space-evenly", borderTop: "none" }}
                >
                    <Button
                        size={"sm"}
                        style={{ marginBottom: 0, background: "#e7e2e2" }}
                        onClick={() => toggleDeleteModal()}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="danger"
                        id="deleteBtn"
                        size={"sm"}
                        style={{ marginBottom: 0 }}
                        onClick={(e) => deleteCard(item)}
                    >
                        {deleteLoader ? (
                            <BeatLoader color={"#fff"} loading={true} size="7" />
                        ) : (
                            "Delete"
                        )}
                    </Button>
                </ModalFooter>
            </form>
        </Modal>
    );
  };
  
  return(
    <Fragment>
      {onDeleteCardModal(selectedIDs)}
      {viewTransaction?
        <ViewTransaction data={transactionData}  merchant_id={merchant_info?._id}  countryData={merchant_info?.country} onBackClick={() => setViewTransaction(!viewTransaction)} />
        :
        <div>
            <div>
                <Tabs className="col-sm-12">
                    <TabList className="tabs tab-title" style={{ marginBottom: '0px !important' }}>
                        <Tab style={{ marginTop: "0.5rem", display: "flex", alignItems: "center" }}>Manage Events</Tab>
                        <Tab style={{ marginTop: "0.5rem", display: "flex", alignItems: "center" }}>Ticket Sales</Tab>
                    </TabList>
                    <TabPanel>
                        <div className="">
                        <div>
                            <div className='px-4 pt-4 pb-2'>
                                <div className=' bg-white'>
                                    <div className='mb-xl-3 table_reservation_heading_block mb-2 flex-xl-row flex-column' style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <div>
                                            <p className='m-0 font-weight-bold' style={{ fontSize: '16px', color: 'black' }}> Tickets Preview Link </p>
                                            <p className='m-0 mob-font'>Share below ticket link with your customers to book tickets online</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex flex-md-row flex-column justify-content-between mb-0 mt-3 mt-md-0'>
                                    <div className='d-flex flex-md-row flex-column align-items-start '>
                                        {redirectLink != "" && <div className="border rounded px-3 mr-0 mr-md-0 d-block d-flex align-items-center" style={{ background: '#e9ecef', width:  "100%", height:  '36.2px' }}>
                                            <div className='d-flex align-items-center justify-content-between w-100'>
                                                <h5 className="my-0 mr-xl-4 mr-2" style={{ color: 'grey', fontSize: "14px" }}> {redirectLink} </h5>
                                                <div className='d-flex d-flex justify-content-center align-items-center'>
                                                    <a className='d-flex justify-content-center' href={false ? null : redirectLink} target='_blank'>
                                                        <Icon path={mdiOpenInNew} className="mx-3" size={0.9} color="gray" />
                                                    </a>

                                                    <span id="link-copy1212" className="d-flex justify-content-center">
                                                        <Icon
                                                            path={mdiContentCopy}
                                                            size={0.8}
                                                            color="gray"
                                                            onClick={() => {
                                                                if (!false) {
                                                                    navigator.clipboard.writeText(redirectLink);
                                                                    copyTextToolTipToggle();
                                                                }
                                                            }}
                                                        />
                                                    </span>
                                                    <br />
                                                </div>
                                                {
                                                    copyTextToolTip && (
                                                        <Tooltip placement="bottom" toggle={copyTextToolTipToggle} target="link-copy1212" isOpen={copyTextToolTip}>
                                                            Copied!
                                                        </Tooltip>
                                                    )
                                                }
                                            </div>
                                        </div>}
                                </div>
                            </div>

                            <div className="meet_tabs mt-3">
                                <Nav tabs className='border-0'>
                                    <NavItem>
                                        <NavLink className={activeTab == '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                                            Upcoming
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={activeTab == '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                                           Past
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={activeTab == '3' ? 'active' : ''} onClick={() => setActiveTab('3')}>
                                           Archived
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>
                        </div>
                        </div>
                        <div>
                            <EventTableComp merchant_info={merchant_info} allEvents={activeTab == 1 ? upcomingEvents : activeTab == 2 ? pastEvents : archiveEvents} setAllEvents={setAllEvents}/>
                        </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div>
                            {loading && <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                    <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                        <DisappearedLoading size="medium" color="#514F4E" />
                    </div>
                </div>}
        <div className="m-1 p-1 mt-2" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div >
                        <form onBlur={handleSearch} onSubmit={(event) => handleSearch(event)}>
                            <div >
                                <input style={{
                                    border: "none",
                                    borderBottom: "1px solid rgb(197, 189, 189)",
                                    borderRadius: "0",
                                    margin: "3px 10px",
                                    fontSize: "0.75rem",

                                }}
                                    type="text"
                                    placeholder="Search"
                                    onChange={(e)=>{
                                        setSearchInputVal(e.target.value)
                                        fetchPaymentLinksData(searchInputVal)
                                    }} 
                                />
                            </div>
                        </form>
                    </div>
                    {(activePage) * pageSize > requestData?.length ?
                        <p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>Showing {((activePage - 1) * pageSize) + 1} to {((activePage - 1) * pageSize)+requestData?.length} of {data} entries</p> :
                        <p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>Showing {((activePage - 1) * pageSize) + 1} to {(activePage) * pageSize} of {data} entries</p>}
                </div>
      <div className="table-responsive mx-1 px-1 my-1 py-1">
        <Table className="table table--border-horizontal">
          <thead>
            <tr>
              <th className="border">#</th>
              <th className="border">Date of booking</th>
              <th className="border">Event Name / Date and time</th>
              <th className="border">ID</th>
              <th className="border">Customer Details</th>
              <th className="border">No of tickets booked</th>
              <th className="border">Promoter</th>
              <th className="border">Amount / Payment Method</th>
              <th className="border">Status</th>
              <th className="border">Action</th>
            </tr>
          </thead>
          { 
            requestData?.length ? (
              <tbody>
                {
                  requestData.map((item,ind)=>{
                    let recordIndex = pageSize*(activePage-1)+(ind+1);
                    const convert_date = new Date(item.status_update_date);
                    const added_date = moment.tz(convert_date,merchantUserTimezone).format("DD/MM/YYYY HH:mm");
                    let dueDate = new Date(item.status_update_date);
                        dueDate.setDate(dueDate.getDate() + 60);
                        dueDate = moment.tz(dueDate,merchantUserTimezone).format("DD/MM/YYYY");

                    let customerDetails = 'NA';
                    let cusData = item.ticket_Cus;
                      if(cusData){
                        if (cusData.name && cusData.name.length) {
                          customerDetails = `Name - ${cusData.name.substring(0, 1).toUpperCase()}${cusData.name.substring(1)}`
                      }

                      if (cusData.mobile && cusData.mobile.length) {
                          customerDetails = `${customerDetails}\nMobile - ${cusData?.mobile_prefix || ''}${cusData.mobile}`
                      }

                      if (cusData.email && cusData.email.length) {
                          customerDetails = `${customerDetails}\nEmail - ${cusData.email}`
                      }

                      if (cusData.date_of_birth && cusData.date_of_birth.length) {
                          customerDetails = `${customerDetails}\nDOB - ${cusData.date_of_birth}`
                      }
                    }
                        return(
                          <tr>
                              <td className="border">{recordIndex}</td>
                              <td className="border">
                                  {added_date}
                              </td>
                              <td className="border">
                                  {item?.ticketName} <br/> {item?.ticket_data ? `${moment(Number(item?.ticket_data?.startDate)).format("DD/MM/YYYY")} ${item?.ticket_data?.startTime} - ${item?.ticket_data?.endTime}` : null}
                              </td>
                              <td className="border" style={{ minWidth: "100px", wordWrap: "break-word" }}>
                                  {item?.ticket_data ? <a style={{textDecoration: 'underline'}} href={`https://${window.location.hostname=="dm.admin.ipaypro.co"?"demo.payments.linky.biz":"payments.linky.biz"}/paynow/${item.token_id}`} target="_blank">{`ST-${item.token_id}`}</a> : `ST-${item.token_id}`}
                              </td>
                              <td className="border" style={{whiteSpace: 'pre'}}>
                                {customerDetails}
                              </td>
                              <td className="border">
                              {`${item.qnt}`} 
                              </td>
                              <td className="border">
                                -
                              </td>
                              <td className="border">
                                {item?.ticket_data?.ticketType == 'free' ? `Free` : ShowCurrencyNum(item.amount.toFixed(2), true)} <br/> {item.status == 1 ? item.payment_method == 3 ? `Cash` : item.payment_method == 2 ? `Card` : item?.ticket_data?.ticketType == 'free' ? " " : "Linky Wallet" : '-'}
                              </td>
                              <td className="border">
                                  {item.refund_status == 1 ? (
                                      <div className="d-flex justify-content-center">
                                          <RenderRefundStatus
                                              refund_data={item?.refund_data}
                                              style={{ minWidth: '105px', maxWidth: '105px',transform: 'scale(0.9)' }}
                                          />
                                      </div>
                                  ) : (
                                      <div>
                                          {item.status == 0 ? (
                                            <span
                                                style={{
                                                    backgroundColor: "transparent",
                                                    borderRadius: "20px",
                                                    color: "red"
                                                }}
                                            >
                                                {
                                                    item.amount > 0 ?
                                                    <div className="d-flex justify-content-center">
                                                        <Badge style={{
                                                            background:'#FFBD57', 
                                                            color:'white',
                                                            width:'80%',    
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            transform: 'scale(0.9)'
                                                            }}>Unpaid</Badge>
                                                    </div>
                                                    : ""
                                                }
                                            </span>
                                          ) : item.status == 1 ? (
                                            <>
                                            {
                                                item.payment_status == 1 && item.payment_method == 3 ?
                                                <div className="d-flex justify-content-center">
                                                    <Badge style={{background:'green', color:'white', minWidth:'100px', maxWidth:'130px', display: 'flex',justifyContent: 'center',padding: '0.5rem', borderRadius: '1rem', fontSize: '12px', fontWeight:'500',transform: 'scale(0.9)'}}>Payment Collected</Badge>
                                                </div>
                                            : item.payment_status == 0 && item.payment_method == 3 ?
                                                <div className="d-flex justify-content-center" onClick={() => { setSelectedTicketID(item._id); toggle(); }}>
                                                    <span style={{
                                                        background: 'red',
                                                        color: 'white',
                                                        minWidth: '130px', maxWidth: '130px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        flexDirection: 'row',
                                                        borderRadius: 15,
                                                        fontSize: 12,
                                                        paddingTop: 2,
                                                        paddingBottom: 2,
                                                        paddingLeft: 5,
                                                        cursor: 'pointer',
                                                        textAlign: 'center',
                                                        transform: 'scale(0.9)',
                                                        alignItems:'center'
                                                    }}>
                                                        <span>Payment Pending</span> 
                                                        <Icon path={mdiChevronDown} size={1} style={{ paddingTop: -2 }} color={'#fff'} />
                                                    </span>
                                                </div>
                                                : 
                                                <div className="d-flex justify-content-center">
                                                    <Badge style={{
                                                        background: 'green',
                                                        color: 'white',
                                                        minWidth: '100px', maxWidth: '130px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        padding: '0.5rem',
                                                        borderRadius: '1rem',
                                                        fontSize: '12px',
                                                        fontWeight: '500',
                                                        transform: 'scale(0.9)'
                                                    }}>
                                                        Paid Online
                                                    </Badge>
                                                </div>
                                            }
                                            </>
                                          ) : item.status == 2 ? (
                                                <div className="d-flex justify-content-center">
                                                    <Badge style={{
                                                        background: 'grey',
                                                        color: 'white',
                                                        minWidth: '105px', maxWidth: '105px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        transform: 'scale(0.9)'
                                                    }}>
                                                        Expired
                                                    </Badge>
                                                </div>
                                          ) : item.status == 3 ? (
                                            <div className="d-flex justify-content-center">
                                                <Badge style={{
                                                    background: 'red',
                                                    color: 'white',
                                                    minWidth: '105px', maxWidth: '105px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    transform: 'scale(0.9)'
                                                }}>
                                                    Marked Suspicious
                                                </Badge>
                                            </div>
                                          ) : null}
                                      </div>
                                  )}
                              </td>
                              <td className="border">
                                  <div>
                                      {item.status == 1 ? (
                                        <div className="d-flex" style={{gap:'0.3rem'}}>
                                            <Button
                                                  outline
                                                  style={{
                                                      padding: "2px",
                                                  }}
                                                  className="d-flex align-items-center m-0"
                                                  color="success"
                                                  onClick={()=>{
                                                    setViewTransaction(!viewTransaction)
                                                    setTransactionData({
                                                        merchant_id: merchant_id,
                                                        wallet_amount: '',
                                                        data: item,
                                                        view_type: 0,
                                                        disabled_refund: '',
                                                    })
                                                  }}
                                              >
                                                  <Icon
                                                      path={mdiEye}
                                                      className="mx-auto"
                                                      size={0.8}
                                                      color="success"
                                                  />
                                              </Button>
                                              {/* <Button
                                                outline
                                                style={{ padding: "2px" }}
                                                className="d-flex align-items-center m-0"
                                                color="danger"
                                                onClick={() => {
                                                    toggleDeleteModal(item);
                                                }}
                                            >
                                                <Icon
                                                    path={mdiTrashCanOutline}
                                                    className="mx-auto"
                                                    size={0.8}
                                                    color="#DC3545"
                                                />
                                            </Button> */}
                                            <Button
                                                outline
                                                style={{ padding: "2px", }}
                                                className="d-flex align-items-center m-0"
                                                color="success"
                                                onClick={() => { downloadTaxInvoice(item) }}
                                            >
                                                <Icon path={mdiDownloadOutline} className="mx-auto" size={0.8} color="success" />
                                            </Button>
                                        </div>
                                      ) : item.status == 0 ? (
                                        <Button
                                            outline
                                            style={{ padding: "2px" }}
                                            className="d-flex align-items-start m-0"
                                            color="danger"
                                            onClick={() => {
                                                toggleDeleteModal(item);
                                            }}
                                        >
                                            <Icon
                                                path={mdiTrashCanOutline}
                                                className="mx-auto"
                                                size={0.8}
                                                color="#DC3545"
                                            />
                                        </Button>
                                    ) : null}
                                  </div>
                              </td>
                          </tr>
                        )
                  })
                }
              </tbody>
            ):
              <h6 style={{ textAlign: "center", marginTop: "1rem" }}> No records found! </h6>
            
          }
        </Table>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <select className="custom-select" style={{ width: "35%", fontSize: "0.63rem", marginRight: "10px" }} onChange={(event) => setPageSize(event.target.value)} value={pageSize}>
                    <option value="15">15</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                </select>
                <span style={{ fontSize: "0.63rem" }}>Records per Page</span>
            </div>
            <PaginationComponent
                totalItems={data}
                pageSize={pageSize}
                onSelect={handlePageChange} 
                defaultActivePage={activePage}
            />
        </div>
      </div> 
                        </div>
                        {modal && selectedTicketID != null &&
                                    <Modal isOpen={modal} centered={true} toggle={toggle}>
                                        {/* <ModalHeader toggle={toggle}>Modal title</ModalHeader> */}
                                        <ModalBody>
                                            <h4>Are you sure want to update ticket payment status?</h4>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color="primary" onClick={() => { updatePaymentStatusFun(selectedTicketID) }}>
                                                Continue
                                            </Button>{' '}
                                            <Button color="secondary" onClick={toggle}>
                                                Cancel
                                            </Button>
                                        </ModalFooter>
                                    </Modal>
                                }
                    </TabPanel>
                </Tabs>
            </div>
          {/* {loading && <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                    <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                        <DisappearedLoading size="medium" color="#514F4E" />
                    </div>
                </div>}
        <div className="m-1 p-1 mt-2" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div >
                        <form onBlur={handleSearch} onSubmit={(event) => handleSearch(event)}>
                            <div >
                                <input style={{
                                    border: "none",
                                    borderBottom: "1px solid rgb(197, 189, 189)",
                                    borderRadius: "0",
                                    margin: "3px 10px",
                                    fontSize: "0.75rem",

                                }}
                                    type="text"
                                    placeholder="Search"
                                    onChange={(e)=>{
                                        setSearchInputVal(e.target.value)
                                        fetchPaymentLinksData(searchInputVal)
                                    }} 
                                />
                            </div>
                        </form>
                    </div>
                    {(activePage) * pageSize > requestData?.length ?
                        <p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>Showing {((activePage - 1) * pageSize) + 1} to {((activePage - 1) * pageSize)+requestData?.length} of {data} entries</p> :
                        <p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>Showing {((activePage - 1) * pageSize) + 1} to {(activePage) * pageSize} of {data} entries</p>}
                </div>
      <div className="table-responsive mx-1 px-1 my-1 py-1">
        <Table className="table table--border-horizontal">
          <thead>
            <tr>
              <th className="border">#</th>
              <th className="border">Date/Time</th>
              <th className="border">Payment No</th>
              <th className="border">Customer Details</th>
              <th className="border">Description</th>
              <th className="border">Payment Method</th>
              <th className="border">Amount</th>
              <th className="border">Status</th>
              <th className="border">Action</th>
            </tr>
          </thead>
          { 
            requestData?.length ? (
              <tbody>
                {
                  requestData.map((item,ind)=>{
                    let recordIndex = pageSize*(activePage-1)+(ind+1);
                    const convert_date = new Date(item.request_date);
                    const added_date = moment.tz(convert_date,getTimeZone()).format("DD/MM/YYYY HH:mm");
                    let dueDate = new Date(item.request_date);
                        dueDate.setDate(dueDate.getDate() + 60);
                        dueDate = moment.tz(dueDate, getTimeZone()).format("DD/MM/YYYY");

                    let customerDetails = 'NA';
                    let cusData = item.ticket_Cus;
                      if(cusData){
                        if (cusData.name && cusData.name.length) {
                          customerDetails = `Name - ${cusData.name.substring(0, 1).toUpperCase()}${cusData.name.substring(1)}`
                      }

                      if (cusData.mobile && cusData.mobile.length) {
                          customerDetails = `${customerDetails}\nMobile - ${cusData?.mobile_prefix || ''}${cusData.mobile}`
                      }

                      if (cusData.email && cusData.email.length) {
                          customerDetails = `${customerDetails}\nEmail - ${cusData.email}`
                      }

                      if (cusData.date_of_birth && cusData.date_of_birth.length) {
                          customerDetails = `${customerDetails}\nDOB - ${cusData.date_of_birth}`
                      }
                    }
                        return(
                          <tr>
                              <td className="border">{recordIndex}</td>
                              <td className="border">
                                  {`${added_date}`.replace(
                                      /(.{10})/g,
                                      "$1\n"
                                  )}
                              </td>
                              <td className="border">
                                  {`ST-${item.token_id}`}
                              </td>
                              <td className="border" style={{ whiteSpace: 'pre' }}>
                                  {customerDetails}
                              </td>
                              <td className="border">
                              {`${item.ticketName} (Qty ${item.qnt})`} 
                              </td>
                              <td className="border">
                                {item.status == 1 ? item.payment_method == 3 ? `Cash` : item.payment_method == 2 ? "Card" : "IpayPro" : '-'}
                              </td>
                              <td className="border">
                                {item.amount > 0 ? ShowCurrencyNum(item.amount.toFixed(2), true) : `-`}
                              </td>
                              <td className="border">
                                  {item.refund_status == 1 ? (
                                      <div className="d-flex justify-content-center">
                                          <RenderRefundStatus
                                              refund_data={item?.refund_data}
                                          />
                                      </div>
                                  ) : (
                                      <div>
                                          {item.status == 0 ? (
                                              <div className="d-flex justify-content-center">
                                                  <Badge style={{
                                                      background:'#FFBD57', 
                                                      color:'white',
                                                      width:'80%',    
                                                      display: 'flex',
                                                      justifyContent: 'center',
                                                      }}>Unpaid</Badge>
                                              </div>
                                          ) : item.status == 1 ? (
                                              <div className="d-flex justify-content-center">
                                                  <Badge style={{background:'green', color:'white', width:'80%', display: 'flex',
                                                      justifyContent: 'center',}}>Paid</Badge>
                                              </div>
                                          ) : item.status == 2 ? (
                                              <span
                                                  style={{
                                                      backgroundColor: "transparent",
                                                      borderRadius: "20px",
                                                      color: "red",
                                                  }}
                                              >
                                                  Expired
                                              </span>
                                          ) : item.status == 3 ? (
                                              <span
                                                  style={{
                                                      backgroundColor: "transparent",
                                                      borderRadius: "20px",
                                                      color: "red",
                                                      cursor: "pointer",
                                                  }}
                                                  // onClick={() =>
                                                  //     handleSuspicious(
                                                  //         item.suspiciousReason
                                                  //     )
                                                  // }
                                              >
                                                  Marked Suspicious
                                              </span>
                                          ) : null}
                                      </div>
                                  )}
                              </td>
                              <td className="border">
                                  <div>
                                      {item.status == 1 ? (
                                        <div className="d-flex" style={{gap:'0.3rem'}}>
                                            <Button
                                                  outline
                                                  style={{
                                                      padding: "4px 6px",
                                                  }}
                                                  className="d-flex align-items-start m-0"
                                                  color="success"
                                                  onClick={()=>{
                                                    setViewTransaction(!viewTransaction)
                                                    setTransactionData({
                                                        merchant_id: merchant_id,
                                                        wallet_amount: '',
                                                        data: item,
                                                        view_type: 0,
                                                        disabled_refund: '',
                                                    })
                                                  }}
                                              >
                                                  <Icon
                                                      path={mdiEye}
                                                      className="mx-auto"
                                                      size={0.6}
                                                      color="success"
                                                  />
                                              </Button>
                                              <Button
                                                outline
                                                style={{ padding: "4px 6px" }}
                                                className="d-flex align-items-start m-0"
                                                color="danger"
                                                onClick={() => {
                                                    toggleDeleteModal(item);
                                                }}
                                            >
                                                <Icon
                                                    path={mdiTrashCanOutline}
                                                    className="mx-auto"
                                                    size={0.8}
                                                    color="#DC3545"
                                                />
                                            </Button>
                                        </div>
                                      ) : item.status == 0 ? (
                                        <Button
                                            outline
                                            style={{ padding: "4px 6px" }}
                                            className="d-flex align-items-start m-0"
                                            color="danger"
                                            onClick={() => {
                                                toggleDeleteModal(item);
                                            }}
                                        >
                                            <Icon
                                                path={mdiTrashCanOutline}
                                                className="mx-auto"
                                                size={0.8}
                                                color="#DC3545"
                                            />
                                        </Button>
                                    ) : null}
                                  </div>
                              </td>
                          </tr>
                        )
                  })
                }
              </tbody>
            ):
              <h6 style={{ textAlign: "center", marginTop: "1rem" }}> No records found! </h6>
            
          }
        </Table>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <select className="custom-select" style={{ width: "35%", fontSize: "0.63rem", marginRight: "10px" }} onChange={(event) => setPageSize(event.target.value)} value={pageSize}>
                    <option value="15">15</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                </select>
                <span style={{ fontSize: "0.63rem" }}>Records per Page</span>
            </div>
            <PaginationComponent
                totalItems={data}
                pageSize={pageSize}
                onSelect={handlePageChange} 
                defaultActivePage={activePage}
            />
        </div>
      </div> */}
        </div>
        }
      
    </Fragment>
  )
}

export default RequestPayments