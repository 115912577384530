import React, { Fragment, useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { DisappearedLoading } from "react-loadingg";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import PaginationComponent from "react-reactstrap-pagination";
import DeleteOutlineIcon from "@material-ui/icons/Delete";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import DescriptionOutlined from "@material-ui/icons/DescriptionOutlined";
import ErrorIcon from "@material-ui/icons/Error";
import moment from "moment-timezone";
import "./WhiteLabelTable.scss";
import useForm from "react-hook-form";


const WhiteLabelTable = ({
    whiteLabelMerchantData,
    handleSearchSubmit,
    handlePageChange,
    updateStatus,
    actionLoading,
    deleteWhiteLabelMerchant,
    toggleShowInvoice,
    defaultVaue,
    searchValue,
    pageSize,
    activePage,
    setPageSize,
    totalData,
    setSelectedWLMData,
}) => {

    let { handleSubmit, register, setValue, errors } = useForm({
        defaultValues: defaultVaue
    });

    // console.log(whiteLabelMerchantData, "whiteLabelMerchantData");


    const [loading, setLoading] = useState(false);
    const [data, seData] = useState(whiteLabelMerchantData);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [getStatus, setGetStatus] = useState(null);
    const [showError, setShowError] = useState(false);
    const [descriptin, setDescription] = useState(null);
    const [showImage, setShowImage] = useState(false);
    const [imageType, setImageType] = useState(1);

    const toggleImage = () => {
        setShowImage(!showImage);
    };

    const toggleDeleteModal = (Id) => {
        setDeleteModal(!deleteModal);
        setSelectedData(Id);
    }

    const render_date = (add_date) => {
        if (typeof add_date === "undefined") {
            return;
        }
        const convert_date = new Date(add_date);
        const added_date = moment.tz(convert_date, "Australia/Sydney").format("DD/MM/YYYY HH:mm");
        return added_date;
    };

    const showUpdateModal = (data) => {

        setShowModal(!showModal);
        setSelectedData(data);
    };

    const updateWLMStatus = (event) => {
        event.preventDefault();

        if (getStatus == null) {
            setShowError(true);

        } else if (descriptin == null) {
            setShowError(true);

        } else {

            let postData = { updateType: 5, updateData: getStatus, updateID: selectedData._id };

            updateStatus(postData);
            setShowModal(false);
            setGetStatus(null);
            setDescription(null);
            setShowError(false);
        }
    }

    const deleteWLMData = (event) => {
        event.preventDefault();
        const postData = { deleteID: selectedData };
        deleteWhiteLabelMerchant(postData);
    }

    const renderUpdateModal = () => {
        return (
            <Modal
                isOpen={showModal}
                toggle={showUpdateModal}
                className="modal-body"
                centered={true}
            >
                <form className="theme-form" noValidate="" onSubmit={updateWLMStatus}>
                    <ModalHeader toggle={showUpdateModal}><h5 style={{ fontSize: "1.15rem" }}>Update  Status</h5></ModalHeader>
                    <ModalBody style={{ padding: "0.5rem 1rem" }}>
                        <div>
                            {
                                selectedData && selectedData.status == 1 ?
                                    <>
                                        <div className="">
                                            <div className="d-flex justify-content-between mx-3 my-1">
                                                <label style={{ fontSize: "16px" }} className="ml-0" for="accept_request">Accept Request</label>
                                                <input
                                                    type="radio"
                                                    style={{ height: "17px", width: "17px" }}
                                                    id="accept_request"
                                                    name="status"
                                                    value="2"
                                                    onChange={(event) => {
                                                        setGetStatus(event.target.value)
                                                    }}
                                                />
                                            </div>
                                            {getStatus == 2 ? <div className="d-flex align-items-start" style={{ marginTop: "-8px", width: "100%" }}>
                                                <div style={{ width: "7%" }}>
                                                    <input
                                                        type="checkbox"
                                                        className=""
                                                        style={{ marginTop: "8px", height: "17px", width: "17px" }}
                                                        id="accept"
                                                        name="accept"
                                                        value="2"
                                                        disabled={getStatus == 2 ? false : true}
                                                        onChange={(event) => {
                                                            setDescription(event.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div style={{ width: "93%" }} > <label style={{ color: "#880808", fontSize: '12px' }} for="accept">I confirm that i have discuss requirements with merchant and have forwarded details to development team for the same. </label></div>
                                            </div> : null}
                                        </div>
                                        <hr className="" style={{ marginTop: "0.4rem", marginBottom: "0.6rem" }} />
                                    </> : null
                            }

                            {
                                selectedData && selectedData.status == 1 ?
                                    <>
                                        <div>
                                            <div className="d-flex justify-content-between mx-3 my-1">
                                                <label style={{ fontSize: "16px" }} className="ml-0" for="reject_request">Reject Request</label>
                                                <input
                                                    type="radio"
                                                    style={{ height: "17px", width: "17px" }}
                                                    id="reject_request"
                                                    name="status"
                                                    value="3"
                                                    onChange={(event) => {
                                                        setGetStatus(event.target.value)
                                                    }}
                                                />
                                            </div>
                                            {getStatus == 3 ? <div className="d-flex align-items-start" style={{ marginTop: "-8px", width: "100%" }}>
                                                <div style={{ width: "7%" }}>
                                                    <input
                                                        type="checkbox"
                                                        className=""
                                                        style={{ marginTop: "8px", height: "17px", width: "17px" }}
                                                        id="reject"
                                                        name="reject"
                                                        value="3"
                                                        disabled={getStatus == 3 ? false : true}
                                                        onChange={(event) => {
                                                            setDescription(event.target.value);
                                                        }}
                                                    /></div>
                                                <div style={{ width: "93%" }}> <label style={{ color: "#800000", fontSize: '12px' }} for="reject">I confirm that as per the discussion with merhant we were not able to meet the
                                                    requirement so management team has confirm to cancel the request and refund the amount if any. </label></div>
                                            </div> : null}
                                        </div>
                                        <hr style={{ marginTop: "0.4rem", marginBottom: "0.6rem" }} />
                                    </> : null
                            }
                            <div>
                                <div className="d-flex justify-content-between mx-3 my-1">
                                    <label style={{ fontSize: "16px" }} className="ml-0" for="request_processed">Request Processed</label>
                                    <input
                                        type="radio"
                                        style={{ height: "17px", width: "17px" }}
                                        id="request_processed"
                                        name="status"
                                        value="4"
                                        onChange={(event) => {
                                            setGetStatus(event.target.value)
                                        }}
                                    />
                                </div>
                                {getStatus == 4 ? <div className="d-flex align-items-start" style={{ marginTop: "-8px", width: "100%" }}>
                                    <div style={{ width: "7%" }}>
                                        <input
                                            type="checkbox"
                                            className=""
                                            style={{ marginTop: "8px", height: "17px", width: "17px" }}
                                            id="processed"
                                            name="processed"
                                            value="4"
                                            disabled={getStatus == 4 ? false : true}
                                            onChange={(event) => {
                                                setDescription(event.target.value);
                                            }}
                                        />
                                    </div>
                                    <div style={{ width: "93%" }}> <label style={{ color: "#800000", fontSize: '12px' }} for="processed">I confirm that requirement of merchants are fufilled and testing is done to confirm everything is working fine</label></div>
                                </div> : null}
                            </div>
                        </div>

                        {(showError && !getStatus || showError && !descriptin) && <div className="d-flex align-items-center justify-content-center">
                            <ErrorIcon style={{ fontSize: "15px", color: "red" }} />
                            <p
                                style={{
                                    color: "red",
                                    fontSize: "12px",
                                    textAlign: "center",
                                    fontWeight: "600",
                                    marginBottom: "0px",
                                    marginLeft: "4px"
                                }}>
                                {!getStatus ? " Please select requets from above to update the status." : !descriptin ? "Please tick the check box to updat the status " : null}
                            </p>
                        </div>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="default"
                            onClick={() => {
                                showUpdateModal();
                                setGetStatus(null);
                                setDescription(null);
                                setShowError(false);
                            }}>
                            Cancel
                        </Button>
                        <Button color="primary" type="submit">
                            {actionLoading ? "Loading" : "Update"}
                        </Button>
                    </ModalFooter>
                </form>
            </Modal >
        );
    };

    const renderDeleteModal = () => {
        return (
            <Modal
                isOpen={deleteModal}
                toggle={toggleDeleteModal}
                className="modal-body"
                centered={true}>
                <form className="theme-form" noValidate="" onSubmit={deleteWLMData}>
                    <ModalBody>
                        <h4>Do you really want to delete this merchant ?</h4>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" onClick={toggleDeleteModal}>
                            No
                        </Button>
                        <Button
                            color="primary"
                            type="submit"
                        >
                            Yes
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>
        );
    };

    const renderImageModal = () => {
        return (
            <Modal
                isOpen={showImage}
                toggle={toggleImage}
                className="modal-body"
                centered={true}
                style={{ width: `${imageType == 2 ? "100%" : "215px"}` }}
            >
                <ModalBody
                    style={{
                        position: "relative"
                    }}
                >
                    <div
                        style={{
                            height: `${imageType == 2 ? "100%" : "150px"}`,
                            width: `${imageType == 2 ? "100%" : "150px"}`
                        }}
                    >
                        <img
                            src={client.url(`images/white-label-images/${selectedData}?${Date.now()}`)}
                            className="img-fluid p-0"
                            alt="drop-img"
                        />
                    </div>
                    <div
                        style={{
                            position: "absolute",
                            top: "-20px",
                            right: "-20px",
                            height: "35px",
                            width: "35px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#fff",
                            border: "1px solid black",
                            fontSize: "15px",
                            borderRadius: "50%",
                            cursor: "pointer"
                        }}
                        onClick={() => { toggleImage() }}
                    >X</div>
                </ModalBody>
            </Modal >
        );
    };

    return (
        <>
            <div className="container-fluid main_div">
                <div className="row">
                    <form noValidate="" className="w-100">
                        <div className="col-sm-12 col-xl-12 xl-100" style={{ padding: "0" }}>
                            <div className="card" style={{ marginBottom: '0px' }}>
                                <div className="card-body" style={{ padding: "0rem 1rem" }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: '1.5rem 0rem' }}>
                                        <div>
                                            <form onSubmit={(event) => handleSearchSubmit(event)}>
                                                <div>
                                                    <input
                                                        style={{
                                                            border: "#dfe8f1 solid 1px",
                                                            borderRadius: "3px",
                                                            padding: "10px",
                                                            fontSize: "0.75rem",
                                                            outlineColor: '#dfe8f1',
                                                            width: "25rem"
                                                        }}
                                                        type="text"
                                                        placeholder="Search"
                                                        value={searchValue}
                                                    // onChange={this.onSearchChangeHandler}
                                                    />
                                                </div>
                                            </form>
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <select
                                                className="custom-select"
                                                style={{ width: "32%", marginRight: "7px" }}
                                                onChange={setPageSize}
                                                value={pageSize}
                                            >
                                                <option value="15">15</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="75">75</option>
                                                <option value="100">100</option>
                                            </select>
                                            <span>Records per Page</span>
                                        </div>
                                    </div>

                                    <div className="table-responsive">
                                        <table className="table table-border-horizontal">
                                            <thead>
                                                <tr style={{ background: '#f9fafe' }}>
                                                    <th scope="col">#</th>
                                                    <th scope="col"><p>Date & Time</p></th>
                                                    <th scope="col" style={{ maxWidth: '190px' }}>Merchant Details</th>
                                                    <th scope="col" style={{ maxWidth: '120px' }}>Attachments</th>
                                                    <th scope="col" style={{ maxWidth: '110px' }}>Domain URL</th>
                                                    <th scope="col">No of store</th>
                                                    <th scope="col">Total Paid</th>
                                                    <th scope="col">Request Status</th>
                                                    <th scope="col" style={{ maxWidth: '100px' }}>Comments</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {
                                                    data.map((item, index) => {
                                                        return (
                                                            <tr key={index._id}>
                                                                <th style={{ padding: "3rem" }} scope="row">{(activePage - 1) * pageSize + index + 1}</th>
                                                                <td style={{ padding: "3rem" }} >{render_date(item.createdAt)}</td>
                                                                <td style={{ padding: "3rem", maxWidth: '190px' }} >
                                                                    <span>{item.name}</span> <br />
                                                                    <span>{item.email}</span> <br />
                                                                    <span>(+{item.mobile_prefix}) {item.mobile}</span>
                                                                </td>
                                                                <td style={{ padding: "3rem", maxWidth: "120px" }}>
                                                                    <span
                                                                        className="imageLink"
                                                                        onClick={() => {
                                                                            toggleImage();
                                                                            setImageType(1);
                                                                            setSelectedData(item.roundLogo);
                                                                        }}
                                                                    >
                                                                        Square Logo
                                                                    </span> <br />
                                                                    <span
                                                                        className="imageLink"
                                                                        onClick={() => {
                                                                            toggleImage();
                                                                            setImageType(2);
                                                                            setSelectedData(item.rectLogo);
                                                                        }}
                                                                    >
                                                                        Rectangle Logo
                                                                    </span>
                                                                </td>
                                                                <td style={{ padding: "3rem", maxWidth: '110px' }} >{item.domain_URL}</td>
                                                                <td style={{ padding: "3rem" }} >{item.no_of_stores}</td>
                                                                <td style={{ padding: "3rem" }} >
                                                                    <div className='d-flex align-item-center'>
                                                                        <span className="mr-1">{item.country.currency_code} {item.total_amounts.total.toFixed(2)} </span>
                                                                        <span
                                                                            onClick={() => {
                                                                                toggleShowInvoice(item.invoive_Id);
                                                                                setSelectedWLMData(item);
                                                                            }}
                                                                        > <DescriptionOutlined style={{ fontSize: "20px", color: "#0000FF" }} />
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td style={{ padding: "3rem" }} >
                                                                    <div className="d-flex flex-column align-items-center justify-content-center">
                                                                        <span
                                                                            className="mb-1"
                                                                            style={{
                                                                                color: `${item.status == 1 ? "#FFA500"
                                                                                    : item.status == 2 ? "#009E60"
                                                                                        : item.status == 3 ? "#FF0000"
                                                                                            : item.status == 4 ? " #0000FF"
                                                                                                : "#000"}`
                                                                            }}
                                                                        >
                                                                            {
                                                                                item.status == 1 ? "PENDING"
                                                                                    : item.status == 2 ? "ACCEPTED"
                                                                                        : item.status == 3 ? "REJECTED"
                                                                                            : item.status == 4 ? "PROCESSED"
                                                                                                : null
                                                                            }
                                                                        </span>
                                                                        {
                                                                            item.status != 4 && <Button
                                                                                className='my-0 d-flex align-items-center'
                                                                                type='button'
                                                                                size='sm'
                                                                                color='primary'
                                                                                outline
                                                                                style={{ fontSize: "14px", padding: "3px 15px" }}
                                                                                onClick={() => { showUpdateModal(item) }}
                                                                            > Update <KeyboardArrowDown style={{ fontSize: "15px", marginTop: "3px" }} />
                                                                            </Button>
                                                                        }

                                                                    </div>
                                                                </td>
                                                                <td style={{ padding: "3rem", maxWidth: '100px' }}>
                                                                    <textarea
                                                                        id={`${item._id}-comments`}
                                                                        name={`${item._id}-comments`}
                                                                        ref={register}
                                                                        style={{ width: '100%' }}
                                                                        rows="2"
                                                                        onBlur={(event) => {
                                                                            let newValue = event.target.value;
                                                                            if (newValue != item.comments) {
                                                                                let postData = { updateType: 10, updateData: event.target.value, updateID: item._id };
                                                                                updateStatus(postData);
                                                                            }
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td style={{ padding: "3rem" }} >
                                                                    <div className="d-flex align-items-center">
                                                                        <span onClick={() => { toggleDeleteModal(item._id) }} >
                                                                            <DeleteOutlineIcon style={{ fontSize: 23 }} />
                                                                        </span>
                                                                        <input
                                                                            type="checkbox"
                                                                            id={`${item._id}`}
                                                                            name={`${item._id}`}
                                                                            value="Bike"
                                                                            style={{ height: "16px", width: "16px", marginTop: "-4px" }}
                                                                        />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        {
                                            data.length > 0 ?
                                                <div
                                                    className="mt-3"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between"
                                                    }}>
                                                    {activePage * pageSize > data.length ? (
                                                        <p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>
                                                            Showing {(activePage - 1) * pageSize + 1} to {data.length} of {totalData.length}{" "}
                                                            entries
                                                        </p>
                                                    ) : (
                                                        <p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>
                                                            Showing {(activePage - 1) * pageSize + 1} to{" "}
                                                            {activePage * pageSize} of {totalData.length} entries
                                                        </p>
                                                    )}
                                                    <PaginationComponent
                                                        totalItems={totalData.length}
                                                        pageSize={pageSize}
                                                        onSelect={handlePageChange}
                                                        defaultActivePage={activePage}
                                                    />
                                                </div> :
                                                <div
                                                    style={{
                                                        height: "150px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center"
                                                    }}>
                                                    <h3 style={{ fontSize: "20px" }}> No record found !!</h3>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            {renderUpdateModal()}
            {renderDeleteModal()}
            {renderImageModal()}
        </ >
    )
}

export default WhiteLabelTable