import React, { useState, useEffect, createRef } from 'react';
import Dropzone, { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import CropperModel from './CropperModel';
import { mdiPencil, mdiTrashCanOutline, mdiChartSankeyVariant, mdiCogOutline } from "@mdi/js"
import Icon from "@mdi/react";
import { Button } from 'reactstrap';
import api from "../../../../constant/apilist"
import client from "../../../../Api/HTTPClient"
import * as actionCreators from "../../../../store/actions/index"


const DropZoneField = (props) => {
  const dispatch = useDispatch();
  const [filec, setFile] = useState(null)
  const [resizedImage, setResizedImage] = useState(null)
  const dropzoneRef = createRef()

  const category = useSelector(state => state.category)
  const onDrop = (acceptedFiles, file) => {
    setFile(acceptedFiles[0])
  }

  const crModel = (croppedFile) => {

    var reader = new FileReader();
    reader.readAsDataURL(filec);
    var mimeType = filec.type;

    if (croppedFile) {
      reader.onload = (_event) => {
        let image_data;
        image_data = reader.result;

        image_data = croppedFile.split(",")[1];

        image_data = { mime: mimeType, data: image_data, id: props.id };
        console.log(image_data)
        client.post(api.img_slider, image_data, async function (error, response) {
          if (!error) {
            if (typeof response.error !== "undefined") {
              try {
                if (!response.error) {
                  dispatch(actionCreators.fetch_slider());
                  toast.success(response.message);
                } else {
                  toast.error(response.message);
                }
              } catch (e) {
                toast.error("Internal error occured. Please contact support 1");
              }
            } else {
              toast.error("Internal error occured. Please contact support 2");
            }
          } else {
            toast.error("Internal error occured. Please contact support 3");
          }
        });

      };
    }
    setFile(null)

  }
  return (
    <div className="container border p-0 mx-0 my-4 "
      style={{
        height: '7.5rem',
        width: '15.5rem',
        // borderColor: "silver",
        // border: "1px solid black"
      }}>
      <div className={`dropzone  dropzone--single${false ? ' dropzone--custom-height' : ''}`}
        style={{
          height: '7.5rem',
          width: '15.5rem',
          overflow: 'hidden',
        }}>
        <Dropzone
          ref={dropzoneRef}
          accept="image/jpeg, image/png"
          name={"file"}
          multiple={false}
          onDrop={(fileToUpload) => {
            onDrop(fileToUpload);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="dropzone__input align-items-center"
              style={
                {
                  minHeight: "unset"
                }
              }
            >
              {/* display profile */}

              {typeof props.img !== "undefined" && props.img != "" || resizedImage ?

                <aside className="dropzone__img px-0">
                  {resizedImage ?
                    <img src={resizedImage} className="img-fluid p-0" alt="drop-img1" />
                    :
                    <img src={client.url(`images/slider/${props.img}?${Date.now()}`)} className="img-fluid p-0" alt="drop-img" />
                  }
                </aside>
                :
                <>
                  <div className="text-center mx-auto mt-5 d-flex align-items-center flex-column">
                    <p class="" style={{ color: "silver", fontSize: "0.9rem" }}>Slider Image</p>
                  </div>
                  <br />

                </>

              }
              {/* take input */}
              <input {...getInputProps()} />
            </div>
          )}
        </Dropzone>

        <CropperModel file={filec}
          onConfirm={(croppedFile) => {

            if (croppedFile) {
              setResizedImage(croppedFile)
            }
          }}
          onCompleted={(croppedFile) => crModel(croppedFile)} />
      </div>
    </div>
  );
};


export default DropZoneField;
