import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import {  withRouter} from "react-router";
import { ToastContainer, toast } from "react-toastify";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import Datatable from "../../components/common/datatable";
import data from '../../data/dummyTableData';
import * as actionCreators from "../../store/actions/index";


class ActiveSessions extends Component {

    state = {
        access_level: localStorage.getItem("accessLevel"),
        token : localStorage.getItem("token"),
        adminDetails: this.props.adminDetails,
        adminUserId: this.props.adminUserId,
        logoutDeviceModal: false,
        selectedBrowserId: null,
        logoutAllModal : false
    }

    refreshPage() {
        window.location.reload(false);
    }

    render_date = (add_date) => {

        if (typeof (add_date) === "undefined") {
            return;
        }

        const convert_date = new Date(add_date);
        const added_date = moment.tz(convert_date, "Australia/Sydney").format("DD/MM/YYYY HH:mm");
        return added_date;
    }

    logOutDeviceClick = (id) => {
        console.log(id);
        this.setState(prevState => {
            return {
                ...prevState,
                logoutDeviceModal: !prevState.logoutDeviceModal,
                selectedBrowserId: id
            }
        })
    }

    togglelogoutAllModal = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                logoutAllModal: !prevState.logoutAllModal
            }
        })
    }

    togglelogoutDeviceModal = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                logoutDeviceModal: !prevState.logoutDeviceModal
            }
        })
    }


    logoutDevicehandler = (event) => {
        event.preventDefault()
        this.props.onLogoutDevice(this.state.adminUserId, this.state.selectedBrowserId, this.state.token);
        this.refreshPage()
    }

    renderAdminLogoutDeviceModal = () => {
        return (
            <Modal isOpen={this.state.logoutDeviceModal} toggle={this.togglelogoutDeviceModal} className="modal-body" centered={true}>
                <form className="theme-form" onSubmit={this.logoutDevicehandler} >
                    <ModalBody>
                        <h6>Are you sure you want the admin user should be logged out of the device ?</h6>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.togglelogoutDeviceModal}>No</Button>
                        <Button color="primary" type="submit" >Yes</Button>
                    </ModalFooter>
                </form>
            </Modal>
        )
    }

    logoutAllHandler = (event) => {
        event.preventDefault()
        this.props.onLogoutAll(this.state.adminUserId, "logoutAllClick", this.props.history);
        //this.refreshPage()
    }

    renderAdminLogoutAllModal = () => {
        return (
            <Modal isOpen={this.state.logoutAllModal} toggle={this.togglelogoutAllModal} className="modal-body" centered={true}>
                <form className="theme-form" onSubmit = {this.logoutAllHandler} >
                    <ModalBody>
                        <h6>Are you sure you want the admin user should be logged out of all loggedIn device ?</h6>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.togglelogoutAllModal}>Not sure!</Button>
                        <Button color="primary" type="submit"  >Yes please!</Button>
                    </ModalFooter>
                </form>
            </Modal>
        )
    }


    renderAllTable = () => {
        const { adminDetails } = this.state;
        const activeDevices = adminDetails.devices_loggedIn.reverse().filter((item, index) =>{
            return item.active == true
        });
        

        return (
            <Fragment>
                {/* {(this.state.currentPage + 1) * this.state.pageSize > customers.length ?
          <p style={{ textAlign: "right" }}>Showing {(this.state.currentPage * this.state.pageSize) + 1} to {customers.length} of {customers.length} entries</p> :
          <p style={{ textAlign: "right" }}>Showing {(this.state.currentPage * this.state.pageSize) + 1} to {(this.state.currentPage + 1) * this.state.pageSize} of {customers.length} entries</p>} */}

                <div className="table-responsive" >
                    <table className="table table-border-horizontal" >
                        <thead>
                            <tr style={{background: '#f9fafe'}}>
                                <th scope="col" style={{ width: "4%" }}>#</th>
                                <th scope="col" style={{ width: "14%" }}>TIME</th>
                                <th scope="col" style={{ width: "20%" }}>DEVICE</th>
                                <th scope="col" style={{ width: "20%" }}>LOCATION</th>
                                <th scope="col" style={{ width: "18%" }}>IP ADDRESS </th>
                                {this.state.access_level != 1 && <th scope="col" style={{ width: "18%" }}>ACTION</th>}
                            </tr>
                        </thead>
                        <tbody>

                            {activeDevices.length > 0 ? activeDevices.map((item, index) => {
                                return (
                                    <tr key={item._id}>
                                        <th scope=" row" style={{ width: "4%" }}>{index + 1}</th>
                                        <td style={{ width: "14%" }}>{this.render_date(item.time)}</td>
                                        <td style={{ width: "20%" }}>{item.device}</td>
                                        <td style={{ width: "20%" }}>{item.location}</td>
                                        <td style={{ width: "18%" }}>{item.ip}</td>
                                        {this.state.access_level != 1 && <td style={{ width: "18%" }}>
                                            <span style={{ marginRight: "10px", fontSize: "1.2em",cursor: "pointer" }}  onClick={() => this.logOutDeviceClick(item.browserId)}>
                                                <i className="icofont icofont-logout" style={{ color: "black" }} id="info"></i>
                                                {/* <UncontrolledTooltip placement="bottom" target="info">
                                                    Logout
                                                </UncontrolledTooltip> */}
                                            </span>
                                        </td>}
                                    </tr>);
                            }): <h5>No active sessions found!</h5>}
                        </tbody>
                    </table>
                </div>
            </Fragment>
        );
    }

    render() {
        /* if (this.props.errorMsg) {
            toast.error(this.props.errorMsg)
        }
        if (this.props.successMsg) {
            toast.success(this.props.successMsg)
        } */
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-xl-12 xl-100">
                        <div className="card">
                            <div className="card-body" style={{ padding: "0.3rem" }}>
                                {this.state.access_level == 3 && <div className="pull-right ">
                                    <button className="btn custBtnAdmin" style={{marginBottom:"12px"}}
                                        onClick={this.togglelogoutAllModal}>Sign out all other sessions</button>
                                </div>}
                                {this.renderAllTable()}
                                {this.state.selectedBrowserId && this.renderAdminLogoutDeviceModal()}
                                {this.renderAdminLogoutAllModal()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        );
    }
}

const mapStateToProps = (state) => {
    return {
        errorMsg: state.auth.errorMsg,
        successMsg: state.auth.successMsg
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLogoutDevice: (userId, browserId, token) => dispatch(actionCreators.logoutDevice(userId, browserId, token)),
        onLogoutAll : (userId, type, history) => dispatch(actionCreators.logoutAll(userId, type, history))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ActiveSessions));