import React, { Component, useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormGroup, Col, Row, Input, CustomInput } from 'reactstrap';
import useForm  from "react-hook-form";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";

let EditCuisineModal = (props) => {
    
    let [loadingbtn, setloadingbtn] = useState(false);
    let [inputList, setInputList] = useState([{ subCategory: "", status: 1}]);
    let [inputCat, setInputCat] = useState('');
    let [editItem, seteditItem] = useState(null);
    let [firstTimeLoad, setfirstTimeLoad] = useState(true);

    const { catid, category, subCategory, isOpen } = props;

    useEffect(() => {
        if(isOpen)
        {
            if(firstTimeLoad)
            {
            setInputCat(category);
            setInputList(subCategory);
            seteditItem(catid);
            setfirstTimeLoad(false);
            }
        }else{
            setfirstTimeLoad(true);
        }
    },[props]);

    const handleResetClick = () => {
            setInputCat('');
            setInputList([{ subCategory: "", status: 1}]);
            seteditItem(null);
    };


    const handleCatInputChange = (e) => {
        const { value } = e.target;
        setInputCat(value);
    }
    

    const { handleSubmit, register, errors } = useForm();

    const onSubmit = form_data => {
        
        if (form_data !== '') {

            setloadingbtn(true);

                let data = { cuisine_name: form_data.cuisine_name, _id: editItem, type: 1};

                client.post(api.edit_cuisines, data, async function (error, response) {
                    console.log(response);
                    if (!error) {
                        if (typeof response.error !== "undefined") {
                            try {
                                if (!response.error) {
                                    setfirstTimeLoad(true);
                                    handleResetClick();
                                    toast.success("Updated Successfully");
                                    props.onClose(true);
                                } else {
                                    toast.error(response.message);
                                }
                            } catch (e) {
                               //toast.error("Internal error occured. Please contact support 2");
                            }
                        } else {
                            toast.error("Internal error occured. Please contact support 2");
                        }
                    } else {
                        toast.error("Internal error occured. Please contact support 3");
                    }
                    setloadingbtn(false);
                });

        }
        else {
            errors.showMessages();
        }
    }

        return (
        
            <Modal isOpen={props.isOpen} toggle={props.onClose} size="md" className="modal-body" centered={true}>
               <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={props.onClose}><p className="m-0 font-weight-bold">Edit Cuisine</p></ModalHeader>
                    <ModalBody>
                        <ModalBody style={{ padding: "16px 0" }}>
                            <Row form>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="level_title">Cuisine</Label>
                                        <input className="form-control" type="text" name="cuisine_name" value={inputCat} onChange={e => handleCatInputChange(e)} ref={register({ required: true })}/>
                                        <span>{errors.cuisine_name && 'Category Name is required'}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            {!loadingbtn ?
                                <>
                                    <Button color="default" type="button" onClick={handleResetClick}>Reset</Button>
                                    <Button color="primary" type="submit" onClick={() => props.onClose(false) }>Update</Button>
                                </>
                                :
                                <div className="loader-box pull-right" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 20, width: 20 }}></span></div>
                            }
                        </ModalFooter>  
                    </ModalBody>
                    </form>
            </Modal>
        );
}

export default EditCuisineModal;