import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { DollarSign, MapPin, X, TrendingDown, ArrowUp, ShoppingCart, Search, Activity, User } from "react-feather";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import CountUp from "react-countup";
import { Map as LeafletMap, TileLayer, GeoJSON, Marker, Popup } from 'react-leaflet';
import WorldData from 'world-map-geojson';
import { Link } from "react-router-dom";

var Knob = require("knob"); // browserify require
var primary = localStorage.getItem("primary_color") || "#4466f2";

const MapData = (props) => {
	const [allData, setAllData] = useState([]);
	const [animate, setAnimate] = useState(false);
    const [latlan, setlatlan] = useState({
        lat: 51.505,
        lng: -0.09,
	});

	const { state } = props.location
	
	console.log(props);
	console.log(state.MerchantMarker);

	const markers = state.MerchantMarker;

    // const markers = [
    //     { key: 'marker1', position: [51.5, -0.1], content: 'My first popup' },
    //     { key: 'marker2', position: [51.51, -0.1], content: 'My second popup' },
    //     { key: 'marker3', position: [51.49, -0.05], content: 'My third popup' },
    // ]

    const handleClick = (e) => {
        setlatlan({
            latlng: e.latlng,
        })
    }

    const toggleAnimate = () => {
        setAnimate({
            animate: !animate,
        })
    }

    const MyPopupMarker = ({ content, position }) => (
        <Marker position={position}>
            <Popup>{content}</Popup>
        </Marker>
    )

    const MyMarkersList = ({ markers }) => {
        const items = markers.map(({ key, ...props }) => (
            <MyPopupMarker key={key} {...props} />
        ))
        return <Fragment>{items}</Fragment>
    }
	

	useEffect(() => {
		
		//document.getElementById('profit').appendChild(profit)
	}, []);

	


	/* console.log(withdrawRequest); */

	return (
		<Fragment>
			<div className="col-lg-7 col-sm-12" style={{ fontSize: "12px" }}>
				<Breadcrumb parent="Home" title="Dashboard" />
			</div>
			<div className="row">
				<div className="col-xl-12 col-md-12">
					<div className="card" style={{border: '1px solid #dfe8f1', borderRadius: '3px'}}>
						<div className="card-header" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
							<h5>{state.tital}</h5>
							<Link to={{pathname: "/dashboard/default"}}><button class="btnexport custBtnAdmin">Back</button></Link>
						</div>
						<div className="card-body">
							<LeafletMap center={[-35.2268896,149.1181933]} zoom={13}>
								<TileLayer
									attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
									url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
								/>
								<MyMarkersList markers={markers} />
							</LeafletMap>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default MapData;
