import React, { useState, useEffect } from "react";
import client from "../../../Api/HTTPClient";
import { toast } from "react-toastify";
import api from "../../../constant/apilist";
import "./AppSettings";

function MerchantApp() {

	const [imagesData, setImagesData] = useState([]);

	useEffect(() => {
		getAllData();
	}, []);

	const getAllData = () => {
		let postdata = {};
		client.get(api.getmerchant_slider, postdata, (error, response) => {
			console.log(response)
			if (!error) {
				if (!response.error) {
					let slidesIndex = response.result?.data?.length;

					let items = response.result?.data;

					var data = new Promise((resolve, reject) => {
						for (let i = slidesIndex; i < 5; i++) {

							let item = {}
	
							item = {
								sliderName: "",
								customeURL: null,
								choice: "",
								imageName: "",
								_id: i,
							}
	
							items[i] = item;
							if (i === 4) resolve();
						}
					});

					data.then(() => {
						setImagesData(items)
					});
					
				}
				else console.log(response)
			}
			else console.log(error)
		});
	};

	const readUrl = async (event, index) => {
		event.preventDefault();
		event.persist();
		let targetName = await event.target.name
		let files = await event.target?.files
		var mimeType = files[0].type;
		if (files) {
			if (mimeType.includes('image')) {
				if (files[0].size <= 102400) {
					var reader = new FileReader();
					reader.readAsDataURL(files[0]);
					reader.onload = (_event) => {
						let imageData;
						imageData = reader.result;
						imageData = imageData.split(",")[1];
						let image_data = { mime: mimeType, data: imageData, imageresult: reader.result };
						if (targetName) updateImagesData(index, event, image_data)
					};
				} else {
					toast.error("Please insert image size less than 100Kb");
				}
			} else toast.error("Invalid file format");

		}
	};

	const deleteSlider = (event, index) => {
		event.preventDefault();
		const postData = { id: imagesData[index]._id };
		client.post(api.deletemerchant_slider, postData, async (error, response) => {
			console.log({ response });
			if (!error) {
				if (!response.error) {
					toast.success(`Remove Slider ${index + 1}`);
					getAllData();
				}
				else toast.error(response);
			}
			else toast.error(response);
		});
	};

	const handleSubmit = (event, index, id) => {
		event.preventDefault();
		let newImageData = imagesData[index]?.mSliderImg ? imagesData[index]?.mSliderImg : "";
		console.log(newImageData)
		console.log(imagesData[index])
		if (imagesData[index].imageName.includes(".") || newImageData?.data) {
			client.post(api.merchant_slider1, { ...imagesData[index], newImageData }, async (error, response) => {
				console.log({ response });
				if (!error) {
					if (!response.error) {
						let respData = await response?.data;
						if (respData) {
							getAllData()
							toast.success(`Slider ${index + 1} Updated..`);
						}
						else console.log("Data Not Received from Backend..")
					}
					else console.log(response);
				}
				else console.log(response);
			});
		}
		else toast.error("Unable to set Empty Screen");
	};


	console.log("state", imagesData)

	const updateImagesData = (i, e, imageData) => {
		console.log("e", e)

		let items = [...imagesData];

		var imageName = "";

		if (imagesData[i]?.mSliderImg && Object.keys(imagesData[i].mSliderImg).length !== 0) {
			imageName = "MerchantSliderImg" + (i + 1);
		}
		if (imagesData[i]?.imageName && imagesData[i]?.imageName !== "") {
			imageName = imagesData[i].imageName
		}
		else imageName = "MerchantSliderImg" + (i + 1);

		let item = {
			...items[i],
			sliderName: `Merchant_Slider${i + 1}`,
			[e.target.name]: imageData ? imageData : e.target.value == "choose" ? "" : e.target.value,
			_id: imagesData[i]?._id ? imagesData[i]._id : i + 1,
			imageName: imageName,
		}
		items[i] = item;
		if (items !== undefined || items != []) setImagesData(items)
	}


	const onurslChange = (index, e) => {
		updateImagesData(index, e)
	}

	const isURL = (str) => {
		if (str !== undefined) {
			var urlValidator = new RegExp(/^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i);
			var res = urlValidator.test(str);;
			return res;
		}
	}



	const renderUI = (index, _id) => {
		return <form key={`form-${index}`} className="row" enctype="multipart/form-data" style={{ marginTop: '1rem' }}>
			<div className="dropzone dropzone-primary col-md-3" style={{ maxWidth: "23%" }}>
				<div class="dzu-dropzone">
					{
						imagesData[index]?.imageName?.includes('.') || imagesData[index]?.mSliderImg?.data ?
							<div className="imgContslider">
								<img src={imagesData[index]?.mSliderImg ? imagesData[index]?.mSliderImg?.imageresult : `${client.url(`sliderImages/merchantSlider/${imagesData[index]?.imageName}`)}`} className="imgSlider" />
								<input className="previewInput" type="file" name="mSliderImg" accept="image/*" onChange={(e) => readUrl(e, index)} />
								<div className="deleteicon">
									<i className="icofont icofont-close-circled" style={{ color: "#ff0000d6", fontSize: "2.5em" }} onClick={(e) => deleteSlider(e, index)} ></i>
								</div>
							</div> : <label className="dzu-inputLabel">Select an image
							<input className="dzu-input" type="file" name="mSliderImg" accept="image/*" onChange={(e) => readUrl(e, index)} />
							</label>
					}
				</div>
			</div>
			<div className="dropdownhome col-md-3">
				<div class="form-group">
					<label for="ChooseRedirect">Redirect to (optional)</label>
					<select class="form-control btn" name="choice" id="chooseRedirect" value={imagesData[index]?.choice} style={{ border: "1px solid grey", paddingLeft: "0.5rem", paddingRight: "0.5rem" }} onChange={(e) => updateImagesData(index, e)} >
						<option selected={imagesData[index]?.choice == ""} value="choose">Choose</option>
						{
							redirectLinks.map((v, i) => {
								return <option selected={imagesData[index]?.choice == Object.values(v)[0]} key={`option-${i}`} value={Object.values(v)[0]}>{Object.keys(v)[0]}</option>
							})
						}

					</select>
				</div>
				<div>
					{
						imagesData[index]?.choice == "URL" ?
							<div>
								<div className="form-group" style={{ marginBottom: '0' }}>
									<input className="form-control"
										type="" name={`customeURL`} required autoComplete="off"
										placeholder="https//example.com" value={imagesData[index]?.customeURL} onChange={(e) => onurslChange(index, e)} />
								</div>
								{
									!isURL(imagesData[index]?.customeURL) && imagesData[index]?.customeURL?.length > 0 ?
										<span className="text-danger">Invalid URl</span> : null
								}
							</div> : null
					}

				</div>
			</div>
			<div className="dropdownhome col-md-3">
				<div class="form-group">
					<button className="btn btn-primary" style={{ marginTop: "10%", width: "50%" }} onClick={(e) => handleSubmit(e, index, imagesData[index]._id)}> Save</button>
				</div>
			</div>
		</form>

	}


	// const redirectLinks = ["Home", "Profile", "Notifications", "My QR code", "Scan QR code", "Wallet", "Refer Merchant", "Request Money", "Transactions"
	// 	, "Rewards", "Language Change", "My Contacts", "WithdrawM", "Setting", "Dart Home", "Bill Home", "COfferHome"]

	const redirectLinks = [
		{ "Home": "MerchDashboard" },
		{ "Profile": "ProfileM" },
		{ "Notifications": "Notifications" },
		{ "My QRCode": "MyQRCodeM" },
		{ "Scan QRCode": "ScanQRCode" },
		{ "Wallet": "MerchWalletScreen" },
		{ "ReferMerch": "ReferScreen" },
		{ "Requestmoney": "RecieveMoney" },
		{ "Transactions": "TransactionsM" },
		{ "Rewards": "RewardsScreenM", },
		{ "Language Change": "LanguageChange" },
		{ "My Contacts": "MyContactsScreen" },
		{ "WithdrawM": "WithdrawM" },
		{ "Setting": "Setting" },
		{ "Dart Home": "DartHome" },
		{ "Bill Home": "BillHome" },
		{ "COfferHome": "COfferHome" },
		{ "Custome URL": "URL" }
	]

	return (
		<div>
			<div className="card">
				<div className="card-header" style={{ paddingTop: "0.5rem", paddingBottom: "0rem" }}>
					<h6 style={{ fontWeight: "400px", paddingTop: "0rem", paddingBottom: "0rem" }}>Slider settings</h6>
				</div>

				<div className="container" style={{ padding: 0 }}>
					{
						[...Array(5)].map((v, i) => {
							return renderUI(i, imagesData[i]?._id)
						})
					}
				</div>
			</div>
		</div>
	);
}

export default MerchantApp;
