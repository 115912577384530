import * as actionTypes from "./actionTypes";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";

export const fetch_slider_start = () => {
    return {
        type: actionTypes.FETCH_SLIDATA_START
    }

}

export const fetch_slider_success = (data) => {
    return {
        type: actionTypes.FETCH_SLIDATA_SUCCESS,
        payload: data
    }
}

export const fetch_slider_failed = error => {
    return {
        type: actionTypes.FETCH_SLIDATA_FAILED,
        payload: error
    }
}

export const fetch_slider = () => {
    return dispatch => {
        client.post(api.fetch_store, { show_all: true }, (error, response) => {
            toast.success(response, error);
            if (!error) {
                try {
                    if (!response.error) {
                        dispatch(fetch_slider_success(response.result.data))
                       
                    } else {
                        dispatch(fetch_slider_failed(response.message))
                    }
                } catch (e) {
                    dispatch(fetch_slider_failed(response.message))
                }
            } else {
                dispatch(fetch_slider_failed(response.message))
            }
            //   current.setState({ loading: false });
        });
    }
}