import React, { Fragment, useState, useEffect } from "react";
import { } from "reactstrap";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { toast } from "react-toastify"
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import ReferralDetails from "./ReferralDetails";
import AffiliateStores from "./AffiliateStores";
import AffiliateTier from "./AffiliateTier";

const AffiliateProgram = (props) => {
  const [tabIndex, setTabIndex] = useState(0);
  console.log(props.merchant_info, "merchant_info")
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-xl-12 xl-100">
                        <Tabs
                            className="col-sm-12"
                            style={{ padding: "0", backgroundColor: "white", borderRadius: "3px", marginBottom: "0px" }}
                            defaultIndex={tabIndex}
                            onSelect={index => { setTabIndex(index) }}>
                            <TabList className="tabs tab-title" style={{ display: "flex", alignItems: "center", marginBottom: "-10px !important" }}>

                                <Tab style={{ display: "flex", alignItems: "center" }} >
                                    Referral Details
                                </Tab>

                                <Tab style={{ display: "flex", alignItems: "center" }} >
                                    My Affiliate Stores
                                </Tab>

                                <Tab style={{ display: "flex", alignItems: "center" }} >
                                  Tier Settings
                                </Tab>

                            </TabList>


                            <div className="tab-content-cls">
                                <TabPanel >
                                    <ReferralDetails merchant_user={props.merchant_user}/>
                                </TabPanel>


                                <TabPanel >
                                    <AffiliateStores merchant_info={props.merchant_info} merchant_user={props.merchant_user}/>
                                </TabPanel>
                                <TabPanel >

                                    <AffiliateTier merchant_info={props.merchant_info} merchant_user={props.merchant_user}/>
                                </TabPanel>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </div>
           
        </Fragment>
    )
}

export default AffiliateProgram;