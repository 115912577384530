import React, { useEffect, useState, useRef } from "react";
import { Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Progress } from "reactstrap";
// import { useTranslation } from "react-i18next";
// import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ColorizeIcon from '@material-ui/icons/Colorize';
import jsPDF from 'jspdf';
import CancelIcon from '@material-ui/icons/Cancel';
import qrcode_shapes_images from "./qrcodeshapes";
import "./Storeqrcode.scss";
import axios from 'axios';
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import { useHistory } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiClose, mdiCheck, mdiFileOutline } from "@mdi/js";
import CropperModel from './CropperModel';
import addPhotoImg from '../../../assets/images/add_image.png'
import CustomDropDown from "./CustomDropDown";
import UploadingLoader from './ImageUploadingLoader';

import { ChromePicker } from 'react-color';


const StoreBizLinkComp = (props) => {

    let { screenSize, screenHeightSize, merchantData } = props;

    const inputFileRef = useRef(null);
    const dropDivRef = useRef(null);
    // const { t } = useTranslation('my_store');
    const history = useHistory();

    // console.log(merchantData, "merchantData");

    const [qrImageData, setQrImageData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [color, setColor] = useState("");
    const [imageSrc, setImageSrc] = useState('');
    const [showColorPicker, setShowColorPicker] = useState(false);
    const colorPreset = ['#D0021B', '#F5A623', '#8B572A', '#7ED321', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#000000'];
    const [modal, setModal] = useState(false);
    const [cropper, setCropper] = useState(null);
    const [qrImageLogo, setQrImageLogo] = useState("");
    const [dropdown, setDropdown] = useState(false);
    const toggleDropdown = () => setDropdown(!dropdown);
    // const dispatch = useDispatch();

    const [selectedQRShape, setSelectedQRShape] = useState(null);
    const [enableShapeModal, setEnableShapeModal] = useState(false);
    const [raw, setraw] = useState(null)
    const [logoLoader, setLogoLoader] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const toggle = () => { setModal(!modal) };
    const toggleQrCodeShapeModal = () => { setEnableShapeModal(!enableShapeModal) };

    useEffect(() => {
        const handleKeyPress = (event) => {
            // if (event.key === 'Enter' ) {
            if (!enableShapeModal) return;
            if (event.keyCode == 13 && !event.shiftKey) {
                document.getElementById('qr_shape_select_btn_id').click()
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [enableShapeModal])


    const handleImageUpload = (e) => {
        let files = e.target?.files;
        setImageSrc(files[0]);
        toggle();
    };

    // console.log(selectedQRShape, "selectedQRShape");

    const getCropData = (croppedFile) => {

        if (typeof croppedFile !== "undefined") {
            onGenerateQr("", croppedFile, false, null, false);
            toggle();
        }
    };

    const downloadQrPNG = () => {
        const a = document.createElement('a');
        a.download = `${merchantData?.business_name + "_LinkyCode.png"}`;
        a.href = qrImageData;
        a.click()
        toggleDropdown();
    };

    const downloadQrPDF = () => {
        var doc = new jsPDF();
        const pageWidth = (doc.internal.pageSize.width / 2) - 55
        const pageHeight = (doc.internal.pageSize.height / 2) - 55
        doc.addImage(qrImageData, 'PNG', pageWidth, pageHeight, 120, 120);
        let fileName = `${merchantData?.business_name + "_LinkyCode.pdf"}`
        doc.save(fileName);
        toggleDropdown();
    };

    const host = client.url("");
    let api_domain = host.replace(/(https?:\/\/)?(www.)?/i, '');
    if (api_domain.indexOf('/') !== -1) {
        api_domain = api_domain.split('/')[0];
    }
    // console.log(api_domain, "api_domain");

    const onGenerateQr = (qrColor = "", qrImage = "", removeQrImageLogo = false, qrShape, isLoading) => {

        let qrData = {};

        if (qrShape) {
            qrData = { shapeName: qrShape.shapeName, xAxis: qrShape.xAxis, yAxis: qrShape.yAxis, size: qrShape.size }
        } else {
            qrData = { shapeName: selectedQRShape.shapeName, xAxis: selectedQRShape.xAxis, yAxis: selectedQRShape.yAxis, size: selectedQRShape.size }
        }

        const postData = {
            user_id: merchantData._id,
            qrType: "i-Pay",
            url: api_domain == "api.ipaypro.co" ? `https://linky.biz/${merchantData.unique_id}` : `https://dm.linky.biz/${merchantData.unique_id}`,
            qrColor,
            removeQrImageLogo,
            qrShapeData: qrData,
        };

        // console.log(postData, "postData");

        let formData = new FormData();

        formData.append('qrcodeFormData', JSON.stringify(postData));
        formData.append('file', qrImage)

        const config = {
            headers: {
                "x-access-token": "ipay-access-token",
                "authorization": localStorage.getItem("token"),
            }
        }

        if (!isLoading && !qrImage) {
            setLoading(true);
        }


        if (qrImage != "") {
            setLogoLoader(true);
        }

        axios.post(`${client.url(api.generate_shape_qr_code)}`, formData, config).then(
            (response) => {
                // console.log(response, "responseresponse")
                if (!response.data.error) {

                    setQrImageData(response.data.imageData);
                    setColor(response.data.qrColor || "");
                    setQrImageLogo(response.data.qrImageLogo);
                    setLoading(false);
                    if (qrImage != "") {
                        setLogoLoader(false);
                    }

                } else {
                    // toast.error("Internal error occured.Please contact support");
                    setLoading(false);
                    if (qrImage != "") {
                        setLogoLoader(false);
                    }
                }

            }).catch(error => {
                toast.error(error);
                setLoading(false);
                if (qrImage != "") {
                    setLogoLoader(false);
                }
            });
    };

    useEffect(() => {

        if (merchantData) {
            let getCurrQRShape = qrcode_shapes_images.find((currQrData) => currQrData.shapeName == merchantData?.qrShape);
            setSelectedQRShape(getCurrQRShape);
            onGenerateQr("", "", false, getCurrQRShape, true);
        }

    }, []);

    const handleClickOutside = (event) => {
        if (dropDivRef.current && !dropDivRef.current.contains(event.target)) {
            if (screenSize >= 576) {
                setDropdown(false);
            } else {
                setTimeout(() => {
                    setDropdown(false);
                }, 1000);
            }
        }
    };

    useEffect(() => {

        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };

    }, []);

    const selectQrCodeFunc = () => {

        onGenerateQr("", "", "", selectedQRShape, false);
    };

    let renderQrCode = () => {

        let option = [{ title: "PNG", _id: 1 }, { title: "PDF", _id: 2 }];

        const selectOption = (data) => {
            if (data._id == 1) {
                downloadQrPNG();
            } else if (data._id == 2) {
                downloadQrPDF()
            }
        }

        return (
            <>
                {!loading && qrImageData ? (
                    <>
                        {screenSize > 576 && <p style={{ color: 'black', fontWeight: 500, fontSize: '15px', textAlign: 'center', textTransform: 'capitalize' }}>{merchantData?.business_name + " LinkyCode"}</p>}

                        <img
                            src={qrImageData}
                            alt="merchant-qr"
                            style={{
                                width: '220px',
                                height: '220px',
                                maxWidth: '220px',
                                margin: selectedQRShape?.shapeName !== "square" ? "2rem 0rem" : "0rem 0rem"
                            }}
                        />
                        <div
                            className="drop_box text-center mb-4"
                            style={{ position: "relative" }}
                        >
                            <div
                                className="download_qr_btn rounded"
                                style={{
                                    fontSize: screenSize <= 576 ? "16px" : "14px",
                                    background: '#d23f99',
                                    color: 'white',
                                    padding: "10px 40px",
                                    cursor: "pointer",

                                }}
                                onClick={() => { toggleDropdown() }}
                            >
                                {'Download QR Code'}
                            </div>



                            {
                                dropdown && <div
                                    ref={dropDivRef}
                                    className="dropdown_menu border rounded box_shadow_style"
                                    style={{
                                        backgroundColor: "#fff",
                                        position: 'absolute',
                                        top: "50px",
                                        left: screenSize <= 576 ? "10px" : "35px",
                                        width: screenSize <= 576 ? "90%" : "70%",
                                        padding: "1rem",
                                        zIndex: "1"
                                    }} >
                                    <p
                                        className="mb-0"
                                        style={{
                                            padding: screenSize <= 576 ? "1rem 0.5rem" : "0.4rem 0.5rem",
                                            color: "#646777",
                                            fontSize: screenSize <= 576 ? "19px" : "14px",
                                            textAlign: "start"
                                        }}
                                    >
                                        Download as:
                                    </p>

                                    {
                                        option.map((item, index) => {
                                            return (
                                                <>
                                                    <div
                                                        key={index}
                                                        className="dropdown_menu_option m-0 d-flex align-items-center"
                                                        style={{
                                                            padding: screenSize <= 576 ? "1rem" : "0.4rem 0.5rem",
                                                            cursor: "pointer",
                                                            color: "black",
                                                            textAlign: "start",
                                                            fontSize: screenSize <= 576 ? "18px" : "14px"
                                                        }}
                                                        onClick={(e) => { selectOption(item); }}>
                                                        {/* <VscFile color={'#ccc'} size={17} className="mr-2" /> */}
                                                        <Icon path={mdiFileOutline} size={0.7} className="mr-2" style={{ color: "#ccc" }} />
                                                        {item.title}
                                                    </div>

                                                </>
                                            )
                                        })
                                    }

                                </div>
                            }
                        </div>
                    </>
                ) : (
                    <div style={{ height: "350px", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        {/* <Loader type="ThreeDots" color="#D23F99" height={30} width={50} /> */}
                        {'Generating, Please wait!'}
                    </div>
                )}
            </>
        )
    };

    return (
        <div
            className={`store_qr_code_style`}
            style={{ height: screenSize > 576 && dropdown ? `${screenHeightSize - 80}px` : screenSize > 576 ? `${screenHeightSize - 145}px` : '100%' }}
        >

            <Col md={12} className={screenSize <= 576 ? 'd-none' : 'd-flex flex-column justify-content-start align-items-center qr_code_col px-0'} style={{}}>
                {renderQrCode()}
            </Col>

            <Col md={12} className="px-0">
                <Row className="my-3 mx-0 d-flex align-items-center">
                    <Col xs={12} sm={12} md={2}>
                        <div className="" style={{ color: '#000', fontWeight: '500', whiteSpace: 'nowrap', maxWidth: 'fit-content', width: 'fit-content', fontSize: screenSize <= 576 ? "17px" : "13px" }}>
                            {'Choose Color'}
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={10} style={{ marginTop: screenSize <= 576 ? "1.3rem" : "0rem" }}>
                        <div className="d-flex align-items-center">
                            <div style={{ border: '2px solid #828282', borderRadius: '50%', cursor: 'pointer', height: "35px", width: "35px" }} onClick={() => setShowColorPicker(true)}><ColorizeIcon style={{ fontSize: '1.875rem', padding: '3px', color: '#828282' }} /></div>
                            {
                                colorPreset.map((preset, ind) => {
                                    return (
                                        <div key={ind} style={{ border: color === preset && `2px solid #d23f99`, borderRadius: color === preset && "50%", marginLeft: screenSize <= 576 ? "0.75rem" : '1rem', padding: color === preset && '4px' }}>
                                            <div style={{ background: preset, border: `1px solid ${preset}`, borderRadius: '50%', width: screenSize <= 576 ? '20px' : '25px', height: screenSize <= 576 ? '20px' : '25px', cursor: 'pointer' }} onClick={() => onGenerateQr(preset, "", false, null, false)}>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Col>
                </Row>
                {
                    showColorPicker ? <div style={{ position: 'absolute', zIndex: 2 }}>
                        <div className="innerColorPick" onClick={() => setShowColorPicker(false)} />
                        <ChromePicker disableAlpha={true} color={color} onChangeComplete={(color) => onGenerateQr(color.hex, "", false, null, false)} />
                    </div> : null
                }
                <div className={`mb-4`} style={{ marginTop: screenSize <= 576 ? "2rem" : "" }}>
                    <div className="col-12 p-0" style={{ color: '#000', fontWeight: '500', fontSize: screenSize <= 576 ? "17px" : "13px" }}>
                        {'Choose logo'}
                    </div>
                    <div className="d-flex align-items-center mt-3 col-12">
                        {
                            qrImageLogo ?
                                <div className="d-flex mt-2 pl-0">
                                    <img
                                        src={client.url(`images/merchant-logo/${qrImageLogo}`)}
                                        alt="logo"
                                        className="logo_style"
                                    />
                                    <CancelIcon style={{ cursor: 'pointer', marginLeft: '-15px' }} onClick={() => onGenerateQr("", "", true, null, false)} />
                                </div>
                                : <div
                                    className="mt-2 mr-2">
                                    <div
                                        className="logo_style"
                                        style={{
                                            border: '1.9px solid #ced4da',
                                            overflow: 'hidden',
                                            cursor: "pointer",
                                            display: 'flex',
                                            alignItems: 'center',
                                            backgroundColor: '#f2f2f2',
                                            borderRadius: '50px',
                                        }}
                                        onClick={() => inputFileRef.current.click()}
                                    >
                                        <div className="d-inline-block text-center mx-auto">
                                            <img src={addPhotoImg} style={{ width: '35px' }} />
                                            <input
                                                className="w-100 h-100"
                                                hidden
                                                name="productImage1"
                                                type="file"
                                                accept="image/*"
                                                ref={inputFileRef}
                                                onChange={evnt => {
                                                    handleImageUpload(evnt)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                        }

                        <div className="ml-3" style={{ fontSize: screenSize <= 576 ? "17px" : "13px" }}>{'Add custom logo to be displayed'}{<br />}{'in the middle of the linky code'}</div>
                    </div>
                </div>
                <Col md={12} className="p-0 mb-4">
                    <div className="col-12 p-0" style={{ color: '#000', fontWeight: '500', fontSize: screenSize <= 576 ? "17px" : "13px" }}>
                        {'Choose Shape'}
                    </div>
                    <div className={`d-flex mt-3 ml-0`}>
                        {
                            qrcode_shapes_images.slice(0, 4).map((qrData) => {
                                return (
                                    <>
                                        <div
                                            className="mr-2"
                                            style={{
                                                border: selectedQRShape?._id == qrData._id ? "2px solid #D23F99" : "2px solid #fff",
                                                borderRadius: selectedQRShape?._id == qrData._id && "20%",
                                                cursor: "pointer",
                                                position: selectedQRShape?._id == qrData._id && "relative",
                                            }}
                                            onClick={() => {
                                                setSelectedQRShape(qrData);
                                                onGenerateQr("", "", "", qrData, false);
                                            }}
                                        >
                                            {selectedQRShape?._id == qrData._id && <div
                                                style={{
                                                    position: "absolute",
                                                    top: "-9px",
                                                    right: "-10px",
                                                    height: "23px",
                                                    width: "23px",
                                                    backgroundColor: "#D23F99",
                                                    borderRadius: "50%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center"
                                                }}
                                            >
                                                {/* <GiCheckMark color={"#fff"} size={13} /> */}
                                                <Icon path={mdiCheck} size={0.8} style={{ color: "#fff" }} />
                                            </div>}
                                            <img
                                                src={qrData.imageSrc}
                                                alt={qrData.alt}
                                                style={{
                                                    height: screenSize <= 375 ? '40px' : "50px",
                                                    width: screenSize <= 375 ? '40px' : "50px",
                                                    margin: "0.5rem"
                                                }}
                                            />
                                        </div>
                                    </>
                                )
                            })
                        }
                        <div
                            style={{
                                height: "60px",
                                width: "60px",
                                padding: "0px",
                                borderRadius: "20%",
                                backgroundColor: "#ccc",
                                display: "flex",
                                alignItems: 'center',
                                justifyContent: "center",
                                cursor: "pointer",
                            }}
                            className="ml-3"
                            onClick={() => { toggleQrCodeShapeModal() }}
                        >
                            <p>View <br />More</p>
                        </div>
                    </div>
                </Col>
            </Col>

            <Modal
                isOpen={enableShapeModal}
                centered
                toggle={toggleQrCodeShapeModal}
                style={screenSize > 576 ? { width: "510px", maxWidth: '510px' } : {}}
            >
                <ModalHeader style={{ display: "flex", justifyContent: "center", padding: "0.25rem", margin: "0.5rem 0rem" }}>
                    <span
                        className="first_close_icon"
                        style={{ position: "absolute", right: "15px" }}
                    >
                        <Icon
                            path={mdiClose}
                            onClick={toggleQrCodeShapeModal}
                            size={screenSize <= 576 ? 1.4 : 1.1}
                            style={{ color: "#646777", cursor: "pointer" }}
                        />
                    </span>
                    <p style={{ fontSize: "18px", fontWeight: "500", marginTop: "0.5rem", marginBottom: "0.5rem" }}>Choose Shape</p>
                </ModalHeader>

                <ModalBody style={{ height: "400px", overflowY: "scroll", padding: "0px" }}>
                    <Col md={12} className="p-0">
                        <Row className='m-0 p-3' style={{}}>
                            {qrcode_shapes_images.map((qrData) => {
                                return (
                                    <>
                                        <Col xs={3} sm={3} md={2} className={`m-0 p-0 d-flex align-items-center justify-content-center`}>
                                            <div
                                                className={`${screenSize <= 576 ? 'w-80' : 'w-90'}`}
                                                style={{
                                                    border: selectedQRShape?._id == qrData._id ? "2px solid #D23F99" : "2px solid #fff",
                                                    borderRadius: selectedQRShape?._id == qrData._id && "20%",
                                                    cursor: "pointer",
                                                    position: selectedQRShape?._id == qrData._id && "relative",
                                                }}
                                                onClick={() => {
                                                    setSelectedQRShape(qrData);
                                                    // onGenerateQr("", "", "", qrData);
                                                }}
                                            >
                                                {selectedQRShape?._id == qrData._id && <div
                                                    style={{
                                                        position: "absolute",
                                                        top: "-9px",
                                                        right: "-10px",
                                                        height: "23px",
                                                        width: "23px",
                                                        backgroundColor: "#D23F99",
                                                        borderRadius: "50%",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        zIndex: '1'
                                                    }}
                                                >
                                                    {/* <GiCheckMark color={"#fff"} size={13} /> */}
                                                    <Icon path={mdiCheck} size={0.8} style={{ color: "#fff" }} />
                                                </div>}
                                                <img
                                                    src={qrData.imageSrc}
                                                    alt={qrData.alt}
                                                    style={{
                                                        height: screenSize <= 375 ? "40px" : "50px",
                                                        width: screenSize <= 375 ? "40px" : "50px",
                                                        margin: "0.5rem"
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </>
                                )
                            })}
                        </Row>
                    </Col>
                </ModalBody>
                <ModalFooter className={`d-flex align-items-center justify-content-center py-3`}>
                    <Button
                        variant="primary"
                        className=''
                        style={{ background: '#d23f99', color: 'white', fontSize: screenSize <= 576 ? "16px" : "14px", padding: "10px 40px " }}
                        onClick={() => {
                            toggleQrCodeShapeModal();
                            selectQrCodeFunc();
                        }}
                        id="qr_shape_select_btn_id"
                    >
                        Select Shape
                    </Button>
                </ModalFooter>
            </Modal>

            {
                modal && <CropperModel
                    file={imageSrc}
                    raw={raw}
                    getRoundedCrop={true}
                    onConfirm={(croppedFile) => {
                        if (croppedFile) {
                            getCropData(croppedFile);
                        }
                    }}
                    onCompleted={(croppedFile, roundedCrop) => {
                        setraw(null);
                        toggle();
                    }}
                />
            }


            {
                loading ? <UploadingLoader
                    customView={
                        <div>
                            <div className="border-bottom px-4 py-3">
                                <h4 className="m-0" style={{ fontSize: "16px" }}>
                                    <b style={{ color: 'black' }}>SAVING CHANGE(S)</b>
                                </h4>
                            </div>

                            <div className="px-4 py-4">
                                <h5 className="mx-0 mt-0 mb-2" style={{ color: '#3E3E3E', fontSize: "14px" }}>
                                    Please do not leave this page or your changes will not get saved.
                                </h5>
                                <Progress animated color="warning" value={100} />
                            </div>
                        </div>
                    }
                    widthSize={"37%"}
                />
                    : logoLoader ? <UploadingLoader />
                        : null
            }

        </div >
    );
}

export default StoreBizLinkComp;