import * as actionTypes from "./actionTypes";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import moment from "moment";
import { toast } from "react-toastify";

import * as actionCreators from "./index";

export const reverToInitialState = () => {
    return {
        type: actionTypes.REVERTO_INITIALSTATE
    }
}

export const auth_start = () => {
    return {
        type: actionTypes.AUTH_START
    }
}

export const auth_success = (token, successMsg) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        successMsg: successMsg
    }
}

export const auth_fail = (errorMsg) => {
    return {
        type: actionTypes.AUTH_FAIL,
        errorMsg: errorMsg
    }
}

export const auth = (email, password, device_loggedIn, history, browserId, toast) => {
    return dispatch => {
        dispatch(auth_start());

        const formData = {};
        formData['email'] = email;
        formData['password'] = password;
        formData['device'] = device_loggedIn
        client.post(api.admin_signin, formData, (error, response) => {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            if (response.is_unique) {
                                console.log(response);
                                const deviceDetails = JSON.stringify(response.result.data.devices_loggedIn)
                                history.push({
                                    pathname: "/verify",
                                    search: "?id=" + response.result.data.id
                                });
                            } else {
                                if (response.expiration) {
                                    console.log(response);
                                    history.push({
                                        pathname: "/verify",
                                        search: "?id=" + response.result.data.id + "&time=" + response.result.data.time
                                    });
                                } else {
                                    //console.log(response);
                                    localStorage.setItem('browserId', browserId)
                                    localStorage.setItem('user', JSON.stringify(response.result.data.user))
                                    localStorage.setItem('token', `Bearer ${response.result.data.token}`);
                                    localStorage.setItem('userId', response.result.data.user._id);
                                    localStorage.setItem('sessionCountryCode', "AU");
                                    localStorage.setItem('sessionCountryName', "Australia");
                                    localStorage.setItem("restrictedfeatures", JSON.stringify(response.result.data.user.hide_fields))
                                    localStorage.setItem('accessLevel', response.result.data.user.access_level);
                                    dispatch(auth_success(response.result.data.token, response.message))
                                }
                            }
                        } else {
                            //console.log(response.message)
                            dispatch(auth_fail(response.message))
                        }
                    } catch (e) {
                        //console.log(response.message)
                        dispatch(auth_fail(response.message))
                    }
                } else {
                    //console.log(response.message)
                    dispatch(auth_fail(response.message))
                }
            } else {
                //console.log(response.message)
                dispatch(auth_fail(response.message))
            }

        });
    }
}

export const verifyOtp_start = () => {
    return {
        type: actionTypes.VERIFY_OTP_START
    }
}

export const verifyOtp_success = (token, successMsg) => {
    return {
        type: actionTypes.VERIFY_OTP_SUCCESS,
        token: token,
        successMsg: successMsg

    }
}

export const verifyOtp_fail = (errorMsg) => {
    return {
        type: actionTypes.VERIFY_OTP_FAIL,
        errorMsg: errorMsg
    }
}

export const verifyOtp = (postData, browserId) => {
    return dispatch => {
        dispatch(verifyOtp_start());

        client.post(api.admin_verifyOtp, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    localStorage.setItem('browserId', browserId)
                    localStorage.setItem('user', JSON.stringify(response.result.data.user))
                    localStorage.setItem('token', `Bearer ${response.result.data.token}`);
                    localStorage.setItem('userId', response.result.data.user._id);
                    localStorage.setItem('sessionCountryCode', "AU");
                    localStorage.setItem('sessionCountryName', "Australia");
                    localStorage.setItem("restrictedfeatures", JSON.stringify(response.result.data.user.hide_fields))
                    localStorage.setItem('accessLevel', response.result.data.user.access_level);
                    dispatch(verifyOtp_success(response.result.data.token, response.message))
                } else {
                    dispatch(verifyOtp_fail(response.message))
                }
            } else {
                dispatch(verifyOtp_fail("Internal error occured.Please contact support."))
            }
        });


    }
}


export const logout_start = () => {
    return {
        type: actionTypes.LOGOUT_START
    }
}

export const logout_success = (successMsg) => {
    localStorage.removeItem('browserId');
    localStorage.removeItem("loggedIn")
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("accessLevel");
    localStorage.removeItem("restrictedfeatures");
    localStorage.removeItem("user");
    return {
        type: actionTypes.LOGOUT_SUCCESS,
        response: successMsg
    }
}

export const logout_fail = (errorMsg) => {
    return {
        type: actionTypes.LOGOUT_FAIL,
        response: errorMsg
    }
}

export const logout = (userId, browserId, token, history) => {
    return dispatch => {
        dispatch(logout_start());
        const postData = {
            userId,
            browserId,
            token
        }
        client.post(api.admin_logout, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    //console.log(response)
                    dispatch(logout_success(response.message));
                    history.replace(`${process.env.PUBLIC_URL}/login`)
                } else {
                    // console.log(response)
                    dispatch(logout_fail(response.message))
                }
            } else {
                // console.log(response)
                dispatch(logout_fail("Internal error occured.Please contaxt support."))
            }
        });
    }

}

export const authCheckState = (history) => {

    return dispatch => {
        let postdata = { userId: localStorage.getItem("userId") };
        client.post(api.admin_details, postdata, (error, response) => {

            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            let token = localStorage.getItem("token");
                            token = token.split(" ")[1]
                            const user = response.result.data;
                            const foundToken = user.tokens.find(tok => {
                                return tok.token == token
                            });
                            if (!foundToken) {
                                dispatch(logout_success("Logout Successfully"));
                                history.replace(`${process.env.PUBLIC_URL}/login`);
                            } else {
                                const token = localStorage.getItem("token");
                                const tok = token.split(" ")[1]
                                const userId = localStorage.getItem("userId");
                                const browserId = localStorage.getItem("browserId");

                                if (new Date() - new Date(user.last_loggedInVerify) > 24 * 60 * 60 * 1000) {

                                    const postData = {
                                        userId: userId,
                                        browserId: browserId,
                                        token: token
                                    }

                                    client.post(api.admin_logout, postData, (error, response) => {
                                        if (!error) {
                                            if (!response.error) {
                                                dispatch(logout_success(response.message));
                                                history.replace(`${process.env.PUBLIC_URL}/login`);
                                            } else {
                                                dispatch(logout_fail(response.message))
                                            }
                                        } else {
                                            dispatch(logout_fail("Internal error occured.Please contaxt support."))
                                        }
                                    });

                                } else {
                                    dispatch(auth_success(tok))
                                }

                            }

                        } else {
                            setTimeout(() => {
                                //toast.error(response.message);
                                console.log(response.message);
                            }, 200);
                        }
                    } catch (e) {
                        setTimeout(() => {
                            //toast.error("Internal error occured. Please contact support");
                            console.log(response.message);
                        }, 200);
                        // _showToast.error("Internal error occured. Please contact support.");
                        // currentComponent.setState({ errormessage: "Internal error." });
                    }
                } else {
                    setTimeout(() => {
                        //toast.error("Internal error occured. Please contact support");
                        console.log(response.message);
                    }, 200);
                    //   _showToast.error("Internal error occured. Please contact support.");
                }
            } else {
                setTimeout(() => {
                    //toast.error("Internal error occured. Please contact support");
                    history.replace(`${process.env.PUBLIC_URL}/login`)
                }, 200);
                // alert(JSON.stringify(error));
                // _showToast.error("Internal error occured. Please contact support.");
            }
            // current.setState({ loading: false });
        });

    }
}

export const logoutDevice_start = () => {
    return {
        type: actionTypes.LOGOUTDEVICE_START
    }
}

export const logoutDevice_success = (successMsg) => {
    return {
        type: actionTypes.LOGOUTDEVICE_SUCCESS,
        response: successMsg
    }
}

export const logoutDevice_fail = (errorMsg) => {
    return {
        type: actionTypes.LOGOUTDEVICE_FAIL,
        response: errorMsg
    }
}

export const logoutDevice = (userId, browserId, token) => {
    return dispatch => {
        dispatch(logoutDevice_start());
        const postData = {
            userId,
            browserId,
            token
        }
        client.post(api.admin_deviceLogout, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    dispatch(logoutDevice_success(response.message))
                } else {
                    dispatch(logoutDevice_fail(response.message))
                }
            } else {
                dispatch(logoutDevice_fail(response.message))
            }
        });
    }
}

export const logoutAll_start = () => {
    return {
        type: actionTypes.LOGOUTALL_START
    }
}

export const logoutAll_success = (successMsg) => {
    return {
        type: actionTypes.LOGOUTALL_SUCCESS,
        response: successMsg
    }
}

export const logoutAll_fail = (errorMsg) => {
    return {
        type: actionTypes.LOGOUTALL_FAIL,
        response: errorMsg
    }
}

export const logoutAll = (userId, type, history) => {
    return dispatch => {
        dispatch(logoutAll_start());
        const postData = { userId: userId }
        client.post(api.admin_logoutAll, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    dispatch(logoutAll_success(response.message));
                    if (type == "logoutClick") {
                        dispatch(logout_success(response.message));
                        history.replace(`${process.env.PUBLIC_URL}/login`)
                    } else if (type == "logoutAllClick") {
                        window.location.reload(false);
                    }

                } else {
                    dispatch(logoutAll_fail(response.message))
                }
            } else {
                dispatch(logoutAll_fail(response.message))
            }
        });
    }
}

export const resendotp_start = () => {
    return {
        type: actionTypes.RESENT_OTP_START
    }
}

export const resendotp_success = (successMsg) => {
    return {
        type: actionTypes.RESENT_OTP_SUCCESS,
        successMsg
    }
}

export const resendotp_failed = (errorMsg) => {
    return {
        type: actionTypes.RESENT_OTP_FAILED,
        errorMsg
    }
}

export const resendotp = (admin_id) => {
    return dispatch => {
        dispatch(resendotp_start());
        const postData = { admin_id: admin_id }
        client.post(api.admin_resendOtp, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    console.log(response);
                    dispatch(resendotp_success(response.message));
                } else {
                    dispatch(resendotp_failed(response.message))
                }
            } else {
                dispatch(resendotp_failed("Internal error occured.Please contact support"))
            }
        });
    }
}