import React, { Component, Fragment } from "react";
import * as devices from "react-device-detect";
import device from 'device';
import useragent from 'useragent';
import { TabContent, TabPane, Nav, NavItem, NavLink as NavLinkTab } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import {DisappearedLoading} from "react-loadingg";

import ActiveSessions from "./ActiveSessions";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import Breadcrumb from "../../components/common/breadcrumb";
import RecentLogins from "./RecentLogins";
import AdminUserProfile from "./AdminuserProfile";

class AdminUserDetails extends Component {

  state = {
    adminUserId: this.props.match.params.userId,
    adminDetails: null,
    activeTab: "1",
    restrictedFeatures: null

  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  componentDidMount() {
    this.getAdminUserDetails();
    this.getAllRestFeatures();
    // const userAgent =  devices.getUA;
    // console.log(userAgent);
    // /* var agent = useragent.parse(req.headers['user-agent']);
    //   agent.device.toString(); */

    // const myDevice = device("Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/84.0.4147.135 Safari/537.36", {parseUserAgent : true});
    // console.log(myDevice);
  }

  getAllRestFeatures = () => {
    const postdata = {};
    client.post(api.get_all_restFeature, postdata, (error, response) => {
      if (!error) {
        if (!response.error) {
          console.log(response);
          this.setState({ restrictedFeatures: response.result.data })
        } else {
          setTimeout(() => {
            toast.error(response.message);
          }, 200);

        }
      } else {
        console.log(response);
        setTimeout(() => {
          toast.error("Internal error occured. Please contact support");
        }, 200);
      }
    });
  }

  getAdminUserDetails = () => {
    let { adminUserId } = this.state;
    let postdata = { userId: adminUserId };
    client.post(api.admin_details, postdata, (error, response) => {
      if (!error) {
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              //console.log(response);
              this.setState({ adminDetails: response.result.data });
            } else {
              setTimeout(() => {
                toast.error(response.message);
              }, 200);
            }
          } catch (e) {
            setTimeout(() => {
              toast.error("Internal error occured. Please contact support");
            }, 200);
            // _showToast.error("Internal error occured. Please contact support.");
            // currentComponent.setState({ errormessage: "Internal error." });
          }
        } else {
          setTimeout(() => {
            toast.error("Internal error occured. Please contact support");
          }, 200);
          //   _showToast.error("Internal error occured. Please contact support.");
        }
      } else {
        setTimeout(() => {
          toast.error("Internal error occured. Please contact support");
        }, 200);
        // alert(JSON.stringify(error));
        // _showToast.error("Internal error occured. Please contact support.");
      }
      // current.setState({ loading: false });
    });
  };


  render() {
    const { adminDetails, adminUserId, restrictedFeatures } = this.state;
    console.log(restrictedFeatures);
    return (

      <Fragment>
        {adminDetails ?
          <Fragment>
          <div className="col-lg-7 col-sm-12" style={{fontSize:"12px"}}>
            <Breadcrumb title={adminDetails.full_name} parent={"Admin Users"} />
            </div>
            {restrictedFeatures &&
              <div className="container-fluid">
                <div className="row theme-tab">
                  <Tabs className="col-sm-12" style={{ padding: "0",backgroundColor:"white",borderRadius:"3px",border:"1px solid rgb(223, 232, 241)",marginBottom:"0px" }} >
                    <TabList className="tabs tab-title">
                      <Tab className="current" style={{display:"flex",alignItems:"center"}}>
                       <span style={{fontSize:"12px"}}> Profile Info</span>
                      </Tab>

                      <Tab style={{display:"flex",alignItems:"center"}}>
                        Recent Logins
                      </Tab>

                      <Tab style={{display:"flex",alignItems:"center"}}>
                        Active Sessions
                      </Tab>
                    </TabList>

                    <div className="tab-content-cls">
                      <TabPanel>
                        <AdminUserProfile adminDetails={adminDetails} adminUserId={adminUserId} restrictedFeatures={restrictedFeatures}></AdminUserProfile>
                      </TabPanel>

                      <TabPanel>
                        <RecentLogins adminDetails={adminDetails} adminUserId={adminUserId}></RecentLogins>
                      </TabPanel>

                      <TabPanel>
                        <ActiveSessions adminDetails={adminDetails} adminUserId={adminUserId}></ActiveSessions>
                      </TabPanel>

                    </div>
                  </Tabs>
                </div>
              </div> }
            {/* <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 ">
                  
                  <Nav tabs className="nav-pills nav-primary" style={{marginBottom:"1.8rem"}}>
                    <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                      <NavLinkTab className={this.state.activeTab == '1' ? 'active nav-link' : ''} onClick={() => { this.toggle('1') }} value='1'>
                        
                                      Profile Info
                            </NavLinkTab>
                    </NavItem>
                    <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                      <NavLinkTab className={this.state.activeTab == '2' ? 'active nav-link' : ''} onClick={() => { this.toggle('2') }} value='2'>
                        
                                      Recent Logins
                          </NavLinkTab>
                    </NavItem>
                    <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                      <NavLinkTab className={this.state.activeTab == '3' ? 'active nav-link' : ''} onClick={() => { this.toggle('3') }} value='3'>

                                      Active Sessions
                          </NavLinkTab>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={this.state.activeTab} >

                    <TabPane tabId="1">
                      <AdminUserProfile adminDetails={adminDetails} adminUserId={adminUserId} restrictedFeatures={restrictedFeatures}></AdminUserProfile>
                    </TabPane>

                    <TabPane tabId="2">
                      <RecentLogins adminDetails={adminDetails} adminUserId={adminUserId}></RecentLogins>
                    </TabPane>

                    <TabPane tabId="3">
                      <ActiveSessions adminDetails={adminDetails} adminUserId={adminUserId}></ActiveSessions>
                    </TabPane>

                  </TabContent>

                </div>
              </div>
            </div> */}
          </Fragment> : <div className="loader-box" style={{height:"100vh",width:"100wh"}}>
                              <div className="loader" style={{ display:"flex",alignItems:"center" }}>
                              <DisappearedLoading size="medium" color="#514F4E" />
                               </div> 
                               </div>}
      </Fragment>
    );
  }
}

export default AdminUserDetails;