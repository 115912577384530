import React from "react";
import { Row, Col } from "reactstrap";
import "./Result.css";

function ResultSurvey() {
	return (
		<div className="card">
			<div className="card-body">
				<Row>
					<Col className="tabCont">
						<h6 className="upperNumber">3083</h6>
						<h6 className="lowerTitle">Sent</h6>
					</Col>
					<Col className="tabCont">
						<h6 className="upperNumber">21</h6>
						<h6>Opened</h6>
					</Col>
					<Col className="tabCont">
						<h6 className="upperNumber">3061</h6>
						<h6>Unopened</h6>
					</Col>
					<Col className="tabCont">
						<h6 className="upperNumber">15</h6>
						<h6>Response</h6>
					</Col>
					<Col className="tabCont">
						<h6 className="upperNumber">1000</h6>
						<h6>Total Spent</h6>
					</Col>
				</Row>
			</div>
			<div className="card-body">
				<div className="table-responsive">
					<table className="table table-hover table-border-horizontal">
						<thead>
							<tr>
								<th className="tableheading " scope="col">
									#
								</th>
								<th className="tableheading" scope="col">
									First Name
								</th>
								<th className="tableheading" scope="col">
									Last Name
								</th>
								<th className="tableheading" scope="col">
									Username
								</th>
								<th className="tableheading" scope="col">
									Role
								</th>
								<th className="tableheading" scope="col">
									Country
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th>1</th>
								<td className="tablecell">Alexander</td>
								<td className="tablecell">Orton</td>
								<td className="tablecell">@mdorton</td>
								<td className="tablecell">Admin</td>
								<td className="tablecell">USA</td>
							</tr>
							<tr>
								<th>2</th>
								<td className="tablecell">John Deo</td>
								<td className="tablecell">Deo</td>
								<td className="tablecell">@johndeo</td>
								<td className="tablecell">User</td>
								<td className="tablecell">USA</td>
							</tr>
							<tr>
								<th>3</th>
								<td className="tablecell">Randy Orton</td>
								<td className="tablecell">the Bird</td>
								<td className="tablecell">@twitter</td>
								<td className="tablecell">admin</td>
								<td className="tablecell">UK</td>
							</tr>
							<tr>
								<th>4</th>
								<td className="tablecell">Randy Mark</td>
								<td className="tablecell">Ottandy</td>
								<td className="tablecell">@mdothe</td>
								<td className="tablecell">user</td>
								<td className="tablecell">AUS</td>
							</tr>
							<tr>
								<th>5</th>
								<td className="tablecell">Ram Jacob</td>
								<td className="tablecell">Thornton</td>
								<td className="tablecell">@twitter</td>
								<td className="tablecell">admin</td>
								<td className="tablecell">IND</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}

export default ResultSurvey;
