import React, { useState } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { Col } from "reactstrap";
import CustomerApp from "./CustomerApp";
import MerchantApp from "./MerchantApp";

const SliderSettings = () => {
	const [tabIndex, setTabIndex] = useState(0);
	return (
		<div className="card">
			<div className="card-header">
				<Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
					<TabList className="tbs tab-title">
						<Col>
							<Tab>Customer App</Tab>
							<Tab>Merchant App</Tab>
						</Col>
					</TabList>
					<TabPanel>
						<CustomerApp setTabIndex={setTabIndex}></CustomerApp>
					</TabPanel>
					<TabPanel>
						<MerchantApp setTabIndex={setTabIndex}></MerchantApp>
					</TabPanel>
				</Tabs>
			</div>
		</div>
	);
};

export default SliderSettings;
