import React, { Fragment, useEffect, useRef, useState, useMemo } from "react";
import { connect } from "react-redux";
import { Card, CardBody, Tooltip } from 'reactstrap';
import ContentCopyIcon from "mdi-react/ContentCopyIcon";
import * as actionCreators from "../../store/actions/index";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { toast } from "react-toastify";
import QrCodeComp from "./MinisiteAndQrCode/StoreQrCodeComp";
import PhoneViewPage from "./PhoneViewPage/PhoneViewPage"
import { BorderBottom } from "@material-ui/icons";
import ErrorControl from "./ErrorControl";


const MerchantQr = (props) => {

    const { merchant_info } = props;
    const [textCopy, setTextCopy] = useState(false);
    const [qrFrontSideData, setQrFrontSideData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [qrBtn, setqrBtn] = useState([]);
    const [defaulttipamount, setdefaulttipamount] = useState(0);
    // console.log(qrFrontSideData, "qrFrontSideData");

    let Qrbuttons = [
        // { title: 'Home Page Settings', img: '', id: "store", enable: true, isMenu: true, },
        { title: 'Store Info', img: '', id: "info", enable: true, isMenu: true, },
        // { title: 'Accepts Payments', img: '', id: "payment", enable: true, isMenu: true, },
        // ...(catFea[0]?.visitor_registry == 1 ? { title: 'Visitor registry', img: '', id: "visitor", enable: true, isMenu: true, } : []),
        // { title: 'Visitor registry', img: '', id: "visitor", enable: true, isMenu: true, },
        // { title: `Store's order link`, img: '', id: "order", enable: true, isMenu: true, },
        // { title: 'Tips & Reviews', img: '', id: "tip", enable: true, isMenu: true, },
        { title: 'Clean table', img: '', id: "clean", enable: true, isMenu: false, },
        { title: 'Offers & Vouchers', img: '', id: "offer", enable: true, isMenu: true, },
        // { title: 'Sell Tickets', img: '', id: "sell", enable: true, isMenu: true, },
        // { title: 'Private Gallery', img: '', id: "donation", enable: true, isMenu: true, },
        // { title: 'Call Waiter', img: '', id: "waiter", enable: true, isMenu: true, },
        // { title: 'Book a table', img: '', id: "table", enable: true, isMenu: true, },
        { title: 'Add more buttons', img: '', id: "other", enable: true, isMenu: true, },
        { title: 'Minisite', img: '', id: "minisite", enable: true, isMenu: true, },
        { title: 'Header', img: '', id: "header", enable: true, isMenu: true, },
        { title: 'Theme', img: '', id: "theme", enable: true, isMenu: true, },
        { title: 'Background', img: '', id: "background", enable: true, isMenu: true, },
        { title: 'Button', img: '', id: "button", enable: true, isMenu: true, },
        { title: 'Fonts', img: '', id: "fonts", enable: true, isMenu: true, },
        // { title: 'Other Setting', img: '', id: "drag", enable: true, isMenu: true, },
    ]


    let getHostname = window && window.location.host.includes('dm.') ? 'dm.' : window && window.location.host.includes('localhost') ? 'dm.' : '';


    const fetchQrFrontSideData = () => {

        let postData = { merchant_ref: merchant_info._id };
        setIsLoading(true);
        client.put(api.fetchQrFronside, postData, (error, response) => {

            if (!error) {
                if (!response.error) {
                    setQrFrontSideData(response.result.data)
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    toast.error("Internal error occured.Please contact support");
                }
            } else {
                toast.error("Internal error occured.Please contact support");
                setIsLoading(false);
            }
        });
    }

    useEffect(() => {

        if (!qrFrontSideData) {
            fetchQrFrontSideData();
        }

    }, [])

    useEffect(() => {

        if (qrFrontSideData && qrFrontSideData.merchant_ref) {

            try {

                if (qrFrontSideData?.Qrbtn.length > 0) {

                    let getQrFrontSideData = qrFrontSideData?.Qrbtn.map(qrMenBtn => {
                        let getQrbnsData = Qrbuttons.find(Qrbns => Qrbns.id == qrMenBtn.id)
                        getQrbnsData = getQrbnsData ? getQrbnsData : qrMenBtn;
                        return {
                            ...getQrbnsData,
                            isMenu: getQrbnsData ? getQrbnsData.isMenu : false
                        }
                    })

                    Qrbuttons.map(otheBtn => {
                        let getIndex = getQrFrontSideData.find(otheBtns => otheBtns.id == otheBtn.id)
                        if (getIndex) { }
                        else {
                            getQrFrontSideData.push({
                                ...otheBtn
                            })
                        }
                    })

                    qrFrontSideData.other_fields.map(otheBtn => {
                        let getIndex = getQrFrontSideData.find(otheBtns => otheBtns.id == otheBtn._id)
                        if (getIndex) { }
                        else {
                            getQrFrontSideData.push({
                                title: otheBtn.button_name,
                                img: '',
                                id: otheBtn._id,
                                enable: true,
                                isMenu: false,
                            })
                        }
                    })

                    setqrBtn(getQrFrontSideData);

                } else {
                    setqrBtn(Qrbuttons)
                }

                setdefaulttipamount(qrFrontSideData.tip_button_props.tip_structures.findIndex(v => v.isSelected === true))


            } catch (error) {
                console.log(error, "error");
            }

        } else {
            //toast.error("Something went wrong!")
        }
    }, [qrFrontSideData])


    const combineAllBUttons = () => {
        let allMenBUttons = [...qrBtn]
        qrFrontSideData && qrFrontSideData.other_fields && qrFrontSideData.other_fields.map(othFld => {
            let getIndex = allMenBUttons.findIndex(qrBtnItem => qrBtnItem.id == othFld._id);
            if (getIndex != -1) {
                allMenBUttons[getIndex] = {
                    ...allMenBUttons[getIndex],
                    ...othFld,
                    img: othFld.img,
                    buttonType: 'other_buttons'
                }
            } else {
                allMenBUttons.push({
                    ...othFld,
                    img: othFld.img,
                    buttonType: 'other_buttons'
                })
            }
        })


        return allMenBUttons;
    }


    const [screenWidth, setScreenWidth] = useState(window?.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window?.innerHeight);

    useEffect(() => {

        function updateSize() {

            setScreenWidth(window?.innerWidth);
            setScreenHeight(window?.innerHeight);
        }

        window.addEventListener("resize", updateSize);
        updateSize();

        return () => window.removeEventListener("resize", updateSize);

    }, []);


    return (
        <Fragment>
            <Card>
                <CardBody style={{ padding: "0px" }}>
                    <div className="row mx-0 w-100">
                        <div
                            className="qrpage-links"
                            style={{
                                width: screenWidth <= 992 ? "100%" : "45%",
                                height: screenWidth <= 992 && "100vh",
                                padding: "2rem",
                                borderRight: screenWidth > 992 && "1px solid #dee2e6",
                                BorderBottom: screenWidth < 992 && "1px solid #dee2e6"
                            }}>
                            <div className="mb-5">
                                <h6 style={{ fontWeight: "bold", marginBottom: "0.2rem", fontSize: "0.85rem" }}>PREVIEW LINK</h6>
                                <div className="qr-details__name mt-3">
                                    <span style={{ color: "grey", textDecoration: "underline" }}>{`https://${getHostname}linky.biz/${merchant_info.unique_id}`}</span>
                                    <CopyToClipboard text={`https://${getHostname}linky.biz/${merchant_info.unique_id}`}
                                        onCopy={() => setTextCopy(true)}>
                                        <span className="font-weight-bold" id="link-copy" style={{ cursor: "pointer" }}><ContentCopyIcon /></span>
                                    </CopyToClipboard>
                                </div>
                                <Tooltip placement="bottom" target="link-copy" isOpen={textCopy}>
                                    Copied!
                                </Tooltip>
                            </div>


                            <div className="d-flex align-items-center justify-content-center" style={{ height: !qrFrontSideData && "180px" }}>
                                {
                                    qrFrontSideData ? <ErrorControl><PhoneViewPage
                                        qrFrontSideData={qrFrontSideData}
                                        merchantData={merchant_info}
                                        combineAllBUttons={combineAllBUttons}
                                        qrBtn={qrBtn}
                                    /></ErrorControl> : <div className="" style={{ fontSize: "16px" }}>Loading...</div>
                                }
                            </div>
                        </div>
                        <div className="my-qr__details" style={{ width: screenWidth <= 992 ? "100%" : "55%", height: screenWidth <= 992 && "100vh", padding: "2rem" }}>
                            <div style={{ margin: "0 0 1.5rem" }}>
                                <div className="qr-details__heading">
                                    <h6 style={{ fontWeight: "bold", textAlign: "center", fontSize: "0.85rem" }}>QR CODE</h6>
                                </div>
                                <QrCodeComp merchantData={merchant_info} />
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Fragment>

    );
}

const mapStateToProps = state => {
    return {
        reducererror: state.merchant.error,
        reducerloading: state.merchant.loading,
        restaurantStructure: state.reservationStructure.restaurantStructure,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateDetails_success: (data) => dispatch(actionCreators.updateDetails_success(data, null)),
        onUpdateDetails: (data) => dispatch(actionCreators.updateDetails(data)),
        onReverToInitialState: () => dispatch(actionCreators.revertToInitialState()),
        onFetchRestaurantStructure: (postData) => dispatch(actionCreators.fetch_restaurantStructure(postData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MerchantQr);