import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import client from "../../Api/HTTPClient";
import Customerbillings from "./customerbillings";
import OtherCharges from "./OtherCharges";
import Invoices from "./Invoices";
import { DisappearedLoading } from 'react-loadingg';
import './settings.scss'
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
// import { Link as NavLink } from "react-router-dom";
import useForm from 'react-hook-form'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { grey, red } from "@material-ui/core/colors";
import Switch from "react-switch";
import Sms_rates from "./SmsRates";
import PaymentGateway from "./PaymentGateway";


// const BorderTable = () => {
function BorderTable() {

  let { register, handleSubmit, errors } = useForm(); // initialise the hook

  let [loadingbtn, setloadingbtn] = useState(false);
  let [loading, setloading] = useState(false);
  let [billingdata, setbillingdata] = useState(null);
  let [billingdatapaypal, setbillingdatapaypal] = useState(null);
  // console.log(billingdatapaypal, "billingdatapaypal")
  let [custbillingdata, setcustbillingdata] = useState(null);
  let [tabIndex, setTabIndex] = useState(4);
  const [access_level, setAccessLevel] = useState(localStorage.getItem("accessLevel"))

  const [sms_rate_data, setSmsRateData] = useState([])

  let [c_to_m_sc_fee_btype, setc_to_m_sc_fee_btype] = useState("1");
  let [c_to_m_sc_fee_atype, setc_to_m_sc_fee_atype] = useState("1");
  let [m_to_m_sc_fee_btype, setm_to_m_sc_fee_btype] = useState("1");
  let [m_to_m_sc_fee_atype, setm_to_m_sc_fee_atype] = useState("1");
  let [m_to_c_sc_fee_btype, setm_to_c_sc_fee_btype] = useState("1");
  let [m_to_c_sc_fee_atype, setm_to_c_sc_fee_atype] = useState("1");
  let [wd_fee_btype, setwd_fee_btype] = useState("1");
  let [wd_fee_atype, setwd_fee_atype] = useState("1");

  let [countryDetails, setcountryDetails] = useState(null);
  let [topupmethods, settopupmethods] = useState(null);

  let [min, setMin] = useState(0);
  let [max, setMax] = useState(0);

  let [ticket_fee_btype, setticket_fee_btype] = useState("1");
  let [ticket_fee_atype, setticket_fee_atype] = useState("1");

  let [venue_ipay_fee_payer, setvenue_ipay_fee_payer] = useState(false);
  let [pl_ipay_fee_payer, setpl_ipay_fee_payer] = useState(false);

  useEffect(() => {
    let postdata = { helo: 'Helo' }
    client.post(api.find_sms_rate, postdata, async function (error, response) {
      if (!error) {

        setSmsRateData(response.result.data)
      } else {
        toast.error("Internal error occured. Please contact support");
        // _showToast.error("Internal error occured. Please contact support.");
      }
      // current.setState({ loading: false });
      setloading(false);

    });

  }, [])


  useEffect(() => {

    client.post(api.fetch_country, { country_code: localStorage.getItem("sessionCountryCode") }, async (error, response) => {
      if (!error) {
        try {
          if (!response.error) {
            let r = response.result.data;
            setMin(r.verify_card_charges.min)
            setMax(r.verify_card_charges.max)

          }
        } catch (e) { }
      } else {
        // toast.error("Internal error occured.Please contact support");
      }
      setloading(false)
    });

  
  }, [])
  
  useEffect(() => {
    fetch_topup_setting();
  }, []);

  const fetch_topup_setting = () => {

    let country_code = localStorage.getItem("sessionCountryCode");
    
    let postdata = {
      "country_code": country_code
    }

    client.post(api.fetch_top_up_details, postdata, async function (
      error,
      response
    ) {
      if (!error) {
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              let country_data = response.result["country"];
              let topupmethods = response.result["topupmethods"];
              if (country_data) {
                setcountryDetails(country_data);
                settopupmethods(topupmethods);
              }
            }
          } catch (e) { }
        }
      }
      setloading(false)
    });
  }



  const onSubmit = data => {
    if (data !== '') {

      data.c_to_m_sc_fee_btype = c_to_m_sc_fee_btype;
      data.c_to_m_sc_fee_atype = c_to_m_sc_fee_atype;
      data.m_to_m_sc_fee_btype = m_to_m_sc_fee_btype;
      data.m_to_m_sc_fee_atype = m_to_m_sc_fee_atype;
      data.m_to_c_sc_fee_btype = m_to_c_sc_fee_btype;
      data.m_to_c_sc_fee_atype = m_to_c_sc_fee_atype;
      data.wd_fee_btype = wd_fee_btype;
      data.wd_fee_atype = wd_fee_atype;
      data.min = min;
      data.max = max;
      // data.ticket_fee_atype = ticket_fee_atype;
      // data.ticket_fee_btype = ticket_fee_btype;
      data.venue_ipay_fee_payer = venue_ipay_fee_payer ? 1 : 0;;
      data.pl_ipay_fee_payer = pl_ipay_fee_payer ? 1 : 0;
      data.gateway=data.gateway?data.gateway:'STRIPE';


      setloadingbtn(true);
      client.post(api.update_billing_settings, data, async function (error, response) {
        if (!error) {
          // alert(JSON.stringify(response));
          //   return false;
          if (typeof response.error !== "undefined") {
            try {
              if (!response.error) {

                getcashback_settings();
                toast.success("Updated Successfully");
                // setcashbackdata(response.result.data);
              } else {
                toast.error(response.message);
              }
            } catch (e) {
              toast.error("Internal error occured. Please contact support");
            }
          } else {
            toast.error("Internal error occured. Please contact support");
          }
        } else {
          toast.error("Internal error occured. Please contact support");
        }
        setloadingbtn(false);
      });
    } else {

      errors.showMessages();
    }
  };


  const getcashback_settings = async () => {
    let postdata = {};
    let current = this;
    // current.setState({ loading: true });
    setloading(true);
    client.post(api.mbillingconfig, postdata, async function (error, response) {
      if (!error) {
        // console.log(JSON.stringify(response.result.data, "test -data"));
        //   return false;
        console.log(response)
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              // alert(response.result.data[0])
              setbillingdata(response.result.data);
              setbillingdatapaypal(response.result.result_paypal);
              setcustbillingdata(response.result.datacust);

              // current.props.navigation.navigate("Verification");
            } else {
              toast.error(response.message);
            }
          } catch (e) {
            toast.error("Internal error occured. Please contact support");
          }
        } else {
          toast.error("Internal error occured. Please contact support");
        }
      } else {
        toast.error("Internal error occured. Please contact support");
        // _showToast.error("Internal error occured. Please contact support.");
      }
      // current.setState({ loading: false });
      setloading(false);

    });
  };

  useEffect(() => {
    // alert("hrer");
    getcashback_settings();
  }, [])

  useEffect(() => {
    // alert("hrer");

    let res = billingdata
    if (tabIndex == 1) {
      res = billingdatapaypal
    }
    if (res) {
    
      setc_to_m_sc_fee_btype(res.c_to_m_sc_fee_btype);
      setc_to_m_sc_fee_atype(res.c_to_m_sc_fee_atype);
      setm_to_m_sc_fee_btype(res.m_to_m_sc_fee_btype);
      setm_to_m_sc_fee_atype(res.m_to_m_sc_fee_atype);
      setm_to_c_sc_fee_btype(res.m_to_c_sc_fee_btype);
      setm_to_c_sc_fee_atype(res.m_to_c_sc_fee_atype);
      setm_to_m_sc_fee_atype(res.m_to_m_sc_fee_atype);

      // setticket_fee_btype(res.ticket_fee_btype);
      // setticket_fee_atype(res.ticket_fee_atype);
    
      setwd_fee_btype(res.wd_fee_btype);
      setwd_fee_atype(res.wd_fee_atype);
      setvenue_ipay_fee_payer(res.venue_ipay_fee_payer);
      setpl_ipay_fee_payer(res.pl_ipay_fee_payer);
    }
    
    // getcashback_settings();
  }, [tabIndex, billingdata, billingdatapaypal])

  const displayDate = (date) => {
    let transaction_date = new Date(date);
    return (
      <span>
        {transaction_date}
      </span>
    )
  }


  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-7 col-sm-12" style={{ fontSize: "12px" }}>
          <Breadcrumb title={"Billing setting"} parent={"Home"} />
        </div>
      </div>

      <div className="container-fluid">
        <div className="row theme-tab">
          <Tabs className="col-sm-12" defaultIndex={tabIndex} onSelect={index => setTabIndex(index)} style={{ padding: "0", backgroundColor: "white", borderRadius: "3px", border: "1px solid rgb(223, 232, 241)", marginBottom: "0px" }}>
            <TabList className="tabs tab-title" >
              <Tab style={{ display: "flex", alignItems: "center" }} >
                Stripe & Linky charges 
              </Tab>
              <Tab style={{ display: "flex", alignItems: "center" }} >
                PayPal & Linky charges 
              </Tab>
              <Tab style={{ display: "flex", alignItems: "center" }} >
                Linky Commissions
              </Tab>
              <Tab style={{ display: "flex", alignItems: "center" }}>
                Payment Gateway
              </Tab>
              {/* <Tab style={{ display: "flex", alignItems: "center" }}>
                Other Charges
              </Tab> */}
              <Tab style={{ display: "flex", alignItems: "center" }}>
                SMS Rate
              </Tab>
              <Tab style={{ display: "flex", alignItems: "center" }}>
                Invoices
              </Tab>
            </TabList>

            {/* {customers ? */}
            <div className="tab-content-cls">
              <TabPanel>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12 col-xl-12 xl-100" style={{ padding: "0" }}>
                      <div className="card">
                        <div className="card-body" style={{ padding: "1rem" }}>
                          {billingdata != null ?
                            <form className="needs-validation theme-form mt-3 billing-form" noValidate="" onSubmit={handleSubmit(onSubmit)}>

                              <input name="gateway" value="STRIPE" type="hidden" />
                              
                              {/* <input name="config_type" value="1" type = "hidden"/> */}
                              {/* <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>iPayPro Service Fee/ C2B "Customer To Business"</h5>
                              <small>When customer pay to a business using iPayPro eWallet</small>
                              <div className="form-row">

                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label className="col-form-label">Minimum Amount</label>
                                    <input className="form-control" step="0.01" min="0" disabled={access_level == 1 ? true : false} type="Number" name="c_to_m_sc_comp_amount" defaultValue={billingdata.c_to_m_sc_comp_amount} ref={register({ required: true })} />
                                    <span>{errors.c_to_m_sc_comp_amount && 'Minimum Amount is requires'}</span>
                                  </div>
                                </div>

                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label className="col-form-label">Fee Type</label>
                                    <select className="form-control select" name="c_to_m_sc_fee_btype" disabled={access_level == 1 ? true : false} value={c_to_m_sc_fee_btype}
                                      onChange={(e) => setc_to_m_sc_fee_btype(e.target.value)}>
                                      <option value="1">Percent</option>
                                      <option value="2">Amount</option>
                                    </select>
                                    <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                  </div>
                                </div>
                                <div className="col-sm-3">
                                  <div className="form-group">
                                    <label className="col-form-label">Fee below min. Amount</label>
                                    <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="c_to_m_sc_fee_below" defaultValue={billingdata.c_to_m_sc_fee_below} ref={register({ required: true })} />
                                    <span>{errors.c_to_m_sc_fee_below && 'Fee is requires'}</span>
                                  </div>
                                </div>

                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label className="col-form-label">Fee Type</label>
                                    <select className="form-control select" name="c_to_m_sc_fee_atype" disabled={access_level == 1 ? true : false} value={c_to_m_sc_fee_atype}
                                      onChange={(e) => setc_to_m_sc_fee_atype(e.target.value)}>
                                      <option value="1">Percent</option>
                                      <option value="2">Amount</option>
                                    </select>

                                    <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                  </div>
                                </div>
                                <div className="col-sm-3">
                                  <div className="form-group">
                                    <label className="col-form-label">Fee above min. Amount</label>
                                    <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="c_to_m_sc_fee_above" ref={register({ required: true })} defaultValue={billingdata.c_to_m_sc_fee_above} />
                                    <span>{errors.c_to_m_sc_fee_above && 'Fee is required'}</span>
                                  </div>
                                </div>
                              </div>

                              <hr /> */}

                              <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>Linky Service Fee</h5>
                              <small>For Payment Link/ Invoice/ Quotes/ Tips/ Tickets/ Linky Meet/ Make a payment button/ Online ordering/ Bookings etc</small>
                              <div className="form-row">


                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label className="col-form-label">Minimum Amount</label>
                                    <input className="form-control" step="0.01" min="0" disabled={access_level == 1 ? true : false} type="Number" name="m_to_m_sc_comp_amount" defaultValue={billingdata.m_to_m_sc_comp_amount} ref={register({ required: true })} />
                                    <span>{errors.m_to_m_sc_comp_amount && 'Minimum Amount is requires'}</span>
                                  </div>
                                </div>

                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label className="col-form-label">Fee Type</label>
                                    <select className="form-control select" name="m_to_m_sc_fee_btype" disabled={access_level == 1 ? true : false} value={m_to_m_sc_fee_btype}
                                      onChange={(e) => setm_to_m_sc_fee_btype(e.target.value)}>
                                      <option value="1">Percent</option>
                                      <option value="2">Amount</option>
                                    </select>

                                    <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                  </div>
                                </div>
                                <div className="col-sm-3">
                                  <div className="form-group">
                                    <label className="col-form-label">Fee below min. Amount</label>
                                    <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="m_to_m_sc_fee_below" defaultValue={billingdata.m_to_m_sc_fee_below} ref={register({ required: true })} />
                                    <span>{errors.m_to_m_sc_fee_below && 'Fee is requires'}</span>
                                  </div>
                                </div>

                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label className="col-form-label">Fee Type</label>
                                    <select className="form-control select" name="m_to_m_sc_fee_atype" disabled={access_level == 1 ? true : false} value={m_to_m_sc_fee_atype}
                                      onChange={(e) => setm_to_m_sc_fee_atype(e.target.value)}>
                                      <option value="1">Percent</option>
                                      <option value="2">Amount</option>
                                    </select>

                                    <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                  </div>
                                </div>
                                <div className="col-sm-3">
                                  <div className="form-group">
                                    <label className="col-form-label">Fee above min. Amount</label>
                                    <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="m_to_m_sc_fee_above" ref={register({ required: true })} defaultValue={billingdata.m_to_m_sc_fee_above} />
                                    <span>{errors.m_to_m_sc_fee_above && 'Fee is required'}</span>
                                  </div>
                                </div>
                              </div>

                              <hr />

                              {/* <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>iPayPro Service Fee/B2C "Business to Customer"</h5>
                              <small>When business pay to a customer using iPayPro eWallet</small>
                              <div className="form-row">


                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label className="col-form-label">Minimum Amount</label>
                                    <input className="form-control" step="0.01" min="0" disabled={access_level == 1 ? true : false} type="Number" name="m_to_c_sc_comp_amount" defaultValue={billingdata.m_to_c_sc_comp_amount} ref={register({ required: true })} />
                                    <span>{errors.m_to_c_sc_comp_amount && 'Minimum Amount is requires'}</span>
                                  </div>
                                </div>

                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label className="col-form-label">Fee Type</label>
                                    <select className="form-control select" name="m_to_c_sc_fee_btype" disabled={access_level == 1 ? true : false} value={m_to_c_sc_fee_btype}
                                      onChange={(e) => setm_to_c_sc_fee_btype(e.target.value)}>
                                      <option value="1">Percent</option>
                                      <option value="2">Amount</option>
                                    </select>

                                    <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                  </div>
                                </div>
                                <div className="col-sm-3">
                                  <div className="form-group">
                                    <label className="col-form-label">Fee below min. Amount</label>
                                    <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="m_to_c_sc_fee_below" defaultValue={billingdata.m_to_c_sc_fee_below} ref={register({ required: true })} />
                                    <span>{errors.m_to_c_sc_fee_below && 'Fee is requires'}</span>
                                  </div>
                                </div>

                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label className="col-form-label">Fee Type</label>
                                    <select className="form-control select" name="m_to_c_sc_fee_atype" disabled={access_level == 1 ? true : false} value={m_to_c_sc_fee_atype}
                                      onChange={(e) => setm_to_c_sc_fee_atype(e.target.value)}>
                                      <option value="1">Percent</option>
                                      <option value="2">Amount</option>
                                    </select>

                                    <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                  </div>
                                </div>
                                <div className="col-sm-3">
                                  <div className="form-group">
                                    <label className="col-form-label">Fee above min. Amount</label>
                                    <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="m_to_c_sc_fee_above" ref={register({ required: true })} defaultValue={billingdata.m_to_c_sc_fee_above} />
                                    <span>{errors.m_to_c_sc_fee_above && 'Fee is required'}</span>
                                  </div>
                                </div>
                              </div> */}

                              

                              <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>Withdrawal Fee</h5>
                              <small>When user send request to withdraw their eWallet funds to their bank account</small>
                              <div className="form-row">

                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label className="col-form-label">Minimum Amount</label>
                                    <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="wd_comp_amount" defaultValue={billingdata.wd_comp_amount} ref={register({ required: true })} />
                                    <span>{errors.wd_comp_amount && 'Minimum Amount is requires'}</span>
                                  </div>
                                </div>

                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label className="col-form-label">Fee Type</label>
                                    <select className="form-control select" name="wd_fee_btype" disabled={access_level == 1 ? true : false} value={wd_fee_btype}
                                      onChange={(e) => setwd_fee_btype(e.target.value)} >
                                      <option value="1">Percent</option>
                                      <option value="2">Amount</option>
                                    </select>
                                    <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                  </div>
                                </div>

                                <div className="col-sm-3">

                                  <div className="form-group">
                                    <label className="col-form-label">Fee below to min. Amount</label>
                                    <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="wd_fee_below" defaultValue={billingdata.wd_fee_below} ref={register({ required: true })} />
                                    <span>{errors.wd_fee_below && 'Fee is requires'}</span>
                                  </div>
                                </div>

                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label className="col-form-label">Fee Type</label>
                                    <select className="form-control select" disabled={access_level == 1 ? true : false}
                                      onChange={(e) => setwd_fee_atype(e.target.value)} name="wd_fee_atype" value={wd_fee_atype}>
                                      <option value="1">Percent</option>
                                      <option value="2">Amount</option>
                                    </select>
                                    <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                  </div>
                                </div>

                                <div className="col-sm-3">
                                  <label className="col-form-label">Fee above min. Amount</label>
                                  <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="wd_fee_above" ref={register({ required: true })} defaultValue={billingdata.wd_fee_above} />
                                  <span>{errors.wd_fee_above && 'Fee is required'}</span>
                                </div>
                              </div>

                              <hr />
                              <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>Online Fees</h5>
                              <span>1) For Payment Link/ Invoice/ Quotes/ Tips/ Tickets/ Linky Meet/ Make a payment button/ Online ordering/ Bookings etc</span>

                              {
                                <>
                                  <div className="container" style={{ marginLeft: "-2px", marginTop: "16px", marginBottom: 16 }}>

                                    {
                                      <div>
                                        <label for="radio1" style={{ fontSize: "14px !important", fontWeight: "bold", display: "flex", }}><span style={{ marginRight: 10 }}>Pass Online Fees to Customer</span>
                                          <Switch
                                            onChange={(checked) => setpl_ipay_fee_payer(checked)}
                                            checked={pl_ipay_fee_payer}
                                            className="react-switch qr-page__switch"
                                            height={17}
                                            width={40}
                                          />
                                        </label>
                                      </div>
                                    }

                                    <label style={{ fontSize: "14px !important" }}>Set the online card fees you want merchant to be able to pass on to customers</label>
                                    {
                                      <div style={{ display: 'flex', flexDirection: 'row' }}>

                                        <div className="col-sm-3">
                                          <div class="input-group">
                                            <input className="form-control" onWheel={() => document.activeElement.blur()} step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="pl_fee_on_amount" defaultValue={billingdata.pl_fee_on_amount} />
                                            <div class="input-group-append">
                                              <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Percent</span>
                                            </div>
                                          </div>
                                          <span className="text-danger">{errors.pl_fee_on_amount && 'This field is requires'}</span>
                                        </div>


                                        <div className="col-sm-3">
                                          <div className="input-group">
                                            <input className="form-control" onWheel={() => document.activeElement.blur()} step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="pl_fixed_fee" defaultValue={billingdata.pl_fixed_fee} />
                                            <div class="input-group-append">
                                              <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Amount</span>
                                            </div>
                                          </div>
                                          <span className="text-danger">{errors.pl_fixed_fee && 'This field is requires'}</span>
                                        </div>

                                        <div className="col-sm-3">
                                          <div className="input-group">

                                            <div class="input-group-append">
                                              <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">(Domestic Card)</span>
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                    }

                                    {
                                      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>

                                        <div className="col-sm-3">
                                          <div class="input-group">
                                            <input className="form-control" onWheel={() => document.activeElement.blur()} step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="pl_fee_on_amount_international" defaultValue={billingdata.pl_fee_on_amount_international} />
                                            <div class="input-group-append">
                                              <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Percent</span>
                                            </div>
                                          </div>
                                          <span className="text-danger">{errors.pl_fee_on_amount_international && 'This field is requires'}</span>
                                        </div>


                                        <div className="col-sm-3">
                                          <div className="input-group">
                                            <input className="form-control" onWheel={() => document.activeElement.blur()} step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="pl_fixed_fee_international" defaultValue={billingdata.pl_fixed_fee_international} />
                                            <div class="input-group-append">
                                              <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Amount</span>
                                            </div>

                                          </div>
                                          <span className="text-danger">{errors.pl_fixed_fee_international && 'This field is requires'}</span>
                                        </div>

                                        <div className="col-sm-3">
                                          <div className="input-group">
                                            <div class="input-group-append">
                                              <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">(International Card)</span>
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                    }

                                  </div>

                                  <span>2) For Online orders</span>
                                  <div className="container" style={{ marginLeft: "-2px", marginTop: "16px" }}>


                                    {
                                      <div>
                                        <label for="radio1" style={{ fontSize: "14px !important", fontWeight: "bold", display: "flex", }}><span style={{ marginRight: 10 }}>Pass Online Fees to Customer</span> <Switch
                                          onChange={(checked) => setvenue_ipay_fee_payer(checked)}

                                          checked={venue_ipay_fee_payer}
                                          className="react-switch qr-page__switch"
                                          height={17}
                                          width={40}
                                        /></label>
                                      </div>
                                    }

                                    <div>

                                      <label for="radio1" style={{ fontSize: "14px !important" }}>The card fee will be passed on to your customers when they pay online with debit, credit, google pay or apple pay</label>
                                    </div>

                                    {
                                      <div className="row">

                                        <div className="col-sm-3">
                                          <div class="input-group">
                                            <input className="form-control" onWheel={() => document.activeElement.blur()} step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="venue_fee_on_amount" defaultValue={billingdata.venue_fee_on_amount} />
                                            <div class="input-group-append">
                                              <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Percent</span>
                                            </div>
                                          </div>
                                          <span className="text-danger">{errors.venue_fee_on_amount && 'This field is requires'}</span>
                                        </div>


                                        <div className="col-sm-3">
                                          <div className="input-group">
                                            <input className="form-control" onWheel={() => document.activeElement.blur()} step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="venue_fixed_fee" defaultValue={billingdata.venue_fixed_fee} />
                                            <div class="input-group-append">
                                              <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Amount</span>
                                            </div>
                                          </div>
                                          <span className="text-danger">{errors.venue_fixed_fee && 'This field is requires'}</span>
                                        </div>

                                        <div className="col-sm-3">
                                          <div className="input-group">

                                            <div class="input-group-append">
                                              <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">(Domestic Card)</span>
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                    }

                                    {
                                      <div className="row" style={{ marginTop: 20 }}>

                                        <div className="col-sm-3">
                                          <div class="input-group">
                                            <input className="form-control" onWheel={() => document.activeElement.blur()} step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="venue_fee_on_amount_international" defaultValue={billingdata.venue_fee_on_amount_international} />
                                            <div class="input-group-append">
                                              <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Percent</span>
                                            </div>
                                          </div>
                                          <span className="text-danger">{errors.venue_fee_on_amount_international && 'This field is requires'}</span>
                                        </div>


                                        <div className="col-sm-3">
                                          <div className="input-group">
                                            <input className="form-control" onWheel={() => document.activeElement.blur()} step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="venue_fixed_fee_international" defaultValue={billingdata.venue_fixed_fee_international} />
                                            <div class="input-group-append">
                                              <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Amount</span>
                                            </div>
                                          </div>
                                          <span className="text-danger">{errors.venue_fixed_fee_international && 'This field is requires'}</span>
                                        </div>

                                        <div className="col-sm-3">
                                          <div className="input-group">
                                            <div class="input-group-append">
                                              <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">(International Card)</span>
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                    }

                                  </div>
                                </>
                              }

                              {/* <hr /> */}

                              {/* <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>SMS Rates</h5> */}

                              {/* {
                                <>
                                  <div className="container" style={{ marginLeft: "-2px", marginTop: "16px", marginBottom: 16 }}>

                                    {
                                      <div style={{ display: 'flex', flexDirection: 'row' }}>

                                        <div className="col-sm-3">
                                          <div class="input-group">
                                            <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="sms_rates" defaultValue={billingdata.sms_rates} />
                                            
                                          </div>
                                          <span className="text-danger">{errors.sms_rates && 'This field is requires'}</span>
                                        </div>
                                      </div>
                                    }

                                  </div>
                                </>
                              } */}

                              <hr />

                              <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>Currency conversion fee</h5>
                              {/* <span>1) For Payment Link/ Invoice</span> */}

                              {
                                <>
                                  <div className="container" style={{ marginLeft: "-2px", marginTop: "16px", marginBottom: 16 }}>

                                    {/* <label style={{ fontSize: "14px !important" }}>Set the online card fees you want merchant to be able to pass on to customers</label> */}
                                    {
                                      <div style={{ display: 'flex', flexDirection: 'row' }}>

                                        <div className="col-sm-3">
                                          <div class="input-group">
                                            <input className="form-control" step="0.01" min="0" type="Number" max="100" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="currency_conversion_fee" defaultValue={billingdata.currency_conversion_fee} />
                                            <div class="input-group-append">
                                              <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Percent</span>
                                            </div>
                                          </div>
                                          <span className="text-danger">{errors.currency_conversion_fee && 'This field is requires'}</span>
                                        </div>
                                      </div>
                                    }

                                  </div>
                                </>
                              }

                              <hr />

                              
                              <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>Card Verification Charges</h5>
                              {/* <span>{JSON.stringify(custbillingdata)}</span> */}
                              <div className="container" style={{ marginLeft: "-2px", marginTop: "16px", marginBottom: 16 }}>
                                <div className="form-row">

                                  <div className="col-sm-3">
                                    <div className="form-group">
                                      <label className="col-form-label">Min</label>
                                      <input className="form-control" step="0.01" min="0" type="Number" onChange={(e) => setMin(e.target.value)} disabled={access_level == 1 ? true : false} name="min" defaultValue={min} ref={register({ required: true })} />
                                      <span>{errors.min && 'Minimum Amount is requires'}</span>
                                    </div>
                                  </div>

                                  <div className="col-sm-3">
                                    <div className="form-group">
                                      <label className="col-form-label">Max</label>
                                      <input className="form-control" step="0.01" min="0" type="Number" onChange={(e) => setMax(e.target.value)} disabled={access_level == 1 ? true : false} name="max" defaultValue={max} ref={register({ required: true })} />
                                      <span>{errors.max && 'Maximum Amount is requires'}</span>
                                    </div>
                                  </div>

                                </div>
                              </div>

                              <hr />
                              
                              <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>Top-up setting</h5>
                              {/* <span>{JSON.stringify(custbillingdata)}</span> */}

                              <div className="container" style={{ marginLeft: "-2px", marginTop: "16px", marginBottom: 16 }}>
                                <div className="form-row">

                                  <div className="col-sm-3">
                                    <div className="form-group">
                                      <label for="level_title">First time top-up</label>
                                      <input className="form-control" type="number" name="m_first_time_topup" defaultValue={countryDetails?.merchant_wallet?.min_first_topup} ref={register({ required: true })} />
                                      <span>{errors.m_first_time_topup && 'First Time Topup is required'}</span>
                                    </div>
                                  </div>

                                  <div className="col-sm-3">
                                    <div className="form-group">
                                      <label for="level_title">Minimum Top-up/Payment Amount</label>
                                      <input className="form-control" defaultValue={topupmethods?.merch_min_topup} type="number" name="m_minimum_topup" ref={register({ required: true })} />
                                      <span>{errors.m_minimum_topup && 'Minimum Topup is required'}</span>
                                    </div>
                                  </div>
                                  <div className="col-sm-3">
                                    <div className="form-group">
                                      <label for="level_title">Max i-pay account wallet limit</label>
                                      <input className="form-control" defaultValue={countryDetails?.merchant_wallet?.wallet_limit} type="number" name="m_max_account_wallet_limit" ref={register({ required: true })} />
                                      <span>{errors.m_max_account_wallet_limit && 'Max account wallet limit is required'}</span>
                                    </div>
                                  </div>

                                </div>
                              </div>

                              <hr />
                              
                              {access_level != 1 && <div className="form-group colg-lg-12">
                                {!loadingbtn ?
                                  <button className="btn btn-primary btn-xs pull-right" type="submit">SAVE</button>
                                  :
                                  <div className="loader-box pull-right" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 42, width: 42 }}></span></div>
                                }
                              </div>}
                            </form>
                            :
                            <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                              <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                                <DisappearedLoading size="medium" color="#514F4E" />
                              </div>
                            </div>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12 col-xl-12 xl-100" style={{ padding: "0" }}>
                      <div className="card">
                        <div className="card-body" style={{ padding: "1rem" }}>
                          {billingdatapaypal != null ?
                            <form className="needs-validation theme-form mt-3 billing-form" noValidate="" onSubmit={handleSubmit(onSubmit)}>

                              <input name="gateway" value="PAYPAL" type = "hidden" ref={register({ required: true })}/>
                              {/* <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>iPayPro Service Fee/ C2B "Customer To Business"</h5>
                              <small>When customer pay to a business using iPayPro eWallet</small>
                              <div className="form-row">

                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label className="col-form-label">Minimum Amount</label>
                                    <input className="form-control" step="0.01" min="0" disabled={access_level == 1 ? true : false} type="Number" name="c_to_m_sc_comp_amount" defaultValue={billingdatapaypal.c_to_m_sc_comp_amount} ref={register({ required: true })} />
                                    <span>{errors.c_to_m_sc_comp_amount && 'Minimum Amount is requires'}</span>
                                  </div>
                                </div>

                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label className="col-form-label">Fee Type</label>
                                    <select className="form-control select" name="c_to_m_sc_fee_btype" disabled={access_level == 1 ? true : false} value={c_to_m_sc_fee_btype}
                                      onChange={(e) => setc_to_m_sc_fee_btype(e.target.value)}>
                                      <option value="1">Percent</option>
                                      <option value="2">Amount</option>
                                    </select>
                                    <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                  </div>
                                </div>
                                <div className="col-sm-3">
                                  <div className="form-group">
                                    <label className="col-form-label">Fee below min. Amount</label>
                                    <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="c_to_m_sc_fee_below" defaultValue={billingdatapaypal.c_to_m_sc_fee_below} ref={register({ required: true })} />
                                    <span>{errors.c_to_m_sc_fee_below && 'Fee is requires'}</span>
                                  </div>
                                </div>

                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label className="col-form-label">Fee Type</label>
                                    <select className="form-control select" name="c_to_m_sc_fee_atype" disabled={access_level == 1 ? true : false} value={c_to_m_sc_fee_atype}
                                      onChange={(e) => setc_to_m_sc_fee_atype(e.target.value)}>
                                      <option value="1">Percent</option>
                                      <option value="2">Amount</option>
                                    </select>

                                    <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                  </div>
                                </div>
                                <div className="col-sm-3">
                                  <div className="form-group">
                                    <label className="col-form-label">Fee above min. Amount</label>
                                    <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="c_to_m_sc_fee_above" ref={register({ required: true })} defaultValue={billingdatapaypal.c_to_m_sc_fee_above} />
                                    <span>{errors.c_to_m_sc_fee_above && 'Fee is required'}</span>
                                  </div>
                                </div>
                              </div>

                              <hr /> */}

                              

                              <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>Linky Service Fee</h5>
                              <small>For Payment Link/ Invoice/ Quotes/ Tips/ Tickets/ Linky Meet/ Make a payment button/ Online ordering/ Bookings etc</small>
                              <div className="form-row">


                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label className="col-form-label">Minimum Amount</label>
                                    <input className="form-control" step="0.01" min="0" disabled={access_level == 1 ? true : false} type="Number" name="m_to_m_sc_comp_amount" defaultValue={billingdatapaypal.m_to_m_sc_comp_amount} ref={register({ required: true })} />
                                    <span>{errors.m_to_m_sc_comp_amount && 'Minimum Amount is requires'}</span>
                                  </div>
                                </div>

                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label className="col-form-label">Fee Type</label>
                                    <select className="form-control select" name="m_to_m_sc_fee_btype" disabled={access_level == 1 ? true : false} value={m_to_m_sc_fee_btype}
                                      onChange={(e) => setm_to_m_sc_fee_btype(e.target.value)}>
                                      <option value="1">Percent</option>
                                      <option value="2">Amount</option>
                                    </select>

                                    <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                  </div>
                                </div>
                                <div className="col-sm-3">
                                  <div className="form-group">
                                    <label className="col-form-label">Fee below min. Amount</label>
                                    <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="m_to_m_sc_fee_below" defaultValue={billingdatapaypal.m_to_m_sc_fee_below} ref={register({ required: true })} />
                                    <span>{errors.m_to_m_sc_fee_below && 'Fee is requires'}</span>
                                  </div>
                                </div>

                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label className="col-form-label">Fee Type</label>
                                    <select className="form-control select" name="m_to_m_sc_fee_atype" disabled={access_level == 1 ? true : false} value={m_to_m_sc_fee_atype}
                                      onChange={(e) => setm_to_m_sc_fee_atype(e.target.value)}>
                                      <option value="1">Percent</option>
                                      <option value="2">Amount</option>
                                    </select>

                                    <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                  </div>
                                </div>
                                <div className="col-sm-3">
                                  <div className="form-group">
                                    <label className="col-form-label">Fee above min. Amount</label>
                                    <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="m_to_m_sc_fee_above" ref={register({ required: true })} defaultValue={billingdatapaypal.m_to_m_sc_fee_above} />
                                    <span>{errors.m_to_m_sc_fee_above && 'Fee is required'}</span>
                                  </div>
                                </div>
                              </div>

                              <hr />

                              {/* <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>iPayPro Service Fee/B2C "Business to Customer"</h5>
                              <small>When business pay to a customer using iPayPro eWallet</small>
                              <div className="form-row">


                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label className="col-form-label">Minimum Amount</label>
                                    <input className="form-control" step="0.01" min="0" disabled={access_level == 1 ? true : false} type="Number" name="m_to_c_sc_comp_amount" defaultValue={billingdatapaypal.m_to_c_sc_comp_amount} ref={register({ required: true })} />
                                    <span>{errors.m_to_c_sc_comp_amount && 'Minimum Amount is requires'}</span>
                                  </div>
                                </div>

                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label className="col-form-label">Fee Type</label>
                                    <select className="form-control select" name="m_to_c_sc_fee_btype" disabled={access_level == 1 ? true : false} value={m_to_c_sc_fee_btype}
                                      onChange={(e) => setm_to_c_sc_fee_btype(e.target.value)}>
                                      <option value="1">Percent</option>
                                      <option value="2">Amount</option>
                                    </select>

                                    <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                  </div>
                                </div>
                                <div className="col-sm-3">
                                  <div className="form-group">
                                    <label className="col-form-label">Fee below min. Amount</label>
                                    <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="m_to_c_sc_fee_below" defaultValue={billingdatapaypal.m_to_c_sc_fee_below} ref={register({ required: true })} />
                                    <span>{errors.m_to_c_sc_fee_below && 'Fee is requires'}</span>
                                  </div>
                                </div>

                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label className="col-form-label">Fee Type</label>
                                    <select className="form-control select" name="m_to_c_sc_fee_atype" disabled={access_level == 1 ? true : false} value={m_to_c_sc_fee_atype}
                                      onChange={(e) => setm_to_c_sc_fee_atype(e.target.value)}>
                                      <option value="1">Percent</option>
                                      <option value="2">Amount</option>
                                    </select>

                                    <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                  </div>
                                </div>
                                <div className="col-sm-3">
                                  <div className="form-group">
                                    <label className="col-form-label">Fee above min. Amount</label>
                                    <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="m_to_c_sc_fee_above" ref={register({ required: true })} defaultValue={billingdatapaypal.m_to_c_sc_fee_above} />
                                    <span>{errors.m_to_c_sc_fee_above && 'Fee is required'}</span>
                                  </div>
                                </div>
                              </div> */}

                              

                              <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>Withdrawal Fee</h5>
                              <small>When user send request to withdraw their eWallet funds to their bank account</small>
                              <div className="form-row">

                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label className="col-form-label">Minimum Amount</label>
                                    <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="wd_comp_amount" defaultValue={billingdatapaypal.wd_comp_amount} ref={register({ required: true })} />
                                    <span>{errors.wd_comp_amount && 'Minimum Amount is requires'}</span>
                                  </div>
                                </div>

                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label className="col-form-label">Fee Type</label>
                                    <select className="form-control select" name="wd_fee_btype" disabled={access_level == 1 ? true : false} value={wd_fee_btype}
                                      onChange={(e) => setwd_fee_btype(e.target.value)} >
                                      <option value="1">Percent</option>
                                      <option value="2">Amount</option>
                                    </select>
                                    <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                  </div>
                                </div>

                                <div className="col-sm-3">

                                  <div className="form-group">
                                    <label className="col-form-label">Fee below to min. Amount</label>
                                    <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="wd_fee_below" defaultValue={billingdatapaypal.wd_fee_below} ref={register({ required: true })} />
                                    <span>{errors.wd_fee_below && 'Fee is requires'}</span>
                                  </div>
                                </div>

                                <div className="col-sm-2">
                                  <div className="form-group">
                                    <label className="col-form-label">Fee Type</label>
                                    <select className="form-control select" disabled={access_level == 1 ? true : false}
                                      onChange={(e) => setwd_fee_atype(e.target.value)} name="wd_fee_atype" value={wd_fee_atype}>
                                      <option value="1">Percent</option>
                                      <option value="2">Amount</option>
                                    </select>
                                    <span>{errors.amcb_nu_type && 'Select Fee type'}</span>

                                  </div>
                                </div>

                                <div className="col-sm-3">
                                  <label className="col-form-label">Fee above min. Amount</label>
                                  <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} name="wd_fee_above" ref={register({ required: true })} defaultValue={billingdatapaypal.wd_fee_above} />
                                  <span>{errors.wd_fee_above && 'Fee is required'}</span>
                                </div>
                              </div>

                              <hr />
                              <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>Online Fees</h5>
                              <span>1) For Payment Link/ Invoice/ Quotes/ Tips/ Tickets/ Linky Meet/ Make a payment button/ Online ordering/ Bookings etc</span>

                              {
                                <>
                                  <div className="container" style={{ marginLeft: "-2px", marginTop: "16px", marginBottom: 16 }}>

                                    {
                                      <div>
                                        <label for="radio1" style={{ fontSize: "14px !important", fontWeight: "bold", display: "flex", }}><span style={{ marginRight: 10 }}>Pass Online Fees to Customer</span>
                                          <Switch
                                            onChange={(checked) => setpl_ipay_fee_payer(checked)}
                                            checked={pl_ipay_fee_payer}
                                            className="react-switch qr-page__switch"
                                            height={17}
                                            width={40}
                                          />
                                        </label>
                                      </div>
                                    }

                                    <label style={{ fontSize: "14px !important" }}>Set the online card fees you want merchant to be able to pass on to customers</label>
                                    {
                                      <div style={{ display: 'flex', flexDirection: 'row' }}>

                                        <div className="col-sm-3">
                                          <div class="input-group">
                                            <input className="form-control" onWheel={() => document.activeElement.blur()} step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="pl_fee_on_amount" defaultValue={billingdatapaypal.pl_fee_on_amount} />
                                            <div class="input-group-append">
                                              <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Percent</span>
                                            </div>
                                          </div>
                                          <span className="text-danger">{errors.pl_fee_on_amount && 'This field is requires'}</span>
                                        </div>


                                        <div className="col-sm-3">
                                          <div className="input-group">
                                            <input className="form-control" onWheel={() => document.activeElement.blur()} step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="pl_fixed_fee" defaultValue={billingdatapaypal.pl_fixed_fee} />
                                            <div class="input-group-append">
                                              <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Amount</span>
                                            </div>
                                          </div>
                                          <span className="text-danger">{errors.pl_fixed_fee && 'This field is requires'}</span>
                                        </div>

                                        <div className="col-sm-3">
                                          <div className="input-group">

                                            <div class="input-group-append">
                                              <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">(Domestic Card)</span>
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                    }

                                    {
                                      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>

                                        <div className="col-sm-3">
                                          <div class="input-group">
                                            <input className="form-control" onWheel={() => document.activeElement.blur()} step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="pl_fee_on_amount_international" defaultValue={billingdatapaypal.pl_fee_on_amount_international} />
                                            <div class="input-group-append">
                                              <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Percent</span>
                                            </div>
                                          </div>
                                          <span className="text-danger">{errors.pl_fee_on_amount_international && 'This field is requires'}</span>
                                        </div>


                                        <div className="col-sm-3">
                                          <div className="input-group">
                                            <input className="form-control" onWheel={() => document.activeElement.blur()} step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="pl_fixed_fee_international" defaultValue={billingdatapaypal.pl_fixed_fee_international} />
                                            <div class="input-group-append">
                                              <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Amount</span>
                                            </div>

                                          </div>
                                          <span className="text-danger">{errors.pl_fixed_fee_international && 'This field is requires'}</span>
                                        </div>

                                        <div className="col-sm-3">
                                          <div className="input-group">
                                            <div class="input-group-append">
                                              <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">(International Card)</span>
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                    }

                                  </div>

                                  <span>2) For Online orders</span>
                                  <div className="container" style={{ marginLeft: "-2px", marginTop: "16px" }}>


                                    {
                                      <div>
                                        <label for="radio1" style={{ fontSize: "14px !important", fontWeight: "bold", display: "flex", }}><span style={{ marginRight: 10 }}>Pass Online Fees to Customer</span> <Switch
                                          onChange={(checked) => setvenue_ipay_fee_payer(checked)}

                                          checked={venue_ipay_fee_payer}
                                          className="react-switch qr-page__switch"
                                          height={17}
                                          width={40}
                                        /></label>
                                      </div>
                                    }

                                    <div>

                                      <label for="radio1" style={{ fontSize: "14px !important" }}>The card fee will be passed on to your customers when they pay online with debit, credit, google pay or apple pay</label>
                                    </div>

                                    {
                                      <div className="row">

                                        <div className="col-sm-3">
                                          <div class="input-group">
                                            <input className="form-control" onWheel={() => document.activeElement.blur()} step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="venue_fee_on_amount" defaultValue={billingdatapaypal.venue_fee_on_amount} />
                                            <div class="input-group-append">
                                              <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Percent</span>
                                            </div>
                                          </div>
                                          <span className="text-danger">{errors.venue_fee_on_amount && 'This field is requires'}</span>
                                        </div>


                                        <div className="col-sm-3">
                                          <div className="input-group">
                                            <input className="form-control" onWheel={() => document.activeElement.blur()} step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="venue_fixed_fee" defaultValue={billingdatapaypal.venue_fixed_fee} />
                                            <div class="input-group-append">
                                              <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Amount</span>
                                            </div>
                                          </div>
                                          <span className="text-danger">{errors.venue_fixed_fee && 'This field is requires'}</span>
                                        </div>

                                        <div className="col-sm-3">
                                          <div className="input-group">

                                            <div class="input-group-append">
                                              <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">(Domestic Card)</span>
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                    }

                                    {
                                      <div className="row" style={{ marginTop: 20 }}>

                                        <div className="col-sm-3">
                                          <div class="input-group">
                                            <input className="form-control" onWheel={() => document.activeElement.blur()} step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="venue_fee_on_amount_international" defaultValue={billingdatapaypal.venue_fee_on_amount_international} />
                                            <div class="input-group-append">
                                              <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Percent</span>
                                            </div>
                                          </div>
                                          <span className="text-danger">{errors.venue_fee_on_amount_international && 'This field is requires'}</span>
                                        </div>


                                        <div className="col-sm-3">
                                          <div className="input-group">
                                            <input className="form-control" onWheel={() => document.activeElement.blur()} step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="venue_fixed_fee_international" defaultValue={billingdatapaypal.venue_fixed_fee_international} />
                                            <div class="input-group-append">
                                              <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Amount</span>
                                            </div>
                                          </div>
                                          <span className="text-danger">{errors.venue_fixed_fee_international && 'This field is requires'}</span>
                                        </div>

                                        <div className="col-sm-3">
                                          <div className="input-group">
                                            <div class="input-group-append">
                                              <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">(International Card)</span>
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                    }

                                  </div>
                                </>
                              }

                              {/* <hr /> */}

                              {/* <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>SMS Rates</h5> */}

                              {/* {
                                <>
                                  <div className="container" style={{ marginLeft: "-2px", marginTop: "16px", marginBottom: 16 }}>

                                    {
                                      <div style={{ display: 'flex', flexDirection: 'row' }}>

                                        <div className="col-sm-3">
                                          <div class="input-group">
                                            <input className="form-control" step="0.01" min="0" type="Number" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="sms_rates" defaultValue={billingdatapaypal.sms_rates} />
                                            
                                          </div>
                                          <span className="text-danger">{errors.sms_rates && 'This field is requires'}</span>
                                        </div>
                                      </div>
                                    }

                                  </div>
                                </>
                              } */}

                              <hr />

                              <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>Currency conversion fee</h5>
                              {/* <span>1) For Payment Link/ Invoice</span> */}

                              {
                                <>
                                  <div className="container" style={{ marginLeft: "-2px", marginTop: "16px", marginBottom: 16 }}>

                                    {/* <label style={{ fontSize: "14px !important" }}>Set the online card fees you want merchant to be able to pass on to customers</label> */}
                                    {
                                      <div style={{ display: 'flex', flexDirection: 'row' }}>

                                        <div className="col-sm-3">
                                          <div class="input-group">
                                            <input className="form-control" step="0.01" min="0" type="Number" max="100" disabled={access_level == 1 ? true : false} ref={register({ required: true })} name="currency_conversion_fee" defaultValue={billingdatapaypal.currency_conversion_fee} />
                                            <div class="input-group-append">
                                              <span style={{ background: "unset", border: "unset", fontSize: 12 }} class="input-group-text" id="inputGroup-sizing-default">Percent</span>
                                            </div>
                                          </div>
                                          <span className="text-danger">{errors.currency_conversion_fee && 'This field is requires'}</span>
                                        </div>
                                      </div>
                                    }

                                  </div>
                                </>
                              }

                              <hr />

                              
                              <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>Card Verification Charges</h5>
                              {/* <span>{JSON.stringify(custbillingdatapaypal)}</span> */}
                              <div className="container" style={{ marginLeft: "-2px", marginTop: "16px", marginBottom: 16 }}>
                                <div className="form-row">

                                  <div className="col-sm-3">
                                    <div className="form-group">
                                      <label className="col-form-label">Min</label>
                                      <input className="form-control" step="0.01" min="0" type="Number" onChange={(e) => setMin(e.target.value)} disabled={access_level == 1 ? true : false} name="min" defaultValue={min} ref={register({ required: true })} />
                                      <span>{errors.min && 'Minimum Amount is requires'}</span>
                                    </div>
                                  </div>

                                  <div className="col-sm-3">
                                    <div className="form-group">
                                      <label className="col-form-label">Max</label>
                                      <input className="form-control" step="0.01" min="0" type="Number" onChange={(e) => setMax(e.target.value)} disabled={access_level == 1 ? true : false} name="max" defaultValue={max} ref={register({ required: true })} />
                                      <span>{errors.max && 'Maximum Amount is requires'}</span>
                                    </div>
                                  </div>

                                </div>
                              </div>

                              <hr />
                              
                              <h5 style={{ fontSize: "1rem", marginBottom: 0 }}>Top-up setting</h5>
                              {/* <span>{JSON.stringify(custbillingdatapaypal)}</span> */}

                              <div className="container" style={{ marginLeft: "-2px", marginTop: "16px", marginBottom: 16 }}>
                                <div className="form-row">

                                  <div className="col-sm-3">
                                    <div className="form-group">
                                      <label for="level_title">First time top-up</label>
                                      <input className="form-control" type="number" name="m_first_time_topup" defaultValue={countryDetails?.merchant_wallet?.min_first_topup} ref={register({ required: true })} />
                                      <span>{errors.m_first_time_topup && 'First Time Topup is required'}</span>
                                    </div>
                                  </div>

                                  <div className="col-sm-3">
                                    <div className="form-group">
                                      <label for="level_title">Minimum Top-up/Payment Amount</label>
                                      <input className="form-control" defaultValue={topupmethods?.merch_min_topup} type="number" name="m_minimum_topup" ref={register({ required: true })} />
                                      <span>{errors.m_minimum_topup && 'Minimum Topup is required'}</span>
                                    </div>
                                  </div>
                                  <div className="col-sm-3">
                                    <div className="form-group">
                                      <label for="level_title">Max i-pay account wallet limit</label>
                                      <input className="form-control" defaultValue={countryDetails?.merchant_wallet?.wallet_limit} type="number" name="m_max_account_wallet_limit" ref={register({ required: true })} />
                                      <span>{errors.m_max_account_wallet_limit && 'Max account wallet limit is required'}</span>
                                    </div>
                                  </div>

                                </div>
                              </div>

                              <hr />
                              
                              {access_level != 1 && <div className="form-group colg-lg-12">
                                {!loadingbtn ?
                                  <button className="btn btn-primary btn-xs pull-right" type="submit">SAVE</button>
                                  :
                                  <div className="loader-box pull-right" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 42, width: 42 }}></span></div>
                                }
                              </div>}
                            </form>
                            :
                            <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                              <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                                <DisappearedLoading size="medium" color="#514F4E" />
                              </div>
                            </div>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
              <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12 col-xl-12 xl-100" style={{ padding: "0" }}>
                      <div className="card">
                      <div className="card-body" style={{ padding: "1rem" }}>
                      {billingdata !== null ?
                      <form className="needs-validation theme-form mt-3 billing-form" noValidate="" onSubmit={handleSubmit(onSubmit)}>

<input name="gateway" value="STRIPE" type = "hidden" ref={register({ required: true })}/>

<h5 style={{ fontSize: "1rem", marginBottom: 0 }}>Function Booking Feature</h5>

<div className="form-row  p-2" style={{gap:'12px'}}>


<div className="">
  <div className="form-group">
    <label className="col-form-label">Minimum Spent (in %)</label>
    <input className="form-control" step="0.01" min="0" disabled={access_level == 1 ? true : false} type="Number" name="m_to_m_func_booking_spent" 
    defaultValue={billingdata?.m_to_m_func_booking_spent}
     ref={register({ required: true })} />
    <span>{errors.m_to_m_func_booking_spent && 'Minimum Amount is requires'}</span>
  </div>
</div>
<div className="">
  <div className="form-group">
    <label className="col-form-label">Venue hire deposit fees (in %)</label>
    <input className="form-control" step="0.01" min="0" disabled={access_level == 1 ? true : false} type="Number" name="m_to_m_func_venue_fees" 
    defaultValue={billingdata?.m_to_m_func_venue_fees}

     ref={register({ required: true })} />
    <span>{errors.m_to_m_func_veneu_fees && 'Minimum Amount is requires'}</span>
  </div>
</div>
</div>

<hr />
                                  {access_level != 1 ? <div className="form-group">
                                    {!loadingbtn ?
                                      <button className="btn btn-primary" type="submit">SAVE</button>
                                      :
                                      <div className="loader-box" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 42, width: 42 }}></span></div>
                                    }
                                  </div> : null}


</form>:

 
<div className="loader-box" style={{ width: "100wh" }}>
<div className="loader" style={{ display: "flex", alignItems: "center" }}>
  <DisappearedLoading size="medium" color="#514F4E" />
</div>
</div>
}

                        </div>
                        
                         </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                {/* {custbillingdata && */}
                  <PaymentGateway />
                {/* } */}
              </TabPanel>
              {/* <TabPanel>
                <OtherCharges custbillingdata={custbillingdata} getcashback_settings={getcashback_settings} />
              </TabPanel> */}
              <TabPanel>
                <Sms_rates sms_rate_data={sms_rate_data} setsmsRateData={setSmsRateData} />
              </TabPanel>
              <TabPanel>
                <Invoices />
              </TabPanel>
            </div>
            {/* : <div className="loader-box" >
                                    <div className="loader" style={{ margin: "auto auto" }}>
                                        <div className="line bg-primary"></div>
                                        <div className="line bg-danger"></div>
                                        <div className="line bg-primary"></div>
                                    </div>
                                </div>} */}
          </Tabs>
        </div>
      </div>
    </Fragment>
  );
  // }
}

export default BorderTable;
