import React, { Fragment, useEffect, useState } from 'react';
import moment from "moment";


const UserLoginHistory = ({ loginHistory }) => {

    const [tableData, setTableData] = useState([]);

    return (
        <Fragment className='p-3'>
            <div className='main_div p-3'>
                <div className="row mb-3">
                    <div className="col-12">
                        <h6 style={{ fontWeight: "600", fontSize: "18px" }}>Login Histoy</h6>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table table-border-horizontal table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">User</th>
                                <th scope="col">Email</th>
                                <th scope="col">IP Address</th>
                                <th scope="col">Login Time</th>
                                <th scope="col">Location</th>
                                <th scope="col">Device Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loginHistory.length > 0 ?
                                    loginHistory.map((val, ind) => {
                                        return (
                                            <>
                                                <tr key={ind + 1}>
                                                    <th scope=" row">{ind + 1}</th>
                                                    <td scope=" row"> {val.userName}</td>
                                                    <td scope=" row"> {val.email}</td>
                                                    <td scope=" row"> {val.login_location && val.login_location.IPv4}</td>
                                                    <td scope=" row"> {moment(val.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                                                    <td scope=" row"> {`${val.login_location ? val.login_location.city + ',' + val.login_location.state + ' / ' + val.login_location.country_name : ""}`}</td>
                                                    <td scope=" row"> {`${val.login_location ? val.login_location.browserName + ',' + val.login_location.osName + ' / ' + val.login_location.IPv4 : ""}`}</td>

                                                </tr>
                                            </>
                                        )
                                    }) : <div>No record found</div>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </Fragment>
    )
}

export default UserLoginHistory;