
const CatSubcat = [
    {
       "categoryId":1,
       "categoryTitle":"Automobiles and Vehicles",
       "subCategory":[
          {
             "subcatId":1,
             "subcatTitle":"Authorized Service Centre"
          },
          {
             "subcatId":2,
             "subcatTitle":"Automobile Parts and Accessories"
          },
          {
             "subcatId":3,
             "subcatTitle":"Authorized Service Centre"
          },
          {
             "subcatId":4,
             "subcatTitle":"Car and Bike Dealership"
          },
          {
             "subcatId":5,
             "subcatTitle":"Puncture Shop"
          },
          {
             "subcatId":6,
             "subcatTitle":"Road Side Assitance"
          }
       ]
    },
    {
       "categoryId":2,
       "categoryTitle":"Beauty and Wellness",
       "subCategory":[
          {
             "subcatId":7,
             "subcatTitle":"Dietician"
          },
          {
             "subcatId":7,
             "subcatTitle":"Gym and Fitness"
          },
          {
             "subcatId":7,
             "subcatTitle":"Salon and Parlour"
          },
          {
             "subcatId":7,
             "subcatTitle":"Spa"
          }
       ]
    },
    {
       "categoryId":1,
       "categoryTitle":"BFSI",
       "subCategory":[
          {
             "subcatId":1,
             "subcatTitle":"Credit Card"
          },
          {
             "subcatId":1,
             "subcatTitle":"Insurance"
          },
          {
             "subcatId":1,
             "subcatTitle":"Loans"
          },
          {
             "subcatId":1,
             "subcatTitle":"Mutual Fund"
          }
       ]
    },
    {
       "categoryId":1,
       "categoryTitle":"Business Services",
       "subCategory":[
          {
             "subcatId":1,
             "subcatTitle":"Accounting Services"
          },
          {
             "subcatId":1,
             "subcatTitle":"Legal Services"
          },
          {
             "subcatId":1,
             "subcatTitle":"Office Space on rent"
          },
          {
             "subcatId":1,
             "subcatTitle":"Office Utitlities"
          },
          {
             "subcatId":1,
             "subcatTitle":"Real Estate"
          },
          {
             "subcatId":1,
             "subcatTitle":"Tax Filing"
          }
       ]
    },
    {
       "categoryId":1,
       "categoryTitle":"Cable and Broadband",
       "subCategory":[
          {
             "subcatId":1,
             "subcatTitle":"Broadband"
          },
          {
             "subcatId":1,
             "subcatTitle":"Vable Player"
          },
          {
             "subcatId":1,
             "subcatTitle":"D2H"
          },
          {
             "subcatId":1,
             "subcatTitle":"Local Cable Operator"
          }
       ]
    },
    {
       "categoryId":1,
       "categoryTitle":"Corporate Alliance",
       "subCategory":[
          {
             "subcatId":1,
             "subcatTitle":"Automobiles"
          },
          {
             "subcatId":1,
             "subcatTitle":"BFSI"
          },
          {
             "subcatId":1,
             "subcatTitle":"Construction"
          },
          {
             "subcatId":1,
             "subcatTitle":"Consulting"
          },
          {
             "subcatId":1,
             "subcatTitle":"Consumer Durables"
          },
          {
             "subcatId":1,
             "subcatTitle":"Facility management"
          },
          {
             "subcatId":1,
             "subcatTitle":"FMCG"
          },
          {
             "subcatId":1,
             "subcatTitle":"IT and ITES"
          },
          {
             "subcatId":1,
             "subcatTitle":"Man power"
          },
          {
             "subcatId":1,
             "subcatTitle":"Market Research"
          },
          {
             "subcatId":1,
             "subcatTitle":"Security"
          }
       ]
    },
    {
       "categoryId":1,
       "categoryTitle":"Education",
       "subCategory":[
          {
             "subcatId":1,
             "subcatTitle":"Coaching Institute"
          },
          {
             "subcatId":1,
             "subcatTitle":"Government Higher Institute"
          },
          {
             "subcatId":1,
             "subcatTitle":"Pre School"
          },
          {
             "subcatId":1,
             "subcatTitle":"Private College"
          },
          {
             "subcatId":1,
             "subcatTitle":"School"
          },
          {
             "subcatId":1,
             "subcatTitle":"University"
          }
       ]
    },
    {
       "categoryId":1,
       "categoryTitle":"Entertainment",
       "subCategory":[
          {
             "subcatId":1,
             "subcatTitle":"Events"
          },
          {
             "subcatId":1,
             "subcatTitle":"Game Parlour"
          },
          {
             "subcatId":1,
             "subcatTitle":"Multiplex"
          },
          {
             "subcatId":1,
             "subcatTitle":"Sports Events"
          },
          {
             "subcatId":1,
             "subcatTitle":"Theme Park"
          }
       ]
    },
    {
       "categoryId":1,
       "categoryTitle":"Event Services",
       "subCategory":[
          {
             "subcatId":1,
             "subcatTitle":"Art and Craft Stall"
          },
          {
             "subcatId":1,
             "subcatTitle":"Caterer"
          },
          {
             "subcatId":1,
             "subcatTitle":"Choreographer"
          },
          {
             "subcatId":1,
             "subcatTitle":"Circus"
          },
          {
             "subcatId":1,
             "subcatTitle":"Dairy Stall"
          },
          {
             "subcatId":1,
             "subcatTitle":"DJ"
          },
          {
             "subcatId":1,
             "subcatTitle":"Flower Stall"
          },
          {
             "subcatId":1,
             "subcatTitle":"Food Stall"
          },
          {
             "subcatId":1,
             "subcatTitle":"Gaming Stall"
          },
          {
             "subcatId":1,
             "subcatTitle":"Garment Stall"
          },
          {
             "subcatId":1,
             "subcatTitle":"Household Stall"
          },
          {
             "subcatId":1,
             "subcatTitle":"Jewelry Stall"
          },
          {
             "subcatId":1,
             "subcatTitle":"Kitchen Applicances Stall"
          },
          {
             "subcatId":1,
             "subcatTitle":"Party Planner"
          },
          {
             "subcatId":1,
             "subcatTitle":"Tent and Decorator"
          },
          {
             "subcatId":1,
             "subcatTitle":"Toy Stall"
          },
          {
             "subcatId":1,
             "subcatTitle":"Wedding Band"
          },
          {
             "subcatId":1,
             "subcatTitle":"Wedding Photographer"
          }
       ]
    },
    {
       "categoryId":1,
       "categoryTitle":"Hospitality",
       "subCategory":[
          {
             "subcatId":1,
             "subcatTitle":"Bakery"
          },
          {
             "subcatId":1,
             "subcatTitle":"Campus Cafeteria"
          },
          {
             "subcatId":1,
             "subcatTitle":"Coffee and Tea Shop"
          },
          {
             "subcatId":1,
             "subcatTitle":"Corporate Cafeteria"
          },
          {
             "subcatId":1,
             "subcatTitle":"Fast Food and QSR"
          },
          {
             "subcatId":1,
             "subcatTitle":"Food Truck"
          },
          {
             "subcatId":1,
             "subcatTitle":"Foodcourt"
          },
          {
             "subcatId":1,
             "subcatTitle":"Juice and Snacks"
          },
          {
             "subcatId":1,
             "subcatTitle":"Restaurant"
          },
          {
             "subcatId":1,
             "subcatTitle":"Restaurant and Bars"
          },
          {
             "subcatId":1,
             "subcatTitle":"Pub"
          },
          {
             "subcatId":1,
             "subcatTitle":"Sweet Shop"
          },
          {
             "subcatId":1,
             "subcatTitle":"Vending Machine"
          }
       ]
    },
    {
       "categoryId":1,
       "categoryTitle":"Government",
       "subCategory":[
          {
             "subcatId":1,
             "subcatTitle":"Central Department"
          },
          {
             "subcatId":1,
             "subcatTitle":"State Department"
          }
       ]
    },
    {
       "categoryId":1,
       "categoryTitle":"Healthcare",
       "subCategory":[
          {
             "subcatId":1,
             "subcatTitle":"Doctors and Clinic"
          },
          {
             "subcatId":1,
             "subcatTitle":"Homeopathic Clinic"
          },
          {
             "subcatId":1,
             "subcatTitle":"Hospital"
          },
          {
             "subcatId":1,
             "subcatTitle":"Pathology Lab"
          },
          {
             "subcatId":1,
             "subcatTitle":"Pharmacy"
          },
          {
             "subcatId":1,
             "subcatTitle":"Physiotherapy Clinic"
          }
       ]
    },
    {
       "categoryId":1,
       "categoryTitle":"Home Services",
       "subCategory":[
          {
             "subcatId":1,
             "subcatTitle":"Cleaning Services"
          },
          {
             "subcatId":1,
             "subcatTitle":"Driver Services"
          },
          {
             "subcatId":1,
             "subcatTitle":"Electronics and Home Appliances Repair"
          },
          {
             "subcatId":1,
             "subcatTitle":"Interior Designing and Architect"
          },
          {
             "subcatId":1,
             "subcatTitle":"Movers and Packers"
          },
          {
             "subcatId":1,
             "subcatTitle":"Other Services"
          },
          {
             "subcatId":1,
             "subcatTitle":"Pest and Termite Control"
          },
          {
             "subcatId":1,
             "subcatTitle":"Plumber Carpenter and Electrician"
          },
          {
             "subcatId":1,
             "subcatTitle":"Rental Services"
          },
          {
             "subcatId":1,
             "subcatTitle":"Repair Services"
          }
       ]
    },
    {
       "categoryId":1,
       "categoryTitle":"Housing",
       "subCategory":[
          {
             "subcatId":1,
             "subcatTitle":"Aggregator"
          },
          {
             "subcatId":1,
             "subcatTitle":"Developer"
          },
          {
             "subcatId":1,
             "subcatTitle":"Facility Management Company"
          },
          {
             "subcatId":1,
             "subcatTitle":"RWA"
          }
       ]
    },
    {
       "categoryId":1,
       "categoryTitle":"Individual Services",
       "subCategory":[
          {
             "subcatId":1,
             "subcatTitle":"Automobile Repairs"
          },
          {
             "subcatId":1,
             "subcatTitle":"Bank and Insurance Agent"
          },
          {
             "subcatId":1,
             "subcatTitle":"Carpenter"
          },
          {
             "subcatId":1,
             "subcatTitle":"Cleaning Services"
          },
          {
             "subcatId":1,
             "subcatTitle":"Cook"
          },
          {
             "subcatId":1,
             "subcatTitle":"Doctor"
          },
          {
             "subcatId":1,
             "subcatTitle":"Driver Services"
          },
          {
             "subcatId":1,
             "subcatTitle":"Electrician"
          },
          {
             "subcatId":1,
             "subcatTitle":"Electronics and Home Appliances Repair"
          },
          {
             "subcatId":1,
             "subcatTitle":"Events Manager"
          },
          {
             "subcatId":1,
             "subcatTitle":"Furniture Repair"
          },
          {
             "subcatId":1,
             "subcatTitle":"Home Beauticians"
          },
          {
             "subcatId":1,
             "subcatTitle":"Home Delivery"
          },
          {
             "subcatId":1,
             "subcatTitle":"Interior Designing and Architect"
          },
          {
             "subcatId":1,
             "subcatTitle":"Maids"
          },
          {
             "subcatId":1,
             "subcatTitle":"Movers and Packers"
          },
          {
             "subcatId":1,
             "subcatTitle":"Newspaper Hawker"
          },
          {
             "subcatId":1,
             "subcatTitle":"Other Services"
          },
          {
             "subcatId":1,
             "subcatTitle":"Painter"
          },
          {
             "subcatId":1,
             "subcatTitle":"Pest and Termite Control"
          },
          {
             "subcatId":1,
             "subcatTitle":"Photo and Video Making"
          },
          {
             "subcatId":1,
             "subcatTitle":"Physiotherapy"
          },
          {
             "subcatId":1,
             "subcatTitle":"Plumber"
          },
          {
             "subcatId":1,
             "subcatTitle":"Plumber Carpenter and Electrician"
          },
          {
             "subcatId":1,
             "subcatTitle":"Rental Services"
          },
          {
             "subcatId":1,
             "subcatTitle":"Repair Services"
          },
          {
             "subcatId":1,
             "subcatTitle":"Sales Agent"
          },
          {
             "subcatId":1,
             "subcatTitle":"Tailor"
          },
          {
             "subcatId":1,
             "subcatTitle":"Tiffin and Catering Service"
          },
          {
             "subcatId":1,
             "subcatTitle":"Trainer"
          },
          {
             "subcatId":1,
             "subcatTitle":"Tutors"
          },
          {
             "subcatId":1,
             "subcatTitle":"Yoga Teacher"
          }
       ]
    },
    {
       "categoryId":1,
       "categoryTitle":"LFR and Brands",
       "subCategory":[
          {
             "subcatId":1,
             "subcatTitle":"Apparel"
          },
          {
             "subcatId":1,
             "subcatTitle":"Bags and Luggage"
          },
          {
             "subcatId":1,
             "subcatTitle":"Books"
          },
          {
             "subcatId":1,
             "subcatTitle":"Computer Accessories and Peripherals"
          },
          {
             "subcatId":1,
             "subcatTitle":"Cosmetics"
          },
          {
             "subcatId":1,
             "subcatTitle":"Electronics and Home Appliances"
          },
          {
             "subcatId":1,
             "subcatTitle":"Florist"
          },
          {
             "subcatId":1,
             "subcatTitle":"Footwear"
          },
          {
             "subcatId":1,
             "subcatTitle":"Gift Shop"
          },
          {
             "subcatId":1,
             "subcatTitle":"Grocery"
          },
          {
             "subcatId":1,
             "subcatTitle":"Handicraft Store"
          },
          {
             "subcatId":1,
             "subcatTitle":"Home Decor and Kitchen Accessories"
          },
          {
             "subcatId":1,
             "subcatTitle":"Hypermarket"
          },
          {
             "subcatId":1,
             "subcatTitle":"Jeweller"
          },
          {
             "subcatId":1,
             "subcatTitle":"Kirana"
          },
          {
             "subcatId":1,
             "subcatTitle":"Mobile Accessories"
          },
          {
             "subcatId":1,
             "subcatTitle":"Music Shop"
          },
          {
             "subcatId":1,
             "subcatTitle":"Optician"
          },
          {
             "subcatId":1,
             "subcatTitle":"Photo Studio"
          },
          {
             "subcatId":1,
             "subcatTitle":"Sports Goods"
          },
          {
             "subcatId":1,
             "subcatTitle":"Stationery"
          },
          {
             "subcatId":1,
             "subcatTitle":"Supermarket"
          },
          {
             "subcatId":1,
             "subcatTitle":"Toys"
          },
          {
             "subcatId":1,
             "subcatTitle":"Watches and other Accessories"
          }
       ]
    },
    {
       "categoryId":1,
       "categoryTitle":"Logistics",
       "subCategory":[
          {
             "subcatId":1,
             "subcatTitle":"Courier Logistics"
          },
          {
             "subcatId":1,
             "subcatTitle":"Delivery"
          }
       ]
    },
    {
       "categoryId":1,
       "categoryTitle":"Milk Dairy and Cooperative",
       "subCategory":[
          {
             "subcatId":1,
             "subcatTitle":"Aavin"
          },
          {
             "subcatId":1,
             "subcatTitle":"Aditya Milk"
          },
          {
             "subcatId":1,
             "subcatTitle":"Amul"
          },
          {
             "subcatId":1,
             "subcatTitle":"Ananda"
          },
          {
             "subcatId":1,
             "subcatTitle":"Dairy Cooperative"
          },
          {
             "subcatId":1,
             "subcatTitle":"Delivery Agent"
          },
          {
             "subcatId":1,
             "subcatTitle":"DMS"
          },
          {
             "subcatId":1,
             "subcatTitle":"Dodla"
          },
          {
             "subcatId":1,
             "subcatTitle":"Dudhsagar"
          },
          {
             "subcatId":1,
             "subcatTitle":"EPIGAMIA"
          },
          {
             "subcatId":1,
             "subcatTitle":"Gokul"
          },
          {
             "subcatId":1,
             "subcatTitle":"Hatsun"
          },
          {
             "subcatId":1,
             "subcatTitle":"Heritage"
          },
          {
             "subcatId":1,
             "subcatTitle":"Icecream Cart"
          },
          {
             "subcatId":1,
             "subcatTitle":"Icecream Parlour"
          },
          {
             "subcatId":1,
             "subcatTitle":"Jersey"
          },
          {
             "subcatId":1,
             "subcatTitle":"Kwality"
          },
          {
             "subcatId":1,
             "subcatTitle":"Madhusudan"
          },
          {
             "subcatId":1,
             "subcatTitle":"Medha"
          },
          {
             "subcatId":1,
             "subcatTitle":"Milk Distributor"
          },
          {
             "subcatId":1,
             "subcatTitle":"Milma"
          },
          {
             "subcatId":1,
             "subcatTitle":"Mother Dairy"
          },
          {
             "subcatId":1,
             "subcatTitle":"Namaste India"
          },
          {
             "subcatId":1,
             "subcatTitle":"Nandini"
          },
          {
             "subcatId":1,
             "subcatTitle":"Omfed"
          },
          {
             "subcatId":1,
             "subcatTitle":"Parag"
          },
          {
             "subcatId":1,
             "subcatTitle":"Private Dairy"
          },
          {
             "subcatId":1,
             "subcatTitle":"Safal"
          },
          {
             "subcatId":1,
             "subcatTitle":"Sanchi"
          },
          {
             "subcatId":1,
             "subcatTitle":"Saras"
          },
          {
             "subcatId":1,
             "subcatTitle":"Sudha"
          },
          {
             "subcatId":1,
             "subcatTitle":"Tirumala"
          },
          {
             "subcatId":1,
             "subcatTitle":"VADILAL"
          },
          {
             "subcatId":1,
             "subcatTitle":"Verka"
          },
          {
             "subcatId":1,
             "subcatTitle":"Vijaya"
          },
          {
             "subcatId":1,
             "subcatTitle":"VITA"
          }
       ]
    },
    {
       "categoryId":1,
       "categoryTitle":"NGO",
       "subCategory":[
          {
             "subcatId":1,
             "subcatTitle":"Crowd Sourcing Platform"
          },
          {
             "subcatId":1,
             "subcatTitle":"Non Profit"
          }
       ]
    },
    {
       "categoryId":1,
       "categoryTitle":"Parking and Tolls",
       "subCategory":[
          {
             "subcatId":1,
             "subcatTitle":"Authority Parking Area"
          },
          {
             "subcatId":1,
             "subcatTitle":"Commercial Office Complex Parking"
          },
          {
             "subcatId":1,
             "subcatTitle":"Electronic Toll Collection"
          },
          {
             "subcatId":1,
             "subcatTitle":"Events Parking"
          },
          {
             "subcatId":1,
             "subcatTitle":"Metro Station Parking"
          },
          {
             "subcatId":1,
             "subcatTitle":"Organized Parking"
          },
          {
             "subcatId":1,
             "subcatTitle":"Parking Airpots"
          },
          {
             "subcatId":1,
             "subcatTitle":"Parking Bus Stands"
          },
          {
             "subcatId":1,
             "subcatTitle":"Parking Hospitals"
          },
          {
             "subcatId":1,
             "subcatTitle":"Parking Malls and Shopping Complex"
          },
          {
             "subcatId":1,
             "subcatTitle":"Parking Metro and Rail"
          },
          {
             "subcatId":1,
             "subcatTitle":"Parking Offices"
          },
          {
             "subcatId":1,
             "subcatTitle":"Parking Others"
          },
          {
             "subcatId":1,
             "subcatTitle":"Parking Standalone and Muncipal"
          },
          {
             "subcatId":1,
             "subcatTitle":"Railway Station Parking"
          },
          {
             "subcatId":1,
             "subcatTitle":"Shopping Market Parking"
          },
          {
             "subcatId":1,
             "subcatTitle":"Theatre and Cinema Parking"
          },
          {
             "subcatId":1,
             "subcatTitle":"Toll Recharge"
          },
          {
             "subcatId":1,
             "subcatTitle":"Tolls Offline"
          },
          {
             "subcatId":1,
             "subcatTitle":"Unorganized Parking"
          }
       ]
    },
    {
       "categoryId":1,
       "categoryTitle":"Personal Services",
       "subCategory":[
          {
             "subcatId":1,
             "subcatTitle":"Astrology Services"
          },
          {
             "subcatId":1,
             "subcatTitle":"Boutique"
          },
          {
             "subcatId":1,
             "subcatTitle":"Concierge Services"
          },
          {
             "subcatId":1,
             "subcatTitle":"Courier Services"
          },
          {
             "subcatId":1,
             "subcatTitle":"Cyber Cafe"
          },
          {
             "subcatId":1,
             "subcatTitle":"Laundry and Dry Cleaner"
          },
          {
             "subcatId":1,
             "subcatTitle":"Matrimony Services"
          },
          {
             "subcatId":1,
             "subcatTitle":"Money Exchanger"
          },
          {
             "subcatId":1,
             "subcatTitle":"Subscription Services"
          },
          {
             "subcatId":1,
             "subcatTitle":"Tailor"
          }
       ]
    },
    {
       "categoryId":1,
       "categoryTitle":"Retail and Shopping",
       "subCategory":[
          {
             "subcatId":1,
             "subcatTitle":"Alcoholic Beverages"
          },
          {
             "subcatId":1,
             "subcatTitle":"Apparel"
          },
          {
             "subcatId":1,
             "subcatTitle":"Bags and Luggage"
          },
          {
             "subcatId":1,
             "subcatTitle":"Books"
          },
          {
             "subcatId":1,
             "subcatTitle":"Computer Accessories and Peripherals"
          },
          {
             "subcatId":1,
             "subcatTitle":"Cosmetics"
          },
          {
             "subcatId":1,
             "subcatTitle":"Disposable Items"
          },
          {
             "subcatId":1,
             "subcatTitle":"Electrical Shop"
          },
          {
             "subcatId":1,
             "subcatTitle":"Electronics and Home Appliances"
          },
          {
             "subcatId":1,
             "subcatTitle":"Florist"
          },
          {
             "subcatId":1,
             "subcatTitle":"Footwear"
          },
          {
             "subcatId":1,
             "subcatTitle":"Gift Shop"
          },
          {
             "subcatId":1,
             "subcatTitle":"Grocery"
          },
          {
             "subcatId":1,
             "subcatTitle":"Handicraft Store"
          },
          {
             "subcatId":1,
             "subcatTitle":"Hardware and Industrial Products"
          },
          {
             "subcatId":1,
             "subcatTitle":"Home Decor and Kitchen Accessories"
          },
          {
             "subcatId":1,
             "subcatTitle":"Hypermarket"
          },
          {
             "subcatId":1,
             "subcatTitle":"Jeweller"
          },
          {
             "subcatId":1,
             "subcatTitle":"Juice Shop"
          },
          {
             "subcatId":1,
             "subcatTitle":"Kirana"
          },
          {
             "subcatId":1,
             "subcatTitle":"Meat Shop"
          },
          {
             "subcatId":1,
             "subcatTitle":"Mobile Accessories"
          },
          {
             "subcatId":1,
             "subcatTitle":"Mumbai Dabbawallas"
          },
          {
             "subcatId":1,
             "subcatTitle":"Music Shop"
          },
          {
             "subcatId":1,
             "subcatTitle":"Optician"
          },
          {
             "subcatId":1,
             "subcatTitle":"Pan Shop"
          },
          {
             "subcatId":1,
             "subcatTitle":"Pet Care and Supplies"
          },
          {
             "subcatId":1,
             "subcatTitle":"Photo Studio"
          },
          {
             "subcatId":1,
             "subcatTitle":"Pooja and Religious Goods"
          },
          {
             "subcatId":1,
             "subcatTitle":"Sports Goods"
          },
          {
             "subcatId":1,
             "subcatTitle":"Stationery"
          },
          {
             "subcatId":1,
             "subcatTitle":"Street Hawker"
          },
          {
             "subcatId":1,
             "subcatTitle":"Supermarket"
          },
          {
             "subcatId":1,
             "subcatTitle":"Tattoo Shop"
          },
          {
             "subcatId":1,
             "subcatTitle":"Telecom"
          },
          {
             "subcatId":1,
             "subcatTitle":"Toys"
          },
          {
             "subcatId":1,
             "subcatTitle":"Watches and other Accessories"
          },
          {
             "subcatId":1,
             "subcatTitle":"Water Supplier"
          }
       ]
    },
    {
       "categoryId":1,
       "categoryTitle":"Transport",
       "subCategory":[
          {
             "subcatId":1,
             "subcatTitle":"Auto"
          },
          {
             "subcatId":1,
             "subcatTitle":"Bike Taxi"
          },
          {
             "subcatId":1,
             "subcatTitle":"E Ricksaw"
          },
          {
             "subcatId":1,
             "subcatTitle":"Taxi"
          }
       ]
    },
    {
       "categoryId":1,
       "categoryTitle":"Travel",
       "subCategory":[
          {
             "subcatId":1,
             "subcatTitle":"Aviation"
          },
          {
             "subcatId":1,
             "subcatTitle":"Hotel"
          },
          {
             "subcatId":1,
             "subcatTitle":"OTA"
          },
          {
             "subcatId":1,
             "subcatTitle":"Tours and Travel Agency"
          },
          {
             "subcatId":1,
             "subcatTitle":"Train"
          }
       ]
    },
    {
       "categoryId":1,
       "categoryTitle":"Tuition and Classes",
       "subCategory":[
          {
             "subcatId":1,
             "subcatTitle":"Dance and Music Classes"
          },
          {
             "subcatId":1,
             "subcatTitle":"Hobby Classes"
          },
          {
             "subcatId":1,
             "subcatTitle":"Language Classes"
          },
          {
             "subcatId":1,
             "subcatTitle":"Personal Tutor"
          },
          {
             "subcatId":1,
             "subcatTitle":"Professional Classes"
          },
          {
             "subcatId":1,
             "subcatTitle":"Sports Coaching"
          },
          {
             "subcatId":1,
             "subcatTitle":"Tuition Centre"
          }
       ]
    },
    {
       "categoryId":1,
       "categoryTitle":"Utitlities",
       "subCategory":[
          {
             "subcatId":1,
             "subcatTitle":"Electricity"
          },
          {
             "subcatId":1,
             "subcatTitle":"Internet Service Provider"
          },
          {
             "subcatId":1,
             "subcatTitle":"LPG and PNG"
          },
          {
             "subcatId":1,
             "subcatTitle":"Telecom Service Provider"
          },
          {
             "subcatId":1,
             "subcatTitle":"Water"
          }
       ]
    },
    {
       "categoryId":1,
       "categoryTitle":"Wholesale Trade and B2B",
       "subCategory":[
          {
             "subcatId":1,
             "subcatTitle":"Apparel and Garments"
          },
          {
             "subcatId":1,
             "subcatTitle":"Automobile Parts and Supplies"
          },
          {
             "subcatId":1,
             "subcatTitle":"Commodity"
          },
          {
             "subcatId":1,
             "subcatTitle":"Construction Material"
          },
          {
             "subcatId":1,
             "subcatTitle":"Consumer Durables"
          },
          {
             "subcatId":1,
             "subcatTitle":"FMCG"
          },
          {
             "subcatId":1,
             "subcatTitle":"Medical Equipment"
          },
          {
             "subcatId":1,
             "subcatTitle":"Telecom"
          }
       ]
    }
 ];

export default CatSubcat;