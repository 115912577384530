import React,{ useState } from "react";
import DatePicker from "react-datepicker";

const Datechoose = (props) => {
    const [startDate, setStartDate] = useState();
    
  const ExampleCustomInput = ({ value, onClick }) => {
    const initialValue = "Select a Date";  
    return(
    <button className="example-custom-input btn btn-outline-primary btn-xs" onClick={onClick} >
      {value == "" ? initialValue : value}
    </button>
  );
}

const datePass = (date)=>{
  setStartDate(date);
    props.onDatePicked(date)
} 


  return (
    <DatePicker style={{width:"20%"}}
      selected={startDate}
      onChange={datePass}
      customInput={<ExampleCustomInput />}
      isClearable
    />
  );
};


export default Datechoose;