import React, { Component, useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormGroup, Col, Row, Input, CustomInput } from 'reactstrap';
import useForm  from "react-hook-form";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";

let AddOfferModal = (props) => {
    
    let [loadingbtn, setloadingbtn] = useState(false);

    const { handleSubmit, register, errors } = useForm();

    const onSubmit = form_data => {
        
        if (form_data !== '') {

            setloadingbtn(true);

            client.post(api.edit_country, form_data, async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                // props.fetc_offers();
                                props.onClose(true);
                                toast.success("Updated Successfully");
                                // setcashbackdata(response.result.data);
                            } else {
                                toast.error(response.message);
                            }
                        } catch (e) {
                            toast.error("Internal error occured. Please contact support");
                        }
                    } else {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
                
                setloadingbtn(false);
            });
        }
        else {
            errors.showMessages();
        }
    }
    
    if (props.item) {
        return (
        
            <Modal isOpen={props.isOpen} toggle={props.onClose} size="lg" className="modal-body" centered={true}>
                {
                    // console.log(from_time)
                }
                <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                    <input type="hidden" name="_id" value={props.item._id} ref={register({ required: true })} />

                    <ModalHeader toggle={props.onClose}>Edit Country</ModalHeader>
                    <ModalBody>
                        <ModalBody style={{ padding: "16px 0" }}>
                            <Row form>
                                <Col md={12}>
                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="level_title">Country Name
                                            </Label>
                                                <input className="form-control" type="text" name="country_name" defaultValue={props.item.country_name} ref={register({ required: true })} />
                                                <span>{errors.country_name && 'Country Name is required'}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="level_title">Country Code</Label>
                                                <input className="form-control" type="text" name="country_code" defaultValue={props.item.country_code} ref={register({ required: true })} />
                                                <span>{errors.country_code && 'Country Code is required'}</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                
                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="level_title">Phone prefix
                                            </Label>
                                                <input className="form-control" type="text" name="phone_prefix" defaultValue={props.item.phone_prefix} ref={register({ required: true })} />
                                                <span>{errors.phone_prefix && 'Phone prefix is required'}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="level_title">Phone Regex</Label>
                                                <input className="form-control" type="text" name="phone_regex" defaultValue={props.item.phone_regex} ref={register({ required: true })} />
                                                <span>{errors.country_code && 'Phone Regex is required'}</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="level_title">Currency code
                                            </Label>
                                                <input className="form-control" type="text" name="currency_code" defaultValue={props.item.currency_code} ref={register({ required: true })} />
                                                <span>{errors.currency_code && 'Currency code is required'}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="level_title">Currency symbol
                                            </Label>
                                                <input className="form-control" type="text" name="currency_symbol" defaultValue={props.item.currency_symbol} ref={register({ required: true })} />
                                                <span>{errors.currency_symbol && 'Currency symbol is required'}</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            {!loadingbtn ?
                                <>
                                    <Button color="default" type="button" onClick={props.onClose}>Cancel</Button>
                                    <Button color="primary" type="submit">Save</Button>
                                </>
                                :
                                <div className="loader-box pull-right" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 20, width: 20 }}></span></div>
                            }
                        </ModalFooter>
                    </ModalBody>
                </form>
            </Modal>
        );
    } else {
        return null;
    }
}

export default AddOfferModal;