import React, { Fragment } from "react";
import HospoSafeComponent from "../../components/hospoSafeRegistry/HosposafeComponent";

class MerchantHospoSafe extends React.Component { 
    render(){
        return(
            <Fragment>
                <HospoSafeComponent id={this.props.merchant_id} info={this.props.merchant_info} customerType="merchant"/>
            </Fragment>
        );
    }
}

export default MerchantHospoSafe;



// import React, { Fragment } from "react";
// import { Button, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem, UncontrolledTooltip, NavLink as NavLinkTab } from 'reactstrap';
// import moment from "moment-timezone";
// import Datechoose from "../../components/ui-elements/datePicker";
// // import Pdf_render from "../hospoSafe/Pdf_render";
// // import ReactPDF from '@react-pdf/renderer';
// import HosposafeShareReport from "../../components/forms/hosposafeShareReport";
// import SweetAlert from 'react-bootstrap-sweetalert';
// import FileSaver from 'file-saver';
// import { DateRangePicker } from 'react-date-range';
// import PaginationComponent from "react-reactstrap-pagination";

// import client from "../../Api/HTTPClient";
// import displayMobile from "../../components/ui-elements/display_mobile";
// import api from "../../constant/apilist";
// import { ToastContainer, toast } from "react-toastify";
// import DatePicker from "react-datepicker";
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file

// class HospoSafeRegistry extends React.Component {
//     constructor(props) {
//         super(props);

//         this.state = {
//             merchant_id: this.props.merchant_id,
//             merchantInfo: this.props.merchant_info,
//             visitors: [],
//             filteredVisitors: null,
//             visitorId: null,
//             deleteModal: false,
//             activePage: 1,
//             pageSize: 15,
//             mailModal: false,
//             loadingmail: false,
//             filteredDate: null,
//             access_level: localStorage.getItem("accessLevel"),
//             share_to_subject: '',
//             alert: null,
//             share_to_email: '',
//             dates: [],
//             applyFilterType: 0,
//             dataLoading: true,
//             searchValue: null,
//             searchedData: null

//         };


//     }

//     componentDidMount() {
//         this.get_merchant_visitors();
//     }

//     createAndDownloadPdf = (form_data = null, type = 1) => {

//         // ReactPDF.render(<Pdf_render />, `${__dirname}/example.pdf`);

//         // return false;

//         // if (this.state.filteredVisitors == null) {
//         //     return;
//         // } else {

//         this.setState({ loadingmail: true });

//         let { merchant_id } = this.state;
//         const data = {
//             email: form_data ? form_data.share_to_email : '',
//             subject: form_data ? form_data.share_to_subject : '',
//             merchant_id: merchant_id,
//             type: type,
//             applyFilterType: this.state.applyFilterType,
//             filteredDate: this.state.filteredDate
//         }

//         client.postGetText(api.createhospsafePdf, data, (error, response) => {
//             this.setState({ loadingmail: false, mailModal: false });
//             if (response) {

//                 if (type == 2) {
//                     FileSaver.saveAs(
//                         new Blob([response], { type: 'application/pdf' }),
//                         `Hospo-Registry.pdf`
//                     );
//                 } else {
//                     this.setState({
//                         alert: (
//                             <SweetAlert success title="" onConfirm={this.hideAlert}>
//                                 Report send successfully
//                             </SweetAlert>
//                         )
//                     });
//                 }
//             } else {
//                 console.log("Error");
//             }
//         })
//         // }

//     }

//     handlePageChange = (pageNumber) => {
//         this.setState({ activePage: pageNumber });
//     }


//     hideAlert = () => {
//         this.setState({
//             alert: null
//         });
//     }

//     get_merchant_visitors() {
//         const postdata = { merchant_id: this.state.merchant_id, applyFilterType: this.state.applyFilterType, filteredDate: this.state.filteredDate };

//         this.setState({ dataLoading: true });
//         client.post(api.get_merchant_visitors, postdata, (error, response) => {
//             if (!error) {
//                 if (!response.error) {
//                     console.log(response);
//                     this.setState(prevState => {
//                         return {
//                             ...prevState,
//                             visitors: response.result.visitors_info.reverse()
//                         }
//                     })
//                 } else {
//                     setTimeout(() => {
//                         toast.error(" No visitors found");
//                     }, 200);
//                 }
//             } else {
//                 setTimeout(() => {
//                     toast.error("Internal error occured. Please contact support");
//                 }, 200);
//             }
//             this.setState({ dataLoading: false });
//         })

//     }
//     pageCount = () => {
//         if (this.state.transactions.length != 0) {
//             return Math.ceil(this.state.customers.length / this.state.pageSize)
//         }
//     }

//     setPageSize = (event) => {
//         this.setState({ pageSize: event.target.value })
//     }

//     pageClick(e, index) {
//         e.preventDefault();
//         this.setState({
//             currentPage: index
//         });

//     }
//     deleteClick = (id) => {
//         this.setState(prevState => {
//             return {
//                 ...prevState,
//                 deleteModal: !prevState.deleteModal,
//                 visitorId: id
//             }
//         });
//     }

//     mailClick = () => {
//         this.setState(prevState => {
//             return {
//                 ...prevState,
//                 mailModal: !prevState.mailModal,
//             }
//         });
//     }

//     toggleMailModal = () => {
//         this.setState(prevState => {
//             return {
//                 ...prevState,
//                 mailModal: !prevState.mailModal
//             }
//         })
//     }


//     dateHandler = async (date) => {
//         await this.setState({ filteredDate: date, applyFilterType: 7 })
//         this.get_merchant_visitors();

//         // const findDate = moment(date).format("L");
//         // this.setState({filteredDate:findDate})
//         // const filteredVisitors = this.state.visitors.filter(el => {
//         //     return (moment(el.added_date).format("L")) == findDate

//         // });
//         // /* console.log(date);
//         // console.log(filteredVisitors); */

//         // if (date != null) {
//         //     this.setState({ filteredVisitors: filteredVisitors })
//         // } else {
//         //     this.setState({ filteredVisitors: null });
//         // }
//     }

//     toggleDeleteModal = () => {
//         this.setState(prevState => {
//             return {
//                 ...prevState,
//                 deleteModal: !prevState.deleteModal
//             }
//         });
//     }

//     deleteVisitorRegistry = (e) => {
//         e.preventDefault();

//         const data = { visitor_id: this.state.visitorId }

//         client.post(api.delete_visitor_registry, data, (error, response) => {
//             if (!error) {
//                 // alert(JSON.stringify(response));
//                 //   return false;
//                 if (typeof response.error !== "undefined") {
//                     try {
//                         if (!response.error) {
//                             toast.success(response.message);
//                             window.location.reload(true);
//                         } else {
//                             toast.error(response.message);
//                         }
//                     } catch (e) {
//                         toast.error("Internal error occured. Please contact support");
//                     }
//                 } else {
//                     toast.error("Internal error occured. Please contact support");
//                 }
//             } else {
//                 toast.error("Internal error occured. Please contact support");
//             }

//         });



//     }

//     render_date = (add_date) => {

//         if (typeof (add_date) === "undefined") {
//             return;
//         }

//         const convert_date = new Date(add_date);
//         const added_date = moment.tz(convert_date, "Australia/Sydney").format("DD/MM/YYYY HH:mm");
//         return added_date;
//     }

//     handleSelect(ranges) {
//         console.log(ranges);
//         // {
//         //   selection: {
//         //     startDate: [native Date Object],
//         //     endDate: [native Date Object],
//         //   }
//         // }
//     }
//     onSelect = dates => this.setState({ dates })


//     renderdatepicker = () => {

//         const selectionRange = {
//             startDate: new Date(),
//             endDate: new Date(),
//             key: 'selection',
//         }
//         return (
//             <DateRangePicker
//                 ranges={[this.state.dates]}
//                 onChange={this.onSelect}
//             />
//         )

//         // return false;

//         // let {startDate, endDate } = this.state;

//         // return (
//         //     <div style={{ display: "flex" }}>
//         //         <div>
//         //         <DatePicker
//         //             selected={startDate}
//         //             onChange={startDate => this.setState({ startDate })}
//         //             selectsStart
//         //             startDate={startDate}
//         //             endDate={endDate}
//         //             />
//         //         </div>
//         //         <div>
//         //         <DatePicker
//         //             selected={endDate}
//         //             onChange={endDate => this.setState({ endDate })}
//         //             selectsEnd
//         //             startDate={startDate}
//         //             endDate={endDate}
//         //             minDate={startDate}
//         //             />
//         //             </div>
//         //         </div>
//         // );
//     }

//     onSearchChangeHandler = (event) => {
//         this.setState({ searchValue: event.target.value })
//     }

//     handleSearchSubmit = (event) => {
//         event.preventDefault();
//         const { searchValue } = this.state;
//         let data = null;

//         if (searchValue == null || searchValue == "") {
//             this.setState({ searchedData: null });
//         } else {
//             const filteredData = [];
//             data = this.state.visitors

//             data.filter(item => {
//                 if (typeof item.added_date != "undefined" && typeof item.name !== "undefined" && typeof item.merchant_name !== "undefined" && typeof item.mobile !== "undefined") {
//                     if (item.name.toLowerCase().includes(searchValue.toLowerCase()) || item.merchant_name.toLowerCase().includes(searchValue.toLowerCase()) ||
//                         item.mobile.toLowerCase().includes(searchValue.toLowerCase()) || this.render_date(item.added_date).toLowerCase().includes(searchValue.toLowerCase())

//                     ) {
//                         filteredData.push(item)
//                         //this.setState({ searchedData: filteredData });
//                     } else if (item.guests != "" && JSON.parse(item.guests).length > 0) {
//                         const guests = JSON.parse(item.guests)
//                         console.log(guests);
//                         const foundName = guests.filter(el => {
//                             return el.value.toLowerCase().includes(searchValue.toLowerCase())
//                         })
//                         console.log(foundName);
//                         if (foundName.length) {
//                             filteredData.push(item)
//                         }
//                         console.log(filteredData);
//                         //this.setState({ searchedData: filteredData });
//                     }
//                 }
//                 this.setState({ searchedData: filteredData });
//             })
//             console.log(filteredData);
//             //this.setState({ searchedData: filteredData });
//         }
//     }


//     renderAllTable = () => {
//         let showVisitors = []
//         let { visitors, access_level, searchedData } = this.state;
//         if (searchedData == null) {
//             showVisitors = [...visitors]
//         } else {
//             showVisitors = [...searchedData]
//         }


//         return (
//             <Fragment>
//                 <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
//                     <div >
//                         <form onSubmit={(event) => this.handleSearchSubmit(event)}>
//                             <div >
//                                 <input style={{
//                                     border: "none",
//                                     borderBottom: "1px solid rgb(197, 189, 189)",
//                                     borderRadius: "0",
//                                     margin: "0px 10px 6px",
//                                     fontSize: "0.75rem",

//                                 }}
//                                     type="text"
//                                     placeholder="Search"
//                                     onChange={this.onSearchChangeHandler} />
//                             </div>
//                         </form>
//                     </div>

//                     {(this.state.activePage) * this.state.pageSize > showVisitors.length ?
//                         <p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>Showing {((this.state.activePage - 1) * this.state.pageSize) + 1} to {showVisitors.length} of {showVisitors.length} entries</p> :
//                         <p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>Showing {((this.state.activePage - 1) * this.state.pageSize) + 1} to {(this.state.activePage) * this.state.pageSize} of {showVisitors.length} entries</p>}
//                 </div>

//                 <div className="table-responsive" ref={this.mailRef}>
//                     <table className="table table-border-horizontal table-striped" >
//                         <thead>
//                             <tr className="d-flex">
//                                 <th scope="col" style={{ width: "4%" }}>#</th>
//                                 <th scope="col" style={{ width: "14%" }}>Date / Time of visit</th>
//                                 <th scope="col" style={{ width: "20%" }}>Number of Guests</th>
//                                 <th scope="col" style={{ width: "20%" }}>Guest Name</th>
//                                 <th scope="col" style={{ width: "18%" }}>Mobile</th>
//                                 <th scope="col" style={{ width: "18%" }}>Email</th>
//                                 {access_level != 1 && <th scope="col" style={{ width: "18%" }}>Action</th>}
//                             </tr>
//                         </thead>
//                         <tbody>

//                             {showVisitors.length != 0 ? showVisitors.slice((this.state.activePage - 1) * this.state.pageSize, (this.state.activePage) * this.state.pageSize).map((item, index) => {
//                                 return (
//                                     <tr className="d-flex" key={item._id}>
//                                         <th scope=" row" style={{ width: "4%" }}>{index + 1}</th>
//                                         <td style={{ width: "14%" }}>{this.render_date(item.added_date)}</td>
//                                         <td style={{ width: "20%" }}>{item.guestno}</td>
//                                         <td style={{ width: "20%" }}>
//                                             <li>{item.name}</li>
//                                             {JSON.parse(item.guests).length != 0 ?
//                                                 JSON.parse(item.guests).map(el => {
//                                                     return <li key={el.index}>{el.value}</li>
//                                                 }) : null}
//                                         </td>
//                                         <td style={{ width: "18%" }}>{displayMobile(item.mobile)}</td>
//                                         <td style={{ width: "18%" }}>{item.customer.length > 0 ? item.customer[0].email == "" ? "N.A" : item.customer[0].email : "Not registered"}</td>
//                                         {access_level != 1 && <td style={{ width: "18%" }}>
//                                             {access_level == 3 && <span style={{ marginRight: "10px", fontSize: "1.2em" }} onClick={() => this.deleteClick(item._id)}>
//                                                 <i style={{ color: "red" }} id="delete" className="fa fa-trash-o"></i>
//                                             </span>}
//                                             <span style={{ marginRight: "10px", fontSize: "1.2em" }}>
//                                                 <i className="fa fa-list-alt" style={{ color: "black" }} id="info"></i>
//                                                 <UncontrolledTooltip placement="bottom" target="info">
//                                                     Info
//                                                 </UncontrolledTooltip>
//                                             </span>
//                                         </td>}
//                                     </tr>);
//                             }) : <h6 style={{ textAlign: "center" }}> No records found! </h6>}
//                         </tbody>
//                     </table>
//                     <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
//                         <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
//                             <select className="custom-select" style={{ width: "35%", fontSize: "0.63rem", marginRight: "10px" }} onChange={this.setPageSize} value={this.state.pageSize}>
//                                 <option value="15">15</option>
//                                 <option value="25">25</option>
//                                 <option value="50">50</option>
//                                 <option value="75">75</option>
//                                 <option value="100">100</option>
//                             </select>
//                             <span style={{ fontSize: "0.63rem" }}>Records per Page</span>
//                         </div>
//                         <PaginationComponent
//                             totalItems={showVisitors.length}
//                             pageSize={this.state.pageSize}
//                             onSelect={this.handlePageChange} />
//                     </div>
//                 </div>
//             </Fragment>
//         );
//     }

//     shareReportApi() {
//         let { share_to_subject, share_to_email } = this.state;
//         alert(share_to_subject + 'dasd ' + share_to_email);
//     }

//     renderMailModal = () => {
//         const { mailModal, loadingmail } = this.state;

//         return (

//             <Modal isOpen={mailModal} toggle={this.toggleMailModal} className="modal-body" centered={true}>
//                 <HosposafeShareReport loadingmail={loadingmail} toggleMailModal={this.toggleMailModal} createAndDownloadPdf={this.createAndDownloadPdf} />
//             </Modal>

//         );
//     }

//     renderDeleteModal = () => {
//         const { deleteModal } = this.state;

//         return (
//             <div className="card-body btn-showcase">
//                 <Modal isOpen={deleteModal} toggle={this.toggleDeleteModal} className="modal-body" centered={true}>
//                     <form className="theme-form" noValidate="" onSubmit={this.deleteVisitorRegistry}>
//                         <ModalBody>
//                             <ModalBody style={{ padding: "16px 0" }}>
//                                 <h4>Do you want to delete this visit record ?</h4>
//                             </ModalBody>
//                             <ModalFooter>
                                
//                                 <Button color="secondary" onClick={this.toggleDeleteModal}>No</Button>
//                                 <Button color="primary" type="submit"  >Yes</Button>
//                             </ModalFooter>
//                         </ModalBody>
//                     </form>
//                 </Modal>
//             </div>
//         );
//     }

//     applyFilter = async (type) => {
//         await this.setState({ applyFilterType: type });

//         this.get_merchant_visitors();

//     }


//     render() {
//         let { visitors, filteredDate, filteredVisitors, merchantInfo, visitorId, applyFilterType } = this.state;
//         console.log(visitors);
//         console.log(filteredVisitors);
//         console.log(filteredDate);
//         console.log(merchantInfo);

//         return (
//             <Fragment>
//                 {visitorId ? this.renderDeleteModal() : null}


//                 {this.state.alert}
//                 <div className="container-fluid">
//                     <div className="row">
//                         <div className="col-sm-12 col-xl-12 xl-100">
//                             <div className="card">
//                                 <div className="card-body" style={{ padding: "0.9rem" }}>

//                                     <div className="row" style={{ margin: "0 0 10px 0" }}>
//                                         <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
//                                             <button className={`btn ${applyFilterType != 1 ? "btn-outline-primary" : "btn-primary"} btn-xs`} onClick={() => this.applyFilter(1)} style={{ marginLeft: 5, marginRight: 5 }} type="button">Today</button>
//                                             <button
//                                                 className={`btn ${applyFilterType != 2 ? "btn-outline-primary" : "btn-primary"} btn-xs`}
//                                                 onClick={() => this.applyFilter(2)} style={{ marginLeft: 5, marginRight: 5 }} type="button">Yesterday</button>
//                                             <button
//                                                 className={`btn ${applyFilterType != 3 ? "btn-outline-primary" : "btn-primary"} btn-xs`}
//                                                 onClick={() => this.applyFilter(3)} type="button" style={{ marginLeft: 5, marginRight: 5 }} >This Week</button>
//                                             <button
//                                                 className={`btn ${applyFilterType != 4 ? "btn-outline-primary" : "btn-primary"} btn-xs`}
//                                                 onClick={() => this.applyFilter(4)} type="button" style={{ marginLeft: 5, marginRight: 5 }} >Last Week</button>
//                                             <button
//                                                 className={`btn ${applyFilterType != 5 ? "btn-outline-primary" : "btn-primary"} btn-xs`}
//                                                 onClick={() => this.applyFilter(5)} type="button" style={{ marginLeft: 5, marginRight: 5 }} >This Month</button>
//                                             <button
//                                                 className={`btn ${applyFilterType != 6 ? "btn-outline-primary" : "btn-primary"} btn-xs`}
//                                                 onClick={() => this.applyFilter(6)} type="button" style={{ marginLeft: 5, marginRight: 5 }} >Last Month</button>

//                                                 <Datechoose className="col-md-4" style={{ width: "20%",marginLeft: 5, marginRight: 5 }} onDatePicked={this.dateHandler}></Datechoose>
//                                                 <div class="manageReport" style={{ color: "#555", float: "right",marginLeft: 5, marginRight: 5 }}>
//                                                     {!this.state.loadingmail ?
//                                                         <div>
//                                                             {/* <i class="fa fa-file-pdf-o" style={{ marginRight: 3, color: "#a5a5a5", cursor: 'pointer' }}></i>
//                                                     <a class="weaklink" style={{ marginRight: 10, textDecoration: "none", borderBottomWidth: 1, color: '#1ea6ec', cursor: 'pointer' }} onClick={() => this.createAndDownloadPdf(null, 2)}>PDF</a>
//                                                  */}
//                                                             <span class="shareReport">
//                                                                 <i class="fa fa-users" style={{ marginRight: 3, color: "#a5a5a5", cursor: 'pointer' }}></i><a style={{ marginRight: 10, textDecoration: "none", borderBottomWidth: 1, color: '#1ea6ec', cursor: 'pointer' }} onClick={() => this.toggleMailModal()} class="weaklink">Share Report</a>
//                                                             </span>
//                                                         </div>
//                                                         : null
//                                                     }
//                                                 </div>
//                                         </div>
//                                     </div>
//                                     {/*        <div className="row" style={{ margin: "0 0 10px 0" }}>
                                        
                                        
//                                     </div> */}
//                                     {
//                                         !this.state.dataLoading ?

//                                             this.renderAllTable() :
//                                             <div className="loader-box" >
//                                                 <div className="loader" style={{ margin: "auto auto" }}>
//                                                     <div className="line bg-primary"></div>
//                                                     <div className="line bg-primary"></div>
//                                                     <div className="line bg-primary"></div>
//                                                     <div className="line bg-primary"></div>
//                                                 </div>
//                                             </div>
//                                     }
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 <div>
//                     {this.renderMailModal()}
//                 </div>
//             </Fragment>
//         );
//     }
// }

// export default HospoSafeRegistry;


