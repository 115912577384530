import * as actionTypes from "../actions/actionTypes";

const initialState = {
    merchant : null,
    loading: null,
    error: null,
    errorMsg: null,
    successMsg: null,
  
}

const merchantReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MERC_INITIALSTATE:
            return {
                ...initialState
            }
        case actionTypes.UPDATEDETAILS_START:
            return {
                ...state,
                loading: true
            }

        case actionTypes.UPDATEDETAILS_SUCCESS:
            return {
                ...state,
                //merchant: action.payload,
                successMsg: action.successMsg,
                loading: false,
                error: false
            }

        case actionTypes.UPDATEDETAILS_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
                errorMsg: action.payload
            }

        default:
            return state;
    }

}

export default merchantReducer;