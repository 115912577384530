import React, { useState, createRef } from 'react';
import Dropzone from 'react-dropzone';
import CropperModel from './CropperModel';
const Cam = "/assets/icons/icon_cam.png"

const DropZoneField = ({
    name, onChange, image_url, onRawChange, setImageLoader, imageLoader
}) => {

    const [imagePreview, setimagePreview] = useState(null);
    const [file, setFile] = useState(null)
    const [resizedImage, setResizedImage] = useState(null)
    const [raw, setraw] = useState(null)

    const dropzoneRef = createRef()

    const onDrop = (file) => {
        setimagePreview(URL.createObjectURL(file[0]))
        setFile(file[0])
    };

    const crModel = (croppedFile) => {
        if (file) {
            if (croppedFile) {
                onChange(croppedFile, { file });
            }
        }
        if (raw) {
            onRawChange(croppedFile);
        }
        setFile(null)
        setraw(null)
    }

    return (
        <div className="container border  p-0 m-0 "
            style={{
                width: "auto",
                maxHeight: '12rem',
                maxWidth: '12rem',
                borderColor: "silver"
            }}>
            <div className={`dropzone dropzoneIpad dropzone--single${false ? ' dropzone--custom-height' : ''}`}
                
            >
                {imageLoader ?
                    <div className="container d-flex align-items-center justify-content-center flex-column mx-auto px-0">
                        wait...
                    </div>
                    :
                    <Dropzone
                        ref={dropzoneRef}
                        accept="image/jpeg, image/png"
                        name={name}
                        multiple={false}
                        onDrop={(fileToUpload) => {
                            onDrop(fileToUpload);
                        }}
                    >

                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className="dropzone__input bg-light"
                                style={
                                    {
                                        minHeight: "unset"
                                    }
                                }
                            >
                                {image_url ?
                                    <aside className="dropzone__img">
                                        {resizedImage ?
                                            <img src={resizedImage} className="p-0" alt="drop-img" />
                                            :
                                            <img
                                                src={`${image_url}`}
                                                className="p-0"
                                            />
                                        }
                                    </aside>
                                    :
                                    <>
                                        <div style={{ paddingTop: "2.5rem", paddingBottom: "1,5rem" }} className="container d-flex align-items-center justify-content-center flex-column mx-auto px-0">
                                            <img src={Cam} alt="Camera_default" style={{ height: "3.5rem", width: "3.5rem" }}></img>
                                            <p class="" style={{ color: "silver", fontSize: "0.9rem" }}>Browse Image</p>
                                        </div>
                                        <br />

                                    </>

                                }
                                <input {...getInputProps()} />
                            </div>
                        )}
                    </Dropzone>}
                <CropperModel file={file}
                    raw={raw}
                    onConfirm={(croppedFile) => {
                        if (croppedFile) {

                            let reader = new FileReader();
                            reader.readAsDataURL(croppedFile);
                            reader.onload = () => {
                                setResizedImage(reader.result);
                            };

                        }
                    }}
                    onCompleted={(croppedFile) => { if (croppedFile) { crModel(croppedFile) }; setraw(null) }} />
            </div>
        </div>
    );
};

const renderDropZoneField = ({ input, image_url, raw_url, id, rawinput, imageLoader, setImageLoader }) => (
    <DropZoneField
        {...input}
        {...rawinput}
        image_url={image_url}
        raw_url={raw_url}
        id={id}
        imageLoader={imageLoader}
        setImageLoader={setImageLoader}
    />
);

export default renderDropZoneField;
