import React, { useState } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { Col, Row } from "reactstrap";
import ResultQuiz from "./ResultQuiz";
import QuizEdit from "./QuizEdit";
import PublishEdit from "./PublishEdit";

const QuizEditTabs = (props) => {
	const [tabIndex, setTabIndex] = useState(0);
	const [postData, setPostData] = useState(null);

	let propsData = props.redirectData;
	console.log(props.redirectData.id, "redirectdata");
	console.log(propsData, "props");
	return (
		<div className="card">
			<div className="card-header">
				<Tabs selectedIndex={tabIndex} defaultIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
					<TabList className="tbs tab-title">
						<Col md={3}>
							<h6 style={{ fontSize: "15px", marginTop: "10px" }}>
								<i onClick={() => props.toggleRedirect(false)} className="icon-arrow-left" style={{ color: "black" }}></i>{" "}
								&nbsp;&nbsp;Quiz - {propsData?.campaignName}
							</h6>
						</Col>
						<Col md={9}>
							<Tab style={{ marginLeft: "22%" }}>CREATE</Tab>
							<Tab>PUBLISH</Tab>
							<Tab>RESULTS</Tab>
						</Col>
					</TabList>
					<TabPanel>
						<QuizEdit setTabIndex={setTabIndex} setPostData={setPostData} propsData={propsData}></QuizEdit>
					</TabPanel>
					<TabPanel>
						<PublishEdit
							setTabIndex={setTabIndex}
							setPostData={setPostData}
							postData={postData}
							campaignName={props.campaignName}
							propsData={propsData}
						></PublishEdit>
					</TabPanel>
					<TabPanel>
						<ResultQuiz quizId={props.redirectData.id}></ResultQuiz>
					</TabPanel>
				</Tabs>
			</div>
		</div>
	);
};

export default QuizEditTabs;
