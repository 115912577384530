import React, { useState, useEffect } from 'react'
import { Edit } from 'react-feather';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import VerifiedMerchantProfile from "./VerifiedMerchantProfile";
import { MerchantUserProfile } from "./MerchantUserProfile";
import { Userandroles } from "./Userandroles";

import MerchantQr from "./MerchantQr";
import VerifiedMerchantTransaction from "./VerifiedMerchantTransaction";
import RewardHistory from "./RewardHistory";
import HospoSafeRegistry from "./HospoSafeRegistry";
import StripeConnect from "./StripeConnect";
import { StoresandCompany } from './StoresandCompany';


export const Merchantsprofile = (props) => {

    const { profiledata: { merchant_info } } = props;
    const [defaultIndex, setDefaultIndex] = useState(0)
    const changeTab = (newTabIndex) => {
        setDefaultIndex(newTabIndex);
    };


    return (
        <div className="container-fluid" style={{ paddingBottom: defaultIndex != 2 && 20 }}>
            <div className="row theme-tab" style={{ marginBottom: '0px !important' }} >
                <Tabs className="col-sm-12"
                    defaultIndex={0}
                    selectedIndex={defaultIndex}
                    onSelect={changeTab}
                    style={{ padding: "0" }}>
                    <TabList className="tabs tab-title" style={{ marginBottom: '0px !important' }}>
                        <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Profile Setting </Tab>
                        <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Store Setting </Tab>
                        {
                            props.merchantDataType && props.merchantDataType.type == 'trashMerchant' ? null
                                : <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Minisite & Qr Code </Tab>
                        }

                        {
                            props.merchantDataType && props.merchantDataType.type == 'trashMerchant' ? null
                                : <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Users & Roles </Tab>
                        }
                        <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Stores & Companies </Tab>

                        {/* {props.profiledata.merchant_info.mobile_status == 0 && props.profiledata.merchant_info.request_source == 2 || props.profiledata.merchant_info.mobile_status == 0 && props.profiledata.merchant_info.request_source != 2 ?
                            null
                            :
                            <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Transaction History </Tab>
                        } */}
                        {/* {merchant_info.mobile_status == 0 && merchant_info.request_source == 2 || !merchant_info.subscription || merchant_info.mobile_status == 0 && merchant_info.request_source != 2 ?
                            null :
                            <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Reward History </Tab>
                        } */}

                        {/* <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}>  Hospo Registry </Tab> */}

                        {/* {
                            props.merchantDataType && props.merchantDataType.type == 'trashMerchant' ? null
                                : <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Payouts </Tab>
                        } */}
                    </TabList>
                    <div className="tab-content-cls">

                        <TabPanel>
                            <div className="container-fluid" style={{ paddingTop: '0.5rem' }}>
                                <MerchantUserProfile
                                    merchant_info={props.profiledata.merchant_info}
                                    merchant_id={props.profiledata.merchant_id}
                                    {...props}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="container-fluid" style={{ paddingTop: '0.5rem' }}>
                                <VerifiedMerchantProfile
                                    merchant_info={props.profiledata.merchant_info}
                                    transactions={props.profiledata.transactions}
                                    getMerchantWalletInfo={props.getMerchantWalletInfo}
                                    merchant_id={props.profiledata.merchant_id}
                                    merchantDataType={props.merchantDataType}
                                ></VerifiedMerchantProfile>
                            </div>
                        </TabPanel>

                        {
                            props.merchantDataType && props.merchantDataType.type == 'trashMerchant' ? null
                                : <TabPanel>
                                    <div className="container-fluid" style={{ padding: '0px' }}>
                                        <MerchantQr merchant_id={props.profiledata.merchant_id} merchant_info={props.profiledata.merchant_info} getMerchantWalletInfo={props.getMerchantWalletInfo} />
                                    </div>
                                </TabPanel>
                        }

                        {
                            props.merchantDataType && props.merchantDataType.type == 'trashMerchant' ? null
                                : <TabPanel>
                                    <div className="container-fluid" style={{ paddingTop: '0.5rem' }}>
                                        <Userandroles
                                            merchant_info={props.profiledata.merchant_info}
                                            merchant_id={props.profiledata.merchant_id}
                                            subUsers={props.subUsers}
                                            storesList={props.storesList}
                                            user_details={props.user_details}
                                            merchant_user={props.merchant_user}
                                        />
                                    </div>
                                </TabPanel>
                        }
                        <TabPanel>
                            <div className="container-fluid" style={{ paddingTop: '0.5rem' }}>
                                <StoresandCompany
                                    merchant_info={props.profiledata.merchant_info}
                                    merchant_id={props.profiledata.merchant_id}
                                    storesList={props.storesList}
                                    setDefaultIndex={setDefaultIndex}
                                    userStorelist={props.userStorelist}
                                />
                            </div>
                        </TabPanel>

                        {/* {props.profiledata.merchant_info.mobile_status == 0 && props.profiledata.merchant_info.request_source == 2 || props.profiledata.merchant_info.mobile_status == 0 && props.profiledata.merchant_info.request_source != 2 ?
                            null
                            :
                            <TabPanel>
                                <div className="container-fluid" style={{ paddingTop: '0.5rem' }}>
                                    <VerifiedMerchantTransaction merchant_info={props.profiledata.merchant_info} merchant_id={props.profiledata.merchant_id}></VerifiedMerchantTransaction>
                                </div>
                            </TabPanel>
                        } */}
                        {/* {props.profiledata.merchant_info.mobile_status == 0 && props.profiledata.merchant_info.request_source == 2 || props.profiledata.merchant_info.mobile_status == 0 && props.profiledata.merchant_info.request_source != 2 ?
                            null :
                            <TabPanel >
                                <div className="container-fluid" style={{ paddingTop: '0.5rem' }}>
                                    <RewardHistory merchant_info={props.profiledata.merchant_info} merchant_id={props.profiledata.merchant_id}></RewardHistory>
                                </div>
                            </TabPanel>
                        } */}

                        {/* <TabPanel>
                            <div className="container-fluid" style={{ paddingTop: '0.5rem' }}>
                                <HospoSafeRegistry merchant_info={props.profiledata.merchant_info} merchant_id={props.profiledata.merchant_id}></HospoSafeRegistry>
                            </div>
                        </TabPanel> */}

                        {/* {
                            props.merchantDataType && props.merchantDataType.type == 'trashMerchant' ? null
                                : <TabPanel>
                                    <div className="container" style={{ paddingTop: '0.5rem' }}>
                                        <StripeConnect merchant_id={props.profiledata.merchant_id} merchant_info={props.profiledata.merchant_info}></StripeConnect>
                                    </div>
                                </TabPanel>
                        } */}
                    </div>
                </Tabs>
            </div>
        </div>

    )
}
