import React, { Fragment, useState, useEffect } from "react";
import { DisappearedLoading } from "react-loadingg";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import WhiteLabelTable from "./WhiteLabelTable";
import InvoiceDetails from "./InvoiceDetails";


const WhiteLabelMer = (props) => {


    const [loading, setLoading] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);
    const [whiteLabelMerchantData, setWhiteLabelMerchantData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [showInvoice, setShowInvoice] = useState(false);
    const [invoiceID, setInvoiceID] = useState(null);
    const [defaultVaue, setDefaultValue] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [pageSize, setPageSize] = useState(15);
    const [activePage, setActivePage] = useState(1);
    const [selectedWLMData, setSelectedWLMData] = useState(null);



    const fetchWhiteLabMerchant = (fetchType, activePageNum) => {

        let skip = (parseInt(activePageNum) - 1) * parseInt(pageSize);
        let postdata = { fetchType: fetchType, limit: parseInt(pageSize), skip };


        setLoading(true);
        client.post(api.whiteLabelMerchant, postdata, async function (error, response) {

            if (!error) {
                if (!response.error) {
                    setWhiteLabelMerchantData(response.result.getWhiteLabel);
                    setTotalData(response.result.totalData);
                    setLoading(false);
                } else {
                    toast.error(response.message);
                }
            } else {
                setLoading(false);
                toast.error("Internal error occured. Please contact support");
            }
        });
    };

    useEffect(() => {

        if (whiteLabelMerchantData.length == 0) {
            let fetchType = 'fetchData'
            fetchWhiteLabMerchant(fetchType, 1);
        }

    }, []);

    useEffect(() => {

        if (whiteLabelMerchantData) {

            let getData = {};

            whiteLabelMerchantData.map((val, index) => {

                getData[`${val._id}-comments`] = val.comments;

            });

            setDefaultValue(getData);
        };

    }, [whiteLabelMerchantData]);

    const updateStatus = (postData) => {

        client.put(api.updateStatus, postData, async function (error, response) {

            if (!error) {
                if (!response.error) {

                    fetchWhiteLabMerchant('updateStatus', activePage);
                    props.getCustomers();
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }
        });
    }

    const deleteWhiteLabelMerchant = (postData) => {

        client.put(api.delete_whitelabel_merchant, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    fetchWhiteLabMerchant('deleteWLM', activePage);
                    toast.success(response.message);
                    props.getCustomers();
                } else {
                    toast.error(response.message);
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }
        });
    };

    const toggleShowInvoice = (data) => {
        setShowInvoice(true);
        setInvoiceID(data);
    }

    const handleSearchSubmit = () => {
        console.log("submit");
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        let fetchType = 'changePage';
        fetchWhiteLabMerchant(fetchType, pageNumber);
    };

    return (
        <>
            {
                loading ?
                    <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                        <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                            <DisappearedLoading size="medium" color="#514F4E" />
                        </div>
                    </div> :
                    <>
                        {
                            showInvoice && invoiceID ?
                                <>
                                    <InvoiceDetails
                                        invoiceID={invoiceID}
                                        setShowInvoice={setShowInvoice}
                                        selectedWLMData={selectedWLMData}
                                    />
                                </>
                                : <WhiteLabelTable
                                    whiteLabelMerchantData={whiteLabelMerchantData}
                                    handleSearchSubmit={handleSearchSubmit}
                                    handlePageChange={handlePageChange}
                                    updateStatus={updateStatus}
                                    actionLoading={actionLoading}
                                    deleteWhiteLabelMerchant={deleteWhiteLabelMerchant}
                                    toggleShowInvoice={toggleShowInvoice}
                                    defaultVaue={defaultVaue}
                                    searchValue={searchValue}
                                    pageSize={pageSize}
                                    activePage={activePage}
                                    setPageSize={setPageSize}
                                    totalData={totalData}
                                    setSelectedWLMData={setSelectedWLMData}
                                />
                        }
                    </>
            }
        </ >
    );
}

export default WhiteLabelMer