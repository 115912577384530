import React, { Fragment, useState, useEffect, useLayoutEffect } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { toast } from "react-toastify";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import LogoBusinessType from "./logoAndBusinessComp/LogoAndBusinessTypePage";
import WhiteLabelMer from "./whiteLabelMerchantComp/WhiteLabelMer"
import OtherSetting from "./otherSettings/OtherSettingPage";
import { Link, useHistory, useLocation } from "react-router-dom";


const BorderTable = () => {

	const [whiteLabelMerchantData, setWhiteLabelMerchantData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [tabIndex, setTabIndex] = useState(1);
	const [type, settype] = useState('');
	const [activePage, setActivePage] = useState(1);
	const [selectedIDs, setSelectedIDs] = useState([]);
	let access_level = 3;

	const location = useLocation();
	// console.log(location, "props data");
	// console.log(tabIndex, "tabIndex");

	// useEffect(() => {

	// 	if (location.state && location.state.secondTabe) {
	// 		setTabIndex(1)
	// 	} else {
	// 		setTabIndex(0)
	// 	}
	// }, [location]);


	useEffect(() => {

		if (whiteLabelMerchantData.length == 0) {
			getCustomers();
		}
	}, []);

	const changeTab = async (type) => {

		if (type == "whiteLabelMerchant") {
			settype(type);
			setActivePage(1);
			getCustomers();
		}
	};

	const getCustomers = () => {

		let skip = (parseInt(1) - 1) * parseInt(15);
		let postdata = { fetchType: "getAllData", limit: parseInt(15), skip };
		setLoading(true);
		client.post(api.whiteLabelMerchant, postdata, async function (error, response) {

			if (!error) {
				if (!response.error) {
					setLoading(false);
					setWhiteLabelMerchantData(response.result.totalData);
				} else {
					toast.error(response.message);
				}

			} else {
				toast.error("Internal error occured. Please contact support");
			}
			setLoading(false);
		});
	};

	const deleteSelectedButtonClick = () => { }


	return (
		<Fragment>

			<div className="row">
				<div className="col-lg-7 col-sm-12" style={{ fontSize: "12px" }}>
					<Breadcrumb title={"WHITE LABEL MERCHANTS"} parent={"Home"} />
				</div>
				<div className="col-lg-5 col-sm-12 ">
					<div
						className="btn-group pull-right"
						style={{ paddingTop: "8px", marginLeft: "0.4rem", marginRight: "0.6rem", marginBottom: "0rem" }}
						role="group"
						aria-label="Basic example"
					>
						{tabIndex == 0 && (
							<button
								className="btn custBtnAdmin"
								onClick={deleteSelectedButtonClick}
								disabled={selectedIDs.length == 0 ? "disabled" : null}
							>
								{selectedIDs.length == 0 ? (
									<i
										style={{ color: "#327ebc", fontSize: "15px", margin: "0 7px 0 0" }}
										id="delete"
										title="delete"
										className="fa fa-trash-o"
									></i>
								) : (
									<i
										style={{ color: "#327ebc", fontSize: "15px", margin: "0 7px 0 0" }}
										id="delete"
										title="delete"
										className="fa fa-trash-o"
									></i>
								)}
								Delete selected
							</button>
						)}
					</div>
				</div>
			</div>

			<div className="container-fluid">
				<div className="row theme-tab">
					<Tabs
						className="col-sm-12"
						defaultIndex={tabIndex}
						style={{
							backgroundColor: "white",
							borderRadius: "3px",
							border: "1px solid rgb(223, 232, 241)",
							marginBottom: "0px",
						}}
						onSelect={(index) => { setTabIndex(index); }}
					>
						<TabList className="tabs tab-title">
							<Tab
								style={{ display: "flex", alignItems: "center" }}
								onClick={() => changeTab("whiteLabelMerchant")}
							>
								<i className="icofont icofont-business-man-alt-1" style={{ fontSize: "0.9rem", marginRight: "8px" }}></i>
								Requests ({whiteLabelMerchantData.length})
							</Tab>
							<Tab
								style={{ display: "flex", alignItems: "center" }}
								onClick={() => changeTab("businessTypes")}
							>
								Logo & Business Types
							</Tab>
							<Tab
								style={{ display: "flex", alignItems: "center" }}
								onClick={() => changeTab("otherSetting")}
							>
								Other Settings
							</Tab>
						</TabList>

						<div className="tab-content-cls">
							<TabPanel> <WhiteLabelMer getCustomers={getCustomers} /> </TabPanel>
						</div>

						<div className="tab-content-cls">
							<TabPanel> <LogoBusinessType /> </TabPanel>
						</div>

						<div className="tab-content-cls">
							<TabPanel> <OtherSetting /> </TabPanel>
						</div>

					</Tabs>
				</div>
			</div>
		</Fragment>
	);
}

export default BorderTable;
