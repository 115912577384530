import React from "react";

const PublishSurvey = (props) => {
	return (
		<div className="card">
			<div class="card-header b-l-primary ">
				<h5 style={{ margin: "30px" }}>Publish Settings</h5>
			</div>
			<form className="form theme-form">
				<div className="card-body">
					<div className="row">
						<div className="col">
							{/* <div className="form-group row">
								<label className="col-sm-3 col-form-label">
									Total Questions
								</label>
								<div className="col-sm-9">
									<input className="form-control" type="number" />
								</div>
							</div> */}
							<div className="form-group row">
								<label className="col-sm-3 col-form-label">App type?</label>
								<div className="col-sm-9">
									<select className="form-control">
										<option disabled value="select" selected="select">
											Select App Type
										</option>
										<option value="Andriod">Andriod</option>
										<option value="Ios">Ios</option>
										<option value="Both">Both</option>
									</select>
								</div>
							</div>

							{/* <div className="form-group row">
								<label className="col-sm-3 col-form-label">
									Cashback per question
								</label>
								<div className="col-sm-9">
									<input
										className="form-control"
										type="number"
										placeholder="Enter Amount"
									/>
								</div>
							</div> */}
							<div className="form-group row">
								<label className="col-sm-3 col-form-label">
									Budget per user
								</label>
								<div className="col-sm-9">
									<input className="form-control" type="number" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="card-footer">
					<div className="col-sm-9 offset-sm-3">
						<button
							className="btn btn-primary"
							style={{ marginLeft: "60%" }}
							type="button"
							onClick={(e) => props.setTabIndex(2)}
						>
							Publish Now
						</button>
						<button
							className="btn btn-info"
							style={{ marginLeft: "4%" }}
							type="reset"
						>
							Save as Draft
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default PublishSurvey;
