import React, { Component, Fragment } from "react";
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Input,
	Label,
	Form,
	FormGroup,
	Col,
	Row,
	FormFeedback,
	CustomInput,
} from "reactstrap";
import { TabContent, TabPane, Nav, NavItem, NavLink as NavLinkTab } from "reactstrap";
import { NavLink, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment-timezone";
import { Redirect } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Select from "react-select";
import default_image from "../../../../assets/images/default_image.png";
import client from "../../../../Api/HTTPClient";
import api from "../../../../constant/apilist";

class Members extends Component {
	state = {
		restrictedFeatures: this.props.restrictedFeatures,
		adminUsers: this.props.adminUsers,
		memberInfo: {},
		isOpen: false,
		imageSrc: null,
		deleteModal: false,
		selectedId: null,
		selectedUser: null,
		selectedUserFields: [],
		editModal: false,
		access_level: localStorage.getItem("accessLevel"),
	};

	refreshPage() {
		window.location.reload(false);
	}

	render_date = (add_date) => {
		if (typeof add_date === "undefined") {
			return;
		}

		const convert_date = new Date(add_date);
		const added_date = moment.tz(convert_date, "Australia/Sydney").format("DD/MM/YYYY HH:mm");
		return added_date;
	};

	onMemberFieldChange = (event) => {
		const { name, value } = event.target;
		this.setState((prevState) => {
			return {
				...prevState,
				memberInfo: {
					...prevState.memberInfo,
					[name]: value,
				},
			};
		});
	};

	onRestrictedFieldChange = (event) => {
		let value = [];
		try {
			value = event.map((item) => {
				return item.value;
			});
		} catch (error) {
			value = [];
		}

		this.setState((prevState) => {
			return {
				...prevState,
				memberInfo: {
					...prevState.memberInfo,
					hide_fields: value,
				},
			};
		});
	};

	onAddNewMember = (event) => {
		event.preventDefault();
		const postdata = this.state.memberInfo;
		//console.log(postdata);

		client.post(api.new_adminUser, postdata, (error, response) => {
			if (!error) {
				if (!response.error) {
					this.refreshPage();
					toast.success(response.message);
				} else {
					setTimeout(() => {
						toast.error(response.message);
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
		});
	};

	renderAddNewMemberModal = () => {
		const { memberInfo, access_level } = this.state;
		const options = this.props.restrictedFeatures.map((el) => {
			return { value: el._id, label: el.name };
		});
		return (
			<Modal isOpen={this.props.addMemberModal} toggle={this.props.toggleAddMemberModal}>
				<Form onSubmit={this.onAddNewMember}>
					<ModalHeader toggle={this.props.toggleAddMemberModal}>Add New Member</ModalHeader>
					<ModalBody>
						<Row>
							<Col md={12}>
								<FormGroup>
									<Label for="full_name">Full Name</Label>
									<Input
										type="text"
										id="full_name"
										name="full_name"
										required
										placeholder="Full Name"
										onChange={this.onMemberFieldChange}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
								<FormGroup>
									<Label for="email">Email</Label>
									<Input
										type="email"
										id="email"
										name="email"
										required
										placeholder="Email"
										onChange={this.onMemberFieldChange}
									/>
								</FormGroup>
							</Col>
							<Col md={6}>
								<FormGroup>
									<Label for="mobile">Mobile</Label>
									<Input
										type="text"
										id="mobile"
										name="mobile"
										required
										placeholder="Mobile"
										onChange={this.onMemberFieldChange}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row></Row>
						<Row>
							<Col md={6}>
								<FormGroup>
									<Label for="company_profile">Company Profile</Label>
									<Input
										type="text"
										id="company_profile"
										name="company_profile"
										required
										placeholder="Company profile"
										onChange={this.onMemberFieldChange}
									/>
								</FormGroup>
							</Col>
							<Col md={6}>
								<FormGroup>
									<Label for="access_level">Access Level</Label>
									<Input
										type="select"
										id="access_level"
										name="access_level"
										required
										placeholder="Access Level"
										onChange={this.onMemberFieldChange}
									>
										<option>Select option</option>
										<option value="1">View</option>
										<option value="2">Edit</option>
										<option value="3">Edit & Delete</option>
									</Input>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col md={12}>
								<FormGroup>
									<Label for="hide_fields">Hide Fields</Label>
									<Select
										options={options}
										isMulti
										name="hide_fields"
										className="basic-multi-select"
										classNamePrefix="select"
										onChange={this.onRestrictedFieldChange}
									></Select>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col md={12}>
								<FormGroup>
									<Label for="password">Create Password</Label>
									<Input
										type="password"
										id="password"
										name="password"
										required
										pattern="^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$"
										placeholder="Password"
										onChange={this.onMemberFieldChange}
									/>
									{memberInfo.password != "" &&
									memberInfo.password != null &&
									!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/.test(memberInfo.password) ? (
										<span style={{ color: "red", marginTop: "5px" }}>
											Password should be atleast 8 characters long containing combination of alphabet,number and special
											characters.
										</span>
									) : null}
								</FormGroup>
							</Col>
						</Row>
					</ModalBody>
					<ModalFooter>
						<Button color="default" onClick={this.props.toggleAddMemberModal}>
							Cancel
						</Button>
						<Button color="primary" type="submit">
							Submit
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		);
	};

	imageClicked = (src) => {
		//console.log("clicked");
		this.setState({ isOpen: true, imageSrc: src });
	};

	deleteClick = (id) => {
		this.setState((prevState) => {
			return {
				...prevState,
				deleteModal: !prevState.deleteModal,
				selectedId: id,
			};
		});
	};

	toggleDeleteModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				deleteModal: !prevState.deleteModal,
			};
		});
	};

	renderDeleteModal = () => {
		const { deleteModal } = this.state;

		if (localStorage.getItem("userId") != this.state.selectedId) {
			return (
				<Modal isOpen={deleteModal} toggle={this.toggleDeleteModal} className="modal-body" centered={true}>
					<form className="theme-form" onSubmit={this.deleteAdminUser}>
						<ModalHeader>
							<h5 style={{ fontSize: "1.15rem" }}>Delete Admin user</h5>
						</ModalHeader>
						<ModalBody>
							<ModalBody style={{ padding: "16px 0" }}>
								<h5 style={{ fontSize: "1.15rem" }}>Are you sure you want to delete the user account ?</h5>
							</ModalBody>
							<ModalFooter>
								<Button color="default" onClick={this.toggleDeleteModal}>
									Not sure!
								</Button>
								<Button color="primary" type="submit">
									Yes Please!
								</Button>
							</ModalFooter>
						</ModalBody>
					</form>
				</Modal>
			);
		} else {
			return toast.error("You are logged in");
		}
	};

	deleteAdminUser = (e) => {
		e.preventDefault();
		const postData = { userId: this.state.selectedId };

		client.post(api.delete_adminUser, postData, (error, response) => {
			if (!error) {
				if (!response.error) {
					this.refreshPage();
					toast.success(response.message);
				} else {
					toast.error(response.message);
				}
			} else {
				toast.error("Internal error occured. Please contact support");
			}
		});
	};

	renderTable = () => {
		const { adminUsers, access_level } = this.state;

		return (
			<div className="table-responsive">
				<table className="table table-border-horizontal">
					<thead>
						<tr style={{background: '#f9fafe'}}>
							<th scope="col" style={{ width: "5%" }}>
								#
							</th>
							<th scope="col" style={{ width: "12%" }}>
								Date / Time
							</th>
							<th scope="col" style={{ width: "22%" }}>
								Name
							</th>
							<th scope="col" style={{ width: "16%" }}>
								Email
							</th>
							<th scope="col" style={{ width: "14%" }}>
								Mobile
							</th>
							<th scope="col" style={{ width: "13%" }}>
								Profile
							</th>
							<th scope="col" style={{ width: "9%" }}>
								Access Level
							</th>
							<th scope="col" style={{ width: "17%" }}>
								Restricted
							</th>
							{/* <th scope="col" style={{ width: "17%" }}>Last Login</th> */}
							{access_level != 1 && (
								<th scope="col" style={{ width: "10%" }}>
									Action
								</th>
							)}
						</tr>
					</thead>
					<tbody>
						{adminUsers.map((item, i) => {
							return (
								<tr>
									<th scope="row" style={{ width: "5%" }}>
										{i + 1}
									</th>
									<td style={{ width: "12%" }}>
										{moment.tz(item.createdAt, "Australia/Sydney").format("DD-MM-YYYY HH:mm")}
									</td>
									<td style={{ width: "22%" }}>
										<span>
											<img style={{width:"34px", height:"34px"}}
												src={item.profile_image != "" ? `${client.url(`images/admin-profile/${item.profile_image}`)}` : default_image}
												onClick={() => this.imageClicked(item.profile_image != "" ? `${client.url(`images/admin-profile/${item.profile_image}`)}` : default_image)}
											/>
											
										</span>
										<NavLink style={{ marginLeft: "5px" }} to={`/adminUsers/${item._id}`}>
											{item.full_name}
										</NavLink>
									</td>
									<td style={{ width: "16%" }}>{item.email}</td>
									<td style={{ width: "14%" }}>{item.mobile}</td>
									<td style={{ width: "13%" }}>{item.company_profile}</td>
									<td style={{ width: "9%" }}>
										{item.access_level == 1 ? "View" : item.access_level == 2 ? "Edit" : "Edit & Delete"}
									</td>
									<td style={{ width: "17%" }}>
										{item.hide_fields.length != 0 ? (
											item.hide_fields.map((el) => {
												return <li key={el._id}>{el.name}</li>;
											})
										) : (
											<span>None</span>
										)}
									</td>
									{/* <td style={{ width: "17%" }}>
                                        <li>{item.devices_loggedIn[item.devices_loggedIn-1].location} </li>
                                        <li>{item.devices_loggedIn[item.devices_loggedIn-1].device} </li>
                                        <li>{item.devices_loggedIn[item.devices_loggedIn-1].time} </li>
                                    </td> */}
									{access_level != 1 && (
										<td style={{ width: "10%" }}>
											{access_level == 3 && (
												<span
													style={{ marginRight: "10px", fontSize: "0.75rem" }}
													onClick={() => this.deleteClick(item._id)}
												>
													<i style={{ color: "red" }} id="delete" className="fa fa-trash-o"></i>
												</span>
											)}
											{/* <span style={{ marginRight: "10px", fontSize: "1.2rem", color: "black" }} onClick={() => this.onEditClick(item)}>
                                            <i className="fa fa-edit"></i>
                                        </span> */}
										</td>
									)}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		);
	};

	render() {
		const { memberInfo, adminUsers, selectedId, selectedUserFields, selectedUser, access_level } = this.state;
		console.log(memberInfo);
		//console.log(adminUsers);
		//console.log(selectedUserFields);
		//console.log(access_level);

		return (
			<Fragment>
				{/* {levelId ? this.renderDeleteModal() : null} */}
				{this.renderAddNewMemberModal()}
				{selectedId && this.renderDeleteModal()}
				{selectedUser && this.renderEditMemberModal()}
				<div className="container-fluid">
					<div className="row">
						<div className="col-xl-12 col-sm-12">
							<div className="card">
								<div className="card-body">
									<div>
										{/* {access_level != 1 && <button className="btn btn-outline-primary" onClick={this.toggleAddMemberModal} style={{ marginBottom: "20px" }}><i className="fa fa-plus" style={{ margin: "0px 7px" }}></i> Add New Member </button>} */}

										{this.renderTable()}
										{this.state.isOpen && (
											<Lightbox mainSrc={this.state.imageSrc} onCloseRequest={() => this.setState({ isOpen: false })} />
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default Members;
