import React,{useEffect, useState} from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Table } from "reactstrap";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";

const Details = (props)=>{
  const {companyDetails,merchantId} = props
  const [defaultIndex, setDefaultIndex] = useState(0)
  const [tax,setTax] = useState([])
  const [taxGroup,setTaxGroup] = useState([])
    const changeTab = (newTabIndex) => {
        setDefaultIndex(newTabIndex);
    };

  useEffect(()=>{
    client.put(api.getTax,{merchant_Id:merchantId},(error,response)=>{
      try{
        if(!response.error){
          if(response.result){
            let arr = response.result.data;
            arr = arr.slice(1)
            setTax(arr.filter(item=>item.isGroup==false))
            arr = response.result.data;
            arr = arr.slice(1)
            setTaxGroup(arr.filter(item=>item.isGroup==true))
          }
        }
      }catch(err){
        console.log(err)
      }
    })
  },[])

  return(
    <div>
      <div className="header">
        <Tabs 
          className="col-sm-12"
          defaultIndex={0}
          selectedIndex={defaultIndex}
          onSelect={changeTab}
          style={{ padding: "0" }}
        >
          <TabList className="tabs tab-title" style={{ marginBottom: '0px !important',padding:0 }}>
            <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}>Details</Tab>
            <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}>Taxes</Tab>
          </TabList>
          <TabPanel>
            <div>
              <div className="">
              <h4 className="mx-0 mt-4" style={{fontSize:'16px'}}>Company Details</h4>
                <div className="" style={{display:'grid',gridTemplateColumns:'2fr 1fr'}}>
                  <div>
                    <div style={{display:'grid',gridTemplateColumns:'2fr 3fr',border:'1px solid gray'}}>
                      <h6 style={{margin: '0px 0.2rem',alignSelf: 'center', padding:'0.5rem',fontSize:'14px'}}>Company Name</h6>
                      <div className="d-flex align-items-center" style={{border: 'none',borderLeft: '1px solid gray',padding:'0 0.5rem',fontSize:'14px'}}>{companyDetails.companyName}</div>
                    </div>
                    <div style={{display:'grid',gridTemplateColumns:'2fr 3fr',border:'1px solid gray',borderTop:0}}>
                      <h6 style={{margin: '0px 0.2rem',alignSelf: 'center',padding:'0.5rem',fontSize:'14px'}}>Business Number/ABN</h6>
                      <div className="d-flex align-items-center" style={{border: 'none',borderLeft: '1px solid gray',padding:'0 0.5rem',fontSize:'14px'}}>{companyDetails.abn}</div>
                    </div>
                    <div style={{display:'grid',gridTemplateColumns:'2fr 3fr',border:'1px solid gray',borderTop:0}}>
                      <h6 style={{margin: '0px 0.2rem',alignSelf: 'center',padding:'0.5rem',fontSize:'14px'}}>Website</h6>
                      <div className="d-flex align-items-center" style={{border: 'none',borderLeft: '1px solid gray',padding:'0 0.5rem',fontSize:'14px'}}>{companyDetails.website} </div>
                    </div>
                    <div style={{display:'grid',gridTemplateColumns:'2fr 3fr',border:'1px solid gray',borderTop:0}}>
                      <h6 style={{margin: '0px 0.2rem',alignSelf: 'center',padding:'0.5rem',fontSize:'14px'}}>TimeZone</h6>
                      <div className="d-flex align-items-center" style={{border: 'none',borderLeft: '1px solid gray',padding:'0 0.5rem',fontSize:'14px'}}>{companyDetails.timeZone}</div>
                    </div>
                    <div style={{display:'grid',gridTemplateColumns:'2fr 3fr',border:'1px solid gray',borderTop:0}}>
                      <h6 style={{margin: '0px 0.2rem',alignSelf: 'center',padding:'0.5rem',fontSize:'14px'}}>Contact Name</h6>
                      <div className="d-flex align-items-center" style={{border: 'none',borderLeft: '1px solid gray',padding:'0 0.5rem',fontSize:'14px'}}>{companyDetails.contactInfo.name} </div>
                    </div>
                    <div style={{display:'grid',gridTemplateColumns:'2fr 3fr',border:'1px solid gray',borderTop:0}}>
                      <h6 style={{margin: '0px 0.2rem',alignSelf: 'center',padding:'0.5rem',fontSize:'14px'}}>Phone Number</h6>
                      <div className="d-flex align-items-center" style={{border: 'none',borderLeft: '1px solid gray',padding:'0 0.5rem',fontSize:'14px'}}>{companyDetails.contactInfo.mobile} </div>
                    </div>
                    <div style={{display:'grid',gridTemplateColumns:'2fr 3fr',border:'1px solid gray',borderTop:0}}>
                      <h6 style={{margin: '0px 0.2rem',alignSelf: 'center',padding:'0.5rem',fontSize:'14px'}}>Email</h6>
                      <div className="d-flex align-items-center" style={{border: 'none',borderLeft: '1px solid gray',padding:'0 0.5rem',fontSize:'14px'}}>{companyDetails.contactInfo.email}</div>
                    </div>
                  </div>
                  <div style={{justifySelf:'flex-end',alignSelf:'center'}}>
                    <img src={companyDetails.profile_image} alt="Profile-Image" width={190} height={210} style={{border:'0.7rem solid silver'}}/>
                  </div>
                  </div>
              </div>
              <div>
                <h4 className="mx-0 mt-4" style={{fontSize:'16px'}}>Tax Settings</h4>
                <div style={{display:'grid',gridTemplateColumns:'2fr 1fr'}}>
                  <div>
                    <div style={{display:'grid',gridTemplateColumns:'2fr 3fr',border:'1px solid gray'}}>
                      <h6 style={{margin: '0px 0.2rem',alignSelf: 'center',padding:'0.5rem',fontSize:'14px'}}>How tax to be calculated?</h6>
                      <div className="d-flex align-items-center" style={{border: 'none',borderLeft: '1px solid gray',padding:'0 0.5rem',fontSize:'14px'}}>"USA Tax inclusive prices"</div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
              <div className="">
                <h4 className="mx-0 mt-4" style={{fontSize:'16px'}}>Comapny Address</h4>
                <div style={{display:'grid',gridTemplateColumns:'2fr 1fr'}}>
                  <div>
                    <div style={{display:'grid',gridTemplateColumns:'2fr 3fr',border:'1px solid gray'}}>
                      <h6 style={{margin: '0px 0.2rem',alignSelf: 'center',padding:'0.5rem',fontSize:'14px'}}>Country</h6>
                      <div className="d-flex align-items-center" style={{border: 'none',borderLeft: '1px solid gray',padding:'0 0.5rem',fontSize:'14px'}}>{companyDetails.address.addresstext.split(',')[2]}</div>
                    </div>
                    <div style={{display:'grid',gridTemplateColumns:'2fr 3fr',border:'1px solid gray',borderTop:0}}>
                      <h6 style={{margin: '0px 0.2rem',alignSelf: 'center',padding:'0.5rem',fontSize:'14px'}}>State</h6>
                      <div className="d-flex align-items-center" style={{border: 'none',borderLeft: '1px solid gray',padding:'0 0.5rem',fontSize:'14px'}}>{companyDetails.address.state}</div>
                    </div>
                    <div style={{display:'grid',gridTemplateColumns:'2fr 3fr',border:'1px solid gray',borderTop:0}}>
                      <h6 style={{margin: '0px 0.2rem',alignSelf: 'center',padding:'0.5rem',fontSize:'14px'}}>Address</h6>
                      <div className="d-flex align-items-center" style={{border: 'none',borderLeft: '1px solid gray',padding:'0 0.5rem',fontSize:'14px'}}>{companyDetails.address.addresstext}</div>
                    </div>
                    <div style={{display:'grid',gridTemplateColumns:'2fr 3fr',border:'1px solid gray',borderTop:0}}>
                      <h6 style={{margin: '0px 0.2rem',alignSelf: 'center',padding:'0.5rem',fontSize:'14px'}}>Street Name</h6>
                      <div className="d-flex align-items-center" style={{border: 'none',borderLeft: '1px solid gray',padding:'0 0.5rem',fontSize:'14px'}}>{companyDetails.address.street_name}</div>
                    </div>
                    <div style={{display:'grid',gridTemplateColumns:'2fr 3fr',border:'1px solid gray',borderTop:0}}>
                      <h6 style={{margin: '0px 0.2rem',alignSelf: 'center',padding:'0.5rem',fontSize:'14px'}}>Postcode / Pincode</h6>
                      <div className="d-flex align-items-center" style={{border: 'none',borderLeft: '1px solid gray',padding:'0 0.5rem',fontSize:'14px'}}>{companyDetails.address.postcode}</div>
                    </div>
                    <div style={{display:'grid',gridTemplateColumns:'2fr 3fr',border:'1px solid gray',borderTop:0}}>
                      <h6 style={{margin: '0px 0.2rem',alignSelf: 'center',padding:'0.5rem',fontSize:'14px'}}>Suburb / City</h6>
                      <div className="d-flex align-items-center" style={{border: 'none',borderLeft: '1px solid gray',padding:'0 0.5rem',fontSize:'14px'}}>{companyDetails.address.suburb}</div>
                    </div>
                  </div>
                  <div style={{justifySelf:'flex-end',alignSelf:'center'}}>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div>
              <div>
                <h5 className="mt-4" style={{fontSize:'16px'}}>Tax</h5>
                <div>
                  <Table striped>
                      <thead>
                        <tr>
                          <th className='border'>#</th>
                          <th className='border'>Tax Name</th>
                          <th className='border'>Rate</th>
                          <th className='border'>Tax Group</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          tax.length > 0 ? tax.map((item,index)=>{
                            return(
                              <tr>
                                <th className='border'>{index+1}</th>
                                <td className='border'>{item.taxName}</td>
                                <td className='border'>{item.taxRate+"%"}</td>
                                <td className='border'>{item.isGroup === true?"Yes":"No"}</td>
                              </tr>
                            )
                          }):(
                            <tr>
                              <td style={{width:'100%',textAlign: 'center'}} colSpan='4'>No records found</td>
                            </tr>
                          )
                        }
                      </tbody>
                  </Table>
                </div>
              </div>
              <div>
                <h5 className="mt-4" style={{fontSize:'16px'}}>Tax Group </h5>
                <div>
                <div>
                  <Table striped>
                    <thead>
                      <tr>
                        <th className='border'>#</th>
                        <th className='border'>Tax Name</th>
                        <th className='border'>Rate</th>
                        <th className='border'>Tax Group</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        taxGroup.length > 0 ? taxGroup.map((item,index)=>{
                          return(
                            <tr>
                              <th className='border'>{index+1}</th>
                              <td className='border'>{item.taxName}</td>
                              <td className='border'>{item.taxRate+"%"}</td>
                              <td className='border'>{item.isGroup === true?"Yes":"No"}</td>
                            </tr>
                          )
                        }):(
                          <tr>
                            <td style={{width:'100%',textAlign: 'center'}} colSpan='4'>No records found</td>
                          </tr>
                        )
                      }
                    </tbody>
                  </Table>
                </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  )
}

export default Details