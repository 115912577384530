import React, { Fragment, useState, useEffect, useLayoutEffect } from "react";
import logo from "../../../assets/images/new-ipay-logo-1.png";
import logo_compact from "../../../assets/images/logo/compact-logo.png";
import iPay_logo from "../../../assets/images/i-Pay_logo.png";
import linkyWhiteLogo from "../../../assets/images/lnky_white_logo.svg";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { ToastContainer, toast } from "react-toastify";

import UserPanel from "./userPanel";
import { MENUITEMS } from "../../../constant/menu";
import { Link } from "react-router-dom";
import { translate } from "react-switch-lang";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import {
  AlignLeft,
  Maximize,
  Bell,
  MessageCircle,
  MoreHorizontal, Repeat
} from "react-feather";


function useWindowSize(wrapper) {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  /* if (wrapper === "horizontal_sidebar") {
    if (size[0] > 100 && size[0] < 991) {
      document.querySelector(".page-wrapper").className =
        "page-wrapper default";
      document.querySelector(".page-body-wrapper").className =
        "page-body-wrapper default";
    } else {
      document.querySelector(".page-wrapper").className =
        "page-wrapper horizontal_sidebar";
      document.querySelector(".page-body-wrapper").className =
        "page-body-wrapper horizontal_sidebar";
    }
  } */

  return size;
}

const Sidebar = props => {
  const dispatch = useDispatch();
  const [margin, setMargin] = useState(0);
  const [hideRightArrow, setHideRightArrow] = useState(false);
  const [hideLeftArrow, setHideLeftArrow] = useState(true);
  const [filteredmenu, setFilteredMenu] = useState([])
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [hideLeftArrowRTL, setHideLeftArrowRTL] = useState(false);
  const [hideRightArrowRTL, setHideRightArrowRTL] = useState(true);
  const configDB = useSelector(content => content.Customizer.sidebar_types);
  const layout = useSelector(content => content.Customizer.layout);
  const totalCountShow = useSelector(content => content.Customizer.totalCountShow);
  const [width, height] = useWindowSize(configDB.wrapper);
  let location = useLocation();
  const [sidebar, setSidebar] = useState(false);
  const [rightSidebar, setRightSidebar] = useState(true);

  const getCounts = (item = "") => {
    if (item === "pendingSettlement" && totalCountShow?.pendingSettlement) {
      return totalCountShow?.pendingSettlement;
    } else if (item === "pendingDocVerification" && totalCountShow?.pendingDocVerification) {
      return totalCountShow?.pendingDocVerification;
    } else if (item === "affiliateProgram" && totalCountShow?.affiliateProgram) {
      return totalCountShow?.affiliateProgram;
    } else {
      return 0;
    }
  }

  useEffect(() => {
    const postData = { id: localStorage.getItem('userId') };
    client.post(api.sidebarCounts, postData, async (error, response) => {
      if (!error) {
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              if (response?.result) {
                const { pendingSettlement, pendingDocVerification, affiliateProgram } = response.result;
                dispatch({ type: "ADD_TOTAL_COUNT", payload: { pendingSettlement: pendingSettlement || 0, pendingDocVerification: pendingDocVerification || 0, affiliateProgram: affiliateProgram || 0 } });
              }
            }
          } catch (e) {
            console.log(e)
          }
        }
      }
    });
  }, [])

  const openCloseSidebar = () => {
    if (sidebar) {
      setSidebar(!sidebar);
      document.querySelector(".page-main-header").classList.remove("open");
      document.querySelector(".sidebar1").classList.remove("open");
      //changes
      // document.querySelector(".page-body-above").style.width="70vw";
      document.querySelector(".page-body-above").style.marginLeft = "0px";
      document.querySelector(".page-body").style.marginLeft = "210px";
      document.querySelector(".page-body").style.transition = "0.8s";
    } else {
      setSidebar(!sidebar);
      document.querySelector(".page-main-header").classList.add("open");
      document.querySelector(".sidebar1").classList.add("open");
      //changes
      // document.querySelector(".sidebar1").style.width="0px";
      document.querySelector(".page-body-above").style.marginLeft = "0px";
      document.querySelector(".page-body").style.marginLeft = "0px";
      document.querySelector(".page-body").style.transition = "0.8s";
    }
  };

  useEffect(() => {
    const currentUrl = location.pathname;

    const postData = { id: localStorage.getItem('userId') };

    client.post(api.get_admin_details, postData, async (error, response) => {
      if (!error) {
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              //console.log(response);
              const hide_fields = response.result.data[0].hide_fields;
              //console.log(hide_fields);
              const show_fields = [];
              const fields_with_child = [];
              //console.log(mainmenu);
              mainmenu.map(item => {
                /* if (item.children) {
                  console.log(item);
                  item.children.map(child=>{
                    if (hide_fields.every(el => el.name != child.title)) {
                      fields_with_child.push(child)
                      console.log(child);
                    }
                  });
                  console.log(fields_with_child );
                  show_fields.push(fields_with_child)   
                } else { */
                if (hide_fields.every(el => el.name != item.title)) {
                  show_fields.push(item)
                }
                // }

              })
              // console.log(show_fields);
              setFilteredMenu(show_fields);
              //console.log(mainmenu);
              show_fields.filter(items => {
                if (items.path === currentUrl) setNavActive(items);
                if (!items.children) return false;
                items.children.filter(subItems => {
                  if (subItems.path === currentUrl) setNavActive(subItems);
                  if (!subItems.children) return false;
                  subItems.children.filter(subSubItems => {
                    if (subSubItems.path === currentUrl) setNavActive(subSubItems);
                  });
                });
              });

            } else {
              setTimeout(() => {
                toast.error(response.message);
              }, 200);
            }
          } catch (e) {
            setTimeout(() => {
              toast.error("Internal error occured. Please contact support " + e.message);
            }, 200);

          }
        } else {
          setTimeout(() => {
            toast.error("Internal error occured. Please contact support 1");
          }, 200);
        }
      } else {
        setTimeout(() => {
          toast.error("Internal error occured. Please contact support 2");
        }, 200);
      }

    });

  }, []);


  //console.log(MENUITEMS);

  const setNavActive = item => {
    MENUITEMS.filter(menuItem => {
      if (menuItem != item) menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true;
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
    item.active = !item.active;
    setMainMenu({ mainmenu: MENUITEMS });
  };

  // Click Toggle menu
  const toggletNavActive = item => {
    if (!item.active) {
      MENUITEMS.forEach(a => {
        if (MENUITEMS.includes(item)) a.active = false;
        if (!a.children) return false;
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
          }
          if (!b.children) return false;
          b.children.forEach(c => {
            if (b.children.includes(item)) {
              c.active = false;
            }
          });
        });
      });
    }
    item.active = !item.active;
    setMainMenu({ mainmenu: MENUITEMS });
  };

  const scrollToRight = () => {
    const elmnt = document.getElementById("myDIV");
    const menuWidth = elmnt.offsetWidth;
    const temp = menuWidth + margin;
    // Checking condition for remaing margin
    if (temp < menuWidth) {
      setMargin(-temp);
      setHideRightArrow(true);
    } else {
      setMargin(margin => (margin += -width));
      setHideLeftArrow(false);
    }
  };

  const scrollToLeft = () => {
    // If Margin is reach between screen resolution
    if (margin >= -width) {
      setMargin(0);
      setHideLeftArrow(true);
    } else {
      setMargin(margin => (margin += width));
      setHideRightArrow(false);
    }
  };

  const scrollToLeftRTL = () => {
    if (margin <= -width) {
      setMargin(margin => (margin += -width));
      setHideLeftArrowRTL(true);
    } else {
      setMargin(margin => (margin += -width));
      setHideRightArrowRTL(false);
    }
  };

  const scrollToRightRTL = () => {
    const temp = width + margin;
    // Checking condition for remaing margin
    if (temp === 0) {
      setMargin(temp);
      setHideRightArrowRTL(true);
    } else {
      setMargin(margin => (margin += width));
      setHideRightArrowRTL(false);
      setHideLeftArrowRTL(false);
    }
  };
  //console.log(filteredmenu);

  return (
    <Fragment>
      <div
        className=""
        style={{
          height: "65px",
          display: 'flex',
          background: '#008fe2',
          width: "260px",
          minWidth: '260px',
          position: "fixed",
          top: 0,
          // zIndex: 1000,
          zIndex: 18,
          transition: "0.3s"
        }}
      >
        <div className="mobile-sidebar d-block" style={{ alignSelf: 'center', paddingLeft: '12px' }}>
          <div className="media-body text-right switch-sm">
            <label className="switch">
              <a className="sidebarbtnn" onClick={() => openCloseSidebar()}>
                <AlignLeft style={{ color: '#fff' }} />
              </a>
            </label>
          </div>
        </div>
        <div className="main-header-left d-block" style={{ padding: '0px', marginLeft: "15px" }}>
          <div className="logo-wrapper compactLogo" style={{ display: "flex" }}>
            <Link to={`${process.env.PUBLIC_URL}/dashboard/default`}>
              {/* <img className="blur-up lazyloaded" src={logo_compact} alt="" /> */}
              <img className="blur-up lazyloaded" src={linkyWhiteLogo} alt="" style={{ height: "70px", width: "70px", display: "block" }} />
            </Link>
            {/* <img className="blur-up lazyloaded" src={iPay_logo} alt="" style={{ height: "80%", width: "90%" }} /> */}
            <h6 style={{ color: '#fff', fontWeight: '600', paddingTop: '22px', display: "grid", fontSize: "0.85rem", whiteSpace: 'nowrap' }}>
              &nbsp;&nbsp;Simple & Powerful<span style={{ color: "#fff", fontSize: "0.65rem", fontWeight: "400", marginLeft: "0.5rem", marginTop: "-12px", float: "left" }}>Business Management Tool</span></h6>
          </div>
        </div>

      </div>
      <div className="page-sidebar sidebar1" style={{ marginTop: width <= 992 ? "6px" : "65px" }}>
        <div className="sidebar custom-scrollbar">
          {/* {<UserPanel />} */}
          <ul
            className="sidebar-menu p-3"
            id="myDIV"
            style={
              configDB.wrapper === "horizontal_sidebar"
                ? layout === "rtl"
                  ? { marginRight: margin + "px" }
                  : { marginLeft: margin + "px" }
                : { margin: "0px" }
            }
          >
            <li
              className={`left-arrow ${layout == "rtl"
                ? hideLeftArrowRTL
                  ? "d-none"
                  : "hideLeftArrowRTL"
                : hideLeftArrow
                  ? "d-none"
                  : "hideLeftArrow"
                }`}
              onClick={
                configDB.wrapper === "horizontal_sidebar" && layout === "rtl"
                  ? scrollToLeftRTL
                  : scrollToLeft
              }
            >
              <i className="fa fa-angle-left"></i>
            </li>
            {filteredmenu.map((menuItem, i) => (
              <li className={`${menuItem.active ? "active" : ""}`} key={i}>
                {menuItem.sidebartitle ? (
                  <div className="sidebar-title" style={{ fontSize: "0.8rem" }}>{menuItem.sidebartitle}</div>
                ) : (
                  ""
                )}
                {menuItem.type === "sub" ? (
                  <a
                    className="sidebar-header"
                    href="#javascript"
                    onClick={() => toggletNavActive(menuItem)}
                  >
                    <menuItem.icon />
                    <span style={{ fontSize: "0.8rem" }}>{props.t(menuItem.title)}</span>
                    <i className="fa fa-angle-right pull-right"></i>
                  </a>
                ) : (
                  ""
                )}
                {menuItem.type === "link" ? (
                  <Link
                    to={`${process.env.PUBLIC_URL}${menuItem.path}`}
                    className={`sidebar-header ${menuItem.active ? "active" : ""
                      }`}
                    onClick={() => toggletNavActive(menuItem)}
                  >
                    <menuItem.icon />
                    <span style={{ fontSize: "0.8rem" }}>{props.t(menuItem.title)}</span>
                    {menuItem.children ? (
                      <i className="fa fa-angle-right pull-right"></i>
                    ) : (
                      ""
                    )}
                  </Link>
                ) : (
                  ""
                )}
                {menuItem.children ? (
                  <ul
                    className={`sidebar-submenu ${menuItem.active ? "menu-open" : ""
                      }`}
                    style={
                      menuItem.active
                        ? { opacity: 1, transition: "opacity 500ms ease-in" }
                        : {}
                    }
                  >
                    {menuItem.children.map((childrenItem, index) => (
                      <li
                        key={index}
                        className={
                          childrenItem.children
                            ? childrenItem.active
                              ? `active ${childrenItem?.count ? "d-flex align-items-center justify-content-between" : ""}`
                              : `${childrenItem?.count ? "d-flex align-items-center justify-content-between" : ""}`
                            : `${childrenItem?.count ? "d-flex align-items-center justify-content-between" : ""}`
                        }
                      >
                        {childrenItem.type === "sub" ? (
                          <a
                            href="#javascript"
                            onClick={() => toggletNavActive(childrenItem)}
                          >
                            {/* <i className="fa fa-circle"></i> */}
                            {props.t(childrenItem.title)}{" "}
                            <i className="fa fa-angle-right pull-right"></i>
                          </a>
                        ) : (
                          ""
                        )}

                        {childrenItem.type === "link" ? (
                          <>
                            <Link style={{ fontSize: "0.8rem" }}
                              to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                              className={childrenItem.active ? "active px-0" : "px-0"}
                              onClick={() => toggletNavActive(childrenItem)}
                            >
                              {/* <i className="fa fa-circle"></i> */}
                              {props.t(childrenItem.title)}{" "}
                            </Link>
                            {childrenItem?.count ? <span className="d-flex align-items-center justify-content-center p-2" style={{ background: "#327ec9", color: "white", borderRadius: "50%", height: "18px", width: "18px", fontSize: "12px", fontWeight: 500 }}>{getCounts(childrenItem?.count)}</span> : null}
                          </>
                        ) : (
                          ""
                        )}
                        {childrenItem.children ? (
                          <ul
                            className={`sidebar-submenu ${childrenItem.active ? "menu-open" : "active"
                              }`}
                          >
                            {childrenItem.children.map(
                              (childrenSubItem, key) => (
                                <li
                                  className={
                                    childrenSubItem.active ? "active" : ""
                                  }
                                  key={key}
                                >
                                  {childrenSubItem.type === "link" ? (
                                    <Link style={{ fontSize: "0.8rem" }}
                                      to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                                      className={
                                        childrenSubItem.active ? "active" : ""
                                      }
                                      onClick={() =>
                                        toggletNavActive(childrenSubItem)
                                      }
                                    >
                                      {/* <i className="fa fa-circle"></i> */}
                                      {props.t(childrenSubItem.title)}
                                    </Link>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              )
                            )}
                          </ul>
                        ) : (
                          ""
                        )}
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
              </li>
            ))}
            <li
              className={`right-arrow ${layout == "rtl"
                ? hideRightArrowRTL
                  ? "d-none"
                  : "hideRightArrowRTL"
                : hideRightArrow
                  ? "d-none"
                  : "hideRightArrow"
                }`}
              onClick={
                configDB.wrapper == "horizontal_sidebar" && layout == "rtl"
                  ? scrollToRightRTL
                  : scrollToRight
              }
            >
              <i className="fa fa-angle-right"></i>
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default translate(Sidebar);
