import React, { useEffect, useState } from "react";
import { Badge } from "reactstrap";
import PaginationComponent from "react-reactstrap-pagination";
import { DisappearedLoading } from "react-loadingg";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import moment from "moment";

const CreditHistory = (props)=>{
  const {merchant_info} = props
  console.log(merchant_info,"merchant_info")

  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [allCredit, setAllCredit] = useState(30);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [wallet, setWallet] = useState([]);
  const [searchData, setSearchData] = useState([]);

  console.log(data, "data")

  const fetchCreditHIstory = ()=>{
    const postData = {
      customer_id: merchant_info._id
    }
    client.post(api.credit_points, postData, (error, response) => {
      console.log(response);
      if (!error) {
          if (typeof response.error !== "undefined") {
              try {
                  if (!response.error) {
                      console.log(response,"response");
                      setData(response.result.credit_data);
                      setSearchData(response.result.credit_data.reverse());
                      setAllCredit(response.result.credit_data.length)
                  } else {
                      // toast.error("Internal error occured.Please contact support.");
                  }
              } catch (e) {
                  // toast.error("Internal error occured.Please contact support.");
              }
          } else {
              // toast.error("Internal error occured.Please contact support.");
          }
      } else {
          // toast.error("Internal error occured.Please contact support.");
      }
    });
  }

  const fetchMerchantWallet = () => {
		const postData = { customer_id: merchant_info._id };
		client.post(api.wallet_details, postData, (error, response) => {
			if (!error) {
				if (typeof response.error !== "undefined") {
					try {
						if (!response.error) {
							console.log(response, "merchant wallet");
							setWallet(response.result.walletData);
              setLoading(false);
						} else {
							console.log(response);
							// setError(true);
						}
					} catch (error) {
						console.log(error);
						// setError(true);
					}
				} else {
					console.log();
					// setError(true);
				}
			} else {
				console.log(response);
				// setError(true);
			}
		});
	};

  const ShowCurrencyNum = (amount, showSymbol) => {
		// const merchantData = JSON.parse(localStorage.getItem("merchant"));
		const merchantData = merchant_info;
		console.log(merchantData,"merchantdata")
		const currency_symbol = merchantData.country.currency_symbol;
		// const currency_symbol = countryData.currency_symbol;

		let getCurrency = !isNaN(parseFloat(amount)) ? amount : 0;

		if (merchantData && merchantData.country) {
			// if (countryData) {
			if (showSymbol && currency_symbol) {
				getCurrency = `${currency_symbol} ${parseFloat(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
			}
			else {
				getCurrency = +`${parseFloat(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
			}
		}

		return getCurrency;
	}

  const getTimeZone = () => {

    try {
        const company = JSON.parse(localStorage.getItem('@_company'));

        if (company && company.timeZone) {
            return company.timeZone;
        } else {
            return "Australia/Sydney";
        }
    } catch {
        return "Australia/Sydney";
    }

  }

  

  const onSearch = (e) => {
    console.log(e.target.value,"searchvalue")
    let value = e.target.value.toLowerCase()
		let result = data && data.filter(ele => ele.unlock_feat_name.includes(value) || ele.credits.includes(value) || ele.balance.includes(value)) 
    setSearchData(result)
  }

  const handlePageChange = (e)=>{
    setPageSize(e.target.value);
  }

  useEffect(()=>{
    fetchMerchantWallet()
    fetchCreditHIstory()
  },[])

  return(
    <div>
      <div className="card-header" style={{ border: "none", padding: "10px 30px" }}>
        <h6>
          Store name : {merchant_info.business_name}, Mobile : {merchant_info.mobile}
        </h6>
      </div>
      {!loading &&<div className="card-body updating-chart height-equal" style={{ padding: "0 25px" }}>
        <div style={{ paddingBottom: 0 }} className="upadates text-center">
          <h2 className="font-primary">
            {/* <DollarSign /> */}
            <span>
              {" "}
              {
                ShowCurrencyNum(wallet?.credit_balance, true)
              }
            </span>
          </h2>
          <p>Credit Balance</p>
        </div>
        {/* <div className="upadates text-center" style={{ paddingBottom: 0 }}>
          <h2 className="font-primary">
            <DollarSign />
            <span> {amountCashback.toFixed(2)} </span>
            <span> {this.ShowCurrencyNum(amountCashback.toFixed(2), true)} </span>
            <span>{ShowCurrencyNum(wallet?.cashback_amount, true)}</span>
          </h2>
          <p>Cashback</p>
        </div> */}
      </div>}
      <div className="card-header" style={{ border: "none", padding: "10px 30px 0" }}>
        <h5 style={{ display: "inline-block" }}>Credit History</h5>
        {activePage * pageSize > allCredit ? (
          <p className="pull-right" style={{ display: "inline-block" }}>
            Showing {(activePage - 1) * pageSize + 1} to {allCredit} of{" "}
            {allCredit} entries

          </p>
        ) : (
          <p className="pull-right" style={{ display: "inline-block" }}>
            Showing {(activePage - 1) * pageSize + 1} to{" "}
            {activePage * pageSize} of {allCredit} entries
          </p>
        )}

      </div>
      <div className="py-2">
        <input
          style={{
            border: "none",
            borderBottom: "1px solid rgb(197, 189, 189)",
            borderRadius: "0",
            margin: "1px 10px",
            fontSize: "0.75rem",
            padding: "0.25rem 0.50rem",
            width: "25%",
          }}
          type="text"
          placeholder="Search "
          onChange={(e) => onSearch(e)}
        />
      </div>
      {
        !loading ? <div className="table-responsive">
        <table className="table table-border-horizontal">
          <thead>
            <tr>
              <th scope="col" style={{ width: "3%" }}>
                #
              </th>
              <th scope="col" style={{ width: "9%" }}>
                Date / Time
              </th>
              <th scope="col" style={{ width: "16%" }}>
                Reference
              </th>
              <th scope="col" style={{ width: "7%" }}>
                Credit
              </th>
              <th scope="col" style={{ width: "7%" }}>
                Debit
              </th>
              <th scope="col" style={{ width: "7%" }}>
                Balance
              </th>
              <th scope="col" style={{ width: "7%" }}>
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {
              searchData?.map((credit,ind)=>{
                console.log(credit,"credit")
                let displayData = JSON.parse(credit.display_data)
                const added_date = moment.tz(credit.created_date, getTimeZone()).format("DD/MM/YYYY HH:mm");
                return(
                  <tr>
                    <td className="border">
                      {ind+1}
                      </td>
                      <td className="border">
                      {added_date}
                      </td>
                      <td className="border">{credit.unlock_feat_name}</td>
                      <td className="border text-success">
                      {/* {credit.credits ? `${Number(credit.credits).toFixed(2)}` : `-`} */}
                      {credit.credits ? ShowCurrencyNum(credit.credits, true) : '-'}
                      </td>
                      <td className="border text-danger">
                      {/* {credit.debit ? `${Number(credit.debit).toFixed(1)}` : `-`} */}
                      {credit.debit ? ShowCurrencyNum(credit.debit, true) : '-'}
                      </td>
                      <td className="border" style={{color: 'blue'}}>
                        {/* {credit.balance ? `${Number(credit.balance).toFixed(2)}` : credit.credits} */}
                        {credit.balance ? ShowCurrencyNum(credit.balance, true) : '-'}
                      </td>
                      <td className="border">
                      <div className="d-flex justify-content-center">
                      <Badge style={{ background: 'green', color: 'white', minWidth: '80px', maxWidth: '80px', display: 'flex', justifyContent: 'center',}}>                                                                 Success
                      </Badge>                                                             </div>
                      </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        {
          searchData.length == 0 && <>
            <div style={{ height: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <p style={{ fontSize: "15px" }}>No record found!</p>
            </div>
          </>
        }
        {searchData.length > 0 && <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0px",
            margin: "1rem 0rem"
          }}
        >
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <select
              className="custom-select"
              style={{ width: "32%", marginRight: "7px" }}
              onChange={(e)=>handlePageChange(e)}
              value={pageSize}
            >
              <option value="15">15</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
            </select>
            <span>Records per Page</span>
          </div>
          <PaginationComponent
            totalItems={allCredit}
            pageSize={pageSize}
            // onSelect={handlePageChange}
            defaultActivePage={activePage}
          />
        </div>}
      </div> :
        <div className="loader-box d-flex align-item-center justify-content-center" style={{ height: "50vh", width: "100wh" }}>
          <DisappearedLoading size="medium" color="#514F4E" style={{ position: "unset" }} />
        </div>
      }
    </div>
  )
}

export default CreditHistory