import React, { Fragment } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import { NavLink } from "react-router-dom";
import catsubcat from "../../CatSubcat";

const businessCategory = catsubcat.map(el=>{
  return el.categoryTitle
});

const subcategory = catsubcat.map(el=>{
  return el.subCategory.map(sub=>{
      return sub.subcatTitle
  }) 
})
/* .reduce((el,nextEl)=>{
  return el.concat(nextEl)
}) */

class PotentialMerchantDetail extends React.Component {

  state = {
    error: null,
    id: this.props.match.params.id,
    isLoaded: false,
    items: [],
    potential_merchant_info: null,
    redirect: false,
    merchant_appr_status: '',
    editProfileInfo:null,
    selectedCategory : '',
    selectedSubCategory:'',
    subCategory : [],
    
      name: '',
      mobile: '',
      email: '',
      business_name: '',
      abn: '',
      landline: '',
      business_category: '',
      business_sub_category: '',
      website: '',
      address: '',
      suburb: '',
      postcode: '',
      dine_in_url: '',
      other_business : '',
      send_mail_modal : false,
      access_level : localStorage.getItem("accessLevel")
  };

  

  changeCategory(event) {
		this.setState({business_category : event.target.value});
		this.setState({subCategory : this.state.categories.find(cat => cat.categoryTitle === event.target.value).subCategory});
    
  }

  componentDidMount() {
    this.getPotentialMerchantDetails();
    this.changeCategory = this.changeCategory.bind(this);
    this.setState({categories:catsubcat})
    //console.log(this.props);

  }

  getPotentialMerchantDetails = () => {
    let { id } = this.state;
    let postdata = { id: id };
    this.setState({ loading: true });
    client.post(api.potential_merchant_details, postdata, (error, response) => {
      if (!error) {
        // alert(JSON.stringify(response));
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              this.setState({ potential_merchant_info: response.result.data });
              //this.setState({ selectedCategory: response.result.data.business_category });
              //this.setState({ selectedSubCategory: response.result.data.business_sub_category });
              //this.setState({editProfileInfo:response.result.data});

              //console.log(response.result.data);

              this.setState({ name: response.result.data.name });
              this.setState({ mobile: response.result.data.mobile });
              this.setState({ email: response.result.data.email });
              this.setState({ business_name: response.result.data.business_name });
              this.setState({ abn: response.result.data.abn });
              this.setState({ landline: response.result.data.landline });
              this.setState({ business_category: response.result.data.business_category });
              this.setState({ business_sub_category: response.result.data.business_sub_category });
              this.setState({ website: response.result.data.website });
              this.setState({ address: response.result.data.address });

              this.setState({ suburb: response.result.data.suburb });

              this.setState({ postcode: response.result.data.postcode });
              this.setState({ dine_in_url: response.result.data.dine_in_url });
              //console.log("business Category =============="+response.result.data.business_category);
              
              try {
                this.setState({ subCategory: response.result.data.business_category ? (this.state.categories.find(cat => cat.categoryTitle === response.result.data.business_category).subCategory) : [] });
              } catch { }
              
              /* try{
                const other_business_data = JSON.parse(response.result.data.other_business);
                const other_business =  other_business_data.map(el=>{
                  return el.business_name
                }).join()
                  
                this.setState({ other_business : other_business  });

              }catch(error){
                this.setState({ other_business : "N.A" });
              } */
              //console.log(response);
            } else {
              setTimeout(() => {
                toast.error(response.message);
              }, 200);
            }
          } catch (e) {
            setTimeout(() => {
              toast.error("Internal error occured. Please contact support1" + e.message);
            }, 200);
            // _showToast.error("Internal error occured. Please contact support.");
            // currentComponent.setState({ errormessage: "Internal error." });
          }
        } else {
          setTimeout(() => {
            toast.error("Internal error occured. Please contact support");
          }, 200);
          //   _showToast.error("Internal error occured. Please contact support.");
        }
      } else {
        setTimeout(() => {
          toast.error("Internal error occured. Please contact support");
        }, 200);
        // alert(JSON.stringify(error));
        // _showToast.error("Internal error occured. Please contact support.");
      }
      this.setState({ loading: false });
    })
  };

update_PotentialMerchant_Details = (event, id) => {
  //const { editProfileInfo } = this.state;
  event.preventDefault();
  //const postData = { _id: id, ...editProfileInfo }

  //console.log(editProfileInfo);
  //console.log(postData);

  let postData = {
    _id: this.state.id,
    name: this.state.name,
    mobile: this.state.mobile,
    email: this.state.email,
    business_name: this.state.business_name,
    abn: this.state.abn,
    landline: this.state.landline,
    business_category: this.state.business_category,
    business_sub_category: this.state.business_sub_category,
    website: this.state.website,
    address: this.state.address,
    suburb: this.state.suburb,
    postcode: this.state.postcode,
    dine_in_url: this.state.dine_in_url
    
  };

    //console.log(postData);
  client.post(api.update_potential_merchant_details, postData, (error, response) => {
      if (!error) {
          if (!response.error) {
              this.refreshPage();
              toast.success("Profile Updated");

          } else {
              setTimeout(() => {
                  toast.error(response.message);
              }, 200);
          }
      } else {
          setTimeout(() => {
              toast.error("Internal error occured. Please contact support");
          }, 200);
      }
  })
}

  redirect_success = () => {
    this.setState({
      redirect: true
    });
  }

 

  refreshPage() {
    window.location.reload(false);
}

send_mail_toggle = (event) => {
  event.preventDefault();
  this.setState(prevState => {
    return {
      ...prevState,
      send_mail_modal: !prevState.send_mail_modal
    }
  })
}

  approval_change = (e, status) => {
    this.setState({
      merchant_appr_status: status
    });

    e.preventDefault();
    let { id } = this.state;
    const { editProfileInfo } = this.state;
    
  //const postData = { _id: id, ...editProfileInfo }

 /*  let postData = {
    _id:this.state.id,
    name:this.state.name ,
    mobile:this.state.mobile ,
    email:this.state.email ,
    business_name:this.state.business_name ,
    abn:this.state.abn ,
    landline:this.state.landline ,
    business_category:this.state.business_category ,
    business_sub_category:this.state.business_sub_category ,
    website:this.state.website ,
    address:this.state.address ,
    suburb:this.state.suburb ,
    postcode:this.state.postcode,
    status: status

}; */
let postdata = { merchant_id: this.state.id };

    let current = this;
    current.setState({ loading: true });

    if (postdata !== '') {
      //setloadingbtn(true);
      client.post(api.webMerc_sendCredentials, postdata, async function (error, response) {
        if (!error) {
          //alert(JSON.stringify(response));
          //   return false;
          if (typeof response.error !== "undefined") {
            try {
              if (!response.error) {
                toast.success("Merchant Approved Successfully");
                current.props.history.push(`${process.env.PUBLIC_URL}/sop/partnerslist`)
               // <Redirect to={`${process.env.PUBLIC_URL}/sop/partnerslist`} />
                //current.refreshPage();

              } else {
                toast.error(response.message);
              }
            } catch (e) {
              //console.log(e);
              toast.error(e.message);
            }
          } else {
            //console.log(response.error);
            toast.error("Internal error occured. Please contact support ");
          }
        } else {
          //console.log(error);

          toast.error("Internal error occured. Please contact support ");
        }
        //setloadingbtn(false);
      });
    }



  }

  refreshPage() {
    window.location.reload(false);
}

changeHandler=(e)=>{
  this.setState({ [e.target.name]: e.target.value });
  
}

  renderApproveModal = () => {
    return (
      <Modal isOpen={this.state.send_mail_modal} toggle={this.send_mail_toggle} className="modal-body" centered={true}>
        <form className="theme-form" noValidate="" >
          <ModalHeader toggle={this.send_mail_toggle} style={{ textAlign: 'center' }}>Approve Potential Merchant</ModalHeader>
          <ModalBody>
            <br />
            <h6>Are you sure you want to approve the potential merchant ?</h6>
            <p style={{ color: "red" }}>Note: An email will be sent with ID & temporary password to the user. User is requested to login into the app and create password to proceed. Thanks!</p>
            <br />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.send_mail_toggle}>Not Sure</Button>
            <Button color="primary" type="submit" onClick={(e) => { this.approval_change(e, 1) }} >Yes Please !</Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }

  
  render() {
    //console.log(catsubcat);
    const { id, potential_merchant_info,editProfileInfo, access_level } = this.state;
    const { name, mobile, email, business_name, abn, landline, business_category, business_sub_category, website, address, suburb, postcode, dine_in_url }=this.state;
    console.log(potential_merchant_info);
    

    return (
      <Fragment>
      <div className="col-lg-7 col-sm-12" style={{fontSize:"12px"}}>
        <Breadcrumb title={"Potential merchants"} parent={"Potential Merchants"} /></div>
        {id ? (
          <div className="container-fluid">
                <div className="row">
                  {this.renderApproveModal()}

                  <div className="col-sm-12">
                    <div className="card">

                      <div className="card-body">
                        {potential_merchant_info ?
                          <form className="theme-form" onSubmit={(event) => this.update_PotentialMerchant_Details(event, id)} >
                          
                            <div className="form-group row">
                              <label className="col-sm-3 col-form-label">Owner/Manager Name:</label>
                              <div className="col-sm-9">
                                <input className="form-control"  type="text" placeholder="Owner/Manager Name" name="name"
                                value={name} onChange={this.changeHandler} disabled={ access_level == 1 ? true : false}
                                />
                                <input type="hidden" name="merchant_id"
                                // value={merchant_info._id}
                                ></input>
                                <input type="hidden" name="approval_status"
                                // value={this.state.approval_status}
                                ></input>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-3 col-form-label">Mobile no. :</label>
                              <div className="col-sm-9">
                                <input className="form-control"  type="text" placeholder="Mobile no." name="mobile"
                                value={mobile} onChange={this.changeHandler} disabled={ access_level == 1 ? true : false}/>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-3 col-form-label">Email</label>
                              <div className="col-sm-9">
                                <input className="form-control" type="email" placeholder="Email" name="email"
                                value={email} onChange={this.changeHandler} disabled={ access_level == 1 ? true : false}/>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-3 col-form-label" htmlFor="business_name">Business Name</label>
                              <div className="col-sm-9">
                                <input className="form-control" id="business_name" type="text" placeholder="Business Name"
                                value={business_name} onChange={this.changeHandler} name="business_name" disabled={ access_level == 1 ? true : false}/>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-3 col-form-label">ABN</label>
                              <div className="col-sm-9">
                                <input className="form-control"type="text" placeholder="ABN" name="abn"
                                value={abn}  onChange={this.changeHandler} disabled={ access_level == 1 ? true : false}/>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-3 col-form-label">Landline No.</label>
                              <div className="col-sm-9">
                                <input className="form-control"  type="text" placeholder="Landline No."
                                value={landline}  onChange={this.changeHandler} name="landline" disabled={ access_level == 1 ? true : false}/>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-3 col-form-label">Business Category</label>
                              <div className="col-sm-9">
                                <select className="form-control" name="business_category" 
                                value={business_category} onChange={this.changeCategory} disabled={ access_level == 1 ? true : false}>                                  
                                  {this.state.categories.map((e, key) => {
                                    return <option key={key}>{e.categoryTitle}</option>;
                                  })}
                                </select>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-3 col-form-label">Business Sub-Category</label>
                              <div className="col-sm-9">
                              <select className="form-control" name="business_sub_category" 
                                  value={business_sub_category} onChange={this.changeHandler} disabled={ access_level == 1 ? true : false}>                           
                                {this.state.subCategory.map((e, key) => {
                                  return <option key={key}>{e.subcatTitle}</option>;
                                })}
                              </select>
                              </div>
                            </div>


                            
                            <div className="form-group row">
                              <label className="col-sm-3 col-form-label">Website</label>
                              <div className="col-sm-9">
                                <input className="form-control" type="text" placeholder="Website"
                                value={website}  onChange={this.changeHandler} name="website" disabled={ access_level == 1 ? true : false}/>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-3 col-form-label">Address</label>
                              <div className="col-sm-9">
                                <input className="form-control" type="text" placeholder="Address"
                                value={address}  onChange={this.changeHandler} name="address" disabled={ access_level == 1 ? true : false}/>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-3 col-form-label">Suburb</label>
                              <div className="col-sm-9">
                                <input className="form-control" type="text" placeholder="Suburb"
                                value={suburb}  onChange={this.changeHandler} name="suburb" disabled={ access_level == 1 ? true : false}/>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-3 col-form-label">Postcode</label>
                              <div className="col-sm-9">
                                <input className="form-control" type="text" placeholder="Postcode"
                                value={postcode}  onChange={this.changeHandler} name="postcode" disabled={ access_level == 1 ? true : false}/>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-3 col-form-label">Dine In URL</label>
                              <div className="col-sm-9">
                                <input className="form-control" type="text" placeholder="Dine In URL"
                                value={dine_in_url}  onChange={this.changeHandler} name="dine_in_url" disabled={ access_level == 1 ? true : false}/>
                              </div>
                            </div>

                              <div className="card-footer">
                              {/* <button className="btn btn-success mr-1" id="approve"
                                onClick={this.send_mail_toggle} disabled={ access_level == 1 ? true : false}
                              >Approve</button> */}
                               <button className="btn btn-success" style={{marginRight:"20px"}} onClick={this.send_mail_toggle} disabled={ access_level == 1 ? true : false}>Send  ID/Password</button>
                              <button className="btn btn-secondary mr-1" id="approve" type="submit" disabled={ access_level == 1 ? true : false}>Update</button>
                            </div>
                          </form>
                          :
                          null
                        }
                      </div>

                    </div>
                  </div>
                </div>
              </div>

        ) : (
            <Redirect to={`${process.env.PUBLIC_URL}/sop/partnerslist`} />
          )}
      </Fragment>
    );
  }
}

export default PotentialMerchantDetail;
