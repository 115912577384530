import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import Icon from "@mdi/react";
import { mdiInformation, mdiContentCopy, mdiOpenInNew } from '@mdi/js';
import { Link } from 'react-router-dom';
import EyeIcon from 'mdi-react/EyeIcon';
import client from "../../Api/HTTPClient"
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import Dinein from "./Dinein";
import { Table } from 'reactstrap';
import moment from 'moment-timezone';
import PaginationComponent from "react-reactstrap-pagination";
import { DisappearedLoading } from "react-loadingg";
import "./bookingToggle.scss";

const Bookings = ({ merchant_id, unique_id, merchant_info, workingTimeData }) => {
    //   console.log(merchant_id,'merchant Id in bookings')
    //   console.log(unique_id,'merchant Id in bookings')
    //   console.log(merchant_info,'merchant_info')
    const [acceptBooking, setAcceptBooking] = useState(workingTimeData?.bookingStatus);
    const [redirectLink, setRedirectLink] = useState(null);
    const [booking, setBooking] = useState([])
    const [activePage, setActivePage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    //   console.log(booking,'booking')
    //   console.log(acceptBooking,'acceptBooking')

    const ToggleOnclick = (event) => {

        setAcceptBooking(event.target.checked)
        let postData = { merchant_id: merchant_id, bookingStatus: event.target.checked, message: "" };
        client.post(api.update_booking_status, postData, (error, response) => {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            toast.success('Booking status updated!');
                        } else {
                            toast.error('Internal error occured.Please contact.')
                        }
                    } catch (error) {
                        toast.error('Internal error occured.Please contact.')
                    }
                } else {
                    toast.error('Internal error occured.Please contact.')
                }
            } else {
                toast.error('Internal error occured.Please contact.')
            }
        });
        return;
    }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    }

    useEffect(() => {
        if (workingTimeData) {
            setAcceptBooking(workingTimeData.bookingStatus);
        }

    }, [workingTimeData])

    useEffect(() => {
        let activePg = Number(activePage)
        let pgSize = Number(pageSize)
        let arr = booking?.slice((activePg - 1) * pgSize, ((activePg - 1) * pgSize) + pgSize)
        setData(arr)
    }, [activePage, pageSize, booking])
    // console.log((activePage - 1) * pageSize, (activePage - 1) * (+pageSize + (+pageSize)), 'ap')

    useEffect(() => {
        const postData = {
            merc_unique_id: unique_id,
            merchant_id: merchant_id,
            redirect_url: ""
        }
        client.post(api.booking_details, postData, (error, response) => {
            try {
                if (!response.error) {
                    setBooking(response.result.data);
                    setLoading(false)
                } else {
                    toast.error("Internal error occured.Please contact.")
                }
            } catch (err) {
                toast.error('Internal error occured.Please contact.')
            }
        })
    }, [])

    useEffect(() => {

        if (typeof window !== 'undefined') {
            if (window.location.hostname == "dm.admin.ipaypro.co" || window.location.hostname == "dm.portal.linky.biz") {
                setRedirectLink(`https://dm.linky.biz/${unique_id}/booking`)
            } else if (window.location.hostname == "admin.ipaypro.co" || window.location.hostname == "portal.linky.biz") {
                setRedirectLink(`https://linky.biz/${unique_id}/booking`)
            } else {// update host as per need 
                setRedirectLink(`http://localhost:3000/${unique_id}/booking`)
            }
        }

    }, [redirectLink])

    const getTimeZone = () => {

        try {
            const company = JSON.parse(localStorage.getItem('@_company'));

            if (company && company.timeZone) {
                return company.timeZone;
            } else {
                return "Australia/Sydney";
            }
        } catch {
            return "Australia/Sydney";
        }

    }

    return (
        <>
            <Tabs className="col-sm-12" defaultIndex={0} style={{ padding: "0" }}>
                <TabList className="tabs tab-title" style={{ marginBottom: '0px !important' }}>
                    <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}>Bookings List</Tab>
                    <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}>Dine-in</Tab>
                </TabList>
                <TabPanel>
                    <div className="m-2 table_reservation_heading_block">
                        <div className="d-flex justify-content-between">
                            <div>
                                <p className='m-0 font-weight-bold' style={{ fontSize: '16px', color: 'black' }}> Table Reservation Preview Link </p>
                                <p className='m-0 mob-font'>Share above table reservation link with customers on social media or by directly sending them a link</p>
                            </div>
                            <div className='toggle_button pl-xl-3 pl-0 my-xl-0 my-2  d-flex align-items-center' style={{ gap: 10 }}>
                                <p style={{ alignSelf: 'center', color: '#646777', position: 'relative', top: '3px' }}>
                                    {acceptBooking ? 'Accepting table booking' : 'Not accepting table booking'}
                                    <span id="MenuModeToolTip" className="ml-1" style={{ position: 'relative', top: '5px', left: '5px' }}>
                                        <Icon
                                            path={mdiInformation}
                                            size={0.8}
                                            color="#BEBEBE"
                                        />
                                    </span>
                                </p>
                                <label className="switch booking_toggle">
                                    <input
                                        className="toggle-btn__input border"
                                        type="checkbox"
                                        checked={acceptBooking}
                                        onClick={ToggleOnclick}
                                    />
                                    <div className={`slider sliderCustom  round opposite`} >
                                        <span className="on"></span>
                                        <span className="off"></span>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className='d-flex flex-md-row flex-column align-items-start '>
                            {redirectLink != "" && <div className="border rounded pb-1 px-3 mr-3 d-block copi_link d-flex align-items-center">
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h5 className="my-0 mr-xl-4 mr-2" style={{ color: 'grey', fontSize: '1rem', paddingTop: '2px' }}>{redirectLink}</h5>
                                    {/* <h5 className="my-0 mr-4" style={{ color: '#45C7A2' }}> {redirectLink} </h5> */}

                                    <div className="mt-2">
                                        <a className='ml-xl-5 ml-2' href={redirectLink} target='_blank' style={{ position: 'relative', top: '1px' }}>
                                            <Icon
                                                path={mdiOpenInNew}
                                                className="mx-3"
                                                size={0.9}
                                                color="gray"
                                            />
                                        </a>

                                        <span id="link-copy1212">
                                            <Icon
                                                path={mdiContentCopy}
                                                size={0.8}
                                                color="gray"
                                            />
                                        </span>
                                        <br />
                                    </div>
                                </div>
                            </div>}
                        </div>
                        <div>
                            {!loading ?
                                <div className="my-4">
                                    <Table className="table table-border-horizontal">
                                        <thead>
                                            <tr style={{ background: '#f9fafe' }}>
                                                <th>#</th>
                                                <th>Date of enquiry</th>
                                                <th>Reservation date/time</th>
                                                <th>Booking ID / Customer detail</th>
                                                <th>Status</th>
                                                <th>Payment</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data?.length > 0 ?
                                                    data.map((val, ind) => {
                                                        const date = moment.tz(val.updatedAt, getTimeZone()).format('DD/MM/YYYY');
                                                        const time = moment.tz(val.updatedAt, getTimeZone()).format('hh:mm A');
                                                        const trans = String(val.transcation)
                                                        
                                                        let getDate = moment(val.date).format('DD-MM-YYYY');
                                                        let setDateAndTime = moment(getDate + ' ' + val.time)

                                                        let currentTime = moment().format('DD-MM-YYYY h:mm A');
                                                        let statusToShow = "";
                                                        if (val.status.value == 2 || val.status.value == 3) {
                                                            statusToShow = val.status.label
                                                        } else if (val.status.value == 0 && moment(currentTime).isAfter(setDateAndTime)) {
                                                            statusToShow = "Expired"
                                                        } else if (val.status.value == 1 && moment(currentTime).isAfter(setDateAndTime)) {
                                                            statusToShow = "Fulfilled"
                                                        }else{
                                                            statusToShow = val.status.label
                                                        }
                                                        

                                                        return (
                                                            <tr>
                                                                <td>{(activePage - 1) * pageSize + ind + 1}</td>
                                                                <td>{`${date} ${time}`}</td>
                                                                <td>{`${moment.tz(val.date, getTimeZone()).format('DD/MM/YYYY')} ${val.time}`}</td>
                                                                <td>
                                                                    <div style={{ width: "18rem" }}>
                                                                        <p className="text-capitalize">ID: {trans.substring(trans.length - 6)}</p>
                                                                        <p className="text-capitalize">Name: {val.customerDetail.name}</p>
                                                                        <p>Email id: {val.customerDetail.email}</p>
                                                                        <p>Mobile: {`${val.customerDetail.mobile_prefix.label} ${val.customerDetail.mobile}`} </p>
                                                                        <p>Number of guests: {val.totalGuest} </p>
                                                                    </div>
                                                                </td>
                                                                {/* <td>{val.status.value == 0 ? 'Pending' : val.status.value == 1 ? 'Confirmed' : val.status.value == 3 ? 'Cancelled' : 'Rejected'}</td> */}
                                                                <td>{statusToShow}</td>
                                                                <td>{val.paymentEnb == 0 || (val.paymentEnb == 1 && val.payMthd === "Free Booking") ? "Free Booking"
                                                                    : val.merBook && val.payMthd == 'Others' ? val.other_payment_method
                                                                        : val.merBook ? val.payMthd.toLowerCase() == 'ipaypro' ? "Linky"
                                                                            : val.payMthd
                                                                            : val.payMthd}
                                                                    {val.paid == 1 && val.payMthd && val.payMthd.toLowerCase() == "cash" ? ` (` + parseFloat(val.transDetail.chargeamt).toFixed(2) + ')' : (val.payMthd && val.payMthd.toLowerCase() == "free booking" ? '' : ` (${merchant_info.country.currency_symbol} ${val.total.toFixed(2)})`)}
                                                                    {val.paid == 1 && <p style={{color:'green'}}>Paid</p>}
                                                                </td>
                                                            </tr>
                                                        )
                                                    }) : null
                                            }
                                        </tbody>
                                    </Table>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <select className="custom-select" style={{ width: "35%", fontSize: "0.63rem", marginRight: "10px" }} onChange={(event) => setPageSize(event.target.value)} value={pageSize}>
                                                <option value="15">15</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="75">75</option>
                                                <option value="100">100</option>
                                            </select>
                                            <span style={{ fontSize: "0.63rem" }}>Records per Page</span>
                                        </div>
                                        <PaginationComponent
                                            totalItems={booking.length}
                                            pageSize={pageSize}
                                            onSelect={handlePageChange} />
                                    </div>
                                </div>
                                :
                                <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                                    <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                                        <DisappearedLoading size="medium" color="#514F4E" />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <Dinein merchant_info={merchant_info} />
                </TabPanel>
            </Tabs>
            {/* <div className="m-2">
    <div  className="d-flex justify-content-between">
        <div>
            <p className='m-0 font-weight-bold' style={{ fontSize: '16px', color: 'black' }}> Table Reservation Preview Link </p>
            <p className='m-0 mob-font'>Share above table reservation link with customers on social media or by directly sending them a link</p>
        </div>
        <div className='toggle_button pl-xl-3 pl-0 my-xl-0 my-2  d-flex align-items-center' style={{ gap: 10 }}>
            <p style={{ alignSelf: 'center', color: '#646777', position:'relative',top:'3px'}}>
                {acceptBooking ? 'Accepting table booking' : 'Not accepting table booking'}
                <span id="MenuModeToolTip" className="ml-1" style={{position:'relative',top:'5px',left:'5px'}}>
                    <Icon
                        path={mdiInformation}
                        size={0.8}
                        style={{ color: "#BEBEBE" }}
                    />
                </span>
            </p>
            <label className="switch booking_toggle">
                <input
                    className="toggle-btn__input border"
                    type="checkbox"
                    checked={!acceptBooking}
                    onClick={ToggleOnclick}
                />
                <div className={`slider sliderCustom  round opposite`} >
                    <span className="on"></span>
                    <span className="off"></span>
                </div>
            </label>
        </div>
    </div>
  <div className='d-flex flex-md-row flex-column align-items-start '>
  {redirectLink != "" && <div className="border rounded pb-1 px-3 mr-3 d-block copi_link d-flex align-items-center">
      <div className='d-flex align-items-center justify-content-between'>
          <h5 className="my-0 mr-xl-4 mr-2" style={{ color: 'grey',fontSize:'1rem', paddingTop:'2px' }}>{redirectLink}</h5>

          <div className="mt-2">
              <a className='ml-xl-5 ml-2' href={redirectLink} target='_blank' style={{position:'relative',top:'1px'}}>
                  <Icon
                      path={mdiOpenInNew}
                      className="mx-3"
                      size={0.9}
                      color="gray"
                  />
              </a>

              <span id="link-copy1212">
                  <Icon
                      path={mdiContentCopy}
                      size={0.8}
                      color="gray"
                  />
              </span>
              <br />
          </div>
        </div>
        </div>}
    </div>
    </div> */}
        </>
    )
}

export default Bookings