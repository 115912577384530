import React, { Fragment, Component, useRef } from 'react';
import { Redirect, NavLink, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { DollarSign } from "react-feather";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup, Col, Row, FormFeedback, CustomInput } from 'reactstrap';
import PaginationComponent from "react-reactstrap-pagination";
import moment from "moment-timezone";

import Breadcrumb from '../../components/common/breadcrumb';
import { DisappearedLoading } from "react-loadingg";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import RenderTransactions from "./RenderTransactions";
import RenderCashbacks from "./RenderCashbacks";
import AddcashbackModal from "../cashbacks/AddcashbackModal";
import Dropdown from 'react-bootstrap/Dropdown';
// import C2MTransactions from './C2M';
// import C2CTransactions from './C2C';
// import M2MTransactions from './M2M';
// import M2CTransactions from './M2C';
// import FlaggedTransactions from './FlaggedTransaction';


// Imagine you have a list of languages that you'd like to autosuggest.
const languages = [
    {
        name: 'C',
        year: 1972
    },
    {
        name: 'Elm',
        year: 2012
    },
];


const getSuggestionValue = suggestion => suggestion.name;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
    <div>
        {suggestion.name}
    </div>
);

class Transactions extends Component {

    state = {
        customers: [],
        merchants: [],
        transactions: [],
        cashbacks: [],
        error: null,
        tabIndex: 0,
        deleteSelectedModal: false,
        selectedIDs: [],
        searchValue: null,
        selectedId: null,
        searchedData: null,
        access_level: localStorage.getItem("accessLevel"),
        adminUser: JSON.parse(localStorage.getItem("user")),
        cards: null,
        topupModal: false,
        receiptModal: false,
        receiptDetails: null,
        customersData: null,
        searchUserFilter: "name",
        filteredUserData: [],
        all_customers: [],
        selectedReceiver: null,
        authorizeModal: false,
        isOpen: false,
        imageSrc: null,
        topup: { topup_type: "Direct deposit" },
        authorizeRejectReason: "",
        authorizeReject: false,
        cashbackModal: false,
        pageSize: 25,
        activePage: 1,
        btnloading: false,
        btnloading: false,

        filter_type: "all",
        filter_status: "pending",

        suggestions: [],
        value: "",
        account_type: 1,
        tab_count: { all: 0, mtm: 0, mtc: 0, ctm: 0, ctc: 0, flagged: 0, cashback: 0 },

        country: [],
        countryTabIndex: 0,
        transactionSubTabNo: 2,
        defaultCountryCode: localStorage.getItem("sessionCountryCode"),
        testLoading: false,
    }

    transMyRef = React.createRef();

    componentDidMount() {
        this.getAllTransactionsCount(this.state.defaultCountryCode);
        this.getAllData();
        this.fetchCountries();
    }

    toggleDeleteSelectedModal = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                deleteSelectedModal: !prevState.deleteSelectedModal
            }
        })
    }

    getAllTransactionsCount = (countryCode) => {
        // console.log(countryCode, 'countryCode')
        let { filter_status, filter_type } = this.state;
        let postdata = { filter_type, filter_status, countryCode };

        client.post(api.tab_counts, postdata, (error, response) => {
            if (!error) {
                // console.log(response, "tab_counts");
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            this.setState({ tab_count: response.result.data })
                        }
                    } catch (e) { }
                }
            }
        });
    };



    getAllData = () => {
        const postdata = { type: 1 };

        client.post(api.getCustMerchData, postdata, (error, response) => {
            if (!error) {
                if (!response.error) {
                    // console.log(response);
                    const filteredUserData = response.result.customers.filter(el => {
                        return el.status == 1 || el.status == 2
                    });
                    const allData = [...response.result.customers, ...response.result.merchants]
                    this.setState({ customersData: allData, filteredUserData: filteredUserData, all_customers: filteredUserData })
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);

                }

            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        });
    }

    refreshPage() {
        window.location.reload(false);
    }


    changeCountryTab = async (currCountry) => {

        this.setState({ defaultCountryCode: currCountry });
        this.getAllTransactionsCount(currCountry)
        try {
            this.transMyRef.current.getAllTransactions(this.state.transactionSubTabNo, 1, currCountry);
        } catch { }
    }

    moveElement(array) {

        let arrangeCountry = [...array];

        let australia = arrangeCountry.map((e) => e.country_code).indexOf('AU');
        if (australia != -1) {
            arrangeCountry.splice(0, 0, arrangeCountry.splice(australia, 1)[0]);
        }

        let japanIdx = arrangeCountry.map((e) => e.country_code).indexOf('JP');
        if (japanIdx != -1) {
            arrangeCountry.splice(1, 0, arrangeCountry.splice(japanIdx, 1)[0]);
        }

        let thailand = arrangeCountry.map((e) => e.country_code).indexOf('TH');
        if (thailand != -1) {
            arrangeCountry.splice(2, 0, arrangeCountry.splice(thailand, 1)[0]);
        }

        let unitedState = arrangeCountry.map((e) => e.country_code).indexOf('US');
        if (unitedState != -1) {
            arrangeCountry.splice(3, 0, arrangeCountry.splice(unitedState, 1)[0]);
        }

        let india = arrangeCountry.map((e) => e.country_code).indexOf('IN');
        if (india != -1) {
            arrangeCountry.splice(4, 0, arrangeCountry.splice(india, 1)[0]);
        }

        return arrangeCountry;
    }


    fetchCountries = () => {

        let current = this;
        client.post(api.fetch_country, { show_all: true }, async function (error, response) {

            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {

                            let getCountryCode = [];
                            let arrangeCountry = [...response.result.data];
                            arrangeCountry = await current.moveElement(arrangeCountry);
                            current.setState({ country: arrangeCountry });

                            arrangeCountry.length > 0 && arrangeCountry.map((countryData) => {
                                getCountryCode.push(countryData.country_code)
                                return countryData;
                            });


                            // current.getCustomersCountByCountry(getCountryCode);
                        }
                    } catch (err) {
                        // console.log(err, "fetch error")
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }
        });
    }


    deleteSelectedClick = (event, id) => {

        if (event.target.checked) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    selectedIDs: [...prevState.selectedIDs, id]
                }
            });

        } else {
            if (this.state.selectedIDs.includes(id)) {
                const idsToKeep = this.state.selectedIDs.filter(el => {
                    return el != id
                })
                this.setState(prevState => {
                    return {
                        ...prevState,
                        selectedIDs: idsToKeep
                    }
                })
            } else {
                return;
            }
        }
    }

    deleteSelectedButtonClick = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                deleteSelectedModal: !prevState.deleteSelectedModal,
            }
        });
    }

    renderDeleteSelectedModal = () => {
        return (

            <Modal isOpen={this.state.deleteSelectedModal} toggle={this.toggleDeleteSelectedModal} className="modal-body" centered={true}>
                <form className="theme-form" noValidate="" onSubmit={this.deleteSelectedHandler}>
                    <ModalBody>
                        <h4>Do you really want to delete these transactions ?</h4>
                    </ModalBody>
                    <ModalFooter>

                        <Button color="default" onClick={this.toggleDeleteSelectedModal}>Not sure</Button>
                        <Button color="primary" type="submit"  >Yes, please!</Button>
                    </ModalFooter>
                </form>
            </Modal>

        );
    }

    deleteSelectedHandler = (event) => {
        event.preventDefault();
        const postData = { selectedIDs: this.state.selectedIDs }
        // console.log(postData);
        client.post(api.delete_selected_transaction, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    this.refreshPage();
                    toast.success(response.message)

                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        })

    }

    receiptClick = (details) => {
        this.setState(prevState => {
            return {
                ...prevState,
                receiptModal: !prevState.receiptModal,
                receiptDetails: details
            }
        })
    }

    toggleReceiptModal = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                receiptModal: !prevState.receiptModal
            }
        })
    }

    renderReceiptModal = () => {
        return (
            <Modal isOpen={this.state.receiptModal} toggle={this.toggleReceiptModal} className="modal-body" centered={true}>
                <form className="theme-form" noValidate="" >
                    <ModalHeader>Receipt Details</ModalHeader>
                    <ModalBody style={{ textAlign: "center" }}>
                        <h6>{this.state.receiptDetails}</h6>
                        <Button className="btn btn-danger" style={{ width: "100%", textAlign: "center", fontSize: "1.1rem", marginTop: "10px" }} onClick={this.toggleReceiptModal}>Dismiss</Button>
                    </ModalBody>

                </form>
            </Modal>
        );
    }

    handleChange = (selectedOptions) => {
        // console.log(selectedOptions);
        if (selectedOptions.length > 0) {

            this.setState(prevState => {
                return {
                    ...prevState,
                    selectedReceiver: selectedOptions,
                    topup: {
                        ...prevState.topup,
                        ipay_id: selectedOptions[0].ipay_id

                    }
                }
            })
        }

    }

    imageClicked = (src) => {
        //console.log("clicked");
        this.setState({ isOpen: true, imageSrc: src })
    }

    toggleTopupModal = () => {
        this.setState(prevState => {
            return {
                topupModal: !prevState.topupModal,
            }
        });
    }


    refreshcashbacktransactions = () => {
        let postdata = { fetch_cashbacks: true };

        client.post(api.cashback_transactions, postdata, (error, response) => {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            this.setState({ cashbacks: response.result.cashbacks });
                        }
                        else {
                            setTimeout(() => {
                                toast.error(response.message);
                            }, 200);
                        }
                    } catch (e) {
                        setTimeout(() => {
                            toast.error("Internal error occured. Please contact support");
                        }, 200);
                    }
                } else {
                    setTimeout(() => {
                        toast.error("Internal error occured. Please contact support");
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        });
    };

    toggleCashbackModal = (reload = 0) => {
        this.setState({
            cashbackModal: !this.state.cashbackModal,
        });
        if (reload == 1) {
            this.refreshcashbacktransactions();
        }
    }

    renderTopupModal = () => {

        const { value, suggestions } = this.state;

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: 'Type a programming language',
            value,
            onChange: this.onChange
        }

        const { filteredUserData, searchUserFilter, customersData, selectedReceiver, topup } = this.state;
        return (
            <Modal isOpen={this.state.topupModal} toggle={this.toggleTopupModal}>
                <Form onSubmit={this.onTopupAdd}>
                    <ModalHeader toggle={this.toggleTopupModal}>Add Topup</ModalHeader>
                    <ModalBody>
                        {/* <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="ipay_id">i-Pay ID</Label>
                                    <Input type="text" id="ipay_id" name="ipay_id" required
                                        placeholder="i-Pay ID" onChange={this.onAddTopuChange} />
                                </FormGroup>
                            </Col>
                        </Row> */}
                        <div className="row" style={{ margin: "0" }}>
                            <div className="col">
                                <div className="form-group col-l-6">
                                    <label>To</label>
                                    <select className="form-control" name="from" defaultValue="customer_name" onChange={this.onToFilterSelect}>
                                        <option value='customer_name'>Customer name </option>
                                        <option value='merchant_name'>Merchant name</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group col-l-6 ">
                                    <label>To</label>
                                    <Typeahead
                                        id="public-typeahead"
                                        // defaultSelected={customersData.slice(0, 0)}
                                        labelKey={searchUserFilter}
                                        options={filteredUserData}
                                        placeholder="User detail"
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row receiver" style={{ marginBottom: "15px" }}>
                            <div className="col-md-12">
                                {selectedReceiver && this.renderReceiverDetails(selectedReceiver)}
                            </div>

                        </div>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="exampleSelect">Topup type</Label>
                                    <Input type="select" name="topup_type" id="exampleSelect" defaultValue="Direct deposit" onChange={this.onAddTopuChange}>
                                        <option value="Direct deposit">Direct deposit</option>
                                        <option value="Pay ID">Pay ID</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="amount">Amount</Label>
                                    <Input type="text" id="amount" name="amount" required
                                        placeholder="Amount" onChange={this.onAddTopuChange} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="receipt">Receipt</Label>
                                    <Input type="file" id="receipt" name="receipt" disabled={topup != null && topup.receipt_details != "" && typeof (topup.receipt_details) != "undefined" ? true : false}
                                        placeholder="Receipt" onChange={this.onAddTopuChange} required={topup != null && topup.receipt_details == "" || topup != null && typeof (topup.receipt_details) == "undefined" ? true : false} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <h5>OR <span style={{ color: "red", fontSize: "0.9rem" }}>(Note:You can either attach receipt or can enter the details.)</span></h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="receipt_details">Paste your your receipt details below</Label>
                                    <Input type="text" id="receipt_details" name="receipt_details" disabled={topup != null && topup.receipt != "" && typeof (topup.receipt) != "undefined" ? true : false}
                                        placeholder="Receipt" onChange={this.onAddTopuChange} required={topup != null && topup.receipt == "" || topup != null && typeof (topup.receipt) == "undefined" ? true : false} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="description">Description</Label>
                                    <Input type="input" id="description" name="description" disabled
                                        placeholder="Description" value={selectedReceiver ? selectedReceiver[0].ipay_id : ""} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="comments">Additional comments</Label>
                                    <Input type="text" id="comments" name="description"
                                        placeholder="Additional comments" onChange={this.onAddTopuChange} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" onClick={this.toggleTopupModal}>Cancel</Button>
                        <Button color="primary" type="submit">Submit</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }

    onAddTopuChange = (event) => {
        const { name, value } = event.target;

        if (event.target.type == "file" && event.target.files.length != 0) {
            let mimeType = event.target.files[0].type;

            if (mimeType.match(/(jpeg|jpg|png|pdf)$/) == null) {
                return;
            }
            // Image upload
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = (_event) => {
                let imageData;
                //this.setState({ url: reader.result });
                imageData = reader.result;
                imageData = imageData.split(",")[1];

                let image_data = { mime: mimeType, data: imageData }
                // console.log(image_data);
                this.setState(prevState => {
                    return {
                        ...prevState,
                        topup: {
                            ...prevState.topup,
                            [name]: image_data
                        }
                    }
                })
            }
        } else {
            this.setState(prevState => {
                return {
                    ...prevState,
                    topup: {
                        ...prevState.topup,
                        [name]: value
                    }

                }
            })
        }

    }

    onTopupAdd = (event) => {
        event.preventDefault();
        const postData = this.state.topup;

        client.post(api.createTopupTransaction, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    this.refreshPage();
                    // console.log(response);
                    toast.success(response.message);
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);

                }

            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        });


    }

    render_date = (add_date) => {
        if (typeof (add_date) === "undefined") {
            return;
        }

        const convert_date = new Date(add_date)
        const added_date = moment.tz(convert_date, "Australia/Sydney").format("DD/MM/YYYY HH:mm:ss");
        return added_date;
    }

    onToFilterSelect = (event) => {
        const { searchUserFilter } = this.state;
        let updatedData = [];

        if (event.target.value == "mobile") {
            const filteredUserData = this.state.customersData.map(el => {
                return el
            });
            this.setState({ searchUserFilter: "mobile", filteredUserData: filteredUserData })
        } else if (event.target.value == "email") {
            const filteredUserData = this.state.customersData.map(el => {
                return el
            });
            this.setState({ searchUserFilter: "email", filteredUserData: filteredUserData })
        } else if (event.target.value == "customer_name") {
            const filteredUserData = this.state.customersData.filter(el => {
                return (el.type == 1 && el.status == 1) || (el.type == 1 && el.status == 2)
            })
            this.setState({ filteredUserData: filteredUserData, searchUserFilter: "name" });

        } else if (event.target.value == "merchant_name") {
            const filteredUserData = this.state.customersData.filter(el => {
                return el.type == 2 && el.status == 1 && el.mobile_status == 1
            })
            this.setState({ filteredUserData: filteredUserData, searchUserFilter: "business_name" });

        } else {
            this.setState({ searchUserFilter: "" })
        }
    }

    renderReceiverDetails = (selectedReceiver) => {
        // const { selectedReceiver } = this.state;

        return (
            <div className="table-responsive">
                <table className="table table-border-horizontal">
                    <thead>
                        <tr style={{ background: '#f9fafe' }}>
                            <th scope="col" >#</th>
                            <th scope="col" >Name</th>
                            <th scope="col" >i-Pay ID</th>
                            <th scope="col" >Email</th>
                            <th scope="col" >Mobile</th>
                            <th scope="col" >Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedReceiver.map((item, index) => {
                            return (
                                <tr >
                                    <th scope="row" >{index + 1}</th>
                                    <td >{item.displayName}</td>
                                    <td >{item.ipay_id}</td>
                                    <td >{item.email}</td>
                                    <td >{item.mobile}</td>
                                    <td >{item.country.currency_symbol}{item.wallet.length > 0 ? item.wallet[0].wallet_amount.toFixed(2) : 0}</td>
                                </tr>

                            );
                        })}


                    </tbody>
                </table>
            </div>
        )
    }

    authorizeTopupClick = (id) => {
        if (localStorage.getItem("userId") == id) {
            toast.error("Not allowed.")
        } else {
            this.setState(prevState => {
                return {
                    ...prevState,
                    selectedId: id,
                    authorizeModal: !prevState.authorizeModal,
                    authorizeReject: false
                }
            });
        }

    }

    toggleAuthorizeModal = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                authorizeModal: !prevState.authorizeModal,
                authorizeReject: false
            }
        });
    }

    authorizeTopup = (event) => {
        event.preventDefault();
        const { adminUser, authorizeReject, authorizeRejectReason } = this.state;

        const postData = {
            transactionId: this.state.selectedId, authorized_by: adminUser.full_name,
            authorizeReject: authorizeReject ? true : false, description: authorizeRejectReason
        };

        this.setState({ btnloading: true })
        client.post(api.authorizeTopup, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    this.refreshPage();
                    //console.log(response);
                    toast.success(response.message);
                    this.setState({ btnloading: false })
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);

                }
                this.setState({ btnloading: false })

            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
            this.setState({ btnloading: false })
        });

    }

    onRejectTopupClick = (event) => {
        event.preventDefault();
        if (!this.state.authorizeReject) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    authorizeReject: true

                }
            })
        } else {
            this.authorizeTopup()
        }
    }

    renderAuthorizeModal = () => {
        const { authorizeModal, authorizeReject } = this.state;
        const style = {
            outline: "none",
            ':focus': {
                outlineColor: "black"
            },
            fontSize: "1rem",
            padding: "2px 7px"
        };

        return (
            <Modal isOpen={authorizeModal} toggle={this.toggleAuthorizeModal} centered={true}>
                <form className="theme-form" onSubmit={this.authorizeTopup} >
                    {!authorizeReject && <ModalHeader toggle={this.toggleAuthorizeModal}><h5 style={{ fontSize: "1.15rem" }}>Authorize Topup</h5></ModalHeader>}
                    {authorizeReject && <ModalHeader toggle={this.toggleAuthorizeModal}><h5 style={{ fontSize: "1.15rem" }}>Reject Topup</h5></ModalHeader>}
                    <ModalBody>
                        <ModalBody style={{ padding: "16px 0" }}>
                            {!authorizeReject && <h5 style={{ fontSize: "1.15rem" }}>Are you sure you want to authorize the topup ?</h5>}
                            {authorizeReject &&
                                <div>
                                    <h5 style={{ fontSize: "1.15rem" }}>Are you sure you want to reject the topup ?</h5>
                                    <input style={style} type="text" size="40" onChange={(event) => this.setState({ authorizeRejectReason: event.target.value })}
                                        placeholder="Please enter comment for your action"
                                        required></input>
                                </div>}
                        </ModalBody>
                        <ModalFooter>

                            {authorizeReject && <Button color="primary" type="submit" onClick={this.toggleAuthorizeModal}>Cancel</Button>}
                            {!authorizeReject ? <Button color="default" onClick={this.onRejectTopupClick}>Reject, Please!</Button>
                                : <Button color="default" type="submit">Reject, Please!</Button>}
                            {!authorizeReject && <Button color="primary" type="submit" disabled={this.state.btnloading}>Yes, Please!</Button>}
                        </ModalFooter>
                    </ModalBody>
                </form>
            </Modal>
        );
    }


    setTransactionSubTabNo = (tab) => {
        this.setState({ transactionSubTabNo: tab })
    }


    /*     toggle = () => {
            setModal(!modal)
        }
    
        toggle2 = () => {
            setModal2(!modal2)
        }
        toggle3 = () => {
            setModal3(!modal3)
        } */

    render() {
        const {
            transactions,
            merchants,
            customers,
            cards,
            access_level,
            adminUser,
            customersData,
            selectedReceiver,
            cashbacks,
            tab_count,
            country,
            defaultCountryCode,
        } = this.state
        // console.log(transactions);
        // console.log(this.props.location.state, "trans _id");
        return (
            <Fragment>
                {this.renderTopupModal()}
                {this.state.receiptDetails && this.renderReceiptModal()}
                {this.state.selectedId && this.renderAuthorizeModal()}
                {this.renderDeleteSelectedModal()}
                <div className="row">
                    <div className="col-lg-7 col-sm-12" style={{ fontSize: "12px" }}>
                        <Breadcrumb title={"transactions"} parent={"Home"} />
                    </div>
                    <div className="col-lg-5 col-sm-12 ">
                        <div className="btn-group" style={{ paddingTop: "10px", marginLeft: "0.4rem", marginRight: "0.6rem", marginBottom: "0rem", width: "auto", display: "flex", justifyContent: "flex-end", alignItems: "center" }} role="group" aria-label="Basic example">
                            {access_level != 1 && <button className="btnCashback custBtnAdmin" onClick={this.toggleCashbackModal} ><DollarSign style={{ margin: "0 7px 0 0", width: "0.8rem", height: "0.8rem", color: "#327ebc" }}></DollarSign>Send cashback</button>}

                            {access_level != 2 && <button className="btnTopup custBtnAdmin" onClick={this.toggleTopupModal} ><DollarSign style={{ margin: "0 7px 0 0", width: "0.8rem", height: "0.8rem", color: "#327ebc" }}></DollarSign>Top-up</button>}

                            {/* {access_level == 3 && <button className="btn custBtnAdmin"
                                onClick={this.deleteSelectedButtonClick} disabled={this.state.selectedIDs.length == 0 ? "disabled" : null}>{this.state.selectedIDs.length == 0 ? <i style={{ color: "#327ebc", fontSize: "15px", margin: "0 7px 0 0" }} id="delete" title="delete" className="fa fa-trash-o"></i> : <i style={{ color: "#327ebc", fontSize: "15px", margin: "0 7px 0 0" }} id="delete" title="delete" className="fa fa-trash-o"></i>}Delete selected</button>} */}
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    {
                        this.state.testLoading ? <>
                            <div>Loading page</div>
                        </> :
                            <div className="row theme-tab">

                                {
                                    country.length > 0 && <Tabs
                                        className="col-sm-12 merchant_req"
                                        // defaultIndex={this.state.countryTabIndex}
                                        style={{
                                            backgroundColor: "white",
                                            borderRadius: "3px",
                                            border: "1px solid rgb(223, 232, 241)",
                                            borderBottom: "none",
                                            marginBottom: "0px",
                                        }}
                                        onSelect={(index) => { this.setState({ countryTabIndex: index }) }}
                                    >

                                        <TabList className="tabs tab-title" style={{ borderBottom: "none" }}>
                                            {/* <div
                                        style={{ display: "flex", alignItems: "center", padding: "10px 15px" }}
                                        onClick={() => this.changeCountryTab('All')}
                                        className={defaultCountryCode == "All" ? 'selectedTabStyle' : 'tabStyle'}
                                    >
                                        All
                                    </div> */}
                                            {
                                                country.length > 0 && country.slice(0, 5).map((countryData) => {

                                                    return (
                                                        <>
                                                            <div
                                                                className={countryData.country_code == defaultCountryCode ? 'selectedTabStyle' : 'tabStyle'}
                                                                style={{ display: "flex", alignItems: "center", padding: "10px 15px" }}
                                                                onClick={() => this.changeCountryTab(countryData.country_code)}
                                                            >
                                                                {countryData.country_name}
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }

                                            <Dropdown style={{ margin: "auto 0px" }}>
                                                <Dropdown.Toggle
                                                    id=""
                                                    className={this.state.countryTabIndex == 6 ? 'selectDropDownBtnStyle' : 'dropDownBtnStyle'}
                                                    style={{ color: "#000" }}
                                                >
                                                    Others
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {
                                                        country.length > 5 && country.slice(5, country.length).map((countryData) => {

                                                            return (
                                                                <>
                                                                    <Dropdown.Item className={countryData.country_code == defaultCountryCode ? 'selectedDropDownStyle' : "dropDownStyle"} onClick={() => this.changeCountryTab(countryData.country_code)}>
                                                                        {countryData.country_name}
                                                                    </Dropdown.Item>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </TabList>
                                    </Tabs>
                                }

                                <Tabs className="col-sm-12" defaultIndex={this.state.tabIndex} onSelect={index => this.setState({ tabIndex: index })} style={{ padding: "0", backgroundColor: "white", borderRadius: "3px", border: "1px solid rgb(223, 232, 241)", marginBottom: "0px" }} >
                                    <TabList className="tabs tab-title">
                                        <Tab style={{ display: "flex", alignItems: "center" }}>
                                            Online Transactions({tab_count.all})
                                        </Tab>

                                        {/* <Tab style={{ display: "flex", alignItems: "center" }}>
                                    C2M({tab_count.ctm})
                                </Tab>

                                <Tab style={{ display: "flex", alignItems: "center" }}>
                                    C2C({tab_count.ctc})
                                </Tab>

                                <Tab style={{ display: "flex", alignItems: "center" }}>
                                    M2M({tab_count.mtm})
                                </Tab>

                                <Tab style={{ display: "flex", alignItems: "center" }}>
                                    M2C({tab_count.mtc})
                                </Tab> */}

                                        <Tab style={{ display: "flex", alignItems: "center" }}>
                                            Flagged({tab_count.flagged})
                                        </Tab>

                                        <Tab style={{ display: "flex", alignItems: "center" }}>
                                            Cashback({tab_count.cashback})
                                        </Tab>
                                    </TabList>

                                    {/* {transactions.length != 0 ? */}
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-sm-12 col-xl-12 xl-100">
                                                <div className="card">
                                                    <div className="card-body" style={{ padding: "1rem" }}>
                                                        <div className="tab-content-cls">
                                                            <TabPanel>
                                                                <RenderTransactions
                                                                    filter_type={'all'}
                                                                    // fetchTransaction={this.getAllTransactions}
                                                                    deleteSelectedClick={this.deleteSelectedClick}
                                                                    imageClicked={this.imageClicked}
                                                                    receiptClick={this.receiptClick}
                                                                    // transactions={transactions}
                                                                    // customers={customers}
                                                                    // merchants={merchants}
                                                                    hideReason={false}
                                                                    // cards={cards}
                                                                    // access_level={access_level}
                                                                    // adminUser={adminUser}
                                                                    authorizeTopupClick={this.authorizeTopupClick}
                                                                    defaultCountryCode={defaultCountryCode}
                                                                    ref={this.transMyRef}
                                                                    activeTab={this.state.transactionSubTabNo}
                                                                    setTransactionSubTabNo={this.setTransactionSubTabNo}
                                                                    queryData={this.props.location.state}

                                                                />
                                                            </TabPanel>

                                                            {/* <TabPanel>
                                                        <RenderTransactions
                                                            filter_type={'c_to_m'}

                                                            deleteSelectedClick={this.deleteSelectedClick}
                                                            imageClicked={this.imageClicked}
                                                            receiptClick={this.receiptClick}
                                                            hideReason={false}
                                                            authorizeTopupClick={this.authorizeTopupClick}
                                                            defaultCountryCode={defaultCountryCode}
                                                            ref={this.transMyRef}
                                                        />

                                                    </TabPanel>

                                                    <TabPanel >

                                                        <RenderTransactions
                                                            filter_type={'c_to_c'}
                                                            deleteSelectedClick={this.deleteSelectedClick}
                                                            imageClicked={this.imageClicked}
                                                            receiptClick={this.receiptClick}
                                                            hideReason={false}
                                                            authorizeTopupClick={this.authorizeTopupClick}
                                                            defaultCountryCode={defaultCountryCode}
                                                            ref={this.transMyRef}
                                                        />
                                                    </TabPanel>

                                                    <TabPanel >
                                                        <RenderTransactions
                                                            filter_type={'m_to_m'}
                                                            deleteSelectedClick={this.deleteSelectedClick}
                                                            imageClicked={this.imageClicked}
                                                            receiptClick={this.receiptClick}
                                                            hideReason={false}
                                                            authorizeTopupClick={this.authorizeTopupClick}
                                                            defaultCountryCode={defaultCountryCode}
                                                            ref={this.transMyRef}
                                                        />
                                                    </TabPanel>

                                                    <TabPanel >
                                                        <RenderTransactions
                                                            filter_type={'m_to_c'}
                                                            deleteSelectedClick={this.deleteSelectedClick}
                                                            imageClicked={this.imageClicked}
                                                            receiptClick={this.receiptClick}
                                                            hideReason={false}
                                                            authorizeTopupClick={this.authorizeTopupClick}
                                                            defaultCountryCode={defaultCountryCode}
                                                            ref={this.transMyRef}
                                                        />
                                                    </TabPanel> */}

                                                            <TabPanel >
                                                                <RenderTransactions
                                                                    filter_type={'flagged'}
                                                                    deleteSelectedClick={this.deleteSelectedClick}
                                                                    imageClicked={this.imageClicked}
                                                                    receiptClick={this.receiptClick}
                                                                    hideReason={false}
                                                                    authorizeTopupClick={this.authorizeTopupClick}
                                                                    defaultCountryCode={defaultCountryCode}
                                                                    ref={this.transMyRef}
                                                                    activeTab={this.state.transactionSubTabNo}
                                                                    setTransactionSubTabNo={this.setTransactionSubTabNo}
                                                                    queryData={this.props.location.state}
                                                                />
                                                            </TabPanel>

                                                            <TabPanel >
                                                                <RenderCashbacks
                                                                    render_date={this.render_date}
                                                                    defaultCountryCode={defaultCountryCode}
                                                                    queryData={this.props.location.state}
                                                                />
                                                                {/* {this.renderCashbackTransactions()} */}
                                                            </TabPanel>

                                                            {this.state.isOpen && (
                                                                <Lightbox
                                                                    mainSrc={this.state.imageSrc}
                                                                    onCloseRequest={() => this.setState({ isOpen: false })}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* : <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                              <div className="loader" style={{ display:"flex",alignItems:"center" }}>
                              <DisappearedLoading size="medium" color="#514F4E" />
                               </div> 
                               </div>} */}
                                </Tabs>
                            </div>
                    }
                </div>
                <AddcashbackModal isOpen={this.state.cashbackModal} all_customers={this.state.all_customers} renderReceiverDetails={this.renderReceiverDetails} toggleModal={this.toggleCashbackModal} />
            </Fragment>
        );
    }
}

export default Transactions;