import React,{Fragment} from 'react';
import logo from "../assets/images/ipay-logo-40-40.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import client from "../Api/HTTPClient";
import api from "../constant/apilist";
import {withRouter} from "react-router-dom";

class ChangePswrd extends React.Component {
    state={
        newpassword:"",
        retypepassword:""
    }

    onChange = (ev) => {
        this.setState({
          [ev.target.name]: ev.target.value
        });
        // this.props.onRevertToInitialState()
      }
    formSubmit = (ev) =>{
        ev.preventDefault();
        const {newpassword, retypepassword  } =  this.state;
        console.log(newpassword,"new");
        console.log(retypepassword,"retype");
        // console.log(this.props.match.params.id);
        const postdata={
            id: this.props.match.params.id,
            newpassword:this.state.newpassword
        }
        console.log(postdata,"postdata")
        if(newpassword==retypepassword){
            setTimeout(() => {
                toast.success("password matched");
              },200); 
            client.post(api.admin_changePassword,postdata,(error,response) =>{
                if(!error){
                  if(!response.error)
                  {
                    setTimeout(()=>{
                      toast.success("Password changed successfully"); 
                    },200);
                    console.log("response:",response)
                    this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                    
                  }
                  else
                  {
                    toast.error("Something went wrong")
                  }
                }
                else
                {
                    setTimeout(() => {
                        toast.error("Password didnt changed ")
                      }, 200);
                }
            })
        }
        else{
            setTimeout(() => {
                toast.error("password not matched")
              }, 200); 
            
        }
    }

    render(){
    return (
        <Fragment>
           <div className="page-wrapper">
            {/* <!-- login page start--> */}

            <div className="container-fluid p-0">

              <div className="authentication-main">
                <div className="row">
                  <div className="col-md-12">
                    <div className="auth-innerright">
                      <div className="authentication-box">
                        <div className="text-center">
                          <img src={logo} alt="" />
                        </div>
                        <div className="card mt-4">
                          <div className="card-body">
                          <form onSubmit={this.formSubmit}>
                            <div className="text-center">
                              <h4>Change Password</h4>
                            </div>

                            <div className="form-group">
                              <label className="col-form-label">New Password</label>
                              <input
                                className="form-control"
                                type="password"
                                name="newpassword"
                                placeholder="Enter new password"
                                value={this.state.newpassword}
                                onChange={this.onChange}
                              />
                              {/* {errors.password && 'Email is required'} */}
                            </div>
                              {/* {errors.email && 'Email is required'} */}
                            <div className="form-group">
                              <label className="col-form-label"> Retype Password</label>
                              <input
                                className="form-control"
                                type="password"
                                name="retypepassword"
                                placeholder="Retype password"
                                value={this.state.retypepassword}
                                onChange={this.onChange}
                              />
                              {/* {errors.password && 'Email is required'} */}
                            </div>
                            <div className="form-group form-row mt-3 mb-0">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit">
                                Change Password
                              </button>
                            </div>
                            </form>
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
                            <ToastContainer />
                            </div>
                            </div>

        </Fragment>
    )
}
}

export default withRouter(ChangePswrd)
