import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import client from "../../Api/HTTPClient";
import Customerbillings from "./customerbillings";
import OtherCharges from "./OtherCharges";
import Invoices from "./Invoices";
import { DisappearedLoading } from 'react-loadingg';
import './settings.scss'
import api from "../../constant/apilist";
import RolesTable from "./rolesTable";
import { ToastContainer, toast } from "react-toastify";
// import { Link as NavLink } from "react-router-dom";
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';

function Rolespage() {

  let [loading, setloading] = useState(false);
  let [roles, setroles] = useState([]);

  window.onchageInput = (roleName, code, option_code) => {

    // alert("here")

		// console.log(code, "code")
    // console.log(option_code, "option_code")
    

    let new_roles = roles.map(row => {
      if (row.roleName == roleName) {

        let accessibleFeatures = row.accessibleFeatures.map(af => {
          if (af.optionCode == code) {
            let options = af.action.map(op => {
              if (op.code == option_code) {
                // console.log(op.check, "op.check")
                return {
                  ...op,
                  check : !op.check
                }
              } else {
                return op
              }
            })
            // console.log(options, "options")

            return {
              ...af,
              action : options
            }
            
          } else {
            return af
          }
        })
        // if (accessibleFeatures) {
        //   let option = accessibleFeatures.action.find()
        // }
        // if(row.)
        return {
          ...row,
          accessibleFeatures
        }
      } else {
        return row
      }
    })

    // console.log(new_roles, "new_roles")

    setroles(new_roles)
		
		
	}

  const saveRoles = () => {

    setloading(true)
    
    client.post(api.updateDefaultRoles, {roles}, async function (
      error,
      response
    ) {
      if (!error) {
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              // setroles(response.result.data)
              
              toast.success("Updated Successfully");
              
            }
          } catch (e) { }
        }
      }
      setloading(false)
    });
  }

  const get_detault_roles = () => {

    client.put(api.getAllRoles, {}, async function (
      error,
      response
    ) {
      if (!error) {
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              setroles(response.result.data)
              
            }
          } catch (e) { }
        }
      }
      setloading(false)
    });
  }

  useEffect(() => {
    get_detault_roles();
  }, [])

 

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-7 col-sm-12" style={{ fontSize: "12px" }}>
          <Breadcrumb title={"Billing setting"} parent={"Home"} />
        </div>
      </div>

      <div className="container-fluid">
        <div className="">
          <div className="card" style={{ border: '1px solid rgb(223, 232, 241)', borderRadius: '3px', padding: "1.5rem" }}>
            <div className="card-header">
              <h6  className="font-weight-bold" style={{ cursor: 'pointer' }}>
                {/* <i className="icon-arrow-left" style={{ color: "black", paddingRight: '5px' }}></i> */}
                Default Roles
                <button disabled={loading} className="btn btn-primary pull-right" onClick={()=>saveRoles()} type="button">SAVE</button>
                
              </h6>
            </div>
            <div className="card-body" style={{  }}>
              <RolesTable Roles={roles} toggleEdit={() => { }} toggleDelete={() => { }} />
          
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
  // }
}

export default Rolespage;
