import React, { useState,Fragment,useEffect, useRef, forwardRef } from "react";
import api from "../../constant/apilist";
import client from "../../Api/HTTPClient";
import {Table, Badge, Button} from 'reactstrap';
import moment from "moment";
import Icon from "@mdi/react";
import PaginationComponent from "react-reactstrap-pagination";
import DatePicker from "react-datepicker";
import { DisappearedLoading } from "react-loadingg";
import {
  mdiEye,
} from "@mdi/js";
import RenderDateSelectField from "./RenderDataSelectField.jsx";
import DateRangePicker from "./DateRangePicker.jsx";
import ViewTransaction from "./ViewTransaction.js";
import {mdiCalendarMonthOutline} from "@mdi/js";
import "./portfolio.css"


const RequestPayments = ({merchant_info,merchant_id})=>{
  const [data, setData] = useState(0)
  const [requestData, setRequestData] = useState();
  const [pageSize, setPageSize] = useState(15);
  const [pageIndex,setPageIndex] = useState(1);
  const [searchInputVal,setSearchInputVal] = useState(null);
  const [filterDuration, setFilterDuration] = useState('show all');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [activePage, setActivePage] = useState(1);
  const [viewTransaction,setViewTransaction] = useState(false);
  const [transactionData,setTransactionData] = useState({
    merchant_id: merchant_id,
    wallet_amount: '',
    data: null,
    view_type: 0,
    disabled_refund: '',
  });
  const [loading,setLoading] = useState(true);

 


  const fetchPaymentLinksData = (searchValue) => {
    const postData = {
      merchant_id: merchant_id,
      request_from: 8,
      pageSize,
      pageIndex: activePage - 1,
      dateFilterType: filterDuration.value,
      startDate,
      endDate,
      paidOnly: true,
      searchInputVal: searchValue == "reset" ? null : searchInputVal,
    }
    client.post(api.link_requests, postData, (error, response) => {
        if (!error) {
            if (!response.error) {
                // console.log(response,'request-response')
                setRequestData(response.result);
                setData(response.recorsCount);
                setLoading(false);
            } else {
                console.log(error, 'error')
            }
        } else {
            console.log(error,'error-1')
        }
    });
  };

  useEffect(()=>{
    fetchPaymentLinksData();
  },[pageIndex, pageSize, filterDuration, startDate, endDate, activePage])

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber)
    fetchPaymentLinksData();
  }

  const handleSearch = (event) => {
    event.preventDefault();
    if(searchInputVal==null || searchInputVal==""){
        fetchPaymentLinksData("reset");
    }
    fetchPaymentLinksData(searchInputVal);
  }

  const getTimeZone = () => {

      try {
        const company = JSON.parse(localStorage.getItem('@_company'));
    
        if (company && company.timeZone) {
          return company.timeZone;
        } else {
          return "Australia/Sydney";
        }
      } catch {
        return "Australia/Sydney";
      }
    
    }  

  const ShowCurrencyNum = (amount, showSymbol) => {
    const merchantData = JSON.parse(localStorage.getItem("merchant"));
    const currency_symbol = merchantData?.country?.currency_symbol;
    if (amount == undefined) {
      return currency_symbol;
    }

    let getCurrency = !isNaN(parseFloat(amount)) ? amount : 0;

    if (merchantData && merchantData.country) {
      if (showSymbol && currency_symbol) {
        if (getCurrency < 0) {
          getCurrency = `-${currency_symbol}${Math.abs(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
        } else {
          getCurrency = `${currency_symbol}${parseFloat(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
        }
      }
      else {
        getCurrency = +`${parseFloat(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
      }
    }

    return getCurrency;
  }

  const RenderRefundStatus = ({ refund_data }) => {

    if (refund_data) {
        
        return (
            <>
                {refund_data.type == 1 ?
                    <Badge color="disabled" style={{width:'80%',display:'flex',justifyContent:'center'}}>Refunded</Badge>
                    :
                    // <p>
                    //     Partially refunded ({ShowCurrencyNum(refund_data?.amount, true)})
                    // </p>
                    <div className='d-flex flex-column align-items-center'>
                        <Badge color="disabled" 
                            style={{
                                width:'80%',
                                display:'flex',
                                justifyContent:'center',
                            }}
                        >
                            Partially refunded
                        </Badge>
                        <span>({ShowCurrencyNum(refund_data?.amount, true)})</span>
                    </div>
                    
                    
                }
            </>
        )
    } else {
        return null
    }
  }

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="d-flex" style={{border:'1px solid #ccc',width:'fit-content',borderRadius:'5px'}}>
        <div className="d-flex">
        <Icon path={mdiCalendarMonthOutline} size={1} color={"#646777"} style={{ cursor: "pointer", margin:'0.4rem 0.49rem' }} />
        </div>
        <div style={{borderRight:'1px solid #ccc'}}></div>
        <button className="example-custom-input" onClick={onClick} ref={ref} style={{background:'transparent',border:'none'}}>
            {value}
        </button>
    </div>
  ));

  return(
    <Fragment>
      {viewTransaction ? 
        <ViewTransaction data={transactionData}  merchant_id={merchant_info?._id}  countryData={merchant_info?.country} onBackClick={() => setViewTransaction(!viewTransaction)} />
      :
      
        <div>
           {loading && <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                    <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                        <DisappearedLoading size="medium" color="#514F4E" />
                    </div>
                </div>}
           <div className="m-4 d-flex justify-content-between align-items-center">
        <div className="d-flex">
            <RenderDateSelectField
                name="filterByDuration"
                className="bg-white "
                style={{ minWidth: filterDuration.value == 'custom_date'
                ? '20vw' : filterDuration.value == 'custom_range'
                    ? '16vw' : '28vw', width: 'auto' }}
                placeholder='Show All'
                value={filterDuration}
                onChange={(event) => {
                    setFilterDuration(event);
                    setPageIndex(1)
                }}
                options={[
                    { value: "showall", label: 'Show All' },
                    { value: "today", label: `Today ${moment.tz(getTimeZone()).format("ll")}` },
                    { value: "yesterday", label: `Yesterday, ${moment.tz(getTimeZone()).subtract(1, "days").format("ll")}` },
                    { value: "this_week", label: `This week, ${moment.tz(getTimeZone()).startOf("week").format("ll") + " to " + moment.tz(getTimeZone()).endOf("week").format("ll")}` },
                    { value: "this_month", label: `This month, ${moment.tz(getTimeZone()).startOf("month").format("ll") + " to " + moment.tz(getTimeZone()).endOf("month").format("ll")}` },
                    { value: "custom_date", label: `Custom date ` },
                    { value: "custom_range", label: `Custom range` },
                ]}
            />

            <div className="mx-3">
            {
                filterDuration.value === "custom_date" ?
                    <div className="date-picker pl-2">
                        <style>
                            {`
                              .date-custom-picker {
                                height:38px;
                              }
                              .date-custom-picker input {
                                height:38px;
                              }
                              `}
                        </style>

                        <DatePicker
                            placeholderText="Select Date"
                            // startDate={startDate}
                            selected={startDate}
                            dateFormat="dd/MM/yyyy"
                            // maxDate={new Date()}
                            onChange={(date) => {
                                setStartDate(date)
                                setPageIndex(1)
                            }}
                            wrapperClassName="date-custom-picker w-50"
                            popperPlacement="right"
                            style={{border:'none'}}
                            customInput={<CustomInput />}
                        />
                    </div>
                    : filterDuration.value === "custom_range" ?
                        <div className="">
                            <DateRangePicker
                                startDate={startDate}
                                endDate={endDate}
                                onStartDateChange={(date) => {
                                    setStartDate(date)
                                    setPageIndex(1)
                                }}
                                onEndDateChange={(date) => {
                                    setEndDate(date)
                                    setPageIndex(1)
                                }}
                            />
                        </div>
                        : null
            }
        </div>
        </div>

        {/* <div className="mx-3">
            {
                filterDuration.value === "custom_date" ?
                    <div className="date-picker pl-2">
                        <style>
                            {`
                              .date-custom-picker {
                                height:38px;
                              }
                              .date-custom-picker input {
                                height:38px;
                              }
                              `}
                        </style>

                        <DatePicker
                            placeholderText="Select Date"
                            // startDate={startDate}
                            selected={startDate}
                            dateFormat="dd/MM/yyyy"
                            // maxDate={new Date()}
                            onChange={(date) => {
                                setStartDate(date)
                                setPageIndex(1)
                            }}
                            wrapperClassName="date-custom-picker w-50"
                            popperPlacement="top"
                            style={{border:'none'}}
                        />
                    </div>
                    : filterDuration.value === "custom_range" ?
                        <div className="ml-3">
                            <DateRangePicker
                                startDate={startDate}
                                endDate={endDate}
                                onStartDateChange={(date) => {
                                    setStartDate(date)
                                    setPageIndex(1)
                                }}
                                onEndDateChange={(date) => {
                                    setEndDate(date)
                                    setPageIndex(1)
                                }}
                            />
                        </div>
                        : null
            }
        </div> */}

        <div className="d-flex justify-content-end">
              <div className="p-2">
                        <form onBlur={handleSearch} onSubmit={(event) => handleSearch(event)}>
                            <div >
                                <input style={{
                                    border: "none",
                                    borderBottom: "1px solid rgb(197, 189, 189)",
                                    borderRadius: "0",
                                    margin: "3px 10px",
                                    fontSize: "0.75rem",

                                }}
                                    type="text"
                                    placeholder="Search"
                                    onChange={(e)=>{
                                      setSearchInputVal(e.target.value)
                                      fetchPaymentLinksData(searchInputVal)
                                    }} 
                                />
                            </div>
                        </form>
                    </div>

            <select
                name="page_size"
                className="custom-select"
                style={{
                    width: "5vw",
                    fontSize: "1rem",
                    height: '35px',
                    borderRadius: '0'
                }}
                onChange={evnt => {
                    setPageIndex(1)
                    setPageSize(evnt.currentTarget.value)
                }}
            >
                <option style={{ fontSize: "1rem" }} value='15'>15</option>
                <option style={{ fontSize: "1rem" }} value='25'>25</option>
                <option style={{ fontSize: "1rem" }} value='50'>50</option>
            </select>
        </div>
    </div>
      <div className="table-responsive mx-2 px-2 my-1 py-1">
        <Table className="table table--border-horizontal">
          <thead>
            <tr>
              <th className="border">#</th>
              <th className="border">Date/Time</th>
              <th className="border">Payment No</th>
              <th className="border">Customer Details</th>
              <th className="border">Payment Method</th>
              <th className="border">Amount</th>
              <th className="border">Status</th>
              <th className="border">Action</th>
            </tr>
          </thead>
          { 
            requestData?.length ? (
              <tbody>
                {
                  requestData.map((item,ind)=>{
                    let recordIndex = pageSize*(activePage-1)+(ind+1);
                    const convert_date = new Date(item.request_date);
                    const added_date = moment.tz(convert_date,getTimeZone()).format("DD/MM/YYYY HH:mm");
                    let dueDate = new Date(item.request_date);
                        dueDate.setDate(dueDate.getDate() + 60);
                        dueDate = moment.tz(dueDate, getTimeZone()).format("DD/MM/YYYY");

                    let customerDetails = 'NA';
                    let cusData = item.ticket_Cus;
                      if(cusData){
                        if (cusData.name && cusData.name.length) {
                          customerDetails = `Name - ${cusData.name.substring(0, 1).toUpperCase()}${cusData.name.substring(1)}`
                      }

                      if (cusData.mobile && cusData.mobile.length) {
                          customerDetails = `${customerDetails}\nMobile - ${cusData?.mobile_prefix || ''}${cusData.mobile}`
                      }

                      if (cusData.email && cusData.email.length) {
                          customerDetails = `${customerDetails}\nEmail - ${cusData.email}`
                      }

                      if (cusData.date_of_birth && cusData.date_of_birth.length) {
                          customerDetails = `${customerDetails}\nDOB - ${cusData.date_of_birth}`
                      }
                    }

                        return(
                          <tr key={ind}>
                              <td className="border">{recordIndex}</td>
                              <td className="border">
                                  {`${added_date}`}
                              </td>
                              <td className="border">
                                  {`PG-${item.token_id}`}
                              </td>
                              <td className="border" style={{ whiteSpace: 'pre' }}>
                                  {customerDetails}
                              </td>
                              <td className="border"> {item.status == 1 ? item.payment_method == 3 ? `Cash` : item.payment_method == 2 ? "Card" : "IpayPro" : '-'}</td>
                              <td className="border"> {item.amount > 0 ? `${ShowCurrencyNum(item.amount.toFixed(2), true)}` : `-`} </td>
                              <td className="border">
                                  {item.refund_status == 1 ? (
                                      <div className="d-flex justify-content-center">
                                          <RenderRefundStatus
                                              refund_data={item?.refund_data}
                                          />
                                      </div>
                                  ) : (
                                      <div>
                                          {item.status == 0 ? (
                                              <div className="d-flex justify-content-center">
                                                  <Badge style={{
                                                      background:'#FFBD57', 
                                                      color:'white',
                                                      width:'80%',    
                                                      display: 'flex',
                                                      justifyContent: 'center',
                                                      }}>Unpaid</Badge>
                                              </div>
                                          ) : item.status == 1 ? (
                                              <div className="d-flex justify-content-center">
                                                  <Badge style={{background:'green', color:'white', width:'80%', display: 'flex',
                                                      justifyContent: 'center',}}>Paid</Badge>
                                              </div>
                                          ) : item.status == 2 ? (
                                              <span
                                                  style={{
                                                      backgroundColor: "transparent",
                                                      borderRadius: "20px",
                                                      color: "red",
                                                  }}
                                              >
                                                  Expired
                                              </span>
                                          ) : item.status == 3 ? (
                                              <span
                                                  style={{
                                                      backgroundColor: "transparent",
                                                      borderRadius: "20px",
                                                      color: "red",
                                                      cursor: "pointer",
                                                  }}
                                                  // onClick={() =>
                                                  //     handleSuspicious(
                                                  //         item.suspiciousReason
                                                  //     )
                                                  // }
                                              >
                                                  Marked Suspicious
                                              </span>
                                          ) : null}
                                      </div>
                                  )}
                              </td>
                              <td className="border">
                                <div className="m-2 d-flex justify-content-center">
                                    {
                                        item.payment_method !== 3 ? item.status == 1 ? 
                                            <Button
                                                outline
                                                style={{
                                                    padding: "4px 6px",
                                                }}
                                                className="d-flex align-items-start m-0"
                                                color="success"
                                                onClick={()=>{
                                                  setViewTransaction(!viewTransaction)
                                                  setTransactionData({
                                                      merchant_id: merchant_id,
                                                      wallet_amount: '',
                                                      data: item,
                                                      view_type: 0,
                                                      disabled_refund: '',
                                                  })
                                              }}
                                            >
                                                <Icon path={mdiEye} className="mx-auto" size={0.6} color="success" />
                                            </Button>
                                            : null : null
                                    }
                                </div>
                            </td>
                          </tr>
                        )
                  })
                }
              </tbody>
            ):
              <h6 style={{ textAlign: "center", marginTop: "1rem" }}> No records found! </h6>
            
          }
        </Table>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <select className="custom-select" style={{ width: "35%", fontSize: "0.63rem", marginRight: "10px" }} onChange={(event) => setPageSize(event.target.value)} value={pageSize}>
                    <option value="15">15</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                </select>
                <span style={{ fontSize: "0.63rem" }}>Records per Page</span>
            </div>
            <PaginationComponent
                totalItems={data}
                pageSize={pageSize}
                onSelect={handlePageChange} 
            />
        </div>
      </div>
        </div>
      }
    </Fragment>
  )
}

export default RequestPayments