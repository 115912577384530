import React, { Fragment, useState, useEffect, useLayoutEffect } from "react";
import { mdiMagnify } from "@mdi/js";
import { GrFormClose } from "react-icons/gr";
import Icon from "@mdi/react";

const SearchBarInput = ({ searchValue, setSearchValue, clearSearch }) => {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const updateDimensions = () => {
        setScreenWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);


    return (
        <>
            <form className="form-inline search-form" onSubmit={(event) => { event.preventDefault() }}            >
                <div className="" style={{ position: "relative", width: screenWidth <= 1400 ? "100%" : '30rem' }}>
                    <div
                        className="searchIcon"
                        style={{ width: '100%', background: '#fff', borderRadius: '3px', height: '42px', display: 'flex' }}
                    >
                        <input
                            className=""
                            type="text"
                            style={{ paddingLeft: 15, width: "100%" }}
                            placeholder="Search..."
                            value={searchValue}
                            onChange={(event) => { setSearchValue(event.target.value) }}
                        />
                        {searchValue != "" ? (
                            <div className="search_icon" style={{ position: "absolute", top: "12px", right: "10px" }}									>
                                <GrFormClose color={"#000000"} size={19} style={{ cursor: "pointer" }} onClick={() => { clearSearch() }} className="mt-0" />
                            </div>
                        ) : (
                            <div
                                className="search_icon"
                                style={{ position: "absolute", top: "11px", right: "10px" }}
                            >
                                <Icon path={mdiMagnify} color="gray" title="Search" size={0.8} className="mt-0" />
                            </div>
                        )}
                    </div>
                </div>
            </form>
        </>
    )
}

export default SearchBarInput;








