const One = "/assets/images/linky-biz-images/linky-icons/1.png";
const Two = "/assets/images/linky-biz-images/linky-icons/2.png";
const Three = "/assets/images/linky-biz-images/linky-icons/3.png";
const Four = "/assets/images/linky-biz-images/linky-icons/4.png";
const Five = "/assets/images/linky-biz-images/linky-icons/5.png";
const Six = "/assets/images/linky-biz-images/linky-icons/6.png";

const BusinessCardSmall1 = "/assets/images/linky-biz-images/linky-icons/Default/BusinessCardSmall1.svg";
const BusinessCardSmall2 = "/assets/images/linky-biz-images/linky-icons/Default/BusinessCardSmall2.svg";
const BusinessCardSmall3 = "/assets/images/linky-biz-images/linky-icons/Default/BusinessCardSmall3 CL-White.svg";
const BusinessCardSmall4 = "/assets/images/linky-biz-images/linky-icons/Default/BusinessCardSmall4.svg";
const BusinessCardLarge1 = "/assets/images/linky-biz-images/linky-icons/Default/BusinessCardLarge1.png";
const BusinessCardLarge2 = "/assets/images/linky-biz-images/linky-icons/Default/BusinessCardLarge2.png";

const AcceptPaymentsSmall1 = "/assets/images/linky-biz-images/linky-icons/Default/AcceptPaymentsSmall1.svg";
const AcceptPaymentsSmall2 = "/assets/images/linky-biz-images/linky-icons/Default/AcceptPaymentsSmall2.svg";
const AcceptPaymentsSmall3 = "/assets/images/linky-biz-images/linky-icons/Default/AcceptPaymentsSmall3 CL-White.svg";
const AcceptPaymentsSmall4 = "/assets/images/linky-biz-images/linky-icons/Default/AcceptPaymentsSmall4.svg";
const AcceptPaymentsLarge1 = "/assets/images/linky-biz-images/linky-icons/Default/AcceptPaymentsLarge1.png";
const AcceptPaymentsLarge2 = "/assets/images/linky-biz-images/linky-icons/Default/AcceptPaymentsLarge2.png";

const VisitorRegSmall1 = "/assets/images/linky-biz-images/linky-icons/Default/VisitorRegSmall1.svg";
const VisitorRegSmall2 = "/assets/images/linky-biz-images/linky-icons/Default/VisitorRegSmall2.svg";
const VisitorRegSmall3 = "/assets/images/linky-biz-images/linky-icons/Default/VisitorRegSmall3 CL-White.svg";
const VisitorRegLarge1 = "/assets/images/linky-biz-images/linky-icons/Default/VisitorRegLarge1.png";
const VisitorRegLarge2 = "/assets/images/linky-biz-images/linky-icons/Default/VisitorRegLarge2.png";

const OrderOnlineSmall1 = "/assets/images/linky-biz-images/linky-icons/Default/OrderOnlineSmall1.svg";
const OrderOnlineSmall2 = "/assets/images/linky-biz-images/linky-icons/Default/OrderOnlineSmall2.svg";
const OrderOnlineSmall3 = "/assets/images/linky-biz-images/linky-icons/Default/OrderOnlineSmall3 CL-White.svg";
const OrderOnlineSmall4 = "/assets/images/linky-biz-images/linky-icons/Default/OrderOnlineSmall4.svg";
const OrderOnlineLarge1 = "/assets/images/linky-biz-images/linky-icons/Default/OrderOnlineLarge1.png";
const OrderOnlineLarge2 = "/assets/images/linky-biz-images/linky-icons/Default/OrderOnlineLarge2.png";

const SellTicketSmall1 = "/assets/images/linky-biz-images/linky-icons/Default/SellTicketSmall1.svg";
const SellTicketSmall2 = "/assets/images/linky-biz-images/linky-icons/Default/SellTicketSmall2.svg";
const SellTicketSmall3 = "/assets/images/linky-biz-images/linky-icons/Default/SellTicketSmall3 CL-White.svg";
const SellTicketSmall4 = "/assets/images/linky-biz-images/linky-icons/Default/SellTicketSmall4.svg";
const SellTicketLarge1 = "/assets/images/linky-biz-images/linky-icons/Default/SellTicketLarge1.png";
const SellTicketLarge2 = "/assets/images/linky-biz-images/linky-icons/Default/SellTicketLarge2.svg";

const CallWaiterSmall1 = "/assets/images/linky-biz-images/linky-icons/Default/CallWaiterSmall1.svg";
const CallWaiterSmall2 = "/assets/images/linky-biz-images/linky-icons/Default/CallWaiterSmall2.svg";
const CallWaiterSmall3 = "/assets/images/linky-biz-images/linky-icons/Default/CallWaiterSmall3 CL-White.svg";
const CallWaiterSmall4 = "/assets/images/linky-biz-images/linky-icons/Default/CallWaiterSmall4.svg";
const CallWaiterLarge1 = "/assets/images/linky-biz-images/linky-icons/Default/CallWaiterLarge1.png";
const CallWaiterLarge2 = "/assets/images/linky-biz-images/linky-icons/Default/CallWaiterLarge2.png";

const CleanTableSmall1 = "/assets/images/linky-biz-images/linky-icons/Default/CleanTableSmall1.svg";
const CleanTableSmall2 = "/assets/images/linky-biz-images/linky-icons/Default/CleanTableSmall2.svg";
const CleanTableSmall3 = "/assets/images/linky-biz-images/linky-icons/Default/CleanTableSmall3 CL-White.svg";
const CleanTableLarge1 = "/assets/images/linky-biz-images/linky-icons/Default/CleanTableLarge1.png";
const CleanTableLarge2 = "/assets/images/linky-biz-images/linky-icons/Default/CleanTableLarge2.png";

const TipsSmall1 = "/assets/images/linky-biz-images/linky-icons/Default/TipsSmall1.svg";
const TipsSmall2 = "/assets/images/linky-biz-images/linky-icons/Default/TipsSmall2.svg";
const TipsSmall3 = "/assets/images/linky-biz-images/linky-icons/Default/TipsSmall3 CL-White.svg";
// const TipsSmall4 = "/assets/images/linky-biz-images/linky-icons/Default/TipsSmall4.svg";
const TipsLarge1 = "/assets/images/linky-biz-images/linky-icons/Default/TipsLarge1.png";
const TipsLarge2 = "/assets/images/linky-biz-images/linky-icons/Default/TipsLarge2.png";

const MinisiteBooking = "/assets/images/linky-biz-images/linky-icons/MinisiteBooking.svg";
const MinisiteBookingFull = "/assets/images/linky-biz-images/linky-icons/MinisiteBookingFull.png";
const MinisiteBookingSmall2 = "/assets/images/linky-biz-images/linky-icons/Default/MinisiteBookingSmall2.svg";
const MinisiteBookingSmall3 = "/assets/images/linky-biz-images/linky-icons/Default/MinisiteBookingSmall3 CL-White.svg";
const MinisiteBookingSmall4 = "/assets/images/linky-biz-images/linky-icons/Default/MinisiteBookingSmall4.svg";
const MinisiteBookingLarge2 = "/assets/images/linky-biz-images/linky-icons/Default/MinisiteBookingLarge2.png";

const MinisiteMeet = "/assets/images/linky-biz-images/linky-icons/MinisiteMeet.png";
const MinisiteMeetSmall1 = "/assets/images/linky-biz-images/linky-icons/Default/MinisiteMeetSmall1.png";
const MinisiteMeetLarge1 = "/assets/images/linky-biz-images/linky-icons/Default/MinisiteMeetLarge1.png";
const MinisiteMeetLarge2 = "/assets/images/linky-biz-images/linky-icons/Default/MinisiteMeetLarge2.png";
const MinisiteMeetLarge3 = "/assets/images/linky-biz-images/linky-icons/Default/MinisiteMeetLarge3.png";
const MinisiteMeetLarge4 = "/assets/images/linky-biz-images/linky-icons/Default/MinisiteMeetLarge4.png";

const MinisiteReviewBooster1 = "/assets/images/linky-biz-images/linky-icons/Default/review-booster/3.jpg";
const MinisiteReviewBooster2 = "/assets/images/linky-biz-images/linky-icons/Default/review-booster/2.jpg";
const MinisiteReviewBooster3 = "/assets/images/linky-biz-images/linky-icons/Default/review-booster/9.jpg";
const MinisiteReviewBooster4 = "/assets/images/linky-biz-images/linky-icons/Default/review-booster/6.png";
const MinisiteReviewBooster5 = "/assets/images/linky-biz-images/linky-icons/Default/review-booster/5.png";
const MinisiteReviewBooster6 = "/assets/images/linky-biz-images/linky-icons/Default/review-booster/4.png";
const MinisiteReviewBooster7 = "/assets/images/linky-biz-images/linky-icons/Default/review-booster/1.png";
const MinisiteReviewBooster8 = "/assets/images/linky-biz-images/linky-icons/Default/review-booster/7.png";
const MinisiteReviewBooster9 = "/assets/images/linky-biz-images/linky-icons/Default/review-booster/8.png";

export const imageSelector = (linkyFeatureButtonType, showFullSizeImage, serial, showMediumSizeImage = false) => {
    console.log("here it is coming");
    console.log(linkyFeatureButtonType, showFullSizeImage, serial,"anytime")
    if (linkyFeatureButtonType === "BusinessCard") {
        if (showFullSizeImage == true) {
            return serial == 1 ? BusinessCardLarge1 : serial == 2 ? BusinessCardLarge2 : ""
        } else {
            return serial == 1 ? BusinessCardSmall1 : serial == 2 ? BusinessCardSmall2 : serial == 3 ? BusinessCardSmall3 : serial == 4 ? BusinessCardSmall4 : ""
        }
    } else if (linkyFeatureButtonType === "MakeMoneyWithLinky") {
        if (showFullSizeImage == true) {
            return serial == 1 ? Five : serial == 2 ? Six : serial == 3 ? Four : ""
        } else {
            return serial == 1 ? Two : serial == 2 ? Three : serial == 3 ? One : ""
        }
    } else if (linkyFeatureButtonType === "AcceptPayments") {
        if (showFullSizeImage == true) {
            return serial == 1 ? AcceptPaymentsLarge1 : serial == 2 ? AcceptPaymentsLarge2 : ""
        } else {
            return serial == 1 ? AcceptPaymentsSmall1 : serial == 2 ? AcceptPaymentsSmall2 : serial == 3 ? AcceptPaymentsSmall3 : serial == 4 ? AcceptPaymentsSmall4 : ""
        }
    } else if (linkyFeatureButtonType === "VisitorReg") {
        if (showFullSizeImage == true) {
            return serial == 1 ? VisitorRegLarge1 : serial == 2 ? VisitorRegLarge2 : ""
        } else {
            return serial == 1 ? VisitorRegSmall1 : serial == 2 ? VisitorRegSmall2 : serial == 3 ? VisitorRegSmall3 : ""
        }
    } else if (linkyFeatureButtonType === "OrderOnline") {
        if (showFullSizeImage == true) {
            return serial == 1 ? OrderOnlineLarge1 : serial == 2 ? OrderOnlineLarge2 : ""
        } else {
            return serial == 1 ? OrderOnlineSmall1 : serial == 2 ? OrderOnlineSmall2 : serial == 3 ? OrderOnlineSmall3 : serial == 4 ? OrderOnlineSmall4 : ""
        }
    } else if (linkyFeatureButtonType === "SellTicket") {
        if (showFullSizeImage == true) {
            return serial == 1 ? SellTicketLarge1 : serial == 2 ? SellTicketLarge2 : ""
        } else {
            return serial == 1 ? SellTicketSmall1 : serial == 2 ? SellTicketSmall2 : serial == 3 ? SellTicketSmall3 : serial == 4 ? SellTicketSmall4 : ""
        }
    } else if (linkyFeatureButtonType === "CallWaiter") {
        if (showFullSizeImage == true) {
            return serial == 1 ? CallWaiterLarge1 : serial == 2 ? CallWaiterLarge2 : ""
        } else {
            return serial == 1 ? CallWaiterSmall1 : serial == 2 ? CallWaiterSmall2 : serial == 3 ? CallWaiterSmall3 : serial == 4 ? CallWaiterSmall4 : ""
        }
    } else if (linkyFeatureButtonType === "CleanTable") {
        if (showFullSizeImage == true) {
            return serial == 1 ? CleanTableLarge1 : serial == 2 ? CleanTableLarge2 : ""
        } else {
            return serial == 1 ? CleanTableSmall1 : serial == 2 ? CleanTableSmall2 : serial == 3 ? CleanTableSmall3 : ""
        }
    } else if (linkyFeatureButtonType === "Tips") {
        if (showFullSizeImage == true) {
            return serial == 1 ? TipsLarge1 : serial == 2 ? TipsLarge2 : ""
        } else {
            return serial == 1 ? TipsSmall1 : serial == 2 ? TipsSmall2 : serial == 3 ? TipsSmall3 : ""
        }
    } else if (linkyFeatureButtonType === "MinisiteBooking") {
        if (showFullSizeImage == true) {
            return serial == 1 ? MinisiteBookingFull : serial == 2 ? MinisiteBookingLarge2 : ""
        } else {
            return serial == 1 ? MinisiteBooking : serial == 2 ? MinisiteBookingSmall2 : serial == 3 ? MinisiteBookingSmall3 : serial == 4 ? MinisiteBookingSmall4 : ""
        }
    } else if (linkyFeatureButtonType === "MinisiteMeet") {
        if (showFullSizeImage == true) {
            return serial == 1 ? MinisiteMeetLarge2 : serial == 2 ? MinisiteMeetLarge1 : serial == 3 ? MinisiteMeetLarge3 : serial == 4 ? MinisiteMeetLarge4 : ""
        } else {
            return serial == 1 ? MinisiteMeet : serial == 2 ? MinisiteMeetSmall1 : ""
        }
    } else if (linkyFeatureButtonType === "MinisiteReviewBooster") {
        if (showFullSizeImage == true) {
            return serial == 1 ? MinisiteReviewBooster1 : serial == 2 ? MinisiteReviewBooster2 : serial == 3 ? MinisiteReviewBooster3 : ""
        } if (showMediumSizeImage == true) {
            return serial == 1 ? MinisiteReviewBooster4 : serial == 2 ? MinisiteReviewBooster5 : serial == 3 ? MinisiteReviewBooster6 : ""
        } else {
            return serial == 1 ? MinisiteReviewBooster7 : serial == 2 ? MinisiteReviewBooster8 : serial == 3 ? MinisiteReviewBooster9 : ""
        }
    }
}