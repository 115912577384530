import React, { Fragment, useState, useEffect } from "react";
import { DisappearedLoading } from "react-loadingg";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import { toast } from "react-toastify";
import useForm from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import OtherSettingComp from './OtherSettingComp';

const OtherSettingPage = () => {

    const [loading, setLoading] = useState(false);
    const [priceData, setPriceData] = useState(null);
    const [alPriceData, setAllPriceData] = useState([]);

    const fetchPriceData = () => {
        setLoading(true);
        let postData = { fetchType: "Admin" };
        client.post(api.fetchPricePerStore, postData, async function (error, response) {
            if (!error) {
                if (!response.error) {
                    setLoading(false);
                    setPriceData(response.result);
                    setAllPriceData(response.all_result);
                } else {
                    toast.error(response.message, { autoClose: 2000, hideProgressBar: true });
                }
            } else {
                setLoading(false);
                toast.error("Internal error occured. Please contact support", { autoClose: 2000, hideProgressBar: true });
            }
        });
    };

    const updatePriceData = (postData) => {

        setPriceData(null)
        client.put(api.updatePricePerStore, postData, async function (error, response) {
            if (!error) {
                if (!response.error) {
                    fetchPriceData();
                    toast.success(response.message, { autoClose: 2000, hideProgressBar: true });
                } else {
                    toast.error(response.message, { autoClose: 2000, hideProgressBar: true });
                }
            } else {
                toast.error("Internal error occured. Please contact support", { autoClose: 2000, hideProgressBar: true });
            }
        });
    };

    const deletePriceData = (postData) => {

        setPriceData(null)
        client.put(api.deletePricePerStore, postData, async function (error, response) {
            if (!error) {
                if (!response.error) {
                    // fetchPriceData();
                    toast.success(response.message, { autoClose: 2000, hideProgressBar: true });
                } else {
                    toast.error(response.message, { autoClose: 2000, hideProgressBar: true });
                }
            } else {
                toast.error("Internal error occured. Please contact support", { autoClose: 2000, hideProgressBar: true });
            }
        });
    };

    useEffect(() => {

        if (!priceData) {
            fetchPriceData();
        }
    }, [])


    return (
        <>
            {
                !loading && priceData ?
                    <div>
                        <OtherSettingComp
                            priceData={priceData}
                            updatePriceData={updatePriceData}
                            deletePriceData={deletePriceData}
                            alPriceData={alPriceData}
                        />
                    </div> :
                    <div>
                        <div className="loader-box" style={{ height: "50vh", width: "100wh" }}>
                            <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                                <DisappearedLoading size="medium" color="#514F4E" />
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default OtherSettingPage