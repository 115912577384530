import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
// import { Link as NavLink } from "react-router-dom";
import useForm from 'react-hook-form'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem, NavLink as NavLinkTab } from 'reactstrap';


// const BorderTable = () => {
function Referrals() {

    const { register, handleSubmit, errors } = useForm(); // initialise the hook
    const { register: register2, handleSubmit: handleSubmit2, errors: errors2 } = useForm(); // initialise the hook
    const [loadingbtn, setloadingbtn] = useState(false);
    const [loading, setloading] = useState(false);
    const [cashbackdata, setcashbackdata] = useState(null);
    let [tabIndex, setTabIndex] = useState(0);
    const [cashbackInfoMerchant, setcashbackInfoMerchant] = useState(null);
    const [activeTab, setActiveTab] = useState('2');



    const onSubmit = data => {

        // console.log(data);
        let config_type = 1;
        if (activeTab == "2")
            config_type = 2
        // return false;
        if (data !== '') {

            data.config_type = config_type;

            setloadingbtn(true);
            client.post(api.update_ashback_settings, data, async function (error, response) {
                if (!error) {
                    // alert(JSON.stringify(response));
                    //   return false;
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {

                                toast.success("Updated Successfully");
                                // setcashbackdata(response.result.data);
                            } else {
                                toast.error(response.message);
                            }
                        } catch (e) {
                            toast.error("Internal error occured. Please contact support");
                        }
                    } else {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
                setloadingbtn(false);
            });
        } else {
            if (config_type == 1)
                errors.showMessages();
            else
                errors2.showMessages();
        }
    };


    const getcashback_settings = async () => {
        let postdata = {};
        let current = this;
        // current.setState({ loading: true });
        setloading(true);
        client.post(api.cashback_settings, postdata, async function (error, response) {
            if (!error) {
                // console.log(JSON.stringify(response.result.dataMerchant));
                //   return false;
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            setcashbackdata(response.result.data);
                            setcashbackInfoMerchant(response.result.dataMerchant);

                            // current.props.navigation.navigate("Verification");
                        } else {
                            toast.error(response.message);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
                // _showToast.error("Internal error occured. Please contact support.");
            }
            // current.setState({ loading: false });
            setloading(false);

        });
    };

    useEffect(() => {
        // alert("hrer");
        getcashback_settings();
    }, [])

    const displayDate = (date) => {
        let transaction_date = new Date(date);
        return (
            <span>
                {transaction_date}
            </span>
        )
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12 col-xl-12 xl-100">
                    <div className="card">
                        <div className="card-body" style={{ padding: "1rem" }}>
                            {/* {access_level != 1 && <Button className="mb-3" outline color="primary" onClick={toggle2}><i className="fa fa-plus" style={{ margin: "0 7px 0 0" }}></i>Add New Template</Button>} */}
                            <Nav tabs className="nav-pills nav-light mb-1">
                                {/* <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                                    <NavLinkTab className={activeTab == '1' ? 'active nav-link' : ''} onClick={() => setActiveTab('1')} value='1'>
                                        Customer
                                </NavLinkTab>
                                </NavItem> */}

                                <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                                    <NavLinkTab className={activeTab == '2' ? 'active nav-link' : ''} onClick={() => setActiveTab('2')} value='2'>
                                        Merchant
                                </NavLinkTab>
                                </NavItem>
                            </Nav>

                            <TabContent activeTab={activeTab} >
                                <TabPane tabId="1">
                                    {cashbackdata != null ?
                                        <form className="needs-validation theme-form mt-3 billing-form" style={{ paddingLeft: "20px" }} onSubmit={handleSubmit(onSubmit)}>
                                            <h5 style={{ fontSize: "0.9rem" }}>Referral Cashback</h5>
                                            <div className="form-row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="col-form-label">Status</label>
                                                        
                                                        <div className="row col-sm-12" style={{ marginLeft: 10 }}>
                                                            <div className="radio">
                                                                <input id="refercb_status1" type="radio" name="refercb_status" value="1" ref={register({ required: true })}defaultChecked={cashbackdata.refercb_status == 1 ? true : false} />
                                                                <label htmlFor="refercb_status1">Active</label>
                                                            </div>
                                                            <div className="radio " style={{ marginLeft: 20 }}>
                                                                <input id="refercb_status0" type="radio" name="refercb_status" value="0" ref={register({ required: true })} defaultChecked={cashbackdata.refercb_status == 0 ? true : false} />
                                                                <label htmlFor="refercb_status0">Inactive</label>
                                                            </div>
                                                        </div>
                                                        <span>{errors.refercb_status && 'Status is required'}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                {/* <div className="col-sm-6">

                                                    <label for="level_title">Refer To Customer</label>
                                                    <div className="row">

                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label className="col-form-label">Cashback To Referrer</label>
                                                                <input className="form-control" type="Number" name="refercb_referrer_amount" placeholder="Enter Amount" ref={register({ required: true })} defaultValue={cashbackdata.refercb_referrer_amount} />
                                                                <span>{errors.refercb_referrer_amount && 'Referrer cashback is required'}</span>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label className="col-form-label">New User Cashback</label>
                                                                <input className="form-control" type="Number" name="refercb_amount" placeholder="Enter Amount" ref={register({ required: true })} defaultValue={cashbackdata.refercb_amount} />
                                                                <span>{errors.refercb_amount && 'New user cashback is required'}</span>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                        
                                                </div> */}
                                                    
                                                <div className="col-sm-6">
                                                    <label for="level_title">Refer To Merchant</label>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label className="col-form-label">Cashback To Referrer</label>
                                                                <input className="form-control" type="Number" name="refercb_referrer_m_amount" placeholder="Enter Amount" ref={register({ required: true })} defaultValue={cashbackdata.refercb_referrer_m_amount} />
                                                                <span>{errors.refercb_referrer_m_amount && 'Referrer cashback is required'}</span>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label className="col-form-label">New User Cashback (Referee Cashback)</label>
                                                                <input className="form-control" type="Number" name="refercb_amount_m" placeholder="Enter Amount" ref={register({ required: true })} defaultValue={cashbackdata.refercb_amount_m} />
                                                                <span>{errors.refercb_amount_m && 'New user cashback is required'}</span>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                    
                                            </div>
                                            <div className="form-group col-lg-12">
                                                {!loadingbtn ?
                                                    <button className="btn btn-primary btn-xs pull-right" type="submit" style={{ fontSize: "0.75rem" }}>SAVE</button>
                                                    :
                                                    <div className="loader-box pull-right" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 42, width: 42 }}></span></div>
                                                }
                                            </div>
                                        </form>
                                        :
                                        <div className="loader-box" >
                                            <div className="loader" style={{ margin: "auto auto" }}>
                                                <div className="line bg-primary"></div>
                                                <div className="line bg-primary"></div>
                                                <div className="line bg-primary"></div>
                                                <div className="line bg-primary"></div>
                                            </div>
                                        </div>
                                    }
                                </TabPane>

                                <TabPane tabId="2">
                                    {cashbackInfoMerchant != null ?
                                        <form key="2" className="needs-validation theme-form mt-3 billing-form" style={{ paddingLeft: "20px" }} onSubmit={handleSubmit2(onSubmit)}>
                                            <h5 style={{ fontSize: "0.9rem" }}>Referral Cashback</h5>
                                            <div className="form-row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="col-form-label">Status</label>
                                                        {/*
                                                    <input className="form-control" type="Number" placeholder="Enter contact number" /> */}
                                                        <div className="row col-sm-12" style={{ marginLeft: 10 }}>
                                                            <div className="radio">
                                                                <input id="merchantrefercb_status1" type="radio" name="refercb_status" value="1" ref={register2({ required: true })} defaultChecked={cashbackInfoMerchant.refercb_status == 1 ? true : false} />
                                                                <label htmlFor="merchantrefercb_status1">Active</label>
                                                            </div>
                                                            <div className="radio" style={{ marginLeft: 20 }}>
                                                                <input id="merchantrefercb_status0" type="radio" name="refercb_status" value="0" ref={register2({ required: true })} defaultChecked={cashbackInfoMerchant.refercb_status == 0 ? true : false} />
                                                                <label htmlFor="merchantrefercb_status0">Inactive</label>
                                                            </div>
                                                        </div>
                                                        <span>{errors2.refercb_status && 'Status is required'}</span>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="form-row">
                                                {/* <div className="col-sm-6">

                                                    <label for="level_title">Refer To Customer</label>
                                                    <div className="row">

                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label className="col-form-label">Cashback To Referrer</label>
                                                                <input className="form-control" type="Number" name="refercb_referrer_amount" placeholder="Enter Amount" ref={register2({ required: true })} defaultValue={cashbackInfoMerchant.refercb_referrer_amount} />
                                                                <span>{errors.refercb_referrer_amount && 'Referrer cashback is required'}</span>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label className="col-form-label">New User Cashback</label>
                                                                <input className="form-control" type="Number" name="refercb_amount" placeholder="Enter Amount" ref={register2({ required: true })} defaultValue={cashbackInfoMerchant.refercb_amount} />
                                                                <span>{errors.refercb_amount && 'New user cashback is required'}</span>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                        
                                                </div> */}
                                                    
                                                <div className="col-sm-6">
                                                    <label for="level_title">Refer To Merchant</label>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label className="col-form-label">Cashback To Referrer</label>
                                                                <input className="form-control" type="Number" name="refercb_referrer_m_amount" placeholder="Enter Amount" ref={register2({ required: true })} defaultValue={cashbackInfoMerchant.refercb_referrer_m_amount} />
                                                                <span>{errors.refercb_referrer_m_amount && 'Referrer cashback is required'}</span>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label className="col-form-label">New User Cashback (Referee Cashback)</label>
                                                                <input className="form-control" type="Number" name="refercb_amount_m" placeholder="Enter Amount" ref={register2({ required: true })} defaultValue={cashbackInfoMerchant.refercb_amount_m} />
                                                                <span>{errors.refercb_amount_m && 'New user cashback is required'}</span>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                    
                                            </div>
                                            <div className="form-group col-lg-12">
                                                {!loadingbtn ?
                                                    <button className="btn btn-primary btn-xs pull-right" type="submit">SAVE</button>
                                                    :
                                                    <div className="loader-box pull-right" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 42, width: 42 }}></span></div>
                                                }
                                            </div>
                                        </form>
                                        :
                                        <div className="loader-box" >
                                            <div className="loader" style={{ margin: "auto auto" }}>
                                                <div className="line bg-primary"></div>
                                                <div className="line bg-primary"></div>
                                                <div className="line bg-primary"></div>
                                                <div className="line bg-primary"></div>
                                            </div>
                                        </div>
                                    }
                                </TabPane>



                            </TabContent>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );

}

export default Referrals;
