import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../../constant/apilist";
import client from "../../../Api/HTTPClient";
import { v4 as uuidv4 } from "uuid";
import "./AppSettings";
import QuizTable from "./QuizTable";
import { DisappearedLoading } from "react-loadingg";

const PublishQuiz = (props) => {
	const [appType, setAppType] = useState("");
	// const [timeToAnswer, setTimeToAnswer] = useState(0);
	// const [rewardType, setRewardType] = useState("");
	// const [budget, setBudget] = useState(0);
	// const [points, setPoints] = useState(0);
	const [redirectDraft, setRedirectDraft] = useState(false);
	const [loading, setLoading] = useState(false);

	// const [spinPoints, setSpinPoints] = useState({
	// 	default: "0",
	// 	ist: "",
	// 	sec: "",
	// 	third: "",
	// 	fourth: "",
	// 	fifth: "",
	// });
	// const [rewardError, setRewardError] = useState(false);
	// const [pointsError, setPointsError] = useState(false);
	// const [spinError, setSpinError] = useState(false);

	const [startDate, setStartDate] = useState("0000/00/00");
	const [startTime, setStartTime] = useState("00:00");

	// console.log(startDate, "startDate");
	// console.log(startTime, "startTime");
	// const expiryDate = startDate + "\xa0\xa0\xa0" + startTime;
	// console.log(expiryDate);

	// useEffect(() => {
	// 	validateRewardType();
	// }, [rewardType, budget, points, spinPoints]);

	// const validateRewardType = () => {
	// 	if (rewardType == "cashback" && budget == 0) {
	// 		// toast.error(" Please enter budget");
	// 		setRewardError(true);
	// 		setPointsError(false);
	// 		setSpinError(false);
	// 	} else {
	// 		setRewardError(false);
	// 	}
	// 	if (rewardType == "points" && points == 0) {
	// 		// toast.error("budget field is empty");
	// 		setPointsError(true);
	// 		setSpinError(false);
	// 		setRewardError(false);
	// 	} else {
	// 		setPointsError(false);
	// 	}
	// 	if (rewardType == "spin") {
	// 		if (
	// 			spinPoints.ist == "" ||
	// 			spinPoints.sec == "" ||
	// 			spinPoints.third == "" ||
	// 			spinPoints.fourth == "" ||
	// 			spinPoints.fifth == ""
	// 		) {
	// 			// toast.error("Spin Points are not filled");
	// 			setSpinError(true);
	// 			setRewardError(false);
	// 			setPointsError(false);
	// 		} else {
	// 			setSpinError(false);
	// 		}
	// 	}
	// };
	// console.log(timeToAnswer, "campaignName");
	// console.log(rewardError, pointsError, spinError, "erross consolee");

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		console.log(props.postData, "propsss");
		const expiryDateTime = startDate + "\xa0\xa0\xa0" + startTime;
		const postdata = {
			id: uuidv4(),
			status: "Publish",
			defaultquiz: false,
			campaignName: props.campaignName,
			quizData: props.postData,
			appType: appType,
			// timeToAnswer: timeToAnswer,
			// rewardType: rewardType,
			expiryDateTime: expiryDateTime,
			// budget: budget,
			// points: points,
			// spinPoints: spinPoints,
		};
		if (appType.length == 0) {
			toast.error("please select app type from dropdown");
		} else {
			await client.post(api.saveQuizdata, postdata, (error, response) => {
				console.log(response, "response");
				if (response) {
					if (!response.error) {
						toast.success("Pubished successfull");
						console.log(response, "response");
						props.setQuizId(postdata.id);
						setLoading(false);
						props.setTabIndex(2);
					} else {
						toast.error("Server issue");
						setLoading(false);
					}
				} else {
					console.log(error);
				}
			});
		}
		//  else {
		// 	if (timeToAnswer == 0 || timeToAnswer > 60) {
		// 		toast.error("please enter seconds ranging from 0 to 60 ");
		// 	} else {
		// 		if (isNaN(timeToAnswer)) {
		// 			console.log("true");
		// 			toast.error("invalid character found on time field");
		// 			document.getElementById("timeToAnswer").focus();
		// 		} else {
		// 			console.log(postdata);
		// 			if (rewardType == "") {
		// 				toast.error("please select Reward type");
		// 			} else {
		// 				if (rewardType == "cashback" && budget == 0) {
		// 					toast.error(" Please enter budget");
		// 					// setRewardError(true);
		// 				} else {
		// 					setRewardError(false);
		// 				}
		// 				if (rewardType == "points" && points == 0) {
		// 					toast.error("budget field is empty");
		// 					// setPointsError(true);
		// 				} else {
		// 					setPointsError(false);
		// 				}
		// 				if (rewardType == "spin") {
		// 					if (
		// 						spinPoints.ist == "" ||
		// 						spinPoints.sec == "" ||
		// 						spinPoints.third == "" ||
		// 						spinPoints.fourth == "" ||
		// 						spinPoints.fifth == ""
		// 					) {
		// 						toast.error("Spin Points are not filled");
		// 						// setSpinError(true);
		// 					} else {
		// 						setSpinError(false);
		// 					}
		// 				}
		// 				// console.log(rewardError, pointsError, spinError, "erross");
		// 				if (!rewardError && !pointsError && !spinError) {
		// 					// setLoading(true);
		// 					await client.post(api.saveQuizdata, postdata, (error, response) => {
		// 						console.log(response, "response");
		// 						if (response) {
		// 							if (!response.error) {
		// 								toast.success("Pubished successfull");
		// 								setLoading(false);
		// 								console.log(response, "response");

		// 								props.setTabIndex(2);
		// 							} else {
		// 								toast.error("Server issue");
		// 								setLoading(false);
		// 							}
		// 						} else {
		// 							console.log(error);
		// 						}
		// 					});
		// 				} else {
		// 					toast.error("please fill required fields");
		// 				}
		// 			}
		// 		}
		// 	}
		// }
		// console.log(props.postData, "props");
	};

	const handleDraft = async (e) => {
		e.preventDefault();
		setLoading(true);
		const expiryDateTime = startDate + "\xa0\xa0\xa0" + startTime;
		console.log(props.campaignName, "campaignName");
		const postdata = {
			id: uuidv4(),
			status: "Draft",
			defaultquiz: false,
			campaignName: props.campaignName,
			quizData: props.postData,
			appType: appType,
			// timeToAnswer: timeToAnswer,
			// rewardType: rewardType,
			expiryDateTime: expiryDateTime,
			// budget: budget,
			// points: points,
			// spinPoints: spinPoints,
		};
		if (appType.length == 0) {
			toast.error("please select app type from dropdown");
		} else {
			await client.post(api.saveQuizdata, postdata, (error, response) => {
				console.log(response, "response");
				if (response) {
					if (!response.error) {
						toast.success("Drafted successfull");
						console.log(postdata, "post");
						// props.setTabIndex(2);
						setLoading(false);

						setRedirectDraft(true);
					} else {
						toast.error("Server issue");
						setLoading(false);
					}
				} else {
					console.log(error);
				}
			});
		}
		//  else {
		// 	if (timeToAnswer == 0 || timeToAnswer > 60) {
		// 		toast.error("please enter seconds ranging from 0 to 60 ");
		// 	} else {
		// 		if (isNaN(timeToAnswer)) {
		// 			console.log("true");
		// 			toast.error("invalid character found on time field");
		// 			document.getElementById("timeToAnswer").focus();
		// 		} else {
		// 			console.log(postdata);
		// 			if (rewardType == "") {
		// 				toast.error("please select Reward type");
		// 			} else {
		// 				if (rewardType == "cashback" && budget == 0) {
		// 					toast.error(" Please enter budget");
		// 					// setRewardError(true);
		// 				} else {
		// 					setRewardError(false);
		// 				}
		// 				if (rewardType == "points" && points == 0) {
		// 					toast.error("budget field is empty");
		// 					// setPointsError(true);
		// 				} else {
		// 					setPointsError(false);
		// 				}
		// 				if (rewardType == "spin") {
		// 					if (
		// 						spinPoints.ist == "" ||
		// 						spinPoints.sec == "" ||
		// 						spinPoints.third == "" ||
		// 						spinPoints.fourth == "" ||
		// 						spinPoints.fifth == ""
		// 					) {
		// 						toast.error("Spin Points are not filled");
		// 						// setSpinError(true);
		// 					} else {
		// 						setSpinError(false);
		// 					}
		// 				}
		// 				// console.log(rewardError, pointsError, spinError, "erross");
		// 				if (!rewardError && !pointsError && !spinError) {
		// 					// setLoading(true);
		// 					await client.post(api.saveQuizdata, postdata, (error, response) => {
		// 						console.log(response, "response");
		// 						if (response) {
		// 							if (!response.error) {
		// 								toast.success("Drafted successfull");
		// 								console.log(postdata, "post");
		// 								// props.setTabIndex(2);
		// 								setLoading(false);

		// 								setRedirectDraft(true);
		// 							} else {
		// 								toast.error("Server issue");
		// 								setLoading(false);
		// 							}
		// 						} else {
		// 							console.log(error);
		// 						}
		// 					});
		// 				} else {
		// 					toast.error("please fill required fields");
		// 				}
		// 			}
		// 		}
		// 	}
		// }
		// console.log(postdata);
		// console.log(props.postData, "props");
	};
	// console.log(spinPoints, "spinPoints");

	// const handlefocus = (first, last) => {
	// 	if (first.target.value.length) {
	// 		document.getElementById(last).focus();
	// 	}
	// };

	if (redirectDraft) {
		return (
			<div className="cardCont" style={{ marginTop: "-46px" }}>
				<QuizTable />
			</div>
		);
	} else {
		return (
			<div className="card">
				<div class="card-header b-l-primary ">
					<h5 style={{ margin: "30px" }}>Publish Settings</h5>
				</div>
				{!loading ? (
					<form className="form theme-form">
						<div className="card-body">
							<div className="row">
								<div className="col">
									<div className="form-group row">
										<label className="col-sm-3 col-form-label">App type?</label>
										<div className="col-sm-9">
											<select className="form-control" onChange={(e) => setAppType(e.target.value)}>
												<option disabled value="select" selected="select">
													Select App Type
												</option>
												<option value="Andriod">Andriod</option>
												<option value="Ios">Ios</option>
												<option value="Both">Both</option>
											</select>
										</div>
									</div>

									{/* <div className="form-group row">
										<label className="col-sm-3 col-form-label">Allow time to answer each questions</label>
										<div className="col-sm-9">
											<div className="row" style={{ marginLeft: "0%" }}>
												<input
													className="form-control col-sm-1 "
													type="text"
													id="timeToAnswer"
													name="timeToAnswer"
													min="0"
													max="60"
													maxLength="2"
													onChange={(e) => setTimeToAnswer(e.target.value)}
												/>
												<label className="col" for="timeToAnswer">
													(in seconds) per question
												</label>
											</div>
										</div>
									</div>

									<div className="form-group row">
										<label className="col-sm-3 col-form-label">Reward type?</label>
										<div className="col-sm-9">
											<select className="form-control" onChange={(e) => setRewardType(e.target.value)}>
												<option disabled value="select" selected="select">
													Select Reward Type
												</option>
												<option value="cashback">Cashback</option>
												<option value="points">Points</option>
												<option value="spin">Spin & Win</option>
											</select>
										</div>
									</div> */}
									{/* {rewardType == "cashback" ? (
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">Budget per user</label>
											<div className="col-sm-9">
												<input
													className="form-control"
													type="number"
													name="budget"
													min="0"
													onChange={(e) => setBudget(e.target.value)}
												/>
											</div>
										</div>
									) : null}

									{rewardType == "points" ? (
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">Points per user</label>
											<div className="col-sm-9">
												<input
													className="form-control"
													type="number"
													name="points"
													min="0"
													onChange={(e) => setPoints(e.target.value)}
												/>
											</div>
										</div>
									) : null}

									{rewardType == "spin" ? (
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">Enter Points</label>
											<div className="col-sm-9">
												<div className="row">
													<input
														className="form-control col-sm-1 otpbox"
														type="text"
														name="points"
														maxLength="1"
														value={spinPoints.default}
														disabled={true}
														// onChange={(e) => setPoints(e.target.value)}
													/>
													<input
														className="form-control col-sm-1 otpbox"
														type="text"
														name="points"
														maxLength="1"
														id="ist"
														onChange={(e) =>
															setSpinPoints({
																...spinPoints,
																ist: e.target.value,
															})
														}
														onKeyUp={(e) => handlefocus(e, "sec")}
													/>
													<input
														className="form-control col-sm-1 otpbox"
														type="text"
														name="points"
														maxLength="1"
														id="sec"
														onChange={(e) =>
															setSpinPoints({
																...spinPoints,
																sec: e.target.value,
															})
														}
														onKeyUp={(e) => handlefocus(e, "third")}
													/>
													<input
														className="form-control col-sm-1 otpbox"
														type="text"
														name="points"
														maxLength="1"
														id="third"
														onChange={(e) =>
															setSpinPoints({
																...spinPoints,
																third: e.target.value,
															})
														}
														onKeyUp={(e) => handlefocus(e, "fourth")}
													/>
													<input
														className="form-control col-sm-1 otpbox"
														type="text"
														name="points"
														maxLength="1"
														id="fourth"
														onChange={(e) =>
															setSpinPoints({
																...spinPoints,
																fourth: e.target.value,
															})
														}
														onKeyUp={(e) => handlefocus(e, "fifth")}
													/>
													<input
														className="form-control col-sm-1 otpbox"
														type="text"
														name="points"
														maxLength="1"
														id="fifth"
														onChange={(e) =>
															setSpinPoints({
																...spinPoints,
																fifth: e.target.value,
															})
														}
													/>
												</div>
											</div>
										</div>
									) : null} */}

									<div className="form-group row">
										<label className="col-sm-3 col-form-label">Quiz Expiry</label>
										<div className="row">
											<div className="col" style={{ paddingLeft: "2rem" }}>
												<input
													type="date"
													id="expiryDate"
													className="form-control digits"
													value={startDate}
													onChange={(e) => setStartDate(e.target.value)}
												/>
											</div>
											<div className="col">
												<input
													type="time"
													id="expiryTime"
													className="form-control digits"
													value={startTime}
													onChange={(e) => setStartTime(e.target.value)}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="card-footer">
							<div className="publishcont row ">
								<div className="col-sm-2">
									<button className="btn btn-primary" onClick={handleSubmit}>
										Publish Now
									</button>
								</div>
								<div className="col-sm-3">
									<button className="btn btn-info" onClick={handleDraft}>
										Save as Draft
									</button>
								</div>
							</div>
						</div>
					</form>
				) : (
					<div className="loader-box">
						<div className="loader" style={{ display: "flex", alignItems: "center" }}>
							<DisappearedLoading size="medium" color="#514F4E" />
						</div>
					</div>
				)}
			</div>
		);
	}
};

export default PublishQuiz;
