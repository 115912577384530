import React, { useState, useEffect, Fragment } from "react";
import "./searchbar.scss";
import SkeletonPageLoader from "./SkeletonPageLoader";
import { RiTakeawayLine, RiSeparator } from "react-icons/ri";
import { VscPerson } from "react-icons/vsc";
import { GrTransaction } from "react-icons/gr";
import { FcBusinessman } from "react-icons/fc";
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { FaRegHandshake } from "react-icons/fa6";

const MasterSearchBarResultComp = ({
	searchResult,
	searchValue,
	loading,
	clearSearch
}) => {


	const history = useHistory();

	let titleStyle = { fontSize: "15px", fontWeight: "bold", margin: "6px 13px 0px 14px", textTransform: 'uppercase' };
	let listStyle = { listStyle: "none", color: "#000", padding: "3px 6px", borderRadius: "5px", fontSize: "13px", cursor: "pointer", margin: "0px 10px", display: "block" };

	const getTimeZone = (currentData) => {
		try {
			if (currentData.timeZone) {
				return currentData.timeZone;
			} else {
				return "Australia/Sydney";
			}
		} catch {
			return "Australia/Sydney";
		}
	}

	const sepratorElement = () => {
		return <span className="" style={{ padding: "0rem 0.7rem" }}><RiSeparator className="m-0" color={"#000"} size={15} style={{}} /></span>
	}

	const getStatus = (status) => {
		if (status == 1) {
			return "Success";
		} else if (status == 0) {
			return "Pending";
		} else {
			return "Failed";
		}
	};

	const getSettlementDetails = (item) => {

		let settlement_data = item.settlement_data;
		if (settlement_data.status == 0) {
			return <p className="mb-0">Unpaid</p>;

		} else if (settlement_data.status == 1) {
			return <p className="mb-0">Paid</p>;

		} else if (settlement_data.status == 3) {
			return <p className="mb-0" style={{ color: "#FF2046" }}>Unauthorized</p>;
		}

	};


	return (
		<>
			<div className="list_result">
				{
					loading ?
						<SkeletonPageLoader />
						: <div className="result_list_section">
							<div className="d-flex border-bottom">
								<li className="info_bar">Show all result for : {searchValue}{" "}</li>
							</div>
							<div className="result_list_data_section">
								{
									searchResult.length > 0 ?
										<div className="text-left">
											{searchResult.map((currResult, currIndx) => {
												if (currResult.categoryName == "merchants") {
													return (
														<>
															{currIndx != 0 && <hr className="m-0" />}
															<p className="mb-2" style={titleStyle}>{currResult.categoryName}</p>
															{
																currResult.data.map((items) => {
																	let renderDate = items.added_date || items.createdAt
																	return (
																		<>
																			<li
																				className={currResult.data[currResult.data.length - 1] == items ? "mb-2" : ""}
																				style={listStyle}
																				onClick={() => {
																					history.push({ pathname: `/merchants/merchantDetails/${items._id}`, });
																					clearSearch();
																				}}
																			>
																				<div className="d-flex justify-content-between">
																					<div className="d-flex">
																						<span className="mr-2" ><FcBusinessman className="mt-0" color={"#000"} size={15} /></span>
																						<div className=" text-capitalize" style={{ width: "150px", wordWrap: "break-word" }}> {items?.business_name}</div>{sepratorElement()}
																						<div className="" style={{ width: "50px" }}>{items?.my_referral_code}</div>{sepratorElement()}
																						<div className="" style={{ width: "50px", wordWrap: "break-word" }}>{items?.unique_id}</div>{sepratorElement()}
																						<div className="" style={{ width: "120px", wordWrap: "break-word" }}> {items?.country.phone_prefix}&nbsp;-&nbsp;{items.mobile ? items.mobile : items.userData.mobile}</div>{sepratorElement()}
																						<div className="" style={{ width: "170px", wordWrap: "break-word" }}> {items.userData.email}</div>
																					</div>
																					<div className="d-flex align-items-start justify-content-between" style={{ width: "130px" }}> {sepratorElement()}{moment.tz(renderDate, getTimeZone(items)).format('ll')}</div>
																				</div>
																			</li>
																		</>
																	)
																})
															}
														</>
													)
												} else if (currResult.categoryName == "transaction") {

													if (currResult.data.length == 1) {

														let currTransData = currResult.data[0];

														if (currTransData.transaction_no) {
															return (
																<>
																	{currIndx != 0 && <hr className="m-0" />}
																	<p className="mb-2" style={titleStyle}>{currResult.categoryName}</p>
																	{
																		currResult.data.map((item) => {

																			let title = "";
																			let subtitle = "";
																			if (item.type == 1) {
																				title = "Wallet top-up";
																				subtitle = item.payment_method == 4 ? "Receipt Payment" : item.payment_method == 5 ? "Receipt Payment" : `from **${item.card_number[14]}${item.card_number[15]}`;
																			} else if (item.type == 2) {
																				title = "Cashback from";
																				subtitle = "i-Pay Cashback"
																			} else if (item.type == 3) {

																				if (item.to_mechant._id == item.from_customer_id) {

																					if (item.request_token) {

																						title = "Requested By";
																					} else {
																						title = "Paid To";
																					}
																					if (item.to_wallet_type == 2 && item.to_mechant && item.to_mechant.length > 0) {
																						subtitle = item.to_mechant[0].displayName;
																					} else if (item.to_customers && item.to_customers.length > 0) {
																						subtitle = item.to_customers[0].displayName;
																					}
																					else if (item.card_number) {
																						subtitle = "**" + item.card_number.slice(-4);
																					}
																				} else {
																					title = "Received From";
																					// console.log(item, "item")
																					if (item.from_wallet_type == 2 && item.from_mechant && item.from_mechant.length > 0) {
																						subtitle = item.from_mechant[0].business_name;
																					} else if (item.from_customers && item.from_customers.length > 0) {
																						subtitle = item.from_customers[0].name;
																					}
																					else if (item.card_number) {
																						subtitle = "**" + item.card_number.slice(-4);
																					}
																				}

																			} else if (item.type == 4) {
																				title = "Settlement";
																				subtitle = "Settlement Request"
																			}

																			const convert_date = new Date(item.transaction_date);
																			const added_date = moment.tz(convert_date, getTimeZone()).format('ll');
																			let description = item.description && item.description.length > 40 ? `${item.description.substring(0, 40)}...` : '';

																			return (
																				<>
																					<li
																						className={currResult.data[currResult.data.length - 1] == item ? "mb-2" : ""}
																						style={listStyle}
																						onClick={() => {

																							history.push({
																								pathname: "/all_transactions",
																								state: {
																									transaction_id: item._id,
																									currentData: item
																								},
																							});
																							clearSearch();
																						}}
																					>
																						<div className="d-flex justify-content-between">
																							<div className="d-flex ">
																								<span className="mr-3" ><GrTransaction className="mt-0" color={"#000"} size={14} /></span>
																								<div className="text-left" style={{ width: "90px" }}> {item.transaction_no ? item.transaction_no : "NA"}</div> {sepratorElement()}
																								<div className="" style={{ width: "200px" }}>{title && subtitle ? `${title} ${subtitle}` : "NA"}</div> {sepratorElement()}
																								<div className="" style={{ width: "60px" }}> ${(item.pay_amount + item.charges).toFixed(2)}</div>{sepratorElement()}
																								<div >
																									{
																										item.type == 4 ? (getSettlementDetails(item))
																											: item.reference == 6 && item.status == 2 ? <p className="mb-0">Rejected</p>
																												: getStatus(item.status) == "Failed" ? <p className="mb-0" style={{ color: "Red" }}>{getStatus(item.status)}</p>
																													: item.status == 0 ? <p className="mb-0">{getStatus(item.status)}</p>
																														: item.status == 1 ? <p className="mb-0" >{getStatus(item.status)}</p>
																															: <p className="mb-0">{getStatus(item.status)}</p>
																									}
																								</div>
																							</div>
																							<div>{added_date}</div>
																						</div>
																					</li>
																				</>
																			)
																		})
																	}
																</>
															)
														}

													} else {
														return (
															<>
																{currIndx != 0 && <hr className="m-0" />}
																<p className="mb-2" style={titleStyle}>{currResult.categoryName}</p>
																{
																	currResult.data.map((item) => {

																		if (item.transaction_no) {

																			let title = "";
																			let subtitle = "";
																			if (item.type == 1) {
																				title = "Wallet top-up";
																				subtitle = item.payment_method == 4 ? "Receipt Payment" : item.payment_method == 5 ? "Receipt Payment" : `from **${item.card_number[14]}${item.card_number[15]}`;
																			} else if (item.type == 2) {
																				title = "Cashback from";
																				subtitle = "i-Pay Cashback"
																			} else if (item.type == 3) {

																				if (item.to_mechant._id == item.from_customer_id) {

																					if (item.request_token) {

																						title = "Requested By";
																					} else {
																						title = "Paid To";
																					}
																					if (item.to_wallet_type == 2 && item.to_mechant && item.to_mechant.length > 0) {
																						subtitle = item.to_mechant[0].displayName;
																					} else if (item.to_customers && item.to_customers.length > 0) {
																						subtitle = item.to_customers[0].displayName;
																					}
																					else if (item.card_number) {
																						subtitle = "**" + item.card_number.slice(-4);
																					}
																				} else {
																					title = "Received From";
																					// console.log(item, "item")
																					if (item.from_wallet_type == 2 && item.from_mechant && item.from_mechant.length > 0) {
																						subtitle = item.from_mechant[0].business_name;
																					} else if (item.from_customers && item.from_customers.length > 0) {
																						subtitle = item.from_customers[0].name;
																					}
																					else if (item.card_number) {
																						subtitle = "**" + item.card_number.slice(-4);
																					}
																				}

																			} else if (item.type == 4) {
																				title = "Settlement";
																				subtitle = "Settlement Request"
																			}

																			const convert_date = new Date(item.transaction_date);
																			const added_date = moment.tz(convert_date, getTimeZone()).format('ll');
																			let description = item.description && item.description.length > 40 ? `${item.description.substring(0, 40)}...` : '';

																			return (
																				<>
																					<li
																						className={currResult.data[currResult.data.length - 1] == item ? "mb-2" : ""}
																						style={listStyle}
																						onClick={() => {

																							history.push({
																								pathname: "/all_transactions",
																								state: {
																									transaction_id: item._id,
																									currentData: item
																								},
																							});
																							clearSearch();
																						}}
																					>
																						<div className="d-flex justify-content-between">
																							<div className="d-flex ">
																								<span className="mr-3" ><GrTransaction className="mt-0" color={"#000"} size={14} /></span>
																								<div className="text-left" style={{ width: "90px" }}> {item.transaction_no ? item.transaction_no : "NA"}</div> {sepratorElement()}
																								<div className="" style={{ width: "200px" }}>{title && subtitle ? `${title} ${subtitle}` : "NA"}</div> {sepratorElement()}
																								<div className="" style={{ width: "60px" }}> ${(item.pay_amount + item.charges).toFixed(2)}</div>{sepratorElement()}
																								<div >
																									{
																										item.type == 4 ? (getSettlementDetails(item))
																											: item.reference == 6 && item.status == 2 ? <p className="mb-0">Rejected</p>
																												: getStatus(item.status) == "Failed" ? <p className="mb-0" style={{ color: "Red" }}>{getStatus(item.status)}</p>
																													: item.status == 0 ? <p className="mb-0">{getStatus(item.status)}</p>
																														: item.status == 1 ? <p className="mb-0" >{getStatus(item.status)}</p>
																															: <p className="mb-0">{getStatus(item.status)}</p>
																									}
																								</div>
																							</div>
																							<div>{added_date}</div>
																						</div>
																					</li>
																				</>
																			)
																		}
																	})
																}
															</>
														)
													}
												} else if (currResult.categoryName == "settlements") {
													return (
														<>
															{currIndx != 0 && <hr className="m-0" />}
															<p className="mb-2" style={titleStyle}>{currResult.categoryName}</p>
															{
																currResult.data.map((items) => {

																	return (
																		<>
																			<li
																				className={currResult.data[currResult.data.length - 1] == items ? "mb-2" : ""}
																				style={listStyle}
																				onClick={() => {
																					history.push({
																						pathname: '/settlement/withdraws',
																						state: { transaction_id: items._id },
																					});
																					clearSearch();
																				}}
																			>
																				<div className="d-flex justify-content-between">
																					<div className="d-flex">
																						<span className="mr-3" ><FaRegHandshake className="mt-0" color={"#000"} size={15} /></span>
																						<div className="text-left" style={{ width: "120px" }}> {items.transaction_no}</div> {sepratorElement()}
																						<div className="" style={{ width: "200px" }}>{items.from_wallet_type == 1 && typeof items.from_customers[0] != "undefined" ? items.from_customers[0].name : typeof items.from_mechant[0] != "undefined" ? items.from_mechant[0].business_name : "NA"}</div> {sepratorElement()}
																						<div className="" style={{ width: "60px" }}> ${(items.pay_amount + items.charges).toFixed(2)}</div>{sepratorElement()}
																						<div className="" style={{ width: "100px" }}>
																							{
																								items.type == 4 ? (getSettlementDetails(items))
																									: items.reference == 6 && items.status == 2 ? <p className="mb-0">Rejected</p>
																										: getStatus(items.status) == "Failed" ? <p className="mb-0" style={{ color: "Red" }}>{getStatus(items.status)}</p>
																											: items.status == 0 ? <p className="mb-0">{getStatus(items.status)}</p>
																												: items.status == 1 ? <p className="mb-0" >{getStatus(items.status)}</p>
																													: <p className="mb-0">{getStatus(items.status)}</p>
																							}
																						</div>
																					</div>
																					<div className="d-flex align-items-start justify-content-end" style={{ width: "130px" }}>{moment.tz(items.transaction_date, getTimeZone(items)).format('ll')}</div>
																				</div>
																			</li>
																		</>
																	)
																})
															}
														</>
													)
												} else if (currResult.categoryName == "visitor") {
													return (
														<>
															{currIndx != 0 && <hr className="m-0" />}
															<p className="mb-2" style={titleStyle}>{currResult.categoryName}</p>
															{
																currResult.data.map((items) => {
																	let form_fields = JSON.parse(items.form_fields);
																	let CountryCode = '';
																	if (form_fields?.country_code !== undefined) {
																		CountryCode = `${form_fields.country_code}-`;
																	} else if (form_fields?.mobile_number && !form_fields.mobile_number.includes('+')) {
																		CountryCode = "+61"
																	}

																	return (
																		<>
																			<li
																				className={currResult.data[currResult.data.length - 1] == items ? "mb-2" : ""}
																				style={listStyle}
																				onClick={() => {
																					history.push({
																						pathname: '/hospo-visitors',
																						state: { visitor_id: items._id },
																					});
																					clearSearch();
																				}}
																			>
																				<div className="d-flex justify-content-between">
																					<div className="d-flex">
																						<span className="mr-2" ><VscPerson className="mt-0" color={"#000"} size={15} /></span>
																						<div className="mr-2" style={{ width: "120px" }}>{form_fields.Name ? form_fields.Name : "NA"}</div>
																						<span><RiSeparator className="mt-0" color={"#000"} size={15} style={{ width: '50px' }} /></span>
																						<div style={{ width: "120px" }}>{form_fields.mobile_number ? <>{CountryCode}{form_fields.mobile_number}</> : "NA"} </div>
																						<span><RiSeparator className="mt-0" color={"#000"} size={15} style={{ width: '50px' }} /></span>
																						<div>{form_fields.Email_id ? form_fields.Email_id : form_fields.Email ? form_fields.Email : "NA"}</div>
																					</div>
																					<div>{moment.tz(items.added_date, getTimeZone()).format('ll')}</div>
																				</div>
																			</li>
																		</>
																	)
																})
															}
														</>
													)
												} else if (currResult.categoryName == "order") {
													return (
														<>
															{currIndx != 0 && <hr className="m-0" />}
															<p className="mb-2" style={titleStyle}>{currResult.categoryName}</p>
															{
																currResult.data.map((items) => {
																	return (
																		<>
																			<li
																				className={currResult.data[currResult.data.length - 1] == items ? "mb-2" : ""}
																				style={listStyle}
																				onClick={() => {
																					history.push({
																						pathname: '/all_orders',
																						state: { order_id: items._id },
																					});
																					clearSearch();
																				}}
																			>
																				<div className="d-flex justify-content-between">
																					<div className="d-flex">
																						<span className="mr-2" ><RiTakeawayLine className="mt-0" color={"#000"} size={15} /></span>
																						<div className="" style={{ width: "60px" }}> {items?.order_number}</div> {sepratorElement()}
																						<div className={`${!items?.merchant?.business_name ? "text-center" : "text-left"}`} style={{ width: "120px" }}> {items?.merchant?.business_name ? items?.merchant?.business_name : "NA"}</div> {sepratorElement()}
																						<div className="" style={{ width: "75px" }}>{items.currency_symbol ? items.currency_symbol : "$"} {items.final_total} </div> {sepratorElement()}
																						<div className="" style={{ width: "100px" }}>{items?.customerInfo?.customerMobile ? `${items?.customerInfo?.customerMobilePrefix ? items?.customerInfo?.customerMobilePrefix : ""} ${items?.customerInfo?.customerMobile}` : "NA"} </div> {sepratorElement()}
																						<div className="" style={{ width: "170px" }}>{items?.customerInfo?.customerEmail} </div> {sepratorElement()}
																					</div>
																					<div className="d-flex align-items-start justify-content-end" style={{ width: "130px" }}>{moment.tz(items.updatedAt, getTimeZone(items)).format('ll')}</div>
																				</div>
																			</li>
																		</>
																	)
																})
															}
														</>
													)
												}
											})}
										</div>
										: <div className="" style={{}}>
											<div style={{ textAlign: "center", margin: "30px 0px" }}>
												<div style={{ fontSize: "3.5rem" }}>											<i className="icofont icofont-ui-search"></i>										</div>
												<div>
													<h5>No search results found</h5>
													<p className="m-0">Please try again with a different search query</p>
												</div>
											</div>
										</div>
								}
							</div>
						</div>
				}
			</div>
		</>
	);

}

export default MasterSearchBarResultComp;
