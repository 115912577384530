import React, { Component, useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormGroup, Col, Row, Input, CustomInput } from 'reactstrap';
import useForm from "react-hook-form";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

let EditDescriptiveModal = (props) => {

    let [loadingbtn, setloadingbtn] = useState(false);
    let [showerrorstatus, setshowerrorstatus] = useState(false);
    const [featureTitle, setFeatureTitle] = useState("")
    const [featureDesc, setFeatureDesc] = useState("")

    const { handleSubmit, register, errors } = useForm();

    useEffect(() => {
        if (!props.editFeatureDesc) return

        setFeatureTitle(props.editFeatureDesc.title)
        setFeatureDesc(props.editFeatureDesc.description)

    }, [props.editFeatureDesc]);


    const onSubmit = form_data => {
        // console.log(form_data, "form")
        if (form_data !== '') {
            let postData = {
                title: featureTitle,
                description: featureDesc,
                status: props.editFeatureDesc.status,
                feature_id: props.editFeatureDesc._id
            }

            setloadingbtn(true);

            client.post(api.edit_features, postData, async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                props.toggleModal(1);
                                setFeatureTitle("");
                                setFeatureDesc("")
                                toast.success("Updated Successfully");
                                props.setEditFeatureDesc(null)
                                // setcashbackdata(response.result.data);
                            } else {
                                toast.error(response.message);
                            }
                        } catch (e) {
                            toast.error("Internal error occured. Please contact support");
                        }
                    } else {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }

                setloadingbtn(false);
            });
        }
        else {
            setshowerrorstatus(true);
            errors.showMessages();
        }
    }



    return (

        <Modal isOpen={props.isOpen} toggle={props.toggleModal} className="modal-body" centered={true}>
            <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader toggle={props.toggleModal}>Edit Descriptive Features</ModalHeader>
                <ModalBody>

                    <div className="row">
                        <div className="col-md-12">
                            <FormGroup className="w-100">
                                <Label for="level_title">Feature Name</Label>
                                <Label for="level_title" style={{ marginLeft: "22%" }}>Feature Description</Label>
                                <Row form>
                                    <Col md={6}>
                                        <input
                                            name="features" className="form-control form-control-sm mb-2" type="text"
                                            ref={register({ required: true })}
                                            value={featureTitle}
                                            onChange={(e) => {
                                                setFeatureTitle(e.target.value)
                                            }}
                                        />
                                        <span>{errors.features && 'Feature is required'}</span>
                                    </Col>
                                    <Col md={6}>
                                        <input
                                            name="description" className="form-control form-control-sm mb-2" type="text"
                                            ref={register()}
                                            value={featureDesc}
                                            onChange={(e) => {
                                                setFeatureDesc(e.target.value)
                                            }}
                                        />
                                        {/* <span>{errors.description && 'Feature is required'}</span> */}
                                    </Col>
                                </Row>
                            </FormGroup>
                        </div>
                    </div>

                </ModalBody>
                <ModalFooter>
                    {!loadingbtn ?
                        <>
                            <Button color="default" type="button" onClick={props.toggleModal}>Cancel</Button>
                            <Button color="primary" type="submit" onClick={() => setshowerrorstatus(true)} >Update</Button>
                        </>
                        :
                        <div className="loader-box pull-right" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 20, width: 20 }}></span></div>
                    }
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default EditDescriptiveModal;