
import React, { useEffect, Fragment, useState, useRef } from "react";
import TextEditor from "./TextEditor";
import { DisappearedLoading } from "react-loadingg";

const PrivacyAndPolicy = ({
    api_domain,
    initialContent,
    submitContentData,
    fetchLoading,
    loading
}) => {

    const [contentData, setContentData] = useState('');


    const submitData = () => {

        let postData = {
            type: initialContent?.type || 3,
            contentDatas: contentData
        }

        submitContentData(postData);
    }

    return (
        <Fragment>
            {
                fetchLoading ?
                    // <div className="d-flex align-items-center justify-content-center" style={{ width: "100%", height: "40vh" }}>
                    //     <div className="px-4 py-4">
                    //         <h3 className="mx-0 mt-0 mb-2" style={{ color: '#3E3E3E' }}>Loading ...! </h3>
                    //     </div>
                    // </div>
                    <div className="loader-box" style={{ height: "60vh" }}>
                        <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                            <DisappearedLoading size="medium" color="#514F4E" />
                        </div>
                    </div>
                    :
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-xl-12 xl-100">
                                <div className="border d-flex align-items-center rounded" style={{}}>
                                    <div className="py-2 px-3 rounded-start" style={{ backgroundColor: "#f1f1f1", borderRight: "1px solid #dee2e6 " }} >
                                        <p className="m-0" style={{ fontSize: "14px" }}>{api_domain == 'api.ipaypro.co' ? "https://linky.biz" : "https://dm.linky.biz"}</p>
                                    </div>
                                    <div className="py-2 px-3"><p className="m-0" style={{ fontSize: "14px" }}>security</p></div>
                                </div>
                                <div className="mt-3 w-100">
                                    <TextEditor
                                        initialContent={initialContent}
                                        setContentData={setContentData}
                                    />
                                </div>
                                <div className="d-flex align-items-center justify-content-end mt-4">
                                    <div
                                        className="rounded"
                                        style={{
                                            padding: "0.5rem 2rem",
                                            color: "#fff",
                                            backgroundColor: "#327EBC",
                                            cursor: loading ? "disabled" : "pointer",
                                            fontSize: "14px"
                                        }}
                                        onClick={() => { submitData() }}
                                    >{loading ? "Please Wait.." : "Save"}</div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </Fragment>
    );
}

export default PrivacyAndPolicy;