import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../components/common/breadcrumb";
import client from "../Api/HTTPClient";
import api from "../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink as NavLinkTab } from 'reactstrap';
import * as moment from 'moment-timezone';
import { NavLink } from "react-router-dom";
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import {DisappearedLoading} from "react-loadingg";


// const BorderTable = () => {
function PendingVerification() {

    let [loadingbtn, setloadingbtn] = useState(false);
    let [loading, setloading] = useState(false);
    const [customersData, setCustomersData] = useState(null);
    const [merchantsData, setMerchantsData] = useState(null);
    let [tabIndex, setTabIndex] = useState(0);
    const [access_level, setAccessLevel] = useState(localStorage.getItem("accessLevel"));
    const [adminDetails, setAdminDetails] = useState(JSON.parse(localStorage.getItem("user")));


    useEffect(() => {
        // alert("hrer");
        getAllData()
    }, []);

    const getAllData = () => {
        let postdata = {pendingCustomer : true, type : 2 };
        client.post(api.getCustMerchData, postdata, (error, response) => {
            if (!error) {
                try {
                    if (!response.error) {
                        //console.log(response);
                        setMerchantsData(response.result.merchants);
                        setCustomersData(response.result.customers);

                    } else {
                        setTimeout(() => {
                            toast.error(response.message);
                        }, 200);
                    }
                } catch (e) {
                    setTimeout(() => {
                        toast.error("Internal error occured. Please contact support" + e.message);
                    }, 200);
                }

            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
            //   current.setState({ loading: false });
        });
    };

    //console.log(merchantsData);
    //console.log(customersData);

    return (
        <Fragment>
             <div className="col-lg-7 col-sm-12" style={{fontSize:"12px"}}>
            <Breadcrumb title={"Pending verifications"} parent={"Home"} /></div>
            {customersData && merchantsData ?
                <Fragment>
                    <div className="container-fluid">
                        <div className="row theme-tab">
                            <Tabs className="col-sm-12" style={{ padding: "0",backgroundColor:"white",borderRadius:"3px",boxShadow:"1px solid rgb(223, 232, 241)",marginBottom:"0px" }}>
                                <TabList className="tabs tab-title">
                                    <Tab className="current" style={{marginTop:"0.5rem",display:"flex",alignItems:"center"}} >
                                       <span style={{fontSize:"12px"}}> Merchant</span>
                                    </Tab>
                                    <Tab style={{marginTop:"0.5rem",display:"flex",alignItems:"center"}}>
                                        Customer
                                    </Tab>
                                </TabList>

                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-sm-12 col-xl-12 xl-100">
                                            <div className="card">
                                                <div className="card-body" style={{ padding: "0rem" }}>
                                                    <div className="tab-content-cls">
                                                        <TabPanel>
                                                            <div className="table-responsive" style={{ marginTop: 10, marginBottom: 20 }}>
                                                                <table className="table table-border-horizontal">
                                                                    <thead>
                                                                        <tr style={{background: '#f9fafe'}}>
                                                                            <th scope="col">#</th>
                                                                            <th scope="col">Merchant Name</th>
                                                                            <th scope="col">Date of Submission</th>
                                                                            <th scope="col">Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {merchantsData.length > 0 && merchantsData.reverse().filter(el => el.status == 1 && el.mobile_status == 1).map((item, index) => {
                                                                            return (
                                                                                <tr key={index + 1}>
                                                                                    <th>{++index}</th>
                                                                                    <td>{item.business_name}</td>
                                                                                    <td>{moment.tz(item.documents[0].updated_date, "Australia/Sydney").format('DD/MM/YYYY HH:MM:SS')}</td>
                                                                                    <td>
                                                                                        <NavLink to={{
                                                                                            pathname: `${process.env.PUBLIC_URL}/merchants/merchantDetails/${item._id}`,
                                                                                            search: "?activeTab=3"
                                                                                        }}>
                                                                                            <i className="fa fa-folder-open-o"></i>
                                                                                        </NavLink>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })}

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </TabPanel>

                                                        <TabPanel>
                                                            <div className="table-responsive" style={{ marginTop: 10, marginBottom: 20 }}>
                                                                <table className="table table-border-horizontal">
                                                                    <thead>
                                                                        <tr style={{background: '#f9fafe'}}>
                                                                            <th scope="col">#</th>
                                                                            <th scope="col">Customer Name</th>
                                                                            <th scope="col">Date of Submission</th>
                                                                            <th scope="col">Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {customersData.length > 0 && customersData.reverse().filter(el => el.status == 1 && el.is_verified == 2).map((item, index) => {
                                                                            return (
                                                                                <tr key={index + 1}>
                                                                                    <th>{++index}</th>
                                                                                    <td>{item.name}</td>
                                                                                    <td>{moment.tz(item.documents[0].updated_date, "Australia/Sydney").format('DD/MM/YYYY HH:MM:SS')}</td>
                                                                                    <td>
                                                                                        <NavLink to={{
                                                                                            pathname: `${process.env.PUBLIC_URL}/customer/wallet/${item._id}`,
                                                                                            search: "?activeTab=3"
                                                                                        }}>
                                                                                            <i className="fa fa-folder-open-o"></i>
                                                                                        </NavLink>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                        </TabPanel>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </Tabs>
                        </div>
                    </div>
                </Fragment> : <div className="loader-box" style={{height:"100vh",width:"100wh"}}>
                              <div className="loader" style={{ display:"flex",alignItems:"center" }}>
                              <DisappearedLoading size="medium" color="#514F4E" />
                               </div> 
                               </div>}

        </Fragment>
    );
}

export default PendingVerification;
