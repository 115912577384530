import React, { useState, useEffect } from 'react'
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    FormGroup,
    Col,
    Row,
    Input,
    Label,
    Nav,
    NavLink,
    NavItem,
    TabContent,
    TabPane
} from 'reactstrap';
import useForm from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import MaintenanceIcon from "../../../assets/staticImages/maintenance_icon.png";
import BeatLoader from "react-spinners/BeatLoader";
import classnames from 'classnames';
import "./undermaintenance.scss";

const UnderMaintenance = () => {

    const knownIssueData = {
        title: `Known issue`,
        descriptionData: `We're investigating reports of slow speeds in our web and desktop applications.we have all hands on deck and we'll have an update here every hour until we have a monitoring status.next update at or before 12: 20 pm AEST`
    };

    const WebUnderMaintanenceData = {
        title: `Website is currently under maintenance`,
        descriptionData: `The website is temporally down due to undergoing maintenance work. We apologize for the inconvenience this may cause you and encourage you to check back with us again shortly. Thank you.`
    };

    let { handleSubmit, register, setValue, reset, errors } = useForm({
        defaultValues: {
            titlename1: "",
            description1: "",
            titlename2: "",
            description2: ""
        }
    });


    const [showData, setShowData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectWebsite1, setSelectWebsite1] = useState(1);
    const [selectWebsite2, setSelectWebsite2] = useState(1);
    const [selectTemplate1, setSelecttemplate1] = useState(0);
    const [selectTemplate2, setSelecttemplate2] = useState(0);
    const [showerrorstatus, setshowerrorstatus] = useState(false);
    const [loadingbtn, setloadingbtn] = useState(false);
    const [activeTab, setActiveTab] = useState("1");

    useEffect(() => {

        if (showData) {

            // if (activeTab == "1" && showData.store_website.selectWebsite2 != 2) {
            //     setSelectWebsite1(2);
            //     setSelectWebsite2(1);
            // } else if (activeTab == "1" && showData.store_website.selectWebsite2 == 2) {
            //     setSelectWebsite1(2);
            //     setSelectWebsite2(2);
            // }

            // if (activeTab == "2" & showData.business_website.selectWebsite1 != 2) {
            //     setSelectWebsite1(1);
            //     setSelectWebsite2(2);
            // } else if (activeTab == "2" && showData.business_website.selectWebsite1 == 2) {
            //     setSelectWebsite1(2);
            //     setSelectWebsite2(2);
            // }

            if (activeTab == "1") {
                setSelectWebsite1(2);
                setSelectWebsite2(1);

            } else if (activeTab == "2") {
                setSelectWebsite1(1);
                setSelectWebsite2(2);
            }
        }

    }, [activeTab, selectWebsite1, selectWebsite2])



    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };



    const fetchData = () => {

        const postData = { getData: true };

        client.post(api.fetchMaintananceMode, postData, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            setShowData(response.result)

                        } else {
                            toast.error(response.message);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }
            setloadingbtn(false);
        });
    }

    useEffect(() => {

        fetchData()

    }, [])

    useEffect(() => {
        if (showData) {
            reset({
                titlename1: showData.business_website.title,
                description1: showData.business_website.description,
                titlename2: showData.store_website.title,
                description2: showData.store_website.description
            });
            setSelectWebsite1(showData.business_website.enable_maintanance == true ? showData.business_website.selectWebsite1 : 2);
            setSelectWebsite2(showData.store_website.enable_maintanance == true ? showData.store_website.selectWebsite2 : 1);
            setSelecttemplate1(showData.business_website.select_template);
            setSelecttemplate2(showData.store_website.select_template);
        }
    }, [showData]);



    const changeTemplate = (event) => {

        let value = event.target.value;
        setSelecttemplate1(value);

        if (value == 1) {
            setValue("titlename1", `${knownIssueData.title}`);
            setValue("description1", `${knownIssueData.descriptionData}`);

        } else if (value == 2) {
            setValue("titlename1", `${WebUnderMaintanenceData.title}`);
            setValue("description1", `${WebUnderMaintanenceData.descriptionData}`);

        } else {

            setValue("titlename1", "");
            setValue("description1", "");
        }
    }

    const changeTemplate2 = (event) => {

        let value = event.target.value;
        setSelecttemplate2(value);

        if (value == 1) {
            setValue("titlename2", `${knownIssueData.title}`);
            setValue("description2", `${knownIssueData.descriptionData}`);

        } else if (value == 2) {
            setValue("titlename2", `${WebUnderMaintanenceData.title}`);
            setValue("description2", `${WebUnderMaintanenceData.descriptionData}`);

        } else {
            setValue("titlename2", "");
            setValue("description2", "");
        }
    }


    const toggleMaintananceModal = () => {
        setShowModal(!showModal);
    }

    const onSubmitForm = (formValue) => {

        if (formValue !== '') {

            if (activeTab == "1" && selectWebsite1 < 2 || activeTab == "2" && selectWebsite2 < 2) {
                return false;
            }

            let postData = null

            if (activeTab == "1") {

                postData = {
                    ...showData,
                    UnderMaintenanceId: showData._id,
                    business_website: {
                        selectWebsite1: showData.business_website.enable_maintanance == true ? 0 : parseInt(selectWebsite1),
                        title: showData.business_website.enable_maintanance == true ? "" : formValue.titlename1,
                        description: showData.business_website.enable_maintanance == true ? "" : formValue.description1,
                        select_template: showData.business_website.enable_maintanance == true ? 0 : parseInt(selectTemplate1),
                        enable_maintanance: showData.business_website.enable_maintanance == true ? false
                            : formValue.titlename1 != "" ? true
                                : false
                    }
                };

            }

            if (activeTab == "2") {
                postData = {
                    ...showData,
                    UnderMaintenanceId: showData._id,
                    store_website: {
                        selectWebsite2: showData.store_website.enable_maintanance == true ? 0 : parseInt(selectWebsite2),
                        title: showData.store_website.enable_maintanance == true ? "" : formValue.titlename2,
                        description: showData.store_website.enable_maintanance == true ? "" : formValue.description2,
                        select_template: showData.store_website.enable_maintanance == true ? 0 : parseInt(selectTemplate2),
                        enable_maintanance: showData.store_website.enable_maintanance == true ? false
                            : formValue.titlename2 != "" ? true
                                : false
                    }
                };
            }


            setloadingbtn(true);

            client.post(api.enableMaintanance, postData, async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                setShowData(response.result)
                                toggleMaintananceModal();
                            } else {
                                toast.error(response.message);
                            }
                        } catch (e) {
                            toast.error("Internal error occured. Please contact support");
                        }
                    } else {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
                setloadingbtn(false);
            });
        }
        else {
            setshowerrorstatus(true);
            errors.showMessages();
        }
    }

    return (
        <>
            {
                !showData ?
                    <div
                        className='mr-2'
                    >
                        <BeatLoader
                            color={"#02A0F0"}
                            loading={true}
                            size="7"
                        />
                    </div> :
                    <div>
                        <div
                            className='mr-2'
                            style={{ cursor: "pointer" }}
                            onClick={() => toggleMaintananceModal()}
                        >
                            <img src={MaintenanceIcon} style={{ height: "100%", width: "25px" }} alt="MaintenanceIcon" />
                        </div>
                        <Modal
                            isOpen={showModal && showData != null}
                            toggle={toggleMaintananceModal}
                            centered
                        >
                            <form noValidate="" onSubmit={handleSubmit(onSubmitForm)} >
                                <ModalHeader toggle={toggleMaintananceModal}>
                                    Maintainence Mode
                                </ModalHeader>
                                <ModalBody>

                                    <div className='main_div'>
                                        <div style={{ padding: "15px 15px 0px 0px" }}>
                                            {/* <Row>
                                                <Col>
                                                    <FormGroup style={{ marginBottom: `${showerrorstatus && (selectWebsite2 < 2 && selectWebsite1 < 2) ? '6px' : "1rem"}` }}>
                                                        <div className="d-flex ">
                                                            <div className="radio radio-primary">
                                                                <input className="radio_animated"
                                                                    id="radioinline1"
                                                                    type="radio"
                                                                    checked={selectWebsite1 == 2 ? true : false}
                                                                    name="selectproject"
                                                                    value={selectWebsite1}
                                                                    style={{ margin: "2px" }}
                                                                    onClick={(e) => {
                                                                        if (e.target.value == 1) {
                                                                            setSelectWebsite1(2)
                                                                        } else if (e.target.value == 2 && selectWebsite2 != 1) {
                                                                            setSelectWebsite1(1)
                                                                        }
                                                                    }}
                                                                />
                                                                <label className="mb-0" for="radioinline1">dm.business.ipaypro.co</label>
                                                            </div>
                                                            <div className="radio radio-primary ml-3">
                                                                <input className="radio_animated"
                                                                    id="radioinline2"
                                                                    type="radio"
                                                                    name="selectproject1"
                                                                    value={selectWebsite2}
                                                                    checked={selectWebsite2 == 2 ? true : false}
                                                                    onClick={(e) => {
                                                                        if (e.target.value == 1) {
                                                                            setSelectWebsite2(2);
                                                                        } else if (e.target.value == 2 && selectWebsite1 != 1) {
                                                                            setSelectWebsite2(1);
                                                                        }

                                                                    }}
                                                                />
                                                                <label className="mb-0" for="radioinline2">dm.ipaypro.co</label>
                                                            </div>
                                                        </div>
                                                    </FormGroup>
                                                    <span style={{ color: 'red', fontSize: "13px" }}>{showerrorstatus && (selectWebsite2 < 2 && selectWebsite1 < 2) ? 'Select atleast one website to enable popup' : showerrorstatus}</span>
                                                </Col>
                                            </Row> */}
                                            <Nav tabs style={{ width: '100%' }}>
                                                <NavItem style={{ width: "50%" }}>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === '1' })}
                                                        onClick={() => { toggle('1') }}
                                                    >
                                                        <div className="radio radio-primary" style={{ padding: "7px" }}>
                                                            <input className="radio_animated"
                                                                id="radioinline1"
                                                                type="radio"
                                                                checked={selectWebsite1 == 2 ? true : false}
                                                                name="selectproject"
                                                                value={selectWebsite1}
                                                                style={{ margin: "2px" }}
                                                            // onClick={(e) => {
                                                            //     if (activeTab == "1" && e.target.value == 1) {
                                                            //         setSelectWebsite1(2)
                                                            //     } else if (e.target.value == 2 && selectWebsite2 != 1) {
                                                            //         setSelectWebsite1(1)
                                                            //     }
                                                            // }}
                                                            />
                                                            <label className="mb-0" for="radioinline1">dm.business.ipaypro.co</label>
                                                        </div>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem style={{ width: "50%" }}>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === '2' })}
                                                        onClick={() => { toggle('2') }}
                                                    >
                                                        <div className="radio radio-primary ml-3" style={{ padding: "7px" }}>
                                                            <input className="radio_animated"
                                                                id="radioinline2"
                                                                type="radio"
                                                                name="selectproject1"
                                                                value={selectWebsite2}
                                                                checked={selectWebsite2 == 2 ? true : false}
                                                            // onClick={(e) => {
                                                            //     if (activeTab == "2" && e.target.value == 1) {
                                                            //         setSelectWebsite2(2);
                                                            //     } else if (e.target.value == 2 && selectWebsite1 != 1) {
                                                            //         setSelectWebsite2(1);
                                                            //     }

                                                            // }}
                                                            />
                                                            <label className="mb-0" for="radioinline2">dm.ipaypro.co</label>
                                                        </div>
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>
                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId="1">
                                                {
                                                    activeTab == "1" ?
                                                        <div
                                                            style={{
                                                                padding: "15px",
                                                                pointerEvents: `${showData && showData.business_website.enable_maintanance ? "none" : "pointer"}`
                                                            }}                                    >

                                                            <Row >
                                                                <Col md={12}>
                                                                    <FormGroup style={{ marginTop: `${showerrorstatus && (selectWebsite2 < 2 && selectWebsite1 < 2) ? '6px' : "0px"}` }}>
                                                                        {
                                                                            showData && showData.business_website.enable_maintanance ?
                                                                                <Label for="Message" style={{ color: "red" }}>Website is under maintenance mode:</Label>
                                                                                :
                                                                                <Label for="Message">Message:</Label>
                                                                        }

                                                                        <div className="mt-3">
                                                                            <Row className=''>
                                                                                <Col md={3}>
                                                                                    <label className="mb-0" for="titlename1">Title </label>
                                                                                </Col>
                                                                                <Col md={9}>

                                                                                    <input type="text" id="titlename1" name="titlename1"
                                                                                        className="form-control"
                                                                                        style={{ width: "100%", height: "35px", padding: "10px", fontSize: "15px" }}
                                                                                        ref={register({ required: true })}
                                                                                        onChange={(event) => setValue("titlename1", event.target.value)}
                                                                                    />
                                                                                    <span style={{ color: 'red', fontSize: "13px" }}>{(activeTab == "1" && errors.titlename1) && 'Title is required'}</span>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row className='mt-3'>
                                                                                <Col md={3}>
                                                                                    <label className="mb-0" for="description1">Description</label>
                                                                                </Col>
                                                                                <Col md={9}>
                                                                                    <textarea id="description1" name="description1" rows="4" cols="50"
                                                                                        className="form-control"
                                                                                        style={{ width: "100%", padding: "10px", fontSize: "15px" }}
                                                                                        ref={register({ required: true })}
                                                                                        onChange={(event) => setValue("description1", event.target.value)}
                                                                                    ></textarea>
                                                                                    <span style={{ color: 'red', fontSize: "13px" }}>{(activeTab == "1" && errors.description1) && 'Description is required'}</span>
                                                                                </Col>
                                                                            </Row>

                                                                        </div>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row >
                                                                <Col md={12}>
                                                                    <FormGroup>
                                                                        <Label for="Message">Template for:</Label>
                                                                        <div className="mt-2">
                                                                            <select
                                                                                className="form-control select"
                                                                                name="select_template1"
                                                                                id="select_template1"
                                                                                style={{ fontSize: "15px" }}
                                                                                defaultValue={showData ? showData.business_website.select_template : 0}
                                                                                onChange={event => changeTemplate(event)}
                                                                            >
                                                                                <option value={0}>Select template for</option>
                                                                                <option value={1}>Known issue</option>
                                                                                <option value={2}>Website is currently under maintenance</option>
                                                                            </select>
                                                                        </div>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        : null
                                                }
                                                {/* <div
                                                    style={{
                                                        display: "flex",
                                                        borderTop: "1px solid gainsboro",
                                                        paddingTop: "17px",
                                                        justifyContent: `${showData && showData.business_website.enable_maintanance ? "between" : "flex-end"}`
                                                    }}
                                                >
                                                    <Button
                                                        className='mr-3'
                                                        color="secondry"
                                                        onClick={toggleMaintananceModal}
                                                    >
                                                        Not Sure!
                                                    </Button>
                                                    <Button
                                                        style={{ padding: `${showData && showData.business_website.enable_maintanance ? "0.37rem 1rem" : "0.37rem 1.7rem"}` }}
                                                        color="primary"
                                                        type="submit"
                                                        onClick={() => setshowerrorstatus(true)} >{`${showData && showData.business_website.enable_maintanance ? "Remove website from maintenance mode" : "Yes, Please!"}`}</Button>
                                                </div> */}
                                            </TabPane>

                                            <TabPane tabId="2">
                                                {
                                                    activeTab == "2" ?
                                                        <div
                                                            style={{
                                                                padding: "15px",
                                                                pointerEvents: `${showData && showData.store_website.enable_maintanance ? "none" : "pointer"}`
                                                            }}                                    >

                                                            <Row >
                                                                <Col md={12}>
                                                                    <FormGroup style={{ marginTop: `${showerrorstatus && (selectWebsite2 < 2 && selectWebsite1 < 2) ? '6px' : "0px"}` }}>
                                                                        {
                                                                            showData && showData.store_website.enable_maintanance ?
                                                                                <Label for="Message" style={{ color: "red" }}>Website is under maintenance mode:</Label>
                                                                                :
                                                                                <Label for="Message">Message:</Label>
                                                                        }

                                                                        <div className="mt-3">
                                                                            <Row className=''>
                                                                                <Col md={3}>
                                                                                    <label className="mb-0" for="titlename2">Title </label>
                                                                                </Col>
                                                                                <Col md={9}>

                                                                                    <input type="text" id="titlename2" name="titlename2"
                                                                                        className="form-control"
                                                                                        style={{ width: "100%", height: "35px", padding: "10px", fontSize: "15px" }}
                                                                                        ref={register({ required: true })}
                                                                                        onChange={(event) => setValue("titlename2", event.target.value)}
                                                                                    />
                                                                                    <span style={{ color: 'red', fontSize: "13px" }}>{(activeTab == "2" && errors.titlename2) && 'Title is required'}</span>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row className='mt-3'>
                                                                                <Col md={3}>
                                                                                    <label className="mb-0" for="description">Description</label>
                                                                                </Col>
                                                                                <Col md={9}>
                                                                                    <textarea id="description2" name="description2" rows="4" cols="50"
                                                                                        className="form-control"
                                                                                        style={{ width: "100%", padding: "10px", fontSize: "15px" }}
                                                                                        ref={register({ required: true })}
                                                                                        onChange={(event) => setValue("description2", event.target.value)}
                                                                                    ></textarea>
                                                                                    <span style={{ color: 'red', fontSize: "13px" }}>{(activeTab == "2" && errors.description2) && 'Description is required'}</span>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row >
                                                                <Col md={12}>
                                                                    <FormGroup>
                                                                        <Label for="Message">Template for:</Label>
                                                                        <div className="mt-2">
                                                                            <select
                                                                                className="form-control select"
                                                                                name="select_template2"
                                                                                id="select_template2"
                                                                                style={{ fontSize: "15px" }}
                                                                                defaultValue={showData ? showData.store_website.select_template : 0}
                                                                                onChange={event => changeTemplate2(event)}
                                                                            >
                                                                                <option value={0}>Select template for</option>
                                                                                <option value={1}>Known issue</option>
                                                                                <option value={2}>Website is currently under maintenance</option>
                                                                            </select>
                                                                        </div>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        : null
                                                }
                                                {/* <div
                                                    style={{
                                                        borderTop: "1px solid gainsboro",
                                                        paddingTop: "17px",
                                                        display: "flex",
                                                        justifyContent: `${showData && showData.store_website.enable_maintanance ? "between" : "flex-end"}`
                                                    }}
                                                >
                                                    <Button
                                                        className='mr-3'
                                                        color="secondry"
                                                        onClick={toggleMaintananceModal}
                                                    >
                                                        Not Sure!
                                                    </Button>
                                                    <Button
                                                        style={{ padding: `${showData && showData.store_website.enable_maintanance ? "0.37rem 1rem" : "0.37rem 1.7rem"}` }}
                                                        color="primary"
                                                        type="submit"
                                                        onClick={() => setshowerrorstatus(true)} >{`${showData && showData.store_website.enable_maintanance ? "Remove website from maintenance mode" : "Yes, Please!"}`}</Button>
                                                </div> */}
                                            </TabPane>

                                        </TabContent>

                                    </div>
                                </ModalBody>
                                <ModalFooter
                                    style={{
                                        display: "flex",
                                        justifyContent: `${showData && showData.enable_maintanance ? "between" : "flex-end"}`
                                    }}
                                >
                                    <Button
                                        color="secondry"
                                        onClick={toggleMaintananceModal}
                                    >
                                        Not Sure!
                                    </Button>
                                    <Button
                                        style={{
                                            padding: `${showData && showData.business_website.enable_maintanance ? "0.37rem 1rem"
                                                : showData && showData.store_website.enable_maintanance ? "0.37rem 1rem"
                                                    : "0.37rem 1.7rem"}`
                                        }}
                                        color="primary"
                                        type="submit"
                                        onClick={() => setshowerrorstatus(true)} >
                                        {`${showData && (activeTab == "1" && showData.business_website.enable_maintanance) ? "Remove website from maintenance mode"
                                            : showData && (activeTab == "2" && showData.store_website.enable_maintanance) ? "Remove website from maintenance mode"
                                                : "Yes, Please!"}`}
                                    </Button>
                                </ModalFooter>
                            </form>
                        </Modal>
                    </div>
            }
        </>
    )

}

export default UnderMaintenance;