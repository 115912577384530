import React, { Fragment, useState, useEffect, useLayoutEffect } from "react";
import { X } from "react-feather";
import BeatLoader from 'react-spinners/PulseLoader';
import './CustomerStyle.scss';
// import { fetchCountryDataActions } from '../../../store/actions/countryDataActions';
import { useSelector, useDispatch } from "react-redux";
import Select from 'react-select';
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { ModalBody, Modal, Button } from "reactstrap";
import { components } from "react-select";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { DisappearedLoading } from "react-loadingg";

// import Loader from '../../common/loader';
// import keys from "../../../keys";
// import { count } from "./smsCounter";
// import HashLoader from "@/components/GlobalLoader/Loader";


function useWindowSize(wrapper) {
    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);

    return size;
}

function moveElement(array) {

    let arrangeCountry = [...array];

    let australia = arrangeCountry.map((e) => e.country_code).indexOf('AU');
    if (australia != -1) {
        arrangeCountry.splice(0, 0, arrangeCountry.splice(australia, 1)[0]);
    }

    let japanIdx = arrangeCountry.map((e) => e.country_code).indexOf('JP');
    if (japanIdx != -1) {
        arrangeCountry.splice(1, 0, arrangeCountry.splice(japanIdx, 1)[0]);
    }

    let thailand = arrangeCountry.map((e) => e.country_code).indexOf('TH');
    if (thailand != -1) {
        arrangeCountry.splice(2, 0, arrangeCountry.splice(thailand, 1)[0]);
    }

    let unitedState = arrangeCountry.map((e) => e.country_code).indexOf('US');
    if (unitedState != -1) {
        arrangeCountry.splice(3, 0, arrangeCountry.splice(unitedState, 1)[0]);
    }

    let india = arrangeCountry.map((e) => e.country_code).indexOf('IN');
    if (india != -1) {
        arrangeCountry.splice(4, 0, arrangeCountry.splice(india, 1)[0]);
    }

    return arrangeCountry;
}

let DefaultRegex = null;
const validate = values => {
    let errors = {};
    let MobileNoValidation = new RegExp(DefaultRegex);
    if (!MobileNoValidation.test(values.mobile)) {
        errors.mobile = "errors.invalid_mobile";
    }
    return errors;
};

const ValueContainer = ({ children, ...props }) => {
    const { getValue, hasValue } = props;
    const value = getValue();
    const newChildren = [...children]
    newChildren[0] = `${value[0].country_code}`;
    // console.log(value,newChildren)
    if (hasValue && value?.length && value[0].country_code) {
        return (
            <components.ValueContainer {...props}>
                {newChildren}
            </components.ValueContainer>
        )
    } else {
        return (
            <components.ValueContainer {...props}>{children}</components.ValueContainer>
        )
    }
};

const SendSMSModal = ({
    smsModalClick,
    toggleSMSModal
}) => {

    const { pageWidth } = useWindowSize();
    const dispatch = useDispatch();

    // const countryData = useSelector(state => state.countryDataReducer.countryData);
    const merchantData = useSelector(state => state.auth.registeredUser);
    // const Wallet = useSelector((state) => state.walletReducer.walletData);

    // const mobileRegex = countryData.find(val => val.country_code == merchantData.country.code)?.phone_regex;
    // const defaultCountry = countryData.find(val => val.country_code == merchantData.country.code);
    const [isLoading, setLoading] = useState(false);
    const [mobilePrefixData, setMobilePrefixData] = useState(null);
    const [getMobileRegex, setGetMobileRegex] = useState(null);
    const [mobOptions, setMobOptions] = useState([]);
    const [smsCharges, setSMSCharges] = useState([]);
    const [exchangeData, setExchangeData] = useState({});
    const [showWalletLowError, setShowWalletLowError] = useState(false);
    const [error, setError] = useState({ value: false, msg: '', type: 1 });
    const [mobileError, setMobileError] = useState({ value: false, msg: 'Invalid Mobile' });
    const [totalCharge, setTotalCharge] = useState(0.00);
    const [totalSMS, setTotalSMS] = useState(0);

    const [selectOptionsOpen, setSelectOptionsOpen] = useState(false);
    const [formData, setFormData] = useState({
        mobprefix: { value: "Australia", label: "Australia (+61)", country_code: `+61` },
        mobile: '',
        message: ''
    });

    const [countryData, setCountryData] = useState([]);
    const [sender, setSender] = useState({ value: 2, label: "Linky" });
    console.log(countryData, "countryData")

    // const storeName = `\n- ${merchantData?.business_name}`;

    const fetchCountries = () => {

        client.post(api.fetch_country, { show_all: true }, async function (error, response) {

            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            let arrangeCountry = [...response.result.data];
                            arrangeCountry = moveElement(arrangeCountry);
                            setCountryData(arrangeCountry)
                        }
                    } catch {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }

        });
    }

    useEffect(() => {
        fetchCountries();
        fetchCharges();
    }, [])

    const fetchCharges = () => {
        let postData = { all: true }
        client.post(api.fetchSMSCharges, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    let a = response.result.data.map(val => {
                        return { value: val.country, label: `${val.country} (+${val.country_code})`, country_code: `+${val.country_code}`, regex: val.phone_regex }
                    })
                    setMobOptions(a);
                    setSMSCharges(response.result.data);
                } else {
                }
            } else {
            }

        });
    }




    // useEffect(() => {
    //     if (!Object.keys(exchangeData).length) {
    //         getCurrencyData()
    //     }
    // }, [])


    // useEffect(() => {
    //     let phone_prefix = merchantData?.country?.phone_prefix;

    //     if (sendSmsContactData && mobOptions.length > 0) {
    //         let getCurrData = mobOptions.find((currElem) => currElem.country_code == sendSmsContactData.mobile_prefix);
    //         setFormData({
    //             ...formData,
    //             mobprefix: { value: getCurrData?.value, label: getCurrData?.label, country_code: getCurrData?.country_code },
    //             mobile: sendSmsContactData.mobile_number
    //         });

    //     } else if (mobOptions.length && phone_prefix) {
    //         let _ = mobOptions.find(e => e?.country_code == phone_prefix);
    //         setFormData({ ...formData, mobprefix: _ })
    //     }

    // }, [mobOptions, merchantData, sendSmsContactData])


    useEffect(() => {

        if (formData && countryData.length > 0) {
            let mobileRegex = countryData.find(val => val.country_name == formData.mobprefix.value)?.phone_regex;
            setGetMobileRegex(mobileRegex)
        }

    }, [formData.mobile, formData.mobprefix])


    // useEffect(() => {

    //     if (!Wallet.length) {
    //         dispatch(fetchWalletDataActions({ customer_id: merchantData._id }));
    //     }

    //     if (!countryData.length) {
    //         dispatch(fetchCountryDataActions({ show_all: true, }))
    //     }

    //     fetchCharges()

    // }, [])

    // useEffect(() => {
    //     if (smsCharges.length) {
    //         let findCountryTotal = smsCharges.find(val => val.country == formData.mobprefix.value).total
    //         let total_sms = count(formData.message)?.messages;
    //         findCountryTotal = findCountryTotal * total_sms;
    //         // let mobileRegex = countryData?.find(val => val.country_name == formData.mobprefix.value)?.phone_regex;
    //         // setGetMobileRegex(mobileRegex)
    //         // let toCurrency = defaultCountry.currency_code;
    //         // let findV = exchangeData[toCurrency];
    //         // findCountryTotal = findCountryTotal * findV
    //         setTotalCharge(parseFloat(findCountryTotal)?.toFixed(2))
    //         setTotalSMS(total_sms)
    //     }
    // }, [formData.message, formData.mobprefix.label, smsCharges.length])


    useEffect(() => {

        if (countryData.length > 0) {
            let gerPrefixData = [];
            countryData.map((val) => {
                gerPrefixData.push({ value: val.country_code, label: val.phone_prefix, regex: val.phone_regex });
            });
            setMobilePrefixData(gerPrefixData);
        }

    }, [countryData])


    // useEffect(() => {

    //     if (formData.mobile && formData.mobprefix && getMobileRegex) {

    //         let mobileRegex = countryData.find(val => val.country_name == formData.mobprefix.value)?.phone_regex;
    //         let MobileNoValidation = new RegExp(mobileRegex);
    //         if (!MobileNoValidation.test(formData.mobile)) {
    //             setMobileError({ value: true, msg: t('common:errors.invalid_mobile') })
    //         }
    //     }
    // }, [formData.mobile, formData.mobprefix, getMobileRegex, countryData])

    const SendSMS = () => {
        console.log(formData)
        if (!formData.mobprefix.value || !formData.mobile) {
            setMobileError({ value: true, msg: 'Enter mobile number' })
        } else if (!formData.message) {
            setError({ value: true, msg: 'Please enter message', type: 2 })
            // } else if (Wallet?.wallet_amount < totalCharge) {
            //     setShowWalletLowError(true)
        } else {
            let country_code = countryData.find(val => val.country == formData.mobprefix.value)?.country_code
            // const postData = { country_code, ...formData, message: `${formData.message}` };
            const postData = {
                message: `${formData.message}`,
                country_code: `${formData.mobprefix.country_code}`,
                mobile: `${formData.mobile}`,
                from: sender.value,
            }
            setLoading(true);
            // setSendSMS(false);
            client.post(api.sendSMS, postData, (error, response) => {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                setLoading(false);
                                toggleSMSModal()
                                // dispatch(setAllData(response.result))
                                toast.success('Message sent successfully')
                            } else {
                                toast.error(response.message);
                                setLoading(false);
                            }
                        } catch (e) {
                            toast.error('Internal error occurred.');
                            setLoading(false);
                        }
                    } else {
                        toast.error('Internal error occurred.');
                        setLoading(false);
                    }
                } else {
                    toast.error('Internal error occurred.');
                    setLoading(false);
                }
            });
        }
    };


    const customStyles = {
        control: styles => ({
            ...styles,
            width: "100px !important",
            height: '43px !important',
            minHeight: '43px !important',
            borderRadius: "0.3rem 0rem 0rem 0.3rem !important",

        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '43px !important',
            padding: '0',
            justifyContent: 'center'

        }),
        option: styles => ({
            ...styles,
            textAlign: 'left'
        }),
        menu: styles => ({
            ...styles,
            width: '200px'
        })

    };

    useEffect(() => {
        let MobileNoValidation = new RegExp(DefaultRegex);
        console.log(formData.mobprefix, "formData.mobprefix")
        if (formData?.mobile && !MobileNoValidation.test(formData.mobile)) {
            setMobileError({ value: true, msg: "Invalid Mobile" })
        } else {
            setMobileError({ value: false, msg: "" })
        }
    }, [formData?.mobile])


    return (
        <div>
            <Modal
                isOpen={smsModalClick}
                toggle={toggleSMSModal}
                centered
                style={{ maxWidth: pageWidth <= 480 ? "90%" : "420px" }}
            >
                <ModalBody>
                    <div className="addCustomerForm addCustomerPopup ">
                        <div className={`add_customer`} style={{}}>
                            <div className="sidemodal__close d-flex align-items-center" style={{ borderBottom: "none", padding: "0.5rem 0.5rem 2.5rem 0.5rem" }}>
                                {/* <X onClick={toggleSMSModal} style={{ cursor: "pointer", marginInline: "0px", color: 'gray', top: "-1%", right: "-2%" }} className="cross_modal_btn" /> */}
                                <h5 className="m-0">
                                    <b style={{ fontSize: "20px", fontWeight: '500', color: 'rgb(70, 74, 81)' }}>
                                        Send Quick SMS
                                    </b>
                                </h5>
                                <X onClick={toggleSMSModal} style={{ cursor: "pointer", marginInline: "0px", color: 'gray', top: "-1%", right: "-2%" }} className="cross_modal_btn" />

                            </div>
                            {
                                !smsCharges?.length ?
                                    <div style={{ padding: "0.5rem", width: "100%", height: "100%" }} >
                                        <div className="mb-3 d-flex flex-column align-items-center">
                                            <DisappearedLoading size="medium" color="#514F4E" />
                                        </div>
                                    </div>
                                    :
                                    <>
                                        <div style={{ padding: "0.5rem 0.5rem" }} >
                                            <div className="mb-3 d-flex flex-column align-items-start">
                                                <label style={{ fontWeight: "500", marginBottom: "0.2rem" }}>
                                                    From (Sender ID)
                                                </label>
                                                <div className="form__form-group-input-wrap" style={{ position: "relative", width: '100%' }}>
                                                    <Select
                                                        name="from_sender"
                                                        options={[{ value: 5, label: 'TwoHandsBar' }, { value: 2, label: "Linky" }]}
                                                        // styles={customStyles}
                                                        margin="normal"
                                                        value={sender}
                                                        onChange={(e) => { setSender(e) }}
                                                        classNamePrefix="react-select"
                                                    />
                                                    {/* <input
                                                        type="text"
                                                        disabled={true}
                                                        value={"Linky"}
                                                        className="text-capitalize w-100"
                                                        style={{ height: "43px", padding: "5px 8px", border: "none" }}
                                                    /> */}
                                                </div>
                                            </div>

                                            <div className="mb-3 d-flex flex-column align-items-start">
                                                <label style={{ fontWeight: "500", marginBottom: "0.2rem" }}>
                                                    Enter mobile number <span style={{ color: '#ff0000' }}>*</span></label>
                                                <div className="w-100 d-flex" >
                                                    <Select
                                                        name="mobile_prefix"
                                                        // countryData={countryData}
                                                        options={mobOptions}
                                                        // setGetMobileRegex={setGetMobileRegex}
                                                        maxMenuHeight={230}
                                                        showDropDown={true}
                                                        fullWidth
                                                        styles={customStyles}
                                                        margin="normal"
                                                        menuIsOpen={selectOptionsOpen}
                                                        onMenuOpen={() => setSelectOptionsOpen(true)}
                                                        onMenuClose={() => setSelectOptionsOpen(false)}
                                                        variant="outlined"
                                                        isMulti={false}
                                                        value={formData.mobprefix}
                                                        onChange={(e) => { setFormData({ ...formData, mobprefix: e }) }}
                                                        classNamePrefix="react-select"
                                                        className="select-main-phone"
                                                        isSearchable={false}
                                                        components={{
                                                            ValueContainer: ValueContainer,
                                                            IndicatorSeparator: () => null
                                                        }}
                                                    />

                                                    <input
                                                        name="mobile"
                                                        type="number"
                                                        className="rounded form-control"
                                                        style={{ height: '43px', width: "100%", borderRadius: "0rem 0.3rem 0.3rem 0rem", padding: "10px" }}
                                                        value={formData.mobile}
                                                        onClick={() => setSelectOptionsOpen(false)}
                                                        onChange={(e) => {
                                                            // if(getMobileRegex && !new RegExp(getMobileRegex).test(e.target.value)){
                                                            //     setMobileError({ value: false, msg: 'Invalid Mobile' })
                                                            // }
                                                            setFormData({ ...formData, mobile: e.target.value })
                                                        }}
                                                    />
                                                </div>
                                                {/* {mobileError.value && (
                                                    <p className="m-0 p-0" style={{ width: 'max-content', color: 'red' }}>{mobileError.msg}</p>
                                                )} */}
                                                {getMobileRegex && formData ? new RegExp(getMobileRegex)?.test(formData.mobile) ? null : <div style={{ color: "red" }}>Number not valid</div> : null}

                                            </div>

                                            <div className="mb-3 d-flex flex-column align-items-start form__form-group-input-wrap form__form-group-input-wrap--error-above" style={{ marginBottom: 0 }}>
                                                <div className="w-100 d-flex align-items-center justify-content-between">
                                                    <label style={{ fontWeight: "500", marginBottom: "0.2rem" }}>Message <span style={{ color: '#ff0000' }}>*</span></label>
                                                    <label style={{ fontWeight: "500", marginBottom: "0.2rem" }}> {formData.message.length + sender.label.length} chars / {totalSMS} SMS </label>
                                                </div>
                                                <textarea
                                                    className="w-100 mini_site_biz_input textarea-custom-h border p-2 textarea-focus-visible-none my-1 remove-focus-text-ar"
                                                    defaultValue={formData?.message}
                                                    onChange={(e) => {
                                                        setError({ value: false, msg: '', type: 1 })
                                                        if ((e.target.value.length) <= 306) {
                                                            setFormData({ ...formData, message: e.target.value })
                                                        }
                                                    }} maxLength={306 - sender.label.length} style={{ borderRadius: '5px', padding: '1rem', color: 'rgb(49, 49, 49)' }}
                                                ></textarea>
                                                <div style={{ textAlign: "start" }}>{sender.label}</div>
                                                <span>{formData.message.length + sender.label.length}/306 character limit</span>
                                                {error.value && error.type == 2 && (
                                                    <p className="m-0 p-0" style={{ width: 'max-content', color: 'red' }}>{error.msg}</p>
                                                )}
                                            </div>

                                            {/* <div className="mb-3 w-100 d-flex align-items-center justify-content-between">
                                                <span style={{ fontWeight: 500, fontSize: '15px' }}>{t('send_sms.send_sms_modal.total')}</span>
                                                <span style={{ fontWeight: 500, fontSize: '15px' }}> {ShowCurrencyNum(totalCharge, true)} </span>
                                            </div> */}
                                        </div>

                                        {/* <div className="mx-2 mb-2">
                                            {
                                                showWalletLowError && <Elements stripe={stripePromise}>
                                                    <EwalletTopUp
                                                        buttonStyle={3}
                                                        setShowWalletLowError={setShowWalletLowError}
                                                        customStyles={true}
                                                    />
                                                </Elements>
                                            }
                                        </div> */}

                                        <div
                                            className="sidemodal__footer sidemodal__footer2 py-2 px-0"
                                            style={{ position: "", bottom: "0px", left: "0", right: "0" }}
                                        >
                                            <div className="d-flex" style={{ gap: "15px" }}>
                                                <Button
                                                    className="mb-0"
                                                    color="default"
                                                    style={{  width: "100%", height: "100%", fontSize: '16px', padding: '1rem 0rem' }}
                                                    onClick={toggleSMSModal}
                                                >
                                                    Close
                                                </Button>
                                                <Button className="mb-0 sendSMSButton-modal"
                                                    color="primary"
                                                    onClick={SendSMS}
                                                    disabled={(getMobileRegex && formData && new RegExp(getMobileRegex)?.test(formData.mobile)) ? false : true}
                                                    style={{ width: "100%", height: "100%", fontSize: '16px', padding: '1rem 0rem', color: "#fff" }}
                                                >
                                                    {isLoading || !smsCharges?.length ? <BeatLoader color={"#fff"} loading={true} size="11" /> : 'Send Now'}
                                                </Button>
                                            </div>
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default SendSMSModal;

