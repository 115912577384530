import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../../../components/common/breadcrumb';

import client from "../../../../Api/HTTPClient";
import api from "../../../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import { Link as NavLink } from "react-router-dom";
import useForm from 'react-hook-form'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem, NavLink as NavLinkTab } from 'reactstrap';
import { DollarSign, MapPin, X, TrendingDown, ArrowUp, ShoppingCart, Search, Activity, User, Users, Book } from 'react-feather';
import Slider from 'react-slick';
import CountUp from 'react-countup';
import RightBar from '../../../../components/social-app/rightBar';
import Mail from './Mail';
import Sms from './Sms';
var Knob = require('knob')// browserify require
var primary = localStorage.getItem('primary_color') || '#4466f2';


function AutoReminders(props) {
    const [delete_modal, set_delete_toggle] = useState();
    const [del_templ_id, set_del_templ_id] = useState("");
    const [access_level, setAccessLevel] = useState(localStorage.getItem("accessLevel"))
    const [templ_name, set_templ_Name] = useState("");
    const [templ_message, set_templ_message] = useState("");
    const [templ_user_type, set_templ_user_type] = useState("");
    const [templ_comments, set_templ_comments] = useState("");
    const [activeTab, setActiveTab] = useState('1');
    let [template_data, set_template_data] = useState(null);



    const [settings, setSettings] = useState({
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        // variableWidth:true,
        arrows: false,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 370,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });

    const { errors } = useForm(); // initialise the hook
    const [loadingbtn, setloadingbtn] = useState(false);
    const [loading, setloading] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [templateChangeData, setTemplateChangeData] = useState({});


    console.log(props.modal3);

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-xl-12 xl-100">
                        <div className="card">
                            <div className="card-body" style={{ padding: "1rem" }}>
                                <Nav tabs className="nav-pills nav-light mb-1">
                                    <NavItem className="nav nav-tabs " id="myTab" role="tablist">
                                        <NavLinkTab className={activeTab == '1' ? 'active nav-link' : ''} onClick={() => setActiveTab('1')} value='1'>
                                            Mail
                                        </NavLinkTab>
                                    </NavItem>
                                    <NavItem className="nav nav-tabs " id="myTab" role="tablist">
                                        <NavLinkTab className={activeTab == '2' ? 'active nav-link' : ''} onClick={() => setActiveTab('2')} value='2'>
                                            Sms
                                        </NavLinkTab>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={activeTab} >
                                    <TabPane tabId="1">
                                        <Mail toggle3={props.toggle3} modal4={props.modal3 && activeTab == "1"}/>
                                    </TabPane>

                                    <TabPane tabId="2">
                                        <Sms toggle3={props.toggle3} modal5={props.modal3 && activeTab == "2"}></Sms>
                                    </TabPane>
                                </TabContent>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
    // }
}

export default AutoReminders;