import * as actionTypes from "./actionTypes";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";


export const fetch_restaurantStructure_start = () => {
    return {
        type: actionTypes.FETCH_RESTAURANT_STRUCTURE_START
    }
}

export const fetch_restaurantStructure_success = (successMsg, restaurantStructure) => {
    return {
        type: actionTypes.FETCH_RESTAURANT_STRUCTURE_SUCCESS,
        successMsg,
        restaurantStructure
    }
}

export const fetch_restaurantStructure_failed = (errorMsg) => {
    return {
        type: actionTypes.FETCH_RESTAURANT_STRUCTURE_FAILED,
        errorMsg
    }
}

export const fetch_restaurantStructure = (postData) => {
    return dispatch => {
        dispatch(fetch_restaurantStructure_start());

        client.post(api.fetch_restaurantstructure, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    console.log(response);
                    if(response?.result?.data)
                    dispatch(fetch_restaurantStructure_success(response.message, response.result.data));
                } else {
                    dispatch(fetch_restaurantStructure_failed(response.message));

                }
            } else {
                dispatch(fetch_restaurantStructure_failed("Internal error occured.Please contact support."));

            }

        });
    }
}