import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const app = firebase.initializeApp({
  apiKey: "AIzaSyAn1ducAURTPPxEj_5LFrfNEiV69jLOaNQ",
  authDomain: "ipaydemo-e61fb.firebaseapp.com",
  databaseURL: "https://ipaydemo-e61fb.firebaseio.com",
  projectId: "ipaydemo-e61fb",
  storageBucket: "ipaydemo-e61fb.appspot.com",
  messagingSenderId: "899250196684",
  appId: "1:899250196684:web:84e624166e447c29193269",
  measurementId: "G-8FH630J29V"
});

export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const twitterProvider = new firebase.auth.TwitterAuthProvider();
export const githubProvider = new firebase.auth.GithubAuthProvider();
export const db = firebase.firestore();

export default app;
