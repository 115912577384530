import React, { Component, Fragment } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup, Col, Row, FormFeedback, CustomInput } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import { Link as NavLink } from "react-router-dom";
import moment from "moment-timezone";

import client from "../../../Api/HTTPClient";
import {DisappearedLoading} from "react-loadingg";
import api from "../../../constant/apilist";

class VersionSettings extends Component {

    state = {
        access_level: localStorage.getItem("accessLevel"),
        uploading_file: false,
        app_version: "",
        app_version_code: "",
        version_type: "",
        apkUrl: "",
        apk_file: null,
    }

    componentDidMount() {
        client.post(api.get_silentapp_version, {}, (error, response) => {
            if (!error) {
                if (!response.error) {
                    try {
                        let data = response.result.data;
                        // console.log(data)
                        this.setState({
                            app_version: data.versionName,
                            app_version_code: data.versionCode,
                            apkUrl: data.apkUrl,
                            version_type: data.forceUpdate == true ? "force" : "optional",
                        })
                    } catch {
                        
                    }
                    // toast.success(response.message);
                } else {
                    // setTimeout(() => {
                    //     toast.error(response.message);
                    // }, 200);
                }

            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        });
        
    }

    

    // fileeventChange = (event) => {
    //     const { name, value } = event.target;
    //     if (event.target.type == "file") {
    //         var mimeType = event.target.files[0].type;

    //         // Image upload
    //         var reader = new FileReader();
    //         console.log(event.target.files[0]);
    //         reader.readAsDataURL(event.target.files[0]);
    //         this.setState({ uploading_file: true });
    //         reader.onload = (_event) => {
    //             // console.log(reader.result)
    //             let imageData;
    //             this.setState({ url: reader.result });
    //             imageData = reader.result;
    //             imageData = imageData.split(",")[1];

    //             let apk_file = { mime: mimeType, data: imageData }
    //             // console.log(apk_file);
    //             this.setState({
    //                 apk_file: apk_file,
    //                 uploading_file: false
    //             })
    //             // this.setState({apk_file : apk_file, uploading_file : false})
    //         }
    //     }
    // }

    onAppVersionChange = (event) => {
        const { name, value } = event.target;
        this.setState({[name]: value})
    }


    onAppVersionSubmit = (event) => {
        event.preventDefault();
        const postdata =  this.state ;
        this.setState({ uploading_file: true });
        
        client.post(api.update_silentapp_version, postdata, (error, response) => {
            if (!error) {
                if (!response.error) {

                    document.getElementById("merchant-app-version").reset();

                    // this.refreshPage();
                    this.setState({ uploading_file: false });
                    // console.log(response);
                    toast.success(response.message);
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        });
    }


    render() {
        const { access_level } =this.state
        //console.log(this.state.appVerisonInfo);
        return (
            <Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-xl-12">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">

                                        <div className="card-body" style={{paddingTop:"0px",paddingLeft:"12px"}}>
                                        <h4 style={{fontSize:"15px",marginTop:"10px",fontWeight:"500",marginBottom:"10px"}}>Silent update settings</h4>
                                            <div> 
                                                <Form id="merchant-app-version" onSubmit={this.onAppVersionSubmit}>
                                                <Row>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="appVersion">App Verison{this.state.app_version}</Label>
                                                            <Input type="text" value={this.state.app_version} name="app_version" required
                                                                placeholder="App Verison" onChange={this.onAppVersionChange} />
                                                        </FormGroup>
                                                        </Col>
                                                        
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="url">App Verison Code</Label>
                                                            <Input type="number" value={this.state.app_version_code} name="app_version_code" required  onChange={this.onAppVersionChange} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                            {this.state.version_type != "" ?
                                                                <FormGroup>
                                                                    <Label for="versionType">Version Type</Label>
                                                                    <Input type="select" name="version_type" required defaultValue={this.state.version_type} onChange={this.onAppVersionChange}>
                                                                        <option value="optional">Optional Updates</option>
                                                                        <option value="force">Force Updates</option>
                                                                    </Input>
                                                                </FormGroup>
                                                                :
                                                                null
                                                            }
                                                    </Col>
                                                    </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <FormGroup>
                                                            <Label for="crown_icon">Download Apk Path</Label>
                                                            <Input type="text" value={this.state.apkUrl} name="apkUrl" onChange={this.onAppVersionChange} >
                                                            </Input>
                                                        </FormGroup>
                                                        
                                                    </Col>
                                                    </Row>
                                                {/* <Row>
                                                    <Col md={2}>
                                                        <FormGroup>
                                                            <Label for="crown_icon">APK File</Label>
                                                            <Input type="file" name="apk_file" onChange={this.fileeventChange} >
                                                            </Input>
                                                        </FormGroup>
                                                        
                                                    </Col>
                                                    </Row> */}
                                                    {!this.state.uploading_file ?
                                                        <Button color="primary" type="submit">Save</Button>
                                                        :<div className="loader-box" style={{height:"100vh",width:"100wh"}}>
                              <div className="loader" style={{ display:"flex",alignItems:"center" }}>
                              <DisappearedLoading size="medium" color="#514F4E" />
                               </div> 
                               </div>}
                                                </Form>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Fragment>
        );
    }
}


export default VersionSettings;