import React, { Fragment, Component } from 'react';
import { Redirect, NavLink, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Pagination, PaginationItem, PaginationLink, NavLink as NavLinkTab } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment-timezone";
import PaginationComponent from "react-reactstrap-pagination";

import Breadcrumb from '../../components/common/breadcrumb';
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import {DisappearedLoading} from "react-loadingg";

class AllTransactions extends Component {

    state = {
        cashbacks : [],
        pageSize: 25,
        loading: true,
        activePage: 1,
        total :0
    }

    setPageSize = (event) => {
        this.setState({
            pageSize: event.target.value,
            activePage : 1,
        }, () => { this.getAllTransactions() })
    }

    handlePageChange = (pageNumber) => {
        this.setState({
            activePage: pageNumber
        }, () => { this.getAllTransactions() })
    }

    componentDidMount() {
        this.getAllTransactions()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.defaultCountryCode !== this.props.defaultCountryCode) {
            this.getAllTransactions();
        }
      }

    getAllTransactions = () => {

        let { pageSize, activePage } = this.state;
        let skip = (parseInt(activePage) - 1) * (parseInt(pageSize));

        this.setState({ cashbacks: [], loading: true })

        client.post(api.cashback_transactions, { apply_limit: true, country_code: this.props.defaultCountryCode, skip, limit: parseInt(pageSize) }, (error, response) => {
            console.log(response, 'response');
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            this.setState({ cashbacks: response.result.cashbacks, total: response.result.total });
                        }
                        else {
                            toast.error(response.message);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }
            this.setState({ loading: false })
        });
        
    }

    render() {
        let { cashbacks, loading, total } = this.state;
        
        return (
            <>
                {!loading ?
                    <div className="table-responsive">
                        <table className="table table-border-horizontal" >
                            <thead>
                                <tr style={{background: '#f9fafe'}}>
                                    <th scope="col" >#</th>
                                    <th scope="col" >Date</th>
                                    <th scope="col" >To</th>
                                    <th scope="col" >Amount</th>
                                    <th scope="col" >Comment</th>
                                </tr>
                            </thead>
                            <tbody>
                                {cashbacks.map((item, index) => {
                                    return (
                                        <tr key={item._id}>
                                            <th scope=" row" >{((this.state.activePage - 1) * this.state.pageSize) + index + 1}</th>
                                            <td>{this.props.render_date(item.transaction_date)}</td>
                                            <td>{item.customers ?
                                                <NavLink
                                                to={{
                                                    pathname: `${process.env.PUBLIC_URL}/customer/wallet/${item.customers._id}`,
                                                    search: "?activeTab=2",
                                                }}
                                                style={{ color: item.customers.status == 2 && "red" }}
                                            >
                                                {item.customers["name"]}
                                            </NavLink>
                                                 :
                                                item.merchant ?
                                                <NavLink
                                                to={{
                                                    pathname: `${process.env.PUBLIC_URL}/merchants/merchantDetails/${item.merchant._id}`,
                                                    search: "?activeTab=1&sub_tab=1",
                                                }}
                                                style={{ color: item.merchant.status == 5 && "red" }}
                                            >
                                                        {item.merchant["business_name"]}
                                            </NavLink>
                                                     : ""}</td>
                                            <td>{item.customers ? item.customers.country?.currency_symbol : item.merchant ? item.merchant.country?.currency_symbol :""}{item.amount}</td>
                                            <td>{item.display_data}</td>
                                        </tr>)
                                })}

                            </tbody>
                        </table>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "20px" }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <select className="custom-select" style={{ width: "32%", marginRight: "7px" }} onChange={this.setPageSize} value={this.state.pageSize}>
                                    <option value="15">15</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="75">75</option>
                                    <option value="100">100</option>
                                </select>
                                <span>Records per Page</span>
                            </div>
                            <PaginationComponent
                                totalItems={total}
                                defaultActivePage={this.state.activePage}
                                pageSize={this.state.pageSize}
                                onSelect={this.handlePageChange} />

                        </div>
                    </div>
                    :

                    <div className="loader-box">
                        <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                            <DisappearedLoading size="medium" color="#514F4E" />
                        </div>
                    </div>
                }
            </>
        );
    }
}

export default AllTransactions;