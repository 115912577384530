import React, {useState, useEffect} from "react";
import {Table, Badge, Button, Modal, ModalBody} from 'reactstrap';
import { mdiPlusThick, mdiTrashCanOutline, mdiContentCopy, mdiPencil, mdiClose, mdiAlertCircleOutline } from '@mdi/js'
import Icon from "@mdi/react";
import QrcodeIcon from "mdi-react/QrcodeIcon";
import QRCode from "qrcode.react";
import moment from "moment";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { toast } from "react-toastify";
import { DisappearedLoading } from "react-loadingg";

const EventTableComp = (props)=>{

    const {merchant_info, allEvents, setAllEvents} = props

    const [deleteEvent, setDeleteEvent] = useState({ data: null, val: false });
    // const [allEvents, setAllEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [enableQrpopup, setEnableQrpopup] = useState(false);
    const [selectedEventData, setSelectedEventData] = useState(null);
    // const [upcomingEvents, setUpcomingEvents] = useState([]);
    // const [pastEvents, setPastEvents] = useState([]);

    // useEffect(() => {
    //     let timeZone = merchant_info?.timeZone

    //     setUpcomingEvents(() => {
    //         return (
    //             allEvents?.filter(elem => {

    //                 let eventTimeStart = elem?.session_start_hour?.value + ":" + elem?.session_start_minutes?.value + " " + elem?.session_start_meridiem?.value
    //                 let eventTimeEnd = elem?.session_end_hour?.value + ":" + elem?.session_end_minutes?.value + " " + elem?.session_end_meridiem?.value
    //                 let eventActualtime
    //                 if (elem.session_type == "single_session") {
    //                     eventActualtime = moment(moment(Number(elem?.sessions_start_date)).format('DD-MM-YYYY') + " " + eventTimeStart, 'DD-MM-YYYY hh:mm A').unix()
    //                     // console.log(moment(Number(elem?.sessions_end_date)).format('DD-MM-YYYY'))
    //                 }
    //                 else {
    //                     if (elem?.occurrence?.value == "multiple") {
    //                         eventActualtime = moment(moment(Number(getHighestDate(elem?.multiple_sessions_date))).format('DD-MM-YYYY') + " " + eventTimeStart, 'DD-MM-YYYY hh:mm A').unix()
    //                     } else {
    //                         eventActualtime = moment(moment(Number(elem?.sessions_end_date)).format('DD-MM-YYYY') + " " + eventTimeStart, 'DD-MM-YYYY hh:mm A').unix()
    //                     }
    //                     // console.log(moment(Number(elem?.sessions_end_date)).format('DD-MM-YYYY'))
    //                 }

    //                 return eventActualtime > moment().tz(timeZone).unix()
    //             })?.sort((a, b) => {

    //                 let eventTimeStartA = a?.session_start_hour?.value + ":" + a?.session_start_minutes?.value + " " + a?.session_start_meridiem?.value
    //                 let eventTimeEndA = a?.session_end_hour?.value + ":" + a?.session_end_minutes?.value + " " + a?.session_end_meridiem?.value

    //                 let eventActualtimeA
    //                 if (a.session_type == "single_session") {
    //                     eventActualtimeA = moment(moment(Number(a?.sessions_start_date)).format('DD-MM-YYYY') + " " + eventTimeStartA, 'DD-MM-YYYY hh:mm A').unix()

    //                 }
    //                 else {
    //                     if (a?.occurrence?.value == "multiple") {
    //                         eventActualtimeA = moment(moment(Number(getHighestDate(a?.multiple_sessions_date))).format('DD-MM-YYYY') + " " + eventTimeStartA, 'DD-MM-YYYY hh:mm A').unix()
    //                     } else {
    //                         eventActualtimeA = moment(moment(Number(a?.sessions_end_date)).format('DD-MM-YYYY') + " " + eventTimeStartA, 'DD-MM-YYYY hh:mm A').unix()
    //                     }
    //                 }


    //                 let eventTimeStartB = b?.session_start_hour?.value + ":" + b?.session_start_minutes?.value + " " + b?.session_start_meridiem?.value
    //                 let eventTimeEndB = b?.session_end_hour?.value + ":" + b?.session_end_minutes?.value + " " + b?.session_end_meridiem?.value

    //                 let eventActualtimeB
    //                 if (b.session_type == "single_session") {
    //                     eventActualtimeB = moment(moment(Number(b?.sessions_start_date)).format('DD-MM-YYYY') + " " + eventTimeStartA, 'DD-MM-YYYY hh:mm A').unix()

    //                 }
    //                 else {
    //                     if (b?.occurrence?.value == "multiple") {
    //                         eventActualtimeB = moment(moment(Number(getHighestDate(b?.multiple_sessions_date))).format('DD-MM-YYYY') + " " + eventTimeStartA, 'DD-MM-YYYY hh:mm A').unix()
    //                     } else {
    //                         eventActualtimeB = moment(moment(Number(b?.sessions_end_date)).format('DD-MM-YYYY') + " " + eventTimeStartA, 'DD-MM-YYYY hh:mm A').unix()
    //                     }
    //                 }

    //                 return eventActualtimeA - eventActualtimeB
    //             })
    //         )
    //     })

    //     function getHighestDate(dates) {
    //         if (dates.length === 0) return null;
    //         let highestDate = dates[0];
    //         for (let i = 1; i < dates.length; i++) {
    //             if (dates[i] > highestDate) highestDate = dates[i];
    //         }
    //         return highestDate;
    //     }

    //     setPastEvents(() => {
    //         return (
    //             allEvents?.filter(elem => {

    //                 let eventTimeStart = elem?.session_start_hour?.value + ":" + elem?.session_start_minutes?.value + " " + elem?.session_start_meridiem?.value
    //                 let eventTimeEnd = elem?.session_end_hour?.value + ":" + elem?.session_end_minutes?.value + " " + elem?.session_end_meridiem?.value

    //                 let eventActualtime
    //                 if (elem.session_type == "single_session") {
    //                     eventActualtime = moment(moment(Number(elem?.sessions_start_date)).format('DD-MM-YYYY') + " " + eventTimeEnd, 'DD-MM-YYYY hh:mm A').unix()
    //                     // console.log(moment(Number(elem?.sessions_end_date)).format('DD-MM-YYYY'))
    //                 }
    //                 else {
    //                     if (elem?.occurrence?.value == "multiple") {
    //                         eventActualtime = moment(moment(Number(getHighestDate(elem?.multiple_sessions_date))).format('DD-MM-YYYY') + " " + eventTimeEnd, 'DD-MM-YYYY hh:mm A').unix()
    //                     } else {
    //                         eventActualtime = moment(moment(Number(elem?.sessions_end_date)).format('DD-MM-YYYY') + " " + eventTimeEnd, 'DD-MM-YYYY hh:mm A').unix()
    //                     }
    //                 }

    //                 return eventActualtime < moment().tz(timeZone).unix()
    //             })?.sort((a, b) => {

    //                 let eventTimeStartA = a?.session_start_hour?.value + ":" + a?.session_start_minutes?.value + " " + a?.session_start_meridiem?.value
    //                 let eventTimeEndA = a?.session_end_hour?.value + ":" + a?.session_end_minutes?.value + " " + a?.session_end_meridiem?.value

    //                 let eventActualtimeA
    //                 if (a.session_type == "single_session") {
    //                     eventActualtimeA = moment(moment(Number(a?.sessions_start_date)).format('DD-MM-YYYY') + " " + eventTimeEndA, 'DD-MM-YYYY hh:mm A').unix()

    //                 }
    //                 else {
    //                     if (a?.occurrence?.value == "multiple") {
    //                         eventActualtimeA = moment(moment(Number(a?.multiple_sessions_date?.[a?.multiple_sessions_date?.length - 1])).format('DD-MM-YYYY') + " " + eventTimeEndB, 'DD-MM-YYYY hh:mm A').unix()
    //                     } else {
    //                         eventActualtimeA = moment(moment(Number(a?.sessions_end_date)).format('DD-MM-YYYY') + " " + eventTimeEndA, 'DD-MM-YYYY hh:mm A').unix()
    //                     }
    //                 }


    //                 let eventTimeStartB = b?.session_start_hour?.value + ":" + b?.session_start_minutes?.value + " " + b?.session_start_meridiem?.value
    //                 let eventTimeEndB = b?.session_end_hour?.value + ":" + b?.session_end_minutes?.value + " " + b?.session_end_meridiem?.value

    //                 let eventActualtimeB
    //                 if (b.session_type == "single_session") {
    //                     eventActualtimeB = moment(moment(Number(b?.sessions_start_date)).format('DD-MM-YYYY') + " " + eventTimeEndB, 'DD-MM-YYYY hh:mm A').unix()

    //                 }
    //                 else {
    //                     if (b?.occurrence?.value == "multiple") {
    //                         eventActualtimeB = moment(moment(Number(b?.multiple_sessions_date?.[b?.multiple_sessions_date?.length - 1])).format('DD-MM-YYYY') + " " + eventTimeEndB, 'DD-MM-YYYY hh:mm A').unix()
    //                     } else {
    //                         eventActualtimeB = moment(moment(Number(b?.sessions_end_date)).format('DD-MM-YYYY') + " " + eventTimeEndB, 'DD-MM-YYYY hh:mm A').unix()
    //                     }
    //                 }

    //                 return eventActualtimeB - eventActualtimeA
    //             })
    //         )
    //     })




    // }, [activeTab, allEvents])
    
    const host = client.url("");
    let api_domain = host.replace(/(https?:\/\/)?(www.)?/i, '');
    if (api_domain.indexOf('/') !== -1) {
        api_domain = api_domain.split('/')[0];
    }

    const toggleQrCodeModal = () => { setEnableQrpopup(!enableQrpopup) };

    // const fetchEvents = () => {

    //     let postData = { merchant_id: merchant_info._id };
    //     // setIsLoading(true);
    //     client.post(api.fetchEvents, postData, (error, response) => {
    
    //         if (!error) {
    //             if (!response.error) {
    //                 // console.log(response)
    //                 setAllEvents(response.data)
    //                 setLoading(false);
    //             } else {
    //                 // setIsLoading(false);
    //                 toast.error("Internal error occured.Please contact support");
    //             }
    //         } else {
    //             toast.error("Internal error occured.Please contact support");
    //             // setIsLoading(false);
    //         }
    //     });
    // }

    // useEffect(()=>{
    //     fetchEvents();
    // },[])

    const handleSellTicketDelete = (buttonData) => {
        if(buttonData && buttonData?._id){
            const postData = {
                merchant_id: merchant_info._id,
                _id: buttonData._id
            }
            client.post(api.deleteEvent, postData, (error,response)=>{
                if(!error){
                    if(!response.error){
                        setAllEvents(response.data)
                    }
                }
            })
            setDeleteEvent({data:null, val:false})
        }
    }

    return (
        <div className=''>
            {
                loading?
                <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                    <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                        <DisappearedLoading size="medium" color="#514F4E" />
                    </div>
                </div>
                :
                <div>
                    <form className="">
                        <div className='px-4'>
                            <div className='my-3'>
                                {allEvents?.length ? <Table className="table--bordered w-100">
                                    <thead>
                                        <tr>
                                            <th className='border' style={{ }}>#</th>
                                            <th className='border' style={{ }}>Event Name</th>
                                            <th className='border' style={{ }}>Event Date & Time</th>
                                            <th className='border' style={{ }}>URL</th>
                                            <th className='border' style={{ }}>Type</th>
                                            <th className='border' style={{ }}>Status</th>
                                            <th className='border' style={{ }}>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            allEvents.length > 0 ?
                                                <>
                                                    {allEvents.map((item, ind) => {

                                                        let link = `https://${api_domain == "dmapi.ipaypro.co" ? "dm." : ""}linky.biz/${merchant_info.unique_id}/events/${item._id}/`;

                                                        return (
                                                            <tr key={ind}>
                                                                <td className="border">
                                                                    <div className=""> {ind + 1}	</div>
                                                                </td>
                                                                <td className="border">
                                                                    <div className="d-flex align-items-center">
                                                                        {item.event_name ? item.event_name : "NA"}
                                                                    </div>
                                                                </td>
                                                                <td className="border">
                                                                    <div className="d-flex align-items-center">
                                                                        {item?.session_type == "multiple_session" ?
                                                                            `Between ${moment(Number(item.sessions_start_date)).format('DD/MM/YYYY')} and ${moment(Number(item.sessions_end_date)).format('DD/MM/YYYY')}` :
                                                                            moment(Number(item.sessions_start_date)).format('DD/MM/YYYY')
                                                                        }, &nbsp; <br />
                                                                        {item.session_start_hour.label}:{item.session_start_minutes.label} {item.session_start_meridiem.label} - {item.session_end_hour.label}:{item.session_end_minutes.label} {item.session_end_meridiem.label}
                                                                    </div>
                                                                </td>
                                                                <td className="border">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className='d-flex align-items-center'>
                                                                            <div className="" style={{ backgroundColor: "#fff", padding: "0.3rem 0.5rem", wordBreak: "break-word", width: "260px" }}>{link}</div>
                                                                        </div>
                                                                        <div
                                                                            className="rounded ml-2 d-flex"
                                                                            style={{ border: "1px solid #70bbfd", padding: "0.1rem", cursor: "pointer" }}
                                                                            onClick={() => { toggleQrCodeModal(); setSelectedEventData(item) }}
                                                                        >
                                                                            <QrcodeIcon className="" style={{ height: "25px", width: "25px", color: "#70bbfd" }} />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="border">
                                                                    <div className="">
                                                                        <div className="form__form-group">
                                                                            <div className="form__form-group-field">
                                                                                {/* <Field
                                                                                    name={`event_type-${item._id}`}
                                                                                    component={renderSelectField}
                                                                                    options={[{ value: "public", label: t('sell_ticket.public') }, { value: "private", label: t('sell_ticket.private') }]}
                                                                                    placeholder=''
                                                                                    onChange={(e) => changeDropDown({ eventType: e }, item)}
                                                                                    customWidth={'110px'}
                                                                                /> */}
                                                                                    {item.eventType.label}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="border">
                                                                    <div className="">
                                                                        <div className="form__form-group">
                                                                            <div className="form__form-group-field">
                                                                                {/* <Field
                                                                                    name={`event_status-${item._id}`}
                                                                                    component={renderSelectField}
                                                                                    options={[{ value: "active", label: t('sell_ticket.active') }, { value: "inactive", label: t('sell_ticket.inactive') }, { value: "soldout", label: t('sell_ticket.sold_out') }]}
                                                                                    placeholder=''
                                                                                    customWidth={'110px'}
                                                                                    customSelectStyle={true}
                                                                                    onChange={(e) => changeDropDown({ status: e }, item)}
                                                                                /> */}
                                                                                {item.status.label}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>

                                                                <td className="border">
                                                                    <div className="d-flex">
                                                                        {/* <Button
                                                                            outline
                                                                            style={{ padding: "4px 6px" }}
                                                                            className="d-flex align-items-start my-0 ml-0 mr-2"
                                                                            color="success"
                                                                            // onClick={(e) => {
                                                                            //     history.push({
                                                                            //         pathname: '/add-events',
                                                                            //         state: { eventData: item }
                                                                            //     })
                                                                            // }}
                                                                        >
                                                                            <Icon path={mdiPencil} className="mx-auto" size={0.7} color="success" />
                                                                        </Button> */}

                                                                        {/* <Button
                                                                            outline
                                                                            style={{ padding: "4px 6px" }}
                                                                            className="d-flex align-items-start my-0 mr-2"
                                                                            color="success"
                                                                            // onClick={(e) => duplicateEvent(item)}
                                                                        >
                                                                            <Icon path={mdiContentCopy} className="mx-auto" size={0.7} color="danger" />
                                                                        </Button> */}

                                                                        <Button
                                                                            outline
                                                                            style={{ padding: "4px 6px" }}
                                                                            className="d-flex align-items-start my-0 mr-2"
                                                                            color="danger"
                                                                            onClick={(e) => { setDeleteEvent({ data: item, val: true }) }}
                                                                        >
                                                                            <Icon path={mdiTrashCanOutline} className="mx-auto" size={0.7} color="danger" />
                                                                        </Button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </> : <>
                                                    <td colSpan={7} className='p-0'>
                                                        <div className='d-flex align-items-center justify-content-center border' style={{ height: "25vh" }}>
                                                            <p style={{ fontSize: "14px" }}>No record!</p>
                                                        </div>
                                                    </td>
                                                </>
                                        }
                                    </tbody>
                                </Table> : <div className="d-flex flex-column align-items-center py-3 justify-content-center" style={{ marginTop: "5rem" }}>
                                    <img src="/assets/images/no_meetings.png" alt="" style={{ width: "100px" }} />
                                    <h5 style={{fontSize: "14px", fontWeight: 500, color: "black", marginBottom: "0.3rem" }}>No events found!</h5>
                                    {/* <p style={{ margin: "0px" }}>Share Linky Meet link to schedule meetings</p> */}
                                </div>}
                            </div>
                        </div>
                    </form>
                </div>
            }


            <Modal
                isOpen={enableQrpopup}
                toggle={toggleQrCodeModal}
                centered
            >
                <ModalBody className='relative'>
                    <div
                        className='p-2 bg-white'
                        style={{ position: "absolute", top: "0px", right: "0px", cursor: "pointer" }}
                        onClick={() => { toggleQrCodeModal() }}
                    >
                        <Icon path={mdiClose} size={1} />
                    </div>
                    <p style={{ fontSize: "15px" }}>Download the QR code to add to posters and other printed material such as brouchers, flyers</p>
                    <div className='mt-4 p-3 d-flex align-items-center border rounded'>
                        <div className=''>
                            <QRCode
                                value={`https://${api_domain == "dmapi.ipaypro.co" ? "dm." : ""}linky.biz/${merchant_info.unique_id}/events/${selectedEventData?._id}/`}
                                renderAs="canvas"
                                size={100}
                            />
                        </div>
                        <div
                            className='rounded ml-4'
                            style={{
                                fontSize: "13px",
                                border: "1px solid #ddb359",
                                padding: "0.5rem 1.4rem",
                                cursor: "pointer",
                                color: "#ddb359",
                                fontWeight: "500"
                            }}
                        >
                            Download Qr Code
                        </div>
                    </div>
                </ModalBody>

            </Modal>

            <Modal
                isOpen={deleteEvent.val}
                onClosed={() => setDeleteEvent({ data: null, val: false })}
                centered
                style={{ maxWidth: "380px" }}
                className="justify-center"
            >
                <ModalBody>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <Icon path={mdiAlertCircleOutline} color="#FF0000" size={3} />
                    </div>

                    <p className="mx-2 mb-5 mt-4 text-center text-desc" style={{ fontSize: "17px" }}>
                    Are you sure you want to delete?
                    </p>
                    <div className="d-flex justify-content-between modal_footer_btn">
                        <Button
                            className="mx-1 my-0 "
                            size="sm"
                            type="button"
                            style={{ padding: "8px 20px" }}
                            onClick={() => setDeleteEvent({ data: null, val: false })}
                        >
                            
                            Cancel
                        </Button>

                        <Button
                            className="mx-1 my-0 "
                            size="sm"
                            color="primary"
                            style={{ padding: "8px 20px" }}
                            onClick={() => {
                                handleSellTicketDelete(deleteEvent.data);
                            }}
                        >
                            Yes, please
                        </Button>
                    </div>
                </ModalBody>
            </Modal> 

        </div>
    )
}

export default EventTableComp