import React, { Fragment } from "react";
import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import {
	TabContent,
	TabPane,
	Nav,
	NavItem,
	UncontrolledTooltip,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from "reactstrap";
import PaginationComponent from "react-reactstrap-pagination";
import client from "../Api/HTTPClient";
import api from "../constant/apilist";

import { DollarSign, MapPin, X, TrendingDown, ArrowUp, ShoppingCart, Search, Activity, User } from "react-feather";
import { Redirect } from "react-router-dom";
import { NavLink } from "react-router-dom";

class CustomerTransaction extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: null,
			customer_id: this.props.customerId,
			customer_info: this.props.customerInfo,
			wallet_info: this.props.walletInfo,
			cashbacks: [],
			flagModal: false,
			flag_note: "",
			deleteModal: false,
			infoModal: false,
			modalId: null,
			isLoaded: false,
			items: [],
			statusModal: false,
			statusMessage: null,
			activePage: 1,
			pageSize: 15,
			access_level: localStorage.getItem("accessLevel"),
			adminUser: JSON.parse(localStorage.getItem("user")),
			crownPointLevels: null,
		};
	}

	componentDidMount() {
		/*  const rewardTransactions = this.props.transactionsInfo.reverse().filter(el => {
             return el.type == 2
         });
         this.setState({ transactions: rewardTransactions }); */

		this.getCustomerCashback(this.state.customer_id);
		this.get_all_crownLevel();
	}

	get_all_crownLevel = () => {
		const postdata = {};
		// console.log(postdata);
		client.post(api.get_Cust_allCrownLevel, postdata, (error, response) => {
			if (!error) {
				if (!response.error) {
					this.setState({ crownPointLevels: response.result.data });
				} else {
					setTimeout(() => {
						toast.error(response.message);
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
		});
	};

	getCustomerCashback(customer_id) {
		//let { customer_id } = this.state;
		let postdata = { customer_id: customer_id };
		// current.setState({ loading: true });
		client.post(api.cashback_transactions, postdata, (error, response) => {
			if (!error) {
				if (typeof response.error !== "undefined") {
					try {
						if (!response.error) {
							this.setState({ cashbacks: response.result.cashbacks });
							// current.props.navigation.navigate("Verification");
							console.log(response);
						} else {
							setTimeout(() => {
								toast.error(response.message);
							}, 200);
						}
					} catch (e) {
						setTimeout(() => {
							toast.error("Internal error occured. Please contact support");
						}, 200);
						console.log(e);

						// _showToast.error("Internal error occured. Please contact support.");
						// currentComponent.setState({ errormessage: "Internal error." });
					}
				} else {
					setTimeout(() => {
						toast.error("Internal error occured. Please contact support");
					}, 200);

					//   _showToast.error("Internal error occured. Please contact support.");
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);

				// alert(JSON.stringify(error));
				// _showToast.error("Internal error occured. Please contact support.");
			}
			// current.setState({ loading: false });
		});
	}

	setPageSize = (event) => {
		this.setState({ pageSize: event.target.value });
	};

	handlePageChange = (pageNumber) => {
		this.setState({ activePage: pageNumber });
	};

	refreshPage() {
		window.location.reload(false);
	}

	render_date = (add_date) => {
		if (typeof add_date === "undefined") {
			return;
		}
		const convert_date = new Date(add_date);
		const added_date = moment.tz(convert_date, "Australia/Sydney").format("DD/MM/YYYY HH:mm:ss");
		return added_date;
	};

	toggleStatusModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				statusModal: !prevState.statusModal,
			};
		});
	};

	getStatus = (status) => {
		if (status == 1) {
			return "Success";
		} else {
			return "Created";
		}
	};

	statusClick = (message) => {
		const failedMessage = JSON.parse(message);
		this.setState((prevState) => {
			return {
				...prevState,
				statusModal: !prevState.statusModal,
				statusMessage: failedMessage.message,
			};
		});
	};

	deleteTransactionHandler = (event, id) => {
		event.preventDefault();
		const postData = { transaction_id: id };

		client.post(api.delete_transaction, postData, (error, response) => {
			if (!error) {
				if (!response.error) {
					this.setState({ deleteModal: false });
					this.getCustomerCashback(this.state.customer_id);
				} else {
					setTimeout(() => {
						toast.error(response.message);
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
		});
	};

	flagSubmitHandler = (event, id) => {
		event.preventDefault();
		let postdata = {};
		const foundTransaction = this.state.cashbacks.find((el) => {
			return el._id == id;
		});

		if (typeof foundTransaction.flag_status === "undefined") {
			foundTransaction.flag_status = false;
		}
		if (event.target.name == "unflag") {
			postdata = {
				transaction_id: id,
				flag_status: !foundTransaction.flag_status,
				flag_note: { type: "unflag", note: "No comments", user: this.state.adminUser.email },
			};
		} else if (event.target.name == "comment") {
			postdata = {
				transaction_id: id,
				flag_note: { type: "normal", note: this.state.flag_note, user: this.state.adminUser.email },
			};
		} else {
			postdata = {
				transaction_id: id,
				flag_status: !foundTransaction.flag_status,
				flag_note: { type: "flag", note: this.state.flag_note, user: this.state.adminUser.email },
			};
		}

		client.put(api.update_transaction, postdata, (error, response) => {
			if (!error) {
				if (!response.error) {
					console.log(response);
					this.refreshPage();
				} else {
					setTimeout(() => {
						toast.error(response.message);
					}, 200);
				}
			} else {
				setTimeout(() => {
					toast.error("Internal error occured. Please contact support");
				}, 200);
			}
		});
	};

	flagClick = (id) => {
		this.setState((prevState) => {
			return {
				...prevState,
				flagModal: !prevState.flagModal,
				modalId: id,
			};
		});
	};

	deleteClick = (id) => {
		this.setState((prevState) => {
			return {
				...prevState,
				deleteModal: !prevState.deleteModal,
				modalId: id,
			};
		});
	};

	infoClick = (id) => {
		this.setState((prevState) => {
			return {
				...prevState,
				infoModal: !prevState.infoModal,
				modalId: id,
			};
		});
	};

	flagNoteHandler = (event) => {
		const flagNote = event.target.value;
		this.setState((prevState) => {
			return {
				...prevState,
				flag_note: flagNote,
			};
		});
	};

	toggleFlagModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				flagModal: !prevState.flagModal,
			};
		});
	};
	toggleDeleteModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				deleteModal: !prevState.deleteModal,
			};
		});
	};
	toggleInfoModal = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				infoModal: !prevState.infoModal,
			};
		});
	};

	renderFlagModal = (id) => {
		const foundTransaction = this.state.cashbacks.find((el) => {
			return el._id == id;
		});
		const style = {
			outline: "none",
			":focus": {
				outlineColor: "black",
			},
		};

		if (!foundTransaction.flag_status) {
			return (
				<Modal isOpen={this.state.flagModal} toggle={this.toggleFlagModal} className="modal-body" centered={true}>
					<ModalBody>
						<form onSubmit={(event) => this.flagSubmitHandler(event, this.state.modalId)} className="needs-validation">
							<h5>Please enter a note to flag transaction.</h5>
							<p style={{ color: "red" }}>Note: User will no longer be able to view transaction in their application.</p>
							<label className="form-label" style={{ display: "block" }}>
								Note:
							</label>
							<input type="text" size="40" onChange={this.flagNoteHandler} style={style} required></input>
							<Button color="primary" style={{ marginTop: "20px", marginRight: "20px" }} name="flag">
								Update status
							</Button>
							<Button
								color="secondary"
								style={{ marginTop: "20px", marginRight: "20px" }}
								type="submit"
								onClick={this.toggleFlagModal}
							>
								Cancel
							</Button>
						</form>
					</ModalBody>
				</Modal>
			);
		}
		return (
			<Modal isOpen={this.state.flagModal} toggle={this.toggleFlagModal} className="modal-body" centered={true}>
				<ModalBody>
					<h5>Are you sure you want to unflag transaction ?</h5>
					<Button
						color="primary"
						style={{ marginTop: "20px", marginRight: "20px" }}
						name="unflag"
						onClick={(event) => this.flagSubmitHandler(event, this.state.modalId)}
					>
						Yes, please
					</Button>
					<Button color="secondary" style={{ marginTop: "20px", marginRight: "20px" }} onClick={this.toggleFlagModal}>
						Not Sure
					</Button>
				</ModalBody>
			</Modal>
		);
	};

	renderDeleteModal = (id) => {
		return (
			<Modal isOpen={this.state.deleteModal} toggle={this.toggleDeleteModal} className="modal-body" centered={true}>
				<form className="theme-form" noValidate="" onSubmit={(event) => this.deleteTransactionHandler(event, id)}>
					<ModalBody>
						<h4>Do you really want to delete this transaction ?</h4>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={this.toggleDeleteModal}>
							No
						</Button>
						<Button color="primary" type="submit">
							Yes
						</Button>
					</ModalFooter>
				</form>
			</Modal>
		);
	};

	renderInfoModal = (id) => {
		const foundTransaction = this.state.cashbacks.find((el) => {
			return el._id == id;
		});

		if (typeof foundTransaction.flag_note == "undefined") {
			foundTransaction.flag_note = [];
		}
		let foundTransactionNote = [...foundTransaction.flag_note].reverse();
		return (
			<Modal isOpen={this.state.infoModal} toggle={this.toggleInfoModal} className="modal-body" centered={true}>
				<h5 style={{ padding: "15px", margin: "0" }}>Comments</h5>

				<ModalBody style={{ paddingTop: "0", paddingBottom: "0", maxHeight: "calc(100vh - 370px)", overflowY: "auto" }}>
					<div className="card-body chat-box" style={{ paddingTop: "0", paddingBottom: "0" }}>
						<div className="chat-right-aside bitcoin-chat">
							<div className="chat">
								<div className="chat-history chat-msg-box custom-scrollbar" style={{ padding: "0", margin: "0" }}>
									<ul style={{ margin: "0" }}>
										{foundTransactionNote.length != 0 ? (
											foundTransactionNote.map((el) => {
												return (
													<li style={{ margin: "0" }}>
														<div
															className="message my-message "
															style={{ marginBottom: "10px", paddingTop: "10px", paddingBottom: "10px" }}
														>
															<h6>
																{el.user}
																<span className="message-data-time pull-right">{this.render_date(el.date)}</span>{" "}
															</h6>
															{el.type == "normal" ? null : <p style={{ fontSize: "0.9rem" }}>Type : {el.type}</p>}
															{el.note}
														</div>
													</li>
												);
											})
										) : (
											<p>No note added!</p>
										)}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter style={{ paddingTop: "10px", paddingBottom: "10px" }}>
					<div className="col-lg-12">
						<form className="theme-form">
							<div className="form-group mb-0 col-12">
								<label htmlFor="exampleFormControlTextarea4">Comment:</label>
								<textarea
									className="form-control"
									id="exampleFormControlTextarea4"
									required
									rows="3"
									onChange={this.flagNoteHandler}
								></textarea>
							</div>
							<div style={{ padding: "15px 0 0 15px" }}>
								<Button color="secondary" style={{ marginRight: "15px" }} onClick={this.toggleInfoModal}>
									Cancel
								</Button>
								<Button
									color="primary"
									type="submit"
									name="comment"
									onClick={(event) => this.flagSubmitHandler(event, this.state.modalId)}
								>
									Submit
								</Button>
							</div>
						</form>
					</div>
				</ModalFooter>
			</Modal>
		);
	};

	renderStatusModal = () => {
		return (
			<Modal isOpen={this.state.statusModal} toggle={this.toggleStatusModal} className="modal-body" centered={true}>
				<form className="theme-form" noValidate="">
					<ModalBody style={{ textAlign: "center" }}>
						<div style={{ fontSize: "2rem", textAlign: "center" }}>
							<i className="icofont icofont-warning" style={{ color: "#FF2046" }}></i>
						</div>
						<h4 style={{ color: "#FF2046" }}>Failure!</h4>
						<h6>{this.state.statusMessage}</h6>
						<Button
							className="btn btn-danger"
							style={{ width: "100%", textAlign: "center", fontSize: "1.1rem", marginTop: "10px" }}
							onClick={this.toggleStatusModal}
						>
							Dismiss
						</Button>
					</ModalBody>
				</form>
			</Modal>
		);
	};

	render() {
		let { customer_info, customer_id, wallet_info, cashbacks, access_level, crownPointLevels } = this.state;

		let crown_image = null;
		if (crownPointLevels && wallet_info) {
			const foundLevel = crownPointLevels.find((el) => {
				return el.to_points >= wallet_info.crown_points;
			});
			crown_image = `${client.url(`static-images/Level1.png`)}`;
		}

		return (
			<Fragment>
				{/*  {this.state.modalId ? this.renderFlagModal(this.state.modalId) : null} */}
				{this.state.modalId ? this.renderDeleteModal(this.state.modalId) : null}
				{/* {this.state.modalId && cashbacks ? this.renderInfoModal(this.state.modalId) : null}
                {this.renderStatusModal()} */}
				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							{customer_info && wallet_info && cashbacks ? (
								<div className="card">
									<div className="card-header">
										<h6>
											{/* {customer_id} */}
											Name : {customer_info.name}, Mobile : {customer_info.mobile}
										</h6>
									</div>
									<div className="card-body updating-chart height-equal" style={{ paddingBottom: 0 }}>
										<div className="upadates text-center" style={{ paddingBottom: 0 }}>
											<h2 className="font-primary">
												<DollarSign />
												<span> {wallet_info.cashback_amount.toFixed(2)} </span>
											</h2>
											<p>Cashback</p>
										</div>
										<div className="upadates text-center" style={{ paddingBottom: 0 }}>
											<h2 className="font-primary">
												<span>
													{wallet_info.crown_points}{" "}
													{crown_image ? (
														<img src={crown_image} alt="crown-image" style={{ width: "40px", height: "40px" }}></img>
													) : null}{" "}
												</span>
											</h2>
											<p>Points</p>
										</div>
									</div>
									<div className="card-header">
										<h5 style={{ display: "inline-block" }}>Reward History</h5>
										{this.state.activePage * this.state.pageSize > cashbacks.length ? (
											<p className="pull-right" style={{ display: "inline-block" }}>
												Showing {(this.state.activePage - 1) * this.state.pageSize + 1} to {cashbacks.length} of{" "}
												{cashbacks.length} entries
											</p>
										) : (
											<p className="pull-right" style={{ display: "inline-block" }}>
												Showing {(this.state.activePage - 1) * this.state.pageSize + 1} to{" "}
												{this.state.activePage * this.state.pageSize} of {cashbacks.length} entries
											</p>
										)}
									</div>
									<div className="table-responsive">
										<table className="table table-border-horizontal">
											<thead>
												<tr style={{background: '#f9fafe'}}>
													<th scope="col" style={{ width: "6%" }}>
														#
													</th>
													<th scope="col" style={{ width: "14%" }}>
														Transaction ID
													</th>
													<th scope="col" style={{ width: "12%" }}>
														{" "}
														Date / Time
													</th>
													<th scope="col" style={{ width: "20%" }}>
														To/From
													</th>
													<th scope="col" style={{ width: "10%" }}>
														Amount
													</th>
													<th scope="col" style={{ width: "10%" }}>
														Status
													</th>
													{access_level != 1 && (
														<th scope="col" style={{ width: "14%" }}>
															Action
														</th>
													)}
												</tr>
											</thead>
											<tbody>
												{cashbacks
													? cashbacks
															.slice(
																(this.state.activePage - 1) * this.state.pageSize,
																this.state.activePage * this.state.pageSize
															)
															.map((item, index) => (
																<tr key={item._id}>
																	<td scope="row" style={{ width: "6%" }}>
																		{(this.state.activePage - 1) * this.state.pageSize + index + 1}
																	</td>
																	<td style={{ width: "14%" }}>{item.transaction_no}</td>
																	<td style={{ width: "12%" }}>{this.render_date(item.transaction_date)}</td>
																	<td style={{ width: "20%" }}>{item.display_data}</td>
																	<td>{item.amount?.toFixed(2)} </td>
																	<td style={{ width: "10%" }}>{this.getStatus(item.status)}</td>
																	{access_level != 1 && (
																		<td style={{ width: "18%" }}>
																			{access_level == 3 && (
																				<span
																					style={{ marginRight: "10px", fontSize: "1.2em" }}
																					onClick={() => this.deleteClick(item._id)}
																				>
																					<i
																						style={{ color: "red" }}
																						id="delete"
																						title="delete"
																						className="fa fa-trash-o"
																					></i>
																				</span>
																			)}
																			{/*  <span style={{ marginRight: "10px", fontSize: "1.2em" }}
                                                                        onClick={() => this.flagClick(item._id)}>
                                                                        <i style={{ color: item.flag_status ? "red" : "black" }} id="block" className="fa fa-flag-o"></i>
                                                                    </span>
                                                                    <span style={{ marginRight: "10px", fontSize: "1.2em" }}
                                                                        onClick={() => this.infoClick(item._id)}>
                                                                        <i className="fa fa-list-alt"
                                                                            style={{ color: "black" }}></i>
                                                                    </span> */}
																		</td>
																	)}
																	{/* <td style={{ width: "14%" }}>${item.type == 3 ? (item.to_customer_id == customer_id ? item.to_closing_balance.toFixed(2) : item.from_closing_balance.toFixed(2)) : item.to_closing_balance.toFixed(2)}</td> */}
																</tr>
															))
													: null}
											</tbody>
										</table>
										<div
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "space-between",
												padding: "5px 10px 15px",
											}}
										>
											<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
												<select
													className="custom-select"
													style={{ width: "32%", marginRight: "7px" }}
													onChange={this.setPageSize}
													value={this.state.pageSize}
												>
													<option value="15">15</option>
													<option value="25">25</option>
													<option value="50">50</option>
													<option value="75">75</option>
													<option value="100">100</option>
												</select>
												<span>Records per Page</span>
											</div>
											<PaginationComponent
												totalItems={cashbacks.length}
												pageSize={this.state.pageSize}
												onSelect={this.handlePageChange}
											/>
										</div>
									</div>
								</div>
							) : null}
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default CustomerTransaction;
