import React, { useEffect, Fragment, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormGroup, Col, Row, Input, CustomInput } from 'reactstrap';
import * as actionCreators from "../../store/actions/index"
import Breadcrumb from '../../components/common/breadcrumb';
import { DisappearedLoading } from "react-loadingg";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import Editmodal from "./editmodal"
import Dropzone from "./Dropzone/dropzone/DropZoneField"
import ReactDragListView from "react-drag-listview";

const StoreSetting = () => {

    const dispatch = useDispatch();

    let [loading, setloading] = useState(true);
    let [addmodalOpen, setaddmodalOpen] = useState(false);
    let [editmodalOpen, seteditmodalOpen] = useState(false);
    const [idedit, setidedit] = useState('')
    const [url, seturl] = useState('')
    let [urledit, seturledit] = useState('')
    const slider = useSelector(state => state.slider.sliderData)
    { slider && console.log(slider[0]?.field) }
    const tableDragProps = {
        onDragEnd(fromIndex, toIndex) {
            let NewStoreTableData = slider[0]?.field;
            let item = NewStoreTableData.splice(fromIndex, 1)[0];
            NewStoreTableData.splice(toIndex, 0, item);
            NewStoreTableData = NewStoreTableData;
            client.post(api.edit_slider, { order: NewStoreTableData, type: 3 }, async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        dispatch(actionCreators.fetch_slider());
                        toast.success(response.message);
                    }
                }
            })
            console.log(NewStoreTableData)
        },
        nodeSelector: "tr",
    };


    const handleResetClick = () => {
        seturl('')
    };
    const handleDeleteClick = (id) => {
        console.log(id)
        if (id) {
            client.post(api.del_slider, { data: id }, async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        dispatch(actionCreators.fetch_slider());
                        toast.success(response.message);
                    }
                }
            })
        }
    }
    const handleStatusChange = (id, enable) => {
        if (id) {
            client.post(api.edit_slider, { data: id, enable, type: 2 }, async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        dispatch(actionCreators.fetch_slider());
                        toast.success(response.message);
                    }
                }
            })
        }
    }
    const handlesubmitClick = () => {
        if (url) {
            client.post(api.add_slider, { data: url }, async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        dispatch(actionCreators.fetch_slider());
                        toast.success(response.message);
                    }
                }
            })
        }
        seturl('')
        togggle()
    };

    const togggle = () => {
        setaddmodalOpen(!addmodalOpen)
    }

    const togggledit = () => {
        seteditmodalOpen(!editmodalOpen)
    }

    useEffect(() => {
        //fetchData();
        dispatch(actionCreators.fetch_slider());
        setloading(false);
    }, [])
    return (
        <>
            <div className="row">
                <div className="col-lg-7 col-sm-12" style={{ fontSize: "12px" }}>
                    <Breadcrumb title="Store Setting" parent="Home" />
                </div>
                <div className="col-lg-5 col-sm-12 ">
                    <div className="btn-group" style={{ paddingTop: "10px", marginLeft: "0.4rem", marginRight: "0.6rem", marginBottom: "0rem", width: "auto", display: "flex", justifyContent: "flex-end", alignItems: "center" }} role="group" aria-label="Basic example">
                        {true && <button className="btnCashback custBtnAdmin" onClick={(e) => { e.preventDefault(); togggle() }} ><i style={{ margin: "0 7px 0 0", color: "#327ebc" }} id="Add New" title="Add New" className="fa fa-plus"></i>Add slider image</button>}
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card merchantSetttings" style={{ border: '1px solid rgb(223, 232, 241)', borderRadius: '3px'}}>
                            <div className="card-header">
                                <h7 class="font-weight-bold">Promotional Slider</h7>
                            </div>
                            <div className="card-body" style={{ padding: "1rem" }}>
                                {!loading ?
                                    <div className="table-responsive custom-scrollbar">
                                        <ReactDragListView {...tableDragProps}>
                                            <table className="table table-border-horizontal" >
                                                <thead>
                                                    <tr style={{background: '#f9fafe'}}>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Slider image</th>
                                                        <th scope="col">URL</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {slider && slider.map((item) => {
                                                        return (
                                                            item.field.map((item, ind) => (
                                                                <tr
                                                                    key={ind}>
                                                                    <td>#</td>
                                                                    <td>
                                                                        <Dropzone id={item._id} img={item.image} />
                                                                    </td>
                                                                    <td>
                                                                        {item.url}
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={item.enable}
                                                                            onChange={(event) => {
                                                                                event.preventDefault();
                                                                                handleStatusChange(item._id, item.enable);
                                                                            }
                                                                            }
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <span style={{ marginRight: "10px", fontSize: "0.75rem", color: "black" }} onClick={(event) => {
                                                                            event.preventDefault(); seteditmodalOpen(!editmodalOpen); setidedit(item._id); seturledit(item.url);
                                                                        }}>
                                                                            <i className="fa fa-edit"></i>
                                                                        </span>
                                                                        <span style={{ marginRight: "10px", fontSize: "0.75rem", color: "red" }} onClick={(event) => { event.preventDefault(); handleDeleteClick(item._id) }}>
                                                                            <i className="fa fa-trash"></i>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        )
                                                    })
                                                    }
                                                </tbody>
                                            </table>
                                        </ReactDragListView>
                                    </div>
                                    :
                                    <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                                        <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                                            <DisappearedLoading size="medium" color="#514F4E" />
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {editmodalOpen && <Editmodal open={editmodalOpen} toggle={togggledit} url={urledit} id={idedit} />}
            <Modal isOpen={addmodalOpen} toggle={togggle} size="md" className="modal-body" centered={true}>
                <ModalHeader toggle={togggle}><p className="m-0 font-weight-bold">Add Slider URL</p></ModalHeader>
                <ModalBody>
                    <input
                        className="form-control mb-2"
                        type="text"
                        name="url"
                        onChange={(event) => { event.preventDefault(); seturl(event.target.value) }}
                        value={url}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="default" type="button" onClick={(e) => { e.preventDefault(); handleResetClick() }}>Reset</Button>
                    <Button color="primary" type="submit" onClick={(e) => { e.preventDefault(); handlesubmitClick() }}>Save</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default StoreSetting

