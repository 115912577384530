import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import * as actionCreators from "../../store/actions/index";


class RecentLogins extends Component {

    state = {
        access_level: localStorage.getItem("accessLevel"),
        adminDetails: this.props.adminDetails,
        adminUserId: this.props.adminUserId,
    }

    render_date = (add_date) => {

        if (typeof (add_date) === "undefined") {
            return;
        }

        const convert_date = new Date(add_date);
        const added_date = moment.tz(convert_date, "Australia/Sydney").format("DD/MM/YYYY HH:mm");
        return added_date;
    }

    renderAllTable = () => {
        const { adminDetails } = this.state;
        return (
            <Fragment>
                {/* {(this.state.currentPage + 1) * this.state.pageSize > customers.length ?
          <p style={{ textAlign: "right" }}>Showing {(this.state.currentPage * this.state.pageSize) + 1} to {customers.length} of {customers.length} entries</p> :
          <p style={{ textAlign: "right" }}>Showing {(this.state.currentPage * this.state.pageSize) + 1} to {(this.state.currentPage + 1) * this.state.pageSize} of {customers.length} entries</p>} */}

                <div className="table-responsive" >
                    <table className="table table-border-horizontal" >
                        <thead>
                            <tr style={{background: '#f9fafe'}}>
                                <th scope="col" style={{ width: "6%" }}>#</th>
                                <th scope="col" style={{ width: "16%" }}>TIME</th>
                                <th scope="col" style={{ width: "22%" }}>DEVICE</th>
                                <th scope="col" style={{ width: "25%" }}>LOCATION</th>
                                <th scope="col" style={{ width: "20%" }}>IP ADDRESS </th>
                                {/* {this.state.access_level != 1 && <th scope="col" style={{ width: "18%" }}>ACTION</th>} */}
                            </tr>
                        </thead>
                        <tbody>

                            {adminDetails.devices_loggedIn.map((item, index) => {
                                return (
                                    <tr key={item._id}>
                                        <th scope=" row" style={{ width: "6%" }}>{index + 1}</th>
                                        <td style={{ width: "16%" }}>{this.render_date(item.time)}</td>
                                        <td style={{ width: "22%" }}>{item.device}</td>
                                        <td style={{ width: "25%" }}>{item.location}</td>
                                        <td style={{ width: "20%" }}>{item.ip}</td>
                                        {/* {this.state.access_level != 1 && <td style={{ width: "18%" }}>
                                            <span style={{ marginRight: "10px", fontSize: "1.2em" }} style={{ cursor: "pointer" }} onClick={() => this.logOutDeviceClick(item._id)}>
                                                <i className="icofont icofont-logout" style={{ color: "black" }} id="info"></i>
                                                
                                            </span>
                                        </td>} */}
                                    </tr>);
                            })}
                        </tbody>
                    </table>
                </div>
            </Fragment>
        );
    }

    render() {
        /* if (this.props.errorMsg) {
            toast.error(this.props.errorMsg)
        }
        if (this.props.successMsg) {
            toast.success(this.props.successMsg)
        } */
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-xl-12 xl-100">
                        <div className="card">
                            <div className="card-body" style={{ padding: "0.3rem" }}>
                                {this.renderAllTable()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        );
    }
}


export default RecentLogins;




