import React, { useState,Fragment,useEffect } from "react";
import api from "../../constant/apilist";
import client from "../../Api/HTTPClient";
import {Table, Badge, Button} from 'reactstrap';
import moment from "moment";
import Icon from "@mdi/react";
import PaginationComponent from "react-reactstrap-pagination";
import { DisappearedLoading } from "react-loadingg";
import {mdiStar,} from "@mdi/js";


const RequestPayments = ({merchant_id})=>{
  const [data, setData] = useState(0)
  const [requestData, setRequestData] = useState();
  const [pageSize, setPageSize] = useState(15);
  const [searchValue, setSearchValue] = useState(null);
  const [pageIndex,setPageIndex] = useState(1);
  const [searchInputVal,setSearchInputVal] = useState(null);
  const [filterDuration, setFilterDuration] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [staffValue, setStaffValue] = useState("");
	const [filterStaff, setFilterStaff] = useState([]);
  const [loading,setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber)
    fetchPaymentLinksData();

  }


  const fetchPaymentLinksData = (searchValue) => {
    const postData = {
      merchant_id: merchant_id,
      request_from: 2,
      pageSize,
      pageIndex: pageIndex - 1,
      dateFilterType: filterDuration.value,
      startDate,
      endDate,
      paidOnly: true,
      searchInputVal: searchValue == "reset" ? null : searchInputVal,
      filterByStaff: staffValue,
    }
    client.post(api.link_requests, postData, (error, response) => {
        if (!error) {
            if (!response.error) {
                // console.log(response,'request-response')
                setRequestData(response.result);
                setData(response.recorsCount);
                setLoading(false);
            } else {
                console.log(error, 'error')
            }
        } else {
            console.log(error,'error-1')
        }
    });
  };

  useEffect(()=>{
    fetchPaymentLinksData();
  },[pageSize, pageIndex, activePage])

  const [copyTextToolTip, setCopyTextToolTip] = useState(false);
  const [getTooltip, setGetToolTip] = useState();

  const getLinkDomain = (type = 1) => {
    const host = client.url("");
    let api_domain = host.replace(/(https?:\/\/)?(www.)?/i, '');
    if (api_domain.indexOf('/') !== -1) {
      api_domain = api_domain.split('/')[0];
    }
  
    if (type == 2) {
      let hostName = window?.location?.hostname.replace("dm.portal.", "");
      hostName = hostName.replace("portal.", "");
  
      if (api_domain == "dmapi.ipaypro.co") {
  
        return `https://dm.${hostName}`;
      } else {
        return `https://${hostName}`;
      }
  
    } else {
  
      if (api_domain == "dmapi.ipaypro.co") {
        return "https://demo.payments.linky.biz";
      } else {
        return "https://payments.linky.biz";
      }
  
    }
  
  }

  const getTimeZone = () => {

    try {
      const company = JSON.parse(localStorage.getItem('@_company'));
  
      if (company && company.timeZone) {
        return company.timeZone;
      } else {
        return "Australia/Sydney";
      }
    } catch {
      return "Australia/Sydney";
    }
  
  }  

  const copyTextToolTipToggle = () => {
  setCopyTextToolTip(!copyTextToolTip);
  };

  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <p className="text">
            {isReadMore ? text.slice(0, 20) : text}
            {text.length >= 20 ? (
                <span
                    style={{ cursor: "pointer", color: "#D23F99", marginLeft: "3px" }}
                    onClick={toggleReadMore}
                    className="read-or-hide"
                >
                    {isReadMore
                        ? `\n...Read More`.replace(
                            /(.{7})/g,
                            "$1\n"
                        )
                        : `\n Show Less`.replace(
                            /(.{6})/g,
                            "$1\n"
                        )}
                </span>
            ) : null}
        </p>
    );
  };

  const ShowCurrencyNum = (amount, showSymbol) => {
    const merchantData = JSON.parse(localStorage.getItem("merchant"));
    const currency_symbol = merchantData?.country?.currency_symbol;
    if (amount == undefined) {
      return currency_symbol;
    }

    let getCurrency = !isNaN(parseFloat(amount)) ? amount : 0;

    if (merchantData && merchantData.country) {
      if (showSymbol && currency_symbol) {
        if (getCurrency < 0) {
          getCurrency = `-${currency_symbol}${Math.abs(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
        } else {
          getCurrency = `${currency_symbol}${parseFloat(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
        }
      }
      else {
        getCurrency = +`${parseFloat(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
      }
    }

    return getCurrency;
  }

  const RenderRefundStatus = ({ refund_data }) => {

    if (refund_data) {
        
        return (
            <>
                {refund_data.type == 1 ?
                    <Badge color="disabled" style={{width:'80%',display:'flex',justifyContent:'center'}}>Refunded</Badge>
                    :
                    // <p>
                    //     Partially refunded ({ShowCurrencyNum(refund_data?.amount, true)})
                    // </p>
                    <div className='d-flex flex-column align-items-center'>
                        <Badge color="disabled" 
                            style={{
                                width:'80%',
                                display:'flex',
                                justifyContent:'center',
                            }}
                        >
                            Partially refunded
                        </Badge>
                        <span>({ShowCurrencyNum(refund_data?.amount, true)})</span>
                    </div>
                    
                    
                }
            </>
        )
    } else {
        return null
    }
  }

  const handleSearch = (event)=>{
    event.preventDefault();
    if(searchInputVal==null||searchInputVal==""){
      fetchPaymentLinksData("reset");
    }
    fetchPaymentLinksData(searchInputVal);
  }

  return(
    <Fragment>
      {loading && <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                    <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                        <DisappearedLoading size="medium" color="#514F4E" />
                    </div>
                </div>}
        <div className="m-1 p-1 mt-2" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div >
                        <form onBlur={handleSearch} onSubmit={(event) => handleSearch(event)}>
                            <div >
                                <input style={{
                                    border: "none",
                                    borderBottom: "1px solid rgb(197, 189, 189)",
                                    borderRadius: "0",
                                    margin: "3px 10px",
                                    fontSize: "0.75rem",

                                }}
                                    type="text"
                                    placeholder="Search"
                                    onChange={(e)=>{
                                      setSearchInputVal(e.target.value)
                                      fetchPaymentLinksData(searchInputVal)
                                    }} 
                                />
                            </div>
                        </form>
                    </div>
                    {(activePage) * pageSize > requestData?.length ?
                        <p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>Showing {((activePage - 1) * pageSize) + 1} to {((activePage - 1) * pageSize)+requestData?.length} of {data} entries</p> :
                        <p style={{ textAlign: "right", fontSize: "0.63rem", margin: "0" }}>Showing {((activePage - 1) * pageSize) + 1} to {(activePage) * pageSize} of {data} entries</p>}
                </div>
      <div className="table-responsive mx-1 px-1 my-1 py-1">
        <Table className="table table--border-horizontal">
          <thead>
            <tr>
              <th className="border">#</th>
              <th className="border">Date/Time</th>
              <th className="border">Payment No</th>
              <th className="border">Customer Details</th>
              <th className="border">Ratings</th>
              <th className="border">Message</th>
              <th className="border">Description</th>
              <th className="border">Payment Method</th>
              <th className="border">Amount</th>
              <th className="border">Status</th>
            </tr>
          </thead>
          { 
            requestData?.length ? (
              <tbody>
                {
                  requestData.map((item,ind)=>{
                    let recordIndex = pageSize*(activePage-1)+(ind+1);
                    const convert_date = new Date(item.request_date);
                    const added_date = moment.tz(convert_date,getTimeZone()).format("DD/MM/YYYY HH:mm");
                    let dueDate = new Date(item.request_date);
                        dueDate.setDate(dueDate.getDate() + 60);
                        dueDate = moment.tz(dueDate, getTimeZone()).format("DD/MM/YYYY");

                    let customerDetails = 'NA';
                    let cusData = item.ticket_Cus;
                      if(cusData){
                        if (cusData.name && cusData.name.length) {
                          customerDetails = `Name - ${cusData.name.substring(0, 1).toUpperCase()}${cusData.name.substring(1)}`
                      }

                      if (cusData.mobile && cusData.mobile.length) {
                          customerDetails = `${customerDetails}\nMobile - ${cusData?.mobile_prefix || ''}${cusData.mobile}`
                      }

                      if (cusData.email && cusData.email.length) {
                          customerDetails = `${customerDetails}\nEmail - ${cusData.email}`
                      }

                      if (cusData.date_of_birth && cusData.date_of_birth.length) {
                          customerDetails = `${customerDetails}\nDOB - ${cusData.date_of_birth}`
                      }
                    }

                    let getDescription = `Tip Paid To ${JSON.parse(item.display_data_from).name}${item && item.staff_details && item.staff_details.add_staff ? ` (${item.staff_details.add_staff})` : ''}, TIP-${item.token_id}`
                        return(
                          <tr key={ind}>
                              <td className="border">{recordIndex}</td>
                              <td className="border">
                                  {`${added_date}`.replace(
                                      /(.{10})/g,
                                      "$1\n"
                                  )}
                              </td>
                              {/* <td className="border">
                                  {`TIP-${item.token_id}`}
                              </td> */}
                              <td className="border" style={{whiteSpace:'pre'}}>
                                TIP-<span>{item.token_id}</span>
                              </td>
                              <td className="border" style={{ whiteSpace: 'pre' }}>
                                  {customerDetails}
                              </td>
                              <td className="border">
                              <div>
                                <Icon path={mdiStar} className="mx-auto" size={0.6} color={item.starRating >= 1 ? "#FD9305" : 'gray'} />
                                <Icon path={mdiStar} className="mx-auto" size={0.6} color={item.starRating >= 2 ? "#FD9305" : 'gray'} />
                                <Icon path={mdiStar} className="mx-auto" size={0.6} color={item.starRating >= 3 ? "#FD9305" : 'gray'} />
                                <Icon path={mdiStar} className="mx-auto" size={0.6} color={item.starRating >= 4 ? "#FD9305" : 'gray'} />
                                <Icon path={mdiStar} className="mx-auto" size={0.6} color={item.starRating >= 5 ? "#FD9305" : 'gray'} />
                              </div> 
                              </td>
                              <td className="border text-left">{item.note && item.note.trim().toLowerCase() != getDescription.trim().toLowerCase() ? `${item.note.charAt(0).toUpperCase()}${item.note.slice(1)}`.replace(/(.{20})/g, '$1\n') : 'NA'}</td>
                              <td className="border text-left">{`${getDescription}`.replace(/(.{20})/g, '$1\n')}</td>
                              <td className="border"> {item.status == 1 ? item.payment_method == 3 ? `Cash` : item.payment_method == 2 ? "Card" : "IpayPro" : '-'}</td>
                              <td className="border"> {item.amount > 0 ? `${ShowCurrencyNum(item.amount.toFixed(2), true)}` : `-`} </td>
                              <td className="border">
                                  {item.refund_status == 1 ? (
                                      <div className="d-flex justify-content-center">
                                          <RenderRefundStatus
                                              refund_data={item?.refund_data}
                                          />
                                      </div>
                                  ) : (
                                      <div>
                                          {item.status == 0 ? (
                                              <div className="d-flex justify-content-center">
                                                  <Badge style={{
                                                      background:'#FFBD57', 
                                                      color:'white',
                                                      width:'80%',    
                                                      display: 'flex',
                                                      justifyContent: 'center',
                                                      }}>Unpaid</Badge>
                                              </div>
                                          ) : item.status == 1 ? (
                                              <div className="d-flex justify-content-center">
                                                  <Badge style={{background:'green', color:'white', width:'80%', display: 'flex',
                                                      justifyContent: 'center',}}>Paid</Badge>
                                              </div>
                                          ) : item.status == 2 ? (
                                              <span
                                                  style={{
                                                      backgroundColor: "transparent",
                                                      borderRadius: "20px",
                                                      color: "red",
                                                  }}
                                              >
                                                  Expired
                                              </span>
                                          ) : item.status == 3 ? (
                                              <span
                                                  style={{
                                                      backgroundColor: "transparent",
                                                      borderRadius: "20px",
                                                      color: "red",
                                                      cursor: "pointer",
                                                  }}
                                                  // onClick={() =>
                                                  //     handleSuspicious(
                                                  //         item.suspiciousReason
                                                  //     )
                                                  // }
                                              >
                                                  Marked Suspicious
                                              </span>
                                          ) : null}
                                      </div>
                                  )}
                              </td>
                          </tr>
                        )
                  })
                }
              </tbody>
            ):
              <h6 style={{ textAlign: "center", marginTop: "1rem" }}> No records found! </h6>
            
          }
        </Table>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <select className="custom-select" style={{ width: "35%", fontSize: "0.63rem", marginRight: "10px" }} onChange={(event) => setPageSize(event.target.value)} value={pageSize}>
                    <option value="15">15</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                </select>
                <span style={{ fontSize: "0.63rem" }}>Records per Page</span>
            </div>
            <PaginationComponent
                totalItems={data}
                pageSize={pageSize}
                onSelect={handlePageChange} 
            />
        </div>
      </div>
    </Fragment>
  )
}

export default RequestPayments