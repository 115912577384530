import React, { Fragment, PureComponent } from "react";
import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import { TabContent, TabPane, Nav, NavItem, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PaginationComponent from "react-reactstrap-pagination";

import client from "../Api/HTTPClient";
import api from "../constant/apilist";
import { DisappearedLoading } from "react-loadingg";

import {
    DollarSign,
} from "react-feather";
import { Link, Redirect, withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";


class CustomerTransaction extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            customer_id: this.props.customerId,
            customer_info: this.props.customerInfo,
            wallet_info: this.props.walletInfo,
            transactions: null,
            flagModal: false,
            flag_note: "",
            deleteModal: false,
            infoModal: false,
            modalId: null,
            isLoaded: false,
            items: [],
            statusModal: false,
            statusMessage: null,
            activePage: 1,
            pageSize: 15,
            access_level: localStorage.getItem("accessLevel"),
            adminUser: JSON.parse(localStorage.getItem("user")),
            successClickModal: false,
            selectedTransaction: null,
            refund_amount: null,
            successClickOption: null,
            transc_rejectReason: null,
            searchData: []

        };
    }


    componentDidMount() {
        //console.log(this.state.transactions);
        this.getCustomersWallet(this.state.customer_id);

    }

    /* componentDidUpdate(prevProps, prevState){
        console.log(prevProps, prevState);
        console.log(this.state);
        if(prevProps.match.params.customer_id != this.state.customer_id)  {
            this.getCustomersWallet(prevProps.customerId);
          }
    } */

    static getDerivedStateFromProps(nextProps, prevState) {
        console.log(nextProps, prevState)
        if (nextProps.customerId != prevState.customer_info._id) {
            console.log("in get derived state from props");
            return {
                customer_id: nextProps.customerId,
                transactions: nextProps.transactionsInfo,
                wallet_info: nextProps.walletInfo,
                customer_info: nextProps.customerInfo,
                activePage: 1
            }
        }
    }

    getCustomersWallet(customer_id) {
        //let { customer_id } = this.state;
        let postdata = { customer_id: customer_id };
        // current.setState({ loading: true });
        client.post(api.customers_wallet, postdata, (error, response) => {
            if (!error) {
                // alert(JSON.stringify(response));
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            this.setState({ transactions: response.result.transactions, searchData: response.result.transactions });
                            // current.props.navigation.navigate("Verification");
                            console.log(response);

                        } else {
                            setTimeout(() => {
                                toast.error(response.message);
                            }, 200);
                        }
                    } catch (e) {
                        setTimeout(() => {
                            toast.error("Internal error occured. Please contact support");
                        }, 200);
                        console.log(e);

                        // _showToast.error("Internal error occured. Please contact support.");
                        // currentComponent.setState({ errormessage: "Internal error." });
                    }
                } else {
                    setTimeout(() => {
                        toast.error("Internal error occured. Please contact support");
                    }, 200);

                    //   _showToast.error("Internal error occured. Please contact support.");
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);


                // alert(JSON.stringify(error));
                // _showToast.error("Internal error occured. Please contact support.");
            }
            // current.setState({ loading: false });
        });
    };



    setPageSize = (event) => {
        this.setState({ pageSize: event.target.value })
    }

    handlePageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber });
    }

    renderTitle(item,) {
        const { customer_id } = this.state;
        let title = "";
        let subtitle = "";

        if (item.type == 1) {
            if (item.topup_type == 2 || item.topup_type == 3) {
                if (customer_id == item.to_customer_id) {
                    title = "Topup request:";
                    subtitle = `${item.topup_type == 2 ? "Direct deposit" : "Pay ID"} `;
                } else {
                    let display_data = {
                        from_name: '',
                        from_mobile: '',
                        to_name: '',
                        to_mobile: '',
                    }
                    try {
                        display_data = JSON.parse(item.display_data);
                    } catch (error) {

                    }

                    title = "Paid To";
                    subtitle = display_data.to_name == "" ? display_data.to_mobile : display_data.to_name + " for receipt topup";
                }


            } else {
                title = "Added to wallet from:";
                /* subtitle = `**${item.card_number[14]}${item.card_number[15]}`; */
                if (item.payment_method == 6)
                    subtitle = "apple pay";
                else if (item.payment_method == 7)
                    subtitle = "google pay";
                else
                    subtitle = `${item.card_number.substring(0, 6)} ****** ${item.card_number.substring(12)}`
            }

        } else if (item.type == 3) {
            let display_data = {
                from_name: '',
                from_mobile: '',
                to_name: '',
                to_mobile: '',
            }
            try {
                display_data = JSON.parse(item.display_data);
            } catch (error) {

            }

            if (customer_id == item.to_customer_id) {
                title = "Received From";

                if (item.from_customers.length > 0) {
                    subtitle = item.from_customers[0].name
                } else if (item.from_mechant.length > 0) {
                    subtitle = item.from_mechant[0].business_name
                } else if (item.card_number) {
                    subtitle = "**" + item.card_number.slice(-4);
                }

            } else if (customer_id == item.from_customer_id) {
                title = "Paid To";

                if (item.to_customers.length > 0) {
                    subtitle = item.to_customers[0].name
                } else if (item.to_mechant.length > 0) {
                    subtitle = item.to_mechant[0].business_name
                } else if (item.card_number) {
                    subtitle = "**" + item.card_number.slice(-4);
                }


            }

        }
        else if (item.type == 4) {
            title = "Settlement Request";
            subtitle = ""
        }
        else if (item.type == 2) {
            title = "Cashback";
            subtitle = "i-Pay Wallet"
        }
        return (
            <div>{item.type == 3 ?
                <span
                    style={{
                        // marginLeft: 5,
                        fontStyle: "normal",
                        letterSpacing: 0,
                        textAlign: "left",
                    }}>
                    <span>
                        <p style={{ fontWeight: 'bold' }}>{title} </p>
                        {
                            <p>
                                {customer_id == item.to_customer_id && item.from_customers.length > 0 ?
                                    <Link to={{
                                        pathname: `${process.env.PUBLIC_URL}/customer/wallet/${item.from_customer_id}`,

                                    }}>{subtitle}
                                    </Link>

                                    : customer_id == item.to_customer_id && item.from_mechant.length > 0 ?
                                        <Link to={{
                                            pathname: `${process.env.PUBLIC_URL}/merchants/merchantDetails/${item.from_customer_id}`,

                                        }}>{subtitle}
                                        </Link>

                                        : customer_id == item.from_customer_id && item.to_customers.length > 0 ?
                                            <Link to={{
                                                pathname: `${process.env.PUBLIC_URL}/customer/wallet/${item.to_customer_id}`,

                                            }}>{subtitle}
                                            </Link>



                                            : customer_id == item.from_customer_id && item.to_mechant.length > 0 ?
                                                <Link to={{
                                                    pathname: `${process.env.PUBLIC_URL}/merchants/merchantDetails/${item.to_customer_id}`,

                                                }}>{subtitle}
                                                </Link>

                                                : null}
                            </p>
                        }
                    </span>
                </span> :
                <span
                    style={{
                        // marginLeft: 5,
                        fontStyle: "normal",
                        letterSpacing: 0,
                        textAlign: "left",
                    }}>
                    <span style={{ fontWeight: 'bold' }}>{title}</span>{subtitle}
                    {/* {`${title} ${subtitle}`} */}
                </span>}


            </div>
        )
    }

    refreshPage() {
        window.location.reload(false);
    }

    render_date = (add_date) => {
        if (typeof (add_date) === "undefined") {
            return;
        }
        const convert_date = new Date(add_date)
        const added_date = moment.tz(convert_date, "Australia/Sydney").format("DD/MM/YYYY HH:mm:ss");
        return added_date;
    }

    toggleStatusModal = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                statusModal: !prevState.statusModal
            }
        })
    }

    getStatus = (status) => {
        if (status == 1) {
            return "Success"
        } if (status == 0) {
            return "Pending"
        } else {
            return "Failed"
        }
    }

    statusClick = (message) => {
        const failedMessage = JSON.parse(message);
        this.setState(prevState => {
            return {
                ...prevState,
                statusModal: !prevState.statusModal,
                statusMessage: failedMessage.message
            }
        });
    }

    deleteTransactionHandler = (id) => {
        const postData = { id: id };

        client.post(api.delete_transaction, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    console.log(response);
                    // this.getAllTransactions();
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        })
    }

    flagSubmitHandler = (event, id) => {
        event.preventDefault();
        let postdata = {}
        const foundTransaction = this.state.transactions.find(el => {
            return el._id == id
        });

        if (typeof foundTransaction.flag_status === "undefined") {
            foundTransaction.flag_status = false;
        }
        if (event.target.name == "unflag") {
            postdata = { transaction_id: id, flag_status: !foundTransaction.flag_status, flag_note: { type: "unflag", note: "No comments", user: this.state.adminUser.email } }
        } else if (event.target.name == "comment") {
            postdata = { transaction_id: id, flag_note: { type: "normal", note: this.state.flag_note, user: this.state.adminUser.email } }
        } else {
            postdata = { transaction_id: id, flag_status: !foundTransaction.flag_status, flag_note: { type: "flag", note: this.state.flag_note, user: this.state.adminUser.email } }
        }
        client.put(api.update_transaction, postdata, (error, response) => {
            if (!error) {
                if (!response.error) {
                    console.log(response);
                    this.refreshPage();
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        })
    }

    flagClick = (id) => {
        this.setState(prevState => {
            return {
                ...prevState,
                flagModal: !prevState.flagModal,
                modalId: id
            }
        });
    }

    deleteClick = (id) => {
        this.setState(prevState => {
            return {
                ...prevState,
                deleteModal: !prevState.deleteModal,
                modalId: id
            }
        });
    }

    infoClick = (id) => {
        this.setState(prevState => {
            return {
                ...prevState,
                infoModal: !prevState.infoModal,
                modalId: id
            }
        });
    }

    flagNoteHandler = (event) => {
        const flagNote = event.target.value;
        this.setState(prevState => {
            return {
                ...prevState,
                flag_note: flagNote
            }
        })
    }

    toggleFlagModal = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                flagModal: !prevState.flagModal
            }
        })
    }
    toggleDeleteModal = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                deleteModal: !prevState.deleteModal
            }
        })
    }
    toggleInfoModal = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                infoModal: !prevState.infoModal
            }
        })
    }

    renderFlagModal = (id) => {
        const foundTransaction = this.state.transactions.find(el => {
            return el._id == id
        });
        const style = {
            outline: "none",
            ':focus': {
                outlineColor: "black"
            }
        };

        if (!foundTransaction.flag_status) {
            return (
                <Modal isOpen={this.state.flagModal} toggle={this.toggleFlagModal} className="modal-body" centered={true}>
                    <ModalBody>
                        <form onSubmit={(event) => this.flagSubmitHandler(event, this.state.modalId)} className="needs-validation">
                            <h5>Please enter a note to flag transaction.</h5>
                            <p style={{ color: "red" }}>Note: User will no longer be able to view transaction in their application.</p>
                            <label className="form-label" style={{ display: "block" }}>Note:</label>
                            <input type="text" size="40" onChange={this.flagNoteHandler}
                                style={style}
                                required></input>
                            <Button color="primary" style={{ marginTop: "20px", marginRight: "20px" }} name="flag">Update status</Button>
                            <Button color="secondary" style={{ marginTop: "20px", marginRight: "20px" }} type="submit" onClick={this.toggleFlagModal}>Cancel</Button>
                        </form>
                    </ModalBody>
                </Modal>
            );
        }
        return (
            <Modal isOpen={this.state.flagModal} toggle={this.toggleFlagModal} className="modal-body" centered={true}>
                <ModalBody>
                    <h5>Are you sure you want to unflag transaction ?</h5>
                    <Button color="primary" style={{ marginTop: "20px", marginRight: "20px" }} name="unflag" onClick={(event) => this.flagSubmitHandler(event, this.state.modalId)}  >Yes, please</Button>
                    <Button color="secondary" style={{ marginTop: "20px", marginRight: "20px" }} onClick={this.toggleFlagModal}>Not Sure</Button>
                </ModalBody>
            </Modal>
        );

    }

    renderDeleteModal = (id) => {
        return (

            <Modal isOpen={this.state.deleteModal} toggle={this.toggleDeleteModal} className="modal-body" centered={true}>
                <form className="theme-form" noValidate="" >
                    <ModalBody>
                        <h4>Do you really want to delete this transaction ?</h4>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit" onClick={() => this.deleteTransactionHandler(id)} >Yes</Button>
                        <Button color="secondary" onClick={this.toggleDeleteModal}>No</Button>
                    </ModalFooter>
                </form>
            </Modal>

        );
    }

    renderInfoModal = (id) => {
        const foundTransaction = this.state.transactions.find(el => {
            return el._id == id
        })

        if (typeof foundTransaction.flag_note == "undefined") {
            foundTransaction.flag_note = [];
        }
        let foundTransactionNote = [...foundTransaction.flag_note].reverse();
        return (

            <Modal isOpen={this.state.infoModal} toggle={this.toggleInfoModal} className="modal-body" centered={true}>
                <h5 style={{ padding: "15px", margin: "0" }}>Comments</h5>

                <ModalBody style={{ paddingTop: "0", paddingBottom: "0", maxHeight: 'calc(100vh - 370px)', overflowY: 'auto' }}>
                    <div className="card-body chat-box" style={{ paddingTop: "0", paddingBottom: "0" }}>
                        <div className="chat-right-aside bitcoin-chat">
                            <div className="chat">
                                <div className="chat-history chat-msg-box custom-scrollbar" style={{ padding: "0", margin: "0" }}>
                                    <ul style={{ margin: "0" }}>
                                        {foundTransactionNote.length != 0 ? foundTransactionNote.map(el => {
                                            return (<li style={{ margin: "0" }}>
                                                <div className="message my-message " style={{ marginBottom: "10px", paddingTop: "10px", paddingBottom: "10px" }}>
                                                    <h6>{el.user}<span className="message-data-time pull-right">{this.render_date(el.date)}</span> </h6>
                                                    {el.type == "normal" ? null : <p style={{ fontSize: "0.9rem" }}>Type : {el.type}</p>}
                                                    {el.note}
                                                </div>
                                            </li>)
                                        }) : <p>No note added!</p>}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </ModalBody>
                <ModalFooter style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                    <div className="col-lg-12">
                        <form className="theme-form" >
                            <div className="form-group mb-0 col-12">
                                <label htmlFor="exampleFormControlTextarea4">Comment:</label>
                                <textarea className="form-control" id="exampleFormControlTextarea4" required rows="3" onChange={this.flagNoteHandler}></textarea>

                            </div>
                            <div style={{ padding: "15px 0 0 15px" }}>
                                <Button color="secondary" onClick={this.toggleInfoModal} style={{ marginRight: "15px" }}>Cancel</Button>
                                <Button color="primary" type="submit" name="comment" onClick={(event) => this.flagSubmitHandler(event, this.state.modalId)}>Submit</Button>
                            </div>
                        </form>
                    </div>
                </ModalFooter>

            </Modal>

        );
    }


    renderStatusModal = () => {
        return (
            <Modal isOpen={this.state.statusModal} toggle={this.toggleStatusModal} className="modal-body" centered={true}>
                <form className="theme-form" noValidate="" >
                    <ModalBody style={{ textAlign: "center" }}>
                        <div style={{ fontSize: "2rem", textAlign: "center" }}><i className="icofont icofont-warning" style={{ color: "#FF2046" }}></i></div>
                        <h4 style={{ color: "#FF2046" }}>Failure!</h4>
                        <h6>{this.state.statusMessage}</h6>
                        <Button className="btn btn-danger" style={{ width: "100%", textAlign: "center", fontSize: "1.1rem", marginTop: "10px" }} onClick={this.toggleStatusModal}>Dismiss</Button>
                    </ModalBody>

                </form>
            </Modal>
        );
    }

    getReason = (transaction) => {
        if (transaction.comment == "") {
            return ""
        }

        const comment = JSON.parse(transaction.comment);
        if (typeof comment.message == "undefined") {
            return ""
        } else if (comment.message.toLowerCase().includes("card details")) {
            return "Invalid card details"
        } else if (comment.message.toLowerCase().includes("i-pay pin")) {
            return "Invalid i-Pay pin"
        } else if (comment.message.toLowerCase().includes("otp authentication") || comment.message.toLowerCase().includes("user cancel")) {
            return "Pressed cancel or back button"
        } else if (comment.message.toLowerCase().includes("working days")) {
            return "Server or api error"
        } else if (comment.message.toLowerCase().includes("insufficient funds")) {
            return "Insufficient funds"
        } else if (comment.message.toLowerCase().includes("card has been declined")) {
            return "Card declined"
        } else if (comment.message.toLowerCase().includes("card type you entered isn't supported")) {
            return "Card type not supported"
        } else if (comment.message.toLowerCase().includes("payment amount is invalid")) {
            return "Invalid amount or exceeds max amount"
        } else if (comment.message.toLowerCase().includes("customer has exceeded the balance or credit limit available on their card")) {
            return "Card limit exceeded"
        } else if (comment.message.toLowerCase().includes("card does not support this type of purchase")) {
            return "Card not supported"
        } else if (comment.message.toLowerCase().includes("card has expired")) {
            return "Card expired"
        } else if (comment.message.toLowerCase().includes("card issuer could not be reached, so the payment could not be authorized")) {
            return "Card declined"
        } else {
            return ""
        }

    }

    onSuccessClick = (transaction) => {
        if (transaction.type == 1) {
            toast.error("Refund or Reject not available")
        }
        else if (typeof transaction.refund_transc_link != "undefined" || transaction.refund_transc_link == "") {
            toast.error("Refund or Reject not available")
            //this.setState({ successClickModal: true, selectedTransaction: transaction, successClickOption: "reject" })
        } else {
            this.setState({ successClickModal: true, selectedTransaction: transaction, successClickOption: "refund" })
        }

    }

    toggleSuccessClickModal = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                successClickModal: !prevState.successClickModal,
                successClickOption: " refund"
            }
        })
    }

    onSubmitRefundRequest = (event) => {
        event.preventDefault();
        let { refund_amount, selectedTransaction } = this.state
        let postData = { transaction_no: selectedTransaction.transaction_no, amount: +refund_amount };
        if (this.state.successClickOption == "reject") {
            postData = { transaction_no: selectedTransaction.transaction_no, amount: selectedTransaction.amount.toFixed(2), type: "reject", rejectReason: this.state.transc_rejectReason };
            refund_amount = selectedTransaction.amount
        }

        //setLoading(true)
        if (+refund_amount <= selectedTransaction.amount) {
            //console.log(postData);
            client.post(api.refund_request, postData, (error, response) => {
                if (!error) {
                    console.log(response);
                    try {
                        if (!response.error) {
                            //console.log(response)
                            toast.success("Amount refunded")
                            //props.fetchTransaction();
                        } else {
                            toast.error("Internal error occured.Please contact support.")
                        }
                    } catch (error) {
                        toast.error("Internal error occured.Plelase contact support.")
                    }

                } else {

                    toast.error("Internal error occured.")

                }
            });

        } else {
            toast.error("Please enter correct amount")
        }
    }

    renderSuccessClickModal = () => {
        const { selectedTransaction, successClickModal, successClickOption } = this.state
        return (
            <Modal isOpen={this.state.successClickModal} toggle={this.toggleSuccessClickModal} className="modal-body" centered={true}>
                <form className="theme-form" noValidate="" onSubmit={this.onSubmitRefundRequest}>
                    <ModalHeader toggle={this.toggleSuccessClickModal}><h6>{successClickOption == "refund" ? "Initiate Refund" : "Reject transaction"}</h6></ModalHeader>
                    <ModalBody >
                        {successClickOption == "refund" ? <Fragment>
                            <lable>Enter amount for refund</lable>
                            <input name="refund_amount" style={{
                                border: "none",
                                borderBottom: "1px solid rgb(197, 189, 189)",
                                borderRadius: "0",
                                margin: "0px 10px 6px",
                                fontSize: "0.75rem",

                            }} required onChange={(event) => this.setState({ refund_amount: event.target.value })} type="text"></input>
                            <p style={{ color: "red" }}>Note:You can refund upto ${(selectedTransaction.amount).toFixed(2)}</p>
                        </Fragment> : <Fragment>
                            <lable>Please enter note for your action</lable>
                            <input name="transc_rejectReason" onChange={(event) => this.setState({ transc_rejectReason: event.target.value })} type="text" required></input>
                        </Fragment>}
                    </ModalBody>
                    <ModalFooter>
                        {<Button color="secondary" onClick={this.toggleSuccessClickModal}>Not sure!</Button>}
                        <Button color="success" type="submit">Yes, Please!</Button>
                        {successClickOption == "refund" && <Button color="primary" onClick={() => this.setState({ successClickOption: "reject" })}>Reject</Button>}
                    </ModalFooter>
                </form>
            </Modal>
        )

    }

    returnText = (data) => {
        console.log("data", data);
        let newdata = JSON.stringify(this.renderTitle(data))
        var name = (JSON.parse(newdata).props?.children?.props?.children?.props?.children[1]?.props.children)?.toString()
        return name !== "" || name !== undefined || name !== null ? name : "";
    }


    onSearch = (e) => {
        //|| v.transaction_no.includes(e.target.value) || v.amount.toFixed(2)
        let searchResult = []
        var reg = new RegExp('^[0-9]+$');
        let result = this.state.transactions && this.state.transactions.map(v => {
            if (this.returnText(v)?.toLowerCase().includes(e.target.value.toLowerCase()) || v.transaction_no.includes(e.target.value) || v.amount.toFixed(2).includes(e.target.value)) {
                return v;
            }
        })

        if (e.target.value?.length > 0) {
            searchResult = result
        }
        else searchResult = this.state.transactions
        this.setState({ searchData: searchResult.filter(v => v !== undefined) })
        console.log(searchResult.filter(v => v !== undefined))
    }

    ShowCurrencyNum = (amount, showSymbol) => {
        // const merchantData = JSON.parse(localStorage.getItem("merchant"));
        const customerData = this.state.customer_info;
        const currency_symbol = customerData.country.currency_symbol;
        // const currency_symbol = countryData.currency_symbol;

        let getCurrency = !isNaN(parseFloat(amount)) ? amount : 0;

        if (customerData && customerData.country) {
            // if (countryData) {
            if (showSymbol && currency_symbol) {
                getCurrency = `${currency_symbol}${parseFloat(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
            }
            else {
                getCurrency = +`${parseFloat(getCurrency).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
            }
        }

        return getCurrency;
    }



    render() {
        let { customer_info, customer_id, wallet_info, transactions, access_level, searchData } = this.state;
        console.log(transactions);
        console.log(customer_id);
        if (transactions) {
            let transactionWithoutCashback = this.state.transactions.filter(el => {
                return el.type == 2 && el.status == 1
            })
            let amountWithoutCashback = 0;
            transactionWithoutCashback.forEach((elem) => {
                amountWithoutCashback += elem.amount
            })
        }


        return (
            <Fragment>
                {this.state.modalId ? this.renderFlagModal(this.state.modalId) : null}
                {this.state.modalId ? this.renderDeleteModal(this.state.modalId) : null}
                {this.state.modalId && transactions ? this.renderInfoModal(this.state.modalId) : null}
                {this.renderStatusModal()}
                {this.state.selectedTransaction && this.renderSuccessClickModal()}
                {customer_id ? (
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12" >
                                {transactions ?
                                    <div className="card" style={{ padding: "20px" }}>
                                        <div className="card-header" style={{ border: "none", padding: "10px 30px" }}>
                                            <h6>
                                                {/* {customer_id} */}
                                                Name : {customer_info.name}, Mobile : {customer_info.mobile}
                                            </h6>
                                        </div>
                                        <div
                                            className="card-body updating-chart height-equal"
                                            style={{ padding: "0 25px" }}
                                        >
                                            <div
                                                className="upadates text-center"
                                                style={{ paddingBottom: 0 }}
                                            >
                                                <h2 className="font-primary">
                                                    {/* <DollarSign /> */}
                                                    {/* <span> {wallet_info.wallet_amount.toFixed(2)} </span> */}
                                                    <span> {this.ShowCurrencyNum(wallet_info.wallet_amount.toFixed(2), true)} </span>
                                                </h2>
                                                <p>Current Wallet Balance</p>
                                            </div>
                                            <div
                                                className="upadates text-center"
                                                style={{ paddingBottom: 0 }}
                                            >
                                                <h2 className="font-primary">
                                                    {/* <DollarSign />
                                                    <span> {wallet_info.cashback_amount.toFixed(2)} </span> */}
                                                    <span> {this.ShowCurrencyNum(wallet_info.cashback_amount.toFixed(2), true)} </span>
                                                </h2>
                                                <p>Cashback</p>
                                            </div>
                                        </div>
                                        <div className="card-header" style={{ border: "none", padding: "10px 30px 0" }}>
                                            <h5 style={{ display: "inline-block" }}>Transaction History</h5>
                                            {(this.state.activePage) * this.state.pageSize > transactions.length ?
                                                <p className="pull-right" style={{ display: "inline-block" }}>Showing {((this.state.activePage - 1) * this.state.pageSize) + 1} to {transactions.length} of {transactions.length} entries</p> :
                                                <p className="pull-right" style={{ display: "inline-block" }}>Showing {((this.state.activePage - 1) * this.state.pageSize) + 1} to {(this.state.activePage) * this.state.pageSize} of {transactions.length} entries</p>}
                                        </div>
                                        <div className="py-2">
                                            <input style={{
                                                border: "none",
                                                borderBottom: "1px solid rgb(197, 189, 189)",
                                                borderRadius: "0",
                                                margin: "1px 10px",
                                                fontSize: "0.75rem",
                                                padding: "0.25rem 0.50rem",
                                                width: '25%'
                                            }}
                                                type="text"
                                                placeholder="Search "
                                                onChange={(e) => this.onSearch(e)}

                                            />

                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-border-horizontal">
                                                <thead>
                                                    <tr style={{ background: '#f9fafe' }}>
                                                        <th scope="col" style={{ width: "4%" }}>#</th>
                                                        <th scope="col" style={{ width: "8%" }}> Date / Time</th>
                                                        <th scope="col" style={{ width: '10%' }}>Transaction ID</th>
                                                        <th scope="col" >Description</th>
                                                        <th scope="col">Reference</th>
                                                        {/* <th scope="col" style={{ width: "8%" }}>Amount</th>
                                                        <th scope="col">Fees</th> */}
                                                        <th scope="col" style={{ width: "7%" }}>Credit</th>
                                                        <th scope="col" style={{ width: "7%" }}>Debit</th>
                                                        {/* <th scope="col">Wallet amount</th>
                                                        <th scope="col" >Cashback amount</th> */}
                                                        {/* <th scope="col" >Reason</th> */}
                                                        <th scope="col" >Balance</th>
                                                        <th scope="col" >Status</th>
                                                        {access_level != 1 && <th scope="col" style={{ width: "8%" }}>Action</th>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {console.clear()}
                                                    {console.log(searchData, 'sData')}
                                                    {searchData ?
                                                        searchData.slice((this.state.activePage - 1) * this.state.pageSize, (this.state.activePage) * this.state.pageSize).map((item, index) => (
                                                            <tr key={item._id}>
                                                                <td scope="row" style={{ width: "4%" }} className='cust_table_data'>{((this.state.activePage - 1) * this.state.pageSize) + index + 1}</td>
                                                                <td className='cust_table_data' >{this.render_date(item.transaction_date)}</td>
                                                                <td className='cust_table_data' style={{ width: "8%" }}>{item.transaction_no}</td>
                                                                <td className='cust_table_data' >{this.renderTitle(item)}</td>
                                                                <td className='cust_table_data' >{item.reference == 6 ? JSON.parse(item.comment).message : item.description}</td>
                                                                {/* <td>{item.amount>=0? item.amount.toFixed(2) : (<span style={{ color: 'red' }}>${item.amount.toFixed(2)}</span>)} </td> */}
                                                                <td style={{ width: "7%" }} className='cust_table_data'>
                                                                    {/* {item.type == 3 || item.type == 1 || item.type == 4 ?
                                                                        (item.from_customer_id == customer_id ?
                                                                            (<span style={{ color: "red" }}>- ${item.amount.toFixed(2)}</span>)
                                                                            : (<span>+ ${item.amount.toFixed(2)}</span>))
                                                                        : <span>+ ${item.amount.toFixed(2)}</span>} */}
                                                                    {item.to_customer_id == customer_id ?
                                                                        this.ShowCurrencyNum(item.pay_amount.toFixed(2), true) :
                                                                        (
                                                                            item.refund_status == 1 ?
                                                                                this.ShowCurrencyNum(item.refund_data?.amount.toFixed(2), true) :
                                                                                '-'
                                                                        )
                                                                    }
                                                                </td>
                                                                <td style={{ width: '7%' }} className='cust_table_data'>
                                                                    {/* {item.charges == 0 ? "-" : <span>${item.charges.toFixed(2)}</span>} */}
                                                                    {
                                                                        item.from_customer_id == customer_id ?
                                                                            this.ShowCurrencyNum(item.pay_amount.toFixed(2), true) :
                                                                            (
                                                                                item.refund_status == 1 ?
                                                                                    this.ShowCurrencyNum(item.refund_data?.amount.toFixed(2), true)
                                                                                    :
                                                                                    '-'
                                                                            )
                                                                    }
                                                                </td>
                                                                {/* <td >{item.type == 3 || item.type == 1 || item.type == 4 ? (item.from_customer_id == customer_id ? item.wallet_amount == "0" ? "-" : (<span style={{ color: "red" }}>- ${item.wallet_amount.toFixed(2)}</span>) : item.wallet_amount == "0" ? "-" : (<span>+ ${item.wallet_amount.toFixed(2)}</span>)) : <span>+ ${item.wallet_amount.toFixed(2)}</span>}</td>
                                                                <td >{item.type == 3 || item.type == 1 || item.type == 4 ? (item.from_customer_id == customer_id ? item.cashback_amount == "0" ? "-" : (<span style={{ color: "red" }}>- ${item.cashback_amount.toFixed(2)}</span>) : item.cashback_amount == "0" ? "-" : (<span>+ ${item.cashback_amount.toFixed(2)}</span>)) : <span>+ ${item.cashback_amount.toFixed(2)}</span>}</td> */}
                                                                {/* <td >{item.status == 2 ? this.getReason(item) : item.refund_status == 1 ? `$${item.refund_data.amount} was refunded.` : null}</td> */}
                                                                <td className='cust_table_data'>
                                                                    {item.status == 2 ?
                                                                        (item.to_customer_id == customer_id ?
                                                                            this.ShowCurrencyNum((item.to_closing_balance - item.amount).toFixed(2), true) :
                                                                            this.ShowCurrencyNum(item.from_closing_balance.toFixed(2), true)
                                                                        )
                                                                        : item.type == 3 || item.type == 1
                                                                            ?
                                                                            (item.to_customer_id == customer_id ?
                                                                                this.ShowCurrencyNum(item.to_closing_balance.toFixed(2), true)
                                                                                : this.ShowCurrencyNum(item.from_closing_balance.toFixed(2), true)
                                                                            )
                                                                            :
                                                                            this.ShowCurrencyNum((item.from_closing_balance - item.amount).toFixed(2), true)
                                                                    }
                                                                </td>
                                                                <td className='cust_table_data'>
                                                                    {this.getStatus(item.status) == "Failed" ?
                                                                        <span onClick={() => this.statusClick(item.comment)}
                                                                            style={{ color: "Red" }}>
                                                                            {this.getStatus(item.status)}
                                                                        </span>
                                                                        : item.status == 1 ? <p className="authorize" onClick={() => this.onSuccessClick(item)}>{this.getStatus(item.status)}</p>
                                                                            : item.reference == 6 && item.status == 2 ? <p >Rejected</p>
                                                                                : <span >
                                                                                    {this.getStatus(item.status)}
                                                                                </span>
                                                                    }
                                                                </td>
                                                                {access_level != 1 && <td style={{ width: "8%" }}>
                                                                    {access_level == 3 && <span style={{ marginRight: "10px", fontSize: "1.2em", cursor: 'pointer' }}
                                                                        onClick={() => this.deleteClick(item._id)}>
                                                                        <i style={{ color: "red" }} id="delete" title="delete" className="fa fa-trash-o"></i>
                                                                    </span>}
                                                                    <span style={{ marginRight: "10px", fontSize: "1.2em", cursor: 'pointer' }}
                                                                        onClick={() => this.flagClick(item._id)}>
                                                                        <i style={{ color: item.flag_status ? "red" : "black" }} id="block" className="fa fa-flag-o"></i>
                                                                    </span>
                                                                    <span style={{ marginRight: "10px", fontSize: "1.2em", cursor: 'pointer' }}
                                                                        onClick={() => this.infoClick(item._id)}>
                                                                        <i className="fa fa-list-alt"
                                                                            style={{ color: "black" }}></i>
                                                                    </span>
                                                                </td>}
                                                            </tr>
                                                        ))
                                                        :
                                                        null
                                                    }

                                                </tbody>
                                            </table>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "5px 10px 15px" }}>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                    <select className="custom-select" style={{ width: "32%", marginRight: "7px" }} onChange={this.setPageSize} value={this.state.pageSize}>
                                                        <option value="15">15</option>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                        <option value="75">75</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                    <span>Records per Page</span>
                                                </div>
                                                <PaginationComponent
                                                    totalItems={transactions.length}
                                                    pageSize={this.state.pageSize}
                                                    onSelect={this.handlePageChange} />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                                        <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                                            <DisappearedLoading size="medium" color="#514F4E" />
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                ) : (
                    <Redirect to={`${process.env.PUBLIC_URL}/customers/all_customers`} />
                )}
            </Fragment>
        );
    }
}

export default withRouter(CustomerTransaction);
