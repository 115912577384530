import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { Container, Collapse, Card, CardBody } from 'reactstrap';
import 'react-light-accordion/demo/css/index.css';
import { ToastContainer, toast } from "react-toastify";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";

class AddCsvModal extends Component {

    state = {
        file_selected:'',
        formdata:'',
    }

    onChange= async (e) =>{
        
        //this.file_selected==e.target.files;

        this.setState({
            file_selected: e.target.files[0]
        });

        
    }

    handle_upload_csv = async (e) => {
        e.preventDefault();
        let current = this;
        current.setState({ loading: true });

        //console.log(data);
        if (this.state.file_selected == '') {
            toast.error("Please Select  a File");
            return false;
        }

        else {
            //let
            
            var reader = new FileReader();
            reader.readAsDataURL(this.state.file_selected);

            reader.onload = (_event) => {
                let imageData;
                var mimeType = this.state.file_selected.type;

                imageData = reader.result;
                imageData = imageData.split(",")[1];

                let image_data = { mime: mimeType, data: imageData }
                let current = this;
                client.post(api.add_potential_merchant_csv, image_data, async function (error, response) {
                    if (!error) {
                        //alert(JSON.stringify(response));
                        //   return false;
                        if (typeof response.error !== "undefined") {
                            try {
                                if (!response.error) {
    
                                    toast.success("CSV uploaded Successfully");
                                    
    
    
                                    current.setState({ send_mail_modal: false });
    
                                    window.location.reload(true);
    
                                } else {
                                    toast.error(response.message);
                                }
                            } catch (err) {
                                //toast.error(err.message);
                            }
                        } else {
                            toast.error("Internal error occured. Please contact support 2");
                        }
                    } else {
                        toast.error("Internal error occured. Please contact support 3"+error.message);
                    }
                    current.setState({ loading: false });
                });
                
            }

            
        }
    }

    handle_upload_csv_old = async (e) => {

        e.preventDefault();
        let current = this;
        current.setState({ loading: true });

        const data = new FormData()
        data.append("filename", this.state.file_selected)
        data.append("name", 'temp')
    
        const api_url = 'https://api.ipaypro.co/backendapi/potentialMerchants/addPotentialMerchantCSV';

        // const api_url = 'http://localhost:5000/backendapi/potentialMerchants/addPotentialMerchantCSV';
        const settings = {        
            method: "POST",
            body: data,
           
            headers: {
                'Accept': 'application/json',
                'x-access-token':'ipay-access-token',
                'X-CLIENT-VERSION':"1.0"
            },
        }
    
        try {
            const response = await fetch(api_url, settings)
            const result = await response.json()
            if (!result.error) {
                toast.success("CSV uploaded Successfully");
                this.props.toggleModal()
                
            }
        } catch (error) {
            toast.error("Unable to Upload file"+error.message);
            this.props.toggleModal()
        }
    }

    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggleModal} className="modal-body" centered={true}>
                <ModalHeader toggle={this.props.toggleModal}>Add Potential Merchant CSV</ModalHeader>
                <form type="file" className="theme-form" noValidate="" >
                <ModalBody style={{ padding: "10px" }}>
                        <div className="card-body" style={{ padding: "15px" }}> 
                            <input className="form-control" type="file" name="add_csv" accept=".csv,.Csv,.CSV" onChange={(e)=>this.onChange(e)} />
                        </div>
                        
                   
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" type="submit" onClick={(e) => {this.handle_upload_csv(e)}} >Upload</Button>
                    
                  
              </ModalFooter>
              </form>
            </Modal>

        );
    }
}

export default AddCsvModal;