import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import RenderTransactions from "../transactions/RenderTransactions";
import { DisappearedLoading } from "react-loadingg";
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import StripeConnect from "./StripeConnect";


// const BorderTable = () => {
function CustomerSettlements(props) {

    let [loading, setloading] = useState(false);
    // let [result, setresult] = useState(null);

    // const getwithdraw_request = async () => {

    //     let current = this;
    //     // current.setState({ loading: true });
    //     setloading(true);
    //     client.post(api.all_transactions, { type: 4, account_type  : 1, fk_id : props.merchant_id }, async function (error, response) {
    //         if (!error) {
    //             // console.log(JSON.stringify(response.result.data));
    //             //   return false;
    //             if (typeof response.error !== "undefined") {
    //                 try {
    //                     if (!response.error) {
    //                         // console.log(response.result.data)
    //                         if (response.result.data) {
    //                             setresult(response.result.data.reverse());
    //                         }
    //                         // current.props.navigation.navigate("Verification");
    //                     } else {
    //                         toast.error(response.message);
    //                     }
    //                 } catch (e) {
    //                     toast.error("Internal error occured. Please contact support");
    //                 }
    //             } else {
    //                 toast.error("Internal error occured. Please contact support");
    //             }
    //         } else {
    //             toast.error("Internal error occured. Please contact support");
    //             // _showToast.error("Internal error occured. Please contact support.");
    //         }
    //         // current.setState({ loading: false });
    //         setloading(false);

    //     });
    // };

    useEffect(() => {
        // getwithdraw_request();
    }, []);

    return (
        <Fragment>
            {!loading ?
                <div className="container-fluid">
                    <div className="row theme-tab" style={{ marginBottom: '0px !important' }} >
                        <Tabs className="col-sm-12" defaultIndex={0} style={{ padding: "0" }}>
                            <TabList className="tabs tab-title" style={{ marginBottom: '0px !important' }}>
                                <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Requests </Tab>
                                {
                                    props.merchantDataType && props.merchantDataType.type == 'trashMerchant' ? null
                                        : <Tab className="subTab" style={{ display: "flex", alignItems: "center", fontSize: "0.75rem" }}> Payouts </Tab>
                                }
                            </TabList>
                            <div className="tab-content-cls">
                                <TabPanel>
                                    <div className="container-fluid" style={{ paddingTop: '0.5rem' }}>
                                        <RenderTransactions
                                            deleteSelectedClick={() => { }}
                                            imageClicked={() => { }}
                                            receiptClick={() => { }}
                                            // transactions={result}
                                            filter_type={'settlement_merchant'}
                                            fk_id={props.merchant_id}
                                            authorizeTopupClick={() => { }}
                                            hideTo={true}
                                            hideAction={true}
                                            hideReceipt={true}
                                            hideMessage={true}
                                            hideFilter={true}
                                            hideBankDetails={false}
                                            hidetransferFee={true}
                                            hidedue={true}
                                                        activeTab={5}
                                                        settlementPage={true}
                                        />
                                    </div>
                                </TabPanel>
                                {
                                    props.merchantDataType && props.merchantDataType.type == 'trashMerchant' ? null
                                        : <TabPanel>
                                            <div className="container-fluid px-4" style={{ paddingTop: '0.5rem' }}>
                                                <StripeConnect merchant_id={props.profiledata.merchant_id} merchant_info={props.profiledata.merchant_info}></StripeConnect>
                                            </div>
                                        </TabPanel>
                                }
                            </div>
                        </Tabs>
                    </div>
                    {/* <div className="row">
                        <div className="col-sm-12 col-xl-12">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card">
                                            <div className="card-body">
                                                <RenderTransactions
                                                    deleteSelectedClick={() => { }}
                                                    imageClicked={() => { }}
                                                    receiptClick={() => { }}
                                                    // transactions={result}
                                                    filter_type={'settlement_merchant'}
                                                    fk_id={props.merchant_id}
                                                    authorizeTopupClick={() => { }}
                                                    hideTo={true}
                                                    hideAction={true}
                                                    hideReceipt={true}
                                                    hideMessage={true}
                                                    hideFilter={true}
                                                    hideBankDetails={false}
                                                    hidetransferFee={false}
                                                    hidedue={false}
                                                    settlementPage={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                : <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                    <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                        <DisappearedLoading size="medium" color="#514F4E" />
                    </div>
                </div>}
        </Fragment>
    );
}

export default CustomerSettlements;