import React, { useState } from "react";
import useForm from "react-hook-form";
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";

const AddPromoCodes = (props) => {
    let [loadingbtn, setloadingbtn] = useState(false);
    const [duplicatePromo, setDuplicatePromo] = useState(false);
    let [showerrorstatus, setshowerrorstatus] = useState(false);

    const { handleSubmit, register, errors, watch } = useForm({
        defaultValues: {
            discount_type: "0",
        },
    });


    // console.log(props.promoCodesData, "promoCodesData")
    const onSubmit = (form_data) => {
        if (form_data !== "") {
            let duplicate = false;
            if (props.promoCodesData.length > 0) {
                let temp = props.promoCodesData.map((dataa) => {
                    console.log(dataa.promo_code == form_data.promo_code, dataa.promo_code, form_data.promo_code, "cond")
                    if (dataa.promo_code.toUpperCase() == form_data.promo_code.toUpperCase()) {
                        duplicate = true
                    }
                    return dataa
                })

            }
            // console.log(duplicate, "dup")
            if (!duplicate) {
                form_data.promo_code = form_data.promo_code.toUpperCase()
                // console.log(form_data, "form")


                setloadingbtn(true);
                // form_data.discount_type == '1' 
                // ? form_data.discount_type = 'Percentage based' 
                // : form_data.discount_type = 'Amount based'
                // console.log(form_data, 'promoDATA');
                client.post(
                    api.savePromoCodes,
                    form_data,
                    async function (error, response) {
                        if (!error) {
                            if (typeof response.error !== "undefined") {
                                try {
                                    if (!response.error) {
                                        props.toggleModal(1);
                                        toast.success("Added Successfully");
                                        // setcashbackdata(response.result.data);
                                    } else {
                                        toast.error(response.message);
                                    }
                                } catch (e) {
                                    toast.error(
                                        "Internal error occured. Please contact support"
                                    );
                                }
                            } else {
                                toast.error(
                                    "Internal error occured. Please contact support"
                                );
                            }
                        } else {
                            toast.error(
                                "Internal error occured. Please contact support"
                            );
                        }

                        setloadingbtn(false);
                    }
                );
            } else {
                setDuplicatePromo(true)
                toast.error("Code already exists")
            }
        } else {
            setshowerrorstatus(true);
            errors.showMessages();
        }
    };

    return (
        <Modal
            isOpen={props.isOpen}
            toggle={props.toggleModal}
            className="modal-body"
            centered={true}
        >
            <form
                className="needs-validation"
                noValidate=""
                onSubmit={handleSubmit(onSubmit)}
            >
                <ModalHeader toggle={props.toggleModal}>
                    Add Promo Codes
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <Label for="promo_code">Promo code</Label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="promo_code"
                                    placeholder="Promo code"
                                    ref={register({ required: true })}
                                    style={{ textTransform: "uppercase" }}
                                />
                                <span style={{ color: "#ff5370" }}>
                                    {errors.promo_code &&
                                        "Promo code is required"}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <Label for="discount_type">Discount type</Label>
                                {/* remaining */}
                                <select
                                    ref={register({ required: true })}
                                    // {...register("discount_type")}
                                    style={{
                                        width: "100%",
                                        paddingBlock: "0.375rem",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                    }}
                                    name="discount_type"
                                // defaultValue={"Amount based"}
                                >
                                    <option
                                        value="0"
                                        // value="Amount based"
                                        style={{
                                            fontSize: "16px",
                                            lineHeight: "24px",
                                        }}
                                    >
                                        Amount based
                                    </option>
                                    <option
                                        value="1"
                                        // value="Percentage based"
                                        style={{
                                            fontSize: "16px",
                                            lineHeight: "24px",
                                        }}
                                    >
                                        Percentage based
                                    </option>
                                </select>
                                <span style={{ color: "#ff5370" }}>
                                    {errors.title && "select a value"}
                                </span>
                            </div>
                        </div>
                    </div>
                    {/* To be decide as per Discount type */}
                    {watch("discount_type") == "0" && (
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <Label for="amount">Amount</Label>
                                    <input
                                        className="form-control"
                                        type="number"
                                        name="amount"
                                        // pattern="[0-9]*"
                                        ref={register({ required: true })}
                                    />
                                    <span style={{ color: "#ff5370" }}>
                                        {errors.amount &&
                                            "Amount is required"}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                    {watch("discount_type") == '1' && (
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <Label for="percentage">Percentage (%)</Label>
                                    <input
                                        className="form-control"
                                        type="number"
                                        name="percentage"
                                        ref={register({ required: true })}
                                    />
                                    <span style={{ color: "#ff5370" }}>
                                        {errors.percentage &&
                                            "Percentage is required"}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <Label for="valid_from">Valid from</Label>
                                <input
                                    className="form-control"
                                    type="date"
                                    name="valid_from"
                                    ref={register({ required: true })}
                                />
                                <span style={{ color: "#ff5370" }}>
                                    {errors.valid_from &&
                                        "date is required"}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <Label for="valid_till">Valid till</Label>
                                <input
                                    className="form-control"
                                    type="date"
                                    name="valid_till"
                                    ref={register({ required: true })}
                                />
                                <span style={{ color: "#ff5370" }}>
                                    {errors.valid_till &&
                                        "date is required"}
                                </span>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {!loadingbtn ? (
                        <>
                            <Button
                                color="default"
                                type="button"
                                onClick={props.toggleModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                type="submit"
                                onClick={() => setshowerrorstatus(true)}
                            >
                                Save
                            </Button>
                        </>
                    ) : (
                        <div
                            className="loader-box pull-right"
                            style={{ height: "auto" }}
                        >
                            <span
                                className="rotate dashed"
                                style={{ height: 20, width: 20 }}
                            ></span>
                        </div>
                    )}
                </ModalFooter>
            </form>
        </Modal>
    );
};

export default AddPromoCodes;
